import { ExpensePolicy } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { SwitchButton } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as MaskUtils from "~/utils/mask.utils";
import { getTypeName } from "../utils";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Flex } from "~/components/Flex";

export interface ExpensePolicyListItemProps extends DataListItemProps {
  data: ExpensePolicy;
  onEditClick: (item: ExpensePolicy) => void;
  onToggleState: (item: ExpensePolicy) => void;
}

export function ExpensePolicyListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: ExpensePolicyListItemProps) {
  const isMobile = useMobile();

  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Política" data={data.policy.name} />
      <DataColItem headerTitle="Tipo de regra" data={getTypeName(data.typeValue)} />
      <DataColItem headerTitle="Tipo de despesa" data={data.expenseType.name} />
      <DataColItem
        headerTitle="Valor"
        data={
          data.policyParameterExpense.type === "price"
            ? MaskUtils.asCurrency(data.value)
            : data.value
        }
      />
      {isMobile ? (
        <Flex justify="between" css={{ width: "100%" }}>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
          <SwitchButton data-checked={data.isActive} onClick={() => onToggleState(data)} />
        </Flex>
      ) : (
        <>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
          <SwitchButton data-checked={data.isActive} onClick={() => onToggleState(data)} />
        </>
      )}
    </DataListItem>
  );
}

ExpensePolicyListItem.displayName = "ExpensePolicyListItem";
