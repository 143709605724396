import { OrderHistory } from "~/application/types/entities/OrderHistory.type";
import { OrderHistoryDTO } from "../dtos/OrderHistoryDTO";

export function mapOrderHistoryDTO(dto: OrderHistoryDTO): OrderHistory {
  return {
    uuid: dto.uuid,
    responsible: {
      uuid: dto.responsible.uuid,
      name: dto.responsible.name,
      avatarUrl: dto.responsible.avatar_url,
      context: dto.responsible.context,
    },
    description: dto.description,
    status: dto.status,
    hour: dto.hour,
  };
}
