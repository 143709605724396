import { FC, useCallback, useEffect, useState } from "react";
import { DateUtils } from "~/application/utils";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgClockCounterClockwise } from "~/components/Icon/icons";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import { getTime } from "../../hooks/useCountdown";
import { OrderTimerCardProps } from "./types";

export const OrderTimer: FC<OrderTimerCardProps> = ({
  onOrderExpired,
  initialDate,
  targetDate,
  enabled = true,
  isLoading,
  ...props
}) => {
  if (!enabled) return null;

  const handleOrderExpired = useCallback(() => onOrderExpired(), []);

  const [countDown, setCountDown] = useState(
    getTime(targetDate, initialDate) || 0
  );

  let intervalId: NodeJS.Timer | undefined;

  const stopCountdown = useCallback(() => {
    setCountDown(0);
    clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (
      countDown !== null &&
      countDown <= 0 &&
      targetDate.toString() !== "Invalid Date"
    ) {
      handleOrderExpired();
      return stopCountdown();
    }

    if (intervalId) {
      clearInterval(intervalId);
    }

    intervalId = setInterval(() => setCountDown(countDown - 1000), 1000);

    return () => {
      if (!intervalId) return;
      clearInterval(intervalId);
    };
  }, [countDown, setCountDown, handleOrderExpired, stopCountdown]);

  if (countDown <= 0) return null;

  return isLoading ? (
    <>
      <Skeleton variant="text-4" />
      <Skeleton variant="text-6" />
    </>
  ) : (
    <Flex gap="2" align="center" {...props}>
      <Icon as={SvgClockCounterClockwise} css={{ fill: "#C36700", mt: "$1" }} />

      <Col>
        <Text
          css={{
            color: "#C36700",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          Expira em
        </Text>
      </Col>

      <Text
        variant="darkest"
        css={{ fw: 700, color: "#C36700", fontSize: "14px" }}
      >
        {DateUtils.timestamp(countDown || 0)}
      </Text>
    </Flex>
  );
};

OrderTimer.displayName = "OrderTimer";
