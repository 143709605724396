import type { Order } from "~/application/types";
import { createContext, useContext } from "react";
import { Summary } from "~/application/types/entities/Summary.type";

export interface BookingContextType {
  summary: Summary | undefined;
  isFetchingSummary: boolean;
  order?: Order;
  isLoadingOrder: boolean;
}

export const BookingContext = createContext({} as BookingContextType);

export const useBooking = () => useContext(BookingContext);
