import {
  ApiError,
  ApiErrorCode,
  Road,
  RoadSeatData,
} from "~/application/types";
import { ReactQueryHooksOptions } from "../../types";

export interface UseQueryRoadSeatsOptions {
  road: Road;
  options?: ReactQueryHooksOptions;
  maxAttempts: number;
}

export interface UseQueryRoadSeatsReturn {
  data?: RoadSeatData;
  error: ApiError | null;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  onRefetch: () => void;
}

export const SEATS_SEARCH_ERROR = new ApiError({
  data: null,
  message: "Não há assentos disponíveis",
  statusCode: 400,
  code: ApiErrorCode.SEATS_SEARCH_ERROR,
});
