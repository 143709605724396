import queryString from "query-string";

import { DateUtils } from "~/application/utils";
import { api } from "~/infrastructure/api";
import { OfflineHotelDTO, OfflineHotelQueryDTO } from "~/infrastructure/api/dtos";
import {
  CreateOfflineHotelProps,
  DeleteOfflineHotelImageProps,
  FindOfflineHotelByIdProps,
  FindOfflineHotelsProps,
  IOfflineHotelService,
  QuoteOfflineHotelProps,
  RequestOfflineHotelProps,
  UpdateOfflineHotelProps,
} from "./IOfflineHotelService";
import { displayDate } from "~/utils/date.utils";
import { OfflineHotel, PaginatedResource } from "~/application/types";
import { mapOfflineHotelDTO } from "~/infrastructure/api/mappers";
import { FormDataBuilder } from "~/application/utils/object-functions";
import { fileArrayToFileList } from "~/presentation/shared/utils/array-functions";

export class OfflineHotelService implements IOfflineHotelService {
  async create({ agencyId, data, image, images }: CreateOfflineHotelProps): Promise<OfflineHotel> {
    const formDataBuilder = new FormDataBuilder({
      integration_code: data.integrationCode || null,
      description: data.description,
      cnpj: data.cnpj ? data.cnpj.replace(/\D/g, "") : null,
      stars: String(data.stars),
      whatsapp: data.whatsapp.replace(/\D/g, ""),
      address: data.address,
      district: data.district,
      city_uuid: data.city.uuid,
      longitude: data.longitude ? String(data.longitude) : null,
      latitude: data.latitude ? String(data.latitude) : null,
      obs: data.obs || null,
      phone: data.phone ? data.phone.replace(/\D/g, "") : null,
      email: data.email || null,
      site: data.site || null,
      image: image,
    });

    formDataBuilder.appendFiles("images", fileArrayToFileList(images));

    const formData = formDataBuilder.getFormData();

    return await api
      .post<OfflineHotelDTO>(`/agencies/${agencyId}/offline-hotels`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(({ data }) => mapOfflineHotelDTO(data));
  }

  async updateById({ data, image, images }: UpdateOfflineHotelProps): Promise<OfflineHotel> {
    const formDataBuilder = new FormDataBuilder({
      integration_code: data.integrationCode || null,
      description: data.description,
      cnpj: data.cnpj ? data.cnpj.replace(/\D/g, "") : null,
      stars: String(data.stars),
      whatsapp: data.whatsapp.replace(/\D/g, ""),
      address: data.address,
      district: data.district,
      city_uuid: data.city.uuid,
      longitude: data.longitude ? String(data.longitude) : null,
      latitude: data.latitude ? String(data.latitude) : null,
      obs: data.obs || null,
      phone: data.phone ? data.phone.replace(/\D/g, "") : null,
      email: data.email || null,
      site: data.site || null,
      image: image,
    });

    formDataBuilder.appendFiles("images", fileArrayToFileList(images));

    const formData = formDataBuilder.getFormData();

    return await api
      .post<OfflineHotelDTO>(`/offline-hotels/${data.uuid}`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(({ data }) => mapOfflineHotelDTO(data));
  }

  async find({
    agencyId,
    page = 1,
    description,
    city,
  }: FindOfflineHotelsProps): Promise<PaginatedResource<OfflineHotel[]>> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/offline-hotels`,
      query: { page, description, city },
    });

    return await api.get<PaginatedResource<OfflineHotelDTO[]>>(url).then(({ data }) => ({
      links: data.links,
      meta: data.meta,
      data: data.data.map(mapOfflineHotelDTO),
    }));
  }

  async findById({ agencyId, offlineHotelId }: FindOfflineHotelByIdProps): Promise<OfflineHotel> {
    return await api
      .get<OfflineHotelDTO>(`/agencies/${agencyId}/offline-hotels/${offlineHotelId}`)
      .then(({ data }) => mapOfflineHotelDTO(data));
  }

  async toggleActive(data: OfflineHotel): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/offline-hotels/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api.patch<void>(`/offline-hotels/${data.uuid}/active`).then(({ data }) => data);
  }

  async request({ orderId, data }: RequestOfflineHotelProps): Promise<void> {
    const accommodations = data.accommodations.map(({ travelers, adultQuantity }) => ({
      guests: travelers.filter((t) => t).map((t) => t.uuid),
      adult_quantity: adultQuantity,
    }));

    await api
      .post<void, OfflineHotelQueryDTO>(`/orders/${orderId}/hotel/offline`, {
        accommodations,
        amenities: data.amenities.map(({ uuid }) => uuid),
        city_uuid: data.city.uuid,
        checkIn: displayDate(data.checkInDate as Date, DateUtils.DateFormats.ISO_DATE_TIME),
        checkOut: displayDate(data.checkOutDate as Date, DateUtils.DateFormats.ISO_DATE_TIME),
        customer_uuid: data.customerId,
        suggestion: data.suggestion,
        regimen: data.regime?.name,
        obs: data.obs || "",
      })
      .then(({ data }) => data);
  }

  async quote({ room, options }: QuoteOfflineHotelProps): Promise<void> {
    return await api
      .post(`orders/hotel/offline/${room?.uuid}/options`, {
        options: options.map((option) => ({
          uuid: option.uuid || undefined,
          description: option.description,
          address: option.address,
          phone: option.phone,
          email: option.email,
          room_type: option.roomType.name,
          regimen: option.regimen.name,
          provider_value: option.providerValue,
          customer_value: option.customerValue,
          hotel_fee: option.hotelFee,
          hotel_markup: option.hotelMarkup,
          obs_issuer: option.obsIssuer,
          obs_agency: option.obsAgency,
          payment_pix: option.paymentPix,
          payment_credit_card: option.paymentCreditCard,
          amenities: option?.amenities?.map(({ uuid }) => uuid),
          note: option.note,
          check_in: displayDate(
            new Date(option.checkIn as Date),
            DateUtils.DateFormats.ISO_DATE_TIME
          ),
          check_out: displayDate(
            new Date(option.checkOut as Date),
            DateUtils.DateFormats.ISO_DATE_TIME
          ),
          other_taxes: option.otherTaxes,
        })),
      })
      .then(({ data }) => data);
  }

  async selectOption(optionId: string): Promise<void> {
    await api.patch(`orders/hotel/offline/options/${optionId}`).then(({ data }) => data);
  }

  async deleteOption(optionId: string): Promise<void> {
    await api.delete(`orders/hotel/offline/options/${optionId}`).then(({ data }) => data);
  }

  async deleteImage({ offlineHotelId, imageId }: DeleteOfflineHotelImageProps): Promise<void> {
    await api
      .delete(`/offline-hotels/${offlineHotelId}/images/${imageId}`)
      .then(({ data }) => data);
  }
}
