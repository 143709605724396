import { CheapestRoad } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { MetricItemGroup } from "~/core/modules/Order/components/MetricItemGroup";
import { RoadCard } from "../../components/RoadCard";

interface CheapestRoadsSectionProps {
  cheapestRoads: CheapestRoad[];
}

export function CheapestRoadsSection({ cheapestRoads }: CheapestRoadsSectionProps) {
  const chosenRoad = cheapestRoads[0];

  const amountPaid = chosenRoad.totalPrice;
  const lowestAmountFound = (cheapestRoads.length - 1) > 0
    ? cheapestRoads[1].totalPrice
    : amountPaid;

  const isTheBestChoose = amountPaid === lowestAmountFound;

  return (
    <Flex direction="column" css={{ gap: "$13" }}>
      <MetricItemGroup amountPaid={amountPaid} lowestAmountFound={lowestAmountFound} />

      <Flex direction="column">
        <RoadCard data={chosenRoad} isChosen isBestOffer={isTheBestChoose} />

        {cheapestRoads.length > 1 ? (
          <>
            <Text variant="darkest" fw="500" css={{ fw: "500", lineHeight: "$5",  mt: "$6", mb: "$3" }}>
              Opções disponíveis no momento da reserva:
            </Text>

            <Flex direction="column" css={{ gap: "$6" }}>
              {cheapestRoads?.slice(1).map((item, i) => {
                return (
                  <RoadCard 
                    key={item.uuid} 
                    data={item}
                    isBestOffer={i === 0 && !isTheBestChoose}
                  />
                );
              })}
            </Flex>
          </>
        ) : (
          <Text variant="darkest" fw="500" css={{ fw: "500", lineHeight: "$5",  mt: "$6", mb: "$3" }}>
            Essa foi a melhor opção disponível no momento da reserva.
          </Text>
        )}
      </Flex>
    </Flex>
  );
}