import queryString from "query-string";
import { useCallback, useMemo, useState } from "react";
import { routes } from "~/application/theme/routes";
import type { Hotel, HotelQuery, Order } from "~/application/types";
import { toISOString } from "~/application/utils/date-functions";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgFilter, SvgSearch } from "~/components/Icon/icons";
import { Checkbox, SearchBar } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { H4, H5 } from "~/components/Typography";
import { FilterListItem } from "~/core/modules/Booking/components/FilterListItem";
import { OfflineQuote } from "~/presentation/shared/components/OfflineQuote";
import { pluralizeWord } from "~/utils/string.utils";
import { HotelBookingBar } from "../../../../../DeprecatedBooking/modules/BookingHotel/components/HotelBookingBar";
import { Filter } from "../../../../utils";
import { formatDailyGuestText } from "../../utils";
import { HotelListItem } from "./components/HotelListItem";
import { HotelsLazyList } from "./components/HotelsLazyList";
import { DateFormats, displayDate } from "~/utils/date.utils";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Button } from "~/components/Button";
import {
  HotelBudgetActionType,
  useHotelBudgetResult,
} from "../HotelDetailsPage/hooks/useHotelBudget/type";
import { dialogService } from "~/components/DialogStack";
import { HotelBudgetDialog } from "../HotelDetailsPage/hooks/useHotelBudget/components/HotelBudgetDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { BoundsData, MapViewDialog } from "../../components/MapViewDialog";
import { FiltersDialog } from "./components/FiltersDialog";
import { ModalPortal } from "~/core/modules/DeprecatedBooking/components/ModalPortal";

export interface HotelsContainer {
  data: Hotel[];
  dataDefault: Hotel[];
  filters: Filter[];
  hotelQuery: HotelQuery;
  isLoading: boolean;
  isSomeSettled: boolean;
  searchText: string;
  order?: Order;
  setSearchText: (searchO: string) => void;
  onFilterChange: (groupKey: string, optionKey: string) => void;

  onSelect: (item: Hotel) => void;
  onSearchHotels: (data: HotelQuery) => void;
  rangeValue: {
    max?: number;
    min?: number;
    providersFinished: number;
  };
  onRangeValue: ({
    max,
    min,
    providersFinished,
  }: {
    max: number;
    min: number;
    providersFinished: number;
  }) => void;
  countProvidersFinished: number;
  hotelBudget: useHotelBudgetResult;
  queryLoading: {
    isLoading: boolean;
  }[];
  setBounds: (data: BoundsData) => void;
  dataFilterMap: Hotel[];
  onSelectHover: (hotel: Hotel | undefined) => void;
  onSelectInMap: (hotel: Hotel | undefined) => void;
  hotelSelectedInMap: Hotel | undefined;
  hotelSelectedHover: Hotel | undefined;
  setCanFilteredMap: (data: boolean) => void;
  canFilteredMap: boolean;
}

export function HotelsContainer({
  data,
  dataDefault,
  dataFilterMap,
  filters,
  isLoading,
  hotelQuery,
  queryLoading,
  searchText,
  rangeValue,
  isSomeSettled,
  hotelBudget,
  onRangeValue,
  setSearchText,
  onFilterChange,
  onSelect,
  onSearchHotels,
  countProvidersFinished,
  hotelSelectedHover,
  order,
  onSelectHover,
  onSelectInMap,
  setBounds,
  hotelSelectedInMap,
  canFilteredMap,
  setCanFilteredMap,
}: HotelsContainer) {
  const hotelDailyGuestText = useMemo<string>(() => formatDailyGuestText(hotelQuery), [hotelQuery]);

  const { activeBudget, onActiveBudget, state, dispatch } = hotelBudget;
  const { contexts, user } = useUser();

  const isMobile = useMobile();

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isShowFilterDialog, setShowFilterDialog] = useState(false);

  const displayText = useMemo(() => {
    return `Encontramos ${data.length} ${pluralizeWord("hotel", "hotéis", data.length)} para ${[
      hotelQuery?.city?.name,
      hotelQuery?.city?.state,
      hotelQuery?.city?.country,
    ]
      .filter(Boolean)
      .join(", ")}`;
  }, [data, hotelQuery]);

  const listRenderer = useCallback(
    (item: Hotel) => (
      <HotelListItem
        data={item}
        description={hotelDailyGuestText}
        onSelect={onSelect}
        hotelBudget={hotelBudget}
        ishotelSelectedHover={
          hotelSelectedHover?.uuid === item?.uuid || hotelSelectedInMap?.uuid === item?.uuid
        }
        key={item?.uuid}
      />
    ),
    [hotelDailyGuestText, onSelect, hotelBudget, hotelSelectedHover, hotelSelectedInMap]
  );

  const filterRenderer = useCallback(
    (item: Filter, index: number) => (
      <FilterListItem
        data={item}
        onFilterChange={onFilterChange}
        key={item.key}
        isLoading={queryLoading[index]?.isLoading}
      />
    ),
    [onFilterChange, isLoading]
  );

  const openFilterDialog = useCallback(
    () => setShowFilterDialog(!isShowFilterDialog),
    [isShowFilterDialog]
  );

  const handleGeneratePdf = useCallback(
    () =>
      dialogService.showDialog(
        <HotelBudgetDialog
          data={state}
          customer={contexts.customer}
          user={user}
          hotelParams={hotelQuery}
        />
      ),
    [state, contexts.customer, user]
  );

  const orderId = window.location.search.split("&orderId=")[1];

  const quoteUrl = queryString.stringifyUrl({
    url: routes.Booking.OfflineHotel.root,
    query: {
      city: JSON.stringify(hotelQuery?.city),
      accommodations: JSON.stringify(hotelQuery?.accommodations),
      checkIn: toISOString(hotelQuery?.checkInDate as Date),
      checkOut: toISOString(hotelQuery?.checkOutDate as Date),
      orderId,
    },
  });

  const allHotelBudget = state?.length === data?.length;

  const handleSelectallVehicleBudget = useCallback(() => {
    if (allHotelBudget) {
      dispatch?.({
        paylod: [],
        type: HotelBudgetActionType.ADD,
      });
      return;
    }
    dispatch?.({
      paylod: data,
      type: HotelBudgetActionType.ADD,
    });
  }, [allHotelBudget, data]);

  return (
    <Flex
      direction="column"
      gap="8"
      css={{
        overflow: "hidden",
      }}
    >
      <HotelBookingBar
        data={hotelQuery}
        onSubmit={onSearchHotels}
        hotelBudget={hotelBudget}
        order={order}
      />

      <Container
        css={{
          m: 0,
          px: "$2",

          "@mxlg": {
            p: 15,
          },
        }}
      >
        {isShowFilterDialog && (
          <ModalPortal isOpen={true}>
            <FiltersDialog
              countProvidersFinished={countProvidersFinished}
              dataDefault={dataDefault}
              filters={filters}
              rangeValue={rangeValue}
              filterRenderer={filterRenderer}
              onRangeValue={onRangeValue}
              onClose={() => setShowFilterDialog(false)}
            />
          </ModalPortal>
        )}

        <Row
          gap="8"
          css={{
            "@mxlg": {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Flex
            justify="center"
            css={{
              "@lg": {
                display: "none",
              },
            }}
          >
            <Box css={{ py: "$1", width: "100%" }}>
              <Box
                css={{
                  textAlign: "center",
                  backgroundColor: "#0064C5",
                  p: "$3",
                  color: "#FFF",
                  fw: "500",
                  borderRadius: "$md",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                <Text>Check-In</Text>
                <br />
                <Text css={{ fontSize: "10px" }}>
                  {displayDate(hotelQuery?.checkInDate as Date, DateFormats.BASIC_DATE)}
                </Text>
              </Box>
            </Box>
            <Box css={{ py: "$1", width: "100%" }}>
              <Box
                css={{
                  textAlign: "center",
                  backgroundColor: "#FFF",
                  p: "$3",
                  fw: "500",
                  borderRadius: "$md",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                }}
              >
                <Text>Check-Out</Text>
                <br />
                <Text css={{ fontSize: "10px" }}>
                  {displayDate(hotelQuery?.checkOutDate as Date, DateFormats.BASIC_DATE)}
                </Text>
              </Box>
            </Box>
          </Flex>
          <Col
            sz="6"
            css={{
              m: 0,

              "@mxlg": {
                width: "100%",
              },
            }}
          >
            <Flex
              direction="column"
              gap="6"
              css={{
                pt: "$3",
              }}
            >
              <H4
                css={{
                  "@mxlg": {
                    display: "none",
                  },
                }}
              >
                {displayText}
              </H4>
              <Flex
                justify="between"
                gap="2"
                css={{
                  width: "100%",
                }}
                direction="column"
              >
                <Flex gap="2">
                  <SearchBar
                    style={{
                      width: "100%",
                    }}
                    search={searchText}
                    onSearchChange={setSearchText}
                    placeholder="Busca por nome ou endereço"
                  />
                  <Button
                    css={{
                      height: "100%",
                    }}
                    variant="secondary"
                    onClick={() => (isMobile ? setIsShowFilter(true) : openFilterDialog())}
                  >
                    <Icon as={SvgFilter} />
                  </Button>
                </Flex>
                <Flex
                  justify="between"
                  direction={isMobile ? "columnReverse" : "row"}
                  gap="2"
                  css={{
                    width: "100%",
                  }}
                >
                  {activeBudget && (
                    <Flex align="center" gap="2">
                      <Checkbox checked={allHotelBudget} onChange={handleSelectallVehicleBudget} />
                      <Text size="3">Selecionar todos desta página</Text>
                    </Flex>
                  )}
                  <Flex></Flex>

                  <Flex gap="2">
                    {activeBudget && (
                      <Button onClick={handleGeneratePdf} disabled={!state.length}>
                        {" "}
                        Gerar PDF
                      </Button>
                    )}

                    <Button
                      variant="secondary-light"
                      css={{
                        "@mxlg": {
                          flex: "1",
                        },

                        backgroundColor: activeBudget ? "$neutrals-black" : "transparent",
                        color: activeBudget ? "$neutrals-white" : "$neutrals-darkest",
                      }}
                      disabled={!data.length}
                      onClick={() => onActiveBudget(!activeBudget)}
                    >
                      Cotação eletrônica
                    </Button>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                css={{
                  maxHeight: !isMobile ? "63.5vh" : "",
                  minHeight: !isMobile ? "63.5vh" : "",
                }}
                gap="4"
                direction="column"
              >
                {isLoading && (
                  <Box>
                    <Alert variant="info">
                      <Spinner size="sm" />
                      <Text css={{ lineHeight: "$6" }}>
                        Aguarde, estamos procurando as melhores condições para sua hospedagem
                      </Text>
                    </Alert>
                  </Box>
                )}

                <HotelsLazyList
                  css={{
                    overflow: "auto",
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#F4F4F4",
                    },
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      background: "#F4F4F4",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#dad7d7",
                    },
                  }}
                  quoteUrl={quoteUrl}
                  isLoading={!isSomeSettled}
                  items={dataFilterMap}
                  gap="6"
                  render={listRenderer}
                  skeletonQuantity={10}
                  skeletonHeight={312}
                  EmptyComponent={
                    !isLoading ? (
                      <Flex
                        align="center"
                        css={{ width: "100%" }}
                        justify="center"
                        direction="column"
                        gap="2"
                      >
                        <Icon as={SvgSearch} />
                        <Text fw="700">Nenhum resultado encontrado</Text>
                        {isSomeSettled && (!data || !data.length) && (
                          <OfflineQuote quoteUrl={quoteUrl} />
                        )}
                      </Flex>
                    ) : (
                      <></>
                    )
                  }
                />
              </Flex>
            </Flex>
          </Col>
          <Col
            sz="6"
            css={{
              m: 0,
              p: 0,
              "@mxlg": {
                display: "none",
              },
            }}
          >
            <MapViewDialog
              hotelsList={data}
              setBounds={setBounds}
              onSelect={onSelectInMap}
              onSelectHover={onSelectHover}
              hotelSelectedHover={hotelSelectedHover}
              hotelSelectedInMap={hotelSelectedInMap}
              searchText={searchText}
              rangeValue={rangeValue}
              setCanFilteredMap={setCanFilteredMap}
              canFilteredMap={canFilteredMap}
            />
          </Col>
        </Row>
        <Card
          css={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            borderRadius: "0",
            width: isShowFilter ? "95%" : "0",
            transition: "$slow",
            border: "0",
            zIndex: "999",
          }}
        >
          <Flex align="center" justify="between">
            <H5 css={{ ml: "$5", mt: "$5" }}>Filtrar resultados</H5>
            <Icon as={SvgClose} onClick={() => setIsShowFilter(false)} />
          </Flex>
          <CardBody
            css={{
              width: "90%",
              margin: "0 auto",
              mt: "$10",
            }}
          >
            {filters.map(filterRenderer)}
          </CardBody>
        </Card>
      </Container>
    </Flex>
  );
}
