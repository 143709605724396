import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Action, Profile } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Grid } from "~/components/Grid";
import { Text } from "~/components/Text";
import { createFormData } from "./utils";
import { Form } from "~/components/Form";
import { Checkbox, Switch, TextInput } from "~/components/Input";
import { Flex } from "~/components/Flex";
import { formatActionGroup } from "../../utils";
import { Spinner } from "~/components/Spinner";
import { actionService } from "~/application/usecases";
import { QueryKeys } from "~/application/constants";
import { Caption } from "~/components/Typography";

export type FormProfileDialogProps = {
  data?: Profile;
  isNew?: boolean;
  onCloseClick: () => void;
  onSubmit: ({
    actions,
    item,
    refetch,
  }: {
    actions?: string[];
    item?: Profile;
    refetch: () => void;
  }) => Promise<void>;
  onFetchProfileAction: (data: { item: Profile }) => UseQueryResult<Action[], unknown>;
};

export const FormProfileDialog: FC<FormProfileDialogProps> = ({
  data: profile,
  onCloseClick,
  onSubmit,
  onFetchProfileAction,
  isNew,
}) => {
  const {
    refetch,
    isLoading,
    data: actions,
  } = onFetchProfileAction({ item: profile || ({} as Profile) });

  const { data: defaultActions, isLoading: isLoadingDefaultActions } = useQuery(
    [QueryKeys.ACTION],
    async () => await actionService.findAll()
  );

  const { handleSubmit, formState, control, watch, setValue } = useForm({
    defaultValues: createFormData({
      actions,
      item: profile,
    }),
  });

  const data = watch();

  const groupPoliciesByGroup = useMemo(
    () => formatActionGroup({ data: defaultActions }),
    [defaultActions]
  );

  useEffect(() => {
    if (profile?.uuid) {
      refetch();
    }
  }, [profile, refetch]);

  useEffect(() => {
    const currentActions = actions?.map((action) => action.uuid);
    if (currentActions) {
      setValue("actions", currentActions);
    }
  }, [actions, setValue]);

  const handleChangeValue = useCallback(
    ({ action }: { action: Action }) => {
      if (data?.actions?.includes?.(action?.uuid)) {
        setValue(
          "actions",
          data?.actions.filter((actionSlug) => action?.uuid !== actionSlug)
        );

        return;
      }
      setValue("actions", [...(data?.actions || []), action.uuid]);
    },
    [data]
  );

  return (
    <Container size="12" fixed>
      <Form onSubmit={handleSubmit((data) => onSubmit({ ...data, refetch }))}>
        <FormDialog
          title={isNew ? "Novo Perfil" : "Editar perfil"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <Flex
            css={{
              p: "$6",
            }}
            gap="4"
            direction="column"
          >
            <Flex gap="4">
              <Col sz="6">
                <FormControl control={control} name="item.name" required>
                  <FieldLabel>Nome</FieldLabel>
                  <TextInput />
                </FormControl>
              </Col>
              <Col sz="6">
                <FormControl control={control} name="item.isApprover">
                  <FieldLabel>Perfil aprovador</FieldLabel>
                  <Switch>
                    <Caption>{data.item?.isApprover ? "Aprovador" : "Não aprovador"}</Caption>
                  </Switch>
                </FormControl>
              </Col>
            </Flex>

            <Col sz="12">
              <FieldLabel>Ações</FieldLabel>

              {(isLoading && !isNew) || isLoadingDefaultActions ? (
                <Flex justify="center" direction="column" align="center">
                  <Spinner />
                  <Text>Carregando ações</Text>
                </Flex>
              ) : (
                <Grid
                  columns="2"
                  gap="2"
                  css={{
                    overflow: "auto",
                    maxHeight: "400px",
                  }}
                >
                  {groupPoliciesByGroup?.map((group) => (
                    <Flex
                      key={group.group}
                      direction="column"
                      css={{
                        height: "100%",
                      }}
                    >
                      <Flex
                        css={{
                          p: "$3",
                          backgroundColor: "$primary-base",
                        }}
                      >
                        <Text fw="600" variant="white">
                          {group.groupDescription}
                        </Text>
                      </Flex>
                      <Flex
                        gap="2"
                        wrap="wrap"
                        css={{
                          p: "$3",
                          backgroundColor: "$neutrals-lightest",
                          height: "100%",
                        }}
                      >
                        {group.policies.map((action) => (
                          <Flex key={action.uuid} gap="1" align="center">
                            <Checkbox
                              checked={data?.actions?.includes?.(action?.uuid)}
                              onChange={() => handleChangeValue({ action })}
                              size="md"
                            />
                            <Text fw="500">{action.description}</Text>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                </Grid>
              )}
            </Col>
          </Flex>
        </FormDialog>
      </Form>
    </Container>
  );
};
