import { Project } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { ProjectListItem } from "./components/ProjectListItem";
import { SearchBar } from "~/components/Input";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface TabProjectsProps {
  data?: Project[];
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  search: string;
  onCreateProject: () => void;
  onToggleActive: (item: Project) => void;
  onUpdateProject: (item: Project) => void;
  onGoToPage: (value: number) => void;
  onSearch: (value: string) => void;
}

export function TabProjects({
  data,
  currentPage,
  lastPage,
  isLoading,
  onCreateProject,
  search,
  onSearch,
  onGoToPage,
  onToggleActive,
  onUpdateProject,
}: TabProjectsProps) {
  const listRenderer = useCallback(
    (item: Project) => (
      <ProjectListItem
        data={item}
        onEditClick={onUpdateProject}
        onToggleState={onToggleActive}
        key={item.uuid}
      />
    ),
    [onUpdateProject, onToggleActive]
  );
  const isMobile = useMobile();

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Projetos</H4>
          </Col>

          <Col sz="auto" css={{ "@mxlg": { margin: "$5 auto", width: "100%" } }}>
            <SearchBar
              search={search}
              onSearchChange={onSearch}
              placeholder="Encontrar"
              style={isMobile ? { width: "100%" } : undefined}
            />
          </Col>

          <Col sz={{ "@lg": "auto" }}>
            <Button
              title="Novo motivo"
              color="primary"
              onClick={onCreateProject}
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <Icon as={SvgPlus} />
              <Text>Novo projeto</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          isLoading={isLoading}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui projetos cadastrados</Text>
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
