import { Col, Row } from "~/components/Grid";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";

export function EmptyBudgetViewItem() {
  return (
    <Row gapX="1" gapY="1" css={{ minHeight: "8rem", borderTop: "1px solid $primary-base" }}>
      <Col sz="12" css={{ alignSelf: "stretch" }}>
        <Flex align="center" justify="center" css={{ flex: 1, height: "100%"  }}>
          <Text size="3" variant="dark" fw="600">Nenhum orçamento encontrado.</Text>
        </Flex>
      </Col>
    </Row>
  );
}