import { Fragment } from "react";
import { AccountabilityExpense } from "~/application/types";
import { IFindExpenseByAdvanceOrderResult } from "~/application/usecases/AccountabilityExpense/IAccountabilityExpenseService";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgMinus, SvgOrder, SvgPlus } from "~/components/Icon/icons";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import * as MaskUtils from "~/utils/mask.utils";
import { TravelerExpenseListItem } from "./components/TravelerExpenseListItem";

export interface TabAccountabilityExpensesProps {
  result?: IFindExpenseByAdvanceOrderResult;
  isLoading: boolean;
  onEditAccountabilityExpense: (data: AccountabilityExpense) => void;
  onDeleteAccountabilityExpense: (data: AccountabilityExpense) => void;
  onShowVoucher: (data: AccountabilityExpense) => void;
}

export function TabAccountabilityExpenses({
  result,
  isLoading,
  onDeleteAccountabilityExpense,
  onEditAccountabilityExpense,
  onShowVoucher,
}: TabAccountabilityExpensesProps) {
  const totalAdvances = result?.advanceOrder.value || 0;

  const getTotalExpenses = () => {
    const expenses = result?.expenses || [];
    return expenses.reduce((total, { value }) => total + value, 0);
  };

  const totalExpenses = getTotalExpenses();

  return (
    <Fragment>
      <Row>
        <Col sz="8">
          {!result?.expenses || result?.expenses.length === 0 ? (
            <EmptyState>
              <Text>
                Você ainda não possui despesas criadas para este pedido
              </Text>
            </EmptyState>
          ) : (
            <TravelerExpenseListItem
              onEditAccountabilityExpense={onEditAccountabilityExpense}
              onDeleteAccountabilityExpense={onDeleteAccountabilityExpense}
              onShowVoucher={onShowVoucher}
              result={result}
            />
          )}
        </Col>

        <Col sz="4" css={{ position: "fixed", left: "66%", maxWidth: "30%" }}>
          <Cart>
            <CartHeader>
              <Icon as={SvgOrder} size="sm" />
              <Text>Resumo do pedido</Text>
            </CartHeader>

            <CardBody>
              <Flex direction="column" gap="6">
                {isLoading ? (
                  <>
                    <Skeleton variant="text-4" />
                    <Skeleton variant="text-4" />
                    <Skeleton variant="text-4" />
                  </>
                ) : (
                  <>
                    <Flex gap="4" align="center">
                      <Icon variant="dark" as={SvgPlus} size="sm" />
                      <Col>
                        <Text variant="darkest" css={{ fw: 700 }} size="2">
                          Adiantamentos
                        </Text>
                      </Col>
                      <Text variant="darkest" size="2">
                        {MaskUtils.asCurrency(totalAdvances ?? 0)}
                      </Text>
                    </Flex>

                    <Flex gap="4" align="center">
                      <Icon variant="dark" as={SvgMinus} size="sm" />
                      <Col>
                        <Text variant="darkest" css={{ fw: 700 }} size="2">
                          Despesas
                        </Text>
                      </Col>
                      <Text variant="darkest" size="2">
                        {MaskUtils.asCurrency(totalExpenses)}
                      </Text>
                    </Flex>
                    <Flex gap="4" align="center">
                      <Icon variant="dark" as={SvgOrder} size="sm" />
                      <Col>
                        <Text variant="darkest" css={{ fw: 700 }} size="2">
                          Valor reembolsável
                        </Text>
                      </Col>
                      <Text variant="darkest" size="2">
                        {MaskUtils.asCurrency(totalExpenses - totalAdvances)}
                      </Text>
                    </Flex>
                  </>
                )}
              </Flex>
            </CardBody>
          </Cart>
        </Col>
      </Row>
    </Fragment>
  );
}
