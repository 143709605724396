import { useMutation } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "~/application/theme/routes";
import { customerEmployeeService } from "~/application/usecases";
import { ChangeCustomerEmployeePasswordProps } from "~/application/usecases/CustomerEmployee/ICustomerEmployeeService";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { logError } from "~/presentation/shared/utils/errors";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { ChangePasswordContainer } from "./ChangeCustomerEmployeePasswordContainer";

const SNACKBAR_MESSAGES = {
  CHANGE_PASSWORD_SUCCESS_MESSAGE: "Senha alterada com sucesso",
  CHANGE_PASSWORD_ERROR_MESSAGE: "Falha ao alterar senha",
} as const;

const LOG_TAG = "Customer/pages/ChangeCustomerEmployeePasswordPage";

export function ChangeCustomerEmployeePasswordPage(): JSX.Element {
  const navigate = useNavigate();

  const [showSecretKeyInput, setShowSecretKeyInput] = useState(false);

  const { csrfToken } = useParams() as {
    csrfToken: string;
  };

  const { saveAuthToken } = useAuth();

  const { control, formState, handleSubmit, watch } = useForm<
    Omit<ChangeCustomerEmployeePasswordProps, "customerEmployeeId">
  >({
    defaultValues: {
      password: "",
      confirmedPassword: "",
      secretKey: "",
    },
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { password, confirmedPassword, secretKey } = watch();

  const { mutate: mutateChangePassword } = useMutation({
    mutationFn: async () => {
      return await customerEmployeeService.changePassword({
        csrfToken,
        password,
        confirmedPassword,
        secretKey: secretKey?.replace(/\s+/g, ''),
      });
    },
    onMutate: () => {
      dialogService.showDialog(<LoadingDialog message="Alterando senha" />);
    },
    onSuccess: async ({ token, user }) => {
      snackbarService.showSnackbar(
        SNACKBAR_MESSAGES.CHANGE_PASSWORD_SUCCESS_MESSAGE,
        "success"
      );

      saveAuthToken({ authToken: token, user });

      setShowSecretKeyInput(false);
      navigate(routes.Auth.Login.root);
    },
    onError: async (error: any) => {
      logError({
        error,
        logTag: LOG_TAG,
        defaultErrorMessage: SNACKBAR_MESSAGES.CHANGE_PASSWORD_ERROR_MESSAGE,
      });
      
      if (error.code === 'MANDATORY_SECRET_KEY') {
        setShowSecretKeyInput(true);
      }
    },
    onSettled: () => dialogService.popAll(),
  });

  const onSubmit = useCallback(async () => {
    return mutateChangePassword();
  }, []);

  const onError = useCallback<
    SubmitErrorHandler<ChangeCustomerEmployeePasswordProps>
  >((formErrors) => {
    // eslint-disable-next-line no-console
    console.log(formErrors);
  }, []);

  return (
    <ChangePasswordContainer
      onSubmit={handleSubmit(onSubmit, onError)}
      control={control}
      isSubmitting={formState.isSubmitting}
      showSecretKeyInput={showSecretKeyInput}
    />
  );
}
