import { FC } from "react";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgAlertTriangle } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { ErrorDialogProps } from "./types";

export const ErrorDialog: FC<ErrorDialogProps> = ({ error, onCloseClick }) => (
  <Container size="4" fixed>
    <Card
      css={{
        boxShadow: "$sm",
        maxHeight: "100%",
        "@mxmd": {
          p: "$5",
        },
      }}
    >
      <Flex as={CardBody} align="center" direction="column" gap="8">
        <Icon as={SvgAlertTriangle} css={{ size: "$10" }} />

        <Text css={{ textAlign: "center" }}>{error.message}</Text>

        <Button onClick={onCloseClick} fullWidth>
          <Text>Entendi</Text>
        </Button>
      </Flex>
    </Card>
  </Container>
);

ErrorDialog.displayName = "ErrorDialog";
