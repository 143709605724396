// This file contains the basic implementation of a
// component with the on-off behavior.

import React, { forwardRef, type ComponentProps } from "react";
import { styled } from "~/application/theme";

export type InputStandardProps = {
  id?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  name?: string;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: string;
  value?: any;
  size?: "sm" | "md" | "smlg";
}

export type InputBaseProps = ComponentProps<typeof InputBaseRoot> & InputStandardProps & {
  inputProps?: ComponentProps<typeof InputBaseInput>;
  leftAdornment?: React.ReactElement;
  rightAdornment?: React.ReactElement;
};

const InputBaseInput = styled("input", {
  // Reset
  all: "unset",
  boxSizing: 'content-box',
  display: 'block',

  // Custom
  font: 'inherit',
  letterSpacing: 'inherit',
  color: 'currentColor',
  border: 0,
  margin: '0',
  marginLeft: '2px',
  minWidth: '0',
  width: '100%',
});

const InputBaseRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  position: "relative",
  display: "inline-flex",

  // Reset custom
  cursor: 'text',
  
  // Custom
  alignItems: "center",
  borderRadius: "$md",
  transition: "$normal",
  color: "$neutrals-darkest",
  fontSize: "$md",
  lineHeight: "$fontSizes$md",
  backgroundColor: "$neutrals-white",

  variants: {
    size: {
      sm: {
        padding: "0 $4",
        [`& > ${InputBaseInput}`]: {
          padding: "$4 0",
        }
      },
      md: {
        padding: "0 $6",
        [`& > ${InputBaseInput}`]: {
          padding: "$6 0",
        }
      },
      smlg: {
        padding: "0 $3",
        width: "390px",
        [`& > ${InputBaseInput}`]: {
          padding: "$3 0",
        }
      }
    }
  },

  "&::after": {
    content: "",
    pointerEvents: "none",
    position: "absolute",
    inset: 0,
    margin: 0,

    borderColor: "$neutrals-light",
    borderRadius: "inherit",
    borderStyle: "solid",
    borderWidth: "1px",

    transition: "$faster",
  },

  "&:hover::after": {
    borderWidth: "2px",
    borderColor: "$primary-base",
  },

  "&[data-focused=true]": {
    boxShadow: "$focus-md",
  },

  "&[data-filled=true]::after": {
    borderWidth: "2px",
    borderColor: "$primary-base",
  },

  "&[data-dirty=true]": {
    backgroundColor: "$error-light",
    "&::after": {
      borderWidth: "2px",
      borderColor: "$error-base",
    }
  },

  "&[data-disabled=true]": {
    opacity: "$disabled",
    pointerEvents: "none",
    cursor: 'default',
  },

  defaultVariants: {
    size: "md"
  }
});

export const InputBase = forwardRef<
  React.ElementRef<typeof InputBaseRoot>,
  InputBaseProps
>(({
  id,
  autoComplete,
  autoFocus,
  disabled,
  defaultValue,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  maxLength,
  minLength,
  placeholder,
  readOnly,
  required,
  type,
  value,
  // Root props
  inputProps,
  leftAdornment,
  rightAdornment,
  ...props
}, forwardedRef) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const [focused, setFocused] = React.useState(false);
  const [filledState, setFilled] = React.useState(false);
  
  const handleFocus: React.FocusEventHandler<HTMLInputElement>  = (event) => {
    if (onFocus) onFocus(event);

    setFocused(true);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (onBlur) onBlur(event);

    setFocused(false);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (onChange) onChange(event);

    setFilled(event.target.value.length > 0);
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (inputRef.current && event.currentTarget === event.target) {
      inputRef.current.focus();
    }

    if (onClick) onClick(event);
  };

  const filled = !!value?.length ?? filledState;

  return (
    <InputBaseRoot
      data-focused={focused}
      data-filled={filled}
      data-disabled={disabled}
      onClick={handleClick}
      ref={forwardedRef}
      {...props}
    >
      {leftAdornment}
      <InputBaseInput
        id={id}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        defaultValue={defaultValue}
        minLength={minLength}
        maxLength={maxLength}
        name={name}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        type={type}
        value={value}
        {...inputProps}
        ref={inputRef}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      {rightAdornment}
    </InputBaseRoot>
  );
});

InputBase.displayName = "InputBase";
