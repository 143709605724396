import React from "react";
import { SvgAirplane, SvgBold, SvgHeading1, SvgHeading2, SvgItalic, SvgList, SvgListOrdered } from "../Icon/icons";
import { Format, isBlockActive, toggleBlock } from "../../utils/slate-mark.utils";
import { useSlate } from "slate-react";

import { styled } from "@stitches/react";
import { IconButton } from "../IconButton";
import { Icon } from "../Icon";

const ButtonRoot = styled("span", {
    cursor: "pointer",

    variants: {
        active: {
            true: { color: "black" },
            false: { color: "#ccc" },
        },
        reversed: {
            true: {
                color: "white",
                variants: {
                    active: {
                        true: { color: "white" },
                        false: { color: "#aaa" },
                    },
                },
            },
            false: {
                variants: {
                    active: {
                        true: { color: "black" },
                        false: { color: "#ccc" },
                    },
                },
            },
        },
    },
    defaultVariants: {
        active: false,
        reversed: false,
    },
});

export const Button = React.forwardRef<HTMLSpanElement, React.ComponentProps<typeof ButtonRoot>>(
    ({ className, active = false, reversed = false, ...props }, ref) => (
        <ButtonRoot
            {...props}
            ref={ref}
            className={className}
            active={active}
            reversed={reversed}
        />
    )
);


interface BlockButtonProps {
    format: Format;
    icon: IconName;
}


type IconName = "format_bold" | 'format_italic' | 'format_bold' | 'heading-one' | 'bulleted_list' | 'numbered_list' | 'heading-two' | 'format_bold'


export const iconMap: Record<IconName, React.FC<React.SVGProps<SVGSVGElement>>> = {
    'heading-one': SvgHeading1,
    'format_italic': SvgItalic,
    'format_bold': SvgBold,
    'bulleted_list': SvgList,
    'numbered_list': SvgListOrdered,
    'heading-two': SvgHeading2
};


export const BlockButton: React.FC<BlockButtonProps> = ({ format, icon }) => {
    const IconComponent = iconMap[icon];
    const editor = useSlate();

    if (!IconComponent) {
        return null;
    }

    return (
        <Button active={isBlockActive(editor, format)} onMouseDown={(event) => {
            event.preventDefault();
            toggleBlock(editor, format);
        }}>
            <IconButton>
                <Icon as={IconComponent} size="xl" />
            </IconButton>
        </Button>
    );
};