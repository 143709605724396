import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type AppBarProps = ComponentProps<typeof AppBar>;

export const AppBar = styled("header", {
  top: "$sizes$app-bar-height",
  left: 0,
  right: 0,
  backgroundColor: "$neutrals-white",
  position: "sticky",
  zIndex: 1,
  borderTop: "1px solid $neutrals-light",

  "&::after": {
    position: "absolute",
    content: "",
    left: 0,
    width: "100%",
    bottom: 0,
    border: "0",
    borderBottomWidth: "1px",
    borderStyle: "solid",
    borderColor: "$neutrals-light",
  },
});

AppBar.displayName = "AppBar";
