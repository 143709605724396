import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { QueryKeys, QueryTimes } from "~/application/constants";
import { orderService } from "~/application/usecases";
import { logError } from "~/presentation/shared/utils/errors";
import { CheapestRoadsContainer } from "./CheapestRoadsContainer";
import { getCheapestRoads } from "./utils";

const LOG_TAG = "Order/CheapestRoadsPage";

const SNACKBAR_MESSAGES = {
  LOAD_CHEAPEST_FLIGHTS_ERROR_MESSAGE: "Falha ao carregar os ônibus mais baratos",
} as const;

export function CheapestRoadsPage() {
  const { orderId } = useParams() as { orderId: string };

  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get('ticketId');

  const { data, isFetching } = useQuery(
    [QueryKeys.ORDER_ROAD, orderId, ticketId], 
    async () => await orderService.getCheapestRoads(ticketId!), 
    {
      staleTime: QueryTimes.SMALL,
      enabled: !!ticketId,
      refetchOnWindowFocus: true,
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_CHEAPEST_FLIGHTS_ERROR_MESSAGE,
        });
      },
    }
  );

  const cheapestRoads = useMemo(
    () => data && getCheapestRoads(data),
    [data?.cheapestRoads]
  );

  return (
    <CheapestRoadsContainer
      cheapestRoads={cheapestRoads}
      isFetchingCheapestRoads={isFetching}
    />
  );
}