import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { ApiError, ApiErrorCode, RoadSeatData } from "~/application/types";
import { roadSeatsService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { ErrorDialog } from "../../views/ErrorDialog";
import { UseQueryRoadSeatsOptions, UseQueryRoadSeatsReturn } from "./types";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar opções",
} as const;

export function useQueryRoadSeats({
  road,
  options,
  maxAttempts,
}: UseQueryRoadSeatsOptions): UseQueryRoadSeatsReturn {
  const [attempts, setAttempts] = useState(0);
  const { LOG_TAG } = useLogTag();

  const roadSeatsQuery = useQuery(
    [QueryKeys.QUERY_LINKS_ROAD_SEATS, road?.id],
    () => roadSeatsService.query(road),
    {
      ...options,
      staleTime: QueryTimes.LONG,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const {
    data,
    error,
    isLoading,
    isFetching,
    isError,
    refetch: onRefetch,
  } = useQuery<RoadSeatData, ApiError>(
    [QueryKeys.ROAD_SEATS, roadSeatsQuery.data?.link],
    () => roadSeatsService.find(roadSeatsQuery.data!.link),
    {
      staleTime: QueryTimes.LONGEST,
      enabled: !!roadSeatsQuery.data && attempts < maxAttempts,
      retryDelay: roadSeatsQuery.data?.waitTime,
      retry: (errorCount, error) => {
        setAttempts((old) => old + 1);

        return error instanceof ApiError
          ? error.code === ApiErrorCode.RETRY_REQUEST
          : errorCount < maxAttempts;
      },
    }
  );

  if (attempts >= maxAttempts) {
    const error = new ApiError({
      data: null,
      message: "Não foi possível obter a lista de assentos",
      statusCode: 400,
      code: ApiErrorCode.SEATS_SEARCH_ERROR,
    });

    dialogService.popDialog();
    dialogService.showDialog(<ErrorDialog error={error} />);
  }

  return {
    data,
    error,
    isFetching,
    isLoading,
    isError,
    onRefetch,
  };
}
