import { ExpenseType } from '~/application/types';
import { ExpenseTypeDTO } from '../dtos';

export function mapExpenseTypeDTO(data: ExpenseTypeDTO): ExpenseType {
  return {
    uuid: data.uuid,
    name: data.name,
    expenseCategory: {
      uuid: data.expense_category.uuid,
      name: data.expense_category.name,
    },
    customerId: data.customer_uuid,
    isActive: data.active,
  };
}

