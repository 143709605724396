import { Agency, City, State } from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Tab, TabBar, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Fragment } from "react";
import { AgencyForm } from "../../components/AgencyForm";
import { AgencyTab, AGENCY_MENU_TABS } from "../../utils";

export interface CreateAgencyContainerProps {
  defaultData?: Agency;
  onCreateAgency: (agency: Agency) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function CreateAgencyContainer({
  defaultData,
  onCreateAgency,
  fetchCitiesByState,
}: CreateAgencyContainerProps) {
  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <Tooltip content={<TooltipLabel>Voltar para agências</TooltipLabel>}>
            <Link to="/configuracoes/agencias">
              <IconButton size="md">
                <Icon as={SvgChevronLeft} />
              </IconButton>
            </Link>
          </Tooltip>

          <AppBarHeading title="Nova Agência" />
        </AppBarContainer>
      </AppBar>

      <Container css={{ py: "$10" }}>
        <Tabs value={AgencyTab.INFORMATION} vertical>
          <Row gap="6">
            <Col sz="3">
              <Card>
                <TabBar>
                  {AGENCY_MENU_TABS.map(({ title, id }) => (
                    <Tab
                      disabled={id !== AgencyTab.INFORMATION}
                      id={id}
                      value={id}
                      key={id}
                    >
                      <Text>{title}</Text>
                    </Tab>
                  ))}
                </TabBar>
              </Card>
            </Col>

            <Col sz="9">
              <AgencyForm
                formId="create-agency"
                defaultData={defaultData}
                fetchCitiesByState={fetchCitiesByState}
                onSubmit={onCreateAgency}
              />

              <Flex gap="4" justify="end" css={{ mt: "$16" }}>
                <Link to="/configuracoes/agencias">
                  <Button form="create-agency" variant="tertiary" type="reset">
                    <Text>Cancelar</Text>
                  </Button>
                </Link>

                <Button form="create-agency" type="submit">
                  <Text>Adicionar</Text>
                </Button>
              </Flex>
            </Col>
          </Row>
        </Tabs>
      </Container>
    </Fragment>
  );
}
