import { Box } from "~/components/Box";
import { Text } from "~/components/Text";
import { dayjs, getWeekStartOffset } from "~/utils/date.utils";
import { ReactElement, useCallback, useMemo } from "react";
import { CalendarDate, CalendarHead, CalendarRow } from "./styled";
import { createDaysArray, parseValue, WEEKDAYS } from "./utils";

export interface CalendarProps<D = string | Date | null> {
  value?: D | [D, D];
  minValue?: D;
  maxValue?: D;
  referenceMonth?: D;
  ranged?: boolean;
  onDateSelect?: (startDate: Date, endDate: Date | null) => void;
}

export function Calendar({
  maxValue,
  minValue,
  value: valueProp = null,
  onDateSelect: onDateSelectProp,
  ranged = false,
  referenceMonth: referenceMonthProp = new Date(),
  ...props
}: CalendarProps) {
  const refMonth = useMemo(
    () => dayjs(referenceMonthProp).startOf("month"),
    [referenceMonthProp]
  );

  const value = useMemo(() => parseValue(valueProp), [valueProp]);

  const weekStartOffset = useMemo<any>(
    () => getWeekStartOffset(refMonth),
    [refMonth]
  );

  const daysArray = useMemo(() => createDaysArray(refMonth), [refMonth]);

  const onDateSelect = useCallback(
    (dateDay: number) => {
      const dateMonth = dayjs(refMonth);
      const date = dateMonth.set("D", dateDay).toDate();

      const [startDate, endDate] = value;

      if (!onDateSelectProp) return;

      // Reset dates when:
      // 1. The calendar is not "ranged"
      // 2. Start date is null
      // 3. Both start and end date are not null
      if (!ranged || !startDate || (startDate && endDate)) {
        onDateSelectProp(date, null);
        return;
      }

      const [newStart, newEnd] = [startDate, endDate, date].sort((a, b) => {
        if (a === null) {
          return 1;
        }

        if (b === null) {
          return -1;
        }

        if (a === b) {
          return 0;
        }

        return a < b ? -1 : 1;
      });

      onDateSelectProp(newStart!, newEnd);
    },
    [ranged, value, refMonth, onDateSelectProp]
  );

  const isDateDisabled = useCallback(
    (date: Date): boolean => {
      const dateTime = date.getTime();

      if (maxValue && dateTime >= dayjs(maxValue).toDate().getTime())
        return true;
      if (minValue && dateTime < dayjs(minValue).toDate().getTime())
        return true;

      return false;
    },
    [minValue, maxValue]
  );

  const isDateSelected = useCallback(
    (date: Date): boolean => {
      const [startDate, endDate] = value;

      const _endDate = endDate ?? startDate;

      return (
        (startDate ? startDate.getTime() <= date.getTime() : false) &&
        (_endDate ? _endDate.getTime() >= date.getTime() : false)
      );
    },
    [value]
  );

  const isDateActive = useCallback(
    (date: Date): boolean => {
      const [startDate, endDate] = value;

      return (
        (startDate ? startDate.getTime() === date.getTime() : false) ||
        (endDate ? endDate.getTime() === date.getTime() : false)
      );
    },
    [value]
  );

  const renderDailyCell = useCallback(
    (item: number, date: Date): ReactElement => {
      const dateDay = 1 + item;

      const disabled = isDateDisabled(date);
      const selected = isDateSelected(date);
      const active = isDateActive(date);

      return (
        <CalendarDate
          startColumn={item === 0 && weekStartOffset}
          data-disabled={disabled}
          data-selected={selected}
          data-active={active}
          onClick={() => onDateSelect(dateDay)}
          role="cell"
          key={item}
        >
          <div>
            <Text>{dateDay}</Text>
          </div>
        </CalendarDate>
      );
    },
    [
      weekStartOffset,
      isDateDisabled,
      isDateSelected,
      isDateActive,
      onDateSelect,
    ]
  );

  return (
    <Box {...props}>
      <CalendarRow>
        {WEEKDAYS.map((weekday, index) => (
          <CalendarHead role="cell" key={index}>
            <div>
              <Text>{weekday}</Text>
            </div>
          </CalendarHead>
        ))}
      </CalendarRow>

      <CalendarRow>
        {daysArray.map(({ day, date }) => renderDailyCell(day, date))}
      </CalendarRow>
    </Box>
  );
}

Calendar.displayName = "Calendar";
