import { ComponentProps, Fragment, useMemo } from "react";
import { styled } from "~/application/theme";
import { Skeleton } from "../Skeleton";
import { LazyListItem } from "./LazyListItem";

export type LazyListProps<TItem = any> = ComponentProps<typeof LazyListRoot> & {
  quoteUrl?: string;
  isLoading?: boolean;
  skeletonQuantity?: number;
  skeletonHeight?: number | string;
  items: TItem[] | undefined | null;
  render: (item: TItem, index: number) => JSX.Element;
  EmptyComponent?: JSX.Element;
  SkeletonComponent?: JSX.Element;
};

export const LazyListRoot = styled("ol", {
  margin: 0,
  padding: 0,
  marginBlock: 0,
  paddingInline: 0,
  listStyle: "none",

  variants: {
    gap: {
      "4": {
        [`& > ${LazyListItem} + ${LazyListItem}`]: {
          marginTop: "$4",
        },
      },
      "6": {
        [`& > ${LazyListItem} + ${LazyListItem}`]: {
          marginTop: "$6",
        },
      },
      "8": {
        [`& > ${LazyListItem} + ${LazyListItem}`]: {
          marginTop: "$8",
        },
      },
    },
  },

  defaultVariants: {
    gap: "6",
  },
});

export const LazyList = <TItem,>({
  skeletonQuantity = 3,
  skeletonHeight = 80,
  isLoading = false,
  items,
  render,
  EmptyComponent,
  SkeletonComponent,
  ...props
}: LazyListProps<TItem>) => {
  
  const skeletonsArray = useMemo(
    () => (
      <Fragment>
        {Array(skeletonQuantity)
          .fill(null)
          .map((_, index) => (
            <LazyListItem key={index}>
              {SkeletonComponent ?? (
                <Skeleton variant="fluid" style={{ height: skeletonHeight }} />
              )}
            </LazyListItem>
          ))}
      </Fragment>
    ),
    [skeletonQuantity, skeletonHeight]
  );

  const itemsArray = useMemo(
    () =>
      items?.map((item, index) => (
        <LazyListItem key={`lazy-list-item${index}`}>
          {render(item, index)}
        </LazyListItem>
      )),
    [items, render]
  );

  return (
    <Fragment>
      {isLoading || !items ? (
        <LazyListRoot {...props}>{skeletonsArray}</LazyListRoot>
      ) : items.length === 0 ? (
        EmptyComponent
      ) : (
        <LazyListRoot {...props}>{itemsArray}</LazyListRoot>
      )}
    </Fragment>
  );
};

LazyList.displayName = "LazyList";
