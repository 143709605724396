import { useContext } from "react";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { useOrder } from "~/presentation/shared/hooks/useOrder/useOrder";
import { OrderItem } from "~/services/resources/OrderApproval/IOrderApprovalService";
import {
  ApprovalOrderContext,
  IApprovalOrderContext,
} from "../../hooks/ApprovalOrderContext";
import { canShowApprovalButtons } from "../../utils";
import { ApprovalButton } from "./ApprovalButton";

type ApprovalButtonsParams = {
  item: OrderItem;
  itemIndex?: number;
} & Pick<IApprovalOrderContext, "approveItem" | "reproveItem">;

export function ApprovalButtons({
  item,
  approveItem,
  reproveItem,
  itemIndex
}: ApprovalButtonsParams) {
  const { items } = useContext(ApprovalOrderContext);
  const { order } = useOrder();
  const thereIsApprovedItem = items?.approved.some(
    ({ uuid }) => uuid === item.itemOrderId
  );

  const headerHeight = document.getElementById("app-bar-header")?.offsetHeight || 0;

  const thereIsRejectedItem = items?.rejected?.some(({ itemOrderId }) => itemOrderId === item.itemOrderId);

  if (!canShowApprovalButtons(order)) {
    return null;
  }

  function handleApproveItem(item: OrderItem, itemIndex?: number) {
    approveItem(item);

    if (itemIndex === undefined) {
      return;
    }

    const nextIndex = itemIndex + 1;
    const cards = document.querySelectorAll(".item-card");

    if (nextIndex < cards.length) {
      const nextCard = cards[nextIndex];

      const nextCardTop = nextCard.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = nextCardTop - (headerHeight + 100);

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async function handleReproveItem(item: OrderItem, itemIndex?: number) {
    reproveItem(item);

    if (itemIndex === undefined) {
      return;
    }
    
    await new Promise((resolve) => setTimeout(resolve, 100));

    
    const cards = document.querySelectorAll(".approval-message-card");

    if (cards.length === 1) {
      itemIndex = 0;
    }

    const card = cards[itemIndex];
    const cardTop = card.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = cardTop - (headerHeight + 100);

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  return (
    <Flex
      direction="column"
      css={{
        "@mxlg": {
          width: "100%",
          mt: "$4",
        },
      }}
    >
      <Flex gap="2">
        <Col>
          <Tooltip
            content={<TooltipLabel>Aprovar item do pedido</TooltipLabel>}
          >
            <ApprovalButton
              onApproveItem={() => handleApproveItem(item, itemIndex)}
              onReproveItem={() => handleReproveItem(item, itemIndex)}
              thereIsRejectedItem={thereIsRejectedItem}
              thereIsApprovedItem={thereIsApprovedItem}
            />
          </Tooltip>
        </Col>

        <Col
          css={{
            "@mxlg": {
              display: "flex",
              justifyContent: "end",
            },
          }}
        >
          <Tooltip
            content={<TooltipLabel>Reprovar item do pedido</TooltipLabel>}
          >
            <ApprovalButton
              isReject
              onApproveItem={() => handleApproveItem(item, itemIndex)}
              onReproveItem={() => handleReproveItem(item, itemIndex)}
              thereIsRejectedItem={thereIsRejectedItem}
              thereIsApprovedItem={thereIsApprovedItem}
            />
          </Tooltip>
        </Col>
      </Flex>
    </Flex>
  );
}
