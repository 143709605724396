import { useLocation } from "react-router-dom";

import { CUSTOMER_REPORTS_MENU_TABS, CustomerReportTab } from "../utils";

export function useReportTab(): CustomerReportTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(CustomerReportTab).includes(hash)) {
    return hash as CustomerReportTab;
  }

  return CUSTOMER_REPORTS_MENU_TABS[0].id;
}
