import { months } from "../utils";
import { Col, Row } from "~/components/Grid";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";

export function BudgetViewHead() {
  return (
    <Row gapX="1" gapY="1" css={{ height: "$18" }}>
      <Col sz="1" css={{ alignSelf: "stretch" }}>
        <Flex align="center" justify="center" css={{ flex: 1, height: "100%"  }}>
          <Text size="3" variant="dark" fw="600">Nome</Text>
        </Flex>
      </Col>

      <Col sz="1" />

      <Col sz="9" css={{ alignSelf: "stretch" }}>
        <Flex css={{ flex: 1, height: "100%"  }}>
          {months.map((month) => (
            <Flex key={month} align="center" justify="center" css={{ flex: 1 }}>
              <Text size="3" variant="dark" fw="600">{month}</Text>
            </Flex>
          ))}
        </Flex>
      </Col>

      <Col sz="1" css={{ alignSelf: "stretch" }}>
        <Flex align="center" justify="center" css={{ flex: 1, height: "100%" }}>
          <Text size="3" variant="dark" fw="600">Total</Text>
        </Flex>
      </Col>
    </Row>
  );
}