import { Customer } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit, SvgExpenses } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { Link } from "~/components/Link";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import * as MaskUtils from "~/utils/mask.utils";

export interface CustomerListItemProps extends DataListItemProps {
  data: Customer;
  onToggleState: (item: Customer) => void;
  onToggleFraudBlocking: (item: Customer) => void;
  updateCreditLimit: (item: Customer) => void;
}

export function CustomerListItem({
  data,
  onToggleState,
  onToggleFraudBlocking,
  updateCreditLimit,
  ...props
}: CustomerListItemProps) {
  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{
        "@mxlg": {
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Razão social" data={data.companyName} />
      <DataColItem headerTitle="Nome fantasia" data={data.tradingName} />
      <DataColItem headerTitle="CNPJ" data={MaskUtils.asCNPJ(data.cnpj)} />
      <DataColItem
        headerTitle="Editar"
        data={
          <Tooltip content={<TooltipLabel>Editar empresa</TooltipLabel>}>
            <Link to={`/configuracoes/empresas/${data.uuid}`}>
              <IconButton size="md">
                <Icon as={SvgEdit} />
              </IconButton>
            </Link>
          </Tooltip>
        }
      />
      <DataColItem
        headerTitle="Editar crédito"
        data={
          <Tooltip content={<TooltipLabel>Editar empresa</TooltipLabel>}>
            <IconButton size="lg" onClick={() => updateCreditLimit(data)}>
              <Icon variant={data.invoiceLimit < 2000 ? "error" : undefined} as={SvgExpenses} />
            </IconButton>
          </Tooltip>
        }
      />
      <DataColItem
        headerTitle={"Bloqueado por fraude"}
        data={
          <Switch checked={data.fraudBlocking} onClick={() => onToggleFraudBlocking(data)}>
            <Caption>{data.fraudBlocking ? "Sim" : "Não"}</Caption>
          </Switch>
        }
      />
      <DataColItem
        headerTitle={data.isActive ? "Inativar" : "Ativar"}
        data={
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        }
      />
    </DataListItem>
  );
}

CustomerListItem.displayName = "CustomerListItem";
