export enum AirwaySeatLocation {
  WINDOW = "Window",
  AISLE = "Aisle",
}

export type AirwaySeats = {
  searchKey: string;
  data: {
    id: string;
    arrivalDate: Date;
    departure: string;
    arrival: string;
    flightNumber: string;
    mapSeats: {
      class: string;
      purchaseOfSeats: boolean;
      blueSpace: boolean;
      rowns: {
        row: number;
        isWing: boolean;
        available: boolean;
        startWing: boolean;
        emergencyExit: boolean;
        endWing: boolean;
        columns: {
          column: string;
          busy: boolean;
          location: AirwaySeatLocation;
          situation: string;
          value: number;
          seatTypeDescription: string;
        }[];
      }[];
    }[];
  }[];
};

export type AirwaySeatsForm = {
  search_key: string;
  seats: {
    column: string;
    row: string;
    customer_employee_uuid: string;
    stretch_id: string;
    value: number;
    selected: boolean;
  }[];
};
