import { FC, useCallback } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { ChangeCustomerEmployeePasswordProps } from "~/application/usecases/CustomerEmployee/ICustomerEmployeeService";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { ChangePasswordDialogProps } from "./types";
import { Alert, AlertIcon } from "~/components/Alert";

export const RequestPasswordChangeDialog: FC<ChangePasswordDialogProps> = ({
  onCloseClick,
  onSubmit,
}) => {
  const { formState, handleSubmit } =
    useForm<ChangeCustomerEmployeePasswordProps>({
      defaultValues: {
        password: "",
        confirmedPassword: "",
      },
      reValidateMode: "onBlur",
      mode: "onSubmit",
    });

  const onError = useCallback<
    SubmitErrorHandler<ChangeCustomerEmployeePasswordProps>
  >((formErrors) => {
    // eslint-disable-next-line no-console
    console.log(formErrors);
  }, []);

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormDialog
          title="Solicitar alteração de senha"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>Solicitar</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Row gap="6">
              <Col sz="12">
                <Alert variant="warning">
                  <AlertIcon />
                  <Text css={{ lineHeight: "1.25" }}>
                    Ao clicar em solicitar será enviado um email com um link
                    para alteração da sua senha.
                  </Text>
                </Alert>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

RequestPasswordChangeDialog.displayName = "ChangePasswordDialog";
