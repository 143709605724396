import { Agency } from "~/application/types";
import * as MaskUtils from "~/utils/mask.utils";

export type EditableAgency = Agency;

export function editAgency(data: Agency): EditableAgency {
  return {
    ...data,
    cnpj: MaskUtils.asCNPJ(data.cnpj),
    zipCode: MaskUtils.asCEP(data.zipCode),
  };
}
