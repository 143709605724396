import { cloneElement, Fragment, useCallback, useEffect, useState } from "react";
import { ObserverEvent } from "~/services/SimpleObservable";
import { Dialog, DialogContent, DialogOverlay } from "../Dialog";
import { dialogService, DialogType } from "./DialogService";

export function DialogContainer() {
  const [dialogPack, setDialogPack] = useState<DialogType[]>([]);

  const hideDialogById = useCallback(
    (id: number) => {
      setDialogPack((old) => old.filter((d) => d.id !== id));
    },
    [setDialogPack]
  );

  const onUpdate = (change?: ObserverEvent): void => {
    if (change?.type === "hide") {
      setDialogPack((old) => old.filter((p) => p.id !== change.data));
      return;
    }
    setDialogPack((old) => [...old, { ...dialogService.getDialog() }]);
  };

  useEffect(() => {
    dialogService.subscribe(onUpdate);
  }, []);

  return (
    <Fragment>
      {dialogPack.map((dialog) => (
        <Dialog open key={dialog.id}>
          <DialogOverlay onClick={() => hideDialogById(dialog.id)} />
          <DialogContent>
            {cloneElement(dialog.component, {
              onCloseClick: () => hideDialogById(dialog.id),
            })}
          </DialogContent>
        </Dialog>
      ))}
    </Fragment>
  );
}
