import { Fragment, useState } from "react";
import { Flex } from "~/components/Flex";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { EmptyState } from "~/components/EmptyState";

export const DashboardRouter = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { user, contexts } = useUser()
  const dashboardLink = user.customer?.dashboardLink || contexts?.customer?.dashboardLink;

  if (!dashboardLink) {
    return null
  }

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading
            title="Dashboard"
            description="Visão geral dos gastos com viagens"
          />
        </AppBarContainer>
      </AppBar>

      <Container css={{ px: '$15', pt: '$10', height: '80vh' }}>
        {isLoading && (
          <EmptyState>
            Aguarde enquanto carregamos o dashboard
          </EmptyState>
        )}

        <Flex justify='center' align='center' css={{ width: '100%', height: '100%' }}>
          <iframe
            width='100%'
            height='100%'
            frameBorder='0'
            style={{ display: isLoading ? 'none' : 'block' }}
            onLoad={() => setIsLoading(false)}
            src={dashboardLink}
          />
        </Flex>
      </Container >
    </Fragment >
  );
};

DashboardRouter.displayName = "DashboardRouter";
