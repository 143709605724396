import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { styled } from "~/application/theme";
import { FormControlProvider } from "./FormControlContext";
import { CSS } from "@stitches/react";

export interface FormControlProps<TData extends FieldValues> {
  control: Control<TData, any>;
  name: Path<TData>;
  children?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  pattern?: RegExp;
  min?: number;
  max?: number;
  css?: CSS;
}

const FormControlRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  position: "relative",

  // Custom
  flexDirection: "column",
});

export function FormControl<TData extends FieldValues>({
  control,
  name,
  disabled = false,
  children,
  css,
  ...rules
}: FormControlProps<TData>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onBlur, onChange, value, name, ref }, fieldState: { error } }) => (
        <FormControlProvider
          value={{
            disabled,
            error,
            name,
            value,
            rules,
            onChange,
            onBlur,
            inputRef: ref,
          }}
        >
          <FormControlRoot css={css}>{children}</FormControlRoot>
        </FormControlProvider>
      )}
    />
  );
}

FormControl.displayName = "FormControl";
