import * as React from "react";
import { SVGProps } from "react";

const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeMiterlimit: 10,
      }}
      d="M10 1.875C5.52 1.875 1.875 5.52 1.875 10S5.52 18.125 10 18.125 18.125 14.48 18.125 10 14.48 1.875 10 1.875zm0 1.25A6.866 6.866 0 0 1 16.875 10 6.866 6.866 0 0 1 10 16.875 6.866 6.866 0 0 1 3.125 10 6.866 6.866 0 0 1 10 3.125Z"
    />
    <path
      style={{
        strokeMiterlimit: 10,
      }}
      d="M10 5.625a3.76 3.76 0 0 0-3.75 3.75 3.76 3.76 0 0 0 3.75 3.75 3.76 3.76 0 0 0 3.75-3.75A3.76 3.76 0 0 0 10 5.625zm0 1.25a2.49 2.49 0 0 1 2.5 2.5 2.49 2.49 0 0 1-2.5 2.5 2.49 2.49 0 0 1-2.5-2.5 2.49 2.49 0 0 1 2.5-2.5z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 11.875a6.251 6.251 0 0 0-5.572 3.418.625.625 0 0 0 .273.84.625.625 0 0 0 .84-.274 5.002 5.002 0 0 1 8.918 0 .625.625 0 0 0 .84.274.625.625 0 0 0 .273-.84A6.252 6.252 0 0 0 10 11.875Z"
    />
  </svg>
);

export default SvgPerson;
