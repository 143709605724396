import { ReactNode, useContext, useEffect, useState } from "react";
import { Box } from "../Box";
import { TabsContext } from "./contexts/TabsContext";

export type TabContentProps<TValue extends string | number = string> = {
  value: TValue;
  children: ReactNode;
};

export const TabContent = <TValue extends string | number = string>({
  value,
  children,
}: TabContentProps<TValue>) => {
  const { activeValue } = useContext(TabsContext);

  const [hasMounted, setHasMounted] = useState(activeValue === value);

  useEffect(() => {
    if (!hasMounted && activeValue === value) {
      setHasMounted(true);
    }
  }, [activeValue, value]);

  if (!hasMounted) {
    return null;
  }

  return (
    <Box
      role="tabpanel"
      css={{ display: activeValue === value ? "block" : "none" }}
    >
      {children}
    </Box>
  );
};

TabContent.displayName = "TabContent";
