import * as React from "react";
import { SVGProps } from "react";

const SvgChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M7.5 3.125a.625.625 0 0 0-.441.184.625.625 0 0 0 0 .882L12.867 10 7.06 15.809a.625.625 0 0 0 0 .882.625.625 0 0 0 .882 0l6.25-6.25a.625.625 0 0 0 0-.882l-6.25-6.25a.625.625 0 0 0-.441-.184Z"
    />
  </svg>
);

export default SvgChevronRight;
