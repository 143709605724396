import { Collapse } from "react-collapse";
import { Box } from "~/components/Box";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataListItem, DataListItemProps } from "~/components/List";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";

export interface AdditionalInfoListItemProps extends DataListItemProps {
  isOpen: boolean;
  onExpand?: () => void;
}

export function AdditionalInfoListItem({
  isOpen,
  onExpand,
  children,
  ...props
}: AdditionalInfoListItemProps) {
  return (
    <Box>
      <DataListItem {...props}>
        <Icon as={SvgInfo} size="lg" variant="primary" />

        <Col>
          <Text size="5" css={{ fw: "600" }}>
            Informações adicionais
          </Text>
        </Col>

        <Tooltip
          content={
            <TooltipLabel>
              {isOpen ? "Recolher itens" : "Ver itens"}
            </TooltipLabel>
          }
        >
          <IconButton size="md" onClick={onExpand}>
            <Icon as={isOpen ? SvgChevronUp : SvgChevronDown} />
          </IconButton>
        </Tooltip>
      </DataListItem>

      <Collapse isOpened={isOpen}>
        <Box css={{ mt: "$6" }}>{children}</Box>
      </Collapse>
    </Box>
  );
}

AdditionalInfoListItem.displayName = "AdditionalInfoListItem";
