import * as React from "react";
import { SVGProps } from "react";

const SvgManual = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.375 13.125A1.875 1.875 0 0 1 7.5 15h-.062a3.125 3.125 0 1 0 0 1.25H7.5a3.125 3.125 0 0 0 3.125-3.125h-1.25Zm-5 4.375a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75ZM9.375 8.125h1.25v3.75h-1.25v-3.75ZM15.625 1.25a3.125 3.125 0 0 0-3.063 2.5H12.5a3.125 3.125 0 0 0-3.125 3.125h1.25A1.875 1.875 0 0 1 12.5 5h.063a3.125 3.125 0 1 0 3.062-3.75Zm0 5a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z" />
  </svg>
);

export default SvgManual;
