import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { Text } from "~/components/Text";
import React, { ComponentProps, ComponentType, forwardRef } from "react";
import { styled } from "~/application/theme";

const StyledSimpleListItem = styled(Flex, {
  px: "$4",
  gap: "$4",
  [`& ${Icon}`]: {
    size: "$5",
    fill: "$neutrals-dark",
  },

  "&:hover": {
    backgroundColor: "$neutrals-lightest",
    cursor: "pointer",
  },
});

export type SimpleListItemProps = ComponentProps<
  typeof StyledSimpleListItem
> & {
  title: string;
  description?: string;
  leftIcon?: ComponentType;
  rightIcon?: ComponentType;
};

export const SimpleListItem = forwardRef<
  React.ElementRef<typeof StyledSimpleListItem>,
  SimpleListItemProps
>(({ title, description, leftIcon, rightIcon, ...props }, forwardedRef) => {
  return (
    <StyledSimpleListItem {...props} ref={forwardedRef}>
      {leftIcon && <Icon as={leftIcon} />}
      <Flex direction="column" gap="2" css={{ flex: "1 0 0%" }}>
        <Text as="p" size="3" css={{ fw: "700" }}>
          {title}
        </Text>
        {!!description && (
          <Text size="2" variant="dark">
            {description}
          </Text>
        )}
      </Flex>
      {rightIcon && <Icon as={rightIcon} />}
    </StyledSimpleListItem>
  );
});

SimpleListItem.displayName = "SimpleListItem";
