import queryString from "query-string";
import {
  DeprecatedPaginatedResource,
  PaginatedResource,
  ReasonTrip,
} from "~/application/types";
import { api } from "~/infrastructure/api";
import { ReasonTripDTO } from "~/infrastructure/api/dtos";
import { mapReasonTripDTO } from "~/infrastructure/api/mappers";
import type {
  IFindReasonTripParams,
  IReasonTripService,
} from "./IReasonTripService";

export class ReasonTripService implements IReasonTripService {
  async findById(id: string): Promise<ReasonTrip> {
    return await api
      .get<ReasonTripDTO>(`/customers/reason-trips/${id}`)
      .then(({ data }) => mapReasonTripDTO(data));
  }

  async find({
    page = 1,
    customerId,
    reason,
    active
  }: IFindReasonTripParams): Promise<PaginatedResource<ReasonTrip[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/reason-trips`,
      query: { page, reason, active },
    });

    return await api
      .get<DeprecatedPaginatedResource<ReasonTripDTO>>(url)
      .then(({ data: { data, links, ...meta } }) => ({
        links: links,
        meta: meta,
        data: data.map(mapReasonTripDTO),
      }));
  }

  async create(data: Omit<ReasonTrip, "uuid">): Promise<ReasonTrip> {
    return await api
      .post<ReasonTripDTO>("/customers/reason-trips", {
        reason: data.reason,
        customer_uuid: data.customerId,
      })
      .then(({ data }) => mapReasonTripDTO(data));
  }

  async updateById(data: ReasonTrip): Promise<ReasonTrip> {
    return await api
      .patch<ReasonTripDTO>(`/customers/reason-trips/${data.uuid}`, {
        reason: data.reason,
        customer_uuid: data.customerId,
      })
      .then(({ data }) => mapReasonTripDTO(data));
  }

  async toggleActive(data: ReasonTrip): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/customers/reason-trips/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/customers/reason-trips/${data.uuid}/active`)
      .then(({ data }) => data);
  }
}
