import { DateUtils } from ".";
import { ENV_MODE, IS_DEVELOPMENT } from "../constants";
import { DateFormats } from "./date-functions";

enum LogLevel {
  VERBOSE = "VERBOSE",
  WARNING = "WARNING",
  ERROR = "ERROR",
  INFO = "INFO",
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss.SSS";

const LOG_FUNCTION_BY_LEVEL: { [l in LogLevel]: (...data: any[]) => void } = {
  // eslint-disable-next-line no-console
  [LogLevel.VERBOSE]: console.debug,
  // eslint-disable-next-line no-console
  [LogLevel.WARNING]: console.warn,
  // eslint-disable-next-line no-console
  [LogLevel.ERROR]: console.error,
  // eslint-disable-next-line no-console
  [LogLevel.INFO]: console.info,
};

export let logFile = "";

const handleLog = async (
  level: LogLevel,
  tag: string,
  ...content: any[]
): Promise<void> => {
  const date = DateUtils.format(new Date(), DATE_FORMAT as DateFormats);

  const logText = `${date}: ${level} [${tag}] ${content
    .map((i) => (typeof i !== "string" ? JSON.stringify(i) : i))
    .join(" ")}`;

  if (ENV_MODE !== "prod") {
    logFile += logText + "\n";
  }

  if (IS_DEVELOPMENT) LOG_FUNCTION_BY_LEVEL[level](logText);
};

export const log = {
  v: (tag: string, ...content: any[]) =>
    handleLog(LogLevel.VERBOSE, tag, ...content),
  w: (tag: string, ...content: any[]) =>
    handleLog(LogLevel.WARNING, tag, ...content),
  e: (tag: string, ...content: any[]) =>
    handleLog(LogLevel.ERROR, tag, ...content),
  i: (tag: string, ...content: any[]) =>
    handleLog(LogLevel.INFO, tag, ...content),
};
