export function objectKeysExcept(obj: object, keys: string[]): object {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key))
  );
}

export class FormDataBuilder {
  private formData: FormData;

  constructor(data: Record<string, any>) {
    this.formData = new FormData();
    this.appendData(data);
  }

  appendData(data: Record<string, any>): void {
    for (const key in data) {
      if (data[key] !== null && data[key] != undefined) {
        const value = data[key];
        this.formData.append(key, value);
      }
    }
  }

  appendFiles(key: string, files: FileList): void {
    if (!files.length) return;

    for (const file of files) {
      this.formData.append(`${key}[]`, file);
    }
  }

  getFormData(): FormData {
    return this.formData;
  }
}
