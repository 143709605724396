import { ComponentProps } from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "~/application/theme";

export type LinkProps = ComponentProps<typeof Link>;

export const Link = styled(RouterLink, {
  // Reset
  boxSizing: "border-box",

  // Custom
  alignItems: "center",
  flexShrink: 0,
  outline: "none",
  textDecorationLine: "none",
  lineHeight: "inherit",
  color: "inherit",

  "&[data-disabled=true]": {
    pointerEvents: "none",
  },
});
