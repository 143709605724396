import { useMemo } from "react";

import { CheapestRoad } from "~/application/types";
import { timestamp } from "~/application/utils/date-functions";
import { asCurrency } from "~/application/utils/mask-functions";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip } from "~/components/Tooltip";
import { Caption, H5, Label } from "~/components/Typography";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import { TrackDots } from "~/presentation/shared/components/Track";
import { formatRoadInfo } from "../../utils";
import { Styled } from "./styled";

interface RoadCardProps {
  data: CheapestRoad;
  isChosen?: boolean;
  isBestOffer?: boolean;
}

export function RoadCard({
  data,
  isChosen,
  isBestOffer,
}: RoadCardProps) {
  const roadInfo = useMemo(() => formatRoadInfo(data), [data]);

  return (
    <Styled.Container
      css={{
        borderColor: isChosen && isBestOffer 
          ? "$success-base" 
          : (isChosen ? "$primary-base" : "$neutrals-white")
      }}
    >
      <Flex
        align="center"
        justify="between"
        gap="4"
        wrap="wrap"
        css={{
          px: "$6",
          py: "$3",
        }}
      >
        {data.companyImage ? (
          <CompanyLogo src={data.companyImage} alt={data.company} size="lg" />
        ) : (
          <DefaultCompanyLogo companyName={data.company} />
        )}

        {isBestOffer && <Tag variant="warning-light" css={{ border: "none" }}>Melhor oferta</Tag>}
      </Flex>

      <Flex 
        direction="column"
        gap="2"
        css={{
          px: "$6",
          pt: "$3",
          pb: "$6",
        }}
      >
        <Flex
          direction={{ "@mxlg": "column" }}
          gap="6"
        >
          <Flex direction={{ "@mxsm": "column" }} gap="6" css={{ flex: 1 }}>
            <Flex direction="column" gap={{ "@initial": "2", "@md": "4" }} css={{ flex: 1 }}>
              <Label css={{ fw: 400 }}>{roadInfo.departureRoadInfo.dateTimeHour12}</Label>
              <H5
                css={{
                  "@mxxl": {
                    fontSize: "$md",
                    fw: "bold",
                  },
                }}
              >
                {roadInfo.departureRoadInfo.name}
              </H5>
              <Text
                css={{
                  "@mxxl": {
                    display: "none",
                  },
                }}
                size="2"
                fw="500"
              >
                {roadInfo.departureRoadInfo.date}
              </Text>
            </Flex>

            <Flex 
              direction="column" 
              align={{ "@sm": "end", "@lg": "start" }} 
              gap={{ "@initial": "2", "@md": "4" }} 
              css={{ flex: 1 }}
            >
              <Label css={{ fw: 400 }} size="2">
                {roadInfo.arrivalRoadInfo.dateTimeHour12}
              </Label>
              <H5
                css={{
                  "@mxxl": {
                    fontSize: "$md",
                    fw: "bold",
                  },
                }}
              >
                {roadInfo.arrivalRoadInfo.name}
              </H5>
              <Text
                css={{
                  "@mxxl": {
                    display: "none",
                  },
                }}
                size="2"
                fw="500"
              >
                {roadInfo.arrivalRoadInfo.date}
              </Text>
            </Flex>
          </Flex>

          <Flex 
            direction={{ "@lg": "column" }} 
            justify={{ "@mxlg": "between" }} 
            align={{ "@mxlg": "center" }} 
            gap={{ "@initial": "2", "@md": "4" }}
            css={{ flex: 0.5 }}
          >
            <Flex direction="column">
              <Label css={{ fw: 400 }}>Duração</Label>
              <Caption
                css={{
                  mt: "$2",
                  "@md": { mt: "$4" }
                }}
              >
                {timestamp(data.travelDuration)}
              </Caption>
            </Flex>

            {data.connection && (
              <Flex direction="column" css={{ maxWidth: "72px" }}>
                <TrackDots numberOfDots={1} />
                <Text
                  variant="primary"
                  size="2"
                  css={{
                    lineHeight: "1.6",
                    fw: 600,
                    textDecoration: "underline",
                    cursor: "pointer",
                    mt: "$4",
                    "@mxxl": {
                      mt: 0,
                    },
                  }}
                >
                  2 trechos
                </Text>
              </Flex>
            )}

            <Tag
              variant="info-light"
              css={{
                width: "fit-content",
                "@mxxl": {
                  fontSize: "10px",
                  border: "0",
                },
              }}
            >
              {data.seatClass}
            </Tag>
          </Flex>

          <Flex 
            direction="column" 
            align={{ "@initial": "end", "@mxlg": "center" }} 
            gap={{ "@initial": "2", "@md": "4" }} 
            css={{ height: "100%" }}
          >
            <Label css={{ ta: "end" }}>A partir de</Label>
            <Flex>
              <Text size="5" css={{ fw: "600", lineHeight: "$8", letterSpacing: "0.015em", ta: "end" }}>
                {asCurrency(data.totalPrice)}
              </Text>
              
              <Tooltip
                css={{  p: "$5" }}
                variant="white"
                content={
                  <Flex direction="column" gap="3">
                    <Text>Tarifa: <Text fw="700">{asCurrency(data.price)}</Text></Text>
                    <Text>Taxas e encargos: <Text fw="700">{asCurrency(data.taxPrice)}</Text></Text>
                    <Text>Taxa de serviço: <Text fw="700">{asCurrency(data.fee)}</Text></Text>
                    {data.isInsuranceIncludedOnValue && <Text>Seguro: <Text fw="700">{asCurrency(data.insurance)}</Text></Text>}
                  </Flex>
                }
              >
                <IconButton size="md">
                  <Icon as={SvgInfo} />
                </IconButton>
              </Tooltip>
            </Flex>

            {isChosen && (
              <Flex
                align="center"
                gap="2" css={{
                  px: "$2",
                  py: "$3",
                  borderRadius: "$md",
                  border: `1px solid ${isBestOffer ? '$success-base' : '$primary-base'}`,
                  mt: "auto"
                }}
              >
                <Icon variant={isBestOffer ? "success" : "primary"} as={SvgCheck} css={{ size: "$6" }} />
                <Text variant={isBestOffer ? "sucess" : "primary"} css={{ fw: 600 }}>Escolhida</Text>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex
          align="center"
          gap="2"
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
        >
          <Flex>
            <Tag variant="neutral-light" css={{ p: "5px" }}>
              <Text css={{ fontSize: "8px" }}>{data.providerIdentification}</Text>
            </Tag>
          </Flex>
          <Label css={{ fw: 400 }}>{data.company}</Label>
        </Flex>
      </Flex>
    </Styled.Container>
  );
}

RoadCard.displayName = "RoadCard";
