import * as React from "react";
import { SVGProps } from "react";

const SvgClock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 1.041C5.06 1.041 1.041 5.06 1.041 10c0 4.94 4.019 8.959 8.959 8.959 4.94 0 8.959-4.019 8.959-8.959 0-4.94-4.019-8.959-8.959-8.959zm0 1.25A7.7 7.7 0 0 1 17.709 10 7.7 7.7 0 0 1 10 17.709 7.7 7.7 0 0 1 2.291 10 7.7 7.7 0 0 1 10 2.291Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 4.375A.625.625 0 0 0 9.375 5v5a.625.625 0 0 0 .346.559l3.334 1.668a.625.625 0 0 0 .838-.282.625.625 0 0 0-.28-.838l-2.988-1.494V5A.625.625 0 0 0 10 4.375Z"
    />
  </svg>
);

export default SvgClock;
