import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type TrackProps = ComponentProps<typeof Track>;
export type TrackDotProps = ComponentProps<typeof TrackDot>;

export const Track = styled("span", {
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  backgroundColor: "$neutrals-base",
  height: "2px",
  width: "100%",
  minWidth: "5px",
  borderRadius: "1px",

  "&[data-orientation=vertical]": {
    minWidth: "2px",
    width: "2px",
    height: "auto",
  },
});

export const trackDotCss = {
  boxSizing: "border-box",
  display: "inline-block",
  size: "$2",
  backgroundColor: "$success-dark",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "$neutrals-white",
  borderRadius: "$md",
};

export const TrackDot = styled("span", trackDotCss);

export const TrackItem = styled("div", {
  display: "flex",

  position: "relative",
  pl: "$6",

  "&:first-child::before": {
    top: 0,
  },

  "&:last-child::before": {
    top: "100%",
  },

  "&::before": {
    ...trackDotCss,
    position: "absolute",
    content: "",
    left: "-1px",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
});

Track.displayName = "Track";
TrackDot.displayName = "TrackDot";
