import { AgencyEmployee } from '~/application/types';
import { AgencyEmployeeDTO } from '../dtos';

export function mapAgencyEmployeeDTO(
  data: AgencyEmployeeDTO
): AgencyEmployee {
  return {
    uuid: data.uuid,
    name: data.name,
    phone: data.phone,
    email: data.email,
    cpf: data.cpf,
    type: data.type,
    userId: data.user_uuid,
    agencyId: data.agency_uuid,
    isActive: Boolean(data.active),
  };
}

