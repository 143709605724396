import { HotelRoom } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgInfo } from "~/components/Icon/icons";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";
import * as MaskUtils from "~/utils/mask.utils";

export interface AccommodationListItemProp {
  data: HotelRoom;
  description?: string;
  selected?: boolean;
  onSelect: () => void;
  onOpenDetails: () => void;
  onOpenPolicies: () => void;
}

export function AccommodationListItem({
  data,
  description,
  selected = false,
  onSelect,
  onOpenDetails,
  onOpenPolicies,
}: AccommodationListItemProp) {
  return (
    <Card
      css={{
        overflow: "visible",
        "@mxlg": {
          width: "100%",
          p: "$3",
        },
      }}
    >
      <Flex direction={{ "@mxlg": "column" }}>
        <Col css={{ borderRight: "1px solid $colors$neutrals-lightest" }}>
          <Flex
            direction="column"
            as={CardBody}
            gap="8"
            css={{
              "@mxlg": {
                px: "0",
              },
            }}
          >
            <Flex direction="column" align={{ "@mxlg": "center" }} gap="4">
              <H4 size={{ "@mxlg": "2" }}>{data.description}</H4>

              <Flex gap="2" wrap="wrap">
                {data.roomInfo.nonRefundable ? (
                  <Tag
                    css={{
                      "@mxlg": {
                        border: 0,
                        fontSize: "10px",
                      },
                    }}
                    variant="error-light"
                  >
                    Não Reembolsável
                  </Tag>
                ) : (
                  <Tag
                    css={{
                      "@mxlg": {
                        border: 0,
                        fontSize: "10px",
                      },
                    }}
                    variant="success-light"
                  >
                    Reembolsável
                  </Tag>
                )}

                {data.roomRegimen && (
                  <Tag
                    css={{
                      "@mxlg": {
                        border: 0,
                        fontSize: "10px",
                      },
                    }}
                    variant="info-light"
                  >
                    {data.roomRegimen}
                  </Tag>
                )}
                {!!data?.violatedPolicies?.length && (
                  <ViolatedPoliciesButton data={data.violatedPolicies} />
                )}
              </Flex>
            </Flex>

            <Flex gap="4" justify={{ "@mxlg": "center" }}>
              <Button
                css={{
                  "@mxlg": {
                    height: "$5",
                    p: "$2",
                    fontSize: "10px",
                  },
                }}
                variant="tertiary"
                onClick={onOpenDetails}
              >
                <Text>Ver detalhes</Text>
              </Button>

              <Button
                css={{
                  "@mxlg": {
                    height: "$5",
                    p: "$2",
                    fontSize: "10px",
                  },
                }}
                variant="tertiary"
                onClick={onOpenPolicies}
              >
                <Icon as={SvgInfo} />
                <Text>Políticas</Text>
              </Button>
            </Flex>
          </Flex>
        </Col>

        <Flex direction="column" align={{ "@mxlg": "center" }} as={CardBody} gap="4">
          {description && (
            <Text
              css={{
                "@mxlg": {
                  mt: "$4",
                },
              }}
              as="p"
              size="2"
              variant="dark"
            >
              {description}
            </Text>
          )}

          <H4>{MaskUtils.asCurrency(data.totalPriceWithFee)}</H4>

          <Box
            css={{
              mt: "auto",
              "@mxlg": {
                width: "95%",
              },
            }}
          >
            <Button
              css={{
                "@mxlg": {
                  width: "100%",
                  height: "$10",
                  margin: "0 auto",
                },
              }}
              onClick={onSelect}
              disabled={selected}
            >
              {selected ? (
                <>
                  <Icon as={SvgCheck} size="sm" />
                  <Text>Selecionado</Text>
                </>
              ) : (
                <Text>Selecionar</Text>
              )}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
}
