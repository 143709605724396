import { ComponentProps, forwardRef } from "react";
import { styled } from "~/application/theme";

export type PaginationButtonProps = ComponentProps<typeof PaginationButtonRoot>;

const PaginationButtonRoot = styled("button", {
  // Reset
  all: "unset",
  alignItems: "center",
  boxSizing: "border-box",
  userSelect: "none",
  fontSize: "$md",
  fontWeight: 600,
  whiteSpace: "nowrap",

  // Custom reset?
  display: "inline-flex",
  flexShrink: 0,
  justifyContent: "center",
  lineHeight: "1",
  backgroundColor: "$neutrals-lightest",

  // Custom
  size: "$12",
  fontVariantNumeric: "tabular-nums",
  borderRadius: "$md",
  transition: "$normal",
  color: "$neutrals-dark",

  "&:disabled": {
    pointerEvents: "none",
    color: "$neutrals-light",
    opacity: "$disabled",
  },

  "&:hover": {
    cursor: "pointer",
    color: "$primary-dark",
  },

  "&[data-active=true]": {
    pointerEvents: "none",
    color: "$primary-base",
    backgroundColor: "$primary-light",
  },
});

export const PaginationButton = forwardRef<
  React.ElementRef<typeof PaginationButtonRoot>,
  PaginationButtonProps
>((props, forwardedRef) => {
  return <PaginationButtonRoot type="button" {...props} ref={forwardedRef} />;
});

PaginationButton.displayName = "PaginationButton";
