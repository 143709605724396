import { FC, Ref, forwardRef, useCallback, useMemo, useState } from "react";
import { StringUtils } from "~/application/utils";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { SvgFile, SvgTrash } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { useInputControl } from "../../hooks/useInputControl";
import { Styled } from "./styled";
import { UploadDropzoneProps } from "./types";
import useMobile from "~/presentation/shared/hooks/useMobile";

const Component = (
  { label, description, acceptTypes, onOpenFile, ...props }: UploadDropzoneProps,
  forwardedRef: Ref<any>
) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const isMobile = useMobile();

  const { onChange, inputProps, inputRef } = useInputControl<File | string | null>(props);

  const handleOnClear = useCallback((): void => {
    onChange(null);
  }, []);

  const handleOnClick = useCallback((): void => {
    if (!props.value) {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.click();
        inputElement.value = "";
      }
    }
  }, [inputRef, props.value]);

  const handleDragIn: React.DragEventHandler<HTMLInputElement> = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
  }, []);

  const handleDragLeave: React.DragEventHandler<HTMLInputElement> = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragActive(false);
  }, []);

  const handleDragOver: React.DragEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!isDragActive) {
        setIsDragActive(true);
      }
    },
    [isDragActive]
  );
  const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      event.preventDefault();

      const files = event.target.files;

      if (files?.length) {
        const selectedFile = files.item(0);

        onChange(selectedFile as File);
      }
    },
    [onChange, inputProps, inputRef]
  );

  const handleDrop: React.DragEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setIsDragActive(false);

      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        const filesToUpload: Array<File | null> = [];

        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          filesToUpload.push(event.dataTransfer.files.item(i));
        }

        onChange(filesToUpload[0]);

        event.dataTransfer.clearData();
      }
    },
    [onChange]
  );

  const fileUrl = useMemo(
    () => (props.value instanceof File ? URL.createObjectURL(props.value) : props.value),
    [props.value]
  );

  const handleOpenFile = useCallback(() => {
    if (!fileUrl) return;

    onOpenFile?.(fileUrl, props.value as File);
  }, [fileUrl, onOpenFile]);

  const activeDescription = useMemo(() => {
    return props.value instanceof File ? StringUtils.humanFileSize(props.value.size) : description;
  }, [description, props.value]);

  const activeLabel = useMemo(() => {
    return props.value instanceof File ? props.value.name : label;
  }, [label, props.value]);

  return (
    <Styled.Root
      onDragEnter={handleDragIn}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleOnClick}
      ref={forwardedRef}
    >
      <Styled.Container
        data-dragging={isDragActive}
        data-contented={!!props.value}
        css={{
          "@mxlg": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Styled.IconContainer role="status">
          <Icon as={SvgFile} size="lg" variant="primary" />
        </Styled.IconContainer>

        <Box css={{ flex: "1 0 0%" }}>
          <Text as="p" size="4" css={{ fw: "600" }}>
            {activeLabel}
          </Text>

          {activeDescription && (
            <Text as="p" size="2" variant="darkest" css={{ mt: "$2" }}>
              {activeDescription}
            </Text>
          )}
        </Box>

        {!props.value ? (
          <Button>
            <Text>Upload de arquivos</Text>
          </Button>
        ) : (
          <>
            {onOpenFile && (
              <Tooltip content={<TooltipLabel>Abrir arquivo</TooltipLabel>}>
                <IconButton size="md" onClick={handleOpenFile}>
                  <Icon as={SvgFile} />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip content={<TooltipLabel>Remover arquivo</TooltipLabel>}>
              <IconButton size="md" onClick={handleOnClear}>
                <Icon as={SvgTrash} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Styled.Container>

      <Styled.Input
        {...inputProps}
        tabIndex={-1}
        onChange={handleFileSelect}
        accept={acceptTypes?.join(", ")}
        type="file"
        ref={inputRef}
      />
    </Styled.Root>
  );
};

export const UploadDropzone: FC<UploadDropzoneProps> = forwardRef(Component);

export type UploadDropzone = typeof UploadDropzone;

UploadDropzone.displayName = "UploadDropzone";
