import { CardBody } from "~/components/Card";
import { Text } from "~/components/Text";
import { SimpleDialog } from "~/core/shared/components/SimpleDialog";

export interface ConfirmCreateAdvanceDialogProps {
  onConfirm: () => void;
  onCloseClick?: () => void;
}

export function ConfirmCreateAdvanceDialog({
  onConfirm,
  onCloseClick,
}: ConfirmCreateAdvanceDialogProps) {
  return (
    <SimpleDialog
      size="8"
      title="Deseja realmente criar um novo adiantamento?"
      loadingMessage="Criando pedido e adiantamento"
      cancelTitle="Cancelar"
      confirmTitle="Continuar"
      onConfirm={onConfirm}
      onCloseClick={onCloseClick}
    >
      <CardBody>
        <Text variant="darkest">
          Ao criar um “novo adiantamento” você estará automaticamente criando um
          novo pedido.
        </Text>
      </CardBody>
    </SimpleDialog>
  );
}
