import { FC } from "react";

import { TravelerListItemProps } from "./types";
import { DateUtils } from "~/application/utils";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Col } from "~/components/Grid";

export const TravelerListItem: FC<TravelerListItemProps> = ({ data }) => {
  return (
    <Card>
      <Flex
        css={{
          borderRadius: "$md",
          backgroundColor: "$neutrals-white",
          width: "100%",
          p: "$3 $6 $3 $6",
          "@mxmd": {
            p: 0,
          },
        }}
      >
        <Flex gap="4" direction="column" css={{ width: "100%" }}>
          <Flex
            justify="between"
            direction={{ "@mxmd": "column" }}
            gap="4"
            css={{
              p: "$3",
              "@mxlg": {
                p: "$3",
              },
            }}
          >
            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex
                direction={{ "@initial": "column", "@mxmd": "row" }}
                align={{ "@mxmd": "center" }}
              >
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                      display: "none",
                    },
                  }}
                >
                  Nome
                </Text>

                <Text size={{ "@initial": "2", "@mxmd": "3" }} css={{ fw: "600" }}>
                  {data.fullName}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="4"
              css={{
                "@mxmd": {
                  width: "100%",
                  p: 0,
                  height: 5,
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  CPF
                </Text>

                <Text
                  size="2"
                  css={{
                    fw: "600",
                    "@mxmd": {
                      color: "#727272",
                    },
                  }}
                >
                  {data.cpf}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  RG
                </Text>

                <Text size="2" css={{ fw: "600", wordBreak: "break-all", color: "#727272" }}>
                  {data.rg}
                </Text>
              </Flex>
            </Col>
            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  Data de nascimento
                </Text>

                <Text size="2" css={{ fw: "600", wordBreak: "break-all", color: "#727272" }}>
                  {DateUtils.smallDateFormat(data.dateBirth)}
                </Text>
              </Flex>
            </Col>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

TravelerListItem.displayName = "TravelerListItem";
