import { To } from "react-router-dom";

import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { EditOfflineHotelForm } from "./components/EditOfflineHotelForm";
import { City, OfflineHotel, State } from "~/application/types";
import { Spinner } from "~/components/Spinner";
import { Flex } from "~/components/Flex";
import { DeleteOfflineHotelImageProps, UpdateOfflineHotelProps } from "~/application/usecases/OfflineHotel";

interface EditOfflineHotelContainerProps {
  offlineHotel: OfflineHotel | undefined;
  isFetchingOfflineHotel: boolean;
  isEditingOfflineHotel: boolean;
  editOfflineHotel: (data: UpdateOfflineHotelProps) => void;
  onDeleteOfflineHotelImage: (data: DeleteOfflineHotelImageProps) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function EditOfflineHotelContainer({
  offlineHotel,
  isFetchingOfflineHotel,
  isEditingOfflineHotel,
  editOfflineHotel,
  onDeleteOfflineHotelImage,
  fetchCitiesByState,
}: EditOfflineHotelContainerProps) {
  return (
    <>
    <AppBar>
        <AppBarContainer>
          <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
            <Link to={-1 as To}>
              <IconButton size="md">
                <Icon as={SvgChevronLeft} />
              </IconButton>
            </Link>
          </Tooltip>

          <AppBarHeading title="Editar Hotel Offline" />
        </AppBarContainer>
      </AppBar>

      <Container css={{ py: "$10", "@mxsm": { px: 0 } }}>
        <Card css={{ p: "$10", overflow: "visible", "@mxsm": { p: "$8" } }}>
          {isFetchingOfflineHotel ? (
            <Flex align="center" justify="center">
              <Spinner />
            </Flex>
          ) : (
            <EditOfflineHotelForm
              offlineHotel={offlineHotel!}
              fetchCitiesByState={fetchCitiesByState}
              editOfflineHotel={editOfflineHotel}
              isEditingOfflineHotel={isEditingOfflineHotel}
              onDeleteOfflineHotelImage={onDeleteOfflineHotelImage}
            />
          )}
        </Card>
      </Container>
    </>
  );
}