import { Box } from "~/components/Box";
import { CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";

import { SvgChevronLeft, SvgChevronRight } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import * as DateUtils from "~/utils/date.utils";
import { useCallback, useState } from "react";
import { Calendar } from "./Calendar";

export type DoubleCalendarProps = {
  date: Date;
  minDate?: Date;
  maxDate?: Date;
  activeMinDate?: Date;
  activeMaxDate?: Date | null;
  onDateClick?: (date: Date) => void;
};

/**
 * @deprecated this component needs to be refactored.
 */
export const DoubleCalendar = ({
  date,
  activeMaxDate,
  activeMinDate,
  maxDate,
  minDate,
  onDateClick,
}: DoubleCalendarProps) => {
  const [currentDate, setCurrentDate] = useState(date);

  const handlePrevious = useCallback(() => {
    setCurrentDate((old) => DateUtils.getPreviousMonth(old));
  }, [setCurrentDate]);

  const handleNext = useCallback(() => {
    setCurrentDate((old) => DateUtils.getNextMonth(old));
  }, [setCurrentDate]);

  const nextMonth = DateUtils.getNextMonth(currentDate);

  return (
    <Grid columns={{ "@initial": 2, "@mxmd": 1 }}>
      <Box>
        <CardBody
          css={{
            "@mxmd": {
              px: "$6",
              py: "$2",
            },
          }}
        >
          <Flex gap="4" align="center">
            <IconButton onClick={handlePrevious}>
              <Icon as={SvgChevronLeft} />
            </IconButton>

            <Text
              size="2"
              css={{
                lineHeight: "$4",
                fw: "600",
                textAlign: "center",
                flex: "1 0 0%",
              }}
            >
              {DateUtils.getMonthName(currentDate)}
            </Text>

            <IconButton onClick={handleNext}>
              <Icon as={SvgChevronRight} />
            </IconButton>
          </Flex>
          <Calendar
            date={currentDate}
            activeMinDate={activeMinDate}
            activeMaxDate={activeMaxDate}
            minDate={minDate}
            maxDate={maxDate}
            onDateClick={onDateClick}
          />
        </CardBody>
      </Box>

      <Box
        css={{
          "@mxmd": {
            display: "none",
          },
        }}
      >
        <CardBody>
          <Flex
            gap="4"
            align="center"
            css={{
              "@mxmd": {
                mb: "$4",
                backgroundColor: "$neutrals-lightest",
                padding: "$4",
              },
            }}
          >
            <IconButton onClick={handlePrevious}>
              <Icon as={SvgChevronLeft} />
            </IconButton>
            <Text
              size={{ "@initial": 2, "@mxmd": 3 }}
              css={{
                lineHeight: "$4",
                fw: "600",
                textAlign: "center",
                flex: "1 0 0%",
              }}
            >
              {DateUtils.getMonthName(nextMonth)}
            </Text>

            <IconButton onClick={handleNext}>
              <Icon as={SvgChevronRight} />
            </IconButton>
          </Flex>
          <Calendar
            date={nextMonth}
            activeMinDate={activeMinDate}
            activeMaxDate={activeMaxDate}
            minDate={minDate}
            maxDate={maxDate}
            onDateClick={onDateClick}
          />
        </CardBody>
      </Box>
    </Grid>
  );
};

DoubleCalendar.displayName = "DoubleCalendar";
