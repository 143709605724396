import * as React from "react";
import { SVGProps } from "react";

const SvgFavicon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M120 57.963V44.476h-16.695V20H89.749v24.476H79.707v13.487h10.042v49.452H120V93.928h-16.695V57.963H120Z"
      fill="#003161"
    />
    <path
      d="M39.844 44.476c-8.492 0-14.61 3.247-18.482 7.493L21.487 20H8v87.415h13.362v-7.368c3.871 4.121 9.99 7.368 18.482 7.368 20.105 0 27.473-13.113 27.473-31.47S59.95 44.476 39.844 44.476Zm-2.248 49.452c-11.738 0-16.234-3.497-16.234-17.983 0-14.485 4.496-17.982 16.234-17.982 11.864 0 16.36 3.496 16.36 17.982 0 14.486-4.497 17.983-16.36 17.983Z"
      fill="#FFF"
    />
  </svg>
);

export default SvgFavicon;
