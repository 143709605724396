import { ComponentProps, useContext, useRef } from "react";

import { styled } from "~/application/theme";
import { formatNumber } from "~/utils/string.utils";
import { TripSectionItemBadge } from "./TripSectionItemBadge";
import { TripSectionContext } from "./contexts/TripSectionContext";
import { Text } from "~/components/Text";

const StyledTripSectionItem = styled("div", {
  // Reset
  boxSizing: "border-box",
  outline: "none",
  border: "none",

  // Custom
  display: "flex",
  flexDirection: "row",
  $$badgeBackground: "$colors$neutrals-lightest",
  $$badgeColor: "$colors$neutrals-dark",
  flex: "1 0 0%",
  alignItems: "center",
  fontSize: "$xs",
  padding: "$4 $6",
  background: "$neutrals-white",
  color: "$neutrals-dark",
  fontWeight: 600,
  transition: "$normal",
  userSelect: "none",
  gap: "$2",

  "&[data-enabled=false]": {
    pointerEvents: "none",
  },

  variants: {
    active: {
      true: {
        $$badgeBackground: "$colors$primary-light",
        $$badgeColor: "$colors$primary-base",

        backgroundColor: "$primary-base",
        color: "$neutrals-white",
      },
    },
  },

  compoundVariants: [
    {
      active: false,
      css: {
        "&:hover": {
          cursor: "pointer",
          color: "$neutrals-black",
          $$badgeColor: "$colors$primary-base",
          $$badgeBackground: "$colors$primary-light",
        },
      },
    },
  ],

  defaultVariants: {
    active: false,
  },
});

export type TripSectionItemProps<TValue extends string | number> = ComponentProps<
  typeof StyledTripSectionItem
> & {
  value: TValue;
};

export function TripSectionItem<TValue extends string | number>({
  children,
  value,
  ...props
}: TripSectionItemProps<TValue>) {
  const { activeValue, setActiveValue, register } = useContext(TripSectionContext);

  const stepNumber = useRef(register(value)).current;

  return (
    <StyledTripSectionItem
      active={value === activeValue}
      {...props}
      onClick={() => setActiveValue?.(value)}
    >
      <TripSectionItemBadge role="badge">
        <Text>{formatNumber(stepNumber, 2)}</Text>
      </TripSectionItemBadge>

      {children}
    </StyledTripSectionItem>
  );
}

TripSectionItem.displayName = "TripSectionItem";
