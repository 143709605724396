import { HotelRule } from '~/application/types';
import { HotelRuleDTO } from '../dtos';

export function mapHotelRuleDTO(data: HotelRuleDTO): HotelRule {
  return {
    irrevocableNoShow: data.IrrevocableNoShow,
    amount: {
      currency: data.Amount.Currency,
      description: data.Amount.Description,
      type: data.Amount.Type,
      value: data.Amount.Value,
    },
    deadLine: {
      description: data.DeadLine.Description,
      hours: data.DeadLine.Hours,
      limitDate: data.DeadLine.LimitDate,
      reference: data.DeadLine.Reference,
      type: data.DeadLine.Type,
    },
    penalty: data.Penalty,
  };
}
