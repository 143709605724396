export type ServiceOption = {
  id: string;
  value: string;
  name: string;
};

export const mapServiceToPortuguese = (serviceType: string): ServiceOption => {
  switch (serviceType) {
    case "airway":
      return { id: "1", value: "airway", name: "Aéreo" };
    case "hotel":
      return { id: "2", value: "hotel", name: "Hotel" };
    case "vehicle":
      return { id: "3", value: "vehicle", name: "Carro" };
    case "road":
      return { id: "4", value: "road", name: "Rodoviário" };
    default:
      return { id: "", value: "", name: "" };
  }
};

export const selectOptionsData = [
  {
    id: "1",
    value: "airway",
    name: "Aéreo",
  },
  {
    id: "2",
    value: "hotel",
    name: "Hotel",
  },
  {
    id: "3",
    value: "vehicle",
    name: "Carro",
  },
  {
    id: "4",
    value: "road",
    name: "Rodoviário",
  },
];
