import "./application/theme/react-quill.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppProvider } from "./presentation/core/providers/AppProvider";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <AppProvider>
    <App />
  </AppProvider>
  // </React.StrictMode>
);
