export interface IGroupedData<TData extends object> {
  groupKey: string;
  data: TData[];
}

export function rangedArray(length: number) {
  return Array.from(Array(length).keys());
}

export function groupByKey<TData extends object>({
  data,
  keyExtract,
}: {
  data: TData[];
  keyExtract: (item: TData) => string;
}): IGroupedData<TData>[] {
  const groups = data.reduce<Record<string, TData[]>>((groups, item) => {
    const itemKey = keyExtract(item);

    if (!groups[itemKey]) {
      groups[itemKey] = [];
    }
    groups[itemKey].push(item);

    return groups;
  }, {});

  return Object.entries<TData[]>(groups).map(([key, value]) => ({
    groupKey: key,
    data: value,
  }));
}

export function toObject<TData extends object>(
  data: TData[],
  keyExtract: (item: TData) => string
) {
  return Object.fromEntries(data.map((item) => [keyExtract(item), item]));
}

export function mapSkippingWindow<A, B>(
  arr: A[],
  windowSize: number,
  fn: (_: A[]) => B
) {
  const result: B[] = [];

  for (let i = 0; i <= arr.length - windowSize + 1; i += windowSize) {
    const window = arr.slice(i, i + windowSize);
    result.push(fn(window));
  }

  return result;
}
