import { useCallback, useState } from "react";
import { OrderAirwaySegment } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { OrderFlightDetailsDialog } from "../../components/OrderFlightDetails";
import { CombinedOrderAirwayItemSegmentType } from "../../views/OrderItem/components/OrderAirwayItem";

export interface UseAirwayItemResult {
  isAirwayItemExpanded: boolean;
  onOpenFlightDetails: (data?: OrderAirwaySegment) => void;
  toggleAirwayItemVisible: () => void;
}

export interface UseAirwayItemOptions {
  orderId: string;
  enabled: boolean;
}

export function useAirwayItem({
  orderId,
  enabled, // eslint-disable-line @typescript-eslint/no-unused-vars
}: UseAirwayItemOptions): UseAirwayItemResult {
  const [isAirwayItemExpanded, setAirwayItemExpanded] = useState(true);

  const onOpenFlightDetails = useCallback(
    (data?: OrderAirwaySegment | CombinedOrderAirwayItemSegmentType) => {
      const { segments, combined, value, tracker } = data as CombinedOrderAirwayItemSegmentType;

      dialogService.showDialog(
        <OrderFlightDetailsDialog
          flightValue={value}
          tracker={tracker}
          data={[...segments, ...(combined || [])]}
          onCloseClick={dialogService.popDialog}
        />
      );
    },
    []
  );

  const toggleAirwayItemVisible = useCallback(() => {
    setAirwayItemExpanded((old) => !old);
  }, []);

  return {
    isAirwayItemExpanded,
    toggleAirwayItemVisible,
    onOpenFlightDetails,
  };
}
