import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { SwitchBase } from "../base/SwitchBase";
import { createRadioHOC } from "./createRadioHOC";
import { RadioButton } from "./RadioButton";

export type ContainedRadioProps = ComponentProps<typeof ContainedRadio>;

const ContainedRadioRoot = styled(SwitchBase, {
  // Custom
  display: "flex",
  padding: "$4",
  border: "1px solid",
  borderColor: "$neutrals-light",
  borderRadius: "$md",
  backgroundColor: "$neutrals-white",
  transition: "$normal",
  alignItems: "flex-start",
  
  "&:hover": {
    borderColor: "$primary-base",
  },

  "&[data-checked=true]": {
    backgroundColor: "$primary-light",
    borderColor: "$primary-base",
  },

  "&[data-disabled=true]": {
    opacity: "$disabled",
  },

  [`& > *:focus + ${RadioButton}`]: {
    boxShadow: "$focus-sm",
  },
});

export const ContainedRadio = createRadioHOC(ContainedRadioRoot);

ContainedRadio.displayName = "ContainedRadio";
