import { Fragment, useCallback } from "react";
import { TravelerAdvance } from "~/application/types";
import { LazyList } from "~/components/LazyList";
import { TravelerAdvanceListItem } from "./TravelerAdvanceListItem";

export interface OrderAdvanceItemProps {
  isLoading: boolean;
  data?: TravelerAdvance[];
}

export function OrderAdvanceItem({
  isLoading, //eslint-disable-line @typescript-eslint/no-unused-vars
  data, //eslint-disable-line @typescript-eslint/no-unused-vars
}: OrderAdvanceItemProps) {
  const listRenderer = useCallback(
    (item: TravelerAdvance) => (
      <TravelerAdvanceListItem data={item} key={item.uuid} />
    ),
    []
  );

  return (
    <Fragment>
      <LazyList
        gap="8"
        isLoading={isLoading}
        items={data}
        render={listRenderer}
        skeletonQuantity={3}
        skeletonHeight={178}
      />
    </Fragment>
  );
}

OrderAdvanceItem.displayName = "OrderAdvanceItem";
