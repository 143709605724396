import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { DialogHeader } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgCreditCard, SvgWhatsapp } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";

export const CreditLimitExceededDiolog = () => {
  /*todo: add whatsapp number*/
  const handleWhatsappMessage = () => {
    const redirectUrl =
      "https://api.whatsapp.com/send/?phone=5599985442224&text=Ol%C3%A1+Kennedy+Viagens%2C+gostaria+de+falar+com+o+setor+financeiro.&type=phone_number&app_absent=0";
    window.open(redirectUrl);
    dialogService.popDialog();
  };
  return (
    <Card
      css={{
        boxShadow: "$sm",
        maxHeight: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "$8",
        px: "$20",
        gap: "$6",
        textAlign: "center",
        maxWidth: "670px",
        position: "relative",
      }}
    >
      <IconButton
        css={{
          position: "absolute",
          right: "$10",
          stroke: "$primary-base",
        }}
        onClick={() => dialogService.popDialog()}
      >
        <Icon as={SvgClose} />
      </IconButton>
      <Icon
        as={SvgCreditCard}
        size="3xl"
        css={{
          stroke: "$primary-base",
        }}
      />
      <DialogHeader
        css={{
          borderBottom: "none",
          p: "0",
        }}
      >
        <H5
          css={{
            maxWidth: "400px",
            fw: "bold",
          }}
        >
          Seu limite de crédito é insuficiente
        </H5>
      </DialogHeader>
      <Text
        css={{
          color: "$neutrals-dark",
        }}
        size={2}
      >
        Entre em contato com a agência para mais informações.
      </Text>
      <Button
        onClick={handleWhatsappMessage}
        css={{
          px: "$8",
          backgroundColor: "$success-base",
          "&:hover": {
            backgroundColor: "$success-dark",
          },
        }}
      >
        <Icon as={SvgWhatsapp} />
        Entrar em contato
      </Button>
    </Card>
  );
};
