import { DeepPartial, Hotel } from "~/application/types";

export type useHotelBudgetResult = {
  activeBudget: boolean;
  onActiveBudget: (active: boolean) => void;
  state: HotelBudgetState;
  dispatch?: React.Dispatch<HotelBudgetAction>;
  onSelectHotelBudget: ({ data }: { data: Hotel }) => void;
};

export type HotelBudgetState = DeepPartial<Hotel>[];

export enum HotelBudgetActionType {
  ADD = "ADDING",
  CLEAR = "CLEAR",
}

export type HotelBudgetAction =
  | {
      type: HotelBudgetActionType.ADD;
      paylod: HotelBudgetState;
    }
  | {
      type: HotelBudgetActionType.CLEAR;
    };
