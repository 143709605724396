import { To } from "react-router-dom";

import { CheapestFlight } from "~/application/types";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { TripSection, TripSectionItem } from "./components/TripSection";
import { TRIP_MENU_SECTIONS, TripSections } from "./utils";
import { TabSection } from "./views/TabSection";

export interface CheapestFlightsContainerProps {
  departureCheapestFlights?: CheapestFlight[];
  arrivalCheapestFlights?: CheapestFlight[];
  isFetchingAirwayDepartureItem: boolean;
  isFetchingAirwayArrivalItem: boolean;
  activeTripSection: TripSections;
  onSetActiveTripSection: (item: TripSections) => void;
}

export function CheapestFlightsContainer({
  departureCheapestFlights,
  arrivalCheapestFlights,
  isFetchingAirwayDepartureItem,
  isFetchingAirwayArrivalItem,
  activeTripSection,
  onSetActiveTripSection,
}: CheapestFlightsContainerProps) {
  const isCombinedOrderAirwayItem = !!departureCheapestFlights && !!arrivalCheapestFlights;

  return (
    <Container size="12" css={{ mt: "$5", px: "$4" }}>
      <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
        <Link to={-1 as To} css={{ width: "fit-content", display: "flex", alignItems: "center" }}>
          <Icon fill="#000" as={SvgChevronLeft} />
        </Link>
      </Tooltip>

      {isFetchingAirwayDepartureItem || isFetchingAirwayArrivalItem ? (
        <Flex direction="column" css={{ gap: "$13", my: "$6" }}>
          <Flex gap="6" direction={{ "@mxmd": "column" }}>
            <Col>
              <Skeleton css={{ height: "11rem" }} />
            </Col>
            <Col>
              <Skeleton css={{ height: "11rem" }} />
            </Col>
            <Col>
              <Skeleton css={{ height: "11rem" }} />
            </Col>
          </Flex>

          <Flex direction="column" gap="6">
            <Skeleton css={{ height: "11.75rem" }} />
            <Skeleton css={{ height: "11.75rem" }} />
          </Flex>
        </Flex>
      ) : (
        isCombinedOrderAirwayItem ? (
          <>
            <TripSection
              activeValue={activeTripSection}
              setActiveValue={onSetActiveTripSection}
              css={{ mt: "$6" }}
            >
              {TRIP_MENU_SECTIONS.map((item) => (
                <TripSectionItem value={item.id} key={item.id}>
                  <Text>{item.title}</Text>
                </TripSectionItem>
              ))}
            </TripSection>

            <Tabs value={activeTripSection}>
              <TabContent value={TripSections.OUTBOUND_SECTION}>
                <TabSection cheapestFlights={departureCheapestFlights!} />
              </TabContent>

              <TabContent value={TripSections.RETURN_SECTION}>
                <TabSection cheapestFlights={arrivalCheapestFlights!} />
              </TabContent>
            </Tabs>
          </>
        ) : (
          departureCheapestFlights ? (
            <TabSection cheapestFlights={departureCheapestFlights!} />
          ) : (
            <TabSection cheapestFlights={arrivalCheapestFlights!} />
          )
        )
      )}
    </Container>
  );
}