import { FC, useMemo } from "react";
import { timestamp } from "~/application/utils/date-functions";
import { asCurrency } from "~/application/utils/mask-functions";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Checkbox } from "~/components/Input";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Caption, H4, H5, Label } from "~/components/Typography";
import { useRoadsPage } from "~/presentation/Booking/BookingRoad/pages/RoadsPage/contexts/RoadsPageContext";
import { formatRoadInfo } from "../../utils";
import { DefaultCompanyLogo } from "../DefaultCompanyLogo/DefaultCompanyLogo";
import { TrackDots } from "../Track";
import { ViolatedPoliciesButton } from "../ViolatedPoliciesButton/ViolatedPoliciesButton";
import { Styled } from "./styled";
import { RoadListItemProps } from "./types";

export const RoadListItem: FC<RoadListItemProps> = ({
  data,
  isSelected,
  TagsElement,
  isGoRoad,
  onSelect,
  onOpenDetails,
  ...props
}) => {
  const roadInfo = useMemo(() => formatRoadInfo(data), [data]);
  const { roadBudget, bookingStep } = useRoadsPage();
  const { activeBudget, onSelectRoadBudget, state } = roadBudget;

  const typeBudget = bookingStep.currentIndex ? "arrival" : "departure";
  const isSelectBudget = state[typeBudget].some((road) => road?.id === data.id);

  return (
    <Styled.Container
      data-active={isSelected}
      {...props}
      css={{
        overflow: "visible",
      }}
    >
      <Flex gap="2" align="start">
        {activeBudget && (
          <Checkbox
            checked={isSelectBudget}
            onChange={() => onSelectRoadBudget({ data, type: typeBudget })}
          />
        )}
        <Flex
          direction="column"
          gap="6"
          css={{
            width: "100%",
          }}
        >
          <Flex align="center" gap="4" {...props} wrap="wrap">
            <Box css={{ flexGrow: "1" }}>
              {data.companyImage ? (
                <CompanyLogo src={data.companyImage} alt={data.company} size="lg" />
              ) : (
                <DefaultCompanyLogo companyName={data.company} />
              )}
            </Box>

            {TagsElement}
            {!!data.violatedPolicies?.length && (
              <ViolatedPoliciesButton data={data.violatedPolicies} />
            )}
          </Flex>

          <Flex direction="column" gap="4">
            <Flex
              wrap={{ "@initial": "noWrap", "@mxxl": "wrap" }}
              gap={{ "@initial": "2", "@mxxl": "1" }}
              justify="between"
            >
              <Flex
                direction={{ "@initial": "column", "@mxxl": "row" }}
                align={{ "@initial": "stretch", "@mxxl": "center" }}
                justify="between"
                gap="4"
              >
                <Label css={{ fw: 400 }}>{roadInfo.departureRoadInfo.dateTimeHour12}</Label>
                <H5
                  css={{
                    "@mxxl": {
                      fontSize: "$md",
                      fw: "bold",
                    },
                  }}
                >
                  {roadInfo.departureRoadInfo.name}
                </H5>
                <Text
                  css={{
                    "@mxxl": {
                      display: "none",
                    },
                  }}
                  size="2"
                  fw="500"
                >
                  {roadInfo.departureRoadInfo.date}
                </Text>
              </Flex>

              <Flex
                direction={{ "@initial": "column", "@mxxl": "row" }}
                align={{ "@initial": "stretch", "@mxxl": "center" }}
                justify="between"
                gap="4"
              >
                <Label css={{ fw: 400 }} size="2">
                  {roadInfo.arrivalRoadInfo.dateTimeHour12}
                </Label>
                <H5
                  css={{
                    "@mxxl": {
                      fontSize: "$md",
                      fw: "bold",
                    },
                  }}
                >
                  {roadInfo.arrivalRoadInfo.name}
                </H5>
                <Text
                  css={{
                    "@mxxl": {
                      display: "none",
                    },
                  }}
                  size="2"
                  fw="500"
                >
                  {roadInfo.arrivalRoadInfo.date}
                </Text>
              </Flex>

              <Flex
                direction={{ "@lg": "column" }}
                justify="between"
                align={{ "@mxxl": "center" }}
                css={{
                  "@mxxl": {
                    width: "100%",
                    height: "$10",
                    mt: "$10",
                  },
                }}
                gap="4"
              >
                <Flex direction="column">
                  <Label css={{ fw: 400 }}>Duração</Label>
                  <Caption
                    css={{
                      mt: "$5",
                      "@mxxl": {
                        mt: 0,
                      },
                    }}
                  >
                    {timestamp(data.travelDuration)}
                  </Caption>
                </Flex>

                {data.connection && (
                  <Flex direction="column">
                    <TrackDots numberOfDots={1} />

                    <Text
                      variant="primary"
                      size="2"
                      onClick={() => onOpenDetails?.(data)}
                      css={{
                        lineHeight: "1.6",
                        fw: 600,
                        textDecoration: "underline",
                        cursor: "pointer",
                        mt: "$4",
                        "@mxxl": {
                          mt: 0,
                        },
                      }}
                    >
                      2 trechos
                    </Text>
                  </Flex>
                )}

                <Tag
                  variant="info-light"
                  css={{
                    "@mxxl": {
                      fontSize: "10px",
                      border: "0",
                    },
                  }}
                >
                  {data.seatClass}
                </Tag>
              </Flex>

              <Flex
                direction="column"
                justify="start"
                align="center"
                gap="4"
                css={{
                  "@mxxl": {
                    width: "100%",
                    mt: "$10",
                  },
                }}
              >
                <Label css={{ ta: "end" }}>A partir de</Label>

                <H4 css={{ fw: 600, ta: "end" }}>{asCurrency(data.price + data.taxPrice)}</H4>
              </Flex>
            </Flex>

            <Flex justify={{ "@initial": "between", "@mxxl": "center" }} align="center">
              <Flex
                align="center"
                gap="2"
                css={{
                  "@mxxl": {
                    display: "none",
                  },
                }}
              >
                <Flex>
                  <Tag variant="neutral-light" css={{ p: "5px" }}>
                    <Text css={{ fontSize: "8px" }}>{data.providerIdentification}</Text>
                  </Tag>
                </Flex>
                <Label css={{ fw: 400 }}>{data.company}</Label>
              </Flex>

              <Button
                disabled={activeBudget}
                onClick={onSelect}
                css={{
                  height: "2rem",
                  px: "$6",
                  "@mxxl": {
                    width: "100%",
                  },
                }}
                size="sm"
              >
                Selecionar {isGoRoad ? "ida" : "volta"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Styled.Container>
  );
};

RoadListItem.displayName = "RoadListItem";
