import { DateFormats, displayDate } from "~/utils/date.utils";

export const dataAtual = new Date();

export const DAYS_OPTIONS = [
  {
    label: "Últimos 30 dias",
    value: displayDate(
      new Date(dataAtual.getTime() - 30 * 24 * 60 * 60 * 1000),
      DateFormats.RANGE_DATE
    ),
  },
  {
    label: "Últimos 60 dias",
    value: displayDate(
      new Date(dataAtual.getTime() - 60 * 24 * 60 * 60 * 1000),
      DateFormats.RANGE_DATE
    ),
  },
  {
    label: "Últimos 90 dias",
    value: displayDate(
      new Date(dataAtual.getTime() - 90 * 24 * 60 * 60 * 1000),
      DateFormats.RANGE_DATE
    ),
  },
];

export enum AgencyReportTab {
  ACCESS = "acesso",
  SHOPPING = "compras",
}

export const AGENCY_REPORTS_MENU_TABS = [
  { title: "Acesso", id: AgencyReportTab.ACCESS },
];
