import { api } from "~/infrastructure/api";
import {
  ImportCostCenterProps,
  ImportCostCenterServiceInterface,
} from "./ImportCostCenterInterface";

export class ImportCostCenterService
  implements ImportCostCenterServiceInterface
{
  async import({ customerId, file }: ImportCostCenterProps): Promise<void> {
    const formData = new FormData();

    formData.append("cost_centers", file);

    await api.post(`/customers/${customerId}/cost-centers/imports`, formData, {
      "Content-Type": "multipart/form-data",
    });
  }
}
