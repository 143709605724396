import { createContext, useContext } from "react";
import { User } from "~/application/types";
import { LoginFormData } from "~/core/modules/Auth/pages/LoginPage";

export type AuthSignInData = {
  user: User;
  storeTokenLocally?: boolean;
  authToken: {
    value?: string;
    createdAt?: Date;
  };
};

export type AuthContextType = {
  user: User;
  authToken: AuthSignInData["authToken"];
  isSigned: boolean;
  isLoading: boolean;
  qrCodeUrl?: string | null;
  saveAuthToken: (data: AuthSignInData) => void;
  signIn: (data: LoginFormData) => void;
  signOut: () => void;
};

export const AuthContext = createContext({} as AuthContextType);

export const useAuth = () => useContext(AuthContext);
