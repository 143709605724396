export async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    try {
      document.execCommand("copy", true, text);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

export function path(
  path: string,
  params: Record<string, string>,
  prefix = ":"
): string {
  let newPath = path;

  Object.entries(params).forEach(([key, value]) => {
    newPath = newPath.replace(prefix + key, value);
  });

  return newPath;
}

export function scrollToTop(smooth = false): void {
  // Timeout needed for chrome
  setTimeout(() => {
    if (smooth) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    window.scrollTo(0, 0);
  }, 0);
}

export function createDownload(
  data: string,
  fileType: string,
  fileName: string
) {
  const blob = new Blob([data], { type: fileType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = fileName;
  link.href = url;
  link.click();
}
