import { Fee } from "~/application/types";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { RadioButton } from "~/components/Input";
import { DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H6, Label } from "~/components/Typography";
import * as OrderUtils from "~/core/shared/utils/order.utils";

export interface FeeListItemProps {
  data: Fee;
  checked: boolean;
  onSelectFee: (item: Fee) => void;
}

export function FeeListItem({ data, checked, onSelectFee }: FeeListItemProps) {
  return (
    <DataListItem data-active={checked}>
      <RadioButton data-checked={checked} onClick={() => onSelectFee(data)} />
      <Col>
        <H6>{data.name}</H6>
      </Col>
      <Flex direction="column">
        <Box css={{ mb: "$4" }}>
          <Label>Serviços</Label>
        </Box>
        <Flex gap="2">
          {data.items.map((item) => (
            <Tooltip
              content={<TooltipLabel>{OrderUtils.getServiceLabel(item.serviceType)}</TooltipLabel>}
              key={item.uuid}
            >
              <IconButton size="md">
                <Icon size="md" as={OrderUtils.getServiceIcon(item.serviceType)} />
              </IconButton>
            </Tooltip>
          ))}
        </Flex>
      </Flex>
    </DataListItem>
  );
}

FeeListItem.displayName = "FeeListItem";
