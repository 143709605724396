import { Fragment, useCallback } from "react";
import { useForm } from "react-hook-form";
import { City, OfflineHotelQuery, Traveler } from "~/application/types";
import { cityService } from "~/application/usecases";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Grid } from "~/components/Grid";
import { SvgCalendar, SvgCheck, SvgGps } from "~/components/Icon/icons";
import { Select, TextAreaInput, TextInput } from "~/components/Input";
import { MultiSelect } from "~/components/Input/MultiSelect";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H5 } from "~/components/Typography";
import { Card, CardSectionTitle } from "~/core/modules/DeprecatedBooking/components/Card";
import { SimpleListItem } from "~/core/modules/DeprecatedBooking/components/SimpleListItem";
import { Chip, ChipText, createControlledChipInput } from "~/core/shared/components/ChipInput";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import { FormControlContent } from "~/core/shared/components/FormControl";
import {
  Popover as DeprecatedPopover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { displayDate } from "~/utils/date.utils";
import { GuestsSelect } from "./components/GuestsSelect";
import { OfflineHotelQueryFormProps } from "./types";
import { useUser } from "~/presentation/core/contexts/UserContext";

const HotelInputChip = createControlledChipInput<City>();

const REGIMES = [{ name: "Com café da manhã" }, { name: "Sem café da manhã" }];

export const OfflineHotelQueryForm = ({
  amenities,
  isLoadingAmenities,
  defaultData,
  onSubmit,
  setQuantityRooms,
  fetchTravelersByName,
  onCreateCustomerEmployee,
  fetchIssuers,
  setIssuer,
  issuer,
  defaultTravelers,
}: OfflineHotelQueryFormProps) => {
  const {
    control,
    setValue,
    resetField,
    watch,
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm<OfflineHotelQuery>({
    defaultValues: defaultData as OfflineHotelQuery,
  });

  const { contexts } = useUser();
  const data = watch();

  const travelers = data.accommodations.reduce((acc, accommodation) => {
    if ((accommodation.travelers || []).filter((t) => t).length > 0) {
      return [...acc, ...accommodation.travelers.filter((t) => t)];
    }

    return acc;
  }, [] as Traveler[]);

  const travelersIds = travelers.map((t) => t.uuid);
  const guestsQuantity = data.accommodations.reduce((acc, accommodation) => {
    return acc + accommodation.adultQuantity + accommodation.guestsChildren.length;
  }, 0);

  const canSubmit = !!(
    dirtyFields.amenities &&
    dirtyFields.regime &&
    travelersIds.filter((t) => t).length === guestsQuantity
  );

  const onFormSubmit = (data: OfflineHotelQuery) => onSubmit?.(data);

  const handleDateClick = useCallback(
    (date: Date) => {
      const { checkInDate, checkOutDate } = data;

      if (!checkInDate || (checkInDate && checkOutDate)) {
        setValue("checkOutDate", null);
        setValue("checkInDate", date);
        return;
      }

      const [newStart, newEnd] = [checkInDate, checkOutDate, date].sort((a, b) => {
        if (a === null) {
          return 1;
        }

        if (b === null) {
          return -1;
        }

        if (a === b) {
          return 0;
        }

        return a < b ? -1 : 1;
      });

      setValue("checkInDate", newStart);
      setValue("checkOutDate", newEnd);
    },
    [data, setValue, resetField]
  );

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <FormDialog
        withCloseButton={false}
        withHeader={false}
        css={{ background: "none", border: "none" }}
      >
        <Flex direction="column">
          <Flex align="center" gap="2" css={{ p: "$6" }} direction={{ "@mxlg": "column" }}>
            <Col
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <FormControl control={control} name="city" required>
                <FieldLabel>Para qual lugar</FieldLabel>

                <Tooltip
                  open={!!errors.city}
                  variant="error"
                  content={<TooltipLabel>Obrigatório</TooltipLabel>}
                  side="bottom"
                >
                  <HotelInputChip
                    {...register("city", { required: true })}
                    placeholder="Digite uma cidade ou estado"
                    defaultValue={data.city}
                    leftIcon={SvgGps}
                    typingDelayMs={100}
                    minChars={3}
                    queryFunc={(text) => cityService.find({ name: text })}
                    chipRenderer={(value) => (
                      <Chip
                        css={{
                          backgroundColor: "$primary-light",
                        }}
                      >
                        <ChipText>{value.name}</ChipText>
                        <Text>{value.state}</Text>
                      </Chip>
                    )}
                    emptyText="Nenhuma cidade encontrada"
                    loadingText="Carregando cidades..."
                    itemsRenderer={({ items, setActiveItem }) => (
                      <Fragment>
                        <CardSectionTitle>Cidades</CardSectionTitle>
                        <Box>
                          {items.map((city) => (
                            <PopoverClose asChild key={city.uuid}>
                              <SimpleListItem
                                onClick={() => setActiveItem(city)}
                                title={city.name}
                                description={`${city.state} - ${city.country}`}
                                css={{ p: "$2 $4" }}
                              />
                            </PopoverClose>
                          ))}
                        </Box>
                      </Fragment>
                    )}
                  />
                </Tooltip>
              </FormControl>
            </Col>

            <DeprecatedPopover>
              <Col
                css={{
                  "@mxlg": {
                    width: "100%",
                  },
                }}
              >
                <FormControl control={control} name="checkInDate" required>
                  <FieldLabel>Check in</FieldLabel>

                  <PopoverTrigger asChild>
                    <Box>
                      <Tooltip
                        open={!!errors.checkInDate}
                        variant="error"
                        content={<TooltipLabel>Obrigatório</TooltipLabel>}
                        side="bottom"
                      >
                        <TextInput
                          {...register("checkInDate", { required: true })}
                          onBlur={(e) => e.preventDefault()}
                          placeholder="Data de entrada"
                          leftIcon={SvgCalendar}
                          value={(data.checkInDate && displayDate(data.checkInDate)) || ""}
                          style={{ width: "100%" }}
                          readOnly
                        />
                      </Tooltip>
                    </Box>
                  </PopoverTrigger>

                  <PopoverAnchor />
                </FormControl>
              </Col>
              <PopoverContent>
                <Card elevated>
                  <DoubleCalendar
                    date={data.checkInDate || new Date()}
                    activeMinDate={data.checkInDate as Date}
                    activeMaxDate={data.checkOutDate}
                    minDate={new Date()}
                    onDateClick={handleDateClick}
                  />
                </Card>
              </PopoverContent>
            </DeprecatedPopover>

            <DeprecatedPopover>
              <Col
                css={{
                  "@mxlg": {
                    width: "100%",
                  },
                }}
              >
                <FormControl control={control} name="checkOutDate" required>
                  <FieldLabel>Check out</FieldLabel>

                  <PopoverTrigger asChild>
                    <FormControlContent>
                      <Tooltip
                        open={!!errors.checkOutDate}
                        variant="error"
                        content={<TooltipLabel>Obrigatório</TooltipLabel>}
                        side="bottom"
                      >
                        <TextInput
                          {...register("checkOutDate", { required: true })}
                          onBlur={(e) => e.preventDefault()}
                          placeholder="Data de saída"
                          leftIcon={SvgCalendar}
                          value={(data.checkOutDate && displayDate(data.checkOutDate)) || ""}
                          style={{ width: "100%" }}
                          readOnly
                        />
                      </Tooltip>
                    </FormControlContent>
                  </PopoverTrigger>
                </FormControl>
              </Col>

              <PopoverContent>
                <Card elevated>
                  <DoubleCalendar
                    date={data.checkInDate || new Date()}
                    activeMinDate={data.checkInDate as Date}
                    activeMaxDate={data.checkOutDate}
                    minDate={new Date()}
                    onDateClick={handleDateClick}
                  />
                </Card>
              </PopoverContent>
            </DeprecatedPopover>
          </Flex>

          <Flex align="center" gap="6" css={{ p: "$6" }} direction={{ "@mxlg": "column" }}>
            <Col
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <FormControl control={control} name="amenities" required>
                <FieldLabel>Necessidades minímas de hospedagens</FieldLabel>
                <MultiSelect
                  placeholder="Selecione uma ou mais opções"
                  options={amenities}
                  isLoading={isLoadingAmenities}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.uuid}
                />
              </FormControl>
            </Col>

            <Col
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <FormControl control={control} name="regime" required>
                <FieldLabel>Regime da hospedagem</FieldLabel>
                <Select
                  placeholder="Selecione uma opção"
                  options={REGIMES}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                />
              </FormControl>
            </Col>
            <Col
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <FormControl control={control} name="accommodations" required>
                <FieldLabel>Quartos</FieldLabel>
                <GuestsSelect
                  data={data}
                  control={control}
                  register={register}
                  setQuantityRooms={setQuantityRooms}
                />
              </FormControl>
            </Col>
          </Flex>
          <Flex
            gap="2"
            justify="between"
            align="end"
            css={{
              p: "$6",
            }}
          >
            {contexts.agency && (
              <Col sz="4">
                <Flex direction="column" gap="3">
                  <Text css={{ fw: "700" }}>Solicitante</Text>

                  <AsyncSelect
                    onChange={(issuer) => setIssuer(issuer)}
                    defaultOptions={issuer}
                    placeholder="Digite o solicitante"
                    fetchOptions={fetchIssuers}
                    getOptionLabel={(c) => `${c.name} ${c.lastName || ""}`}
                    getOptionValue={(c) => c.uuid}
                  />
                </Flex>
              </Col>
            )}
            <Flex align={{ "@lg": "end", "@mxlg": "center" }} direction="column">
              <Button
                css={{
                  "@mxlg": {
                    width: "80%",
                  },
                }}
                onClick={onCreateCustomerEmployee}
                variant="secondary"
              >
                <Text>Novo funcionário</Text>
              </Button>
            </Flex>
          </Flex>
          <Grid columns="2" gap="6" css={{ p: "$6" }}>
            {(data.accommodations || []).map((a, i) => {
              return (
                <Flex css={{ width: "100%" }} direction="column" key={`accomodations-${i}`}>
                  <H5 css={{ mb: "$4" }}>Quarto {i + 1}</H5>
                  <Grid columns="2" gap="2">
                    {Array.from({
                      length: a.adultQuantity + a.guestsChildren.length,
                    }).map((_, j) => (
                      <Col key={`traveler-${i}`} sz="12" css={{ mt: "$4" }}>
                        <FormControl
                          control={control}
                          name={`accommodations.${i}.travelers.${j}`}
                          required
                        >
                          <FieldLabel>Hóspede {j + 1}</FieldLabel>
                          <AsyncSelect
                            placeholder="Digite o hóspede"
                            options={defaultTravelers}
                            fetchOptions={fetchTravelersByName}
                            getOptionLabel={(option) => {
                              return `${option.name} ${option.lastName}`;
                            }}
                            getOptionValue={(option) => option.uuid}
                          />
                        </FormControl>
                      </Col>
                    ))}
                  </Grid>
                </Flex>
              );
            })}
          </Grid>

          <Flex align="center" gap="6" css={{ p: "$6", pt: "0" }}>
            <Col css={{ mt: "$4" }}>
              <FormControl control={control} name="obs">
                <FieldLabel>Observações</FieldLabel>
                <TextAreaInput
                  css={{ minHeight: "109px" }}
                  placeholder={`Ex: "Gostaria de ficar na região central da cidade, próximo a prefeitura." ou "Gostaria de ficar no hotel Bistrol"`}
                />
              </FormControl>
            </Col>
          </Flex>

          <Col css={{ alignSelf: "end", mb: "$4", mr: "$4" }}>
            <Button type="submit" disabled={!canSubmit}>
              <SvgCheck />
              Solicitar
            </Button>
          </Col>
        </Flex>
      </FormDialog>
    </Form>
  );
};

OfflineHotelQueryForm.displayName = "OfflineHotelQueryForm";
