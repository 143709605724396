import { useReducer } from "react";
import type {
  OfflineHotelBookingDispatchAction,
  OfflineHotelBookingState,
  OfflineHotelReducer,
} from "../types";

function offlineHotelReducer(
  state: OfflineHotelBookingState,
  action: OfflineHotelBookingDispatchAction
): OfflineHotelBookingState {
  switch (action.type) {
    case "SET_HOTEL":
      return {
        ...state,
        hotelSelected: action.payload,
        roomsSelected: null,
      };
    case "SET_HOTEL_ROOMS":
      return { ...state, roomsSelected: action.payload };
    case "RESET_BOOKING":
      return {
        ...state,
        hotelSelected: null,
      };
    default:
      return state;
  }
}

const DEFAULT_BOOKING_HOTEL_STATE: OfflineHotelBookingState = {
  hotelSelected: null,
  roomsSelected: null,
};

export function useOfflineHotelReducer(): OfflineHotelReducer {
  const [bookingState, dispatch] = useReducer(
    offlineHotelReducer,
    DEFAULT_BOOKING_HOTEL_STATE
  );

  return { bookingState, dispatch };
}
