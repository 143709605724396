import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";
import { useCallback, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import type { HotelQuery } from "~/application/types";
import { hotelQueryService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryTimes } from "~/constants";
import { QueryKeys } from "~/constants/queryKeys";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { toISOString } from "~/utils/date.utils";
import { log } from "~/utils/log";
import { useBooking } from "../../contexts/BookingContext";
import { BookingHotelContext } from "./contexts/BookingHotelContext";
import { useHotelParams } from "./hooks/useHotelParams";
import { useHotelReducer } from "./hooks/useHotelReducer";
import { BrokenPolicyJustificationProvider } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";

const LOG_TAG = "Booking/BookingHotel/BookingHotelContainer";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao procurar hotéis",
} as const;

export function BookingHotelContainer() {
  const { contexts } = useUser();
  const { order, isLoadingOrder } = useBooking();
  const [quantityRooms, setQuantityRooms] = useState(1);

  const hotelQuery = useHotelParams();
  const hotelReducer = useHotelReducer();

  const navigate = useNavigate();

  const [_, rest] = window.location.href.split("&customerId=");
  const customerId = rest?.slice(0, rest?.indexOf("&"));

  const { data: hotelQueryLinks, isFetching: isLoadingLinks } = useQuery(
    [QueryKeys.QUERY_HOTEL, contexts.customer, hotelQuery],
    () => hotelQueryService.query(contexts.customer?.uuid ?? customerId, hotelQuery!),
    {
      retry: 3,
      staleTime: QueryTimes.LONGEST,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
      enabled: !isLoadingOrder && !!hotelQuery,
    }
  );

  const onSearchHotels = useCallback(
    (data: HotelQuery) => {
      const url = queryString.stringifyUrl({
        url: "/busca/hospedagens/resultados",
        query: {
          city: JSON.stringify(data.city),
          accommodations: JSON.stringify(data.accommodations),
          checkIn: toISOString(data.checkInDate as Date),
          checkOut: toISOString(data.checkOutDate as Date),
          orderId: order?.uuid,
          reasonTrip: data?.reasonTrip ? JSON.stringify(data.reasonTrip) : null,
        },
      });

      navigate(url);
    },
    [order, navigate]
  );

  return (
    <BrokenPolicyJustificationProvider>
      <BookingHotelContext.Provider
        value={{
          hotelQuery,
          isLoadingLinks,
          hotelQueryLinks,
          hotelReducer,
          onSearchHotels,
          quantityRooms,
          setQuantityRooms,
          order,
        }}
      >
        <Outlet />
      </BookingHotelContext.Provider>
    </BrokenPolicyJustificationProvider>
  );
}
