import { useQuery } from "@tanstack/react-query";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { ApiError, Customer, PaginatedResource } from "~/application/types";
import { customerService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { snackbarService } from "~/components/SnackbarStack";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { UseQueryCustomersOptions, UseQueryCustomersReturn } from "./types";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar empresas",
} as const;

export function useQueryCustomers({
  currentPage,
  search,
  options,
}: UseQueryCustomersOptions): UseQueryCustomersReturn {
  const { user, contexts } = useUser();
  const { LOG_TAG } = useLogTag();

  const {
    data,
    isLoading,
    isFetching,
    isError,
    refetch: onRefetch,
  } = useQuery<PaginatedResource<Customer[]>, ApiError>(
    [
      QueryKeys.CUSTOMERS,
      user?.agency ?? contexts?.agency,
      currentPage,
      { search },
    ],
    () => customerService.find({ page: currentPage, name: search }),
    {
      ...options,
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  return {
    data,
    isFetching,
    isLoading,
    isError,
    onRefetch,
  };
}
