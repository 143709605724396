import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type CardSectionHeaderProps = ComponentProps<typeof CardSectionHeader>;

export const CardSectionHeader = styled("div", {
  padding: "$4 $4 $2 $4",
  fontSize: "$xs",
  lineHeight: "100%",
  color: "$neutrals-black",
  background: "$neutrals-lightest",
  fontWeight: 700,
});
