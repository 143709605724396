import { AdvanceOrderStatus } from "~/application/types";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { orderAdvanceService } from "~/application/usecases";
import { log } from "~/utils/log";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ExpensesContainer } from "./ExpensesContainer";
import { useExpensesTab } from "./hooks/useExpensesTab";

const LOG_TAG = "Expense/ExpensesPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar despesas",
} as const;

export function ExpensesPage() {
  const { user } = useUser();
  const tabValue = useExpensesTab();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: response, isFetching } = useQuery(
    [user],
    () =>
      orderAdvanceService.findByApprover({
        statuses: [AdvanceOrderStatus.APPROVING, AdvanceOrderStatus.APPROVED],
      }),
    {
      staleTime: QueryTimes.SMALL,
      refetchOnMount: "always",
      refetchOnWindowFocus: true,
      onError: (error) => {
        log.e(LOG_TAG, error);
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  return (
    <ExpensesContainer
      isLoading={isFetching}
      currentPage={currentPage}
      lastPage={response?.meta?.last_page || 1}
      advances={response?.data ?? []}
      onGoToPage={setCurrentPage}
      activeTab={tabValue}
    />
  );
}
