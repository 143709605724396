import { useCallback, useEffect, useRef, useState } from "react";
import { UseCountdownOptions, UseCountdownReturn } from "./types";

export function getTime<T extends Date | null = Date>(
  date: T,
  targetDate = new Date(),
  stopAt = 0
): null | number {
  if (!date) return null;

  let countDown = date.getTime() - targetDate.getTime();

  countDown = Math.round(countDown / 1000) * 1000;

  return Math.max(stopAt, countDown);
}

export function useCountdown({
  targetDate,
  enabled = true,
}: UseCountdownOptions): UseCountdownReturn {
  const interval = useRef<NodeJS.Timer>();
  const [countDown, setCountDown] = useState(getTime(targetDate));

  const stopCountdown = useCallback(() => {
    clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (enabled) {
      interval.current = setInterval(
        () => setCountDown(getTime(targetDate)),
        1000
      );
    }

    return () => stopCountdown();
  }, [targetDate, enabled]);

  return { countDown, stopCountdown };
}
