import React, {
  ComponentProps,
  ComponentType,
  forwardRef,
  useContext
} from "react";
import { FormControlContext } from "../../FormControl/FormControlContext";
import { SwitchBase, SwitchBaseInputProps } from "../base/SwitchBase";
import { CheckboxButton, CheckboxButtonVariants } from "./CheckboxButton";

export type CheckboxHOCProps = ComponentProps<typeof SwitchBase>;

export const createCheckboxHOC = <
  P extends CheckboxHOCProps = CheckboxHOCProps
>(
  Wrapper: ComponentType<P>
) =>
  forwardRef<React.ElementRef<typeof Wrapper>, SwitchBaseInputProps & CheckboxButtonVariants>(
    (
      {
        // variants
        size,
        variant,
        // others props
        name: nameProp,
        disabled: disabledProp,
        onBlur: onBlurProp,
        onChange: onChangeProp,
        value: valueProp,
        ...props
      },
      forwardedRef
    ) => {
      const formControl = useContext(FormControlContext);

      const name = nameProp ?? formControl.name;
      const disabled = disabledProp ?? formControl.disabled;
      const onBlur = onBlurProp ?? formControl.onBlur;
      const onChange = onChangeProp ?? formControl.onChange;
      const value = valueProp ?? formControl.value;
      const isDirty = !!formControl.error;

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Wrapper
          type="checkbox"
          name={name}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          data-dirty={isDirty}
          checked={value}
          {...props}
          render={({ isChecked, isDisabled }) => (
            <CheckboxButton
              size={size}
              variant={variant}
              data-checked={isChecked}
              data-disabled={isDisabled}
            />
          )}
          ref={forwardedRef}
        />
      );
    }
  );
