import { useCallback, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

import { ApiError, OfflineHotel, PaginatedResource } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { offlineHotelService } from "~/application/usecases/OfflineHotel";
import { OfflineHotelsContainer } from "./OfflineHotelsContainer";
import { queryClient } from "~/services/queryClient";
import { logError } from "~/presentation/shared/utils/errors";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";

const LOG_TAG = "Agency/OfflineHotelsPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar hotéis offline",
  UPDATING_MESSAGE: "Atualizando hotel offline",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar hotel offline",
  ACTIVATE_SUCCESS_MESSAGE: "Hotel offline ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar hotel offline",
  INACTIVATE_SUCCESS_MESSAGE: "Hotel offline inativado",
} as const;

export function OfflineHotelsPage() {
  const { user } = useUser();
  const [descriptionFilter, setDescriptionFilter] = useState<string>("");
  const [cityFilter, setCityFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [description] = useDebounce<string>(descriptionFilter, 700);
  const [city] = useDebounce<string>(cityFilter, 700);

  const { data, isFetching } = useQuery<PaginatedResource<OfflineHotel[]>, ApiError>(
    [QueryKeys.OFFLINE_HOTELS, user.agency, currentPage, { description, city }],
    () => offlineHotelService.find({ agencyId: user.agency.uuid, page: currentPage, description, city }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (data: OfflineHotel) => offlineHotelService.toggleActive(data),
    {
      onMutate: (data) => {
        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message={`${data.isActive ? "Inativando" : "Ativando"} hotel offline`} />
        );
      },
      onSuccess: (_, data) => {
        queryClient.invalidateQueries([QueryKeys.OFFLINE_HOTELS]);

        snackbarService.showSnackbar(
          data.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error, data) => {
        const errorMessage = data.isActive
          ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
          : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE;

        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: errorMessage,
        });

        snackbarService.showSnackbar(errorMessage, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const handleToggleState = useCallback((data: OfflineHotel) => {
    if (!data.isActive) {
      mutateToggleState(data);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando hotel offline"
        onConfirm={() => mutateToggleState(data)}
      />
    );
  }, []);

  const handleChangeDescriptionFilter = useCallback((text: string) => {
    setDescriptionFilter(text);
    setCurrentPage(1);
  }, []);

  const handleChangeCityFilter = useCallback((text: string) => {
    setCityFilter(text);
    setCurrentPage(1);
  }, []);

  return (
    <OfflineHotelsContainer
      isLoading={isFetching}
      offlineHotels={data?.data}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      descriptionFilter={descriptionFilter}
      setDescriptionFilter={handleChangeDescriptionFilter}
      cityFilter={cityFilter}
      setCityFilter={handleChangeCityFilter}
      onGoToPage={setCurrentPage}
      onToggleState={handleToggleState}
    />
  );
}
