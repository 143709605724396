import { OrderAirway } from '~/application/types';
import { OrderAirwayDTO } from '../dtos';
import { mapOrderAirwaySegmentDTO } from './mapOrderAirwaySegmentDTO';
import { mapTravelerDTO } from './mapTravelerDTO';

export function mapOrderAirwayDTO(data: OrderAirwayDTO): OrderAirway {
  return {
    goFlight: mapOrderAirwaySegmentDTO(data.flight_details[0]),
    returnFlight: data.flight_details[1]
      ? mapOrderAirwaySegmentDTO(data.flight_details[1])
      : null,
    travelers: data.travelers.map(mapTravelerDTO),
  };
}
