import { CardsContainer } from "./CardsContainer";
import { useCallback, useState } from "react";
import { dialogService } from "~/components/DialogStack";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys, QueryTimes } from "~/constants";
import { snackbarService } from "~/components/SnackbarStack";
import { log } from "~/application/utils/log";
import { LOG_TAG } from "../AgenciesOrdersPage/types";
import { credentialService } from "~/application/usecases/Credentials";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { queryClient } from "~/services/queryClient";
import { SubmissionData } from "~/application/usecases/Credentials/ICredentialService";
import { logError } from "~/presentation/shared/utils/errors";
import { cardService } from "~/application/usecases/CreditCard";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { CardDialog } from "./components/CardDialog";
import { EditableCard } from "~/application/usecases/CreditCard";
import { cardFlagService } from "~/application/usecases/CardFlag";
import { Card } from "~/application/types";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";

const DEFAULT_FORM_DATA = {
  description: "",
  number: "",
  owner: "",
  validity: "",
} as EditableCard;

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Erro ao buscar cartões de crédito",
  UPDATE_SUCCESS_MESSAGE: "Cartão de crédito atualizada com sucesso.",
  UPDATE_ERROR_MESSAGE: "Erro ao atualizar cartão de crédito",
  CREATE_SUCCESS_MESSAGE: "Cartão de crédito cadastrada com sucesso",
  CREATE_ERROR_MESSAGE: "Erro ao criar cartão de crédito",
  ACTIVE_SUCCESS_MESSAGE: "Cartão de crédito ativado",
  ACTIVE_ERROR_MESSAGE: "Falha ao ativar cartão de crédito",
  INACTIVE_ERROR_MESSAGE: "Falha ao inativar cartão de crédito",
  INACTIVE_SUCCESS_MESSAGE: "Cartão de crédito inativado",
};

export function CardsPage() {
  const { user } = useAuth();
  const agencyUuid = user.agency.uuid;

  const { mutate: handleCreateCard } = useMutation(
    (data: EditableCard) => cardService.create({ ...data, agencyUuid }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando cartão" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CARDS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: handleActiveCard } = useMutation(
    (uuid: string) => cardService.activate(uuid),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Ativando cartão" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CARDS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.ACTIVE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.ACTIVE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: handleInactiveCard } = useMutation(
    (uuid: string) => cardService.inactivate(uuid),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Inativando cartão" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CARDS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.ACTIVE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.ACTIVE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: handleUpdateCard } = useMutation(
    (data: EditableCard) => cardService.update(data),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message="Atualizando cartão" />
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CARDS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { data: cards, isLoading: isLoadingCards } = useQuery(
    [QueryKeys.CARDS, agencyUuid],
    () => cardService.find(agencyUuid),
    {
      staleTime: QueryTimes.LONG,
      refetchOnWindowFocus: true,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: cardFlags } = useQuery(
    [QueryKeys.CARD_FLAGS],
    () => cardFlagService.find(),
    {
      staleTime: QueryTimes.LONG,
      refetchOnWindowFocus: true,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const onOpenCreateDialog = useCallback(() => {
    dialogService.showDialog(
      <CardDialog
        onCloseClick={() => dialogService.popDialog()}
        defaultData={DEFAULT_FORM_DATA}
        onSubmit={handleCreateCard}
        cardFlags={cardFlags}
      />
    );
  }, [handleCreateCard, cardFlags]);

  const onOpenEditDialog = useCallback(
    (item: EditableCard) => {
      dialogService.showDialog(
        <CardDialog
          cardFlags={cardFlags}
          onCloseClick={() => dialogService.popDialog()}
          defaultData={item}
          onSubmit={handleUpdateCard}
        />
      );
    },
    [handleUpdateCard, cardFlags]
  );

  const handleToggleState = useCallback(
    (data: Card) => {
      if (data.active) {
        dialogService.showDialog(
          <InactivateDialog
            loadingMessage="Inativando filial"
            onConfirm={() => handleInactiveCard(data.uuid)}
          />
        );

        return;
      }

      handleActiveCard(data.uuid);
    },
    [handleActiveCard, handleInactiveCard]
  );

  return (
    <CardsContainer
      cards={cards}
      isLoading={isLoadingCards}
      onOpenCreateDialog={onOpenCreateDialog}
      onActive={handleToggleState}
      onInactive={handleToggleState}
      onOpenEditDialog={onOpenEditDialog}
    />
  );
}
