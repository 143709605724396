import { styled } from "~/application/theme";

const Container = styled("div", {
  display: "flex",
  gap: "$2",
  borderWidth: "4px",
  borderStyle: "solid",
  borderColor: "$primary-base",
  overflow: "hidden",
  borderRadius: "80px 8px 8px 80px",
  paddingLeft: "$3",
  backgroundColor: "$neutrals-white",
  width: "max-content",
});

const SeatsContainer = styled("div", {
  display: "grid",
  padding: "$4",
  gap: "$3",
});

export const Styled = {
  Container,
  SeatsContainer,
} as const;
