import { FC } from "react";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { getServiceLabel } from "~/core/shared/utils/order.utils";
import { JustificationListItemProps } from "./types";

export const JustificationListItem: FC<JustificationListItemProps> = ({
  data,
  onEditClick,
  onToggleState,
  ...props
}) => {
  return (
    <DataListItem data-active={data.isActive} {...props}>
      <DataColItem headerTitle="Justificativa" data={data.name} />

      <DataColItem
        headerTitle="Tipo"
        data={getServiceLabel(data.serviceType)}
      />

      {onEditClick && (
        <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditClick(data)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
      )}

      {onToggleState && (
        <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
          <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
        </Switch>
      )}
    </DataListItem>
  );
};

JustificationListItem.displayName = "JustificationListItem";
