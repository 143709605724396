import { DeepPartial, Vehicle } from "~/application/types";

export type useVehicleBudgetResult = {
  activeBudget: boolean;
  onActiveBudget: (active: boolean) => void;
  state: VehicleBudgetState;
  dispatch?: React.Dispatch<VehicleBudgetAction>;
  onSelectVehicleBudget: ({ data }: { data: Vehicle }) => void;
};

export type VehicleBudgetState = DeepPartial<Vehicle>[];

export enum VehicleBudgetActionType {
  ADD = "ADDING",
  CLEAR = "CLEAR",
}

export type VehicleBudgetAction =
  | {
      type: VehicleBudgetActionType.ADD;
      paylod: VehicleBudgetState;
    }
  | {
      type: VehicleBudgetActionType.CLEAR;
    };
