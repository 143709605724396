import queryString from "query-string";
import type { Policy } from "~/application/types";
import { api } from "~/infrastructure/api";
import { PolicyDTO } from "~/infrastructure/api/dtos";
import type { FormPolicyData, IPolicyService } from "./IPolicyService";
import { mapPolicyDTO } from "~/infrastructure/api/mappers";

export class PolicyService implements IPolicyService {
  updateById(data: Policy): Promise<Policy> {
    throw new Error("Method not implemented.");
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async findById(id: string): Promise<Policy> {
    throw new Error("Method not implemented.");
  }

  async find({ customerId }: { customerId: string }): Promise<Policy[]> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/policies`,
      query: {},
    });

    return await api
      .get<PolicyDTO[]>(url)
      .then(({ data }) => data.map(mapPolicyDTO));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async create(data: Omit<Policy, "uuid">): Promise<Policy> {
    throw new Error("Method not implemented.");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async update(data: FormPolicyData): Promise<void> {
    const formData = {
      value: data.value,
    };
    await api.put(`/customers/policy-values/${data.policyValueId}`, formData);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async toggleActive(data: Policy): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
