export enum AppErrorCode {
  BAD_AIRWAY_QUERY = "BAD_AIRWAY_QUERY",
  BAD_HOTEL_QUERY = "BAD_HOTEL_QUERY",
  BAD_ROAD_QUERY = "BAD_ROAD_QUERY",
  BAD_VEHICLE_QUERY = "BAD_VEHICLE_QUERY",
  VEHICLE_QUERY_NOT_FOUND = "VEHICLE_QUERY_NOT_FOUND",
  ROAD_QUERY_NOT_FOUND = "ROAD_NOT_FOUND",
  HOTEL_NOT_FOUND = "HOTEL_NOT_FOUND",
}

export class AppError {
  public readonly message: string;
  public readonly code: AppErrorCode;

  constructor(data: AppError) {
    this.message = data.message;
    this.code = data.code;
  }

  static badAirwayQuery() {
    return new AppError({
      code: AppErrorCode.BAD_AIRWAY_QUERY,
      message: "Ocorreu um erro ao processar sua pesquisa de passagens aéreas.",
    });
  }

  static badHotelQuery() {
    return new AppError({
      code: AppErrorCode.BAD_AIRWAY_QUERY,
      message: "Ocorreu um erro ao processar sua pesquisa de hospedagens.",
    });
  }

  static badRoadQuery() {
    return new AppError({
      code: AppErrorCode.BAD_ROAD_QUERY,
      message:
        "Ocorreu um erro ao processar sua pesquisa de passagens de ônibus.",
    });
  }

  static badVehicleQuery() {
    return new AppError({
      code: AppErrorCode.BAD_VEHICLE_QUERY,
      message: "Ocorreu um erro ao processar sua pesquisa de carros.",
    });
  }

  static vehicleQueryNotFound() {
    return new AppError({
      code: AppErrorCode.VEHICLE_QUERY_NOT_FOUND,
      message:
        "Você precisa buscar por carros antes de visualizar essa página.",
    });
  }

  static roadQueryNotFound() {
    return new AppError({
      code: AppErrorCode.ROAD_QUERY_NOT_FOUND,
      message:
        "Você precisa buscar por ônibus antes de visualizar essa página.",
    });
  }

  static hotelNotFound() {
    return new AppError({
      code: AppErrorCode.HOTEL_NOT_FOUND,
      message:
        "Você precisa selecionar um hotel antes de visualizar essa página.",
    });
  }

  static hotelQueryNotFound() {
    return new AppError({
      code: AppErrorCode.BAD_HOTEL_QUERY,
      message:
        "Você precisa selecionar um hotel antes de visualizar essa página.",
    });
  }
}
