import * as React from "react";
import { SVGProps } from "react";

const SvgAirplaneTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.888 11.535 8.804-2.331a.864.864 0 0 0 .548-.415.848.848 0 0 0 .102-.67.752.752 0 0 0-.408-.505.921.921 0 0 0-.665-.068l-2.488.67-4-3.75-1.227.292 2.4 4.2-2.573.638-1.25-.95-.777.212 1.534 2.677ZM17.692 15.5H2.308c-.499 0-.925-.177-1.278-.53a1.741 1.741 0 0 1-.53-1.278v-3.288a2.423 2.423 0 0 0 1.427-.838C2.309 9.117 2.5 8.595 2.5 8c0-.595-.191-1.117-.573-1.566A2.423 2.423 0 0 0 .5 5.596V2.308c0-.499.177-.925.53-1.278C1.383.677 1.809.5 2.308.5h15.384c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v11.384c0 .499-.177.925-.53 1.278-.353.353-.779.53-1.278.53Zm0-1.5a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.22V2.307a.3.3 0 0 0-.087-.221.3.3 0 0 0-.22-.087H2.307a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.22V4.55a4.1 4.1 0 0 1 1.462 1.462C3.821 6.621 4 7.283 4 8c0 .717-.18 1.38-.538 1.987A4.1 4.1 0 0 1 2 11.45v2.242a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087h15.385Z"
      fill="#475A6B"
    />
  </svg>
);

export default SvgAirplaneTicket;
