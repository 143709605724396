import { useMutation } from "@tanstack/react-query";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { set } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeepPartial, RemakeSearchData, Traveler } from "~/application/types";
import { orderService } from "~/application/usecases";
import { RemakeSearchDataDTO, TravelerDTO } from "~/infrastructure/api/dtos";
import { mapRemakeSearchDataDTO, mapTravelerDTO } from "~/infrastructure/api/mappers";
import { createAirwayQueryUrl } from "~/presentation/Booking/BookingAirway/utils";
import { createRoadQueryUrl } from "~/presentation/Booking/BookingRoad/utils";
import { RoadQueryFormData } from "~/presentation/shared/components/RoadQueryForm";
import { LOG_TAG } from "~/presentation/shared/components/TabOrderMessages/types";
import { log } from "~/utils/log";

export type RemakeSearchContextData = {
  data: RemakeSearchData;
  currentStep: number;
  orderId: string;
  travelers: Traveler[];
  oldOrderId: string;
  finishStep: {
    road: boolean;
    airway: boolean;
  };
  setData: (data: RemakeSearchData) => void;
  setCurrentStep: (currentStep: number) => void;
  setOrderId: (orderId: string) => void;
  setState: (state: DeepPartial<RemakeSearchContextData>) => void;
  setTravelers: (travelers: Traveler[]) => void;
  setOldOrderId: (oldOrderId: string) => void;
  setFinishStep: (finishStep: { road: boolean; airway: boolean }) => void;
};

export type DataInEmailSearch = RemakeSearchDataDTO & {
  travelers: TravelerDTO[];
  order_uuid: string;
};

export const remakeSearchContext = createContext<RemakeSearchContextData>(
  {} as RemakeSearchContextData
);

export const useRemakeSearch = () => {
  const context = useContext(remakeSearchContext);
  if (!context) {
    throw new Error("useRemakeSearch must be used within an RemakeSearch");
  }
  return context;
};

export const RemakeSearchProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<RemakeSearchContextData>({
    data: {} as RemakeSearchData,
    travelers: [],
    currentStep: 0,
    orderId: "",
    oldOrderId: "",
    finishStep: {
      road: false,
      airway: false,
    },
    setOldOrderId: (oldOrderId: string) => {
      setState((prevState) => ({ ...prevState, oldOrderId }));
    },
    setData: (data: RemakeSearchData) => {
      setState((prevState) => ({ ...prevState, data }));
    },
    setState: (state: DeepPartial<RemakeSearchContextData>) => {
      setState((prevState) => ({
        ...prevState,
        ...(state as RemakeSearchContextData),
      }));
    },
    setCurrentStep: (currentStep: number) => {
      setState((prevState) => ({ ...prevState, currentStep }));
    },
    setOrderId: (orderId: string) => {
      setState((prevState) => ({ ...prevState, orderId }));
    },
    setTravelers: (travelers: Traveler[]) => {
      setState((prevState) => ({ ...prevState, travelers }));
    },
    setFinishStep: (finishStep: { road: boolean; airway: boolean }) => {
      setState((prevState) => ({ ...prevState, finishStep: finishStep }));
    },
  });

  const navigate = useNavigate();
  const isValidUrl = window.location.href.includes("resultados");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");

  const createRoadUrl = useCallback(() => {
    const { data, currentStep, orderId } = state;

    if (!data || !data.road || currentStep === data.road.length) return;
    const currentRoadData = data.road[currentStep];
    if (!currentRoadData) return;
    const roadQueryData = {
      stretches: [
        {
          dateGoSelected: currentRoadData.departureDate,
          dateReturnSelected: null,
          destinationSelected: {
            city: currentRoadData.destination,
            stops: [] as any[],
          },
          originSelected: { city: currentRoadData.origin, stops: [] as any[] },
        },
      ],
    } as RoadQueryFormData;
    return createRoadQueryUrl(roadQueryData, orderId);
  }, [state, navigate]);

  const createAirwayUrl = useCallback(() => {
    const { data, currentStep, orderId } = state;
    if (!data || !data.airway || currentStep === data.airway.length) return;
    const currentAirwayData = data.airway[currentStep];
    if (!currentAirwayData) return;

    const airwayQueryData = {
      adultQuantity: currentAirwayData.travelers.length,
      childrenQuantity: 0,
      destination: currentAirwayData.destination,
      origin: currentAirwayData.origin,
      outboundDate: currentAirwayData.departureDate,
      returnDate: currentAirwayData.arrivalDate,
    };

    return createAirwayQueryUrl(airwayQueryData, orderId);
  }, [state, navigate]);

  useEffect(() => {
    const { data, finishStep } = state;
    const urlRoad = createRoadUrl();
    const urlAirway = createAirwayUrl();

    if (urlRoad && !isValidUrl && !finishStep.road && data.road.length) {
      navigate(urlRoad, { replace: true });
      return;
    }

    if (urlAirway && !isValidUrl && !finishStep.airway && data.airway.length) {
      navigate(urlAirway, { replace: true });
      return;
    }
  }, [state, isValidUrl]);

  useEffect(() => {
    if (search) {
      const data = JSON.parse(search) as DataInEmailSearch;
      const oldOrderId = data.order_uuid;

      const travelers = data.travelers.map((traveler) => mapTravelerDTO(traveler));
      const remakeSearchData = mapRemakeSearchDataDTO(data);
      setState((prevState) => ({
        ...prevState,
        data: remakeSearchData,
        travelers,
        oldOrderId,
        orderId: oldOrderId,
      }));
    }
  }, [search]);

  return <remakeSearchContext.Provider value={state}>{children}</remakeSearchContext.Provider>;
};
