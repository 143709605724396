import { ComponentProps } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { styled } from "~/application/theme";
import { Text } from "../Text";

export type NavLinkProps = ComponentProps<typeof NavLink>;

export const NavLink = styled(RouterNavLink, {
  // Reset
  boxSizing: "border-box",

  // Custom
  alignItems: "center",
  flexShrink: 0,
  outline: "none",
  textDecorationLine: "none",
  lineHeight: "inherit",

  [`& ${Text}`]: {
    color: "inherit",
  },

  "&[data-disabled=true]": {
    pointerEvents: "none",
  },
});
