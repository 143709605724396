import { Justification } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { JustificationListItem } from "./components/JustificationListItem";

export interface TabJustificationsProps {
  data?: Justification[];
  isLoading: boolean;
  onCreateJustification: () => void;
  onEditJustification: (item: Justification) => void;
  onToggleJustificationState: (item: Justification) => void;
}

export function TabJustifications({
  data,
  onCreateJustification,
  onEditJustification,
  onToggleJustificationState,
}: TabJustificationsProps) {
  const listRenderer = useCallback(
    (item: Justification) => (
      <JustificationListItem
        data={item}
        onEditClick={onEditJustification}
        onToggleState={onToggleJustificationState}
        key={item.uuid}
      />
    ),
    [onEditJustification, onToggleJustificationState]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Justificativas de quebra de política</H4>
          </Col>

          <Col sz={{ "@lg": "auto" }}>
            <Button
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
              title="Nova Justificativa"
              color="primary"
              onClick={onCreateJustification}
            >
              <Icon as={SvgPlus} />
              <Text>Nova Justificativa</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Esta empresa ainda não possui justificativas de viagem</Text>
            </EmptyState>
          }
        />
      </Box>
    </Fragment>
  );
}
