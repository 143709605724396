import { useCallback, useState } from "react";
import {
  AccountabilityExpense,
  AdvanceOrderStatus,
  Order,
} from "~/application/types";
import { Avatar } from "~/components/Avatar";
import { Container } from "~/components/Container";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronRight } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { DataColItem, DataListItem } from "~/components/List";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H6 } from "~/components/Typography";
import { asCurrency } from "~/utils/mask.utils";
import { advanceOrderStatus } from "../../../../utils";
import { AccountabilityExpenseListItem } from "../AccountabilityExpenseListItem";

export type IssuerExpenseListItemProps = {
  order?: Order;
  expenses?: AccountabilityExpense[];
  isLoading: boolean;
  onEditClick: (data: AccountabilityExpense) => void;
  onDeleteClick: (data: AccountabilityExpense) => void;
  onShowVoucher: (data: AccountabilityExpense) => void;
};

interface IssuerExpenseListItemState {
  showExpensesOfTraveler: boolean;
  expensesOfTraveler?: AccountabilityExpense[];
}

export function IssuerExpenseListItem({
  order,
  expenses,
  isLoading,
  onEditClick,
  onDeleteClick,
  onShowVoucher,
}: IssuerExpenseListItemProps) {
  const [state, setState] = useState<IssuerExpenseListItemState>({
    showExpensesOfTraveler: false,
  });

  const { expensesOfTraveler, showExpensesOfTraveler } = state;

  const listRenderer = useCallback(
    (item: AccountabilityExpense) => (
      <AccountabilityExpenseListItem
        data={item}
        onShowVoucher={onShowVoucher}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        key={item.uuid}
      />
    ),
    [onShowVoucher, onEditClick, onDeleteClick]
  );

  const handleShowExpensesOfTraveler = useCallback(
    (travelerId: string) => {
      const expensesOfTraveler = (expenses || []).filter(
        ({ advanceOrder }) => advanceOrder?.traveler?.uuid === travelerId
      );

      setState({ ...state, expensesOfTraveler, showExpensesOfTraveler: true });
    },
    [expenses]
  );

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="text-4" />
          <Skeleton variant="text-4" />
          <Skeleton variant="text-4" />
        </>
      ) : (
        <>
          {showExpensesOfTraveler && (
            <Container css={{ pt: "$10", pb: "$20", background: "red" }}>
              <LazyList
                gap="6"
                items={expensesOfTraveler}
                render={listRenderer}
                skeletonQuantity={4}
                skeletonHeight={370}
                EmptyComponent={
                  <EmptyState>
                    <Text>
                      Você ainda não possui despesas criadas para este pedido
                    </Text>
                  </EmptyState>
                }
              />
            </Container>
          )}

          {!showExpensesOfTraveler &&
            order?.travelers.map(({ uuid, fullName }) => {
              const travelerExpenses = (expenses || []).filter(
                ({ advanceOrder }) => advanceOrder?.traveler?.uuid === uuid
              );

              const travelerExpense = travelerExpenses.at(0);
              const advanceOrder = travelerExpense?.advanceOrder;
              const advancesTotal = advanceOrder?.value || 0;

              const expensesTotal = travelerExpenses?.reduce(
                (total, { value }) => total + value,
                0
              );

              const getAdvanceOrderStatusJsx = () => {
                if (!advanceOrder?.status) {
                  return <Text>Em Aberto</Text>;
                }

                const canShowCurrentApprover =
                  advanceOrder.status === AdvanceOrderStatus.DISAPPROVED &&
                  advanceOrder.currentApprover;

                if (
                  advanceOrder.status === AdvanceOrderStatus.APPROVING ||
                  canShowCurrentApprover
                ) {
                  return (
                    <Tooltip
                      content={
                        <TooltipLabel>
                          Aprovador: {advanceOrder.currentApprover}
                        </TooltipLabel>
                      }
                    >
                      <Text>
                        {advanceOrder.status &&
                          advanceOrderStatus[advanceOrder.status]}
                      </Text>
                    </Tooltip>
                  );
                }

                return <Text>{advanceOrderStatus[advanceOrder.status]}</Text>;
              };

              return (
                <Link to={`viajantes/${uuid}/despesas`}>
                  <DataListItem
                    css={{
                      p: "$6",
                      width: "95%",
                      margin: "0 auto",
                      mb: "$8",
                      "@mxlg": {
                        display: "flex",
                        flexDirection: "column",
                      },
                    }}
                    key={uuid}
                  >
                    <DataColItem
                      headerTitle="Funcionário"
                      data={
                        <Flex align="center">
                          <Avatar css={{ mr: "$2" }} />
                          <H6>{fullName}</H6>
                        </Flex>
                      }
                    />

                    <DataColItem
                      headerTitle="Status"
                      data={getAdvanceOrderStatusJsx()}
                    />

                    <DataColItem
                      headerTitle="Adiantamentos"
                      data={<Text>{asCurrency(advancesTotal ?? 0)}</Text>}
                    />

                    <DataColItem
                      headerTitle="Despesas"
                      data={<Text>{asCurrency(expensesTotal ?? 0)}</Text>}
                    />

                    <Col
                      sz="auto"
                      css={{
                        "@mxlg": {
                          display: "none",
                        },
                      }}
                    >
                      <Flex justify="end">
                        <Tooltip
                          content={
                            <TooltipLabel>Ver despesa do viajante</TooltipLabel>
                          }
                        >
                          <Link to={`viajantes/${uuid}/despesas`}>
                            <IconButton
                              size="md"
                              onClick={() => handleShowExpensesOfTraveler(uuid)}
                            >
                              <Icon as={SvgChevronRight} />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </Flex>
                    </Col>
                  </DataListItem>
                </Link>
              );
            })}
        </>
      )}
    </>
  );
}

IssuerExpenseListItem.displayName = "IssuerExpenseListItem";
