import { Navigate } from "react-router-dom";
import { useAuth } from "../../presentation/core/contexts/AuthContext";
import { AgencyEmployeeType, UserContext } from "~/application/types";

export function Redirect() {
  const { isSigned, user } = useAuth();

  if (!isSigned) {
    return <Navigate to="/entrar" />;
  }

  const isManager = user.type === AgencyEmployeeType.MANAGER;
  const agencyTo = isManager ? "/configuracoes/empresas" : "/pedidos/pedidos-pendentes";

  switch (user.context) {
    case UserContext.Biztrip:
      return <Navigate replace to="/configuracoes/agencias" />;
    case UserContext.Agency:
      return <Navigate replace to={agencyTo} />;
    case UserContext.Customer:
      return <Navigate replace to="/busca" />;
    default:
      throw new Error("User context not supported");
  }
}
