import { BaseSyntheticEvent, Fragment } from "react";
import { Control } from "react-hook-form";
import QRCode from "react-qr-code";

import { BiztripLogo } from "~/components/BiztripLogo";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Icon } from "~/components/Icon";
import { SvgAirplane, SvgBus, SvgCar, SvgHotels, SvgMail } from "~/components/Icon/icons";
import { Checkbox, PasswordInput, TextInput } from "~/components/Input";
import { Link } from "~/components/Link";
import { Text } from "~/components/Text";
import { Label } from "~/components/Typography";
import { useAuth } from "~/presentation/core/contexts/AuthContext";

export type LoginFormData = {
  email: string;
  password: string;
  keepSigned: boolean;
  securityKey: string;
};

export interface LoginContainerProps {
  control: Control<LoginFormData, any>;
  isSubmitting: boolean;
  onFormSubmit: (event: BaseSyntheticEvent) => Promise<void>;
}

export function LoginContainer({ control, isSubmitting, onFormSubmit }: LoginContainerProps) {
  const { qrCodeUrl } = useAuth();
  const serviceIcon = [SvgAirplane, SvgCar, SvgBus, SvgHotels];

  return (
    <Fragment>
      <Box style={{ height: "100vh", padding: 0 }}>
        <Box
          mode="show"
          css={{
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "450px",
            mb: "$20",
            zIndex: "-1",
          }}
        >
          <Flex direction="column" align="center">
            <Flex
              align="center"
              direction="column"
              css={{
                margin: "$20 auto",
                height: "$80",
                width: "700px",
                ta: "center",
                "@mxlg": {
                  width: "80%",
                },
              }}
            >
              <Text
                as="h1"
                size={{ "@initial": "8", "@mxlg": "6" }}
                variant="white"
                css={{ mb: "$5", ta: "center" }}
              >
                Facilite sua gestão de viagens com nossa plataforma inovadora
              </Text>
              <Text variant="white" css={{ ta: "center" }} size="3">
                Esqueça as dificuldades das agências tradicionais
              </Text>

              <Flex gap="3" css={{ mt: "$5" }}>
                {serviceIcon.map((service, i) => (
                  <Flex
                    key={`service-${i}`}
                    align="center"
                    justify="center"
                    css={{
                      borderRadius: "100%",
                      border: "1px solid #FFF",
                      background: "#FFF",
                      width: "50px",
                      height: "50px",
                    }}
                  >
                    <Icon fill="#0064C5" as={service} />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Box>

        <Container css={{ p: "$5" }}>
          <Container
            size={{ "@initial": "5", "@mxmd": "7" }}
            css={{
              m: 0,
              margin: "0 auto",
              background: "#FFF",
              position: "relative",
              top: "270px",
              px: "$10",
              py: "$6",
              borderRadius: "$md",
              boxShadow: "$md",
              width: "100%",
            }}
          >
            <Flex justify="center" direction="column">
              <BiztripLogo 
                size={{ "@initial": "md", "@mxmd": "lg" }} 
                css={{ mb: "$3", mx: (qrCodeUrl || qrCodeUrl === null) ? "auto" : 0 }} 
              />

              <Text
                id="login-text-header"
                size="4"
                as="h1"
                css={{
                  fw: "700",
                  mb: "$3",
                  mx: (qrCodeUrl || qrCodeUrl === null) ? "auto" : 0,
                  "@media (max-width: 768px)": {
                    textAlign: "center",
                  },
                }}
              >
                Acessar a plataforma
              </Text>

              <form onSubmit={onFormSubmit}>
                <Flex direction="column">
                  {qrCodeUrl && (
                    <>
                      <Box css={{ mb: "$6", mx: "auto" }}>
                        <QRCode
                          size={256}
                          value={qrCodeUrl}
                        />
                      </Box>

                      <Box css={{ mb: "$6", mx: "auto" }}>
                        <Label size="3"css={{ textAlign: "center" }}>
                          Para continuar, escaneie o QR code com o aplicativo Google Authenticator e insira o código de segurança gerado
                        </Label>
                      </Box>
                    </>
                  )}

                  {qrCodeUrl !== undefined && (
                    <Box css={{ mb: "$2" }}>
                      <FormControl name="securityKey" control={control} required>
                        <FieldLabel>Código de segurança</FieldLabel>
                        <TextInput id="login-secret-key" />
                      </FormControl>
                    </Box>
                  )}

                  {qrCodeUrl === undefined && (
                    <>
                      <Box css={{ mb: "$6" }}>
                        <FormControl name="email" control={control}>
                          <FieldLabel>E-mail</FieldLabel>
                          <TextInput id="login-email" leftIcon={SvgMail} />
                        </FormControl>
                      </Box>

                      <Box css={{ mb: "$4" }}>
                        <FormControl name="password" control={control}>
                          <FieldLabel>Senha</FieldLabel>
                          <PasswordInput id="login-password" autoComplete="password" />
                        </FormControl>
                      </Box>
                    </>
                  )}

                  {qrCodeUrl === null && (
                    <Box css={{ mb: "$6", mx: "auto" }}>
                      <Label size="3"css={{ textAlign: "center" }}>
                        Insira o código de segurança gerado pelo aplicativo Google Authenticator para continuar
                      </Label>
                    </Box>
                  )}

                  <Flex direction="column" align={{ "@mxmd": "center" }}>
                    {qrCodeUrl === undefined && (
                      <Flex direction={{ "@mxmd": "column" }} align="center" justify="between">
                        <Box
                          css={{ mb: "$4" }}
                        >
                          <FormControl control={control} name="keepSigned">
                            <Checkbox>
                              <Text size="3">Mantenha-me conectado</Text>
                            </Checkbox>
                          </FormControl>
                        </Box>

                        <Box
                          css={{
                            mb: "$4",
                            "@media (max-width: 768px)": {
                              mb: "$8",
                            },
                          }}
                        >
                          <Link to="/redefinir-senha">
                            <Text size="3" css={{ color: "$primary-base", textDecoration: "underline" }}>Esqueci minha senha</Text>
                          </Link>
                        </Box>
                      </Flex>
                    )}

                    <Button
                      mode={{ "@mxmd": "align" }}
                      id="login-submit-button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Entrar
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </Flex>
          </Container>
        </Container>
      </Box>
    </Fragment>
  );
}
