import type { AirwayEmission, DeepPartial, OrderAirwayItemType } from "~/application/types";
import { AirwayIssuanceFormData } from "./types";

export function createFormData(
  orderAirway: OrderAirwayItemType
): DeepPartial<AirwayIssuanceFormData> {
  return {
    issuances: orderAirway.flights.map((i) => ({
      uuid: i.uuid,
      tracker: i.tracker,
      value: i.value,
      changeTax: i.changeTax || 0,
      travelers: i.passengers.map((p) => ({
        uuid: p.uuid,
        ticket: p.ticket,
      })),
    })),
  };
}

export function parseFormData(formData: AirwayIssuanceFormData): AirwayEmission[] {
  return formData.issuances.map((i) => ({
    value: i.value,
    changeTax: i.changeTax,
    tracker: i.tracker,
    uuid: i.uuid,
    travelers: i.travelers,
  }));
}
