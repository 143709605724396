import React, { memo } from "react";
import { Popover } from "./Popover";

type PopoverComponent<P> = P & {
  popoverOpen?: boolean;
  onClickOutside?: () => void;
  popoverContent: JSX.Element;
};

export function createPopover<P>(Component: React.ComponentType<P>) {
  return memo(
    ({ popoverOpen = false, popoverContent, onClickOutside, ...props }: PopoverComponent<P>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const anchorElement = <Component {...props} />;

      return (
        <Popover
          popoverOpen={popoverOpen}
          popoverAnchor={anchorElement}
          popoverContent={popoverContent}
          onClickOutside={onClickOutside}
        />
      );
    }
  );
}
