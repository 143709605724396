import { Fragment, useCallback } from "react";
import { CustomerSettingParameter } from "~/application/types";
import { Box } from "~/components/Box";
import { EmptyState } from "~/components/EmptyState";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { CustomerSettingParameterListItem } from "./components/CustomerSettingParameterListItem";
import { Card } from "~/components/Card";
import { Col, Row } from "~/components/Grid";
import { H4 } from "~/components/Typography";

export type TabCustomerSettingParameterProps = {
  data?: CustomerSettingParameter[];
  isUpdating: boolean;
  onEditClick: (item: CustomerSettingParameter) => void;
};

export function TabCustomerSettingParameters({
  data,
  onEditClick,
  isUpdating,
}: TabCustomerSettingParameterProps) {
  const listRenderer = useCallback(
    (item: CustomerSettingParameter) => (
      <CustomerSettingParameterListItem
        data={item}
        onEditClick={onEditClick}
        isUpdating={isUpdating}
      />
    ),
    [onEditClick, isUpdating]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Parâmetros de configuração</H4>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui parâmetros de configuração cadastrados</Text>
            </EmptyState>
          }
        />
      </Box>
    </Fragment>
  );
}
