import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { SwitchBase } from "../base/SwitchBase";
import { CheckboxButton } from "./CheckboxButton";
import { createCheckboxHOC } from "./createCheckboxHOC";

export type CheckboxProps = ComponentProps<typeof Checkbox>;

const CheckboxRoot = styled(SwitchBase, {
  // Custom
  width: 'fit-content',
  zIndex: 0,
  [`& > *:focus + ${CheckboxButton}`]: {
    boxShadow: "$focus-sm",
  },
});

export const Checkbox = createCheckboxHOC(CheckboxRoot);

Checkbox.displayName = "Checkbox";
