import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Stop } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgArrowBack, SvgSearch, SvgSwap } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { RoadQueryForm, RoadQueryFormData } from "~/presentation/shared/components/RoadQueryForm";
import { displayDate } from "~/utils/date.utils";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { RoadBookingBarUIProps } from "./types";
import { RoadQueryContainer } from "~/presentation/shared/components/RoadQueryContainer/RoadQueryContainer";

export const RoadBookingBarUI: FC<RoadBookingBarUIProps> = ({
  roadQuery,
  isEditable,
  currentStep,
  roadParams,
  onBarClick,
  setCurrentStep,
  onQuery,
}) => {
  const navigation = useNavigate();
  const { bookingStep, roadBudget, order } = useRoadsPage();
  const title = bookingStep.current === "select-travelers" ? "Passageiros" : "Reserva rodoviário";

  const setNavigate = () => {
    if (currentStep === 0) {
      navigation("/");
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const getDataCitys = (stop: Stop) => {
    if (!stop?.stops?.length) return { city: "", state: "", terminal: "" };
    const [cityComplete, terminal] = stop?.stops?.[0]?.name?.split(" - ") as string[];
    const [city, state] = cityComplete?.split(", ") as string[];

    return { city, state, terminal };
  };

  return (
    <>
      <Container>
        {isEditable ? (
          <RoadQueryContainer
            defaultData={roadParams as RoadQueryFormData}
            roadBudget={roadBudget}
            order={order}
            onSubmit={onQuery}
          />
        ) : (
          <Flex
            align="center"
            gap="6"
            onClick={onBarClick}
            css={{
              py: "$4",
              display: "none",
              "@lg": {
                display: "flex",
              },
            }}
          >
            <Grid gap="4" css={{ flexGrow: 1 }}>
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Origem
              </Text>
              <Text
                css={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "$1",
                }}
              >
                <Text>
                  {roadQuery?.originSelected?.city?.name ??
                    getDataCitys(roadQuery?.originSelected)?.city}
                  ,{" "}
                  <strong>
                    {roadQuery?.originSelected?.city?.state ??
                      getDataCitys(roadQuery?.originSelected)?.state}
                  </strong>
                </Text>
                <Text variant="darkest" size="3" fw="500">
                  {!roadQuery?.originSelected?.city.name &&
                    (getDataCitys(roadQuery?.originSelected)?.terminal ?? "")}
                </Text>
              </Text>
            </Grid>

            <Icon as={SvgSwap} size="lg" variant="primary" />

            <Grid gap="4" css={{ flexGrow: 1 }}>
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Destino
              </Text>
              <Text
                css={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "$1",
                }}
              >
                <Text>
                  {roadQuery?.destinationSelected?.city?.name ??
                    getDataCitys(roadQuery?.destinationSelected)?.city}{" "}
                  ,{" "}
                  <strong>
                    {roadQuery?.destinationSelected?.city?.state ??
                      getDataCitys(roadQuery?.destinationSelected)?.state}
                  </strong>
                </Text>
                <Text variant="darkest" size="3" fw="500">
                  {!roadQuery?.originSelected?.city?.name &&
                    getDataCitys(roadQuery?.destinationSelected)?.terminal}
                </Text>
              </Text>
            </Grid>
            <Grid gap="4" css={{ flexGrow: 1 }}>
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Data da ida
              </Text>
              <Text>{displayDate(roadQuery?.dateGoSelected as Date)}</Text>
            </Grid>

            {roadQuery?.dateReturnSelected && (
              <Grid gap="4" css={{ flexGrow: 1 }}>
                <Text variant="dark" size="2" css={{ fw: "700" }}>
                  Data da volta
                </Text>
                <Text>{displayDate(roadQuery?.dateReturnSelected)}</Text>
              </Grid>
            )}

            <Button variant="tertiary">
              <Icon as={SvgSearch} />
            </Button>
          </Flex>
        )}
      </Container>
      <Flex
        justify="between"
        css={{
          padding: "$6",
          backgroundColor: "$primary-base",
          color: "white",
          "@lg": {
            display: "none",
          },
        }}
      >
        <Box>
          <Icon as={SvgArrowBack} fill="white" onClick={setNavigate} />
        </Box>
        <Text>{title}</Text>
        <Box></Box>
      </Flex>
    </>
  );
};

RoadBookingBarUI.displayName = "RoadBookingBarUI";
