import { styled } from "~/application/theme";
import { Text } from "~/components/Text";

const Container = styled("li", {
  p: "$4",
  listStyle: "none",
  transition: "$normal",
  userSelect: "none",

  "&[data-selected=true], &:hover": {
    backgroundColor: "$primary-light",
    [`& > ${Text}`]: {
      color: "$primary-base",
    },
  },

  "&:hover": {
    cursor: "pointer",
  },
});

const Title = styled(Text, {
  fw: "600",
  mb: "$1",
});

export const Styled = {
  Container,
  Title,
} as const;
