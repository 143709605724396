import { useCallback, useState } from "react";

export interface UseCarouselOptions {
  size: number;
  initialItem?: number;
}

export interface UseCarouselReturn {
  currentItem: number;
  toNextItem: () => void;
  toPreviousItem: () => void;
}

export function useCarousel({
  size,
  initialItem = 0,
}: UseCarouselOptions): UseCarouselReturn {
  const [currentItem, setCurrentItem] = useState<number>(initialItem);

  const toNextItem = useCallback(
    () => setCurrentItem((old) => (old === 0 ? size - 1 : --old)),
    [size]
  );

  const toPreviousItem = useCallback(
    () => setCurrentItem((old) => (old === size - 1 ? 0 : ++old)),
    [size]
  );

  return {
    currentItem,
    toNextItem,
    toPreviousItem,
  };
}
