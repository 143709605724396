import { api } from "~/infrastructure/api";
import { IOrderRoadRebooking } from "./IOrderRoadRebooking";

export class OrderRoadRebooking implements IOrderRoadRebooking {
  async rebook(orderId: string): Promise<void> {
    return await api
      .post(`/orders/${orderId}/road/re-booking`)
      .then(({ data }) => data);
  }
}
