import { Judgement } from "~/application/types";
import { api } from "~/infrastructure/api";
import { JudgementDTO } from "~/infrastructure/api/dtos";
import { mapJudgementDTO } from "~/infrastructure/api/mappers";
import {
  IExpenseApprovalParams,
  IExpenseApprovalService,
} from "./IExpenseApprovalService";

export class ExpenseApprovalService implements IExpenseApprovalService {
  async approve(data: IExpenseApprovalParams): Promise<Judgement> {
    return await api
      .post<JudgementDTO>("/customers/expenses/approval", {
        advance_order_uuid: data.advanceOrderId,
        reason_rejected: data.reasonRejected,
        obs: data.obs,
        rejected_items: data.rejectedItems?.map((item) => ({
          accountability_expense_uuid: item.accountabilityExpenseId,
          reason_rejected: item.reasonRejected,
        })),
      })
      .then(({ data }) => mapJudgementDTO(data));
  }
}
