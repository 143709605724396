import { useMemo, useReducer } from "react";

type StateDataExample = { message: string };

type ReducerState<T extends object = object> = {
  isOpen: boolean;
  data: T;
};

type ReducerAction<T extends object = object> =
  | {
      type: "HIDE";
    }
  | {
      type: "SHOW";
      payload: { data?: T };
    };

export type UseModalOptions<T extends object = object> = {
  defaultOpen?: boolean;
  defaultData?: T;
};

export type UseModalReturn<T extends object = object> = {
  data: T;
  isOpen: boolean;
  actions: {
    show: (data?: T) => void;
    close: VoidFunction;
  };
};

export function useModal<T extends object = StateDataExample>(
  options?: UseModalOptions<T>
): UseModalReturn<T> {
  const [{ isOpen, data }, dispatch] = useReducer(
    (prevState: ReducerState<T>, action: ReducerAction<T>) => {
      switch (action.type) {
        case "SHOW":
          return { ...prevState, isOpen: true, ...action.payload };
        case "HIDE":
          return { ...prevState, isOpen: false };
      }
    },
    {
      isOpen: options?.defaultOpen || false,
      data: options?.defaultData || ({} as T),
    }
  );

  const actions = useMemo(
    () => ({
      show: (data?: T) => dispatch({ type: "SHOW", payload: { data } }),
      close: () => dispatch({ type: "HIDE" }),
    }),
    [dispatch]
  );

  return { isOpen, actions, data };
}
