import { ElementType } from "react";
import { CustomerOrder, Order } from "~/application/types";
import { Download } from "~/components/Download";
import { IconButtonProps } from "~/components/IconButton";

export interface OrderVoucherIconProps extends IconButtonProps {
  readonly downloadUrl: string;
  readonly retries?: number;
  readonly delay?: number;
  readonly icon?: ElementType | null;
  readonly onIconClick?: () => void;
  readonly onDownload: () => void;
  readonly onDownloaded?: () => void;
}

export function DownloadOrderVoucher(props: OrderVoucherIconProps) {
  const {
    icon,
    retries = 3,
    delay = 1000,
    downloadUrl,
    onDownloaded,
    onIconClick,
    ...defaultProps
  } = props;

  const emptyFunction = () => null;

  return (
    <Download
      icon={icon}
      url={downloadUrl}
      onIconClick={onIconClick ?? emptyFunction}
      onDownloaded={onDownloaded ?? emptyFunction}
      retries={retries}
      delay={delay}
      {...defaultProps}
    />
  );
}
