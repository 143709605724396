import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";


export interface LoadingDialogProps {
  message?: string;
}

export function LoadingDialog({ message }: LoadingDialogProps) {
  return (
    <Container size="4" fixed>
      <Card css={{ boxShadow: "$sm", "@mxmd": {
        width: "80%",
        margin: "0 auto"
      }}}>
        <DialogBody css={{ "@mxmd": {
          p: "$5",
          width: "80%",
          margin: "0 auto",
        }}}>
          <Flex
            as={CardBody}
            align="center"
            justify="center"
            direction="column"
            css={{ gap: "$8" }}
          >
            <Spinner />
            <Text css={{ textAlign: "center" }}>{message}</Text>
          </Flex>
        </DialogBody>
      </Card>
    </Container>
  );
}
