import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { LoadingSeatsModalProps } from "./types";

export function LoadingSeatsModal({
  message,
  children,
}: LoadingSeatsModalProps) {
  return (
    <Container size="4" fixed>
      <Card
        css={{
          boxShadow: "$sm",
          maxHeight: "100%",
          "@mxmd": {
            p: "$5",
            width: "80%",
            margin: "0 auto",
          },
        }}
      >
        <Flex as={CardBody} align="center" direction="column" gap="8">
          <Spinner />
          <Flex direction="column">
            <Text css={{ textAlign: "center" }}>{message}</Text>
            {children}
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
