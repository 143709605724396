import { styled } from "~/application/theme";

/**
 * @deprecated
 */
export const CardBody = styled("div", {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  padding: "$4",
  flexDirection: "column",
  // flexShrink: 0,
});

/**
 * @deprecated
 */
export const CardScrollView = styled("div", {
  // Reset
  boxSizing: "border-box",
  overflow: "auto",
  maxHeight: "100%",
});

/**
 * @deprecated
 */
export const Card = styled("div", {
  // Reset
  boxSizing: "border-box",
  appearance: "none",
  display: "flex",
  border: "none",
  outline: "none",
  
  // Custom Reset?
  flexDirection: "column",

  // Custom
  font: "inherit",
  lineHeight: "1",
  padding: 0,
  textAlign: "inherit",
  verticalAlign: "middle",
  backgroundColor: "$neutrals-white",
  textDecoration: "none",
  color: "inherit",
  borderRadius: "$md",
  position: "relative",
  overflow: "hidden",

  variants: {
    elevated: {
      true: {
        boxShadow: "$md",
      },
    },
    spacing: {
      "6": {
        [`& ${CardBody}`]: {
          padding: "$6",
        },
      },
      "8": {
        [`& ${CardBody}`]: {
          padding: "$8",
        },
      },
    },
  },
});

/**
 * @deprecated
 */
export const CardHeader = styled("header", {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  padding: "$4 $6",
  backgroundColor: "$primary-dark",
  color: "$neutrals-white",
  gap: "$2",
  fontWeight: 600,
  fontSize: "$md",
  fill: "$neutrals-dark",
});

/**
 * @deprecated
 */
export const CardFooter = styled("footer", {
  // Reset
  boxSizing: "border-box",

  borderTop: "1px solid $colors$neutrals-lightest",
});

/**
 * @deprecated
 */
export const CardSectionTitle = styled("div", {
  padding: "$4 $4 $2 $4",
  fontSize: "$xs",
  lineHeight: "100%",
  color: "$neutrals-black",
  background: "$neutrals-lightest",
  fontWeight: 700,
});
