import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { SvgClock, SvgGps } from "~/components/Icon/icons";
import { displayDate, DateFormats } from "~/utils/date.utils";
import { useContext } from "react";
import { OrderVehicle, VehicleQuery } from "~/application/types";
import { Box } from "~/components/Box";
import { Icon } from "~/components/Icon";
import { FormDialog } from "~/components/FormDialog";
import { DialogBody } from "~/components/Dialog";
import { Container } from "~/components/Container";
import { Text } from "~/components/Text";

type VehicleConfirmationDialogProp = {
  data: OrderVehicle;
};

export function OrderVehicleDialogDetails({
  data,
}: VehicleConfirmationDialogProp) {

  return (
    <Container size="8" fixed>
      <FormDialog
        title="Detalhes da reserva"
        css={{ width: "95%", margin: "0 auto" }}
      >
        <DialogBody css={{ p: 20 }}>
          <Flex direction="column" gap="6">
            <Text>Datas e localização</Text>

            <Grid columns="1" gap="6">
              <Text>
                <Flex direction="column" gap="4">
                  <Text css={{ fw: "bold", fontSize: "$sm" }}>
                    Informações sobre retirada
                  </Text>

                  <Flex gap="3" align="center">
                    <Icon variant="primary" as={SvgClock} />
                    <Text css={{ fontSize: "$sm" }}>Data e hora</Text>
                    <Text>{displayDate(data.departureDate)}</Text>
                  </Flex>

                  <Flex gap="3" align="center">
                    <Icon variant="primary" as={SvgGps} />
                    <Text css={{ fontSize: "$sm" }}>Local</Text>
                    <Text css={{ fontSize: "$sm" }}>{data.departure}</Text>
                  </Flex>
                </Flex>
              </Text>

              <Box>
                <Flex direction="column" gap="4">
                  <Text css={{ fw: "bold", fontSize: "$sm" }}>
                    Informações sobre devolução
                  </Text>

                  <Flex gap="3" align="center">
                    <Icon variant="primary" as={SvgClock} />
                    <Text css={{ fontSize: "$sm" }}>Data e hora</Text>
                    <Text>
                      {displayDate(data.arrivalDate)}
                    </Text>
                  </Flex>

                  <Flex gap="3" align="center">
                    <Icon variant="primary" as={SvgGps} />
                    <Text css={{ fontSize: "$sm" }}>Local</Text>
                    <Text css={{ fontSize: "$sm" }}>{data.arrival}</Text>
                  </Flex>
                </Flex>
              </Box>
            </Grid>
          </Flex>
        </DialogBody>
      </FormDialog>
    </Container>
  );
}
