import { OrderRoadSegment } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { OrderRoadSegmentDTO } from "../dtos";
import { mapTravelerDTO } from "./mapTravelerDTO";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapOrderRoadSegmentDTO(data: OrderRoadSegmentDTO): OrderRoadSegment {
  return {
    uuid: data.uuid,
    companyName: data.company_name,
    companyImage: data.company_image,
    departure: data.departure,
    departureCity: data.departure_city,
    departureDate: toDate(data.departure_date),
    arrival: data.arrival,
    arrivalCity: data.arrival_city,
    arrivalDate: toDate(data.arrival_date),
    duration: 1000 * parseFloat(data.duration),
    providerUnitFee: data.provider_unit_fee,
    value: data.value,
    insuranceValue: data.insurance_value,
    isTheCheapestRoad: data.is_the_cheapest_road,
    seatClass: (data.seatClass || data.seat_class)!,
    fee: data.fee,
    fare: data.fare,
    markup: data.markup,
    providerFees: data.provider_fees,
    originalValue: data.original_value,
    tracker: data.tracker ?? undefined,
    provider: data.provider,
    credential: data.credential,
    travelers: data.travelers.map((traveler) => ({
      ...mapTravelerDTO(traveler),
      seats: traveler.seats.map((seat) => ({
        seatType: seat.seat_type,
        isConnection: seat.is_connection,
        seatNumber: seat.seat_number,
        ticket: seat.ticket,
        bpe: seat.bpe,
      })),
    })),
    connection: data.connection
      ? {
        name: data.connection?.name,
        seatClass: data.connection?.seat_class,
        departureDate: toDate(data.connection?.departure_date),
        arrivalDate: toDate(data.connection?.arrival_date),
        availableSeats: data.connection?.available_seats,
        bpe: data.connection?.bpe,
        companyName: data.connection?.company_name,
        companyUrl: data.connection?.company_url,
      }
      : undefined,
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
    bpeTicket: Boolean(data.bpe_ticket),
    reasonRejected: data.reason_rejected,
    providerIdentification: data.provider_identification,
  };
}
