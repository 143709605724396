import { dayjs } from "./dayjs";
import { DateFormats, DateType } from "./types";

export function format(date: DateType, template: DateFormats): string {
  return dayjs(date).format(template);
}

export function toDate(date: DateType): Date {
  return dayjs(date).toDate();
}

export function startOfDay(date: DateType): Date {
  return dayjs(date).startOf("day").toDate();
}

export function toISOString(date: DateType): string {
  return format(date, DateFormats.DATE_ISO);
}

export function diffBetweenDates(
  date1?: DateType,
  date2?: DateType,
  unit: dayjs.OpUnitType = "day"
): number {
  return dayjs(date1).diff(dayjs(date2), unit);
}

export function asBrazilianDate(
  date: string,
  template: DateFormats = DateFormats.ISO_DATE
): string {
  const firstSlashIndex = date.indexOf("/");
  const secondSlashIndex = date.indexOf("/", firstSlashIndex + 1);
  const day = date.slice(0, firstSlashIndex);
  const month = date.slice(firstSlashIndex + 1, secondSlashIndex);
  const year = date.slice(secondSlashIndex + 1);

  return dayjs(`${year}-${month}-${day}`).format(template);
}

export function addTimeToDate(date: DateType, time: number): Date {
  return dayjs(date).add(time, "ms").toDate();
}

export function timestamp(timestampMs: number): string {
  const seconds = timestampMs / 1000;

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + "d " : "";
  const hDisplay = h > 0 ? h + "h " : "";
  const mDisplay = m > 0 ? m + "m " : "";
  const sDisplay = s > 0 ? s + "s " : "";

  return (dDisplay + hDisplay + mDisplay + sDisplay).trim();
}

export function timestampInterval(date1: DateType, date2: DateType): string {
  const dateJs1 = dayjs(date1);
  const dateJs2 = dayjs(date2);

  const difference = dateJs1.diff(dateJs2);

  return timestamp(Math.abs(difference));
}

export function smallDateFormat(date: DateType): string {
  return format(date, DateFormats.SMALL_DATE);
}

export function toSmallDate(date: string): Date {
  return toDate(smallDateFormat(date));
}
