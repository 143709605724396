import { OrderAirwayItem } from "~/application/types";
import { UnflownData } from "./type";

export const EMPTY_VALUE = {
  differenceValue: 0,
  taxOfChange: 0,
  markup: 0,
  totalValue: 0,
};
export const handleCreateFormData = (data: OrderAirwayItem[]) => {
  const result = [] as UnflownData[];

  data.forEach((airway) => {
    airway.passengers.forEach((passenger) => {
      result.push({
        ...EMPTY_VALUE,
        itemId: airway.uuid,
        tracker: airway.tracker || "",
        passenger: {
          uuid: passenger.uuid,
        },
      });
    });
  });
  return {
    data: result,
  };
};
