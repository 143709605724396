import * as React from "react";
import { SVGProps } from "react";

const SvgArrowBack = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 10a.625.625 0 0 1-.625.625H7.134l2.684 2.683a.626.626 0 0 1-.885.885l-3.75-3.75a.626.626 0 0 1 0-.885l3.75-3.75a.626.626 0 1 1 .885.885L7.134 9.375h7.241A.625.625 0 0 1 15 10Z"
    />
  </svg>
);

export default SvgArrowBack;
