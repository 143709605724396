import { createContext, ReactNode } from "react";
import { OrderApprovalItem } from "~/presentation/shared/utils";
import { OrderItem } from "~/services/resources/OrderApproval/IOrderApprovalService";
import { HandleJudgmentParams, useApprovalOrder } from "./useApprovalOrder";
import { OrderItemsWithChangedPrice } from "~/application/types";

export interface ApprovalOrderState {
  isRejectingOrderDuePriceChange: boolean;
  items: {
    rejected: {
      itemOrderId: string;
      item: OrderApprovalItem;
      reasonRejected: string;
    }[];
    approved: { uuid: string; item: OrderApprovalItem }[];
  };
}

export interface IApprovalOrderContext {
  items: ApprovalOrderState["items"];
  isRejectingOrderDuePriceChange: boolean;
  isJudging: boolean;
  isSuccessfulJudgment: boolean;
  amountOfItems: number;
  cannotReproveItems: boolean;
  cannotApproveItems: boolean;
  setItems: (items: ApprovalOrderState["items"]) => void;
  approveItem: (data: OrderItem) => void;
  reproveItem: (data: OrderItem) => void;
  handleJudgment: (data?: HandleJudgmentParams) => void;
  onOrderItemsPriceChange: (items: OrderItemsWithChangedPrice) => void;
  onConsultOrderItemsPrice: (orderUuid?: string) => Promise<OrderItemsWithChangedPrice>;
}

export const ApprovalOrderContext = createContext<IApprovalOrderContext>(
  {} as IApprovalOrderContext
);

interface ApprovalOrderProviderParams {
  children: ReactNode;
  refetchOrder: () => void;
  refetchOrderHistory: () => void;
}

export function ApprovalOrderProvider({
  refetchOrder,
  refetchOrderHistory,
  children,
}: ApprovalOrderProviderParams) {
  return (
    <ApprovalOrderContext.Provider value={useApprovalOrder({ refetchOrder, refetchOrderHistory })}>
      {children}
    </ApprovalOrderContext.Provider>
  );
}
