import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { pendingOrderService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { log } from "~/utils/log";
import { IPendingOrdersFilters, PendingOrdersContainer } from "./PendingOrdersContainer";
import { usePendingOrdersTab } from "./hooks/usePendingOrdersTab";
import { useDebounce } from "use-debounce";
import { filterOrders } from "./utils";
import { dataAtual } from "~/core/modules/Agency/pages/ReportsPage/utils";

type ISelectOption = {
  value: string;
  label: string;
};

const LOG_TAG = "Order/PendingOrdersPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar pedidos pendentes",
} as const;

const EMPTY_PENDING_ORDERS_FILTERS: IPendingOrdersFilters = {
  consultants: [],
  customers: [],
  status: [],
  travelers: [],
  issuers: [],
  orderItems: [],
  groupByCustomer: null,
  orderNumber: "",
  tracker: "",
};

export function PendingOrdersPage() {
  const { user } = useUser();
  const tabValue = usePendingOrdersTab();

  const [selectedFilters, setSelectedFilters] = useState(EMPTY_PENDING_ORDERS_FILTERS);

  const [selected] = useDebounce(selectedFilters, 700);

  const { data, isFetching } = useQuery(
    [QueryKeys.AGENCY_PENDING_ORDERS, user.agency.uuid],
    () => {
      return pendingOrderService.find({
        tracker: selected.tracker,
      });
    },
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: true,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );
  const dataParsed = useMemo(() => data?.orders.map(order => ({
    uuid: order.uuid, 
    orderNumber: order.orderNumber,
    orderLink: order.orderLink,
    expiresAt: order.expiresAt,
    createdAt: order.createdAt,
    isExpired: order.isExpired,
    nearestServiceDate: order.nearestServiceDate,
    isUrgent: order.isUrgent,
    itemsIncluded: {
      ...order.itemsIncluded,
    },
    status: {
      status: order.status.status,
      createdAt: new Date(order.status.createdAt),
    },
    travelers: order.travelers.map((traveler) => ({
      uuid: traveler.uuid,
      fullName: traveler.fullName,
    })),
    customer: {
      uuid: order.customer.uuid,
      tradingName: (() => {
        const [firstName, secondName] = order.customer.tradingName.split(" ");
        return secondName ? `${firstName} ${secondName}` : firstName;
      })(),
    },
    consultant: {
      uuid: order?.consultant?.uuid,
      fullName: order?.consultant?.fullName,
    },
    issuer: {
      uuid: order.issuer.uuid,
      name: order.issuer.name,
      email: order.issuer.email,
    },
  })), [data]);

  const filteredData = useMemo(
    () => filterOrders(dataParsed, selected),
    [data, selected, dataParsed]
  );

  const availableFilters = useMemo<IPendingOrdersFilters>(
    () => ({
      consultants:
        data?.filters.consultants.map((i) => ({
          label: i.name,
          value: i.uuid,
        })) || [],
      customers:
        data?.filters.customers.map((i) => ({
          label: i.tradingName,
          value: i.uuid,
        })) || [],
      status:
        data?.filters.status.map((i) => ({
          label: {
            approved: "Pendente de emissão",
            quoting: "Em cotação",
            canceling: "Cancelando",
            changing: "Emitido",
          }[i],
          value: i,
        })) || [],
      travelers:
        data?.filters.travelers.map((i) => ({
          label: i.fullName,
          value: i.uuid,
        })) || [],
      orderNumber: data?.filters.orderNumber || "",
      tracker: "",
      orderItems: data?.filters?.orderItems?.map(i => ({
        label: i.item,
        value: i.value
      })) || [], 
      groupByCustomer: data?.filters.groupByCustomers || { label: "", value: "" },
      issuers:
        data?.filters.issuers.map((i) => ({
          label: i.name,
          value: i.uuid,
        })) || [],
    }),
    [data?.filters]
  );
  
  
  const handleFilterCustomer = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, customers: data }));
  }, []);

  const handleFilterTravelers = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, travelers: data }));
  }, []);

  const handleFilterConsultant = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, consultants: data }));
  }, []);

  const handleFilterOrderItem = useCallback((item: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, orderItems: item }));
  }, []);

  const handleFilterStatus = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, status: data }));
  }, []);

  const handleOrderNumber = useCallback((search: string) => {
    setSelectedFilters((old) => ({ ...old, orderNumber: search }));
  }, []);

  const handleIssuer = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, issuers: data }));
  }, []);

  const handleGroupCustomer = useCallback((data: ISelectOption) => {
    setSelectedFilters((old) => ({ ...old, groupByCustomer: data }));
  }, []);

  const handleFilterTracker = useCallback((search: string) => {
    setSelectedFilters((old) => ({ ...old, tracker: search }));
  }, []);

  const handleCleanFilters = useCallback(() => {
    setSelectedFilters(EMPTY_PENDING_ORDERS_FILTERS);
  }, []);

  return (
    <PendingOrdersContainer
      isLoading={isFetching}
      pendingOrdersList={filteredData}
      activeTab={tabValue}
      filters={availableFilters}
      selectedFilters={selectedFilters}
      onFilterCustomer={handleFilterCustomer}
      onFilterGroupCustomer={handleGroupCustomer}
      onFilterTravelers={handleFilterTravelers}
      onFilterConsultant={handleFilterConsultant}
      onFilterOrderItem={handleFilterOrderItem}
      onFilterStatus={handleFilterStatus}
      onCleanFilters={handleCleanFilters}
      onOrderNumber={handleOrderNumber}
      onFilterTracker={handleFilterTracker}
      onIssuer={handleIssuer}
    />
  );
}
