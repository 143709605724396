import { OrderRoad, RoadOrderWithCheapestOptionsType } from "~/application/types";
import { OrderRoadDTO, RoadOrderWithCheapestOptionsTypeDTO } from "../dtos";
import { mapOrderRoadSegmentDTO } from "./mapOrderRoadSegmentDTO";
import { mapCheapestRoadsDTO } from "./mapRoadDTO";

export function mapOrderRoadDTO(data: OrderRoadDTO): OrderRoad {
  return {
    goRoad: mapOrderRoadSegmentDTO(data[0]),
    returnRoad: data[1] ? mapOrderRoadSegmentDTO(data[1]) : null,
  };
}

export function mapRoadOrderWithCheapestOptionsTypeDTO(
  data: RoadOrderWithCheapestOptionsTypeDTO
): RoadOrderWithCheapestOptionsType {
  const { road_order, cheapests_roads } = data;

  return {
    roadOrder: mapOrderRoadSegmentDTO(road_order),
    cheapestRoads: cheapests_roads ? mapCheapestRoadsDTO(cheapests_roads) : null,
  };
}
