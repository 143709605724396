export function getAirlineLogo(logo: string): string | undefined {
  switch (logo) {
    case "gws":
    case "gol":
      return "/images/airlines/gws.png";
    case "azul":
      return "/images/airlines/azul.png";
    case "latam":
      return "/images/airlines/latam.png";
    default:
      return undefined;
  }
}
