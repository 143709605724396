import React, { forwardRef, useCallback, useContext } from "react";
import ReactInputMask, { InputState } from "react-input-mask";
import { FormControlContext } from "../../FormControl/FormControlContext";
import { TextInput, TextInputProps } from "../TextInput";
import { controlValueMask, formatChars, mask } from "./utils";

export type DateInputProps = TextInputProps;

export const DateInput = forwardRef<React.ElementRef<typeof TextInput>, DateInputProps>(
  (
    {
      onFocus,
      name: nameProps,
      disabled: disabledProps,
      onBlur: onBlurProps,
      onChange: onChangeProps,
      value: valueProps,
      ...props
    },
    forwardedRef
  ) => {
    const formControl = useContext(FormControlContext);

    const name = nameProps ?? formControl.name;
    const disabled = disabledProps ?? formControl.disabled;
    const onBlur = onBlurProps ?? formControl.onBlur;
    const onChange = onChangeProps ?? formControl.onChange;
    const value = valueProps ?? formControl.value;
    const isDirty = !!formControl.error;

    const CustomInput = useCallback(
      (inputProps: TextInputProps) => (
        <TextInput disabled={disabled} {...props} {...inputProps} ref={forwardedRef} />
      ),
      [props, disabled, forwardedRef]
    );

    const beforeMaskedValueChange = useCallback((nextState: InputState): InputState => {
      const value = controlValueMask(nextState.value);
      return { value, selection: nextState.selection };
    }, []);

    return (
      <ReactInputMask
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatChars={formatChars}
        alwaysShowMask
        mask={mask}
        maskChar=""
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        name={name}
        data-dirty={isDirty}
        beforeMaskedValueChange={beforeMaskedValueChange}
      >
        {CustomInput}
      </ReactInputMask>
    );
  }
);

DateInput.displayName = "DateInput";
