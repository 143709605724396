import { Button } from "~/components/Button";
import { Col } from "~/components/Grid";
import { SearchBar } from "~/components/Input";
import { DateInputWithCalendar } from "~/components/Input/DateInput/DateInputWithCalendar";
import { DataColItem } from "~/components/List";
import { OrderSearch } from "../../AgenciesOrdersPage";
import { Text } from "~/components/Text";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface AgencyOrderFilterProps {
  searches?: OrderSearch;
  isLoading: boolean;
  onClearFilters: () => void;
  setOrderNumber: (search: string) => void;
  setEmployeeName: (search: string) => void;
  setCustomerName: (search: string) => void;
  setIssuerName: (search: string) => void;
  setApproverName: (search: string) => void;
  setStartDate: (search: string) => void;
  setTracker: (search: string) => void;
  setStartDateCallback: (date: string) => void;
  setEndDateCallback: (date: string) => void;
}

export function OrderFilter({
  searches,
  setOrderNumber,
  setEmployeeName,
  setCustomerName,
  setIssuerName,
  setApproverName,
  setTracker,
  setStartDateCallback,
  setEndDateCallback,
  onClearFilters,
}: AgencyOrderFilterProps) {
  const isMobile = useMobile();

  return (
    <>
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Número do pedido"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.orderNumber}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setOrderNumber}
              placeholder="Encontrar"
            />
          </Col>
        }
      />
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Localizador"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.tracker}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setTracker}
              placeholder="Encontrar"
            />
          </Col>
        }
      />
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Nome do funcionário"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.employeeName}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setEmployeeName}
              placeholder="Encontrar"
            />
          </Col>
        }
      />
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Nome da empresa"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.customerName}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setCustomerName}
              placeholder="Encontrar"
            />
          </Col>
        }
      />
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Nome do solicitante"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.issuerName}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setIssuerName}
              placeholder="Encontrar"
            />
          </Col>
        }
      />
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Nome do aprovador"
        data={
          <Col sz="auto" css={{ "@mxlg": { maxWidth: "100%" } }}>
            <SearchBar
              search={searches?.approverName}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setApproverName}
              placeholder="Encontrar"
            />
          </Col>
        }
      />
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Data de início"
        data={
          <Col sz="auto">
            <DateInputWithCalendar
              size="sm"
              maxValue={new Date()}
              style={{ width: isMobile ? "100%" : "auto" }}
              placeholder="Encontrar"
              value={searches?.startDate}
              onChangeCallback={setStartDateCallback}
            />
          </Col>
        }
      />
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Data de fim"
        data={
          <Col sz="auto" css={{ "@mxlg": { maxWidth: "100%" } }}>
            <DateInputWithCalendar
              size="sm"
              maxValue={new Date()}
              style={{ width: isMobile ? "100%" : "auto" }}
              placeholder="Encontrar"
              value={searches?.endDate}
              onChangeCallback={setEndDateCallback}
            />
          </Col>
        }
      />
      <Col sz="auto" css={{ "@mxlg": { maxWidth: "100%" } }}>
        <Button
          title="Limpar filtros"
          variant="tertiary"
          onClick={onClearFilters}
        >
          <Text>Limpar filtros</Text>
        </Button>
      </Col>
    </>
  );
}
