import { ComponentProps } from "react";
import { styled, keyframes } from "~/application/theme";

export type SkeletonProps = ComponentProps<typeof Skeleton>;

const pulse = keyframes({
  "0%": { opacity: "0%" },
  "100%": { opacity: "20%" },
});

export const Skeleton = styled("span", {
  // Reset
  boxSizing: "border-box",

  // Custom
  cursor: "wait",
  display: "block",
  backgroundColor: "$neutrals-lightest",
  position: "relative",
  overflow: "hidden",
  borderRadius: "$sm",

  "&::after": {
    animationName: `${pulse}`,
    animationDuration: "500ms",
    animationDirection: "alternate",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
    backgroundColor: "$neutrals-dark",
    borderRadius: "inherit",
    content: "''",
    position: "absolute",
    inset: 0,
  },

  variants: {
    variant: {
      "avatar-sm": {
        borderRadius: "$md",
        size: "$6",
      },
      "avatar-md": {
        borderRadius: "$md",
        size: "$8",
      },
      "avatar-lg": {
        borderRadius: "$md",
        size: "$10",
      },
      "icon-sm": {
        borderRadius: "$sm",
        size: "$4",
      },
      "icon-md": {
        size: "$5",
      },
      "icon-lg": {
        size: "$8",
      },
      "text-2": {
        height: "$3",
      },
      "text-4": {
        height: "$4",
      },
      "text-6": {
        height: "$6",
      },
      "text-8": {
        height: "$8",
      },
      "button-sm": {
        height: "$12",
        width: "$8",
      },
      "button-md": {
        height: "$13",
        width: "$8",
      },
      "button-lg": {
        height: "$20",
        width: "$8",
      },
      "input-md": {
        height: "$16",
      },
      "input-sm": {
        height: "$12",
      },
      tag: {
        height: "$8",
        width: "$40",
      },
      fluid: {},
    },
  },
  defaultVariants: {
    variant: "text-4",
  },
});

Skeleton.displayName = "Skeleton";
