import { Road } from "~/application/types";
import { RoadSelected } from "~/presentation/Booking/BookingRoad/types";
import type { RoadBookingCartPrice } from "./types";

export function calculateRoadBookingCartPrice(
  goRoad: Road | null,
  returnRoad: Road | null,
  seatsQuantity: number,
  stretches: RoadSelected[]
): RoadBookingCartPrice {
  let totalValue = 0;
  let totalTaxesValue = 0;
  let individualValue = 0;
  let feesValue = 0;

  if (stretches?.length) {
    stretches.forEach((stretche) => {
      totalValue += stretche.road.totalPrice * seatsQuantity;
      totalTaxesValue += stretche.road.taxPrice * seatsQuantity;
      feesValue += stretche.road.fee * seatsQuantity;
      individualValue += stretche.road.price;
    });
  }

  if (goRoad) {
    totalValue += goRoad.totalPrice * seatsQuantity;
    totalTaxesValue += goRoad.taxPrice * seatsQuantity;
    feesValue += goRoad.fee * seatsQuantity;
    individualValue += goRoad.price;
  }

  if (returnRoad) {
    totalValue += returnRoad.totalPrice * seatsQuantity;
    totalTaxesValue += returnRoad.taxPrice * seatsQuantity;
    feesValue += returnRoad.fee * seatsQuantity;
    individualValue += returnRoad.price;
  }

  return {
    feesValue,
    totalValue,
    individualValue,
    totalTaxesValue,
  };
}
