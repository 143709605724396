import * as React from "react";
import { SVGProps } from "react";

const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 25.998h-3a6.998 6.998 0 0 1-5.964-10.655 7 7 0 0 1 7.703-3.127m-.74 3.782a10 10 0 1 1 18 6m-13.242-1.757 4.242-4.243m0 0 4.243 4.243m-4.243-4.243v10"
      stroke="#0064C5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgUpload;
