import { Agency } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { Link } from "~/components/Link";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import * as MaskUtils from "~/utils/mask.utils";

export interface AgencyListItemProps extends DataListItemProps {
  data: Agency;
  onToggleState: (item: Agency) => void;
}

export function AgencyListItem({ data, onToggleState, ...props }: AgencyListItemProps) {
  return (
    <DataListItem data-active={data.isActive} {...props}>
      <DataColItem headerTitle="Razão social" data={data.companyName} />
      <DataColItem headerTitle="Nome fantasia" data={data.tradingName} />
      <DataColItem headerTitle="CNPJ" data={MaskUtils.asCNPJ(data.cnpj)} />
      <DataColItem headerTitle="Matrícula" data={data.stateRegistration} />
      <Tooltip content={<TooltipLabel>Editar agência</TooltipLabel>}>
        <Link to={`/configuracoes/agencias/${data.uuid}`}>
          <IconButton size="md">
            <Icon as={SvgEdit} />
          </IconButton>
        </Link>
      </Tooltip>
      <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
        <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
      </Switch>
    </DataListItem>
  );
}

AgencyListItem.displayName = "AgencyListItem";
