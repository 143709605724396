import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { SwitchBase } from "../base/SwitchBase";
import { createCheckboxHOC } from "./createCheckboxHOC";
import { CheckboxButton } from "./CheckboxButton";

export type ContainedCheckboxProps = ComponentProps<typeof ContainedCheckbox>;

const ContainedCheckboxRoot = styled(SwitchBase, {
  // Custom
  display: "flex",
  padding: "$4",
  border: "1px solid",
  borderColor: "$neutrals-light",
  borderRadius: "$md",
  backgroundColor: "$neutrals-white",
  transition: "$normal",
  alignItems: "flex-start",

  "&:hover": {
    borderColor: "$primary-base",
  },

  "&[data-checked=true]": {
    backgroundColor: "$primary-light",
    borderColor: "$primary-base",
  },

  "&[data-disabled=true]": {
    opacity: "$disabled",
  },

  [`& > *:focus + ${CheckboxButton}`]: {
    boxShadow: "$focus-sm",
  },
});

export const ContainedCheckbox = createCheckboxHOC(ContainedCheckboxRoot);

ContainedCheckbox.displayName = "ContainedCheckbox";
