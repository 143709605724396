import queryString from "query-string";

import { api } from "~/infrastructure/api";
import { OfflineHotelRoomDTO } from "~/infrastructure/api/dtos";
import {
  CreateOfflineHotelRoomProps,
  DeleteOfflineHotelRoomImageProps,
  FindOfflineHotelRoomByIdProps,
  FindOfflineHotelRoomsProps,
  IOfflineHotelRoomService,
  UpdateOfflineHotelRoomProps,
} from "./IOfflineHotelRoomService";
import { OfflineHotelRoom, PaginatedResource } from "~/application/types";
import { mapOfflineHotelRoomDTO } from "~/infrastructure/api/mappers";

export class OfflineHotelRoomService implements IOfflineHotelRoomService {
  async create({ offlineHotelId, data, images }: CreateOfflineHotelRoomProps): Promise<OfflineHotelRoom> {
    const formData = new FormData();

    formData.append("regime", data.regime);
    formData.append("description", data.description);
    formData.append("type", data.type);
    formData.append("value", String(data.value));
    formData.append("adults_amount", String(data.adultsAmount));

    for (const amenity of data.amenities) {
      formData.append("amenities[]", amenity);
    }

    for (const image of images) {
      formData.append("images[]", image);
    }

    return await api
      .post<OfflineHotelRoomDTO>(`/offline-hotels/${offlineHotelId}/rooms`, formData, {
        "Content-Type": "multipart/form-data",
      }).then(({ data }) => mapOfflineHotelRoomDTO(data));
  }

  async updateById({ data, images }: UpdateOfflineHotelRoomProps): Promise<OfflineHotelRoom> {
    const formData = new FormData();

    formData.append("regime", data.regime);
    formData.append("description", data.description);
    formData.append("type", data.type);
    formData.append("value", String(data.value));
    formData.append("adults_amount", String(data.adultsAmount));

    for (const amenity of data.amenities) {
      formData.append("amenities[]", amenity);
    }

    if (images.length) {
      for (const image of images) {
        formData.append("images[]", image);
      }
    }

    return await api
      .post<OfflineHotelRoomDTO>(`/offline-hotels/rooms/${data.uuid}`, formData, {
        "Content-Type": "multipart/form-data",
      }).then(({ data }) => mapOfflineHotelRoomDTO(data));
  }

  async find({ offlineHotelId, page = 1 }: FindOfflineHotelRoomsProps): Promise<PaginatedResource<OfflineHotelRoom[]>> {
    const url = queryString.stringifyUrl({
      url: `/offline-hotels/${offlineHotelId}/rooms`,
      query: { page },
    });

    return await api
      .get<PaginatedResource<OfflineHotelRoomDTO[]>>(url)
      .then(({ data }) => ({
        links: data.links,
        meta: data.meta,
        data: data.data.map(mapOfflineHotelRoomDTO),
      }));
  }

  async findById({ offlineHotelId, offlineHotelRoomId }: FindOfflineHotelRoomByIdProps): Promise<OfflineHotelRoom> {
    return await api
      .get<OfflineHotelRoomDTO>(`/offline-hotels/${offlineHotelId}/rooms/${offlineHotelRoomId}`)
      .then(({ data }) => mapOfflineHotelRoomDTO(data));
  }

  async deleteImage({ offlineHotelRoomId, imageId }: DeleteOfflineHotelRoomImageProps): Promise<void> {
    await api.delete(`/offline-hotels/rooms/${offlineHotelRoomId}/images/${imageId}`).then(({ data }) => data);
  }
}
