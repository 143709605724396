import { FC, useMemo } from "react";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { CardBody, CardSectionHeader } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { formatFlightInfo, getAirlineUrl } from "~/presentation/shared/utils";
import { AirwayCartItemProps } from "./types";

export const AirwayCartItem: FC<AirwayCartItemProps> = ({
  title,
  flight,
  isCombined = false,
  onOpenDetails,
}) => {
  const flightInfo = useMemo(() => formatFlightInfo(flight), [flight]);

  if (isCombined) {
    flightInfo.flight.flightOrigin = "combined";
  }

  return (
    <Box
      css={{
        "@mxlg": {
          display: "none",
        },
      }}
    >
      <CardSectionHeader>{title}</CardSectionHeader>

      <CardBody>
        <Flex align="center" gap="4" css={{ mb: "$6" }}>
          <Box css={{ flexGrow: "1" }}>
            <CompanyLogo src={getAirlineUrl(flightInfo.flight.airline)} size="lg" />
          </Box>

          <Button variant="tertiary" size="sm" onClick={() => onOpenDetails(flightInfo.flight)}>
            <Text>Ver detalhes</Text>
          </Button>
        </Flex>

        <Grid gap="6" columns="2">
          <Flex direction="column" gap="4">
            <Caption>Saída</Caption>
            <Text size="5" css={{ fw: "600" }}>
              {flightInfo.departureFlightInfo.airportIata}
            </Text>
            <Text size="3" css={{ fw: "500" }}>
              {flightInfo.departureFlightInfo.airport}
            </Text>
            <Text size="3">{flightInfo.departureFlightInfo.date}</Text>
            <Caption css={{ fw: 500 }}>{flightInfo.departureFlightInfo.dateTimeHour12}</Caption>
          </Flex>

          <Flex direction="column" gap="4">
            <Caption>Chegada</Caption>
            <Text size="5" css={{ fw: 600 }}>
              {flightInfo.arrivalInfo.airportIata}
            </Text>
            <Text size="3" css={{ fw: 500 }}>
              {flightInfo.arrivalInfo.airport}
            </Text>
            <Text size="3">{flightInfo.arrivalInfo.date}</Text>
            <Caption css={{ fw: 500 }}>{flightInfo.arrivalInfo.dateTimeHour12}</Caption>
          </Flex>
        </Grid>
      </CardBody>
    </Box>
  );
};

AirwayCartItem.displayName = "AirwayCartItem";
