import { PolicyParameterExpense } from '~/application/types';
import { PolicyParameterExpenseDTO } from '../dtos';

export function mapPolicyParameterExpenseDTO(
  data: PolicyParameterExpenseDTO
): PolicyParameterExpense {
  return {
    uuid: data.uuid,
    description: data.description,
    slug: data.slug,
    type: data.type,
  };
}