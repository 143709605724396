import { CustomerSettingParameter } from "~/application/types";
import { SettingParameterTypeValue } from "~/application/types/entities/SettingParameter.type";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Switch } from "~/components/Input";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { EditSettingParameter } from "~/core/shared/components/EditSettingParameter";

export interface SettingParameterListItemProps {
  data: CustomerSettingParameter;
  onEditClick: (item: CustomerSettingParameter) => void;
  isUpdating: boolean;
}

export function SettingParameterListItem({
  data,
  isUpdating,
  onEditClick,
}: SettingParameterListItemProps) {
  const getDefaultData = () => {
    if (data.value === null) {
      return "Não definido";
    }

    if (data.typeValue === SettingParameterTypeValue.BOOLEAN) {
      return (
        <Switch checked={!!data.value} onClick={() => onEditClick(data)}>
          <Caption>{data.value ? "Ativo" : "Inativo"}</Caption>
        </Switch>
      );
    }

    if (data.typeValue === SettingParameterTypeValue.PERCENTAGE) {
      return `${data.value}%`;
    }

    return data.value;
  };

  if (isUpdating) {
    return (
      <LoadingModal
        css={{ width: "100%" }}
        isOpen={isUpdating}
        message={"Editando parâmetro"}
      />
    );
  }

  return (
    <Row
      css={{
        px: "$6",
        py: "$4",
        background: "$neutrals-white",
        borderRadius: "$md",
      }}
      justify="between"
      align="center"
    >
      <Col sz="6">
        <Text
          size="4"
          css={{ mb: "$$data-list-gap", fw: "500", display: "block" }}
        >
          {data.description}
        </Text>
      </Col>

      <Col sz="1">
        <Flex align="center" justify="between">
          {data.typeValue === SettingParameterTypeValue.BOOLEAN ? (
            getDefaultData()
          ) : (
            <Tag variant="info-light">
              <Text size="2">{getDefaultData()}</Text>
            </Tag>
          )}

          {data.typeValue !== SettingParameterTypeValue.BOOLEAN && (
            <EditSettingParameter data={data} onEditClick={onEditClick} />
          )}
        </Flex>
      </Col>
    </Row>
  );
}

SettingParameterListItem.displayName = "SettingParameterListItem";
