import { FC, useCallback } from "react";
import { colors } from "~/application/theme";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgVooIda } from "~/components/Icon/icons";
import { Link } from "~/components/Link";
import { Text } from "~/components/Text";
import { SessionExpiredDialogProps } from "./types";

export const SessionExpiredDialog: FC<SessionExpiredDialogProps> = ({
  onReload,
  onCloseClick,
}) => {
  const handleOnReload = useCallback(() => {
    onReload?.();
    onCloseClick?.();
  }, [onReload, onCloseClick]);

  return (
    <Container size="4" fixed>
      <Card css={{ boxShadow: "$sm", maxHeight: "100%", "@mxlg": {
        p: 20
      }}}>
        <Flex as={CardBody} align="center" direction="column" gap="6">
          <Icon
            as={SvgVooIda}
            css={{ size: "$16" }}
            fill={colors["primary-base"]}
          />

          <Box>
            <Text
              as="h4"
              size="6"
              css={{ textAlign: "center", fontWeight: 600, mb: "$3" }}
            >
              Sessão expirada
            </Text>

            <Text as="p" css={{ textAlign: "center", lineHeight: 1.25 }}>
              Sua sessão expirou, deseja começar de novo em uma nova sessão?
            </Text>
          </Box>

          <Flex direction="column" gap="2" css={{ width: "100%" }}>
            <Button onClick={handleOnReload} fullWidth>
              <Text>Começar novamente</Text>
            </Button>

            <Link to="/">
              <Button variant="secondary" onClick={onCloseClick} fullWidth>
                <Text>Voltar para o início</Text>
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
};

SessionExpiredDialog.displayName = "SessionExpiredDialog";
