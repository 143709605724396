import {
  useViolatedPoliciesInOrderDetailsProps,
  useViolatedPoliciesInOrderDetailsReturn,
} from "./types";

export const useViolatedPoliciesInOrderDetails = ({
  order,
}: useViolatedPoliciesInOrderDetailsProps): useViolatedPoliciesInOrderDetailsReturn => {
  const totalRoadViolatedPolicies =
    order?.items.road?.travels?.reduce(
      (acc, item) => (item?.violatedPolicies?.length ? acc + 1 : acc),
      0
    ) || 0;
  const totalHotelViolatedPolicies =
    order?.items.hotel?.rooms?.reduce(
      (acc, item) => (item?.violatedPolicies?.length ? acc + 1 : acc),
      0
    ) || 0;
  const totalVehicleViolatedPolicies =
    order?.items.vehicle?.vehicles?.reduce(
      (acc, item) => (item?.violatedPolicies?.length ? acc + 1 : acc),
      0
    ) || 0;
  const totalAirwayViolatedPolicies =
    order?.items.airway?.flights?.reduce(
      (acc, item) => (item?.violatedPolicies?.length ? acc + 1 : acc),
      0
    ) || 0;

  const itemsNumberWithViolatedPolicies =
    totalVehicleViolatedPolicies +
    totalRoadViolatedPolicies +
    totalHotelViolatedPolicies +
    totalAirwayViolatedPolicies;

  return { itemsNumberWithViolatedPolicies };
};
