import { useMemo, useState } from "react";
import { StepItem, createStepItems } from "~/presentation/shared/utils";
import { BookingRoadSteps, createBookingMenu } from "../../constants";
import { BookingRoadQuery, RoadBookingStep } from "../useRoadReducer/types";
import { UseRoadBookingProps, UseRoadBookingResult } from "./types";
import { RoadQuery } from "~/application/types";
import { roadQueryService } from "~/application/usecases";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { QueryKeys } from "~/constants";
import { useQueries } from "@tanstack/react-query";

export const useRoadBooking = ({
  roadParams,
  roadQueryLinks,
  isLoadingLinks,
  roadBudget,
  isManyStretch,
}: UseRoadBookingProps): UseRoadBookingResult => {
  const [currentStep, setCurrentStep] = useState(0);
  const { activeBudget } = roadBudget;
  const { contexts } = useUser();

  const BOOKING_MENU_STEPS = createBookingMenu({ stretchLength: roadParams?.length || 0 });

  const bookingSteps = useMemo<StepItem<BookingRoadSteps | string>[]>(() => {
    return createStepItems(BOOKING_MENU_STEPS, {
      isBudget: !activeBudget,
      params: roadParams?.at(0) as RoadQuery,
    });
  }, [roadParams, activeBudget]);

  const roadQueries = useQueries({
    queries:
      roadParams?.map((query) => ({
        queryKey: [QueryKeys.HOTEL, query],
        enabled: !isLoadingLinks,
        queryFn: async () => await roadQueryService.query(contexts.customer.uuid, query),
      })) ?? [],
  });

  const bookingStep = useMemo<RoadBookingStep>(
    () => ({
      current: bookingSteps[currentStep].key,
      currentIndex: currentStep,
      steps: bookingSteps,
      setStep: setCurrentStep,
    }),
    [bookingSteps, currentStep]
  );

  const currentRoadQuery = roadQueries.at(bookingStep.currentIndex);
  const currentRoadParams = roadParams?.at(bookingStep.currentIndex);

  const roadQuery = useMemo<BookingRoadQuery>(
    () => ({
      data: isManyStretch ? currentRoadParams : roadParams?.at(0),
      isLoading: isManyStretch ? !!currentRoadQuery?.isLoading : isLoadingLinks,
      links: isManyStretch ? currentRoadQuery?.data : roadQueryLinks,
    }),
    [roadParams, roadQueryLinks, isLoadingLinks, currentRoadQuery, isManyStretch]
  );

  const isOneWay = useMemo<boolean>(() => !roadParams?.at(0)?.dateReturnSelected, [roadParams]);

  return {
    currentStep,
    setCurrentStep,
    roadQuery,
    bookingStep,
    bookingSteps,
    isOneWay,
  };
};
