import queryString from "query-string";

import { FindPhasesProps, IPhaseService } from "./IPhaseService";
import { PaginatedResource, Phase } from "~/application/types";
import { mapPhaseDTO } from "~/infrastructure/api/mappers";
import { PhaseDTO } from "~/infrastructure/api/dtos";
import { api } from "~/infrastructure/api";
import { dayjs } from "~/utils/date.utils";

export class PhaseService implements IPhaseService {
  async find({
    projectId,
    page = 1,
    name,
    isActive,
  }: FindPhasesProps): Promise<PaginatedResource<Phase[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/projects/${projectId}/phases`,
      query: { page, name, active: isActive },
    });

    return await api.get<PaginatedResource<PhaseDTO[]>>(url).then(({ data }) => ({
      links: data.links,
      meta: data.meta,
      data: data.data.map(mapPhaseDTO),
    }));
  }

  async create(data: Phase): Promise<Phase> {
    return await api
      .post<PhaseDTO>(`/customers/projects/${data.projectId}/phases`, {
        name: data.name,
        code: data.code,
        budget: data.budget,
        alert: data.alert,
        start_at: dayjs(data.startAt).format("YYYY-MM-DD HH:mm:ss"),
        end_at: data.endAt ? dayjs(data.endAt).format("YYYY-MM-DD HH:mm:ss") : null,
      })
      .then(({ data }) => mapPhaseDTO(data));
  }

  async update(data: Phase): Promise<Phase> {
    return await api
      .put<PhaseDTO>(`/customers/phases/${data.uuid}`, {
        name: data.name,
        code: data.code,
        budget: data.budget,
        alert: data.alert,
        start_at: dayjs(data.startAt).format("YYYY-MM-DD HH:mm:ss"),
        end_at: data.endAt ? dayjs(data.endAt).format("YYYY-MM-DD HH:mm:ss") : null,
      })
      .then(({ data }) => mapPhaseDTO(data));
  }
}
