import { ExpenseCategory } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import {
  DataColItem,
  DataListItem,
  DataListItemProps,
} from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { EXPENSE_CATEGORY_ICONS } from "../utils";

export interface ExpenseCategoryListItemProps extends DataListItemProps {
  data: ExpenseCategory;
  onEditClick: (item: ExpenseCategory) => void;
  onToggleState: (item: ExpenseCategory) => void;
}

export function ExpenseCategoryListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: ExpenseCategoryListItemProps) {
  const icon = EXPENSE_CATEGORY_ICONS.find(
    ({ slug }) => slug === data.icon
  )?.icon;

  return (
    <DataListItem data-active={data.isActive} {...props}>
      <Icon as={icon} size="lg" />
      <DataColItem headerTitle="Nome da despesa" data={data.name} />
      <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
        <IconButton size="md" onClick={() => onEditClick(data)}>
          <Icon as={SvgEdit} />
        </IconButton>
      </Tooltip>
      <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
        <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
      </Switch>
    </DataListItem>
  );
}

ExpenseCategoryListItem.displayName = "ExpenseCategoryListItem";
