import { styled } from "@stitches/react";
import React from "react";

const MenuContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  "> *": {
    display: "inline-block",
  },
  "> * + *": {
    marginLeft: "15px",
  },
});

export const Menu = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof MenuContainer>>(
  ({ className, ...props }, ref) => (
    <MenuContainer
      {...props}
      ref={ref}
      className={className}
    />
  )
);
