import { Descendant } from "slate";

type TextNode = {
    text: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    code?: boolean;
  };
  
  type LinkNode = {
    type: 'link';
    url: string;
    children: SlateNode[]; 
  };
  
  type ElementNode = {
    type:
      | 'paragraph'
      | 'block-quote'
      | 'list-item'
      | 'numbered-list'
      | 'bulleted-list'
      | 'heading-one'
      | 'heading-two';
    children: SlateNode[];
  };
  
  export type SlateNode = TextNode | ElementNode | LinkNode | Descendant[];
  
  type CustomDescendant = SlateNode; 
  
  export function convertToHTML(nodes: CustomDescendant[]): string {
    return nodes
      .map((node) => {
        if ('text' in node) {
          let text = node.text;
          if (node.bold) text = `<strong>${text}</strong>`;
          if (node.italic) text = `<em>${text}</em>`;
          if (node.underline) text = `<u>${text}</u>`;
          if (node.code) text = `<code>${text}</code>`;
          return text;
        } else if ('type' in node && node.children) {
          const children = convertToHTML(node.children);
  
          switch (node.type) {
            case 'paragraph':
              return `<p>${children}</p>`;
            case 'block-quote':
              return `<blockquote>${children}</blockquote>`;
            case 'numbered-list':
              return `<ol>${children}</ol>`;
            case 'list-item':
              return `<li>${children}</li>`;
            case 'bulleted-list':
              return `<ul>${children}</ul>`;
            case 'heading-one':
              return `<h1>${children}</h1>`;
            case 'heading-two':
              return `<h2>${children}</h2>`;
            case 'link':
              return `<a href="${(node as LinkNode).url}">${children}</a>`;
            default:
              return children;
          }
        }
        return '';
      })
      .join('');
  }
  
  export function htmlToSlate(htmlString: string): SlateNode[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const slateNodes: SlateNode[] = [];
  
    if (htmlString === '') {
      return [
        {
          type: 'paragraph',
          children: [
            { text: "" }
          ]
        }
      ];
    }
  
    function processNode(node: Node): SlateNode | null {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent || '';
        return textContent ? { text: textContent } : null;
      }
  
      if (node.nodeType !== Node.ELEMENT_NODE) {
        return null;
      }
  
      const children: SlateNode[] = Array.from(node.childNodes)
        .map(processNode)
        .filter((child): child is SlateNode => child !== null);
  
      if (children.length === 0) {
        children.push({ text: "" }); 
      }
  
      switch (node.nodeName.toLowerCase()) {
        case "p":
          return { type: "paragraph", children };
        case "strong":
        case "em":
        case "u":
        case "code": {
          const textNode = children[0] as TextNode;
          if (!textNode || !('text' in textNode)) return null;
  
          if (node.nodeName.toLowerCase() === "strong") textNode.bold = true;
          if (node.nodeName.toLowerCase() === "em") textNode.italic = true;
          if (node.nodeName.toLowerCase() === "u") textNode.underline = true;
          if (node.nodeName.toLowerCase() === "code") textNode.code = true;
  
          return textNode;
        }
        case "blockquote":
          return { type: "block-quote", children };
        case "ol":
          return { type: "numbered-list", children };
        case "ul":
          return { type: "bulleted-list", children };
        case "li":
          return { type: "list-item", children };
        case "h1":
          return { type: "heading-one", children };
        case "h2":
          return { type: "heading-two", children };
        default:
          return { type: "paragraph", children };
      }
    }
  
    Array.from(doc.body.childNodes).forEach((node) => {
      const slateNode = processNode(node);
      if (slateNode) {
        slateNodes.push(slateNode);
      }
    });
  
    return slateNodes;
  }
  