import { FC, useCallback } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { CustomerSettingParameter } from "~/application/types";
import { SettingParameterTypeValue } from "~/application/types/entities/SettingParameter.type";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { NumberInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";

export type CustomerSettingParameterDialogProps = {
  data?: CustomerSettingParameter;
  onCloseClick?: () => void;
  onSubmit: (data: CustomerSettingParameter) => void;
};

export const CustomerSettingParameterDialog: FC<CustomerSettingParameterDialogProps> =
  ({ data, onCloseClick, onSubmit: onSubmitProp }) => {
    const isNew = !data;

    const { control, formState, handleSubmit } =
      useForm<CustomerSettingParameter>({
        defaultValues: data,
        reValidateMode: "onBlur",
        mode: "onSubmit",
      });

    const onSubmit = useCallback(
      (formData: CustomerSettingParameter) => {
        onSubmitProp(formData);
      },
      [onSubmitProp]
    );

    const onError = useCallback<SubmitErrorHandler<CustomerSettingParameter>>(
      (formErrors) => {
        // eslint-disable-next-line no-console
        console.log(formErrors);
      },
      []
    );

    const values: Record<SettingParameterTypeValue, JSX.Element> = {
      [SettingParameterTypeValue.INTEGER]: (
        <>
          <FieldLabel>Valor</FieldLabel>
          <NumberInput
            size="sm"
            thousandSeparator=""
            decimalScale={0}
            placeholder="Digite o valor"
          />
        </>
      ),

      [SettingParameterTypeValue.FLOAT]: (
        <>
          <FieldLabel>Valor</FieldLabel>
          <NumberInput placeholder="Digite o valor" size="sm" />
        </>
      ),

      [SettingParameterTypeValue.PERCENTAGE]: (
        <>
          <FieldLabel>Valor</FieldLabel>
          <NumberInput
            size="sm"
            suffix="%"
            placeholder="Digite o percentual"
            fixedDecimalScale={false}
          />
        </>
      ),
    } as Record<SettingParameterTypeValue, JSX.Element>;

    return (
      <Container size="8" fixed>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <FormDialog
            title={"Editar parâmetro de configuração"}
            negativeButton={
              <Button variant="tertiary" type="reset" onClick={onCloseClick}>
                <Text>Cancelar</Text>
              </Button>
            }
            positiveButton={
              <Button disabled={formState.isSubmitting} type="submit">
                <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
              </Button>
            }
            onClickDismissButton={onCloseClick}
          >
            <DialogBody css={{ p: "$6" }}>
              <Box css={{ mb: "$10" }}>
                <H5>Informações básicas</H5>
              </Box>

              <Row gap="6">
                <Col>
                  <FormControl name="value" control={control}>
                    {data?.typeValue &&
                      data.typeValue in values &&
                      values[data.typeValue]}
                  </FormControl>
                </Col>
              </Row>
            </DialogBody>
          </FormDialog>
        </Form>
      </Container>
    );
  };

CustomerSettingParameterDialog.displayName = "CustomerSettingParameterDialog";
