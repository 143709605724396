import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";

const StyledCounterListItem = styled(Flex, {
  p: "$2 $4",
  gap: "$4",
});

export type CounterListItemProps = ComponentProps<
  typeof StyledCounterListItem
> & {
  title: string;
  description?: string;
};

/**
 * @deprecated this component needs to be refactored.
 */
export const CounterListItem = ({
  title,
  description,
  children,
  ...props
}: CounterListItemProps) => {
  return (
    <StyledCounterListItem {...props}>
      <Flex direction="column" gap="2" css={{ flex: "1 0 0%" }}>
        <Text as="p" size="3" css={{ fw: "700" }}>
          {title}
        </Text>
        {description && (
          <Text size="2" variant="dark">
            {description}
          </Text>
        )}
      </Flex>
      {children}
    </StyledCounterListItem>
  );
};

CounterListItem.displayName = "CounterListItem";
