import { Component, ErrorInfo, Fragment, ReactNode } from "react";
import { AppError } from "~/application/types";
import { BiztripLogo } from "~/components/BiztripLogo";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Link } from "~/components/Link";
import { Text } from "~/components/Text";
import { log } from "~/utils/log";

export interface AppErrorBoundaryProps {
  children: ReactNode;
}

type AppErrorBoundaryState = {
  error: AppError | null;
};

const LOG_TAG = "AppErrorBoundary";

export class AppErrorBoundary extends Component<
  AppErrorBoundaryProps,
  AppErrorBoundaryState
> {
  constructor(props: AppErrorBoundaryProps) {
    super(props);

    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.e(LOG_TAG, error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <Fragment>
          <Container
            size="8"
            css={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Flex gap="6" direction="column" align="center">
              <Text as="h1" size="9" css={{ ta: "center" }}>
                Ops!
              </Text>

              {this.state.error.code ? (
                <Fragment>
                  <Text as="h3" size="7" variant="dark" css={{ ta: "center" }}>
                    {this.state.error.message}
                  </Text>

                  <Text as="h5" size="4" variant="dark" css={{ ta: "center" }}>
                    CÓDIGO: {this.state.error.code}
                  </Text>
                </Fragment>
              ) : (
                <Text as="h3" size="7" variant="dark" css={{ ta: "center" }}>
                  Um erro desconhecido ocorreu, por favor contate o
                  administrador do sistema
                </Text>
              )}

              <Link to="/" onClick={() => this.setState({ error: null })}>
                <Button>
                  <Text>Retornar ao início</Text>
                </Button>
              </Link>
            </Flex>
          </Container>

          <Box
            css={{
              position: "fixed",
              left: "50%",
              bottom: "10%",
              transform: "translate(-50%, 0)",
            }}
          >
            <BiztripLogo />
          </Box>
        </Fragment>
      );
    }

    return this.props.children;
  }
}
