import { Fragment } from "react";
import { Order, OrderItemStatus, OrderStatus } from "~/application/types";
import { OrderHistory } from "~/application/types/entities/OrderHistory.type";
import { Avatar } from "~/components/Avatar";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import {
  DataColItem,
  DataListItem,
  DataListItemProps,
} from "~/components/List";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H6, Label } from "~/components/Typography";
import { OrderStatusTag } from "~/presentation/shared/components/OrderStatusTag";

export interface OrderHistoryListItemProps extends DataListItemProps {
  order?: Order;
  data: OrderHistory;
}

export function OrderHistoryListItem({
  order,
  data,
  ...props
}: OrderHistoryListItemProps) {
  const canShowHistoryDetails = [
    OrderStatus.ON_APPROVAL,
    OrderStatus.APPROVED,
    OrderStatus.REJECTED,
    OrderStatus.ISSUED,
  ].includes(data.status as OrderStatus);

  if (
    data.status !== OrderItemStatus.FAILED &&
    data.description &&
    data.description.toLowerCase().includes("falha")
  ) {
    data.status = OrderItemStatus.FAILED;
  }

  const orderHistoryDetailContent = (status: OrderStatus | OrderItemStatus) => {
    const defaultContent = (
      <Text>Email enviado para {order?.issuer.email}</Text>
    );

    switch (status) {
      case OrderStatus.ON_APPROVAL:
        return (
          <Fragment>
            <Text>Email enviado para:</Text>
            <br />
            <br />
            <Flex direction="column" justify="between">
              {order?.approvalModel?.approvers?.map((approver) => (
                <Text css={{ mt: "$1" }} key={approver.uuid}>
                  {approver.email}
                </Text>
              ))}
            </Flex>
          </Fragment>
        );
      case OrderStatus.APPROVED:
        return defaultContent;
      case OrderStatus.REJECTED: {
        const airwayReasonsRejected = order?.items.airway?.flights
          .filter(({ reasonRejected }) => reasonRejected?.reason)
          .map(({ uuid, reasonRejected }) => ({
            uuid,
            reason: reasonRejected?.reason,
          }));

        const roadReasonsRejected = order?.items.road?.travels
          .filter(({ reasonRejected }) => reasonRejected?.reason)
          .map(({ uuid, reasonRejected }) => ({
            uuid,
            reason: reasonRejected?.reason,
          }));

        const hotelReasonsRejected = order?.items.hotel?.rooms
          .filter(({ reasonRejected }) => reasonRejected?.reason)
          .map(({ uuid, reasonRejected }) => ({
            uuid,
            reason: reasonRejected?.reason,
          }));

        const vehicleReasonsRejected = order?.items.vehicle?.vehicles
          .filter(({ reasonRejected }) => reasonRejected?.reason)
          .map(({ uuid, reasonRejected }) => ({
            uuid,
            reason: reasonRejected?.reason,
          }));

        type ReasonRejected = { uuid: string; reason: string };
        const reasonsRejected = [] as ReasonRejected[];

        if (airwayReasonsRejected) {
          reasonsRejected.concat(airwayReasonsRejected as ReasonRejected[]);
        }

        if (roadReasonsRejected) {
          reasonsRejected.concat(roadReasonsRejected as ReasonRejected[]);
        }

        if (hotelReasonsRejected) {
          reasonsRejected.concat(hotelReasonsRejected as ReasonRejected[]);
        }

        if (vehicleReasonsRejected) {
          reasonsRejected.concat(vehicleReasonsRejected as ReasonRejected[]);
        }

        return (
          <>
            {defaultContent}

            {reasonsRejected && reasonsRejected.length > 0 && (
              <>
                <br />
                <br />
                <Text css={{ mb: "$1" }}>Motivos:</Text>
              </>
            )}

            <Flex direction="column" justify="between">
              {reasonsRejected?.map(({ uuid, reason }) => (
                <Text css={{ mt: "$1" }} key={uuid}>
                  {reason}
                </Text>
              ))}
            </Flex>
          </>
        );
      }
      case OrderStatus.ISSUED:
        return defaultContent;
      default:
        return null;
    }
  };

  const OrderHistoryDetail = (
    <TooltipLabel>
      <H6 css={{ mb: "$4" }}>Detalhes</H6>
      {orderHistoryDetailContent(data.status)}
    </TooltipLabel>
  );

  return (
    <DataListItem
      {...props}
      css={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        mb: "$6",
        "@mxlg": {
          flexDirection: "column",
        },
      }}
    >
      <Col
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <Flex align="center" justify="between">
          <DataColItem
            data={
              <Flex align="center" gap="3">
                <Avatar
                  src={data.responsible.avatarUrl}
                  css={{
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                />
                <H6
                  css={{
                    "@mxlg": {
                      fontSize: "14px",
                    },
                  }}
                >
                  {data.responsible.name}
                </H6>
              </Flex>
            }
          />

          <DataColItem
            css={{
              "@mxlg": {
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              },
            }}
            data={
              <Flex
                justify="start"
                align="end"
                css={{
                  mb: "$2",
                  height: "100%",
                }}
              >
                <Label size={{ "@mxmd": "2" }} css={{ ta: "end" }}>
                  {data.description}
                </Label>
              </Flex>
            }
          />
        </Flex>
      </Col>

      <Col
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <Flex>
          <DataColItem
            data={
              <Flex
                justify="start"
                align="end"
                css={{
                  mb: "$2",
                  height: "100%",
                  "@mxlg": {
                    mb: "0",
                    mt: "$2",
                  },
                }}
              >
                <Label
                  css={{
                    "@mxlg": {
                      textAlign: "start",
                    },
                  }}
                  size={{ "@mxmd": "2" }}
                >
                  {data.hour}
                </Label>
              </Flex>
            }
          />

          <DataColItem
            css={{
              "@mxlg": {
                display: "flex",
                alignItems: "end",
              },
            }}
            data={
              <Flex>
                <OrderStatusTag
                  css={{
                    "@mxlg": {
                      mb: "$2",
                    },
                  }}
                  data={data.status}
                />
              </Flex>
            }
          />

          <DataColItem
            css={{
              flex: 0,
              visibility: canShowHistoryDetails ? "visible" : "hidden",
            }}
            data={
              <Tooltip content={OrderHistoryDetail}>
                <IconButton size="md">
                  <Icon as={SvgInfo} />
                </IconButton>
              </Tooltip>
            }
          />
        </Flex>
      </Col>
    </DataListItem>
  );
}
