import { api } from "~/infrastructure/api";
import {
  ImportCustomerEmployeeProps,
  ImportCustomerEmployeeServiceInterface,
} from "./ImportCustomerEmployeeServiceInterface";

export class ImportCustomerEmployeeService
  implements ImportCustomerEmployeeServiceInterface
{
  async import({
    customerId,
    file,
  }: ImportCustomerEmployeeProps): Promise<void> {
    const formData = new FormData();

    formData.append("customer_employees", file);

    await api.post(
      `/customers/${customerId}/customer-employees/imports`,
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  }
}
