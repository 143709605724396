import queryString from "query-string";
import type {
  CostCenter,
  ExpenseCategory,
  ExpenseType,
  PaginatedResource,
} from "~/application/types";
import { api } from "~/infrastructure/api";
import { CostCenterDTO, ExpenseTypeDTO } from "~/infrastructure/api/dtos";
import {
  mapCostCenterDTO,
  mapExpenseTypeDTO,
} from "~/infrastructure/api/mappers";
import type {
  IExpenseTypeService,
  IFindByExpenseCategoryParams,
  IFindExpenseCategoryTypeParams,
  IFindExpenseTypeParams,
} from "./IExpenseTypeService";

export class ExpenseTypeService implements IExpenseTypeService {
  async findByExpenseCategory(
    expenseCategory: ExpenseCategory,
    data: IFindByExpenseCategoryParams
  ): Promise<PaginatedResource<ExpenseType[]>> {
    const url = queryString.stringifyUrl({
      url: `/expense-categories/${expenseCategory.uuid}/expense-types`,
      query: {
        page: data.page,
        active: data.active,
        customer_uuid: data.customerId,
      },
    });

    return await api
      .get<PaginatedResource<ExpenseTypeDTO[]>>(url)
      .then(({ data }) => ({
        links: data.links,
        meta: data.meta,
        data: data.data.map(mapExpenseTypeDTO),
      }));
  }

  async findCostCenter(customerId: string): Promise<CostCenter[]> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/cost-centers`,
    });

    return await api
      .get<PaginatedResource<CostCenterDTO[]>>(url)
      .then(({ data: response }) => response.data.map(mapCostCenterDTO));
  }

  async find({
    customerId,
    active,
    page,
  }: IFindExpenseTypeParams): Promise<PaginatedResource<ExpenseType[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/expense-types`,
      query: {
        page: page,
        active: active,
      },
    });

    return await api
      .get<PaginatedResource<ExpenseTypeDTO[]>>(url)
      .then(({ data }) => ({
        links: data.links,
        meta: data.meta,
        data: data.data.map(mapExpenseTypeDTO),
      }));
  }

  async findByCategory({
    expenseCategoryId,
    customerId,
  }: IFindExpenseCategoryTypeParams): Promise<
    PaginatedResource<ExpenseType[]>
  > {
    const url = queryString.stringifyUrl({
      url: `/expense-categories/${expenseCategoryId}/expense-types`,
      query: {
        customer_uuid: customerId,
      },
    });

    return await api
      .get<PaginatedResource<ExpenseTypeDTO[]>>(url)
      .then(({ data }) => ({
        links: data.links,
        meta: data.meta,
        data: data.data.map(mapExpenseTypeDTO),
      }));
  }

  async create({
    customerId,
    expenseCategory,
    name,
  }: Omit<ExpenseType, "uuid">): Promise<ExpenseType> {
    return await api
      .post<ExpenseTypeDTO>(`/customers/${customerId}/expense-types`, {
        name: name,
        expense_category_uuid: expenseCategory.uuid,
      })
      .then(({ data }) => mapExpenseTypeDTO(data));
  }

  async updateById(data: ExpenseType): Promise<ExpenseType> {
    return await api
      .put<ExpenseTypeDTO>(`/customers/expense-types/${data.uuid}`, {
        name: data.name,
        expense_category_uuid: data.expenseCategory.uuid,
        active: data.isActive,
      })
      .then(({ data }) => mapExpenseTypeDTO(data));
  }

  async toggleActive(data: ExpenseType): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/customers/expense-types/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/customers/expense-types/${data.uuid}/active`)
      .then(({ data }) => data);
  }
}
