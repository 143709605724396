import { Branch } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { BranchListItem } from "./components/BranchListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface TabBranchesProps {
  data?: Branch[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  searchText: string;
  onGoToPage: (value: number) => void;
  setSearchText: (search: string) => void;
  onCreateBranch: () => void;
  onEditBranch: (item: Branch) => void;
  onToggleBranchState: (item: Branch) => void;
}

export function TabBranches({
  data,
  currentPage,
  lastPage,
  searchText,
  onGoToPage,
  setSearchText,
  onCreateBranch,
  onEditBranch,
  onToggleBranchState,
}: TabBranchesProps) {
  const isMobile = useMobile();

  const listRenderer = useCallback(
    (item: Branch) => (
      <BranchListItem
        data={item}
        onEditClick={onEditBranch}
        onToggleState={onToggleBranchState}
        key={item.uuid}
      />
    ),
    [onEditBranch, onToggleBranchState]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Filiais</H4>
          </Col>

          <Col
            sz="auto"
            css={{
              "@mxlg": {
                margin: "$5 auto",
                width: "100%",
              },
            }}
          >
            <SearchBar
              style={isMobile ? { width: "100%" } : undefined}
              search={searchText}
              onSearchChange={setSearchText}
              placeholder="Encontrar"
            />
          </Col>

          <Col
            sz={{ "@lg": "auto" }}
            css={{
              "@mxlg": {
                width: "100%",
              },
            }}
          >
            <Button
              title="Nova filial"
              color="primary"
              onClick={onCreateBranch}
              css={{ "@mxlg": { width: "100%" } }}
            >
              <Icon as={SvgPlus} />
              <Text>Nova filial</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhuma filial corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui filiais cadastradas</Text>
              )}
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
