import { Actions, Project } from "~/application/types";
import { Button } from "~/components/Button";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { useCallback } from "react";
import { ProjectListItem } from "~/core/modules/Customer/pages/CustomerPage/views/Projects/components/ProjectListItem";
import { Flex } from "~/components/Flex";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { SearchBar } from "~/components/Input";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";

export interface ProjectsContainerProps {
  data?: Project[];
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  onCreateProject: () => void;
  onToggleActive: (item: Project) => void;
  onUpdateProject: (item: Project) => void;
  onGoToPage: (value: number) => void;
  onSearch: (value: string) => void;
}

export function ProjectsContainer({
  data,
  currentPage,
  lastPage,
  isLoading,
  onCreateProject,
  onGoToPage,
  onToggleActive,
  onUpdateProject,
  onSearch,
}: ProjectsContainerProps) {
  const { contexts, profile } = useUser();
  const listRenderer = useCallback(
    (item: Project) => (
      <ProjectListItem
        data={item}
        onEditClick={onUpdateProject}
        onToggleState={onToggleActive}
        key={item.uuid}
      />
    ),
    [onUpdateProject, onToggleActive]
  );

  const canCreateProject = useVerifyActions({
    actions: [Actions.CreateUpdateProject],
    contexts,
    profile,
  });

  return (
    <Flex direction="column" gap="4">
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Projetos" />

          <AppBarActions>
            <Flex gap="4">
              <SearchBar placeholder="Buscar projeto" onSearchChange={(name) => onSearch(name)} />
              {canCreateProject && (
                <Button title="Novo Projeto" color="primary" onClick={onCreateProject}>
                  <Icon as={SvgPlus} />
                  <Text>Novo projeto</Text>
                </Button>
              )}
            </Flex>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não tipos de despesas cadastrados</Text>
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Flex>
  );
}
