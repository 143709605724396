export enum QueryKeys {
  USER = "user",
  CRMs = "CRM's",
  DASHBOARDBI = "dashboardBI",
  AIRLINES = "airlines",
  CUSTOMERS = "agency/customers",
  AGENCIES = "agencies",
  CREDENTIALS = "credentials",
  CARDS = "cards",
  VEHICLE_TRAVELERS = "vehicle/vehicle-travelers",
  HOTEL_TRAVELERS = "hotel/hotel-travelers",
  AIRWAY_TRAVELERS = "airway/airway-travelers",
  ROAD_TRAVELERS = "road/road-travelers",
  CARD_FLAGS = "card-flags",
  AGREEMENTS = "agreements",
  ORDER_MESSAGES = "order/messages",
  PROVIDERS = "providers",
  ORDER_HISTORY = "order/history",
  APPROVERS = "aprovers",
  AGENCY = "agency",
  CUSTOMER = "customer",
  EXPENSE_CATEGORIES = "expense-categories",
  INTERVENTIONS = "dashboardBI/interventions",
  LOGOUT_CUSTOMERS = "dashboardBI/logoutCustomers",
  NEW_CLIENTS_PURCHASE_MONTH = "dashboardBI/newClientsPurchaseMonth",
  NOT_BUYING_CUSTOMERS = "dashboardBI/notBuyingCustomers",
  PENDING_FIRST_PURCHASE = "dashboardBI/pendingFirstPurchase",
  ORDERS_NOT_ISSUED_WEEK = "dashboardBI/ordersNotIssuedWeek",
  POLICY_PARAMETER_EXPENSES = "policy-parameter-expenses",
  AGENCY_EMPLOYEES = "agency/agency-employees",
  AGENCY_FEES = "agency/agency-fees",
  AGENCY_MARKDOWNS = "agency/markdowns",
  AGENCY_MARKUPS = "agency/markups",
  CUSTOMER_JUSTIFICATIONS = "customer/customer-justifications",
  CUSTOMER_CREDENTIAL = "customer/credentials",
  CUSTOMER_REASON_TRIPS = "customer/customer-reason-trips",
  CUSTOMER_COST_CENTERS = "customer/customer-cost-centers",
  CUSTOMER_EXPENSE_TYPES = "customer/customer-expense-types",
  CUSTOMER_EMPLOYEES = "customer/customer-employees",
  CUSTOMER_POLICIES = "customer/policies",
  CUSTOMER_EXPENSE_POLICIES = "customer/expense-policies",
  CUSTOMER_BRANCHES = "customer/branches",
  CUSTOMER_APPROVAL_MODELS = "customer/approval-models",
  STATE_CITIES = "state/cities",
  CUSTOMER_ORDERS = "customer/orders",
  AGENCY_PENDING_ORDERS = "agency/pending-orders",
  ORDERS = "orders",
  ORDER_ACCOUNTABILITY_EXPENSES = "order/accountability-expense",
  ORDER_HOTEL = "order/hotel",
  ORDER_AIRWAY = "order/airway",
  ORDER_VEHICLE = "order/vehicle",
  ORDER_ROAD = "order/road",
  ORDER_ADVANCE = "order/advance",
  ORDER_EXPENSE = "order/expense",
  QUERY_ROAD = "query/road",
  QUERY_HOTEL = "query/hotel",
  QUERY_FLIGHTS = "query/flights",
  HOTEL = "booking/hotels",
  QUERY_HOTEL_DETAILS = "query/hotel-details",
  QUERY_VEHICLE = "query/vehicle",
  HOTEL_DETAILS = "booking/hotel/details",
  QUERY_HOTEL_ROOM_RULES = "query/hotel-rules",
  HOTEL_ROOM_RULES = "booking/hotel/rules",
  ROADS_GO = "booking/roads/go",
  SUMMARY = "booking/summary/",
  ROADS_RETURN = "booking/roads/return",
  QUERY_LINKS_FLIGHTS = "query-links/flights",
  QUERY_FLIGHTS_GO = "query/flights/departure",
  QUERY_FLIGHTS_RETURN = "query/flights/arrival",
  CUSTOMER_SETTING_PARAMETERS = "customer/setting-parameters",
  AGENCY_SETTING_PARAMETERS = "agency/setting-parameters",
  SETTING_PARAMETERS = "setting-parameters",
  MARKUP_PER_SUPLIER = "markup-per-suplier",
  REORDER = "reorder",
  PROJECTS = "customer/projects",
  PHASES = "customer/projects/phases",
  BUDGETS = "customer/budgets",
  BUDGETS_VIEW = "customer/budgets/view",
  QUERY_VEHICLE_DETAILS = "query/vehicle-details",
  QUERY_VEHICLE_LINKS = "query/vehicle-links",
  HOTEL_OFF_PAYMENT_METHOD = "hotel-offline-payment-mathod",
  PAYMENT_METHODS = "payment-methods",
  OFFLINE_HOTEL = "offline-hotel",
  OFFLINE_HOTELS = "offline-hotels",
  OFFLINE_HOTEL_ROOM = "offline-hotel-room",
  OFFLINE_HOTEL_ROOMS = "offline-hotel-rooms",
  AMENITIES = "amenities",
}
