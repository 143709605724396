import { useCallback, useEffect, useMemo, useState } from "react";
import { set, useForm } from "react-hook-form";
import {
  Markup,
  MarkupPerSuplier,
  OrderItems,
  Provider,
} from "~/application/types";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FormControl } from "~/components/FormControl";
import { Icon } from "~/components/Icon";
import {
  SvgCheck,
  SvgChevronDown,
  SvgChevronUp,
  SvgEdit,
  SvgPlus,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch, NumberInput, Select } from "~/components/Input";
import {
  DataColItem,
  DataListItem,
  DataListItemProps,
} from "~/components/List/DataListItem";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import { getServiceLabel } from "~/presentation/shared/utils";
import { ItemsProvider, MarkupForm, ProviderForm } from "../MarkupsPage";
import { UseQueryResult } from "@tanstack/react-query";
import { Spinner } from "~/components/Spinner";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface MarkupListItemProps
  extends Omit<DataListItemProps, "size" | "css"> {
  data: Markup;
  providersList: ProviderForm[];
  onEditClick: (item: Markup) => void;
  onToggleState: (item: Markup) => void;
  submit: (item: MarkupForm) => Promise<void>;
  useMarkupsPerSuplier: (
    markupId: string
  ) => UseQueryResult<MarkupPerSuplier[], unknown>;
}

export function MarkupListItem({
  data,
  providersList,
  onEditClick,
  onToggleState,
  submit,
  useMarkupsPerSuplier,
  ...props
}: MarkupListItemProps) {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const {
    data: markupPerSuplier,
    refetch,
    isFetching,
  } = useMarkupsPerSuplier(data.uuid);
  const isMobile = useMobile();

  const itemsDefault = [
    OrderItems.AIRWAY,
    OrderItems.HOTEL,
    OrderItems.ROAD,
    OrderItems.VEHICLE,
  ] as ItemsProvider[];

  const { control, handleSubmit, watch, setValue, formState } =
    useForm<MarkupForm>({
      defaultValues: { markup: data, markupPerSuplier, isEditing: false },
      reValidateMode: "onBlur",
      mode: "onSubmit",
    });
  const { providersListFiltred, provider } = watch();

  useEffect(() => {
    const newProvidersListFiltred = providersList?.filter(({ provider }) =>
      markupPerSuplier?.some((markup) => {
        return provider.uuid === markup.uuid;
      })
    );

    const providersListFiltredWithItems = newProvidersListFiltred?.map(
      (provider) => {
        const markup = markupPerSuplier?.find(
          (markup) => markup.uuid === provider.provider.uuid
        );

        const items = itemsDefault?.reduce((acc, item) => {
          const value = markup?.services.find(
            (providerService) => providerService.serviceType === item
          )?.percentValue;

          return {
            ...acc,
            [item]: value || 0,
          };
        }, {});

        return {
          ...provider,
          items,
        } as ProviderForm;
      }
    );
    if (providersListFiltred?.length !== providersListFiltredWithItems?.length)
      setValue("providersListFiltred", providersListFiltredWithItems);
  }, [providersList, markupPerSuplier]);

  const hasItemProvider = (item: ItemsProvider, provider: Provider) => {
    return provider.providerServices.some(
      (providerService) => providerService.serviceType === item
    );
  };

  const newProvidersList = useMemo(() => {
    return providersList?.filter(({ provider }) => {
      return !providersListFiltred?.some(
        (providerFiltred) => providerFiltred.provider.uuid === provider.uuid
      );
    });
  }, [providersList, providersListFiltred, providersListFiltred]);

  const { dirtyFields } = formState;

  const getProvidersLabel = useCallback(
    ({ provider }: ProviderForm) => provider?.name,
    []
  );
  const getProvidersValue = useCallback(
    ({ provider }: ProviderForm) => provider?.uuid,
    []
  );

  return (
    <DataListItem
      size="sm"
      data-active={data.isActive}
      {...props}
      css={{
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      <Flex
        align="center"
        direction={{ "@mxlg": "column" }}
        gap="6"
        css={{
          width: "100%",
        }}
      >
        <DataColItem headerTitle="Título" data={data.name} />
        <DataColItem
          headerTitle="Itens do pedido"
          data={
            <Flex align={{ "@initial": "center", "@mxlg": "start" }} gap="2" direction={{ "@mxlg": "column" }}>
              {data.items.map((item) => (
                <Flex align="center" gap="2" key={item.uuid}>
                  <IconButton size="md">
                    <Icon as={OrderUtils.getServiceIcon(item.serviceType)} />
                  </IconButton>
                  <Text css={{ fw: "600" }}>{`${item.range}%`}</Text>
                </Flex>
              ))}
            </Flex>
          }
        />
        {!isMobile && (
          <>
            <Tooltip content={<TooltipLabel>Editar markup</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditClick(data)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
        <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
          <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
        </Switch>
          </>
        )}

        {isMobile && (
          <Flex justify="between" css={{ width: "100%" }}>
            <Tooltip content={<TooltipLabel>Editar markup</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditClick(data)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
        <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
          <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
        </Switch>
          </Flex>
        )}
        <IconButton
          size="md"
          onClick={() => {
            if (!isOpened)
              refetch().then(() => {
                setValue("provider", undefined);
              });
            setIsOpened(!isOpened);
          }}
        >
          <Icon as={isOpened ? SvgChevronDown : SvgChevronUp} />
        </IconButton>
      </Flex>
      {isOpened && (
        <>
          {isFetching ? (
            <Flex
              css={{
                width: "100%",
              }}
              justify="center"
              align="center"
            >
              <Spinner />
            </Flex>
          ) : (
            <Flex
              css={{
                width: "100%",
              }}
              justify="between"
              direction="column"
            >
              <Form
                onSubmit={handleSubmit((data) => {
                  submit(data);
                  setTimeout(() => {
                    refetch().then(() => {
                      setValue("provider", undefined);
                    });
                  }, 500);
                })}
              >
                <Flex
                  css={{
                    width: "100%",
                  }}
                  direction="column"
                  justify="between"
                  align="end"
                  gap="3"
                >
                  <Flex
                    css={{
                      width: "100%",
                    }}
                    justify="between"
                    direction={{ "@mxlg": "column" }}
                    gap="3"
                    align="end"
                  >
                    <Flex
                      css={{
                        width: "100%",
                        // maxWidth: "250px",
                        "@mxlg": {
                          maxWidth: "100%",
                        }
                      }}
                    >
                      <FormControl css={{ "@mxlg": { width: "100%" }}} control={control} name={`provider`}>
                        <Flex
                          direction="column"
                          gap="2"
                          css={{
                            width: "100%",
                          }}
                        >
                          <Text size="2" fw="500" variant="dark">
                            Fornecedores{" "}
                          </Text>
                          <Select
                            size="xs"
                            portalled
                            isLoading={!newProvidersList}
                            placeholder="Selecione um fronecedor"
                            options={newProvidersList}
                            getOptionLabel={getProvidersLabel}
                            getOptionValue={getProvidersValue}
                          />
                        </Flex>
                      </FormControl>
                    </Flex>
                    <Flex gap="2" direction={{ "@mxlg": "column" }} css={{ "@mxlg": { width: "100%" }}} align={{ "@mxlg": "start" }}>
                      {provider &&
                        itemsDefault.map((item, index) => (
                          <FormControl
                            control={control}
                            name={`provider.items.${item}`}
                            css={{ "@mxlg": { width: "100%" }}}
                            key={index}
                          >
                            <Flex direction="column" gap="2" css={{ "@mxlg": { width: "100%" }}}>
                              <Text
                                size="2"
                                fw="500"
                                css={{
                                  color: "$neutrals-dark",
                                }}
                              >
                                {getServiceLabel(item)}
                              </Text>
                              <NumberInput
                                defaultValue={0}
                                suffix="%"
                                fixedDecimalScale={false}
                                disabled={
                                  !hasItemProvider(item, provider.provider)
                                }
                                style={{
                                  width: !isMobile ? "80px" : "100%",
                                  height: "40px",
                                }}
                              />
                            </Flex>
                          </FormControl>
                        ))}
                    </Flex>
                    <Button
                      disabled={
                        !provider ||
                        provider?.provider.providerServices.length === 0 ||
                        !dirtyFields.provider?.items
                      }
                      size="sm"
                      type="submit"
                      css={{
                        px: "25px",
                      }}
                    >
                      <Icon as={SvgPlus} />
                      <Text size="2" fw="500" variant="white">
                        Adicionar
                      </Text>
                    </Button>
                  </Flex>
                  <Flex
                    css={{
                      width: "100%",
                    }}
                    direction="column"
                    gap="4"
                  >
                    {providersListFiltred?.map(
                      ({ provider }, indexProvider) => (
                        <Flex
                          key={provider.uuid}
                          css={{
                            width: "100%",
                          }}
                          gap="2"
                          justify="between"
                          direction={{ "@mxlg": "column" }}
                          align={{ "@initial": "center", "@mxlg": "start" }}
                          
                        >
                          <Flex
                            direction="column"
                            gap="2"
                            css={{
                              width: "100%",
                              maxWidth: "250px",
                            }}
                          >
                            <Text
                              size="2"
                              fw="500"
                              css={{
                                color: "$neutrals-dark",
                              }}
                            >
                              Fornecedor
                            </Text>
                            <Text size="3" fw="600" variant="black">
                              {provider.name}
                            </Text>
                          </Flex>
                          <Flex gap="2" direction={{ "@mxlg": "column" }} css={{ "@mxlg": { width: "100%" }}}>
                            {itemsDefault.map((item, index) => (
                              <FormControl
                                control={control}
                                name={`providersListFiltred.${indexProvider}.items.${item}`}
                                key={index}
                              >
                                <Flex direction="column" gap="2">
                                  <Text
                                    size="2"
                                    fw="500"
                                    css={{
                                      color: "$neutrals-dark",
                                    }}
                                  >
                                    {getServiceLabel(item)}
                                  </Text>
                                  <NumberInput
                                    suffix="%"
                                    fixedDecimalScale={false}
                                    
                                    disabled={!hasItemProvider(item, provider)}
                                    style={{
                                      width: !isMobile ? "80px" : "100%",
                                      height: "40px",
                                    }}
                                  />
                                </Flex>
                              </FormControl>
                            ))}
                          </Flex>
                          <Button
                            size="sm"
                            css={{
                              px: "$3",
                              visibility: dirtyFields.providersListFiltred?.[
                                indexProvider
                              ]
                                ? "visible"
                                : "hidden",
                            }}
                            variant="secondary"
                            onClick={() => {
                              submit({
                                markup: data,
                                provider: providersListFiltred?.[indexProvider],
                                isEditing: true,
                              });
                            }}
                          >
                            <Icon as={SvgCheck} />
                            <Text size="1" fw="500" variant="white">
                              Salvar alterações
                            </Text>
                          </Button>
                        </Flex>
                      )
                    )}
                  </Flex>
                </Flex>
              </Form>
            </Flex>
          )}
        </>
      )}
    </DataListItem>
  );
}
