import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type CartHeaderProps = ComponentProps<typeof CartHeader>;

export const CartHeader = styled("header", {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  padding: "$4 $6",
  backgroundColor: "$primary-dark",
  gap: "$2",
  fontWeight: 600,
  fontSize: "$md",
  color: "$neutrals-white",
  fill: "$neutrals-white",
  variants: {
    variant: {
      lightest: {
        background: "$neutrals-lightest",
        color: "$neutrals-black",
      },
    },
  },
});
