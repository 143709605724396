import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type DropdownMenuIndicatorProps = ComponentProps<typeof DropdownMenuIndicator>;

export const DropdownMenuIndicator = styled("span", {
  // Reset
  boxSizing: "border-box",

  // Custom
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

DropdownMenuIndicator.displayName = "DropdownMenuIndicator";
