import { AccountabilityExpense } from "~/application/types";
import { parseTravelerAdvanceDTO } from "~/application/usecases/OrderAdvance/OrderAdvanceService";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { AccountabilityExpenseDTO } from "../dtos";

export function mapAccountabilityExpenseDTO(
  data: AccountabilityExpenseDTO
): AccountabilityExpense {
  return {
    uuid: data.uuid,
    expenseType: {
      uuid: data.expense_type.uuid,
      name: data.expense_type.name,
    },
    expenseCategory: {
      uuid: data.expense_type.expense_category.uuid,
      name: data.expense_type.expense_category.name,
      icon: data.expense_type?.expense_category?.icon,
    },
    expenseDate: DateUtils.format(data.expense_date, DateFormats.SMALL_DATE),
    voucherPath: data.voucher_path,
    company: data.company,
    value: data.value,
    justification: data.justification,
    advanceOrder: data.advance_order
      ? parseTravelerAdvanceDTO(data.advance_order)
      : undefined,
    reasonRejected: data.reason_rejected,
    unsatisfiedPolicies:
      data.unsatisfied_policies?.map((policy) => ({
        uuid: data.uuid,
        policyParameter: policy.policy_parameter,
        typeValue: policy.type_value,
        value: policy.value,
        voucherRequired: policy.voucher_required,
      })) || [],
  };
}
