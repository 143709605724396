import { ExpenseCategory } from "~/application/types";
import { ExpenseCategoryDTO } from "../dtos";

export function mapExpenseCategoryDTO(
  data: ExpenseCategoryDTO
): ExpenseCategory {
  return {
    uuid: data.uuid,
    name: data.name,
    isActive: data.active,
    icon: data.icon,
  };
}
