import * as React from "react";
import { SVGProps } from "react";

const SvgApproveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.226 12.669-.003.007-.003.007a.548.548 0 0 1-.249.28.905.905 0 0 1-.471.127H2c-.383 0-.695-.13-.97-.405a1.302 1.302 0 0 1-.405-.97v-5c0-.13.021-.233.055-.318a.707.707 0 0 1 .162-.24l.01-.01.008-.009 4.159-4.52.264.264a.389.389 0 0 1 .1.155.29.29 0 0 1 .004.155l-.001.007-.001.007-.6 3.4-.13.734H10c.126 0 .202.036.27.104a.345.345 0 0 1 .105.27v.8a.788.788 0 0 1-.053.262L8.226 12.67ZM13.774 9.761l.004-.007.002-.007a.548.548 0 0 1 .249-.28.905.905 0 0 1 .471-.127H20c.383 0 .695.129.97.404.276.276.405.588.405.97v5c0 .13-.021.234-.055.319a.709.709 0 0 1-.162.24l-.01.01-.008.009-4.159 4.52-.264-.264a.39.39 0 0 1-.1-.156.29.29 0 0 1-.004-.155l.001-.006.002-.008.6-3.4.129-.733H12a.345.345 0 0 1-.27-.105.346.346 0 0 1-.105-.27v-.8a.791.791 0 0 1 .053-.262l2.097-4.892Z"
      stroke="#475A6B"
      strokeWidth={1.25}
    />
  </svg>
);

export default SvgApproveIcon;
