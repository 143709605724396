import type { DeepPartial, OrderRoadItem, Provider } from "~/application/types";
import { RoadIssuanceFormData } from "./types";

export type CreateFormDataProps = {
  data: OrderRoadItem;
  providers?: Provider[];
};

export function createFormData({
  data,
  providers,
}: CreateFormDataProps): DeepPartial<RoadIssuanceFormData> {
  const { uuid, travelers, tracker, providerUnitFee, fare, provider } = data;
  
  return {
    issuance: {
      uuid,
      tracker,
      travelers: travelers.map((traveler) => ({
        uuid: traveler.uuid,
        providerFees: providerUnitFee,
        value: fare,
        provider: {
          name: provider,
          uuid: providers?.filter(({ name }) => name === provider).at(0)?.uuid,
        },
        seats: traveler.seats.map((seat) => ({
          ticket: seat.ticket,
          seatNumber: seat.seatNumber,
          bpe: seat.bpe,
          isConnection: seat.isConnection,
          seatType: seat.seatType,
        })),
      })),
    },
  };
}

export function parseFormData({ issuance }: RoadIssuanceFormData) {
  return {
    uuid: issuance.uuid,
    tracker: issuance.tracker,
    travelers: issuance.travelers.map((traveler) => ({
      uuid: traveler.uuid,
      value: traveler.value,
      providerId: traveler.provider.uuid,
      providerFees: traveler.providerFees,
      seats: traveler.seats.map((seat) => ({
        ticket: seat.ticket,
        seatNumber: seat.seatNumber,
        bpe: seat.bpe,
        isConnection: seat.isConnection,
        seatType: seat.seatType,
      })),
    })),
  };
}
