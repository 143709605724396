import * as React from "react";
import { SVGProps } from "react";

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M8.625 1.25A1.876 1.876 0 0 0 6.75 3.125v.625H3.625A.625.625 0 0 0 3 4.375.625.625 0 0 0 3.625 5h.625v11.25A1.252 1.252 0 0 0 5.5 17.5h10a1.253 1.253 0 0 0 1.25-1.25V5h.625A.625.625 0 0 0 18 4.375a.625.625 0 0 0-.625-.625H14.25v-.625a1.876 1.876 0 0 0-1.875-1.875Zm0 1.25h3.75a.624.624 0 0 1 .625.625v.625H8v-.625a.623.623 0 0 1 .625-.625ZM5.5 5h10v11.25h-10Zm3.125 2.5A.625.625 0 0 0 8 8.125v5a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625v-5a.625.625 0 0 0-.625-.625Zm3.75 0a.625.625 0 0 0-.625.625v5a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625v-5a.625.625 0 0 0-.625-.625Z"
    />
  </svg>
);

export default SvgTrash;
