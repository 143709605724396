import React, { useCallback, useMemo, useState } from "react";
import { BaseEditor, Descendant } from "slate";
import { createEditor } from "slate";
import { Slate, Editable, withReact, ReactEditor, RenderElementProps, RenderLeafProps } from "slate-react";
import { withHistory } from "slate-history";
import { Element, Leaf } from ".";
import { Toolbar } from "./Toolbar";
import { MarkButton } from "./MarkButton";
import { htmlToSlate, SlateNode } from "~/utils/slateValue.util";
import { BlockButton } from "./BlockButton";


export type CustomEditor = BaseEditor & ReactEditor;

interface SlateEditorProps {
  value: Descendant[];
  onChangeText: React.Dispatch<React.SetStateAction<SlateNode[]>>
}

function SlateEditor({ value, onChangeText }: SlateEditorProps): JSX.Element {

  const editor = useMemo(
    () => withHistory(withReact(createEditor() as CustomEditor)),
    []
  );

  
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

  return (
    <div>
      <Slate editor={editor} initialValue={value} onChange={onChangeText}>
        <div>
          <Toolbar>
            <MarkButton format="italic" icon="format_italic" />
            <BlockButton format="heading-one" icon="heading-one" />
            <BlockButton format="heading-two" icon="heading-two" />
            <BlockButton format="bulleted-list" icon="bulleted_list" />
            <BlockButton format="numbered-list" icon="numbered_list" />
            <MarkButton format="bold" icon="format_bold" />
          </Toolbar>
          <Editable
            style={{ border: "1px solid #c2ccd6", height: value ? "auto" : "200px", marginTop: "12px", padding: "10px" }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </div>
      </Slate>
    </div>
  );
}

export default SlateEditor;
