import React from "react";
import { Text, TextProps } from "../Text";

export interface TooltipLabelProps extends TextProps {
  children: React.ReactElement;
}

export const TooltipLabel = ({ children, css }: TextProps) => (
  <Text as="p" size="2" css={{ fw: "500", ...css }}>
    {children}
  </Text>
);

TooltipLabel.displayName = "TooltipLabel";
