import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Customer, State } from "~/application/types";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { customerService, stateService } from "~/application/usecases";
import { log } from "~/utils/log";
import { CreateCustomerContainer } from "./CreateCustomerContainer";
import { logError } from "~/presentation/shared/utils/errors";
import { editCustomer } from "../../utils/form";
import { PATTERN_CEP, PATTERN_CNPJ } from "~/utils/mask.utils";

const LOG_TAG = "Customer/CreateCustomerPage";

type ErrorType = { statusCode: number; message: string };

const SNACKBAR_MESSAGES = {
  CREATE_SUCCESS_MESSAGE: "Empresa criada",
  CREATE_ERROR_MESSAGE: "Falha ao criar empresa",
} as const;

const DEFAULT_FORM_DATA = {
  companyName: "",
  tradingName: "",
  cnpj: "",
  phone: "",
  zipCode: "",
  wintourCode: "",
  address: "",
  number: "",
  district: "",
  stateRegistration: "",
  email: "",
  dashboardLink: "",
  domains: [{ domain: "" }],
} as Customer;

const updateCustomerSchema = yup.object().shape({
  cnpj: yup.string().matches(PATTERN_CNPJ, "CNPJ inválido").required("O CNPJ é obrigatório"),
  stateRegistration: yup.string().nullable(),
  companyName: yup.string().required("A razão social é obrigatória"),
  wintourCode: yup.string().nullable(),
  tradingName: yup.string().required("O nome fantasia é obrigatório"),
  dashboardLink: yup.string().url("Link inválido").nullable(),
  email: yup.string().required("O e-mail é obrigatório").email("E-mail inválido"),
  phone: yup.string().min(18, "Telefone inválido").required("O telefone é obrigatório"),
  zipCode: yup.string().matches(PATTERN_CEP, "CEP inválido").required("CEP é obrigatório"),
  address: yup.string().required("O logradouro é obrigatório"),
  number: yup.string().required("O número é obrigatório"),
  district: yup.string().required("O bairro é obrigatório"),
  state: yup.object().shape({
    name: yup.string().required("O estado é obrigatório"),
    state: yup.string().required(),
  }),
  city: yup.object().shape({
    name: yup.string().required("A cidade é obrigatória"),
  }),
  domains: yup.array().of(
    yup.object().shape({
      domain: yup.string(),
    })
  ),
});

export type UpdateCustomerSchema = yup.InferType<typeof updateCustomerSchema>;

export function CreateCustomerPage() {
  const { user } = useUser();
  const navigate = useNavigate();

  function customerHookForm(defaultData?: Customer) {
    return useForm<UpdateCustomerSchema>({
      defaultValues: defaultData && editCustomer(defaultData),
      resolver: yupResolver(updateCustomerSchema),
    });
  }

  const fetchCitiesByState = useCallback(
    (state: State) =>
      stateService
        .findCities(state)
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const { mutate: handleCreateCustomer, isLoading: isCreatingCustomer } = useMutation(
    (item: Customer) => {
      return customerService.create({
        ...item,
        agency: { ...item.agency, uuid: user.agency.uuid },
      });
    },
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Customer(${item.uuid})`);
      },
      onSuccess: (data) => {
        log.i(LOG_TAG, `Successfully created Customer(${data.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMERS]);

        navigate(`/configuracoes/empresas/${data.uuid}`);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error: ErrorType) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
    }
  );

  return (
    <CreateCustomerContainer
      defaultData={DEFAULT_FORM_DATA}
      isCreatingCustomer={isCreatingCustomer}
      customerHookForm={customerHookForm}
      onCreateCustomer={handleCreateCustomer}
      fetchCitiesByState={fetchCitiesByState}
    />
  );
}
