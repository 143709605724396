import queryString from "query-string";
import { PaginatedResource, AccessReport } from "~/application/types";
import { api } from "~/infrastructure/api";
import { ReportsDTO } from "~/infrastructure/api/dtos/ReportsDTO";
import { mapReportsDTO } from "~/infrastructure/api/mappers/mapReportsDTO";
import { DateFormats, displayDate, toDate } from "~/utils/date.utils";
import { IReportService } from "./IReportsServices";
import { FindAccessReportsData, ShoppingReportData } from "./types";
import { convertKeysToSnakeCase } from "~/utils/object.utils";
import { getAuthToken } from "~/utils/auth.utils";

export class ReportService implements IReportService {
  async findAccess({
    agency,
    page,
    search,
    customer,
    date,
  }: FindAccessReportsData): Promise<PaginatedResource<AccessReport[]>> {
    const currentDate = new Date();
    const endDate = displayDate(currentDate, DateFormats.RANGE_DATE);
    const url = `/agencies/${agency}/access-history?page=${page}&search=${search}&customer_uuid=${customer}&start_date=${date}&end_date=${endDate}`;

    return await api
      .get<PaginatedResource<ReportsDTO[]>>(url)
      .then(({ data }) => ({
        data: data.data.map(mapReportsDTO),
        links: data.links,
        meta: data.meta,
      }));
  }

  async findShopping(data: ShoppingReportData): Promise<any> {
    const body = {
      issuers_uuid: data.issuers?.map(({ uuid }) => uuid).join(","),
      approvers_uuid: data.approvers?.map(({ uuid }) => uuid).join(","),
      passengers_uuid: data.passengers?.map(({ uuid }) => uuid).join(","),
      cost_centers_uuid: data.costCenters?.map(({ uuid }) => uuid).join(","),
      start_date: displayDate(data.shoppingDate.start, DateFormats.ISO_TIME_DATE),
      end_date: data?.shoppingDate?.end
        ? displayDate(data?.shoppingDate?.end, DateFormats.ISO_TIME_DATE)
        : undefined,
      departure_date: data?.travelDate?.start
        ? displayDate(data?.travelDate?.start, DateFormats.ISO_TIME_DATE)
        : undefined,
      arrival_date: data?.travelDate?.end
        ? displayDate(data?.travelDate?.end, DateFormats.ISO_TIME_DATE)
        : undefined,
      airway_orders: Boolean(data.items?.airway),
      road_orders: Boolean(data.items?.road),
      vehicle_orders: Boolean(data.items?.vehicle),
      hotel_orders: Boolean(data.items?.hotel),
      hotel_offline: Boolean(data.items?.offline),
    };

    const url = queryString.stringifyUrl({
      url: `${import.meta.env.VITE_API_URL}/customers/${
        data.customer.uuid
      }/purchase-report`,
      query: convertKeysToSnakeCase(body),
    });

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return await response.blob();
  }
}
