import { useParams } from "react-router-dom";

import { usePhases } from "~/core/modules/Customer/pages/CustomerPage/hooks/usePhases";
import { PhasesContainer } from "./PhasesContainer";

export function PhasesPage() {
  const { projectId } = useParams() as { projectId: string };

  const phaseState = usePhases({
    projectId,
    enabled: true,
  });

  return <PhasesContainer {...phaseState} />;
}
