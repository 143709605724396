import { useCallback } from "react";
import { HotelRule } from "~/application/types";
import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { FormDialog } from "~/components/FormDialog";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";

export type AccommodationRulesDialogProps = {
  data: HotelRule[];
  onCloseClick?: () => void;
};

function getRuleName(name: string): string {
  switch (name) {
    case "NoShow":
      return "Política de no-show";
    case "ImmediateDeadLine":
      return "Política de cancelamento com emissão imediata";
    case "DeadLine":
      return " Política de cancelamento";
    case "BookingChange":
      return " Política de alteração";
    default:
      return name;
  }
}

export function AccommodationRulesDialog({
  data,
  onCloseClick,
}: AccommodationRulesDialogProps) {
  const renderItem = useCallback(
    (item: HotelRule) => (
      <Box key={item.deadLine.type}>
        <H5 size={{ "@mxlg": "3" }} css={{ fw: "700", mb: "$4" }}>{getRuleName(item.deadLine.type)}</H5>
        <Text size={{ "@initial": "3", "@mxlg": "2" }} as="p" variant="darkest">
          {item.deadLine.description}
        </Text>
      </Box>
    ),
    []
  );

  return (
    <Container size="8" fixed>
      <FormDialog css={{ 
        margin: "0 auto",
        width: "90%" 
      }} title="Políticas" onClickDismissButton={onCloseClick}>
        <DialogBody css={{ p: "$6" }}>
          <LazyList
            gap={8}
            items={data}
            render={renderItem}
            skeletonHeight={50}
          />
        </DialogBody>
      </FormDialog>
    </Container>
  );
}

AccommodationRulesDialog.displayName = "AccommodationRulesDialog";
