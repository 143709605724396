import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type DropdownMenuSubTriggerProps = ComponentProps<
  typeof DropdownMenuSubTrigger
>;

export const DropdownMenuSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  // Reset
  outline: 0,

  // Custom
  '&[data-state="open"]': {
    backgroundColor: "$neutrals-lightest",
  },
});
