import * as React from "react";
import { SVGProps } from "react";

const SvgHeading1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="heading_1_svg__lucide heading_1_svg__lucide-heading-1"
    {...props}
  >
    <path d="M4 12h8M4 18V6M12 18V6M17 12l3-2v8" />
  </svg>
);

export default SvgHeading1;
