import * as React from "react";
import { SVGProps } from "react";

const SvgLunch = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.188 2.5a.625.625 0 1 0-1.25 0h1.25Zm-1.25 3.125a.625.625 0 1 0 1.25 0h-1.25Zm.625 3.438v-.626.626ZM5.938 17.5a.625.625 0 1 0 1.25 0h-1.25ZM9.367 2.397a.625.625 0 0 0-1.234.206l1.234-.206Zm.008 3.853H10a.625.625 0 0 0-.008-.103l-.617.103Zm-5.625 0-.616-.103a.625.625 0 0 0-.009.103h.625Zm1.242-3.647a.625.625 0 0 0-1.233-.206l1.233.206Zm10.945 10.522a.625.625 0 1 0 0-1.25v1.25Zm-4.374-.625-.622-.067a.625.625 0 0 0 .621.692V12.5Zm4.374-10h.626a.625.625 0 0 0-.84-.587l.214.587Zm-.624 15a.625.625 0 1 0 1.25 0h-1.25Zm-9.376-15v3.125h1.25V2.5h-1.25Zm0 6.563V17.5h1.25V9.062h-1.25Zm2.196-6.46.626 3.75 1.232-.206-.624-3.75-1.234.206ZM8.75 6.25c0 .58-.23 1.137-.64 1.547l.883.884A3.437 3.437 0 0 0 10 6.25H8.75Zm-.64 1.547c-.41.41-.967.64-1.547.64v1.25c.911 0 1.786-.362 2.43-1.006l-.884-.884Zm-1.547.64c-.58 0-1.137-.23-1.547-.64l-.884.884a3.437 3.437 0 0 0 2.43 1.007v-1.25Zm-1.547-.64c-.41-.41-.641-.967-.641-1.547h-1.25c0 .912.362 1.786 1.007 2.43l.884-.883Zm-.65-1.444.626-3.75-1.233-.206-.625 3.75 1.233.206Zm11.572 5.522h-4.376v1.25h4.376v-1.25Zm-4.376.625.622.067v-.005l.002-.017.008-.07.035-.27c.032-.236.08-.576.15-.988.14-.825.36-1.935.683-3.078.325-1.148.748-2.304 1.284-3.238.543-.948 1.151-1.576 1.805-1.814l-.427-1.174c-1.065.387-1.863 1.321-2.463 2.366-.606 1.058-1.063 2.324-1.401 3.52-.34 1.2-.57 2.356-.714 3.21a31.09 31.09 0 0 0-.205 1.424l.621.067Zm3.75-10v15h1.25v-15h-1.25Z" />
  </svg>
);

export default SvgLunch;
