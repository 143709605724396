import { api } from "~/infrastructure/api";
import { CustomerCRM } from "~/application/types/entities/CustomerCrm.type";
import { mapCustomerCrmDTO } from "~/infrastructure/api/mappers/mapCustomerCrmDTO";
import { CrmData, ICrmService } from "./ICrmService";

export class CustomerCrmService implements ICrmService {
 
  async create(data: CrmData & { customerId: string }): Promise<CustomerCRM> {

    return await api
      .post(`/customers/${data.customerId}/additional-info`, {
        service_type: data.serviceType,
        obs: data.obs
      })
      .then(({ data }) => mapCustomerCrmDTO(data));
  }  

  async find(customerId: string): Promise<CustomerCRM[]> {
    return await api
      .get(`/customers/${customerId}/additional-info`)
      .then(({ data }) => data.data.map(mapCustomerCrmDTO));
  }  

  async toggleCrm(data: CrmData): Promise<void> {
    return await api
      .patch(`/customers/additional-info/${data.uuid}/${data.isActive ? 'inactivate' : 'activate'}`)
      .then(({ data }) => data.data);
  }  


  async update(data: CrmData): Promise<CustomerCRM[]> {
    return await api
      .put(`/customers/additional-info/${data.uuid}`, {
        obs: data.obs,
      })
      .then(({ data }) => data);
  }  
}
