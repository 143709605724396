import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { QueryKeys } from "~/application/constants/queryKeys";
import { customerService, orderMessageService, orderService } from "~/application/usecases";
import { orderHistoryService } from "~/application/usecases/OrderHistory";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryTimes } from "~/constants/queryTimes";
import { OrderProvider } from "~/presentation/shared/hooks/useOrder";
import { useOrderTab } from "~/presentation/shared/hooks/useOrderTab";
import { OrderTab } from "~/presentation/shared/utils";
import { logError } from "~/presentation/shared/utils/errors";
import { SendVoucherInWhatsappDialog } from "~/presentation/shared/views/SendVoucherInWhatsappDialog";
import { log } from "~/utils/log";
import { AgencyOrderContainer } from "./AgencyOrderContainer";
import { useOrderItems } from "./hooks/useOrderItems";
import { useUser } from "~/presentation/core/contexts/UserContext";

const LOG_TAG = "Agency/AgencyOrderPage";

const SNACKBAR_MESSAGES = {
  LOAD_ORDER_ERROR_MESSAGE: "Falha ao carregar pedido",
  LOAD_ORDER_MESSAGES_ERROR_MESSAGE: "Falha ao carregar mensagens do pedido",
  LOAD_ORDER_HISTORY_ERROR_MESSAGE: "Falha ao carregar histórico do pedido",
} as const;

export function AgencyOrderPage() {
  const { orderId } = useParams() as { orderId: string };
  const { contexts } = useUser()
  const tabValue = useOrderTab();
 
  const {
    data: order,
    isFetching,
    refetch: refetchOrder,
  } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId),
    {
      staleTime: QueryTimes.LONG,
      refetchOnMount: "always",
      refetchOnWindowFocus: true,
      enabled: !!orderId,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ORDER_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  

  const { data: orderMessages, isLoading: isLoadingOrderMessages } = useQuery(
    [QueryKeys.ORDER_MESSAGES, orderId],
    () => orderMessageService.find(orderId),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnWindowFocus: true,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ORDER_MESSAGES_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const {
    data: additionalInfo,
    isLoading: isLoadingAdditionalInfo,
  } = useQuery(
    [order],
    async () => await customerService.findById(order?.customer.uuid ?? "", 
      { withApprovers: true, withIssuers: true, withAdditionalInfo: true }
    ),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      enabled: !!order?.customer,
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage:
            SNACKBAR_MESSAGES.LOAD_ORDER_HISTORY_ERROR_MESSAGE,
        });
      },
    }
  );

  const {
    data: orderHistory,
    isLoading: isLoadingOrderHistory,
    refetch: refetchOrderHistory,
  } = useQuery(
    [QueryKeys.ORDER_HISTORY, orderId],
    async () => await orderHistoryService.get(orderId),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      enabled: !!orderId,
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage:
            SNACKBAR_MESSAGES.LOAD_ORDER_HISTORY_ERROR_MESSAGE,
        });
      },
    }
  );

  const orderItemsState = useOrderItems({
    orderId,
    order,
    refetchOrder,
    refetchOrderHistory,
    enabled: tabValue === OrderTab.ITEMS,
  });

  const onSendVoucherInWhatsapp = useCallback(() => {
    const firstNonEmptyPhone = order?.travelers
      .filter(({ phone }) => phone?.length > 0)
      .at(0);

    const defaultData = {
      ddi: "+55",
      ddd: "",
      phone: firstNonEmptyPhone?.phone || "",
    };

    dialogService.showDialog(
      <SendVoucherInWhatsappDialog defaultData={defaultData} order={order} />
    );
  }, [order]);

  return (
    <OrderProvider order={order}>
      <AgencyOrderContainer
        onSendVoucherInWhatsapp={onSendVoucherInWhatsapp}
        isLoadingAdditionalInfo={isLoadingAdditionalInfo}
        isLoading={isFetching}
        additionalInfo={additionalInfo}
        activeTab={tabValue}
        orderItemsState={orderItemsState}
        isLoadingOrderMessages={isLoadingOrderMessages}
        orderMessages={orderMessages || []}
        isLoadingOrderHistory={isLoadingOrderHistory}
        orderHistory={orderHistory}
      />
    </OrderProvider>
  );
}
