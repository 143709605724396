import { styled } from "~/application/theme";

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const FormControl = styled("div", {
  // Reset
  boxSizing: "border-box",

  // Custom
  display: "block",
  appearance: "none",
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  width: "100%",
  color: "#212529",

  "&:not([aria-required=true]) *::placeholder": {
    fontStyle: "italic",
  },
});

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
// ...

export const FormControlLabel = styled("label", {
  // Reset
  boxSizing: "border-box",

  // Custom
  display: "block",
  color: "$neutrals-darkest",
  fontWeight: 700,
  marginBottom: "$2",
  fontSize: "$xs",
  marginLeft: "$2",
  lineHeight: "1",

  "[aria-required=true] &": {
    "&::after": {
      content: "Obrigatório",
      color: "$warning-dark",
      marginLeft: "$2",
    },
  },
});


/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const FormControlContent = styled("div", {
  // Reset
  boxSizing: "border-box",
  // Custom
  display: "block",
});

FormControl.displayName = "FormControl";
FormControlLabel.displayName = "FormControlLabel";
FormControlContent.displayName = "FormControlContent";
