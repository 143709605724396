import { rangedArray } from "~/utils/array.utils";
import { DateType, dayjs, getWeekdays } from "~/utils/date.utils";

export const WEEKDAYS = getWeekdays().map((d) => d.substring(0, 1).toUpperCase());

export function parseValue<D = string | Date>(
  value: D | [D | null, D | null]
): [Date | null, Date | null] {
  // Ensure value is a array
  if (!Array.isArray(value)) {
    value = [value, null];
  }

  const [startDate, endDate] = value as any[];

  return [!startDate ? null : new Date(startDate), !endDate ? null : new Date(endDate)];
}

export function createDaysArray(month: DateType) {
  const daysInMonth = dayjs(month).daysInMonth();

  return rangedArray(daysInMonth).map((day) => ({
    day,
    date: dayjs(month)
      .set("D", 1 + day)
      .toDate(),
  }));
}
