import { styled } from "~/application/theme";

const Input = styled("input", {
  display: "none",
});

const IconContainer = styled("div", {
  padding: "$2",
  borderRadius: "$md",
  backgroundColor: "$neutrals-white",
});

const Root = styled("div", {
  position: "relative",
});

const Container = styled("div", {
  // Reset
  display: "flex",

  // Custom
  backgroundColor: "$neutrals-lightest",
  borderRadius: "$md",
  border: "1px dashed $colors$neutrals-base",
  padding: "$6",
  alignItems: "center",
  gap: "$4",

  "&[data-dragging=true]": {
    backgroundColor: "$primary-light",
  },

  "&[data-contented=true]": {
    borderColor: "transparent",
    backgroundColor: "transparent",
    boxShadow: "0 0 0 2px inset $colors$primary-base",

    [`& ${IconContainer}`]: {
      backgroundColor: "$primary-light",
    },
  },
});

export const Styled = {
  Input,
  IconContainer,
  Container,
  Root,
} as const;
