import * as React from "react";
import { SVGProps } from "react";

const SvgGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m20.614 18.212-.027-.031a9.213 9.213 0 0 0-5.105-3.045 6.353 6.353 0 0 0 2.293-3.044h1.607c.901 0 1.634-.733 1.634-1.634v-.484c0-5.06-4.117-9.176-9.177-9.176S2.663 4.914 2.663 9.974v.484c0 .901.733 1.634 1.633 1.634h1.55a6.354 6.354 0 0 0 2.345 3.08 9.204 9.204 0 0 0-4.947 3.008l-.027.033C1.746 19.954 1 21.933 1 24.092v.706h21.831v-.706c0-2.16-.746-4.137-2.217-5.88Zm-8.804-3.31a4.918 4.918 0 0 1-4.439-2.81h3.527V10.68H6.947a4.918 4.918 0 0 1 4.863-5.602 4.918 4.918 0 0 1 4.912 4.912 4.918 4.918 0 0 1-4.912 4.912Zm7.794-4.444c0 .123-.1.222-.222.222h-1.286a6.363 6.363 0 0 0-.003-1.412h1.479c.02.233.032.468.032.706v.484ZM11.84 2.21c3.548 0 6.548 2.391 7.472 5.647h-1.548a6.335 6.335 0 0 0-5.953-4.19 6.334 6.334 0 0 0-5.953 4.19H4.368C5.292 4.6 8.292 2.21 11.84 2.21Zm-7.542 8.47a.222.222 0 0 1-.222-.222v-.484c0-.238.011-.473.032-.706h1.42a6.362 6.362 0 0 0-.003 1.412H4.297ZM2.444 23.386c.144-1.545.765-2.976 1.851-4.262l.027-.033c1.484-1.756 3.707-2.764 6.099-2.764h2.99c2.391 0 4.614 1.008 6.098 2.765l.027.032c1.086 1.286 1.707 2.717 1.85 4.262H2.445Z"
      fill="#0064C5"
      stroke="#0064C5"
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgGroup;
