import { useCallback } from "react";
import { useFieldArray } from "react-hook-form";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/core/modules/DeprecatedBooking/components/Card";
// import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Grid } from "~/components/Grid";
import { displayGuests } from "~/core/modules/Booking/modules/BookingHotel/utils";
import {
  Popover as DeprecatedPopover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { SelectButton } from "~/core/shared/components/SelectButton";
import { RoomSection } from "../RoomSection";
import { GuestsSelectProps } from "./types";
import { Flex } from "~/components/Flex";

export const GuestsSelect = ({ data, control, register, setQuantityRooms }: GuestsSelectProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "accommodations",
  });

  const handleAppendRoom = useCallback(() => {
    if (fields.length >= 10) return;
    append({ adultQuantity: 1, guestsChildren: [], travelers: [] });
    setQuantityRooms?.(fields.length + 1);
  }, [append, fields, setQuantityRooms]);

  const handleRemoveRoom = useCallback(
    (index: number) => {
      remove(index);
      setQuantityRooms?.(fields.length - 1);
    },
    [remove]
  );

  const rooms = displayGuests(
    data.accommodations?.reduce(
      (prev, { adultQuantity, guestsChildren }) => ({
        roomQuantity: prev.roomQuantity + 1,
        guestQuantity: prev.guestQuantity + adultQuantity + guestsChildren.length,
      }),
      {
        roomQuantity: 0,
        guestQuantity: 0,
      }
    )
  );

  return (
    <DeprecatedPopover>
      <PopoverTrigger asChild>
        <SelectButton size="sm">{rooms}</SelectButton>
      </PopoverTrigger>

      <PopoverContent align="start" sideOffset={8}>
        <Container size="5">
          <Card
            elevated
            css={{
              overflow: "hidden",
            }}
          >
            <Flex
              direction="column"
              css={{
                maxHeight: "500px",
                overflow: "auto",
              }}
            >
              {fields.map((room, roomIndex) => {
                return (
                  <RoomSection
                    data={data}
                    control={control}
                    register={register}
                    roomIndex={roomIndex}
                    key={room.id}
                  >
                    {roomIndex !== 0 && (
                      <CardBody>
                        <Button
                          variant="tertiary"
                          type="button"
                          onClick={() => handleRemoveRoom(roomIndex)}
                        >
                          Remover quarto
                        </Button>
                      </CardBody>
                    )}
                  </RoomSection>
                );
              })}
            </Flex>
            <CardBody>
              <Grid columns="2" gap="4">
                <Button
                  variant="tertiary"
                  type="button"
                  disabled={fields.length >= 10}
                  onClick={handleAppendRoom}
                >
                  Adicionar quarto
                </Button>

                <PopoverClose asChild>
                  <Button variant="secondary">Aplicar</Button>
                </PopoverClose>
              </Grid>
            </CardBody>
          </Card>
        </Container>
      </PopoverContent>
    </DeprecatedPopover>
  );
};
