import { Airline } from '~/application/types';
import { AirlineDTO } from '../dtos';

export function mapAirlineDTO(
  data: AirlineDTO
): Airline {
  return {
    uuid: data.uuid,
    name: data.name,
    logo: data.logo,
    slug: data.slug,
    isActive: Boolean(data.active),
  };
}

