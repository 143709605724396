import { FC } from "react";
import { Badge } from "~/components/Badge";
import { Box } from "~/components/Box";
import { CardBody, CardSectionHeader } from "~/components/Card";
import { Col } from "~/components/Grid";
import { Checkbox } from "~/components/Input";
import { Text } from "~/components/Text";
import { FilterListItemProps } from "./types";

export const FilterListItem: FC<FilterListItemProps> = ({
  data,
  onFilterChange,
  ...props
}) => {
  return (
    <Box {...props} css={{ overflowY: "auto" }}>
      <CardSectionHeader>
        <Text>{data.label}</Text>
      </CardSectionHeader>

      <CardBody css={{ p: "$4", gap: "$2" }}>
        {data.options.map((option) => (
          <Checkbox
            checked={option.selected}
            onChange={() => onFilterChange(data.key, option.key)}
            key={option.key}
          >
            <Col>
              <Text>{option.label}</Text>
            </Col>

            <Badge>{option.quantity}</Badge>
          </Checkbox>
        ))}
      </CardBody>
    </Box>
  );
};

FilterListItem.displayName = "FilterListItem";
