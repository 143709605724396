import { useLocation } from "react-router-dom";

import { AGENCY_REPORTS_MENU_TABS, AgencyReportTab } from "../utils";

export function useReportTab(): AgencyReportTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(AgencyReportTab).includes(hash)) {
    return hash as AgencyReportTab;
  }

  return AGENCY_REPORTS_MENU_TABS[0].id;
}
