import { FC } from "react";
import { DateUtils } from "~/application/utils";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClock } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { Track } from "~/presentation/shared/components/Track";
import { RoadSegmentItem } from "./components/RoadSegmentItem";
import { RoadSegmentListItemProps } from "./types";

export const RoadSegmentListItem: FC<RoadSegmentListItemProps> = ({
  data,
  seats,
  seatsConnection,
}) => {
  const arrivalDate = data.connection
    ? (data.connection.arrivalDate as Date)
    : data.arrivalDate;

  const roadData = { ...data, arrivalDate };

  return (
    <>
      <RoadSegmentItem
        data={roadData}
        to={data.connection?.name || data.to}
        seats={data.connection ? seats : seatsConnection}
      />

      {data.connection && (
        <>
          <Box css={{ my: "$0" }}>
            <Flex css={{ height: "$10", justifyContent: "center" }}>
              <Track data-orientation="vertical" />
            </Flex>

            <Alert
              variant="neutral"
              size="sm"
              css={{
                p: "$4",
                borderStyle: "dashed",
                justifyContent: "center",
              }}
            >
              <Icon as={SvgClock} />

              <Text>
                {"Espera de "}
                <strong>
                  {DateUtils.timestampInterval(
                    data.connection?.arrivalDate as Date,
                    data.connection?.departureDate as Date
                  )}
                </strong>
                {" em "}
                <strong>{data.connection?.name}</strong> (Troca de ônibus)
              </Text>
            </Alert>

            <Flex css={{ height: "$10", justifyContent: "center" }}>
              <Track data-orientation="vertical" />
            </Flex>
          </Box>

          <RoadSegmentItem
            to={data.to}
            mt="$0"
            seats={seatsConnection}
            data={{
              ...data.connection,
              arrivalDate: data.arrivalDate,
              company: data.connection.companyName,
              companyImage: data.connection.companyUrl,
              from: data.connection.name,
              to: data.to,
              travelers: (data.travelers || []).map((traveler) => ({
                ...traveler,
                seats: traveler.seats.slice(1),
              })),
            }}
          />
        </>
      )}
    </>
  );
};
