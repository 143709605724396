import { Control, UseFormHandleSubmit } from "react-hook-form";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Icon } from "~/components/Icon";
import { SvgAlertTriangle, SvgClose } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { QuoteOfflineHotelFormData } from "../../types";

export type ConfirmCreateQuoteApprovalProps = {
  control: Control<QuoteOfflineHotelFormData, any>;
  handleSubmit: UseFormHandleSubmit<QuoteOfflineHotelFormData>;
  onSubmitForm: (data: QuoteOfflineHotelFormData) => void;
  onClose: () => void;
};

export const ConfirmCreateQuoteApproval = ({
  control,
  handleSubmit,
  onSubmitForm,
  onClose,
}: ConfirmCreateQuoteApprovalProps) => {
  return (
    <Card
      css={{
        width: "500px",
        position: "relative",
      }}
    >
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmitForm)}>
          <Flex
            align="center"
            direction="column"
            gap="5"
            css={{
              width: "100%",
            }}
          >
            <Flex
              css={{
                textAlign: "center",
              }}
              align="center"
              direction="column"
              gap="3"
            >
              <IconButton
                css={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                onClick={onClose}
              >
                <Icon as={SvgClose} size="md" />
              </IconButton>
              <Icon as={SvgAlertTriangle} size="3xl" variant="primary" />
              <Text size="5" variant="black" fw="600">
                A cotação anterior será invalidada.
              </Text>
              <Text size="3" variant="dark">
                Ao realizar esta nova cotação, ela será automaticamente aprovada
                e a cotação anterior será invalidada. Esta ação é irreversível.
              </Text>

              <Text size="3" variant="dark">
                Deseja continuar?
              </Text>
            </Flex>
            <FormControl control={control} name="options.0.note" required>
              <FieldLabel>Justificativa</FieldLabel>
              <Flex css={{ width: "100%" }}>
                <TextInput
                  placeholder="Ex: Vinicius Almeida aprovou a cotação via email"
                  size="smlg"
                />
              </Flex>
            </FormControl>
            <Button type="submit" size="sm" css={{ width: "70%", p: 0 }}>
              Continuar
            </Button>
          </Flex>
        </Form>
      </CardBody>
    </Card>
  );
};
