import { OrderStatus } from "~/application/types";

export enum AdvancesTab {
  OPEN = "abertos",
  APPROVED = "aprovados",
  WAITING_APPROVAL = "aguardando-aprovacao",
  CANCELLED = "cancelados",
}

export const ADVANCES_MENU_TABS = [
  { title: "Em aberto", id: AdvancesTab.OPEN, status: OrderStatus.OPEN },
  {
    title: "Aprovados",
    id: AdvancesTab.APPROVED,
    status: OrderStatus.APPROVED,
  },
  {
    title: "Aguardando aprovação",
    id: AdvancesTab.WAITING_APPROVAL,
    status: OrderStatus.WAITING,
  },
  {
    title: "Cancelados",
    id: AdvancesTab.CANCELLED,
    status: OrderStatus.EXPIRED,
  },
];
