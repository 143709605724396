import * as React from "react";
import { SVGProps } from "react";

const SvgMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.875 9.769a.625.625 0 0 0 0 1.25v-1.25Zm6.25 1.25a.625.625 0 1 0 0-1.25v1.25Zm3.75-.625A6.875 6.875 0 0 1 10 17.27v1.25a8.125 8.125 0 0 0 8.125-8.125h-1.25ZM10 17.27a6.875 6.875 0 0 1-6.875-6.875h-1.25A8.125 8.125 0 0 0 10 18.52v-1.25Zm-6.875-6.875A6.875 6.875 0 0 1 10 3.52V2.27a8.125 8.125 0 0 0-8.125 8.125h1.25ZM10 3.52a6.875 6.875 0 0 1 6.875 6.875h1.25A8.125 8.125 0 0 0 10 2.27v1.25Zm-3.125 7.5h6.25v-1.25h-6.25v1.25Z" />
  </svg>
);

export default SvgMinus;
