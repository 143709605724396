import { FlightQuery } from "~/application/types";
import { IStepItem } from "~/presentation/shared/utils";

export enum BookingAirwaySteps {
  SELECT_GO_TICKET = "select-go-ticket",
  SELECT_RETURN_TICKET = "select-return-ticket",
  SELECT_TRAVELERS = "select-travelers",
  CONFIRMATION = "confirmation",
}

export type BookingStepsAirwayParams = {
  params: FlightQuery;
  isBudget: boolean;
};

export const createBookingMenu = ({
  stretchLength,
}: {
  stretchLength: number;
}): IStepItem<BookingAirwaySteps | string, BookingStepsAirwayParams>[] => {
  const newArray =
    stretchLength > 1
      ? Array.from(Array(stretchLength)).map((_, index) => ({
          title: `Escolha o trecho ${index + 1}`,
          key: `${index}`,
        }))
      : [];
  return [
    ...newArray,
    {
      title: "Escolha da ida",
      key: BookingAirwaySteps.SELECT_GO_TICKET,
      isAvailable: () => {
        return stretchLength <= 1;
      },
    },
    {
      title: "Escolha da volta",
      key: BookingAirwaySteps.SELECT_RETURN_TICKET,
      isAvailable: ({ params }: BookingStepsAirwayParams) => {
        return !!params.dateReturnSelected;
      },
    },
    {
      title: "Passageiros",
      key: BookingAirwaySteps.SELECT_TRAVELERS,
      isAvailable: ({ isBudget }: BookingStepsAirwayParams) => !!isBudget,
    },
    {
      title: "Efetuar reserva",
      key: BookingAirwaySteps.CONFIRMATION,
      isAvailable: ({ isBudget }: BookingStepsAirwayParams) => !!isBudget,
    },
  ];
};
