import { api } from "~/infrastructure/api";
import { IProviderService } from "./IProviderService";
import { mapProviderDTO } from "~/infrastructure/api/mappers/mapProviderDTO";
import { Agreement } from "~/application/types/entities/BindCredential.type";
import { mapAgreementDTO } from "~/infrastructure/api/mappers/mapAgreementDTO";
import { Provider } from "~/application/types";

export class OrderProvider implements IProviderService {
  async find(): Promise<Provider[]> {
    return await api
      .get(`/providers`)
      .then(({ data }) => mapProviderDTO(data));
  }

  async findAgreements({ agreementId }: { agreementId?: string }): Promise<Agreement[]> {
    return await api
      .get(`/customers/${agreementId}/agreements`)
      .then(({ data }) => data.data.map(mapAgreementDTO))
  }
}
