import { AgencyEmployee } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { Fragment, useCallback } from "react";
import { AgencyEmployeeListItem } from "./components/AgencyEmployeeListItem";

export interface TabAgencyEmployeesProps {
  data?: AgencyEmployee[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  onGoToPage: (value: number) => void;
  onCreateAgencyEmployee: () => void;
  onEditAgencyEmployee: (item: AgencyEmployee) => void;
  onToggleAgencyEmployeeState: (item: AgencyEmployee) => void;
}

export function TabAgencyEmployees({
  data,
  currentPage,
  lastPage,
  onGoToPage,
  onCreateAgencyEmployee,
  onEditAgencyEmployee,
  onToggleAgencyEmployeeState,
}: TabAgencyEmployeesProps) {
  const listRenderer = useCallback(
    (item: AgencyEmployee) => (
      <AgencyEmployeeListItem
        data={item}
        onEditClick={onEditAgencyEmployee}
        onToggleState={onToggleAgencyEmployeeState}
        key={item.uuid}
      />
    ),
    [onEditAgencyEmployee, onToggleAgencyEmployeeState]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Flex align="center">
          <Col>
            <Text as="h4" size="6" css={{ fw: 500 }}>
              Administradores
            </Text>
          </Col>

          <Col sz="auto">
            <Button
              title="Novo administrador"
              color="primary"
              onClick={onCreateAgencyEmployee}
            >
              <Icon as={SvgPlus} />
              <Text>Novo administrador</Text>
            </Button>
          </Col>
        </Flex>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui funcionários cadastrados</Text>
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
}
