import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { ApiError, PaginatedResource, ReasonTrip } from "~/application/types";
import { reasonTripService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { InactivateDialog } from "~/presentation/shared/views/InactivateDialog";
import { ReasonTripDialog } from "~/presentation/shared/views/ReasonTripDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";

const LOG_TAG = "CustomerSettings/ReasonsTripPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar motivos de viagem",
  CREATE_SUCCESS_MESSAGE: "Novo motivo de viagem adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar motivo de viagem",
  UPDATE_SUCCESS_MESSAGE: "Motivo de viagem atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar motivo de viagem",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar motivo de viagem",
  ACTIVATE_SUCCESS_MESSAGE: "Motivo de viagem ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar motivo de viagem",
  INACTIVATE_SUCCESS_MESSAGE: "Motivo de viagem inativado",
} as const;

export function useReasonsTripPage() {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { contexts } = useUser();

  const customerId = contexts.customer.uuid;

  const { data, isFetching } = useQuery<
    PaginatedResource<ReasonTrip[]>,
    ApiError
  >(
    [QueryKeys.CUSTOMER_REASON_TRIPS, customerId, currentPage],
    () => reasonTripService.find({ page: currentPage, customerId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateCreateReasonTrip } = useMutation(
    (item: ReasonTrip) => reasonTripService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating ReasonTrip(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created ReasonTrip(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_REASON_TRIPS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateUpdateReasonTrip } = useMutation(
    (item: ReasonTrip) => reasonTripService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ReasonTrip(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ReasonTrip(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_REASON_TRIPS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: ReasonTrip) => reasonTripService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ReasonTrip(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ReasonTrip(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_REASON_TRIPS]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: ReasonTrip) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog onConfirm={() => mutateToggleState(item)} />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <ReasonTripDialog onSubmit={mutateCreateReasonTrip} />
    );
  }, [customerId]);

  const handleOpenEditModal = useCallback((item: ReasonTrip) => {
    dialogService.showDialog(
      <ReasonTripDialog onSubmit={mutateUpdateReasonTrip} data={item} />
    );
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page ?? 0,
    onGoToPage: setCurrentPage,
    onCreateReasonTrip: handleOpenCreateModal,
    onEditReasonTrip: handleOpenEditModal,
    onToggleReasonTripState: handleToggleState,
  };
}
