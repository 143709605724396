import { AccessReport } from "~/application/types";
import { ReportsDTO } from "../dtos/ReportsDTO";

export const mapReportsDTO = (data: ReportsDTO): AccessReport => {
  return {
    accessedAt: data.accessed_at,
    customerEmployee: {
      uuid: data.customer_employee.uuid,
      name: data.customer_employee.name,
      email: data.customer_employee.email,
      customer: {
        name: data.customer_employee.customer.name,
        uuid: data.customer_employee.customer.uuid,
      },
    },
  };
};
