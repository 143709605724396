import { Route, Routes } from "react-router-dom";
import { ExpenseApprovalContainer } from "../core/modules/Expense/pages/ExpensesPage/ExpenseApprovalContainer";
import { ExpensesPage } from "../core/modules/Expense/pages/ExpensesPage/ExpensesPage";
import { RequireRole } from "./helpers/RequireRole";
import { UserContext } from "~/application/types";

export const ExpenseRouter = () => {
  return (
    <Routes>
      <Route path="*">
        <Route
          index
          element={
            <RequireRole role={UserContext.Customer}>
              <ExpensesPage />
            </RequireRole>
          }
        />

        <Route path="aprovações/adiantamentos">
          <Route path=":advanceOrderId">
            <Route index element={<ExpenseApprovalContainer />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

ExpenseRouter.displayName = "ExpenseRouter";
