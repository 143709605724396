import { FC, useCallback, useMemo } from "react";
import { OrderItems, Road } from "~/application/types";
import { NavigatorUtils } from "~/application/utils";
import { log } from "~/application/utils/log";
import { useQueryReturnRoads } from "~/presentation/Booking/BookingRoad/hooks/useQueryReturnRoads";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { useFilters } from "~/presentation/shared/hooks/useFilters";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { RoadSeatFormData } from "~/presentation/shared/views/RoadSeatsDialog";
import { BookingRoadSteps, DYNAMIC_ROAD_FILTERS, STATIC_ROAD_FILTERS } from "../../../constants";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { ReturnRoadsSectionUI } from "./ReturnRoadsSectionUI";
import { usePoliciesFormats } from "~/presentation/shared/hooks/usePoliciesFormats";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { RoadBudgetActionType } from "../../../hooks/useRoadBudget/type";

const VIEW_ID = BookingRoadSteps.SELECT_RETURN_TICKET;

interface ReturnRoadsSectionProps {
  onOpenDetails?: (data: Road) => void;
}

export const ReturnRoadsSection: FC<ReturnRoadsSectionProps> = ({ onOpenDetails }) => {
  const { contexts, user } = useUser();
  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;
  const { LOG_TAG } = useLogTag();
  const { bookingStep, roadReducer, roadQuery, roadBudget } = useRoadsPage();
  const { activeBudget } = roadBudget

  const { bookingInfo } = roadReducer;
  const { data, isAvailable, isLoading } = useQueryReturnRoads({
    enabled: bookingStep.current === VIEW_ID,
    roadQueryLinks: roadQuery.links,
    retryCount: 50,
  });

  const isMobile = useMobile();

  const roadsList = useMemo(() => data.sort((a, b) => a.totalPrice - b.totalPrice), [data]);

  const roads = usePoliciesFormats({
    customerId,
    data: roadsList,
    itemType: OrderItems.ROAD,
    isReturn: true,
  }) as Road[];

  const { filteredData, filters, onFilterChange } = useFilters({
    filters: STATIC_ROAD_FILTERS,
    filtersGenerator: DYNAMIC_ROAD_FILTERS,
    data: roads,
    sort: false,
  });

  const onSelectRoad = useCallback((road: Road, { seats, seatsConnection }: RoadSeatFormData) => {
    log.i(LOG_TAG, "onSelectRoad", { road, seats });

    roadReducer.dispatch({
      type: "SET_RETURN_ROAD",
      payload: { road, seats, seatsConnection },
    });

    if (!isMobile) {
      NavigatorUtils.scrollToTop(true);
    }
  }, []);

  const allRoadBudget = roadBudget.state.arrival.length === filteredData.length;

  const onSelectAllBookingRoad = useCallback(() => {
    if(allRoadBudget) {
      roadBudget?.dispatch?.({ 
        paylod: [], 
        type: RoadBudgetActionType.ARRIVAL 
      })
    }

    if(!allRoadBudget) {
      roadBudget?.dispatch?.({ 
        paylod: filteredData, 
        type: RoadBudgetActionType.ARRIVAL 
      })
    }
  }, [filteredData])
  

  return (
    <ReturnRoadsSectionUI
      data={filteredData}
      filters={filters}
      allRoadBudget={allRoadBudget}
      onSelectAllBookingRoad={onSelectAllBookingRoad}
      activeBudget={activeBudget}
      isLoading={isLoading}
      isAvailable={isAvailable}
      bookingInfo={bookingInfo}
      onFilterChange={onFilterChange}
      onSelectRoad={onSelectRoad}
      onOpenDetails={onOpenDetails}
    />
  );
};

ReturnRoadsSection.displayName = "ReturnRoadsSection";
