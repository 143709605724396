import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { LineChartComponent, chartDataProps } from "./components/LineChart";
import { Card } from "~/components/Card";
import { Box } from "~/components/Box";
import { Text } from "~/components/Text";
import { useState, useEffect, useMemo, Dispatch, SetStateAction, useCallback } from "react";
import { Grid } from "~/components/Grid";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { DashboardAdditionalInfo } from "./components/DashboardReports";
import { JsonEntry } from "./types";
import { convertData, DURANTIONS, DURANTIONSMAPING } from "./utils";
import { DashboardBiResponse } from "~/application/usecases/DashboardBI/IDashboardService";
import { Skeleton } from "~/components/Skeleton";
import { LogoutCustomers, NewClientsPurchaseMonth, NotBuyingCustomers, OperationalInterventions, OrdersNotIssuedWeek, PendingFirstPurchase } from "~/infrastructure/api/mappers/mapDashboardBIDTO";

export interface CredentialsContainerProps {
  data: DashboardBiResponse | undefined;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  interventions?: OperationalInterventions;
  logoutCustomers?: LogoutCustomers;
  isLoadingInterventions: boolean;
  isLoadingCustomers: boolean;
  ordersNotIssuedWeek?: OrdersNotIssuedWeek;
  notBuyingCustomersPage: number
  newClientsPurchaseMonth?: NewClientsPurchaseMonth;
  operationalInterventions?: OperationalInterventions;
  isLoadingNewClientsPurchaseMonth: boolean;
  notBuyingCustomers?: NotBuyingCustomers;
  isLoadingNotBuyingCustomers: boolean;
  pendingFirstPurchase?: PendingFirstPurchase;
  isLoadingPendingFirstPurchase: boolean
  isLoadingOrdersNotIssuedWeek: boolean;
  notBuyingCustomersLastPage: number;
  logoutCustomersPage: number;
  logoutCustomersLastPage: number;
  onSearchLogoutCustomers: (text: string) => void;
  onSearchNotBuyingCustomersText: (text: string) => void;
  onChangeLogoutCustomersPage: Dispatch<React.SetStateAction<number>>;
  onChangeNotBuyingCustomers: Dispatch<React.SetStateAction<number>>;
  onChangeMaxDate: Dispatch<SetStateAction<Date | null>>;
  onChangeMinDate: Dispatch<SetStateAction<Date | null>>;
}

export interface JsonData extends Array<JsonEntry> {}

export function DashboardBIContainer({ 
  data, 
  isLoading,
  endDate,
  startDate,
  interventions,
  isLoadingInterventions,
  logoutCustomersPage,
  logoutCustomers,
  isLoadingCustomers,
  isLoadingNewClientsPurchaseMonth,
  isLoadingNotBuyingCustomers,
  isLoadingOrdersNotIssuedWeek,
  operationalInterventions,
  isLoadingPendingFirstPurchase,
  newClientsPurchaseMonth,
  notBuyingCustomers,
  ordersNotIssuedWeek,
  pendingFirstPurchase,
  notBuyingCustomersPage,
  notBuyingCustomersLastPage,
  logoutCustomersLastPage,
  onSearchLogoutCustomers,
  onSearchNotBuyingCustomersText,
  onChangeLogoutCustomersPage,
  onChangeNotBuyingCustomers,
  onChangeMinDate,
  onChangeMaxDate,
 }: CredentialsContainerProps
) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [filteredDataChart, setFilteredDataChart] = useState<chartDataProps[]>([]);

  const isMobile = useMobile();

  const dataChart = useMemo(() => convertData(data?.filters), [data, isLoading]);

  const handleChangeChatData = useCallback((selectedIndex: number) => {
    if (dataChart.year.length > 0) {
      const key = DURANTIONSMAPING[selectedIndex];
  
      if (key && key in dataChart) {
       return setFilteredDataChart(dataChart[key]);
      } 
      
      return setFilteredDataChart(dataChart.year);
    }
  }, [dataChart, data]);


  useEffect(() => handleChangeChatData(0), [data])

  return (
    <Flex direction="column" gap="4">
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Dashboard BI" />
        </AppBarContainer>
      </AppBar>

      {!isLoading ? (
        <Container css={{ pt: "$10", pb: "$20", width: "90vw", "@mxlg": { p: "$2" }}}>
          <Card css={{ height: "auto", background: "white", p: "$10", borderRadius: "$sm", overflowY: "hidden", "@mxlg": { p: "$3" }}}>
            <Flex justify="between" css={{ width: "40%" }}>
              {DURANTIONS.map((duration, index) => (
                <Box
                  key={index}
                  css={{
                    background: selectedIndex === index ? "$primary-base" : "transparent",
                    p: "$2",
                    color: selectedIndex === index ? "#fff" : "inherit",
                    borderRadius: "$sm",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedIndex(index)
                    handleChangeChatData(index)
                  }}
                >
                  <Text>{duration}</Text>
                </Box>
              ))}
            </Flex>
            <LineChartComponent chartData={filteredDataChart} />
          </Card>

          <Grid gap="5" columns={isMobile ? 1 : 3} css={{ mt: "$5" }}>
            <DashboardAdditionalInfo  
              logoutCustomersPage={logoutCustomersPage}
              onChangeLogoutCustomersPage={onChangeLogoutCustomersPage}
              isLoadingCustomers={isLoadingCustomers}
              logoutCustomers={logoutCustomers}
              onChangeMaxDate={onChangeMaxDate}
              isLoadingInterventions={isLoadingInterventions}
              interventions={interventions}
              startDate={startDate}
              logoutCustomersLastPage={logoutCustomersLastPage}
              notBuyingCustomersLastPage={notBuyingCustomersLastPage}
              isLoadingNewClientsPurchaseMonth={isLoadingNewClientsPurchaseMonth}
              isLoadingNotBuyingCustomers={isLoadingNotBuyingCustomers}
              isLoadingOrdersNotIssuedWeek={isLoadingOrdersNotIssuedWeek}
              isLoadingPendingFirstPurchase={isLoadingPendingFirstPurchase}
              newClientsPurchaseMonth={newClientsPurchaseMonth}
              notBuyingCustomers={notBuyingCustomers}
              ordersNotIssuedWeek={ordersNotIssuedWeek}
              operationalInterventions={operationalInterventions}
              pendingFirstPurchase={pendingFirstPurchase}
              endDate={endDate}
              notBuyingCustomersPage={notBuyingCustomersPage}
              onSearchLogoutCustomers={onSearchLogoutCustomers}
              onChangeMinDate={onChangeMinDate} 
              onSearchNotBuyingCustomersText={onSearchNotBuyingCustomersText}
              onChangeNotBuyingCustomers={onChangeNotBuyingCustomers}
              data={data}
            />
          </Grid>
        </Container>
      ) : (
          <Flex gap="2" css={{ p: "$14", width: "100%" }} direction="column">
              <Skeleton css={{ width: "100%", height: "300px" ,margin: "0 auto" }} />
           <Grid gap="2" columns={{ "@initial": "3", "@mxlg": "1"}}>
              <Skeleton css={{ width: "100%", height: "300px" ,margin: "0 auto" }} />
              <Skeleton css={{ width: "100%", height: "300px" ,margin: "0 auto" }} />
              <Skeleton css={{ width: "100%", height: "300px" ,margin: "0 auto" }} />
           </Grid>
          </Flex>
      )}
    </Flex>
  );
}
