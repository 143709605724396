import { TravelerAdvance } from "~/application/types";
import { Avatar } from "~/components/Avatar";
import { Card, CardBody } from "~/components/Card";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import * as MaskUtils from "~/utils/mask.utils";

export interface TravelerAdvanceListItemProps {
  data: TravelerAdvance;
}

export function TravelerAdvanceListItem({
  data,
  ...props
}: TravelerAdvanceListItemProps) {
  return (
    <Card {...props}>
      <CardBody>
        <Row gap="6" align="center">
          <Col sz="auto">
            <Avatar
              size="lg"
              src={data.traveler.avatarUrl}
              alt={data.traveler.fullName}
            />
          </Col>
          <Col>
            <Flex direction="column" gap="2">
              <Caption>Nome</Caption>
              <Text>{data.traveler.fullName}</Text>
            </Flex>
          </Col>
          <Col>
            <Flex direction="column" gap="2">
              <Caption>CPF</Caption>
              <Text>{MaskUtils.asCPF(data.traveler.cpf)}</Text>
            </Flex>
          </Col>
          <Col>
            <Flex direction="column" gap="2">
              <Caption>Valor</Caption>
              <Text>{MaskUtils.asCurrency(data.value)}</Text>
            </Flex>
          </Col>
        </Row>
      </CardBody>

      <Divider />

      <CardBody>
        <Flex direction="column" gap="2">
          <Caption>Observações</Caption>
          <Text>{data.description}</Text>
        </Flex>
      </CardBody>
    </Card>
  );
}

TravelerAdvanceListItem.displayName = "TravelerAdvanceListItem";
