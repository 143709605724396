import { Dayjs } from "dayjs";

export type DateType = Date | string | Dayjs;

export enum DateFormats {
  ISO_DATE = "YYYY-MM-DD",
  DATE_ISO = "YYYY-MM-DD",
  ISO_EXPENSE = "YYYY-DD-MM",
  ISO_DATE_TIME = "YYYY-MM-DD HH:mm",

  SMALL_DATE = "DD/MM/YYYY",
  LONG_DATE = "DD [de] MMMM, YYYY",

  SMALL_DATE_TIME = "DD/MM/YYYY HH:mm",
  LONG_DATE_TIME = "D [de] MMMM, YYYY [às] HH:mm",

  MONTH_YEAR = "MMMM, YYYY",
  SMALL_TIME = "HH:mm",
  SMALL_TIME_AM_PM = "HH:mm a",
  LONG_TIME = "HH:mm:ss",

  SMALL_WEEK_DATE = "ddd, D MMM",
}
