import { Fragment, useCallback } from "react";
import { Branch } from "~/application/types";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { BranchListItem } from "~/presentation/shared/components/BranchListItem";
import { useBranchesPage } from "./logic/useBranchesPage";

export function BranchesPage() {
  const {
    data,
    currentPage,
    isLoading,
    searchText,
    lastPage,
    onCreateBranch,
    onEditBranch,
    onGoToPage,
    onToggleBranchState,
    setSearchText,
  } = useBranchesPage();

  const listRenderer = useCallback(
    (item: Branch) => (
      <BranchListItem
        data={item}
        onEditClick={onEditBranch}
        onToggleState={onToggleBranchState}
        key={item.uuid}
      />
    ),
    [onEditBranch, onToggleBranchState]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Filiais" />

          <AppBarActions>
            <SearchBar search={searchText} onSearchChange={setSearchText} placeholder="Encontrar" />

            <Button title="Nova filial" color="primary" onClick={onCreateBranch}>
              <Icon as={SvgPlus} />
              <Text>Nova filial</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhuma filial corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui filiais cadastradas</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Fragment>
  );
}
