import { useMutation } from "@tanstack/react-query";
import { QueryKeys } from "~/application/constants";
import { orderMessageService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { snackbarService } from "~/components/SnackbarStack";
import { queryClient } from "~/services/queryClient";
import {
  CreateOrderMessageParams,
  UseCreateOrderMessageOptions,
  UseCreateOrderMessageReturn,
} from "./types";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";

const SNACKBAR_MESSAGES = {
  CREATE_ERROR_MESSAGE: "Falha ao enviar mensagem",
} as const;

export function useCreateOrderMessage(
  logTag: string,
  { orderId }: UseCreateOrderMessageOptions
): UseCreateOrderMessageReturn {
  const { mutateAsync: mutateCreate } = useMutation(
    ({ message, notify }: CreateOrderMessageParams) =>
      orderMessageService.create({ message, orderId, notify }),
    {
      onMutate: (item) => {
        dialogService.showDialog(<LoadingDialog message="Enviando mensagem" />);
      },
      onSuccess: (item) => {
        log.i(logTag, `Successfully created OrderMessage(${item.uuid})`);

        dialogService.popDialog();

        queryClient.invalidateQueries([QueryKeys.ORDER_MESSAGES, orderId]);
      },
      onError: (error) => {
        log.e(logTag, error);

        dialogService.popDialog();

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  return {
    create: mutateCreate,
  };
}
