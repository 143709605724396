import { FC, useCallback, useState } from "react";
import { Order, OrderStatus } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgTicket } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H5, Label } from "~/components/Typography";
import { OrderStatusTag } from "~/presentation/shared/components/OrderStatusTag";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { asCurrency } from "~/utils/mask.utils";
import { UnflownAirwayDialog } from "../UnflownAirwayDialog";
import { UnflownData } from "../UnflownAirwayDialog/type";

export type OrderSummaryCardProps = {
  data: Order;
  title: string;
  isHotelOffline?: boolean;
  isQuotingStatus: boolean;
  canIssuePendingOrder: boolean;
  canSendQuotation?: boolean;
  isIssuingOrder: boolean;
  isIssued: boolean;
  onIssueOrder: () => void;
  onManualCancelOrder: () => void;
  onSendOfflineQuote: (uuid: string) => void;
  onRejectCancelling: () => void;
  onCancelOrder: () => void;
  onUpdateUnflownAirway: (data: UnflownData[]) => void;
};

export const OrderSummaryCard: FC<OrderSummaryCardProps> = ({
  data,
  title,
  canIssuePendingOrder,
  isQuotingStatus,
  canSendQuotation,
  isIssued,
  isIssuingOrder,
  isHotelOffline,
  onIssueOrder,
  onCancelOrder,
  onSendOfflineQuote,
  onRejectCancelling,
  onManualCancelOrder,
  onUpdateUnflownAirway,
}: OrderSummaryCardProps) => {
  const isCancellingStatus = data.status === OrderStatus.CANCELING;
  const isUnflownAirway = data.items.airway?.flights.some((flight) =>
    flight.passengers.some((passenger) => passenger?.unflownAirway?.uuid)
  );
  const canShowInsertButton = isQuotingStatus && isUnflownAirway;
  const isQuoted = data.status === OrderStatus.QUOTED;

  const isCanceled = data.status === OrderStatus.CANCELED;

  const canFinalizeOrder =
    !isQuotingStatus &&
    !isCancellingStatus &&
    canIssuePendingOrder &&
    !isCanceled &&
    !canShowInsertButton &&
    !isQuoted;

  const [cartIsOpen, setCartIsOpen] = useState(false);
  const isMobile = useMobile();

  const handleOpenUnflownAirway = useCallback(() => {
    dialogService.showDialog(<UnflownAirwayDialog data={data} onSubmit={onUpdateUnflownAirway} />);
  }, [data, onUpdateUnflownAirway]);

  return (
    <Card
      css={{
        width: 450,
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
        borderBottom: "0",
        "@mxlg": {
          position: "fixed",
          zIndex: 100,
          overflow: "hidden",
          height: cartIsOpen ? "auto" : "45px",
          transition: "$slower",
          borderRadius: "0",
          border: "0",
          bottom: "0",
          width: "100%",
          left: "0",
        },
      }}
    >
      {!isMobile ? (
        <Flex
          align="center"
          css={{
            height: "$15",
            backgroundColor: "$primary-dark",
            color: "White",
            padding: "0 0 0 $5",
          }}
        >
          <Icon as={SvgTicket} css={{ mr: "$2" }} />
          <H5>{title}</H5>
        </Flex>
      ) : (
        <Flex
          align="center"
          justify="center"
          onClick={() => setCartIsOpen((prev) => !prev)}
          css={{
            height: "$15",
            backgroundColor: "$primary-dark",
            color: "White",
            padding: "0 0 0 $5",
          }}
        >
          <Icon as={cartIsOpen ? SvgChevronDown : SvgChevronUp} css={{ mr: "$2" }} />
        </Flex>
      )}
      <CardBody
        css={{
          "@mxlg": {
            backgroundColor: "#003161",
            color: "#FFF",
          },
        }}
      >
        <Row gap="3" align="center">
          <Flex
            justify="between"
            css={{
              "@mxlg": {
                color: "#FFF",
              },
            }}
          >
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Empresa
            </Label>

            <Label
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {data.customer.name}
            </Label>
          </Flex>

          <Flex justify="between">
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Data de expiração
            </Label>

            <Label
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {DateUtils.format(data.expirationDate, DateFormats.SMALL_DATE_TIME)}
            </Label>
          </Flex>

          <Flex justify="between">
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Data de criação
            </Label>

            <Label
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {DateUtils.format(data.createdAt, DateFormats.SMALL_DATE)}
            </Label>
          </Flex>

          <Flex
            css={{
              display: data.consultant?.fullName ? "flex" : "none",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Consultor
            </Label>
            <Label
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {data.consultant?.fullName}
            </Label>
          </Flex>
          <Flex justify="between">
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Solicitante
            </Label>

            <Label
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {data.issuer.name}
            </Label>
          </Flex>

          <Flex justify="between">
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Aprovador
            </Label>
            {/*TODO - Show only last approver*/}
            <Label
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {data.approvalModel?.approvers?.at(0)?.name}
            </Label>
          </Flex>

          <Flex justify="between">
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Centro de Custo
            </Label>

            <Label
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {data.costCenter.name}
            </Label>
          </Flex>

          <Flex justify="between">
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Motivo
            </Label>

            <Label
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {data.reasonTrip?.reason}
            </Label>
          </Flex>

          <Flex align="center" justify="between">
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Status
            </Label>
            <Text css={{ fw: "600" }}>
              <OrderStatusTag data={data.status} />
            </Text>
          </Flex>
          <Flex align="center" justify="between">
            <Label
              paragraph
              css={{
                mb: "$2",
                fw: "bold",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Valor total
            </Label>
            <Text css={{ fw: "600" }} size="5">
              {asCurrency(data.totalValue)}
            </Text>
          </Flex>
        </Row>
        <Flex direction="column" css={{ mt: "$5" }} gap="3">
          {canFinalizeOrder && (
            <Button
              onClick={onIssueOrder}
              disabled={isIssuingOrder}
              css={{
                display: isIssued ? "none" : "flex",
              }}
            >
              <Text>Finalizar pedido</Text>
            </Button>
          )}

          {isQuotingStatus && (isHotelOffline || isUnflownAirway) && (
            <Button
              onClick={() => onSendOfflineQuote(data?.uuid ?? "")}
              disabled={!(canSendQuotation || isUnflownAirway)}
            >
              Enviar cotação
            </Button>
          )}

          {isCancellingStatus && (
            <Button onClick={onCancelOrder} variant="primary">
              <Text>Cancelar online</Text>
            </Button>
          )}

          {canShowInsertButton && (
            <Button onClick={handleOpenUnflownAirway} variant="secondary">
              <Text>Inserir dados</Text>
            </Button>
          )}

          {[OrderStatus.PENDING_ISSUE, OrderStatus.CANCELING].includes(data.status) && (
            <Button variant="secondary" onClick={onManualCancelOrder}>
              Cancelar manualmente
            </Button>
          )}
          {isCancellingStatus && (
            <Button onClick={onRejectCancelling} variant="secondary-light">
              <Text>Retornar para emitido</Text>
            </Button>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};
