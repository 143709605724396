import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { RoadQuery } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { log } from "~/application/utils/log";
import { RoadQueryFormData } from "~/presentation/shared/components/RoadQueryForm";

const LOG_TAG = "Booking/BookingRoad/useRoadParams";

export function useRoadParams(): RoadQueryFormData | null {
  const [searchParams] = useSearchParams();

  const roadParams = useMemo(() => {
    const obj = Object.fromEntries(searchParams);

    try {
      return {
        reasonTrip: obj?.reasonTrip ? JSON.parse(obj?.reasonTrip) : null,
        stretches: JSON.parse(obj.stretches).map((stretch: any) => ({
          originSelected: JSON.parse(stretch.originSelected),
          destinationSelected: JSON.parse(stretch.destinationSelected),
          dateGoSelected: toDate(stretch.dateGoSelected),
          dateReturnSelected: !stretch.dateReturnSelected
            ? null
            : toDate(stretch.dateReturnSelected),
        })),
      };
    } catch (error) {
      log.w(LOG_TAG, error);

      return null;
    }
  }, [searchParams]);

  return roadParams;
}
