import { FC } from "react";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgRemove } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { Styled } from "./styled";
import { FlightOptionBenefitProps } from "./types";

export const FlightOptionBenefit: FC<FlightOptionBenefitProps> = ({
  name,
  isExists,
}) => {
  return (
    <Styled.Container data-active={isExists}>
      <Icon as={isExists ? SvgCheck : SvgRemove} size="md" css={{ flexShrink: 0 }} />
      <Text css={{ lineHeight: 1.4 }}>{name}</Text>
    </Styled.Container>
  );
};

FlightOptionBenefit.displayName = "FlightOptionBenefit";
