import * as React from "react";
import { SVGProps } from "react";

const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.375 8.875a.625.625 0 1 0 0 1.25v-1.25Zm6.25 1.25a.625.625 0 1 0 0-1.25v1.25Zm-2.5-3.75a.625.625 0 1 0-1.25 0h1.25Zm-1.25 6.25a.625.625 0 1 0 1.25 0h-1.25Zm7.5-3.125a6.875 6.875 0 0 1-6.875 6.875v1.25A8.125 8.125 0 0 0 18.625 9.5h-1.25ZM10.5 16.375A6.875 6.875 0 0 1 3.625 9.5h-1.25a8.125 8.125 0 0 0 8.125 8.125v-1.25ZM3.625 9.5A6.875 6.875 0 0 1 10.5 2.625v-1.25A8.125 8.125 0 0 0 2.375 9.5h1.25ZM10.5 2.625A6.875 6.875 0 0 1 17.375 9.5h1.25A8.125 8.125 0 0 0 10.5 1.375v1.25Zm-3.125 7.5h6.25v-1.25h-6.25v1.25Zm2.5-3.75v6.25h1.25v-6.25h-1.25Z" />
  </svg>
);

export default SvgPlus;
