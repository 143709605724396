import { Fragment, useCallback, useState } from "react";
import {
  Order,
  OrderStatus,
  RemakeSearchData,
  Traveler,
} from "~/application/types";
import { AppBar } from "~/components/AppBar";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Row } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { Pagination } from "~/components/Pagination";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, CounterTabBadge, TabBar, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { H4, H5 } from "~/components/Typography";
import { OrderListItem } from "../../../../../presentation/shared/components/OrderListItem";
import { GroupedCustomerOrder } from "../../utils";
import { OrderSearch } from "./OrdersPage";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutationResult,
} from "@tanstack/react-query";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Icon } from "~/components/Icon";
import { SvgFilter } from "~/components/Icon/icons";
import { OrderFilter } from "./components/OrderFilter";
import Close from "~/components/Icon/icons/Close";

export interface OrdersContainerProps {
  isLoading: boolean;
  customerOrdersGroupsList?: GroupedCustomerOrder[];
  activeStatusTab: OrderStatus;
  currentPage: number;
  searches?: OrderSearch;
  lastPage: number;
  tabSummary?: {
    id: OrderStatus;
    total: number;
    title: string;
  }[];
  onGoToPage: (page: number) => void;
  onClearFilters: () => void;
  setOrderNumber: (search: string) => void;
  setEmployeeName: (search: string) => void;
  setIssuerName: (search: string) => void;
  setApproverName: (search: string) => void;
  setStartDate: (search: string) => void;
  setEndDate: (search: string) => void;
  setTracker: (search: string) => void;
  onIssuerNotify: () => UseMutationResult<void, unknown, string, void>;
  onRefetchRoadQuery: (
    orderUuid: string,
    travelers: Traveler[],
    oldOrderId: string
  ) => {
    refetch: <TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<RemakeSearchData, unknown>>;
    isLoading: boolean;
  };
}

export function OrdersContainer({
  isLoading,
  customerOrdersGroupsList,
  activeStatusTab,
  currentPage,
  lastPage,
  tabSummary,
  searches,
  onGoToPage,
  onClearFilters,
  setOrderNumber,
  setEmployeeName,
  setIssuerName,
  setApproverName,
  setStartDate,
  setEndDate,
  setTracker,
  onIssuerNotify,
  onRefetchRoadQuery,
}: OrdersContainerProps) {
  const isMobile = useMobile();
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false);

  const orderListRenderer = useCallback(
    (item: Order) => (
      <OrderListItem
        voucherDownloadUrl={`${import.meta.env.VITE_API_URL}/orders/${item.uuid
          }/download/pdf`}
        onIssuerNotify={onIssuerNotify}
        detailOrderLink={`/pedidos/${item.uuid}${`#${item.status}`}`}
        data={item}
        key={item.uuid}
        onRefetchRoadQuery={onRefetchRoadQuery}
      />
    ),
    [onIssuerNotify]
  );

  const listRenderer = useCallback(
    (item: GroupedCustomerOrder) => (
      <Box key={item.timestamp}>
        <Box css={{ mb: "$6" }}>
          <H5 variant="darkest" css={{ fw: 600 }}>
            {item.label}
          </H5>
        </Box>

        <LazyList
          gap="6"
          items={item.items}
          render={orderListRenderer}
          skeletonHeight={114}
        />
      </Box>
    ),
    [orderListRenderer]
  );

  return (
    <Fragment>
      {isMobile && (
        <Flex
          direction="column"
          css={{
            width: "80%",
            height: "100vh",
            position: "fixed",
            display: sideBarIsOpen ? "block" : "none",
            zIndex: "999",
            top: "0",
            left: "0",
            backgroundColor: "White",
            borderRight: "1px solid $neutrals-light",
          }}
        >
          <Flex
            justify="between"
            align="center"
            css={{ pt: "$5", px: "$5" }}
          >
            <H5>Filtrar pedido</H5>
            <Close onClick={() => setSideBarIsOpen(false)} />
          </Flex>

          <Flex
            align="center"
            justify="center"
            direction="column"
            css={{ p: "$5" }}
          >
            {!isLoading ? (
              <OrderFilter
                searches={searches}
                onClearFilters={onClearFilters}
                setEmployeeName={setEmployeeName}
                setIssuerName={setIssuerName}
                setApproverName={setApproverName}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                setOrderNumber={setOrderNumber}
                setTracker={setTracker}
                tabSummary={tabSummary}
              />
            ) : (
              <Flex direction="column" gap="8">
                <Skeleton variant="text-4" style={{ width: "100%" }} />
                <Skeleton variant="text-4" style={{ width: "100%" }} />
                <Skeleton variant="text-4" style={{ width: "100%" }} />
                <Skeleton variant="text-4" style={{ width: "100%" }} />
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
      
      <Tabs value={activeStatusTab}>
        <AppBar>
          <Card
            css={{
              px: "$16",
              border: "none",
              "@mxlg": {
                px: 10,
                overflowX: "hidden",
              },
            }}
          >
            <CardBody style={{ border: "none" }}>
              <Row
                gap="4"
                align="center"
                justify={{ "@mxlg": "between" }}
                wrap="noWrap"
                css={{
                  overflowX: "hidden",
                }}
              >
                <Flex
                  gap="4"
                  css={{
                    p: "0",
                    width: "240px",
                    "@mxlg": {
                      py: "$2",
                    },
                  }}
                >
                  <H4 variant="primary-dark">Pedidos</H4>
                </Flex>
                {!isMobile ? (
                  <>
                    <OrderFilter
                      searches={searches}
                      onClearFilters={onClearFilters}
                      setEmployeeName={setEmployeeName}
                      setIssuerName={setIssuerName}
                      setApproverName={setApproverName}
                      setEndDate={setEndDate}
                      setStartDate={setStartDate}
                      setOrderNumber={setOrderNumber}
                      setTracker={setTracker}
                      tabSummary={tabSummary}
                    />
                  </>
                ) : (
                  <Card
                    onClick={() => setSideBarIsOpen((old) => !old)}
                    css={{ width: "$0", p: "$2" }}
                  >
                    <Icon as={SvgFilter} />
                  </Card>
                )}
              </Row>
            </CardBody>
          </Card>

          <Container data-mobile={isMobile}>
            <TabBar
              css={{
                overflowX: "auto",
              }}
            >
              {isLoading || !tabSummary ? (
                <Flex gap="8" css={{ p: "$4", pl: 0 }}>
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                </Flex>
              ) : (
                tabSummary.map(({ title, total, id }) => (
                  <Link to={`#${id}`} title={title} key={id}>
                    <CounterTab id={id} value={id}>
                      <Text>{title}</Text>
                      <CounterTabBadge>{total}</CounterTabBadge>
                    </CounterTab>
                  </Link>
                ))
              )}
            </TabBar>
          </Container>
        </AppBar>

        <Container
          css={{
            pt: "$10",
            pb: "$20",
            "@mxlg": {
              p: "$5",
            },
          }}
        >
          <LazyList
            gap="6"
            items={customerOrdersGroupsList}
            render={listRenderer}
            skeletonHeight={114}
            EmptyComponent={
              <EmptyState>
                <Text>Você não possui pedidos</Text>
              </EmptyState>
            }
          />
        </Container>
      </Tabs>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </Fragment>
  );
}
