import React from "react";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { FormDialog } from "~/components/FormDialog";
import { Text } from "~/components/Text";

export interface SimpleDialogProps {
  title: string;
  size?: number | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
  children?: React.ReactNode;
  loadingMessage?: string;
  cancelTitle?: string;
  confirmTitle?: string;
  onConfirm: () => void;
  onCloseClick?: () => void;
}

export function SimpleDialog({
  title,
  size = "8",
  children,
  cancelTitle,
  confirmTitle,
  onConfirm,
  onCloseClick,
}: SimpleDialogProps) {
  return (
    <Container size={{ "@initial": size, "@mxlg": "3" }} fixed>
      <FormDialog
        title={title}
        negativeButton={
          <Button onClick={onCloseClick} variant="tertiary" css={{
            "@mxlg": {
              height: "$5"
            }
          }}>
            <Text>{cancelTitle}</Text>
          </Button>
        }
        positiveButton={
          <Button onClick={onConfirm} css={{
            "@mxlg": {
              height: "$5"
            }
          }}>
            <Text>{confirmTitle}</Text>
          </Button>
        }
        onClickDismissButton={onCloseClick}
      >
        {children}
      </FormDialog>
    </Container>
  );
}

SimpleDialog.displayName = "SimpleDialog";
