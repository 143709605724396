import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Avatar } from "~/components/Avatar";
import { CardBody } from "~/components/Card";
import { Cart } from "~/components/Cart";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col } from "~/components/Grid";
import { TextAreaInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { OrderItem } from "~/services/resources/OrderApproval/IOrderApprovalService";
import * as DateUtils from "~/utils/date.utils";
import { ApprovalOrderContext } from "../../hooks/ApprovalOrderContext";

export interface ApprovalMessageProps {
  item?: OrderItem;
  itemIndex?: number;
}

export function ReprovalMessage({ item, itemIndex }: ApprovalMessageProps) {
  const { control, watch } = useForm<{ reasonRejected: string }>({
    defaultValues: { reasonRejected: "" },
  });

  const reasonRejected = watch("reasonRejected");

  const { items, setItems, isSuccessfulJudgment } =
    useContext(ApprovalOrderContext);

  useEffect(() => {
    if (items?.rejected) {
      const index = items.rejected.findIndex(
        (element) => element.itemOrderId === item?.itemOrderId
      );

      setItems({
        ...items,
        rejected: items.rejected.map((element, idx) =>
          idx === index ? { ...element, reasonRejected } : element
        ),
      });
    }
  }, [reasonRejected]);

  return (
    <>
      <Divider />

      <Cart 
        id={`approval-message-${itemIndex}`} 
        className="approval-message-card" 
        tabIndex={-1} 
        css={{ mt: "$5", width: "100%", boxShadow: "$sm" }}
      >
        <CardBody>
          <Flex
            gap="3"
            css={{
              mb: "$4",
              "@mxlg": {
                display: "none",
              },
            }}
          >
            <Avatar />
            <Flex gap="3" direction="column">
              <Text size="3" css={{ fw: 600 }}>
                Você
              </Text>
              <Text size="2" variant="dark">
                {DateUtils.getTimeFromNow(new Date())}
              </Text>
            </Flex>
          </Flex>

          <Col>
            <FormControl
              name="reasonRejected"
              control={control}
              disabled={isSuccessfulJudgment}
              required
            >
              <FieldLabel>Mensagem</FieldLabel>
              <TextAreaInput placeholder="Digite o motivo para revisão do item do pedido" />
            </FormControl>
          </Col>
        </CardBody>
      </Cart>
    </>
  );
}
