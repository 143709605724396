import { PaginatedResource, Project } from "~/application/types";
import { IProjectService } from "./IProjectService";
import { api } from "~/infrastructure/api";
import { ProjectDTO } from "~/infrastructure/api/dtos";
import { mapProjectDTO } from "~/infrastructure/api/mappers";
import queryString from "query-string";

export class ProjectService implements IProjectService {
  async getAll({
    customerId,
    page,
    name,
    isActive,
  }: {
    page?: number;
    customerId: string;
    name?: string;
    isActive?: boolean;
  }): Promise<PaginatedResource<Project[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/projects`,
      query: { page, name, active: isActive },
    });

    return await api.get<PaginatedResource<ProjectDTO[]>>(url).then(({ data }) => ({
      ...data,
      data: data.data.map(mapProjectDTO),
    }));
  }

  async create({ customerId, name, code }: { customerId: string; name: string; code: string | null; }): Promise<Project> {
    return await api
      .post<ProjectDTO>(`/customers/${customerId}/projects`, { name, code })
      .then(({ data }) => mapProjectDTO(data));
  }

  async toggleActive({ projectId, active }: { projectId: string; active: boolean }): Promise<void> {
    await api.patch(`/customers/projects/${projectId}/${active ? "inactive" : "active"}`);
  }

  async update({ projectId, name, code }: { projectId: string; name: string; code: string | null; }): Promise<Project> {
    return await api
      .put<ProjectDTO>(`/customers/projects/${projectId}`, { name, code })
      .then(({ data }) => mapProjectDTO(data));
  }
}
