import { createContext, RefCallback, useContext } from "react";

export type FormControlRules = {
  min?: number;
  max?: number;
  required?: boolean;
};

export interface FormControlContextType {
  name: string;
  value: any;
  disabled: boolean;
  error: any;
  rules: FormControlRules;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  inputRef: RefCallback<any>;
}

export const FormControlContext = createContext({} as FormControlContextType);

export const FormControlProvider = FormControlContext.Provider;

export const useFormControl = () => useContext(FormControlContext);
