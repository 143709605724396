import { useLocation } from "react-router-dom";

import { CustomerTab, CUSTOMER_MENU_TABS } from "../../../utils";

export function useCustomerTab(): CustomerTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(CustomerTab).includes(hash)) {
    return hash as CustomerTab;
  }

  return CUSTOMER_MENU_TABS[0].id;
}
