import { Collapse } from "react-collapse";
import { OrderItems } from "~/application/types";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataListItem, DataListItemProps } from "~/components/List";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { shouldTagItemAsRejected } from "~/presentation/shared/utils";

export interface OrderItemListItemProps extends DataListItemProps {
  isOpen: boolean;
  orderId?: string;
  item: {
    type: OrderItems;
    isRejected?: boolean;
  };
  onItemExpand?: () => void;
}

export function OrderItemListItem({
  isOpen,
  item,
  orderId,
  onItemExpand,
  children,
  ...props
}: OrderItemListItemProps) {
  const { order } = useOrder();

  const shouldMarkAsRejected = shouldTagItemAsRejected({
    itemIsRejected: item.isRejected,
    order,
  });

  return (
    <Box>
      <DataListItem {...props}>
        <Icon
          as={OrderUtils.getServiceIcon(item.type)}
          size="lg"
          variant="primary"
        />

        <Col>
          <Text size="5" css={{ fw: "600" }}>
            {OrderUtils.getServiceLabel(item.type)}
          </Text>
        </Col>

        {shouldMarkAsRejected && (
          <Tag variant="error-light">
            <Text>Reprovado</Text>
          </Tag>
        )}

        <Tooltip
          content={
            <TooltipLabel>
              {isOpen ? "Recolher itens" : "Ver itens"}
            </TooltipLabel>
          }
        >
          <IconButton size="md" onClick={onItemExpand}>
            <Icon as={isOpen ? SvgChevronUp : SvgChevronDown} />
          </IconButton>
        </Tooltip>
      </DataListItem>

      <Collapse isOpened={isOpen}>
        <Box css={{ mt: "$6" }}>{children}</Box>
      </Collapse>
    </Box>
  );
}

OrderItemListItem.displayName = "OrderItemListItem";
