import * as React from "react";
import { SVGProps } from "react";

const SvgCar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M1.25 8.75a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h17.5a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625ZM14.375 13.75a.625.625 0 0 0-.625.625v1.875c0 .33.132.649.365.883h.002v.002c.234.233.553.365.883.365h1.875a1.253 1.253 0 0 0 1.25-1.25v-1.875a.625.625 0 0 0-.625-.625.625.625 0 0 0-.625.625v1.875H15v-1.875a.625.625 0 0 0-.625-.625ZM2.5 13.75a.625.625 0 0 0-.625.625v1.875a1.252 1.252 0 0 0 1.25 1.25H5a1.253 1.253 0 0 0 1.25-1.25v-1.875a.625.625 0 0 0-.625-.625.625.625 0 0 0-.625.625v1.875H3.125v-1.875a.625.625 0 0 0-.625-.625ZM5 11.25a.625.625 0 0 0-.625.625A.625.625 0 0 0 5 12.5h1.25a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625ZM13.75 11.25a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625H15a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M5.406 3.125a1.251 1.251 0 0 0-1.142.742L1.93 9.121a.625.625 0 0 0-.055.254v5A.625.625 0 0 0 2.5 15h15a.625.625 0 0 0 .625-.625v-5a.625.625 0 0 0-.055-.254l-2.334-5.254a1.251 1.251 0 0 0-1.142-.742Zm0 1.25h9.188l2.281 5.13v4.245H3.125V9.508Z"
    />
  </svg>
);

export default SvgCar;
