import { Icon } from "~/components/Icon";
import { SvgChevronDown } from "~/components/Icon/icons";
import * as SelectPrimitive from "@radix-ui/react-select";
import React, { ComponentProps, forwardRef } from "react";
import { Flex } from "~/components/Flex";

import { styled } from "~/application/theme";
import useMobile from "~/presentation/shared/hooks/useMobile";

const StyledSelectButton = styled("button", {
  // Reset
  boxSizing: "border-box",
  display: "inline-flex",

  // Custom
  position: "relative",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "$fast",
  outline: "none",
  gap: "$2",
  backgroundColor: "$neutrals-white",
  fontSize: "$md",
  color: "$neutrals-darkest",
  fill: "$neutrals-darkest",
  fontWeight: 500,
  border: "none",
  borderRadius: "$md",

  "&::after": {
    content: "",
    pointerEvents: "none",
    position: "absolute",
    margin: 0,
    inset: 0,
    borderColor: "$neutrals-light",
    borderRadius: "inherit",
    borderStyle: "solid",
    borderWidth: "1px",
    transition: "$faster",
  },

  "&:hover": {
    cursor: "pointer",
    color: "$neutrals-black",
    fill: "$neutrals-black",
  },

  [`& ${Icon}`]: {
    fill: "inherit",
  },

  variants: {
    size: {
      sm: {
        padding: "$4",
      },
      md: {
        padding: "$6",
      },
    },
  },

  defaultVariants: {
    size: "sm",
  },
});

const StyledSelectMenuIndicator = styled(Icon, {
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

export type SelectButtonProps = ComponentProps<typeof StyledSelectButton>;

export type SelectContentProps = ComponentProps<typeof StyledSelectContent> & {
  useScroll?: boolean;
};

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectButton = forwardRef<
  React.ElementRef<typeof StyledSelectButton>,
  SelectButtonProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <StyledSelectButton type="button" {...props} ref={forwardedRef}>
      {children}
      <StyledSelectMenuIndicator as={SvgChevronDown} size="sm" />
    </StyledSelectButton>
  );
});

const StyledSelectLabel = styled(SelectPrimitive.Label, {});

const StyledPortal = styled(SelectPrimitive.Portal, {});
const StyledGroup = styled(SelectPrimitive.Group, {});

const StyledSelectContent = styled(SelectPrimitive.Content, {
  overflow: "hidden",
  backgroundColor: "$neutrals-white",
  borderRadius: "$md",
  border: "1px solid $colors$neutrals-light",
});
const StyledSelectViewport = styled(SelectPrimitive.Viewport, {});
const StyledSelectScrollUpButton = styled(SelectPrimitive.ScrollUpButton, {});
const StyledSelectScrollDownButton = styled(SelectPrimitive.ScrollUpButton, {});
const StyledSelectIcon = styled(SelectPrimitive.Icon, {
  display: "inherit",
});
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const Select = SelectPrimitive.Root;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectTrigger = SelectPrimitive.Trigger;

export const SelectPortal = StyledPortal;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectValue = SelectPrimitive.Value;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectGroup = SelectPrimitive.Group;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectItemText = SelectPrimitive.ItemText;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectScrollUpButton = StyledSelectScrollUpButton;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectScrollDownButton = StyledSelectScrollDownButton;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectViewport = StyledSelectViewport;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectLabel = StyledSelectLabel;
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectItem = styled(SelectPrimitive.Item, {
  all: "unset",
  display: "flex",
  padding: "$3 $4",
  position: "relative",
  userSelect: "none",
  fontSize: "$sm",
  lineHeight: "1",
  fontWeight: "500",
  color: "$neutrals-darkest",
  transition: "$normal",

  "&[data-disabled]": {
    pointerEvents: "none",
  },

  "&:hover": {
    cursor: "pointer",
    color: "$neutrals-black",
    backgroundColor: "$neutrals-lightest",
  },

  "&:active": {
    color: "$neutrals-black",
    backgroundColor: "$neutrals-light",
  },

  "&:focus": {
    color: "$primary-base",
    backgroundColor: "$primary-light",
  },
});
/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectContent = forwardRef<
  React.ElementRef<typeof StyledSelectContent>,
  SelectContentProps
>(({ children, placeholder, useScroll, ...props }, forwardedRef) => {
  const isMobile = useMobile();

  return (
    <SelectPortal>
      <StyledSelectContent
        ref={forwardedRef}
        css={{
          zIndex: "9999",
          "@mxmd": {
            height: "$64",
            overflowY: "auto",
          },
        }}
      >
        {/* Scroll */}
        {useScroll && (
          <SelectScrollUpButton>
            <Icon name="chevron-up" size="sm" />
          </SelectScrollUpButton>
        )}

        {/* Content */}
        {isMobile ? (
          <Flex>
            <SelectViewport>{children}</SelectViewport>
          </Flex>
        ) : (
          <SelectViewport>{children}</SelectViewport>
        )}

        {/* Scroll */}
        {useScroll && (
          <SelectScrollDownButton>
            <Icon name="chevron-down" size="sm" />
          </SelectScrollDownButton>
        )}
      </StyledSelectContent>
    </SelectPortal>
  );
});

SelectButton.displayName = "SelectButton";
