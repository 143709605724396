import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type TagProps = ComponentProps<typeof Tag>;

export const Tag = styled("span", {
  // Reset
  boxSizing: "border-box",
  display: "inline-flex",

  // Custom
  borderRadius: "$md",
  fontWeight: 700,
  fontSize: "$xs",
  lineHeight: "100%",
  alignItems: "center",
  gap: "$2",
  fill: "CurrentColor",

  variants: {
    size: {
      sm: {
        padding: "$1",
      },
      md: {
        padding: "$2",
      },
    },
    variant: {
      info: {
        color: "$neutrals-white",
        backgroundColor: "$primary-base",
        border: "1px solid $neutrals-white",
      },
      "info-light": {
        color: "$primary-base",
        backgroundColor: "$primary-light",
        border: "1px solid $primary-base",
      },
      success: {
        color: "$neutrals-white",
        backgroundColor: "$success-base",
        border: "1px solid $neutrals-white",
      },
      "success-light": {
        color: "$success-base",
        backgroundColor: "$success-light",
        border: "1px solid $success-base",
      },
      warning: {
        color: "$neutrals-black",
        backgroundColor: "$warning-base",
        border: "1px solid $neutrals-black",
      },
      "warning-light": {
        color: "$warning-dark",
        backgroundColor: "$warning-light",
        border: "1px solid $warning-dark",
      },
      error: {
        color: "$neutrals-white",
        backgroundColor: "$error-base",
        border: "1px solid $neutrals-white",
      },
      "error-light": {
        color: "$error-base",
        backgroundColor: "$error-light",
        border: "1px solid $error-base",
      },
      neutral: {
        color: "$neutrals-white",
        backgroundColor: "$neutrals-dark",
        border: "1px solid $neutrals-white",
      },
      "neutral-light": {
        color: "$neutrals-darkest",
        backgroundColor: "$neutrals-lightest",
        border: "1px solid $neutrals-darkest",
      },
    },
  },

  defaultVariants: {
    variant: "neutral",
    size: "md",
  },
});

Tag.displayName = "Tag";
