import { useForm } from "react-hook-form";
import { AgencyEmployee, AgencyEmployeeType, DeepPartial } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { MaskedInput, Radio, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { Label } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import * as MaskUtils from "~/utils/mask.utils";

export interface AgencyEmployeeDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<AgencyEmployee>;
  onCloseClick?: () => void;
  onSubmit: (data: AgencyEmployee) => void;
}

export function AgencyEmployeeDialog({
  isNew,
  defaultData,
  onCloseClick,
  onSubmit,
}: AgencyEmployeeDialogProps) {
  const { control, formState, watch, handleSubmit } = useForm<AgencyEmployee>({
    defaultValues: defaultData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { phone } = watch();

  if (formState.isSubmitting) {
    return (
      <LoadingModal
        isOpen={formState.isSubmitting}
        message={isNew ? "Criando administrador" : "Editando administrador"}
      />
    );
  }

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          title={isNew ? "Novo administrador" : "Editar administrador"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Row gap="6">
              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome Completo</FieldLabel>
                  <TextInput />
                </FormControl>
              </Col>
              <Col sz="12">
                <FormControl name="cpf" control={control} required pattern={MaskUtils.PATTERN_CPF}>
                  <FieldLabel>CPF</FieldLabel>
                  <MaskedInput mask={MaskUtils.MASK_CPF} />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="email" control={control} required>
                  <FieldLabel>E-mail</FieldLabel>
                  <TextInput />
                </FormControl>
              </Col>
              <Col sz="12">
                <FormControl name="phone" control={control}>
                  <FieldLabel>Telefone</FieldLabel>
                  <MaskedInput mask={MaskUtils.pickPhoneNumberMask(phone)} />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="type" control={control} required>
                  <FieldLabel>Tipo</FieldLabel>
                  <Flex gap="2">
                    <Flex gap="2" align="center">
                      <Radio value={AgencyEmployeeType.MANAGER} />
                      <Label size="3">Gestor</Label>
                    </Flex>
                    <Flex gap="2" align="center">
                      <Radio value={AgencyEmployeeType.ISSUER} />
                      <Label size="3">Emissor</Label>
                    </Flex>
                  </Flex>
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
