import { Fragment, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";

import { BiztripLogo } from "~/components/BiztripLogo";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { FormControl } from "~/components/FormControl";
import { SvgArrowBack, SvgMail } from "~/components/Icon/icons";
import { TextInput } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { authService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { SNACKBAR_MESSAGES } from "~/presentation/core/containers/AppContainer/components/ProfileMenu";
import { logError } from "~/presentation/shared/utils/errors";

const LOG_TAG = "Auth/pages/RedefinePasswordPage/RedefinePasswordContainer";

export type RedefinePasswordFormData = {
  email: string;
};

const defaultData: RedefinePasswordFormData = {
  email: "",
};

const redefinePasswordSchema = yup.object().shape({
  email: yup
          .string()
          .email("Informe um e-mail válido")
          .required("O campo e-mail é obrigatório"),
});

export function RedefinePasswordContainer() {
  const [isEmailSent, setIsEmailSent] = useState(false);

  const navigate = useNavigate();

  const { formState: { errors, isSubmitting }, control, handleSubmit } = useForm<RedefinePasswordFormData>({
    defaultValues: defaultData,
    resolver: yupResolver(redefinePasswordSchema)
  });

  const { mutate: mutateRequestRedefinePassword, isSuccess, isLoading } = useMutation(
    authService.forgotPassword,
    {
      onSuccess: () => {
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.REQUEST_PASSWORD_CHANGE_SUCCESS_MESSAGE,
          "success"
        );

        setIsEmailSent(true);
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.REQUEST_PASSWORD_CHANGE_ERROR_MESSAGE,
        });
      },
    }
  );

  function redefinePassword(data: RedefinePasswordFormData) {
    mutateRequestRedefinePassword(data.email);
  }
  
  return (
    <Fragment>
      <Box style={{ height: "100vh", padding: 0 }}>
        <Box
          mode="show"
          css={{
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "450px",
            mb: "$20",
            zIndex: "-1",
          }}
        />

        <Container css={{ padding: "$5" }}>
          <Container
            size={{ "@initial": "5", "@mxmd": "7" }}
            css={{
              m: 0,
              margin: "0 auto",
              background: "#FFF",
              position: "relative",
              top: "270px",
              px: "$10",
              py: "$6",
              borderRadius: "$md",
              boxShadow: "$md",
              width: "100%",
            }}
          >
            <Flex justify="center" direction="column" gap="3" css={{ position: "relative" }}>
              <BiztripLogo css={{ margin: "0 auto" }} size={{ "@initial": "md", "@mxmd": "lg" }} />

              <Button 
                variant="none" 
                onClick={() => navigate(-1)} 
                css={{ 
                  width: "fit-content", 
                  height: "auto", 
                  position: "absolute", 
                  top: 0, 
                  left: 0, 
                  "&:hover": { cursor: "pointer" } 
                }}
              >
                <SvgArrowBack />
              </Button>

              {isEmailSent && (
                <>
                  <Text
                    size="4"
                    as="h1"
                    css={{
                      fw: "700",
                      textAlign: "center",
                    }}
                  >
                    E-mail enviado!
                  </Text>

                  <Text size="4" css={{ textAlign: "center", lineHeight: "$5" }}>
                    Caso o e-mail esteja cadastrado no sistema, você receberá uma mensagem com as instruções para definir uma nova senha
                  </Text>

                  <Divider />

                  <Text size="3" css={{ textAlign: "center", lineHeight: "$5", color: "$neutrals-base" }}>
                    Caso não tenha recebida a mensagem, verifique se digitou o endereço de e-mail corretamente ou se caiu na caixa de spam
                  </Text>

                  <Button 
                    variant="tertiary" 
                    css={{ width: "100%" }}
                    onClick={() => setIsEmailSent(false)}
                  >Reenviar e-mail</Button>
                </>
              )}

              {!isEmailSent && (
                <>
                  <Text
                    size="4"
                    as="h1"
                    css={{
                      fw: "700",
                      textAlign: "center",
                    }}
                  >
                    Redefinição de senha
                  </Text>

                  <Text size="4" css={{ textAlign: "center", lineHeight: "$5" }}>
                    Informe seu e-mail cadastrado para enviarmos as instruções de redefinição de senha
                  </Text>

                  <form onSubmit={handleSubmit(redefinePassword)}>
                    <Flex direction="column" gap="6">
                      <Box>
                        <FormControl name="email" control={control}>
                          <TextInput placeholder="E-mail" leftIcon={SvgMail} />
                          {errors.email && (
                            <Text variant="error-base" size="3" css={{ mt: "$2" }}>{errors.email.message}</Text>
                          )}
                        </FormControl>
                      </Box>

                      <Button
                          mode={{ "@mxmd": "align" }}
                          type="submit"
                          disabled={isSubmitting || isLoading}
                        >
                          {isSubmitting && <Spinner css={{ borderLeftColor: "$neutrals-white", width: "$4", height: "$4", borderWidth: "2px" }} />} {''}
                          Continuar
                        </Button>
                    </Flex>
                  </form>
                </>
              )}
            </Flex>
          </Container>
        </Container>
      </Box>
    </Fragment>
  );
}