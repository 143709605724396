import { FC } from "react";
import { LogTagContext } from "../../contexts/LogTagContext";
import { LogTagProviderProps } from "./types";

export const LogTagProvider: FC<LogTagProviderProps> = ({
  logTag,
  children,
}) => {
  return (
    <LogTagContext.Provider value={{ LOG_TAG: logTag }}>
      {children}
    </LogTagContext.Provider>
  );
};

LogTagProvider.displayName = "LogTagProvider";
