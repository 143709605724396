import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type ExternalLinkProps = ComponentProps<typeof ExternalLink>;

export const ExternalLink = styled("a", {
  // Reset
  boxSizing: "border-box",

  // Custom
  alignItems: "center",
  flexShrink: 0,
  outline: "none",
  lineHeight: "inherit",

  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
    color: "$primary-base",
    fill: "$primary-base",
  },
});
