import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { addTimeToDate } from "~/application/utils/date-functions";
import { dialogService } from "~/components/DialogStack";
import { useCountdown } from "~/presentation/shared/hooks/useCountdown";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { SessionExpiredDialog } from "../../views/SessionExpiredDialog";
import { AirwayTimerSectionUI } from "./AirwayTimerSectionUI";

const BOOKING_AIRWAY_TIME_LIMIT = 10 * 60 * 1000; // 10 minutes

export type AirwayTimerSectionProps = {
  isMobile: boolean;
};

export const AirwayTimerSection: FC<AirwayTimerSectionProps> = ({
  isMobile,
}) => {
  const navigate = useNavigate();

  const { flightQuery } = useFlightsPage();

  // Date that session will expire
  const deadline = useMemo(() => {
    if (flightQuery.isLoading) return null;

    return addTimeToDate(new Date(), BOOKING_AIRWAY_TIME_LIMIT);
  }, [flightQuery.isLoading]);

  const { countDown, stopCountdown } = useCountdown({
    targetDate: deadline!,
    enabled: deadline !== null,
  });

  const onOpenExpiredDialog = useCallback(() => {
    dialogService.showDialog(
      <SessionExpiredDialog onReload={() => navigate(0)} />
    );
  }, [navigate]);

  useEffect(() => {
    if (countDown !== null && countDown <= 0) {
      onOpenExpiredDialog();
      stopCountdown();
    }
  }, [countDown, onOpenExpiredDialog, stopCountdown]);

  return (
    <AirwayTimerSectionUI
      time={countDown}
      isLoading={!deadline}
      isMobile={isMobile}
    />
  );
};

AirwayTimerSection.displayName = "AirwayTimerSection";
