import { ApiResponse } from "./ApiResponse.class";

export enum ApiErrorCode {
  UNKNOWN = "UNKNOWN",
  RETRY_REQUEST = "RETRY_REQUEST",
  SEATS_SEARCH_ERROR = "SEATS_SEARCH_ERROR",
  INACTIVE_USER = "INACTIVE_USER",
  NETWORK_ERROR = "ERR_NETWORK",
  AIRWAY_RESERVE_ERROR = "AIRWAY_RESERVE_ERROR",
}

export class ApiError extends ApiResponse {
  public code?: ApiErrorCode;
  public message?: string;

  constructor(data: ApiError) {
    super(data);

    this.code = data.code;
    this.message = data.message;
  }

  static isAsyncError(data: any) {
    return data?.message === "try later";
  }
}
