import { FC } from "react";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { SwitchButton } from "~/components/Input";
import { DataColItem, DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { ExpenseTypeListItemProps } from "./types";

export const ExpenseTypeListItem: FC<ExpenseTypeListItemProps> = ({
  data,
  onEditClick,
  onToggleState,
  ...props
}) => {
  return (
    <DataListItem data-active={data.isActive} {...props}>
      <DataColItem headerTitle="Nome da despesa" data={data.name} />

      <DataColItem headerTitle="Categoria" data={data.expenseCategory.name} />

      {onEditClick && (
        <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditClick(data)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
      )}

      {onToggleState && (
        <SwitchButton
          data-checked={data.isActive}
          onClick={() => onToggleState(data)}
        />
      )}
    </DataListItem>
  );
};

ExpenseTypeListItem.displayName = "ExpenseTypeListItem";
