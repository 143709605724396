import { useReducer } from "react";
import type {
  HotelBookingDispatchAction,
  HotelBookingState,
  HotelReducer,
} from "../types";

function hotelBookingReducer(
  state: HotelBookingState,
  action: HotelBookingDispatchAction
): HotelBookingState {
  switch (action.type) {
    case "SET_HOTEL":
      return {
        ...state,
        hotelSelected: action.payload,
        roomsSelected: null,
      };
    case "SET_HOTEL_ROOMS":
      return { ...state, roomsSelected: action.payload };
    case "RESET_BOOKING":
      return {
        ...state,
        hotelSelected: null,
      };
    default:
      return state;
  }
}

const DEFAULT_BOOKING_HOTEL_STATE: HotelBookingState = {
  hotelSelected: null,
  roomsSelected: null,
};

export function useHotelReducer(): HotelReducer {
  const [bookingState, dispatch] = useReducer(
    hotelBookingReducer,
    DEFAULT_BOOKING_HOTEL_STATE
  );

  return { bookingState, dispatch };
}
