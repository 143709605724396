import { styled } from "~/application/theme";

const Container = styled("div", {
  background: "$neutrals-white",
  borderWidth: "2px",
  borderStyle: "solid",
  borderRadius: "$md",
  borderColor: "transparent",
  color: "$neutrals-black",
  transition: "$normal",

  "&:hover": {
    boxShadow: "$md",
  },

  "&[data-open=true]": {
    borderColor: "$primary-base",
  },

  "&[data-active=true]": {
    borderColor: "$success-base",
  },
});

export const Styled = {
  Container,
} as const;
