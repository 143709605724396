import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  ApiError,
  DeepPartial,
  Markdown,
  PaginatedResource,
} from "~/application/types";
import { airlineService, markdownService } from "~/application/usecases";
import { CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { Text } from "~/components/Text";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { MarkdownsContainer } from "./MarkdownsContainer";
import { MarkdownDialog } from "./components/MarkdownDialog";

const LOG_TAG = "Agency/MarkdownsPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar markdowns",
  CREATE_SUCCESS_MESSAGE: "Markdown criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar markdown",
  UPDATE_SUCCESS_MESSAGE: "Markdown atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar markdown",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar markdowns",
  ACTIVATE_SUCCESS_MESSAGE: "Markdown ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar markdowns",
  INACTIVATE_SUCCESS_MESSAGE: "Markdown inativado",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<Markdown> = {
  name: "",
};

export function MarkdownsPage() {
  const { user } = useUser();
  const agencyId = user.agency.uuid;

  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<
    PaginatedResource<Markdown[]>,
    ApiError
  >(
    [QueryKeys.AGENCY_MARKDOWNS, agencyId, currentPage, { search }],
    () => markdownService.find({ page: currentPage, name: search, agencyId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const fetchAirlines = useCallback(() => airlineService.find(), []);

  const { mutateAsync: mutateCreateMarkdown } = useMutation(
    async (item: Markdown) =>
      await markdownService.create({ ...item, agencyId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Markdown(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created Markdown(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_MARKDOWNS, agencyId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutateAsync: mutateUpdateMarkdown } = useMutation(
    async (item: Markdown) => await markdownService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Markdown(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Markdown(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_MARKDOWNS, agencyId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: Markdown) => markdownService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Markdown(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Markdown(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_MARKDOWNS]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: Markdown) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando markdown"
        onConfirm={() => mutateToggleState(item)}
      >
        <CardBody>
          <Text variant="darkest">Você poderá ativa-lo novamente.</Text>
        </CardBody>
      </InactivateDialog>
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <MarkdownDialog
        isNew
        onSubmit={mutateCreateMarkdown}
        defaultData={DEFAULT_FORM_DATA}
        fetchAirlines={fetchAirlines}
      />
    );
  }, [agencyId, fetchAirlines]);

  const handleOpenEditModal = useCallback(
    (item: Markdown) => {
      dialogService.showDialog(
        <MarkdownDialog
          onSubmit={mutateUpdateMarkdown}
          defaultData={item}
          fetchAirlines={fetchAirlines}
        />
      );
    },
    [fetchAirlines]
  );

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return (
    <MarkdownsContainer
      isLoading={isFetching}
      markdownsList={data?.data}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      searchText={searchText}
      setSearchText={handleChangeSearch}
      onGoToPage={setCurrentPage}
      onToggleState={handleToggleState}
      onCreateMarkdown={handleOpenCreateModal}
      onEditMarkdown={handleOpenEditModal}
    />
  );
}
