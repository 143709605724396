import { Fragment, useEffect, useMemo, useState } from "react";
import { LazyListItem, LazyListProps } from "~/components/LazyList";
import { LazyListRoot } from "~/components/LazyList/LazyList";
import { Skeleton } from "~/components/Skeleton";
import { OfflineQuote } from "~/presentation/shared/components/OfflineQuote";

export const HotelsLazyList = <TItem,>({
  skeletonQuantity = 3,
  skeletonHeight = 80,
  isLoading = false,
  items,
  render,
  quoteUrl,
  EmptyComponent,
  SkeletonComponent,
  ...props
}: LazyListProps<TItem>) => {
  const skeletonsArray = useMemo(
    () => (
      <Fragment>
        {Array(skeletonQuantity)
          .fill(null)
          .map((_, index) => (
            <LazyListItem key={index}>
              {SkeletonComponent ?? <Skeleton variant="fluid" style={{ height: skeletonHeight }} />}
            </LazyListItem>
          ))}
      </Fragment>
    ),
    [skeletonQuantity, skeletonHeight]
  );

  const [showOfflineQuote, setShowOfflineQuote] = useState(false);

  useEffect(() => {
    if (showOfflineQuote) {
      setShowOfflineQuote(false);
      return;
    }
    setTimeout(() => {
      setShowOfflineQuote(true);
    }, 30000);
  }, [quoteUrl]);

  const itemsArray = useMemo(() => {
    const newArray = new Array((items?.length as number) + 1).fill(null);
    return newArray?.map((_, index) => {
      const key = `lazy-list-item${index}`;
      if (index === newArray.length - 1) {
        return (
          <LazyListItem key={key} css={{ px: "$3" }}>
            <OfflineQuote quoteUrl={quoteUrl as string} />
          </LazyListItem>
        );
      }

      return <LazyListItem key={key}>{render(items![index], index)}</LazyListItem>;
    });
  }, [items, render, quoteUrl]);

  return (
    <Fragment>
      {isLoading || !items ? (
        showOfflineQuote ? (
          <OfflineQuote quoteUrl={quoteUrl as string} />
        ) : (
          <LazyListRoot {...props}>{skeletonsArray}</LazyListRoot>
        )
      ) : items.length === 0 ? (
        EmptyComponent
      ) : (
        <LazyListRoot {...props}>{itemsArray}</LazyListRoot>
      )}
    </Fragment>
  );
};

HotelsLazyList.displayName = "HotelsLazyList";
