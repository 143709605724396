import { FC, useCallback } from "react";
import { Flight } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { FlightDetailsDialog } from "~/presentation/shared/views/FlightDetailsDialog";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { ConfirmationUI } from "./ConfirmationUI";

export const ConfirmationSection: FC = () => {
  const { airwayReducer } = useFlightsPage();

  const onOpenDetails = useCallback((item: Flight) => {
    dialogService.showDialog(
      <FlightDetailsDialog data={item} flightOrigin={item.flightOrigin} />
    );
  }, []);

  return (
    <ConfirmationUI
      bookingState={airwayReducer.bookingState}
      onOpenDetails={onOpenDetails}
    />
  );
};
