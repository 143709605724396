import { ApiError, ReasonTrip, Road, RoadQuery, RoadQueryLinks } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { api } from "~/infrastructure/api";
import { RoadDTO, RoadQueryLinksDTO } from "~/infrastructure/api/dtos";
import { mapRoadDTO } from "~/infrastructure/api/mappers";
import type { IRoadService } from "./IRoadQueryService";

export class RoadService implements IRoadService {
  async query(
    customerId: string,
    {
      originSelected,
      destinationSelected,
      dateGoSelected,
      dateReturnSelected,
      reasonTrip,
    }: RoadQuery
  ): Promise<RoadQueryLinks> {
    return api
      .post<RoadQueryLinksDTO>("/road", {
        customer_uuid: customerId,
        origin: originSelected.city.uuid ?? originSelected.stops[0].uuid,
        destination: destinationSelected.city.uuid ?? destinationSelected.stops[0].uuid,
        departureDate: DateUtils.toISOString(dateGoSelected as Date),
        arrivalDate: !dateReturnSelected ? undefined : DateUtils.toISOString(dateReturnSelected),
        reason_trip_uuid: reasonTrip?.uuid,
      })
      .then(({ data }) => ({
        waitTime: data.wait_time,
        links: data.links,
      }));
  }

  async findReturn(url: string): Promise<Road[]> {
    return api
      .post<{
        search_key: string;
        travels: RoadDTO[];
      }>(url, {})
      .then(({ data }) => {
        if (!data.travels) {
          throw new ApiError({ data, statusCode: 204 });
        }

        return data.travels.map((road) => mapRoadDTO(road, data.search_key));
      });
  }

  async findGo(url: string): Promise<Road[]> {
    return api
      .post<{
        search_key: string;
        travels: RoadDTO[];
      }>(url)
      .then(({ data }) => {
        if (!data.travels) {
          throw new ApiError({ data, statusCode: 204 });
        }

        return data.travels.map((road) => mapRoadDTO(road, data.search_key));
      });
  }
}
