import { useCallback, useEffect, useRef, useState } from "react";

export type UseOutsideListenerOptions = {
  defaultOpen?: boolean;
};

export type UseOutsideListenerReturn = {
  open: boolean;
  subscribe: (ref: Element | null) => void;
  toggleOpen: VoidFunction;
  setOpen: (open: boolean) => void;
};

export function useOutsideListener(
  options?: UseOutsideListenerOptions
): UseOutsideListenerReturn {
  const [open, setOpen] = useState<boolean>(options?.defaultOpen || false);
  const displaysRefs = useRef<Element[]>([]);
  displaysRefs.current = [];

  const handleClickOutside: EventListener = useCallback(
    (event: Event) => {
      if (displaysRefs.current.length > 0) {
        const path = event.composedPath && event.composedPath();

        if (displaysRefs.current.every((ref) => !path.includes(ref))) {
          setOpen(false);
        }
      }
    },
    [displaysRefs.current, setOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const toggleOpen = useCallback(() => setOpen((old) => !old), [setOpen]);

  const subscribe = useCallback(
    (elementRef: Element | null) => {
      if (elementRef) {
        displaysRefs.current.push(elementRef);
      }
    },
    [displaysRefs.current]
  );

  return { open, subscribe, toggleOpen, setOpen };
}
