import { CostCenter } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgFile, SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { CostCenterListItem } from "./components/CostCenterListItem";
import { SearchBar } from "~/components/Input";
import { Pagination } from "~/components/Pagination";
import { Alert, AlertIcon } from "~/components/Alert";
import { useParams } from "react-router-dom";

export interface TabCostCentersProps {
  data?: CostCenter[];
  isLoading: boolean;
  searchText: string;
  currentPage: number;
  lastPage: number;
  hasUploadedFromSpreadsheet: boolean;
  onGoToPage: (page: number) => void;
  setSearchText: (value: string) => void;
  onCreateCostCenter: () => void;
  onEditCostCenter: (item: CostCenter) => void;
  onToggleCostCenter: (item: CostCenter) => void;
  onCreateCostCentersBySpreadsheet: (customerId: string) => void;
}

export function TabCostCenters({
  data,
  currentPage,
  searchText,
  lastPage,
  hasUploadedFromSpreadsheet,
  onGoToPage,
  setSearchText,
  onCreateCostCenter,
  onEditCostCenter,
  onToggleCostCenter,
  onCreateCostCentersBySpreadsheet,
}: TabCostCentersProps) {
  const { customerId } = useParams() as { customerId: string };

  const listRenderer = useCallback(
    (item: CostCenter) => (
      <CostCenterListItem
        data={item}
        onEditClick={onEditCostCenter}
        onToggleState={onToggleCostCenter}
        key={item.uuid}
      />
    ),
    [onEditCostCenter, onToggleCostCenter]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Centros de custo</H4>
          </Col>

          <Col
            sz="auto"
            css={{
              "@mxlg": {
                width: "100%",
              },
            }}
          >
            <SearchBar
              style={{ width: "100%" }}
              search={searchText}
              onSearchChange={setSearchText}
              placeholder="Encontrar"
            />
          </Col>

          <Col
            sz="auto"
            css={{
              "@mxlg": {
                width: "100%",
              },
            }}
          >
            <Button
              variant="tertiary"
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
              onClick={() => {
                onCreateCostCentersBySpreadsheet(customerId as string);
              }}
            >
              <Icon as={SvgFile} />
              <Text>Importar planilha</Text>
            </Button>
          </Col>

          <Col
            sz="auto"
            css={{
              "@mxlg": {
                width: "100%",
              },
            }}
          >
            <Button
              title="Novo centro de custo"
              color="primary"
              onClick={onCreateCostCenter}
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <Icon as={SvgPlus} />
              <Text>Novo centro de custo</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        {hasUploadedFromSpreadsheet && (
          <Alert variant="info" css={{ mb: "$6" }}>
            <AlertIcon />
            <Text css={{ lineHeight: "1.25" }}>
              Alguns cadastros podem não ter sido concluídos por falta de alguma informação.
              Certifique-se que as informações estejam corretas para cadastrar os centros de custos.
            </Text>
          </Alert>
        )}

        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui centros de custos cadastrados</Text>
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
