export const mask = "dD/mM/YYYY";

export const formatChars = {
  Y: "[0-9]",
  d: "[0-3]",
  D: "[0-9]",
  m: "[0-1]",
  M: "[1-9]",
};

export const controlValueMask = (value: string) => {
  const dateParts = value.split("/");
  const dayPart = dateParts[0];
  const monthPart = dateParts[1];

  if (!dayPart || !monthPart) return value;

  if (dayPart.startsWith("3")) formatChars["D"] = "[0-1]";
  else if (dayPart.startsWith("0")) formatChars["D"] = "[1-9]";
  else formatChars["D"] = "[0-9]";

  if (monthPart.startsWith("1")) formatChars["M"] = "[0-2]";
  else if (monthPart.startsWith("0")) formatChars["M"] = "[1-9]";
  else formatChars["M"] = "[0-9]";

  return value;
};
