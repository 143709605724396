import * as React from "react";
import { SVGProps } from "react";

const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M4.166 8.541a2.3 2.3 0 0 0-2.291 2.291v5.834a2.3 2.3 0 0 0 2.291 2.291h11.668a2.3 2.3 0 0 0 2.291-2.291v-5.834a2.3 2.3 0 0 0-2.291-2.291zm0 1.25h11.668c.583 0 1.041.458 1.041 1.041v5.834c0 .583-.458 1.041-1.041 1.041H4.166a1.031 1.031 0 0 1-1.041-1.041v-5.834c0-.583.458-1.041 1.041-1.041z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 1.041a4.792 4.792 0 0 0-4.793 4.791v3.334a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625V5.832A3.54 3.54 0 0 1 10 2.291a3.54 3.54 0 0 1 3.541 3.541v3.334a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625V5.832A4.794 4.794 0 0 0 10 1.041Z"
    />
  </svg>
);

export default SvgLock;
