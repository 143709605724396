import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import {
  AgencyEmployee,
  AgencyEmployeeType,
  ApiError,
  DeepPartial,
  PaginatedResource,
} from "~/application/types";
import { agencyEmployeeService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { AgencyEmployeeDialog } from "../views/AgencyEmployee/components/AgencyEmployeeDialog";

export interface UseEmployeesResult {
  data?: AgencyEmployee[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  onGoToPage: (value: number) => void;
  onCreateAgencyEmployee: () => void;
  onEditAgencyEmployee: (item: AgencyEmployee) => void;
  onToggleAgencyEmployeeState: (item: AgencyEmployee) => void;
}

export interface UseAgencyEmployeesOptions {
  agencyId: string;
  enabled: boolean;
}

const LOG_TAG = "Agency/AgencyPage/useAgencyEmployees";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar funcionários da agência",
  CREATE_SUCCESS_MESSAGE: "Funcionário criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar funcionário",
  UPDATE_SUCCESS_MESSAGE: "Funcionário atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar funcionário",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar funcionário",
  ACTIVATE_SUCCESS_MESSAGE: "Funcionário ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar funcionário",
  INACTIVATE_SUCCESS_MESSAGE: "Funcionário inativado",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<AgencyEmployee> = {
  name: "",
  email: "",
  phone: "",
  type: AgencyEmployeeType.MANAGER,
};

export function useAgencyEmployees({
  agencyId,
  enabled,
}: UseAgencyEmployeesOptions): UseEmployeesResult {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching } = useQuery<PaginatedResource<AgencyEmployee[]>, ApiError>(
    [QueryKeys.AGENCY_EMPLOYEES, currentPage ,agencyId],
    () => agencyEmployeeService.find({ page: currentPage, agencyId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutateAsync: mutateCreateAgencyEmployee } = useMutation(
    async (item: AgencyEmployee) => await agencyEmployeeService.create({ ...item, agencyId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating AgencyEmployee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created AgencyEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_EMPLOYEES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutateAsync: mutateUpdateAgencyEmployee } = useMutation(
    async (item: AgencyEmployee) => await agencyEmployeeService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating AgencyEmployee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated AgencyEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_EMPLOYEES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: AgencyEmployee) => agencyEmployeeService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating AgencyEmployee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated AgencyEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_EMPLOYEES]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: AgencyEmployee) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando funcionário"
        onConfirm={() => mutateToggleState(item)}
      />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <AgencyEmployeeDialog
        isNew
        onSubmit={mutateCreateAgencyEmployee}
        defaultData={DEFAULT_FORM_DATA}
      />
    );
  }, [agencyId]);

  const handleOpenEditModal = useCallback((item: AgencyEmployee) => {
    dialogService.showDialog(
      <AgencyEmployeeDialog onSubmit={mutateUpdateAgencyEmployee} defaultData={item} />
    );
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page || 0,
    onGoToPage: setCurrentPage,
    onCreateAgencyEmployee: handleOpenCreateModal,
    onEditAgencyEmployee: handleOpenEditModal,
    onToggleAgencyEmployeeState: handleToggleState,
  };
}
