import { FC } from "react";
import { RoadTraveler } from "~/application/types";
import { DateFormats } from "~/application/utils/date-functions";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Divider, DividerProps } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H4, H6, Label } from "~/components/Typography";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import { displayDate, timeInterval, toHour12 } from "~/utils/date.utils";
import { RoadSegmentTravelerListItem } from "../../../RoadSegmentTravelerListItem";
import { RoadSegmentListItemProps } from "../../types";

export const RoadSegmentItem = ({
  data,
  seats,
  to,
  mt,
}: {
  to: string;
  mt?: string;
  seats?: string[];
} & RoadSegmentListItemProps) => {
  const canShowPassengers =
    data.travelers &&
    data.travelers.length > 0 &&
    data.travelers.some((t) => t.seats.length);

  return (
    <Card css={{ mt: mt ?? "$4", background: "$neutrals-lightest" }}>
      <Grid
        gap="2"
        columns="2"
        justify="between"
        align="center"
        css={{
          padding: "$4",
          width: "100%",
          borderBottom: "solid 1px $neutrals-lightest",
        }}
      >
        {data.companyImage ? (
          <CompanyLogo src={data.companyImage} alt={data.company} size="lg" />
        ) : (
          <DefaultCompanyLogo companyName={data.company} />
        )}
      </Grid>

      <Divider css={{ flexFlow: 1, borderBottom: "0.5px solid #668099" }} />

      <Grid
        gap="4"
        columns="3"
        align="center"
        css={{ py: "$4", width: "100%" }}
      >
        <Flex direction="column" gap="3" align="center">
          <Text size="3" variant="darkest">
            {displayDate(data.departureDate, DateFormats.SMALL_WEEK_DATE)}
          </Text>

          <H4>{toHour12(data.departureDate)}</H4>

          <H6
            css={{
              "@mxlg": {
                textAlign: "center",
              },
            }}
            size={{ "@mxmd": "2" }}
            variant="dark"
          >
            {data.from}
          </H6>
        </Flex>

        <Flex justify="evenly" align="center" gap="4">
          <SegmentDivider css={{ maxWidth: "$20" }} />

          <Flex direction="column" align="center" gap="2">
            <Label size="2">Duração</Label>

            <Text size="2" css={{ fw: "bold" }}>
              {timeInterval(data.departureDate, data.arrivalDate)}
            </Text>
          </Flex>

          <SegmentDivider css={{ maxWidth: "$20" }} />
        </Flex>

        <Flex direction="column" gap="3" align="center">
          <Text size="3" variant="darkest">
            {displayDate(data.arrivalDate, DateFormats.SMALL_WEEK_DATE)}
          </Text>

          <H4>{toHour12(data.arrivalDate)}</H4>

          <H6
            css={{
              "@mxlg": {
                textAlign: "center",
              },
            }}
            size={{ "@mxmd": "2" }}
            variant="dark"
          >
            {to ?? data.to}
          </H6>
        </Flex>
      </Grid>

      {seats && seats.length > 0 && (
        <>
          <Divider css={{ flexFlow: 1, borderBottom: "0.5px solid #668099" }} />

          {canShowPassengers ? (
            <RoadPassengers travelers={data.travelers} />
          ) : (
            <>
              <Flex justify="between" css={{ p: "$7" }}>
                <Flex gap="4" direction="column">
                  <Flex direction="column">
                    <Text size="2" variant="dark" css={{ mb: "$2", fw: "500" }}>
                      Tipo de assento
                    </Text>

                    <Flex>
                      <Tag variant="info-light">
                        <Text css={{ fw: "600" }}>{data.seatClass}</Text>
                      </Tag>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex direction="column" gap="4">
                  <Flex direction="column">
                    <Text
                      size="2"
                      variant="dark"
                      css={{ mb: "$3", fw: "500", display: "block" }}
                    >
                      Número do assento
                    </Text>

                    <Grid columns="3">
                      {seats &&
                        seats?.length > 0 &&
                        seats.map((seat) => (
                          <Text key={seat} size="3" css={{ fw: "600" }}>
                            {seat}
                          </Text>
                        ))}
                    </Grid>
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
        </>
      )}
    </Card>
  );
};

const SegmentDivider = (props?: DividerProps) => {
  const css = {
    flexGrow: 1,
    width: "100%",
    border: "1px solid $neutrals-dark",
    ...props?.css,
  };

  return <Divider {...props} css={css} />;
};

const RoadPassengers: FC<{ travelers?: RoadTraveler[] }> = ({ travelers }) => {
  return (
    <Card css={{ border: "none", background: "$neutrals-lightest" }}>
      <CardBody>
        <Flex
          direction="column"
          gap="6"
          css={{
            my: "$5",
            mx: "$5",
            "@mxlg": {
              m: "0"
            }
          }}
        >
          <Text size="5">Passageiros</Text>

          <LazyList
            items={travelers}
            render={(traveler) => (
              <RoadSegmentTravelerListItem
                css={{ background: "$neutrals-lightest", p: "0" }}
                data={traveler}
                key={traveler.uuid}
              />
            )}
            skeletonHeight={88}
            skeletonQuantity={2}
          />
        </Flex>
      </CardBody>
    </Card>
  );
};
