import { createContext, useContext } from "react";
import type { HotelQuery, HotelQueryLinks, Order } from "~/application/types";
import type { HotelReducer } from "../types";

export interface BookingHotelContextType {
  order?: Order;
  hotelQuery: HotelQuery | null;
  hotelQueryLinks?: HotelQueryLinks;
  isLoadingLinks: boolean;
  hotelReducer: HotelReducer;
  quantityRooms: number;
  onSearchHotels: (data: HotelQuery) => void;
  setQuantityRooms: (value: number) => void;
}

export const BookingHotelContext = createContext({} as BookingHotelContextType);

export const useBookingHotel = () => useContext(BookingHotelContext);
