import React, { FC, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createRoadQueryUrl } from "~/presentation/Booking/BookingRoad/utils";
import { RoadQueryFormData } from "~/presentation/shared/components/RoadQueryForm";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { useRoadReducer } from "../../../hooks/useRoadReducer";
import { RoadBookingBarUI } from "./RoadBookingBarUI";
import { RoadQuery } from "~/application/types";
import { useRoadParams } from "../../../hooks/useRoadParams";

export const RoadBookingBarSection: FC<{
  setCurrentStep: (step: number) => void;
  currentStep: number;
}> = ({ setCurrentStep, currentStep }) => {
  const navigate = useNavigate();

  const { roadQuery, refetchRoadQueryLinks } = useRoadsPage();
  const roadParams = useRoadParams();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const { dispatch } = useRoadReducer();

  const [isEditable, setEditable] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (data: RoadQueryFormData) => {
      const queryUrl = createRoadQueryUrl(data, orderId ?? "");

      dispatch({ type: "CLEAR" });

      if (window.location.href.includes(queryUrl)) {
        refetchRoadQueryLinks();
      } else {
        navigate(queryUrl, { replace: true });
      }

      setEditable(false);
    },
    [navigate, orderId]
  );

  const handleBarClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      // ignore clicks while editing
      if (!isEditable) {
        // Ignore clicks on the bar itself
        if (event.target !== event.currentTarget) {
          setEditable(true);
        }
      }
    },
    [isEditable]
  );

  return (
    <RoadBookingBarUI
      roadQuery={roadQuery.data as RoadQuery}
      roadParams={roadParams}
      isEditable={isEditable}
      setCurrentStep={setCurrentStep}
      currentStep={currentStep}
      onBarClick={handleBarClick}
      onQuery={onSubmit}
    />
  );
};

RoadBookingBarSection.displayName = "RoadBookingBarSection";
