import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { ApprovalModel, Approver, CostCenter, CustomerEmployee, Level } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Select, Switch, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { Caption, H5 } from "~/components/Typography";
import { AsyncSelect } from "../../components/AsyncSelect";
import { ApprovalLevelListItem } from "./ApprovalLevelListItem";
import { ApprovalModelDialogProps } from "./types";
import { getDefaultValues } from "./utils";
import { MultiSelectWrap } from "~/components/Input/MultiSelectWrap";

const approvalModelSchema = yup.object().shape({
  applyExpense: yup.boolean().default(true),
  branchName: yup
    .object()
    .shape({
      uuid: yup.string(),
      name: yup.string(),
    })
    .nullable()
    .when("isAllBranches", {
      is: false,
      then: (schema) =>
        schema.shape({
          uuid: yup.string().required("O nome da filial é obrigatório"),
          name: yup.string().required(),
        }),
      otherwise: (schema) => schema.nullable(),
    }),
  expenseApprover: yup
    .object()
    .shape({
      uuid: yup.string(),
      name: yup.string(),
    })
    .nullable()
    .when("applyExpense", {
      is: false,
      then: (schema) =>
        schema.shape({
          uuid: yup.string().required("O aprovador de despesas é obrigatório"),
          name: yup.string().required(),
        }),
      otherwise: (schema) => schema.nullable(),
    }),
  costCenters: yup.array().of(
    yup.object().shape({
      uuid: yup.string().required(),
      name: yup.string().required(),
    })
  ),
  isAllBranches: yup.boolean().default(true),
  levels: yup
    .array()
    .of(
      yup.object().shape({
        approvers: yup
          .array()
          .of(
            yup.object().shape({
              uuid: yup.string().required("O aprovador é obrigatório"),
              name: yup.string().required(),
              selfApprover: yup.boolean().default(false),
            })
          )
          .min(1, "Selecione pelo menos um aprovador")
          .required(),
      })
    )
    .min(1, "Selecione pelo menos um nível")
    .required(),
  name: yup.string().required("O nome é obrigatório"),
  outPolicyApprovalModel: yup
    .object()
    .shape({
      uuid: yup.string(),
      name: yup.string(),
    })
    .nullable(),
});

export type ApprovalModelSchema = yup.InferType<typeof approvalModelSchema>;

type ApproverSchema = {
  uuid: string;
  name: string;
  selfApprover: boolean;
};

type LevelSchema = {
  approvers: ApproverSchema[];
};

export type OnSelectApproverParams = {
  approver: Approver;
  levelIndex: number;
};

export function ApprovalModelDialog({
  isNew,
  branches,
  approvalModels,
  isLoadingApprovalModels,
  isLoadingBranches,
  costCenters: defaultCostCenters,
  isLoadingCostCenters,
  availableApprovers,
  isLoadingAvailableApprovers,
  defaultData,
  fetchApprovalModels,
  fetchApprovers,
  fetchCostCenters,
  onCloseClick,
  onSubmit,
  onCreateApprover,
  onUpdateApprover,
  onInactivateApprover,
}: ApprovalModelDialogProps) {
  const [markAllCostCenters, setCanMarkAllCostCenters] = useState(false);
  const [availableCostCenters, setAvailableCostCenters] = useState(defaultCostCenters);

  const updateCostCenters = useCallback(async () => {
    if (!fetchCostCenters) {
      return [];
    }

    return await fetchCostCenters("", defaultData?.uuid as string);
  }, [fetchCostCenters]);

  useEffect(() => {
    updateCostCenters().then((data) => setAvailableCostCenters(data || []));
  }, [fetchCostCenters, updateCostCenters]);

  const defaultValues = getDefaultValues({ defaultData: defaultData as ApprovalModel, isNew });

  const { control, formState, handleSubmit, watch, setValue, trigger } =
    useForm<ApprovalModelSchema>({
      defaultValues,
      resolver: yupResolver(approvalModelSchema),
    });
  

  const { branchName, isAllBranches, applyExpense, levels } = watch();

  const { append, update, remove, insert } = useFieldArray({
    control,
    name: "levels",
    keyName: "uuid",
  });

  const defaultApprovers = (availableApprovers ?? []).filter(
    (approver) =>
      !levels?.some((l) => l.approvers.some((a) => a.uuid === approver.uuid)) && approver.isActive
  ) as Approver[];

  const handleApproverSelection = async ({ approver, levelIndex }: OnSelectApproverParams) => {
    const level = levels.at(levelIndex);

    const hasApproverWithSameUuidInThisLevel = level?.approvers.some(
      (a) => a.uuid === approver.uuid
    );

    if (hasApproverWithSameUuidInThisLevel) {
      return;
    }

    const levelApprovers = (level?.approvers ?? []).filter((a) => a.uuid.length > 0);
    const newApprovers = [...levelApprovers, approver] as ApproverSchema[];

    update(levelIndex, { ...level, approvers: newApprovers });

    await trigger("levels");
  };

  const getApproverOptionLabel = useCallback((item: CustomerEmployee) => item.name, []);

  const getApproverOptionValue = useCallback((item: CustomerEmployee) => item.uuid, []);

  const inactivateApprovers = () => {
    defaultData?.approvers?.forEach((approver) => {
      const approverExists = levels.some(({ approvers }) =>
        approvers.some(({ uuid }) => uuid === approver.uuid)
      );

      if (!approverExists) {
        onInactivateApprover(approver.approverModelId);
      }
    });
  };

  const createOrUpdateApprovers = (levels: Level[], approvalModelId?: string) => {
    levels.forEach((level) => {
      level.approvers.forEach((approver) => {
        const currentApprover = defaultData?.approvers?.find((a) => a.uuid === approver.uuid);
        const approverData = { ...approver, approvalModelId };

        if (currentApprover) {
          return onUpdateApprover({ ...currentApprover, ...approverData });
        }

        onCreateApprover(approverData);
      });
    });
  };

  const onAddLevel = (level: Level, levelIndex: number) => {
    if (levelIndex === levels.length) {
      return append(level as LevelSchema);
    }

    insert(levelIndex, level as LevelSchema);
  };

  const onRemoveLevel = (levelIndex: number) => {
    if (levels.length === 1) {
      return;
    }

    remove(levelIndex);
  };

  const onRemoveApprover = (approver: Approver, levelIndex: number) => {
    const level = levels.at(levelIndex);
    const newApprovers = (level?.approvers ?? []).filter((a) => a.uuid !== approver.uuid);

    update(levelIndex, { ...level, approvers: newApprovers });
  };

  const handleOnSubmit = (data: ApprovalModelSchema) => {
    const formattedLevels = levels.map((level, levelIndex) => {
      return {
        ...level,
        approvers: level.approvers.map((approver) => ({
          ...approver,
          level: levelIndex + 1,
        })),
      };
    }) as Level[];

    if (!isNew) {
      inactivateApprovers();
      createOrUpdateApprovers(formattedLevels, defaultData?.uuid);
    }

    onSubmit({ ...data, levels: formattedLevels, markAllCostCenters });
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <FormDialog
          title={isNew ? "Novo modelo de aprovação" : "Editar modelo de aprovação"}
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button
              disabled={
                formState.isSubmitting ||
                isLoadingApprovalModels ||
                isLoadingAvailableApprovers ||
                isLoadingBranches ||
                isLoadingCostCenters
              }
              type="submit"
            >
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col sz="6">
                <FormControl
                  name="isAllBranches"
                  control={control}
                  required={branchName?.name?.length === 0}
                >
                  <FieldLabel>Aplica-se para todas as filiais</FieldLabel>
                  <Switch checked={isAllBranches}>
                    <Caption>{isAllBranches ? "Ativo" : "Inativo"}</Caption>
                  </Switch>
                </FormControl>
              </Col>

              <Col sz="6">
                <FormControl name="applyExpense" control={control}>
                  <FieldLabel>Aplica-se as despesas</FieldLabel>
                  <Switch checked={applyExpense}>
                    <Caption>{applyExpense ? "Ativo" : "Inativo"}</Caption>
                  </Switch>
                </FormControl>
              </Col>

              {!applyExpense && (
                <Col sz="12">
                  <FormControl name="expenseApprover" control={control} required={!applyExpense}>
                    <FieldLabel>Aprovador das Despesas</FieldLabel>
                    <Select
                      options={availableApprovers}
                      isLoading={isLoadingAvailableApprovers}
                      getOptionLabel={getApproverOptionLabel}
                      getOptionValue={getApproverOptionValue}
                      placeholder="Selecione o aprovador das despesas"
                      size="sm"
                    />
                    {formState.errors.expenseApprover?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.expenseApprover.uuid.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}

              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome</FieldLabel>
                  <TextInput placeholder="Digite o nome do modelo de aprovação" />
                  {formState.errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              {!isAllBranches && (
                <Col sz="12">
                  <FormControl
                    name="branchName"
                    control={control}
                    required={!isAllBranches}
                    disabled={isAllBranches}
                  >
                    <FieldLabel>Nome da filial</FieldLabel>
                    <Select
                      disabled={isAllBranches}
                      options={branches}
                      isLoading={isLoadingBranches}
                      getOptionLabel={(item) => item.name}
                      getOptionValue={(item) => item.uuid}
                      placeholder="Selecione a filial"
                      size="sm"
                    />
                    {formState.errors.branchName?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.branchName.uuid.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}

              <Col sz="12">
                <FormControl name="costCenters" control={control} required>
                  <FieldLabel>Nome do centro de custo</FieldLabel>
                  <MultiSelectWrap
                    size="sm"
                    placeholder="Selecione o centro de custo"
                    isLoading={isLoadingCostCenters}
                    getOptionLabel={(item: unknown) => (item as CostCenter).name}
                    getOptionValue={(item: unknown) => (item as CostCenter).uuid}
                    options={[
                      { uuid: "", name: "Desmarcar todos" },
                      { uuid: "", name: "Marcar todos" },
                      ...(availableCostCenters || []),
                    ]}
                    onInputChange={(search: string) => {
                      if (!fetchCostCenters) {
                        return;
                      }

                      fetchCostCenters(search, defaultData?.uuid).then((costCenters) => {
                        setAvailableCostCenters((old) => {
                          if (!old) return costCenters;

                          const newCostCenters = costCenters.filter(
                            (c) => !old.some((o) => o.uuid === c.uuid)
                          );
                          return [...old, ...newCostCenters];
                        });
                      });
                    }}
                    onChange={async (costCenters: { uuid: string; name: string }[]) => {
                     
                      if (costCenters.find(({ name }) => name === "Desmarcar todos")) {
                        setValue("costCenters", []);
                        return setCanMarkAllCostCenters(false);
                      }
                      if (costCenters.find(({ name }) => name === "Marcar todos")) {
                        setCanMarkAllCostCenters(true);
                        return setValue("costCenters", availableCostCenters || []);
                      }

                      setValue("costCenters", costCenters || []);
                      await trigger("costCenters");
                      return setCanMarkAllCostCenters(false)
                    }}
                  />
                  {formState.errors.costCenters && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.costCenters.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="outPolicyApprovalModel" control={control}>
                  <FieldLabel>Modelo de aprovação para política violada</FieldLabel>
                  <AsyncSelect
                    queryKey="fetch-approval-models"
                    defaultOptions={approvalModels ?? []}
                    fetchOptions={fetchApprovalModels}
                    isLoading={isLoadingApprovalModels}
                    getOptionLabel={(item) => item.name}
                    getOptionValue={(item) => item.uuid}
                    placeholder="Selecione o modelo de aprovação para a política violada"
                    size="sm"
                  />
                  {formState.errors.outPolicyApprovalModel?.uuid && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.outPolicyApprovalModel.uuid.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Box>
                {formState.errors.levels && (
                  <Text variant="error-base" size="2" css={{ fontWeight: "bold" }}>
                    {formState.errors.levels.message}
                  </Text>
                )}

                {levels.map((level, index) => (
                  <ApprovalLevelListItem
                    css={{
                      border: "1px solid $neutrals-light",
                      mt: "$2",
                      mb: "$4",
                      px: "$6",
                      py: "$6",
                      borderRadius: "$sm",
                    }}
                    key={`level-${index}`}
                    level={level as Level}
                    index={index}
                    control={control}
                    formState={formState}
                    fetchApprovers={fetchApprovers}
                    defaultApprovers={defaultApprovers}
                    onSelectApprover={handleApproverSelection}
                    onAddLevel={onAddLevel}
                    onRemoveApprover={onRemoveApprover}
                    onRemoveLevel={onRemoveLevel}
                  />
                ))}
              </Box>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
