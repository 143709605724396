import { Order } from "~/application/types";
import {
  AppBar,
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { Pagination } from "~/components/Pagination";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, CounterTabBadge, TabBar, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { GroupedCustomerOrder } from "../../utils";
import { AdvanceListItem } from "./components/AdvanceListItem";
import { AdvancesTab } from "./utils";

export interface AdvancesContainerProps {
  isLoading: boolean;
  advancesGroupsList?: GroupedCustomerOrder[];
  activeTab: AdvancesTab;
  currentPage: number;
  lastPage: number;
  tabSummary?: {
    id: AdvancesTab;
    total: number;
    title: string;
  }[];
  onCreateAdvance: () => void;
  onGoToPage: (page: number) => void;
}

export function AdvancesContainer({
  isLoading,
  advancesGroupsList,
  activeTab,
  currentPage,
  lastPage,
  tabSummary,
  onCreateAdvance,
  onGoToPage,
}: AdvancesContainerProps) {
  const orderListRenderer = useCallback(
    (item: Order) => <AdvanceListItem data={item} key={item.uuid} />,
    []
  );

  const listRenderer = useCallback(
    (item: GroupedCustomerOrder) => (
      <Box key={item.timestamp}>
        <Box css={{ mb: "$6" }}>
          <H5 variant="darkest">{item.label}</H5>
        </Box>

        <LazyList
          gap="6"
          items={item.items}
          render={orderListRenderer}
          skeletonHeight={114}
        />
      </Box>
    ),
    [orderListRenderer]
  );

  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <AppBarHeading title="Adiantamentos" />

            <AppBarActions>
              <Button
                title="Novo adiantamento"
                color="primary"
                onClick={onCreateAdvance}
              >
                <Text>Novo adiantamento</Text>
              </Button>
            </AppBarActions>
          </AppBarContainer>

          <Container>
            <TabBar>
              {isLoading || !tabSummary ? (
                <Flex gap="8" css={{ p: "$4", pl: 0 }}>
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                </Flex>
              ) : (
                tabSummary.map(({ title, total, id }) => (
                  <Link to={`#${id}`} title={title} key={id}>
                    <CounterTab id={id} value={id}>
                      <Text>{title}</Text>
                      <CounterTabBadge>{total}</CounterTabBadge>
                    </CounterTab>
                  </Link>
                ))
              )}
            </TabBar>
          </Container>
        </AppBar>

        <Container css={{ pt: "$10", pb: "$20" }}>
          <LazyList
            gap="6"
            items={advancesGroupsList}
            render={listRenderer}
            skeletonHeight={114}
            EmptyComponent={
              <EmptyState>
                <Text>Você não possui adiantamentos</Text>
              </EmptyState>
            }
          />
        </Container>
      </Tabs>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </Fragment>
  );
}
