import { FC, useCallback, useEffect, useState } from "react";
import {
  Order,
  OrderHotelItem as OrderHotel,
  OrderHotelItemType,
  OrderHotelOfflineOption,
  OrderItemStatus,
  OrderItems,
  OrderStatus,
} from "~/application/types";
import { QuoteOfflineHotelProps } from "~/application/usecases/OfflineHotel";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { Card, CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCopy, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H4, Label } from "~/components/Typography";
import { ManualEmissionButtons } from "~/core/modules/Order/pages/ManageOrderPage/views/OrderItems/components/ManualEmissionButtons";
import { QuoteOfflineHotelForm } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/QuoteOfflineHotelForm";
import { EMPTY_OPTION } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/QuoteOfflineHotelForm/constants";
import { QuoteOfflineHotelFormData } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/QuoteOfflineHotelForm/types";
import { AgencyOrderHotelOffline } from "~/presentation/shared/components/AgencyOrderHotelOffline";
import { OrderItemStatusTag } from "~/presentation/shared/components/OrderItemStatusTag";
import { TravelerListItem } from "~/presentation/shared/components/TravelerListItem";
import { asCurrency } from "~/utils/mask.utils";
import { OrderHotelItem as HotelItemType } from "~/application/types";

export type OrderHotelItemProps = {
  data: OrderHotelItemType;
  isLoading: boolean;
  onCopyText: (value: string) => void;
  onOpenHotelOptionDetails: (item: OrderHotel, option: OrderHotelOfflineOption) => void;
  onQuote: (data: QuoteOfflineHotelProps) => void;
  order?: Order;
  onDeleteQuoteOffline: (orderId: string) => void;
  onIssueOrder: () => void;
  onCancelItem: ({
    orderItemId,
    orderItemType,
  }: {
    orderItemId: string;
    orderItemType: OrderItems;
  }) => void;
  onIssueHotel: (orderData: OrderHotel) => void;
};

export const OrderHotelItem: FC<OrderHotelItemProps> = ({
  data,
  order,
  onIssueOrder,
  onCopyText,
  onOpenHotelOptionDetails,
  onQuote,
  onDeleteQuoteOffline,
  onIssueHotel,
  onCancelItem,
}: OrderHotelItemProps) => {
  const [roomExpanded, setRoomExpanded] = useState(
    data?.rooms.map((room) => {
      return {
        id: room.uuid,
        roomExpanded: true,
        checked: room.options?.some((option) => option.status === OrderItemStatus.QUOTED),
      };
    }) as Array<{ id: string; roomExpanded: boolean; checked: boolean }>
  );

  const handleOpenQuoteOfflineHotelForm = useCallback(
    (item: OrderHotel, options: OrderHotelOfflineOption[]) => {
      const onSubmit = (data: QuoteOfflineHotelFormData) => {
        onQuote({
          room: item,
          options: data.options.map((option) => ({
            uuid: option.uuid,
            description: option.description,
            address: option.address,
            phone: option.phone,
            email: option.email,
            providerValue: option.providerValue || 0,
            customerValue: option.customerValue || 0,
            hotelFee: option.hotelFee || 0,
            hotelMarkup: option.hotelMarkup || 0,
            obsIssuer: option.obsIssuer,
            obsAgency: option.obsAgency,
            paymentPix: option.paymentPix,
            paymentCreditCard: option.paymentCreditCard,
            regimen: option.regimen,
            roomType: option.roomType,
            amenities: option.amenities?.map((amenity) => ({
              uuid: amenity.uuid,
              name: amenity.name,
            })),
            status: option.status,
            note: option.note,
            checkIn: option.checkIn,
            checkOut: option.checkOut,
            otherTaxes: option.otherTaxes,
          })),
        });
      };

      const defaultData = {
        options:
          options && options.length
            ? options.map((option) => ({
                uuid: option.uuid,
                description: option.description,
                address: option.address,
                phone: option.phone,
                email: option.email,
                providerValue: option.providerValue,
                customerValue: option.customerValue,
                hotelFee: option.hotelFee,
                hotelMarkup: option.hotelMarkup,
                obsIssuer: option.obsIssuer,
                obsAgency: option.obsAgency,
                paymentPix: option.paymentPix,
                paymentCreditCard: option.paymentCreditCard,
                regimen: { name: option.regimen },
                roomType: { name: option.roomType },
                amenities: option.amenities?.map((amenity) => ({
                  uuid: amenity.uuid,
                  name: amenity.name,
                })),
                checkIn: option.checkIn || new Date(item.checkIn),
                checkOut: option.checkOut || new Date(item.checkOut),
                checkOutHour:
                  option.checkOut?.getHours().toString() || item.checkOut.getHours().toString(),
                checkInHour:
                  option.checkIn?.getHours().toString() || item.checkIn.getHours().toString(),
                otherTaxes: option.otherTaxes,
              }))
            : [
                {
                  ...EMPTY_OPTION,
                  checkIn: new Date(item.checkIn),
                  checkOut: new Date(item.checkOut),
                },
              ],
      };

      dialogService.showDialog(
        <QuoteOfflineHotelForm
          order={order as Order}
          item={item}
          defaultData={defaultData as QuoteOfflineHotelFormData}
          onSubmit={onSubmit}
        />
      );
    },
    [order]
  );

  const handleCopyQuoteOfflineHotel = useCallback(
    (item: HotelItemType, options: OrderHotelOfflineOption[]) => {
      const onSubmit = (data: QuoteOfflineHotelFormData) => {
        onQuote({
          room: data.options[0].replicateRoom,
          options: data.options.map((option) => ({
            description: option.description,
            address: option.address,
            phone: option.phone,
            email: option.email,
            providerValue: option.providerValue || 0,
            customerValue: option.customerValue || 0,
            hotelFee: option.hotelFee || 0,
            hotelMarkup: option.hotelMarkup || 0,
            obsIssuer: option.obsIssuer,
            obsAgency: option.obsAgency,
            paymentPix: option.paymentPix,
            paymentCreditCard: option.paymentCreditCard,
            regimen: option.regimen,
            roomType: option.roomType,
            amenities: option.amenities.map((amenity) => ({
              uuid: amenity.uuid,
              name: amenity.name,
            })),
            status: option.status,
            note: option.note,
            checkIn: option.checkIn,
            checkOut: option.checkOut,
          })),
        });
      };

      const defaultData = {
        options: options.length
          ? options.map((option) => ({
              uuid: option.uuid,
              description: option.description,
              address: option.address,
              phone: option.phone,
              email: option.email,
              providerValue: option.providerValue,
              customerValue: option.customerValue,
              hotelFee: option.hotelFee,
              hotelMarkup: option.hotelMarkup,
              obsIssuer: option.obsIssuer,
              obsAgency: option.obsAgency,
              paymentPix: option.paymentPix,
              paymentCreditCard: option.paymentCreditCard,
              regimen: { name: option.regimen },
              roomType: { name: option.roomType },
              amenities: option?.amenities?.map((amenity) => ({
                uuid: amenity.uuid,
                name: amenity.name,
              })),
              checkIn: option.checkIn,
              checkOut: option.checkOut,
              checkOutHour:
                option.checkOut?.getHours().toString() || item.checkOut.getHours().toString(),
              checkInHour:
                option.checkIn?.getHours().toString() || item.checkIn.getHours().toString(),
              otherTaxes: option.otherTaxes,
            }))
          : [
              {
                ...EMPTY_OPTION,
                checkIn: new Date(item.checkIn),
                checkOut: new Date(item.checkOut),
              },
            ],
      };

      dialogService.showDialog(
        <QuoteOfflineHotelForm
          isCopy={true}
          order={order as Order}
          item={item}
          defaultData={defaultData as QuoteOfflineHotelFormData}
          onSubmit={onSubmit}
        />
      );
    },
    [order, onQuote]
  );

  const toggleRoomExpanded = useCallback(
    (room: OrderHotel) => {
      setRoomExpanded((old) => {
        const index = old.findIndex((item) => item.id === room.uuid);

        const newRoom = {
          id: room.uuid,
          roomExpanded: old[index].roomExpanded ? false : true,
          checked: room.options?.some(
            (option) => option.status === OrderItemStatus.QUOTED
          ) as boolean,
        };

        return [...old.slice(0, index), newRoom, ...old.slice(index + 1)];
      });
    },
    [roomExpanded]
  );

  const renderCopyCredential = useCallback(
    (credential: string) => (
      <Tooltip content={<TooltipLabel>Copiar credencial</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(credential)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  return (
    <Flex gap="2" direction="column">
      {data.rooms.map((item, index) =>
        item.isOffline ? (
          <AgencyOrderHotelOffline
            index={index}
            item={item}
            onItemExpand={toggleRoomExpanded}
            onOpenOptionDetails={onOpenHotelOptionDetails}
            handleCopyQuoteOfflineHotelForm={handleCopyQuoteOfflineHotel}
            onOpenQuoteOfflineHotelForm={handleOpenQuoteOfflineHotelForm}
            order={order as Order}
            roomExpanded={roomExpanded}
            onDeleteQuoteOffline={onDeleteQuoteOffline}
            onCancelItem={onCancelItem}
            onIssueHotel={onIssueHotel}
          />
        ) : (
          <Card key={item.uuid}>
            <Flex direction="column" as={CardBody} gap="6">
              <Row gap="6">
                <Col sz="6">
                  <Label paragraph css={{ mb: "$2" }}>
                    Data
                  </Label>
                  <Text
                    size={{ "@mxlg": "4" }}
                    css={{
                      fw: "600",
                    }}
                  >
                    {DateUtils.format(item.createdAt, DateFormats.SMALL_DATE_TIME)}
                  </Text>
                </Col>

                <Col
                  sz="6"
                  css={{
                    "@mxlg": {
                      textAlign: "end",
                    },
                  }}
                >
                  <Label paragraph css={{ mb: "$2" }}>
                    Status
                  </Label>
                  <Text css={{ fw: "600" }}>
                    <OrderItemStatusTag
                      css={{
                        "@mxlg": {
                          fontSize: "$xxs",
                          height: "$3",
                          border: "0",
                        },
                      }}
                      data={item.status}
                    />
                  </Text>
                </Col>
              </Row>

              <Card
                css={{
                  "@mxlg": {
                    border: "0",
                    px: 0,
                  },
                }}
              >
                <CardBody
                  css={{
                    "@mxlg": {
                      px: 0,
                      overflow: "hidden",
                    },
                  }}
                >
                  <Row gap="6">
                    <Col
                      sz="6"
                      css={{
                        "@mxlg": {
                          display: "block",
                          width: "100%",
                        },
                      }}
                    >
                      <Text as="p" size="2" variant="dark" css={{ mb: "$4" }}>
                        Hotel
                      </Text>

                      <Text size={{ "@mxlg": "3" }} css={{ fw: "600" }}>
                        {item.hotel}
                      </Text>
                    </Col>

                    <Col
                      sz="6"
                      css={{
                        "@mxlg": {
                          display: "block",
                          width: "100%",
                        },
                      }}
                    >
                      <Text as="p" size="2" variant="dark" css={{ mb: "$4" }}>
                        Acomodação
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {item.accommodation}
                      </Text>
                    </Col>

                    <Col
                      sz="6"
                      css={{
                        "@mxlg": {
                          display: "block",
                          width: "100%",
                        },
                      }}
                    >
                      <Text as="p" size="2" variant="dark" css={{ mb: "$4" }}>
                        Cidade
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {item.cityName}
                      </Text>
                    </Col>

                    <Col
                      sz="6"
                      css={{
                        "@mxlg": {
                          display: "block",
                          width: "100%",
                        },
                      }}
                    >
                      <Text as="p" size="2" variant="dark" css={{ mb: "$4" }}>
                        Endereço
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {item.address}
                      </Text>
                    </Col>

                    <Col sz="6">
                      <Text as="p" size="2" variant="dark" css={{ mb: "$4" }}>
                        Check In
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {DateUtils.format(item.checkIn, DateFormats.SMALL_DATE)}
                      </Text>
                    </Col>

                    <Col
                      sz="6"
                      css={{
                        "@mxlg": {
                          textAlign: "end",
                        },
                      }}
                    >
                      <Text as="p" size="2" variant="dark" css={{ mb: "$4" }}>
                        Check Out
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {DateUtils.format(item.checkOut, DateFormats.SMALL_DATE)}
                      </Text>
                    </Col>

                    <Col
                      sz="6"
                      css={{
                        "@mxlg": {
                          display: "block",
                          width: "100%",
                        },
                      }}
                    >
                      <Text as="p" size="2" variant="dark" css={{ mb: "$4" }}>
                        Amenidades
                      </Text>

                      <Flex gap="2">
                        <Text css={{ fw: "600", wordBreak: "break-all" }}>
                          {item.amenities?.map((amenity, index) => amenity).join(", ")}
                        </Text>
                      </Flex>
                    </Col>

                    <Col
                      sz="6"
                      css={{
                        "@mxlg": {
                          display: "block",
                          width: "100%",
                        },
                      }}
                    >
                      <Text as="p" size="2" variant="dark" css={{ mb: "$4" }}>
                        Regime
                      </Text>

                      <Flex gap="2">
                        <Text css={{ fw: "600", wordBreak: "break-all" }}>{item.roomRegimen}</Text>
                      </Flex>
                    </Col>

                    {item.guests.map((guest) => (
                      <Col sz="12" key={guest.uuid}>
                        <Text as="p" size="2" variant="dark" css={{ mb: "$1" }}>
                          Hóspede
                        </Text>

                        <TravelerListItem data={guest} />
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>

              <Card
                css={{
                  "@mxlg": {
                    border: "0",
                  },
                }}
              >
                <CardBody
                  css={{
                    "@mxlg": {
                      px: 0,
                      overflow: "hidden",
                    },
                  }}
                >
                  <Row gap="2">
                    <Col sz={{ "@initial": "3", "@mxlg": "5" }}>
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Tarifa
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>
                          {asCurrency((item.value || 0) - (item.fee || 0) - (item.markup || 0))}
                        </H4>
                      </Flex>
                    </Col>

                    

                    <Col sz="3">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Fee
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>{asCurrency(item.fee || 0)}</H4>
                      </Flex>
                    </Col>
                    
                    <Col sz="2">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Markup
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>{asCurrency(item.markup || 0)}</H4>
                      </Flex>
                    </Col>
                    <Col sz={{ "@initial": "2", "@mxlg": "5" }} css={{ "@mxlg": { py: "$5" } }}>
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Valor aprovado
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>{asCurrency(item.approvedValue || 0)}</H4>
                      </Flex>
                    </Col>

                    <Col sz={{ "@initial": "2", "@mxlg": "5" }} css={{ "@mxlg": { py: "$5" } }}>
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Valor total
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }} variant="dark">
                          {asCurrency(item.value || 0)}
                        </H4>
                      </Flex>
                    </Col>

                    <Col sz="6">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Nome do fornecedor
                      </Text>
                      <Text>{item.provider}</Text>
                    </Col>

                    <Col sz="6">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Credenciais
                      </Text>

                      {item.credential && (
                        <Flex gap="2" align="center">
                          <Text>{item.credential}</Text>

                          {renderCopyCredential(item.credential)}
                        </Flex>
                      )}
                    </Col>

                    <Col sz="6">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Número do bilhete
                      </Text>

                      <Flex gap="2" align="center">
                        {item.tracker}
                      </Flex>
                    </Col>

                    <Col sz="3">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Localizador
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>{item.tracker}</H4>
                      </Flex>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <ManualEmissionButtons
                data={order as Order}
                item={item}
                onCancelItem={onCancelItem}
                onIssueItem={onIssueHotel}
                itemType={OrderItems.HOTEL}
              />
            </Flex>
          </Card>
        )
      )}
    </Flex>
  );
};

OrderHotelItem.displayName = "OrderHotelItem";
