import { Order } from "~/application/types";
import { IssuerExpenseListItem } from "./components/IssuerExpenseListItem";
import { useTabAccountabilityExpenses } from "./hooks/useTabAccountabilityExpenses";

export interface TabIssuerAccountabilityExpensesParams {
  order?: Order;
}

export function TabIssuerAccountabilityExpenses({
  order,
}: TabIssuerAccountabilityExpensesParams) {
  const {
    expenses,
    isLoadingExpenses,
    onShowVoucher,
    onEditAccountabilityExpense,
    onDeleteAccountabilityExpense,
  } = useTabAccountabilityExpenses({ order });

  return (
    <IssuerExpenseListItem
      order={order}
      expenses={expenses}
      isLoading={isLoadingExpenses}
      onShowVoucher={onShowVoucher}
      onEditClick={onEditAccountabilityExpense}
      onDeleteClick={onDeleteAccountabilityExpense}
    />
  );
}
