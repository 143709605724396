import React, { ComponentProps, ComponentType, forwardRef } from "react";
import { useFormControl } from "../../FormControl/FormControlContext";
import { SwitchBase, SwitchBaseInputProps } from "../base/SwitchBase";
import { RadioButton, RadioButtonVariants } from "./RadioButton";

export type RadioHOCProps = ComponentProps<typeof SwitchBase>;

export const createRadioHOC = <P extends RadioHOCProps = RadioHOCProps>(
  Wrapper: ComponentType<P>
) =>
  forwardRef<
    React.ElementRef<typeof Wrapper>,
    SwitchBaseInputProps & RadioButtonVariants
  >(
    (
      {
        // variants
        size,
        // others props
        name: nameProp,
        disabled: disabledProp,
        onBlur: onBlurProp,
        onChange: onChangeProp,
        value: valueProp,
        checked: checkedProp,
        ...props
      },
      forwardedRef
    ) => {
      const formControl = useFormControl();

      const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
        event
      ) => {
        if (onChangeProp) onChangeProp(event);
        if (formControl.onChange) formControl.onChange(event);
      };

      const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        if (onBlurProp) onBlurProp(event);
        if (formControl.onBlur) formControl.onBlur(event);
      };

      const name = nameProp ?? formControl.name;
      const disabled = disabledProp ?? formControl.disabled;
      const checked = checkedProp ?? valueProp === formControl.value;
      const isDirty = !!formControl.error;

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Wrapper
          type="radio"
          name={name}
          disabled={disabled}
          data-dirty={isDirty}
          onChange={handleChange}
          onBlur={handleBlur}
          value={valueProp}
          checked={checked}
          {...props}
          render={({ isChecked, isDisabled }) => (
            <RadioButton
              size={size}
              data-checked={isChecked}
              data-disabled={isDisabled}
            />
          )}
          ref={forwardedRef}
        />
      );
    }
  );
