import * as React from "react";
import { SVGProps } from "react";

const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 1.875C5.52 1.875 1.875 5.52 1.875 10S5.52 18.125 10 18.125 18.125 14.48 18.125 10 14.48 1.875 10 1.875zm0 1.25A6.866 6.866 0 0 1 16.875 10 6.866 6.866 0 0 1 10 16.875 6.866 6.866 0 0 1 3.125 10 6.866 6.866 0 0 1 10 3.125Z"
    />
    <path d="M10 15a.937.937 0 1 0 0-1.875A.937.937 0 0 0 10 15Z" />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10.549 5.68a2.813 2.813 0 0 0-3.361 2.758.625.625 0 0 0 .625.624.625.625 0 0 0 .625-.624 1.56 1.56 0 0 1 .964-1.444 1.561 1.561 0 0 1 1.465 2.742A1.56 1.56 0 0 1 10 10a.625.625 0 0 0-.625.625v.625a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625v-.19c.321-.077.66-.1.938-.285a2.81 2.81 0 0 0 .425-4.326 2.81 2.81 0 0 0-1.44-.77Z"
    />
  </svg>
);

export default SvgQuestion;
