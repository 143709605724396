import { FC } from "react";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { LoadingModalProps } from "./types";

export const LoadingModal: FC<LoadingModalProps> = ({
  isOpen = true,
  message,
  title,
  children,
}) => {
  return isOpen ? (
    <Container
      size="4"
      fixed
      css={{
        zIndex: 999999,
      }}
    >
      <Card css={{ boxShadow: "$sm", maxHeight: "100%" }}>
        <Flex
          as={CardBody}
          align="center"
          direction="column"
          gap="8"
          css={{
            width: "100%",
          }}
        >
          <Spinner />
          <Flex direction="column" gap="2">
            <Text
              css={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {title}
            </Text>
            <Text css={{ textAlign: "center", fontSize: "10px" }}>
              {message}
            </Text>
            {children}
          </Flex>
        </Flex>
      </Card>
    </Container>
  ) : null;
};

LoadingModal.displayName = "LoadingModal";
