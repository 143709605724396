import { styled } from "~/application/theme";

const Container = styled("div", {
  display: "inline-flex",
  alignItems: "flex-start",
  gap: "$2",
  fontSize: "$sm",
  fontWeight: 600,
  color: "$neutrals-dark",
  padding: "$4",

  "&[data-active=true]": {
    fill: "$success-dark",
  },

  "&[data-active=false]": {
    fill: "$error-base",
  },
});

export const Styled = {
  Container,
} as const;
