import { FC, useRef } from "react";
import { Button } from "~/components/Button";
import { FileUploadProps } from "./types";
import { Flex } from "~/components/Flex";
import { H5 } from "~/components/Typography";
import { Container } from "~/components/Container";
import { Icon } from "~/components/Icon";
import { SvgCloud, SvgUpload } from "~/components/Icon/icons";
import { Box } from "~/components/Box";

export const FileUpload: FC<FileUploadProps> = ({
  title,
  allowedExtensions,
  onUpload,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    onUpload(e.dataTransfer.files[0]);
  };

  return (
    <Container
      {...props}
      fixed
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "500px",
        background: "$neutrals-white",
        borderRadius: "$md",
      }}
      size="8"
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleOnDrop}
    >
      <Flex direction="column" align="center" gap="3">
        <Box
          css={{
            p: "$4",
            borderRadius: "$circular",
            background: "$primary-light",
          }}
        >
          <Icon size="lg" as={SvgUpload} />
        </Box>

        <H5>{title}</H5>

        <p>
          Os formatos aceitos são:{" "}
          {allowedExtensions.map((ext, index) => {
            const extension = ext.startsWith(".") ? ext : `.${ext}`;
            return index === allowedExtensions.length - 1
              ? extension
              : `${extension}, `;
          })}
        </p>

        <Button onClick={() => inputRef.current?.click()}>
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            onChange={({ target }) => target.files && onUpload(target.files[0])}
          />
          <Icon size="lg" as={SvgCloud} />
          Upload de arquivos
        </Button>
      </Flex>
    </Container>
  );
};
