import { Dispatch, Fragment, SetStateAction, useCallback, useRef } from "react";
import { Hotel, HotelDetails, HotelQuery, HotelRoom, OrderItems } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { LazyList } from "~/components/LazyList";
import { Skeleton } from "~/components/Skeleton";
import { Tab, TabBar, Tabs } from "~/components/Tabs";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H4 } from "~/components/Typography";
import { StarRating } from "~/core/shared/components/StarsRating";
import { formatNumber } from "~/utils/string.utils";
import { HotelBookingCart } from "../../../../../../../DeprecatedBooking/modules/BookingHotel/components/HotelBookingCart";
import {
  formatAccommodationText,
  formatHotelAddress,
  getHotelDataFromUrl,
  getPolicyFromUrl,
} from "../../../../utils";
import { AccommodationListItem } from "./components/AccommodationListItem";
import { HotelImage } from "./components/HotelImage";
import { HotelImagesGrid } from "./components/HotelImagesGrid";
import { LocaleCard } from "./components/LocaleCard";
import { useBookingHotel } from "../../../../contexts/BookingHotelContext";
import { Carrossel } from "../../../../components/MapViewDialog/components/Carrossel";
import { BookingHotelSteps } from "../../utils";
import { usePoliciesFormats } from "~/presentation/shared/hooks/usePoliciesFormats";
import { useUser } from "~/presentation/core/contexts/UserContext";

export interface TabSelectRoomsProps {
  isLoading: boolean;
  hotel: Hotel;
  hotelDetails?: HotelDetails;
  hotelQuery: HotelQuery;
  hotelRooms?: HotelRoom[];
  selectedAccommodations: Array<HotelRoom | null>;
  currentRoomTab: number;
  isShowCart: boolean;
  activeStep?: BookingHotelSteps;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
  setCurrentRoomTab: (index: number) => void;
  onOpenAccommodationDetails: (room: HotelRoom) => void;
  onOpenAccommodationPolicies: (room: HotelRoom) => void;
  onSelectAccommodation: (
    position: number,
    room: HotelRoom,
    element: HTMLDivElement | null
  ) => void;
  onOpenGallery: () => void;
  onScrollToAccommodations: () => void;
  onGoNextStep: () => void;
  onMapView: () => void;
}

export const HOTEL_ACCOMMODATIONS_SECTION_ID = "listagem-quartos";

export function TabSelectRooms({
  isLoading,
  hotel,
  hotelDetails,
  hotelQuery,
  setIsShowCart,
  isShowCart,
  hotelRooms,
  selectedAccommodations,
  currentRoomTab,
  activeStep,
  onGoNextStep,
  setCurrentRoomTab,
  onSelectAccommodation,
  onOpenAccommodationDetails,
  onOpenAccommodationPolicies,
  onOpenGallery,
  onScrollToAccommodations,
  onMapView,
}: TabSelectRoomsProps) {
  const { onSearchHotels } = useBookingHotel();

  const targetElementRef = useRef<HTMLDivElement | null>(null);
  const { user, contexts } = useUser();
  const customerId = user.customer?.uuid || contexts.customer?.uuid;

  hotel = hotel || getHotelDataFromUrl();

  const policy = getPolicyFromUrl() as number;

  const hotelAccommodations = usePoliciesFormats({
    customerId,
    data: hotelRooms as HotelRoom[],
    itemType: OrderItems.HOTEL,
    isRooms: true,
    lowerRoom: Number(policy),
  }) as HotelRoom[];

  const accommodationsListRenderer = useCallback(
    (item: HotelRoom, index: number) => (
      <AccommodationListItem
        data={item}
        description={formatAccommodationText(hotelQuery, currentRoomTab)}
        onSelect={() => onSelectAccommodation(currentRoomTab, item, targetElementRef?.current)}
        onOpenDetails={() => onOpenAccommodationDetails(item)}
        onOpenPolicies={() => onOpenAccommodationPolicies(item)}
        selected={selectedAccommodations[currentRoomTab]?.roomId === item.roomId}
        key={item.roomId}
      />
    ),
    [
      currentRoomTab,
      selectedAccommodations,
      onSelectAccommodation,
      onOpenAccommodationDetails,
      onOpenAccommodationPolicies,
      hotelQuery,
      targetElementRef,
    ]
  );

  return (
    <Fragment>
      <Row gap="6">
        <Col sz="12">
          <Card
            css={{
              "@mxlg": {
                backgroundColor: "transparent",
                border: 0,
              },
            }}
          >
            <CardBody
              css={{
                "@mxlg": {
                  px: "0",
                },
              }}
            >
              <Flex align="center" direction={{ "@mxlg": "column" }} gap="6" css={{ mb: "$8" }}>
                <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
                  <IconButton
                    size="lg"
                    css={{
                      "@mxlg": {
                        display: "none",
                      },
                    }}
                  >
                    <Icon as={SvgChevronLeft} onClick={() => onSearchHotels(hotelQuery)} />
                  </IconButton>
                </Tooltip>

                {hotelDetails?.images && (
                  <Box
                    css={{
                      width: "100%",
                      "@lg": {
                        display: "none",
                      },
                    }}
                  >
                    <Carrossel size={5} images={hotelDetails?.images} />
                  </Box>
                )}

                <Flex direction="column" gap="3">
                  <Flex align="center" direction={{ "@mxlg": "column" }} gap="4">
                    <H4
                      size={{ "@mxlg": "6" }}
                      css={{
                        "@mxlg": {
                          textAlign: "center",
                        },
                      }}
                    >
                      {hotel?.name}
                    </H4>

                    <StarRating stars={hotel?.stars as number} />
                  </Flex>
                  <Text
                    size="3"
                    css={{
                      fw: "500",
                      "@mxlg": {
                        textAlign: "center",
                      },
                    }}
                  >
                    {hotel?.address?.cityName}{" "}
                    <Text variant="dark">{formatHotelAddress(hotel)}</Text>
                  </Text>
                </Flex>

                <Button
                  variant="secondary"
                  css={{
                    ml: "auto",
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                  onClick={onScrollToAccommodations}
                >
                  <Text>Selecionar quartos</Text>
                </Button>
              </Flex>

              <Flex
                direction="column"
                gap="2"
                css={{
                  "@mxlg": {
                    p: 26,
                    borderRadius: "$md",
                    backgroundColor: "#FFF",
                  },
                }}
              >
                <Text size="5" css={{ fw: "500" }}>
                  Sobre o hotel
                </Text>

                {isLoading || !hotelDetails ? (
                  <Flex direction="column" gap="1">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </Flex>
                ) : (
                  <Text size="3" css={{ lineHeight: "$5" }} variant="darkest">
                    {hotelDetails?.description}
                  </Text>
                )}
                <Button
                  css={{
                    "@lg": {
                      display: "none",
                    },
                  }}
                  onClick={onMapView}
                  variant={"secondary"}
                >
                  Ver no mapa
                </Button>
              </Flex>
            </CardBody>
          </Card>
        </Col>

        <Col
          sz="9"
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
        >
          <HotelImagesGrid data-grid-style={Math.min(3, hotelDetails?.images.length ?? 3)}>
            {isLoading || !hotelDetails ? (
              <>
                <Skeleton variant="fluid" />
                <Skeleton variant="fluid" />
                <Skeleton variant="fluid" />
              </>
            ) : (
              hotelDetails.images.slice(0, 3).map((image, imageIndex) => (
                <HotelImage src={image} key={`images-${imageIndex}`}>
                  {hotelDetails.images.length > 3 && imageIndex === 2 && (
                    <Flex
                      css={{
                        position: "absolute",
                        inset: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button variant="tertiary" onClick={onOpenGallery}>
                        <Text>Ver todas as fotos</Text>
                      </Button>
                    </Flex>
                  )}
                </HotelImage>
              ))
            )}
          </HotelImagesGrid>
        </Col>

        <Col
          sz="3"
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
        >
          <LocaleCard onMapView={onMapView} style={{ height: "100%" }} />
        </Col>

        <Col sz="12" ref={targetElementRef}>
          <Card
            css={{
              "@mxlg": {
                p: 20,
              },
            }}
          >
            <CardBody
              css={{
                "@mxlg": {
                  p: "0",
                },
              }}
            >
              <Text size="5" css={{ fw: "500", mb: "$8" }}>
                Oferece
              </Text>

              {hotelDetails?.amenities && hotelDetails.amenities.length > 0 ? (
                <Text css={{ mb: "$8" }}>
                  Obs: amenidades sujeito a cobrança por parte do hotel
                </Text>
              ) : null}

              {isLoading || !hotelDetails ? (
                <Flex gap="2" align="start" wrap="wrap">
                  <Skeleton variant="tag" />
                  <Skeleton variant="tag" />
                  <Skeleton variant="tag" />
                  <Skeleton variant="tag" />
                  <Skeleton variant="tag" />
                </Flex>
              ) : hotelDetails.amenities.length > 0 ? (
                <Flex gap="2" align="start" wrap="wrap">
                  {hotelDetails.amenities.map((amenity, amenityIndex) => (
                    <Tag
                      css={{
                        "@mxlg": {
                          border: 0,
                        },
                      }}
                      variant="info-light"
                      key={`amenity-${amenityIndex}`}
                    >
                      <Text>{amenity}</Text>
                    </Tag>
                  ))}
                </Flex>
              ) : (
                <EmptyState>
                  <Text>Nenhuma amenidade oferecida</Text>
                </EmptyState>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col
          sz="8"
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
          id={HOTEL_ACCOMMODATIONS_SECTION_ID}
        >
          <Box css={{ mb: "$6" }}>
            <Tabs value={currentRoomTab} setValue={setCurrentRoomTab}>
              <Card style={{ display: "inline-flex" }}>
                <TabBar>
                  {selectedAccommodations.map((_room, roomIndex) => (
                    <Tab value={roomIndex} key={roomIndex}>
                      <Text>Quarto {formatNumber(1 + roomIndex, 2)}</Text>
                    </Tab>
                  ))}
                </TabBar>
              </Card>
            </Tabs>
          </Box>

          <LazyList items={hotelAccommodations} render={accommodationsListRenderer} />
        </Col>

        <Col sz="4">
          <HotelBookingCart
            isShowCart={isShowCart}
            setIsShowCart={setIsShowCart}
            onGoNextStep={onGoNextStep}
            rooms={selectedAccommodations}
            activeStep={activeStep}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
