import { createContext } from "react";

export type StepperContextType<TValue extends string | number = any> = {
  registers: Record<string | number, number>;
  activeValue: TValue;
  setActiveValue?: (value: TValue) => void;
  register: (value: TValue) => number;
};

export const StepperContext = createContext({} as StepperContextType);
