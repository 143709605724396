import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { keyframes } from "~/application/theme";
import { JudgementStatus, Order, OrderItems, OrderStatus } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { Alert, AlertIcon } from "~/components/Alert";
import { Button } from "~/components/Button";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { DialogBody } from "~/components/Dialog";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgChevronDown,
  SvgChevronUp,
  SvgExpenses,
  SvgOrder,
  SvgPerson,
  SvgTicket,
  SvgWhatsapp,
} from "~/components/Icon/icons";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import { Label } from "~/components/Typography";
import { userIsIssuer } from "~/core/modules/Order/utils";
import { OrderStatusSteps } from "~/core/shared/components/OrderStatusSteps";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { CartApprovalButtons } from "~/presentation/shared/components/CartApprovalButtons";
import { NotifyIssuerButton } from "~/presentation/shared/components/NotifyIssuerButton";
import { OrderStatusTag } from "~/presentation/shared/components/OrderStatusTag";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { useOrderApproval } from "~/presentation/shared/hooks/useOrderApproval/useOrderApproval";
import { isValidDate } from "~/utils/date.utils";
import * as MaskUtils from "~/utils/mask.utils";
import { ApprovalOrderContext } from "../../hooks/ApprovalOrderContext";
import { canShowApprovalButtons, getTotalProviderFees } from "../../utils";
import { ApproversList } from "../ApproversList";

export interface ApproverOrderCartProps {
  order?: Order;
  isLoading: boolean;
  isIssuingOrder: boolean;
  isRebookingRoad: boolean;
  isRoadRebookError: boolean;
  isSuccessfulRoadRebooking: boolean;
  onSendVoucherInWhatsapp: () => void;
  onNotifyIssuerThatOrderExpired: () => void;
  onRequestOrderCancellation: () => void;
}

export function ApproverOrderCart({
  order,
  isLoading,
  isIssuingOrder,
  isRebookingRoad,
  isRoadRebookError,
  onSendVoucherInWhatsapp,
  onNotifyIssuerThatOrderExpired,
  onRequestOrderCancellation,
}: ApproverOrderCartProps) {
  const { user } = useUser();
  const context = useContext(ApprovalOrderContext);
  const approvers = order?.approvalModel?.approvers;
  const {
    cannotApproveItems,
    cannotReproveItems,
    amountOfItems,
    onConsultOrderItemsPrice,
    onOrderItemsPriceChange,
    handleJudgment,
  } = context;

  const items = context.items || { approved: [], rejected: [] };

  const { handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const airwaySeatsValue = order?.items.airway?.fees?.seatValue;
  const isMobile = useMobile();
  const [cartApproverIsOpen, setApproverCartIsOpen] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const shake = useMemo(() => {
    return keyframes({
      "0%": {
        height: "5%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
      "25%": {
        height: "3%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
      "50%": {
        height: "6%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
      "75%": {
        height: "5%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
      "100%": {
        height: "5%",
        boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
      },
    });
  }, []);

  useEffect(() => {
    if (
      items.rejected.length === amountOfItems &&
      items.rejected.every((item) => item.reasonRejected !== "")
    ) {
      const timer = setTimeout(() => setShouldAnimate(true), 1000);

      return () => clearTimeout(timer);
    }
  }, [items?.rejected]);

  useEffect(() => {
    if (items.approved.length === amountOfItems) {
      setApproverCartIsOpen(true);
    }
  }, [items.approved]);

  const cancellationTax = order?.items.road?.travels.reduce(
    (prev, curr) => (prev += curr?.cancellationTax ?? 0),
    0
  );

  const isUserOrderApprover = approvers?.some(
    ({ approvalStatus, uuid }) =>
      uuid === user.profiles.customer.uuid && approvalStatus === JudgementStatus.APPROVED
  );

  const isUserOrderIssuer = userIsIssuer(user, order);
  const isUserCustomerAndNonIssuerAndNonIssuer =
    !isUserOrderIssuer && !isUserOrderApprover && user.context === "customer";

  const orderStatus = order?.status as OrderStatus;

  const canOrderBeCanceled = ![
    OrderStatus.CANCELED,
    OrderStatus.CANCELING,
    OrderStatus.ON_APPROVAL,
  ].includes(orderStatus);

  const { onSubmit } = useOrderApproval({
    handleSubmit,
    handleJudgment,
    canShowApprovalButtons: canShowApprovalButtons(order),
    onConsultOrderItemsPrice,
    onOrderItemsPriceChange,
    isRebookingRoad,
    isRoadRebookError,
    cannotApproveItems,
  });

  function getApproveButtonText() {
    if (orderStatus === OrderStatus.APPROVED) {
      return "Emitindo pedido";
    }

    return "Aprovar itens do pedido";
  }

  const totalProviderFees = getTotalProviderFees(order);
  const returnFeeAnotherCity = order?.items.vehicle?.vehicles.reduce(
    (acc, item) => acc + (item.returnFeeAnotherCity || 0),
    0
  );

  const isOfflineQuote = (order: Order | undefined) => {
    if (!order) return false;

    const { itemsIncluded, items } = order;

    const hasOfflineHotel = itemsIncluded.includes(OrderItems.HOTEL_OFFLINE);

    const hasOfflineRoom = items.hotel?.rooms?.some((room) => room.isOffline) || false;

    return hasOfflineHotel || hasOfflineRoom;
  };

  return (
    <Flex direction="column">
      <Form>
        <DialogBody>
          <Cart
            css={{
              borderBottomLeftRadius: "$none",
              borderBottomRightRadius: "$none",
              "@mxlg": {
                position: "fixed",
                width: "100%",
                zIndex: "9999",
                left: "0",
                height: cartApproverIsOpen ? "590px" : "50px",
                overflowY: "scroll",
                transition: "$slow",
                bottom: "0",
                backgroundColor: "#003161",
                borderRadius: "0",
                animation: shouldAnimate ? `${shake} 2s infinite` : undefined,
              },
            }}
          >
            <CartHeader css={{ width: "100%" }}>
              {!isMobile ? (
                <>
                  <Icon as={SvgExpenses} size="sm" />
                  <Text>Resumo do pedido</Text>
                </>
              ) : (
                <Flex
                  justify="center"
                  css={{ width: "100%" }}
                  onClick={() => {
                    if (shouldAnimate) {
                      setApproverCartIsOpen((old) => !old);
                      setShouldAnimate(false);
                    } else {
                      setApproverCartIsOpen((old) => !old);
                    }
                  }}
                >
                  <Icon as={cartApproverIsOpen ? SvgChevronDown : SvgChevronUp} />
                </Flex>
              )}
            </CartHeader>

            {isValidDate(order?.expirationDate) && (
              <>
                <CardBody
                  css={{
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  <Alert variant={order?.isExpired ? "error" : "info"}>
                    <AlertIcon />

                    <Text>
                      {order?.isExpired ? "Expirado em " : "Expira em "}
                      {DateUtils.format(order?.expirationDate || "", DateFormats.LONG_DATE)}
                    </Text>
                  </Alert>
                </CardBody>

                <Divider css={{ "@mxlg": { display: "none" } }} />
              </>
            )}

            <OrderStatusSteps status={orderStatus} isOfflineQuote={isOfflineQuote(order)} />

            <Divider css={{ "@mxlg": { display: "none" } }} />

            <CardBody>
              <Flex direction="column" gap="6">
                <Text size="3" fw="600" css={{ "@mxlg": { color: "White" } }}>
                  Informações do pedido
                </Text>

                <Flex direction="column" gap="6">
                  {isLoading || !order ? (
                    <Skeleton variant="text-6" />
                  ) : (
                    <Flex gap="2" align="center">
                      <Col>
                        <Label css={{ "@mxlg": { color: "White" } }}>Solicitante</Label>
                      </Col>

                      <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                        {order.issuer.name}
                      </Text>
                    </Flex>
                  )}

                  {isLoading || !order ? (
                    <>
                      <Skeleton variant="text-4" />
                      <Skeleton variant="text-4" />
                      <Skeleton variant="text-4" />
                    </>
                  ) : (
                    order.itemsIncluded.map((item) => (
                      <Flex gap="2" align="center" key={item}>
                        <Icon variant="dark" as={OrderUtils.getServiceIcon(item)} size="sm" />

                        <Col>
                          <Label css={{ "@mxlg": { color: "White" } }}>
                            {OrderUtils.getServiceLabel(item)}
                          </Label>
                        </Col>

                        <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                          {MaskUtils.asCurrency(order.items[item]?.value ?? 0)}
                        </Text>
                      </Flex>
                    ))
                  )}

                  {!!returnFeeAnotherCity && (
                    <Flex gap="2" align="center">
                      <Icon variant="dark" as={SvgOrder} size="sm" />

                      <Col>
                        <Label css={{ "@mxlg": { color: "White" } }}>
                          Taxa de devolução em outra loja
                        </Label>
                      </Col>

                      <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                        {MaskUtils.asCurrency(returnFeeAnotherCity)}
                      </Text>
                    </Flex>
                  )}

                  {isLoading || !order ? (
                    <Skeleton variant="text-4" />
                  ) : (
                    <>
                      <Flex gap="2" align="center">
                        <Icon variant="dark" as={SvgOrder} size="sm" />

                        <Col>
                          <Label css={{ "@mxlg": { color: "White" } }}>Taxas e encargos</Label>
                        </Col>

                        <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                          {MaskUtils.asCurrency(totalProviderFees)}
                        </Text>
                      </Flex>

                      {order?.totalFees > 0 && (
                        <Flex gap="4" align="center">
                          <Icon variant="dark" as={SvgOrder} size="sm" />
                          <Col>
                            <Label>Taxa de serviço</Label>
                          </Col>
                          <Text variant="dark" size="2">
                            {MaskUtils.asCurrency(order.totalFees)}
                          </Text>
                        </Flex>
                      )}
                    </>
                  )}

                  {!!airwaySeatsValue && (
                    <Flex gap="2" align="center">
                      <Icon variant="dark" as={SvgTicket} size="sm" />

                      <Col>
                        <Label css={{ "@mxlg": { color: "White" } }}>Valor dos assentos</Label>
                      </Col>

                      <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                        {MaskUtils.asCurrency(airwaySeatsValue)}
                      </Text>
                    </Flex>
                  )}

                  {!!cancellationTax && (
                    <Flex gap="2" align="center">
                      <Col>
                        <Label css={{ color: "$error-base" }}>Taxa de cancelamento</Label>
                      </Col>

                      <Text variant="dark" css={{ color: "$error-base" }} size="2">
                        {MaskUtils.asCurrency(cancellationTax)}
                      </Text>
                    </Flex>
                  )}

                  {isLoading || !order ? (
                    <Skeleton variant="text-4" />
                  ) : (
                    <Flex gap="2" align="center">
                      <Col>
                        <Label css={{ "@mxlg": { color: "White" } }}>Centro de custo</Label>
                      </Col>

                      <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                        {order.costCenter.name}
                      </Text>
                    </Flex>
                  )}

                  {isLoading || !order ? (
                    <Skeleton variant="text-4" />
                  ) : (
                    <Flex gap="2" align="center">
                      <Col>
                        <Label css={{ "@mxlg": { color: "White" } }}>Status do pedido</Label>
                      </Col>
                      <OrderStatusTag data={orderStatus} />
                    </Flex>
                  )}

                  {isLoading || !order ? (
                    <Skeleton variant="text-6" />
                  ) : (
                    <Flex gap="4" align="center">
                      <Col>
                        <Label
                          variant="black"
                          size="5"
                          fw="700"
                          css={{ "@mxlg": { color: "#FFF" } }}
                        >
                          Total
                        </Label>
                      </Col>

                      <Text size="5" fw="700" css={{ "@mxlg": { color: "#FFF" } }}>
                        {MaskUtils.asCurrency(order.totalValue)}
                      </Text>
                    </Flex>
                  )}

                  {isMobile && <ApproversList />}

                  {order?.approvalModel?.approvalType === "single" &&
                    order.status === OrderStatus.APPROVED &&
                    isUserOrderApprover && (
                      <Flex gap="2" align="center">
                        <Icon variant="dark" as={SvgPerson} size="sm" />

                        <Col>
                          <Text variant="dark" css={{ fw: 700 }} size="2">
                            Aprovado por você
                          </Text>
                        </Col>
                      </Flex>
                    )}
                </Flex>

                <Flex direction="column" gap="3">
                  {canOrderBeCanceled && isUserCustomerAndNonIssuerAndNonIssuer && (
                    <Alert variant="warning">
                      <AlertIcon />
                      <Text css={{ lineHeight: "1.25" }}>
                        Apenas o solicitante do pedido pode solicitar o cancelamento.
                      </Text>
                    </Alert>
                  )}

                  {isUserOrderIssuer && (
                    <>
                      <NotifyIssuerButton
                        enabled={order?.isExpired}
                        onNotifyIssuer={onNotifyIssuerThatOrderExpired}
                      />

                      {orderStatus === OrderStatus.ISSUED && (
                        <Button variant="success" onClick={onSendVoucherInWhatsapp}>
                          <Icon as={SvgWhatsapp} />
                          <Text>Enviar voucher no whatsapp</Text>
                        </Button>
                      )}
                    </>
                  )}

                  <CartApprovalButtons
                    isIssuingOrder={isIssuingOrder}
                    onSubmit={onSubmit}
                    isRebookingRoad={isRebookingRoad}
                    getApproveButtonText={getApproveButtonText}
                    cannotApproveItems={cannotApproveItems}
                    cannotReproveItems={cannotReproveItems}
                  />

                  {canOrderBeCanceled && isUserOrderIssuer && (
                    <Button
                      variant="error"
                      onClick={onRequestOrderCancellation}
                      disabled={!canOrderBeCanceled || isUserCustomerAndNonIssuerAndNonIssuer}
                    >
                      <Text>Cancelar pedido</Text>
                    </Button>
                  )}
                </Flex>
              </Flex>
            </CardBody>
          </Cart>

          {!isMobile && <ApproversList />}
        </DialogBody>
      </Form>
    </Flex>
  );
}
