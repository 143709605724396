import * as React from "react";
import { SVGProps } from "react";

const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 18.5H0h1ZM3.5 16v-1 1ZM17 17a1 1 0 1 0 0-2v2ZM3.5 1V0v1ZM17 1h1a1 1 0 0 0-1-1v1Zm0 20v1a1 1 0 0 0 1-1h-1ZM1 3.5H0h1Zm1 15c0-.398.158-.78.44-1.06l-1.415-1.415A3.5 3.5 0 0 0 0 18.5h2Zm.44-1.06A1.5 1.5 0 0 1 3.5 17v-2a3.5 3.5 0 0 0-2.475 1.025L2.44 17.44ZM3.5 17H17v-2H3.5v2Zm0-15H17V0H3.5v2ZM16 1v20h2V1h-2Zm1 19H3.5v2H17v-2ZM3.5 20a1.5 1.5 0 0 1-1.06-.44l-1.415 1.415A3.5 3.5 0 0 0 3.5 22v-2Zm-1.06-.44A1.5 1.5 0 0 1 2 18.5H0a3.5 3.5 0 0 0 1.025 2.475L2.44 19.56ZM2 18.5v-15H0v15h2Zm0-15c0-.398.158-.78.44-1.06L1.024 1.024A3.5 3.5 0 0 0 0 3.5h2Zm.44-1.06A1.5 1.5 0 0 1 3.5 2V0a3.5 3.5 0 0 0-2.475 1.025L2.44 2.44Z"
      fill="#079455"
    />
  </svg>
);

export default SvgBook;
