import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LogTagProvider } from "~/presentation/core/providers/LogTagProvider";
import {
  RoadQueryFormData,
  TravelRoadQueryForm,
} from "~/presentation/shared/components/RoadQueryForm";
import { createRoadQueryUrl } from "../../utils";
import { RoadQueryPageUI } from "./ui/RoadQueryPageUI";
import { useRoadReducer } from "../RoadsPage/hooks/useRoadReducer";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "~/application/usecases";
import { QueryKeys } from "~/constants";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingRoad/RoadQueryPage";

const DEFAULT_FORM_DATA: Partial<RoadQueryFormData> = {
  stretches: [{} as TravelRoadQueryForm],
};

export function RoadQueryPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("orderId");

  const { data: order } = useQuery(
    [QueryKeys.ORDERS, orderId],
    async () => await orderService.findById(orderId!),
    {
      enabled: !!orderId,
    }
  );
  const { dispatch } = useRoadReducer();

  const onQuery = useCallback(
    async (data: RoadQueryFormData) => {
      const queryUrl = createRoadQueryUrl(data, orderId ?? "");
      dispatch({ type: "CLEAR" });
      navigate(queryUrl, {
        replace: true,
      });
    },
    [navigate, orderId, dispatch]
  );

  return (
    <LogTagProvider logTag={LOG_TAG}>
      <RoadQueryPageUI defaultQuery={DEFAULT_FORM_DATA} onQuery={onQuery} order={order} />
    </LogTagProvider>
  );
}
