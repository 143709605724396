import { useCallback } from "react";
import { NavigatorUtils } from "~/application/utils";
import { Icon } from "~/components/Icon";
import { SvgCopy } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";

export function useCopyTracker() {
  const onCopyText = useCallback(
    (value: string) => NavigatorUtils.copyToClipboard(value),
    []
  );

  const renderCopyTracker = useCallback(
    (tracker: string) => (
      <Tooltip content={<TooltipLabel>Copiar localizador</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(tracker)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  return { renderCopyTracker };
}

