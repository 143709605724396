import dayjs from "dayjs";
import pt from "dayjs/locale/pt";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.locale({
  ...pt,
  weekStart: 0,
});

export type DateType = Date | string | dayjs.Dayjs;

export enum DateFormats {
  ISO_TIME_DATE = "YYYY-MM-DD 23:59",
  ISO_DATE = "YYYY-MM-DD",
  ISO_DATE_TIME = "YYYY-MM-DDTHH:mm",
  SMALL_DATE = "DD/MM/YYYY",
  TIMELY_MONTH = "DD [de] MMMM, HH:mm",
  BASIC_DATE = "DD [de] MMMM",
  LONG_DATE = "DD [de] MMMM, YYYY",
  LONG_BRAZILIAN_DATE = "DD [de] MMMM [de] YYYY",
  RANGE_DATE = "YYYY-MM-DD HH:mm:ss",
  SMALL_DATE_TIME = "DD/MM/YYYY HH:mm",
  LONG_DATE_TIME = "D [de] MMMM, YYYY [às] HH:mm",
  SMALL_WEEK_DATE = "ddd, D MMM",
  SMALL_COMPLETE_WEEK_DATE = "dddd, D [de] MMMM [de] YYYY",
  MONTH_YEAR = "MMMM, YYYY",
  HOURS_MINUTES = "HH:mm",
  HOURS = "HH[h]mm",
}

export function displayDate(
  date: Date | string,
  format: string = DateFormats.LONG_DATE
): string {
  return dayjs(date).format(format);
}

export function timeInterval(a: Date, b: Date): string {
  return displayTimestamp(Math.abs(a.getTime() - b.getTime()));
}

export function displayTimestamp(timestamp: number): string {
  const seconds = timestamp / 1000;

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + "d " : "";
  const hDisplay = h > 0 ? h + "h " : "";
  const mDisplay = m > 0 ? m + "m " : "";
  const sDisplay = s > 0 ? s + "s " : "";

  return (dDisplay + hDisplay + mDisplay + sDisplay).trim();
}

export function displayDurationInSeconds(seconds: number) {
  dayjs.extend(duration);
  return dayjs.duration(seconds, "second").format("DD[d] HH[h] mm[m]");
}

export function toHour12(date: Date | string, hour12 = false) {
  return dayjs(date)
    .toDate()
    .toLocaleString("pt-br", {
      hour: "numeric",
      minute: "numeric",
      hour12: hour12,
    })
    .toLowerCase();
}

export function toDate(value: string, format?: string): Date {
  return dayjs(value, format).toDate();
}

export function toISOString(value: DateType): string {
  return dayjs(value).format("YYYY-MM-DD");
}

export function daysBetween(date1: Date, date2: Date) {
  return dayjs(date1).diff(dayjs(date2), "d");
}

export function getMouthRange(date: Date) {
  return {
    start: dayjs(date).startOf("month").startOf("week"),
    end: dayjs(date).endOf("month").endOf("week"),
  };
}

export function getMonthName(date: Date): string {
  return dayjs(date).format("MMMM");
}

export function getPreviousMonth(date: Date): Date {
  return dayjs(date).subtract(1, "month").toDate();
}

export function getNextMonth(date: Date): Date {
  return dayjs(date).add(1, "month").toDate();
}

export const areSameMonth = (first: Date, second: Date) => {
  return dayjs(first).isSame(dayjs(second), "month");
};

export function getDayAtStart(date: Date): Date {
  return dayjs(date).startOf("day").toDate();
}

export function getTimeFromNow(date: Date | string): string {
  const pastDate = dayjs(date);
  const nowDate = dayjs();

  const diff = nowDate.diff(pastDate);

  if (diff <= 1000 * 60) return "Há poucos segundos";
  if (diff <= 1000 * 60 * 60)
    return `Há ${nowDate.diff(pastDate, "m")} minutos`;
  if (diff <= 1000 * 60 * 60 * 24)
    return `Há ${nowDate.diff(pastDate, "h")} horas`;
  if (diff <= 1000 * 60 * 60 * 24 * 30)
    return `Há ${nowDate.diff(pastDate, "d")} dias`;
  if (diff <= 1000 * 60 * 60 * 24 * 365)
    return `Há ${nowDate.diff(pastDate, "M")} meses`;
  return `Há ${nowDate.diff(pastDate, "y")} anos`;
}

export function isValidDate(date?: Date | string): boolean {
  return dayjs(date).isValid();
}

export function getCurrentYear() {
  return dayjs().year();
}

export function getYearsRange(startYear?: number, range = 4) {
  if (!startYear) {
    startYear = getCurrentYear();
  }

  return Array.from({ length: range }, (_, i) => {
    const year = dayjs().year(startYear as number).add(i, 'year').year();
    return { label: String(year), value: year };
  });
}

export function getWeekdays(): string[] {
  return dayjs.localeData().weekdays();
}

export function getDaysInMonth(date: DateType): number {
  return dayjs(date).daysInMonth();
}

export function getWeekStartOffset(date: DateType): number {
  const dayjsDate = dayjs(date).startOf("month");

  return dayjsDate.diff(dayjsDate.startOf("week"), "d");
}

export function isDateValid(date: Date) {
  return date instanceof Date && !isNaN(date.getTime());
}

export const isSameDay = (date1: Date | string, date2: Date | string) => {
  return dayjs(date1).isSame(dayjs(date2), "day");
};


export { dayjs };

export function validateDate(value: string) {
  if (typeof value !== "string") {
    return false;
  }

  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
    return false;
  }

  const partsData = value.split("/");
  const data = {
    day: partsData[0],
    month: partsData[1],
    ano: partsData[2],
  };

  const day = parseInt(data.day);
  const month = parseInt(data.month);
  const ano = parseInt(data.ano);

  const daysMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (ano % 400 === 0 || (ano % 4 === 0 && ano % 100 !== 0)) {
    daysMonth[2] = 29;
  }

  if (month < 1 || month > 12 || day < 1) {
    return false;
  } else if (day > daysMonth[month]) {
    return false;
  }

  return true;
}
