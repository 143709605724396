import { useContext } from "react";
import { FormControlContext } from "~/components/FormControl/FormControlContext";
import { MultiSelect } from "../MultiSelect";
import { SelectBaseProps } from "../base/SelectBase";

export type CheckMarkMultiSelectProps<TOption = unknown> =
  SelectBaseProps<TOption>;

export const CheckMarkMultiSelect = ({
  options,
  name: nameProps,
  disabled: disabledProps,
  onBlur: onBlurProps,
  value: valueProps,
  ...props
}: CheckMarkMultiSelectProps) => {
  const formControl = useContext(FormControlContext);
  
  const name = nameProps ?? formControl.name;
  const disabled = disabledProps ?? formControl.disabled;
  const isDirty = !!formControl.error;
  const onBlur = onBlurProps ?? formControl.onBlur;
 
  return (
    <MultiSelect
      {...props}
      name={name}
      value={valueProps}
      disabled={disabled}
      onBlur={onBlur}
      isDirty={isDirty}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      options={options}
    />
  );
};

CheckMarkMultiSelect.displayName = "MultiSelect";
