import { useQuery } from "@tanstack/react-query";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { flightDetailsService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { snackbarService } from "~/components/SnackbarStack";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import {
  UseQueryFlightDetailsOptions,
  UseQueryFlightDetailsReturn,
} from "./types";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar opções",
} as const;

export function useQueryFlightDetails({
  flight,
  flightOrigin,
  options,
}: UseQueryFlightDetailsOptions): UseQueryFlightDetailsReturn {
  const { LOG_TAG } = useLogTag();

  const {
    data,
    isLoading,
    isFetching,
    isError,
    refetch: onRefetch,
  } = useQuery(
    [QueryKeys.FLIGHT_DETAILS, flight?.hash, flightOrigin],
    () => flightDetailsService.find(flight, flightOrigin),
    {
      ...options,
      staleTime: QueryTimes.NORMAL,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  return {
    data,
    isFetching,
    isLoading,
    isError,
    onRefetch,
  };
}
