import { ComponentProps } from "react";
import { keyframes, styled } from "~/application/theme";

export const pulse = keyframes({
  "0%": {
    transform: "scale(0.95)",
    boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
  },
  "70%": {
    transform: "scale(1)",
    boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
  },
  "100%": {
    transform: "scale(0.95)",
    boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
  },
});

const PulseRoot = styled("div", {
  background: "transparent",
  borderRadius: "50%",
  margin: "10px",
  height: "auto",
  width: "auto",
  boxShadow: "0 0 0 0 rgba(0, 100, 197, 0.7)",
  transform: "scale(1)",
  animation: `${pulse} 2s infinite`,
});

type PulseProps = ComponentProps<typeof PulseRoot>;

export function Pulse({ children, ...props }: PulseProps) {
  return <PulseRoot {...props}>{children}</PulseRoot>;
}
