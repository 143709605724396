import * as React from "react";
import { SVGProps } from "react";

const SvgCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 13H4.5a3.5 3.5 0 1 1 .87-6.892M5 8a5 5 0 1 1 9 3m-6.62-.88L9.5 8m0 0 2.122 2.12M9.5 8v5"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCloud;
