import { BudgetView } from "~/application/types";
import { BudgetViewDTO } from "../dtos";

export function mapBudgetViewDTO(data: BudgetViewDTO): BudgetView {
  return {
    budgetId: data.uuid,
    name: data.name,
    projectName: data.project_name,
    months: data.months.map((month) => ({
      name: month.name,
      forecast: month.predicted,
      consumed: month.consumed,
      balance: month.balance,
    })),
    total: {
      forecast: data.total.predicted,
      consumed: data.total.consumed,
      balance: data.total.balance,
    },
  };
}
