import queryString from "query-string";

import { routes } from "~/application/theme/routes";
import { OfflineHotel } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit, SvgHotelOffline } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { Link } from "~/components/Link";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import * as MaskUtils from "~/utils/mask.utils";

export interface OfflineHotelListItemProps extends DataListItemProps {
  data: OfflineHotel;
  onToggleState: (item: OfflineHotel) => void;
}

export function OfflineHotelListItem({
  data,
  onToggleState,
  ...props
}: OfflineHotelListItemProps) {
  const editOfflineHotelUrl = routes.Configuration.OfflineHotels.OfflineHotel.EditOfflineHotel.root
    .replace(':offlineHotelId', data.uuid);

  const offlineHotelRoomsUrl = routes.Configuration.OfflineHotels.OfflineHotel.OfflineHotelRoom.root
    .replace(':offlineHotelId', data.uuid);

  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{
        "@mxlg": {
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Nome" data={data.description} />
      <DataColItem headerTitle="CNPJ" data={data.cnpj ? MaskUtils.asCNPJ(data.cnpj) : null} />
      <DataColItem headerTitle="Cidade" data={data.city.name} />
      <DataColItem
        headerTitle="Editar"
        data={
          <Tooltip content={<TooltipLabel>Editar hotel offline</TooltipLabel>}>
            <Link to={editOfflineHotelUrl}>
              <IconButton size="md">
                <Icon as={SvgEdit} />
              </IconButton>
            </Link>
          </Tooltip>
        }
      />
      <DataColItem
        headerTitle="Quartos"
        data={
          <Tooltip content={<TooltipLabel>Visualizar quartos</TooltipLabel>}>
            <Link
              to={queryString.stringifyUrl({
                url: offlineHotelRoomsUrl,
                query: { hotel_description: data.description },
              })}
            >
              <IconButton size="md">
                <Icon as={SvgHotelOffline} />
              </IconButton>
            </Link>
          </Tooltip>
        }
      />
      <DataColItem
        headerTitle={data.isActive ? "Inativar" : "Ativar"}
        data={
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        }
      />
    </DataListItem>
  );
}

OfflineHotelListItem.displayName = "OfflineHotelListItem";
