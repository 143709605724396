import { decodeBase64 } from "~/core/modules/Customer/pages/CustomerPage/views/Crm/utils";
import { CustomerCrmDTO } from "../dtos/CustomerCrmDTO";
import { CustomerCRM } from "~/application/types/entities/CustomerCrm.type";

export function mapCustomerCrmDTO(data: CustomerCrmDTO): CustomerCRM {

  return {
     uuid: data.uuid,
     serviceType: data.service_type,
     isActive: data.active,
     obs: decodeBase64(data.obs)
  };
}
