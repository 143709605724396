import type { ExpensePolicy } from "~/application/types";

export type EditableExpensePolicy = ExpensePolicy;

const DEFAULT_FORM_DATA = {
  voucherRequired: false,
} as EditableExpensePolicy;

export function createExpensePolicy(
  editable: EditableExpensePolicy
): ExpensePolicy {
  return {
    ...editable,
    value: parseFloat(editable.value?.toString())
  };
}

export function editExpensePolicy(data?: ExpensePolicy): EditableExpensePolicy {
  if (!data) return DEFAULT_FORM_DATA;

  return {
    ...data,
  };
}
