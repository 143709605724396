import { Order, OrderItems } from "~/application/types";
import { HotelQuery } from "~/application/types";
import { Box } from "~/components/Box";
import { BookingMenuLayout } from "../../../../../../shared/BookingMenuLayout";
import { HotelQueryForm } from "../../components/HotelQueryForm";

export interface HotelQueryContainerProps {
  defaultQuery: Partial<HotelQuery>;
  order?: Order;
  onSearchHotels: (data: HotelQuery) => void;
  setQuantityRooms?: (value: number) => void;
}

export function HotelQueryContainer({
  defaultQuery,
  onSearchHotels,
  setQuantityRooms,
  order,
}: HotelQueryContainerProps) {
  return (
    <BookingMenuLayout activeTab={OrderItems.HOTEL} title="Encontre hospedagens para sua equipe.">
      <Box>
        <HotelQueryForm
          defaultData={defaultQuery}
          onSubmit={onSearchHotels}
          setQuantityRooms={setQuantityRooms}
          order={order}
        />
      </Box>
    </BookingMenuLayout>
  );
}
