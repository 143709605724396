import * as React from "react";
import { SVGProps } from "react";

const SvgMop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mop_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={17}
    >
      <path fill="#D9D9D9" d="M0 .941h16v16H0z" />
    </mask>
    <g mask="url(#mop_svg__a)">
      <path
        d="M7.333 8.276h1.334V3.609a.645.645 0 0 0-.192-.475A.645.645 0 0 0 8 2.943a.645.645 0 0 0-.475.191.645.645 0 0 0-.192.475v4.667Zm-4 2.667h9.334V9.609H3.333v1.334Zm-.966 4H4v-1.334c0-.188.064-.347.192-.475a.645.645 0 0 1 .475-.191c.189 0 .347.064.475.191a.645.645 0 0 1 .191.475v1.334h2v-1.334c0-.188.064-.347.192-.475A.645.645 0 0 1 8 12.943c.189 0 .347.064.475.191a.645.645 0 0 1 .192.475v1.334h2v-1.334c0-.188.063-.347.191-.475a.645.645 0 0 1 .475-.191c.19 0 .348.064.475.191a.645.645 0 0 1 .192.475v1.334h1.633l-.666-2.667H3.033l-.666 2.667Zm11.266 1.333H2.367c-.434 0-.784-.172-1.05-.517a1.262 1.262 0 0 1-.234-1.15L2 10.943V9.609c0-.366.13-.68.392-.941.26-.261.575-.392.941-.392H6V3.609c0-.555.194-1.027.583-1.416A1.929 1.929 0 0 1 8 1.609c.556 0 1.028.195 1.417.584.389.389.583.86.583 1.416v4.667h2.667c.366 0 .68.13.941.392.261.26.392.575.392.941v1.334l.917 3.666c.144.423.08.806-.192 1.15-.272.345-.636.517-1.092.517Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);

export default SvgMop;
