import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { PaymentMethod } from "~/application/types/entities/PaymentMethod.type";
import { BindPaymentMethodsProps } from "~/application/usecases/PaymentMethod/types";
import { Select } from "~/components/Input";
import { Alert } from "~/components/Alert";
import { SvgInfo } from "~/components/Icon/icons";
import { Icon } from "~/components/Icon";
import { Flex } from "~/components/Flex";
import { Spinner } from "~/components/Spinner";

const bindPaymentMethodsSchema = yup.object().shape({
  serviceType: yup.object().shape({
    description: yup.string().required(),
    uuid: yup.string().required("O serviço é obrigatório"),
  }),
  paymentMethod: yup.object().shape({
    description: yup.string().required(),
    uuid: yup.string().required("O método de pagamento é obrigatório"),
  }),
});

export type BindPaymentMethodsSchema = yup.InferType<typeof bindPaymentMethodsSchema>;

export interface PaymentMethodDialogProps {
  isNew?: boolean;
  isLoading: boolean;
  defaultData?: PaymentMethod;
  data?: PaymentMethod[];
  serviceTypes: { uuid: string; description: string }[];
  onCloseClick?: () => void;
  onSubmit: (data: Omit<BindPaymentMethodsProps, "customerId">) => void;
}

export function BindPaymentMethodDialog({
  isLoading,
  data,
  isNew = false,
  defaultData,
  serviceTypes,
  onCloseClick,
  onSubmit,
}: PaymentMethodDialogProps) {
  const serviceType = serviceTypes.find(
    (item) => item.uuid === defaultData?.serviceType
  );

  const defaultValues = {
    serviceType: {
      uuid: defaultData?.serviceType,
      description: serviceType?.description,
    },
    paymentMethod: {
      uuid: defaultData?.uuid,
      description: defaultData?.description,
    },
  };

  const { control, handleSubmit, formState } = useForm<BindPaymentMethodsSchema>({
    defaultValues: defaultData ? defaultValues : undefined,
    resolver: yupResolver(bindPaymentMethodsSchema),
  });

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          title="Vincular método de pagamento"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button type="submit" disabled={formState.isSubmitting || isLoading}>
              {formState.isSubmitting && (
                 <Spinner 
                    css={{ 
                      borderLeftColor: "$neutrals-white", 
                      width: "$4", height: "$4", 
                      borderWidth: "2px" 
                    }} 
                  />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ height: "max-content", p: "$6" }}>
            <Flex direction="column" gap="4">
              <Row>
                <Col sz="12">
                  <FormControl name="serviceType" control={control} required>
                    <FieldLabel>Serviço</FieldLabel>
                    <Select
                      placeholder="Selecione o serviço"
                      options={serviceTypes}
                      disabled={!!defaultData}
                      getOptionValue={(data) => data.uuid}
                      getOptionLabel={(data) => data.description}
                    />
                    {formState.errors.serviceType?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>{formState.errors.serviceType.uuid.message}</Text>
                    )}
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col sz="12">
                  <FormControl name="paymentMethod" control={control} required>
                    <FieldLabel>Método de pagamento</FieldLabel>
                    <Select
                      isLoading={isLoading}
                      placeholder="Selecione o método de pagamento"
                      options={data}
                      getOptionValue={(data) => data.uuid}
                      getOptionLabel={(data) => data.description}
                    />
                    {formState.errors.paymentMethod?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>{formState.errors.paymentMethod.uuid.message}</Text>
                    )}
                  </FormControl>
                </Col>
              </Row>

              <Alert variant="info">
                <Icon as={SvgInfo} size="lg" />
                <Text fw={500} variant="primary-dark">
                  Note que só é possível vincular um método de pagamento por
                  serviço.
                </Text>
              </Alert>
            </Flex>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
