import type { Justification, Modify, OrderItems } from "~/application/types";
import { getServiceLabel } from "../../utils";

export type EditableJustification = Modify<
  Justification,
  {
    serviceType: {
      value: OrderItems;
      label: string;
    };
  }
>;

const DEFAULT_FORM_DATA = {
  name: "",
} as EditableJustification;

export function createJustification(
  editable: EditableJustification
): Justification {
  return {
    ...editable,
    serviceType: editable.serviceType.value,
  };
}

export function editJustification(data?: Justification): EditableJustification {
  if (!data) return DEFAULT_FORM_DATA;

  return {
    ...data,
    serviceType: data.serviceType && {
      value: data.serviceType,
      label: getServiceLabel(data.serviceType),
    },
  };
}
