import * as pako from "pako";

export function compressString(input: string): string {
  const binaryString = new TextEncoder().encode(input);
  const compressed = pako.gzip(binaryString);
  return btoa(String.fromCharCode(...compressed));
}

export function decompressString(compressed: string): string {
  const binaryString = atob(compressed);
  const charData = binaryString.split("").map((char) => char.charCodeAt(0));
  const compressedData = new Uint8Array(charData);
  const decompressed = pako.ungzip(compressedData);
  return new TextDecoder().decode(decompressed);
}
