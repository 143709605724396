import { Auth } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { AuthDTO, QRCodeDTO } from "../dtos";
import { mapUserDTO } from "./mapUserDTO";

export function mapAuthDTO(data: AuthDTO): Auth {
  return {
    user: mapUserDTO(data.user),
    authToken: {
      value: data.token.value,
      createdAt: DateUtils.toDate(data.token.created_at),
    },
  };
}

export function mapQRCodeDTO(data: QRCodeDTO) {
  return {
    qrCodeUrl: data.qr_code_url,
  };
}