import { Button } from "~/components/Button";
import { SvgNotificationsActive } from "~/components/Icon/icons";
import { Text } from "~/components/Text";

interface NotifyIssuerButtonProps {
  onNotifyIssuer: () => void;
  enabled?: boolean;
}

export function NotifyIssuerButton({
  enabled = true,
  onNotifyIssuer,
}: NotifyIssuerButtonProps) {
  return enabled ? (
    <Button
      variant="warning"
      onClick={onNotifyIssuer}
      css={{
        width: "100%",
      }}
    >
      <SvgNotificationsActive />
      <Text>Notificar solicitante</Text>
    </Button>
  ) : null;
}
