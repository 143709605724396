import { OrderAirwayTracker } from "~/application/types";
import { OrderAirwayTrackerDTO } from "../dtos";
import { toDate } from "~/utils/date.utils";

export function mapOrderAirwayTrackerDTO(
  dto: OrderAirwayTrackerDTO
): OrderAirwayTracker {
  return {
    tracker: dto.tracker,
    expiresAt: toDate(dto.expires_at),
  };
}
