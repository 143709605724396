import { Fragment } from "react";
import { UseFormReturn } from "react-hook-form";
import { City, Customer, State } from "~/application/types";
import { CustomerForm } from "../../../components/CustomerForm";
import { CustomerFormSkeleton } from "../../../components/CustomerFormSkeleton";
import { CustomerSchema } from "../CustomerPage";

export interface TabCustomerInformationProps {
  data?: Customer | undefined;
  isLoading: boolean;
  isUpdatingCustomer: boolean;
  customerHookForm: (defaultData?: Customer | undefined) => UseFormReturn<CustomerSchema, any>;
  onUpdateCustomer: (data: Customer) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function TabCustomerInformation({
  data,
  isLoading,
  isUpdatingCustomer,
  customerHookForm,
  onUpdateCustomer,
  fetchCitiesByState,
}: TabCustomerInformationProps) {
  return (
    <Fragment>
      {isLoading ? (
        <CustomerFormSkeleton />
      ) : (
        <CustomerForm
          defaultData={data}
          isSubmitting={isUpdatingCustomer}
          customerHookForm={customerHookForm}
          onSubmit={onUpdateCustomer}
          fetchCitiesByState={fetchCitiesByState}
        />
      )}
    </Fragment>
  );
}
