import { OrderItems, VehicleQuery } from "~/application/types";
import { toISOString } from "~/utils/date.utils";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../../../../Booking/contexts/BookingContext";
import { VehicleQueryForm } from "../components/VehicleQueryForm";
import { BookingMenuLayout } from "~/core/shared/BookingMenuLayout";

const initialFormData: Partial<VehicleQuery> = {
  timeGetSelected: "10:00",
  timeReturnSelected: "10:00",
};

export function VehicleQueryPage() {
  const navigate = useNavigate();

  const { order } = useBooking();

  function handleSubmit(data: VehicleQuery) {
    const vehicleQueryStr = {
      destinationSelected: JSON.stringify(data.destinationSelected),
      originSelected: JSON.stringify(data.originSelected),
      dateGetSelected: toISOString(data.dateGetSelected as Date),
      dateReturnSelected: toISOString(data.dateReturnSelected as Date),
      timeGetSelected: data.timeGetSelected,
      timeReturnSelected: data.timeReturnSelected,
      reasonTrip: JSON.stringify(data.reasonTrip),
    };

    navigate(
      queryString.stringifyUrl({
        url: "/busca/alugar-carros/resultados",
        query: { ...vehicleQueryStr, orderId: order?.uuid },
      })
    );
  }

  return (
    <BookingMenuLayout activeTab={OrderItems.VEHICLE} title="Encontre carros para sua equipe.">
      <VehicleQueryForm onSubmit={handleSubmit} defaultData={initialFormData} order={order} />
    </BookingMenuLayout>
  );
}
