import React, { ComponentProps, forwardRef } from "react";
import { styled } from "~/application/theme";
import { Icon } from "../Icon";

export type ButtonProps = ComponentProps<typeof ButtonRoot>;

const ButtonRoot = styled("button", {
  // Reset
  all: "unset",
  alignItems: "center",
  boxSizing: "border-box",
  userSelect: "none",
  whiteSpace: "nowrap",

  "&::before": {
    boxSizing: "border-box",
  },
  "&::after": {
    boxSizing: "border-box",
  },

  // Custom reset?
  display: "inline-flex",
  flexShrink: 0,
  justifyContent: "center",
  lineHeight: "1",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",

  // Custom
  fontWeight: 600,
  fontSize: "$md",
  fontVariantNumeric: "tabular-nums",
  borderRadius: "$md",
  transition: "$normal",
  gap: "$2",

  "&:disabled": {
    pointerEvents: "none",
    opacity: "$disabled",
  },

  "&:hover": {
    cursor: "pointer",
  },

  "&:focus": {
    boxShadow: "$focus-md",
  },

  variants: {
    size: {
      sm: {
        height: "$11",
        padding: "$4",
        fontSize: "$md",
        lineHeight: "$4",

        [`& ${Icon}`]: {
          size: "$4",
        },
      },
      md: {
        height: "$13",
        padding: "$4",
        fontSize: "$lg",
        lineHeight: "$5",

        [`& ${Icon}`]: {
          size: "$5",
        },
      },
      mdl: {
        height: "$16",
        padding: "$4",
        fontSize: "$lg",
        lineHeight: "$5",

        [`& ${Icon}`]: {
          size: "$5",
        },
      },
      lg: {
        height: "$20",
        padding: "$6",
        fontSize: "$xl",
        lineHeight: "$6",

        [`& ${Icon}`]: {
          size: "$8",
        },
      },
    },
    variant: {
      none: {
        background: "none",
        padding: "0",
        border: "none",

        "&:hover": {
          cursor: "auto",
        },

        "&:focus": {
          boxShadow: "none",
        },
      },
      primary: {
        backgroundColor: "$primary-base",
        color: "$neutrals-white",
        fill: "$neutrals-white",

        "&:hover": {
          backgroundColor: "$primary-dark",
        },
      },
      "secondary-light": {
        backgroundColor: "transparent",
        color: "$neutrals-darkest",
        fill: "$neutrals-darkest",
        border: "1px solid $neutrals-darkest",

        "&:hover": {
          backgroundColor: "$neutrals-black",
          color: "$neutrals-white",
        },

        "&:active": {
          backgroundColor: "$neutrals-black",
          color: "$neutrals-white",
        },
      },
      secondary: {
        backgroundColor: "$neutrals-darkest",
        color: "$neutrals-white",
        fill: "$neutrals-white",

        "&:hover": {
          backgroundColor: "$neutrals-black",
        },
      },
      warning: {
        color: "$warning-dark",
        backgroundColor: "rgba(255, 237, 216, 1)",
        fill: "$warning-dark",

        "&:hover": {
          backgroundColor: "$warning-base",
        },
      },
      "warning-light": {
        color: "#DA763A",
        backgroundColor: "#FFF5EA",
        fill: "$warning-dark",
        border: "1px solid #DA763A",

        "&:hover": {
          backgroundColor: "$warning-base",
        },

        "&:focus": {
          boxShadow: "none",
        },
      },
      tertiary: {
        backgroundColor: "$neutrals-lightest",
        color: "$neutrals-black",
        fill: "$neutrals-black",

        "&:hover": {
          backgroundColor: "$neutrals-light",
        },
      },
      success: {
        backgroundColor: "$success-base",
        color: "$success-light",
        fill: "$success-base",
        opacity: 0.95,

        "&:hover": {
          opacity: 1,
        },
      },
      "success-light": {
        color: "$success-base",
        backgroundColor: "$success-light",
        fill: "$success-base",
        border: "1px solid $success-base",

        "&:hover": {
          backgroundColor: "#ddf1db"
        },

        "&:focus": {
          boxShadow: "none",
        },
      },
      error: {
        fill: "$neutrals-white",
        color: "$neutrals-white",
        backgroundColor: "$error-base",
        opacity: 0.85,
        "&:hover": {
          opacity: 1,
        },
      },
      "error-light": {
        color: "$error-base",
        backgroundColor: "$error-light",
        fill: "$error-base",
        border: "1px solid $error-base",

        "&:hover": {
          backgroundColor: "#f9d1db"
        },

        "&:focus": {
          boxShadow: "none",
        },
      },
    },
    ghost: {
      true: {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    },
    rounded: {
      true: {
        borderRadius: "$pill",
      },
    },
    fullWidth: {
      true: {
        width: "100%",
      },
    },
    mode: {
      align: {
        width: "53%",
        margin: "0 auto",
        alignItems: "center",
      },
    },
  },

  defaultVariants: {
    variant: "primary",
    size: "sm",
  },
});

export const Button = forwardRef<React.ElementRef<typeof ButtonRoot>, ButtonProps>(
  (props, forwardedRef) => {
    return <ButtonRoot type="button" {...props} ref={forwardedRef} />;
  }
);

Button.displayName = "Button";
