import { OfflineHotelRoom } from "~/application/types";
import { OfflineHotelRoomDTO } from "../dtos";

export function mapOfflineHotelRoomDTO(data: OfflineHotelRoomDTO): OfflineHotelRoom {
  return {
    uuid: data.uuid,
    offlineHotelUuid: data.offline_hotel_uuid,
    description: data.description,
    regime: data.regime,
    type: data.type,
    value: Number(data.value),
    adultsAmount: data.adults_amount,
    imagesUrl: data.images_url.map((image) => ({
      uuid: image.uuid,
      imageUrl: image.image_url,
    })),
    amenities: data.amenities,
  };
}
