import { useCallback, useReducer, useState } from "react";
import {
  VehicleBudgetAction,
  VehicleBudgetActionType,
  VehicleBudgetState,
  useVehicleBudgetResult,
} from "./type";
import { Vehicle } from "~/application/types";

export const useVehicleBudget = (): useVehicleBudgetResult => {
  const [activeBudget, setActiveBudget] = useState(false);

  function VehicleBudgetReducer(
    state: VehicleBudgetState,
    action: VehicleBudgetAction
  ) {
    switch (action.type) {
      case VehicleBudgetActionType.ADD: {
        return [...action.paylod];
      }
      case VehicleBudgetActionType.CLEAR: {
        return [] as VehicleBudgetState;
      }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(
    VehicleBudgetReducer,
    [] as VehicleBudgetState
  );

  const onSelectVehicleBudget = useCallback(
    ({ data }: { data: Vehicle }) => {
      const findVehicle = state.find((sv) => sv.id === data.id);

      if (findVehicle) {
        const vehiclesFiltered = state.filter((sv) => sv.id !== data.id);

        dispatch({
          paylod: vehiclesFiltered,
          type: VehicleBudgetActionType.ADD,
        });
        return;
      }
      dispatch({ paylod: [...state, data], type: VehicleBudgetActionType.ADD });
    },
    [state]
  );

  return {
    activeBudget,
    onActiveBudget: setActiveBudget,
    dispatch,
    state,
    onSelectVehicleBudget,
  };
};
