export enum SettingParameterTypeValue {
  INTEGER = "integer",
  BOOLEAN = "boolean",
  FLOAT = "float",
  PERCENTAGE = "percentage",
  SELECT = "select",
}

export enum SettingParameterType {
  CUSTOMER = "customer",
  AGENCY = "agency",
  BOTH = "both",
}

export enum SettingParameterSlug {
  HOTEL_PRICE_CHANGE = "hotel-price-change-parameter",
  VOUCHER_WITHOUT_VALUE = "voucher-without-value",
  SELECT_APPROVERS = "select-approvers",
  POLICY_BY_REASON_TRIP = "policy-by-reason-trip",
  MANAGE_BUDGET_BY = "manage-budget-by",
  COST_CENTER_REQUIRED_PHASE = "cost-center-required-phase",
}

export enum BudgetOption {
  COST_CENTER = "Centro de custo",
  PROJECT = "Projeto",
  PHASE = "Fase",
}

export type SettingParameter = {
  uuid: string;
  slug: SettingParameterSlug;
  description: string;
  type: SettingParameterType;
  typeValue: SettingParameterTypeValue;
  value: string;
};

export const getAvailableParameterOptions = (slug: string) => {
  switch (slug) {
    case SettingParameterSlug.MANAGE_BUDGET_BY:
      return [
        { label: BudgetOption.COST_CENTER, value: BudgetOption.COST_CENTER },
        { label: BudgetOption.PROJECT, value: BudgetOption.PROJECT },
        { label: BudgetOption.PHASE, value: BudgetOption.PHASE }
      ];
    default:
      return undefined;
  }
}