import { PolicyParamsSlug } from "~/application/types";

export const mapSlugViolatedPoliciesType = (data: PolicyParamsSlug) => {
  switch (data) {
    case PolicyParamsSlug.FIXED_VALUE:
      return "Valor fixo";
    case PolicyParamsSlug.LEAD_TIME:
      return "Dias de antecedência";
    case PolicyParamsSlug.LOWER_FARE:
      return "Menor taxa";
  }
};
