const baseColors = {
  // Brand
  "primary-base": "#0064c5",
  "primary-light": "#e0f0ff",
  "primary-dark": "#003161",
  // Semantic
  "success-base": "#377C2F",
  "success-light": "#EEF8ED",
  "success-dark": "#173414",
  "error-base": "#AE1437",
  "error-light": "#FCE8ED",
  "error-dark": "#570A1B",
  "warning-base": "#FFC866",
  "warning-light": "#F6F4F3",
  "warning-dark": "#C36700",
  // Neutrals
  "neutrals-white": "#FFFFFF",
  "neutrals-lightest": "#f0f2f5",
  "neutrals-light": "#c2ccd6",
  "neutrals-base": "#a3b3c2",
  "neutrals-dark": "#668099",
  "neutrals-darkest": "#475A6B",
  "neutrals-black": "#141a1f",
};

const aliases = {
  "theme-background": baseColors["neutrals-lightest"],
};

export const colors = { ...baseColors, ...aliases };