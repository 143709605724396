import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { QueryKeys } from "~/application/constants";
import { ApiError, Customer, PaginatedResource } from "~/application/types";
import { customerService } from "~/application/usecases";
import { reportService } from "~/application/usecases/Reports";
import { QueryTimes } from "~/constants";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { logError } from "~/presentation/shared/utils/errors";
import { AgencyReportsContainer } from "./ReportsContainer";
import { useReportTab } from "./hooks";
import { DAYS_OPTIONS } from "./utils";

const LOG_TAG = "Agency/ReportPage";

export const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar relatórios da agência",
} as const;

export function AgencyReportsPage() {
  const tabValue = useReportTab();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { user } = useUser();
  const [searchText, setSearchText] = useState<string>("");
  const [searchCustomer, setSearchCustomer] = useState<string>("");
  const [searchDate, setSearchDate] = useState<{
    label: string;
    value: string;
  }>(DAYS_OPTIONS[0]);

  const [customerSelected, setCustomerSelected] =
    useState<Customer | null>(null);

  const [customer] = useDebounce<string>(searchCustomer, 700);
  const [text] = useDebounce<string>(searchText, 700);

  const { data: reports } = useQuery(
    [
      QueryKeys.REPORTS,
      user.agency.uuid,
      currentPage,
      text,
      customerSelected,
      searchDate,
    ],
    () => {
      return reportService.findAccess({
        agency: user.agency.uuid,
        page: currentPage,
        search: text,
        customer: customerSelected?.uuid || "",
        date: searchDate?.value || "",
      });
    },
    { enabled: true }
  );

  const { data: customerList, isFetching: loadingCustomerList } = useQuery<
    PaginatedResource<Customer[]>,
    ApiError
  >(
    [QueryKeys.CUSTOMERS, user.agency, { customer }],
    () => customerService.find({ name: customer }),
    {
      staleTime: QueryTimes.NORMAL,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  return (
    <AgencyReportsContainer
      reports={reports?.data}
      lastPage={reports?.meta.last_page || 1}
      onGoPage={setCurrentPage}
      currentPage={currentPage}
      searchText={searchText}
      onSelectCustomer={setCustomerSelected}
      onSearchCustomer={setSearchCustomer}
      onSearchText={setSearchText}
      customerList={customerList?.data}
      loadingCustomerList={loadingCustomerList}
      onSelectDate={setSearchDate}
      activeTab={tabValue}
    />
  );
}
