import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { Agency, ApiError, PaginatedResource } from "~/application/types";
import { agencyService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { AgenciesContainer } from "./AgenciesContainer";

const LOG_TAG = "Agency/AgenciesPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar agências",
  UPDATING_MESSAGE: "Atualizando agência",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar agência",
  ACTIVATE_SUCCESS_MESSAGE: "Agência ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar agência",
  INACTIVATE_SUCCESS_MESSAGE: "Agência inativada",
} as const;

export function AgenciesPage() {
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<PaginatedResource<Agency[]>, ApiError>(
    [QueryKeys.AGENCIES, currentPage, { search }],
    () => agencyService.find({ page: currentPage, name: search }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: Agency) => agencyService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Agency(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Agency(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCIES]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: Agency) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando agência"
        onConfirm={() => mutateToggleState(item)}
      />
    );
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return (
    <AgenciesContainer
      isLoading={isFetching}
      agenciesList={data?.data}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      searchText={searchText}
      setSearchText={handleChangeSearch}
      onGoToPage={setCurrentPage}
      onToggleState={handleToggleState}
    />
  );
}
