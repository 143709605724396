import { CSS } from "@stitches/react/types/css-util";
import { Avatar } from "~/components/Avatar";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import * as DateUtils from "~/utils/date.utils";

export interface ReasonRejectedProps {
  reasonRejected?: {
    reason?: string;
    approver: string;
    date: string;
  };
  css?: CSS;
}

export function ReasonRejectedItem({
  reasonRejected,
  css,
}: ReasonRejectedProps) {
  return (
    <Flex direction="column" css={css as CSS}>
      <Text variant="error-base" size="5">
        Motivo Da Reprovação
      </Text>
      <Card css={{ mt: "$5", mb: "$5" }}>
        <CardBody>
          <Flex gap="3" css={{ mb: "$4" }}>
            <Avatar />
            <Flex gap="3" direction="column">
              <Text size="3" css={{ fw: 600 }}>
                {reasonRejected?.approver}
              </Text>
              <Text size="2" variant="dark">
                {DateUtils.getTimeFromNow(reasonRejected?.date as string)}
              </Text>
              <Text
                as="p"
                variant="darkest"
                css={{ mt: "$2", lineHeight: 1.5 }}
              >
                {reasonRejected?.reason}
              </Text>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
