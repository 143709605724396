import { useLocation } from "react-router-dom";

import { PENDING_ORDERS_MENU_TABS, PendingOrdersTab } from "../utils";

export function usePendingOrdersTab(): PendingOrdersTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(PendingOrdersTab).includes(hash)) {
    return hash as PendingOrdersTab;
  }

  return PENDING_ORDERS_MENU_TABS[0].id;
}
