import { RoadQuery } from "~/application/types";
import { IStepItem } from "~/presentation/shared/utils";

export enum BookingRoadSteps {
  SELECT_GO_TICKET = "select-go-ticket",
  SELECT_RETURN_TICKET = "select-return-ticket",
  SELECT_TRAVELERS = "select-travelers",
  CONFIRMATION = "confirmation",
}

export type BookingStepsRoadParams = {
  params: RoadQuery;
  isBudget: boolean;
};

export const createBookingMenu = ({
  stretchLength,
}: {
  stretchLength: number;
}): IStepItem<BookingRoadSteps | string, BookingStepsRoadParams>[] => {
  const newArray =
    stretchLength > 1
      ? Array.from(Array(stretchLength)).map((_, index) => ({
          title: `Escolha o trecho ${index + 1}`,
          key: `${index}`,
        }))
      : [];

  return [
    ...newArray,
    {
      title: "Escolha da ida",
      key: BookingRoadSteps.SELECT_GO_TICKET,
      isAvailable: () => {
        return stretchLength <= 1;
      },
    },
    {
      title: "Escolha da volta",
      key: BookingRoadSteps.SELECT_RETURN_TICKET,
      isAvailable: ({ params }) => !!params?.dateReturnSelected,
    },
    {
      title: "Passageiros",
      key: BookingRoadSteps.SELECT_TRAVELERS,
      isAvailable: ({ isBudget }) => isBudget,
    },
    {
      title: "Efetuar reserva",
      key: BookingRoadSteps.CONFIRMATION,
      isAvailable: ({ isBudget }) => isBudget,
    },
  ];
};
