import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { Stepper, StepperItem } from "~/components/Stepper";
import { VehicleBookingContext } from "../contexts/VehicleBookingContext";
import { AppBar } from "~/components/AppBar";
import { VehicleBookingBar } from "../components/VehicleBookingBar";
import { TabContent, Tabs } from "../../../components/Tabs";
import { VehicleChooseSection } from "../components/VehicleChooseSection";
import { VehicleTravelerSection } from "../components/VehicleTravelerSection";
import { VehicleReservationSection } from "../components/VehicleReservationSection";
import { Helmet } from "react-helmet";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Flex } from "~/components/Flex";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Traveler } from "~/application/types";
import { Issuer } from "../utils/vehicle.utils";

export function VehicleBookingResultsPage() {
  const { queryData, actions, bookingInfo, vehicleBudget, order } =
    useContext(VehicleBookingContext);
  const { user, contexts } = useUser();

  const { activeBudget } = vehicleBudget;

  const isMobile = useMobile();

  const [activeStep, setActiveStep] = useState("vehicleChoose");

  const bookingSteps = [
    {
      key: "vehicleChoose",
      title: "Escolha do veículo",
    },
    {
      key: "travelers",
      title: "Passageiros",
    },
    {
      key: "bookingConfirmation",
      title: "Efetuar reserva",
    },
  ];

  const nextStepIsEnabled = (index: number) =>
    index <= bookingSteps.findIndex((step) => step.key === activeStep);
  const [cartIsOpen, setCartIsOpen] = useState(false);

  useEffect(() => {
    return actions.setIssuer({
      uuid: order?.issuer?.uuid ?? user?.profiles?.customer?.uuid,
    } as Issuer);
  }, [user]);

  useEffect(() => setActiveStep("vehicleChoose"), [queryData]);

  const currentActiveStep = activeStep === "travelers" || activeStep === "bookingConfirmation";

  return (
    <Fragment>
      <Helmet>
        <html color-scheme="light" />
      </Helmet>

      <AppBar>
        <VehicleBookingBar
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          bookingInfo={bookingInfo}
          actions={actions}
          vehicleBudget={vehicleBudget}
          order={order}
        />
      </AppBar>

      <Tabs value={activeStep}>
        <Container
          css={{
            "@mxlg": {
              p: "$3",
            },
          }}
        >
          {!isMobile ? (
            <Stepper activeValue={activeStep} setActiveValue={setActiveStep} css={{ my: "$10" }}>
              {bookingSteps.map((item, index) => (
                <StepperItem key={item.key} enabled={nextStepIsEnabled(index)} value={item.key}>
                  {item.title}
                </StepperItem>
              ))}
            </Stepper>
          ) : (
            <Flex
              justify="center"
              css={{
                my: "$5",
                borderRadius: "$md",
                background: "#FFF",
                display: currentActiveStep ? "none" : "flex",
              }}
            >
              <Box
                css={{
                  width: "100%",
                  borderTopLeftRadius: "$md",
                  borderBottomLeftRadius: "$md",
                  background: "#0064C5",
                  p: "$5",
                }}
              >
                <Flex align="center" justify="center">
                  <Box css={{ textAlign: "center", color: "#F0F2F5" }}>
                    <Box>Retirada</Box>
                    <Box css={{ color: "#FFF" }}>
                      {displayDate(
                        bookingInfo.query.dateGetSelected as Date,
                        DateFormats.BASIC_DATE
                      )}
                    </Box>
                  </Box>
                </Flex>
              </Box>

              <Box
                css={{
                  width: "100%",
                  background: "#FFF",
                  p: "$5",
                  borderTopRightRadius: "$md",
                  borderBottomRightRadius: "$md",
                }}
              >
                <Flex align="center" justify="center">
                  <Box css={{ textAlign: "center", color: "black" }}>
                    <Box>Devolução</Box>
                    <Box>
                      {displayDate(
                        bookingInfo.query.dateReturnSelected as Date,
                        DateFormats.BASIC_DATE
                      )}
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          )}
          <Box>
            <TabContent value="vehicleChoose" unmountOnHidden>
              <VehicleChooseSection
                activeStep={activeStep}
                cartIsOpen={cartIsOpen}
                setCartIsOpen={setCartIsOpen}
                setNextStep={() => setActiveStep("travelers")}
              />
            </TabContent>

            <TabContent value="travelers" unmountOnHidden>
              <VehicleTravelerSection
                cartIsOpen={cartIsOpen}
                currentActiveStep={activeStep}
                setCartIsOpen={setCartIsOpen}
                setNextStep={() => setActiveStep("bookingConfirmation")}
              />
            </TabContent>

            <TabContent value="bookingConfirmation" unmountOnHidden>
              <VehicleReservationSection
                activeStep={activeStep}
                cartIsOpen={cartIsOpen}
                setCartIsOpen={setCartIsOpen}
              />
            </TabContent>
          </Box>
        </Container>
      </Tabs>
    </Fragment>
  );
}
