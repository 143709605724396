import { FC } from "react";
import { OrderItems } from "~/application/types";
import { BookingMenuLayout } from "~/core/shared/BookingMenuLayout";
import { RoadQueryPageUIProps } from "./types";
import { RoadQueryContainer } from "~/presentation/shared/components/RoadQueryContainer/RoadQueryContainer";

export const RoadQueryPageUI: FC<RoadQueryPageUIProps> = ({ defaultQuery, onQuery, order }) => {
  return (
    <BookingMenuLayout activeTab={OrderItems.ROAD} title="Encontre passagens para sua equipe.">
      <RoadQueryContainer defaultData={defaultQuery} onSubmit={onQuery} order={order} />
    </BookingMenuLayout>
  );
};
