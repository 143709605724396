import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { SwitchBase } from "../base/SwitchBase";
import { createRadioHOC } from "./createRadioHOC";
import { RadioButton } from "./RadioButton";

export type RadioProps = ComponentProps<typeof Radio>;

const RadioRoot = styled(SwitchBase, {
  // Custom
  [`& > *:focus + ${RadioButton}`]: {
    boxShadow: "$focus-sm",
  },
});

export const Radio = createRadioHOC(RadioRoot);

Radio.displayName = "Radio";
