import { ComponentProps } from "react";
import { styled } from "~/application/theme";

const DataListItemRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  position: "relative",
  display: "flex",

  // Custom
  padding: "$4",
  gap: "$6",
  width: "95%",
  margin: "0 auto",
  alignItems: "center",
  backgroundColor: "$neutrals-white",
  borderRadius: "$md",
  transition: "$fast",

  "&::after": {
    content: "",
    pointerEvents: "none",
    position: "absolute",
    inset: 0,
    margin: 0,
    borderRadius: "inherit",
    borderStyle: "solid",
    borderColor: "transparent",
    transition: "inherit",
  },

  "&[data-list=true]::after": {
    borderStyle: "solid solid none solid",
  },

  "&[data-active=true]": {
    backgroundColor: "#E0F0FF",
  },

  variants: {
    size: {
      md: {
        "$$data-list-gap": "$space$4",
      },
      sm: {
        "$$data-list-gap": "$space$2",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

export type DataListItemProps = ComponentProps<typeof DataListItemRoot> & {
  isActive?: boolean;
};

export const DataListItemMenu = ({ isActive, ...props }: DataListItemProps) => {
  return <DataListItemRoot data-active={isActive} {...props} />;
};

DataListItemMenu.displayName = "DataListItemMenu";
