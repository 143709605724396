import { FC, useCallback, useEffect, useMemo } from "react";
import { Traveler } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Text } from "~/components/Text";
import { H4, H5 } from "~/components/Typography";
import { TravelersSectionUIProps } from "./types";
import { Select } from "~/components/Input";

export const TravelersSectionUI: FC<TravelersSectionUIProps> = ({
  data,
  seatsQuantity,
  order,
  roadReducer,
  fetchTravelers,
  isLoadingTravelers,
  onSearchTraveler,
  travelers,
  onCreateCustomerEmployee,
  onSelectTraveler,
}) => {
  const getTravelerLabel = useCallback((item: Traveler) =>`Nome: ${item.fullName} \n CPF: ${item.cpf}`, []);
  
  const SelectFormatedOption = (option: Traveler) => {
    return (
      <Flex direction="column">
        <Text>Nome: {option.fullName}</Text>
        <Text css={{ color: '#999999', mt: "$2" }}>CPF: {option.cpf}</Text> 
     </Flex>
    )
  }
  
  const getTravelerValue = useCallback((item: Traveler) => item.uuid, []);

  useEffect(() => {
    if(order?.issuer.uuid) {
      roadReducer.dispatch({
        type: "SET_ISSUER",
        payload: order.issuer.uuid as string,
      })
    }
  }, []);

  const seats = useMemo(() => Array(seatsQuantity).fill(null), [seatsQuantity]);

  return (
    <Box>
      <Flex
        align="center"
        direction={{ "@mxmd": "column" }}
        css={{ mb: "$10" }}
      >
        <Col>
          <H4
            css={{
              "@mxmd": {
                fontSize: "$lg",
              },
            }}
          >
            Adicione passageiros
          </H4>
        </Col>

        <Button
          css={{
            "@mxmd": {
              width: "100%",
              mt: "$5",
            },
          }}
          onClick={onCreateCustomerEmployee}
          variant="secondary"
        >
          <Text>Novo funcionário</Text>
        </Button>
      </Flex>

      <Box css={{ mb: "$10" }}>
        <Box css={{ mb: "$6" }}>
          <H5
            css={{
              "@mxmd": {
                fontSize: "lg",
              },
            }}
          >
            Passageiros
          </H5>
        </Box>

        <Flex direction="column" gap="6">
          {seats.map((_, index) => {
            return (
              <Box key={`seat_${index}`}>
                <Select
                  placeholder="Digite o passageiro"
                  options={travelers}
                  isLoading={isLoadingTravelers}
                  onInputChange={onSearchTraveler}
                  formatOptionLabel={(option) => SelectFormatedOption(option)}
                  defaultValue={data[index]}
                  getOptionValue={getTravelerValue}
                  getOptionLabel={getTravelerLabel}
                  onChange={(value) => onSelectTraveler(value, index)}
                />
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
};
