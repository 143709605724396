import * as React from "react";
import { SVGProps } from "react";

const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 20 20" {...props}>
    <path d="m5.688 17.125 1.145-4.813-3.812-3.25 5-.437L10 4.062l1.979 4.584 5 .416-3.812 3.25 1.145 4.813L10 14.583Z" />
  </svg>
);

export default SvgStar;
