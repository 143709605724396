import { Navigate, Route, Routes } from "react-router-dom";
import { BookingOfflineHotelContainer } from "~/core/modules/Booking/modules/BookingOfflineHotel/BookingOfflineHotelContainer";
import { OfflineHotelQueryPage } from "~/core/modules/Booking/modules/BookingOfflineHotel/pages/OfflineHotelQueryPage/OfflineHotelQueryPage";
import { FlightQueryPage } from "~/presentation/Booking/BookingAirway/pages/FlightQueryPage";
import { FlightsPage } from "~/presentation/Booking/BookingAirway/pages/FlightsPage";
import { RoadQueryPage } from "~/presentation/Booking/BookingRoad/pages/RoadQueryPage";
import { RoadsPage } from "~/presentation/Booking/BookingRoad/pages/RoadsPage";
import { BookingContainer } from "../core/modules/Booking/BookingContainer";
import { BookingHotelContainer } from "../core/modules/Booking/modules/BookingHotel/BookingHotelContainer";
import { HotelDetailsPage } from "../core/modules/Booking/modules/BookingHotel/pages/HotelDetailsPage";
import { HotelQueryPage } from "../core/modules/Booking/modules/BookingHotel/pages/HotelQueryPage";
import { HotelsPage } from "../core/modules/Booking/modules/BookingHotel/pages/HotelsPage";
import { BookingVehicleContainer } from "../core/modules/Booking/modules/BookingVehicle/BookingVehicleContainer";
import { VehicleBookingContainer } from "../core/modules/DeprecatedBooking/modules/BookingVehicle/VehicleBookingContainer";
import { VehicleBookingResultsPage } from "../core/modules/DeprecatedBooking/modules/BookingVehicle/pages/VehicleBookingResultsPage";
import { VehicleQueryPage } from "../core/modules/DeprecatedBooking/modules/BookingVehicle/pages/VehicleQueryPage";

export const BookingRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<BookingContainer />}>
        <Route path="passagens-aereas">
          <Route index element={<FlightQueryPage />} />

          <Route path="resultados" element={<FlightsPage />} />
        </Route>

        <Route path="hospedagens" element={<BookingHotelContainer />}>
          <Route index element={<HotelQueryPage />} />

          <Route path="resultados" element={<HotelsPage />} />

          <Route path="detalhes" element={<HotelDetailsPage />} />
        </Route>

        <Route path="hospedagens-offline" element={<BookingOfflineHotelContainer />}>
          <Route index element={<OfflineHotelQueryPage />} />
        </Route>

        <Route path="passagens-onibus">
          <Route index element={<RoadQueryPage />} />

          <Route path="resultados" element={<RoadsPage />} />
        </Route>

        <Route path="alugar-carros" element={<BookingVehicleContainer />}>
          <Route index element={<VehicleQueryPage />} />

          <Route path="resultados" element={<VehicleBookingContainer />}>
            <Route index element={<VehicleBookingResultsPage />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/busca/passagens-aereas" />} />
      </Route>
    </Routes>
  );
};

BookingRouter.displayName = "BookingRouter";
