import { Order, OrderItems, OrderItemStatus } from "~/application/types";
import { getServiceLabel } from "~/presentation/shared/utils";
import { OrderItemsMenuItem } from "../types";

export const getOrderMenuItems = (order: Order): OrderItemsMenuItem[] => {
  const menuItems: OrderItemsMenuItem[] = [
    {
      title: "Consultor",
      sectionId: "section-consult",
      status: OrderItemStatus.APPROVED,
    },

    order.items.airway && {
      title: getServiceLabel(OrderItems.AIRWAY),
      sectionId: "section-airway",
      status: order.items.airway.flights.some(
        (i) => i.status !== OrderItemStatus.APPROVED
      )
        ? OrderItemStatus.REJECTED
        : OrderItemStatus.APPROVED,
    },

    order.items.hotel && {
      title: getServiceLabel(OrderItems.HOTEL),
      sectionId: "section-hotel",
      status: order.items.hotel.rooms.some(
        (i) => i.status !== OrderItemStatus.APPROVED
      )
        ? OrderItemStatus.REJECTED
        : OrderItemStatus.APPROVED,
    },

    order.items.road && {
      title: getServiceLabel(OrderItems.ROAD),
      sectionId: "section-road",
      status: order.items.road.travels.some(
        (i) => i.status !== OrderItemStatus.APPROVED
      )
        ? OrderItemStatus.REJECTED
        : OrderItemStatus.APPROVED,
    },

    order.items.vehicle && {
      title: getServiceLabel(OrderItems.VEHICLE),
      sectionId: "section-vehicle",
      status: order.items.vehicle.vehicles.some(
        (i) => i.status !== OrderItemStatus.APPROVED
      )
        ? OrderItemStatus.REJECTED
        : OrderItemStatus.APPROVED,
    },

    order.items.advance && {
      title: getServiceLabel(OrderItems.ADVANCE),
      sectionId: "section-advance",
      status: OrderItemStatus.APPROVED,
    },
  ] as OrderItemsMenuItem[];

  return menuItems.filter(Boolean);
};
