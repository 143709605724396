import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PolicyParamsSlug, Traveler } from "~/application/types";
import { orderHotelService, orderService, travelerService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys } from "~/constants";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useCustomerEmployeeDialog } from "~/presentation/shared/hooks/useCustomerEmployeeDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { useBooking } from "../../../../../contexts/BookingContext";
import { useBookingHotel } from "../../../contexts/BookingHotelContext";
import { HotelRoomSelected } from "../../../types";
import { getHotelDataFromUrl } from "../../../utils";
import { useBrokenPolicyJustification } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";
import { useQuery } from "@tanstack/react-query";
import { logError } from "~/presentation/shared/utils/errors";
import { useDebounce } from "use-debounce";

export interface UseSelectGuestsResult {
  travelers?: Traveler[];
  roomGuests?: Traveler[];
  roomsSelected?: HotelRoomSelected[];
  isLoadingGuests: boolean;
  onSearchGuest: Dispatch<SetStateAction<string>>;
  fetchGuests: (name: string) => Promise<Traveler[]>;
  onCreateBooking: () => void;
  onCreateCustomerEmployee: () => void;
  onSelectGuest: (guest: Traveler, roomIndex: number, position: number) => void;
}

export interface UseSelectGuestsOptions {
  enabled: boolean;
  issuerId: string;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingHotel/HotelDetailsPage/useSelectGuests";

export function useSelectGuests({
  issuerId,
  setIsShowCart,
}: UseSelectGuestsOptions): UseSelectGuestsResult {
  const { user, contexts } = useUser();
  const customerId = contexts.customer?.uuid;
  const { order } = useBooking();
  const { hotelQuery, hotelReducer } = useBookingHotel();
  const { brokenPolicyItems, scrollToSection } = useBrokenPolicyJustification();

  const [searchGuest, setSearchGuest] = useState("");
  const [search] = useDebounce(searchGuest, 700);
  const navigate = useNavigate();

  const emptySelectedRooms = useMemo<HotelRoomSelected[] | undefined>(
    () =>
      hotelReducer.bookingState.roomsSelected?.map((room, roomIndex) => ({
        room,
        guests: Array(
          hotelQuery!.accommodations[roomIndex]?.adultQuantity +
            hotelQuery!.accommodations[roomIndex].guestsChildren.length
        ).fill(null),
      })),
    [hotelQuery, hotelReducer]
  );

  const [roomsSelected, setRoomsSelected] = useState<HotelRoomSelected[]>(emptySelectedRooms ?? []);

  const fetchGuestsByName = useCallback(
    async (name: string) => {
      return (
        order?.travelers ??
        (await travelerService.find(user.customer ?? contexts.customer, {
          name,
          isActive: "ativo",
        }))
      );
    },
    [order, user, contexts]
  );
  const { handleOpenCreateModal } = useCustomerEmployeeDialog({});

  const { data, isLoading } = useQuery(
    [QueryKeys.HOTEL_TRAVELERS, search],
    async () =>
      await travelerService.find(user.customer ?? contexts.customer, {
        name: searchGuest,
        isActive: "ativo",
      })
  );

  const handleSelectGuest = useCallback((guest: Traveler, room: number, position: number) => {
    setRoomsSelected((old) => {
      old[room].guests[position] = guest;
      return [...old];
    });
  }, []);

  useEffect(() => {
    let totalGuests = 0;

    hotelQuery?.accommodations.forEach((accommodation) => {
      totalGuests += accommodation.adultQuantity;

      if (accommodation.guestsChildren && accommodation.guestsChildren.length > 0) {
        totalGuests += accommodation.guestsChildren.length;
      }
    });

    const totalGuestsSelected = roomsSelected?.reduce((total, quarto) => {
      return total + quarto.guests.filter((guest) => guest !== null).length;
    }, 0);

    if (totalGuestsSelected === totalGuests) {
      setIsShowCart(true);
    }
  }, [roomsSelected, setIsShowCart]);

  const handleCreateBooking = useCallback(async () => {
    let newOrder = order;
    const items = hotelReducer.bookingState.roomsSelected
      ?.map((room) =>
        room.violatedPolicies.length ? `${room.roomId}-${room.accommodationIndex}` : ""
      )
      .filter((id) => id)
      .filter((item) => !brokenPolicyItems.map((broken) => broken.uuid).includes(item));

    if (items?.length) {
      return scrollToSection(items);
    }

    if (!newOrder) {
      try {
        const travelersSummary = roomsSelected.reduce<Traveler[]>(
          (p, c) => [...p, ...(c.guests as Traveler[])],
          []
        );

        dialogService.showDialog(<LoadingDialog message="Aguarde, estamos criando seu pedido" />);

        newOrder = await orderService.create({
          customerId,
          issuerId,
          travelersIds: travelersSummary.map((t) => t.uuid),
          reasonTripId: hotelQuery?.reasonTrip?.uuid,
        });
      } catch (error) {
        log.e(LOG_TAG, error);
      } finally {
        dialogService.popDialog();
      }
    }

    try {
      dialogService.showDialog(<LoadingDialog message="Aguarde, estamos criando seu pedido" />);

      const hotel = hotelReducer.bookingState.hotelSelected! || getHotelDataFromUrl();

      await orderHotelService.create({
        orderId: newOrder!.uuid,
        hotelQuery: hotelQuery!,
        hotel,
        rooms: roomsSelected.map(({ room, guests }) => ({
          lowerFareViolated: room.violatedPolicies.some(
            (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
          ),
          room: room,
          guests: guests as Traveler[],
          brokenPolicyJustification: brokenPolicyItems.find(
            (brokenPolicy) => brokenPolicy.uuid === `${room.roomId}-${room.accommodationIndex}`
          )?.justification,
        })),
      });

      queryClient.invalidateQueries([QueryKeys.ORDERS, newOrder!.uuid]);
      navigate(`/pedidos/${newOrder!.uuid}`);
    } catch (error) {
      log.e(LOG_TAG, error);
    } finally {
      dialogService.popDialog();
    }
  }, [order, hotelQuery, hotelReducer, roomsSelected, issuerId, brokenPolicyItems]);

  useEffect(() => {
    if (emptySelectedRooms) {
      setRoomsSelected(emptySelectedRooms);
    }
  }, [emptySelectedRooms]);

  return {
    roomsSelected,
    roomGuests: data,
    travelers: order?.travelers,
    isLoadingGuests: isLoading,
    onSearchGuest: setSearchGuest,
    fetchGuests: fetchGuestsByName,
    onSelectGuest: handleSelectGuest,
    onCreateCustomerEmployee: handleOpenCreateModal,
    onCreateBooking: handleCreateBooking,
  };
}
