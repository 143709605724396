import { FC, Fragment } from "react";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FormDialog } from "~/components/FormDialog";
import { Grid } from "~/components/Grid";
import { Stepper, StepperItem } from "~/components/Stepper";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { SEATS_SEARCH_ERROR } from "../../hooks/useQueryRoadSeats/types";
import { ErrorDialog } from "../ErrorDialog";
import { BusPreview } from "./components/BusPreview";
import { BusSeat } from "./components/BusSeat";
import { LoadingSeatsModal } from "./components/LoadingSeatsModal";
import { RoadInfo } from "./components/RoadInfo";
import { SeatsSummary } from "./components/SeatsSummary";
import { useRoadSeats } from "./hooks/useRoadSeats";
import { RoadSeatsDialogProps } from "./types";
import { Box } from "~/components/Box";
import { useRoadsPage } from "~/presentation/Booking/BookingRoad/pages/RoadsPage/contexts/RoadsPageContext";

export const RoadSeatsDialog: FC<RoadSeatsDialogProps> = ({
  data,
  minSeats = 0,
  maxSeats = Infinity,
  travelers,
  isGoRoad,
  onSelectSeats,
  onCloseClick,
  currentRoadSeats,
}) => {
  const {
    road,
    fields,
    seats,
    isLastStep,
    roadSeatsConnection,
    canAddSeat,
    connection,
    roadSeats,
    floors,
    currentFloor,
    currentStep,
    isSeatsDisabled,
    roadSeatsError,
    connectionSteps,
    isLoadingRoadSeats,
    onSubmit,
    onSelectSeat,
    onRemoveSeat,
    setCurrentFloor,
    setCurrentStep,
    handleSubmit,
  } = useRoadSeats({
    data,
    travelers,
    minSeats,
    maxSeats,
    onSelectSeats,
    currentRoadSeats,
  });

  const hasConnection = !!data.connection;

  const title = roadSeatsConnection
    ? "Selecione os assentos dos trechos"
    : "Escolha os assentos para essa passagem";

  const from = isLastStep ? data.connection?.name : data.from;
  const to = isLastStep ? data.to : data.connection?.name;
  const seatClass = isLastStep ? data.connection?.seatClass : data.seatClass;

  const departureDate = isLastStep ? (data.connection?.departureDate as Date) : data.departureDate;

  const arrivalDate = isLastStep ? data.arrivalDate : (data.connection?.arrivalDate as Date);

  const company = isLastStep ? data.connection?.companyName : data.company;
  const companyImage = isLastStep ? data.connection?.companyUrl : data.companyImage;

  if (roadSeatsError || (roadSeats?.length && !floors.length)) {
    return <ErrorDialog error={roadSeatsError || SEATS_SEARCH_ERROR} onCloseClick={onCloseClick} />;
  }

  if (isLoadingRoadSeats || !seats) {
    return <LoadingSeatsModal message="Carregando assentos" />;
  }

  return (
    <Box
      css={{
        width: "100%",
        "@mxmd": {
          width: data.connection ? "70%" : "85%",
          margin: "0 auto",
        },
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          title={title}
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={isSeatsDisabled} type="submit">
              <Text>Adicionar</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody
            css={{
              p: "$6",
              height: "100%",
              backgroundColor: "$neutrals-lightest",
            }}
          >
            {hasConnection && (
              <>
                <H5>
                  {isGoRoad ? "Ida" : "Volta"} ({currentStep + 1} de {connectionSteps.length})
                </H5>

                <Container css={{ px: "$0", py: "$6" }}>
                  <Stepper
                    activeValue={currentStep}
                    setActiveValue={setCurrentStep}
                    css={{
                      cursor: road.seats.length ? "pointer" : undefined,
                      borderBottomRightRadius: "$none",
                      borderBottomLeftRadius: "$none",
                    }}
                  >
                    {connectionSteps.map((item, index) => (
                      <StepperItem
                        value={index}
                        css={{
                          "@mxlg": {
                            fontSize: "9px",
                            p: 8,
                          },
                        }}
                        key={item.key}
                        enabled={road.seats.length > 0}
                      >
                        <Text>{item.title}</Text>
                      </StepperItem>
                    ))}
                  </Stepper>

                  <RoadInfo
                    to={to}
                    from={from}
                    company={company}
                    companyImage={companyImage}
                    arrivalDate={arrivalDate}
                    departureDate={departureDate}
                    travelDuration={data.travelDuration}
                    isLastStep={isLastStep}
                    seatClass={seatClass}
                  />
                </Container>
              </>
            )}

            <Grid
              css={{
                gap: "$10",
                width: "100%",
                justifyContent: "center",
                gridTemplateColumns: data.connection ? undefined : "auto 320px",
                px: data.connection ? "$40" : undefined,
                "@mxlg": {
                  gridTemplateColumns: "400px",
                  px: data.connection ? "$10" : undefined,
                  justifyContent: "center",
                },
              }}
            >
              <Flex direction="column" align={{ "@mxlg": "center" }} css={{ gap: "$6" }}>
                <Flex
                  justify="end"
                  align="center"
                  css={{
                    "@mxlg": {
                      margin: "0 auto",
                    },
                  }}
                >
                  <Fragment>
                    {floors.map(({ floor: floorNumber }, i) => (
                      <Button
                        key={i}
                        variant="secondary"
                        css={{
                          ml: "$3",
                          p: "$6",
                          opacity: currentFloor + 1 !== floorNumber ? 0.4 : 1,
                        }}
                        onClick={() => setCurrentFloor(floorNumber - 1)}
                      >
                        {floorNumber}° Andar
                      </Button>
                    ))}
                  </Fragment>
                </Flex>

                <BusPreview
                  seats={seats}
                  currentFloor={currentFloor}
                  seatsSelected={isLastStep ? connection.seats : road.seats}
                  disabled={!canAddSeat}
                  onSelectSeat={onSelectSeat}
                />

                <Box
                  css={{
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  {seats.length > 0 && <SeatStates />}
                </Box>
              </Flex>

              <Box
                css={{
                  "@mxlg": {
                    display: seats.length > 0 ? "block" : "none",
                    margin: "0 auto",
                    width: "70%",
                  },
                }}
              >
                {!data.connection && (
                  <SeatsSummary data={data} onRemoveSeat={onRemoveSeat} seatFields={fields} />
                )}
              </Box>
            </Grid>
          </DialogBody>
        </FormDialog>
      </Form>
    </Box>
  );
};

const SeatStates = () => {
  return (
    <Card>
      <Flex as={CardBody} align="center" gap="4">
        <Flex gap="2" align="center">
          <BusSeat size="sm" />
          <Text size="2">Disponível</Text>
        </Flex>

        <Flex gap="2" align="center">
          <BusSeat data-selected="true" size="sm" />
          <Text size="2">Selecionado</Text>
        </Flex>

        <Flex gap="2" align="center">
          <BusSeat data-available="false" size="sm" />
          <Text size="2">Indisponível</Text>
        </Flex>
      </Flex>
    </Card>
  );
};

RoadSeatsDialog.displayName = "RoadSeatsDialog";
