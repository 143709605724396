
import { PaymentMethod } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { PaymentMethodListItem } from "./components/PaymentMethodListItem";

export interface TabPaymentMethodsProps {
  data?: PaymentMethod[];
  isLoading: boolean;
  onCreatePaymentMethod: () => void;
  onUpdatePaymentMethod: (item: PaymentMethod) => void;
}

export function TabPaymentMethods({
  data,
  isLoading,
  onCreatePaymentMethod,
  onUpdatePaymentMethod,
}: TabPaymentMethodsProps) {
  const listRenderer = useCallback(
    (item: PaymentMethod) => (
      <PaymentMethodListItem
        data={item}
        onEditClick={onUpdatePaymentMethod}
        key={item.uuid}
      />
    ),
    [onUpdatePaymentMethod]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Métodos de pagamentos</H4>
          </Col>

          <Col sz="auto">
            <Button
              title="Novo motivo"
              color="primary"
              onClick={onCreatePaymentMethod}
            >
              <Icon as={SvgPlus} />
              <Text>Novo método de pagamento</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          isLoading={isLoading}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui método de pagamento cadastrados</Text>
            </EmptyState>
          }
        />
      </Box>
    </Fragment>
  );
}
