import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type BadgeProps = ComponentProps<typeof Badge>;

export const Badge = styled("span", {
  // Reset
  boxSizing: "border-box",
  display: "inline-block",

  // Custom
  borderRadius: "$md",
  fontWeight: 700,
  fontSize: "$xxs",
  lineHeight: "1",
  padding: "$1",
  color: "$primary-dark",
  backgroundColor: "$primary-light",
});
