import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState } from "react";
import type { HotelQuery, Traveler } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { Caption, H4 } from "~/components/Typography";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { formatNumber } from "~/utils/string.utils";
import { HotelBookingCart } from "../../../../../../../DeprecatedBooking/modules/BookingHotel/components/HotelBookingCart";
import { HotelRoomSelected } from "../../../../types";
import { BookingHotelSteps } from "../../utils";
import { ItemBrokenPolicyJustication } from "~/presentation/shared/components/BrokenPolicyJusticationItem/ItemBrokenPolicyJusticationItem";
import { Select } from "~/components/Input";

export interface TabSelectGuestsProps {
  travelers?: Traveler[];
  roomGuests?: Traveler[];
  roomsSelected?: HotelRoomSelected[];
  isShowCart: boolean;
  isLoadingGuests: boolean;
  onSearchGuest: Dispatch<SetStateAction<string>>;
  onSelectGuest: (guest: Traveler, roomIndex: number, position: number) => void;
  onCreateBooking: () => void;
  onCreateCustomerEmployee: () => void;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
  fetchGuests: (name: string) => Promise<Traveler[]>;
  activeStep?: BookingHotelSteps;
}

export function TabSelectGuests({
  travelers,
  roomGuests,
  roomsSelected = [],
  isShowCart,
  activeStep,
  isLoadingGuests,
  onSearchGuest,
  fetchGuests,
  setIsShowCart,
  onSelectGuest,
  onCreateBooking,
  onCreateCustomerEmployee,
}: TabSelectGuestsProps) {
  const SelectFormatedOption = (option: Traveler) => {
    return (
      <Flex direction="column">
        <Text>Nome: {option.fullName}</Text>
        <Text css={{ color: "#999999", mt: "$2" }}>CPF: {option.cpf}</Text>
      </Flex>
    );
  };

  const getTravelerLabel = useCallback(
    (item: Traveler) => `Nome: ${item.fullName}\nCPF: ${item.cpf}`,
    []
  );
  const getTravelerValue = useCallback((item: Traveler) => item.uuid, []);

  return (
    <Fragment>
      <Row>
        <Col
          sz="8"
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <Flex align="center" direction={{ "@mxlg": "column" }} css={{ mb: "$10" }}>
            <Col>
              <H4>Adicione hóspedes</H4>
            </Col>

            <Button
              css={{
                "@mxlg": {
                  width: "80%",
                  mt: "$6",
                },
              }}
              onClick={onCreateCustomerEmployee}
              variant="secondary"
            >
              <Text>Novo funcionário</Text>
            </Button>
          </Flex>

          <Flex direction="column" gap="8">
            {roomsSelected.map(({ room, guests }, roomIndex) => (
              <Flex direction="column" gap="6" key={`room_${roomIndex}`}>
                <Text css={{ fw: "700" }}>{room.description}</Text>
                {guests.map((guest, guestIndex) => (
                  <Box key={`guest_${roomIndex}_${guestIndex}`}>
                    <Caption>{`Adulto ${formatNumber(1 + guestIndex, 2)}`}</Caption>

                    <Select
                      options={roomGuests}
                      formatOptionLabel={(option) => SelectFormatedOption(option)}
                      isLoading={isLoadingGuests}
                      onInputChange={onSearchGuest}
                      placeholder="Selecione um hóspede"
                      getOptionValue={getTravelerValue}
                      getOptionLabel={getTravelerLabel}
                      onChange={(value) => {
                        onSelectGuest(value, roomIndex, guestIndex);
                      }}
                    />
                  </Box>
                ))}
                {!!room?.violatedPolicies?.length && (
                  <ItemBrokenPolicyJustication
                    itemId={`${room.roomId}-${room.accommodationIndex}`}
                  />
                )}
              </Flex>
            ))}
          </Flex>
        </Col>

        <Col
          sz="4"
          css={{
            "@mxlg": {
              width: "0",
              p: 0,
            },
          }}
        >
          <HotelBookingCart
            rooms={roomsSelected.map(({ room }) => room)}
            isShowCart={isShowCart}
            setIsShowCart={setIsShowCart}
            onGoNextStep={onCreateBooking}
            activeStep={activeStep}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
