import { FC, Fragment, useCallback, useEffect } from "react";
import { Actions, Airport } from "~/application/types";
import { airportService } from "~/application/usecases";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgCalendar, SvgGps, SvgSearch, SvgSwap, SvgTrash } from "~/components/Icon/icons";
import { TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import {
  Card,
  CardBody,
  CardFooter,
  CardSectionTitle,
} from "~/core/modules/DeprecatedBooking/components/Card";
import { SimpleListItem } from "~/core/modules/DeprecatedBooking/components/SimpleListItem";
import { Chip, ChipText, createControlledChipInput } from "~/core/shared/components/ChipInput";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import {
  FormControl,
  FormControlContent,
  FormControlLabel,
} from "~/core/shared/components/FormControl";
import {
  Popover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { displayDate } from "~/utils/date.utils";
import { AirwayQueryFormProps } from "./types";
import { useFlightsPage } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/contexts/FlightsPageContext";
import { AirwayBudgetDispatchType } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/hooks/useAirwayBudget/type";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useVerifyActions } from "../../hooks/useVerifyActions";
import useMobile from "../../hooks/useMobile";

const AirportInputChip = createControlledChipInput<Airport>();

export const AirwayQueryForm: FC<AirwayQueryFormProps> = ({
  formState,
  index,
  onRemoveField,
  isManyStretch,
}) => {
  const {
    watch,
    setValue,
    resetField,
    register,
    formState: { errors },
  } = formState;

  const data = watch();
  const { profile, contexts } = useUser();
  const isMobile = useMobile();

  const { airwayBudget } = useFlightsPage();

  const handleSwapFlights = useCallback(() => {
    const { stretch } = data;
    const currentOrigin = stretch?.at(index)?.origin as Airport;
    const currentDestination = stretch?.at(index)?.destination as Airport;
    setValue(`stretch.${index}.destination`, currentOrigin);
    setValue(`stretch.${index}.origin`, currentDestination);
  }, [data, setValue, index]);

  // Calendar form
  const excludeDates = useCallback(() => {
    setValue(`stretch.${index}.outboundDate`, null);
    setValue(`stretch.${index}.returnDate`, null);
  }, [resetField, index]);

  useEffect(() => {
    const nextOrigin = data.stretch?.at(index)?.destination;
    const validNewOrigin = !data.stretch?.at(index + 1)?.origin && !!data.stretch?.at(index + 1);

    if ((data.stretch?.length || 0) >= index + 2 && nextOrigin && validNewOrigin) {
      setValue(`stretch.${index + 1}.origin`, nextOrigin);
    }
  }, [data.stretch?.at(index)?.destination, index]);

  const canCreateOrder = useVerifyActions({
    actions: [Actions.CreateOrder, Actions.CreateAirwayOrder],
    contexts,
    profile,
  });

  const handleDateClick = useCallback(
    (date: Date) => {
      if (isManyStretch) {
        setValue(`stretch.${index}.outboundDate`, date);
        return;
      }

      if (!data?.stretch?.at(index)?.outboundDate) {
        setValue(`stretch.${index}.outboundDate`, date);
      } else if (!data.stretch?.at(index)?.returnDate) {
        if (date.getTime() < (data?.stretch?.at(index)?.outboundDate as Date)?.getTime()) {
          setValue(`stretch.${index}.outboundDate`, date);
        } else {
          setValue(`stretch.${index}.returnDate`, date);
        }
      } else {
        setValue(`stretch.${index}.outboundDate`, date);
        setValue(`stretch.${index}.returnDate`, null);
      }
    },
    [data, setValue]
  );

  const lastMinDateSelectIndex =
    data.stretch?.findLastIndex(
      (stretch, indexStretch) => stretch.outboundDate && index > indexStretch
    ) || 0;

  const lastMinDateSelect = data.stretch?.at(lastMinDateSelectIndex)?.outboundDate;

  const firstMaxDateSelect = data.stretch?.find(
    (stretch, indexStretch) => stretch.outboundDate && index < indexStretch
  )?.outboundDate;

  return (
    <Flex align="center" direction={{ "@mxlg": "column" }} gap={{ "@initial": "2", "@mxlg": "6" }}>
      {isMobile && isManyStretch && (
        <Text css={{ pt: "$3", ta: "start", width: "100%", color: "#fff" }}>
          Trecho {index + 1}
        </Text>
      )}

      <FormControl aria-required="true">
        <FormControlLabel
          css={{
            "@mxlg": {
              color: "White",
            },
          }}
        >
          Origem
        </FormControlLabel>

        <Tooltip
          open={!!errors?.stretch?.at?.(index)?.origin}
          variant="error"
          content={<TooltipLabel>Obrigatório</TooltipLabel>}
          side="bottom"
        >
          <AirportInputChip
            {...register(`stretch.${index}.origin`, {
              required: true,
            })}
            placeholder="Digite a origem"
            value={data?.stretch?.at(index)?.origin}
            leftIcon={SvgGps}
            typingDelayMs={100}
            minChars={3}
            queryFunc={(text) => airportService.find({ name: text })}
            chipRenderer={(value) => (
              <Chip
                css={{
                  backgroundColor: "$primary-light",
                }}
              >
                <ChipText>{value.city}</ChipText>
                <Text>{value.iata}</Text>
              </Chip>
            )}
            emptyText="Nenhuma cidade encontrada"
            loadingText="Carregando cidades..."
            itemsRenderer={({ items, setActiveItem }) => (
              <Fragment>
                <CardSectionTitle>Cidades</CardSectionTitle>
                <Box css={{ maxHeight: 250, overflowY: "auto" }}>
                  {items.map((airport) => (
                    <PopoverClose asChild key={airport.uuid}>
                      <SimpleListItem
                        onClick={() => setActiveItem(airport)}
                        title={`${airport.name} - ${airport.iata}`}
                        description={`${airport.city} - ${airport.countryCode}`}
                        css={{ p: "$2 $4" }}
                      />
                    </PopoverClose>
                  ))}
                </Box>
              </Fragment>
            )}
          />
        </Tooltip>
      </FormControl>

      <Button
        rounded
        size="sm"
        tabIndex={-1}
        css={{
          mt: "$4",
          "@mxlg": {
            display: "none",
          },
        }}
        type="button"
        onClick={handleSwapFlights}
      >
        <Icon as={SvgSwap} />
      </Button>

      <FormControl aria-required="true">
        <FormControlLabel css={{ "@mxlg": { color: "white" } }}>Destino</FormControlLabel>

        <Tooltip
          open={!!errors.stretch?.at?.(index)?.destination}
          variant="error"
          content={<TooltipLabel>Obrigatório</TooltipLabel>}
          side="bottom"
        >
          <AirportInputChip
            {...register(`stretch.${index}.destination`, {
              required: true,
            })}
            placeholder="Digite o destino"
            value={data.stretch?.at(index)?.destination}
            leftIcon={SvgGps}
            typingDelayMs={100}
            minChars={3}
            queryFunc={(text) => airportService.find({ name: text })}
            chipRenderer={(value) => (
              <Chip
                css={{
                  backgroundColor: "$primary-light",
                }}
              >
                <ChipText>{value.city}</ChipText>
                <Text>{value.iata}</Text>
              </Chip>
            )}
            emptyText="Nenhuma cidade encontrada"
            loadingText="Carregando cidades..."
            itemsRenderer={({ items, setActiveItem }) => (
              <Fragment>
                <CardSectionTitle>Cidades</CardSectionTitle>
                <Box css={{ maxHeight: 250, overflowY: "auto" }}>
                  {items.map((airport) => (
                    <PopoverClose asChild key={airport.uuid}>
                      <SimpleListItem
                        onClick={() => setActiveItem(airport)}
                        title={`${airport.name} - ${airport.iata}`}
                        description={`${airport.city} - ${airport.countryCode}`}
                        css={{ p: "$2 $4" }}
                      />
                    </PopoverClose>
                  ))}
                </Box>
              </Fragment>
            )}
          />
        </Tooltip>
      </FormControl>

      <Popover>
        <FormControl aria-required="true">
          <FormControlLabel
            css={{
              "@mxlg": {
                color: "White",
              },
            }}
          >
            Data de ida
          </FormControlLabel>

          <PopoverTrigger asChild>
            <FormControlContent>
              <Tooltip
                open={!!errors?.stretch?.at?.(index)?.outboundDate}
                variant="error"
                content={<TooltipLabel>Obrigatório</TooltipLabel>}
                side="bottom"
              >
                <TextInput
                  {...register(`stretch.${index}.outboundDate`, {
                    required: true,
                  })}
                  placeholder="Escolha a data"
                  leftIcon={SvgCalendar}
                  value={
                    data?.stretch?.at?.(index)?.outboundDate &&
                    displayDate(data?.stretch?.at?.(index)?.outboundDate as Date)
                  }
                  style={{ width: "100%" }}
                  onChange={() => null}
                  onBlur={() => null}
                  readOnly
                />
              </Tooltip>
            </FormControlContent>
          </PopoverTrigger>

          {/* Anchor */}
          <PopoverAnchor />
        </FormControl>

        {!isManyStretch && (
          <FormControl>
            <FormControlLabel
              css={{
                "@mxlg": {
                  color: "White",
                },
              }}
            >
              Data de volta
            </FormControlLabel>

            <PopoverTrigger asChild>
              <FormControlContent>
                <Tooltip
                  open={!!errors?.stretch?.at?.(index)?.returnDate}
                  variant="error"
                  content={<TooltipLabel>Obrigatório</TooltipLabel>}
                  side="bottom"
                >
                  <TextInput
                    {...register(`stretch.${index}.returnDate`)}
                    placeholder="Escolha a data"
                    leftIcon={SvgCalendar}
                    style={{ width: "100%" }}
                    value={
                      data.stretch?.at(index)?.returnDate
                        ? displayDate(data?.stretch?.at?.(index)?.returnDate as Date)
                        : undefined
                    }
                    readOnly
                  />
                </Tooltip>
              </FormControlContent>
            </PopoverTrigger>
          </FormControl>
        )}

        <PopoverContent>
          <Card
            css={{
              "@mxlg": {
                top: -100,
                position: "absolute",
                width: "70vw",
              },
            }}
          >
            <DoubleCalendar
              date={data.stretch?.at?.(index)?.outboundDate || new Date()}
              activeMinDate={data.stretch?.at?.(index)?.outboundDate as Date}
              activeMaxDate={data.stretch?.at?.(index)?.returnDate}
              minDate={lastMinDateSelect && index ? (lastMinDateSelect as Date) : new Date()}
              maxDate={firstMaxDateSelect as Date}
              onDateClick={handleDateClick}
            />

            <CardFooter>
              <CardBody
                css={{
                  "@mxlg": {
                    p: "$2",
                  },
                }}
              >
                <Flex
                  gap="4"
                  css={{
                    justifyContent: "flex-end",
                    "@mxlg": {
                      justifyContent: "space-around",
                    },
                  }}
                >
                  <PopoverClose asChild>
                    <Button
                      css={{
                        "@mxlg": {
                          fontSize: "$sm",
                          height: "$5",
                        },
                      }}
                      variant="tertiary"
                      onClick={excludeDates}
                    >
                      Excluir
                    </Button>
                  </PopoverClose>

                  <PopoverClose asChild>
                    <Button
                      css={{
                        "@mxlg": {
                          fontSize: "$sm",
                          height: "$5",
                        },
                      }}
                      variant="secondary"
                    >
                      Aplicar
                    </Button>
                  </PopoverClose>
                </Flex>
              </CardBody>
            </CardFooter>
          </Card>
        </PopoverContent>
      </Popover>

      {isManyStretch && (
        <Button
          variant="secondary"
          size="mdl"
          onClick={onRemoveField}
          css={{
            alignSelf: "flex-end",
            visibility: index < 1 ? "hidden" : "",
            display: index < 1 && isMobile ? "none" : "flex",
          }}
        >
          <Icon as={SvgTrash} />
        </Button>
      )}

      {!isManyStretch && canCreateOrder && (
        <Button
          size="md"
          type="submit"
          css={{
            size: "$16",
            alignSelf: "flex-end",
            "@mxlg": {
              margin: "$4 auto",
              width: "$50",
              height: "$11",
              backgroundColor: "#F0F2F5",
              color: "$primary-base",
            },
          }}
          onClick={() => airwayBudget.dispatch?.({ type: AirwayBudgetDispatchType.CLEAR })}
        >
          <Icon as={SvgSearch} variant={{ "@mxlg": "primary" }} />
          <Text
            css={{
              "@lg": {
                display: "none",
              },
            }}
          >
            Buscar
          </Text>
        </Button>
      )}
    </Flex>
  );
};
