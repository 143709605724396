import { Box } from "~/components/Box";
import { Icon } from "~/components/Icon";
import React, {
  ComponentProps,
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";
import { styled } from "~/application/theme";

type TabsContextType = {
  vertical: boolean;
  activeValue?: string;
  setActiveValue: (value: string) => void;
};

type TabsProps = {
  vertical?: boolean;
  children: ReactNode;
  value?: string;
  defaultValue?: string;
  setValue?: (value: string) => void;
};

type TabItemProps = {
  value: string;
  children: JSX.Element;
};

type TabContentProps = {
  value: string;
  children: JSX.Element;
  unmountOnHidden?: boolean;
};

type TabsListProps = ComponentProps<typeof StyledTabsList>;

const TabsContext = createContext({} as TabsContextType);

/**
 * @deprecated
 */
export const Tab = styled(Box, {
  display: "inline-flex",
  alignItems: "center",
  gap: "$2",
  padding: "$4",
  background: "$neutrals-white",
  color: "$neutrals-dark",
  fill: "$neutrals-dark",
  fontSize: "$md",
  fontWeight: 500,
  transition: "$normal",
  userSelect: "none",

  "&:hover": {
    cursor: "pointer",
    background: "$primary-light",
    color: "$primary-dark",
    fill: "$primary-dark",
  },

  "&[data-active=true]": {
    backgroundColor: "$primary-base",
    color: "$neutrals-white",
    fill: "$neutrals-white",
    fontWeight: 700,
  },

  [`& ${Icon}`]: {
    size: "$5",
  },

  variants: {
    disabled: {
      true: {
        opacity: "$disabled",
        pointerEvents: "none",
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

/**
 * @deprecated
 */
export const StyledTabsList = styled("div", {
  // Reset
  boxSizing: "border-box",

  // Custom
  display: "flex",
  backgroundColor: "$neutrals-white",

  "&[data-orientation=vertical]": {
    flexDirection: "column",
  },
});

/**
 * @deprecated
 */
export const TabsList = React.forwardRef<
  React.ElementRef<typeof StyledTabsList>,
  TabsListProps
>((props, forwardedRef) => {
  const { vertical } = useContext(TabsContext);

  return (
    <StyledTabsList
      data-orientation={vertical ? "vertical" : "horizontal"}
      {...props}
      ref={forwardedRef}
    />
  );
});

/**
 * @deprecated
 */
export const Tabs = ({
  children,
  value,
  setValue,
  vertical = false,
  defaultValue,
}: TabsProps) => {
  const [activeValue, setActiveValue] = useState(defaultValue);

  return (
    <TabsContext.Provider
      value={{
        vertical,
        activeValue: value || activeValue,
        setActiveValue: setValue || setActiveValue,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

/**
 * @deprecated
 */
export const TabItem = ({ value, children }: TabItemProps) => {
  const { activeValue, setActiveValue } = useContext(TabsContext);

  return React.cloneElement(children, {
    "data-active": value === activeValue,
    type: "button",
    onClick: () => setActiveValue(value),
  });
};

/**
 * @deprecated
 */
export const TabContent = ({
  value,
  children,
  unmountOnHidden = false,
}: TabContentProps) => {
  const { activeValue } = useContext(TabsContext);

  if (activeValue === value) {
    return children;
  }

  if (!unmountOnHidden) {
    return <Box css={{ display: "none" }}>{children}</Box>;
  }

  return null;
};

Tabs.displayName = "Tabs";
TabItem.displayName = "TabItem";
TabsList.displayName = "TabsList";
TabContent.displayName = "TabContent";
