import queryString from "query-string";
import type { ExpensePolicy, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { ExpensePolicyDTO } from "~/infrastructure/api/dtos";
import { mapExpensePolicyDTO } from "~/infrastructure/api/mappers";
import type {
  IExpensePolicyService,
  IFindExpensePolicyParams,
} from "./IExpensePolicyService";

export class ExpensePolicyService implements IExpensePolicyService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async findById(id: string): Promise<ExpensePolicy> {
    throw new Error("Method not implemented.");
  }

  async find({
    page = 1,
    customerId,
    policies,
    expenseTypes,
    policyParameterExpenses,
  }: IFindExpensePolicyParams): Promise<PaginatedResource<ExpensePolicy[]>> {
    const url = queryString.stringifyUrl(
      {
        url: `/customers/${customerId}/expense-policies`,
        query: {
          page,
          policy_uuid: policies?.map((i) => i.uuid),
          expense_type_uuid: expenseTypes?.map((i) => i.uuid),
          policy_parameter_uuid: policyParameterExpenses?.map((i) => i.uuid),
        },
      },
      {
        arrayFormat: "bracket",
      }
    );

    return await api
      .get<PaginatedResource<ExpensePolicyDTO[]>>(url)
      .then(({ data }) => ({
        links: data.links,
        meta: data.meta,
        data: data.data.map(mapExpensePolicyDTO),
      }));
  }

  async create(data: Omit<ExpensePolicy, "uuid">): Promise<ExpensePolicy> {
    return await api
      .post<ExpensePolicyDTO>(`/customers/expense-policies`, {
        policy_uuid: data.policy.uuid,
        policy_parameter_expense_uuid: data.policyParameterExpense?.uuid,
        type_value: data.typeValue,
        value: data.value,
        voucher_required: data.voucherRequired,
        expense_type_uuid: data.expenseType.uuid,
      })
      .then(({ data }) => mapExpensePolicyDTO(data));
  }

  async updateById(data: ExpensePolicy): Promise<ExpensePolicy> {
    return await api
      .put<ExpensePolicyDTO>(`/customers/expense-policies/${data.uuid}`, {
        policy_uuid: data.policy.uuid,
        policy_parameter_expense_uuid: data.policyParameterExpense.uuid,
        type_value: data.typeValue,
        value: data.value,
        voucher_required: data.voucherRequired,
        expense_type_uuid: data.expenseType.uuid,
      })
      .then(({ data }) => mapExpensePolicyDTO(data));
  }

  async toggleActive(data: ExpensePolicy): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/customers/expense-policies/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/customers/expense-policies/${data.uuid}/active`)
      .then(({ data }) => data);
  }
}
