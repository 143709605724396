import { useQuery } from "@tanstack/react-query";
import {
  Flight,
  Hotel,
  HotelRoom,
  OrderItems,
  PolicyParamsSlug,
  Road,
  Vehicle,
  ViolatedPolicy,
} from "~/application/types";
import { policyService } from "~/application/usecases";
import { QueryKeys } from "~/constants";
import { ReturnFlightType } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/ui/sections/ReturnFlightsSection";

export type usePoliceFormatsProps = {
  customerId: string;
  data: Hotel[] | Vehicle[] | Road[] | Flight[] | ReturnFlightType[] | HotelRoom[];
  itemType: Exclude<OrderItems, OrderItems.HOTEL_OFFLINE | OrderItems.ADVANCE>;
  isReturn?: boolean;
  isRooms?: boolean;
  lowerRoom?: number;
};

export const usePoliciesFormats = ({
  customerId,
  data,
  itemType,
  isReturn,
  isRooms,
  lowerRoom,
}: usePoliceFormatsProps) => {
  const { data: violatedPolicy } = useQuery(
    [QueryKeys.CUSTOMER_POLICIES, customerId],
    async () => await policyService.find({ customerId }),
    {
      enabled: !!customerId,
    }
  );

  const lowerFarePercentValue = Number(
    violatedPolicy
      ?.find((policy) =>
        policy.values.some((value) => value.parameter.slug === PolicyParamsSlug.LOWER_FARE)
      )
      ?.values.find((value) => value.parameter.serviceType === itemType)?.value
  );

  switch (itemType) {
    case OrderItems.HOTEL: {
      if (isRooms) {
        const hotelRooms = data as HotelRoom[];
        if (!lowerFarePercentValue) return hotelRooms;

        return hotelRooms?.map((room) => {
          const formatValueRoom = (lowerRoom || 0) * (lowerFarePercentValue / 100 + 1);
          const value = lowerFarePercentValue;

          const newPolicy = {
            message: `Valor acima de ${value}% da menor tarifa`,
            value,
            serviceType: itemType,
            policyType: PolicyParamsSlug.LOWER_FARE,
          };

          const policyAlreadyExists = (room?.violatedPolicies as ViolatedPolicy[]).some(
            (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
          );

          return {
            ...room,
            violatedPolicies: [
              ...(room?.violatedPolicies as ViolatedPolicy[]),
              (room?.totalPriceWithFee || 0) > formatValueRoom && !policyAlreadyExists && newPolicy,
            ].filter((h) => h),
          };
        });
      }

      const hotels = data as Hotel[];
      const lowerHotel = hotels.at(0);

      if (!lowerFarePercentValue) return hotels;

      return hotels.map((hotel) => {
        const formatValueHotel =
          (lowerHotel?.bestValueTotal || 0) * (lowerFarePercentValue / 100 + 1);
        const value = lowerFarePercentValue;
        return {
          ...hotel,
          violatedPolicies: [
            ...(hotel?.violatedPolicies as ViolatedPolicy[]),
            (hotel?.bestValueTotal || 0) > formatValueHotel && {
              message: `Valor acima de ${value}% da menor tarifa`,
              value,
              serviceType: itemType,
              policyType: PolicyParamsSlug.LOWER_FARE,
            },
          ].filter((h) => h),
        };
      });
    }
    case OrderItems.VEHICLE: {
      const vehicles = data as Vehicle[];
      const lowerVehicle = data.at(0) as Vehicle;

      if (!lowerFarePercentValue) return vehicles;

      return vehicles.map((vehicle) => {
        const formatValueVehicle =
          (lowerVehicle?.totalPrice || 0) * (lowerFarePercentValue / 100 + 1);

        const value = lowerFarePercentValue;

        return {
          ...vehicle,
          violatedPolicies: [
            ...(vehicle?.violatedPolicies as ViolatedPolicy[]),
            (vehicle?.totalPrice || 0) > formatValueVehicle && {
              message: `Valor acima de ${value}% da menor tarifa.`,
              value,
              serviceType: itemType,
              policyType: PolicyParamsSlug.LOWER_FARE,
            },
          ].filter((v) => v),
        };
      });
    }
    case OrderItems.ROAD: {
      const roads = data as Road[];
      const lowerRoad = data.at(0) as Road;

      if (!lowerFarePercentValue) return roads;

      return roads?.map((road) => {
        const formatValueroad = (lowerRoad?.totalPrice || 0) * (lowerFarePercentValue / 100 + 1);
        const value = lowerFarePercentValue;
        return {
          ...road,
          violatedPolicies: [
            ...(road?.violatedPolicies as ViolatedPolicy[]),
            (road?.totalPrice || 0) > formatValueroad && {
              message: `Valor acima de ${value}% da menor tarifa.`,
              value,
              serviceType: itemType,
              policyType: PolicyParamsSlug.LOWER_FARE,
            },
          ].filter((r) => r),
        };
      });
    }
    case OrderItems.AIRWAY: {
      if (isReturn) {
        const airways = data as ReturnFlightType[];
        const lowerAirway = airways?.at(0);

        if (!lowerFarePercentValue) return airways;

        return airways?.map((airway) => {
          const formatValueAirway =
            (lowerAirway?.flight?.options.at(0)?.pricingInfo.total.amount || 0) *
            (lowerFarePercentValue / 100 + 1);
          const value = lowerFarePercentValue;

          return {
            ...airway,
            flight: {
              ...airway?.flight,
              violatedPolicies: [
                ...(airway?.flight?.violatedPolicies as ViolatedPolicy[]),
                (airway?.flight?.options?.at(0)?.pricingInfo.total.amount || 0) >
                  formatValueAirway && {
                  message: `Valor acima de ${value}% da menor tarifa.`,
                  value,
                  serviceType: itemType,
                  policyType: PolicyParamsSlug.LOWER_FARE,
                },
              ].filter((a) => a),
              options: airway?.flight?.options.map((option) => {
                const violatedLowerFarePolicy = option.pricingInfo.total.amount > formatValueAirway;

                return {
                  ...option,
                  violatedPolicies: [
                    ...option.violatedPolicies,
                    violatedLowerFarePolicy && {
                      message: `Valor acima de ${value}% da menor tarifa.`,
                      value,
                      serviceType: itemType,
                      policyType: PolicyParamsSlug.LOWER_FARE,
                    },
                  ].filter((op) => op),
                };
              }),
            },
          };
        });
      }

      const airways = data as Flight[];
      const lowerAirway = data.at(0) as Flight;

      if (!lowerFarePercentValue) return airways;

      return airways?.map((airway) => {
        const formatValueAirway =
          (lowerAirway?.options?.at(0)?.pricingInfo.total.amount || 0) *
          (lowerFarePercentValue / 100 + 1);
        const value = lowerFarePercentValue;

        return {
          ...airway,
          violatedPolicies: [
            ...(airway?.violatedPolicies as ViolatedPolicy[]),
            (airway?.options.at(0)?.pricingInfo.total.amount || 0) > formatValueAirway && {
              message: `Valor acima de ${value}% da menor tarifa.`,
              value,
              serviceType: itemType,
              policyType: PolicyParamsSlug.LOWER_FARE,
            },
          ].filter((a) => a),
          options: airway.options.map((option) => {
            const violatedLowerFarePolicy = option.pricingInfo.total.amount > formatValueAirway;
            return {
              ...option,
              violatedPolicies: [
                ...option.violatedPolicies,
                violatedLowerFarePolicy && {
                  message: `Valor acima de ${value}% da menor tarifa.`,
                  value,
                  serviceType: itemType,
                  policyType: PolicyParamsSlug.LOWER_FARE,
                },
              ].filter((op) => op),
            };
          }),
        };
      });
    }
  }
};
