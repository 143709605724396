import * as React from "react";
import { SVGProps } from "react";

const SvgSvgBlueCheckbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={19} height={19} rx={9.5} fill="#0064C5" />
    <rect x={0.5} y={0.5} width={19} height={19} rx={9.5} stroke="#0064C5" />
    <path d="m13.75 7.5-5 5-2.5-2.5" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgSvgBlueCheckbox;
