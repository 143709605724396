import * as React from "react";
import { SVGProps } from "react";

const SvgAlertTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 7.5a.625.625 0 0 0-.625.625v3.125a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625V8.125A.625.625 0 0 0 10 7.5Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 1.875a1.87 1.87 0 0 0-1.623.936L1.504 14.686A1.874 1.874 0 0 0 3.127 17.5h13.746a1.875 1.875 0 0 0 1.623-2.814L11.623 2.81A1.875 1.875 0 0 0 10 1.875Zm0 1.25a.626.626 0 0 1 .541.312l6.873 11.874a.624.624 0 0 1-.228.855.624.624 0 0 1-.313.084H3.127a.625.625 0 0 1-.541-.94L9.459 3.438A.625.625 0 0 1 10 3.126Z"
    />
    <path d="M10 15a.937.937 0 1 0 0-1.875A.937.937 0 0 0 10 15Z" />
  </svg>
);

export default SvgAlertTriangle;
