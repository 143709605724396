import { ComponentProps, useContext, useRef, useState } from "react";
import { styled } from "~/application/theme";
import { formatNumber } from "~/utils/string.utils";
import { Icon } from "../Icon";
import { SvgCheck, SvgEdit } from "../Icon/icons";
import { Text } from "../Text";
import { StepperItemBadge } from "./StepperItemBadge";
import { StepperContext } from "./contexts/StepperContext";

const StyledStepperItem = styled("div", {
  // Reset
  boxSizing: "border-box",
  outline: "none",
  border: "none",

  // Custom
  display: "flex",
  flexDirection: "row",
  $$badgeBackground: "$colors$neutrals-lightest",
  $$badgeColor: "$colors$neutrals-dark",
  flex: "1 0 0%",
  alignItems: "center",
  fontSize: "$xs",
  padding: "$4 $6",
  background: "$neutrals-white",
  color: "$neutrals-dark",
  fontWeight: 600,
  transition: "$normal",
  userSelect: "none",
  gap: "$2",

  "&[data-enabled=false]": {
    pointerEvents: "none",
  },

  variants: {
    done: {
      true: {
        $$badgeColor: "$colors$success-base",
        $$badgeBackground: "$colors$success-light",
      },
    },
    active: {
      true: {
        $$badgeBackground: "$colors$primary-light",
        $$badgeColor: "$colors$primary-base",

        backgroundColor: "$primary-base",
        color: "$neutrals-white",
      },
    },
  },

  compoundVariants: [
    {
      active: false,
      done: true,
      css: {
        "&:hover": {
          cursor: "pointer",
          color: "$neutrals-black",
          $$badgeColor: "$colors$primary-base",
          $$badgeBackground: "$colors$primary-light",
        },
      },
    },
  ],

  defaultVariants: {
    active: false,
    done: false,
  },
});

export type StepperItemProps<TValue extends string | number> = ComponentProps<
  typeof StyledStepperItem
> & {
  value: TValue;
  isEditable?: boolean;
  enabled?: boolean;
};

export function StepperItem<TValue extends string | number>({
  isEditable,
  children,
  value,
  enabled = true,
  ...props
}: StepperItemProps<TValue>) {
  const { activeValue, setActiveValue, register, registers } =
    useContext(StepperContext);

  const [isHover, setIsHover] = useState(false);

  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  const stepNumber = useRef(register(value)).current;

  const done = registers[value] < registers[activeValue];
  isEditable = done;

  return (
    <StyledStepperItem
      active={value === activeValue}
      done={done}
      {...props}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => setActiveValue?.(value)}
      data-enabled={enabled}
    >
      <StepperItemBadge role="badge">
        {isHover && isEditable ? (
          <Icon as={SvgEdit} size="sm" />
        ) : value === activeValue || done ? (
          <Icon as={SvgCheck} size="sm" />
        ) : (
          <Text>{formatNumber(stepNumber, 2)}</Text>
        )}
      </StepperItemBadge>

      {children}
    </StyledStepperItem>
  );
}

StepperItem.displayName = "StepperItem";
