import type { IFilterGroup } from "~/core/modules/Booking/utils";
import type { Hotel } from "~/application/types";

export const STATIC_HOTEL_FILTERS: IFilterGroup<Hotel>[] = [
  {
    key: "stars",
    label: "Estrelas",
    isValid: (item, optionValue) =>
      Math.floor(item?.stars || 0) === optionValue,
    options: [
      {
        key: "stars1",
        label: "1 estrela",
        value: 1,
      },
      {
        key: "stars2",
        label: "2 estrelas",
        value: 2,
      },
      {
        key: "stars3",
        label: "3 estrelas",
        value: 3,
      },
      {
        key: "stars4",
        label: "4 estrelas",
        value: 4,
      },
      {
        key: "stars5",
        label: "5 estrelas",
        value: 5,
      },
    ],
  },
];
