import { FC } from "react";
import { Box } from "~/components/Box";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H6, Label } from "~/components/Typography";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import * as DateUtils from "~/utils/date.utils";
import { RoadInfoProps } from "./types";

export const RoadInfo: FC<RoadInfoProps> = ({
  travelDuration,
  company,
  companyImage,
  from,
  to,
  seatClass,
  departureDate,
  arrivalDate,
  isLastStep,
}) => {
  return (
    <Box css={{ background: "$neutrals-white", p: "$6", "@mxlg": {
      display: "none",
    }}}>
      <Flex direction="column" gap="4" align="start">
        {companyImage ? (
          <CompanyLogo src={companyImage} alt={company} />
        ) : (
          <DefaultCompanyLogo companyName={company as string} />
        )}

        <Flex justify="between" align="center" gap="6" css={{ width: "100%" }}>
          <Col>
            <Flex align="start" direction="column" gap="4">
              <Label>{DateUtils.toHour12(departureDate)}</Label>
              <H6>{from}</H6>
              <Text size="3">{DateUtils.displayDate(departureDate)}</Text>
            </Flex>
          </Col>

          <Col>
            <Flex align="start" direction="column" gap="4">
              <Label>{DateUtils.toHour12(arrivalDate)}</Label>

              <H6>{to}</H6>

              <Text size="3">{DateUtils.displayDate(arrivalDate)}</Text>
            </Flex>
          </Col>

          <Col sz="2">
            <Flex align="start" direction="column" gap="4">
              <Label>Duração</Label>
              <Label>
                {isLastStep
                  ? DateUtils.timeInterval(arrivalDate, departureDate)
                  : DateUtils.displayTimestamp(travelDuration)}
              </Label>

              <Tag variant="info-light">
                <Text fw="800" variant="primary">
                  {seatClass}
                </Text>
              </Tag>
            </Flex>
          </Col>
        </Flex>
      </Flex>
    </Box>
  );
};
