import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { OrderItems } from "~/application/types";
import { Box } from "~/components/Box";
import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { useBookingOfflineHotel } from "../../contexts/BookingOfflineHotelContext";
export interface OfflineHotelMenuLayoutProps {
  title: string;
  activeTab: OrderItems;
  children: React.ReactNode;
}

export function OfflineHotelMenuLayout({
  children,
  title,
  activeTab,
}: OfflineHotelMenuLayoutProps) {
  const { quantityRooms, hotelQuery, onSearchHotels } =
    useBookingOfflineHotel();

  return (
    <Fragment>
      <Helmet>
        <html color-scheme="primary" />
      </Helmet>

      <Tabs value={activeTab}>
        <Box css={{ backgroundColor: "$primary-light" }}>
          <Container size="12">
            <Text as="h1" size={{ "@initial": "8", "@mxlg": "4" }} css={{ fw: "700", py: "$14" }}>
              <IconButton
                size="md"
                onClick={() => onSearchHotels(hotelQuery!)}
                css={{
                  mr: "$4",
                }}
              >
                <Icon as={SvgChevronLeft} />
              </IconButton>
              {title}
            </Text>
          </Container>
        </Box>

        <Container
          size="12"
          css={{
            height: "110vh",
            transition: "height 0.3s ease-in-out",
          }}
        >
          <Card
            css={{
              backgroundColor: "$neutrals-lightest",
              borderRadius: "0 0 $md $md",
              overflow: "visible",
            }}
          >
            {children}
          </Card>
        </Container>
      </Tabs>
    </Fragment>
  );
}
