import React, { forwardRef, useCallback, useContext } from "react";
import { NumericFormat, OnValueChange } from "react-number-format";
import { FormControlContext } from "../../FormControl/FormControlContext";
import { TextInput, TextInputProps } from "../TextInput";

export type NumberInputProps = TextInputProps & {
  min?: number;
  max?: number;
  thousandSeparator?: boolean | string;
  decimalSeparator?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  allowLeadingZeros?: boolean;
  suffix?: string;
  prefix?: string;
};

export const NumberInput = forwardRef<
  React.ElementRef<typeof NumericFormat>,
  NumberInputProps
>(
  (
    {
      onFocus,
      name: nameProps,
      disabled: disabledProps,
      onBlur: onBlurProps,
      onChange: onChangeProps,
      value: valueProps,
      thousandSeparator = ".",
      decimalSeparator = ",",
      decimalScale = 2,
      fixedDecimalScale = true,
      allowNegative,
      allowLeadingZeros,
      suffix,
      prefix,
      ...props
    },
    forwardedRef
  ) => {
    const formControl = useContext(FormControlContext);

    const name = nameProps ?? formControl.name;
    const disabled = disabledProps ?? formControl.disabled;
    const onBlur = onBlurProps ?? formControl.onBlur;
    const onChange = onChangeProps ?? formControl.onChange;
    const value = valueProps ?? formControl.value;
    const isDirty = !!formControl.error;

    const CustomInput = useCallback(
      (inputProps: any) => <TextInput ref={forwardedRef} {...inputProps} />,
      [forwardRef]
    );

    const handleValueChange = useCallback<OnValueChange>(
      ({ floatValue }) => {
        onChange({
          type: "change",
          target: {
            value: floatValue ?? "",
          },
        } as any);
      },
      [onChange]
    );

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <NumericFormat
        customInput={CustomInput}
        onValueChange={handleValueChange}
        // onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        name={name}
        data-dirty={isDirty}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        allowNegative={allowNegative}
        allowLeadingZeros={allowLeadingZeros}
        suffix={suffix}
        prefix={prefix}
        {...props}
      />
    );
  }
);

NumberInput.displayName = "NumberInput";
