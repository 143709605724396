import { FC, Fragment, SVGProps, useState } from "react";
import { City } from "~/application/types";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { SvgBus, SvgChevronDown, SvgChevronUp } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { CardSectionTitle } from "~/core/modules/DeprecatedBooking/components/Card";
import { SimpleListItem } from "~/core/modules/DeprecatedBooking/components/SimpleListItem";
import { ItemsRendererProps } from "./types";

export const ItemsRenderer: FC<ItemsRendererProps> = ({
  items,
  setActiveItem,
}) => {
  const [cityUuids, setCityUuids] = useState<string[]>([]);

  const RightIcon = ({
    cityUuid,
    ...props
  }: SVGProps<SVGSVGElement> & { cityUuid: string }) => {
    return cityUuids.includes(cityUuid) ? (
      <SvgChevronDown {...props} />
    ) : (
      <SvgChevronUp {...props} />
    );
  };

  const togglecityUuids = (uuid: string) => {
    if (cityUuids.includes(uuid)) {
      return setCityUuids((old) => old.filter((item) => item !== uuid));
    }

    setCityUuids((old) => [...old, uuid]);
  };

  return (
    <Fragment>
      <CardSectionTitle>Cidades</CardSectionTitle>
      <Box
        css={{
          maxHeight: window.innerHeight / 2.5,
          overflowY: "auto",
        }}
      >
        {items.map((stop) => (
          <Fragment key={stop.city.uuid}>
            <SimpleListItem
              onClick={() => setActiveItem(stop)}
              title={stop.city.name}
              description={`${stop.city.state} - ${stop.city.country}`}
              style={{ display: "flex", alignItems: "start" }}
              rightIcon={() =>
                stop.stops.length ? (
                  <RightIcon
                    cityUuid={stop.city.uuid}
                    onClick={(event) => {
                      event.stopPropagation();
                      togglecityUuids(stop.city.uuid);
                    }}
                  />
                ) : null
              }
              css={{ p: "$2 $4" }}
            />

            {cityUuids.includes(stop.city.uuid) && (
              <>
                <Flex
                  css={{
                    p: "$2 $4",
                    background: "$neutrals-lightest",
                  }}
                >
                  <Text size="2" fw="600">
                    Terminais
                  </Text>
                </Flex>

                <Box
                  css={{
                    maxHeight: window.innerHeight / 2.5,
                    overflowY: "auto",
                  }}
                >
                  {stop.stops.length > 0
                    ? stop.stops.map((terminal) => (
                        <SimpleListItem
                          key={terminal.uuid}
                          leftIcon={SvgBus}
                          onClick={() => {
                            setActiveItem({
                              city: {} as City,
                              stops: [terminal],
                            });
                          }}
                          title={terminal.name}
                          description={`${stop.city.name}, ${stop.city.country}`}
                          css={{ p: "$2 $4" }}
                        />
                      ))
                    : null}
                </Box>
              </>
            )}
          </Fragment>
        ))}
      </Box>
    </Fragment>
  );
};
