import { FC } from "react";
import { DateUtils } from "~/application/utils";
import { mapSkippingWindow } from "~/application/utils/array-functions";
import { DateFormats } from "~/application/utils/date-functions";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { SvgGroup, SvgHotels, SvgPlus, SvgRemove } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H4, H6 } from "~/components/Typography";
import { OfflineHotelDetailsProps } from "./types";

export const OfflineHotelDetails: FC<OfflineHotelDetailsProps> = ({
  order,
  item,
  isEditing,
  isAgencyEmployee,
  cannotRemoveQuote,
  onAddQuote,
  onRemoveQuote,
}) => {
  return (
    <Flex gap="8" direction="column" css={{ width: "100%" }}>
      <Card css={{ width: "100%" }}>
        <CardBody>
          <Flex direction="column">
            <Flex gap="8" direction="column">
              <Flex justify="between" align="center">
                <H4>Dados da Solicitação</H4>
              </Flex>

              <Row
                gap="6"
                css={{
                  "@mxlg": {
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  },
                }}
              >
                <Col sz="3">
                  <Flex gap="2" direction="column">
                    <H6 size={{ "@mxlg": "2" }}>Pedido</H6>
                    <Text size={{ "@mxlg": "2" }} fw="500">
                      #{order.orderNumber}
                    </Text>
                  </Flex>
                </Col>

                <Col sz="3">
                  <Flex gap="2" direction="column">
                    <Flex
                      gap="2"
                      direction="column"
                      css={{
                        "@mxlg": {
                          width: "$40",
                        },
                      }}
                    >
                      <H6 size={{ "@mxlg": "2" }}>Solicitante</H6>
                      <Text size={{ "@mxlg": "2" }} fw="500">
                        {order.issuer.name}
                      </Text>
                    </Flex>
                  </Flex>
                </Col>

                <Col sz="3">
                  <Flex gap="2" direction="column">
                    <H6 size={{ "@mxlg": "2" }}>Empresa</H6>
                    <Text size={{ "@mxlg": "2" }} fw="500">
                      {order.customer.name}
                    </Text>
                  </Flex>
                </Col>

                <Col sz="3">
                  <Flex
                    gap="2"
                    direction="column"
                    css={{
                      "@mxlg": {
                        width: "$40",
                      },
                    }}
                  >
                    <H6 size={{ "@mxlg": "2" }}>Cidade</H6>
                    <Text size={{ "@mxlg": "2" }} fw="500">
                      {item.cityName}
                    </Text>
                  </Flex>
                </Col>
                <Flex gap="2" direction="column">
                  <Flex
                    gap="2"
                    direction="column"
                    css={{
                      "@mxlg": {
                        width: "$40",
                      },
                    }}
                  >
                    <H6 size={{ "@mxlg": "2" }}>Observações</H6>
                    <Text size={{ "@mxlg": "2" }} fw="500" variant="warning-dark">
                      {item.obsOffline?.obs}
                    </Text>
                  </Flex>
                </Flex>
              </Row>

              <Row
                gap="6"
                css={{
                  "@mxlg": {
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  },
                }}
              >
                <Col sz="3">
                  <Flex gap="2" direction="column">
                    <H6 size={{ "@mxlg": "2" }}>Check-in</H6>
                    <Text size={{ "@mxlg": "2" }} fw="500">
                      {DateUtils.format(item.checkIn, DateFormats.SMALL_DATE)}
                    </Text>
                  </Flex>
                </Col>

                <Col sz="3">
                  <Flex gap="2" direction="column">
                    <Flex
                      gap="2"
                      direction="column"
                      css={{
                        "@mxlg": {
                          width: "$40",
                        },
                      }}
                    >
                      <H6 size={{ "@mxlg": "2" }}>Regimes</H6>
                      <Text size={{ "@mxlg": "2" }} fw="500">
                        {item.roomRegimen}
                      </Text>
                    </Flex>
                  </Flex>
                </Col>

                <Col sz="3">
                  <Flex gap="2" direction="column">
                    <Flex gap="2" direction="column">
                      <H6 size={{ "@mxlg": "2" }}>Check-out</H6>
                      <Text size={{ "@mxlg": "2" }} fw="500">
                        {DateUtils.format(item.checkOut, DateFormats.SMALL_DATE)}
                      </Text>
                    </Flex>
                  </Flex>
                </Col>

                <Col>
                  <Flex gap="2" direction="column">
                    <H6 size={{ "@mxlg": "2" }}>Hóspedes</H6>

                    {mapSkippingWindow(item.guests, 2, ([first, second]) => (
                      <Text
                        fw="500"
                        size={{ "@mxlg": "2" }}
                        key={`${first ? first.uuid : first}-${second ? second.uuid : second}`}
                      >
                        {first.fullName} {second?.fullName ? `e ${second.fullName}` : ""}
                      </Text>
                    ))}
                  </Flex>
                </Col>
              </Row>

              {order.observation && (
                <Row>
                  <Col>
                    <Flex direction="column" gap="2">
                      <H6>Observações do Solicitante</H6>
                      <Text fw="500">{order.observation}</Text>
                    </Flex>
                  </Col>
                </Row>
              )}
            </Flex>
          </Flex>
        </CardBody>
      </Card>

      <Flex gap="8" direction="column" css={{ width: "100%" }}>
        <Card css={{ width: "100%" }}>
          <CardBody>
            <Flex direction="column">
              <Flex gap="8" direction="column">
                <H4 size={{ "@mxlg": "3" }}>Necessidades da hospedagem</H4>

                <Row>
                  <Col>
                    {(item.obsOffline?.amenities || []).map((amenity) => (
                      <li
                        key={amenity}
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        {amenity}
                      </li>
                    ))}
                  </Col>
                </Row>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Flex>

      {isAgencyEmployee && (
        <Card>
          <CardBody>
            <Flex justify="between" align="center" direction={{ "@mxlg": "column" }}>
              <Flex gap="2">
                <SvgGroup />
                <H4
                  size={{ "@mxlg": "2" }}
                  css={{
                    "@mxlg": {
                      mb: "$10",
                    },
                  }}
                >
                  Dados da cotação
                </H4>
              </Flex>

              <Flex gap="4" direction={{ "@mxlg": "column" }}>
                <Button variant="tertiary">
                  <SvgHotels />
                  Consultar base de hotel
                </Button>

                {!isEditing && (
                  <>
                    <Button variant="secondary" onClick={onAddQuote}>
                      <SvgPlus />
                      Adicionar cotação
                    </Button>

                    <Button variant="error" onClick={onRemoveQuote} disabled={cannotRemoveQuote}>
                      <SvgRemove />
                      Remover cotação
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      )}
    </Flex>
  );
};
