import DOMPurify from "dompurify";
import { Buffer } from "buffer";

export const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        ['clean']
    ],
};

export const serviceTypeOptions = [
    { label: "Carro", type: "vehicle" },
    { label: "Ônibus", type: "road" },
    { label: "Aéreo", type: "airway" },
    { label: "Hotel", type: "hotel" }
];

export const crmObsClean = (data: string) => {
    const obsClean = DOMPurify.sanitize(data,
        {
            ALLOWED_TAGS: ['b', 'p', 'em', 'li', 'a', 'strong', 'u', 'li', 'ul', 'h1', 'h2', 'ol', 'li', 'u'],
            FORBID_ATTR: ['style', 'href', 'class']
        });

    return obsClean

}


export const decodeBase64 = (data?: string) => Buffer.from(data?.replaceAll('<p>', '').replace('</p>', '') || '', 'base64').toString();
 

export const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'align',
    'link',
];