import { Customer, CustomerEmployee, Traveler } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";

export interface IFilterOption {
  label: string;
  key: string;
  value: any;
}

export interface IFilterGroup<TItem> {
  key: string;
  label: string;
  isValid: (item: TItem, value: any) => boolean;
  options: IFilterOption[];
}

export interface IFilterGenerator<TItem> {
  key: string;
  label: string;
  generator: (item: TItem) => string;
}

export interface FilterItem {
  label: string;
  key: string;
  quantity: number;
  selected: boolean;
}

export interface Filter {
  key: string;
  label: string;
  options: FilterItem[];
}

/**
 * This functions create filter groups extracting group and item keys.
 */
export function createFilterGroups<TData>(
  data: TData[],
  filterEntries: IFilterGenerator<TData>[]
): IFilterGroup<TData>[] {
  return filterEntries.map((filter) => ({
    key: filter.key,
    label: filter.label,
    isValid: (item: TData, value: any) => filter.generator(item) === value,
    options: Object.values(
      data.reduce((prev, item) => {
        const valueKey = filter.generator(item);

        if (!prev[valueKey])
          prev[valueKey] = {
            key: valueKey,
            label: valueKey,
            value: valueKey,
          };

        return prev;
      }, {} as any)
    ),
  }));
}

export function employeeToTraveler(
  employee: CustomerEmployee,
  customer: Customer
): Traveler {
  return {
    uuid: employee.uuid,
    fullName: `${employee.name} ${employee.lastName}`,
    cpf: employee.cpf,
    email: employee.email,
    rg: employee.rg,
    dateBirth: toDate(employee.birthDate),
    phone: employee.phone,
    company: {
      tradingName: customer.tradingName,
    },
  };
}
