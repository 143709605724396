import { ComponentProps, useRef } from "react";
import { styled } from "~/application/theme";
import { StepperContext } from "./contexts/StepperContext";

const StyledStepper = styled("ol", {
  // Reset
  boxSizing: "border-box",

  // Custom
  display: "flex",
  flexDirection: "row",
  height: "auto",
  borderRadius: "$md",
  overflow: "hidden",
  margin: "0",
  padding: "0",
  listStyle: "none",
});

export type StepperProps<TValue extends string | number> = ComponentProps<
  typeof StyledStepper
> & {
  activeValue: TValue;
  setActiveValue?: (value: TValue) => void;
};

export function Stepper<TValue extends string | number>({
  activeValue,
  setActiveValue,
  ...props
}: StepperProps<TValue>) {
  const currentStep = useRef<number>(1);
  currentStep.current = 1;

  const registers = useRef<Record<string, number>>({});

  const register = (value: string) => {
    if (registers.current[value]) return registers.current[value];
    const next = currentStep.current++;
    registers.current[value] = next;
    return next;
  };

  return (
    <StepperContext.Provider
      value={{
        activeValue,
        setActiveValue,
        registers: registers.current,
        register,
      }}
    >
      <StyledStepper {...props} />
    </StepperContext.Provider>
  );
}

Stepper.displayName = "Stepper";
