import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import {
  ApiError,
  DeepPartial,
  ExpenseType,
  PaginatedResource,
} from "~/application/types";
import {
  expenseCategoryService,
  expenseTypeService,
} from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { ExpenseTypeDialog } from "../views/ExpenseType/components/ExpenseTypeDialog";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";

export interface UseExpenseTypesResult {
  data?: ExpenseType[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  onGoToPage: (value: number) => void;
  onCreateExpenseType: () => void;
  onEditExpenseType: (item: ExpenseType) => void;
  onToggleExpenseTypeState: (item: ExpenseType) => void;
}

export interface UseExpenseTypesOptions {
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useExpenseTypes";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar tipos de despesas",
  CREATE_SUCCESS_MESSAGE: "Tipo de despesa criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar tipo de despesa",
  UPDATE_SUCCESS_MESSAGE: "Tipo de despesa atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar tipo de despesa",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar tipo de despesa",
  ACTIVATE_SUCCESS_MESSAGE: "Tipo de despesa ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar tipo de despesa",
  INACTIVATE_SUCCESS_MESSAGE: "Tipo de despesa inativado",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<ExpenseType> = {
  name: "",
};

export function useExpensesType({
  customerId,
  enabled,
}: UseExpenseTypesOptions): UseExpenseTypesResult {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching } = useQuery<
    PaginatedResource<ExpenseType[]>,
    ApiError
  >(
    [QueryKeys.CUSTOMER_EXPENSE_TYPES, customerId, currentPage],
    () => expenseTypeService.find({ page: currentPage, customerId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const fetchExpenseCategories = useCallback(
    () =>
      expenseCategoryService
        .find({})
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const { mutate: mutateCreateExpenseType } = useMutation(
    (item: ExpenseType) => expenseTypeService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating ExpenseType(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message="Criando novo tipo de despesa" />
        );
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created ExpenseType(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_TYPES]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: mutateUpdateExpenseType } = useMutation(
    (item: ExpenseType) => expenseTypeService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ExpenseType(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message="Atualizando tipo de despesa" />
        );
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ExpenseType(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_TYPES]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: ExpenseType) => expenseTypeService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ExpenseType(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ExpenseType(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_TYPES]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: ExpenseType) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando tipo de despesa"
        onConfirm={() => mutateToggleState(item)}
      />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <ExpenseTypeDialog
        isNew
        onSubmit={mutateCreateExpenseType}
        fetchExpenseCategories={fetchExpenseCategories}
        defaultData={DEFAULT_FORM_DATA}
      />
    );
  }, [mutateCreateExpenseType, fetchExpenseCategories]);

  const handleOpenEditModal = useCallback(
    (item: ExpenseType) => {
      dialogService.showDialog(
        <ExpenseTypeDialog
          onSubmit={mutateUpdateExpenseType}
          fetchExpenseCategories={fetchExpenseCategories}
          defaultData={item}
        />
      );
    },
    [mutateUpdateExpenseType, fetchExpenseCategories]
  );

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page || 0,
    onGoToPage: setCurrentPage,
    onCreateExpenseType: handleOpenCreateModal,
    onEditExpenseType: handleOpenEditModal,
    onToggleExpenseTypeState: handleToggleState,
  };
}
