import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export const Text = styled("span", {
  // Reset
  lineHeight: "1",
  margin: "0",
  fontFamily: "$default",
  fontVariantNumeric: "tabular-nums",

  variants: {
    size: {
      "1": {
        fontSize: "$xxs",
      },
      "2": {
        fontSize: "$xs",
      },
      "3": {
        fontSize: "$sm",
      },
      "4": {
        fontSize: "$md",
      },
      "5": {
        fontSize: "$lg",
      },
      "6": {
        fontSize: "$xl",
      },
      "7": {
        fontSize: "$2xl",
      },
      "8": {
        fontSize: "$4xl",
      },
      "9": {
        fontSize: "$5xl",
      },
    },
    media: {
      "@mxmd": {
        backgroundColor: "red",
        margin: "0 auto",
      },
    },
    variant: {
      sucess: {
        color: "$success-base",
      },
      neutral: {
        color: "$neutrals-base",
      },
      primary: {
        color: "$primary-base",
      },
      "primary-dark": {
        color: "$primary-dark",
      },
      black: {
        color: "$neutrals-black",
      },
      white: {
        color: "$neutrals-white",
      },
      dark: {
        color: "$neutrals-dark",
      },
      darkest: {
        color: "$neutrals-darkest",
      },
      "error-base": {
        color: "$error-base",
      },
      "error-dark": {
        color: "$error-dark",
      },
      "error-light": {
        color: "$error-light",
      },
      "warning-dark": {
        color: "$warning-dark",
      },
    },
    fw: {
      "100": {
        fw: 100,
      },
      "200": {
        fw: 200,
      },
      "300": {
        fw: 300,
      },
      "400": {
        fw: 400,
      },
      "500": {
        fw: 500,
      },
      "600": {
        fw: 600,
      },
      "700": {
        fw: 700,
      },
      "800": {
        fw: 800,
      },
      "900": {
        fw: 900,
      },
    },
    paragraph: {
      true: {
        display: "block",
      },
    },
    gradient: {
      true: {
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
    },
  },
});

export type TextProps = ComponentProps<typeof Text>;
