import { FC, useMemo } from "react";
import { RoadSeat } from "~/application/types";
import { Box } from "~/components/Box";
import { Image } from "~/components/Image";
import { BusSeat } from "../BusSeat";
import { Styled } from "./styled";
import { BusPreviewProps } from "./types";

export const BusPreview: FC<BusPreviewProps> = ({
  seats,
  seatsSelected,
  currentFloor = 0,
  disabled,
  onSelectSeat,
}) => {
  const { xSeatsQuantity, ySeatsQuantity } = useMemo(() => {
    return seats.reduce(
      (p, c) => {
        return {
          xSeatsQuantity: Math.max(p.xSeatsQuantity, c.position.x),
          ySeatsQuantity: Math.max(p.ySeatsQuantity, c.position.y),
        };
      },
      {
        xSeatsQuantity: 1,
        ySeatsQuantity: 1,
      }
    );
  }, [seats]);

  const isAvailable = (seat: RoadSeat) =>
    !seat.occupied && currentFloor === seat.position.z;

  const busSeat = ({
    seat,
    index,
    isInvisible,
  }: {
    seat: RoadSeat;
    index: number;
    isInvisible?: boolean;
  }) => {
    
    return (
      <Box
        style={{
          gridColumnStart: 1 + seat.position.x,
          gridColumnEnd: 2 + seat.position.x,
          gridRowStart: 1 + seat.position.y,
          gridRowEnd: 2 + seat.position.y,
        }}
        key={`seat_${seat.seat}_${index}`}
        css={{
          "@mxmd": {
            transform: "rotate(270deg)",
          },
        }}
      >
        <BusSeat
          isInvisible={isInvisible}
          data-selected={
            seatsSelected.findIndex((s) => seat.seat === s.seat) > -1 &&
            currentFloor === seat.position.z
          }
          data-disabled={
            disabled ||
            (seat.position.z < 0 && currentFloor === seat.position.z)
          }
          data-available={isAvailable(seat)}
          seatNumber={seat.seat}
          onClick={() => onSelectSeat?.(seat)}
        />
      </Box>
    );
  };

  const setBusMarginTop = () => {
    if (seats.length >= 60) {
      return "$80";
    } else if (seats.length >= 20) {
      return "$54";
    }
  };
  
  return (
    <Styled.Container
      css={{
        gridTemplateColumns: `repeat(${xSeatsQuantity}, 1fr)`,
        gridTemplateRows: `repeat(${ySeatsQuantity}, 1fr)`,
        gap: 0,
        borderRadius: "32px 8px 8px 32px",
        border: "1px solid var(--neutrals-light, #C2CCD6)",
        boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.16)",
        "@mxlg": {
          transform: "rotate(90deg)",
          mt: setBusMarginTop(),
          mb: seats.length >= 60 ? "$20" : "0",
        },
      }}
    >
      <Image src="/images/bus-front.png" />

      <Styled.SeatsContainer>
        {seats.some(isAvailable)
          ? seats.map((seat, index) => busSeat({ seat, index }))
          : seats.map((seat, index) =>
              busSeat({ seat, index, isInvisible: true })
            )}
      </Styled.SeatsContainer>
    </Styled.Container>
  );
};

BusPreview.displayName = "BusPreview";
