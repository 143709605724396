import {
  components,
  MultiValueProps,
  MultiValueRemoveProps,
} from "react-select";
import { Icon } from "../../../components/Icon";
import { SvgClose } from "../../../components/Icon/icons";
import { styled } from "~/application/theme";

const StyledMultiValue = styled(components.MultiValue, {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  userSelect: "none",
  alignItems: "center",

  // Custom
  minWidth: 0,
  padding: "$1",
  color: "$neutrals-black",
});

const SelectChipRoot = styled("div", {
  // Reset
  all: "unset",
  display: "flex",
  position: "relative",

  // Custom
  mr: "$3",
  userSelect: "none",
  fontWeight: "600",
  borderRadius: "$md",
  border: "1px solid $colors$primary-dark",
  backgroundColor: "$primary-light",
});

export const SelectChip = (props: MultiValueProps) => {
  return (
    <SelectChipRoot>
      <StyledMultiValue {...props} />
    </SelectChipRoot>
  );
};

export const SelectChipRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon variant="dark" as={SvgClose} size="sm" />
    </components.MultiValueRemove>
  );
};
