import { Agency, City, State } from "~/application/types";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Fragment } from "react";
import { AgencyForm } from "../../../components/AgencyForm";
import { AgencyFormSkeleton } from "../../../components/AgencyFormSkeleton";

export interface TabAgencyInformationProps {
  data?: Agency | undefined;
  isLoading: boolean;
  fetchCitiesByState: (state: State) => Promise<City[]>;
  onUpdateAgency: (data: Agency) => void;
}

export function TabAgencyInformation({
  data,
  isLoading,
  fetchCitiesByState,
  onUpdateAgency,
}: TabAgencyInformationProps) {
  return (
    <Fragment>
      {isLoading ? (
        <AgencyFormSkeleton />
      ) : (
        <AgencyForm
          formId="update-agency"
          defaultData={data}
          onSubmit={onUpdateAgency}
          fetchCitiesByState={fetchCitiesByState}
        />
      )}

      <Flex gap="4" justify="end" css={{ mt: "$16" }}>
        <Button form="update-agency" variant="tertiary" type="reset">
          <Text>Cancelar</Text>
        </Button>

        <Button form="update-agency" type="submit">
          <Text>Adicionar</Text>
        </Button>
      </Flex>
    </Fragment>
  );
}
