import { createContext, ReactNode, useContext } from "react";

export type ModalContextType<T extends object = object> = {
  isOpen: boolean;
  actions: {
    show: (data?: T) => void;
    close: VoidFunction;
  };
};

type ModalProviderProps<T extends object = object> = {
  children?: ReactNode;
  isOpen: boolean;
  actions: {
    show: (data?: T) => void;
    close: VoidFunction;
  };
};

export const ModalContext = createContext({} as ModalContextType<any>);

export const ModalProvider = ModalContext.Provider;

export const useModalContext = () => useContext(ModalContext);
