import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { FormDialog } from "~/components/FormDialog";
import { Icon } from "~/components/Icon";
import { DataColItem, DataListItem } from "~/components/List";
import { Text } from "~/components/Text";
import { H3, H5 } from "~/components/Typography";
import { getServiceLabel } from "~/core/shared/utils/order.utils";
import { Approvers, Issuers } from "~/infrastructure/api/dtos";

export interface CustomerInfoDialogProps {
    approvers?: Approvers[];
    issuers?: Issuers[];
}

export function CustomerInfoDialog({
    approvers = [], 
    issuers = [],
}: CustomerInfoDialogProps) {
    return (
        <Container size="8" fixed>
            <FormDialog
                title={"Dados sobre o cliente"}
                onClickDismissButton={() => dialogService.popDialog()}
            >
                <DialogBody css={{ p: "$6" }}>
                    <Box>
                        <Flex><H5>Solicitantes</H5></Flex>
                        {issuers.length > 0 ? (
                            <Box css={{ mt: "$10" }}>
                                {issuers.map((issuer, index) => (
                                    <DataListItem key={`issuer-${index}`} css={{ mb: "$5", width: "100%" }} isActive={true}>
                                        <DataColItem headerTitle="Nome" data={issuer.name} />
                                        <DataColItem headerTitle="Email" data={issuer.email} css={{ wordBreak: "break-all" }} />
                                        <DataColItem headerTitle="Telefone" data={issuer.phone} />
                                    </DataListItem>
                                ))}
                            </Box>
                        ) : (
                            <Box css={{ py: "$10" }}>
                                <Text>Este cliente não possui solicitantes</Text>
                            </Box>
                        )}
                        <Flex><H5>Aprovadores</H5></Flex>
                        {approvers.length > 0 ? (
                            <Box css={{ mt: "$10" }}>
                                {approvers.map((approver, index) => (
                                    <DataListItem key={`approver-${index}`} css={{ mb: "$5" }} isActive={true}>
                                        <DataColItem headerTitle="Nome" data={approver.name} />
                                        <DataColItem headerTitle="Email" data={approver.email} css={{ wordBreak: "break-all" }} />
                                        <DataColItem headerTitle="Telefone" data={approver.phone} />
                                    </DataListItem>
                                ))}
                            </Box>
                        ) : (
                            <Box css={{ py: "$10" }}>
                                <Text>Este cliente não possui aprovadores</Text>
                            </Box>
                        )}
                    </Box>
                </DialogBody>
            </FormDialog>
        </Container>
    );
}

CustomerInfoDialog.displayName = "CustomerInfoDialog";
