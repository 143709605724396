import { useNavigate, useParams } from "react-router-dom";
import { routes } from "~/application/theme/routes";
import { OrderRoadSegment } from "~/application/types";
import { Button } from "~/components/Button";
import { Card, CardBody, CardProps } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgRemove } from "~/components/Icon/icons";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H6, Label } from "~/components/Typography";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import useMobile from "~/presentation/shared/hooks/useMobile";
import * as DateUtils from "~/utils/date.utils";

export interface OrderRoadCardProps extends CardProps {
  data: OrderRoadSegment;
  onOpenDetails: (data: OrderRoadSegment) => void;
}

export function OrderRoadCard({
  data,
  onOpenDetails,
  ...props
}: OrderRoadCardProps) {
  const navigate = useNavigate();
  const { orderId } = useParams() as { orderId: string };
  const isMobile = useMobile();

  function navigateToCheapestRoadsPage() {
    const url = routes.Orders.Order.CheapestRoads.root
      .replace(":orderId", orderId)
      .concat(`?ticketId=${data.uuid}`);
    
    navigate(url);
  }

  return (
    <Card {...props}>
      <Flex
        as={CardBody}
        align="center"
        justify="between"
        gap={{ "@lg": "6" }}
        css={{ pb: "0" }}
      >
        <Flex direction="column" gap="4" align="start" css={{ width: "100%" }}>
          <Flex justify="between" direction={{ "@mxlg": "column" }} align="center" css={{ width: "100%" }}>
           <Flex justify={{ "@mxlg": "between" }} css={{ "@mxlg": {
             width: "100%", 
             mb: "$5"
           }}}>
            {data.companyImage ? (
                <CompanyLogo
                  src={data.companyImage}
                  alt={data.companyName}
                  size="lg"
                />
              ) : (
                <DefaultCompanyLogo companyName={data.companyName} />
              )}

              {isMobile && (
                <Button
                 css={{ p: "$2", height: "$7" }}
                 variant="tertiary"
                 onClick={() => onOpenDetails(data)}
              >
                Ver detalhes
              </Button>
              )}
           </Flex>

            <Flex align="center" gap="2" wrap="wrap" css={{ "@mxlg": { width: "100%" }}}>
              {data.isTheCheapestRoad ? (
                <Button variant="success-light" css={{ "@mxlg": { width: "100%" }}} onClick={navigateToCheapestRoadsPage}>
                  <Icon as={SvgCheck} />
                  <Text>Fez a melhor escolha</Text>
                </Button>
              ) : (
                <Button variant="error-light" onClick={navigateToCheapestRoadsPage}>
                  <Icon as={SvgRemove} />
                  <Text>Melhor opção disponível</Text>
                </Button>
              )}
              
              {!isMobile && (
                  <Button
                  variant="tertiary"
                  onClick={() => onOpenDetails(data)}
                >
                  Ver detalhes
                </Button>
              )}
            </Flex>


          </Flex>

          <Flex css={{ gap: "$4" }}>
            <Text size="4">{data.companyName}</Text>

            {data.providerIdentification && (
              <Tag variant="neutral-light" css={{ p: "5px" }}>
                <Text css={{ fontSize: "8px" }}>
                  {data.providerIdentification}
                </Text>
              </Tag>
            )}
          </Flex>
        </Flex>

        <Flex gap="3">
          {data.bpeTicket && (
            <Tag variant="error-light">
              <Text>Passagem no celular</Text>
            </Tag>
          )}
        </Flex>
      </Flex>

      <CardBody>
        <Row
          gap="6"
          wrap="wrap"
          css={{
            "@mxlg": {
              flexDirection: "column",
            },
          }}
        >
          <Col>
            <Flex align="start" direction="column" gap="4">
              <Label>{DateUtils.toHour12(data.departureDate)}</Label>

              <H6>Partida {data.departure}</H6>

              <Text size="3">{DateUtils.displayDate(data.departureDate)}</Text>
            </Flex>
          </Col>

          <Col>
            <Flex align="start" direction="column" gap="4">
              <Label>{DateUtils.toHour12(data.arrivalDate)}</Label>

              <H6>Chegada {data.arrival}</H6>

              <Text size="3">{DateUtils.displayDate(data.arrivalDate)}</Text>
            </Flex>
          </Col>

          <Col>
            <Flex align="start" direction="column" gap="4">
              <Label>Duração</Label>
              <Label>{DateUtils.displayDurationInSeconds(data.duration)}</Label>
            </Flex>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

OrderRoadCard.displayName = "OrderRoadCard";
