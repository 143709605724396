import { FormControlContext } from "../../FormControl/FormControlContext";
import React, {
  ComponentType,
  forwardRef,
  useCallback,
  useContext,
  useState,
} from "react";
import { styled } from "~/application/theme";
import { Icon } from "../../Icon";
import { SvgEye, SvgEyeSlash, SvgLock } from "../../Icon/icons";
import { IconButton } from "../../IconButton";
import { InputBase, InputStandardProps } from "../base/InputBase";
import { InputAdornment } from "../base/InputBase/InputAdornment";

export type PasswordInputProps = Omit<InputStandardProps, "type"> & {
  leftIcon?: ComponentType;
  visible?: boolean;
  defaultVisible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
};

const PasswordInputRoot = styled(InputBase, {});

export const PasswordInput = forwardRef<
  React.ElementRef<typeof PasswordInputRoot>,
  PasswordInputProps
>(
  (
    {
      defaultVisible = false,
      visible: visibleProp,
      onVisibleChange,
      size = "md",
      leftIcon = SvgLock,
      name: nameProps,
      disabled: disabledProps,
      onBlur: onBlurProps,
      onChange: onChangeProps,
      value: valueProps,
      ...props
    },
    forwardedRef
  ) => {
    const [visibleState, setIsVisible] = useState<boolean>(defaultVisible);
    const formControl = useContext(FormControlContext);

    const name = nameProps ?? formControl.name;
    const disabled = disabledProps ?? formControl.disabled;
    const onBlur = onBlurProps ?? formControl.onBlur;
    const onChange = onChangeProps ?? formControl.onChange;
    const value = valueProps ?? formControl.value;
    const isDirty = !!formControl.error;

    const toggleVisibility = useCallback(() => {
      setIsVisible((old) => !old);

      if (onVisibleChange) onVisibleChange(!visibleState);
    }, [onVisibleChange]);

    const visible = visibleProp ?? visibleState;

    return (
      <PasswordInputRoot
        type={visible ? "text" : "password"}
        leftAdornment={
          leftIcon && (
            <InputAdornment position="start" disablePointerEvents>
              <Icon as={leftIcon} size={size} />
            </InputAdornment>
          )
        }
        rightAdornment={
          <InputAdornment position="end">
            <IconButton onClick={toggleVisibility} size={size}>
              <Icon as={visible ? SvgEyeSlash : SvgEye} />
            </IconButton>
          </InputAdornment>
        }
        size={size}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        data-dirty={isDirty}
        {...props}
        ref={forwardedRef}
      />
    );
  }
);

PasswordInput.displayName = "PasswordInput";
