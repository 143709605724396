import { Action } from "~/application/types";
import { GroupedPolicy } from "./components/ProfileListItem/ProfileListItem";

export const formatActionGroup = ({ data }: { data: Action[] | undefined }) => {
  if (!data) return;
  const grouped = data.reduce<Record<string, GroupedPolicy>>((acc, policy) => {
    const { group, groupDescription } = policy;
    if (!acc[group]) {
      acc[group] = {
        group,
        groupDescription,
        policies: [],
      };
    }
    acc[group].policies.push(policy);
    return acc;
  }, {});

  return Object.values(grouped);
};
