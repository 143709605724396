import { Branch } from "~/application/types";

export type EditableBranch = Branch;

const DEFAULT_FORM_DATA = {
  name: "",
  email: "",
  phone: "",
  cnpj: "",
  integrationId: "",
} as EditableBranch;

export function createBranch(editable: EditableBranch): Branch {
  return {
    ...editable,
  };
}

export function editBranch(data?: Branch): EditableBranch {
  if (!data) return DEFAULT_FORM_DATA;

  return {
    ...data,
  };
}
