import { dayjs, getCurrentYear } from "~/utils/date.utils";

export enum Periodicity {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  SEMIANNUAL = "semiannual",
  ANNUAL = "annual",
  UNIQUE = "unique",
}

export interface BudgetItem {
  startAt: Date;
  endAt: Date | null;
}

export const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

export const generateBudgetPeriods = (year: number, periodicity: Periodicity): BudgetItem[] => {
  const periods: BudgetItem[] = [];

  switch (periodicity) {
    case Periodicity.MONTHLY: {
      for (let month = 0; month < 12; month++) {
        const startAt = dayjs(new Date(year, month, 1)).toDate();
        const endAt = dayjs(startAt).endOf("month").toDate();
        periods.push({ startAt, endAt });
      }
      break;
    }

    case Periodicity.QUARTERLY: {
      for (let month = 0; month < 12; month += 3) {
        const startAt = dayjs(new Date(year, month, 1)).toDate();
        const endAt = dayjs(new Date(year, month + 2, 1)).endOf("month").toDate();
        periods.push({ startAt, endAt });
      }
      break;
    }

    case Periodicity.SEMIANNUAL: {
      const firstHalfStart = new Date(year, 0, 1);
      const firstHalfEnd = dayjs(new Date(year, 5, 1)).endOf("month").toDate();
      const secondHalfStart = new Date(year, 6, 1);
      const secondHalfEnd = dayjs(new Date(year, 11, 1)).endOf("month").toDate();

      periods.push({ startAt: firstHalfStart, endAt: firstHalfEnd });
      periods.push({ startAt: secondHalfStart, endAt: secondHalfEnd });
      break;
    }

    case Periodicity.ANNUAL: {
      const startAt = new Date(year, 0, 1);
      const endAt = dayjs(new Date(year, 11, 1)).endOf("month").toDate();
      periods.push({ startAt, endAt });
      break;
    }

    case Periodicity.UNIQUE: {
      const startAt = new Date(year, 0, 1);
      const endAt = null;
      periods.push({ startAt, endAt });
      break;
    }

    default:
      break;
  }

  return periods;
};

export const periodicityOptions = [
  { label: "Mensal", value: Periodicity.MONTHLY },
  { label: "Trimestral", value: Periodicity.QUARTERLY },
  { label: "Semestral", value: Periodicity.SEMIANNUAL },
  { label: "Anual", value: Periodicity.ANNUAL },
  { label: "Único", value: Periodicity.UNIQUE },
];

export const getPeriodicity = (periodicity?: Periodicity) => {
  return periodicityOptions.find((item) => item.value === periodicity);
};

export const getYear = (date?: Date | null) => {
  return date
    ? {
      value: dayjs(date).year(),
      label: String(dayjs(date).year()),
    }
    : { value: getCurrentYear(), label: String(getCurrentYear()) };
};

export const getLabelsByPeriodicity = (periodicity?: Periodicity) => {
  switch (periodicity) {
    case Periodicity.MONTHLY:
      return ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    case Periodicity.QUARTERLY:
      return ["Jan-Mar", "Abr-Jun", "Jul-Set", "Out-Dez"];
    case Periodicity.SEMIANNUAL:
      return ["Jan-Jun", "Jul-Dez"];
    case Periodicity.ANNUAL:
      return ["Jan-Dez"];
    case Periodicity.UNIQUE:
      return ["Valor"];
    default:
      return [];
  }
};

export enum BudgetTab {
  BUDGETS = "orcamentos-criados",
  VIEW = "visualizacao-dos-orcamentos",
}

export const BUDGETS_MENU_TABS = [
  { title: "Orçamentos criados", id: BudgetTab.BUDGETS },
  { title: "Visualização", id: BudgetTab.VIEW },
];