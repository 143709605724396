import { FC, ReactNode } from "react";
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuContent,
  DropdownMenuItems,
  DropdownMenuTrigger,
} from "~/application/components/DropdownMenu";
import { NavMenuItemType } from "~/application/utils/menu-functions";
import { Text } from "~/components/Text";
import { NavItemLink } from "./NavItemLink";

export type NavMenuItemProps = {
  isActive?: boolean;
  menuItems?: NavMenuItemType[];
  children: ReactNode;
  menuCustomerItem?: () => void;
};

export const NavMenuItem: FC<NavMenuItemProps> = ({
  isActive,
  menuItems,
  children,
  menuCustomerItem
}) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger data-active={isActive} asChild>
        <DropdownMenuButton>{children}</DropdownMenuButton>
      </DropdownMenuTrigger>
      {menuCustomerItem && (
        <DropdownMenuButton onClick={menuCustomerItem}>
         <Text css={{ flexGrow: 1 }}>Dados do cliente</Text>
        </DropdownMenuButton>
      )}
      {menuItems && menuItems?.length > 0 && (
        <DropdownMenuContent align="start">
          <DropdownMenuItems>
            {menuItems?.map((item) => (
                <NavItemLink href={item.href} title={item.title} key={item.title}>
                  <Text>{item.title}</Text>
                </NavItemLink>
            ))}
          </DropdownMenuItems>
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};