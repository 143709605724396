import { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { styled } from "~/application/theme";
import { Text } from "~/components/Text";
import * as DateUtils from "~/utils/date.utils";

const CalendarHeader = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  size: "$8",
  textAlign: "center",
  color: "$neutrals-black",
  fontSize: "$xs",
  lineHeight: "1",
  fontWeight: "600",
});

const CalendarDateItem = styled("button", {
  size: "$8",
  textAlign: "center",
  color: "$neutrals-darkest",
  fontSize: "$xs",
  lineHeight: "100%",
  transition: "$normal",
  border: "none",
  outline: "none",
  backgroundColor: "transparent",

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$primary-light",
  },

  "&[data-selected=true]": {
    backgroundColor: "$primary-light",
  },

  "&[data-active=true]": {
    position: "relative",
    color: "$neutrals-white",
    backgroundColor: "$primary-light",

    [`& ${Text}`]: {
      content: "",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      inset: "2px",
      zIndex: 1,
      position: "absolute",
      borderRadius: "$md",
      backgroundColor: "$primary-base",
    },
  },

  "&:disabled": {
    backgroundColor: "transparent!important",
    color: "$neutrals-base",
    pointerEvents: "none",
  },
});

const StyledCalendar = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  rowGap: "$1",

  [`& ${CalendarDateItem}`]: {
    "&:nth-child(7n+8)": {
      borderTopLeftRadius: "$md",
      borderBottomLeftRadius: "$md",
    },

    [`&:nth-child(7n+14), &[data-selected=true] + ${CalendarDateItem}[data-active=true]`]:
      {
        borderTopRightRadius: "$md",
        borderBottomRightRadius: "$md",
      },

    [`[data-active=true] + &`]: {
      borderTopRightRadius: "$sm",
      borderBottomRightRadius: "$sm",
    },
  },
});

export type CalendarProps = {
  date: Date;
  minDate?: Date;
  maxDate?: Date;
  activeDate?: Date;
  activeMinDate?: Date;
  activeMaxDate?: Date | null;
  onDateClick?: (date: Date) => void;
};

/**
 * @deprecated this component needs to be refactored.
 */
export const Calendar = ({
  date,
  minDate: minDateProp,
  maxDate,
  activeDate,
  activeMinDate,
  activeMaxDate,
  onDateClick,
}: CalendarProps) => {
  const minDate = useMemo(() => {
    if (!minDateProp) {
      return null;
    }

    const start = new Date(minDateProp.getTime());
    start.setUTCHours(0, 0, 0, 0);

    return start;
  }, [minDateProp]);

  const days = useMemo(() => {
    const { start, end } = DateUtils.getMouthRange(date);

    const daysQuantity =
      1 + DateUtils.daysBetween(end.toDate(), start.toDate());

    return Array(daysQuantity)
      .fill(null)
      .map((_, index) => start.add(index, "day").toDate());
  }, [date]);

  const checkDisabled = useCallback(
    (value: Date) => {
      const time = value.getTime();

      if (!DateUtils.areSameMonth(value, date)) {
        return true;
      }

      if (maxDate && time >= maxDate.getTime()) {
        return true;
      }

      if (minDate && time < minDate.getTime()) {
        return true;
      }

      return false;
    },
    [date, maxDate, minDate]
  );

  const checkActive = useCallback(
    (value: Date) => {
      const time = value.getTime();
      let activeMinTime: number;
      let activeMaxTime: number;

      if (activeMinDate && activeMaxDate) {
        activeMinTime = activeMinDate.getTime();
        activeMaxTime = activeMaxDate.getTime();
        return activeMinTime === time || time === activeMaxTime;
      }

      if (activeMinDate) {
        activeMinTime = activeMinDate.getTime();
        return activeMinTime === time;
      }

      if (activeMaxDate) {
        activeMaxTime = activeMaxDate.getTime();
        return time === activeMaxTime;
      }

      return false;
    },
    [date, activeMinDate, activeMaxDate]
  );

  const checkSelected = useCallback(
    (value: Date) => {
      if (activeMinDate && activeMaxDate) {
        const time = value.getTime();
        const activeMinTime = activeMinDate.getTime();
        const activeMaxTime = activeMaxDate.getTime();

        return activeMinTime < time && time < activeMaxTime;
      }

      return false;
    },
    [date, activeMinDate, activeMaxDate]
  );

  return (
    <StyledCalendar>
      <CalendarHeader>
        <Text>D</Text>
      </CalendarHeader>
      <CalendarHeader>
        <Text>S</Text>
      </CalendarHeader>
      <CalendarHeader>
        <Text>T</Text>
      </CalendarHeader>
      <CalendarHeader>
        <Text>Q</Text>
      </CalendarHeader>
      <CalendarHeader>
        <Text>Q</Text>
      </CalendarHeader>
      <CalendarHeader>
        <Text>S</Text>
      </CalendarHeader>
      <CalendarHeader>
        <Text>S</Text>
      </CalendarHeader>
      {days.map((value) => {
        const disabled = checkDisabled(value);

        return (
          <CalendarDateItem
            key={uuidv4()}
            data-active={!disabled && checkActive(value)}
            data-selected={checkSelected(value)}
            disabled={disabled}
            onClick={() => onDateClick?.(value)}
          >
            <Text>
              {DateUtils.areSameMonth(value, date) && value.getDate()}
            </Text>
          </CalendarDateItem>
        );
      })}
    </StyledCalendar>
  );
};
