import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type ImageProps = ComponentProps<typeof Image>;

export const Image = styled("img", {
  // Reset
  boxSizing: "border-box",
  verticalAlign: "middle",
  maxWidth: "100%",
  objectFit: "contain",
});
