import { dialogService } from "~/components/DialogStack";
import { CancelDialog } from "~/components/CancelDialog";
import { Text } from "~/components/Text";
import { SvgAlert } from "~/components/Icon/icons";
import { OrderRoadItem, OrderTaxCancellation } from "~/application/types";
import { toHour12 } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";

export type SomeOrderCancellationProps = {
  onRequestOrderCancellation: () => Promise<void>;
  taxCancellation?: number;
  dontAllowsCancelItems?: OrderRoadItem[];
};

export function SomeOrderOrderCancellation({
  onRequestOrderCancellation,
  dontAllowsCancelItems,
  taxCancellation,
}: SomeOrderCancellationProps) {
  return (
    <CancelDialog
      title="Os seguintes itens não poderão ser cancelados:"
      textCancelation="Continuar cancelamento"
      onConfirm={onRequestOrderCancellation}
      onCloseClick={() => dialogService.popDialog()}
      svg={SvgAlert}
    >
      <Text
        size="3"
        css={{
          fontWeight: "bold",
          display: "flex",
          gap: "0.5rem",
        }}
      >
        Ônibus:{" "}
        {dontAllowsCancelItems?.map((item) => {
          return (
            <Text
              as="p"
              css={{
                fontWeight: "normal",
              }}
              key={item.uuid}
            >
              {item.departure}
              {" -> "}
              {item.arrival} {`(${toHour12(item.arrivalDate)}h)`}
              {" - "}
              {item.companyName}
            </Text>
          );
        })}
      </Text>
      <Text as="p" size="3">
        {" "}
        Os itens restantes gerarão uma taxa de{" "}
        <Text
          css={{
            fontWeight: "bold",
          }}
        >
          {asCurrency(taxCancellation?.toLocaleString() || "0")}
        </Text>{" "}
        por conta das políticas da companhia.
        <Text as="p">Deseja continuar a solicitação?</Text>
      </Text>
    </CancelDialog>
  );
}
