import React, { ComponentProps, forwardRef, useContext } from "react";
import { styled } from "~/application/theme";
import { TabsContext } from "./contexts/TabsContext";

export type TabBarProps = ComponentProps<typeof TabBarRoot>;

const TabBarRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  // Custom
  display: "flex",
  backgroundColor: "$neutrals-white",
  
  "&[data-orientation=vertical]": {
    flexDirection: "column",

    "& [role=tab]": {
      width: "100%",
    },
  },
});

export const TabBar = forwardRef<
  React.ElementRef<typeof TabBarRoot>,
  TabBarProps
>((props, forwardedRef) => {
  const { vertical } = useContext(TabsContext);

  return (
    <TabBarRoot
      role="tablist"
      data-orientation={vertical ? "vertical" : "horizontal"}
      {...props}
      ref={forwardedRef}
    />
  );
});

TabBar.displayName = "TabBar";
