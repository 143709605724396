import React, { FC, useMemo, useState } from "react";
import { AirwaySeats, AirwaySeatsForm, Passenger } from "~/application/types";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { AirwaySeat } from "../AirwaySeat/AirwaySeat";
import { Grid } from "~/components/Grid";
import { AirwaySeatsReducerState } from "../../../utils/type";
import { Card } from "~/components/Card";
import { Image } from "~/components/Image";

import Wings from "~/assets/images/Asas.png";
import { insertColumn } from "../../../utils";

export type AirwaySeatsContainerProps = {
  seatData: AirwaySeats;
  currentConection: number;
  selectSeat: (seat: AirwaySeatsReducerState) => void;
  canSelectSeat: ({ strectId }: { strectId: string }) => boolean;
  isSeatSelected: (seat: AirwaySeatsReducerState) => boolean;
  passengers: Passenger[];
  passengerSelected?: Passenger;
  stretch: {
    stretch: string;
    seats: AirwaySeatsForm["seats"];
  }[];
};

export const AirwaySeatsContainer: FC<AirwaySeatsContainerProps> = ({
  seatData,
  currentConection,
  selectSeat,
  isSeatSelected,
  canSelectSeat,
  passengers,
  passengerSelected,
  stretch,
}) => {
  const { data } = seatData;
  return (
    <Flex
      css={{
        position: "relative",
        minHeight: "800px",
      }}
    >
      <Image
        src={Wings}
        alt="Airplane"
        css={{
          position: "absolute",
          top: "50%",
          left: "100%",
        }}
      />
      <Image
        src={Wings}
        alt="Airplane"
        css={{
          position: "absolute",
          top: "50%",
          right: "100%",
          transform: "rotateY(180deg)",
        }}
      />
      <Card
        css={{
          width: "fit-content",
          padding: "20px",
          pt: "130px",
          borderRadius: "$pill $pill $none $none",
        }}
      >
        <Flex align="center" justify="center">
          {data?.at(currentConection)?.mapSeats.map((seats, indexSeats) => (
            <Flex direction="column" key={seats.class} gap="2">
              {seats.rowns.map((row) => (
                <Flex key={row.row} gap="3">
                  {insertColumn(row.columns).map((colum, index) => {
                    const columnLength = insertColumn(row.columns).length;
                    const middleIndex = Math.floor(columnLength / 2);
                    return (
                      <Flex
                        key={`${row.row}${colum.column}`}
                        css={{
                          pl:
                            columnLength < 3 || index !== middleIndex
                              ? "0"
                              : "$8",
                        }}
                      >
                        <AirwaySeat
                          size="md"
                          seatNumber={`${row.row}${colum.column}`}
                          onClick={() =>
                            selectSeat({
                              column: colum.column,
                              row: row.row.toString(),
                              stretchId: data?.at(currentConection)?.id || "",
                              passenger: passengerSelected,
                              value: colum.value,
                              selected: true,
                              departure:
                                data?.at(currentConection)?.departure || "",
                              arrival:
                                data?.at(currentConection)?.arrival || "",
                            })
                          }
                          data-selected={isSeatSelected({
                            column: colum.column,
                            row: row.row.toString(),
                            stretchId: data?.at(currentConection)?.id || "",
                            value: colum.value,
                            selected: true,
                            departure:
                              data?.at(currentConection)?.departure || "",
                            arrival: data?.at(currentConection)?.arrival || "",
                            passenger: passengerSelected,
                          })}
                          data-disabled={
                            colum.busy ||
                            !row.available ||
                            !canSelectSeat({
                              strectId: data?.at(currentConection)?.id || "",
                            }) ||
                            !passengerSelected ||
                            !!stretch
                              ?.find(
                                (stre) =>
                                  stre.stretch ===
                                  data?.at(currentConection)?.id
                              )
                              ?.seats.find(
                                (seat) =>
                                  seat.column === colum.column &&
                                  seat.row === row.row.toString()
                              )
                          }
                          data-available={!colum.busy}
                          isInvisible={false}
                        />
                      </Flex>
                    );
                  })}
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
      </Card>
    </Flex>
  );
};
