import { FunctionComponent } from "react";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { Text } from "~/components/Text";

export interface OrderStatusStepProps {
  label: string;
  icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isActive: boolean;
  isCompleted: boolean;
}

export function OrderStatusStep({ label, isActive, isCompleted, icon }: OrderStatusStepProps) {
  return (
    <Flex css={{ position: "relative" }}>
      <Flex
        align="center"
        justify="center"
        css={{
          width: "$10",
          height: "$10",
          borderRadius: "$circular",
          border: (isCompleted || isActive) ? "none" : "2px solid $neutrals-light",
          backgroundColor: (isCompleted || isActive) ? "$primary-base" : "transparent",
        }}
      >
        <Icon variant={(isCompleted || isActive) ? "white" : "light"} as={icon} />
      </Flex>

      <Text
        fw="600"
        size="3"
        css={{
          position: "absolute",
          bottom: -8,
          left: "50%",
          transform: "translate(-50%, 100%)",
          color: (isCompleted || isActive) ? "$primary-base" : "$neutrals-light",
          whiteSpace: "nowrap",
        }}
      >
        {label}
      </Text>
    </Flex>
  );
}