import { useLocation } from "react-router-dom";
import { OrderStatus } from "~/application/types";

export function useAgencyOrdersTab(): OrderStatus {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(OrderStatus).includes(hash)) {
    return hash as OrderStatus;
  }

  return OrderStatus.OPEN;
}
