import { FC } from "react";
import { SimpleDialog } from "../../components/SimpleDialog";
import { InactivateDialogProps } from "./types";

export const InactivateDialog: FC<InactivateDialogProps> = ({
  children,
  onConfirm,
  onCloseClick,
}) => {
  return (
    <SimpleDialog
      size="8"
      title="Confirmar inativação"
      negativeTitle="Cancelar"
      positiveTitle="Aplicar"
      onPositiveClick={onConfirm}
      onCloseClick={onCloseClick}
    >
      {children}
    </SimpleDialog>
  );
};

InactivateDialog.displayName = "InactivateDialog";
