import { Avatar } from "~/components/Avatar";
import { Icon } from "~/components/Icon";
import { SvgChevronDown } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { Text } from "~/components/Text";
import React, { ComponentProps, useCallback, useState } from "react";
import { Collapse } from "react-collapse";
import { styled } from "~/application/theme";
import { useOutsideListener } from "../hooks/useOutsideListener";
import { AvatarListItem } from "./AvatarListItem";
import { CardSectionTitle } from "./Card";
import { Traveler } from "~/application/types";

type Person = {
  uuid: string;
  name: string;
  description?: string;
  image?: string;
  fullName?: string;
};

export type InputPersonQueryFn = (text: string) => Promise<Person[]>;

export type InputPersonProps = ComponentProps<typeof StyledInputPerson> & {
  queryFunction?: InputPersonQueryFn;
  defaultOpen?: boolean;
  placeholder?: string;
  selectedPerson?: Traveler;
  onSelectPerson?: (person: Person) => void;
  searchFunction?: (text: string) => Person[];
  disabled?: boolean;
  readOnly?: boolean;
  // items: {
  //   uuid: string;
  //   image: string;
  //   email: string;
  //   name: string;
  // }[];
};

const StyledInputBase = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
});
const StyledInputPerson = styled("button", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  borderRadius: "$md",
  textAlign: "start",
  border: "none",
  outline: "none",
  fontSize: "$md",
  color: "$neutrals-darkest",
  lineHeight: "$fontSizes$md",
  backgroundColor: "$neutrals-white",
  padding: "$4",
  transition: "$fast",
  gap: "$4",

  "&::after": {
    content: "",
    pointerEvents: "none",
    position: "absolute",
    inset: 0,
    margin: 0,

    borderColor: "$neutrals-light",
    borderRadius: "inherit",
    borderStyle: "solid",
    borderWidth: "1px",

    transition: "$faster",
  },

  variants: {
    disabled: {
      true: {
        pointerEvents: "none",
        borderColor: "$neutrals-base",
      },
      false: {
        "&:hover::after": {
          borderWidth: "2px",
          borderColor: "$primary-base",
        },
      },
    },
    filled: {
      true: {
        color: "$neutrals-black",
        "&::after": {
          borderWidth: "2px",
          borderColor: "$primary-base",
        },
      },
    },
  },

  compoundVariants: [
    {
      disabled: false,
      css: {
        cursor: "pointer",
      },
    },
  ],

  defaultVariants: {
    disabled: false,
  },
});
const StyledContent = styled("div", {
  position: "absolute",
  zIndex: "$dropdown",
  top: 0,
  left: 0,
  right: 0,
  height: "auto",
  boxShadow: "$md",
  background: "$neutrals-white",
  borderRadius: "$md",
  overflow: "hidden",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "$neutrals-light",
  display: "flex",
  flexDirection: "column",
});
const StyledSearchBox = styled("div", {
  padding: "$4",
});

/**
 * @deprecated
 */
export const InputPerson = ({
  defaultOpen,
  placeholder,
  queryFunction,
  selectedPerson,
  onSelectPerson,
  disabled,
  readOnly,
  ...props
}: InputPersonProps) => {
  const { open, subscribe, toggleOpen } = useOutsideListener({ defaultOpen });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchString, setSearchString] = useState("");
  const [persons, setPersons] = useState<Person[]>();

  // const togglerRef = React.useRef<HTMLButtonElement>(null);
  // const displayAreaRef = React.useRef<HTMLDivElement>(null);

  const onChangeSearchText = useCallback(
    async (text: string) => {
      setSearchString(text);
      queryFunction?.(text).then((persons) => setPersons(persons));
    },
    [queryFunction, setSearchString]
  );
  const onAvatarClick = useCallback(
    (person: Person) => {
      onSelectPerson?.(person);
      toggleOpen();
    },
    [onSelectPerson, toggleOpen]
  );

  return (
    <StyledInputBase>
      <StyledInputPerson
        filled={!!selectedPerson}
        onClick={toggleOpen}
        disabled={disabled || readOnly}
        ref={subscribe}
        {...props}
      >
        <Avatar src={selectedPerson?.avatarUrl} />
        <Text>
          {selectedPerson?.fullName || placeholder}
        </Text>
        {!disabled || (!readOnly && <Icon as={SvgChevronDown} size="sm" />)}
      </StyledInputPerson>

      {open && (
        <StyledContent ref={subscribe}>
          <StyledSearchBox>
            <SearchBar
              style={{ width: "100%" }}
              onSearchChange={onChangeSearchText}
            />
          </StyledSearchBox>
          <Collapse isOpened={open}>
            <CardSectionTitle>Pessoas</CardSectionTitle>
            {persons?.map((item) => (
              <AvatarListItem
                isActive={selectedPerson?.uuid === item.uuid}
                onClick={() => onAvatarClick(item)}
                name={item.name}
                email={item.description}
                key={item.uuid}
              />
            ))}
          </Collapse>
        </StyledContent>
      )}
    </StyledInputBase>
  );
};

InputPerson.displayName = "InputPerson";
