import { OrderMessage } from "~/application/types";

export interface TabOrderMessagesProps {
  readonly isLoadingOrderMessages: boolean;
  readonly orderMessages: OrderMessage[];
}

export interface TabOrderMessagesFormData {
  readonly message: string;
  readonly notify: boolean;
}

export const LOG_TAG = "/presentation/shared/TabOrderMessages";
