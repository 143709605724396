import { Tag, TagProps } from "~/components/Tag";
import { Text } from "~/components/Text";

export interface CreateTagHOCProps<TKey extends string>
  extends Partial<TagProps> {
  data: TKey;
  customLabels?: Partial<Record<TKey, string>>;
}

export interface CreateTagHOCOptions<TKey extends string> {
  config: Record<
    TKey,
    {
      title: string;
      variant: TagProps["variant"];
    }
  >;
}

export function createTagHOC<TKey extends string>({
  config,
}: CreateTagHOCOptions<TKey>) {
  return ({ data, customLabels, ...props }: CreateTagHOCProps<TKey>) => {
    const label = customLabels?.[data] ?? config[data]?.title;

    return (
      <Tag variant={config[data]?.variant} {...props}>
        <Text fw="700">{label ?? data}</Text>
      </Tag>
    );
  };
}
