import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { QueryKeys, QueryTimes } from "~/application/constants";
import { orderService } from "~/application/usecases";
import { logError } from "~/presentation/shared/utils/errors";
import { CheapestFlightsContainer } from "./CheapestFlightsContainer";
import { getCheapestFlights, TripSections } from "./utils";

const LOG_TAG = "Order/CheapestFlightsPage";

const SNACKBAR_MESSAGES = {
  LOAD_CHEAPEST_FLIGHTS_ERROR_MESSAGE: "Falha ao carregar os voos mais baratos",
} as const;

export function CheapestFlightsPage() {
  const [searchParams] = useSearchParams();

  const departureId = searchParams.get('departureId');
  const arrivalId = searchParams.get('arrivalId');

  const [activeTripSection, setActiveTripSection] = useState(TripSections.OUTBOUND_SECTION);

  const {
    data: airwayDepartureItem,
    isFetching: isFetchingAirwayDepartureItem,
  } = useQuery([QueryKeys.ORDERS, departureId], async () => await orderService.getCheapestFlights(departureId!), {
    staleTime: QueryTimes.SMALL,
    enabled: !!departureId,
    refetchOnWindowFocus: true,
    onError: (error) => {
      logError({
        logTag: LOG_TAG,
        error,
        defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_CHEAPEST_FLIGHTS_ERROR_MESSAGE,
      });
    },
  });

  const {
    data: airwayArrivalItem,
    isFetching: isFetchingAirwayArrivalItem,
  } = useQuery([QueryKeys.ORDERS, arrivalId], async () => await orderService.getCheapestFlights(arrivalId!), {
    staleTime: QueryTimes.SMALL,
    enabled: !!arrivalId,
    refetchOnWindowFocus: true,
    onError: (error) => {
      logError({
        logTag: LOG_TAG,
        error,
        defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_CHEAPEST_FLIGHTS_ERROR_MESSAGE,
      });
    },
  });

  const departureCheapestFlights = useMemo(
    () => airwayDepartureItem && getCheapestFlights(airwayDepartureItem),
    [airwayDepartureItem?.cheapestFlights]
  );

  const arrivalCheapestFlights = useMemo(
    () => airwayArrivalItem && getCheapestFlights(airwayArrivalItem),
    [airwayArrivalItem?.cheapestFlights]
  );

  return (
    <CheapestFlightsContainer
      isFetchingAirwayDepartureItem={isFetchingAirwayDepartureItem}
      isFetchingAirwayArrivalItem={isFetchingAirwayArrivalItem}
      departureCheapestFlights={departureCheapestFlights}
      arrivalCheapestFlights={arrivalCheapestFlights}
      activeTripSection={activeTripSection}
      onSetActiveTripSection={setActiveTripSection}
    />
  );
}