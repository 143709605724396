import { Hotel } from "~/application/types";
import {
  HotelBudgetAction,
  HotelBudgetActionType,
  HotelBudgetState,
  useHotelBudgetResult,
} from "./type";
import { useCallback, useReducer, useState } from "react";

export const useHotelBudget = (): useHotelBudgetResult => {
  const [activeBudget, setActiveBudget] = useState(false);

  function HotelBudgetReducer(
    state: HotelBudgetState,
    action: HotelBudgetAction
  ) {
    switch (action.type) {
      case HotelBudgetActionType.ADD: {
        return [...action.paylod];
      }
      case HotelBudgetActionType.CLEAR: {
        return [] as HotelBudgetState;
      }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(
    HotelBudgetReducer,
    [] as HotelBudgetState
  );

  const onSelectHotelBudget = useCallback(
    ({ data }: { data: Hotel }) => {
      const findHotel = state.find((sv) => sv?.uuid === data?.uuid);

      if (findHotel) {
        const hotelsFiltered = state.filter((sv) => sv?.uuid !== data?.uuid);

        dispatch({
          paylod: hotelsFiltered,
          type: HotelBudgetActionType.ADD,
        });
        return;
      }
      dispatch({ paylod: [...state, data], type: HotelBudgetActionType.ADD });
    },
    [state]
  );

  return {
    activeBudget,
    onActiveBudget: setActiveBudget,
    dispatch,
    state,
    onSelectHotelBudget,
  };
};
