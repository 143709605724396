import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";

export type EditSettingParameterProps<T> = {
  data: T;
  onEditClick: (item: T) => void;
};

export const EditSettingParameter = <T,>({
  data,
  onEditClick,
}: EditSettingParameterProps<T>) => (
  <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
    <IconButton size="md" onClick={() => onEditClick(data)}>
      <Icon as={SvgEdit} />
    </IconButton>
  </Tooltip>
);
