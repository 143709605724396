import { Hotel } from "~/application/types";
import { HotelDTO } from "../dtos";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapHotelDTO(data: HotelDTO, searchKey: string): Hotel {
  return {
    searchKey,
    uuid: data.id,
    hotelImages: data.hotelImages,
    name: data.name,
    stars: parseInt(data.stars),
    address: {
      cityName: data.address.cityName,
      state: data.address.state,
      country: data.address.country,
      street: data.address.street,
      quarter: data.address.quarter,
      zipCode: data.address.zipCode,
      number: data.address.number,
      complement: data.address.complement,
      latitude: data.address.latitude,
      longitude: data.address.longitude,
    },
    provider: {
      name: data.provider.name,
      slug: data.provider.slug,
    },
    tax: data.tax,
    bestValueTotal: data.bestValueTotal,
    isAvailable: data.isAvailable,
    bestRoomValue: data.best_room_value,
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
  };
}
