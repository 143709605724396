import * as React from "react";
import { SVGProps } from "react";

const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeMiterlimit: 10,
      }}
      d="M10 1.875C5.52 1.875 1.875 5.52 1.875 10S5.52 18.125 10 18.125 18.125 14.48 18.125 10 14.48 1.875 10 1.875zm0 1.25A6.866 6.866 0 0 1 16.875 10 6.866 6.866 0 0 1 10 16.875 6.866 6.866 0 0 1 3.125 10 6.866 6.866 0 0 1 10 3.125Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 5.625a.625.625 0 0 0-.625.625v4.375a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625V6.25A.625.625 0 0 0 10 5.625Z"
    />
    <path d="M10 14.375a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z" />
  </svg>
);

export default SvgAlert;
