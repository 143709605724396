import { SimpleObservable } from "../../services/SimpleObservable";

export interface DialogType {
  id: number;
  component: JSX.Element;
  isMobile: boolean;
}

export class DialogService extends SimpleObservable {
  private nextId = 0;
  private dialog!: DialogType;

  public getDialog(): DialogType {
    return this.dialog;
  }

  public hasDialog() {
    return this.nextId > 0;
  }

  public nameIs(name: string) {
    return this.hasDialog() && this.dialog?.component?.type?.name === name;
  }

  public showDialog(component: JSX.Element): Promise<number> {
    return new Promise((resolve) => {
      this.dialog = {
        id: this.nextId++,
        isMobile: false,
        component,
      };

      this.inform({ type: "show", data: null });

      resolve(this.nextId);
    });
  }

  public popDialog(id?: number): void {
    this.inform({ type: "hide", data: id ?? this.nextId - 1 });
  }

  public popAll(): void {
    while (this.nextId) {
      this.inform({ type: "hide", data: --this.nextId });
    }
  }
}

export const dialogService = new DialogService();
