import * as React from "react";
import { SVGProps } from "react";

const SvgGps = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 5.014a3.135 3.135 0 0 0-3.125 3.125A3.135 3.135 0 0 0 10 11.264a3.135 3.135 0 0 0 3.125-3.125A3.135 3.135 0 0 0 10 5.014zm0 1.25c1.043 0 1.875.832 1.875 1.875A1.866 1.866 0 0 1 10 10.014a1.866 1.866 0 0 1-1.875-1.875c0-1.043.832-1.875 1.875-1.875z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 1.264a6.876 6.876 0 0 0-6.875 6.875c0 6.088 6.516 10.511 6.516 10.511a.625.625 0 0 0 .718 0s6.516-4.423 6.516-10.511A6.876 6.876 0 0 0 10 1.264Zm0 1.25a5.621 5.621 0 0 1 5.625 5.625c0 4.851-4.99 8.671-5.625 9.148-.636-.477-5.625-4.297-5.625-9.148A5.627 5.627 0 0 1 10 2.514Z"
    />
  </svg>
);

export default SvgGps;
