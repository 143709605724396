import { EditableCard } from "~/application/usecases/CreditCard";

const DEFAULT_FORM_DATA = {} as EditableCard;

export function createCard(editable: EditableCard): EditableCard {
  return {
    ...editable,
  };
}

export function editCard(data?: EditableCard): EditableCard {
  if (!data?.uuid) return DEFAULT_FORM_DATA;

  return {
    ...data,
    internalCardCode: data.internalCardCode ?? undefined,
    installments: data.installments ?? 1,
    flag: {
      uuid: data.flag.uuid,
      identifier: data.flag.identifier,
      name: data.flag.name,
    },
  };
}
