import { Fragment, useCallback } from "react";
import { CustomerEmployee } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgFile, SvgPlus } from "~/components/Icon/icons";
import { SearchBar, Select } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { DataColItem } from "~/components/List";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { CustomerEmployeeListItem } from "~/presentation/shared/components/CustomerEmployeeListItem";
import { TabCustomerEmployeesProps } from "./types";
import { useParams } from "react-router-dom";
import { Alert, AlertIcon } from "~/components/Alert";
import { FormControl } from "~/components/FormControl";

export function TabCustomerEmployees({
  data,
  searchText,
  profilesOptions,
  activenessOptions,
  hasUploadedFromSpreadsheet,
  control,
  currentPage,
  lastPage,
  approverOptions,
  onGoToPage,
  setSearchText,
  onCleanFilters,
  onChangePassword,
  onCreateCustomerEmployee,
  onEditCustomerEmployee,
  onToggleCustomerEmployeeState,
  onCreateCustomerEmployeeBySpreadsheet,
}: TabCustomerEmployeesProps) {
  const { customerId } = useParams() as { customerId: string };
  
  const listRenderer = useCallback(
    (item: CustomerEmployee) => (
      <CustomerEmployeeListItem
        data={item}
        onEditClick={onEditCustomerEmployee}
        onChangePassword={onChangePassword}
        onToggleState={onToggleCustomerEmployeeState}
        key={item.uuid}
      />
    ),
    [onEditCustomerEmployee, onToggleCustomerEmployeeState]
  );

  return (
    <Fragment>
      <Card>
        <CardBody css={{ borderBottom: "1px solid $colors$neutrals-light" }}>
          <Row align="center">
            <Col>
              <H4>Funcionários</H4>
            </Col>

            <Col
              sz="auto"
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <SearchBar
                search={searchText}
                onSearchChange={setSearchText}
                style={{ width: "100%" }}
                placeholder="Digite o nome ou cpf"
              />
            </Col>

            <Col
              sz="auto"
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <Button
                variant="tertiary"
                css={{
                  "@mxlg": {
                    width: "100%",
                  },
                }}
                onClick={() => {
                  onCreateCustomerEmployeeBySpreadsheet(customerId);
                }}
              >
                <Icon as={SvgFile} />
                <Text>Importar planilha</Text>
              </Button>
            </Col>

            <Col
              sz="auto"
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <Button
                css={{
                  "@mxlg": {
                    width: "100%",
                  },
                }}
                title="Novo funcionário"
                color="primary"
                onClick={onCreateCustomerEmployee}
              >
                <Icon as={SvgPlus} />
                <Text>Novo funcionário</Text>
              </Button>
            </Col>
          </Row>
        </CardBody>

        <CardBody>
          <Row
            gap="4"
            align="center"
            wrap="noWrap"
            css={{
              "@mxlg": {
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <DataColItem
              boxCss={{ my: "$2" }}
              headerTitle="Perfis"
              data={
                <FormControl control={control} name="profiles">
                  <Select options={profilesOptions} placeholder="Perfis" portalled />
                </FormControl>
              }
            />

            <DataColItem
              boxCss={{ my: "$2" }}
              headerTitle="Aprovador"
              data={
                <FormControl control={control} name="approvers">
                  <Select options={approverOptions} placeholder="Perfis" portalled />
                </FormControl>
              }
            />

            <DataColItem
              headerTitle="Estado"
              boxCss={{ my: "$2" }}
              data={
                <FormControl name="activeness" control={control}>
                  <Select options={activenessOptions} placeholder="Estado" portalled />
                </FormControl>
              }
            />

            <Col sz="auto">
              <Button title="Limpar filtros" variant="tertiary" onClick={onCleanFilters}>
                <Text>Limpar filtros</Text>
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Box css={{ my: "$6" }}>
        {hasUploadedFromSpreadsheet && (
          <Alert variant="info" css={{ mb: "$6" }}>
            <AlertIcon />
            <Text css={{ lineHeight: "1.25" }}>
              Alguns cadastros podem não ter sido concluídos por falta de alguma informação.
              Certifique-se que as informações estejam corretas para cadastrar os funcionários.
            </Text>
          </Alert>
        )}

        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhum funcionário corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui funcionários cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
