import * as React from "react";
import { SVGProps } from "react";

const SvgReload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="reload_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={25}
      height={24}
    >
      <path fill="#D9D9D9" d="M.5 0h24v24H.5z" />
    </mask>
    <g mask="url(#reload_svg__a)">
      <path
        d="M12.55 20c-2.233 0-4.133-.775-5.7-2.325C5.283 16.125 4.5 14.233 4.5 12v-.175l-1.6 1.6-1.4-1.4 4-4 4 4-1.4 1.4-1.6-1.6V12c0 1.667.588 3.083 1.762 4.25C9.438 17.417 10.867 18 12.55 18c.433 0 .858-.05 1.275-.15.417-.1.825-.25 1.225-.45l1.5 1.5a8.247 8.247 0 0 1-1.95.825 7.698 7.698 0 0 1-2.05.275Zm6.95-4.025-4-4 1.4-1.4 1.6 1.6V12c0-1.667-.587-3.083-1.762-4.25C15.563 6.583 14.133 6 12.45 6c-.433 0-.858.05-1.275.15-.417.1-.825.25-1.225.45l-1.5-1.5a8.25 8.25 0 0 1 1.95-.825A7.696 7.696 0 0 1 12.45 4c2.233 0 4.133.775 5.7 2.325C19.717 7.875 20.5 9.767 20.5 12v.175l1.6-1.6 1.4 1.4-4 4Z"
        fill="#668099"
      />
    </g>
  </svg>
);

export default SvgReload;
