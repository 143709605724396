import React, { forwardRef, useCallback, useContext, useMemo, useState } from "react";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { FormControlContext } from "~/components/FormControl/FormControlContext";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgChevronRight } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { createPopover } from "~/components/Popover/PopoverHoC";
import { Text } from "~/components/Text";
import {
  DateFormats,
  displayDate,
  getNextMonth,
  getPreviousMonth,
  isDateValid,
  toDate,
} from "~/utils/date.utils";
import { Calendar } from "../../Calendar";
import { TextInputProps } from "../TextInput";
import { DateInput } from "./DateInput";

export type DateInputWithCalendarProps = TextInputProps & {
  onChangeCallback?: (value: string) => void;
  minValue?: Date | string;
  maxValue?: Date | string;
};

const PopoverDateInput = createPopover(DateInput);

const DATE_FORMAT = DateFormats.SMALL_DATE;

export const DateInputWithCalendar = forwardRef<
  React.ElementRef<typeof PopoverDateInput>,
  DateInputWithCalendarProps
>(
  (
    {
      minValue,
      maxValue = new Date(),
      name: nameProp,
      disabled: disabledProp,
      onBlur: onBlurProp,
      onFocus: onFocusProp,
      onChange: onChangeProp,
      value: valueProp,
      onChangeCallback,
      ...props
    },
    forwardedRef
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [valueState, setValue] = useState<string>("");

    const formControl = useContext(FormControlContext);

    const name = nameProp ?? formControl.name;
    const disabled = disabledProp ?? formControl.disabled;
    const value = valueProp ?? formControl.value ?? valueState;
    const isDirty = !!formControl.error;
    const onBlur = onBlurProp ?? formControl.onBlur;
    const onChange = onChangeProp ?? formControl.onChange;

    const dateValue = useMemo(() => {
      const date = toDate(value, DATE_FORMAT);
      return isDateValid(date) ? date : new Date();
    }, [value]);

    const [refMonth, setRefMonth] = useState(dateValue);

    const handleDateSelect = useCallback(
      (date: Date) => {
        let value = displayDate(date, DATE_FORMAT) as any;

        if (value === valueState) {
          value = "";
        }

        onChange?.(value);
        onChangeCallback?.(value);
        setValue(value);
      },
      [onChange, onChangeCallback, valueState]
    );

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        setIsPopoverOpen(true);

        const value = event.target.value;

        const date = toDate(value, DATE_FORMAT);

        if (isDateValid(date)) {
          setRefMonth(date);
        }

        setValue(value);

        onChange?.(event);

        onChangeCallback?.(value);
      },
      [onChange, onChangeCallback]
    );

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        setIsPopoverOpen(true);
        onFocusProp?.(event);
      },
      [onFocusProp]
    );

    const handlePrevious = useCallback(() => {
      setRefMonth((old) => getPreviousMonth(old));
    }, []);

    const handleNext = useCallback(() => {
      setRefMonth((old) => getNextMonth(old));
    }, []);

    const handleClosePopover = useCallback(() => {
      setIsPopoverOpen(false);
    }, []);

    return (
      <PopoverDateInput
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={onBlur}
        disabled={disabled}
        name={name}
        data-dirty={isDirty}
        ref={forwardedRef}
        {...props}
        onClickOutside={handleClosePopover}
        popoverOpen={isPopoverOpen}
        popoverContent={
          <Card
            css={{
              boxShadow: "$md",
              "@mxlg": {
                position: "relative",
                bottom: "$40",
              },
            }}
          >
            <CardBody css={{ p: "$4" }}>
              <Flex gap="4" align="center" justify="between">
                <IconButton size="md" onClick={handlePrevious}>
                  <Icon as={SvgChevronLeft} />
                </IconButton>

                <Text size="2" css={{ fw: "600", textAlign: "center" }}>
                  {displayDate(refMonth, DateFormats.MONTH_YEAR)}
                </Text>

                <IconButton size="md" onClick={handleNext}>
                  <Icon as={SvgChevronRight} />
                </IconButton>
              </Flex>

              <Box css={{ mt: "$4" }}>
                <Calendar
                  value={dateValue}
                  minValue={minValue}
                  maxValue={maxValue}
                  onDateSelect={handleDateSelect}
                  referenceMonth={refMonth}
                />
              </Box>
            </CardBody>
          </Card>
        }
      />
    );
  }
);

DateInputWithCalendar.displayName = "DateInputWithCalendar";
