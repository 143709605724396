import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { ApiError } from "~/application/types";
import type {
  Hotel,
  HotelRoom,
  HotelRule,
  HotelRulesLink,
} from "~/application/types";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { hotelRuleService } from "~/application/usecases";
import { log } from "~/utils/log";
import { useQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { AccommodationRulesDialog } from ".";

export type AsyncAccommodationRulesDialogProps = {
  hotel: Hotel;
  room: HotelRoom;
  onCloseClick?: () => void;
};

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar regras",
} as const;

export function AsyncAccommodationRulesDialog({
  hotel,
  room,
  onCloseClick,
}: AsyncAccommodationRulesDialogProps) {
  const { data: hotelRulesLink, isFetching: isFetchingLink } = useQuery<
    HotelRulesLink,
    ApiError
  >(
    [QueryKeys.QUERY_HOTEL_ROOM_RULES, hotel, room],
    () =>
      hotelRuleService.query(
        hotel?.searchKey || "",
        hotel?.uuid || "",
        room.roomId
      ),
    {
      cacheTime: QueryTimes.NORMAL,
      onError: (error) => {
        log.e("", error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { data: hotelRules, isFetching: isFetchingHotelRules } = useQuery<
    HotelRule[],
    ApiError
  >(
    [QueryKeys.HOTEL_ROOM_RULES, hotel, room],
    () => hotelRuleService.find(hotelRulesLink!.link),
    {
      staleTime: QueryTimes.NORMAL,
      enabled: !isFetchingLink,
      retry: true,
      retryDelay: hotelRulesLink?.waitTime,
      onError: (error) => {
        log.e("", error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  return (
    <Fragment>
      {isFetchingHotelRules || !hotelRules ? (
        <LoadingDialog message="Carregando políticas" />
      ) : (
        <AccommodationRulesDialog
          data={hotelRules}
          onCloseClick={onCloseClick}
        />
      )}
    </Fragment>
  );
}

AsyncAccommodationRulesDialog.displayName = "AsyncAccommodationRulesDialog";
