import React, { ComponentProps, forwardRef, useContext } from "react";
import { styled } from "~/application/theme";
import { FormControlContext } from "../../FormControl/FormControlContext";
import { TextAreaBase } from "../base/TextAreaBase";

export type TextAreaInputProps = ComponentProps<typeof TextAreaInputRoot>;

const TextAreaInputRoot = styled(TextAreaBase, {});

export const TextAreaInput = forwardRef<
  React.ElementRef<typeof TextAreaInputRoot>,
  TextAreaInputProps
>(
  (
    {
      size,
      name: nameProps,
      disabled: disabledProps,
      onBlur: onBlurProps,
      onChange: onChangeProps,
      value: valueProps,
      ...props
    },
    forwardedRef
  ) => {
    const formControl = useContext(FormControlContext);

    const name = nameProps ?? formControl.name;
    const disabled = disabledProps ?? formControl.disabled;
    const onBlur = (onBlurProps ??
      formControl.onBlur) as React.FocusEventHandler<HTMLDivElement> &
      React.FocusEventHandler<HTMLTextAreaElement>;
    const onChange = (onChangeProps ??
      formControl.onChange) as React.ChangeEventHandler<HTMLDivElement> &
      React.ChangeEventHandler<HTMLTextAreaElement>;
    const value = valueProps ?? formControl.value;
    const isDirty = !!formControl.error;

    return (
      <TextAreaInputRoot
        size={size}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        data-dirty={isDirty}
        {...props}
        ref={forwardedRef}
      />
    );
  }
);
