import queryString from "query-string";
import { routes } from "~/application/theme/routes";
import { toISOString } from "~/application/utils/date-functions";
import { RoadQueryFormData } from "~/presentation/shared/components/RoadQueryForm";

export function createRoadQueryUrl(data: RoadQueryFormData, orderId: string) {
  const roadQuery = {
    orderId: orderId,
    reasonTrip: JSON.stringify(data.reasonTrip),
    stretches: JSON.stringify(
      data.stretches.map((stretch) => ({
        destinationSelected: JSON.stringify(stretch.destinationSelected),
        originSelected: JSON.stringify(stretch.originSelected),
        dateGoSelected: toISOString(stretch.dateGoSelected as Date),
        dateReturnSelected: !stretch.dateReturnSelected
          ? null
          : toISOString(stretch.dateReturnSelected),
      }))
    ),
  };

  const queryUrl = queryString.stringifyUrl({
    url: routes.Booking.Road.Result.root,
    query: roadQuery,
  });

  return queryUrl;
}
