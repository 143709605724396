import { useNavigate } from "react-router-dom";

import { CostCenter } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { CustomerTab } from "~/core/modules/Customer/utils";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";

export interface CostCenterListItemProps extends DataListItemProps {
  data: CostCenter;
  onEditClick: (item: CostCenter) => void;
  onToggleState: (item: CostCenter) => void;
}

export function CostCenterListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: CostCenterListItemProps) {
  const navigate = useNavigate();

  const approvalModelStatus = data.approvalModels.find((approvalModel) => approvalModel.active);

  const isMobile = useMobile();

  const handleOpenEditApprovalModelModal = () => {
    if (!approvalModelStatus?.name) return;

    navigate(`?modelo-de-aprovacao=${approvalModelStatus.uuid}#${CustomerTab.APPROVAL_MODELS}`);

    dialogService.showDialog(<LoadingDialog message="Buscando modelo de aprovação" />);
  };

  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{ "@mxlg": { display: "flex", flexDirection: "column" } }}
    >
      <DataColItem headerTitle="Nome" data={data.name} />
      <DataColItem headerTitle="Integração Externa" data={data.costCenterCode} />
      <DataColItem
        headerTitle="Modelo de aprovação"
        data={approvalModelStatus?.name ?? "Sem modelo vinculado"}
        css={{ 
          cursor: approvalModelStatus?.name ? "pointer" : "auto", 
          "&:hover": { textDecoration: approvalModelStatus?.name ? "underline" : "none"},
        }}
        onClick={handleOpenEditApprovalModelModal}
      />

      {isMobile ? (
        <Flex justify="between" css={{ width: "100%" }}>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          {onToggleState && (
            <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
              <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
            </Switch>
          )}
        </Flex>
      ) : (
        <>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          {onToggleState && (
            <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
              <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
            </Switch>
          )}
        </>
      )}
    </DataListItem>
  );
}

CostCenterListItem.displayName = "CostCenterListItem";
