import queryString from "query-string";
import { useMemo } from "react";
import { Collapse } from "react-collapse";
import { OrderItems, OrderStatus } from "~/application/types";
import { Box } from "~/components/Box";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgClose, SvgPlus } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { DataListItem, DataListItemProps } from "~/components/List";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import { useOrder } from "../../../../../../../../../presentation/shared/hooks/useOrder/useOrder";
import { shouldTagItemAsRejected } from "../../utils";
import { Button } from "~/components/Button";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";

export interface OrderItemListItemProps extends DataListItemProps {
  isOpen: boolean;
  orderId?: string;
  item: {
    type: OrderItems;
    isRejected?: boolean;
  };
  onItemExpand?: () => void;
  canceled?: boolean;
}

export function OrderItemListItem({
  isOpen,
  item,
  orderId,
  onItemExpand,
  children,
  canceled,
  ...props
}: OrderItemListItemProps) {
  const { order } = useOrder();

  const linkToAddItem = useMemo(() => {
    const serviceUrl = OrderUtils.getServiceLink(item.type);

    if (serviceUrl) {
      return queryString.stringifyUrl({
        url: serviceUrl,
        query: {
          orderId,
        },
      });
    }
  }, [item, orderId, order]);

  const orderStatus = order?.status as OrderStatus;
  const canAddItem =
    ![
      OrderStatus.APPROVED,
      OrderStatus.ON_APPROVAL,
      OrderStatus.ISSUED,
      OrderStatus.CANCELED,
      OrderStatus.CANCELING,
      OrderStatus.PENDING_ISSUE,
      OrderStatus.QUOTING,
    ].includes(orderStatus) && linkToAddItem;

  const shouldMarkAsRejected = shouldTagItemAsRejected({
    itemIsRejected: item.isRejected,
    order,
  });

  return (
    <Box>
      <DataListItem {...props}>
        <Icon as={OrderUtils.getServiceIcon(item.type)} size="lg" variant="primary" />

        <Col>
          <Text size="5" css={{ fw: "600" }}>
            {OrderUtils.getServiceLabel(item.type)}
          </Text>
        </Col>

        {shouldMarkAsRejected && (
          <Tag variant="error-light">
            <Text>Reprovado</Text>
          </Tag>
        )}

        {canAddItem && (
          <Link to={linkToAddItem}>
            <Button
              variant="tertiary"
              css={{
                "@mxlg": {
                  p: "$2",
                  height: "$8",
                },
              }}
            >
              <Icon
                as={SvgClose}
                css={{
                  fill: "$neutrals-dark",
                  transform: "rotate(45deg)",
                }}
              />
              <Text
                size="2"
                variant="dark"
                css={{
                  mt: "$1",
                  "@mxlg": {
                    display: "none",
                  },
                }}
              >
                Adicionar item
              </Text>
            </Button>
          </Link>
        )}

        {!canceled && (
          <Tooltip content={<TooltipLabel>{isOpen ? "Recolher itens" : "Ver itens"}</TooltipLabel>}>
            <IconButton size="md" onClick={onItemExpand}>
              <Icon as={isOpen ? SvgChevronUp : SvgChevronDown} />
            </IconButton>
          </Tooltip>
        )}
      </DataListItem>

      <Collapse isOpened={isOpen}>
        <Box css={{ mt: "$6" }}>{children}</Box>
      </Collapse>
    </Box>
  );
}

OrderItemListItem.displayName = "OrderItemListItem";
