import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type ContainerProps = ComponentProps<typeof Container>;

export const Container = styled("div", {
  // Reset
  boxSizing: "border-box",
  flexShrink: 0,

  // Custom
  width: "100%",
  ml: "auto",
  mr: "auto",

  "&[data-mobile=true]": {
    p: "0",
  },

  variants: {
    size: {
      "1": {
        maxWidth: "72px",
      },
      "2": {
        maxWidth: "168px",
      },
      "3": {
        maxWidth: "264px",
      },
      "4": {
        maxWidth: "360px",
      },
      "5": {
        maxWidth: "456px",
      },
      "6": {
        maxWidth: "552px",
      },
      "7": {
        maxWidth: "648px",
      },
      "8": {
        maxWidth: "744px",
      },
      "9": {
        maxWidth: "840px",
      },
      "10": {
        maxWidth: "936px",
      },
      "11": {
        maxWidth: "1032px",
      },
      "12": {
        maxWidth: "1128px",
      },
      "13": {
        maxWidth: "1224px",
      },
      fluid: {
        maxWidth: "none",
        px: "$16",
      },
    },
    fixed: {
      true: {
        width: "100vw",
      },
    },
  },

  defaultVariants: {
    size: "fluid",
  },
});
