import { FC } from "react";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgBus } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { DefaultCompanyLogoProps } from "./types";

export const DefaultCompanyLogo: FC<DefaultCompanyLogoProps> = ({
  companyName,
}) => {
  return (
    <Flex
      css={{
        width: "fit-content",
        height: "max-content",
        borderRadius: "$lg",
      }}
      align="center"
    >
      <Icon
        size="xl"
        as={SvgBus}
        css={{
          fill: "#fff",
          borderRadius: "$md 0 0 $md",
          p: "$2",
          backgroundColor: "$primary-dark",
        }}
      />
      <Flex
        css={{
          backgroundColor: "$primary-base",
          borderRadius: "0 $md $md 0",
          p: "$2",
          pr: "$4",
          height: "$10",
        }}
        align="center"
      >
        <Text
          css={{
            fontSize: "clamp(10px, 1.5vw, 16px)",
            color: "#fff",
            fontWeight: 600,
          }}
        >
          {companyName}
        </Text>
      </Flex>
    </Flex>
  );
};
