import queryString from "query-string";
import type { CostCenter, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { CostCenterDTO } from "~/infrastructure/api/dtos";
import { mapCostCenterDTO } from "~/infrastructure/api/mappers";
import {
  FindCostCenterState,
  type ICostCenterService,
  type IFindCostCenterParams,
  type IFindCostCenterWithoutApprovalModelLinkParams,
} from "./ICostCenterService";

export class CostCenterService implements ICostCenterService {
  async findById(id: string): Promise<CostCenter> {
    return await api
      .get<CostCenterDTO>(`/customers/cost-centers/${id}`)
      .then(({ data }) => mapCostCenterDTO(data));
  }

  async find({
    customerId,
    name,
    page,
    state = FindCostCenterState.ALL,
    phase,
  }: IFindCostCenterParams): Promise<CostCenter[]> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/cost-centers`,
      query: { name, page, state, phase },
    });

    return await api
      .get<PaginatedResource<CostCenterDTO[]>>(url)
      .then(({ data: response }) => response.data.map(mapCostCenterDTO));
  }

  async findPaginated({
    customerId,
    name,
    page,
  }: IFindCostCenterParams): Promise<PaginatedResource<CostCenter[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/cost-centers`,
      query: { name, page, state: "all" },
    });

    return await api.get<PaginatedResource<CostCenterDTO[]>>(url).then(({ data: response }) => ({
      data: response.data.map(mapCostCenterDTO),
      meta: response.meta,
      links: response.links,
    }));
  }

  async findWithoutApprovalModelLink({
    name,
    customerId,
    approvalModelId,
  }: IFindCostCenterWithoutApprovalModelLinkParams): Promise<CostCenter[]> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/approval-models/cost-centers`,
      query: { approval_model_uuid: approvalModelId, name },
    });

    return await api
      .get<{ data: CostCenterDTO[] }>(url)
      .then(({ data }) => data.data.map(mapCostCenterDTO));
  }

  async create(data: Omit<CostCenter, "uuid">): Promise<CostCenter> {
    return await api
      .post<CostCenterDTO>("/customers/cost-centers", {
        name: data.name,
        cost_center_code: data.costCenterCode,
        credit: data.credit ?? 0,
        customer_uuid: data.customerId,
      })
      .then(({ data }) => mapCostCenterDTO(data));
  }

  async updateById(data: CostCenter): Promise<CostCenter> {
    return await api
      .put<CostCenterDTO>(`/customers/cost-centers/${data.uuid}`, {
        name: data.name,
        cost_center_code: data.costCenterCode,
        credit: data.credit,
      })
      .then(({ data }) => mapCostCenterDTO(data));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async toggleActive(data: CostCenter): Promise<void> {
    return await api
      .patch<undefined>(
        `/customers/cost-centers/${data.uuid}/${data.isActive ? "inactive" : "active"}`
      )
      .then(({ data }) => data);
  }

  async deleteById(data: CostCenter): Promise<void> {
    return await api
      .delete<undefined>(`/customers/cost-centers/${data.uuid}`)
      .then(({ data }) => data);
  }
}
