import { FC } from "react";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, TabBar } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { MenuTabsProps } from "./types";

export const MenuTabs: FC<MenuTabsProps> = ({ isLoading, tabs }) => {
  return (
    <Container>
      <TabBar>
        {isLoading ? (
          <Flex gap="8" css={{ p: "$4" }}>
            <Skeleton variant="text-4" style={{ width: 96 }} />
            <Skeleton variant="text-4" style={{ width: 96 }} />
          </Flex>
        ) : (
          tabs.map(({ title, id }) => (
            <Link to={`#${id}`} title={title} key={id}>
              <CounterTab id={id} value={id}>
                <Text>{title}</Text>
              </CounterTab>
            </Link>
          ))
        )}
      </TabBar>
    </Container>
  );
};
