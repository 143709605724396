import { useCallback, useEffect, useRef, useState } from "react";

import { Box } from "~/components/Box";
import GoogleMapReact from "google-map-react";
import { Marker } from "./components/Marker";
import { Skeleton } from "~/components/Skeleton";
import { Hotel } from "~/application/types";
import { Checkbox } from "~/components/Input";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";

export type BoundsData = {
  northeast: {
    lat: number | undefined;
    lng: number | undefined;
  };
  southwest: {
    lat: number | undefined;
    lng: number | undefined;
  };
};
export interface MapViewDialogProps {
  onCloseClick?: () => void;
  hotelsList?: Hotel[];
  onSelect?: (data: Hotel | undefined) => void;
  onSelectHover?: (data: Hotel | undefined) => void;
  data?: Hotel;
  description?: string;
  setBounds?: (data: BoundsData) => void;
  hotelSelectedHover?: Hotel | undefined;
  hotelSelectedInMap?: Hotel | undefined;
  searchText?: string;
  rangeValue?: {
    max?: number;
    min?: number;
    providersFinished: number;
  };
  setCanFilteredMap?: (data: boolean) => void;
  canFilteredMap?: boolean;
}

export function MapViewDialog({
  hotelsList,
  onSelect,
  setBounds,
  onSelectHover,
  hotelSelectedHover,
  hotelSelectedInMap,
  canFilteredMap,
  setCanFilteredMap,
}: MapViewDialogProps) {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [hotelLength, setHotelLength] = useState<number | undefined>(0);

  const getMapBounds = (
    map: google.maps.Map,
    maps: typeof google.maps,
    hotels?: Hotel[]
  ): google.maps.LatLngBounds | null => {
    const bounds = new maps.LatLngBounds();
    let hasValidCoordinates = false;

    hotels?.forEach((hotel) => {
      const latitude = hotel?.address.latitude || 0;
      const longitude = hotel?.address.longitude || 0;

      if (latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180) {
        bounds.extend(new maps.LatLng(latitude, longitude));
        hasValidCoordinates = true;
      }
    });

    return hasValidCoordinates ? bounds : null;
  };

  const handleMapIdle = (data: GoogleMapReact.ChangeEventValue) => {
    const mapBounds = data.bounds;
    const ne = mapBounds?.ne;
    const sw = mapBounds?.sw;

    const boundsData = {
      northeast: { lat: ne?.lat, lng: ne?.lng },
      southwest: { lat: sw?.lat, lng: sw?.lng },
    };

    setBounds?.(boundsData);
  };
  const handleMapLoad = useCallback(
    (map: google.maps.Map, maps: typeof google.maps) => {
      mapRef.current = map;

      const bounds = getMapBounds(map, maps, hotelsList);

      if (bounds) {
        map.fitBounds(bounds);
      }
    },
    [hotelsList]
  );

  useEffect(() => {
    if (
      mapRef.current &&
      (hotelsList?.length || 0) > 0 &&
      (hotelLength || 0) !== (hotelsList?.length || 0)
    ) {
      const maps = google.maps;
      const bounds = getMapBounds(mapRef.current, maps, hotelsList);
      if (bounds) {
        mapRef.current.fitBounds(bounds);
        setHotelLength(hotelsList?.length);
      }
    }
  }, [hotelsList, hotelLength]);

  return (
    <Box
      css={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {!hotelsList?.length && (
        <Skeleton
          css={{
            width: "100%",
            height: "100%",
          }}
        />
      )}
      <Flex
        css={{
          width: "fit-content",
          position: "absolute",
          top: "$2",
          left: "$2",
          p: "$2",
          backgroundColor: "White",
          zIndex: "$appBar",
        }}
      >
        <Checkbox checked={canFilteredMap} onClick={() => setCanFilteredMap?.(!canFilteredMap)}>
          <Text fw={"700"}>Filtrar pelo mapa</Text>
        </Checkbox>
      </Flex>
      {!!hotelsList?.length && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_CLIENT_ID }}
          yesIWantToUseGoogleMapApiInternals
          onChange={handleMapIdle}
          defaultZoom={15}
          defaultCenter={{ lat: 0, lng: 0 }}
          onGoogleApiLoaded={({ map, maps }) => handleMapLoad(map, maps)}
        >
          {hotelsList?.map((hotel) => {
            return (
              <Marker
                onSelectHover={onSelectHover}
                onSelectInMap={onSelect}
                key={hotel?.uuid}
                data={hotel}
                ishotelSelectedHover={hotelSelectedHover?.uuid === hotel?.uuid}
                ishotelSelected={hotelSelectedInMap?.uuid === hotel?.uuid}
                lat={hotel?.address.latitude}
                lng={hotel?.address.longitude}
              />
            );
          })}
        </GoogleMapReact>
      )}
    </Box>
  );
}
