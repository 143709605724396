import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgClose } from "~/components/Icon/icons";
import { Text } from "~/components/Text";

type ApprovalButtonProps = {
  thereIsApprovedItem: boolean;
  thereIsRejectedItem: boolean;
  onApproveItem: () => void;
  onReproveItem: () => void;
  isReject?: boolean;
};

export const ApprovalButton = ({
  thereIsApprovedItem,
  thereIsRejectedItem,
  onApproveItem,
  onReproveItem,
  isReject = false,
}: ApprovalButtonProps) => {
  const clicked = thereIsApprovedItem || thereIsRejectedItem;

  return isReject ? (
    <Flex onClick={onReproveItem} css={{ cursor: "pointer" }} align="center">
      <Flex
        css={{
          backgroundColor: `
        ${thereIsRejectedItem ? "$error-light" : ""}`,
          borderRadius: "$lg 0 0 $lg",
          p: "14px 0 14px 14px",
          border: `2px solid ${thereIsRejectedItem
              ? "$error-base"
              : clicked
                ? "$neutrals-base"
                : "$primary-base"
            }`,
          borderRight: "none",
        }}
      ></Flex>
      <Flex
        css={{
          p: "$1 0",
          backgroundColor: `
          ${thereIsRejectedItem ? "$error-light" : ""}`,
          border: `2px solid ${thereIsRejectedItem
              ? "$error-base"
              : clicked
                ? "$neutrals-base"
                : "$primary-base"
            }`,
          borderRight: "none",
          borderLeft: "none",
          fill: `${thereIsRejectedItem
              ? "$error-base"
              : clicked
                ? "$neutrals-base"
                : "$primary-base"
            }`,
        }}
        gap="2"
        align="center"
      >
        <Icon as={SvgClose} />
        <Text
          variant={`${thereIsRejectedItem ? "error-base" : clicked ? "neutral" : "primary"
            }`}
          fw="600"
        >
          {" "}
          Recusar
        </Text>
      </Flex>
      <Flex
        css={{
          backgroundColor: `
        ${thereIsRejectedItem ? "$error-light" : ""}`,
          borderRadius: " 0 $lg $lg 0 ",
          p: "14px 14px 14px 0",
          border: `2px solid ${thereIsRejectedItem
              ? "$error-base"
              : clicked
                ? "$neutrals-base"
                : "$primary-base"
            }`,
          borderLeft: "none",
        }}
      ></Flex>
    </Flex>
  ) : (
    <Flex onClick={onApproveItem} css={{ cursor: "pointer" }} align="center">
      <Flex
        css={{
          backgroundColor: `
        ${thereIsApprovedItem ? "$success-light" : ""}`,
          borderRadius: "$lg 0 0 $lg",
          p: "14px 0 14px 14px",
          border: `2px solid ${thereIsApprovedItem
              ? "$success-base"
              : clicked
                ? "$neutrals-base"
                : "$primary-base"
            }`,
          borderRight: "none",
        }}
      ></Flex>
      <Flex
        css={{
          backgroundColor: `
          ${thereIsApprovedItem ? "$success-light" : ""}`,
          p: "$1 0",
          border: `2px solid ${thereIsApprovedItem
              ? "$success-base"
              : clicked
                ? "$neutrals-base"
                : "$primary-base"
            }`,
          borderRight: "none",
          borderLeft: "none",
          fill: `${thereIsApprovedItem
              ? "$success-base"
              : clicked
                ? "$neutrals-base"
                : "$primary-base"
            }`,
        }}
        gap="2"
        align="center"
      >
        <Icon as={SvgCheck} />
        <Text
          variant={`${thereIsApprovedItem ? "sucess" : clicked ? "neutral" : "primary"
            }`}
          fw="600"
        >
          {" "}
          Aprovar
        </Text>
      </Flex>
      <Flex
        css={{
          backgroundColor: `
        ${thereIsApprovedItem ? "$success-light" : ""}`,
          borderRadius: " 0 $lg $lg 0 ",
          p: "14px 14px 14px 0",
          border: `2px solid ${thereIsApprovedItem
              ? "$success-base"
              : clicked
                ? "$neutrals-base"
                : "$primary-base"
            }`,
          borderLeft: "none",
        }}
      ></Flex>
    </Flex>
  );
};
