import React, { createContext, ReactNode } from "react";
import { Order } from "~/application/types";

export interface IOrderContext {
  order?: Order;
  orderReducer?: {
    state: Order;
    dispatch: React.Dispatch<{
      type: string;
      payload: Order;
    }>;
  };
}

export const OrderContext = createContext<IOrderContext>({} as IOrderContext);

interface OrderContextProviderParams {
  order?: Order;
  children: ReactNode;
  orderReducer?: {
    state: Order;
    dispatch: React.Dispatch<{
      type: string;
      payload: Order;
    }>;
  };
}

export function OrderProvider({
  order,
  children,
  orderReducer,
}: OrderContextProviderParams) {
  return (
    <OrderContext.Provider value={{ order, orderReducer }}>
      {children}
    </OrderContext.Provider>
  );
}
