import { AdvanceOrderStatus, TravelerAdvance } from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { Pagination } from "~/components/Pagination";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, CounterTabBadge, TabBar, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Fragment, useCallback } from "react";
import { ExpenseListItem } from "./components/ExpenseListItem";
import { ExpensesTab, EXPENSE_MENU_TABS } from "./utils";

export interface ExpensesContainerProps {
  isLoading: boolean;
  activeTab: ExpensesTab;
  currentPage: number;
  lastPage: number;
  advances: TravelerAdvance[];
  onGoToPage: (page: number) => void;
}

export function ExpensesContainer({
  advances,
  isLoading,
  activeTab,
  currentPage,
  lastPage,
  onGoToPage,
}: ExpensesContainerProps) {
  const { user } = useUser();
  const listRenderer = useCallback(
    (item: TravelerAdvance) => (
      <ExpenseListItem user={user} advanceOrder={item} key={item.uuid} />
    ),
    []
  );

  const approvingAdvances = advances.filter(
    ({ status }) => status === AdvanceOrderStatus.APPROVING
  );

  const approvedAdvances = advances.filter(
    ({ status }) => status === AdvanceOrderStatus.APPROVED
  );

  const currentAdvances =
    activeTab === ExpensesTab.CONFERENCE ? approvingAdvances : approvedAdvances;

  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <AppBarHeading title="Despesas" />
          </AppBarContainer>

          <Container>
            <TabBar>
              {isLoading ? (
                <Flex gap="8" css={{ p: "$4", pl: 0 }}>
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                </Flex>
              ) : (
                EXPENSE_MENU_TABS.map(({ title, id }) => (
                  <Link to={`#${id}`} title={title} key={id}>
                    <CounterTab id={id} value={id}>
                      <Text>{title}</Text>
                      <CounterTabBadge>
                        {title === ExpensesTab.CONFERENCE
                          ? approvingAdvances.length
                          : approvedAdvances.length}
                      </CounterTabBadge>
                    </CounterTab>
                  </Link>
                ))
              )}
            </TabBar>
          </Container>
        </AppBar>

        <Container
          css={{
            pt: "$10",
            pb: "$20",
            "@mxlg": {
              px: "$5",
            },
          }}
        >
          <LazyList
            gap="6"
            items={currentAdvances}
            render={listRenderer}
            skeletonHeight={114}
            EmptyComponent={
              <EmptyState>
                <Text>
                  {window.location.href.includes("Aprovados")
                    ? "Você não possui despesas aprovadas"
                    : "Você não possui despesas em aberto"}
                </Text>
              </EmptyState>
            }
          />
        </Container>
      </Tabs>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </Fragment>
  );
}
