import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { itemStyle } from "./itemStyle";

export type DropdownMenuButtonProps = ComponentProps<typeof DropdownMenuButton>;

export const DropdownMenuButton = styled("button", {
  ...itemStyle,

  "&[data-state=open]": {
    backgroundColor: "$neutrals-lightest",
    color: "$neutrals-black",
  },
});

DropdownMenuButton.displayName = "DropdownMenuButton";
