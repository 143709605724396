export enum OrderTab {
  ITEMS = "itens-do-pedido",
  VOUCHERS = "vouchers",
  HISTORY = "historico",
  OBSERVATIONS = "observacoes",
}

export const ORDER_MENU_TABS = [
  { title: "Itens do pedido", id: OrderTab.ITEMS },
  { title: "Vouchers", id: OrderTab.VOUCHERS },
  { title: "Observações", id: OrderTab.OBSERVATIONS },
  { title: "Histórico", id: OrderTab.HISTORY },
];
