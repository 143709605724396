import { components } from "react-select";
import { styled } from "~/application/theme";

export const SelectValueContainer = styled(components.Control, {
  // Reset
  boxSizing: "border-box",
  display: "flex",

  // Custom
  alignItems: "center",
  flex: 1,
  flexWrap: "nowrap",
  position: "relative",
  overflow: "hidden",
});