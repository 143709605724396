import type { HotelRule, HotelRulesLink } from "~/application/types";
import { ApiError } from "~/application/types";
import { api } from "~/infrastructure/api";
import { HotelRuleDTO, HotelRulesLinkDTO } from "~/infrastructure/api/dtos";
import type { IHotelRuleService } from "./IHotelRuleService";
import { mapHotelRuleDTO } from "~/infrastructure/api/mappers";

export class HotelRuleService implements IHotelRuleService {
  async query(
    searchKey: string,
    hotelId: string,
    roomId: number
  ): Promise<HotelRulesLink> {
    return api
      .post<HotelRulesLinkDTO>("/hotels/rules", {
        search_key: searchKey,
        hotel_id: hotelId,
        room_id: roomId.toString(),
      })
      .then(({ data }) => ({
        link: data.link,
        waitTime: data.wait_time,
      }));
  }

  async find(url: string): Promise<HotelRule[]> {
    return api.get<HotelRuleDTO[]>(url).then(({ data }) => {
      if (ApiError.isAsyncError(data)) {
        throw new ApiError({
          data: data,
          statusCode: 204,
        });
      }

      return data.map(mapHotelRuleDTO);
    });
  }
}
