import { Card } from "~/components/Card";
import { DialogHeader } from "~/components/Dialog";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgTrash } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { H4 } from "~/components/Typography";
import { Button } from "~/components/Button";

export interface CancelDialogProps {
  title: string;
  children?: React.ReactNode;
  loadingMessage?: string;
  cancelTitle?: string;
  textCancelation?: string;
  svg: React.FC;
  showSvg?: boolean;
  onConfirm: () => void;
  onCloseClick?: () => void;
}

export function CancelOrderDialog({
  title,
  children,
  textCancelation,
  onConfirm,
  onCloseClick,
  svg,
  showSvg = true,
}: CancelDialogProps) {
  return (
    <Card
      css={{
        boxShadow: "$sm",
        height: "340px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "$5",
        px: "$15",
        gap: "$4",
        textAlign: "center",
        width: "700px",
        position: "relative",
        color: "$neutrals-dark",
        "@mxsm": {
          width: "60%",
          margin: "0 auto",
          overflow: "hidden",
        },
      }}
    >
      <Icon
        size="4xl"
        as={svg}
        css={{
          fill: "$primary-base",
        }}
      />
      <IconButton
        css={{
          position: "absolute",
          right: "$5",
          stroke: "$primary-base",
        }}
        onClick={onCloseClick}
      >
        <Icon as={SvgClose} />
      </IconButton>
      <DialogHeader
        css={{
          borderBottom: "none",
          p: 0,
        }}
      >
        <H4
          fw="600"
          css={{
            width: "390px",
            gap: "$13",
            color: "$neutrals-black",
            "@mxmd": {
              fontSize: "$lg",
            },
          }}
        >
          {title}
        </H4>
      </DialogHeader>
      {children}
      <Button
        variant="primary"
        css={{
          [`& ${Icon}`]: {
            size: "$7",
          },
          mt: "$7",
          "@mxmd": {
            fontSize: "$sm",
          },
        }}
        onClick={onConfirm}
      >
        {showSvg && (
          <Icon
            css={{
              "@mxmd": {
                size: "$5",
              },
            }}
            as={SvgTrash}
          />
        )}
        {textCancelation}
      </Button>
    </Card>
  );
}

CancelOrderDialog.displayName = "CancelDialog";
