import { AccessReport } from "~/application/types";
import { Box } from "~/components/Box";
import { DataColItem, DataListItem } from "~/components/List";
import { Text } from "~/components/Text";
import { DateFormats, displayDate } from "~/utils/date.utils";

export type ReportsItemProps = {
  item: AccessReport;
};

export function ReportsItem({ item }: ReportsItemProps) {
  return (
    <DataListItem>
      <DataColItem
        headerTitle="Nome"
        data={
          <Box>
            <Text size="3" css={{ fw: "500" }}>
              {item.customerEmployee.name}
            </Text>
          </Box>
        }
      />
      <DataColItem
        headerTitle="Empresa"
        data={
          <Box>
            <Text size="3" css={{ fw: "500" }}>
              {item.customerEmployee.customer.name}
            </Text>
          </Box>
        }
      />
      <DataColItem
        headerTitle="E-mail"
        data={
          <Box>
            <Text size="3" css={{ fw: "500" }}>
              {item.customerEmployee.email}
            </Text>
          </Box>
        }
      />
      <DataColItem
        css={{
          display: "flex",
          alignItems: "end",
          flex: "2",
        }}
        headerTitle="Hórario"
        data={
          <Box>
            <Text size="3" css={{ fw: "700" }}>
              {displayDate(item.accessedAt, DateFormats.SMALL_DATE)}
              {", "}
            </Text>
            <Text size="3" css={{ fw: "500" }}>
              {displayDate(item.accessedAt, DateFormats.HOURS_MINUTES)}
            </Text>
          </Box>
        }
      />
    </DataListItem>
  );
}
