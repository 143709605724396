import {
  ComponentProps
} from "react";
import { styled } from "~/application/theme";
import { AlertContext, type AlertContextType } from "./AlertContext";

export type AlertProps = ComponentProps<typeof AlertRoot> & {
  variant: AlertContextType["variant"];
};

const AlertRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  
  "&::before": {
    boxSizing: "border-box",
  },
  "&::after": {
    boxSizing: "border-box",
  },

  // Custom
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  border: "1px solid",
  borderRadius: "$md",
  color: "$neutrals-darkest",
  fontWeight: "500",

  variants: {
    variant: {
      neutral: {
        backgroundColor: "$neutrals-lightest",
        borderColor: "$neutrals-dark",
        fill: "$neutrals-dark",
      },
      success: {
        backgroundColor: "$success-light",
        borderColor: "$success-base",
        fill: "$success-base",
      },
      warning: {
        backgroundColor: "$warning-light",
        borderColor: "$warning-dark",
        fill: "$warning-dark",
      },
      error: {
        backgroundColor: "$error-light",
        borderColor: "$error-base",
        fill: "$error-base",
      },
      info: {
        backgroundColor: "$primary-light",
        borderColor: "$primary-base",
        fill: "$primary-base",
      },
    },
    size: {
      sm: {
        padding: "$2",
      },
      md: {
        padding: "$6",
      },
    },
  },

  defaultVariants: {
    variant: "neutral",
    size: "md",
  },
});

export const Alert = ({ variant, ...props }: AlertProps) => {
  return (
    <AlertContext.Provider value={{ variant }}>
      <AlertRoot role="alert" variant={variant} {...props} />
    </AlertContext.Provider>
  );
};

Alert.displayName = "Alert";
