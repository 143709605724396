import * as React from "react";
import { SVGProps } from "react";

const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M3.334 2.709A2.303 2.303 0 0 0 1.041 5v10a2.303 2.303 0 0 0 2.293 2.291h13.332A2.303 2.303 0 0 0 18.959 15V5a2.303 2.303 0 0 0-2.293-2.291zm0 1.25h13.332c.576 0 1.043.465 1.043 1.041v10c0 .576-.467 1.041-1.043 1.041H3.334A1.042 1.042 0 0 1 2.291 15V5c0-.576.467-1.041 1.043-1.041z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M2.025 4.488a.625.625 0 0 0-.87.153.625.625 0 0 0 .154.87l8.332 5.835a.625.625 0 0 0 .716 0l8.334-5.834a.625.625 0 0 0 .155-.871.625.625 0 0 0-.871-.153L10 10.07Z"
    />
  </svg>
);

export default SvgMail;
