import { useState } from "react";

import { IOption } from "~/core/modules/Customer/pages/CustomerPage/hooks/useBudgets";
import { Actions, Budget, BudgetView } from "~/application/types";
import {
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar, Select } from "~/components/Input";
import { Text } from "~/components/Text";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Flex } from "~/components/Flex";
import { Box } from "~/components/Box";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { CounterTab, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Skeleton } from "~/components/Skeleton";
import { Link } from "~/components/Link";
import { TabBudgets } from "./views/TabBudgets";
import { DataColItem } from "~/components/List";
import { Col } from "~/components/Grid";
import { getCurrentYear } from "~/utils/date.utils";
import { TabBudgetsView } from "./views/TabBudgetsView";
import { BUDGETS_MENU_TABS, BudgetTab } from "~/core/modules/Customer/pages/CustomerPage/views/Budgets/utils";

export interface BudgetsContainerProps {
  budgets?: Budget[];
  budgetsView?: BudgetView[];
  yearsOptions?: IOption[];
  currentViewPage: number;
  currentPage: number;
  lastPage: number;
  lastViewPage: number;
  search: string;
  searchView: string;
  year: number;
  activeTab: BudgetTab;
  onCreateBudget: () => void;
  onEditBudget: (data: Budget) => void;
  onSearch: (search: string) => void;
  onSearchView: (search: string) => void;
  onChangeYear: (year: number) => void;
  onGoToPage: (page: number) => void;
  onGoToViewPage: (page: number) => void;
}

export function BudgetsContainer({
  budgets,
  budgetsView,
  yearsOptions,
  currentPage,
  currentViewPage,
  lastPage,
  lastViewPage,
  search,
  searchView,
  year,
  activeTab,
  onCreateBudget,
  onEditBudget,
  onSearch,
  onSearchView,
  onChangeYear,
  onGoToPage,
  onGoToViewPage,
}: BudgetsContainerProps) {
  const [searchInput, setSearchInput] = useState(false);

  const { contexts, profile } = useUser();
  const isMobile = useMobile();

  const tabBudgetsProps = {
    budgets,
    currentPage,
    lastPage,
    search,
    onEditBudget,
    onGoToPage,
  };

  const tabBudgetsViewProps = {
    budgets: budgetsView,
    currentPage: currentViewPage,
    lastPage: lastViewPage,
    onGoToPage: onGoToViewPage,
  };

  const canCreateBudget = useVerifyActions({
    actions: [Actions.CreateUpdateBudget],
    contexts,
    profile,
  });

  const currentYear = getCurrentYear();
  
  return (
    <Tabs value={activeTab}>
      <AppBar>
        <AppBarContainer>
          <Flex
            css={{
              "@mxlg": {
                display: searchInput ? "none" : "flex",
                width: "100%",
              },
            }}
          >
            <AppBarHeading title="Orçamentos" />
          </Flex>

          <Box
            onClick={() => setSearchInput(false)}
            css={{
              zIndex: 10000,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(100, 100, 100, 0.5)",
              display: !searchInput ? "none" : "block",
            }}
          />

          {activeTab === BudgetTab.BUDGETS && (
            <AppBarActions>
              {!isMobile ? (
                <SearchBar
                  search={search}
                  onSearchChange={onSearch}
                  placeholder="Encontrar"
                />
              ) : (
                <SearchBar
                  search={search}
                  onSearchChange={onSearch}
                  placeholder="Encontrar"
                  style={{
                    width: searchInput ? "100%" : "34%",
                    marginRight: "10px",
                    paddingTop: "0px",
                    zIndex: "10001",
                  }}
                  onClick={() => setSearchInput(true)}
                  onBlur={() => setSearchInput(false)}
                />
              )}

              {canCreateBudget && (
                <Button
                  title="Novo orçamento"
                  css={{ "@mxlg": { background: "$neutrals-white" } }}
                  onClick={onCreateBudget}
                >
                  <Icon as={SvgPlus} css={{ "@mxlg": { fill: "black" } }} />
                  <Text css={{ "@mxlg": { display: "none" } }}>
                    Novo orçamento
                  </Text>
                </Button>
              )}
            </AppBarActions>
          )}

          {activeTab === BudgetTab.VIEW && (
            <AppBarActions>
              <SearchBar
                search={searchView}
                onSearchChange={onSearchView}
                placeholder="Encontrar"
              />

              <DataColItem
                data={
                  <Col sz="auto">
                    <Select 
                      size="xs" 
                      defaultValue={{ label: String(currentYear), value: currentYear }}
                      value={{ label: String(year), value: year }}
                      options={yearsOptions} 
                      placeholder="Selecione o ano" 
                      onChange={(event) => onChangeYear(event.value)}
                    />
                  </Col>
                }
              />
            </AppBarActions>
          )}
        </AppBarContainer>

        <Container>
          <TabBar>
            {!budgets ? (
              <Flex gap="8" css={{ p: "$4" }}>
                <Skeleton variant="text-4" style={{ width: 96 }} />
                <Skeleton variant="text-4" style={{ width: 96 }} />
              </Flex>
            ) : (
              BUDGETS_MENU_TABS.map(({ title, id }) => (
                <Link to={`#${id}`} title={title} key={id}>
                  <CounterTab id={id} value={id}>
                    <Text>{title}</Text>
                  </CounterTab>
                </Link>
              ))
            )}
          </TabBar>
        </Container>
      </AppBar>

      <TabContent value={BudgetTab.BUDGETS}>
        <TabBudgets {...tabBudgetsProps} />
      </TabContent>

      <TabContent value={BudgetTab.VIEW}>
        <TabBudgetsView {...tabBudgetsViewProps} />
      </TabContent>
    </Tabs>
  );
}
