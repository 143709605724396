import { useLocation } from "react-router-dom";

import { AgencyTab, AGENCY_MENU_TABS } from "../../../utils";

export function useAgencyTab(): AgencyTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(AgencyTab).includes(hash)) {
    return hash as AgencyTab;
  }

  return AGENCY_MENU_TABS[0].id;
}
