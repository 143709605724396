import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { contentStyle } from "./contentStyle";

export type DropdownMenuSubContentProps = ComponentProps<
  typeof DropdownMenuPrimitive.SubContent
>;

const SubContentRoot = styled(DropdownMenuPrimitive.SubContent, {
  ...contentStyle,
});

export const DropdownMenuSubContent = (props: DropdownMenuSubContentProps) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <SubContentRoot {...props} />
    </DropdownMenuPrimitive.Portal>
  );
};

DropdownMenuSubContent.displayName = "DropdownMenuSubContent";
