import { CheapestFlight } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { MetricItemGroup } from "~/core/modules/Order/components/MetricItemGroup";
import { FlightItem } from "../../components/FlightItem";

interface TabSectionProps {
  cheapestFlights: CheapestFlight[];
}

export function TabSection({ cheapestFlights }: TabSectionProps) {
  const chosenFlight = cheapestFlights[0];

  const amountPaid = chosenFlight.value;
  const lowestAmountFound = (cheapestFlights.length - 1) > 0
    ? cheapestFlights[1].value
    : amountPaid;

  const isTheBestChoose = amountPaid === lowestAmountFound;

  return (
    <Flex direction="column" css={{ gap: "$13" }}>
      <MetricItemGroup amountPaid={amountPaid} lowestAmountFound={lowestAmountFound} />

      <Flex direction="column">
        <FlightItem data={chosenFlight} isChosen isBestOffer={isTheBestChoose} />

        {cheapestFlights.length > 1 ? (
          <>
            <Text variant="darkest" fw="500" css={{ fw: "500", lineHeight: "$5",  mt: "$6", mb: "$3" }}>Opções disponíveis no momento da reserva:</Text>

            <Flex direction="column" css={{ gap: "$6" }}>
              {cheapestFlights?.slice(1).map((item, i) => {
                return (
                  <FlightItem 
                    key={`${item.flightId}-${item.optionId}`} 
                    data={item}
                    isBestOffer={i === 0 && !isTheBestChoose}
                  />
                );
              })}
            </Flex>
          </>
        ) : (
          <Text variant="darkest" fw="500" css={{ fw: "500", lineHeight: "$5",  mt: "$6", mb: "$3" }}>
            Essa foi a melhor opção disponível no momento da reserva.
          </Text>
        )}
      </Flex>
    </Flex>
  );
}