import { Summary } from "~/application/types/entities/Summary.type";
import { SummaryDTO } from "../dtos/SummaryDTO";

export const mapSummaryDTO = (data: SummaryDTO): Summary => {
  return {
    nextTrips: data.next_trips.map((nextTrip) => ({
      orderId: nextTrip.order_uuid,
      issuerName: nextTrip.issuer_name,
      orderNumber: nextTrip.order_number,
      createdAt: nextTrip.created_at,
      itemIncluded: nextTrip.item_included,
      departureDate: nextTrip.departure_date,
      departure: nextTrip.departure,
      arrival: nextTrip.arrival,
      travelers: nextTrip.travelers.map((traveler) => ({
        uuid: traveler.uuid,
        fullName: traveler.full_name,
        cpf: traveler.cpf,
        rg: traveler.rg,
        dateBirth: traveler.date_birth,
        email: traveler.email,
        phone: traveler.phone,
        company: {
          tradingName: traveler.company.trading_name,
        },
        avatarUrl: traveler.avatar_url,
      })),
    })),
    pendingApprovals: data.pending_approvals,
    accountability: data.accountability,
    openOrders: data.open_orders
  };
};
