import { useMutation } from "@tanstack/react-query";
import { QueryKeys } from "~/application/constants";
import { customerEmployeeService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { snackbarService } from "~/components/SnackbarStack";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { queryClient } from "~/services/queryClient";
import {
  CreateCustomerEmployeeParams,
  UseCreateCustomerEmployeeOptions,
  UseCreateCustomerEmployeeReturn,
} from "./types";

const SNACKBAR_MESSAGES = {
  CREATE_ERROR_MESSAGE: "Falha ao criar funcionário",
} as const;

export function useCreateCustomerEmployee({
  customerId,
}: UseCreateCustomerEmployeeOptions): UseCreateCustomerEmployeeReturn {
  const { LOG_TAG } = useLogTag();

  const { mutateAsync: mutateCreateAsync } = useMutation(
    (params: CreateCustomerEmployeeParams) =>
      customerEmployeeService.create({ ...params, customerId } as any),
    {
      onSuccess: (item) => {
        log.i(LOG_TAG, `Successfully created CustomerEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);
        snackbarService.showSnackbar(
          "Funcionário criado com sucesso",
          "success"
        );
      },
      onError: (error: { message: string }) => {
        const errorMessage = error?.message;
        snackbarService.showSnackbar(errorMessage, "error");
      },
    }
  );

  return {
    create: mutateCreateAsync,
  };
}
