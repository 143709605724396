import { FC } from "react";
import { asCurrency } from "~/application/utils/mask-functions";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgTrash } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { BusSeat } from "../BusSeat";
import { SeatListItemProps } from "./types";

export const SeatListItem: FC<SeatListItemProps> = ({
  data,
  price,
  isConnection,
  onRemove,
}) => {
  return (
    <Flex gap="2" align="center" css={{ padding: "$3" }}>
      <BusSeat data-selected="true" size="sm" />

      <Col>
        <Text size="3" css={{ fw: "600" }}>
          Assento {data.seat}
        </Text>
      </Col>

      {!isConnection && <Caption>{asCurrency(price)}</Caption>}

      <IconButton onClick={onRemove}>
        <Icon as={SvgTrash} />
      </IconButton>
    </Flex>
  );
};

SeatListItem.displayName = "SeatListItem";
