import { Flex } from "~/components/Flex";
import { Skeleton } from "~/components/Skeleton";

export interface OrderRoadItemSkeletonProps {}

export function OrderRoadItemSkeleton() {
  return (
    <Flex direction="column" gap="6">
      <Skeleton variant="text-6" />

      <Skeleton variant="fluid" style={{ height: "176px" }} />

      <Skeleton variant="text-6" />

      <Skeleton variant="fluid" style={{ height: "176px" }} />

      <Skeleton variant="text-6" />

      <Skeleton variant="fluid" style={{ height: "88px" }} />

      <Skeleton variant="fluid" style={{ height: "88px" }} />
    </Flex>
  );
}

OrderRoadItemSkeleton.displayName = "OrderRoadItemSkeleton";
