import { useCallback, useMemo, useState } from "react";
import { DeepPartial, OrderItems, Policy, PolicyParamsSlug } from "~/application/types";
import {
  PurchansingPolicyType,
  usePurchansingPoliciesProps,
  usePurchansingPoliciesResult,
} from "./type";
import { useMutation, useQuery } from "@tanstack/react-query";
import { policyService } from "~/application/usecases";
import { QueryKeys } from "~/constants";
import { FormPolicyData } from "~/application/usecases/Policy/IPolicyService";
import { snackbarService } from "~/components/SnackbarStack";
import { queryClient } from "~/services/queryClient";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { PolicyFilter } from "../type";

export type NewOrderItems = Exclude<OrderItems, OrderItems.HOTEL_OFFLINE | OrderItems.ADVANCE>;

export const usePurchansingPolicies = ({
  customerId,
}: usePurchansingPoliciesProps): usePurchansingPoliciesResult => {
  const [policySelect, setPolicySelect] = useState<PurchansingPolicyType>({
    id: OrderItems.ROAD,
    name: "Rodoviário",
  });

  const isReasonTripPolicy = policySelect.id === "reason-trip";

  const { data, isLoading } = useQuery([QueryKeys.CUSTOMER_POLICIES], () =>
    policyService.find({ customerId })
  );

  const [formPolicyData, setFormPolicyData] = useState<FormPolicyData[]>([]);

  const { mutate: policiesPurchansingMutate } = useMutation(
    [isLoading],
    async (data: FormPolicyData) => await policyService.update(data),
    {
      onSuccess: (_, item) => {
        dialogService.popAll();
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_POLICIES]);
        const findPolicy = data?.find((policy) => policy.uuid === item.policyId);
        snackbarService.showSnackbar(`${findPolicy?.name} atualizada.` || "", "success");
      },
      onError: (_, item) => {
        const findPolicy = data?.find((policy) => policy.uuid === item.policyId);
        throw new Error(`Erro ao atualizar a ${findPolicy?.name}`);
      },
    }
  );

  const policyFilteredPerService = useMemo(
    () =>
      data
        ?.map((policy) => {
          const policyFind = policy.values.find(
            (value) => value.parameter?.serviceType === policySelect.id
          );
          const policyFindReasonTrip = policy.values.find((value) => value.reasonTrip?.uuid);

          if (policyFindReasonTrip) return;
          return {
            customerId: policy.customerId,
            isActive: policy.isActive,
            name: policy.name,
            uuid: policy.uuid,
            value: { ...policyFind, value: Number(policyFind?.value) },
          };
        })
        .filter((policy) => policy),
    [data, policySelect]
  );

  const policyPerReasonTrip = useMemo(() => {
    const result = [] as (PolicyFilter | undefined)[];
    data?.forEach((policy) => {
      policy.values.forEach((value) => {
        if (value.reasonTrip?.uuid) {
          result.push({
            customerId: policy.customerId,
            isActive: policy.isActive,
            name: policy.name,
            uuid: policy.uuid,
            value: { ...value, value: Number(value?.value) },
          });
        }
      });
    });
    return result;
  }, [data, policySelect]);

  const onSubmitPolicies = useCallback(() => {
    try {
      if (formPolicyData.length)
        dialogService.showDialog(<LoadingDialog message="Atualizando políticas" />);
      formPolicyData?.forEach(async (formData) => {
        await policiesPurchansingMutate(formData as FormPolicyData);
      });
      setFormPolicyData([]);
    } catch {
      snackbarService.showSnackbar("Não foi possivel atualizar as políticas", "error");
    }
  }, [formPolicyData, policiesPurchansingMutate]);

  const handleCreateFormPolicy = ({
    policyParameterId,
    policyId,
    value,
    policyValueId,
  }: FormPolicyData) => {
    setFormPolicyData((old) => [
      ...old.filter((e) => e.policyValueId !== policyValueId),
      {
        policyId,
        policyParameterId,
        value,
        policyValueId,
      },
    ]);
  };

  const onPolicySelect = (data: PurchansingPolicyType) => {
    setPolicySelect(data);
    setFormPolicyData([]);
  };

  return {
    data: isReasonTripPolicy ? policyPerReasonTrip : policyFilteredPerService,
    isLoading,
    policySelect,
    onSubmitPolicies,
    disableSubmit: !!formPolicyData.length,
    setPolicySelect: onPolicySelect,
    onChancePolicies: handleCreateFormPolicy,
    isReasonTripPolicy,
  };
};
