import { ApiError, Flight, FlightQuery, FlightQueryLinks } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { api } from "~/infrastructure/api";
import { FlightDTO, FlightQueryLinksDTO } from "~/infrastructure/api/dtos";
import { mapFlightDTO } from "~/infrastructure/api/mappers";
import type { FindCombinedData, IFlightService } from "./IFlightQueryService";

export class FlightService implements IFlightService {
  async query(
    customerId: string,
    {
      adultQuantity,
      childrenQuantity,
      originSelected,
      destinationSelected,
      dateGoSelected,
      dateReturnSelected,
      reasonTrip,
    }: FlightQuery
  ): Promise<FlightQueryLinks> {
    return api
      .post<FlightQueryLinksDTO>("/flights", {
        customer_uuid: customerId,
        origin: originSelected.iata,
        destination: destinationSelected.iata,
        departure_date: DateUtils.toISOString(dateGoSelected),
        arrival_date: !dateReturnSelected ? undefined : DateUtils.toISOString(dateReturnSelected),
        reason_trip_uuid: reasonTrip?.uuid,
        travelers: [
          {
            code: "ADT",
            quantity: adultQuantity,
          },
          {
            code: "CHD",
            quantity: childrenQuantity,
          },
        ].filter((v) => v.quantity > 0),
      })
      .then(({ data }) => ({
        waitTime: data.wait_time,
        links: data.links.map((link) => ({
          departure: link.departure,
          arrival: link.arrival,
          departureArrival: link.departure_arrival,
        })),
      }));
  }

  async find(url: string, goFlightId?: number | null, optionId?: number): Promise<Flight[]> {
    return api
      .post<{ search_key: string; data: FlightDTO[] }>(url, {
        flight_departure_id: goFlightId,
        option_id: optionId,
      })
      .then(({ data }) => {
        if (!data.data) {
          throw new ApiError({
            data: data,
            statusCode: 204,
          });
        }

        return data.data.map((flight) => mapFlightDTO(flight, data.search_key));
      });
  }

  async findCombined(url: string, data: FindCombinedData): Promise<Flight[]> {
    return api
      .post<{ search_key: string; data: FlightDTO[] }>(url, {
        search_key: data.searchKey,
        travel_id: data.travelId,
        fare_id: data.fareId,
      })
      .then(({ data }) => {
        if (!data.data) {
          throw new ApiError({
            data: data,
            statusCode: 204,
          });
        }

        return data.data.map((flight) => mapFlightDTO(flight, data.search_key));
      });
  }
}
