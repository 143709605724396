import { FC, Fragment } from "react";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { NumberInput, TextInput } from "~/components/Input";
import { H5 } from "~/components/Typography";
import { TravelerListItem } from "~/presentation/shared/components/TravelerListItem";
import { AirwayIssuanceFormProps } from "./types";

export const AirwayIssuanceForm: FC<AirwayIssuanceFormProps> = ({ control, orderAirway }) => {
  return (
    <Fragment>
      <Box css={{ mb: "$6" }}>
        <H5>Funcionários</H5>
      </Box>

      <Row gap="6">
        {orderAirway.flights.map((item, issuanceIndex) => (
          <Col
            sz="12"
            key={item.uuid}
            css={{
              "@mxlg": {
                px: "0",
              },
            }}
          >
            {item.passengers.map((traveler, travelerIndex) => (
              <Card
                key={traveler.uuid}
                css={{
                  "@mxlg": {
                    border: "0",
                  },
                }}
              >
                <CardBody
                  css={{
                    gap: "$6",
                    "@mxlg": {
                      px: "$2",
                    },
                  }}
                >
                  <TravelerListItem data={traveler} />
                  <FormControl
                    control={control}
                    name={`issuances.${issuanceIndex}.travelers.${travelerIndex}.ticket`}
                    required
                  >
                    <FieldLabel>Número do bilhete</FieldLabel>
                    <TextInput placeholder="Digite o número do bilhete" maxLength={14} />
                  </FormControl>
                  <FormControl
                    control={control}
                    name={`issuances.${issuanceIndex}.tracker`}
                    required
                  >
                    <FieldLabel>Localizador</FieldLabel>
                    <TextInput placeholder="Digite o localizador" />
                  </FormControl>
                  <FormControl control={control} name={`issuances.${issuanceIndex}.value`} required>
                    <FieldLabel>Valor</FieldLabel>
                    <NumberInput prefix="R$: " />
                  </FormControl>
                  <FormControl control={control} name={`issuances.${issuanceIndex}.changeTax`}>
                    <FieldLabel>Multa de alteração</FieldLabel>
                    <NumberInput prefix="R$: " />
                  </FormControl>
                </CardBody>
              </Card>
            ))}
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

AirwayIssuanceForm.displayName = "AirwayIssuanceForm";
