import { useNavigate } from "react-router-dom";

import { Project, UserContext } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgChevronRight, SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { useUser } from "~/presentation/core/contexts/UserContext";

export interface ProjectListItemProps extends DataListItemProps {
  data: Project;
  onEditClick: (item: Project) => void;
  onToggleState: (item: Project) => void;
}

export function ProjectListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: ProjectListItemProps) {
  const navigate = useNavigate();
  const { user } = useUser();

  const goToPhasesPage = () => {
    const path = user.context === UserContext.Agency
      ? `?projeto=${data.name}&id_projeto=${data.uuid}#fases`
      : `/configuracoes/projetos/${data.uuid}/fases?projeto=${data.name}`;

    navigate(path);
  };

  return (
    <DataListItem data-active={data.active} {...props}>
      <DataColItem headerTitle="Projeto" data={data.name} />
      <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
        <IconButton size="md" onClick={() => onEditClick(data)}>
          <Icon as={SvgEdit} />
        </IconButton>
      </Tooltip>
      <Switch checked={data.active} onClick={() => onToggleState(data)}>
        <Caption>{data.active ? "Ativo" : "Inativo"}</Caption>
      </Switch>
      <Tooltip content={<TooltipLabel>Ver fases</TooltipLabel>}>
        <IconButton size="md" onClick={goToPhasesPage}>
          <Icon as={SvgChevronRight} />
        </IconButton>
      </Tooltip>
    </DataListItem>
  );
}
