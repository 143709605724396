import { Box, BoxProps } from "~/components/Box";
import React from "react";

export interface HotelImageProps extends BoxProps {
  src: string;
  children?: React.ReactNode;
}

export function HotelImage({ src, children, ...props }: HotelImageProps) {
  return (
    <Box
      css={{
        borderRadius: "$md",
        overflow: "hidden",
        position: "relative",

        [`& > ${Box}`]: {
          width: "100%",
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          transition: "$fast",

          "&:hover": {
            transform: "scale(1.25)",
          },
        },
      }}
      {...props}
    >
      <Box
        style={{
          backgroundImage: `url(${src}), url(/images/broken-image.png)`,
        }}
      />
      {children}
    </Box>
  );
}

HotelImage.displayName = "HotelImage";
