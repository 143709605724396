import { Fragment, useCallback } from "react";
import { ExpenseType } from "~/application/types";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { ExpenseTypeListItem } from "~/presentation/shared/components/ExpenseTypeListItem";
import { useExpenseTypesPage } from "./logic/useExpenseTypesPage";

export function ExpenseTypesPage() {
  const {
    data,
    isLoading,
    lastPage,
    currentPage,
    onToggleExpenseTypeState,
    onCreateExpenseType,
    onEditExpenseType,
    onGoToPage,
  } = useExpenseTypesPage();

  const listRenderer = useCallback(
    (item: ExpenseType) => (
      <ExpenseTypeListItem
        data={item}
        onEditClick={onEditExpenseType}
        onToggleState={onToggleExpenseTypeState}
        key={item.uuid}
      />
    ),
    [onEditExpenseType, onToggleExpenseTypeState]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Tipos de despesas" />

          <AppBarActions>
            <Button title="Novo tipo de despesa" color="primary" onClick={onCreateExpenseType}>
              <Icon as={SvgPlus} />
              <Text>Novo tipo de despesa</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não tipos de despesas cadastrados</Text>
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Fragment>
  );
}
