import { useMutation } from "@tanstack/react-query";
import { QueryKeys } from "~/application/constants";
import { orderRoadService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { queryClient } from "~/services/queryClient";
import {
  DeleteOrderRoadParams,
  UseDeleteOrderRoadOptions,
  UseDeleteOrderRoadReturn,
} from "./types";
import { useNavigate } from "react-router-dom";
import { getTotalOrderItems } from "~/core/shared/utils/order.utils";
import { LoadingDialog } from "../../views/LoadingDialog";
import { DeleteOrderItemDialog } from "../../views/DeleteOrderItemDialog";

const SNACKBAR_MESSAGES = {
  DELETE_ERROR_MESSAGE: "Não foi possível deletar item rodoviário",
  CANCEL_ORDER_SUCESS: "Seu pedido foi cancelado com sucesso!",
} as const;

export function useDeleteOrderRoad(
  logTag: string,
  { order }: UseDeleteOrderRoadOptions
): UseDeleteOrderRoadReturn {
  const navigation = useNavigate();
  const orderId = order?.uuid

  const { mutateAsync: mutateDelete } = useMutation(
    ({ itemId }: DeleteOrderRoadParams) => orderRoadService.delete(itemId),
    {
      onSuccess: (_, { itemId }) => {
        log.i(logTag, `Successfully deleted OrderRoad(${itemId})`);
      
          queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);
          queryClient.invalidateQueries([QueryKeys.CUSTOMER_ORDERS]);

          dialogService.popAll();

        if (getTotalOrderItems(order) === 1) {
          navigation("/pedidos/meus-pedidos")
          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.CANCEL_ORDER_SUCESS,
            "success"
          );
        }


      },
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Removendo o item" />);
      },
      onError: (error) => {
        log.e(logTag, error);
        dialogService.popDialog();
        dialogService.showDialog(
          <DeleteOrderItemDialog
            onConfirm={() => mutateDelete({ itemId: orderId as string })}
          />
        );
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.DELETE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  return {
    delete: mutateDelete,
  };
}
