import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import {
  AccountabilityExpense,
  DeepPartial,
  ExpenseCategory,
  ExpensePolicy,
  ExpenseType,
  Order,
} from "~/application/types";
import {
  accountabilityExpenseService,
  expenseCategoryService,
  expensePolicyService,
} from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { SimpleDialog } from "~/core/shared/components/SimpleDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { AccountabilityExpenseDialog } from "../views/AccountabilityExpense/components/AccountabilityExpenseDialog";
import { VoucherDialog } from "../views/AccountabilityExpense/components/VoucherDialog";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { logError } from "~/presentation/shared/utils/errors";

export interface UseAccountabilityExpensesResult {
  data?: AccountabilityExpense[];
  order?: Order;
  isLoading: boolean;
  cartSummary?: {
    advances: number;
    expenses: number;
    refundable: number;
  };
  fetchExpenseCategories: () => Promise<ExpenseCategory[]>;
  fetchExpensePolicies: (expenseType: ExpenseType) => Promise<ExpensePolicy[]>;
  onCreateAccountabilityExpense: (viatorId?: string) => void;
  onEditAccountabilityExpense: (data: AccountabilityExpense) => void;
  onDeleteAccountabilityExpense: (data: AccountabilityExpense) => void;
  onShowVoucher: (data: AccountabilityExpense) => void;
}

export interface UseAccountabilityExpensesOptions {
  customerEmployeeId?: string;
  enabled: boolean;
  isExpenseApproval?: boolean;
  order?: Order;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LOG_TAG = "Order/OrderPage/useAccountabilityExpenses";

export const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar despesas",
  CREATE_SUCCESS_MESSAGE: "Despesa criada",
  CREATE_ERROR_MESSAGE: "Falha ao criar despesa",
  UPDATE_SUCCESS_MESSAGE: "Despesa atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar despesa",
  DELETE_SUCCESS_MESSAGE: "Despesa deletada",
  DELETE_ERROR_MESSAGE: "Falha ao deletar despesa",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<AccountabilityExpense> = {
  company: "",
  expenseDate: "",
  justification: "",
};

export function useAccountabilityExpenses({
  order,
  customerEmployeeId,
  isExpenseApproval,
}: UseAccountabilityExpensesOptions): UseAccountabilityExpensesResult {
  const { user, contexts } = useUser();
  const orderId = order?.uuid as string;
  const customerId = user.customer?.uuid || contexts.customer?.uuid;
  const travelerId = customerEmployeeId ?? "";

  const { data: expenses, isLoading: isLoadingExpenses } = useQuery(
    [QueryKeys.ORDER_ACCOUNTABILITY_EXPENSES, orderId],
    () =>
      accountabilityExpenseService.find({
        orderId,
        travelerId: isExpenseApproval ? "" : travelerId,
      }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!orderId,
      onError: (error) => {
        log.e(LOG_TAG, error);
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: handleCreateAccountabilityExpense } = useMutation(
    (item: AccountabilityExpense & { travelerId?: string }) => {
      return accountabilityExpenseService.create({
        ...item,
        travelerId: item.travelerId ?? travelerId,
        orderId,
      });
    },
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating AccountabilityExpense(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(
          LOG_TAG,
          `Successfully created AccountabilityExpense(${item.uuid})`
        );

        queryClient.invalidateQueries([
          QueryKeys.ORDER_ACCOUNTABILITY_EXPENSES,
          orderId,
        ]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: handleUpdateAccountabilityExpense } = useMutation(
    (item: AccountabilityExpense) =>
      accountabilityExpenseService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating AccountabilityExpense(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(
          LOG_TAG,
          `Successfully updated AccountabilityExpense(${item.uuid})`
        );

        queryClient.invalidateQueries([
          QueryKeys.ORDER_ACCOUNTABILITY_EXPENSES,
          orderId,
        ]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateDeleteAccountabilityExpense } = useMutation(
    (item: AccountabilityExpense) =>
      accountabilityExpenseService.deleteById(item),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Deletando despesa" />);
      },
      onSuccess: (_, item) => {
        log.i(
          LOG_TAG,
          `Successfully deleted AccountabilityExpense(${item.uuid})`
        );

        queryClient.invalidateQueries([
          QueryKeys.ORDER_ACCOUNTABILITY_EXPENSES,
          orderId,
        ]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.DELETE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.DELETE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const fetchExpenseCategories = useCallback(
    (): Promise<ExpenseCategory[]> =>
      expenseCategoryService
        .find({})
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const fetchExpensePolicies = useCallback(
    (expenseType: ExpenseType): Promise<ExpensePolicy[]> =>
      expensePolicyService
        .find({ customerId, expenseTypes: [expenseType] })
        .then(({ data }) => data),
    [customerId]
  );

  const handleOpenCreateModal = useCallback(
    (viatorId?: string) => {
      dialogService.showDialog(
        <AccountabilityExpenseDialog
          isNew
          customerEmployeeId={viatorId ?? travelerId}
          customerId={customerId}
          orderId={orderId}
          onSubmit={handleCreateAccountabilityExpense}
          fetchExpenseCategories={fetchExpenseCategories}
          fetchExpensePolicies={fetchExpensePolicies}
          defaultData={DEFAULT_FORM_DATA}
        />
      );
    },
    [
      customerId,
      travelerId,
      orderId,
      fetchExpenseCategories,
      handleCreateAccountabilityExpense,
      fetchExpensePolicies,
    ]
  );

  const handleOpenEditModal = useCallback(
    (item: AccountabilityExpense) => {
      dialogService.showDialog(
        <AccountabilityExpenseDialog
          customerEmployeeId={travelerId}
          customerId={customerId}
          orderId={orderId}
          onSubmit={handleUpdateAccountabilityExpense}
          fetchExpenseCategories={fetchExpenseCategories}
          fetchExpensePolicies={fetchExpensePolicies}
          defaultData={item}
        />
      );
    },
    [fetchExpenseCategories]
  );

  const handleOpenDeleteModal = useCallback(
    (item: AccountabilityExpense) => {
      dialogService.showDialog(
        <SimpleDialog
          title="Deseja apagar despesa?"
          cancelTitle="Cancelar"
          confirmTitle="Apagar"
          loadingMessage="Apagando despesa"
          onConfirm={() => mutateDeleteAccountabilityExpense(item)}
        />
      );
    },
    [mutateDeleteAccountabilityExpense]
  );

  const handleVoucherModal = useCallback(
    (item: AccountabilityExpense) => {
      dialogService.showDialog(<VoucherDialog data={item} />);
    },
    [fetchExpenseCategories]
  );

  return {
    order,
    fetchExpensePolicies,
    fetchExpenseCategories,
    isLoading: isLoadingExpenses,
    data: expenses?.data,
    cartSummary: expenses?.summary,
    onCreateAccountabilityExpense: handleOpenCreateModal,
    onEditAccountabilityExpense: handleOpenEditModal,
    onDeleteAccountabilityExpense: handleOpenDeleteModal,
    onShowVoucher: handleVoucherModal,
  };
}
