import { useCallback, useMemo } from "react";
import {
  ApiErrorCode,
  FlightSegment,
  Order,
  OrderAirwaySegment,
  OrderAirwayTracker,
  OrderItems,
  Passenger,
} from "~/application/types";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { LazyList } from "~/components/LazyList";
import { AlertViolatedPoliciesPerProducts } from "~/presentation/shared/components/AlertViolatedPoliciePerProducts/AlertViolatedPoliciesPerProducts";
import { OrderAirwayItemSkeleton } from "~/presentation/shared/components/OrderAirwayItemSkeleton";
import { OrderAirwayItemSegment } from "./OrderAirwayItemSegment";
import { CombinedOrderAirwayItemSegment } from "./components/ CombinedOrderAirwayItemSegment";

export interface OrderAirwayItemProps {
  isLoading: boolean;
  order?: Order;
  data?: OrderAirwaySegment[];
  canShowApprovalButtons?: boolean;
  onOpenDetails: (data?: OrderAirwaySegment) => void;
  onDeleteItem?: (itemId: string) => void;
  onReloadTracker?: (flightId: string) => Promise<OrderAirwayTracker | ApiErrorCode>;
  onRemakeOrder: () => void;
  onOpenAirwaySeats: ({
    itemId,
    passengers,
  }: {
    itemId: string;
    passengers: Passenger[];
    item: OrderAirwaySegment;
  }) => void;
  onRemoveSeat?: ({ orderItemId, seatId }: { orderItemId: string; seatId: string }) => void;
  itemIndex: number;
}

export type CombinedOrderAirwayItemSegmentType = OrderAirwaySegment & {
  combined?: FlightSegment[];
  familyFare2?: string;
  uuid2: string;
};

export function OrderAirwayItem({
  data,
  order,
  canShowApprovalButtons,
  onOpenDetails,
  onDeleteItem,
  onReloadTracker,
  onRemakeOrder,
  onOpenAirwaySeats,
  onRemoveSeat,
  itemIndex,
}: OrderAirwayItemProps) {
  const combinedOrderItem = useMemo(() => {
    const combined = [] as CombinedOrderAirwayItemSegmentType[];
    data?.forEach((item) => {
      data.forEach((item2) => {
        if (
          item.tracker !== "Gerando localizador" &&
          item.tracker === item2.tracker &&
          item.uuid !== item2.uuid &&
          combined.findIndex((c) => c.tracker === item.tracker) === -1
        ) {
          const passengers = [] as Passenger[];

          item.passengers.forEach((passenger) => {
            const matchingPassenger = item2.passengers.find(
              (passenger2) => passenger.uuid === passenger2.uuid
            );

            const seats = matchingPassenger
              ? passenger.seats?.concat(matchingPassenger.seats || [])
              : passenger.seats || [];

            if (!passengers.some((pass) => pass.uuid === passenger.uuid)) {
              passengers.push({ ...passenger, seats });
            }
          });

          combined.push({
            ...item,
            uuid2: item2.uuid,
            combined: item2.segments,
            familyFare2: item2.familyFare,
            passengers: passengers,
            isTheCheapestFlight: item.isTheCheapestFlight && item2.isTheCheapestFlight,
          });
        }
      });
    });

    return data
      ?.filter((item) => {
        return (
          combined.findIndex((c) => c.tracker === item.tracker && !!item.tracker && !!c.tracker) ===
          -1
        );
      })
      .map(
        (item) =>
          ({
            ...item,
            combined: undefined,
          } as CombinedOrderAirwayItemSegmentType)
      )
      .concat(combined.every((c) => c.tracker) ? combined : []);
  }, [data]);

  const listRenderer = useCallback(
    (item: CombinedOrderAirwayItemSegmentType) => {
      return item?.combined ? (
        <CombinedOrderAirwayItemSegment
          onOpenAirwaySeats={onOpenAirwaySeats}
          item={item}
          itemIndex={itemIndex}
          onOpenDetails={onOpenDetails}
          onRemakeOrder={onRemakeOrder}
          onDeleteItem={onDeleteItem}
          canShowApprovalButtons={canShowApprovalButtons}
          onRemoveSeat={onRemoveSeat}
        />
      ) : (
        <OrderAirwayItemSegment
          item={item}
          itemIndex={itemIndex}
          canShowApprovalButtons={canShowApprovalButtons}
          onDeleteItem={onDeleteItem}
          onOpenDetails={onOpenDetails}
          onReloadTracker={onReloadTracker}
          onRemakeOrder={onRemakeOrder}
          onOpenAirwaySeats={onOpenAirwaySeats}
          onRemoveSeat={onRemoveSeat}
        />
      );
    },
    [onOpenDetails, onReloadTracker, onDeleteItem, canShowApprovalButtons, onOpenAirwaySeats]
  );

  return (
    <Box>
      <Flex direction="column" gap="2">
        <AlertViolatedPoliciesPerProducts order={order} itemType={OrderItems.AIRWAY} />
        <LazyList
          items={combinedOrderItem && combinedOrderItem}
          gap="8"
          render={listRenderer}
          skeletonQuantity={1}
          SkeletonComponent={<OrderAirwayItemSkeleton />}
        />
      </Flex>
    </Box>
  );
}
