import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { asCPF } from "~/utils/mask.utils";
import { RoadSegmentTravelerListItemProps } from "./types";

export function RoadSegmentTravelerListItem({
  data,
  ...props
}: RoadSegmentTravelerListItemProps) {
  const firstSeat = data.seats.at(0);
  const ticket = firstSeat?.ticket;
  const seatType = firstSeat?.seatType;
  const seatNumber = firstSeat?.seatNumber;

  return (
    <Card {...props}>
      <Flex
        justify="between"
        direction={{ "@mxlg": "column" }}
        css={{ p: "$8", "@mxlg": { p: "$5" }}}
      >
        <Col
          sz="3"
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <Flex gap="4" direction="column">
            <Flex
              justify={{ "@mxlg": "between" }}
              align={{ "@mxlg": "center" }}
              direction={{ "@initial": "column", "@mxlg": "row" }}
            >
              <Text
                size="2"
                variant="dark"
                fw="500"
                css={{ mb: "$3", display: "block" }}
              >
                Nome
              </Text>

              <Text
                size="4"
                css={{
                  fw: "600",
                  "@mxlg": {
                    fontSize: "$sm",
                  },
                }}
              >
                {data.fullName}
              </Text>
            </Flex>

            <Flex
              justify={{ "@mxlg": "between" }}
              align={{ "@mxlg": "center" }}
              direction={{ "@initial": "column", "@mxlg": "row" }}
            >
              <Text
                size="2"
                variant="dark"
                fw="500"
                css={{
                  mb: "$2",
                  "@mxlg": {
                    mb: 0,
                  },
                }}
              >
                Tipo de assento
              </Text>

              <Flex>
                <Tag
                  css={{
                    fw: "600",
                    "@mxlg": {
                      height: "$5",
                    },
                  }}
                  variant="info-light"
                >
                  <Text size={{ "@initial": "4", "@mxlg": "2" }} fw="600">
                    {seatType}
                  </Text>
                </Tag>
              </Flex>
            </Flex>
          </Flex>
        </Col>

        <Col
          sz="3"
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <Flex
            direction="column"
            gap="4"
            css={{
              mt: "$6",
            }}
          >
            <Flex
              justify={{ "@mxlg": "between" }}
              align={{ "@mxlg": "center" }}
              direction={{ "@initial": "column", "@mxlg": "row" }}
            >
              <Text
                size="2"
                variant="dark"
                fw="500"
                css={{
                  mb: "1rem",
                  display: "block",
                  "@mxlg": {
                    mb: 0,
                  },
                }}
              >
                CPF
              </Text>

              <Text size={{ "@initial": "4", "@mxlg": "2" }} fw="600">
                {asCPF(data.cpf)}
              </Text>
            </Flex>

            <Flex
              justify={{ "@mxlg": "between" }}
              align={{ "@mxlg": "center" }}
              direction={{ "@initial": "column", "@mxlg": "row" }}
            >
              <Text
                size="2"
                variant="dark"
                fw="500"
                css={{ mb: "$3", display: "block" }}
              >
                Número do assento
              </Text>

              <Text size={{ "@initial": "4", "@mxlg": "2" }} fw="600">
                {seatNumber}
              </Text>
            </Flex>
          </Flex>
        </Col>

        <Col
          sz="2"
          css={{
            width: "100%",
          }}
        >
          <Flex
            direction="column"
            gap="4"
            css={{
              mt: "$3",
            }}
          >
            <Flex
              justify={{ "@mxlg": "between" }}
              direction={{ "@initial": "column", "@mxlg": "row" }}
            >
              <Text
                size={{ "@initial": "4", "@mxlg": "2" }}
                variant="dark"
                fw="500"
                css={{ mb: "1rem", display: "block" }}
              >
                RG
              </Text>

              <Text size="2" fw="600">
                {data.rg}
              </Text>
            </Flex>

            {ticket && (
              <Flex direction="column">
                <Text
                  size="2"
                  variant="dark"
                  fw="500"
                  css={{
                    mb: "1rem",
                    display: "block",
                    "@mxlg": {
                      mb: 0,
                    },
                  }}
                >
                  Ticket
                </Text>

                <Text size="2" fw="600">
                  {ticket}
                </Text>
              </Flex>
            )}
          </Flex>
        </Col>
      </Flex>
    </Card>
  );
}

RoadSegmentTravelerListItem.displayName = "RoadSegmentTravelerListItem";
