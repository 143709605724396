import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutationResult,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Order, OrderStatus, RemakeSearchData, Traveler, UserContext } from "~/application/types";
import { Button } from "~/components/Button";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgDolar, SvgDownload, SvgExternalLink } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { ExternalLink } from "~/components/Link/ExternalLink";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { userIsOrderApprover } from "~/core/modules/Order/pages/OrderPage/utils";
import { userIsIssuer } from "~/core/modules/Order/utils";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { DateFormats, displayDate } from "~/utils/date.utils";
import * as MaskUtils from "~/utils/mask.utils";
import { useDownload } from "../../hooks/useDownload";
import useMobile from "../../hooks/useMobile";
import { IssueNotifyButton } from "../IssueNotifyButton";
import { LoadingModal } from "../LoadingModal";
import { OrderItemService } from "../OrderItemsService";
import { OrderStatusTag } from "../OrderStatusTag";

export type RefetchRoadOrder = (
  orderId: string,
  travelers: Traveler[],
  oldOrderId: string
) => {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<RemakeSearchData, unknown>>;
  isLoading: boolean;
};

export type OrderListItemProps = DataListItemProps & {
  readonly data: Order;
  readonly voucherDownloadUrl?: string;
  readonly detailOrderLink: string;
  readonly onIconClick?: () => void;
  readonly onDownloaded?: () => void;
  readonly onIssuerNotify?: () => UseMutationResult<void, unknown, string, void>;
  readonly onCancelOrder?: (orderUuid: string) => void;
  readonly onRefetchRoadQuery: RefetchRoadOrder;
};

const LOG_TAG = "presentation/shared/OrderListItem";

export function OrderListItem({
  data,
  detailOrderLink,
  onIssuerNotify,
  voucherDownloadUrl,
  onRefetchRoadQuery,
  onIconClick,
  onDownloaded,
  ...props
}: OrderListItemProps) {
  const { user } = useAuth();
  const { contexts } = useUser();
  const cancellationTax = data.cancellationTax.road;
  const isAgencyEmployee = user.context === UserContext.Agency;
  const isIssuer = userIsIssuer(user, data, contexts);
  const isApprover = userIsOrderApprover(data);
  const navigate = useNavigate();

  const isMobile = useMobile();

  const orderStatus = data.status;

  const { onDownload } = useDownload({
    logTag: LOG_TAG,
  });

  const isExpirableStatus = ![
    OrderStatus.CANCELED,
    OrderStatus.ISSUED,
    OrderStatus.CANCELING,
  ].includes(orderStatus);

  const {
    isLoading: isNotifying,
    isError: isNotifyingError,
    isSuccess: isNotifingSuccess,
    mutate: onMutateIssuerNotify,
  } = onIssuerNotify?.() as UseMutationResult<void, unknown, string, void>;

  const isNotified = data.hasNotifiedExpiration;

  const userIsAgencyEmployee = user.context === UserContext.Agency;
  const refetchRoadQuery = onRefetchRoadQuery?.(data.uuid, data.travelers, data.uuid);

  const { setState } = useRemakeSearch();

  const onRemakeOrder = () => {
    dialogService.showDialog(
      <LoadingModal
        message="Isso pode demorar alguns minutos."
        title="Aguarde, estamos refazendo o seu pedido."
      />
    );

    setState({
      currentStep: 0,
      finishStep: {
        road: false,
        airway: false,
      },
      data: {},
      orderId: "",
      travelers: [],
    });

    refetchRoadQuery.refetch?.().then(() => {
      dialogService.popDialog();
    });
  };

  const handleOpenOrder = () => {
    navigate(detailOrderLink);
  };

  const isExpired = data.isExpired;

  return (
    <Flex
      onClick={handleOpenOrder}
      wrap="wrap"
      direction="column"
      css={{
        cursor: "pointer",
        "&:hover": {
          ":after": {
            boxShadow: "$md",
            borderWidth: "2px",
            borderColor: "$primary-base",
          },
        },
      }}
    >
      <DataListItem
        {...props}
        data-list={true}
        css={{
          pb: "$2",
          "@mxlg": {
            flexDirection: "column",
          },
        }}
      >
        <Flex css={{ flex: "2", width: "100%", alignSelf: "stretch" }}>
          <DataColItem
            headerTitle="Pedido"
            data={
              <Tooltip content={<TooltipLabel>Ver em pedidos</TooltipLabel>}>
                <Flex gap="2">
                  <ExternalLink
                    to={`/pedidos/${data.uuid}`}
                    onClick={(event) => event.stopPropagation()}
                    as={Link}
                  >
                    <Text css={{ fw: "600" }}>{data.orderNumber}</Text>
                    <Icon as={SvgExternalLink} size="sm" />
                  </ExternalLink>
                </Flex>
              </Tooltip>
            }
            css={{ width: "max-content" }}
            boxCss={{ mb: "$2" }}
          />

          <DataColItem
            headerTitle={isMobile ? "" : "Status"}
            data={<OrderStatusTag data={orderStatus} />}
            css={{
              alignItems: "start",
              color: "$error-base",
            }}
          />
        </Flex>

        {isExpirableStatus && (
          <DataColItem
            css={{
              minWidth: "180px",
              color: isExpired ? "$error-base" : "$warning-dark",
            }}
            headerTitle={isExpired ? "Expirado em" : "Expira em"}
            data={displayDate(data.expirationDate, DateFormats.TIMELY_MONTH).toString()}
          />
        )}

        {userIsAgencyEmployee && (
          <DataColItem headerTitle="Solicitante" data={data.issuer.name} boxCss={{ mb: "$2" }} />
        )}

        {userIsAgencyEmployee && (
          <DataColItem headerTitle="Empresa" data={data.customer.name} boxCss={{ mb: "$2" }} />
        )}

        <DataColItem
          headerTitle="Itens do pedido"
          css={{}}
          data={
            <Flex gap="1">
              {data.itemsIncluded.map((service, i) => {
                const orderViolatedPolicies = data.orderViolatedPolicies[service];

                return (
                  <OrderItemService key={i} service={service} orderViolatedPolicies={orderViolatedPolicies} />
                );
              })}
            </Flex>
          }
        />

        <DataColItem
          headerTitle="Valor"
          boxCss={{ mb: "$2" }}
          css={{
            display: "flex",
          }}
          data={
            <Text
              css={{
                fw: "600",
                display: "flex",
              }}
              size="5"
            >
              {MaskUtils.asCurrency(data.totalValue)}
              {cancellationTax && !isAgencyEmployee && (
                <Flex
                  css={{
                    width: "100%",
                    justifyContent: "center",
                    ml: "$2",
                  }}
                >
                  <Tooltip content={<TooltipLabel>Taxa de cancelamento</TooltipLabel>}>
                    <IconButton size="sm">
                      <SvgDolar stroke="#AE1437" />
                    </IconButton>
                  </Tooltip>
                </Flex>
              )}
            </Text>
          }
        />
        {isExpirableStatus && (
          <Flex
            justify="center"
            css={{
              visibility: isExpired ? "visible" : "hidden",
              width: "100%",
              flex: 1,
            }}
          >
            {isIssuer && orderStatus !== OrderStatus.QUOTING ? (
              <DataColItem
                css={{
                  "@mxlg": {
                    alignItems: "center",
                  },
                }}
                data={
                  <Button
                    onClick={(e) => {
                      e.stopPropagation(), onRemakeOrder();
                    }}
                    css={{
                      "@mxlg": {
                        px: "$2",
                        height: "$5",
                        width: "100%",
                      },
                    }}
                  >
                    <Text size={{ "@mxlg": "2" }}>Refazer pedido</Text>
                  </Button>
                }
              />
            ) : (
              isApprover && (
                <DataColItem
                  css={{}}
                  data={
                    <IssueNotifyButton
                      isNotifying={isNotifying || isNotifyingError || isNotifingSuccess}
                      isNotified={isNotified}
                      onIssuerNotify={() => onMutateIssuerNotify(data.uuid)}
                    />
                  }
                />
              )
            )}
          </Flex>
        )}

        {[OrderStatus.ISSUED, OrderStatus.CANCELING].includes(orderStatus) && (
          <DataColItem
            headerTitle="Baixar voucher"
            boxCss={{ alignItems: "center" }}
            css={{ opacity: voucherDownloadUrl ? 1 : 0 }}
            data={
              <Flex direction="row" align="center" onClick={(e) => e.stopPropagation()}>
                <IconButton
                  css={{
                    size: "$8",
                    background: "#0064C5",
                    opacity: 0.9,
                    "&:hover": {
                      background: "#0064C5",
                      opacity: 1,
                    },
                  }}
                  onClick={() => onDownload(data)}
                >
                  <SvgDownload />
                </IconButton>
              </Flex>
            }
          />
        )}
      </DataListItem>

      <Flex
        className="test"
        css={{
          boxSizing: "border-box",
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          transition: "$fast",
          borderRadius: "$md",

          "&::after": {
            content: "",
            pointerEvents: "none",
            position: "absolute",
            inset: 0,
            margin: 0,
            borderRadius: "inherit",
            borderStyle: "none solid solid solid",
            borderColor: "transparent",
            transition: "inherit",
          },
          "&[data-active=true]::after": {
            borderWidth: "2px",
            borderColor: "$primary-base",
          },
        }}
      >
        <Flex
          css={{
            backgroundColor: "$neutrals-white",
            p: "0 $2 $6 0",
            borderRadius: " $md 0 0 $md ",
          }}
        ></Flex>
        <Flex
          css={{
            backgroundColor: "$neutrals-white",
            border: "3px $neutrals-light",
            borderStyle: "dotted none none none",
            width: "100%",
            p: "$3 $6 $3 $6",
            gap: "$1",
          }}
          direction="column"
        >
          <Text
            css={{
              color: "$neutrals-dark",
              fw: "600",
              fontSize: "$sm",
            }}
          >
            Passageiros
          </Text>
          <Flex
            gap={{ "@initial": "8", "@mxlg": "4" }}
            css={{
              width: "100%",
              "@mxlg": {
                mt: "$4",
              },
            }}
            justify="center"
            direction={{ "@mxlg": "column" }}
          >
            {data.travelers.map((traveler) => (
              <Text
                key={traveler.uuid}
                css={{
                  color: "$neutrals-darkest",
                  fw: "500",
                  fontSize: "clamp(10px, 3vw, 16px)",
                }}
              >
                {traveler.fullName}
              </Text>
            ))}
          </Flex>
        </Flex>
        <Flex
          css={{
            backgroundColor: "$neutrals-white",
            p: "0 $2 $6 0",
            borderRadius: " 0 $md $md 0 ",
          }}
        ></Flex>
      </Flex>
    </Flex>
  );
}
