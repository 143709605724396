import { Auth, Profile } from "~/application/types";
import { api } from "~/infrastructure/api";
import { AuthDTO, ProfileDTO, QRCodeDTO, UserDTO } from "~/infrastructure/api/dtos";
import {
  mapAuthDTO,
  mapProfileDTO,
  mapQRCodeDTO,
  mapUserDTO,
} from "~/infrastructure/api/mappers";
import { IAuthService } from "./IAuthService";

export class AuthService implements IAuthService {
  async verifyToken({
    value,
    createdAt,
  }: {
    value: string;
    createdAt: Date;
  }): Promise<Auth> {
    return await api
      .get<{ data: UserDTO }>("/me", undefined, {
        Authorization: `Bearer ${value}`,
      })
      .then(({ data }) => ({
        authToken: {
          value,
          createdAt,
        },
        user: mapUserDTO(data.data),
      }))
      .catch((error) => {
        delete api.headers.get["Authorization"];
        throw error;
      });
  }

  async getProfile(): Promise<Profile> {
    return await api
      .get<ProfileDTO>("/me/profile")
      .then(({ data }) => mapProfileDTO(data));
  }

  async signIn(data: { email: string; password: string; secret?: string }): Promise<Auth> {
    return await api
      .post<AuthDTO>("/login", {
        email: data.email,
        password: data.password,
        secret: data.secret,
      })
      .then(({ data }) => mapAuthDTO(data));
  }

  async requestChangePassword(customerEmployeeId: string): Promise<void> {
    const url = "/request-change-password";
    const body = { customer_employee_uuid: customerEmployeeId };

    return await api.post(url, body).then(({ data }) => data);
  }

  async forgotPassword(email: string): Promise<void> {
    const url = "/forgot-password";
    const body = { email };

    return await api.post(url, body).then(({ data }) => data);
  }

  async generateSecretKey(data: { email: string; password: string; }): Promise<{ qrCodeUrl: string | null }> {
    return await api
      .post<QRCodeDTO>("/generate-secret-key", {
        email: data.email,
        password: data.password,
      })
      .then(({ data }) => mapQRCodeDTO(data));
  }
}
