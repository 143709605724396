import { DashboardBiAdditionalInfo, DashboardBiFilter, PurchaseReport } from "~/application/types/entities/DashBoardBI.type";
import { DashboardBIDTO, PurchaseReportDTO } from "../dtos/DashboardBIDTO";
import { OrderItems } from "~/application/types";
import { Report } from "~/core/modules/Agency/pages/DashboardBIPage/types";

export type ValueAndDescription = Omit<PurchaseReport, "report">;

export type NewClientsPurchaseMonth = {
  report: Report[];
  value?: number;
  description?: string;
};

export type PendingFirstPurchase = {
  report: Report[];
  value?: number;
  description?: string;
};

export type NotBuyingCustomers = {
  report: Report[];
  value?: number;
  description?: string;
};


export type OrdersNotIssuedWeek = {
  report: Report[];
  value?: number;
  description?: string;
};

export type OperationalInterventions = {
  report: Report[];
  value?: number;
  description?: string;
};

export type LogoutCustomers = {
  report?: Report[];
  value?: number;
  description?: string;
};

export const mapReport = (data: PurchaseReportDTO): Report => ({
  tradingName: data.trading_name,
  issuedOrdersAmount: data.issued_orders_amount,
  loggedUserName: data.logged_username,
  loginAmount: data.login_amount,
  notIssuedOrdersAmount: data.not_issued_orders_amount,
  loginDays: data.login_days,
  logoutDays: data.logout_days,
  date: data.date,
  agreementId: data.agreement_uuid,
  serviceType: data.service_type,
  interventionsAmount: data.interventions_amount,
  providerName: data.provider_name,
  orderAmount: data.order_amount
});

export type NewClientsPurchaseMonthProps = DashboardBIDTO["additional_info"]['new_clients_purchase_month'] | undefined
export type PendingFirstPurchaseProps = DashboardBIDTO["additional_info"]['pending_first_purchase'] | undefined
export type NotBuyingCustomersProps = DashboardBIDTO["additional_info"]['not_buying_customers']
export type LogoutCustomersProps = DashboardBIDTO["additional_info"]["logout_customers"]
export type OrdersNotIssuedWeekProps = DashboardBIDTO["additional_info"]["orders_not_issued_week"]
export type OperationalInterventionsProps = DashboardBIDTO["additional_info"]["operational_interventions"]

const mapValueAndDescription = (data: { value?: number; description?: string }): ValueAndDescription => ({
  value: data?.value,
  description: data?.description,
});

export const mapNewClientsPurchaseMonth = (data: NewClientsPurchaseMonthProps): NewClientsPurchaseMonth => ({
  ...mapValueAndDescription({ description: data?.description, value: data?.value }),
  report: data?.report.map((r) => ({
    tradingName: r.trading_name,
    orderAmount: r.orders_amount,
  })) ?? [],
});

export const mapPendingFirstPurchase = (data: PendingFirstPurchaseProps): PendingFirstPurchase => ({
  ...mapValueAndDescription({ description: data?.description, value: data?.value }),
  report: data?.report?.map((r) => ({
    ...mapReport(r),
  })) ?? [],
});

export const mapNotBuyingCustomers = (data: NotBuyingCustomersProps): NotBuyingCustomers => ({
  ...mapValueAndDescription({ description: data?.description, value: data?.value }),
  report: data?.report.map(mapReport) ?? [],
});

export const mapLogoutCustomers = (data: LogoutCustomersProps): LogoutCustomers => ({
  ...mapValueAndDescription({ description: data?.description, value: data?.value }),
  report: data?.report.map(report => ({
    ...mapReport(report),
    issuedOrdersAmount: report.issued_orders_amount,
  })) ?? [],
});

export const mapOrdersNotIssuedWeek = (data: OrdersNotIssuedWeekProps): OrdersNotIssuedWeek => ({
  ...mapValueAndDescription({ description: data?.description, value: data?.value }),
  report: data?.report.map(report => ({
    tradingName: report.trading_name,
    loggedUserName: report.logged_username,
    notIssuedOrdersAmount: report.not_issued_orders_amount,
  })) ?? [],
});

export const mapOperationalInterventions = (data: OperationalInterventionsProps): OperationalInterventions => ({
  ...mapValueAndDescription({ description: data?.description, value: data?.value }),
  report: data?.report.map(report => ({
    date: report.date,
    agreementId: report.agreement_uuid,
    serviceType: report.service_type,
    interventionsAmount: report.interventions_amount,
    providerName: report.provider_name,
  })) ?? [],
});


export function mapDashboardBIDTO(dto: DashboardBIDTO): DashboardBiFilter {
  return {
    filter: {
      value: dto.value,
      type: dto.type,
      data: dto.data.map(data => ({
        day: data.day,
        hour: data.hour,
        month: data.month,
        salesAmount: data.sales_amount,
        year: data.year,
      })),
    },
  };
}
