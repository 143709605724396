import { CardFlag } from "~/application/types/entities/CardFlag.type";
import { api } from "~/infrastructure/api";
import { mapCardFlagDTO } from "~/infrastructure/api/mappers/mapCardFlagDTO";

export class CardFlagService {
  async find(): Promise<CardFlag[]> {
    return api
      .get("/agencies/card-flags")
      .then(({ data: response }) => response.data.map(mapCardFlagDTO));
  }
}
