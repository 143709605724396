import React, { ComponentType, forwardRef, useContext } from "react";
import { styled } from "~/application/theme";
import { FormControlContext } from "../../FormControl/FormControlContext";
import { Icon } from "../../Icon";
import { InputBase, InputStandardProps } from "../base/InputBase";
import { InputAdornment } from "../base/InputBase/InputAdornment";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";

export type TextInputProps = InputStandardProps & {
  style?: React.CSSProperties;
  leftIcon?: ComponentType;
  rightIcon?: ComponentType;
  prefix?: string;
};

const TextInputRoot = styled(InputBase, {});

export const TextInput = forwardRef<React.ElementRef<typeof TextInputRoot>, TextInputProps>(
  (
    {
      leftIcon,
      prefix,
      rightIcon,
      size = "md",
      name: nameProps,
      disabled: disabledProps,
      onBlur: onBlurProps,
      onChange: onChangeProps,
      value: valueProps,
      ...props
    },
    forwardedRef
  ) => {
    const formControl = useContext(FormControlContext);

    const name = nameProps ?? formControl.name;
    const disabled = disabledProps ?? formControl.disabled;
    const onBlur = onBlurProps ?? formControl.onBlur;
    const onChange = onChangeProps ?? formControl.onChange;
    const value = valueProps ?? formControl.value;
    const isDirty = !!formControl.error;

    return (
      <TextInputRoot
        type="text"
        leftAdornment={
          <Flex gap="2">
            <Text fw="600">{prefix}</Text>
            {leftIcon && (
              <InputAdornment position="start" disablePointerEvents>
                <Icon as={leftIcon} size={size} />
              </InputAdornment>
            )}
          </Flex>
        }
        rightAdornment={
          rightIcon && (
            <InputAdornment position="end" disablePointerEvents>
              <Icon as={rightIcon} size={size} />
            </InputAdornment>
          )
        }
        size={size}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        data-dirty={isDirty}
        {...props}
        ref={forwardedRef}
      />
    );
  }
);

TextInput.displayName = "TextInput";
