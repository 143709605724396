import { AdvanceOrderStatus, TravelerAdvance } from "~/application/types";
import { TravelerAdvanceDTO } from "../dtos";
import { mapTravelerDTO } from "./mapTravelerDTO";

export function mapTravelerAdvanceDTO(
  data: TravelerAdvanceDTO
): TravelerAdvance {
  return {
    uuid: data.uuid,
    order: {
      uuid: data.order?.uuid,
      travelers: (data.order?.travelers || []).map(mapTravelerDTO),
    },
    status: data.status as AdvanceOrderStatus,
    traveler: mapTravelerDTO(data.traveler),
    description: data.description,
    currentApprover: data.current_approver,
    approvedBy: data.approved_by,
    approvalRequestDate: data.approval_request_date,
    expenses: data.expenses,
    value: data.value,
    approvers: (data.approvers || []).map(({ uuid, name }) => ({
      id: uuid,
      name,
    })),
  };
}
