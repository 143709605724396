import { Agreement } from "~/application/types/entities/BindCredential.type";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { getServiceIcon } from "~/presentation/shared/utils";

export interface CredentialListItemProps extends DataListItemProps {
  data: Agreement;
  onEditClick: (item: Agreement) => void;
  onToggleState: (params: { credentialId: string; isActive: boolean }) => void;
}

export function BindCredentialListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: CredentialListItemProps) {
  const isMobile = useMobile();
  return (
    <DataListItem
      data-active={data.active}
      {...props}
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Fornecedor" data={data.credential.provider.name} />

      <DataColItem headerTitle="Descrição" data={data.codeAgreement} />
      <DataColItem
        headerTitle="Serviço"
        data={
          <Flex gap="2">
            <Flex align="center" gap="2">
              <IconButton size="md">
                <Icon as={getServiceIcon(data.credential.serviceType)} />
              </IconButton>
            </Flex>
          </Flex>
        }
      />

      {isMobile ? (
        <Flex justify="between" css={{ width: "100%" }}>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          <Switch
            checked={data.active}
            onClick={() => onToggleState({ credentialId: data.uuid, isActive: data.active })}
          >
            <Caption>{data.active ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </Flex>
      ) : (
        <>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          <Switch
            checked={data.active}
            onClick={() => onToggleState({ credentialId: data.uuid, isActive: data.active })}
          >
            <Caption>{data.active ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </>
      )}
    </DataListItem>
  );
}

BindCredentialListItem.displayName = "BindCredentialListItem";
