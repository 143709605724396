import * as React from "react";
import { SVGProps } from "react";

const SvgBus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M3.281 5a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625H16.72a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625ZM3.281 8.75a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625H16.72a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M5.156 2.5a2.5 2.5 0 0 0-2.5 2.5v10a.625.625 0 0 0 .625.625H16.72a.625.625 0 0 0 .625-.625V5a2.5 2.5 0 0 0-2.5-2.5Zm0 1.25h9.688A1.25 1.25 0 0 1 16.094 5v9.375H3.906V5a1.25 1.25 0 0 1 1.25-1.25Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M13.594 14.375a.625.625 0 0 0-.625.625v1.875a1.252 1.252 0 0 0 1.25 1.25h1.875a1.253 1.253 0 0 0 1.25-1.25V15a.625.625 0 0 0-.625-.625.625.625 0 0 0-.625.625v1.875h-1.875V15a.625.625 0 0 0-.625-.625zM3.281 14.375a.625.625 0 0 0-.625.625v1.875a1.252 1.252 0 0 0 1.25 1.25h1.875a1.253 1.253 0 0 0 1.25-1.25V15a.625.625 0 0 0-.625-.625.625.625 0 0 0-.625.625v1.875H3.906V15a.625.625 0 0 0-.625-.625z"
    />
    <path d="M6.563 13.125a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875zM13.438 13.125a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875z" />
  </svg>
);

export default SvgBus;
