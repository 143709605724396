import { OrderItems } from "./OrderItems.enum";

export enum PolicyParamsSlug {
  FIXED_VALUE = "fixed-value",
  LEAD_TIME = "lead-time",
  LOWER_FARE = "lower-fare",
  REASON_TRIP = "reason-trip",
}

export type PolicyParameter = {
  uuid: string;
  serviceType: Exclude<OrderItems, OrderItems.HOTEL_OFFLINE | OrderItems.ADVANCE>;
  slug: PolicyParamsSlug;
  contentType: string;
};

export type PolicyValue = {
  uuid: string;
  value: string;
  parameter: PolicyParameter;
  reasonTrip?: {
    name: string;
    uuid: string;
  };
};

export type Policy = {
  uuid: string;
  name: string;
  isActive: boolean;
  customerId: string;
  values: PolicyValue[];
};
