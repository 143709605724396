import { Agency, State } from "~/application/types";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { queryClient } from "~/services/queryClient";
import { agencyService, stateService } from "~/application/usecases";
import { log } from "~/utils/log";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CreateAgencyContainer } from "./CreateAgencyContainer";

const LOG_TAG = "Agency/CreateAgencyPage";

const SNACKBAR_MESSAGES = {
  CREATE_SUCCESS_MESSAGE: "Agência criada",
  CREATE_ERROR_MESSAGE: "Falha ao criar agência",
} as const;

const DEFAULT_FORM_DATA = {
  companyName: "",
  tradingName: "",
  cnpj: "",
  phone: "",
  zipCode: "",
  address: "",
  number: "",
  district: "",
  stateRegistration: "",
  email: "",
} as Agency;

export function CreateAgencyPage() {
  const navigate = useNavigate();

  const fetchCitiesByState = useCallback(
    (state: State) =>
      stateService
        .findCities(state)
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const { mutate: handleCreateAgency } = useMutation(
    (item: Agency) => agencyService.create(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Agency(${item.uuid})`);
      },
      onSuccess: (data) => {
        log.i(LOG_TAG, `Successfully created Agency(${data.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCIES]);

        navigate(`/configuracoes/agencias/${data.uuid}`);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  return (
    <CreateAgencyContainer
      defaultData={DEFAULT_FORM_DATA}
      onCreateAgency={handleCreateAgency}
      fetchCitiesByState={fetchCitiesByState}
    />
  );
}
