import { useCallback, useEffect, useState } from "react";
import { styled } from "~/application/theme";
import { Snackbar } from "./Snackbar";
import { snackbarService, SnackbarType } from "./SnackbarService";

const SnackbarContainerRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  position: "fixed",
  display: "flex",

  // Custom
  flexDirection: "column",
  alignItems: "flex-end",
  zIndex: "$alert",
  right: "10%",
  bottom: "$20",
  gap: "$4",
});

export function SnackbarContainer() {
  const [snackbarPack, setSnackbarPack] = useState<SnackbarType[]>([]);

  const onUpdate = (): void => {
    setSnackbarPack((old) => [...old, { ...snackbarService.getSnackbar() }]);
  };

  const removeSnack = useCallback(
    (snackId: number): void => {
      setSnackbarPack((old) => old.filter((item) => item.id !== snackId));
    },
    [setSnackbarPack]
  );

  useEffect(() => {
    snackbarService.subscribe(onUpdate);
  }, []);

  return (
    <SnackbarContainerRoot>
      {snackbarPack.map((snackbar) => (
        <Snackbar
          message={snackbar.message}
          milliSeconds={snackbar.milliSeconds}
          onRemoveSnack={() => removeSnack(snackbar.id)}
          mobile={false}
          key={snackbar.id}
        />
      ))}
    </SnackbarContainerRoot>
  );
}
