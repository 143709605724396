import { ExpensePolicy, ExpensePolicyTypeValue } from '~/application/types';
import { ExpensePolicyDTO } from '../dtos';

export function mapExpensePolicyDTO(data: ExpensePolicyDTO): ExpensePolicy {
  return {
    uuid: data.uuid,
    policy: {
      uuid: data.policy.uuid,
      name: data.policy.name,
    },
    expenseType: {
      uuid: data.expense_type.uuid,
      name: data.expense_type.name,
    },
    expenseCategory: {
      uuid: data.expense_type.expense_category.uuid,
      name: data.expense_type.expense_category.name,
    },
    policyParameterExpense: {
      uuid: data.policy_parameter_expense.uuid,
      description: data.policy_parameter_expense.description,
      slug: data.policy_parameter_expense.slug,
      type: data.policy_parameter_expense.type,
    },
    typeValue: data.type_value as ExpensePolicyTypeValue,
    value: parseFloat(data.value),
    voucherRequired: data.voucher_required,
    isActive: Boolean(data.active),
  };
}