import type { ReasonTrip } from "~/application/types";

export type EditableReasonTrip = ReasonTrip;

const DEFAULT_FORM_DATA = {
  reason: "",
} as EditableReasonTrip;

export function createReasonTrip(editable: EditableReasonTrip): ReasonTrip {
  return {
    ...editable,
  };
}

export function editReasonTrip(data?: ReasonTrip): EditableReasonTrip {
  if (!data) return DEFAULT_FORM_DATA;

  return {
    ...data,
  };
}
