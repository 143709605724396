import { ComponentProps } from "react";
import { styled } from "~/application/theme";

const BiztripLogoRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  cursor: "pointer",

  // Custom
  alignItems: "center",
  justifyContent: "start",

  variants: {
    size: {
      sm: {
        height: "$6",
        [`& svg`]: {
          height: "17px",
          width: "58px",
        },
      },
      md: {
        height: "$8",
        [`& svg`]: {
          height: "23px",
          width: "77px",
        },
      },
      lg: {
        height: "$10",
        [`& svg`]: {
          height: "29px",
          width: "97px",
        },
      },
      xlg: {
        height: "$10",
        margin: "0 auto",
        paddingBottom: "40px",
        [`& svg`]: {
          height: "164px",
          width: "240px",
        },
      },
    },

    variant: {
      light: {
        [`& .bt-biz-paths`]: {
          fill: "$primary-light",
        },
        [`& .bt-trip-paths`]: {
          fill: "$primary-base",
        },
      },
      dark: {
        [`& .bt-biz-paths`]: {
          fill: "$primary-base",
        },
        [`& .bt-trip-paths`]: {
          fill: "$primary-dark",
        },
      },
    },
  },
  defaultVariants: {
    size: "md",
    variant: "dark",
  },
});

export type BiztripLogoProps = ComponentProps<typeof BiztripLogoRoot>;

export const BiztripLogo = (props: BiztripLogoProps) => {
  return (
    <BiztripLogoRoot {...props}>
      <svg
        width={97}
        height={29}
        viewBox="0 0 97 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="bt-biz-paths">
          <path d="M8.5 6.533c-2.267 0-3.9.867-4.933 2v-1.3H3.6V0H0v23.333h3.567v-1.966c1.033 1.1 2.666 1.966 4.933 1.966 5.367 0 7.333-3.5 7.333-8.4s-1.966-8.4-7.333-8.4Zm-.6 13.2c-3.133 0-4.333-.933-4.333-4.8 0-3.866 1.2-4.8 4.333-4.8 3.167 0 4.367.934 4.367 4.8 0 3.867-1.2 4.8-4.367 4.8ZM19.14 0v3.333h3.6V0h-3.6Zm0 6.533v16.8h3.6v-16.8h-3.6ZM41.742 6.533H27.076v3.6h9l-9 10.8v2.4h14.666v-3.6h-8.966l8.966-10.8v-2.4Z" />
        </g>
        <g className="bt-trip-paths">
          <path d="M56.094 10.133v-3.6h-4.433V0h-3.6v6.533h-2.666v3.6h2.666v13.2h8.033v-3.6h-4.433v-9.6h4.433ZM70.083 6.533h-10v16.8h3.6v-13.2h6.4v-3.6ZM73.438 0v3.333h3.6V0h-3.6Zm0 6.533v16.8h3.6v-16.8h-3.6ZM89.49 6.533c-2.267 0-3.9.867-4.934 2v-2H80.99v22.134h3.6v-5.9h-.034v-1.4c1.034 1.1 2.667 1.966 4.934 1.966 5.366 0 7.333-3.5 7.333-8.4s-1.967-8.4-7.333-8.4Zm-.6 13.2c-3.134 0-4.334-.933-4.334-4.8 0-3.866 1.2-4.8 4.334-4.8 3.166 0 4.366.934 4.366 4.8 0 3.867-1.2 4.8-4.366 4.8Z" />
        </g>
      </svg>
    </BiztripLogoRoot>
  );
};

BiztripLogo.displayName = "BiztripLogo";
