import { Judgement } from "~/application/types";
import { JudgementDTO } from "../dtos";

export function mapJudgementDTO(data: JudgementDTO): Judgement {
  return {
    id: data.uuid,
    assessmentId: data.assessment_uuid,
    approverAssessmentId: data.approver_assessment_uuid,
    reasonRejected: data.reasonRejected,
    status: data.status,
    obs: data.obs,
  };
}
