import { AirwaySeats } from "~/application/types/entities/AirwaySeats.type";
import { AirwaySeatsDTO } from "../dtos/AirwaySeatsDTO";
import { DateUtils } from "~/application/utils";

export const mapAirwaySeatsDTO = (dto: AirwaySeatsDTO): AirwaySeats => {
  return {
    searchKey: dto.search_key,
    data: dto.data.map((item) => ({
      id: item.id,
      arrivalDate: DateUtils.toDate(item.arrival_date),
      departure: item.departure,
      arrival: item.arrival,
      flightNumber: item.flight_number,
      mapSeats: item.map_seats.map((mapSeat) => ({
        class: mapSeat.class,
        purchaseOfSeats: mapSeat.purchase_of_seats,
        blueSpace: mapSeat.blue_space,
        rowns: mapSeat.rowns.map((rown) => ({
          row: rown.row,
          isWing: rown.is_wing,
          available: rown.available,
          startWing: rown.start_wing,
          emergencyExit: rown.emergency_exit,
          endWing: rown.end_wing,
          columns: rown.columns.map((column) => ({
            column: column.column,
            busy: column.busy,
            situation: column.situation,
            location: column.location,
            value: column.value,
            seatTypeDescription: column.seat_type_description,
          })),
        })),
      })),
    })),
  };
};
