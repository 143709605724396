import { ApiError, Hotel, HotelQuery, HotelQueryLinks } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { api } from "~/infrastructure/api";
import { HotelDTO, HotelQueryDTO, HotelQueryLinksDTO } from "~/infrastructure/api/dtos";
import { mapHotelDTO } from "~/infrastructure/api/mappers";
import type { IHotelQueryService } from "./IHotelQueryService";

const DEFAULT_ADULT_AGE = 24;

export class HotelQueryService implements IHotelQueryService {
  async query(
    customerId: string,
    { accommodations, checkInDate, checkOutDate, city, reasonTrip }: HotelQuery
  ): Promise<HotelQueryLinks> {
    return api
      .post<HotelQueryLinksDTO, HotelQueryDTO>("/hotels", {
        accommodations: accommodations.map((item) => ({
          guests: [
            ...Array(item.adultQuantity).fill({
              type: "ADT",
              age: DEFAULT_ADULT_AGE.toString(),
            }),
            ...item.guestsChildren.map((children) => ({
              type: "CHD" as const,
              age: children.age,
            })),
          ],
        })),
        city_uuid: city.uuid,
        checkIn: DateUtils.toISOString(checkInDate as Date),
        checkOut: DateUtils.toISOString(checkOutDate as Date),
        customer_uuid: customerId,
        reason_trip_uuid: reasonTrip?.uuid,
      })
      .then(({ data }) => ({
        waitTime: data.wait_time,
        links: data.links,
      }));
  }

  async find(url: string): Promise<Hotel[]> {
    return api.get<{ search_key: string; hotels: HotelDTO[] }>(url).then(({ data }) => {
      if (ApiError.isAsyncError(data)) {
        throw new ApiError({ data, statusCode: 204 });
      }

      return data.hotels.map((hotel) => mapHotelDTO(hotel, data.search_key));
    });
  }
}
