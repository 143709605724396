import { Traveler } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import type { TravelerDTO } from "../dtos";

export function mapTravelerDTO(data: TravelerDTO): Traveler {
  return {
    uuid: data.uuid,
    email: data.email,
    rg: data.rg,
    cpf: data.cpf,
    fullName: data.full_name,
    position: data.position,
    dateBirth: toDate(data.date_birth),
    phone: data.phone,
    company: data.company && {
      tradingName: data.company.trading_name,
    },
    seats: data.seats?.map((seat) => {
      const indexFistNot0 = seat.seat_number
        .split("")
        .findIndex((c) => c !== "0");
      return {
        bpe: seat.bpe ?? null,
        isConnection: seat.is_connection,
        seatNumber: seat.seat_number.slice(
          indexFistNot0 === -1 ? 0 : indexFistNot0
        ),
        seatType: seat.seat_type,
        ticket: seat.ticket ?? null,
        uuid: seat.uuid,
        arrival: seat.arrival,
        departure: seat.departure,
        value: seat.value,
      };
    }),
    avatarUrl: data.avatarUrl ?? undefined,
  };
}
