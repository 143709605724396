import { ExpensePolicyTypeValue } from "~/application/types";
import { ExpensePolicyRule } from "./types";

export const EXPENSE_POLICY_TYPE_VALUE: Record<ExpensePolicyTypeValue, string> =
  {
    [ExpensePolicyTypeValue.TOUCHABLE_LESS]: "Alterável para menos",
    [ExpensePolicyTypeValue.TOUCHABLE_MORE_LESS]: "Alterável para mais e menos",
    [ExpensePolicyTypeValue.UNTOUCHABLE]: "Inalterável",
    [ExpensePolicyTypeValue.AMOUNT_MAXIMUM]: "Quantidade Máxima",
  };

export const EXPENSE_POLICY_RULE_TYPES: ExpensePolicyRule[] = [
  {
    value: ExpensePolicyTypeValue.UNTOUCHABLE,
    name: EXPENSE_POLICY_TYPE_VALUE[ExpensePolicyTypeValue.UNTOUCHABLE],
    ruleType: "price",
    description:
      "Não admite variações de valor para cima ou para baixo da política",
  },
  {
    value: ExpensePolicyTypeValue.TOUCHABLE_LESS,
    name: EXPENSE_POLICY_TYPE_VALUE[ExpensePolicyTypeValue.TOUCHABLE_LESS],
    ruleType: "price",
    description: "Admite valores iguais ou menores do que a política",
  },
  {
    value: ExpensePolicyTypeValue.TOUCHABLE_MORE_LESS,
    name: EXPENSE_POLICY_TYPE_VALUE[ExpensePolicyTypeValue.TOUCHABLE_MORE_LESS],
    ruleType: "price",
    description: "Admite valores maiores ou menores do que a regra",
  },
  {
    value: ExpensePolicyTypeValue.AMOUNT_MAXIMUM,
    name: EXPENSE_POLICY_TYPE_VALUE[ExpensePolicyTypeValue.AMOUNT_MAXIMUM],
    ruleType: "amount",
    description: "Admite valores até, no máximo, o estabelecido na regra",
  },
];
