import { Stop } from "~/application/types";
import { StopDTO } from "../dtos";
import { mapCityDTO } from "./mapCityDTO";

export function mapStopDTO(data: StopDTO): Stop {
  return {
    city: mapCityDTO(data.city),
    stops: data.stops,
  };
}
