import { ComponentProps, useContext } from "react";
import { styled } from "~/application/theme";
import { Icon } from "../Icon";
import { TabsContext } from "./contexts/TabsContext";

export type TabProps<TValue extends string | number = string> = ComponentProps<
  typeof TabRoot
> & {
  value: TValue;
};

const TabRoot = styled("button", {
  // Reset
  all: "unset",
  boxSizing: "border-box",

  // Custom
  display: "inline-flex",
  alignItems: "center",
  gap: "$2",
  padding: "$4",
  background: "$neutrals-white",
  color: "$neutrals-dark",
  fill: "$neutrals-dark",
  fontSize: "$md",
  fontWeight: 500,
  transition: "$normal",
  userSelect: "none",

  "&:hover": {
    cursor: "pointer",
    background: "$primary-light",
    color: "$primary-dark",
    fill: "$primary-dark",
  },

  "&[data-active=true]": {
    backgroundColor: "$primary-base",
    color: "$neutrals-white",
    fill: "$neutrals-white",
    fontWeight: 700,
  },

  "&:disabled": {
    opacity: "$disabled",
    pointerEvents: "none",
  },

  [`& ${Icon}`]: {
    size: "$5",
  },
});

export const Tab = <TValue extends string | number = string>({
  value,
  ...props
}: TabProps<TValue>) => {
  const { activeValue, setActiveValue } = useContext(TabsContext);

  return (
    <TabRoot
      role="tab"
      type="button"
      data-active={value === activeValue}
      onClick={() => setActiveValue(value)}
      {...props}
    />
  );
};

Tab.displayName = "Tab";
