import { styled } from "~/application/theme";

const Container = styled("span", {
  userSelect: "none",
  position: "relative",
  size: "$8",
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "$sm",
  borderColor: "transparent",
  color: "$neutrals-white",
  transition: "$normal",
  backgroundColor: "$success-base",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "$sm",
  fontWeight: "700",

  "&::after": {
    content: "",
    position: "absolute",
    backgroundColor: "$success-dark",
    width: "5px",
    height: "80%",
    right: 0,
    transform: "translate(75%, 0)",
    borderTopRightRadius: "$sm",
    borderBottomRightRadius: "$sm",
  },

  "&:hover": {
    cursor: "pointer",
  },

  "&[data-disabled=true]": {
    pointerEvents: "none",
    backgroundColor: "$neutrals-base",
    "&::after": { backgroundColor: "$neutrals-darkest" },
  },

  "&[data-selected=true]": {
    pointerEvents: "inherit",
    backgroundColor: "$primary-base",
    "&::after": { backgroundColor: "$primary-dark" },
  },

  "&[data-available=false]": {
    pointerEvents: "none",
    backgroundColor: "$neutrals-dark",
    borderColor: "$neutrals-base",
    "&::after": { backgroundColor: "$neutrals-darkest" },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "$primary-dark",
      "&::after": { backgroundColor: "$primary-base" },
    },
  },

  variants: {
    size: {
      sm: {
        size: "$6",
      },
      md: {
        size: "$8",
      },
      lg: {
        size: "$10",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

export const Styled = {
  Container,
} as const;
