export function objectKeysExcept(obj: object, keys: string[]): object {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)));
}

export function convertKeysToSnakeCase(obj: Record<string, any>): Record<string, any> {
  const snakeCaseObject: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeCaseKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
      snakeCaseObject[snakeCaseKey] = obj[key];
    }
  }

  return snakeCaseObject;
}

export function isRecord(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
}

export function groupBy(arr: any[], key: string): Record<string, any[]> {
  return arr.reduce((acc, obj) => {
    const keyValue = obj[key];

    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }

    acc[keyValue].push(obj);

    return acc;
  }, {});
}
