import { SvgSearch } from "../../Icon/icons";
import React, { forwardRef, useCallback } from "react";
import { TextInput, TextInputProps } from "../TextInput";
import { CSS } from "@stitches/react/types/css-util";

export interface SearchBarProps extends TextInputProps {
  search?: string;
  onSearchChange: (search: string) => void;
  css?: CSS;
}

export const SearchBar = forwardRef<React.ElementRef<typeof TextInput>, SearchBarProps>(
  ({ search, onSearchChange, ...props }, forwardedRef) => {
    const handleOnChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
      (event) => onSearchChange(event.currentTarget.value),
      [onSearchChange]
    );

    return (
      <TextInput
        value={search}
        onChange={handleOnChange}
        leftIcon={SvgSearch}
        size="sm"
        {...props}
        ref={forwardedRef}
      />
    );
  }
);

SearchBar.displayName = "SearchBar";
