import { Icon } from "~/components/Icon";
import { SvgChevronDown } from "~/components/Icon/icons";
import React, { ComponentProps, forwardRef } from "react";
import { styled } from "~/application/theme";

export interface SelectButtonProps
  extends ComponentProps<typeof SelectButtonRoot> {
  useScroll?: boolean;
}

const SelectMenuIndicator = styled(Icon, {
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

const SelectButtonRoot = styled("button", {
  // Reset
  boxSizing: "border-box",
  display: "inline-flex",
  userSelect: "none",

  // Custom
  position: "relative",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "$fast",
  outline: "none",
  gap: "$2",
  backgroundColor: "$neutrals-white",
  fontSize: "$md",
  color: "$neutrals-dark",
  fill: "$neutrals-darkest",
  fontFamily: "$default",
  fontWeight: 500,
  border: "none",
  borderRadius: "$md",

  "&::after": {
    content: "",
    pointerEvents: "none",
    position: "absolute",
    margin: 0,
    inset: 0,
    borderColor: "$neutrals-light",
    borderRadius: "inherit",
    borderStyle: "solid",
    borderWidth: "1px",
    transition: "$faster",
  },

  "&:hover": {
    cursor: "pointer",
    color: "$neutrals-black",
    fill: "$neutrals-black",
  },

  "&:disabled": {
    pointerEvents: "none",
    opacity: "$disabled",
  },

  variants: {
    size: {
      sm: {
        height: "3.75rem",
        px: "$4",
      },
      md: {
        height: "4.25rem",
        px: "$6",
      },
    },
  },

  defaultVariants: {
    size: "sm",
  },
});

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const SelectButton = forwardRef<
  React.ElementRef<typeof SelectButtonRoot>,
  SelectButtonProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <SelectButtonRoot type="button" {...props} ref={forwardedRef}>
      {children}
      <SelectMenuIndicator as={SvgChevronDown} size="sm" />
    </SelectButtonRoot>
  );
});
