import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type BoxProps = ComponentProps<typeof Box>;

export const Box = styled("div", {
  // Reset
  boxSizing: "border-box",
  variants: {
    mode: {
      show: {
        backgroundColor: "$primary-base",
      },
      hide: {
        display: "none",
      },
      align: {
        margin: "0 auto",
        padding: "$3",
      },
    },
  },
});
