import { Card } from "~/components/Card";
import { DialogHeader } from "~/components/Dialog";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgNotBenefit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { H5 } from "~/components/Typography";
import { Button } from "../Button";

export interface CancelDialogProps {
  title: string;
  children?: React.ReactNode;
  loadingMessage?: string;
  cancelTitle?: string;
  onConfirm: () => void;
  onCloseClick?: () => void;
  textCancelation?: string;
  svg: React.FC;
  showSvg?: boolean;
}

export function CancelDialog({
  title,
  children,
  textCancelation,
  onConfirm,
  onCloseClick,
  svg,
  showSvg = true,
}: CancelDialogProps) {
  return (
    <Card
      css={{
        boxShadow: "$sm",
        maxHeight: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "$8",
        px: "$10",
        gap: "$6",
        textAlign: "center",
        maxWidth: "670px",
        position: "relative",
      }}
    >
      <IconButton
        disabled
        size="4xl"
        css={{
          height: "fit-content",
          fill: "transparent",
        }}
      >
        <Icon
          as={svg}
          css={{
            stroke: "$primary-base",
          }}
        />
      </IconButton>
      <IconButton
        css={{
          position: "absolute",
          right: "$10",
          stroke: "$primary-base",
        }}
        onClick={onCloseClick}
      >
        <Icon as={SvgClose} />
      </IconButton>
      <DialogHeader
        css={{
          borderBottom: "none",
          p: "0",
        }}
      >
        <H5
          css={{
            maxWidth: "400px",
          }}
        >
          {title}
        </H5>
      </DialogHeader>
      {children}
      <Button
        variant="primary"
        css={{
          px: "$8",
          [`& ${Icon}`]: {
            size: "$7",
          },
        }}
        onClick={onConfirm}
      >
        {showSvg && <Icon as={SvgNotBenefit} />}
        {textCancelation}
      </Button>
    </Card>
  );
}

CancelDialog.displayName = "CancelDialog";
