// This file contains the basic implementation of a
// component with the on-off behavior.

import React, { forwardRef, useState, type ComponentProps } from "react";
import { styled } from "~/application/theme";

type SwitchBaseComponentProps = {
  isChecked: boolean;
  isDisabled: boolean;
};

export type SwitchBaseInputProps = Omit<ComponentProps<typeof SwitchBaseInput>, "size">;

export type SwitchBaseBaseProps = ComponentProps<typeof SwitchBaseRoot>;

export type SwitchBaseProps = SwitchBaseInputProps & {
  inputRef?: (element: any) => void;
  render: (props: SwitchBaseComponentProps) => React.ReactElement;
};

const SwitchBaseRoot = styled("span", {
  // Reset
  boxSizing: "border-box",
  
  // Custom
  position: "relative",
  display: "inline-flex",
  outline: 0,
  border: 0,
  margin: 0,
  userSelect: "none",
  verticalAlign: "middle",
  cursor: "pointer",
  alignItems: "center",
  gap: "$2",

  "&[data-disabled=true]": {
    pointerEvents: "none",
    cursor: "default",
  }
});

export const SwitchBaseInput = styled("input", {
  cursor: "inherit",
  position: "absolute",
  opacity: 0, 
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  zIndex: 1,
});

export const SwitchBase = forwardRef<
  React.ElementRef<typeof SwitchBaseRoot>,
  SwitchBaseProps
>(({
  id,
  autoFocus,
  checked: checkedProp,
  defaultChecked,
  disabled,
  name,
  onChange,
  onBlur,
  onFocus,
  readOnly,
  required,
  tabIndex,
  type,
  value,
  inputRef,
  children,
  render,
  ...props
}, forwardedRef) => {
  const [checkedState, setCheckedState] = useState(defaultChecked);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.nativeEvent.defaultPrevented) return;

    const newChecked = event.target.checked;

    setCheckedState(newChecked);

    if (onChange) onChange(event);
  };

  const checked = checkedProp ?? checkedState;

  return (
    <SwitchBaseRoot
      data-checked={checked}
      data-disabled={disabled}
      ref={forwardedRef}
      {...props}
    >
      <SwitchBaseInput
        id={id}
        autoFocus={autoFocus}
        checked={checkedProp}
        defaultChecked={defaultChecked}
        disabled={disabled}
        name={name}
        onChange={handleInputChange}
        onBlur={onBlur}
        onFocus={onFocus}
        readOnly={readOnly}
        required={required}
        tabIndex={tabIndex}
        type={type}
        value={value}
        ref={inputRef}
      />
      {render({
        isChecked: !!checked,
        isDisabled: !!disabled
      })}
      {children}
    </SwitchBaseRoot>
  );
});

SwitchBase.displayName = "SwitchBase";
