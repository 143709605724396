import { SettingParameter } from "~/application/types/entities/SettingParameter.type";
import { SettingParameterDTO } from "../dtos/SettingParameterDTO";

export function mapSettingParameterDTO(data: SettingParameterDTO): SettingParameter {
  return {
    uuid: data.uuid,
    slug: data.slug,
    description: data.description,
    type: data.type,
    value: data.value,
    typeValue: data.type_value,
  };
}
