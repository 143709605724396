import { MarkupPerSuplier } from "~/application/types";
import { MarkupPerSuplierDTO } from "../dtos/MarkupPerSuplierDTO";

export const mapMarkupPerSuplier = (
  data: MarkupPerSuplierDTO[]
): MarkupPerSuplier[] => {
  return data.map((markupPerSuplier) => {
    return {
      uuid: markupPerSuplier.uuid,
      name: markupPerSuplier.name,
      slug: markupPerSuplier.slug,
      services: markupPerSuplier.services.map((service) => {
        return {
          providerServiceUuid: service.provider_service_uuid,
          serviceType: service.service_type,
          percentValue: Number(service.percent_value),
        };
      }),
    };
  });
};
