import { OrderRoadItem, OrderRoadSegment, Road } from "~/application/types";
import {
  DateFormats,
  format,
  timestampInterval,
} from "~/application/utils/date-functions";
import { RoadInfo } from "./types";

export function formatRoadInfo(road: Road): RoadInfo {
  return {
    road: road,
    roadDuration: timestampInterval(road.departureDate, road.arrivalDate),
    departureRoadInfo: {
      name: road.from,
      dateTimeHour12: format(road.departureDate, DateFormats.SMALL_TIME),
      date: format(road.departureDate, DateFormats.LONG_DATE),
    },
    arrivalRoadInfo: {
      name: road.to,
      dateTimeHour12: format(road.arrivalDate, DateFormats.SMALL_TIME),
      date: format(road.arrivalDate, DateFormats.LONG_DATE),
    },
  };
}

export function getRoadSeats(road: OrderRoadItem | OrderRoadSegment): string[] {
  return road.travelers.reduce((seats, traveler) => {
    return seats.concat(
      traveler.seats.filter((s) => !s.isConnection).map((s) => s.seatNumber)
    );
  }, [] as string[]);
}

export function getRoadConnectionSeats(
  road: OrderRoadItem | OrderRoadSegment
): string[] {
  return road.travelers.reduce((seats, traveler) => {
    return seats.concat(
      traveler.seats.filter((s) => s.isConnection).map((s) => s.seatNumber)
    );
  }, [] as string[]);
}
