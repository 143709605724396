import * as React from "react";
import { SVGProps } from "react";

const SvgLaundry = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 20 20" width="24px" {...props}>
    <path d="M5.75 17.583q-.562 0-.948-.395-.385-.396-.385-.938V3.75q0-.542.395-.937.396-.396.938-.396h8.5q.562 0 .948.396.385.395.385.937v12.5q0 .542-.395.938-.396.395-.938.395Zm0-1.083h8.5q.083 0 .167-.083.083-.084.083-.167V3.75q0-.083-.083-.167-.084-.083-.167-.083h-8.5q-.083 0-.167.083-.083.084-.083.167v12.5q0 .083.083.167.084.083.167.083ZM10 14.688q1.312 0 2.25-.938t.938-2.25q0-1.312-.938-2.25T10 8.312q-1.312 0-2.25.938t-.938 2.25q0 1.312.938 2.25t2.25.938Zm0-1.188q-.396 0-.76-.146-.365-.146-.636-.437l2.813-2.813q.291.271.437.636.146.364.146.76 0 .833-.583 1.417-.584.583-1.417.583ZM7.25 6q.312 0 .531-.219Q8 5.562 8 5.25q0-.312-.219-.531Q7.562 4.5 7.25 4.5q-.312 0-.531.219-.219.219-.219.531 0 .312.219.531Q6.938 6 7.25 6Zm2.5 0q.312 0 .531-.219.219-.219.219-.531 0-.312-.219-.531-.219-.219-.531-.219-.312 0-.531.219Q9 4.938 9 5.25q0 .312.219.531Q9.438 6 9.75 6ZM5.5 16.5v-13 13Z" />
  </svg>
);

export default SvgLaundry;
