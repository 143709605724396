import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { ApiError, ExpenseType, PaginatedResource } from "~/application/types";
import {
  expenseCategoryService,
  expenseTypeService,
} from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { ExpenseTypeDialog } from "~/presentation/shared/views/ExpenseTypeDialog";
import { InactivateDialog } from "~/presentation/shared/views/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";

const LOG_TAG = "CustomerSettings/ExpenseTypesPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar tipos de despesas",
  CREATE_SUCCESS_MESSAGE: "Tipo de despesa criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar tipo de despesa",
  UPDATE_SUCCESS_MESSAGE: "Tipo de despesa atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar tipo de despesa",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar tipo de despesa",
  ACTIVATE_SUCCESS_MESSAGE: "Tipo de despesa ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar tipo de despesa",
  INACTIVATE_SUCCESS_MESSAGE: "Tipo de despesa inativado",
} as const;

export function useExpenseTypesPage() {
  const { contexts } = useUser();

  const customerId = contexts.customer.uuid;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching } = useQuery<
    PaginatedResource<ExpenseType[]>,
    ApiError
  >(
    [QueryKeys.CUSTOMER_EXPENSE_TYPES, customerId, currentPage],
    () => expenseTypeService.find({ page: currentPage, customerId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const fetchExpenseCategories = useCallback(
    () =>
      expenseCategoryService
        .find({})
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const { mutate: handleCreateExpenseType } = useMutation(
    (item: ExpenseType) => expenseTypeService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating ExpenseType(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created ExpenseType(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_TYPES]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: handleUpdateExpenseType } = useMutation(
    (item: ExpenseType) => expenseTypeService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ExpenseType(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ExpenseType(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_TYPES]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: ExpenseType) => expenseTypeService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ExpenseType(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ExpenseType(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_TYPES]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: ExpenseType) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog onConfirm={() => mutateToggleState(item)} />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <ExpenseTypeDialog
        onSubmit={handleCreateExpenseType}
        fetchExpenseCategories={fetchExpenseCategories}
      />
    );
  }, [handleCreateExpenseType, fetchExpenseCategories]);

  const handleOpenEditModal = useCallback(
    (item: ExpenseType) => {
      dialogService.showDialog(
        <ExpenseTypeDialog
          data={item}
          onSubmit={handleUpdateExpenseType}
          fetchExpenseCategories={fetchExpenseCategories}
        />
      );
    },
    [handleUpdateExpenseType, fetchExpenseCategories]
  );

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page ?? 0,
    onGoToPage: setCurrentPage,
    onCreateExpenseType: handleOpenCreateModal,
    onEditExpenseType: handleOpenEditModal,
    onToggleExpenseTypeState: handleToggleState,
  };
}
