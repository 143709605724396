import { useMutation } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { Order, OrderAdvance, TravelerAdvance } from "~/application/types";
import { orderAdvanceService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { AdvanceDialog } from "../../../../components/AdvanceDialog";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { logError } from "~/presentation/shared/utils/errors";

export interface UseAdvanceItemResult {
  isAdvanceItemExpanded: boolean;
  onAddOrderAdvance: () => void;
  toggleAdvanceItemVisible: () => void;
}

export interface UseAdvanceItemOptions {
  order?: Order;
  orderId: string;
  enabled: boolean;
}

const LOG_TAG = "Order/OrderPage/useAdvanceItem";

const SNACKBAR_MESSAGES = {
  LOAD_ADVANCE_ERROR_MESSAGE: "Falha ao carregar item adiantamentos",
  CREATE_ADVANCE_SUCCESS_MESSAGE: "Adiantamento adicionado ao pedido",
  CREATE_ADVANCE_ERROR_MESSAGE: "Falha ao adicionar adiantamento",
} as const;

export function useAdvanceItem({
  order,
  orderId,
  enabled, // eslint-disable-line @typescript-eslint/no-unused-vars
}: UseAdvanceItemOptions): UseAdvanceItemResult {
  const [isAdvanceItemExpanded, setAdvanceItemExpanded] = useState(true);

  const toggleAdvanceItemVisible = useCallback(() => {
    setAdvanceItemExpanded((old) => !old);
  }, []);

  const { mutate: mutateCreateOrderAdvance } = useMutation(
    (item: OrderAdvance) => orderAdvanceService.append(orderId, item.travelers),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message="Criando adiantamento" />
        );
      },
      onSuccess: (_, item) => {
        log.i(
          LOG_TAG,
          `Successfully created OrderAdvance(orderId: ${item.orderId})`
        );

        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ADVANCE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ADVANCE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const handleOpenCreateAdvanceModal = useCallback(() => {
    dialogService.showDialog(
      <AdvanceDialog
        isNew={false}
        defaultData={{
          travelers: order?.travelers.map(
            (traveler) =>
              ({
                traveler: traveler,
                description: "",
              } as TravelerAdvance)
          ),
        }}
        onSubmit={mutateCreateOrderAdvance}
        fetchTravelers={() => Promise.resolve(order?.travelers || [])}
      />
    );
  }, [order, mutateCreateOrderAdvance]);

  return {
    isAdvanceItemExpanded,
    onAddOrderAdvance: handleOpenCreateAdvanceModal,
    toggleAdvanceItemVisible,
  };
}
