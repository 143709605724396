import { DeepPartial, Flight, FlightOption } from "~/application/types";
import { ReturnFlightType } from "../../ui/sections/ReturnFlightsSection";

export type useAirwayBudgetResult = {
  activeBudget: boolean;
  onActiveBudget: (active: boolean) => void;
  state: AirwayBudgetState;
  dispatch?: React.Dispatch<AirwayBudgetDispatchAction>;
  onSelectBudget: (
    data: FlightOption,
    flight?: Flight,
    types?: "departure" | "arrival"
  ) => void;
};

export type AirwayBudgetState = {
  departure: DeepPartial<Flight[]>;
  arrival: DeepPartial<Flight[]>;
};

export enum AirwayBudgetDispatchType {
  CLEAR = "CLEAR",
  DEPARTURE = "DEPARTURE",
  ARRIVAL = "ARRIVAL",
}

export type AirwayBudgetDispatchAction =
  | {
      type: AirwayBudgetDispatchType.DEPARTURE;
      paylod: DeepPartial<Flight[]>;
    }
  | {
      type: AirwayBudgetDispatchType.ARRIVAL;
      paylod: DeepPartial<Flight[]>;
    }
  | {
      type: AirwayBudgetDispatchType.CLEAR;
    };
