import { FC } from "react";
import { Text } from "~/components/Text";
import { Styled } from "./styled";
import { ListItemProps } from "./types";

export const ListItem: FC<ListItemProps> = ({
  title,
  description,
  isSelected,
  onClick,
}) => {
  return (
    <Styled.Container data-selected={isSelected} onClick={onClick}>
      <Styled.Title size="3" as="p">
        {title}
      </Styled.Title>

      {description && (
        <Text variant="primary-dark" size="2">
          {description}
        </Text>
      )}
    </Styled.Container>
  );
};

ListItem.displayName = "ListItem";
