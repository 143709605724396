import React, { ComponentProps } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { styled } from "~/application/theme";

export type PopoverContentProps = ComponentProps<typeof PopoverPrimitive.Content>;

export const Popover = styled(PopoverPrimitive.Root, {});

export const PopoverTrigger = styled(PopoverPrimitive.Trigger, {});

export const PopoverAnchor = styled(PopoverPrimitive.Anchor, {});

export const PopoverClose = PopoverPrimitive.Close;

const StyledPopoverContent = styled(PopoverPrimitive.Content, {
  zIndex: "$dropdown",
});

export const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps
>(({ children, ...props }, forwardedRef) => (
  <StyledPopoverContent align="start" sideOffset={8} {...props} ref={forwardedRef}>
    {children}
  </StyledPopoverContent>
));
