import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FormDialog } from "~/components/FormDialog";
import { Text } from "~/components/Text";
import { AirwayIssuanceForm } from "./AirwayIssuanceForm";
import { AirwayIssuanceDialogProps, AirwayIssuanceFormData } from "./types";
import { createFormData, parseFormData } from "./utils";

export const AirwayIssuanceDialog: FC<AirwayIssuanceDialogProps> = ({
  orderAirway,
  onCloseClick,
  onSubmit: onSubmitProp,
}) => {
  const { control, formState, handleSubmit } = useForm<AirwayIssuanceFormData>({
    defaultValues: createFormData(orderAirway),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const onSubmit = useCallback(
    async (formData: AirwayIssuanceFormData) => {
      const data = parseFormData(formData);

      return await onSubmitProp(data);
    },
    [onSubmitProp]
  );

  return (
    <Container size={{ "@initial": "8", "@mxlg": "4" }} fixed>
      <Form
        css={{
          "@mxlg": {
            width: "95%",
            margin: "0 auto",
          },
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormDialog
          title="Emissão de item de aéreo"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>Emitir</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <AirwayIssuanceForm control={control} orderAirway={orderAirway} />
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

AirwayIssuanceDialog.displayName = "AirwayIssuanceDialog";
