import { Markup } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { IconButton } from "~/components/IconButton";
import { RadioButton } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Text } from "~/components/Text";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface MarkupListItemProps extends DataListItemProps {
  data: Markup;
  checked: boolean;
  onToggleMarkup: (item: Markup) => void;
}

export function MarkupListItem({ data, checked, onToggleMarkup, ...props }: MarkupListItemProps) {
  const isMobile = useMobile();
  return (
    <DataListItem
      data-active={checked}
      {...props}
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {isMobile ? (
        <Flex css={{ width: "100%" }}>
          <RadioButton data-checked={checked} onClick={() => onToggleMarkup(data)} />
        </Flex>
      ) : (
        <>
          <RadioButton data-checked={checked} onClick={() => onToggleMarkup(data)} />
        </>
      )}

      <DataColItem headerTitle="Markup" data={data.name} />
      <DataColItem
        headerTitle="Serviços"
        data={
          <Flex align="center" gap="2">
            {data.items.map((item) => (
              <Flex align="center" gap="2" key={item.uuid}>
                <IconButton size="md">
                  <Icon as={OrderUtils.getServiceIcon(item.serviceType)} />
                </IconButton>
                <Text css={{ fw: "600" }}>{`${item.value}%`}</Text>
              </Flex>
            ))}
          </Flex>
        }
      />
    </DataListItem>
  );
}

MarkupListItem.displayName = "MarkupListItem";
