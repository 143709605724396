import { useContext } from "react";
import { FormControlContext } from "../../FormControl/FormControlContext";
import {
  DEFAULT_COMPONENTS,
  SelectBaseProps,
} from "../base/SelectWrapBase/SelectBaseWrap";
import { SelectChip, SelectChipRemove } from "./SelectWrapChip";
import { SelectBaseWrap } from "../base/SelectWrapBase/SelectBaseWrap";

export type MultiSelectWrapProps<TOption = unknown> = SelectBaseProps<TOption>;

const components = {
  ...DEFAULT_COMPONENTS,
  MultiValue: SelectChip,
  MultiValueRemove: SelectChipRemove,
};

export const MultiSelectWrap = <TOption,>({
  name: nameProps,
  disabled: disabledProps,
  onBlur: onBlurProps,
  onChange: onChangeProps,
  value: valueProps,
  ...props
}: MultiSelectWrapProps<TOption>) => {
  const formControl = useContext(FormControlContext);

  const name = nameProps ?? formControl.name;
  const disabled = disabledProps ?? formControl.disabled;
  const onBlur = onBlurProps ?? formControl.onBlur;
  const onChange = onChangeProps ?? formControl.onChange;
  const value = valueProps ?? formControl.value;
  const isDirty = !!formControl.error;
  
  return (
    <SelectBaseWrap
      {...props}
      isMulti
      name={name}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      isDirty={isDirty}
      components={components}
    />
  );
};

MultiSelectWrap.displayName = "MultiSelectWrap";