import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

import { Select } from "~/components/Input";
import { AsyncSelectProps } from "./types";

export function AsyncSelect<T extends { uuid: string }>({
  queryKey,
  defaultValue,
  defaultOptions,
  selectedOptions,
  fetchOptions,
  getOptionValue,
  getOptionLabel,
  ...props
}: AsyncSelectProps<T>) {
  const [inputValue, setInputValue] = useState("");
  const [input] = useDebounce(inputValue, 700);

  const { data: searchOptions, isFetching: isLoadingSearchOptions } = useQuery(
    [queryKey, input],
    () => fetchOptions?.(input)
  );

  let options = defaultOptions;

  if (searchOptions?.length) {
    options = searchOptions;
  }

  const filterNotSelected = (option: T) => {
    if (!selectedOptions || !selectedOptions.length) return true;

    const selected = selectedOptions.find((item) => option?.uuid === item?.uuid);

    return !selected;
  };

  const currentOptions = options?.filter(filterNotSelected);

  return (
    <Select
      {...props}
      defaultValue={defaultValue}
      isLoading={isLoadingSearchOptions}
      options={currentOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onInputChange={(e) => {
        setInputValue(e);
        props.onInputChange?.(e);
      }}
    />
  );
}
