import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import type { OfflineHotelQuery } from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { toDate } from "~/utils/date.utils";
import { log } from "~/utils/log";

const LOG_TAG = "Booking/BookingHotel/useOfflineHotelParams";

export function useOfflineHotelParams(): OfflineHotelQuery | null {
  const [searchParams] = useSearchParams();
  const { contexts } = useUser();
  const customerId = contexts.customer?.uuid;

  const hotelParams = useMemo<OfflineHotelQuery | null>(() => {
    const obj = Object.fromEntries(searchParams);

    try {
      return {
        city: JSON.parse(obj.city),
        accommodations: JSON.parse(obj.accommodations).map((x: any) => ({
          ...x,
          travelers: [],
        })),
        checkInDate: toDate(obj.checkIn),
        checkOutDate: toDate(obj.checkOut),
        customerId,
        amenities: [],
        travelers: [],
        suggestion: "",
      };
    } catch (error) {
      log.w(LOG_TAG, error);

      return null;
    }
  }, [searchParams]);

  return hotelParams;
}
