import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type FooterProps = ComponentProps<typeof FooterRoot>;

const FooterRoot = styled("footer", {
  // Reset
  boxSizing: "border-box",

  // Custom
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  padding: "$4 0",
  borderTop: "1px solid $colors$neutrals-light",
  backgroundColor: "$neutrals-white",
  zIndex: "$navigationBar",

  "@mxxl": {
    zIndex: "revert",
  }
});

export const Footer = (props: FooterProps) => {
  return <FooterRoot {...props} />;
};

Footer.displayName = "Footer";
