import { OrderTaxCancellation } from "~/application/types";
import { OrderTaxCancellationDTO } from "../dtos";

export function mapOrderTaxCancellation(
  data: OrderTaxCancellationDTO
): OrderTaxCancellation {
  return {
    road: data.road.map((item) => ({
      uuid: item.uuid,
      value: item.value,
      allowsCancellation: item.allows_cancellation,
    })),
  };
}
