import { Passenger } from "~/application/types";

export type AirwaySeatsReducerState = {
  column: string;
  row: string;
  stretchId: string;
  passenger?: Passenger;
  value: number;
  selected: boolean;
  departure: string;
  arrival: string;
};

export enum AirwaySeatActionTypes {
  SELECT = "SELECT",
  UNSELECT = "UNSELECT",
  CLEAR = "CLEAR",
}

export type AirwaySeatsReducerAction = {
  type: AirwaySeatActionTypes;
  payload: AirwaySeatsReducerState;
};

export type AirwaySeatsReducerDispatch = (
  action: AirwaySeatsReducerAction
) => void;
