import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { SwitchBase } from "../base/SwitchBase";
import { createSwitchHOC } from "./createSwitchHOC";
import { SwitchButton } from "./SwitchButton";

export type SwitchProps = ComponentProps<typeof Switch>;

const SwitchRoot = styled(SwitchBase, {
  // Custom
  width: 'fit-content',
  zIndex: 0,
  [`& > *:focus + ${SwitchButton}`]: {
    boxShadow: "$focus-sm",
  },
});

export const Switch = createSwitchHOC(SwitchRoot);

Switch.displayName = "Switch";
