import { useUser } from "~/presentation/core/contexts/UserContext";
import { PurchasingPoliciesContainer } from "./PurchasingPoliciesContainer";
import { usePurchansingPolicies } from "./hooks/usePurchansingPolicies";
import { useReasonTrips } from "~/core/modules/Customer/pages/CustomerPage/hooks/useReasonTrips";
import { useState } from "react";
import { ReasonTrip } from "~/application/types";

export const PurchasingPoliciesPage = ({ customer }: { customer?: string }) => {
  const { contexts, user } = useUser();

  const customerId = contexts?.customer?.uuid || user?.customer?.uuid || (customer as string);

  const [reasonSelected, setReason] = useState<ReasonTrip>();

  const purchasingPoliciesState = usePurchansingPolicies({
    customerId,
  });

  const { isReasonTripPolicy } = purchasingPoliciesState;

  const {
    data: reasonTrip,
    isLoading: isLoadingReasonTrip,
    fetchReasonTrip,
  } = useReasonTrips({
    customerId,
    enabled: isReasonTripPolicy,
  });

  return (
    <PurchasingPoliciesContainer
      {...purchasingPoliciesState}
      reasonTrip={reasonTrip}
      isLoadingReasonTrip={isLoadingReasonTrip}
      fetchReasonTrip={fetchReasonTrip}
      onReason={setReason}
      reasonSelected={reasonSelected}
    />
  );
};
