import { states } from "~/application/data";
import { Customer } from "~/application/types";
import { CustomerDTO } from "../dtos";
import { mapSettingParameterDTO } from "./mapSettingParameterDTO";

export function mapCustomerDTO(data: CustomerDTO): Customer {
  const state = states.find((s) => s.state === data.state)!;

  return {
    uuid: data.uuid,
    companyName: data.company_name,
    tradingName: data.trading_name,
    cnpj: data.cnpj,
    stateRegistration: data.state_registration,
    wintourCode: data.wintour_code,
    phone: data.phone,
    email: data.email,
    integrationId: data.integration_id,
    flightManager: data.flight_manager,
    zipCode: data.zip_code,
    additionalInfo: data.additional_info,
    issuers: data.issuers,
    approvers: data.approvers,
    address: data.address,
    number: data.number,
    district: data.district,
    state: state,
    city: {
      uuid: data.city_uuid,
      name: data.city,
      state: state?.state,
      country: "",
    },
    dashboardLink: data.dashboard_link,
    settingsParameters: (data.setting_parameters ?? []).map(mapSettingParameterDTO),
    invoiceLimit: Number(data.invoice_limit),
    isActive: Boolean(data.is_active),
    fraudBlocking: Boolean(data.fraud_blocking),
    agency: {
      uuid: data.agency?.uuid,
      name: data.agency?.name,
      email: data.agency?.email,
      phone: data.agency?.phone,
    },
    markupId: data.markup_uuid,
    feeId: data.fee_uuid,
    markdownId: data.markdown_uuid,
    domains: data.domains?.map((domain) => ({ domain })),
  };
}
