import { FC, useMemo } from "react";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { formatFlightInfo } from "../../utils";
import { TrackDots } from "../Track";
import { ViolatedPoliciesButton } from "../ViolatedPoliciesButton/ViolatedPoliciesButton";
import { FlightReadListItemProps } from "./types";

export const FlightReadListItem: FC<FlightReadListItemProps> = ({ data, onOpenDetails }) => {
  const flightInfo = useMemo(() => data && formatFlightInfo(data), [data]);

  return (
    <Card>
      {/* Header */}
      <Flex align="center" gap="4" css={{ p: "$6" }}>
        <Box css={{ flexGrow: "1" }}>
          <CompanyLogo
            src={`/images/airlines/${flightInfo.flight.airline.toLowerCase()}.png`}
            size="lg"
          />
        </Box>

        {/*{tags}*/}
        <Flex align="center" gap="2">
          {!!data.violatedPolicies?.length && (
            <ViolatedPoliciesButton data={data.violatedPolicies} />
          )}
          <Button
            variant="tertiary"
            size="sm"
            css={{
              "@mxlg": {
                p: 4,
                height: "$6",
              },
            }}
            onClick={() => onOpenDetails(flightInfo.flight)}
          >
            <Text
              css={{
                "@mxlg": {
                  fontSize: "small",
                },
              }}
            >
              Ver detalhes
            </Text>
          </Button>
        </Flex>
      </Flex>

      {/* Ticket */}
      {flightInfo && (
        <Flex gap="6" justify={{ "@mxlg": "around" }} css={{ p: "$6" }}>
          <Flex direction="column" gap="4">
            <Caption>Saída</Caption>
            <Flex direction="column" gap="2">
              <Text size="5" css={{ fw: 600 }}>
                {flightInfo.departureFlightInfo.airportIata}
              </Text>
              <Text size="3">{flightInfo.departureFlightInfo.airport}</Text>
            </Flex>
            <Text
              size="3"
              css={{
                "@mxlg": {
                  display: "none",
                },
              }}
            >
              {flightInfo.departureFlightInfo.date}
            </Text>
            <Caption css={{ fw: 500 }}>{flightInfo.departureFlightInfo.dateTimeHour12}</Caption>
          </Flex>
          <Flex
            direction="column"
            align="center"
            justify="center"
            css={{
              "@lg": {
                display: "none",
              },
            }}
          >
            {data.segments.length > 1 ? (
              <>
                <TrackDots numberOfDots={data.segments.length - 1} />
                <Text css={{ mt: "$3" }}>{data.segments.length - 1} conexão</Text>
              </>
            ) : null}
          </Flex>
          <Flex direction="column" gap="4">
            <Caption>Chegada</Caption>
            <Flex direction="column" gap="2">
              <Text size="5" css={{ fw: 600 }}>
                {flightInfo.arrivalInfo.airportIata}
              </Text>
              <Text size="3">{flightInfo.arrivalInfo.airport}</Text>
            </Flex>
            <Text
              size="3"
              css={{
                "@mxlg": {
                  display: "none",
                },
              }}
            >
              {flightInfo.arrivalInfo.date}
            </Text>
            <Caption css={{ fw: 500 }}>{flightInfo.arrivalInfo.dateTimeHour12}</Caption>
          </Flex>
        </Flex>
      )}
    </Card>
  );
};

FlightReadListItem.displayName = "FlightReadListItem";
