export function formatNumber(value: number, maxLength: number): string {
  return value.toString().padStart(maxLength, "0");
}

export function pluralizeWord(
  singularWord: string,
  pluralWord: string,
  count: number
): string {
  return count > 1 ? pluralWord : singularWord;
}

export function formatSentence(...items: [number, string, string][]): string {
  return items
    .reduce((prev, [quantity, singular, plural]) => {
      if (quantity > 0) {
        return [
          ...prev,
          `${quantity} ${pluralizeWord(singular, plural, quantity)}`,
        ];
      }
      return prev;
    }, [] as string[])
    .join(", ");
}

export function humanFileSize(bytes: number, si = true, decimalPoints = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** decimalPoints;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(decimalPoints) + " " + units[u];
}
