import { useLocation } from "react-router-dom";

import { BudgetTab } from "../views/Budgets/utils";

export function useBudgetTab(): BudgetTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(BudgetTab).includes(hash)) {
    return hash as BudgetTab;
  }

  return BudgetTab.BUDGETS;
}
