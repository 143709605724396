import { useCallback, useState } from "react";

export interface UseHotelItemResult {
  isHotelItemExpanded: boolean;
  toggleHotelItemVisible: () => void;
}

export function useHotelItem(): UseHotelItemResult {
  const [isHotelItemExpanded, setHotelItemExpanded] = useState(true);

  const toggleHotelItemVisible = useCallback(() => {
    setHotelItemExpanded((old) => !old);
  }, []);

  return {
    isHotelItemExpanded,
    toggleHotelItemVisible,
  };
}
