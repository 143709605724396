import { IFilterGenerator, IFilterGroup, IFilterOption } from "./types";

/**
 * This functions create filter groups extracting group and item keys.
 */
export function createFilterGroups<TData>(
  data: TData[],
  filterEntries: IFilterGenerator<TData>[]
): IFilterGroup<TData>[] {
  return filterEntries.map((filter) => {
    const generateFilter = (item: TData): IFilterOption<string> => {
      let generated = filter.generator(item);

      if (typeof generated === "string") {
        generated = {
          key: generated,
          label: generated,
          value: generated,
        };
      }

      return generated;
    };

    return {
      key: filter.key,
      label: filter.label,
      isValid: (item: TData, value: unknown) =>
        generateFilter(item).value === value,
      options: Object.values(
        data.reduce<{
          [s: string]: IFilterOption;
        }>((prev, item) => {
          const generated = generateFilter(item);

          if (!prev[generated.key]) prev[generated.key] = generated;

          return prev;
        }, {})
      ),
    };
  });
}
