import { Fragment } from "react";
import { AdvanceOrderStatus, ApprovalModel, Order } from "~/application/types";
import {
  AppBar,
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgPlus } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { userIsIssuer } from "~/core/modules/Order/utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { OrderTab } from "~/presentation/shared/utils";
import {
  TabTravelerExpense,
  TabTravelerExpenseProps,
} from "../TabTravelerExpenses";
import { useTabAccountabilityExpenses } from "../hooks/useTabAccountabilityExpenses";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface AccountabilityExpenseContainerProps {
  travelerId: string;
  isLoading: boolean;
  activeTab: OrderTab;
  order?: Order;
  fetchApprovalModels: () => Promise<ApprovalModel[]>;
}

export function AccountabilityExpenseContainer({
  order,
  travelerId,
  isLoading,
  activeTab,
}: AccountabilityExpenseContainerProps) {
  const getTitle = () => {
    if (isLoading) {
      return <Skeleton variant="text-6" style={{ maxWidth: 360 }} />;
    }

    return "Despesas";
  };

  const title = getTitle();
  const isMobile = useMobile();

  const {
    expenses,
    onShowVoucher,
    isLoadingExpenses,
    isLoadingRequestApproval,
    handleRequestApproval,
    onCreateAccountabilityExpense,
    onEditAccountabilityExpense,
    onDeleteAccountabilityExpense,
    fetchApprovalModels,
    isSuccessfulRequestApproval,
    cartSummary,
  } = useTabAccountabilityExpenses({ order });

  const expensesOfTraveler = (expenses || []).filter(
    ({ advanceOrder }) => advanceOrder?.traveler.uuid === travelerId
  );

  const advanceOrder = expensesOfTraveler?.at(0)?.advanceOrder;

  const tabTravelerExpenseProps = {
    data: expensesOfTraveler,
    order,
    isLoading: isLoadingExpenses,
    isLoadingRequestApproval,
    cartSummary,
    isSuccessfulRequestApproval,
    onShowVoucher,
    onEditAccountabilityExpense,
    fetchApprovalModels,
    onDeleteAccountabilityExpense,
    onRequestApproval: handleRequestApproval,
  } as TabTravelerExpenseProps;

  const isAbleToAddNewExpense = () => {
    if (isSuccessfulRequestApproval) {
      return false;
    }

    const statuses = [AdvanceOrderStatus.OPEN, AdvanceOrderStatus.DISAPPROVED];
    return statuses.includes(
      (advanceOrder?.status || AdvanceOrderStatus.OPEN) as AdvanceOrderStatus
    );
  };

  const canAddNewExpense = isAbleToAddNewExpense();
  const { user } = useUser();

  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
              <Link to={`/pedidos/${order?.uuid}#despesas`}>
                <IconButton size="md">
                  {" "}
                  <Icon
                    as={SvgChevronLeft}
                    css={{
                      "@mxlg": {
                        fill: "#fff",
                      },
                    }}
                  />{" "}
                </IconButton>
              </Link>
            </Tooltip>

            <AppBarHeading title={title} />

            {canAddNewExpense && (
              <AppBarActions>
                <Button
                  onClick={() =>
                    onCreateAccountabilityExpense(
                      userIsIssuer(user, order)
                        ? (advanceOrder?.traveler.uuid as string)
                        : travelerId
                    )
                  }
                  variant="tertiary"
                >
                  {!isMobile ? (
                    <Text>Adicionar nova despesa</Text>
                  ) : (
                    <Icon as={SvgPlus} />
                  )}
                </Button>
              </AppBarActions>
            )}
          </AppBarContainer>
        </AppBar>

        <Container
          css={{
            py: "$10",
            "@mxlg": {
              px: "$5",
            },
          }}
        >
          <TabTravelerExpense {...tabTravelerExpenseProps} />
        </Container>
      </Tabs>
    </Fragment>
  );
}
