import * as React from "react";
import { SVGProps } from "react";

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M5 4.375a.625.625 0 0 0-.441.184.625.625 0 0 0 0 .882L9.117 10 4.56 14.559a.625.625 0 0 0 0 .882.625.625 0 0 0 .882 0L10 10.883l4.559 4.558a.625.625 0 0 0 .882 0 .625.625 0 0 0 0-.882L10.883 10l4.558-4.559a.625.625 0 0 0 0-.882.625.625 0 0 0-.882 0L10 9.117 5.441 4.56A.625.625 0 0 0 5 4.375Z"
    />
  </svg>
);

export default SvgClose;
