import type { CustomerFee } from "~/application/types";
import { api } from "~/infrastructure/api";
import { CustomerFeeDTO } from "~/infrastructure/api/dtos";
import type { ICustomerFeeService } from "./ICustomerFeeService";

export class CustomerFeeService implements ICustomerFeeService {
  async update(data: CustomerFee): Promise<void> {
    return await api
      .patch<undefined, CustomerFeeDTO>("/customers/fee", {
        customer_uuid: data.customerId,
        fee_uuid: data.feeId,
      })
      .then(({ data }) => data);
  }
}
