import * as React from "react";
import { SVGProps } from "react";

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M9.063 1.756c-3.963 0-7.188 3.225-7.188 7.187 0 3.963 3.225 7.188 7.188 7.188 1.752 0 3.303-.705 4.552-1.752l3.444 3.443a.625.625 0 0 0 .882 0 .625.625 0 0 0 0-.883l-3.443-3.443c1.047-1.249 1.752-2.8 1.752-4.553 0-3.962-3.225-7.187-7.188-7.187zm0 1.25A5.928 5.928 0 0 1 15 8.943a5.918 5.918 0 0 1-1.734 4.198.625.625 0 0 0-.004.002.625.625 0 0 0-.002.004 5.918 5.918 0 0 1-4.198 1.734 5.928 5.928 0 0 1-5.937-5.938 5.928 5.928 0 0 1 5.937-5.937z"
    />
  </svg>
);

export default SvgSearch;
