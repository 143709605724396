import {
  AirlineCode,
  ExpensePolicyTypeValue,
  Order,
  OrderAdvanceItem,
  OrderAirwaySegment,
  OrderHotelItem,
  OrderItemStatus,
  OrderItems,
  OrderItemsWithChangedPrice,
  OrderRoadSegment,
  OrderStatus,
  OrderVehicle,
  TravelerAdvance,
} from "~/application/types";
import { MaskUtils } from "~/application/utils";
import { Alert } from "~/components/Alert";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FormDialog } from "~/components/FormDialog";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgAirplane, SvgBus, SvgCar, SvgExpenses, SvgHotels } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H6, Label } from "~/components/Typography";
import { CART_ORDER_STATUS_LABELS, ORDER_STATUS_LABELS } from "./constants";
import { UseConsultOrderItemsPriceChangeProps, UseConsultOrderItemsPriceResult } from "./types";
import { CompanyLogo } from "~/components/CompanyLogo";
import { getAirlineUrl } from "../airway-functions";

export function getServiceLabel(service: OrderItems): string {
  switch (service) {
    case OrderItems.AIRWAY:
      return "Aéreo";
    case OrderItems.HOTEL:
      return "Hotel";
    case OrderItems.VEHICLE:
      return "Carro";
    case OrderItems.ROAD:
      return "Rodoviário";
    case OrderItems.ADVANCE:
      return "Adiantamento";
    default:
      return service;
  }
}

export function getServiceLink(service: OrderItems): string | undefined {
  switch (service) {
    case OrderItems.AIRWAY:
      return "/busca/passagens-aereas";
    case OrderItems.HOTEL:
      return "/busca/hospedagens";
    case OrderItems.VEHICLE:
      return "/busca/alugar-carros";
    case OrderItems.ROAD:
      return "/busca/passagens-onibus";
  }
}

export function getServiceIcon(service: OrderItems | string): React.ElementType | undefined {
  switch (service) {
    case OrderItems.AIRWAY:
      return SvgAirplane;
    case OrderItems.HOTEL:
      return SvgHotels;
    case OrderItems.VEHICLE:
      return SvgCar;
    case OrderItems.ROAD:
      return SvgBus;
    case OrderItems.ADVANCE:
      return SvgExpenses;
  }
}

export function getOrderStatus(orderStatus: OrderStatus | OrderItemStatus): string {
  return ORDER_STATUS_LABELS[orderStatus as keyof typeof ORDER_STATUS_LABELS] ?? orderStatus;
}

export function getCartOrderStatus(orderStatus: OrderStatus | OrderItemStatus): string {
  return (
    CART_ORDER_STATUS_LABELS[orderStatus as keyof typeof CART_ORDER_STATUS_LABELS] ?? orderStatus
  );
}

export function getUnsatisfiedPolicyMessage(typeValue: ExpensePolicyTypeValue, value: number) {
  switch (typeValue) {
    case ExpensePolicyTypeValue.AMOUNT_MAXIMUM:
      return `A opção selecionada está acima da quantidade máxima máximo definido de ${value}.`;
    case ExpensePolicyTypeValue.TOUCHABLE_LESS:
      return `A opção selecionada está acima do teto máximo definido de ${MaskUtils.asCurrency(
        value
      )}.`;
    case ExpensePolicyTypeValue.UNTOUCHABLE:
      return `A opção selecionada não se enquadra no valor definido de ${MaskUtils.asCurrency(
        value
      )}.`;
    default:
      return "";
  }
}

export type GetOrderItemResult =
  | TravelerAdvance[]
  | OrderAirwaySegment[]
  | OrderHotelItem[]
  | OrderVehicle[]
  | OrderAdvanceItem[]
  | OrderRoadSegment[]
  | undefined;

function getOrderItem({ order, item }: { order?: Order; item: OrderItems }): GetOrderItemResult {
  switch (item) {
    case OrderItems.AIRWAY: {
      return order?.items.airway?.flights ?? ([] as any);
    }

    case OrderItems.HOTEL: {
      return order?.items.hotel?.rooms ?? ([] as any);
    }

    case OrderItems.ROAD: {
      return order?.items.road?.travels ?? ([] as any);
    }

    case OrderItems.VEHICLE: {
      return order?.items.vehicle?.vehicles ?? ([] as any);
    }

    case OrderItems.ADVANCE: {
      return order?.items.advance?.advances ?? [];
    }

    default:
      return undefined;
  }
}

export function isAirwayRejected(order?: Order): boolean {
  const orderAirwayData = getOrderItem({
    order,
    item: OrderItems.AIRWAY,
  }) as OrderAirwaySegment[];

  return orderAirwayData?.some(({ reasonRejected }) => reasonRejected) as boolean;
}

export function isHotelRejected(order?: Order): boolean {
  const orderHotelData = getOrderItem({
    order,
    item: OrderItems.HOTEL,
  }) as OrderHotelItem[];

  return orderHotelData?.some((hotel) => hotel.reasonRejected) as boolean;
}

export function isVehicleRejected(order?: Order): boolean {
  const orderVehicleData = getOrderItem({
    order,
    item: OrderItems.VEHICLE,
  }) as OrderVehicle[];
  return orderVehicleData?.some((vehicle) => vehicle.reasonRejected) as boolean;
}

export function isRoadRejected(order?: Order): boolean {
  const orderRoadData = getOrderItem({
    order,
    item: OrderItems.ROAD,
  }) as OrderRoadSegment[];

  return orderRoadData?.some((road) => road.reasonRejected) as boolean;
}

export function getAirwayFrom({ order }: { order?: Order }): OrderAirwaySegment[] {
  return getOrderItem({
    order,
    item: OrderItems.AIRWAY,
  }) as OrderAirwaySegment[];
}

export function getHotelFrom({ order }: { order?: Order }): OrderHotelItem[] {
  return getOrderItem({ order, item: OrderItems.HOTEL }) as OrderHotelItem[];
}

export function getVehicleFrom({ order }: { order?: Order }): OrderVehicle[] {
  return getOrderItem({
    order,
    item: OrderItems.VEHICLE,
  }) as OrderVehicle[];
}

export function getRoadFrom({ order }: { order?: Order }): OrderRoadSegment[] {
  return getOrderItem({
    order,
    item: OrderItems.ROAD,
  }) as OrderRoadSegment[];
}

export function getAdvanceFrom({ order }: { order?: Order }): TravelerAdvance[] {
  return getOrderItem({ order, item: OrderItems.ADVANCE }) as TravelerAdvance[];
}

export function shouldTagItemAsRejected({
  itemIsRejected,
  order,
}: {
  order?: Order;
  itemIsRejected?: boolean;
}): boolean {
  return !!itemIsRejected && order?.status === OrderStatus.REJECTED;
}

export function getTotalProviderFees(order?: Order): number {
  const roadTotalProviderTaxes = (order?.items.road?.travels || []).reduce(
    (total, { providerFees }) => total + providerFees,
    0
  );

  // TODO: add other order items provider fees

  return roadTotalProviderTaxes;
}

type ReasonRejected = {
  reason?: string;
  approver: string;
  date: string;
};

export function canMarkAsRejected({
  reasonRejected,
  order,
}: {
  reasonRejected?: ReasonRejected;
  order?: Order;
}): boolean {
  return !!reasonRejected && order?.status === OrderStatus.REJECTED;
}

export function consultOrderItemsPriceChange(
  props: UseConsultOrderItemsPriceChangeProps
): UseConsultOrderItemsPriceResult {
  const { negativeButton, positiveButton, order } = props;

  const onCloseClick = () => {
    dialogService.popDialog();
  };

  const onOrderItemsPriceChange = (orderItems: OrderItemsWithChangedPrice) => {
    const modal = (
      <Container size="8" fixed>
        <Form>
          <FormDialog
            withCloseButton={false}
            title="Uma atualização no valor"
            negativeButton={negativeButton}
            positiveButton={positiveButton}
            onClickDismissButton={onCloseClick}
          >
            <DialogBody css={{ p: "$6" }}>
              <Col>
                <Alert variant="info">
                  <Text fw={500} variant="primary-dark">
                    {!orderItems?.airway?.length
                      ? "Não se preocupe, isso acontece às vezes. Isso pode ocorrer pela disponibilidade de assentos e de outros fatores"
                      : `Informamos que houve uma atualização no valor da sua passagem. Não se preocupe, 
                      atualizações dos valores podem ocorrer devido a variações de preço ou disponibilidade da companhia aerea.`}
                  </Text>
                </Alert>

                {orderItems?.airway?.map(({ value, newValue, itemsUuid }, index) => {
                  const orderItems = order?.items.airway?.flights.filter((flight) => {
                    return itemsUuid.some((uuid) => flight?.uuid === uuid);
                  });

                  return (
                    <Cart css={{ mt: "$4" }} key={itemsUuid?.at(0)}>
                      <CartHeader>
                        <Flex
                          gap="4"
                          align="center"
                          justify="between"
                          css={{
                            width: "100%",
                          }}
                        >
                          <Flex
                            css={{
                              flex: "5",
                            }}
                            justify="center"
                            align="center"
                            gap="2"
                          >
                            <Flex
                              align="center"
                              key={orderItems?.at(0)?.uuid}
                              css={{
                                width: "fit-content",
                              }}
                              gap="3"
                            >
                              <Text>{orderItems?.at(0)?.departureAirport}</Text>
                              <Text>{" <-> "}</Text>
                              <Text>{orderItems?.at(0)?.arrivalAirport}</Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </CartHeader>

                      <CardBody>
                        <Flex
                          css={{
                            width: "100%",
                          }}
                          justify="between"
                        >
                          <Flex direction="column">
                            <Flex gap="2" align="center">
                              <Icon variant="dark" as={SvgExpenses} size="md" />

                              <Label>Valor anterior</Label>

                              <Text variant="darkest" size="2">
                                {MaskUtils.asCurrency(value)}
                              </Text>
                            </Flex>

                            <Flex gap="2" align="center">
                              <Icon variant="dark" as={SvgExpenses} size="md" />

                              <Label>Valor atual</Label>

                              <Text variant="darkest" as={H6}>
                                {MaskUtils.asCurrency(newValue as number)}
                              </Text>
                            </Flex>
                          </Flex>
                          <CompanyLogo
                            src={getAirlineUrl(
                              orderItems?.at(0)?.segments?.at(0)?.airline as AirlineCode
                            )}
                          />
                        </Flex>
                      </CardBody>
                    </Cart>
                  );
                })}
              </Col>
            </DialogBody>
          </FormDialog>
        </Form>
      </Container>
    );

    dialogService.showDialog(modal);
  };

  return {
    onOrderItemsPriceChange,
  };
}
