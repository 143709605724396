import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { ApiError, DeepPartial, PaginatedResource, ReasonTrip } from "~/application/types";
import { reasonTripService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { ReasonTripDialog } from "../views/ReasonTrip/components/ReasonTripDialog";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";

export interface UseReasonTripsResult {
  data?: ReasonTrip[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  onGoToPage: (value: number) => void;
  onCreateReasonTrip: () => void;
  onEditReasonTrip: (item: ReasonTrip) => void;
  onToggleReasonTripState: (item: ReasonTrip) => void;
  fetchReasonTrip: (reason: string) => Promise<ReasonTrip[]>;
}

export interface UseReasonTripsOptions {
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useReasonTrips";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar motivos de viagem",
  CREATE_SUCCESS_MESSAGE: "Novo motivo de viagem adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar motivo de viagem",
  UPDATE_SUCCESS_MESSAGE: "Motivo de viagem atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar motivo de viagem",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar motivo de viagem",
  ACTIVATE_SUCCESS_MESSAGE: "Motivo de viagem ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar motivo de viagem",
  INACTIVATE_SUCCESS_MESSAGE: "Motivo de viagem inativado",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<ReasonTrip> = {
  reason: "",
};

export function useReasonTrips({
  customerId,
  enabled,
}: UseReasonTripsOptions): UseReasonTripsResult {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching } = useQuery<PaginatedResource<ReasonTrip[]>, ApiError>(
    [QueryKeys.CUSTOMER_REASON_TRIPS, customerId, currentPage],
    () => reasonTripService.find({ page: currentPage, customerId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutateAsync: mutateCreateReasonTrip } = useMutation(
    async (item: ReasonTrip) => await reasonTripService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating ReasonTrip(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando novo motivo de viagem" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created ReasonTrip(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_REASON_TRIPS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutateAsync: mutateUpdateReasonTrip } = useMutation(
    async (item: ReasonTrip) => await reasonTripService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ReasonTrip(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando motivo de viagem" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ReasonTrip(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_REASON_TRIPS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const fetchReasonTrip = useCallback(
    async (reason: string) =>
      await reasonTripService
        .find({ reason, customerId })
        .then((data) => data.data.filter((reason) => reason.isActive)),
    [customerId]
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: ReasonTrip) => reasonTripService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ReasonTrip(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ReasonTrip(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_REASON_TRIPS]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: ReasonTrip) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando motivo de viagem"
        onConfirm={() => mutateToggleState(item)}
      />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <ReasonTripDialog isNew onSubmit={mutateCreateReasonTrip} defaultData={DEFAULT_FORM_DATA} />
    );
  }, [customerId]);

  const handleOpenEditModal = useCallback((item: ReasonTrip) => {
    dialogService.showDialog(
      <ReasonTripDialog onSubmit={mutateUpdateReasonTrip} defaultData={item} />
    );
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page || 0,
    onGoToPage: setCurrentPage,
    fetchReasonTrip,
    onCreateReasonTrip: handleOpenCreateModal,
    onEditReasonTrip: handleOpenEditModal,
    onToggleReasonTripState: handleToggleState,
  };
}
