import { FC, useEffect, useState } from "react";
import { AGENCY_CUSTOMER_ID } from "~/application/constants/storageKeys";
import { AgencyReportsPage } from "~/core/modules/Agency/pages/ReportsPage/ReportsPage";
import { CustomerReportsPage } from "~/presentation/CustomerSettings/pages/ReportsPage";
import { useUser } from "~/presentation/core/contexts/UserContext";

export function ReportsPage() {
  const { user } = useUser();
  const customerId = localStorage.getItem(AGENCY_CUSTOMER_ID);
  const [component, setComponent] = useState<JSX.Element>(
    <CustomerReportsPage />
  );

  useEffect(() => {
    if (user?.agency && !customerId) {
      return setComponent(<AgencyReportsPage />);
    }
    setComponent(<CustomerReportsPage />);
  }, [customerId]);

  return component;
}
