import * as React from "react";
import { SVGProps } from "react";

const SvgCustomers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.165 16.5v-1.667a3.333 3.333 0 0 0-3.333-3.333H4.165a3.333 3.333 0 0 0-3.333 3.333V16.5m18.333 0v-1.667a3.334 3.334 0 0 0-2.5-3.225m-3.333-10a3.333 3.333 0 0 1 0 6.459m-2.5-3.234a3.333 3.333 0 1 1-6.667 0 3.333 3.333 0 0 1 6.667 0Z"
      stroke="#17B26A"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCustomers;
