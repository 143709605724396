import { HotelDetails } from "~/application/types";
import { HotelDetailsDTO } from "../dtos";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapHotelDetailsDTO(data: HotelDetailsDTO): HotelDetails {
  return {
    hotelId: data.hotelId,
    hotelImage: data.hotelImage,
    amenities: data.amenityies,
    description: data.description,
    images: data.images,
    name: data.name,
    address: {
      cityName: data.address.cityName,
      state: data.address.state,
      country: data.address.country,
      street: data.address.street,
      quarter: data.address.quarter,
      zipCode: data.address.zipCode,
      number: data.address.number,
      complement: data.address.complement,
      latitude: data.address.latitude,
      longitude: data.address.longitude,
    },
    rooms: data.rooms.map((room) => ({
      roomId: room.roomId,
      accommodationIndex: room.accommodationIndex,
      description: room.description,
      roomRegimen: room.room_regimen,
      totalPrice: room.totalPrice,
      totalPriceWithFee: room.totalPriceWithFee,
      quantityAvailable: room.quantityAvailable,
      roomInfo: room.roomInfo,
      violatedPolicies: mapViolatedPoliciesDTO(room.violated_policies),
      adultAmount: room.adult_amount,
      childAmount: room.child_amount,
    })),
  };
}
