import { api } from "~/infrastructure/api";
import { ISummary } from "./ISummary";
import { Summary } from "~/application/types/entities/Summary.type";
import { mapSummaryDTO } from "~/infrastructure/api/mappers/mapSummaryDTO";
import { SummaryDTO } from "~/infrastructure/api/dtos/SummaryDTO";


export class SummaryService implements ISummary {
  async findById(customerId: string): Promise<Summary> {
    return await api
      .get<SummaryDTO>(`/customers/${customerId}/dashboard`)
      .then(({ data }) => mapSummaryDTO(data));
  }
}
