import { useMemo } from "react";
import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { PaginationButton } from "./PaginationButton";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Icon } from "../Icon";
import { Text } from "../Text";

export type PaginationItem = number | "separator";

export type PaginationProps = {
  activePage: number;
  pagesCount: number;
  setActivePage: (page: number) => void;
};

export const Pagination = ({
  activePage = 1,
  setActivePage,
  pagesCount,
}: PaginationProps) => {
  const isMobile = useMobile();

  const items = useMemo<PaginationItem[]>(() => {
    if (isMobile) {
      if (pagesCount < 4) {
        return Array.from({ length: pagesCount }, (_, i) => i + 1);
      } else if (activePage === 1) {
        return [1, 2, 3];
      } else if (activePage === pagesCount) {
        return [pagesCount - 2, pagesCount - 1, pagesCount];
      } else {
        return [activePage - 1, activePage, activePage + 1];
      }
    } else {
      if (pagesCount <= 6) {
        return Array.from({ length: pagesCount }, (_, i) => i + 1);
      } else if (activePage > 2 && activePage < pagesCount - 2) {
        return [
          1,
          "separator",
          activePage - 1,
          activePage,
          activePage + 1,
          "separator",
          pagesCount,
        ];
      } else {
        return [
          1,
          2,
          3,
          "separator",
          pagesCount - 2,
          pagesCount - 1,
          pagesCount,
        ];
      }
    }
  }, [activePage, pagesCount]);

  return (
    <Flex align="center" justify="between" gap="6">
      <Button
        size="sm"
        variant="tertiary"
        onClick={() => setActivePage(activePage - 1)}
        css={{
          "@mxlg": {
            height: "$5",
            width: "$7",
          },
        }}
        disabled={activePage === 1}
      >
        {!isMobile ? "Anterior" : <Text fw="400">&lt;</Text>}
      </Button>

      <Flex align="center" gap="4">
        {items.map((item, key) => {
          return item === "separator" ? (
            <Box as="span" role="separator" key={key}>
              ...
            </Box>
          ) : (
            <PaginationButton
              css={{ "@mxlg": {
                fontSize: "$xs", width: "$8", height: "$7" 
              } }}
              data-active={item === activePage}
              onClick={() => setActivePage(item)}
              key={key}
            >
              {item}
            </PaginationButton>
          );
        })}
      </Flex>

      <Button
        size="sm"
        variant="tertiary"
        onClick={() => setActivePage(activePage + 1)}
        css={{
          "@mxlg": {
            height: "$5",
            width: "$7",
          },
        }}
        disabled={activePage === pagesCount}
      >
       {!isMobile ? "Próximo" : <Text fw="400">&gt;</Text>}
      </Button>
    </Flex>
  );
};

Pagination.displayName = "Pagination";
