import { CustomerEmployee } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { CustomerEmployeeDTO } from "../dtos";
import { mapProfileDTO } from "./mapProfileDTO";

export function mapCustomerEmployeeDTO(data: CustomerEmployeeDTO): CustomerEmployee {
  return {
    uuid: data.uuid,
    name: data.name,
    lastName: data.last_name,
    phone: data.phone,
    email: data.email,
    gender: data.gender,
    cpf: data.cpf,
    rg: data.rg,
    birthDate: data.birthdate ? DateUtils.format(data.birthdate, DateFormats.SMALL_DATE) : "",
    registration: data.registration,
    position: data.position,
    outsourced: data.outsourced,
    passportNumber: data.passport_number,
    passportExpiration: data.passport_expiration
      ? DateUtils.format(data.passport_expiration, DateFormats.SMALL_DATE)
      : "",
    vip: data.vip,
    loyaltyLatam: data.loyalty_latam,
    loyaltyAzul: data.loyalty_azul,
    loyaltyGol: data.loyalty_gol,
    preferredSeat: data.preferred_seat,
    roadLocation: data.road_location,
    typeAirSeat: data.type_air_seat,
    costCenter: data.cost_center
      ? { uuid: data.cost_center.uuid, name: data.cost_center.name }
      : undefined,
    customerId: data.customer_uuid,
    policyId: data.policy_uuid,
    isActive: Boolean(data.active),
    userId: data.user_uuid,
    profile: data?.profile ? mapProfileDTO(data.profile) : undefined,
    avatarUrl: data.avatar_url ?? undefined,
  };
}
