import * as React from "react";
import { SVGProps } from "react";

const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 2.709C3.69 2.709.275 9.721.275 9.721a.625.625 0 0 0 0 .558S3.69 17.291 10 17.291s9.727-7.012 9.727-7.012a.625.625 0 0 0 0-.558S16.309 2.709 10 2.709zm0 1.25c5.12 0 8.132 5.53 8.404 6.041-.272.51-3.284 6.041-8.404 6.041S1.868 10.511 1.596 10C1.868 9.49 4.88 3.959 10 3.959Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 6.875A3.135 3.135 0 0 0 6.875 10 3.135 3.135 0 0 0 10 13.125 3.135 3.135 0 0 0 13.125 10 3.135 3.135 0 0 0 10 6.875zm0 1.25c1.043 0 1.875.832 1.875 1.875A1.866 1.866 0 0 1 10 11.875 1.866 1.866 0 0 1 8.125 10c0-1.043.832-1.875 1.875-1.875Z"
    />
  </svg>
);

export default SvgEye;
