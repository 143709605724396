import { Fragment, useCallback } from "react";
import {
  Customer,
  GroupedOrderHistory,
  Order,
  OrderItemsWithChangedPrice,
  OrderMessage,
} from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgPerson } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, CounterTabBadge, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { TabOrderMessages } from "~/presentation/shared/components/TabOrderMessages";
import { TabOrderHistory } from "../OrderPage/views/OrderItem/components/TabOrderHistory";
import { ORDER_MENU_TABS, OrderTab } from "./utils";
import { TabOrderAdvances } from "./views/OrderAdvance/TabOrderAdvances";
import { TabOrderItems, TabOrderItemsProps } from "./views/OrderItems/TabOrderItems";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { H4 } from "~/components/Typography";
import { getServiceIcon } from "~/core/shared/utils/order.utils";
import { dialogService } from "~/components/DialogStack";
import { CustomerInfoDialog } from "~/core/modules/Agency/pages/AgencyOrderPage/components/CustomerInfoDialog";
import { AdditionalInfo } from "~/core/modules/Agency/pages/AgencyOrderPage/components/AdditionalInfoDialog";
import { AdditionalInfoProps } from "~/core/modules/Agency/pages/AgencyOrderPage";

export interface ManageOrderContainerProps {
  isLoading: boolean;
  activeTab: OrderTab;
  isLoadingPaymentMethod: boolean;
  order?: Order;
  paymentMethod?:{
    description: string;
    uuid: string;
  }[];
  isLoadingAdditionalInfo: boolean
  additionalInfo: Customer | undefined
  orderItemsState: TabOrderItemsProps;
  isLoadingOrderMessages: boolean;
  orderMessages: OrderMessage[];
  orderHistory?: GroupedOrderHistory[];
  isLoadingOrderHistory: boolean;
  onConsultOrderItemsPrice: (orderUuid?: string | undefined) => Promise<OrderItemsWithChangedPrice>;
}

export function ManageOrderContainer({
  isLoading,
  activeTab,
  order,
  additionalInfo,
  orderItemsState,
  isLoadingPaymentMethod,
  isLoadingOrderMessages,
  orderMessages,
  isLoadingOrderHistory,
  isLoadingAdditionalInfo,
  orderHistory,
  paymentMethod,
  onConsultOrderItemsPrice,
}: ManageOrderContainerProps) {
  const tabOrderItemsProps = {
    ...orderItemsState,
    paymentMethod,
    isLoading,
    isLoadingPaymentMethod,
    onConsultOrderItemsPrice,
  };

  const isMobile = useMobile();
  const tabOrderMessagesProps = {
    isLoadingOrderMessages,
    orderMessages,
  };

  const dialogAdditionalInfo = useCallback(({ label, obs }: AdditionalInfoProps) => {
    return dialogService.showDialog(<AdditionalInfo label={label} obs={obs} />)
  }, [])

  const customerInfoDialog = useCallback(() => {
    return dialogService.showDialog(<CustomerInfoDialog approvers={additionalInfo?.approvers} issuers={additionalInfo?.issuers} />)
  }, [additionalInfo])

  const tabOrderHistoryProps = {
    order,
    isLoadingOrderHistory,
    orderHistory: orderHistory || [],
  };
  
  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
              <Link to="/pedidos/pedidos-pendentes">
                <IconButton size="md">
                  <Icon as={SvgChevronLeft} css={{ fill: isMobile ? "#FFF" : "" }} />
                </IconButton>
              </Link>
            </Tooltip>

            <AppBarHeading
              title={
                isLoading ? (
                  <Skeleton variant="text-6" style={{ maxWidth: 360 }} />
                ) : (
                  <Flex justify={{ "@mxlg": "center" }}>
                    <H4 size={{ "@mxlg": "2" }}>
                      <Flex align="center" direction={{ "@mxlg": "column" }}>
                        Pedido #{order?.orderNumber}{" "}
                        {isLoadingAdditionalInfo ? (
                          <Flex gap="8" css={{ p: "$4" }}>
                          <Skeleton variant="text-4" style={{ width: 100 }} />
                        </Flex>

                        ) : (
                          <Flex align="center">
                            <IconButton size="lg" onClick={() => customerInfoDialog()}>
                              <Icon size="md" as={SvgPerson} css={{ "@mxlg": { fill: "#fff" }}} />
                            </IconButton>
                            {additionalInfo?.additionalInfo &&
                              Object.entries(additionalInfo.additionalInfo).map(([key, value]) => {
                                const ServiceIcon = getServiceIcon(key);
                                return (  
                                  <IconButton key={key} size="lg" onClick={() => dialogAdditionalInfo({ label: key, obs: value.obs })}>
                                    <Flex key={key} css={{ cursor: "pointer" }}>
                                      <Icon as={ServiceIcon} css={{ "@mxlg": { fill: "#fff" }}} />
                                    </Flex>
                                  </IconButton>
                                );
                              })}
                          </Flex>
                        )}
                      </Flex>
                    </H4>
                  </Flex>
                )
              }
            />
          </AppBarContainer>

          <Row
            css={{
              "@mxlg": {
                width: "103%",
              },
            }}
          >
            <Container
              css={{
                "@mxlg": {
                  overflowX: "scroll",
                  p: 0,
                },
              }}
            >
              <TabBar>
                {isLoading || !order ? (
                  <Flex gap="8" css={{ p: "$4" }}>
                    <Skeleton variant="text-4" style={{ width: 96 }} />
                    <Skeleton variant="text-4" style={{ width: 96 }} />
                  </Flex>
                ) : (
                  ORDER_MENU_TABS.map(({ title, id }) => (
                    <Link to={`#${id}`} title={title} key={id}>
                      <CounterTab id={id} value={id}>
                        <Text>{title}</Text>

                        {id === OrderTab.ITEMS && (
                          <CounterTabBadge>
                            <Text>{order.itemsIncluded.length}</Text>
                          </CounterTabBadge>
                        )}

                        {id === OrderTab.OBSERVATIONS && (
                          <CounterTabBadge>
                            <Text>{orderMessages.length}</Text>
                          </CounterTabBadge>
                        )}

                        {id === OrderTab.HISTORY && (
                          <CounterTabBadge>
                            <Text>
                              {Object.values(orderHistory || {}).reduce(
                                (acc, { history }) => acc + history.length,
                                0
                              )}
                            </Text>
                          </CounterTabBadge>
                        )}
                      </CounterTab>
                    </Link>
                  ))
                )}
              </TabBar>
            </Container>
          </Row>
        </AppBar>

        {!isLoading && (
          <Container css={{ px: 5 }}>
            <TabContent value={OrderTab.ITEMS}>
              <TabOrderItems {...tabOrderItemsProps} />
            </TabContent>

            <TabContent value={OrderTab.VOUCHERS}>
              <TabOrderAdvances />
            </TabContent>

            <TabContent value={OrderTab.OBSERVATIONS}>
              <TabOrderMessages {...tabOrderMessagesProps} />
            </TabContent>

            <TabContent value={OrderTab.HISTORY}>
              <TabOrderHistory {...tabOrderHistoryProps} />
            </TabContent>
          </Container>
        )}
      </Tabs>
    </Fragment>
  );
}
