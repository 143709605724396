import { BiztripLogo } from "~/components/BiztripLogo";
import { Box } from "~/components/Box";
import { Spinner } from "~/components/Spinner";
import { Fragment } from "react";

export interface AppLoadingProps {}

export const AppLoading = () => {
  return (
    <Fragment>
      <Box
        css={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner size="lg" />
      </Box>

      <Box
        css={{
          position: "fixed",
          left: "50%",
          bottom: "10%",
          transform: "translate(-50%, 0)",
        }}
      >
        <BiztripLogo />
      </Box>
    </Fragment>
  );
};
