import { StorageKeys } from "~/application/constants";
import { ApiClient, ApiErrorCode } from "~/application/types";

export const api = new ApiClient({
  baseUrl: import.meta.env.VITE_API_URL,
  timeoutMs: 1000 * 60 * 3, // 3 minutes
});

let isRefreshing = false;

export const refreshToken = async () => {
  const authToken =
    sessionStorage.getItem(StorageKeys.AUTH_TOKEN) || localStorage.getItem(StorageKeys.AUTH_TOKEN);

  if (!authToken) return;

  const url = `${import.meta.env.VITE_API_URL}/refresh-token/${authToken}`;

  try {
    const response = await fetch(url, { method: "POST" });
    const { data } = await response.json();

    localStorage.setItem(StorageKeys.AUTH_TOKEN, data.token.value);
    sessionStorage.setItem(StorageKeys.AUTH_TOKEN, data.token.value);

    return data.token;
  } catch (error) {
    window.location.href = "/";
    localStorage.removeItem(StorageKeys.AUTH_TOKEN);
    sessionStorage.removeItem(StorageKeys.AUTH_TOKEN);
    return null;
  }
};

api.instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const request = error.config;
    const response = error?.response;
    const isLoginUrl = request.url === "/login";
    const isChangePasswordUrl = request.url === "/change-password";
    const errorCode = response?.data?.code || error.code;

    if (!isLoginUrl && !isChangePasswordUrl && errorCode === ApiErrorCode.INACTIVE_USER) {
      window.location.href = "/";
    }

    if (!isLoginUrl && !isChangePasswordUrl && response?.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        const newToken = await refreshToken();

        if (!newToken) {
          isRefreshing = false;
          return Promise.reject(new Error("Falha ao renovar o token."));
        }
        request.headers["Authorization"] = `Bearer ${newToken.value}`;

        return api.instance(request).finally(() => {
          isRefreshing = false;
        });
      }
    }

    return Promise.reject(error);
  }
);
