import React, { ComponentProps, useMemo } from "react";
import { styled } from "~/application/theme";
import { Text } from "../Text";
import { AvatarRoot } from "./Avatar";

export type AvatarGroupProps = ComponentProps<typeof AvatarGroupRoot> & {
  cap?: number;
};

const AvatarMorePerson = styled("span", {
  // Reset
  display: "inline-flex",
  boxSizing: "border-box",
  position: "relative",

  // Custom
  borderRadius: "$md",
  backgroundColor: "$neutrals-lightest",
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",
  fontSize: "$xs",
  fontWeight: 500,
  color: "$neutrals-dark",
  size: "$8",
  boxShadow: "0px 0px 0px 4px $colors$neutrals-white",
});

const AvatarGroupRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  position: "relative",

  // Custom
  display: "flex",

  "&[data-collapsed=true]": {
    [`& > ${AvatarRoot} + ${AvatarRoot}`]: {
      marginLeft: "-$2",
    },
  },

  [`& > ${AvatarRoot} + ${AvatarRoot}`]: {
    marginLeft: "-$1",
  },

  [`& > ${AvatarMorePerson}`]: {
    marginLeft: "-$2",
  },
});

export const AvatarGroup = ({
  children: childrenProp,
  cap = 5,
  ...props
}: AvatarGroupProps) => {
  const children = React.Children.toArray(childrenProp);

  const avatarChildren = useMemo(() => children.slice(0, cap), [children, cap]);

  const hasCaped = children.length > avatarChildren.length;

  return (
    <AvatarGroupRoot data-collapsed={hasCaped} {...props}>
      {avatarChildren}
      {hasCaped && (
        <AvatarMorePerson>
          <Text>+{children.length - avatarChildren.length}</Text>
        </AvatarMorePerson>
      )}
    </AvatarGroupRoot>
  );
};

AvatarGroup.displayName = "AvatarGroup";
