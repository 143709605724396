import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgExpenses } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { asCurrency } from "~/utils/mask.utils";

export const CreditLimit = () => {
  const { user } = useUser();
  return (
    <Flex
      css={{
        position: "relative",
      }}
      direction="column"
    >
      <Flex
        gap="2"
        align="center"
        css={{
          borderRadius: "$md",
          p: "$2",
          border: " 1px solid $primary-base",

          cursor: "pointer",
        }}
      >
        <Flex gap="1" align="center" direction="column">
          <Text variant="darkest" size="1">
            Crédito disponível
          </Text>
          <Flex gap="1" align="center">
            <Icon as={SvgExpenses} variant="primary" />
            <Text variant="primary" size="3">
              {asCurrency(user?.customer?.invoiceLimit)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

CreditLimit.displayName = "CreditLimit";
