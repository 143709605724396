import { dialogService } from "~/components/DialogStack";
import { CancelDialog, CancelDialogProps } from "~/components/CancelDialog";
import { Text } from "~/components/Text";
import { SvgDolar } from "~/components/Icon/icons";
import { CardProps } from "~/components/Card";

export interface ConfirmOrderCancelProps extends CardProps {
  onCancelOrder: () => Promise<void>;
}

export function ConfirmOrderCancel({
  onCancelOrder,
  ...props
}: ConfirmOrderCancelProps) {
  return (
    <CancelDialog
      onConfirm={onCancelOrder}
      onCloseClick={() => dialogService.popDialog()}
      title="Cancelamento do pedido"
      textCancelation="Cancelar pedido"
      svg={SvgDolar}
      {...props}
    >
      <Text as="p">Deseja continuar?</Text>
    </CancelDialog>
  );
}
