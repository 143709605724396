import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { OfflineHotelAmenity, OfflineHotelRoom } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { NumberInput, Select, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { editOfflineHotelRoomSchema, EditOfflineHotelRoomSchema } from "../utils/form";
import { Col, Row } from "~/components/Grid";
import { UploadImageInput } from "../../../components/UploadImageInput";
import { DeleteOfflineHotelRoomImageProps, UpdateOfflineHotelRoomProps } from "~/application/usecases/OfflineHotelRoom";
import { Flex } from "~/components/Flex";
import { Image } from "~/components/Image";
import { MultiSelect } from "~/components/Input/MultiSelect";
import { IconButton } from "~/components/IconButton";
import { Icon } from "~/components/Icon";
import { SvgTrash } from "~/components/Icon/icons";

export interface EditOfflineHotelRoomDialogProps {
  defaultData: OfflineHotelRoom;
  amenities?: OfflineHotelAmenity[];
  isLoadingAmenities: boolean;
  onCloseClick?: () => void;
  onSubmit: (data: UpdateOfflineHotelRoomProps) => void;
  onDeleteOfflineHotelRoomImage: (data: DeleteOfflineHotelRoomImageProps) => void;
}

export function EditOfflineHotelRoomDialog({
  defaultData,
  amenities,
  isLoadingAmenities,
  onCloseClick,
  onSubmit,
  onDeleteOfflineHotelRoomImage,
}: EditOfflineHotelRoomDialogProps) {
  const { 
    control, 
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors, isSubmitting }, 
  } = useForm<EditOfflineHotelRoomSchema>({
    defaultValues: {
      ...defaultData,
      amenities: defaultData.amenities?.map((amenity) => ({ name: amenity })),
      regime: defaultData.regime ? { name: defaultData.regime } : undefined,
      type: defaultData.type ? { name: defaultData.type } : undefined,
      images: FileList,
    },
    resolver: yupResolver(editOfflineHotelRoomSchema)
  });

  const [imagesToRemove, setImagesToRemove] = useState<{ uuid: string, imageUrl: string }[]>([]);

  if (isSubmitting) {
    return (
      <LoadingModal
        isOpen={isSubmitting}
        message={"Editando quarto"}
      />
    );
  }

  const { images } = watch();
  const REGIMES = [{ name: "Com café da manhã" }, { name: "Sem café da manhã" }];
  const ROOM_TYPES = [{ name: "Single" }, { name: "Duplo" }, { name: "Triple" }, { name: "Quadruplo" }];

  const removeImage = (indexToRemove: number) => {
    const dataTransfer = new DataTransfer();

    Array.from(images as FileList).forEach((file, index) => {
      if (index !== indexToRemove) {
        dataTransfer.items.add(file);
      }
    });

    setValue("images", dataTransfer.files);
  };

  const editOfflineHotelRoom = (data: EditOfflineHotelRoomSchema) => {
    const imagesToUpload = images as FileList;
    const amenities = data.amenities?.map((amenity) => amenity.name);
    
    onSubmit({
      data: {
        ...data,
        amenities,
        regime: data.regime?.name,
        type: data.type?.name,
      },
      images: imagesToUpload,
    } as UpdateOfflineHotelRoomProps);

    for (const image of imagesToRemove) {
      onDeleteOfflineHotelRoomImage({ offlineHotelRoomId: defaultData.uuid, imageId: image.uuid });
    } 
  };

  const handleDeleteOfflineHotelRoomImage = (image: { uuid: string, imageUrl: string }) => {
    setImagesToRemove((prev) => [ ...prev, image ]);
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(editOfflineHotelRoom)}>
        <FormDialog
          title="Editar quarto"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={isSubmitting} type="submit">
              <Text>Aplicar</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Flex direction="column" gap="6">
              <H4>Informações básicas</H4>

              <Flex direction="column" gap="6">
                <FormControl name="description" control={control} required>
                  <FieldLabel>Descrição</FieldLabel>
                  <TextInput placeholder="Digite uma descrição para o quarto" />
                  {errors.description && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.description.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl name="type" control={control} required>
                  <FieldLabel>Tipo</FieldLabel>
                  <Select
                    placeholder="Selecione o tipo de quarto"
                    options={ROOM_TYPES}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                  />
                  {errors.type?.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.type.name.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl name="regime" control={control} required>
                  <FieldLabel>Regime</FieldLabel>
                  <Select
                    placeholder="Selecione o regime do quarto"
                    options={REGIMES}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                  />
                  {errors.regime?.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.regime.name.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl name="value" control={control} required>
                  <FieldLabel>Valor</FieldLabel>
                  <NumberInput
                    prefix="R$ "
                    placeholder="Digite o valor para este quarto"
                  />
                  {errors.value && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.value.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl name="adultsAmount" control={control} required>
                  <FieldLabel>Número de adultos</FieldLabel>
                  <NumberInput 
                    placeholder="Digite o número de adultos permitidos"
                    allowNegative={false} 
                    decimalScale={0} 
                    min={1}
                  />
                  {errors.adultsAmount && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.adultsAmount.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl control={control} name="amenities" required>
                  <FieldLabel>Comodidades da hospedagem</FieldLabel>
                  <MultiSelect
                    placeholder="Selecione uma ou mais opções"
                    options={amenities}
                    isLoading={isLoadingAmenities}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.uuid}
                  />
                  {errors.amenities && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.amenities.message}
                    </Text>
                  )}
                </FormControl>
              </Flex>
            </Flex>

            <Flex direction="column" gap="6" css={{ mt: "$10" }}>
              <H4>Fotos</H4>

              <Row gap="1">
                {defaultData.imagesUrl.map((image) => {
                  if (imagesToRemove.includes(image)) {
                    return null;
                  }

                  return (
                    <Col key={image.uuid} sz={{ "@initial": "4", "@mxlg": "12" }} css={{ position: "relative" }}>
                      <Image 
                        src={image.imageUrl} 
                        alt={defaultData.description} 
                        css={{
                          width: "100%",
                          height: "9.75rem",
                          objectFit: "cover",
                          borderRadius: "$md",
                        }}
                      />

                      <IconButton 
                        size="md" 
                        css={{ 
                          position: "absolute", 
                          right: "$6", 
                          top: "$3",
                          backgroundColor: "$neutrals-white"
                        }}
                        onClick={() => handleDeleteOfflineHotelRoomImage(image)}
                      >
                        <Icon as={SvgTrash} />
                      </IconButton>
                    </Col>
                  );
                })}
              </Row>

              <Col>
                <UploadImageInput 
                  {...register("images")}
                  label="Importe fotos do quarto"
                  files={images as FileList}
                  removeFile={removeImage}
                  accept="image/*"
                  multiple
                />
                {errors.images && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {errors.images.message}
                  </Text>
                )}
              </Col>
            </Flex>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
