import { useCallback } from "react";

import { OrderStatus } from "~/application/types";
import { Button } from "~/components/Button";
import { Col } from "~/components/Grid";
import { SearchBar } from "~/components/Input";
import { DateInputWithCalendar } from "~/components/Input/DateInput/DateInputWithCalendar";
import { DataColItem } from "~/components/List";
import { Text } from "~/components/Text";
import { OrderSearch } from "../../OrdersPage";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface OrdersContainerProps {
  searches?: OrderSearch;
  tabSummary?: {
    id: OrderStatus;
    total: number;
    title: string;
  }[];
  onClearFilters: () => void;
  setOrderNumber: (search: string) => void;
  setEmployeeName: (search: string) => void;
  setIssuerName: (search: string) => void;
  setApproverName: (search: string) => void;
  setStartDate: (search: string) => void;
  setEndDate: (search: string) => void;
  setTracker: (search: string) => void;
}

export function OrderFilter({
  searches,
  onClearFilters,
  setOrderNumber,
  setEmployeeName,
  setIssuerName,
  setApproverName,
  setStartDate,
  setEndDate,
  setTracker,
}: OrdersContainerProps) {
  const isMobile = useMobile();
  
  const setStartDateCallback = useCallback((date: string) => {
    setStartDate(String(date));
  }, []);

  const setEndDateCallback = useCallback((date: string) => {
    setEndDate(String(date));
  }, []);

  return (
    <>
      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Número do pedido"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.orderNumber}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setOrderNumber}
              placeholder="Encontrar"
            />
          </Col>
        }
      />

      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Localizador"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.tracker}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setTracker}
              placeholder="Encontrar"
            />
          </Col>
        }
      />

      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Nome do funcionário"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.employeeName}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setEmployeeName}
              placeholder="Encontrar"
            />
          </Col>
        }
      />

      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Nome do solicitante"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <SearchBar
              search={searches?.issuerName}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setIssuerName}
              placeholder="Encontrar"
            />
          </Col>
        }
      />

      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Nome do aprovador"
        data={
          <Col sz="auto" css={{ "@mxlg": { maxWidth: "100%" } }}>
            <SearchBar
              search={searches?.approverName}
              style={{ width: isMobile ? "100%" : "auto" }}
              onSearchChange={setApproverName}
              placeholder="Encontrar"
            />
          </Col>
        }
      />

      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Data de início"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <DateInputWithCalendar
              size="sm"
              maxValue={new Date()}
              placeholder="Encontrar"
              style={{ width: isMobile ? "100%" : "auto" }}
              value={searches?.startDate}
              onChangeCallback={setStartDateCallback}
            />
          </Col>
        }
      />

      <DataColItem
        boxCss={{ my: "$2" }}
        headerTitle="Data de fim"
        data={
          <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
            <DateInputWithCalendar
              size="sm"
              maxValue={new Date()}
              placeholder="Encontrar"
              style={{ width: isMobile ? "100%" : "auto" }}
              value={searches?.endDate}
              onChangeCallback={setEndDateCallback}
            />
          </Col>
        }
      />

      <Col sz="auto">
        <Button
          title="Limpar filtros"
          variant="tertiary"
          css={{
            mt: "$4",
          }}
          onClick={onClearFilters}
        >
          <Text>Limpar filtros</Text>
        </Button>
      </Col>
    </>
  );
}
