import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";

export type RequireRoleProps = {
  role: UserContext | UserContext[];
  children?: React.ReactElement;
};

export function RequireRole({ children, role }: RequireRoleProps) {
  const { user } = useUser();

  const location = useLocation();

  const shouldAccess = () => {
    if (user.context === UserContext.Agency && role === "customer") {
      return true;
    }

    return typeof role === "string"
      ? user.context === role
      : role.includes(user.context);
  };

  const canAccess = shouldAccess();

  useEffect(() => {
    if (!canAccess) {
      alert(
        "Você foi redirecionado pois não possui permissão para visualizar este conteúdo."
      );
    }
  }, []);

  if (!canAccess) {
    return <Navigate replace to="/" state={{ from: location }} />;
  }

  return children || <Outlet />;
}
