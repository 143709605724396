import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import React, { ComponentProps, forwardRef } from "react";
import { DropdownMenuButton } from "./DropdownMenuButton";

export type DropdownMenuItemProps = ComponentProps<typeof DropdownMenuButton>;

export const DropdownMenuItem = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  DropdownMenuItemProps
>((props, forwardedRef) => {
  return (
    <DropdownMenuPrimitive.Item asChild ref={forwardedRef}>
      <DropdownMenuButton {...props} />
    </DropdownMenuPrimitive.Item>
  );
});

DropdownMenuItem.displayName = "DropdownMenuItem";
