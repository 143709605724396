import { FC } from "react";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Card, Card as CardEntity } from "~/application/types";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Flex } from "~/components/Flex";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input/Switch";
import { Icon } from "~/components/Icon";

interface CardListItemProps extends DataListItemProps {
  data: CardEntity;
  onToggleState: (data: Card) => void;
  onEditClick: (data: CardEntity) => void;
}

export const CardListItem: FC<CardListItemProps> = ({
  data,
  onToggleState,
  onEditClick,
  ...props
}) => {
  const isMobile = useMobile();

  return (
    <DataListItem
      data-active={true}
      {...props}
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Dono" data={data.owner} />

      <DataColItem headerTitle="Descrição" data={data.description} />

      <DataColItem headerTitle="Código" data={data.internalCardCode} />

      <DataColItem headerTitle="Bandeira" data={data.flag.name} />

      <DataColItem headerTitle="Validade" data={data.validity} />

      {isMobile ? (
        <Flex justify="between" css={{ width: "100%" }}>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          <Switch checked={data.active} onClick={() => onToggleState(data)}>
            <Caption>{data.active ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </Flex>
      ) : (
        <>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          <Switch checked={data.active} onClick={() => onToggleState(data)}>
            <Caption>{data.active ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </>
      )}
    </DataListItem>
  );
};

CardListItem.displayName = "CreditCardListItem";
