import { FC } from "react";
import { asCurrency } from "~/application/utils/mask-functions";
import { Box } from "~/components/Box";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Label } from "~/components/Typography";
import { SeatListItem } from "../SeatListItem";
import { SeatsSummaryProps } from "./types";

export const SeatsSummary: FC<SeatsSummaryProps> = ({
  data,
  seatFields,
  onRemoveSeat,
}) => {
  return (
    <Cart
      css={{
        height: "400px",
        "@mxlg": {
          height: "auto",
          mt: "$50",
          display: seatFields.length > 0 ? "block" : "none",
        },
      }}
    >
      <CartHeader>
        <Text
          css={{
            "@mxmd": {
              fontSize: "$sm",
            },
          }}
        >
          Assentos selecionados
        </Text>
      </CartHeader>

      <Box css={{ overflow: "auto", flexGrow: 1, flexShrink: 1 }}>
        {seatFields.map((seat, index) => (
          <SeatListItem
            data={seat}
            isConnection={!!data.connection}
            price={data.price + data.taxPrice}
            onRemove={() => onRemoveSeat(seatFields[index])}
            key={seat.id}
          />
        ))}
      </Box>

      <Flex
        as={CardBody}
        justify="between"
        align="center"
        css={{
          borderTop: "1px solid $colors $neutrals-light",
          "@mxlg": {
            display: "none",
          },
        }}
      >
        <Label>Valor total</Label>

        <Text size="6" css={{ fw: "600" }}>
          {asCurrency(seatFields.length * (data.price + data.taxPrice))}
        </Text>
      </Flex>
    </Cart>
  );
};
