import { ExpenseType } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { ExpenseTypeListItem } from "./components/ExpenseTypeListItem";

export interface TabExpenseTypesProps {
  data?: ExpenseType[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  onGoToPage: (value: number) => void;
  onCreateExpenseType: () => void;
  onEditExpenseType: (item: ExpenseType) => void;
  onToggleExpenseTypeState: (item: ExpenseType) => void;
}

export function TabExpenseTypes({
  data,
  currentPage,
  lastPage,
  onGoToPage,
  onCreateExpenseType,
  onEditExpenseType,
  onToggleExpenseTypeState,
}: TabExpenseTypesProps) {
  const listRenderer = useCallback(
    (item: ExpenseType) => (
      <ExpenseTypeListItem
        data={item}
        onEditClick={onEditExpenseType}
        onToggleState={onToggleExpenseTypeState}
        key={item.uuid}
      />
    ),
    [onEditExpenseType, onToggleExpenseTypeState]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Tipos de despesas</H4>
          </Col>

          <Col
            sz={{ "@lg": "auto" }}
            css={{
              "@mxlg": {
                width: "100%",
              },
            }}
          >
            <Button
              title="Novo tipo de despesa"
              color="primary"
              onClick={onCreateExpenseType}
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <Icon as={SvgPlus} />
              <Text>Novo tipo de despesa</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não tipos de despesas cadastrados</Text>
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
