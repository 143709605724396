import * as React from "react";
import { SVGProps } from "react";

const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M3.75 2.5A1.26 1.26 0 0 0 2.5 3.75v12.5c0 .683.567 1.25 1.25 1.25h12.5a1.26 1.26 0 0 0 1.25-1.25V3.75a1.26 1.26 0 0 0-1.25-1.25Zm0 1.25h12.5v12.5H3.75Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M13.75 1.25a.625.625 0 0 0-.625.625v2.5A.625.625 0 0 0 13.75 5a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625ZM6.25 1.25a.625.625 0 0 0-.625.625v2.5A.625.625 0 0 0 6.25 5a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625ZM3.125 6.25a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h13.75a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625Z"
    />
  </svg>
);

export default SvgCalendar;
