import { Order, OrderItems } from "~/application/types";
import { ExpensePolicyTypeValue } from "~/application/types";
import {
  SvgAirplane,
  SvgBus,
  SvgCar,
  SvgExpenses,
  SvgGear,
  SvgHotelOffline,
  SvgHotels,
  SvgSearch,
  SvgTicket,
} from "~/components/Icon/icons";
import * as MaskUtils from "~/utils/mask.utils";
import React from "react";
import { MenuItems } from "~/presentation/core/containers/AppContainer/components/NavMenuMobile/NavMenuMobile";

export function getServiceLabel(service: OrderItems | string): string {
  switch (service) {
    case OrderItems.AIRWAY:
      return "Aéreo";
    case OrderItems.HOTEL:
      return "Hotel";
    case OrderItems.VEHICLE:
      return "Carro";
    case OrderItems.ROAD:
      return "Rodoviário";
    case OrderItems.ADVANCE:
      return "Adiantamento";
    case OrderItems.HOTEL_OFFLINE:
      return "Hotel Offline";
    default:
      return service;
  }
}

export function getServiceMobileLabel(service: MenuItems): string {
  switch (service) {
    case MenuItems.SEARCH:
      return "Buscar";
    case MenuItems.EXPENSE:
      return "Despesas";
    case MenuItems.ORDER:
      return "Pedidos";
    case MenuItems.CONFIG:
      return "Configurações";
  }
}

export function getServiceLink(service: OrderItems): string | undefined {
  switch (service) {
    case OrderItems.AIRWAY:
      return "/busca/passagens-aereas";
    case OrderItems.HOTEL:
      return "/busca/hospedagens";
    case OrderItems.VEHICLE:
      return "/busca/alugar-carros";
    case OrderItems.ROAD:
      return "/busca/passagens-onibus";
  }
}

export function getServiceIcon(service: OrderItems | string): React.ElementType | undefined {
  switch (service) {
    case OrderItems.AIRWAY:
      return SvgAirplane;
    case OrderItems.HOTEL:
      return SvgHotels;
    case OrderItems.VEHICLE:
      return SvgCar;
    case OrderItems.ROAD:
      return SvgBus;
    case OrderItems.ADVANCE:
      return SvgExpenses;
    case OrderItems.HOTEL_OFFLINE:
      return SvgHotelOffline;
  }
}

export function getServiceMenuIcon(service: MenuItems): React.ElementType | undefined {
  switch (service) {
    case MenuItems.SEARCH:
      return SvgSearch;
    case MenuItems.EXPENSE:
      return SvgExpenses;
    case MenuItems.ORDER:
      return SvgTicket;
    case MenuItems.CONFIG:
      return SvgGear;
  }
}

export function getUnsatisfiedPolicyMessage(typeValue: ExpensePolicyTypeValue, value: number) {
  switch (typeValue) {
    case ExpensePolicyTypeValue.AMOUNT_MAXIMUM:
      return `A opção selecionada está acima da quantidade máxima máximo definido de ${value}.`;
    case ExpensePolicyTypeValue.TOUCHABLE_LESS:
      return `A opção selecionada está acima do teto máximo definido de ${MaskUtils.asCurrency(
        value
      )}.`;
    case ExpensePolicyTypeValue.UNTOUCHABLE:
      return `A opção selecionada não se enquadra no valor definido de ${MaskUtils.asCurrency(
        value
      )}.`;
    default:
      return "";
  }
}

export function getTotalOrderItems(order?: Order): number {
  const totalRoadItems = order?.items.road?.travels.length || 0;
  const totalHotelItems = order?.items.hotel?.rooms.length || 0;
  const totalAirwayItems = order?.items.airway?.flights.length || 0;
  const totalVehicleItems = order?.items.vehicle?.vehicles.length || 0;

  return totalAirwayItems + totalVehicleItems + totalHotelItems + totalRoadItems;
}
