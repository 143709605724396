import { createContext, useContext } from "react";
import type { CustomerEmployee, OfflineHotelQuery, Traveler } from "~/application/types";
import type { OfflineHotelReducer } from "../types";

export interface BookingOfflineHotelContextType {
  hotelQuery: OfflineHotelQuery | null;
  hotelReducer: OfflineHotelReducer;
  quantityRooms: number;
  isRequestingOfflineHotel: boolean;
  onRequestOfflineHotel: (data: OfflineHotelQuery) => void;
  setQuantityRooms: (value: number) => void;
  fetchTravelersByName: (name: string) => Promise<CustomerEmployee[]>;
  onSearchHotels: (data: OfflineHotelQuery) => void;
  setIssuer: (issuer: CustomerEmployee) => void;
}
export const BookingOfflineHotelContext = createContext({} as BookingOfflineHotelContextType);

export const useBookingOfflineHotel = () => {
  return useContext(BookingOfflineHotelContext);
};
