import { Editor, Transforms, Range, Element as SlateElement, Node, BaseEditor } from "slate";
import { CustomElement } from "~/@types/slate";

export const LIST_TYPES = ["numbered-list", "bulleted-list"] as const;

export type Format = "numbered-list" | "bulleted-list" | "paragraph" | "link" | "heading-one" | "italic" | "heading-two" | "bold";

type EditorType = Editor & {
  selection: Range | null;
};

export type MarkFormat = "bold" | "italic" | "underline" | "code"; 

type MarkObject = Partial<Record<MarkFormat, boolean>>;

export const isBlockActive = (editor: Editor, format: Format): boolean => {
    const [match] = Editor.nodes(editor, {
      match: (n) => (n as CustomElement).type === format,
    });
  
    return !!match;
  };
  
  export const toggleBlock = (editor: BaseEditor, format: Format): void => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format as "numbered-list" | "bulleted-list");
  
    Transforms.unwrapNodes(editor, {
      match: (n) => LIST_TYPES.includes((n as CustomElement).type as "numbered-list" | "bulleted-list"),
      split: true,
    });
  
    Transforms.setNodes(editor, {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    });
  
    if (!isActive && isList) {
      const block: CustomElement = {
        type: format as "numbered-list" | "bulleted-list",
        children: [], 
      };
      Transforms.wrapNodes(editor, block);
    }
  };

  export const isMarkActive = (editor: Editor, format: MarkFormat): boolean => {
    const marks: MarkObject | null | undefined = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  export const toggleMark = (editor: Editor, format: MarkFormat): void => {
    const isActive = isMarkActive(editor, format);
   
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };
  

export const isLinkActive = (editor: EditorType): boolean => {
  const [link] = Editor.nodes(editor, { match: (n): n is CustomElement => SlateElement.isElement(n) && n.type === "link" });
  return !!link;
};

export const unwrapLink = (editor: EditorType): void => {
  Transforms.unwrapNodes(editor, { match: (n): n is CustomElement => SlateElement.isElement(n) && n.type === "link" });
};

export const wrapLink = (editor: EditorType, url: string): void => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: CustomElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

export const insertLink = (editor: EditorType, url: string): void => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
