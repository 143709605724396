import { Fragment } from "react";
import { AccountabilityExpense, AdvanceOrderStatus } from "~/application/types";
import { IFindExpenseByAdvanceOrderResult } from "~/application/usecases/AccountabilityExpense/IAccountabilityExpenseService";
import { Avatar } from "~/components/Avatar";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgAlert, SvgEdit, SvgFile, SvgTrash } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { CounterTabBadge } from "~/components/Tabs";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { EXPENSE_CATEGORY_ICONS } from "~/core/modules/ExpenseCategory/pages/ExpenseCategoriesPage/utils";
import { getUnsatisfiedPolicyMessage } from "~/core/shared/utils/order.utils";
import * as DateUtils from "~/utils/date.utils";
import * as MaskUtils from "~/utils/mask.utils";
import {
  ReasonRejectedItem,
  ReasonRejectedProps,
} from "../../../../../../../../../presentation/shared/components/ReasonRejectedItem";

interface TravelerExpenseListItemParams {
  result?: IFindExpenseByAdvanceOrderResult;
  onShowVoucher: (data: AccountabilityExpense) => void;
  onEditAccountabilityExpense: (data: AccountabilityExpense) => void;
  onDeleteAccountabilityExpense: (data: AccountabilityExpense) => void;
}

export function TravelerExpenseListItem({
  result,
  onShowVoucher,
  onEditAccountabilityExpense,
  onDeleteAccountabilityExpense,
}: TravelerExpenseListItemParams) {
  const getCardBorderCss = (expense: AccountabilityExpense): string => {
    let borderCss = "";

    if (
      result?.advanceOrder.status === AdvanceOrderStatus.APPROVING &&
      expense.reasonRejected
    ) {
      borderCss = "1px solid red";
    }

    return borderCss;
  };

  return (
    <Fragment>
      {result?.expenses.map((expense, index) => {
        const icon = EXPENSE_CATEGORY_ICONS.find(
          ({ slug }) => slug === expense.expenseCategory.icon
        )?.icon;

        const expenseDate = DateUtils.displayDate(
          expense.expenseDate,
          DateUtils.DateFormats.LONG_DATE
        );

        const border = getCardBorderCss(expense);

        return (
          <Card key={index} css={{ mb: "$6", border }}>
            <CardBody>
              <Flex direction="column" gap="6">
                <Flex justify="between">
                  <Text
                    as="p"
                    variant="dark"
                    css={{ fw: "500", lineHeight: 1.25 }}
                  >
                    {expenseDate}
                  </Text>

                  <Box>
                    <Tooltip
                      content={<TooltipLabel>Editar Despesa</TooltipLabel>}
                    >
                      <IconButton
                        size="md"
                        onClick={() => onEditAccountabilityExpense(expense)}
                      >
                        <Icon as={SvgEdit} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      content={<TooltipLabel>Deletar Despesa</TooltipLabel>}
                    >
                      <IconButton
                        size="md"
                        onClick={() => onDeleteAccountabilityExpense(expense)}
                      >
                        <Icon as={SvgTrash} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Flex>

                <Flex gap="2">
                  <Flex gap="2">
                    <Tag variant="info-light">
                      {icon && <Icon as={icon} />}
                      <Text
                        size="2"
                        css={{
                          p: "$0",
                          fw: "600",
                          display: "block",
                          mr: icon ? "$2" : "$0",
                        }}
                      >
                        {expense.expenseCategory.name}
                      </Text>
                    </Tag>
                  </Flex>

                  {expense.unsatisfiedPolicies.length > 0 && (
                    <>
                      <Tooltip
                        content={
                          <Flex direction="column" gap="2" css={{ p: "$2" }}>
                            <Text variant="black" size="3" css={{ fw: 700 }}>
                              Esta opção está fora da politica de compra.
                            </Text>
                            {expense.unsatisfiedPolicies.map(
                              ({ uuid, typeValue, value }) => (
                                <Text size="3" as="p" key={uuid}>
                                  {getUnsatisfiedPolicyMessage(
                                    typeValue,
                                    value
                                  )}
                                </Text>
                              )
                            )}
                            <Text size="3" as="p">
                              Ao selecionar esta opção, você precisará criar uma
                              justificativa para o seu aprovador.
                            </Text>
                          </Flex>
                        }
                        variant="white"
                      >
                        <Tag variant="warning-light">
                          <Icon size="sm" as={SvgAlert} />
                          <Text>FORA DA POLÍTICA</Text>
                        </Tag>
                      </Tooltip>
                    </>
                  )}
                </Flex>

                <Row align="center">
                  <Col>
                    <Text size="2" variant="dark">
                      Tipo de Despesa
                    </Text>
                    <Text css={{ fw: "600", display: "block", mt: "$2" }}>
                      {expense.expenseType.name}
                    </Text>
                  </Col>

                  <Col>
                    <Text size="2" variant="dark">
                      Estabelecimento
                    </Text>
                    <Text css={{ fw: "600", display: "block", mt: "$2" }}>
                      {expense.company}
                    </Text>
                  </Col>

                  <Col sz="auto">
                    <Flex gap="4">
                      <Col>
                        <Text size="2" variant="dark">
                          Valor
                        </Text>
                        <Text
                          css={{
                            fw: "600",
                            display: "block",
                            mt: "$2",
                          }}
                        >
                          {MaskUtils.asCurrency(expense.value)}
                        </Text>
                      </Col>

                      <Col>
                        {expense.voucherPath && (
                          <Flex>
                            <Tooltip
                              content={
                                <TooltipLabel>
                                  Visualizar Comprovante
                                </TooltipLabel>
                              }
                            >
                              <IconButton
                                size="md"
                                css={{ m: "$0", width: "auto" }}
                                onClick={() => onShowVoucher(expense)}
                              >
                                <Icon as={SvgFile} />
                              </IconButton>
                            </Tooltip>
                            <CounterTabBadge
                              css={{ alignSelf: "start", p: "$1" }}
                            >
                              1
                            </CounterTabBadge>
                          </Flex>
                        )}
                      </Col>
                    </Flex>
                  </Col>
                </Row>
              </Flex>
            </CardBody>

            {expense.justification && expense.justification?.length > 0 && (
              <>
                <Divider />

                <CardBody>
                  <Flex gap="3">
                    <Avatar />
                    <Flex gap="2" direction="column">
                      <Text size="3" css={{ fw: 600 }}>
                        Você
                      </Text>
                      <Text size="2" variant="dark">
                        {DateUtils.getTimeFromNow(expense.expenseDate)}
                      </Text>
                      <Text
                        as="p"
                        variant="darkest"
                        css={{ mt: "$2", lineHeight: 1.5 }}
                      >
                        {expense.justification}
                      </Text>
                    </Flex>
                  </Flex>
                </CardBody>
              </>
            )}

            {expense.reasonRejected && (
              <ReasonRejectedItem
                reasonRejected={
                  expense.reasonRejected as ReasonRejectedProps["reasonRejected"]
                }
              />
            )}
          </Card>
        );
      })}
    </Fragment>
  );
}

TravelerExpenseListItem.displayName = "TravelerExpenseListItem";
