import { ExpenseType } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { SwitchButton } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface ExpenseTypeListItemProps extends DataListItemProps {
  data: ExpenseType;
  onEditClick: (item: ExpenseType) => void;
  onToggleState: (item: ExpenseType) => void;
}

export function ExpenseTypeListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: ExpenseTypeListItemProps) {
  const isMobile = useMobile();
  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{ "@mxlg": { display: "flex", flexDirection: "column" } }}
    >
      <DataColItem headerTitle="Nome da despesa" data={data.name} />
      <DataColItem headerTitle="Categoria" data={data.expenseCategory.name} />
      {isMobile ? (
        <Flex justify="between" css={{ width: "100%" }}>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
          <SwitchButton data-checked={data.isActive} onClick={() => onToggleState(data)} />
        </Flex>
      ) : (
        <>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
          <SwitchButton data-checked={data.isActive} onClick={() => onToggleState(data)} />
        </>
      )}
    </DataListItem>
  );
}

ExpenseTypeListItem.displayName = "ExpenseTypeListItem";
