import queryString from "query-string";
import type { PolicyParameterExpense } from "~/application/types";
import { api } from "~/infrastructure/api";
import { PolicyParameterExpenseDTO } from "~/infrastructure/api/dtos";
import { mapPolicyParameterExpenseDTO } from "~/infrastructure/api/mappers";
import type { IPolicyParameterExpenseService } from "./IPolicyParameterExpenseService";

export class PolicyParameterExpenseService
  implements IPolicyParameterExpenseService
{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async findById(id: string): Promise<PolicyParameterExpense> {
    throw new Error("Method not implemented.");
  }

  async find(): Promise<PolicyParameterExpense[]> {
    const url = queryString.stringifyUrl({
      url: `/policy-parameter-expenses`,
      query: {},
    });

    return await api
      .get<PolicyParameterExpenseDTO[]>(url)
      .then(({ data }) => data.map(mapPolicyParameterExpenseDTO));
  }

  async create(
    data: Omit<PolicyParameterExpense, "uuid"> // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Promise<PolicyParameterExpense> {
    throw new Error("Method not implemented.");
  }

  async updateById(
    data: PolicyParameterExpense // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Promise<PolicyParameterExpense> {
    throw new Error("Method not implemented.");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async toggleActive(data: PolicyParameterExpense): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
