import { useContext } from "react";
import { FormControlContext } from "../../FormControl/FormControlContext";
import {
  DEFAULT_COMPONENTS,
  SelectBase,
  SelectBaseProps,
} from "../base/SelectBase";
import { SelectChip, SelectChipRemove } from "./SelectChip";
import { SelectControl } from "./SelectControl";

export type MultiSelectProps<TOption = unknown> = SelectBaseProps<TOption>;

const components = {
  ...DEFAULT_COMPONENTS,
  MultiValue: SelectChip,
  MultiValueRemove: SelectChipRemove,
  Control: SelectControl,
};

export const MultiSelect = <TOption,>({
  name: nameProps,
  disabled: disabledProps,
  onBlur: onBlurProps,
  onChange: onChangeProps,
  value: valueProps,
  ...props
}: MultiSelectProps<TOption>) => {
  const formControl = useContext(FormControlContext);

  const name = nameProps ?? formControl.name;
  const disabled = disabledProps ?? formControl.disabled;
  const onBlur = onBlurProps ?? formControl.onBlur;
  const onChange = onChangeProps ?? formControl.onChange;
  const value = valueProps ?? formControl.value;
  const isDirty = !!formControl.error;

  return (
    <SelectBase
      {...props}
      isMulti
      name={name}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      isDirty={isDirty}
      components={components}
    />
  );
};

MultiSelect.displayName = "MultiSelect";
