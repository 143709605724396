import { ElementType, FC, MouseEvent } from "react";
import { Box } from "../Box";
import { SvgFile } from "../Icon/icons";
import { IconButton, IconButtonProps } from "../IconButton";
import { useDownload } from "~/presentation/shared/hooks/useDownload";
import { Order } from "~/application/types";

export interface DownloadParams extends IconButtonProps {
  readonly url: string;
  readonly headers?: object;
  readonly retries: number;
  readonly delay: number;
  readonly icon?: ElementType | null;
  readonly onDownload?: () => void;
  readonly onDownloaded: () => void;
  readonly onIconClick: (event: MouseEvent<HTMLElement>) => void;
}

const defaultHeaders = {
  "Content-Type": "application/pdf",
};

const LOG_TAG = "components/Download";

export const Download: FC<DownloadParams> = ({
  url,
  icon,
  headers = defaultHeaders,
  retries,
  delay,
  onIconClick,
  onDownload,
  onDownloaded,
  ...props
}) => {
  const Icon = icon === null ? null : icon ?? SvgFile;

  if (icon === null) {
    return <Box onClick={onDownload}>{props.children}</Box>;
  }

  return (
    <IconButton {...props} onClick={onDownload}>
      {Icon && <Icon />}
    </IconButton>
  );
};

Download.displayName = "Download";
