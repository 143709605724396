import { useQuery } from "@tanstack/react-query";
import { RemakeSearchData, Traveler } from "~/application/types";
import { orderService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys } from "~/constants";
import { ErrorRemakeSearchDialog } from "../../components/ErrorRemakeSearchDialog";
import { UseFetchRoadQuery } from "./types";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";

export function useFetchRoadQuery({ onCancelOrder }: UseFetchRoadQuery) {
  const { setState: setRemakerSearch } = useRemakeSearch();
  return (orderId: string, travelers: Traveler[], oldOrderId: string) => {
    const { refetch, isLoading } = useQuery<RemakeSearchData>(
      [QueryKeys.REORDER, orderId],
      async () => await orderService.remakeSearch(orderId),
      {
        enabled: false,
        onSuccess: (data) => {
          if (Object.values(data).some((value) => value.length)) {
            setRemakerSearch({
              data,
              finishStep: {
                airway: false,
                road: false,
              },
              travelers,
              oldOrderId,
              orderId,
            });

            return;
          }

          dialogService.showDialog(<ErrorRemakeSearchDialog />);
        },
        onError: (error) => {
          dialogService.showDialog(<ErrorRemakeSearchDialog />);
          onCancelOrder(orderId);
        },
      }
    );

    return { refetch, isLoading };
  };
}
