import { State } from "~/application/types";
import { StateDTO } from "../dtos";

export function mapStateDTO(data: StateDTO): State {
  return {
    // TODO: The api should return the name
    name: "",
    state: data,
  };
}
