import { useCallback, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { DeepPartial, OfflineHotelRoom, PaginatedResource } from "~/application/types";
import { 
  CreateOfflineHotelRoomProps, 
  DeleteOfflineHotelRoomImageProps, 
  offlineHotelRoomService, 
  UpdateOfflineHotelRoomProps 
} from "~/application/usecases/OfflineHotelRoom";
import { offlineHotelAmenityService } from "~/application/usecases/OfflineHotelAmenityService";
import { CreateOfflineHotelRoomDialog } from "./components/CreateOfflineHotelRoomDialog";
import { EditOfflineHotelRoomDialog } from "./components/EditOfflineHotelRoomDialog";
import { OfflineHotelRoomsContainer } from "./OfflineHotelRoomsContainer";
import { snackbarService } from "~/components/SnackbarStack";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { logError } from "~/presentation/shared/utils/errors";
import { queryClient } from "~/services/queryClient";

const LOG_TAG = "Agency/OfflineHotelRoomsPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar quartos",
  CREATE_SUCCESS_MESSAGE: "Quarto criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar quarto",
  UPDATE_SUCCESS_MESSAGE: "Quarto atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar quarto",
  LOAD_AMENITIES_ERROR_MESSAGE: "Falha ao listar as necessidades mínimas",
  DELETE_IMAGE_SUCCESS_MESSAGE: "Foto deletada",
  DELETE_IMAGE_ERROR_MESSAGE: "Falha ao deletar foto",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<OfflineHotelRoom> = {
  description: "",
  regime: "",
  type: "",
  adultsAmount: 1,
};

export function OfflineHotelRoomsPage() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { offlineHotelId } = useParams() as { offlineHotelId: string };

  const { data } = useQuery<PaginatedResource<OfflineHotelRoom[]>>(
    [QueryKeys.OFFLINE_HOTEL_ROOMS, offlineHotelId],
    () => offlineHotelRoomService.find({ offlineHotelId, page: currentPage }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { data: amenities, isLoading: isLoadingAmenities } = useQuery(
    [QueryKeys.AMENITIES],
    () => offlineHotelAmenityService.getAll(),
    {
      staleTime: QueryTimes.NORMAL,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_AMENITIES_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_AMENITIES_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: createOfflineHotelRoom } = useMutation(
    ({ data, images }: Omit<CreateOfflineHotelRoomProps, "offlineHotelId">) => {
      return offlineHotelRoomService.create({ offlineHotelId, data, images });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.OFFLINE_HOTEL_ROOMS]);
        
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: updateOfflineHotelRoom } = useMutation(
    ({ data, images }: UpdateOfflineHotelRoomProps) => {
      return offlineHotelRoomService.updateById({ data, images });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.OFFLINE_HOTEL_ROOMS]);
        
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: deleteOfflineHotelRoomImage } = useMutation(
    ({ offlineHotelRoomId, imageId }: DeleteOfflineHotelRoomImageProps) => {
      return offlineHotelRoomService.deleteImage({ offlineHotelRoomId, imageId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.OFFLINE_HOTEL_ROOMS]);
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.DELETE_IMAGE_ERROR_MESSAGE,
        });
      },
    }
  );

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <CreateOfflineHotelRoomDialog
        onSubmit={createOfflineHotelRoom}
        defaultData={DEFAULT_FORM_DATA}
        amenities={amenities}
        isLoadingAmenities={isLoadingAmenities}
      />
    );
  }, [amenities]);

  const handleOpenEditModal = useCallback((data: OfflineHotelRoom) => {
    dialogService.showDialog(
      <EditOfflineHotelRoomDialog
        onSubmit={updateOfflineHotelRoom}
        defaultData={data}
        amenities={amenities}
        isLoadingAmenities={isLoadingAmenities}
        onDeleteOfflineHotelRoomImage={deleteOfflineHotelRoomImage}
      />
    );
  }, [amenities]);

  return (
    <OfflineHotelRoomsContainer
      offlineHotelRooms={data?.data}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      onCreateOfflineHotelRoom={handleOpenCreateModal}
      onEditOfflineHotelRoom={handleOpenEditModal}
      onGoToPage={setCurrentPage}
    />
  );
}
