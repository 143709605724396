import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import { EditOfflineHotelContainer } from "./EditOfflineHotelContainer";
import { stateService } from "~/application/usecases";
import { ApiError, OfflineHotel, State } from "~/application/types";
import { DeleteOfflineHotelImageProps, offlineHotelService, UpdateOfflineHotelProps } from "~/application/usecases/OfflineHotel";
import { queryClient } from "~/services/queryClient";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { snackbarService } from "~/components/SnackbarStack";
import { logError } from "~/presentation/shared/utils/errors";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";

const LOG_TAG = "Agency/EditOfflineHotelPage";

const SNACKBAR_MESSAGES = {
  EDIT_SUCCESS_MESSAGE: "Hotel offline editado",
  EDIT_ERROR_MESSAGE: "Falha ao editar hotel offline",
  DELETE_IMAGE_SUCCESS_MESSAGE: "Foto deletada",
  DELETE_IMAGE_ERROR_MESSAGE: "Falha ao deletar foto",
  LOAD_ERROR_MESSAGE: "Falha ao carregar hotel offline"
} as const;

export function EditOfflineHotelPage() {
  const { offlineHotelId } = useParams() as { offlineHotelId: string };
  const navigate = useNavigate();
  const { user } = useUser();

  const fetchCitiesByState = useCallback(
    (state: State) =>
      stateService
        .findCities(state)
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const { data: offlineHotel, isFetching: isFetchingOfflineHotel } = useQuery<OfflineHotel, ApiError>(
    [QueryKeys.OFFLINE_HOTEL, offlineHotelId],
    () => offlineHotelService.findById({ agencyId: user.agency.uuid, offlineHotelId }),
    {
      staleTime: QueryTimes.NONE,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { mutate: editOfflineHotel, isLoading: isEditingOfflineHotel } = useMutation(
    ({ data, image, images }: UpdateOfflineHotelProps) => {
      return offlineHotelService.updateById({ data, image, images });
    },
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Editando hotel offline" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.OFFLINE_HOTELS]);

        navigate(`/configuracoes/hoteis-offline`);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.EDIT_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.EDIT_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: deleteOfflineHotelImage } = useMutation(
    ({ offlineHotelId, imageId }: DeleteOfflineHotelImageProps) => {
      return offlineHotelService.deleteImage({ offlineHotelId, imageId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.OFFLINE_HOTEL, offlineHotelId]);
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.DELETE_IMAGE_ERROR_MESSAGE,
        });
      },
    }
  );

  return (
    <EditOfflineHotelContainer 
      offlineHotel={offlineHotel}
      isFetchingOfflineHotel={isFetchingOfflineHotel}
      fetchCitiesByState={fetchCitiesByState}
      editOfflineHotel={editOfflineHotel}
      isEditingOfflineHotel={isEditingOfflineHotel}
      onDeleteOfflineHotelImage={deleteOfflineHotelImage}
    />
  );
}