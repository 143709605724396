import { FC } from "react";
import { DeleteOrderItemDialogProps } from "./types";
import { SvgAlertTriangle } from "~/components/Icon/icons";
import { CancelOrderDialog } from "../CancelOrderDialog/CancelOrderDialog";
import { Box } from "~/components/Box";

export const DeleteOrderItemDialog: FC<DeleteOrderItemDialogProps> = ({
  children,
  onConfirm,
  onCloseClick,
}) => {
  return (
    <CancelOrderDialog
      title="Seu pedido será cancelado"
      textCancelation="Excluir mesmo assim"
      onConfirm={onConfirm}
      onCloseClick={onCloseClick}
      svg={SvgAlertTriangle}
    >
      {children ? (
        children
      ) : (
        <Box as="span" css={{ "@mxmd": {
          fontSize: "$sm",
        }}}>
          Ao excluir todos os itens, o seu pedido será cancelado
          automaticamente. Esta ação é irreversível.
          <Box css={{ mt: "$2" }}>Deseja continuar?</Box>
        </Box>
      )}
    </CancelOrderDialog>
  );
};

DeleteOrderItemDialog.displayName = "DeleteOrderItemDialog";
