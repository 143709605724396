import { OrderMessage } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { OrderMessageDTO } from "../dtos";

export function mapOrderMessageDTO(data: OrderMessageDTO): OrderMessage {
  const sender = data.agency_employee || data.customer_employee;

  return {
    uuid: data.uuid,
    orderId: data.order_uuid,
    message: data.message,
    sender: {
      uuid: sender?.uuid as string,
      fullName: sender?.full_name as string,
      avatar: sender?.avatar ?? undefined,
      agencyName: data.agency_employee?.agency_name ?? "",
      customerName: data.customer_employee?.customer_name ?? "",
    },
    notify: data.notify,
    createdAt: DateUtils.toDate(data.created_at),
  };
}
