import { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { SvgKey } from "~/components/Icon/icons";
import { MaskedInput, NumberInput, Select, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { createCard, editCard } from "./utils";
import { EditableCard } from "~/application/usecases/CreditCard";
import { CardFlag } from "~/application/types/entities/CardFlag.type";
import { MaskUtils } from "~/application/utils";
import useMobile from "~/presentation/shared/hooks/useMobile";

export type CardDialogProps = {
  defaultData?: EditableCard;
  cardFlags?: CardFlag[];
  onCloseClick?: () => void;
  onSubmit: (data: EditableCard) => void;
};

export const CardDialog: FC<CardDialogProps> = ({
  defaultData,
  cardFlags,
  onCloseClick,
  onSubmit: onSubmitProp,
}) => {
  const isNew = !defaultData?.number;
  const isMobile = useMobile();

  const defaultValue = editCard(defaultData);

  const { control, formState, handleSubmit, watch, setError } = useForm<EditableCard>({
    defaultValues: defaultValue,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { flag } = watch();
  const onSubmit = useCallback(
    (formData: EditableCard) => onSubmitProp?.(createCard(formData)),
    [onSubmitProp]
  );

  const handleSubmitCredit = (data: EditableCard) => {
    const mouth = Number(data.validity.split("/")[0]);

    if (data.validity.length < 7 || mouth > 12) {
      setError("validity", {
        type: "min",
        message: "",
      });
      return;
    }
    onSubmit(data);
    setError("validity", {
      type: "min",
      message: "",
    });
  };

  const maskCardNumber = useMemo(() => {
    switch (flag?.name) {
      case "Visa": {
        return MaskUtils.MASK_CARD_NUMBER_VISA;
      }
      case "Amex": {
        return MaskUtils.MASK_CARD_NUMBER_AMERICAN;
      }
      case "diners": {
        return MaskUtils.MASK_CARD_NUMBER_DINERS;
      }
      case "hipercard": {
        return MaskUtils.MASK_CARD_NUMBER_HIPERCARD;
      }
      case "elo": {
        return MaskUtils.MASK_CARD_NUMBER_ELO;
      }
      case "Mastercard":
        return MaskUtils.MASK_CARD_NUMBER_MASTERCARD;
    }
  }, [flag]);

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(handleSubmitCredit)}>
        <FormDialog
          title={isNew ? "Novo cartão" : "Editar cartão"}
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col sz={isMobile ? "12" : "6"}>
                <FormControl name="owner" control={control} required>
                  <FieldLabel>Dono</FieldLabel>
                  <TextInput placeholder="Digite o nome do dono" />
                </FormControl>
              </Col>

              <Col sz={isMobile ? "12" : "6"}>
                <FormControl name="flag" control={control} required>
                  <FieldLabel>Bandeira</FieldLabel>
                  <Select
                    options={cardFlags}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.uuid}
                    placeholder="Digite a bandeira do cartão"
                  />
                </FormControl>
              </Col>

              <Col sz={isMobile ? "12" : "6"}>
                <FormControl name="internalCardCode" control={control}>
                  <FieldLabel>Código interno</FieldLabel>
                  <TextInput leftIcon={SvgKey} placeholder="Digite o código interno" />
                </FormControl>
              </Col>

              <Col sz={isMobile ? "12" : "6"}>
                <FormControl name="number" control={control} required>
                  <FieldLabel>Numero</FieldLabel>
                  <MaskedInput
                    mask={maskCardNumber || ""}
                    placeholder="Digite o número do cartão"
                  />
                </FormControl>
              </Col>

              <Col sz={isMobile ? "12" : "6"}>
                <FormControl name="securityCode" control={control} required>
                  <FieldLabel>Código de segurança</FieldLabel>
                  <TextInput placeholder="Digite o código de segurança" maxLength={4} />
                </FormControl>
              </Col>

              <Col sz={isMobile ? "12" : "6"}>
                <FormControl name="validity" control={control} required>
                  <FieldLabel>Validade</FieldLabel>
                  <MaskedInput
                    mask={MaskUtils.MASK_MOUTH_DAY}
                    placeholder="Digite a validade do cartão mês/dia"
                  />
                </FormControl>
              </Col>

              <Col>
                <FormControl name="description" control={control} required>
                  <FieldLabel>Descrição</FieldLabel>
                  <TextInput placeholder="Digite a descrição" />
                </FormControl>
              </Col>

              <Col>
                <FormControl name="installments" control={control} required>
                  <FieldLabel>Quantidade de parcelas</FieldLabel>
                  <NumberInput
                    thousandSeparator=""
                    decimalScale={0}
                    allowNegative={false}
                    placeholder="Digite a quantidade de parcelas"
                  />
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

CardDialog.displayName = "CardDialog";
