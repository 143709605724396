import { createRef, useCallback, useState } from "react";
import { styled } from "~/application/theme";
import { Button } from "~/components/Button";
import { CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { FormDialog } from "~/components/FormDialog";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgChevronRight } from "~/components/Icon/icons";
import { Image } from "~/components/Image";
import { HotelImage } from "../HotelImage";

const HotelImageItem = styled(HotelImage, {
  size: "$20",

  "&:hover": {
    cursor: "pointer",
  },

  "&[data-active=true]": {
    borderWidth: "4px",
    borderStyle: "solid",
    borderColor: "$primary-base",
  },
});

export type HotelGalleryDialogProps = {
  images: string[];
  onCloseClick?: () => void;
};

export function HotelGalleryDialog({
  images,
  onCloseClick,
}: HotelGalleryDialogProps) {
  const container = createRef<HTMLDivElement>();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleSelectImage = (imageIndex: number) => {
    if (container.current) {
      const child = container.current.children.item(
        imageIndex
      ) as unknown as HTMLDivElement;

      if (container.current.parentElement) {
        container.current.parentElement.scrollTo({
          behavior: "smooth",
          left: child.offsetLeft - 2 * child.clientWidth || 0,
        });
      }
    }
    setActiveIndex(imageIndex);
  };

  const handleIncrease = useCallback(() => {
    handleSelectImage(activeIndex >= images.length - 1 ? 0 : activeIndex + 1);
  }, [activeIndex, handleSelectImage, images]);

  const handleDecrease = useCallback(() => {
    handleSelectImage(activeIndex <= 0 ? images.length - 1 : activeIndex - 1);
  }, [activeIndex, handleSelectImage]);

  return (
    <Container size="12" fixed>
      <FormDialog title="Galeria de fotos" onClickDismissButton={onCloseClick}>
        <DialogBody>
          <Image
            src={images[activeIndex]}
            css={{ width: "100%", aspectRatio: "16/9" }}
            draggable={false}
          />

          <CardBody>
            <Flex align="center" gap="6">
              <Button variant="tertiary" onClick={handleDecrease}>
                <Icon as={SvgChevronLeft} />
              </Button>

              <Flex css={{ flex: "1 0 0%", overflow: "hidden" }}>
                <Flex align="center" gap="6" ref={container}>
                  {images.map((image, imageIndex) => (
                    <HotelImageItem
                      onClick={() => handleSelectImage(imageIndex)}
                      data-active={imageIndex === activeIndex}
                      src={image}
                      key={imageIndex}
                    />
                  ))}
                </Flex>
              </Flex>

              <Button variant="tertiary" onClick={handleIncrease}>
                <Icon as={SvgChevronRight} />
              </Button>
            </Flex>
          </CardBody>
        </DialogBody>
      </FormDialog>
    </Container>
  );
}

HotelGalleryDialog.displayName = "HotelGalleryDialog";
