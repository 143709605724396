export enum OrderItemStatus {
  OPEN = "open",
  APPROVED = "approved",
  REJECTED = "rejected",
  DONE = "done",
  FAILED = "failed",
  FAILED_CANCEL = "failed_cancel",
  CANCELED = "canceled",
  EXPIRED_TIME = "expired_time",
  QUOTING = "quoting",
  QUOTED = "quoted",
}
