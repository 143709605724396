import { OrderItemsWithChangedPrice, OrderItemWithChangedPrice } from "~/application/types";
import { OrderItemsWithChangedPriceDTO, OrderItemWithChangedPriceDTO } from "../dtos";

export const mapOrderItemPriceChangeDTO = ({
  new_value,
  value,
  items_uuid,
}: OrderItemWithChangedPriceDTO): OrderItemWithChangedPrice => ({
  itemsUuid: items_uuid,
  value: parseFloat(value),
  newValue: new_value ? parseFloat(new_value) : undefined,
});

export const mapOrderItemsPriceChangeDTO = (
  data: OrderItemsWithChangedPriceDTO
): OrderItemsWithChangedPrice => {
  return {
    road: data?.road?.map(mapOrderItemPriceChangeDTO),
    airway: data?.airway?.map(mapOrderItemPriceChangeDTO),
    hotel: data?.hotel?.map(mapOrderItemPriceChangeDTO),
    vehicle: data?.vehicle?.map(mapOrderItemPriceChangeDTO),
  };
};
