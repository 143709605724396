import { ReasonTrip } from '~/application/types';
import { ReasonTripDTO } from '../dtos';

export function mapReasonTripDTO(data: ReasonTripDTO): ReasonTrip {
  return {
    uuid: data.uuid,
    reason: data.reason,
    customerId: data.customer_uuid,
    isActive: Boolean(data.active),
  };
}