import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { IconButton } from "~/components/IconButton";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import { OrderItemsServiceProps } from "./types";
import { Icon } from "~/components/Icon";
import { Flex } from "~/components/Flex";

export const OrderItemService = ({ service, orderViolatedPolicies, ...props }: OrderItemsServiceProps) => {
  const hasOrderViolatedPolicies = Boolean(orderViolatedPolicies?.length);

  return (
    <Tooltip
      variant={hasOrderViolatedPolicies ? "warning" : "black"}
      content={
        hasOrderViolatedPolicies ? (
          <Flex direction="column" gap="3">
            <TooltipLabel>{OrderUtils.getServiceLabel(service)}</TooltipLabel>

            <Flex direction="column" gap="1">
              <TooltipLabel>Políticas desrespeitadas:</TooltipLabel>
              <ul style={{ padding: "0 0 0 24px" }}>
                {orderViolatedPolicies?.map((item, i) => (
                  <li key={`${item.message}-${i}`}>
                    <TooltipLabel>{item.message}</TooltipLabel>
                  </li>
                ))}
              </ul>
            </Flex>
          </Flex>
        ) : (
          <TooltipLabel>{OrderUtils.getServiceLabel(service)}</TooltipLabel>
        )
      }
      {...props}
    >
      <IconButton
        size="md"
        css={{
          backgroundColor: hasOrderViolatedPolicies ? "#FFF5EA" : "transparent",
          "&:hover": {
            backgroundColor: hasOrderViolatedPolicies ? "#FFEBD5" : "$neutrals-lightest",
          },
        }}
      >
        <Icon as={OrderUtils.getServiceIcon(service)} variant={hasOrderViolatedPolicies ? "warning-dark" : "dark"} />
      </IconButton>
    </Tooltip>
  );
};

