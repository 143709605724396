import { Collapse } from "react-collapse";
import { Box } from "~/components/Box";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataListItemProps } from "~/components/List";
import { DataListItemMenu } from "~/components/List/DataListItem/DataListItemMenu";
import { Text } from "~/components/Text";
import * as OrderUtils from "~/core/shared/utils/order.utils";

export enum MenuItems {
  SEARCH = "search",
  ORDER = "Pedidos",
  EXPENSE = "expanse",
  CONFIG = "config",
}

export interface MenuItemProps extends DataListItemProps {
  isOpen: boolean;
  orderId?: string;
  item: {
    type: MenuItems;
    isRejected?: boolean;
  };
  onItemExpand?: () => void;
  canceled?: boolean;
}

export function MenuItemList({
  isOpen,
  item,
  onItemExpand,
  children,
  canceled,
  ...props
}: MenuItemProps) {
  return (
    <Box>
      <DataListItemMenu {...props}>
        <Icon
          as={OrderUtils.getServiceMenuIcon(item.type)}
          size="md"
          variant="darkest"
        />

        <Col>
          <Text size="4" css={{ fw: "600" }}>
            {OrderUtils.getServiceMobileLabel(item.type)}
          </Text>
        </Col>

        {!canceled && (
          <IconButton size="md" onClick={onItemExpand}>
            <Icon as={isOpen ? SvgChevronUp : SvgChevronDown} />
          </IconButton>
        )}
      </DataListItemMenu>

      <Collapse isOpened={isOpen}>
        <Box css={{ mt: "$6" }}>{children}</Box>
      </Collapse>
    </Box>
  );
}

MenuItemList.displayName = "OrderItemListItem";
