import { Branch } from "~/application/types";
import { BranchDTO } from "../dtos";

export function mapBranchDTO(data: BranchDTO): Branch {
  return {
    uuid: data.uuid,
    name: data.name,
    phone: data.phone,
    cnpj: data.cnpj,
    email: data.email,
    isActive: data.active,
    integrationId: data.integration_id,
    customerId: data.customer_id,
  };
}
