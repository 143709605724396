import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Actions, OrderItems } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody, CardSectionHeader } from "~/components/Card";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgAirplaneTicket, SvgApproveIcon, SvgReceiptLong } from "~/components/Icon/icons";
import { Link } from "~/components/Link";
import { Tab, TabBar, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { H6 } from "~/components/Typography";
import { getServiceIcon, getServiceLink } from "~/core/shared/utils/order.utils";
import { useBookingHotel } from "../../modules/Booking/modules/BookingHotel/contexts/BookingHotelContext";

import { Col } from "~/components/Grid";
import { DataColItem, DataListItem } from "~/components/List";
import { Spinner } from "~/components/Spinner";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { useBooking } from "../../modules/Booking/contexts/BookingContext";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useSearchParams } from "react-router-dom";

export interface BookingMenuLayoutProps {
  title?: string;
  activeTab?: OrderItems;
  children: React.ReactNode;
}

const BOOKING_MODULES_ITEMS = [
  {
    label: "Avião",
    service: OrderItems.AIRWAY,
  },
  {
    label: "Hotel",
    service: OrderItems.HOTEL,
  },
  {
    label: "Ônibus",
    service: OrderItems.ROAD,
  },
  {
    label: "Carro",
    service: OrderItems.VEHICLE,
  },
];
export function BookingMenuLayout({ children, title, activeTab }: BookingMenuLayoutProps) {
  const { quantityRooms } = useBookingHotel();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const { profile } = useUser();
  const { summary, isFetchingSummary } = useBooking();

  return (
    <Fragment>
      <Helmet>
        <html color-scheme="primary" />
      </Helmet>

      <Tabs value={activeTab}>
        <Box css={{ backgroundColor: "$primary-light" }}>
          <Container size="12">
            <Box
              css={{
                "@mxxl": {
                  width: "80%",
                  margin: "0 auto",
                },
              }}
            >
              <Text
                as="h1"
                size="9"
                variant="primary-dark"
                css={{
                  fw: "700",
                  py: "$14",
                  "@mxlg": {
                    fontSize: "$2xl",
                  },
                }}
              >
                {title}
              </Text>
            </Box>
            <Card
              css={{
                display: "inline-flex",
                borderRadius: "$md $md 0 0",
                marginBottom: -1,
                "@mxlg": {
                  display: "flex",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  marginTop: "0",
                  position: "relative",
                  top: "$8",
                  zIndex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                },
              }}
            >
              <TabBar
                css={{
                  "@mxlg": {
                    display: "flex",
                    backgroundColor: "transparent",
                    justifyContent: "space-between",
                    width: "80%",
                  },
                }}
              >
                {BOOKING_MODULES_ITEMS.map(({ label, service }) => (
                  <Link
                    to={`${getServiceLink(service) || "/"}${orderId ? `?orderId=${orderId}` : ""}`}
                    key={service}
                    css={{
                      "@mxlg": {
                        display: "absolute",
                        padding: "5px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <Tab
                      value={service}
                      css={{
                        "@mxlg": {
                          borderRadius: "50%",
                        },
                      }}
                    >
                      <Icon as={getServiceIcon(service)} />
                      <Text
                        css={{
                          "@mxlg": {
                            display: "none",
                          },
                        }}
                      >
                        {label}
                      </Text>
                    </Tab>
                  </Link>
                ))}
              </TabBar>
            </Card>
          </Container>
        </Box>
        <Container
          size="12"
          css={{
            height: quantityRooms > 2 ? "calc(100vh - 100px)" : "auto",
            transition: "height 0.3s ease-in-out",
          }}
        >
          <Card
            css={{
              backgroundColor: "$neutrals-lightest",
              borderRadius: "0 0 $md $md",
              overflow: "visible",
              "@mxlg": {
                backgroundColor: "inherit",
                border: "0",
              },
            }}
          >
            {children}
          </Card>

          <Flex
            css={{
              mt: "$5",
              height: "350px",
              display: "none",
              "@lg": {
                display: "flex",
              },
            }}
          >
            <Card css={{ width: "90%", mr: "$5" }}>
              <CardSectionHeader
                css={{
                  background: "#FFF",
                  p: "$5",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Flex justify="between" align="center" css={{ width: "150px" }}>
                  <Icon as={SvgAirplaneTicket} />
                  <Text size="3" fw="500" variant="darkest">
                    Próximas viagens
                  </Text>
                </Flex>
              </CardSectionHeader>
              <Card
                css={{
                  borderRadius: "0",
                  border: "0",
                  height: "100vh",
                  background: "$neutrals-lightest",
                }}
              >
                <CardBody>
                  {isFetchingSummary ? (
                    <Flex justify="center" align="center" css={{ mt: "$16" }}>
                      <Spinner />
                    </Flex>
                  ) : (
                    summary?.nextTrips.map((trip) => (
                      <Link to={`/pedidos/${trip.orderId}#open`} key={trip.createdAt}>
                        <DataListItem css={{ mb: "$3", cursor: "pointer" }}>
                          <DataColItem
                            data={
                              <Col>
                                <H6 css={{ mb: "$2" }}>{trip.issuerName}</H6>
                                <Text
                                  size="3"
                                  css={{
                                    display: "block",
                                    color: "#668099",
                                    lineHeight: "$6",
                                  }}
                                >{`#${trip.orderNumber} - ${trip.departure}`}</Text>
                                <Text size="3">{trip.travelers[0].fullName}</Text>
                                {trip.travelers.length > 1 ? (
                                  <Text size="3" css={{ lineHeight: "$6", display: "block" }}>
                                    + {trip.travelers.length - 1}
                                  </Text>
                                ) : null}
                              </Col>
                            }
                          />

                          <DataColItem
                            css={{ alignItems: "center" }}
                            data={
                              <Col>
                                <Text size="4" css={{ color: "$neutrals-dark" }}>
                                  Itens da viagem
                                </Text>
                                <Flex>
                                  {trip.itemIncluded.map((item) => (
                                    <Icon
                                      key={item}
                                      css={{
                                        mt: "$3",
                                        mr: "$2",
                                        fill: "$neutrals-darkest",
                                      }}
                                      as={getServiceIcon(item)}
                                    />
                                  ))}
                                </Flex>
                              </Col>
                            }
                          />

                          <DataColItem
                            data={
                              <Col css={{ color: "#0064C5", ta: "end" }}>
                                <H6 size="4" css={{ mb: "$2" }}>
                                  {displayDate(trip.departureDate, DateFormats.SMALL_DATE)}
                                </H6>
                                <Text
                                  css={{ color: "#668099" }}
                                  size="3"
                                >{`${trip.departure} -> ${trip.arrival}`}</Text>
                              </Col>
                            }
                          />
                        </DataListItem>
                      </Link>
                    ))
                  )}
                </CardBody>
              </Card>
            </Card>
            <Flex direction="column" css={{ width: "35%", height: "350px" }}>
              <Card css={{ width: "100%", height: "200px", mb: "$5" }}>
                <CardSectionHeader
                  css={{
                    background: "#FFF",
                    p: "$5",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Flex justify="between" align="center" css={{ width: "74%" }}>
                    <Icon as={SvgApproveIcon} />
                    <Text size="3" fw="500" variant="darkest">
                      Pendentes de aprovação
                    </Text>
                  </Flex>
                </CardSectionHeader>
                <CardBody css={{ height: "100vh", background: "$neutrals-lightest" }}>
                  <Box css={{ textAlign: "center", color: "#0064C5" }}>
                    {isFetchingSummary ? (
                      <Spinner />
                    ) : (
                      <Link to="/pedidos/meus-pedidos#on_approval">
                        <Text
                          css={{
                            display: "block",
                            fontSize: "$2xl",
                            fw: "bold",
                          }}
                        >
                          {summary?.pendingApprovals}
                        </Text>
                        <Text>Pendentes de aprovação</Text>
                      </Link>
                    )}
                  </Box>
                </CardBody>
              </Card>
              <Card css={{ width: "100%", height: "200px" }}>
                <CardSectionHeader
                  css={{
                    background: "#FFF",
                    p: "$5",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Flex justify="between" align="center" css={{ width: "155px" }}>
                    <Icon as={SvgReceiptLong} />
                    <Text size="3" fw="500" variant="darkest">
                      Pedidos em aberto
                    </Text>
                  </Flex>
                </CardSectionHeader>
                <CardBody css={{ background: "$neutrals-lightest", height: "100%" }}>
                  <Box css={{ textAlign: "center", color: "#0064C5" }}>
                    {isFetchingSummary ? (
                      <Spinner />
                    ) : (
                      <Link to="/pedidos/meus-pedidos">
                        <Text
                          css={{
                            display: "block",
                            fontSize: "$2xl",
                            fw: "bold",
                          }}
                        >
                          {summary?.openOrders}
                        </Text>
                        <Text>Em aberto</Text>
                      </Link>
                    )}
                  </Box>
                </CardBody>
              </Card>
            </Flex>
          </Flex>
        </Container>
      </Tabs>
    </Fragment>
  );
}

BookingMenuLayout.displayName = "BookingMenuLayout";
