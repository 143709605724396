import { Policy } from "~/application/types";
import { PolicyDTO } from "../dtos";

export function mapPolicyDTO(data: PolicyDTO): Policy {
  return {
    uuid: data.uuid,
    name: data.name,
    isActive: data.active,
    customerId: data.customer_uuid,
    values: data.policy_values?.map((value) => ({
      reasonTrip: value.reason_trip,
      parameter: {
        contentType: value.policy_parameter.content_type,
        serviceType: value.policy_parameter.service_type,
        slug: value.policy_parameter.slug,
        uuid: value.policy_parameter.uuid,
      },
      uuid: value.uuid,
      value: value.value,
    })),
  };
}
