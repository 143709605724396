import * as React from "react";
import { SVGProps } from "react";

const SvgCall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="call_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={16}
      height={16}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#call_svg__a)">
      <path
        d="M13.3 14c-1.389 0-2.761-.303-4.117-.908-1.355-.606-2.589-1.464-3.7-2.575-1.11-1.111-1.97-2.345-2.575-3.7C2.303 5.46 2 4.089 2 2.7c0-.2.067-.367.2-.5s.3-.2.5-.2h2.7a.62.62 0 0 1 .417.158.61.61 0 0 1 .216.375l.434 2.334c.022.177.016.327-.017.45a.701.701 0 0 1-.183.316L4.65 7.267c.222.41.486.808.792 1.191.305.384.641.753 1.008 1.109.344.344.706.664 1.083.958.378.294.778.564 1.2.808L10.3 9.767c.1-.1.23-.175.392-.225.16-.05.32-.064.475-.042l2.3.467a.772.772 0 0 1 .383.241c.1.117.15.248.15.392v2.7c0 .2-.067.367-.2.5s-.3.2-.5.2ZM4.017 6l1.1-1.1-.284-1.567H3.35c.056.456.133.906.233 1.35.1.445.245.884.434 1.317Zm5.966 5.967a8.275 8.275 0 0 0 2.684.666v-1.466L11.1 10.85l-1.117 1.117Z"
        fill="#0064C5"
      />
    </g>
  </svg>
);

export default SvgCall;
