import { Order } from "~/application/types";
import { Avatar, AvatarGroup } from "~/components/Avatar";
import { Icon } from "~/components/Icon";
import { SvgChevronRight, SvgExternalLink } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { ExternalLink, Link } from "~/components/Link";
import {
  DataColItem,
  DataListItem,
  DataListItemProps,
} from "~/components/List/DataListItem";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as MaskUtils from "~/utils/mask.utils";

export interface AdvanceListItemProps extends DataListItemProps {
  data: Order;
}

export function AdvanceListItem({ data, ...props }: AdvanceListItemProps) {
  return (
    <DataListItem {...props}>
      <DataColItem
        headerTitle="Pedido"
        data={
          <Tooltip content={<TooltipLabel>Ver em pedidos</TooltipLabel>}>
            <ExternalLink to={`/pedidos/${data.uuid}`} as={Link}>
              <Text css={{ fw: "600", mr: "$2" }}>{data.orderNumber}</Text>
              <Icon as={SvgExternalLink} size="sm" />
            </ExternalLink>
          </Tooltip>
        }
      />
      <DataColItem
        headerTitle="Funcionários"
        data={
          <AvatarGroup cap={5}>
            {data.travelers.map((person) => (
              <Tooltip
                key={`${data.uuid}-${person.uuid}`}
                content={<TooltipLabel>{person.fullName}</TooltipLabel>}
              >
                <Avatar src={person.avatarUrl} alt={person.fullName} />
              </Tooltip>
            ))}
          </AvatarGroup>
        }
      />
      <DataColItem
        css={{ alignItems: "flex-end" }}
        headerTitle="Valor"
        data={
          <Text css={{ fw: "600" }} size="6">
            {MaskUtils.asCurrency(data.totalValue)}
          </Text>
        }
      />
      <Tooltip content={<TooltipLabel>Detalhes do pedido</TooltipLabel>}>
        <Link to={`/pedidos/${data.uuid}`}>
          <IconButton size="md">
            <Icon as={SvgChevronRight} />
          </IconButton>
        </Link>
      </Tooltip>
    </DataListItem>
  );
}

AdvanceListItem.displayName = "AdvanceListItem";
