import { Fragment, useCallback, useState } from "react";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { TabCustomerCrmProps } from "./types";
import { CustomerCrmListItem } from "./components/CustomerCrmListItem";
import { Box } from "~/components/Box";
import { CustomerCRM } from "~/application/types/entities/CustomerCrm.type";

export function TabCustomerCrm({
  data,
  isLoading,
  onToggle,
  onOpenDialog,
  onEditClick
}: TabCustomerCrmProps) {

  const [collapsedItems, setCollapsedItems] = useState<Record<string, boolean>>({});

  const toggleCollapse = (id: string) => {
    setCollapsedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const listRenderer = useCallback(
    (item: CustomerCRM) => {
      return (
        <CustomerCrmListItem
          checked={item.isActive}
          isCollapse={collapsedItems[item.uuid]}
          onToggleCollapse={toggleCollapse}
          data={item}
          onEditClick={onEditClick}
          key={item.serviceType}
          onToggle={onToggle}
      />
      )
    },
    [data, isLoading, onToggle, toggleCollapse, collapsedItems]
  );

  return (
    <Fragment>
      <Card>
        <CardBody css={{ borderBottom: "1px solid $colors$neutrals-light" }}>
          <Row align="center">
            <Col>
              <H4>CRM</H4>
            </Col>
            <Col
              sz="auto"
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <Button
                css={{
                  "@mxlg": {
                    width: "100%",
                  },
                }}
                title="Novo CRM"
                color="primary"
                onClick={onOpenDialog}
              >
                <Icon as={SvgPlus} />
                <Text>Novo CRM</Text>
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Box css={{ my:"$5" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui CRM cadastradas</Text>
            </EmptyState>
          }
        />
      </Box>
    </Fragment>
  );
}
