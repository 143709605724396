import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { contentStyle } from "./contentStyle";

export type DropdownMenuContentProps = ComponentProps<
  typeof DropdownMenuPrimitive.Content
>;

const ContentRoot = styled(DropdownMenuPrimitive.Content, {
  ...contentStyle,
});

export const DropdownMenuContent = ({
  children,
  ...props
}: DropdownMenuContentProps) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <ContentRoot sideOffset={8} {...props}>
        {children}
      </ContentRoot>
    </DropdownMenuPrimitive.Portal>
  );
};

DropdownMenuContent.displayName = "DropdownMenuContent";
