import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { OrderRoadSegment } from "~/application/types";
import { orderRoadService, orderService } from "~/application/usecases";
import { ICreateOrderRoadData } from "~/application/usecases/OrderRoad";
import { log } from "~/application/utils/log";
import { Button } from "~/components/Button";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { Text } from "~/components/Text";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { useCreateOrder } from "~/presentation/shared/hooks/useCreateOrder";
import { consultOrderItemsPriceChange } from "~/presentation/shared/utils";
import { logError } from "~/presentation/shared/utils/errors";
import { queryClient } from "~/services/queryClient";
import { CreateOrderRoadParams, UseCreateOrderRoadProps, UseCreateOrderRoadReturn } from "./types";
import { useBrokenPolicyJustification } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";

const SNACKBAR_MESSAGES = {
  CREATE_ERROR_MESSAGE: "Falha ao efetuar sua reserva",
  LOAD_ERROR_MESSAGE: "Falha ao carregar pedido",
} as const;

export function useCreateRoadBooking({
  customerId,
  bookingInfo,
  lowerFareViolated,
  roadQuery,
}: UseCreateOrderRoadProps): UseCreateOrderRoadReturn {
  const navigate = useNavigate();
  const issuerId = bookingInfo.issuerId;
  const { brokenPolicyItems } = useBrokenPolicyJustification();

  const { LOG_TAG } = useLogTag();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { data: order } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      staleTime: QueryTimes.LONGEST,
      refetchOnWindowFocus: false,
      enabled: !!orderId,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { create: createOrderAsync } = useCreateOrder({
    customerId,
    issuerId,
    reasonTripId: roadQuery?.reasonTrip?.uuid,
  });

  const { mutateAsync: mutateCreateRoadAsync } = useMutation(
    (params: ICreateOrderRoadData) => orderRoadService.create(params),
    {
      onSuccess: (_, params) => {
        log.i(LOG_TAG, `Successfully created OrderRoad(order:${params.orderId})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS, params.orderId]);
      },
    }
  );

  const onRemoveRoadOrders = useCallback(
    async (orderId: string, roadOrders: OrderRoadSegment[]) => {
      dialogService.popAll();

      await Promise.all(roadOrders.map(({ uuid }) => orderRoadService.delete(uuid)));

      navigate(`/pedidos/${orderId}`);
    },
    []
  );

  const onContinue = useCallback((orderId: string) => {
    dialogService.popAll();
    navigate(`/pedidos/${orderId}`);
  }, []);

  const createRoadBookingAsync = useCallback(
    async ({ goRoad, returnRoad, travelers, stretches }: CreateOrderRoadParams) => {
      let orderTarget = order;
      let roadOrders = [] as OrderRoadSegment[];

      try {
        let orderId = orderTarget?.uuid as string;

        if (!orderTarget) {
          orderTarget = await createOrderAsync({
            travelersIds: travelers.map(({ uuid }) => uuid),
          });
          orderId = orderTarget.uuid;
        }

        if (!stretches.length) {
          const goPolicyJustification = brokenPolicyItems.find(
            (brokenPolicyItem) => brokenPolicyItem.uuid === goRoad.road.id
          )?.justification;
          const returnPolicyJustification = brokenPolicyItems.find(
            (brokenPolicyItem) => brokenPolicyItem.uuid === returnRoad?.road?.id
          )?.justification;

          roadOrders = await mutateCreateRoadAsync({
            orderId: orderTarget.uuid,
            goRoad,
            returnRoad,
            travelers,
            lowerFareViolated,
            brokenPolicyJustification: {
              goPolicyJustification,
              returnPolicyJustification,
            },
          });
        } else {
          const roadPromises = stretches?.map((goRoad) => {
            const goPolicyJustification = brokenPolicyItems.find(
              (brokenPolicyItem) => brokenPolicyItem.uuid === goRoad.road.id
            )?.justification;

            return mutateCreateRoadAsync({
              orderId: orderTarget?.uuid as string,
              goRoad,
              returnRoad,
              travelers,
              lowerFareViolated,
              brokenPolicyJustification: {
                returnPolicyJustification: undefined,
                goPolicyJustification,
              },
            });
          });
          const promisesArray = await Promise.all<OrderRoadSegment[]>(roadPromises || []);
          roadOrders = promisesArray.flat();
        }

        const positiveButton = (
          <Button type="submit" onClick={() => onContinue(orderId)}>
            <Text>Prosseguir</Text>
          </Button>
        );

        const negativeButton = (
          <Button
            variant="tertiary"
            type="reset"
            onClick={() => onRemoveRoadOrders(orderId, roadOrders)}
          >
            <Text>Cancelar</Text>
          </Button>
        );

        const { onOrderItemsPriceChange } = consultOrderItemsPriceChange({
          negativeButton,
          positiveButton,
        });

        const orderItems = await onConsultOrderItemsPrice(orderId);

        const someRoadIncreasedPrice = orderItems?.road?.some(({ newValue }) => newValue);

        if (someRoadIncreasedPrice) {
          onOrderItemsPriceChange(orderItems);
        } else onContinue(orderId);
      } catch (error: any) {
        if (!(error instanceof TypeError)) {
          snackbarService.showSnackbar(error.message, "error");
        }
      }

      return orderTarget?.uuid as string;
    },
    [
      order,
      mutateCreateRoadAsync,
      createOrderAsync,
      onContinue,
      onRemoveRoadOrders,
      lowerFareViolated,
      brokenPolicyItems,
    ]
  );

  const { mutateAsync: onConsultOrderItemsPrice } = useMutation(
    async (orderId: string) => {
      return await orderService.getOrderItemsPriceChange(orderId);
    },
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Verificando alteração de preço" />);
      },
      onError: (error) => log.e(LOG_TAG, error),
      onSettled: () => dialogService.popAll(),
    }
  );

  const { isLoading, mutateAsync: mutateCreateAsync } = useMutation(
    (params: CreateOrderRoadParams) => createRoadBookingAsync(params),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
    }
  );

  return {
    create: mutateCreateAsync,
    isCreating: isLoading,
  };
}
