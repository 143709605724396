import * as React from "react";
import { SVGProps } from "react";

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m7.243 16.876.002-.625h-.002v.625Zm-4.118-4.117h.625v-.003l-.625.003Zm.18-.438-.442-.442-.001.001.443.441Zm9.376-9.375.442.441.003-.003-.445-.438Zm.445-.187v.625-.625Zm.445.187-.445.438.003.003.442-.442Zm3.485 3.484-.442.442.003.003.439-.445Zm0 .89-.439-.445-.003.004.442.442ZM7.68 16.697l.441.443h.001l-.442-.443Zm3.388-12.138a.625.625 0 0 0-.884.884l.884-.884Zm3.49 5.26a.625.625 0 0 0 .885-.884l-.884.884ZM7.244 16.25H3.75v1.25h3.493v-1.25Zm-3.493 0-.884.884c.235.234.553.366.884.366v-1.25Zm0 0H2.5c0 .331.132.65.366.884l.884-.884Zm0 0v-3.492H2.5v3.492h1.25Zm0-3.495v.003l-1.157-.475a1.242 1.242 0 0 0-.093.477l1.25-.005Zm0 .003a.007.007 0 0 1-.002.003l-.886-.882a1.243 1.243 0 0 0-.269.404l1.157.475Zm-.003.004 9.375-9.376-.883-.883-9.376 9.375.884.884Zm9.379-9.379-.483-1.153a1.251 1.251 0 0 0-.408.276l.89.877Zm0 0v-1.25c-.166 0-.33.033-.483.097l.483 1.153Zm0 0 .483-1.153a1.25 1.25 0 0 0-.483-.097v1.25Zm0 0 .89-.877a1.25 1.25 0 0 0-.407-.276l-.483 1.153Zm.003.003 3.485 3.485.884-.884-3.485-3.484-.884.883Zm3.488 3.488 1.153-.482a1.252 1.252 0 0 0-.276-.408l-.877.89Zm0 0h1.25c0-.165-.033-.33-.097-.482l-1.153.482Zm0 0 1.153.483a1.25 1.25 0 0 0 .097-.483h-1.25Zm0 0 .877.891c.118-.116.212-.255.276-.408l-1.153-.483Zm-.003.004-9.376 9.375.884.884 9.376-9.375-.884-.884Zm-9.375 9.374.003-.001.475 1.156c.15-.062.288-.153.404-.269l-.882-.886Zm.003-.001.003-.001-.005 1.25c.164 0 .326-.031.477-.093l-.475-1.156Zm2.942-10.81 4.375 4.376.884-.884-4.375-4.376-.884.884Z" />
  </svg>
);

export default SvgEdit;
