import { useCallback, useState } from "react";

export interface UseHotelItemResult {
  isHotelItemExpanded: boolean;
  onOpenHotelDetails: () => void;
  onOpenHotelPolicies: () => void;
  toggleHotelItemVisible: () => void;
}

export interface UseHotelItemOptions {
  enabled: boolean;
  orderId: string;
}

export function useHotelItem({
  orderId,
  enabled, // eslint-disable-line @typescript-eslint/no-unused-vars
}: UseHotelItemOptions): UseHotelItemResult {
  const [isHotelItemExpanded, setHotelItemExpanded] = useState(true);

  // TODO: Open hotel details modal
  const onOpenHotelDetails = useCallback(() => null, []);

  // TODO: Open hotel policies modal
  const onOpenHotelPolicies = useCallback(() => null, []);

  const toggleHotelItemVisible = useCallback(() => {
    setHotelItemExpanded((old) => !old);
  }, []);

  return {
    isHotelItemExpanded,
    toggleHotelItemVisible,
    onOpenHotelDetails,
    onOpenHotelPolicies,
  };
}
