import queryString from "query-string";
import { useMemo } from "react";
import { Order, OrderItems, OrderStatus } from "~/application/types";
import { Button } from "~/components/Button";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgPlus } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { DataListItem, DataListItemProps } from "~/components/List";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as OrderUtils from "~/core/shared/utils/order.utils";

export interface OrderAddItemListItemProps extends DataListItemProps {
  item: OrderItems;
  order: Order;
  onAddItem?: () => void;
}

export function OrderAddItemListItem({
  item,
  order,
  onAddItem,
  ...props
}: OrderAddItemListItemProps) {
  const orderId = order.uuid;
  const linkToAddItem = useMemo(() => {
    const serviceUrl = OrderUtils.getServiceLink(item);

    if (serviceUrl) {
      return queryString.stringifyUrl({
        url: serviceUrl,
        query: {
          orderId,
        },
      });
    }
  }, [item, orderId]);

  return (
    <DataListItem {...props}>
      <Icon as={OrderUtils.getServiceIcon(item)} size="lg" variant="primary" />

      <Col>
        <Text size="5" css={{ fw: "600" }}>
          {OrderUtils.getServiceLabel(item)}
        </Text>
      </Col>

      {order.status !== OrderStatus.WAITING &&
        (onAddItem || !linkToAddItem ? (
          <Button
            css={{
              "@mxlg": {
                height: "$8",
                p: "$3",
              },
            }}
            variant="tertiary"
            onClick={onAddItem}
          >
            <Icon
              as={SvgClose}
              css={{
                fill: "$neutrals-dark",
                transform: "rotate(45deg)",
              }}
            />
            <Text
              size="2"
              variant="dark"
              css={{
                mt: "$1",
                "@mxlg": {
                  display: "none",
                },
              }}
            >
              Adicionar item
            </Text>
          </Button>
        ) : (
          <Link to={linkToAddItem}>
            <Button
              css={{
                "@mxlg": {
                  height: "$8",
                  p: "$2",
                },
              }}
              variant="tertiary"
              onClick={onAddItem}
            >
              <Icon
                as={SvgClose}
                css={{
                  fill: "$neutrals-dark",
                  transform: "rotate(45deg)",
                }}
              />
              <Text
                size="2"
                variant="dark"
                css={{
                  mt: "$1",
                  "@mxlg": {
                    display: "none",
                  },
                }}
              >
                Adicionar item
              </Text>
            </Button>
          </Link>
        ))}
    </DataListItem>
  );
}

OrderAddItemListItem.displayName = "OrderAddItemListItem";
