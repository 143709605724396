import { ViolatedPolicy } from "~/application/types";
import { ViolatedPolicyDTO } from "../dtos";

export const mapViolatedPoliciesDTO = (data: ViolatedPolicyDTO[]): ViolatedPolicy[] => {
  return data?.map((policy) => ({
    message: policy.message,
    policyType: policy.policy_type,
    serviceType: policy.service_type,
    value: policy.policy_value,
  }));
};
