import queryString from "query-string";
import type { AgencyEmployee, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { AgencyEmployeeDTO } from "~/infrastructure/api/dtos";
import { mapAgencyEmployeeDTO } from "~/infrastructure/api/mappers";
import type { IAgencyEmployeeService, IFindAgencyEmployeeParams } from "./IAgencyEmployeeService";

export class AgencyEmployeeService implements IAgencyEmployeeService {
  async findById(id: string): Promise<AgencyEmployee> {
    const url = `/agencies/agency-employees/${id}`;

    return await api.get<AgencyEmployeeDTO>(url).then(({ data }) => mapAgencyEmployeeDTO(data));
  }

  async find({
    page = 1,
    agencyId,
  }: IFindAgencyEmployeeParams): Promise<PaginatedResource<AgencyEmployee[]>> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/agency-employees`,
      query: { page },
    });

    return await api.get<PaginatedResource<AgencyEmployeeDTO[]>>(url).then(({ data }) => ({
      links: data.links,
      meta: data.meta,
      data: data.data.map(mapAgencyEmployeeDTO),
    }));
  }

  async create(data: Omit<AgencyEmployee, "uuid">): Promise<AgencyEmployee> {
    const url = `/agencies/${data.agencyId}/agency-employees`;
    return await api
      .post<AgencyEmployeeDTO>(url, {
        name: data.name,
        phone: data.phone,
        email: data.email,
        cpf: data.cpf,
        type: data.type,
      })
      .then(({ data }) => mapAgencyEmployeeDTO(data));
  }

  async updateById(data: AgencyEmployee): Promise<AgencyEmployee> {
    return await api
      .put<AgencyEmployeeDTO>(`/agencies/agency-employees/${data.uuid}`, {
        name: data.name,
        phone: data.phone,
        email: data.email,
        cpf: data.cpf,
        type: data.type,
      })
      .then(({ data }) => mapAgencyEmployeeDTO(data));
  }

  async toggleActive(data: AgencyEmployee): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/agencies/agency-employees/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/agencies/agency-employees/${data.uuid}/active`)
      .then(({ data }) => data);
  }
}
