import * as React from "react";
import { SVGProps } from "react";

const SvgCarDoor = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.833 11.667h-2.5v1.666h2.5v-1.666Zm2.5 5.833H2.5V9.167L9.167 2.5H17.5a.833.833 0 0 1 .833.833V17.5ZM9.858 4.167l-5 5h11.809v-5H9.858Z" />
  </svg>
);

export default SvgCarDoor;
