import { useLocation } from "react-router-dom";

import { ORDER_MENU_TABS, OrderTab } from "~/presentation/shared/utils";

export function useOrderTab(): OrderTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(OrderTab).includes(hash)) {
    return hash as OrderTab;
  }

  return ORDER_MENU_TABS[0].id;
}
