import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { log } from "~/utils/log";
import { UseChannelProps } from "./types";

const defaulOptions = {
  cluster: "mt1",
};

export function useChannel({
  logTag,
  channelName,
  eventName,
  enabled,
  options,
  onMessage,
}: UseChannelProps) {
  const [state, setState] = useState({
    isSubscribed: false,
    isLoading: false,
  });

  const { isSubscribed, isLoading } = state;

  useEffect(() => {
    if (!enabled || !onMessage || isSubscribed) return;

    const appKey = import.meta.env.VITE_PUSHER_APP_KEY;
    const pusher = new Pusher(appKey, options ?? defaulOptions);
    const channel = pusher.subscribe(channelName);

    log.i(`Subscribed to channel ${channelName} ${logTag ? `(${logTag})` : ""}`);

    channel.bind(eventName, onMessage);

    setState((state) => ({ ...state, isSubscribed: true, isLoading: true }));

    log.i(`Listening to event ${eventName}`);

    return () => {
      channel.unbind_all();
      pusher.unsubscribe(channelName);
      setState((state) => ({ ...state, isSubscribed: false, isLoading: false }));
    };
  }, [enabled]);

  return { isSubscribed, isLoading };
}
