import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { SvgCarDoor, SvgManual, SvgSnowflake } from "~/components/Icon/icons";
import { Box } from "~/components/Box";
import { Icon } from "~/components/Icon";
import { Image } from "~/components/Image";
import { Tag } from "~/components/Tag";
import { FormDialog } from "~/components/FormDialog";

export type VehicleCardProps = {
  data: {
    vehicle: {
      description: string;
      group: string;
      image: string;
      airCondition: boolean;
      transmissionType: string;
      numberDoors: string;
      details: string;
    };
  };
};

export const VehicleDetailsDialog = ({ data }: VehicleCardProps) => {
  return (
    <Container
      fixed
      css={{
        width: "99%",
      }}
    >
      <FormDialog title="Detalhes sobre o carro">
        <DialogBody css={{ p: 20 }}>
          <Flex direction="column" gap="6">
            <Flex justify="center">
              <Image
                src={data.vehicle.image}
                css={{ height: "152px", width: "168px" }}
              />
            </Flex>

            <Flex direction="column" gap="4">
              <Box css={{ fw: "600" }}>{data.vehicle.group}</Box>
              <Box>{data.vehicle.description}</Box>
            </Flex>

            <Flex gap="4">
              {data.vehicle.airCondition && (
                <Tag variant="neutral-light">
                  <Icon as={SvgSnowflake} />
                  <Box>Ar condicionado</Box>
                </Tag>
              )}

              <Tag variant="neutral-light">
                <Icon as={SvgManual} />
                <Box>{data.vehicle.transmissionType}</Box>
              </Tag>

              <Tag variant="neutral-light">
                <Icon as={SvgCarDoor} />
                <Box>{data.vehicle.numberDoors}</Box>
              </Tag>
            </Flex>
          </Flex>
        </DialogBody>
      </FormDialog>
    </Container>
  );
};

VehicleDetailsDialog.displayName = "VehicleDetailsDialog";
