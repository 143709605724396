import {
  CustomerOrder,
  Order,
  User,
  UserContext,
  UserContexts,
} from "~/application/types";
import { groupArrayByKey } from "~/utils/array.utils";
import * as DateUtils from "~/utils/date.utils";

export interface GroupedCustomerOrder {
  timestamp: number;
  label: string;
  items: Order[];
}

export const groupCustomerOrderByDate = (
  customerOrders: Order[]
): GroupedCustomerOrder[] => {
  return groupArrayByKey({
    data: customerOrders,
    keyExtract: (order) => {
      return order.issuedAt ? order.issuedAt.toISOString() : order.createdAt.toISOString();
    },

  })
    .map((group) => {
      const date = new Date(group.groupKey);

      return {
        items: group.data,
        timestamp: date.getTime(),
        label: DateUtils.displayDate(date, DateUtils.DateFormats.LONG_DATE),
      } as GroupedCustomerOrder;
    })
    .sort((a, b) => (a.timestamp - b.timestamp > 0 ? -1 : 1));
};

export function userIsAgencyAsCustomer(user: User, context: UserContexts) {
  return (
    user.context === UserContext.Agency &&
    context.customer?.uuid === user.profiles.customer.uuid
  );
}

export function userIsIssuer(
  user: User,
  order?: Order | CustomerOrder,
  context?: UserContexts
) {
  if (!order) {
    return false;
  }

  const issuerId = order.issuer.uuid;

  if (user.context === "agency") {
    return (
      issuerId === user.profiles.agency.uuid ||
      issuerId === context?.agency?.uuid
    );
  }

  if (user.context === "customer") {
    return (
      issuerId === user.profiles.customer.uuid ||
      issuerId === context?.customer?.uuid
    );
  }

  return false;
}
