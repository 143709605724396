import { BoxProps } from "../Box";
import { Container } from "../Container";
export type AppBarContainerProps = {
  children: React.ReactNode;
};
export const AppBarContainer = ({ children }: BoxProps) => {
  const currentUrl = window.location.href;
  const path = currentUrl.split("/").at(4);
  return (
    <Container
      css={{
        py: "$4",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "$6",
        minHeight: 88,
        "@mxlg": {
          px: "$5",
          color: "#FFF",
          ta: path === "empresas" ? "start" : "center",
          backgroundColor: "$primary-base",
        },
      }}
    >
      {children}
    </Container>
  );
};
AppBarContainer.displayName = "AppBarContainer";
