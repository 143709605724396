import { useCallback, useState } from "react";

export interface UseRoadItemResult {
  isRoadItemExpanded: boolean;
  toggleRoadItemVisible: () => void;
}

export interface UseRoadItemOptions {
  orderId: string;
  enabled: boolean;
}

const LOG_TAG = "Order/OrderPage/useRoadItem";

const SNACKBAR_MESSAGES = {
  LOAD_ROAD_ERROR_MESSAGE: "Falha ao carregar item rodoviário",
} as const;

export function useRoadItem({
  orderId,
  enabled, // eslint-disable-line @typescript-eslint/no-unused-vars
}: UseRoadItemOptions): UseRoadItemResult {
  const [isRoadItemExpanded, setRoadItemExpanded] = useState(true);

  const toggleRoadItemVisible = useCallback(() => {
    setRoadItemExpanded((old) => !old);
  }, [isRoadItemExpanded]);

  return {
    isRoadItemExpanded,
    toggleRoadItemVisible,
  };
}
