import queryString from "query-string";
import type { City } from "~/application/types";
import { api } from "~/infrastructure/api";
import { CityDTO } from "~/infrastructure/api/dtos";
import { mapCityDTO } from "~/infrastructure/api/mappers";
import type { ICityService, IFindCityParams } from "./ICityService";

export class CityService implements ICityService {
  async find({ name }: IFindCityParams): Promise<City[]> {
    const url = queryString.stringifyUrl({
      url: `/cities/${name}`,
      query: {},
    });

    return await api
      .get<CityDTO[]>(url)
      .then(({ data }) => data.map(mapCityDTO));
  }
}
