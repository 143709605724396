import { ReactElement } from "react";
import { Text, TextProps } from "../Text";

export const Label = (props: TextProps): ReactElement => (
  <Text variant="dark" size="2" css={{ fw: "500" }} {...props} />
);

export const Caption = (props: TextProps): ReactElement => (
  <Text variant="darkest" size="2" css={{ fw: "700" }} {...props} />
);

export const H1 = (props: TextProps): ReactElement => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Text as="h1" size="10" css={{ fw: "600" }} {...props} />
);

export const H2 = (props: TextProps): ReactElement => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Text as="h2" size="8" css={{ fw: "600" }} {...props} />
);

export const H3 = (props: TextProps): ReactElement => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Text as="h3" size="7" css={{ fw: "600" }} {...props} />
);

export const H4 = (props: TextProps): ReactElement => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Text as="h4" size="6" css={{ fw: "600" }} {...props} />
);

export const H5 = (props: TextProps): ReactElement => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Text as="h5" size="5" css={{ fw: "600" }} {...props} />
);

export const H6 = (props: TextProps): ReactElement => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Text as="h6" size="4" css={{ fw: "600" }} {...props} />
);
