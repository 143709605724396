import queryString from "query-string";
import type { ExpenseCategory } from "~/application/types";
import { api } from "~/infrastructure/api";
import { ExpenseCategoryDTO } from "~/infrastructure/api/dtos";
import { mapExpenseCategoryDTO } from "~/infrastructure/api/mappers";
import type {
  IExpenseCategoryService,
  IFindExpenseCategoryParams,
} from "./IExpenseCategoryService";

export class ExpenseCategoryService implements IExpenseCategoryService {
  async findById(id: string): Promise<ExpenseCategory> {
    return await api
      .get<ExpenseCategoryDTO>(`/expense-categories/${id}`)
      .then(({ data }) => mapExpenseCategoryDTO(data));
  }

  async find({ name }: IFindExpenseCategoryParams): Promise<ExpenseCategory[]> {
    const url = queryString.stringifyUrl({
      url: "/expense-categories",
      query: { name },
    });

    return await api
      .get<ExpenseCategoryDTO[]>(url)
      .then(({ data }) => data.map(mapExpenseCategoryDTO));
  }

  async create(data: Omit<ExpenseCategory, "uuid">): Promise<ExpenseCategory> {
    return await api
      .post<ExpenseCategoryDTO>("/expense-categories", data)
      .then(({ data }) => mapExpenseCategoryDTO(data));
  }

  async updateById(data: ExpenseCategory): Promise<ExpenseCategory> {
    return await api
      .put<ExpenseCategoryDTO>(`/expense-categories/${data.uuid}`, data)
      .then(({ data }) => mapExpenseCategoryDTO(data));
  }

  async toggleActive(data: ExpenseCategory): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/expense-categories/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/expense-categories/${data.uuid}/active`)
      .then(({ data }) => data);
  }
}
