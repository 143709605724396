import { Order } from "~/application/types";
import { groupArrayByKey } from "~/utils/array.utils";
import * as DateUtils from "~/utils/date.utils";

export interface GroupedCustomerOrder {
  timestamp: number;
  label: string;
  items: Order[];
}

export const groupOrderByDate = (
  customerOrders: { createdAt: Date }[]
): GroupedCustomerOrder[] => {
  return groupArrayByKey({
    data: customerOrders,
    keyExtract: (order) => DateUtils.getDayAtStart(order.createdAt).toISOString(),
  })
    .map((group) => {
      const date = new Date(group.groupKey);

      return {
        items: group.data,
        timestamp: date.getTime(),
        label: DateUtils.displayDate(date, DateUtils.DateFormats.LONG_DATE),
      } as GroupedCustomerOrder;
    })
    .sort((a, b) => (a.timestamp - b.timestamp > 0 ? -1 : 1));
};
