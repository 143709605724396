import { Customer } from "~/application/types";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";

export const useVerifyParameter = ({
  customer,
  parameter,
  value = "true",
}: {
  customer: Customer;
  parameter: SettingParameterSlug;
  value?: string;
}) => {
  return customer?.settingsParameters?.some((settings) => {
    return settings.slug === parameter && settings.value === value;
  });
};
