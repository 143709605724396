import { Fragment } from "react";
import { Customer, AccessReport } from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { SearchBar } from "~/components/Input";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { AGENCY_REPORTS_MENU_TABS, AgencyReportTab } from "./utils";
import { TabAccessReports } from "./views/TabAccessReports";

type AgencyReportsContainerProps = {
  reports?: AccessReport[];
  currentPage: number;
  activeTab: AgencyReportTab;
  lastPage: number;
  searchText: string;
  customerList?: Customer[];
  loadingCustomerList?: boolean;
  onGoPage: (page: number) => void;
  onSearchText: (search: string) => void;
  onSearchCustomer?: (customer: string) => void;
  onSelectCustomer?: (customer: Customer) => void;
  onSelectDate?: ({ label, value }: { label: string; value: string }) => void;
};

export function AgencyReportsContainer({
  reports,
  lastPage,
  activeTab,
  searchText,
  currentPage,
  customerList,
  loadingCustomerList,
  onSearchCustomer,
  onSelectCustomer,
  onSelectDate,
  onSearchText,
  onGoPage,
}: AgencyReportsContainerProps) {
  const tabAccessProps = {
    lastPage,
    reports,
    searchText,
    currentPage,
    customerList,
    loadingCustomerList,
    onSearchCustomer,
    onSelectCustomer,
    onSelectDate,
    onGoPage,
  };

  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <AppBarHeading title="Relatórios" />
            <SearchBar placeholder="Buscar" onSearchChange={onSearchText} />
          </AppBarContainer>

          <Container>
            <TabBar>
              {!reports ? (
                <Flex gap="8" css={{ p: "$4" }}>
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                </Flex>
              ) : (
                AGENCY_REPORTS_MENU_TABS.map(({ title, id }) => (
                  <Link to={`#${id}`} title={title} key={id}>
                    <CounterTab id={id} value={id}>
                      <Text>{title}</Text>
                    </CounterTab>
                  </Link>
                ))
              )}
            </TabBar>
          </Container>
        </AppBar>

        <TabContent value={AgencyReportTab.ACCESS}>
          <TabAccessReports {...tabAccessProps} />
        </TabContent>
      </Tabs>
    </Fragment>
  );
}
