import { FC } from "react";
import { POLICIES_TYPES } from "../../utils";
import { PurchansingPolicyType } from "../../hooks/type";
import { Icon } from "~/components/Icon";
import { Text } from "~/components/Text";
import { Flex } from "~/components/Flex";
import { Card } from "~/components/Card";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Select } from "~/components/Input";

export type CardSelectTypePoliciesProps = {
  policieSelect: PurchansingPolicyType;
  onSelectPolicieSelect: (type: PurchansingPolicyType) => void;
};

export const CardSelectTypePolicies: FC<CardSelectTypePoliciesProps> = ({
  onSelectPolicieSelect,
  policieSelect,
}) => {
  const { contexts } = useUser();
  const isMobile = useMobile();

  const canReasonTripPolicy =
    useVerifyParameter({
      customer: contexts.customer,
      parameter: SettingParameterSlug.POLICY_BY_REASON_TRIP,
    }) || contexts.agency;

  return isMobile ? (
    <Select />
  ) : (
    <Card>
      <Flex direction="column">
        {POLICIES_TYPES.filter(({ id }) => id !== "reason-trip" || canReasonTripPolicy).map(
          ({ id, name, icon }) => (
            <Flex
              key={id}
              css={{
                cursor: "pointer",
                p: "$4",
                backgroundColor: policieSelect.id === id ? "$primary-base" : "transparent",

                ["&:hover"]: {
                  backgroundColor: policieSelect.id === id ? "$primary-base" : "$neutrals-lightest",
                },

                [`${Icon}`]: {
                  fill: policieSelect.id === id ? "$neutrals-white" : "$neutrals-dark",
                },
                [`${Text}`]: {
                  color: policieSelect.id === id ? "$neutrals-white" : "$neutrals-dark",
                },
              }}
              gap="2"
              align="center"
              onClick={() => onSelectPolicieSelect({ id, name })}
            >
              <Icon as={icon} />{" "}
              <Text size="3" variant="dark" fw="600">
                {name}
              </Text>
            </Flex>
          )
        )}
      </Flex>
    </Card>
  );
};
