import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type DialogBodyProps = ComponentProps<typeof DialogBody>;

export const DialogBody = styled("div", {
  // Reset
  boxSizing: "border-box",
  overflow: "auto",
});
