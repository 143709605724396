import { FC } from "react";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { LoadingDialogProps } from "./types";
import { Box } from "~/components/Box";

export const LoadingDialog: FC<LoadingDialogProps> = ({
  message,
  children,
}) => (
  <Container size="4" fixed>
    <Card
      css={{
        boxShadow: "$sm",
        maxHeight: "100%",
        "@mxmd": {
          width: "80%",
          margin: "0 auto",
          p: "$5",
        },
      }}
    >
      <Flex as={CardBody} align="center" direction="column" gap="8">
        <Spinner />
        <Flex direction="column">
          <Text css={{ textAlign: "center" }}>{message}</Text>
          {children}
        </Flex>
      </Flex>
    </Card>
  </Container>
);

LoadingDialog.displayName = "LoadingDialog";
