import { FC } from "react";
import { VehicleBudgetState } from "../type";
import { FormDialog } from "~/components/FormDialog";
import { Card, CardBody } from "~/components/Card";
import { usePDF } from "react-to-pdf";
import { Flex } from "~/components/Flex";
import { Button } from "~/components/Button";
import { Divider } from "~/components/Divider";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { Text } from "~/components/Text";
import { SvgCheck, SvgFavicon } from "~/components/Icon/icons";
import { Icon } from "~/components/Icon";
import { Customer, User, VehicleQuery } from "~/application/types";
import { Grid } from "~/components/Grid";
import { asCurrency } from "~/utils/mask.utils";

export type VehicleBudgetDialogProps = {
  data: VehicleBudgetState;
  user?: User;
  customer?: Customer;
  vehicleParams?: VehicleQuery;
};

export const VehicleBudgetDialog: FC<VehicleBudgetDialogProps> = ({
  data,
  customer,
  user,
  vehicleParams,
}) => {
  const { toPDF, targetRef } = usePDF({
    filename: "Orçamento.pdf",
  });

  return (
    <Card
      css={{
        width: "55vw",
      }}
    >
      <FormDialog
        title={"PDF"}
        css={{
          width: "100%",
          overflow: "hidden",
        }}
        positiveButton={
          <Flex justify="end">
            <Button onClick={() => toPDF()}>Baixar PDF</Button>
          </Flex>
        }
      >
        <Flex
          css={{
            width: "100%",
            overflow: "auto",
          }}
          direction="column"
        >
          <CardBody ref={targetRef} css={{}}>
            <Flex gap="2" direction="column">
              <Flex
                css={{
                  width: "100%",
                  p: "$3 $6",
                  borderRadius: "$md $md $none $none",
                  backgroundColor: "$primary-base",
                }}
                align="center"
                justify="between"
              >
                <Icon as={SvgFavicon} size="lg" />
                <Flex direction="column" gap="1">
                  <Text size="1" variant="white">
                    Empresa
                  </Text>
                  <Text size="3" variant="white" fw="600">
                    {customer?.companyName}
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="2" direction="column">
                <Text fw="700">
                  Agente: <Text fw="400">{`${user?.name}`}</Text>
                </Text>
                <Text fw="700">
                  Telefone: <Text fw="400">{user?.phone}</Text>
                </Text>
                <Text fw="700">
                  E-mail: <Text fw="400">{user?.email}</Text>
                </Text>
              </Flex>
              <Divider
                css={{
                  borderColor: "$neutrals-dark",
                  width: "100%",
                }}
              />
              <Flex
                css={{
                  width: "100%",
                }}
                align="center"
                justify="between"
              >
                <Flex
                  css={{
                    p: "$3",
                    borderRadius: "$pill",
                    backgroundColor: "$primary-base",
                  }}
                >
                  <Text variant="white" fw="700" size="4">
                    Cotação Eletrônica
                  </Text>
                </Flex>
                <Flex direction="column" gap="1">
                  <Text size="1" fw="400">
                    Data da cotação
                  </Text>
                  <Text size="3" fw="600">
                    {displayDate(Date(), DateFormats.SMALL_DATE)}
                  </Text>
                </Flex>
              </Flex>
              <Divider
                css={{
                  width: "100%",
                  borderColor: "$neutrals-dark",
                }}
              />
              <Flex justify="around">
                <Text fw="600" variant="darkest">
                  {`Retirada dia ${displayDate(
                    vehicleParams?.dateGetSelected as Date
                  )} ás ${vehicleParams?.timeGetSelected} `}
                </Text>
                {"-"}
                <Text fw="600" variant="darkest">{`
                Devolução ${displayDate(
                  vehicleParams?.dateReturnSelected as Date
                )} ás ${vehicleParams?.timeReturnSelected}`}</Text>
              </Flex>
              <Divider
                css={{
                  width: "100%",
                  borderColor: "$neutrals-dark",
                }}
              />
              <Grid columns="2" gap="2">
                {data.map((vehicle, index) => {
                  const carName =
                    vehicle.vehicle?.description?.split("ou similar")?.at(0) +
                    "ou similar";
                  const carDetails = vehicle.vehicle?.description
                    ?.split("ou similar /")
                    ?.at(1)
                    ?.split("/");
                  return (
                    <Flex
                      key={vehicle.id}
                      direction="column"
                      gap="2"
                      css={{ maxWidth: "400px" }}
                    >
                      <Text fw="700" size="5" variant="primary">
                        {`Opção ${index + 1}`}
                      </Text>
                      <Flex
                        direction="column"
                        css={{
                          border: "1px solid $neutrals-base",
                          borderRadius: "$sm",
                          height: "100%",
                        }}
                      >
                        <Flex
                          css={{
                            p: "$4 $6",
                          }}
                          justify="between"
                          align="center"
                        >
                          <Text size="4" fw="600">
                            {vehicle.rentalCompany}
                          </Text>
                        </Flex>
                        <Divider
                          css={{
                            width: "100%",
                            borderColor: "$neutrals-base",
                          }}
                        />
                        <Flex
                          direction="column"
                          gap="4"
                          css={{
                            p: "$6",
                            height: "100%",
                          }}
                        >
                          <Flex justify="between">
                            <Flex direction="column" gap="2">
                              <Text fw="700" size="4">
                                {vehicle.vehicle?.group}
                              </Text>
                              <Text fw="500" size="2" variant="dark">
                                {carName}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex
                            direction="column"
                            gap="2"
                            css={{
                              height: "100%",
                            }}
                          >
                            {carDetails?.map((detail) => (
                              <Flex key={detail} gap="1" align="center">
                                <Icon as={SvgCheck} variant="success" />
                                <Text size="2" variant="darkest" fw="600">
                                  {detail}
                                </Text>
                              </Flex>
                            ))}
                            <Flex gap="1" align="center">
                              <Icon as={SvgCheck} variant="success" />
                              <Text size="2" variant="darkest" fw="600">
                                {vehicle.vehicle?.numberDoors}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex justify="end">
                            <Flex direction="column" gap="2" align="end">
                              <Text size="1" variant="dark" fw="600">
                                Mínimo diária
                              </Text>
                              <Text size="4" fw="700">
                                {asCurrency(vehicle.totalPrice || 0)}
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  );
                })}
              </Grid>
            </Flex>
          </CardBody>
        </Flex>
      </FormDialog>
    </Card>
  );
};
