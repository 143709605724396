import { VariantProps } from "@stitches/react";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type SwitchButtonProps = ComponentProps<typeof SwitchButton>;

export type SwitchButtonVariants = VariantProps<typeof SwitchButton>;

export const SwitchButton = styled("span", {
  // Reset
  display: "block",
  boxSizing: "border-box",

  // Custom Reset?
  inset: 0,

  // Custom
  position: "relative",
  backgroundColor: "white",
  borderWidth: "2px",
  borderStyle: "solid",
  overflow: "hidden",
  borderRadius: "12px",
  width: "48px",
  height: "24px",
  transition: "$slow",
  borderColor: "$neutrals-dark",

  "&:hover": {
    cursor: "pointer",
    "&::before": {
      boxShadow: "0px 0px 0px 8px rgba(0, 100, 197, 0.08)",
    },
  },

  "&::before": {
    content: "\\00d7", // ISO code for the times symbol
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "$xs",
    lineHeight: "100%",
    color: "$neutrals-white",
    position: "absolute",
    height: "$4",
    width: "$4",
    borderRadius: "$circular",
    backgroundColor: "$neutrals-dark",
    left: 2,
    top: 2,
    transition: "$slow",
  },

  "&:focus": {
    boxShadow: "$focus-sm",
  },

  "&[data-checked=true]": {
    borderColor: "$primary-base",
    backgroundColor: "$primary-light",

    "&::before": {
      content: "\\2713", // ISO code for the check symbol
      backgroundColor: "$primary-base",
      left: "calc(100% - 2px)",
      transform: "translateX(-100%)",
    },
  },

  "&[data-disabled=true]": {
    pointerEvents: "none",
    borderColor: "$neutrals-base",
    backgroundColor: "$neutrals-light",

    "&::before": {
      backgroundColor: "$neutrals-base",
    },
  },
});

SwitchButton.displayName = "SwitchButton";
