import * as React from "react";
import { SVGProps } from "react";

const SvgSnowflake = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 4.375A.625.625 0 0 0 9.375 5v10a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625V5A.625.625 0 0 0 10 4.375Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M8.125 2.5a.625.625 0 0 0-.441.184.625.625 0 0 0 0 .882l1.875 1.875a.625.625 0 0 0 .882 0l1.875-1.875a.625.625 0 0 0 0-.882.625.625 0 0 0-.882 0L10 4.117 8.566 2.684a.625.625 0 0 0-.441-.184ZM9.559 14.559l-1.875 1.875a.625.625 0 0 0 0 .882.625.625 0 0 0 .882 0L10 15.883l1.434 1.433a.625.625 0 0 0 .882 0 .625.625 0 0 0 0-.882l-1.875-1.875a.625.625 0 0 0-.882 0zM5.508 6.896a.625.625 0 0 0-.38.292.625.625 0 0 0 .23.853l8.66 5a.625.625 0 0 0 .853-.229.625.625 0 0 0-.228-.853l-8.66-5a.625.625 0 0 0-.475-.063z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M4.822 4.336a.625.625 0 0 0-.441.764l.523 1.959-1.957.523a.625.625 0 0 0-.441.766.625.625 0 0 0 .764.443l2.562-.687a.625.625 0 0 0 .441-.766l-.685-2.56a.625.625 0 0 0-.766-.442ZM16.73 11.21l-2.562.686a.625.625 0 0 0-.441.766l.685 2.56a.625.625 0 0 0 .766.444.625.625 0 0 0 .441-.766l-.523-1.959 1.957-.523a.625.625 0 0 0 .441-.766.625.625 0 0 0-.764-.441zM14.018 6.959l-8.66 5a.625.625 0 0 0-.23.854.625.625 0 0 0 .854.228l8.66-5a.625.625 0 0 0 .23-.854.625.625 0 0 0-.854-.228Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M3.27 11.21a.625.625 0 0 0-.764.442.625.625 0 0 0 .441.766l1.957.523-.523 1.96a.625.625 0 0 0 .441.765.625.625 0 0 0 .766-.443l.685-2.56a.625.625 0 0 0-.441-.767ZM15.178 4.336a.625.625 0 0 0-.766.441l-.685 2.56a.625.625 0 0 0 .441.767l2.562.687a.625.625 0 0 0 .764-.443.625.625 0 0 0-.441-.766l-1.957-.523.523-1.96a.625.625 0 0 0-.441-.763Z"
    />
  </svg>
);

export default SvgSnowflake;
