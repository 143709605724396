import { Fragment, useCallback } from "react";
import { CustomerEmployee } from "~/application/types";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar, Select } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { DataColItem } from "~/components/List";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { CustomerEmployeeListItem } from "~/presentation/shared/components/CustomerEmployeeListItem";
import { useCustomerEmployeesPage } from "./logic/useCustomerEmployeesPage";
import { FormControl } from "~/components/FormControl";
import { Flex } from "~/components/Flex";

export function CustomerEmployeesPage() {
  const {
    data,
    isLoading,
    currentPage,
    lastPage,
    searchText,
    onCreateCustomerEmployee,
    onEditCustomerEmployee,
    onToggleCustomerEmployeeState,
    setSearchText,
    onGoToPage,
    activenessOptions,
    profilesOptions,
    control,
    onCleanFilters,
  } = useCustomerEmployeesPage();

  const listRenderer = useCallback(
    (item: CustomerEmployee) => (
      <CustomerEmployeeListItem
        data={item}
        onEditClick={onEditCustomerEmployee}
        onToggleState={onToggleCustomerEmployeeState}
        key={item.uuid}
      />
    ),
    [onEditCustomerEmployee, onToggleCustomerEmployeeState]
  );

  return (
    <Fragment>
      <Card>
        <CardBody
          css={{
            borderBottom: "1px solid $colors$neutrals-light",
            px: "$16",
          }}
        >
          <Row align="center">
            <Col>
              <H4>Funcionários</H4>
            </Col>

            <Col sz="auto">
              <SearchBar
                search={searchText}
                onSearchChange={setSearchText}
                placeholder="Encontrar"
              />
            </Col>

            <Col sz="auto">
              <Button title="Novo funcionário" color="primary" onClick={onCreateCustomerEmployee}>
                <Icon as={SvgPlus} />
                <Text>Novo funcionário</Text>
              </Button>
            </Col>
          </Row>
        </CardBody>

        <CardBody css={{ px: "$16" }}>
          <Flex justify="between" align="center">
            <Flex gap="3" align="center">
              <DataColItem
                headerTitle="Perfis"
                data={
                  <FormControl control={control} name="profiles">
                    <Select options={profilesOptions} placeholder="Perfis" portalled />
                  </FormControl>
                }
              />

              <DataColItem
                headerTitle="Estado"
                data={
                  <FormControl name="activeness" control={control}>
                    <Select options={activenessOptions} placeholder="Estado" portalled />
                  </FormControl>
                }
              />
            </Flex>

            <Col sz="auto">
              <Button title="Limpar filtros" variant="tertiary" onClick={onCleanFilters}>
                <Text>Limpar filtros</Text>
              </Button>
            </Col>
          </Flex>
        </CardBody>
      </Card>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhum funcionário corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui funcionários cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Fragment>
  );
}
