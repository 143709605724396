import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { Branch } from "~/application/types";
import { branchService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { BranchDialog } from "~/presentation/shared/views/BranchDialog";
import { InactivateDialog } from "~/presentation/shared/views/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";

const LOG_TAG = "CustomerSettings/BranchesPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar filiais",
  CREATE_SUCCESS_MESSAGE: "Nova filial adicionada",
  CREATE_ERROR_MESSAGE: "Falha ao criar filial",
  UPDATE_SUCCESS_MESSAGE: "Filial atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar filial",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar filial",
  ACTIVATE_SUCCESS_MESSAGE: "Filial ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar filial",
  INACTIVATE_SUCCESS_MESSAGE: "Filial inativada",
} as const;

export function useBranchesPage() {
  const { contexts } = useUser();

  const customerId = contexts.customer.uuid;

  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery(
    [QueryKeys.CUSTOMER_BRANCHES, customerId, currentPage, { search }],
    () =>
      branchService.find({
        customerId,
        page: currentPage,
        name: search,
      }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateCreateBranch } = useMutation(
    (item: Branch) => branchService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Branch(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created Branch(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_BRANCHES]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(<BranchDialog onSubmit={mutateCreateBranch} />);
  }, [mutateCreateBranch]);

  const { mutate: mutateUpdateBranch } = useMutation(
    (branch: Branch) => branchService.updateById(branch),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Branch(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Branch(${item.uuid})`);

        queryClient.invalidateQueries([
          QueryKeys.CUSTOMER_BRANCHES,
          customerId,
        ]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleOpenEditModal = useCallback(
    (item: Branch) => {
      dialogService.showDialog(
        <BranchDialog onSubmit={mutateUpdateBranch} data={item} />
      );
    },
    [mutateUpdateBranch]
  );

  const { mutate: mutateToggleBranchState } = useMutation(
    (item: Branch) => branchService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Branch(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Branch(${item.uuid})`);

        queryClient.invalidateQueries([
          QueryKeys.CUSTOMER_BRANCHES,
          customerId,
        ]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((branch: Branch) => {
    if (branch.isActive) {
      return dialogService.showDialog(
        <InactivateDialog onConfirm={() => mutateToggleBranchState(branch)} />
      );
    }

    mutateToggleBranchState(branch);
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page ?? 0,
    searchText: searchText,
    onGoToPage: setCurrentPage,
    setSearchText: handleChangeSearch,
    onCreateBranch: handleOpenCreateModal,
    onEditBranch: handleOpenEditModal,
    onToggleBranchState: handleToggleState,
  };
}
