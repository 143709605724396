import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type CardProps = ComponentProps<typeof Card>;

export const Card = styled("div", {
  // Reset
  boxSizing: "border-box",
  appearance: "none",
  display: "flex",
  outline: "none",

  // Custom Reset?
  position: "relative",
  flexDirection: "column",

  // Custom
  font: "inherit",
  lineHeight: "1",
  padding: 0,
  textAlign: "inherit",
  verticalAlign: "middle",
  backgroundColor: "$neutrals-white",
  color: "inherit",
  borderRadius: "$md",
  border: "1px solid $colors$neutrals-light",
  overflow: "auto",
});
