import { OrderVehicle } from "~/application/types";
import { api } from "~/infrastructure/api";

type OrderVehicleCreate = (data: {
  searchKey?: string;
  orderUuid?: string;
  vehicleId?: number;
  travelerUuid: string;
  lowerFareViolated?: boolean;
  brokenPolicyJustification?: string;
}) => Promise<void>; // TODO

class OrderVehicleService {
  create: OrderVehicleCreate = async (data) => {
    return await api
      .post(`/orders/${data.orderUuid}/vehicle`, {
        search_key: data.searchKey,
        vehicle_id: data?.vehicleId?.toString(),
        traveler_id: data.travelerUuid,
        lower_fare_violated: data.lowerFareViolated,
        broken_policy_justification: data.brokenPolicyJustification,
      })
      .then(() => undefined);
  };
}

const orderVehicleService = new OrderVehicleService();

export { orderVehicleService };
