import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { QueryKeys } from "~/application/constants";
import { Action, DeepPartial, Profile } from "~/application/types";
import { profileService } from "~/application/usecases/Profile";
import { dialogService } from "~/components/DialogStack";
import { FormProfileDialog } from "../views/Profiles/components/FormProfileDialog/FormProfileDialog";
import {
  IUpdateProfileActionsParams,
  IUpdateProfileParams,
} from "~/application/usecases/Profile/IProfileService";
import { logError } from "~/presentation/shared/utils/errors";
import { queryClient } from "~/services/queryClient";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";

export type useProfilesProps = {
  customerId: string;
  enabled: boolean;
};

export type EditProfileProps = {
  item: Profile;
};

export type useProfilesResult = {
  data?: Profile[];
  lastPage: number;
  searchText: string;
  currentPage: number;
  onSearchName: (name: string) => void;
  onGoToPage: (page: number) => void;
  useFetchProfileAction: ({ item }: { item: Profile }) => UseQueryResult<Action[], unknown>;
  onEditProfile: ({ item }: EditProfileProps) => void;
  onCreateProfile: () => void;
};

export type ProfileFilters = {
  name: string;
};

const SNACKBAR_MESSAGES = {
  CREATE_PROFILE_ERROR_MESSAGE: "Falha ao criar o perfil",
  UPDATE_PROFILE_ERROR_MESSAGE: "Falha ao atualizar o perfil",
  UPDATE_PROFILE_ACTION_ERROR_MESSAGE: "Falha ao adicionar as ações ao perfil",
};

const LOG_TAG = "Customer/CustomerPage/Profile";

export const useProfiles = ({ customerId, enabled }: useProfilesProps): useProfilesResult => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<DeepPartial<ProfileFilters>>();

  const [filtersDebounced] = useDebounce(filters, 700);

  const { data } = useQuery(
    [QueryKeys.PROFILES, customerId, currentPage, filtersDebounced?.name],
    () => profileService.findAll({ customerId, page: currentPage, name: filtersDebounced?.name }),
    {
      enabled,
    }
  );

  const { mutate: mutateUpdateActionsProfile } = useMutation(
    async ({ actions, profile }: IUpdateProfileActionsParams) =>
      await profileService.updateProfileActions({ actions, profile }),
    {
      onMutate({ isNew }) {
        dialogService.showDialog(
          <LoadingDialog message={isNew ? "Adicionando ações" : "Atualizando perfil"} />
        );
      },
      onSuccess(_, { refetch }) {
        dialogService.popDialog();
        refetch?.();
        dialogService.popAll();
      },
    }
  );

  const { mutate: mutateUpdateProfile } = useMutation(
    async ({ profile }: IUpdateProfileParams) => await profileService.updateProfile({ profile }),
    {
      onMutate() {
        dialogService.showDialog(<LoadingDialog message="Atualizando" />);
      },
      onSuccess() {
        queryClient.invalidateQueries([QueryKeys.PROFILES]);
      },
      onError(error) {
        dialogService.popDialog();
        logError({
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_PROFILE_ACTION_ERROR_MESSAGE,
          logTag: LOG_TAG,
        });
      },
    }
  );

  const handleUpdateProfile = useCallback(
    async ({
      actions,
      item,
      refetch,
    }: {
      item?: Profile;
      actions?: string[];
      refetch: () => void;
    }) => {
      try {
        mutateUpdateProfile({ profile: item as Profile }),
          mutateUpdateActionsProfile({
            actions,
            profile: item as Profile,
            refetch,
          });
      } catch (e) {
        dialogService.popDialog();
        logError({
          error: e,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_PROFILE_ACTION_ERROR_MESSAGE,
        });
      }
    },
    [mutateUpdateActionsProfile, mutateUpdateProfile]
  );

  const handleCreateProfile = useCallback(
    async ({ profile, actions }: { profile?: Profile; actions?: string[] }) => {
      try {
        dialogService.showDialog(<LoadingDialog message="Criando Perfil" />);
        const newProfile = await profileService
          .createProfile({ customerId, profile })
          .finally(() => {
            dialogService.popDialog();
            queryClient.invalidateQueries([QueryKeys.PROFILES]);
          });

        mutateUpdateActionsProfile({
          actions,
          profile: newProfile,
          isNew: true,
        });
      } catch (e) {
        dialogService.popDialog();
        logError({
          error: e,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_PROFILE_ERROR_MESSAGE,
        });
      }
    },
    [customerId]
  );

  const useFetchProfileAction = ({ item }: { item: Profile }) =>
    useQuery([QueryKeys.PROFILE_ACTIONS, item], () => profileService.findProfileAction(item), {
      enabled: false,
    });

  const handleSearchName = (name: string) => setFilters({ name });

  const onEditProfile = useCallback(
    ({ item }: EditProfileProps) => {
      dialogService.showDialog(
        <FormProfileDialog
          data={item}
          onFetchProfileAction={useFetchProfileAction}
          onCloseClick={() => dialogService.popDialog()}
          onSubmit={handleUpdateProfile}
        />
      );
    },
    [handleCreateProfile, handleUpdateProfile, useFetchProfileAction]
  );

  const onCreateProfile = useCallback(() => {
    dialogService.showDialog(
      <FormProfileDialog
        onSubmit={({ item, actions }) => handleCreateProfile({ actions, profile: item })}
        onCloseClick={() => dialogService.popDialog()}
        onFetchProfileAction={useFetchProfileAction}
        isNew
      />
    );
  }, [handleCreateProfile, handleUpdateProfile, useFetchProfileAction]);

  return {
    data: data?.data,
    onSearchName: handleSearchName,
    currentPage,
    lastPage: data?.meta.last_page || 0,
    onGoToPage: setCurrentPage,
    searchText: filters?.name || "",
    useFetchProfileAction,
    onEditProfile,
    onCreateProfile,
  };
};
