import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "~/application/constants";
import {
  AirwaySeats,
  AirwaySeatsForm,
  Passenger,
  Traveler,
} from "~/application/types";
import { orderAirwayService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { log } from "~/utils/log";
import { LOG_TAG } from "../../useAccountabilityExpenses";
import { UseFormHandleSubmit, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  AirwaySeatActionTypes,
  AirwaySeatsReducerAction,
  AirwaySeatsReducerState,
} from "./utils/type";
import { createAirwaySeatsFormData } from "./utils";
import { QueryTimes } from "~/constants";

export type UseAirwaySeatsProps = {
  orderItemId: string;
  passengers: Passenger[];
};

export type PassengerSeatMap = {
  [key: string]: {
    stretchId: string;
    row: string;
    column: string;
  }[];
};

export type UseAirwayResult = {
  data?: AirwaySeats;
  isLoading: boolean;
  stepAirwaySeats: number;
  handleSubmit: UseFormHandleSubmit<AirwaySeatsForm>;
  selectSeat: (seat: AirwaySeatsReducerState) => void;
  setStepAirwaySeats: (step: number) => void;
  isSeatSelected: (seat: AirwaySeatsReducerState) => boolean;
  canSelectSeat: ({ strectId }: { strectId: string }) => boolean;
  onPassengerSelected: (passenger: Passenger) => void;
  passengerSelected?: Passenger;
  seatsFormData: AirwaySeatsForm;
  totalValue: number;
};

export const useAirwaySeats = ({
  orderItemId,
  passengers,
}: UseAirwaySeatsProps): UseAirwayResult => {
  const [step, setStep] = useState(0);
  const [seatsMarked, setSeatsMarked] = useState<AirwaySeatsReducerState[]>();
  const [passengerSelected, onPassengerSelected] = useState<Passenger>(
    passengers[0]
  );

  const { data, isLoading } = useQuery(
    [QueryKeys.AIRWAYSEATS, orderItemId],
    () => orderAirwayService.getSeats({ orderItemId }),
    {
      enabled: true,
      staleTime: QueryTimes.LONG,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar("Erro ao carregar os assentos", "error");
      },
    }
  );

  const [seatsSelected, dispatch] = useReducer(
    (state: AirwaySeatsReducerState[], action: AirwaySeatsReducerAction) => {
      switch (action.type) {
        case AirwaySeatActionTypes.SELECT:
          return [...state, action.payload];
        case AirwaySeatActionTypes.UNSELECT:
          return state.filter((seat) =>
            seat.stretchId === action.payload.stretchId
              ? seat.row === action.payload.row &&
                seat.column === action.payload.column
                ? false
                : true
              : true
          );
        case AirwaySeatActionTypes.CLEAR:
          return [];
        default:
          return state;
      }
    },
    []
  );

  const seatsFiltered = useMemo(() => {
    const stretchsIds = seatsMarked?.map((seats) => seats.stretchId);

    const stretchs = [] as {
      length: number;
      stretchId: string;
    }[];

    stretchsIds?.forEach((stretchId) => {
      let count = 0;
      stretchsIds.forEach((s) => {
        if (s === stretchId) {
          count = count + 1;
        }
      });

      if (stretchs.every((stre) => stre.stretchId !== stretchId))
        stretchs.push({
          length: count,
          stretchId,
        });
    });

    return data?.data.filter((seats) => {
      const stretch = stretchs.find((stre) => stre.stretchId === seats.id);

      return (stretch?.length || 0) !== passengers.length;
    });
  }, [data, seatsMarked, passengers]);

  const totalValue = seatsSelected
    .filter((seat) => seat.selected)
    .reduce((acc, cur) => {
      return acc + cur.value;
    }, 0);

  const isSeatSelected = useCallback(
    (seat: AirwaySeatsReducerState) =>
      seatsSelected.some(
        (selectedSeat) =>
          selectedSeat.stretchId === seat.stretchId &&
          selectedSeat.row === seat.row &&
          selectedSeat.column === seat.column &&
          seat.passenger?.uuid === selectedSeat.passenger?.uuid
      ),
    [seatsSelected]
  );

  const handleSelectSeat = useCallback(
    (seat: AirwaySeatsReducerState) => {
      dispatch({
        type: isSeatSelected(seat)
          ? AirwaySeatActionTypes.UNSELECT
          : AirwaySeatActionTypes.SELECT,
        payload: seat,
      });
    },
    [seatsSelected]
  );

  const canSelectSeat = useCallback(
    ({ strectId }: { strectId: string }) => {
      const selectedSeats = seatsSelected.filter(
        (seat) => seat.stretchId === strectId
      );

      return !selectedSeats.find(
        (seat) => seat.passenger?.uuid === passengerSelected?.uuid
      );
    },
    [seatsSelected, passengerSelected]
  );

  const seatsFormData = useMemo(() => {
    return createAirwaySeatsFormData({
      seats: {
        searchKey: data?.searchKey || "",
        data: seatsFiltered as AirwaySeats["data"],
      },
      seatsSelected,
    });
  }, [seatsFiltered, seatsSelected]);

  const { handleSubmit, watch } = useForm({
    defaultValues: seatsFormData,
  });

  useEffect(() => {
    if (seatsSelected.length || isLoading) return;

    passengers.forEach((passenger) => {
      passenger.seats?.forEach((seat) => {
        const column = seat.seatNumber.split("").pop() as string;
        const row = seat.seatNumber
          .split("")
          .filter((seat) => seat !== column)
          .join()
          .replace(",", "");

        data?.data.forEach((stretch) => {
          if (
            seat.arrival === stretch.arrival &&
            seat.departure === stretch.departure
          ) {
            setSeatsMarked((old) => [
              ...(old || ([] as AirwaySeatsReducerState[])),
              {
                column,
                row,
                stretchId: stretch.id,
                value: seat.value,
                selected: false,
                passenger,
                departure: stretch.departure,
                arrival: stretch.arrival,
              },
            ]);

            handleSelectSeat({
              column,
              row,
              stretchId: stretch.id,
              value: seat.value,
              selected: false,
              passenger,
              departure: stretch.departure,
              arrival: stretch.arrival,
            });
          }
        });
      });
    });
  }, [passengers, isLoading, data, seatsFiltered]);

  return {
    handleSubmit,
    data: {
      searchKey: data?.searchKey || "",
      data: seatsFiltered as AirwaySeats["data"],
    },
    isLoading,
    selectSeat: handleSelectSeat,
    setStepAirwaySeats: setStep,
    isSeatSelected,
    canSelectSeat,
    onPassengerSelected,
    stepAirwaySeats: step,
    seatsFormData,
    totalValue,
    passengerSelected,
  };
};
