import type { ExpenseType } from "~/application/types";

export type EditableExpenseType = ExpenseType;

const DEFAULT_FORM_DATA = {
  name: "",
} as EditableExpenseType;

export function createExpenseType(editable: EditableExpenseType): ExpenseType {
  return {
    ...editable,
  };
}

export function editExpenseType(data?: ExpenseType): EditableExpenseType {
  if (!data) return DEFAULT_FORM_DATA;

  return {
    ...data,
  };
}
