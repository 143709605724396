import { Budget, BudgetView } from "~/application/types";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { SearchBar, Select } from "~/components/Input";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { IOption } from "../../hooks/useBudgets";
import { BUDGETS_MENU_TABS, BudgetTab } from "./utils";
import { getCurrentYear } from "~/utils/date.utils";
import { CounterTab, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Flex } from "~/components/Flex";
import { Skeleton } from "~/components/Skeleton";
import { Link } from "~/components/Link";
import { TabBudgetsView } from "./views/TabBudgetsView";
import { TabBudgets as TabBudgetsList } from "./views/TabBudgets";

export interface TabBudgetsProps {
  budgets?: Budget[];
  budgetsView?: BudgetView[];
  yearsOptions?: IOption[];
  currentViewPage: number;
  currentPage: number;
  lastPage: number;
  lastViewPage: number;
  search: string;
  searchView: string;
  year: number;
  activeTab: BudgetTab;
  onCreateBudget: () => void;
  onEditBudget: (data: Budget) => void;
  onSearch: (search: string) => void;
  onSearchView: (search: string) => void;
  onChangeYear: (year: number) => void;
  onGoToPage: (page: number) => void;
  onGoToViewPage: (page: number) => void;
}

export function TabBudgets({
  budgets,
  budgetsView,
  yearsOptions,
  currentPage,
  currentViewPage,
  lastPage,
  lastViewPage,
  search,
  searchView,
  year,
  activeTab,
  onCreateBudget,
  onEditBudget,
  onSearch,
  onSearchView,
  onChangeYear,
  onGoToPage,
  onGoToViewPage,
}: TabBudgetsProps) {
  const isMobile = useMobile();

  const tabBudgetsProps = {
    budgets,
    currentPage,
    lastPage,
    search,
    onEditBudget,
    onGoToPage,
  };

  const tabBudgetsViewProps = {
    budgets: budgetsView,
    currentPage: currentViewPage,
    lastPage: lastViewPage,
    onGoToPage: onGoToViewPage,
  };

  const currentYear = getCurrentYear();
  
  return (
    <Tabs value={activeTab}>
      <Card css={{ p: "$6 $6 0 $6", overflow: "visible" }}>
        <Row align="center">
          <Col>
            <H4>Orçamentos</H4>
          </Col>

          {activeTab === BudgetTab.BUDGETS && (
            <>
              <Col sz="auto" css={{ "@mxlg": { margin: "$5 auto", width: "100%" } }}>
                <SearchBar
                  search={search}
                  onSearchChange={onSearch}
                  placeholder="Encontrar"
                  style={isMobile ? { width: "100%" } : undefined}
                />
              </Col>

              <Col sz={{ "@lg": "auto" }}>
                <Button
                  title="Novo orçamento"
                  color="primary"
                  onClick={onCreateBudget}
                  css={{
                    "@mxlg": {
                      width: "100%",
                    },
                  }}
                >
                  <Icon as={SvgPlus} />
                  <Text>Novo orçamento</Text>
                </Button>
              </Col>
            </>
          )}

          {activeTab === BudgetTab.VIEW && (
            <>
              <Col sz="auto" css={{ "@mxlg": { margin: "$5 auto", width: "100%" } }}>
                <SearchBar
                  search={searchView}
                  onSearchChange={onSearchView}
                  placeholder="Encontrar"
                  style={isMobile ? { width: "100%" } : undefined}
                />
              </Col>

              <Col sz={{ "@lg": "auto" }}>
                <Select 
                  size="xs" 
                  defaultValue={{ label: String(currentYear), value: currentYear }}
                  value={{ label: String(year), value: year }}
                  options={yearsOptions} 
                  placeholder="Selecione o ano" 
                  onChange={(event) => onChangeYear(event.value)}
                />
              </Col>
            </>
          )}

        </Row>

        <TabBar>
          {!budgets ? (
            <Flex gap="8" css={{ p: "$4" }}>
              <Skeleton variant="text-4" style={{ width: 96 }} />
              <Skeleton variant="text-4" style={{ width: 96 }} />
            </Flex>
          ) : (
            BUDGETS_MENU_TABS.map(({ title, id }) => (
              <Link to={`#${id}`} title={title} key={id}>
                <CounterTab id={id} value={id}>
                  <Text>{title}</Text>
                </CounterTab>
              </Link>
            ))
          )}
        </TabBar>
      </Card>

      <TabContent value={BudgetTab.BUDGETS}>
        <TabBudgetsList {...tabBudgetsProps} />
      </TabContent>

      <TabContent value={BudgetTab.VIEW}>
        <TabBudgetsView {...tabBudgetsViewProps} />
      </TabContent>
    </Tabs>
  );
}
