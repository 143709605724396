import { Flex } from "~/components/Flex";
import { Skeleton } from "~/components/Skeleton";

export interface OrderHotelItemSkeletonProps {}

export function OrderHotelItemSkeleton() {
  return (
    <Flex direction="column" gap="6">
      <Skeleton variant="text-6" />

      <Skeleton variant="fluid" style={{ height: "324px" }} />

      <Skeleton variant="text-6" />

      <Skeleton variant="fluid" style={{ height: "88px" }} />

      <Skeleton variant="fluid" style={{ height: "88px" }} />
    </Flex>
  );
}

OrderHotelItemSkeleton.displayName = "OrderHotelItemSkeleton";
