import { Vehicle } from "~/application/types";
import { IFilterGenerator, IFilterGroup } from "../../../../../utils";

export const STATIC_VEHICLE_FILTERS: IFilterGroup<Vehicle>[] = [
  {
    key: "airCondition",
    label: "Ar condicionado",
    isValid: (item, optionValue) => item.vehicle.airCondition === optionValue,
    options: [
      {
        key: "yes",
        label: "Sim",
        value: true,
      },
    ],
  },
];

export const DYNAMIC_VEHICLE_FILTERS: IFilterGenerator<Vehicle>[] = [
  {
    key: "rentalCompany",
    label: "Locatária",
    generator: (item) => item.rentalCompany,
  },
  {
    key: "retrieveLocation",
    label: "Local de retirada",
    generator: (item) => item.origin,
  },
  {
    key: "transmissionType",
    label: "Tipo de transmissão",
    generator: (item) => item.vehicle.transmissionType,
  },
  {
    key: "numberDoors",
    label: "Número de portas",
    generator: (item) => item.vehicle.numberDoors,
  },
];
