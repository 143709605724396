import * as React from "react";
import { SVGProps } from "react";

const SvgCancel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.48 48 32 36.48 43.52 48 48 43.52 36.48 32 48 20.48 43.52 16 32 27.52 20.48 16 16 20.48 27.52 32 16 43.52 20.48 48ZM32 64c-4.427 0-8.587-.84-12.48-2.52-3.893-1.68-7.28-3.96-10.16-6.84S4.2 48.373 2.52 44.48C.84 40.587 0 36.427 0 32s.84-8.587 2.52-12.48c1.68-3.893 3.96-7.28 6.84-10.16s6.267-5.16 10.16-6.84C23.413.84 27.573 0 32 0s8.587.84 12.48 2.52c3.893 1.68 7.28 3.96 10.16 6.84s5.16 6.267 6.84 10.16C63.16 23.413 64 27.573 64 32s-.84 8.587-2.52 12.48c-1.68 3.893-3.96 7.28-6.84 10.16s-6.267 5.16-10.16 6.84C40.587 63.16 36.427 64 32 64Zm0-6.4c7.147 0 13.2-2.48 18.16-7.44C55.12 45.2 57.6 39.147 57.6 32s-2.48-13.2-7.44-18.16C45.2 8.88 39.147 6.4 32 6.4s-13.2 2.48-18.16 7.44C8.88 18.8 6.4 24.853 6.4 32s2.48 13.2 7.44 18.16C18.8 55.12 24.853 57.6 32 57.6Z"
      fill="#0064C5"
    />
  </svg>
);

export default SvgCancel;
