import { FC, useMemo } from "react";
import { RoadBudgetState } from "../type";
import { FormDialog } from "~/components/FormDialog";
import { Card, CardBody } from "~/components/Card";
import { usePDF } from "react-to-pdf";
import { Customer, Road, RoadQuery, User } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Button } from "~/components/Button";
import { SvgFavicon } from "~/components/Icon/icons";
import { Icon } from "~/components/Icon";
import { Text } from "~/components/Text";
import { Divider } from "~/components/Divider";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { CompanyLogo } from "~/components/CompanyLogo";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import { formatRoadInfo } from "~/presentation/shared/utils";
import { asCurrency } from "~/utils/mask.utils";

export type RoadBudgetDialogProps = {
  data: RoadBudgetState;
  user?: User;
  customer?: Customer;
  roadParams: RoadQuery | null;
};

export const RoadBudgetDialog: FC<RoadBudgetDialogProps> = ({
  data,
  customer,
  user,
  roadParams,
}) => {
  const { toPDF, targetRef } = usePDF({ filename: "Orçamento.pdf" });

  const currentData = Object.values(data);

  return (
    <Card
      css={{
        width: "45vw",
      }}
    >
      <FormDialog
        title={"PDF"}
        css={{
          width: "100%",
          overflow: "hidden",
        }}
        positiveButton={
          <Flex justify="end">
            <Button onClick={() => toPDF()}>Baixar PDF</Button>
          </Flex>
        }
      >
        <Flex
          css={{
            width: "100%",
            overflow: "auto",
          }}
          direction="column"
        >
          <CardBody ref={targetRef} css={{ p: "$8" }}>
            <Flex gap="2" direction="column">
              <Flex
                css={{
                  width: "100%",
                  p: "$3 $6",
                  borderRadius: "$md $md $none $none",
                  backgroundColor: "$primary-base",
                }}
                align="center"
                justify="between"
              >
                <Icon as={SvgFavicon} size="lg" />
                <Flex direction="column" gap="1">
                  <Text size="1" variant="white">
                    Empresa
                  </Text>
                  <Text size="3" variant="white" fw="600">
                    {customer?.companyName}
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="2" direction="column">
                <Text fw="700">
                  Agente: <Text fw="400">{`${user?.name}`}</Text>
                </Text>
                <Text fw="700">
                  Telefone: <Text fw="400">{user?.phone}</Text>
                </Text>
                <Text fw="700">
                  E-mail: <Text fw="400">{user?.email}</Text>
                </Text>
              </Flex>
              <Divider
                css={{
                  borderColor: "$neutrals-dark",
                  width: "100%",
                }}
              />
              <Flex
                css={{
                  width: "100%",
                }}
                align="center"
                justify="between"
              >
                <Flex
                  css={{
                    p: "$3",
                    borderRadius: "$pill",
                    backgroundColor: "$primary-base",
                  }}
                >
                  <Text variant="white" fw="700" size="4">
                    Cotação Eletrônica
                  </Text>
                </Flex>
                <Flex direction="column" gap="1">
                  <Text size="1" fw="400">
                    Data da cotação
                  </Text>
                  <Text size="3" fw="600">
                    {displayDate(Date(), DateFormats.SMALL_DATE)}
                  </Text>
                </Flex>
              </Flex>
              <Divider
                css={{
                  width: "100%",
                  borderColor: "$neutrals-dark",
                }}
              />
              {currentData.map((newData, index) => {
                if (!newData.length) return;

                return (
                  <Flex direction="column" gap="4" key={index}>
                    <Text fw="700" variant="primary-dark">
                      Data da {index > 0 ? "volta" : "ida"}:{" "}
                      <Text fw="500" variant="primary-dark">
                        {displayDate(
                          index > 0
                            ? (roadParams?.dateReturnSelected as Date)
                            : (roadParams?.dateGoSelected as Date),
                          DateFormats.SMALL_COMPLETE_WEEK_DATE
                        )}
                      </Text>
                    </Text>
                    <Flex direction="column" gap="8">
                      {newData?.map((road, index) => {
                        const roadInfo = useMemo(
                          () => formatRoadInfo(road as Road),
                          [road]
                        );

                        return (
                          <Flex key={road?.id} direction="column" gap="2">
                            <Text
                              fw="700"
                              size="5"
                              variant="primary"
                            >{`Opção 0${index + 1}`}</Text>

                            <Flex
                              direction="column"
                              css={{
                                border: "1px solid $neutrals-dark",
                                borderRadius: "$sm",
                              }}
                            >
                              <Flex
                                css={{
                                  p: "$3 $6",
                                }}
                              >
                                <Text variant="black" size="5" fw="700">
                                  {road?.company}
                                </Text>
                              </Flex>
                              <Divider
                                css={{
                                  borderColor: "$neutrals-base",
                                }}
                              />
                              <Flex
                                css={{
                                  p: "$3 $6",
                                }}
                                direction="column"
                                gap="6"
                              >
                                <Flex>
                                  <Text fw="600">Itinerário</Text>
                                </Flex>
                                <Flex
                                  justify="between"
                                  align="center"
                                  css={{ width: "100%" }}
                                >
                                  <Flex
                                    direction="column"
                                    gap="2"
                                    align="center"
                                    css={{
                                      flex: "1",
                                    }}
                                  >
                                    <Text variant="darkest" size="3" fw="600">
                                      {displayDate(
                                        road?.departureDate as Date,
                                        DateFormats.SMALL_WEEK_DATE
                                      )}
                                    </Text>
                                    <Text fw="700">
                                      {
                                        roadInfo.departureRoadInfo
                                          .dateTimeHour12
                                      }
                                    </Text>
                                    <Text variant="darkest" size="3" fw="600">
                                      {roadInfo.departureRoadInfo.name}
                                    </Text>
                                  </Flex>
                                  <Flex
                                    direction="column"
                                    gap="2"
                                    align="center"
                                    css={{
                                      flex: "1",
                                    }}
                                  >
                                    <Text fw="600" variant="darkest" size="2">
                                      Duração
                                    </Text>
                                    <Flex
                                      css={{
                                        width: "100%",
                                      }}
                                      align="center"
                                      gap="1"
                                    >
                                      <Divider
                                        css={{
                                          width: "80%",
                                          borderColor: "$neutrals-dark",
                                        }}
                                      />
                                      <Text
                                        size="2"
                                        fw="700"
                                        css={{
                                          minWidth: "max-content",
                                        }}
                                      >
                                        {roadInfo.roadDuration}
                                      </Text>
                                      <Divider
                                        css={{
                                          width: "80%",
                                          borderColor: "$neutrals-dark",
                                        }}
                                      />
                                    </Flex>
                                  </Flex>
                                  <Flex
                                    direction="column"
                                    gap="3"
                                    align="center"
                                    css={{
                                      flex: "1",
                                    }}
                                  >
                                    <Text variant="darkest" size="3" fw="600">
                                      {displayDate(
                                        road?.arrivalDate as Date,
                                        DateFormats.SMALL_WEEK_DATE
                                      )}
                                    </Text>
                                    <Text fw="700">
                                      {roadInfo.arrivalRoadInfo.dateTimeHour12}
                                    </Text>
                                    <Text variant="darkest" size="3" fw="600">
                                      {roadInfo.arrivalRoadInfo.name}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex
                                  justify="center"
                                  css={{
                                    p: "$2 $3",
                                    borderRadius: "$md",
                                    backgroundColor: "$neutrals-lightest",
                                  }}
                                >
                                  <Flex
                                    direction="column"
                                    gap="2"
                                    align="center"
                                  >
                                    <Text fw="600" size="2" variant="neutral">
                                      Valor Total
                                    </Text>
                                    <Text fw="700" size="5">
                                      {asCurrency(road?.totalPrice || 0)}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </CardBody>
        </Flex>
      </FormDialog>
    </Card>
  );
};
