import * as React from "react";
import { SVGProps } from "react";

const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 27.125c6.42 0 11.625-5.205 11.625-11.625S21.92 3.875 15.5 3.875 3.875 9.08 3.875 15.5 9.08 27.125 15.5 27.125Z"
      stroke="#AE1437"
      strokeWidth={1.938}
      strokeMiterlimit={10}
    />
    <path
      d="M15.5 9.688v6.78"
      stroke="#AE1437"
      strokeWidth={1.938}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.5 22.281a1.453 1.453 0 1 0 0-2.906 1.453 1.453 0 0 0 0 2.906Z" fill="#AE1437" />
  </svg>
);

export default SvgError;
