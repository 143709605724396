import type { CustomerMarkup } from "~/application/types";
import { api } from "~/infrastructure/api";
import { CustomerMarkupDTO } from "~/infrastructure/api/dtos";
import type { ICustomerMarkupService } from "./ICustomerMarkupService";

export class CustomerMarkupService implements ICustomerMarkupService {
  async update(data: CustomerMarkup): Promise<void> {
    return await api
      .patch<undefined, CustomerMarkupDTO>("/customers/markup", {
        customer_uuid: data.customerId,
        markup_uuid: data.markupId,
      })
      .then(({ data }) => data);
  }

  async remove(customerId: string): Promise<void> {
    return await api
      .patch<undefined, CustomerMarkupDTO>(
        `/customers/${customerId}/markup/remove`
      )
      .then(({ data }) => data);
  }
}
