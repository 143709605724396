import { Box } from "~/components/Box";
import { CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgChevronRight } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import * as DateUtils from "~/utils/date.utils";
import { useCallback, useState } from "react";
import { Calendar } from "../DoubleCalendar/Calendar";

export type SingleCalendarProps = {
  date: Date;
  minDate?: Date;
  maxDate?: Date;
  activeMinDate?: Date;
  activeMaxDate?: Date | null;
  onDateClick?: (date: Date) => void;
  onDateSelect?: (selectedDate: { startDate: Date | null, endDate: Date | null }) => void; 
};

export const SingleCalendar = ({
  date,
  activeMaxDate,
  activeMinDate,
  maxDate,
  minDate,
  onDateClick,
  onDateSelect,
}: SingleCalendarProps) => {
  const [currentDate, setCurrentDate] = useState(date);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handlePrevious = useCallback(() => {
    setCurrentDate((old) => DateUtils.getPreviousMonth(old));
  }, [setCurrentDate]);

  const handleNext = useCallback(() => {
    setCurrentDate((old) => DateUtils.getNextMonth(old));
  }, [setCurrentDate]);

  const handleDateClick = (clickedDate: Date) => {
    if (startDate && DateUtils.isSameDay(clickedDate, startDate)) {
      setStartDate(null);
      setEndDate(null);
      onDateSelect?.({ startDate: null, endDate: null });
    } else {
      if (!startDate) {
        setStartDate(clickedDate);
        onDateSelect?.({ startDate: clickedDate, endDate: null });
      } else if (clickedDate < startDate) {
        setStartDate(clickedDate);
        onDateSelect?.({ startDate: clickedDate, endDate: endDate });
      } else {
        setEndDate(clickedDate);
        onDateSelect?.({ startDate: startDate, endDate: clickedDate });
      }
    }
    onDateClick?.(clickedDate);
  };

  return (
    <Flex>
      <Box>
        <CardBody css={{ "@mxmd": { px: "$6", py: "$2" } }}>
          <Flex gap="4" align="center">
            <IconButton onClick={handlePrevious}>
              <Icon as={SvgChevronLeft} />
            </IconButton>

            <Text
              size="2"
              css={{
                lineHeight: "$4",
                fw: "600",
                textAlign: "center",
                flex: "1 0 0%",
              }}
            >
              {DateUtils.getMonthName(currentDate)}
            </Text>

            <IconButton onClick={handleNext}>
              <Icon as={SvgChevronRight} />
            </IconButton>
          </Flex>
          <Calendar
            date={currentDate}
            activeMinDate={activeMinDate}
            activeMaxDate={activeMaxDate}
            minDate={minDate}
            maxDate={maxDate}
            onDateClick={handleDateClick}
          />
        </CardBody>
      </Box>
    </Flex>
  );
};

SingleCalendar.displayName = "SingleCalendar";
