import * as React from "react";
import { SVGProps } from "react";

const SvgExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M12.188 2.5a.625.625 0 0 0-.626.625.625.625 0 0 0 .626.625h3.18l-4.56 4.559a.625.625 0 0 0 0 .882.625.625 0 0 0 .883 0l4.559-4.558v3.18a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.626V3.125a.625.625 0 0 0-.047-.238.625.625 0 0 0-.137-.203.625.625 0 0 0-.441-.184ZM3.75 5c-.331 0-.65.131-.885.365a1.252 1.252 0 0 0-.365.885v10a1.252 1.252 0 0 0 1.25 1.25h10c.33 0 .649-.132.883-.365v-.002c.233-.234.367-.552.367-.883v-5a.625.625 0 0 0-.625-.625.625.625 0 0 0-.625.625v5h-10v-10h5a.625.625 0 0 0 .625-.625A.625.625 0 0 0 8.75 5Z"
    />
  </svg>
);

export default SvgExternalLink;
