import { useCallback, useState } from "react";

export interface UseAdditionalInfoResult {
  isAdditionalInfoExpanded: boolean;
  toggleAdditionalInfoVisible: () => void;
}

export function useAdditionalInfo(): UseAdditionalInfoResult {
  const [isAdditionalInfoExpanded, setAdditionalInfoExpanded] = useState(true);

  const toggleAdditionalInfoVisible = useCallback(() => {
    setAdditionalInfoExpanded((old) => !old);
  }, []);

  return {
    isAdditionalInfoExpanded,
    toggleAdditionalInfoVisible,
  };
}
