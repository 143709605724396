export function formatNumber(value: number, maxLength: number): string {
  return value.toString().padStart(maxLength, "0");
}

export function pluralizeWord(
  singularWord: string,
  pluralWord: string,
  count: number
): string {
  return count > 1 ? pluralWord : singularWord;
}

export function formatSentence(...items: [number, string, string][]): string {
  return items
    .reduce((prev, [quantity, singular, plural]) => {
      if (quantity > 0) {
        return [
          ...prev,
          `${quantity} ${pluralizeWord(singular, plural, quantity)}`,
        ];
      }
      return prev;
    }, [] as string[])
    .join(", ");
}

export const titleize = (string: string, separator = " ") => {
  return string
    .split(separator)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(separator);
};
