import * as React from "react";
import { SVGProps } from "react";

const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="m9.559 5.809-6.25 6.25a.625.625 0 0 0 0 .882.625.625 0 0 0 .882 0L10 7.133l5.809 5.808a.625.625 0 0 0 .882 0 .625.625 0 0 0 0-.882l-6.25-6.25a.625.625 0 0 0-.882 0z"
    />
  </svg>
);

export default SvgChevronUp;
