import { FC } from "react";
import { OnOpenRoadDetailProps } from "../../../RoadsPage";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { ConfirmationUI } from "./ConfirmationUI";

export interface ConfirmationSectionProps {
  onOpenDetails: (data: OnOpenRoadDetailProps) => void;
}

export const ConfirmationSection: FC<ConfirmationSectionProps> = ({
  onOpenDetails,
}) => {
  const { roadReducer } = useRoadsPage();

  return (
    <ConfirmationUI
      bookingInfo={roadReducer.bookingInfo}
      onOpenDetails={onOpenDetails}
    />
  );
};
