import { DeepPartial } from "react-hook-form";
import { ApprovalModel, Approver, User } from "~/application/types";
import {
  ApprovalModelDTO,
  ApproverDTO,
  CreateApprovalModelDTO,
  UpdateApprovalModelDTO,
} from "~/infrastructure/api/dtos";
import { EditableApprovalModel } from "~/presentation/shared/views/ApprovalModelDialog";

export function parseApprovalModelDTO(data: ApprovalModelDTO): ApprovalModel {
  return {
    uuid: data.uuid,
    name: data.name,
    approvalType: data.approval_type,
    branch: data.branch,
    customerEmployeeId: data.customer_employee_uuid,
    isAllBranches: data.all_branches,
    agencyEmployeeId: data.agency_employee_uuid,
    costCenters: data.cost_centers,
    isActive: data.active,
    applyExpense: data.apply_expense,
    approvers: data.approvers.map(parseApproverDTO),
    approverExpense: {
      uuid: data.approver_expense?.uuid,
      name: data.approver_expense?.name,
    },
  };
}

export interface ParseCreationBodyParams {
  readonly customerId: string;
  readonly user: User;
  readonly data: DeepPartial<EditableApprovalModel>;
}

export function parseCreationBody({
  customerId,
  user,
  data,
}: ParseCreationBodyParams): CreateApprovalModelDTO {
  const body: Partial<CreateApprovalModelDTO> = {
    name: data.name as string,
    customer_uuid: customerId,
    approval_type: "",
    mark_all_cost_centers: data.markAllCostCenters,
    cost_centers: (data.costCenters || []).map((costCenter) => ({
      uuid: costCenter?.uuid as string,
    })),
    customer_employee_uuid: user.customer?.uuid,
    agency_employee_uuid: user.agency?.uuid,
    apply_expense: data.applyExpense,
    approver_expense_uuid: !data.applyExpense ? data.expenseApprover?.uuid : "",
    all_branches: data.isAllBranches,
    out_policy_approval_model_uuid: data.outPolicyApprovalModel?.uuid,
    levels: (data.levels ?? []).map((l) => ({
      approvers: (l?.approvers ?? []).map((a) => ({
        uuid: a?.uuid as string,
        self_approver: !!a?.selfApprover,
      })),
    })),
  };

  if (data.branchName?.uuid) {
    body.branch_uuid = data.branchName.uuid;
    body.all_branches = false;
  }

  return body as CreateApprovalModelDTO;
}

export function parseUpdateBody(data: EditableApprovalModel): UpdateApprovalModelDTO {
  const body: Partial<UpdateApprovalModelDTO> = {
    uuid: data.uuid,
    name: data.name as string,
    all_branches: data.isAllBranches,
    apply_expense: data.applyExpense,
    approver_expense_uuid: !data.applyExpense ? data.expenseApprover?.uuid : "",
    out_policy_approval_model_uuid: data.outPolicyApprovalModel?.uuid,
    mark_all_cost_centers: data.markAllCostCenters,
    cost_centers: (data.costCenters || []).map((costCenter) => ({
      uuid: costCenter?.uuid as string,
      is_active: costCenter.isActive as boolean,
    })),
    levels: (data.levels ?? []).map((l) => ({
      approvers: (l?.approvers ?? []).map((a) => ({
        uuid: a?.uuid as string,
        self_approver: !!a?.selfApprover,
      })),
    })),
  };

  if (!data.isAllBranches) {
    body.branch_uuid = data.branchName?.uuid;
  }

  return body as UpdateApprovalModelDTO;
}

export function parseApproverDTO(approver: ApproverDTO): Approver {
  return {
    uuid: approver.uuid,
    approverModelId: approver.approver_uuid,
    approverId: approver.uuid,
    name: approver.name,
    order: approver.order,
    level: approver.level,
    selfApprover: approver.self_approver,
    isActive: approver.is_active,
  } as Approver;
}
