import { states } from "~/application/data";
import { Agency } from "~/application/types";
import { AgencyDTO } from "../dtos";

export function mapAgencyDTO(data: AgencyDTO): Agency {
  const state = states.find((s) => s.state === data.state)!;

  return {
    uuid: data.uuid,
    companyName: data.company_name,
    tradingName: data.trading_name,
    cnpj: data.cnpj,
    phone: data.phone,
    zipCode: data.zip_code,
    address: data.address,
    number: data.number,
    district: data.district,
    state: state,
    city: {
      uuid: data.city_uuid,
      name: data.city,
      state: state?.state,
      country: "",
    },
    isActive: Boolean(data.is_active),
    stateRegistration: data.state_registration,
    email: data.email,
  };
}
