import React from "react";
import { RenderElementProps, RenderLeafProps } from "slate-react";

type CustomElement =  { type: string; children: CustomText[] }

type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  code?: boolean;
};

export function Element({ attributes, children, element }: RenderElementProps) {
  const customElement = element as CustomElement;
  
  switch (customElement.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;

    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;

    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;

    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;

    case "list-item":
      return <li {...attributes}>{children}</li>;
    
    case "italic":
      return <em {...attributes}>{children}</em>;


    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;

    default:
      return <p {...attributes}>{children}</p>;
  }
}

export function Leaf({ attributes, children, leaf }: RenderLeafProps) {

  const customLeaf = leaf as CustomText;

  if (customLeaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (customLeaf.code) {
    children = <code>{children}</code>;
  }

  if (customLeaf.italic) {
    children = <em>{children}</em>;
  }

  if (customLeaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
}
