import * as React from "react";
import { SVGProps } from "react";

const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M2.5 9.375a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h15a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625ZM2.5 4.375A.625.625 0 0 0 1.875 5a.625.625 0 0 0 .625.625h15A.625.625 0 0 0 18.125 5a.625.625 0 0 0-.625-.625ZM2.5 14.375a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h15a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625Z"
    />
  </svg>
);

export default SvgMenu;
