import { FC, Ref, forwardRef } from "react";
import { UserContext } from "~/application/types";
import { Avatar } from "~/components/Avatar";
import { Flex } from "~/components/Flex";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Styled } from "./styled";

interface ProfileCardProps {
  onSetCustomerContextClick: () => void;
}

const Component = ({ onSetCustomerContextClick }: ProfileCardProps, ref: Ref<HTMLDivElement>) => {
  const { user, contexts } = useUser();
  const isBiztrip = user.context === UserContext.Biztrip;
  const nameWords = user.name.split(" ");
  const firstName = nameWords.at(0);
  const lastName = nameWords.at(nameWords.length - 1);

  return (
    <Styled.Container ref={ref}>
      {isBiztrip && <Tag variant="info-light">Administrador</Tag>}
      {user.context === UserContext.Agency && contexts.customer?.companyName && (
        <Tag
          onClick={(e) => {
            onSetCustomerContextClick();
            e.stopPropagation();
          }}
          variant="info-light"
        >
          Empresa
        </Tag>
      )}

      <Avatar src={user.avatarUrl} size="md" />

      <Flex direction="column" gap="2">
        <Text size="2" css={{ fw: "700" }}>
          {nameWords.length > 2 ? `${firstName} ${lastName}` : user.name}
        </Text>

        {!isBiztrip && (
          <Text size="1" variant="darkest">
            {contexts.customer ? contexts.customer?.companyName : contexts.agency?.companyName}
          </Text>
        )}
      </Flex>
    </Styled.Container>
  );
};

export const ProfileCard: FC<ProfileCardProps> = forwardRef(Component);

ProfileCard.displayName = "ProfileCard";
