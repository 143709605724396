import { ReasonTrip } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface ReasonTripListItemProps extends DataListItemProps {
  data: ReasonTrip;
  onEditClick: (item: ReasonTrip) => void;
  onToggleState: (item: ReasonTrip) => void;
}

export function ReasonTripListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: ReasonTripListItemProps) {
  const isMobile = useMobile();
  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Motivo" data={data.reason} css={{ wordBreak: "break-all" }} />

      {isMobile ? (
        <Flex css={{ width: "100%", justifyContent: "space-between" }}>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </Flex>
      ) : (
        <>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </>
      )}
    </DataListItem>
  );
}

ReasonTripListItem.displayName = "ReasonTripListItem";
