import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { ApiError, Branch, DeepPartial, PaginatedResource } from "~/application/types";
import { branchService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { BranchDialog } from "../views/Branch/components/BranchDialog";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";

export type UseBranchesResult = {
  data?: Branch[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  searchText: string;
  onGoToPage: (value: number) => void;
  setSearchText: (search: string) => void;
  onCreateBranch: () => void;
  onEditBranch: (item: Branch) => void;
  onToggleBranchState: (item: Branch) => void;
};

export interface UseBranchesOptions {
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useBranches";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar filiais",
  CREATE_SUCCESS_MESSAGE: "Nova filial adicionada",
  CREATE_ERROR_MESSAGE: "Falha ao criar filial",
  UPDATE_SUCCESS_MESSAGE: "Filial atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar filial",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar filial",
  ACTIVATE_SUCCESS_MESSAGE: "Filial ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar filial",
  INACTIVATE_SUCCESS_MESSAGE: "Filial inativada",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<Branch> = {
  name: "",
  email: "",
  phone: "",
  cnpj: "",
  integrationId: "",
};

export function useBranches({ customerId, enabled }: UseBranchesOptions): UseBranchesResult {
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<PaginatedResource<Branch[]>, ApiError>(
    [QueryKeys.CUSTOMER_BRANCHES, customerId, currentPage, { search }],
    () =>
      branchService.find({
        customerId,
        page: currentPage,
        name: search,
      }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutateAsync: mutateCreateBranch } = useMutation(
    async (item: Branch) => await branchService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Branch(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando nova filial" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created Branch(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_BRANCHES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <BranchDialog isNew onSubmit={mutateCreateBranch} defaultData={DEFAULT_FORM_DATA} />
    );
  }, [mutateCreateBranch]);

  const { mutateAsync: mutateUpdateBranch } = useMutation(
    async (branch: Branch) => await branchService.updateById(branch),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Branch(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando filial" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Branch(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_BRANCHES, customerId]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const handleOpenEditModal = useCallback(
    (item: Branch) => {
      dialogService.showDialog(<BranchDialog onSubmit={mutateUpdateBranch} defaultData={item} />);
    },
    [mutateUpdateBranch]
  );

  const { mutate: mutateToggleBranchState } = useMutation(
    (item: Branch) => branchService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Branch(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Branch(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_BRANCHES, customerId]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((branch: Branch) => {
    if (branch.isActive) {
      return dialogService.showDialog(
        <InactivateDialog
          loadingMessage="Inativando filial"
          onConfirm={() => mutateToggleBranchState(branch)}
        />
      );
    }

    mutateToggleBranchState(branch);
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page ?? 0,
    searchText: searchText,
    onGoToPage: setCurrentPage,
    setSearchText: handleChangeSearch,
    onCreateBranch: handleOpenCreateModal,
    onEditBranch: handleOpenEditModal,
    onToggleBranchState: handleToggleState,
  };
}
