import { Approver } from "~/application/types";
import { ApproverDTO } from "../dtos";

export function mapApproverDTO(approver: ApproverDTO): Approver {
  return {
    uuid: approver.approver_uuid,
    approverModelId: approver.uuid,
    name: approver.name,
    order: approver.order,
    level: approver.level,
    selfApprover: approver.self_approver,
    isActive: approver.is_active,
  } as Approver;
}
