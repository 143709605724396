import { FC } from "react";
import { Order, OrderItems } from "~/application/types";
import { Alert } from "~/components/Alert";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgAlertTriangle, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { Tooltip } from "~/components/Tooltip";

export type AlertViolatedPoliciesPerProductsType = {
  order?: Order;
  itemType: OrderItems;
};

export const AlertViolatedPoliciesPerProducts: FC<AlertViolatedPoliciesPerProductsType> = ({
  order,
  itemType,
}: AlertViolatedPoliciesPerProductsType) => {
  const violatadPolicy = order?.orderViolatedPolicies?.[itemType];

  return (
    <>
      {!!violatadPolicy?.length && (
        <Alert variant="warning" size="md">
          <Icon as={SvgAlertTriangle} size="md" />
          <Flex align="center" css={{ flex: "1" }} justify="center" gap="2">
            <Text variant="warning-dark">
              Seu pedido se encontra <Text fw="600">fora das políticas</Text> de compra
            </Text>
            <Tooltip
              css={{
                backgroundColor: "$warning-light",
                color: "$warning-dark",
                p: "$5",
              }}
              content={
                <Flex direction="column" gap="2">
                  <Text>Politicas violadas:</Text>
                  <ul>
                    {violatadPolicy?.map((policy) => (
                      <li key={policy.message}>{policy.message}</li>
                    ))}
                  </ul>
                </Flex>
              }
            >
              <IconButton size="md">
                <Icon as={SvgInfo} />
              </IconButton>
            </Tooltip>
          </Flex>
        </Alert>
      )}
    </>
  );
};
