import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { ApiError, Justification } from "~/application/types";
import { justificationService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { InactivateDialog } from "~/presentation/shared/views/InactivateDialog";
import { JustificationDialog } from "~/presentation/shared/views/JustificationDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";

const LOG_TAG = "CustomerSettings/JustificationsPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar justificativas",
  CREATE_SUCCESS_MESSAGE: "Nova justificativa de quebra de política adicionada",
  CREATE_ERROR_MESSAGE: "Falha ao criar justificativa",
  UPDATE_SUCCESS_MESSAGE: "Justificativa atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar justificativa",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar justificativa",
  ACTIVATE_SUCCESS_MESSAGE: "Justificativa ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar justificativa",
  INACTIVATE_SUCCESS_MESSAGE: "Justificativa inativada",
} as const;

export function useJustificationsPage() {
  const { contexts } = useUser();

  const customerId = contexts.customer.uuid;

  const { data, isFetching } = useQuery<Justification[], ApiError>(
    [QueryKeys.CUSTOMER_JUSTIFICATIONS, customerId],
    () => justificationService.find({ customerId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateCreateJustification } = useMutation(
    (item: Justification) =>
      justificationService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Justification(${item.uuid})`);
      },
      onSuccess: (data) => {
        log.i(LOG_TAG, `Successfully created Justification(${data.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_JUSTIFICATIONS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateUpdateJustification } = useMutation(
    (item: Justification) => justificationService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Justification(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Justification(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_JUSTIFICATIONS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: Justification) => justificationService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Justification(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Justification(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_JUSTIFICATIONS]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: Justification) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog onConfirm={() => mutateToggleState(item)} />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <JustificationDialog onSubmit={mutateCreateJustification} />
    );
  }, [customerId]);

  const handleOpenEditModal = useCallback((item: Justification) => {
    dialogService.showDialog(
      <JustificationDialog data={item} onSubmit={mutateUpdateJustification} />
    );
  }, []);

  return {
    data: data,
    isLoading: isFetching,
    onCreateJustification: handleOpenCreateModal,
    onEditJustification: handleOpenEditModal,
    onToggleJustificationState: handleToggleState,
  };
}
