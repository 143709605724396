import { OrderItems } from "~/application/types";

export enum DashboardBIAdditionalInfoDTO {
    NEW_CLIENTS_MONTH = 'newClientsPurchaseMonth',
    PENDING_FIRST_PURCHASE = 'pendingFirstPurchase',
    NOT_BUYING_CUSTOMERS = 'notBuyingCustomers',
    LOGOUT_CUSTOMERS = 'logoutCustomers',
    ORDERS_NOT_ISSUED_WEEK = 'ordersNotIssuedWeek',
    OPERATIONAL_INTERVENTIONS = 'operationalInterventions'
}

type AdditionalInfoBIDTO = {
    value?: number;
    description?: string;
};

export type PurchaseReportDTO = {
    trading_name: string;
    logged_username: string;
    issued_orders_amount: number;
    login_amount: number;
    not_issued_orders_amount: number;
    login_days: number;
    logout_days: number;
    date: string;
    agreement_uuid: string;
    service_type: OrderItems;
    interventions_amount: number;
    provider_name: string;
    order_amount: number;
  };

export type OperationalInterventionReportDTO = {
    date: string;
    agreement_uuid: string;
    provider_name: string;
    service_type: OrderItems;
    interventions_amount: number;
};

export type NotBuyingCustomerReport = PurchaseReportDTO & { 
    login_days: number 
};

export type LogoutCustomerReport = PurchaseReportDTO & { 
    logout_days: number 
};

export type DashboardBIDTO = {
    value: number; 
    type: 'days' | 'months' | 'hours';
    data: Array<{
       year: string;
       month: string;
       day: string;
       hour: string;
       sales_amount: number;
    }>
    additional_info: {
        new_clients_purchase_month: AdditionalInfoBIDTO & {
            report: Array<{
                trading_name: string;
                orders_amount: number;
            }>
        };
        pending_first_purchase: AdditionalInfoBIDTO & {
            report: PurchaseReportDTO[];
        };
        not_buying_customers: AdditionalInfoBIDTO & {
            report: NotBuyingCustomerReport[];
        };
        logout_customers: AdditionalInfoBIDTO & {
            report: LogoutCustomerReport[];
        };
        orders_not_issued_week: AdditionalInfoBIDTO & {
            report: Omit<PurchaseReportDTO, 'login_amount'>[];
        };
        operational_interventions: AdditionalInfoBIDTO & {
            report: OperationalInterventionReportDTO[];
        };
    };
};
