import type { HotelQuery } from "~/application/types";
import { useBookingHotel } from "../../contexts/BookingHotelContext";
import { HotelQueryContainer } from "./HotelQueryContainer";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingHotel/HotelQueryPage";

const DEFAULT_FORM_DATA: Partial<HotelQuery> = {
  accommodations: [{ adultQuantity: 1, guestsChildren: [] }],
};

export function HotelQueryPage() {
  const { onSearchHotels, setQuantityRooms, order } = useBookingHotel();

  return (
    <HotelQueryContainer
      defaultQuery={DEFAULT_FORM_DATA}
      onSearchHotels={onSearchHotels}
      setQuantityRooms={setQuantityRooms}
      order={order}
    />
  );
}
