import queryString from "query-string";
import type { City, State } from "~/application/types";
import { api } from "~/infrastructure/api";
import { CityDTO, StateDTO } from "~/infrastructure/api/dtos";
import { mapCityDTO, mapStateDTO } from "~/infrastructure/api/mappers";
import type { IStateService } from "./IStateService";

export class StateService implements IStateService {
  async find(): Promise<State[]> {
    const url = queryString.stringifyUrl({
      url: "/expense-categories",
      query: {},
    });

    return await api
      .get<StateDTO[]>(url)
      .then(({ data }) => data.map(mapStateDTO));
  }

  async findCities(state: State): Promise<City[]> {
    return await api
      .get<CityDTO[]>(`/states/${state.state}/cities`)
      .then(({ data }) => data.map(mapCityDTO));
  }
}
