import { FC } from "react";
import { UserContext } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit, SvgLock } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import * as MaskUtils from "~/utils/mask.utils";
import { CustomerEmployeeListItemProps } from "./types";
import useMobile from "../../hooks/useMobile";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";

export const CustomerEmployeeListItem: FC<CustomerEmployeeListItemProps> = ({
  data,
  onEditClick,
  onToggleState,
  onChangePassword,
  ...props
}) => {
  const { user } = useAuth();
  const isMobile = useMobile();

  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{ "@mxlg": { display: "flex", flexDirection: "column" }, display: "flex", flex: "1" }}
    >
      <DataColItem
        headerTitle="Nome"
        data={<Text fw="600">{data.name + " " + data.lastName}</Text>}
      />
      <DataColItem
        headerTitle="Email"
        data={
          <Text fw="600" css={{ wordBreak: "break-all" }}>
            {data.email}
          </Text>
        }
      />
      <DataColItem headerTitle="Perfil" data={data.profile?.name} />
      <DataColItem headerTitle="CPF" data={<Text fw="600">{MaskUtils.asCPF(data.cpf)}</Text>} />

      {isMobile ? (
        <Flex justify="between" css={{ width: "100%" }}>
          {user.context === UserContext.Agency && (
            <Tooltip content={<TooltipLabel>Solicitar alteração de senha</TooltipLabel>}>
              <IconButton size="md" onClick={() => onChangePassword && onChangePassword(data.uuid)}>
                <Icon as={SvgLock} />
              </IconButton>
            </Tooltip>
          )}

          {onEditClick && (
            <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
              <IconButton size="md" onClick={() => onEditClick(data)}>
                <Icon as={SvgEdit} />
              </IconButton>
            </Tooltip>
          )}

          {onToggleState && (
            <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
              <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
            </Switch>
          )}
        </Flex>
      ) : (
        <>
          {user.context === UserContext.Agency && (
            <Tooltip content={<TooltipLabel>Solicitar alteração de senha</TooltipLabel>}>
              <IconButton size="md" onClick={() => onChangePassword && onChangePassword(data.uuid)}>
                <Icon as={SvgLock} />
              </IconButton>
            </Tooltip>
          )}

          {onEditClick && (
            <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
              <IconButton size="md" onClick={() => onEditClick(data)}>
                <Icon as={SvgEdit} />
              </IconButton>
            </Tooltip>
          )}

          {onToggleState && (
            <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
              <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
            </Switch>
          )}
        </>
      )}
    </DataListItem>
  );
};

CustomerEmployeeListItem.displayName = "CustomerEmployeeListItem";
