import { useCallback, useState } from "react";
import { OfflineHotel } from "~/application/types";
import {
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { OfflineHotelListItem } from "./components/OfflineHotelListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Flex } from "~/components/Flex";
import { Box } from "~/components/Box";

export interface OfflineHotelsContainerProps {
  isLoading: boolean;
  offlineHotels: OfflineHotel[] | undefined;
  currentPage: number;
  lastPage: number;
  descriptionFilter: string;
  cityFilter: string;
  setDescriptionFilter: (search: string) => void;
  setCityFilter: (search: string) => void;
  onGoToPage: (page: number) => void;
  onToggleState: (data: OfflineHotel) => void;
}

export function OfflineHotelsContainer({
  offlineHotels,
  currentPage,
  lastPage,
  descriptionFilter,
  cityFilter,
  setDescriptionFilter,
  setCityFilter,
  onGoToPage,
  onToggleState,
}: OfflineHotelsContainerProps) {
  const [searchInput, setSearchInput] = useState(false);
  const isMobile = useMobile();

  const listRenderer = useCallback(
    (item: OfflineHotel) => (
      <OfflineHotelListItem
        key={item.uuid}
        data={item}
        onToggleState={onToggleState}
      />
    ),
    [onToggleState]
  );
  
  return (
    <>
      <AppBar>
        <AppBarContainer>
          <Flex
            css={{
              "@mxlg": {
                display: searchInput ? "none" : "flex",
                width: "100%",
              },
            }}
          >
            <AppBarHeading
              title="Hotéis offline"
            />
          </Flex>

          <Box
            onClick={() => setSearchInput(false)}
            css={{
              zIndex: 10000,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(100, 100, 100, 0.5)",
              display: !searchInput ? "none" : "block",
            }}
          />

          <AppBarActions>
            {!isMobile ? (
              <Flex gap="4">
                <SearchBar
                  search={descriptionFilter}
                  onSearchChange={setDescriptionFilter}
                  placeholder="Encontrar pela descrição"
                />

                <SearchBar
                  search={cityFilter}
                  onSearchChange={setCityFilter}
                  placeholder="Encontrar pela cidade"
                />
              </Flex>
            ) : (
              <Flex direction={searchInput ? "column" : "row"} gap="2">
                <SearchBar
                  search={descriptionFilter}
                  onSearchChange={setDescriptionFilter}
                  placeholder="Encontrar pela descrição"
                  style={{
                    width: searchInput ? "100%" : "34%",
                    marginRight: "10px",
                    paddingTop: "0px",
                    zIndex: "10001",
                  }}
                  onClick={() => setSearchInput(true)}
                  onBlur={() => setSearchInput(false)}
                />
                <SearchBar
                  search={cityFilter}
                  onSearchChange={setCityFilter}
                  placeholder="Encontrar pela cidade"
                  style={{
                    width: searchInput ? "100%" : "34%",
                    marginRight: "10px",
                    paddingTop: "0px",
                    zIndex: "10001",
                  }}
                  onClick={() => setSearchInput(true)}
                  onBlur={() => setSearchInput(false)}
                />
              </Flex>
            )}

            <Link
              title="Novo hotel offline"
              to="/configuracoes/hoteis-offline/cadastrar"
            >
              <Button
                css={{
                  "@mxlg": {
                    background: "#fff",
                  },
                }}
              >
                <Icon
                  as={SvgPlus}
                  css={{
                    "@mxlg": {
                      fill: "black",
                    },
                  }}
                />
                <Text
                  css={{
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  Novo hotel offline
                </Text>
              </Button>
            </Link>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container
        css={{
          pt: "$10",
          pb: "$20",
          "@mxlg": {
            p: "$5",
          },
        }}
      >
        <LazyList
          gap="6"
          items={offlineHotels}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {(descriptionFilter.length > 0) || (cityFilter.length > 0)  ? (
                <Text>Nenhuma hotel offline corresponde aos dados informados</Text>
              ) : (
                <Text>Você não possui hotéis offline cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </>
  );
}
