import * as React from "react";
import { SVGProps } from "react";

const SvgVooIda = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M.31 15.995h18.75v1.25H.31v-1.25ZM17.485 3.814c-.914-.255-1.885-.237-2.735.05l-.035.012-2.543 1.209-7.421-2.95a1.864 1.864 0 0 0-1.944.346l-.994.89a.937.937 0 0 0 .084 1.463l4.6 3.26-1.764.924-1.398-1.276a1.878 1.878 0 0 0-2.01-.336l-.76.33a.937.937 0 0 0-.455 1.3l2.845 5.345 15.491-7.636c.928-.437 1.04-1.134.837-1.643-.226-.563-.882-1.033-1.798-1.288Zm.425 1.802-.005.002-14.416 7.106-2.116-3.977.449-.194a.626.626 0 0 1 .67.111l2.044 1.868 4.35-2.278-5.947-4.213.702-.63a.621.621 0 0 1 .648-.115l7.928 3.152 2.966-1.41c.604-.196 1.323-.202 1.98-.017.54.153.827.375.924.492a.943.943 0 0 1-.177.103Z" />
  </svg>
);

export default SvgVooIda;
