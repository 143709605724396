import queryString from "query-string";
import { Agency, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { AgencyDTO } from "~/infrastructure/api/dtos";
import { mapAgencyDTO } from "~/infrastructure/api/mappers";
import type { IAgencyService, IFindAgencyParams } from "./IAgencyService";

export class AgencyService implements IAgencyService {
  async findById(id: string): Promise<Agency> {
    return await api
      .get<AgencyDTO>(`/agencies/${id}`)
      .then(({ data }) => mapAgencyDTO(data));
  }

  async find({
    page = 1,
    name,
  }: IFindAgencyParams): Promise<PaginatedResource<Agency[]>> {
    const url = queryString.stringifyUrl({
      url: "/agencies",
      query: { page, name },
    });

    return await api
      .get<PaginatedResource<AgencyDTO[]>>(url)
      .then(({ data }) => ({
        links: data.links,
        meta: data.meta,
        data: data.data.map(mapAgencyDTO),
      }));
  }

  async create(data: Omit<Agency, "uuid">): Promise<Agency> {
    return await api
      .post<AgencyDTO>("/agencies", {
        company_name: data.companyName,
        trading_name: data.tradingName,
        state_registration: data.stateRegistration,
        cnpj: data.cnpj.replace(/\D/g, ""),
        email: data.email,
        phone: data.phone.replace(/\D/g, ""),
        zip_code: data.zipCode,
        address: data.address,
        number: data.number,
        district: data.district,
        state: data.state.state,
        city_uuid: data.city.uuid,
        city: data.city.name,
      })
      .then(({ data }) => mapAgencyDTO(data));
  }

  async updateById(data: Agency): Promise<Agency> {
    return await api
      .put<AgencyDTO>(`/agencies/${data.uuid}`, {
        company_name: data.companyName,
        trading_name: data.tradingName,
        cnpj: data.cnpj.replace(/\D/g, ""),
        email: data.email,
        phone: data.phone.replace(/\D/g, ""),
        zip_code: data.zipCode,
        address: data.address,
        number: data.number,
        district: data.district,
        state: data.state.state,
        city_uuid: data.city.uuid,
        city: data.city.name,
      })
      .then(({ data }) => mapAgencyDTO(data));
  }

  async toggleActive(data: Agency): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/agencies/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/agencies/${data.uuid}/active`)
      .then(({ data }) => data);
  }
}
