export enum Actions {
  ActiveApprovalModel = "active-approval-model",
  ActiveCustomerEmployee = "active-customer-employee",
  ActiveExpenseType = "active-expense-type",
  ActiveJustification = "active-justification",
  ActivePolicy = "active-policy",
  ActivePolicyValueExpense = "active-policy-value-expense",
  ActiveReasonTrip = "active-reason-trip",
  CancelAirwayOrder = "cancel-airway-order",
  CreateProfileApprover = "create-profile-approver",
  CreateApprovalModel = "create-update-approval-model",
  CreateCostCenter = "create-cost-center",
  CreateCustomerEmployee = "create-customer-employee",
  CreateExpenseType = "create-expense-type",
  CreateJustification = "create-justification",
  CreateOrder = "create-order",
  CreateOrderHotel = "create-order-hotel",
  CreatePolicy = "create-policy",
  UpdatePolicyValue = "update-policy-value",
  CreatePolicyParameter = "create-policy-parameter",
  CreatePolicyValue = "create-update-policy-value",
  CreatePolicyValueExpense = "create-policy-value-expense",
  CreateProfile = "create-profile",
  CreateReasonTrip = "create-reason-trip",
  DeleteCostCenter = "delete-cost-center",
  DeleteExpenseType = "delete-expense-type",
  DoneAirwayOrder = "done-airway-order",
  ViewCustomerEmployee = "view-customer-employee",
  GetDetailsExpenseType = "get-details-expense-type",
  GetExpenseType = "get-expense-type",
  GetOrder = "get-order",
  GetPolicyValueExpense = "get-policy-value-expense",
  GetProfile = "get-profile",
  ViewReasonTrip = "view-reason-trip",
  InactiveApprovalModel = "inactive-approval-model",
  InactiveCustomerEmployee = "inactive-customer-employee",
  InactiveExpenseType = "inactive-expense-type",
  InactiveJustification = "inactive-justification",
  InactivePolicy = "inactive-policy",
  InactivePolicyValueExpense = "inactive-policy-value-expense",
  InactiveReasonTrip = "inactive-reason-trip",
  SetActionProfile = "set-action-profile",
  SetDefaultProfile = "set-default-profile",
  UpdateApprovalModel = "update-approval-model",
  UpdateCostCenter = "update-cost-center",
  UpdateCustomerEmployee = "update-customer-employee",
  UpdateExpenseType = "update-expense-type",
  UpdateJustification = "update-justification",
  UpdatePolicy = "update-policy",
  CreateUpdateProject = "create-update-project",
  CreateUpdateBudget = "create-update-budget",
  UpdatePolicyValueExpense = "update-policy-value-expense",
  UpdateProfile = "update-profile",
  UpdateReasonTrip = "update-reason-trip",
  ViewAirwayOrder = "view-airway-order",
  ViewAnyApprovalModel = "view-any-approval-model",
  ViewAnyPolicy = "view-any-policy",
  ViewApprovalModel = "view-approval-model",
  ViewBranch = "view-branch",
  ViewCostCenter = "view-cost-center",
  ViewCustomerProfiles = "view-customer-profiles",
  ViewHotelOrder = "view-hotel-order",
  ViewJustification = "view-justification",
  ViewPolicy = "view-policy",
  ViewPolicyParameter = "view-policy-parameter",
  ViewSettingParameter = "view-setting-parameter",
  UpdateSettingParameter = "update-setting-parameter",
  ViewPolicyValue = "view-policy-value",
  ViewProject = "view-project",
  ViewOrder = "view-order",
  ViewAccountabilityExpense = "view-accountability-expense",
  ViewDashboard = "view-dashboard",
  ViewPurchaseReport = "view-purchase-report",
  ViewAirwaySeats = "view-airway-seats",
  ViewCreditLimit = "view-credit-limit",
  CreateVehicleOrder = "create-vehicle-order",
  CreateAirwayOrder = "create-airway-order",
  CreateHotelOrder = "create-hotel-order",
  CreateRoadOrder = "create-road-order",
  ViewBudget = "view-budget",
}
