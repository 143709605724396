import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { SvgMinus, SvgPlus } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import React, {
  ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { styled } from "~/application/theme";

const StyledCounter = styled("div", {
  // Reset
  boxSizing: "border-box",

  display: "inline-flex",
  alignItems: "center",
  gap: "$4",
});

export type CounterProps = Omit<
  ComponentProps<typeof StyledCounter>,
  "onChange"
> & {
  name?: string;
  onChange?: (event: { target: any; type?: any }) => void;
  onIncrease?: VoidFunction;
  onDecrease?: VoidFunction;
  defaultValue?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
};

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const Counter = forwardRef<
  React.ElementRef<typeof StyledCounter>,
  CounterProps
>(
  (
    {
      name,
      defaultValue = 0,
      minValue = -Infinity,
      maxValue = Infinity,
      disabled,
      onChange,
      onIncrease,
      onDecrease,
      ...props
    },
    forwardedRef
  ) => {
    const [count, setCount] = useState<number>(defaultValue);

    const handleIncrease = useCallback(() => {
      setCount((old) => (old >= maxValue ? maxValue : old + 1));
      onIncrease?.();
    }, [maxValue, setCount, onIncrease]);

    const handleDecrease = useCallback(() => {
      setCount((old) => (old <= minValue ? minValue : old - 1));
      onDecrease?.();
    }, [minValue, setCount, onDecrease]);

    useEffect(() => {
      if (onChange) {
        onChange({
          target: {
            name: name,
            value: count,
          },
          type: "change",
        });
      }
    }, [onChange, count]);

    return (
      <StyledCounter {...props} ref={forwardedRef}>
        <Button
          variant="tertiary"
          disabled={disabled || (!isNaN(minValue) && minValue >= count)}
          onClick={handleDecrease}
          type="button"
        >
          <Icon as={SvgMinus} />
        </Button>
        <Text>{count.toString()}</Text>
        <Button
          variant="tertiary"
          disabled={disabled || (!isNaN(maxValue) && maxValue <= count)}
          onClick={handleIncrease}
          type="button"
        >
          <Icon as={SvgPlus} />
        </Button>
      </StyledCounter>
    );
  }
);

Counter.displayName = "Counter";
