import { useCallback, useEffect } from "react";
import { Road, RoadQuery, RoadSeat, RoadTraveler } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { LogTagProvider } from "~/presentation/core/providers/LogTagProvider";
import { RoadDetails } from "../../../../shared/components/RoadDetails";
import { RoadsPageProvider } from "./contexts/RoadsPageContext";
import { useRoadBooking } from "./hooks/useRoadBooking";
import { useRoadParams } from "./hooks/useRoadParams";
import { useRoadReducer } from "./hooks/useRoadReducer";
import { useRoadQuery } from "./hooks/useRoadsQuery";
import { RoadsPageUI } from "./ui/RoadsPageUI";
import { useDeleteOrderRoad } from "~/presentation/shared/hooks/useDeleteOrderRoad";
import { useRoadBudget } from "./hooks/useRoadBudget/useRoadBudget";
import { BrokenPolicyJustificationProvider } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";

export type OnOpenRoadDetailProps = {
  road: Road;
  seats?: RoadSeat[];
  seatsConnection?: RoadSeat[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingRoad/RoadsPage";

export function RoadsPage() {
  const { contexts, user } = useUser();

  const roadParams = useRoadParams();
  const roadReducer = useRoadReducer();

  const { bookingInfo } = roadReducer;
  const roadBudget = useRoadBudget();

  const formatRoadParams = roadParams?.stretches?.map(
    (stretch) =>
      ({
        reasonTrip: roadParams.reasonTrip,
        dateGoSelected: stretch.dateGoSelected,
        dateReturnSelected: stretch.dateReturnSelected,
        destinationSelected: stretch.destinationSelected,
        originSelected: stretch.originSelected,
      } as RoadQuery)
  );

  const isManyStretch = (formatRoadParams?.length || 0) > 1;

  const { order, roadQueryLinks, isLoadingLinks, refetchRoadQueryLinks } = useRoadQuery({
    logTag: LOG_TAG,
    contexts,
    roadParams: formatRoadParams?.at(0) as RoadQuery,
  });

  const { isOneWay, roadQuery, bookingStep, bookingSteps, currentStep, setCurrentStep } =
    useRoadBooking({
      roadParams: formatRoadParams,
      roadQueryLinks,
      isLoadingLinks,
      roadBudget,
      isManyStretch,
    });

  const { delete: deleteOrderRoad } = useDeleteOrderRoad(LOG_TAG, {
    order,
  });

  useEffect(() => {
    roadReducer.dispatch({
      type: "SET_ISSUER",
      payload: user.profiles.customer?.uuid,
    });
  }, [user, order]);
  const onOpenDetails = useCallback(
    ({ road, seats, seatsConnection }: OnOpenRoadDetailProps) => {
      const travelers: RoadTraveler[] = bookingInfo.travelers.map((traveler) => ({
        ...traveler,
        seats: [],
      }));

      dialogService.showDialog(
        <RoadDetails
          data={{ ...road, travelers }}
          seats={seats?.map((s) => s.seat)}
          seatsConnection={seatsConnection?.map((s) => s.seat)}
        />
      );
    },
    [bookingInfo]
  );

  const roadContext = {
    isOneWay,
    roadReducer,
    roadQuery,
    bookingStep,
    order,
    refetchRoadQueryLinks,
    deleteOrderRoad,
    roadBudget,
    roadParams: formatRoadParams,
    isManyStretch,
  };

  return (
    <BrokenPolicyJustificationProvider>
      <LogTagProvider logTag={LOG_TAG}>
        <RoadsPageProvider value={roadContext}>
          <RoadsPageUI
            bookingInfo={bookingInfo}
            bookingSteps={bookingSteps}
            bookingMobileSteps={bookingSteps}
            bookingStep={bookingSteps[currentStep].key}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onOpenDetails={onOpenDetails}
          />
        </RoadsPageProvider>
      </LogTagProvider>
    </BrokenPolicyJustificationProvider>
  );
}
