import { snackbarService } from "~/components/SnackbarStack";
import { log } from "~/utils/log";

export interface LogErrorProps {
  readonly error: any;
  readonly logTag: string;
  readonly defaultErrorMessage: string;
}

export function logError({
  error,
  logTag,
  defaultErrorMessage,
}: LogErrorProps) {
  log.e(logTag, error);

  if (error?.response && error.response?.status >= 500) {
    return snackbarService.showSnackbar(defaultErrorMessage, "error");
  }

  if (error?.message) {
    snackbarService.showSnackbar(error.message, "error");
  } else {
    snackbarService.showSnackbar(defaultErrorMessage, "error");
  }
}
