import { useEffect } from "react";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { PasswordInput, Select, TextInput } from "~/components/Input";
import { Parameter } from "~/application/types/entities/CredentialParameter.type";
import { Control, UseFormSetValue } from "react-hook-form";
import { CredentialDialogForm } from "./CredentialDialog";
import { mapServiceToPortuguese, selectOptionsData } from "../utils";

interface CredentialFormProps {
  isNew?: boolean;
  control: Control<CredentialDialogForm>;
  parameter: Parameter[];
  setValue: UseFormSetValue<CredentialDialogForm>;
}

export const CredentialForm = ({ parameter, control, setValue, isNew }: CredentialFormProps) => {
  useEffect(() => {
    if (!isNew) {
      parameter.forEach((item) => {
        setValue(item.name as keyof CredentialDialogForm, item?.value);
      });

      setValue("serviceType", mapServiceToPortuguese(parameter[0].serviceType.name));
    }
  }, [isNew]);

  return (
    <>
      <Row gap="6" css={{ mt: "$2" }}>
        <Col sz="12">
          <FormControl name="description" control={control} required>
            <FieldLabel>Nome da credencial</FieldLabel>
            <TextInput />
          </FormControl>
        </Col>
      </Row>

      <Row gap="6" css={{ mt: "$2" }}>
        <Col sz="12">
          <FormControl name="identifier" control={control}>
            <FieldLabel>Identificador</FieldLabel>
            <TextInput />
          </FormControl>
        </Col>
      </Row>

      <Row gap="6" css={{ mt: "$2" }}>
        <Col sz="12">
          <FormControl name="serviceType" control={control} required>
            <FieldLabel>Tipo de serviço</FieldLabel>
            <Select
              placeholder="Selecione o tipo de serviço"
              options={selectOptionsData}
              getOptionValue={(service) => service.id}
              getOptionLabel={(service) => service.name}
            />
          </FormControl>
        </Col>
      </Row>

      {parameter.map((item) => (
        <Col css={{ mt: "$5" }} key={item.uuid}>
          <FormControl
            control={control}
            name={item.name as keyof CredentialDialogForm}
            required={item.required}
          >
            <FieldLabel>{item?.title}</FieldLabel>
            {(item.input_type === "string" ||
              item.input_type === "text" ||
              item.input_type === "int") && <TextInput autoComplete="off" />}
            {item.input_type === "password" && <PasswordInput autoComplete="off" />}
          </FormControl>
        </Col>
      ))}
    </>
  );
};

CredentialForm.displayName = "CredentialForm";
