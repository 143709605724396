import { TabApproverAccountabilityExpenses } from "~/core/modules/Order/pages/OrderPage/views/AccountabilityExpense/TabApproverAccountabilityExpenses";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { Tabs } from "~/components/Tabs";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Fragment } from "react";
import { useAccountabilityExpenses } from "../../../Order/pages/OrderPage/hooks/useAccountabilityExpenses";

export function ExpenseApprovalContainer() {
  const {
    onCreateAccountabilityExpense,
    isLoading,
    ...accountabilityExpensesState
  } = useAccountabilityExpenses({
    enabled: true,
    isExpenseApproval: true,
  });

  return (
    <Fragment>
      <Tabs>
        <AppBar>
          <AppBarContainer>
            <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
              <Link to="/despesas">
                <IconButton size="md">
                  <Icon
                    as={SvgChevronLeft}
                    css={{
                      "@mxlg": {
                        fill: "#FFF",
                      },
                    }}
                  />
                </IconButton>
              </Link>
            </Tooltip>

            <AppBarHeading
              title={
                isLoading ? (
                  <Skeleton variant="text-6" style={{ maxWidth: 360 }} />
                ) : (
                  "Despesas"
                )
              }
            />
          </AppBarContainer>
        </AppBar>

        <Container
          css={{
            py: "$10",
            "@mxlg": {
              px: "0",
              margin: "0 auto",
            },
          }}
        >
          <TabApproverAccountabilityExpenses
            isLoading={isLoading}
            order={accountabilityExpensesState.order}
            onShowVoucher={accountabilityExpensesState.onShowVoucher}
          />
        </Container>
      </Tabs>
    </Fragment>
  );
}
