import { VariantProps } from "@stitches/react";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type CheckboxButtonProps = ComponentProps<typeof CheckboxButton>;

export type CheckboxButtonVariants = VariantProps<typeof CheckboxButton>;

export const CheckboxButton = styled("span", {
  // Reset
  display: "block",
  boxSizing: "border-box",

  // Custom
  position: "relative",
  borderStyle: "solid",
  borderColor: "$neutrals-dark",
  transition: "$normal",

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$primary-light",
    borderColor: "$primary-base",
  },

  "&::after": {
    content: "",
    display: "none", // Only visible when checked
    position: "absolute",
    width: "25%",
    height: "50%",
    left: "50%",
    top: "50%",
    borderStyle: "solid",
    borderColor: "$neutrals-white",
    transform: "rotate(45deg) translate(-125%, -30%)",
  },

  "&[data-focus=true]": {
    boxShadow: "$focus-sm",
  },

  "&[data-disabled=true]": {
    pointerEvents: "none",
    opacity: "$disabled",
  },

  "&[data-checked=true]": {
    borderColor: "$primary-base",
    backgroundColor: "$primary-base",

    "&:hover": {
      borderColor: "$primary-dark",
      backgroundColor: "$primary-dark",
    },

    "&::after": {
      display: "block",
    },
  },

  variants: {
    size: {
      sm: {
        borderWidth: "1px",
        size: "$4",

        "&::after": {
          borderWidth: "0 1px 1px 0",
        },
      },
      md: {
        borderWidth: "1px",
        size: "$5",

        "&::after": {
          borderWidth: "0 1px 1px 0",
        },
      },
      lg: {
        borderWidth: "2px",
        size: "$8",

        "&::after": {
          borderWidth: "0 2px 2px 0",
        },
      },
    },
    variant: {
      rounded: {
        borderRadius: "$sm",
      },
      circle: {
        borderRadius: "$circular",
      },
    },
  },

  defaultVariants: {
    size: "md",
    variant: "rounded",
  },
});

CheckboxButton.displayName = "CheckboxButton";
