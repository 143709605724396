import * as DialogPrimitive from "@radix-ui/react-dialog";
import { keyframes, styled } from "~/application/theme";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

/**
 * @deprecated
 */
export const Dialog = DialogPrimitive.Root;

/**
 * @deprecated
 */
export const DialogPortal = DialogPrimitive.Portal;

/**
 * @deprecated
 */
export const DialogTrigger = styled(DialogPrimitive.Trigger, {});

/**
 * @deprecated
 */
export const DialogContent = styled(DialogPrimitive.Content, {
  display: "flex",
  position: "fixed",
  zIndex: "$modal",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "calc(100vh - 8rem)",
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  "&:focus": { outline: "none" },
});

/**
 * @deprecated
 */
export const DialogClose = DialogPrimitive.Close;

/**
 * @deprecated
 */
export const DialogOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: "rgba(99, 105, 115, 0.32)",
  position: "fixed",
  zIndex: "$overlay",
  inset: 0,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
});
