import { FC } from "react";
import { HotelIssuanceFormProps } from "./types";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { NumberInput, Select, TextInput } from "~/components/Input";
import { Flex } from "~/components/Flex";
import { Card, CardBody } from "~/components/Card";
import { Col } from "~/components/Grid";
import { SvgExpenses, SvgInfo } from "~/components/Icon/icons";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { IconButton } from "~/components/IconButton";
import { Icon } from "~/components/Icon";
import { Text } from "~/components/Text";

export const HotelIssuanceForm: FC<HotelIssuanceFormProps> = ({
  control,
  agreements,
  isLoading,
  totalValue,
  value,
  isOffline,
  taxes,
  paymentMethods,
  isLoadingPaymentMethod,
  formState,
}) => {
  const { fee, markup } = taxes;
  const newProviders = [
    {
      uuid: '',
      codeAgreement: 'Hotel direto',
      customerId: '',
      agreementComparation: {
        uuid: '',
        codeAgreement: '',
      },
      active: false,
      credential: {
        uuid: '',
        serviceType: '',
        description: '',
        provider: {
          name: '',
          logo: '',
          slug: '',
          serviceType: '',
          active: false
        },
      },
    },
    ...(agreements || [])
  ];

  return (
    <Flex
      align="center"
      direction="column"
      css={{
        py: "$10",
        backgroundColor: "$neutrals-lightest",
      }}
      gap="4"
    >
      <Flex
        as={Card}
        direction="column"
        css={{
          overflow: "visible",
        }}
      >
        <Flex as={CardBody} direction="column" gap="4">
          <Col>
            <FormControl control={control} name="tracker" required>
              <FieldLabel>Código da reserva</FieldLabel>
              <TextInput placeholder="Digite o código da reserva" />
            </FormControl>
          </Col>

          <Col>
            <FormControl control={control} name="paymentMethod" required>
              <FieldLabel>Forma de pagamento</FieldLabel>
              <Select
                isLoading={isLoadingPaymentMethod}
                placeholder="Selecione o método de pagamento"
                options={paymentMethods}
                getOptionValue={(method) => method.uuid}
                getOptionLabel={(method) => method.description}
              />
            </FormControl>
          </Col>

          <Col>
            <FormControl control={control} name="provider">
              <FieldLabel>Fornecedor</FieldLabel>
              <Select
                isLoading={isLoading}
                placeholder="Selecione o fornecedor"
                options={newProviders}
                getOptionValue={(provider) => provider.uuid}
                getOptionLabel={(provider) => provider.codeAgreement}
              />
            </FormControl>
          </Col>

          {!isOffline && (
            <>
              <Flex direction="column" gap="4">
                <Col>
                  <FormControl name="fareValue" control={control} required>
                    <Flex align="center">
                      <FieldLabel css={{ mb: 0 }}>Valor do fornecedor</FieldLabel>
                      <Tooltip content={<TooltipLabel></TooltipLabel>}>
                        <IconButton size="sm">
                          <Icon as={SvgInfo} />
                        </IconButton>
                      </Tooltip>
                    </Flex>
                    <NumberInput
                      prefix="R$ "
                      leftIcon={SvgExpenses}
                      defaultValue={(value || 0) - fee - markup}
                    />
                    {formState?.errors?.fareValue && (
                      <Text
                        variant="error-base"
                        css={{
                          mt: "$2",
                          width: "",
                          wordBreak: "break-all",
                        }}
                      >
                        {formState?.errors?.fareValue?.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
                <Col>
                  <FormControl name="value" control={control} required>
                    <FieldLabel>Cobrado do cliente</FieldLabel>
                    <NumberInput
                      placeholder="0,00 R$"
                      allowNegative={false}
                      leftIcon={SvgExpenses}
                    />
                  </FormControl>
                </Col>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

HotelIssuanceForm.displayName = "HotelIssuanceForm";
