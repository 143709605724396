import { To } from "react-router-dom";

import { CheapestRoad } from "~/application/types";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { CheapestRoadsSection } from "./views/CheapestRoadsSection";

export interface CheapestRoadsContainerProps {
  cheapestRoads?: CheapestRoad[];
  isFetchingCheapestRoads: boolean;
}

export function CheapestRoadsContainer({
  cheapestRoads,
  isFetchingCheapestRoads,
}: CheapestRoadsContainerProps) {
  return (
    <Container size="12" css={{ mt: "$5", px: "$4" }}>
      <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
        <Link to={-1 as To} css={{ width: "fit-content", display: "flex", alignItems: "center" }}>
          <Icon fill="#000" as={SvgChevronLeft} />
        </Link>
      </Tooltip>

      {isFetchingCheapestRoads ? (
        <Flex direction="column" css={{ gap: "$13", my: "$6" }}>
          <Flex gap="6" direction={{ "@mxmd": "column" }}>
            <Col>
              <Skeleton css={{ height: "11rem" }} />
            </Col>
            <Col>
              <Skeleton css={{ height: "11rem" }} />
            </Col>
            <Col>
              <Skeleton css={{ height: "11rem" }} />
            </Col>
          </Flex>

          <Flex direction="column" gap="6">
            <Skeleton css={{ height: "11.75rem" }} />
            <Skeleton css={{ height: "11.75rem" }} />
          </Flex>
        </Flex>
      ) : (
        <CheapestRoadsSection cheapestRoads={cheapestRoads!} />
      )}
    </Container>
  );
}