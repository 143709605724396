import { useQuery } from "@tanstack/react-query";
import { FC, useCallback, useMemo, useState } from "react";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { UserActions, UserContext as UserContextEnum, UserContexts } from "~/application/types";
import { authService } from "~/application/usecases";
import { useAuth } from "../../contexts/AuthContext";
import { UserContext } from "../../contexts/UserContext";
import { UserProviderProps } from "./types";

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const { user, isLoading: isAuthenticating, isSigned } = useAuth();

  const [stateContexts, setStateContexts] = useState({} as Partial<UserContexts>);

  const { data: profile, isFetching: isLoadingProfile } = useQuery(
    [QueryKeys.USER_PROFILE],
    () => authService.getProfile(),
    {
      cacheTime: QueryTimes.INFINITY,
      enabled: isSigned && user?.context === UserContextEnum.Customer,
    }
  );

  const userActions = useMemo<UserActions>(() => {
    const actionsEntries = profile?.actions?.map((a) => [a.action, true]) ?? [];

    return Object.fromEntries(actionsEntries);
  }, [profile?.actions]);

  // This variable holds all contexts inherent to the authenticated user
  const userContexts = useMemo<UserContexts>(
    () =>
      user && {
        agency: user.agency,
        customer: user.customer,
        biztrip: user.context === UserContextEnum.Biztrip,
      },
    [user]
  );

  const contexts = useMemo<UserContexts>(() => {
    return {
      ...userContexts,
      ...stateContexts,
    };
  }, [userContexts, stateContexts]);

  const setUserContext = useCallback(
    (context: Partial<UserContexts>) => {
      setStateContexts((old) => {
        return {
          ...old,
          ...context,
        };
      });
    },
    [setStateContexts]
  );

  return (
    <UserContext.Provider
      value={{
        user,
        profile: profile!, //! [DANGER] "profile" CAN be null
        contexts,
        userActions,
        isLoading: isAuthenticating || isLoadingProfile,
        setUserContext,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.displayName = "UserProvider";
