import type { HotelQuery } from "~/application/types";
import { toDate } from "~/utils/date.utils";
import { log } from "~/utils/log";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const LOG_TAG = "Booking/BookingHotel/useHotelParams";

export function useHotelParams(): HotelQuery | null {
  const [searchParams] = useSearchParams();

  const hotelParams = useMemo<HotelQuery | null>(() => {
    const obj = Object.fromEntries(searchParams);

    try {
      return {
        city: JSON.parse(obj.city),
        accommodations: JSON.parse(obj.accommodations),
        checkInDate: toDate(obj?.checkIn),
        checkOutDate: toDate(obj.checkOut),
        reasonTrip: obj?.reasonTrip ? JSON.parse(obj?.reasonTrip) : null,
      };
    } catch (error) {
      log.w(LOG_TAG, error);

      return null;
    }
  }, [searchParams]);

  return hotelParams;
}
