import { ComponentProps, FC } from "react";
import { styled } from "~/application/theme";
import { Hotel } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { asCurrency } from "~/utils/mask.utils";

const MarkerRoot = styled("div", {
  position: "relative",
  width: "max-content",
  height: "fit-content",
  p: "$1",
  backgroundColor: "$primary-base",
  border: "2px solid $primary-dark",
  borderRadius: "25%",
  cursor: "pointer",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",

  "&::after": {
    content: "",
    position: "absolute",
    bottom: "-8px",
    left: "50%",
    transform: "translateX(-50%)",
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: "8px solid $primary-base",
  },
});
export type MarkerProps = ComponentProps<typeof MarkerRoot> & {
  data: Hotel;
  lat: any;
  lng: any;
  onSelectHover?: (data: Hotel | undefined) => void;
  ishotelSelectedHover: boolean;
  ishotelSelected: boolean;
  onSelectInMap?: (data: Hotel | undefined) => void;
};

export const Marker: FC<MarkerProps> = ({
  data,
  css,
  onSelectHover,
  ishotelSelectedHover,
  onSelectInMap,
  ishotelSelected,
}) => (
  <MarkerRoot
    css={{
      ...css,
      backgroundColor: ishotelSelectedHover || ishotelSelected ? "$success-base" : "",
      border: ishotelSelectedHover || ishotelSelected ? "2px solid $success-dark" : "",
      "&::after": {
        borderTop: ishotelSelectedHover || ishotelSelected ? "8px solid $success-base" : "",
      },
    }}
    onClick={() => onSelectInMap?.(!ishotelSelected ? data : undefined)}
    onMouseOver={() => {
      onSelectHover?.(data);
    }}
    onMouseOut={() => {
      onSelectHover?.(undefined);
    }}
  >
    <Text fw="600" variant="white">
      {asCurrency(data?.bestRoomValue || 0)}{" "}
    </Text>
  </MarkerRoot>
);
