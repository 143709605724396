import type { DeepPartial, HotelEmission, OrderHotelItem, Provider } from "~/application/types";
import { Agreement } from "~/application/types/entities/BindCredential.type";

export type CreateFormDataProps = {
  data: OrderHotelItem;
  isOffline: boolean;
  agreements?: Agreement[];
};

export function createFormData({
  data,
  isOffline,
  agreements,
}: CreateFormDataProps): DeepPartial<HotelEmission> {
  const { uuid, tracker, provider, value } = data;

  return {
    uuid,
    tracker,
    provider: !provider && isOffline ? { name: 'Hotel direto', uuid: '' } :  {
      name: provider,
      uuid: agreements?.find(({ codeAgreement }) => codeAgreement === provider)?.uuid,
    },
    value: Number(value),
  };
}
