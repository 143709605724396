import { FC, Fragment, useCallback, useMemo } from "react";
import { Road } from "~/application/types";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Spinner } from "~/components/Spinner";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { BookingRoadListItem } from "~/presentation/Booking/BookingRoad/pages/RoadsPage/ui/views/BookingRoadListItem";
import { FiltersSection } from "~/presentation/shared/components/FiltersSection";
import { ReturnRoadsSectionUIProps } from "./types";
import { Checkbox } from "~/components/Input";

export const ReturnRoadsSectionUI: FC<ReturnRoadsSectionUIProps> = ({
  data,
  filters,
  isLoading,
  isAvailable,
  bookingInfo,
  activeBudget,
  allRoadBudget,
  onSelectAllBookingRoad,
  onSelectRoad,
  onFilterChange,
  onOpenDetails,
}) => {
  const minimumPrice = useMemo(() => data[0]?.totalPrice, [data]);

  const listRenderer = useCallback(
    (item: Road) => {
      const currentRoad = bookingInfo.returnRoadSelected;
      const currentRoadSeats = currentRoad?.road.id === item.id ? currentRoad?.seats : undefined;
      const isSelected = bookingInfo.returnRoadSelected?.road.id === item.id;

      return (
        <BookingRoadListItem
          data={item}
          currentRoadSeats={currentRoadSeats}
          isSelected={isSelected}
          minSeats={bookingInfo.seatsQuantity}
          maxSeats={bookingInfo.seatsQuantity}
          onSelectRoad={onSelectRoad}
          onOpenDetails={onOpenDetails}
          TagsElement={
            <Flex justify="end" align="center" css={{ width: "100%" }}>
              <Box
                css={{
                  display: "flex",
                  height: "$7",
                  justifyContent: "center",
                }}
              >
                {item.withBPE && (
                  <Tag
                    css={{
                      mr: "$3",
                      "@mxlg": {
                        fontSize: "10px",
                        border: "0",
                      },
                    }}
                    variant="info-light"
                  >
                    Bilhete eletrônico
                  </Tag>
                )}
              </Box>

              {minimumPrice === item.totalPrice && (
                <Tag
                  variant="success-light"
                  css={{
                    "@mxlg": {
                      fontSize: "10px",
                      border: "0",
                    },
                  }}
                >
                  Melhor oferta
                </Tag>
              )}

              {item.connection && onOpenDetails && (
                <Button
                  css={{
                    "@mxlg": {
                      ml: "$3",
                      height: "$2",
                    },
                  }}
                  variant="tertiary"
                  onClick={() => onOpenDetails(item)}
                >
                  Ver detalhes
                </Button>
              )}
            </Flex>
          }
          key={item.id}
        />
      );
    },
    [bookingInfo.returnRoadSelected, minimumPrice, onSelectRoad, onOpenDetails]
  );

  return (
    <Row
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <Col
        sz={{ "@initial": "4", "@mxlg": "11" }}
        css={{
          "@mxlg": {
            mb: "$10",
          },
        }}
      >
        <FiltersSection
          isLoading={!isAvailable}
          filters={filters}
          title="Filtrar resultados"
          onFilterChange={onFilterChange}
        />
      </Col>

      <Col
        sz="8"
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        {activeBudget && (
          <Flex align="center" css={{ my: "$2" }}>
            <Checkbox checked={allRoadBudget} onChange={onSelectAllBookingRoad} css={{ mr: "$3" }} />
            <Text fw="500" size="3">
              Selecionar todos desta página
            </Text>
          </Flex>
        )}
        {isLoading && (
          <Box css={{ mb: "$8" }}>
            <Alert variant="info">
              <Spinner size="sm" />
              <Text>Aguarde, estamos procurando as melhores ofertas para você</Text>
            </Alert>
          </Box>
        )}

        <LazyList
          gap="8"
          items={data}
          isLoading={!isAvailable}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={200}
          EmptyComponent={
            <Fragment>
              {!isLoading && (
                <EmptyState>
                  <Text>Nenhuma passagem encontrada</Text>
                </EmptyState>
              )}
            </Fragment>
          }
        />
      </Col>
    </Row>
  );
};

ReturnRoadsSectionUI.displayName = "ReturnRoadsSectionUI";
