import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { CustomerEmployee } from "~/application/types";
import { customerEmployeeService } from "~/application/usecases";
import { fetchCostCenters } from "~/application/usecases/CostCenter/utils";
import { fetchProfile } from "~/application/usecases/Profile/utils";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import {
  LOG_TAG,
  SNACKBAR_MESSAGES,
} from "~/presentation/CustomerSettings/pages/CustomerEmployeesPage/logic/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { logError } from "~/presentation/shared/utils/errors";
import {
  CustomerEmployeeDialog,
  EditableCustomerEmployee,
} from "~/presentation/shared/views/CustomerEmployeeDialog";
import { queryClient } from "~/services/queryClient";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { log } from "~/utils/log";

type useCustomerEmployeeDialogProps = {
  onSubmit?: (item: EditableCustomerEmployee) => Promise<void>;
};

export const useCustomerEmployeeDialog = ({ onSubmit }: useCustomerEmployeeDialogProps) => {
  const { user, contexts } = useUser();

  const customerId = contexts?.customer?.uuid;

  const { mutate: mutateCreateCustomerEmployee, isLoading: isLoadingCreateEmployee } = useMutation(
    (item: EditableCustomerEmployee) => customerEmployeeService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating CustomerEmployee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created CustomerEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");

        dialogService.popAll();
      },
      onError: (error: { message: string }, item) => {
        dialogService.popDialog();
        handleOpenEditModal({
          ...item,
          birthDate: displayDate(item.birthDate, DateFormats.SMALL_DATE),
        });
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const { mutate: mutateUpdateCustomerEmployee, isLoading: isLoadingUpdateEmployee } = useMutation(
    (item: EditableCustomerEmployee) => customerEmployeeService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating CustomerEmployee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated CustomerEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");

        dialogService.popAll();
      },
      onError: (error, item) => {
        dialogService.popDialog();
        handleOpenEditModal({
          ...item,
          birthDate: displayDate(item.birthDate, DateFormats.SMALL_DATE),
        });
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const showLoadingDialog = (isOpen: boolean) => {
    if (!isOpen) {
      dialogService.popDialog();
      return;
    }
    dialogService.showDialog(<LoadingModal isOpen={isOpen} message="Criando funcionário" />);
  };

  const handleCreateCustomerEmployee = useCallback(async (item: EditableCustomerEmployee) => {
    if (!onSubmit) return;
    dialogService.popDialog();

    try {
      showLoadingDialog(true);
      await onSubmit(item);
      showLoadingDialog(false);
    } catch (error) {
      showLoadingDialog(false);
      handleOpenEditModal({
        ...item,
        birthDate: displayDate(item.birthDate, DateFormats.SMALL_DATE),
      });
    }
  }, []);

  const getCostCenters = useCallback(() => fetchCostCenters({ customerId }), [customerId]);

  useEffect(() => {
    if (isLoadingCreateEmployee || isLoadingUpdateEmployee) {
      dialogService.popAll();
      dialogService.showDialog(
        <LoadingModal
          isOpen={isLoadingCreateEmployee || isLoadingUpdateEmployee}
          message={isLoadingCreateEmployee ? "Criando funcionário" : "Editando funcionário"}
        />
      );
    }
  }, [isLoadingCreateEmployee, isLoadingUpdateEmployee]);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <CustomerEmployeeDialog
        user={user}
        fetchProfile={fetchProfile}
        onSubmit={onSubmit ? handleCreateCustomerEmployee : mutateCreateCustomerEmployee}
        fetchCostCenters={getCostCenters}
        customerId={customerId}
      />
    );
  }, [user, getCostCenters, customerId]);

  const handleOpenEditModal = useCallback(
    (item: CustomerEmployee) => {
      dialogService.showDialog(
        <CustomerEmployeeDialog
          customerId={customerId}
          user={user}
          data={item}
          fetchProfile={fetchProfile}
          onSubmit={onSubmit ? handleCreateCustomerEmployee : mutateUpdateCustomerEmployee}
          fetchCostCenters={getCostCenters}
        />
      );
    },
    [user, getCostCenters, customerId]
  );

  return {
    handleOpenCreateModal,
    handleOpenEditModal,
  };
};
