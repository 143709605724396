import { FC } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { CustomerSettingParameter } from "~/application/types";
import { SettingParameterTypeValue } from "~/application/types/entities/SettingParameter.type";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { NumberInput, Select } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";

const customerSettingParameterSchema = yup.object().shape({
  value: yup
    .mixed()
    .required("O valor é obrigatório")
    .test({
      name: "is-value-not-empty",
      test: (value: any) => value !== "",
      message: "O valor é obrigatório",
      exclusive: true,
    }),
});

type CustomerSettingParameterSchema = yup.InferType<typeof customerSettingParameterSchema>;

export type CustomerSettingParameterDialogProps = {
  data?: CustomerSettingParameter;
  options?: { label: string; value: string; }[];
  onCloseClick?: () => void;
  onSubmit: (data: CustomerSettingParameter) => void;
};

export const CustomerSettingParameterDialog: FC<CustomerSettingParameterDialogProps> =
  ({ data, onCloseClick, onSubmit: onSubmitProp, options }) => {
    const isNew = !data;

    const { control, formState, handleSubmit } = useForm<CustomerSettingParameterSchema>({
      defaultValues: data?.typeValue === SettingParameterTypeValue.SELECT 
      ? { ...data, value: { label: data.value, value: data.value }}
      : data,
      resolver: yupResolver(customerSettingParameterSchema),
    });

    const values: Record<SettingParameterTypeValue, JSX.Element> = {
      [SettingParameterTypeValue.INTEGER]: (
        <>
          <FieldLabel>Valor</FieldLabel>
          <NumberInput
            size="sm"
            thousandSeparator=""
            decimalScale={0}
            defaultValue={data!.value}
            placeholder="Digite o valor"
          />
        </>
      ),

      [SettingParameterTypeValue.FLOAT]: (
        <>
          <FieldLabel>Valor</FieldLabel>
          <NumberInput
            placeholder="Digite o valor"
            size="sm"
            defaultValue={data!.value}
          />
        </>
      ),

      [SettingParameterTypeValue.PERCENTAGE]: (
        <>
          <FieldLabel>Valor</FieldLabel>
          <NumberInput
            size="sm"
            suffix="%"
            placeholder="Digite o percentual"
            fixedDecimalScale={false}
          />
        </>
      ),

      [SettingParameterTypeValue.SELECT]: (
        <>
          <FieldLabel>Valor</FieldLabel>
          <Select options={options} placeholder="Selecione uma opção" />
        </>
      ),
    } as Record<SettingParameterTypeValue, JSX.Element>;

    const typeValue = data?.typeValue;

    const onSubmit = (data: CustomerSettingParameterSchema) => {
      onSubmitProp({
        ...data,
        value: (typeof data.value === 'object' && 'value' in data.value) 
          ? data.value.value 
          : data.value,
      } as CustomerSettingParameter);
    };

    return (
      <Container size="8" fixed>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormDialog
            title={"Editar parâmetro de configuração"}
            negativeButton={
              <Button variant="tertiary" type="reset" onClick={onCloseClick}>
                <Text>Cancelar</Text>
              </Button>
            }
            positiveButton={
              <Button disabled={formState.isSubmitting} type="submit">
                <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
              </Button>
            }
            onClickDismissButton={onCloseClick}
          >
            <DialogBody css={{ p: "$6" }}>
              <Box css={{ mb: "$10" }}>
                <H5>Informações básicas</H5>
              </Box>

              <Row gap="6">
                <Col>
                  <FormControl name="value" control={control}>
                    {typeValue && typeValue in values && values[typeValue]}
                    {formState.errors.value && (
                      <Text variant="error-base" size="3" css={{ fontWeight: "bold", mt: "$2" }}>
                        {formState.errors.value.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              </Row>
            </DialogBody>
          </FormDialog>
        </Form>
      </Container>
    );
  };

CustomerSettingParameterDialog.displayName = "CustomerSettingParameterDialog";
