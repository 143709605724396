import { FC, useRef, useState } from "react";
import { ViolatedPolicy } from "~/application/types";
import { Button } from "~/components/Button";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { Tooltip } from "~/components/Tooltip";
import { Popover, PopoverContent, PopoverTrigger } from "~/core/shared/components/Popover";
import { mapSlugViolatedPoliciesType } from "./utils";

export type ViolatedPoliciesButtonProps = {
  data?: ViolatedPolicy[];
  full?: boolean;
};

export const ViolatedPoliciesButton: FC<ViolatedPoliciesButtonProps> = ({ data, full = true }) => {
  const [isOpenViolatedPolicies, setOpenViolatedPolicies] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover open={isOpenViolatedPolicies} onOpenChange={setOpenViolatedPolicies}>
      <PopoverTrigger asChild>
        <Button ref={triggerRef} variant="warning-light" css={{ "@mxlg": { width: full ? "100%": "fit-content" } }}>
          <Text size="4" fw="600">Políticas desrespeitadas</Text>
          <Icon as={isOpenViolatedPolicies ? SvgChevronDown : SvgChevronUp} />
        </Button>
      </PopoverTrigger>

      <PopoverContent onOpenAutoFocus={(event) => event.preventDefault()}>
        <Flex
          direction="column"
          css={{
            backgroundColor: "#FFF5EA",
            border: "1px solid $warning-dark",
            p: "$2",
            borderRadius: "$md",
            width: triggerRef.current ? `${triggerRef.current.offsetWidth}px` : "auto",
          }}
          onMouseLeave={() => setOpenViolatedPolicies(false)}
        >
          {data?.map((policy, index) => (
            <Flex
              key={policy.policyType}
              direction="column"
              css={{
                width: "100%",
              }}
            >
              <Flex align="center" gap="3">
                <Tooltip
                  css={{
                    border: "1px solid $warning-dark",
                    backgroundColor: "$warning-light",
                    p: "$2",
                    maxWidth: "200px",
                    textAlign: "center",
                  }}
                  content={
                    <Text size="4" variant="warning-dark">
                      {policy.message}
                    </Text>
                  }
                >
                  <IconButton size="md">
                    <Icon as={SvgInfo} variant="warning-dark" />
                  </IconButton>
                </Tooltip>
                <Text variant="warning-dark" fw="500" size="3">
                  {mapSlugViolatedPoliciesType(policy.policyType)}
                </Text>
              </Flex>
              {!!data.length && index < data.length - 1 && (
                <Divider
                  css={{
                    borderColor: "$warning-base",
                  }}
                />
              )}
            </Flex>
          ))}
        </Flex>
      </PopoverContent>
    </Popover>
  );
};
