import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { SvgClock, SvgGps } from "~/components/Icon/icons";
import { displayDate, DateFormats } from "~/utils/date.utils";
import { Card, CardBody } from "../../../components/Card";
import { VehicleBookingContext } from "../contexts/VehicleBookingContext";
import { useContext } from "react";
import { VehicleQuery } from "~/application/types";
import { Box } from "~/components/Box";
import { Icon } from "~/components/Icon";
import { FormDialog } from "~/components/FormDialog";
import { DialogBody } from "~/components/Dialog";
import { Container } from "~/components/Container";

type VehicleConfirmationDialogProp = {
  queryData: VehicleQuery;
};

export function VehicleConfirmationDialog({
  queryData,
}: VehicleConfirmationDialogProp) {
  return (
    <Container size="8" fixed>
      <FormDialog
        title="Detalhes da reserva"
        css={{ width: "95%", margin: "0 auto" }}
      >
        <DialogBody css={{ p: 20 }}>
          <Flex direction="column" gap="6">
            <Box css={{ fw: "700" }}>Datas e localização</Box>
            <Grid columns="1" gap="6">
              <Card spacing="6">
                <Flex direction="column" gap="6">
                  <Box css={{ fontSize: "$sm", fw: "bold" }}>
                    Informações sobre retirada
                  </Box>

                  <Flex gap="3" align="center">
                    <Icon as={SvgClock} />
                    <Box css={{ fontSize: "$sm" }}>Data e hora</Box>
                    <Box css={{ fontSize: "$sm" }}>
                      {displayDate(
                        queryData.dateGetSelected as Date,
                        DateFormats.LONG_DATE_TIME
                      )}
                    </Box>
                  </Flex>

                  <Flex gap="3" align="center">
                    <Icon as={SvgGps} />
                    <Box css={{ fontSize: "$sm" }}>Local</Box>
                    <Box css={{ fontSize: "$sm" }}>
                      {queryData.destinationSelected.name}
                    </Box>
                  </Flex>
                </Flex>
              </Card>

              <Card spacing="6">
                <Flex direction="column" gap="6">
                  <Box css={{ fontSize: "$sm", fw: "bold" }}>
                    Informações sobre devolução
                  </Box>

                  <Flex gap="3" align="center">
                    <Icon as={SvgClock} />
                    <Box css={{ fontSize: "$sm" }}>Data e hora</Box>
                    <Box css={{ fontSize: "$sm" }}>
                      {displayDate(
                        queryData.dateReturnSelected as Date,
                        DateFormats.LONG_DATE_TIME
                      )}
                    </Box>
                  </Flex>

                  <Flex gap="3" align="center">
                    <Icon as={SvgGps} />
                    <Box css={{ fontSize: "$sm" }}>Local</Box>
                    <Box css={{ fontSize: "$sm" }}>
                      {queryData.originSelected.name}
                    </Box>
                  </Flex>
                </Flex>
              </Card>
            </Grid>
          </Flex>
        </DialogBody>
      </FormDialog>
    </Container>
  );
}
