
import { api } from "~/infrastructure/api";
import { PaymentMethodDTO } from "~/infrastructure/api/dtos/PaymentMethodDTO";
import {
  mapPaymentMethodDTO,
} from "~/infrastructure/api/mappers/mapPaymentMethodDTO";
import { PaymentMethod } from "~/application/types/entities/PaymentMethod.type";
import { BindPaymentMethodsProps, FindPaymentMethodsProps } from "./types";

export class PaymentMethodService {
  async findAll(): Promise<PaymentMethod[]> {
    return await api
      .get<{ data: PaymentMethodDTO[] }>("/payment-methods")
      .then(({ data }) => data.data.map(mapPaymentMethodDTO));
  }

  async find({ customerId }: FindPaymentMethodsProps): Promise<PaymentMethod[]> {
    return await api
      .get<{ data: PaymentMethodDTO[] }>(`/customers/${customerId}/payment-methods`)
      .then(({ data }) => data.data.map(mapPaymentMethodDTO));
  }

  async bind(data: BindPaymentMethodsProps): Promise<void> {
    await api.post(`customers/${data.customerId}/payment-methods`, {
      service_type: data.serviceType.uuid,
      payment_method_uuid: data.paymentMethod.uuid,
    });
  }

  async update(data: BindPaymentMethodsProps): Promise<void> {
    await api.put(`customers/${data.customerId}/payment-methods`, {
      service_type: data.serviceType.uuid,
      payment_method_uuid: data.paymentMethod.uuid,
    });
  }
}
