import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody, CardProps } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgGps } from "~/components/Icon/icons";
import { Text } from "~/components/Text";

export interface LocaleCardProps extends CardProps {
  onMapView?: () => void;
}

export function LocaleCard({ onMapView, ...props }: LocaleCardProps) {
  return (
    <Card {...props}>
      <Flex
        align="center"
        justify="center"
        css={{
          flex: "1 0 0%",
          backgroundImage: `linear-gradient(180deg, rgba(0, 100, 197, 0.4) 0%, rgba(0, 100, 197, 0) 100%), url(/images/mapsicle-map.png)`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          p: "$6",
        }}
      >
        <Box
          css={{
            p: "$6",
            borderRadius: "$lg",
            backgroundColor: "$primary-dark",
            boxShadow: "$lg",
          }}
        >
          <Icon as={SvgGps} variant="white" size="lg" />
        </Box>
      </Flex>

      <CardBody>
        <Button variant="tertiary" onClick={onMapView}>
          <Text>Ver no mapa</Text>
        </Button>
      </CardBody>
    </Card>
  );
}

LocaleCard.displayName = "LocaleCard";
