import { OrderStatus } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FormDialog } from "~/components/FormDialog";
import { SvgCheck } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { ConfirmCreateQuoteApproval } from "./components/ConfirmCreateQuoteApproval";
import { OfflineHotelDetails } from "./components/OfflineHotelDetails";
import { OfflineHotelOption } from "./components/OfflineHotelOption";
import { useQuoteOfflineHotelForm } from "./hooks/useQuoteOfflineHotemForm/useQuoteOfflineHotelForm";
import { QuoteOfflineHotelFormProps } from "./types";
import { useMemo } from "react";

export function QuoteOfflineHotelForm(props: QuoteOfflineHotelFormProps) {
  const {
    isEditing,
    isAgencyEmployee,
    amenities,
    providerValue,
    hotelMarkup,
    hotelFee,
    fields,
    options,
    control,
    currentPage,
    isLoadingAmenities,
    cannotRemoveQuote,
    roomTypeOptions,
    setCurrentPage,
    setHotelFee,
    setHotelMarkup,
    setProviderValue,
    onAddQuote,
    onRemoveQuote,
    setValue,
    handleSubmit,
    onSubmitForm,
    hotelFeeType,
  } = useQuoteOfflineHotelForm(props);

  const { order, item } = props;

  const itemAmenities = (item.obsOffline?.amenities ?? []).map((name) => name);
  const currentPageOptions = options.at(currentPage - 1 || 0);
  const amenitiesSelected = (currentPageOptions?.amenities ?? []).map((name) => name.name);

  const OPTIONS_TO_REPLICATE = order.items.hotel?.rooms.map((_, index) => ({
    name: `Quarto ${index + 1}`,
    uuid: order.items.hotel?.rooms[index].uuid,
  }));

  const currentRoomOptionIndex = OPTIONS_TO_REPLICATE?.findIndex(
    (option) => option.uuid === item.uuid
  );

  const isSameAmenity = useMemo(() => {
    if (itemAmenities.length !== amenitiesSelected.length) return false;

    const sortedItemAmenities = itemAmenities.slice().sort();
    const sortedAmenitiesSelected = amenitiesSelected.slice().sort();

    return sortedItemAmenities.every((value, index) => value === sortedAmenitiesSelected[index]);
  }, [itemAmenities, amenitiesSelected]);

  const isSameRoomRegimen = currentPageOptions?.regimen?.name === item.roomRegimen;

  const hasRegimenValue = !!currentPageOptions?.regimen?.name;
  const hasAmenitiesSelected = amenitiesSelected.length > 0;

  const isPendingStatus = order.status === OrderStatus.PENDING_ISSUE;

  const handleCreateQuoteApproval = () => {
    if (!isPendingStatus) return;

    dialogService.popDialog();

    dialogService.showDialog(
      <ConfirmCreateQuoteApproval
        control={control}
        onSubmitForm={onSubmitForm}
        handleSubmit={handleSubmit}
        onClose={() => dialogService.popDialog()}
      />
    );
  };

  return (
    <Container size={{ "@initial": "10", "@mxlg": "4" }} fixed>
      <Form onSubmit={handleSubmit(onSubmitForm)}>
        <FormDialog title={isEditing ? "Editar item" : "Cotizar item"}>
          <DialogBody css={{ p: "$6" }}>
            <Flex direction="column" gap="4">
              <OfflineHotelDetails
                order={order}
                item={item}
                isAgencyEmployee={isAgencyEmployee}
                cannotRemoveQuote={cannotRemoveQuote}
                isEditing={isEditing}
                onAddQuote={onAddQuote}
                onRemoveQuote={onRemoveQuote}
              />

              {isAgencyEmployee &&
                fields.map((field, index) =>
                  index === currentPage - 1 ? (
                    <OfflineHotelOption
                      key={`field-${field.uuid}`}
                      index={index}
                      isCopy={props.isCopy}
                      amenities={amenities}
                      currentRoomOptionIndex={currentRoomOptionIndex}
                      optionsToReplicate={OPTIONS_TO_REPLICATE}
                      roomTypeOptions={roomTypeOptions}
                      isSameAmenity={isSameAmenity}
                      hasRegimenValue={hasRegimenValue}
                      isSameRoomRegimen={isSameRoomRegimen}
                      hasAmenitiesSelected={hasAmenitiesSelected}
                      item={item}
                      currentPage={currentPage}
                      fields={fields}
                      hotelFee={hotelFee}
                      hotelFeeType={hotelFeeType!}
                      hotelMarkup={hotelMarkup}
                      isEditing={isEditing}
                      isLoadingAmenities={isLoadingAmenities}
                      providerValue={providerValue}
                      field={field}
                      options={options}
                      control={control}
                      setCurrentPage={setCurrentPage}
                      setHotelFee={setHotelFee}
                      setHotelMarkup={setHotelMarkup}
                      setProviderValue={setProviderValue}
                      setValue={setValue}
                    />
                  ) : null
                )}
            </Flex>
          </DialogBody>

          {isAgencyEmployee && (
            <Container css={{ p: "$4" }}>
              <Flex direction="column" align="center">
                <Button
                  type={isPendingStatus ? "button" : "submit"}
                  onClick={handleCreateQuoteApproval}
                >
                  <SvgCheck />
                  <Text>Salvar Dados</Text>
                </Button>
              </Flex>
            </Container>
          )}
        </FormDialog>
      </Form>
    </Container>
  );
}
