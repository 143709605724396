import { Fragment, useCallback } from "react";
import { Justification } from "~/application/types";
import {
  AppBar,
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { JustificationListItem } from "~/presentation/shared/components/JustificationListItem";
import { useJustificationsPage } from "./logic/useJustificationsPage";

export function JustificationsPage() {
  const {
    data,
    isLoading,
    onCreateJustification,
    onEditJustification,
    onToggleJustificationState,
  } = useJustificationsPage();

  const listRenderer = useCallback(
    (item: Justification) => (
      <JustificationListItem
        data={item}
        onEditClick={onEditJustification}
        onToggleState={onToggleJustificationState}
        key={item.uuid}
      />
    ),
    [onEditJustification, onToggleJustificationState]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Justificativas de quebra de política" />

          <AppBarActions>
            <Button
              title="Nova justificativa"
              color="primary"
              onClick={onCreateJustification}
            >
              <Icon as={SvgPlus} />
              <Text>Nova justificativa</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>
                Esta empresa ainda não possui justificativas de viagem
              </Text>
            </EmptyState>
          }
        />
      </Container>
    </Fragment>
  );
}
