import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { Flex } from "~/components/Flex";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Card } from "~/application/types";
import { Container } from "~/components/Container";
import { LazyList } from "~/components/LazyList";
import { useCallback } from "react";
import { EmptyState } from "~/components/EmptyState";
import { CardListItem } from "./components/CardListItem";

export interface CardsContainerProps {
  isLoading: boolean;
  cards?: Card[];
  onActive: (data: Card) => void;
  onInactive: (data: Card) => void;
  onOpenCreateDialog: () => void;
  onOpenEditDialog: (item: any) => void;
}

export function CardsContainer({
  cards,
  isLoading,
  onOpenEditDialog,
  onOpenCreateDialog,
  onActive,
  onInactive,
}: CardsContainerProps) {
  const renderCreditCardItem = useCallback(
    (data: Card) => (
      <CardListItem
        onToggleState={data.active ? () => onInactive(data) : () => onActive(data)}
        onEditClick={onOpenEditDialog}
        data={data}
      />
    ),
    [onActive, onInactive, onOpenEditDialog]
  );

  return (
    <Flex direction="column" gap="4">
      <AppBar>
        <AppBarContainer>
          <AppBarHeading
            title="Cartões"
            css={{
              "@mxlg": {
                ta: "start",
              },
            }}
          />

          <AppBarActions>
            <Button
              title="Novo cartão"
              color="primary"
              onClick={onOpenCreateDialog}
              css={{
                "@mxlg": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Icon
                as={SvgPlus}
                css={{
                  "@mxlg": {
                    fill: "#000",
                  },
                }}
              />
              <Text css={{ "@mxlg": { display: "none" } }}>Novo cartão</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container
        css={{
          pt: "$10",
          pb: "$20",
          "@mxlg": {
            p: "$5",
          },
        }}
      >
        <LazyList
          gap="6"
          items={cards}
          isLoading={isLoading}
          render={renderCreditCardItem}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não tem cartões cadastrados</Text>
            </EmptyState>
          }
        />
      </Container>
    </Flex>
  );
}
