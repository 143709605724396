import React, {
  ComponentProps,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from "react";
import { styled } from "~/application/theme";
import { Box } from "../../Box";
import { Button } from "../../Button";
import { Icon } from "../../Icon";
import { SvgFile, SvgTrash } from "../../Icon/icons";
import { IconButton } from "../../IconButton";
import { Text } from "../../Text";
import { Flex } from "~/components/Flex";

export type UploadFileProps = ComponentProps<typeof UploadFileInput> & {
  label?: string;
  description?: string;
  file?: File;
  defaultFile?: File;
};

const UploadFileInput = styled("input", {
  cursor: "inherit",
  position: "absolute",
  opacity: 0,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  zIndex: 1,
});

const UploadFileRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  position: "relative",
  display: "flex",

  // Custom
  userSelect: "none",
  gap: "$4",
  padding: "$6",
  borderRadius: "$md",
  border: "1px dashed $colors$neutrals-base",
  backgroundColor: "$neutrals-lightest",
  alignItems: "center",
  transition: "$normal",

  "& [role=status]": {
    size: "$12",
    padding: "$2",
    borderRadius: "$md",
    backgroundColor: "$neutrals-white",
  },

  "&[data-dragging=true]": {
    backgroundColor: "$primary-light",
  },

  "&[data-contented=true]": {
    borderColor: "transparent",
    backgroundColor: "transparent",
    boxShadow: "0 0 0 2px inset $colors$primary-base",

    "& [role=status]": {
      backgroundColor: "$primary-light",
    },
  },
});

export const UploadFile = forwardRef<
  React.ElementRef<typeof UploadFileRoot>,
  UploadFileProps
>(
  (
    {
      label,
      description,
      placeholder,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
      file: fileProp,
      defaultFile,
      ...props
    },
    forwardedRef
  ) => {
    const dropZoneRef = useRef<HTMLInputElement>(null);

    const [isDragActive, setIsDragActive] = useState(false);
    const [fileState, setFile] = useState(defaultFile);

    const handleDragIn: React.DragEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        onDragEnter?.(event);

        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
          setIsDragActive(true);
        }
      },
      [onDragEnter]
    );

    const handleDragLeave: React.DragEventHandler<HTMLInputElement> =
      useCallback(
        (event) => {
          event.preventDefault();
          event.stopPropagation();

          if (onDragLeave) onDragLeave(event);

          // Set active state to "false":
          setIsDragActive(false);
        },
        [onDragLeave]
      );

    const handleDragOver: React.DragEventHandler<HTMLInputElement> =
      useCallback(
        (event) => {
          event.preventDefault();
          event.stopPropagation();

          onDragOver?.(event);

          // Set active state to "true" if it is not active:
          if (!isDragActive) {
            setIsDragActive(true);
          }
        },
        [isDragActive, onDragOver]
      );

    const handleDrop: React.DragEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        // Prevent default events:

        // Set active state to false:
        setIsDragActive(false);

        // Invoke any optional method passed as "onDrop()":
        onDrop?.(event);

        // If there are any files dropped:
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
          // Convert these files to an array:
          const filesToUpload = [];

          for (let i = 0; i < event.dataTransfer.files.length; i++) {
            filesToUpload.push(event.dataTransfer.files.item(i));
          }

          // Invoke any optional method passed as "onFilesDrop()", passing array of files as an argument:
          // onFilesDrop?.(filesToUpload);

          setFile(filesToUpload[0] ?? undefined);
        }
      },
      [onDrop]
    );

    const file = fileProp || fileState;

    return (
      <UploadFileRoot
        data-dragging={isDragActive}
        data-contented={!!file}
        {...props}
        ref={forwardedRef}
      >
        <Box role="status">
          <Icon as={SvgFile} size="lg" variant="primary" />
        </Box>

        <Box css={{ flex: "1 0 0" }}>
          {label && (
            <Text as="p" size="4" css={{ fw: "600" }}>
              {label}
            </Text>
          )}

          {description && (
            <Text as="p" size="2" variant="darkest" css={{ mt: "$2" }}>
              {description}
            </Text>
          )}
        </Box>

        {file ? (
          <Flex>
            <Text>{file.name}</Text>

            <IconButton size="md" onClick={() => setFile(undefined)}>
              <Icon as={SvgTrash} />
            </IconButton>
          </Flex>
        ) : (
          <Button>Upload de arquivos</Button>
        )}

        <UploadFileInput
          onDragEnter={handleDragIn}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          {...props}
          ref={dropZoneRef}
          type="file"
        />
      </UploadFileRoot>
    );
  }
);

UploadFile.displayName = "UploadFile";
