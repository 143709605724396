import { useQuery } from "@tanstack/react-query";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CustomerEmployee, Traveler, UserContext } from "~/application/types";
import { orderService, travelerService } from "~/application/usecases";
import { employeeToTraveler } from "~/application/usecases/Traveler/TravelerService";
import { rangedArray } from "~/application/utils/array-functions";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants";
import { SNACKBAR_MESSAGES } from "~/core/modules/Agency/pages/AgenciesOrdersPage/types";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { SelectIssuer } from "~/presentation/shared/components/SelectIssuerModal";
import { LOG_TAG } from "~/presentation/shared/components/TabOrderMessages/types";
import { useCreateCustomerEmployee } from "~/presentation/shared/hooks/useCreateCustomerEmployee";
import { useCustomerEmployeeDialog } from "~/presentation/shared/hooks/useCustomerEmployeeDialog";
import { log } from "~/utils/log";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { TravelersSectionUI } from "./TravelersSectionUI";
import { useDebounce } from "use-debounce";

export const TravelersSection: FC = () => {
  const { contexts, user } = useUser();
  const [searchParams] = useSearchParams();
  const state = useRemakeSearch();

  const orderId = searchParams.get("orderId");

  const [inputValue, setInputValue] = useState("");

  const [search] = useDebounce(inputValue, 700);

  const { data: order } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      staleTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
      enabled: !!orderId,
    }
  );

  const { roadReducer, roadQuery } = useRoadsPage();

  const { bookingInfo } = roadReducer;

  const travelersPlaceholder = useMemo(() => rangedArray(bookingInfo.seatsQuantity), [roadQuery]);

  useEffect(() => {
    const { travelers, data, currentStep } = state;
    if (travelers?.length > 0 && currentStep !== data?.road.length) {
      roadReducer.dispatch({
        type: "SET_TRAVELERS",
        payload: travelers,
      });
      return;
    }
    roadReducer.dispatch({
      type: "SET_TRAVELERS",
      payload: [],
    });
  }, [state]);

  useEffect(() => {
    if (order) {
      roadReducer.dispatch({
        type: "SET_TRAVELERS",
        payload: order.travelers,
      });
    }
  }, [order]);

  const fetchTravelersByName = useCallback(
    async (name: string) => {
      if (state.travelers && state.travelers?.length > 0) {
        return state.travelers;
      }
      return order?.travelers ?? travelerService.find(contexts.customer, { 
        name, 
        onlyUser: false,
        isActive: "ativo",
      });
    },
    [order, contexts, state]
  );

  const { data, isLoading } = useQuery(
    [QueryKeys.ROAD_TRAVELERS, search, contexts],
    async () => await travelerService.find(contexts.customer, { 
      name: search, 
      onlyUser: false,
      isActive: "ativo",
    }),
  );

  const fetchIssueByName = useCallback(
    async (name: string) => {
      if (state.travelers && state.travelers?.length > 0) {
        return state.travelers;
      }
      return order?.travelers ?? travelerService.find(contexts.customer, { 
        name, 
        onlyUser: true,
        isActive: "ativo",
      });
    },
    [order, contexts, state]
  );

  const onSelectTraveler = useCallback(
    (item: Traveler, optionIndex: number) => {
      bookingInfo.travelers[optionIndex] = item;

      roadReducer.dispatch({
        type: "SET_TRAVELERS",
        payload: [...bookingInfo.travelers],
      });
    },
    [bookingInfo.travelers, roadReducer]
  );

  const { create: createCustomerEmployee } = useCreateCustomerEmployee({
    customerId: contexts.customer.uuid,
  });

  const onSubmitCustomerEmployee = useCallback(
    async (data: CustomerEmployee) => {
      try {
        const customerEmployee = await createCustomerEmployee({
          ...data,
          cpf: data.cpf.replaceAll(/\D/g, ""),
        });

        for (let i = 0; i < travelersPlaceholder.length; i++) {
          if (!bookingInfo.travelers[i]) {
            const traveler = employeeToTraveler(contexts.customer, customerEmployee);

            onSelectTraveler(traveler, i);
            break;
          }
        }
      } catch (error) {
        throw new Error();
      }
    },
    [contexts, travelersPlaceholder, onSelectTraveler, createCustomerEmployee]
  );

  const { handleOpenCreateModal } = useCustomerEmployeeDialog({
    onSubmit: onSubmitCustomerEmployee,
  });

  const canSelectIssuer =
    user.context === UserContext.Agency && contexts.customer && !order?.issuer?.uuid;

  const onSelectIssuer = useCallback(({ uuid }: CustomerEmployee) => {
    roadReducer.dispatch({ type: "SET_ISSUER", payload: uuid });
  }, []);

  return (
    <>
      <TravelersSectionUI
        data={order?.travelers ?? bookingInfo.travelers}
        travelers={data}
        isLoadingTravelers={isLoading}
        onSearchTraveler={setInputValue}
        roadReducer={roadReducer}
        order={order}
        seatsQuantity={bookingInfo.seatsQuantity}
        onCreateCustomerEmployee={handleOpenCreateModal}
        fetchTravelers={fetchTravelersByName}
        onSelectTraveler={onSelectTraveler}
      />

      {canSelectIssuer && (
        <SelectIssuer
          fetchTravelers={fetchIssueByName}
          css={{ marginTop: "2rem" }}
          onChange={onSelectIssuer}
        />
      )}
    </>
  );
};
