import {
  ApiError,
  ApiErrorCode,
  Road,
  RoadSeat,
  RoadSeatData,
  RoadSeatLink,
} from "~/application/types";
import {
  RoadSeatDataDTO,
  RoadSeatLinkApiType,
} from "~/core/modules/DeprecatedBooking/types";
import { api } from "~/infrastructure/api";
import type { IRoadSeatsService } from "./IRoadSeatsService";

export class RoadSeatsService implements IRoadSeatsService {
  async query({ searchKey, id }: Road): Promise<RoadSeatLink> {
    return await api
      .post<RoadSeatLinkApiType>("/road/seats", {
        search_key: searchKey,
        id: id,
      })
      .then(
        ({ data }): RoadSeatLink => ({
          waitTime: data.wait_time,
          link: data.link,
        })
      );
  }
  async find(url: string): Promise<RoadSeatData> {
    return api.get<RoadSeatDataDTO>(url).then(({ data }): RoadSeatData => {
      if (ApiError.isAsyncError(data)) {
        throw new ApiError({
          data: data,
          code: ApiErrorCode.RETRY_REQUEST,
          statusCode: 204,
        });
      }

      const mapSeats = (seats: RoadSeat[]) => {
        return seats.map((seat) => ({
          occupied: seat.occupied,
          position: seat.position,
          seat: seat.seat,
          type: seat.type,
        }));
      };

      return {
        seats: mapSeats(data.seats),
        seatsConnection: mapSeats(data.seats_connection || []),
      };
    });
  }
}
