import { ReactElement, ReactNode } from "react";
import { Button } from "../Button";
import { Card, CardProps } from "../Card";
import { DialogFooter, DialogHeader } from "../Dialog";
import { dialogService } from "../DialogStack";
import { Icon } from "../Icon";
import { SvgClose } from "../Icon/icons";
import { H4 } from "../Typography";
import { Flex } from "../Flex";

export interface FormDialogProps extends CardProps {
  title?: string;
  header?: ReactElement;
  withHeader?: boolean;
  titleLeftIcon?: ReactElement;
  withCloseButton?: boolean;
  positiveButton?: ReactElement;
  negativeButton?: ReactElement;
  children?: ReactNode;
  onClickDismissButton?: () => void;
  alert?: ReactElement;
}

export const FormDialog = ({
  title,
  children,
  header,
  negativeButton,
  positiveButton,
  withHeader = true,
  withCloseButton = true,
  alert,
  onClickDismissButton,
  ...props
}: FormDialogProps) => {
  const onCloseForm = onClickDismissButton || (() => dialogService.popDialog());

  return (
    <Card css={{ boxShadow: "$sm", maxHeight: "100%" }} {...props}>
      {withHeader && (
        <DialogHeader>
          {header ?? (
            <>
              <H4
                css={{
                  ml: "$2",
                  "@mxmd": {
                    fontSize: "$md",
                  },
                }}
              >
                {title}
              </H4>
            </>
          )}

          {withCloseButton && (
            <Button variant="tertiary" size="sm" tabIndex={-1} onClick={onCloseForm}>
              <Icon as={SvgClose} />
            </Button>
          )}
        </DialogHeader>
      )}

      {children}

      {(negativeButton || positiveButton) && (
        <DialogFooter css={{ margin: "none" }}>
          <Flex direction="column" gap="2">
            {alert}
            <Flex justify="end" gap="2">
              {negativeButton}
              {positiveButton}
            </Flex>
          </Flex>
        </DialogFooter>
      )}
    </Card>
  );
};
