import { styled } from "~/application/theme";

import { Text } from "~/components/Text";

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const Chip = styled("span", {
  // Rest
  boxSizing: "border-box",

  // Custom
  display: "inline-flex",
  borderRadius: "$md",
  padding: "$2",
  gap: "$2",
  position: "absolute",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const ChipText = styled(Text, {
  // Custom
  color: "$primary-base",
  fontSize: "$md",
  fontWeight: 700,
});
