import { FlightOption } from "~/application/types";
import { FlightOptionDTO } from "../dtos";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapFlightOptionDTO(data: FlightOptionDTO): FlightOption {
  return {
    ...data,
    code: data.code,
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
    pricingInfo: {
      du: data.pricingInfo.du,
      markdown: data.pricingInfo.markdown,
      tariffNet: data.pricingInfo.tariff_net,
      tariffWithoutAgreement: data.pricingInfo.tariff_without_agreement,
      total: {
        amount: Number(data.pricingInfo.total.amount.toFixed(2)),
        currency: data.pricingInfo.total.currency,
      },
      baseFare: {
        amount: Number(data.pricingInfo.baseFare.amount),
        currency: data.pricingInfo.baseFare.currency,
      },
      taxes: data.pricingInfo.taxes.map((tax) => {
        return {
          ...tax,
          amount: Number(tax.amount),
        };
      }),
    },
    servicesIncluded: {
      baggage: data.servicesInclude.baggage,
      checkedBaggage: data.servicesInclude.checked_baggage,
      comfortSeat: data.servicesInclude.comfort_seat,
      refund: data.servicesInclude.refund,
      refundDescription: data.servicesInclude.refund_description,
      rescheduling: data.servicesInclude.rescheduling,
      seatAssignment: data.servicesInclude.seat_assignment,
    },
  };
}
