import { FlightFeeCode, FlightOption } from "~/application/types";
import { AirwayBookingCartPrice, AirwayFeeItem } from "./types";

export function calculateAirwayBookingCartPrice(
  goFlight: FlightOption | null,
  returnFlight: FlightOption | null,
  adultQuantity: number,
  childrenQuantity: number,
  stretchs: FlightOption[]
): AirwayBookingCartPrice {
  const passengersQuantity = adultQuantity + childrenQuantity;

  let totalValue = 0;
  let individualValue = 0;

  const feeItemsObject: Record<FlightFeeCode, AirwayFeeItem> = {
    [FlightFeeCode.BOARDING]: {
      label: "Taxas e encargos",
      value: 0,
      code: FlightFeeCode.BOARDING,
    },
    [FlightFeeCode.SERVICES]: {
      label: "Taxas e serviços",
      value: 0,
      code: FlightFeeCode.SERVICES,
    },
  };

  if (stretchs.length) {
    stretchs.forEach((stretch) => {
      individualValue += stretch.pricingInfo.baseFare.amount;
      totalValue += stretch.pricingInfo.total.amount * passengersQuantity;

      for (const tax of stretch.pricingInfo.taxes) {
        if (feeItemsObject[tax.code]) {
          feeItemsObject[tax.code].value += tax.amount * passengersQuantity;
        }
      }
    });
  }

  if (goFlight) {
    individualValue += goFlight.pricingInfo.baseFare.amount;
    totalValue += goFlight.pricingInfo.total.amount * passengersQuantity;

    for (const tax of goFlight.pricingInfo.taxes) {
      if (feeItemsObject[tax.code]) {
        feeItemsObject[tax.code].value += tax.amount * passengersQuantity;
      }
    }
  }

  if (returnFlight) {
    individualValue += returnFlight.pricingInfo.baseFare.amount;
    totalValue += returnFlight.pricingInfo.total.amount * passengersQuantity;

    for (const tax of returnFlight.pricingInfo.taxes) {
      if (feeItemsObject[tax.code]) {
        feeItemsObject[tax.code].value += tax.amount * passengersQuantity;
      }
    }
  }

  return {
    totalValue,
    adultIndividualValue: individualValue,
    childIndividualValue: childrenQuantity > 0 ? individualValue : null,
    feeItems: Object.values(feeItemsObject),
  };
}
