import { Customer } from "~/application/types";
import * as MaskUtils from "~/utils/mask.utils";

export type EditableCustomer = Customer;

export function editCustomer(data: Customer): EditableCustomer {
  return {
    ...data,
    domains: data.domains.map(({ domain }) => ({ domain })),
    cnpj: MaskUtils.asCNPJ(data.cnpj),
    zipCode: MaskUtils.asCEP(data.zipCode),
    stateRegistration: data.stateRegistration ?? undefined,
    wintourCode: data.wintourCode ?? undefined,
    dashboardLink: data.dashboardLink ?? undefined,
    phone: data.phone ? MaskUtils.pickPhoneNumberMask(data?.phone) : "",
  };
}
