import { useCallback, useState } from "react";
import { OrderAirwaySegment } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { OrderFlightDetailsDialog } from "~/core/modules/Order/pages/OrderPage/components/OrderFlightDetails";

export interface UseAirwayItemResult {
  isAirwayItemExpanded: boolean;
  onOpenFlightDetails: (data: OrderAirwaySegment | undefined) => void;
  toggleAirwayItemVisible: () => void;
}

export function useAirwayItem(): UseAirwayItemResult {
  const [isAirwayItemExpanded, setAirwayItemExpanded] = useState(true);

  const onOpenFlightDetails = useCallback((data: OrderAirwaySegment | undefined) => {
    const { segments, value, tracker } = data as OrderAirwaySegment;
    dialogService.showDialog(
      <OrderFlightDetailsDialog
        flightValue={value}
        data={segments}
        tracker={tracker}
        onCloseClick={dialogService.popDialog}
      />
    );
  }, []);

  const toggleAirwayItemVisible = useCallback(() => {
    setAirwayItemExpanded((old) => !old);
  }, []);

  return {
    isAirwayItemExpanded,
    toggleAirwayItemVisible,
    onOpenFlightDetails,
  };
}
