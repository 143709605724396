import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { IPendingOrdersFilters } from "../PendingOrdersContainer";
import { Container } from "~/components/Container";
import { Col } from "~/components/Grid";
import { MultiSelect } from "~/components/Input/MultiSelect";
import { Button } from "~/components/Button";
import { SearchBar, Select } from "~/components/Input";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { groupByCustomer, orderItems } from "../utils";

type ISelectOption = {
  value: string;
  label: string;
};

export interface PendingOrderFilter {
  isLoading: boolean;
  selectedFilters: IPendingOrdersFilters;
  filters?: IPendingOrdersFilters;
  onFilterOrderItem: (item: ISelectOption[]) => void;
  onFilterCustomer: (data: ISelectOption[]) => void;
  onFilterTravelers: (data: ISelectOption[]) => void;
  onFilterGroupCustomer: (data: ISelectOption) => void;
  onFilterConsultant: (data: ISelectOption[]) => void;
  onFilterStatus: (data: ISelectOption[]) => void;
  onCleanFilters: () => void;
  onOrderNumber: (search: string) => void;
  onFilterTracker: (search: string) => void;
  onIssuer: (data: ISelectOption[]) => void;
}

export function PendingOrderFilter({
  selectedFilters,
  filters,
  isLoading,
  onFilterCustomer,
  onFilterOrderItem,
  onCleanFilters,
  onFilterConsultant,
  onFilterGroupCustomer,
  onFilterStatus,
  onOrderNumber,
  onFilterTracker,
  onIssuer,
}: PendingOrderFilter) {
  const isMobile = useMobile();

  return (
    <Container
      css={{
        py: "$6",
        borderTop: "1px solid $colors$neutrals-light",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "@mxlg": {
          px: "0",
        },
      }}
    >
      <Flex
        direction={{ "@mxlg": "column" }}
        gap="4"
        align="center"
        wrap="noWrap"
      >
        <Col css={{ "@mxlg": { width: "100%" } }}>
          <SearchBar
            placeholder="Nº do pedido ou localizador"
            search={selectedFilters.orderNumber}
            onSearchChange={onOrderNumber}
            style={{ width: isMobile ? "100%" : "auto" }}
          />
        </Col>

        <Col
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <MultiSelect
            value={selectedFilters.customers}
            options={filters?.customers}
            isLoading={isLoading}
            onChange={onFilterCustomer}
            placeholder="Empresas"
          />
        </Col>

        <Col
          sz="1"
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <MultiSelect
            value={selectedFilters.issuers}
            options={filters?.issuers}
            isLoading={isLoading}
            onChange={onIssuer}
            placeholder="Solicitante"
          />
        </Col>

        <Col
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <Select
            value={selectedFilters.groupByCustomer || ""}
            options={groupByCustomer}
            isLoading={isLoading}
            portalled
            onChange={onFilterGroupCustomer}
            placeholder="Listar por"
          />
        </Col>

        <Col
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <MultiSelect
            value={selectedFilters.consultants}
            options={filters?.consultants}
            isLoading={isLoading}
            onChange={onFilterConsultant}
            placeholder="Consultores"
          />
        </Col>

        <Col
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <MultiSelect
            value={selectedFilters.status}
            options={filters?.status}
            isLoading={isLoading}
            onChange={onFilterStatus}
            placeholder="Status"
          />
        </Col>

        <Col
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <MultiSelect
            value={selectedFilters.orderItems}
            isLoading={isLoading}
            options={orderItems}
            onChange={onFilterOrderItem}
            placeholder="Item"
          />
        </Col>

        <Col sz="auto">
          <Button
            title="Limpar filtros"
            variant="tertiary"
            onClick={onCleanFilters}
          >
            <Text>Limpar filtros</Text>
          </Button>
        </Col>
      </Flex>
    </Container>
  );
}

PendingOrderFilter.displayName = "PendingOrderFilter";
