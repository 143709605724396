import { BaseSyntheticEvent, Fragment } from "react";
import { Control, FormState } from "react-hook-form";
import QRCode from "react-qr-code";

import { BiztripLogo } from "~/components/BiztripLogo";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Icon } from "~/components/Icon";
import { SvgAirplane, SvgBus, SvgCar, SvgHotels, SvgMail } from "~/components/Icon/icons";
import { PasswordInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { InstantlyApprovalAuthFormData } from "./InstantlyApprovalAuthPage";

interface InstantlyApprovalAuthContainerProps {
  control: Control<InstantlyApprovalAuthFormData, any>;
  formState: FormState<InstantlyApprovalAuthFormData>;
  onFormSubmit: (e: BaseSyntheticEvent) => void;
}

export function InstantlyApprovalAuthContainer({
  control,
  formState,
  onFormSubmit,
}: InstantlyApprovalAuthContainerProps) {
  const { errors } = formState;
  const { qrCodeUrl } = useAuth();
  const serviceIcon = [SvgAirplane, SvgCar, SvgBus, SvgHotels];

  return (
    <Fragment>
      <Box style={{ height: "100vh", padding: 0 }}>
        <Box
          mode="show"
          css={{
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "450px",
            mb: "$20",
            zIndex: "-1",
          }}
        >
          <Flex direction="column" align="center">
            <Flex
              align="center"
              direction="column"
              css={{
                margin: "$20 auto",
                height: "$80",
                width: "700px",
                ta: "center",
                "@mxlg": {
                  width: "80%",
                },
              }}
            >
              <Text
                as="h1"
                size={{ "@initial": "8", "@mxlg": "6" }}
                variant="white"
                css={{ mb: "$5", ta: "center" }}
              >
                Facilite sua gestão de viagens com nossa plataforma inovadora
              </Text>
              <Text variant="white" css={{ ta: "center" }} size="3">
                Esqueça as dificuldades das agências tradicionais
              </Text>

              <Flex gap="3" css={{ mt: "$5" }}>
                {serviceIcon.map((service, i) => (
                  <Flex
                    key={`service-${i}`}
                    align="center"
                    justify="center"
                    css={{
                      borderRadius: "100%",
                      border: "1px solid #FFF",
                      background: "#FFF",
                      width: "50px",
                      height: "50px",
                    }}
                  >
                    <Icon fill="#0064C5" as={service} />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Box>

        <Container css={{ p: "$5" }}>
          <Container
            size={{ "@initial": "5", "@mxmd": "7" }}
            css={{
              m: 0,
              margin: "0 auto",
              background: "#FFF",
              position: "relative",
              top: "270px",
              px: "$10",
              py: "$6",
              borderRadius: "$md",
              boxShadow: "$md",
              width: "100%",
            }}
          >
            <Flex justify="center" direction="column">
              <BiztripLogo
                size={{ "@initial": "md", "@mxmd": "lg" }}
                css={{ mb: "$3", mx: qrCodeUrl || qrCodeUrl === null ? "auto" : 0 }}
              />

              <Text
                id="login-text-header"
                size="4"
                as="h1"
                css={{
                  fw: "700",
                  mb: "$3",
                  mx: qrCodeUrl || qrCodeUrl === null ? "auto" : 0,
                  "@media (max-width: 768px)": {
                    textAlign: "center",
                  },
                }}
              >
                Por favor confirme sua senha
              </Text>

              <Form onSubmit={onFormSubmit}>
                <Flex direction="column" gap="5">
                  <FormControl name="password" control={control} required>
                    <FieldLabel>Senha</FieldLabel>

                    <PasswordInput placeholder="Digite a sua senha" autoComplete="off" />

                    {errors.password && (
                      <Text variant="error-base" size="4" css={{ mt: "$2" }}>
                        {errors.password.message}
                      </Text>
                    )}
                  </FormControl>

                  <Button type="submit">Aprovar pedido</Button>
                </Flex>
              </Form>
            </Flex>
          </Container>
        </Container>
      </Box>
    </Fragment>
  );
}
