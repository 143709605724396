import { useFieldArray } from "react-hook-form";
import { formatNumber } from "~/application/utils/string-functions";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { CardSectionTitle } from "~/core/modules/DeprecatedBooking/components/Card";
import { Counter } from "~/core/shared/components/Counter";
import { CounterListItem } from "~/core/shared/components/CounterListItem";
import { SelectButton } from "~/core/shared/components/SelectButton";
import {
  SelectContent,
  SelectItem,
  SelectItemText,
  Select as SelectRoot,
  SelectTrigger,
  SelectValue,
} from "~/core/shared/components/SelectButton/Select";
import { rangedArray } from "~/presentation/shared/utils/array-functions";
import { RoomSectionProps } from "./types";

const MAX_GUEST_QUANTITY = 10;

export const RoomSection = ({
  data,
  control,
  register,
  roomIndex,
  children,
  ...props
}: RoomSectionProps) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `accommodations.${roomIndex}.guestsChildren`,
  });

  const childrenQuantity = data.accommodations[roomIndex].guestsChildren.length;
  const adultQuantity = data.accommodations[roomIndex].adultQuantity;

  return (
    <Flex direction="column" {...props}>
      <CardSectionTitle>Quarto {formatNumber(1 + roomIndex, 2)}</CardSectionTitle>

      <CounterListItem title="Adultos" description="A partir de 18 anos de idade">
        <Counter
          {...register(`accommodations.${roomIndex}.adultQuantity`, {
            min: 1,
            max: 99,
          })}
          minValue={1}
          maxValue={MAX_GUEST_QUANTITY - childrenQuantity}
          defaultValue={data.accommodations[roomIndex].adultQuantity}
        />
      </CounterListItem>

      <CounterListItem title="Crianças" description="Até 17 anos de idade">
        <Counter
          minValue={0}
          maxValue={MAX_GUEST_QUANTITY - adultQuantity}
          onIncrease={() => append({ age: "1" })}
          onDecrease={() => remove(fields.length - 1)}
          defaultValue={data.accommodations[roomIndex].guestsChildren.length}
        />
      </CounterListItem>

      {fields.map((field, fieldIndex) => (
        <Flex gap="4" align="center" justify="between" css={{ p: "$2 $4" }} key={field.id}>
          <Text size="3" css={{ fw: "600" }}>
            Idade menor {1 + fieldIndex}
          </Text>

          <SelectRoot defaultValue={field.age} onValueChange={(age) => update(fieldIndex, { age })}>
            <SelectTrigger asChild>
              <SelectButton>
                <SelectValue />
              </SelectButton>
            </SelectTrigger>

            <SelectContent>
              {rangedArray(15).map((value) => (
                <SelectItem value={`${1 + value}`} key={value}>
                  <SelectItemText>
                    {value === 0 ? "Até 12 meses" : `Até ${1 + value} anos`}
                  </SelectItemText>
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Flex>
      ))}

      {children}
    </Flex>
  );
};
