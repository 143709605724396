import { styled } from "~/application/theme";
import { Icon } from "../Icon";

export const StepperItemBadge = styled("span", {
  // Reset
  boxSizing: "border-box",

  // Custom
  borderRadius: "$circular",
  size: "$8",
  fontSize: "$xxs",
  fontWeight: 500,

  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "$2",

  backgroundColor: "$$badgeBackground",
  color: "$$badgeColor",

  [`& ${Icon}`]: {
    fill: "$$badgeColor",
  },
});

StepperItemBadge.displayName = "StepperItemBadge";
