import { useProjects } from "~/core/modules/Customer/pages/CustomerPage/hooks/useProjects";
import { ProjectsContainer } from "./ProjectsContainer";
import { useUser } from "~/presentation/core/contexts/UserContext";

export function ProjectsPage() {
  const { contexts } = useUser();

  const projectState = useProjects({
    customerId: contexts.customer.uuid,
    enabled: true,
  });

  return <ProjectsContainer {...projectState} />;
}
