import { Fragment, useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Link } from "react-router-dom";

import { City, Customer, State } from "~/application/types";
import { Box } from "~/components/Box";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Grid, Row } from "~/components/Grid";
import { MaskedInput, Select, TextInput } from "~/components/Input";
import { H4 } from "~/components/Typography";
import { QueryKeys } from "~/constants/queryKeys";
import * as MaskUtils from "~/utils/mask.utils";
import { states } from "../../../../data";
import { Text } from "~/components/Text";
import { CustomerSchema } from "../pages/CustomerPage";
import { Flex } from "~/components/Flex";
import { Button } from "~/components/Button";
import { Spinner } from "~/components/Spinner";
import { Icon } from "~/components/Icon";
import { SvgPlus, SvgTrash } from "~/components/Icon/icons";

export interface CustomerFormProps {
  defaultData?: Customer;
  isSubmitting: boolean;
  customerHookForm: (defaultData?: Customer | undefined) => UseFormReturn<CustomerSchema, any>;
  onSubmit: (data: Customer) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function CustomerForm({
  defaultData,
  isSubmitting,
  customerHookForm,
  fetchCitiesByState,
  onSubmit,
}: CustomerFormProps) {
  const { control, formState, watch, handleSubmit, setValue } = customerHookForm(defaultData);

  const { state, phone } = watch();

  const { data: stateCities, isFetching } = useQuery(
    [QueryKeys.STATE_CITIES, state?.state],
    () => fetchCitiesByState(state!),
    {
      cacheTime: 0,
      enabled: !!state,
    }
  );
  useEffect(() => {
    if (!state || !formState.dirtyFields.state) return;
    setValue("city", {} as City);
  }, [state]);

  const getStateLabel = useCallback((state: State) => state.name, []);
  const getStateValue = useCallback((state: State) => state.state, []);

  const getCityLabel = useCallback((city: City) => city.name, []);
  const getCityValue = useCallback((city: City) => city.uuid, []);

  const css = {
    "@mxlg": {
      width: "100%",
    },
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit((data) => onSubmit(data as Customer))}>
        <Box css={{ mb: "$10" }}>
          <H4>Informações básicas</H4>
        </Box>

        <Row
          gap="6"
          css={{
            "@mxlg": {
              flexDirection: "column",
            },
          }}
        >
          <Col sz="8" css={css}>
            <FormControl name="cnpj" control={control} required>
              <FieldLabel>CNPJ</FieldLabel>
              <MaskedInput mask={MaskUtils.MASK_CNPJ} />
              {formState.errors.cnpj && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.cnpj.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="4" css={css}>
            <FormControl name="stateRegistration" control={control}>
              <FieldLabel>Inscrição Estadual</FieldLabel>
              <TextInput />
              {formState.errors.stateRegistration && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.stateRegistration.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="12" css={css}>
            <FormControl name="companyName" control={control} required>
              <FieldLabel>Razão social</FieldLabel>
              <TextInput />
              {formState.errors.companyName && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.companyName.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="12" css={css}>
            <FormControl name="wintourCode" control={control}>
              <FieldLabel>Código de integração</FieldLabel>
              <TextInput />
              {formState.errors.wintourCode && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.wintourCode.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="12" css={css}>
            <FormControl name="tradingName" control={control} required>
              <FieldLabel>Nome Fantasia</FieldLabel>
              <TextInput />
              {formState.errors.tradingName && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.tradingName.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="12" css={css}>
            <FormControl name="dashboardLink" control={control}>
              <FieldLabel>Link do dashboard</FieldLabel>
              <TextInput />
              {formState.errors.dashboardLink && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.dashboardLink.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="8" css={css}>
            <FormControl name="email" control={control} required>
              <FieldLabel>E-mail</FieldLabel>
              <TextInput />
              {formState.errors.email && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.email.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="4" css={css}>
            <FormControl name="phone" control={control} required>
              <FieldLabel>Telefone</FieldLabel>
              <MaskedInput mask={MaskUtils.pickPhoneNumberMask(phone)} />
              {formState.errors.phone && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.phone.message}
                </Text>
              )}
            </FormControl>
          </Col>
          <Grid
            columns={2}
            css={{
              width: "100%",
            }}
            gap="2"
          >
            <Flex
              css={{
                width: "100%",
              }}
            >
              <FormControl
                control={control}
                name={`domains.${0}.domain`}
                css={{ width: "100%" }}
                required
              >
                <FieldLabel>Dominio 1</FieldLabel>
                <Flex
                  align="center"
                  gap="2"
                  css={{
                    width: "100%",
                  }}
                >
                  <TextInput
                    prefix="@"
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
              </FormControl>
            </Flex>
            <Flex
              css={{
                width: "100%",
              }}
            >
              <FormControl control={control} name={`domains.${1}.domain`} css={{ width: "100%" }}>
                <FieldLabel>Dominio 2</FieldLabel>
                <Flex
                  align="center"
                  gap="2"
                  css={{
                    width: "100%",
                  }}
                >
                  <TextInput
                    prefix="@"
                    style={{
                      width: "100%",
                    }}
                  />
                </Flex>
              </FormControl>
            </Flex>
          </Grid>
        </Row>

        <Box css={{ my: "$10" }}>
          <H4>Endereço</H4>
        </Box>

        <Row gap="6">
          <Col sz="3" css={css}>
            <FormControl name="zipCode" control={control} pattern={MaskUtils.PATTERN_CEP} required>
              <FieldLabel>CEP</FieldLabel>
              <MaskedInput mask={MaskUtils.MASK_CEP} />
              {formState.errors.zipCode && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.zipCode.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="9" css={css}>
            <FormControl name="address" control={control} required>
              <FieldLabel>Logradouro</FieldLabel>
              <TextInput />
              {formState.errors.address && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.address.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="3" css={css}>
            <FormControl name="number" control={control} required>
              <FieldLabel>Número</FieldLabel>
              <TextInput />
              {formState.errors.number && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.number.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="9" css={css}>
            <FormControl name="district" control={control} required>
              <FieldLabel>Bairro</FieldLabel>
              <TextInput />
              {formState.errors.district && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.district.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="6" css={css}>
            <FormControl name="state" control={control} required>
              <FieldLabel>Estado</FieldLabel>
              <Select
                placeholder="Selecione o estado"
                getOptionLabel={getStateLabel}
                getOptionValue={getStateValue}
                options={states}
              />
              {formState.errors.state?.name && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.state.name.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="6" css={css}>
            <FormControl name="city" control={control} required>
              <FieldLabel>Cidade</FieldLabel>
              <Select
                placeholder="Selecione a cidade"
                options={stateCities}
                getOptionLabel={getCityLabel}
                getOptionValue={getCityValue}
                isLoading={isFetching}
              />
              {formState.errors.city?.name && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.city.name.message}
                </Text>
              )}
            </FormControl>
          </Col>
        </Row>

        <Flex gap="4" justify="end" css={{ mt: "$16" }}>
          <Link to="/configuracoes/empresas">
            <Button form="update-customer" variant="tertiary" type="reset">
              <Text>Cancelar</Text>
            </Button>
          </Link>

          <Button type="submit" disabled={isFetching || formState.isSubmitting || isSubmitting}>
            {formState.isSubmitting ||
              (isSubmitting && (
                <Spinner
                  css={{
                    borderLeftColor: "$neutrals-white",
                    width: "$4",
                    height: "$4",
                    borderWidth: "2px",
                  }}
                />
              ))}
            <Text>Salvar</Text>
          </Button>
        </Flex>
      </Form>
    </Fragment>
  );
}
