import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItems,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "~/application/components/DropdownMenu";
import { ENV_MODE } from "~/application/constants";
import { AGENCY_CUSTOMER_ID } from "~/application/constants/storageKeys";
import { Customer, UserContext } from "~/application/types";
import { authService, customerService } from "~/application/usecases";
import { logFile } from "~/application/utils/log";
import { asCNPJ } from "~/application/utils/mask-functions";
import { createDownload } from "~/application/utils/navigator-functions";
import { Box } from "~/components/Box";
import { CardSectionHeader } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronRight, SvgClose, SvgPerson } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { snackbarService } from "~/components/SnackbarStack";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { ListItem } from "~/presentation/shared/components/ListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { useQueryCustomers } from "~/presentation/shared/hooks/useQueryCustomers";
import { RequestPasswordChangeDialog } from "~/presentation/shared/views/ChangePasswordDialog";
import { ProfileCard } from "./ProfileCard";
import { H5 } from "~/components/Typography";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog/LoadingDialog";
import { logError } from "~/presentation/shared/utils/errors";
import { CreditLimit } from "../CreditLimits/CreditLimit";
import { QueryKeys } from "~/constants";
import { log } from "~/utils/log";

export const SNACKBAR_MESSAGES = {
  REQUEST_PASSWORD_CHANGE_SUCCESS_MESSAGE: "Solicitação de alteração de senha feita com sucesso",
  REQUEST_PASSWORD_CHANGE_ERROR_MESSAGE: "Falha ao solicitar alteração de senha",
  CUSTOMER_LOAD_ERROR_MESSAGE: " Falha ao carregar a empresa",
} as const;

const LOG_TAG = "AppContainer/ProfileMenu";

export const ProfileMenu: FC = () => {
  const [ProfileSideMenuIsOpen, setSideMenuIsOpen] = useState(false);

  const { user, contexts, setUserContext } = useUser();
  const { signOut, signIn } = useAuth();

  const [searchText, setSearchText] = useState("");
  const [debouncedText] = useDebounce(searchText, 700);
  const customerId = localStorage.getItem(AGENCY_CUSTOMER_ID);
  const isMobile = useMobile();

  const { data: customerList } = useQueryCustomers({
    currentPage: 1,
    search: debouncedText,
    options: { enabled: !!contexts.agency },
  });

  const { data: customer } = useQuery(
    [QueryKeys.CUSTOMER, user?.agency ?? contexts?.agency, customerId],
    () => customerService.findById(customerId!),
    {
      enabled: !!customerId,
      onError: (error) => {
        logError({
          defaultErrorMessage: SNACKBAR_MESSAGES.CUSTOMER_LOAD_ERROR_MESSAGE,
          error,
          logTag: LOG_TAG,
        });
      },
    }
  );

  const onDownloadLogs = useCallback(() => {
    const fileData = logFile;

    const fileName = `log-${new Date().toISOString()}.log`;

    createDownload(fileData, "text/plain", fileName);
  }, [logFile]);

  const setCustomerContext = useCallback(
    (customer?: Customer, isEffect?: boolean) => {
      if (customerId && customerId === customer?.uuid && !isEffect) {
        setUserContext({ customer: undefined });
        localStorage.removeItem(AGENCY_CUSTOMER_ID);
        return;
      }

      setUserContext({
        customer: customer?.uuid !== contexts.customer?.uuid ? customer : undefined,
      });

      localStorage.setItem(AGENCY_CUSTOMER_ID, customer?.uuid ?? "");
    },
    [contexts, setUserContext, customerId]
  );
  useEffect(() => {
    if (contexts.agency && !contexts.customer && customer) {
      setCustomerContext(customer, true);
    } else if (!contexts.agency && !contexts.customer) {
      setCustomerContext(user.customer, true);
    }
  }, [contexts.agency, contexts.customer, customer, customerId, user, setCustomerContext]);

  const handleSignOut = useCallback(() => {
    signOut();
    setCustomerContext(undefined);
  }, [signOut]);

  const canChangePassword = user.context === UserContext.Customer;

  const { mutate: mutateRequestPasswordChange } = useMutation(
    async () => await authService.requestChangePassword(user.profiles.customer.uuid),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Solicitando alteração de senha" />);
      },
      onSuccess: () => {
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.REQUEST_PASSWORD_CHANGE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.REQUEST_PASSWORD_CHANGE_ERROR_MESSAGE,
        });
      },
      onSettled: () => {
        dialogService.popDialog();
      },
    }
  );

  const handleRequestPasswordChange = useCallback(() => {
    dialogService.showDialog(
      <RequestPasswordChangeDialog onSubmit={mutateRequestPasswordChange} />
    );
  }, [mutateRequestPasswordChange]);

  const onSetCustomerContextClick = () => setCustomerContext(user.customer, true);

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Box>
            {isMobile ? (
              <SvgPerson />
            ) : (
              <ProfileCard onSetCustomerContextClick={onSetCustomerContextClick} />
            )}
          </Box>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          align="end"
          style={{ display: ProfileSideMenuIsOpen ? "none" : "block" }}
        >
          <DropdownMenuItems>
            {isMobile && user.customer ? <CreditLimit /> : null}

            {canChangePassword && (
              <DropdownMenuItem onClick={handleRequestPasswordChange}>
                <Text>Alterar senha</Text>
              </DropdownMenuItem>
            )}

            {ENV_MODE !== "prod" && (
              <DropdownMenuItem onClick={onDownloadLogs}>
                <Text>Baixar logs</Text>
              </DropdownMenuItem>
            )}

            {!!contexts.agency && (
              <DropdownMenuSub>
                <DropdownMenuSubTrigger asChild>
                  <DropdownMenuButton
                    onClick={isMobile ? () => setSideMenuIsOpen((old) => !old) : undefined}
                  >
                    <Text css={{ flexGrow: 1 }}>Trocar Empresa</Text>
                    <Icon as={SvgChevronRight} />
                  </DropdownMenuButton>
                </DropdownMenuSubTrigger>

                <DropdownMenuSubContent
                  sideOffset={16}
                  style={{ display: ProfileSideMenuIsOpen ? "none" : "block" }}
                >
                  <CardSectionHeader>
                    <Text>Empresas</Text>
                  </CardSectionHeader>

                  <Box
                    css={{
                      maxHeight: 350,
                      overflowY: "auto",
                    }}
                  >
                    <Box
                      css={{
                        p: "$4",
                        borderBottom: "1px solid $colors$neutrals-light",
                      }}
                    >
                      <SearchBar
                        search={searchText}
                        onSearchChange={setSearchText}
                        placeholder="Procurar empresa"
                        style={{ width: "100%" }}
                      />
                    </Box>
                    {!customerList ? (
                      <Flex align="center" justify="center" css={{ p: "$4" }}>
                        <Spinner />
                      </Flex>
                    ) : customerList.data.length === 0 ? (
                      <Box css={{ p: "$4" }}>
                        {searchText.length > 0 ? (
                          <Text>Nenhuma empresa corresponde aos dados informados</Text>
                        ) : (
                          <Text>Você não possui empresas cadastradas</Text>
                        )}
                      </Box>
                    ) : (
                      customerList.data.map((item) => (
                        <ListItem
                          title={item.tradingName}
                          description={asCNPJ(item.cnpj)}
                          onClick={() => setCustomerContext(item)}
                          isSelected={contexts.customer?.uuid === item.uuid}
                          key={item.uuid}
                        />
                      ))
                    )}
                  </Box>
                </DropdownMenuSubContent>
              </DropdownMenuSub>
            )}

            <DropdownMenuItem onClick={handleSignOut}>
              <Text>Sair</Text>
            </DropdownMenuItem>
          </DropdownMenuItems>
        </DropdownMenuContent>
      </DropdownMenu>
      {isMobile && ProfileSideMenuIsOpen ? (
        <Flex
          direction="column"
          align="center"
          css={{
            background: "#FFF",
            height: "100vh",
            top: "0",
            width: "80%",
            borderRadius: "$md",
            transition: "$slow",
            position: "absolute",
            left: "0",
          }}
        >
          <Flex justify="between" align="center" css={{ height: "0", mt: "$10", width: "80%" }}>
            <H5>Empresas</H5>
            <Icon as={SvgClose} onClick={() => setSideMenuIsOpen(false)} />
          </Flex>

          <Flex
            direction="column"
            justify="center"
            css={{
              overflow: "scroll",
              height: "90%",
              mt: "$10",
              mx: "$4",
              boxShadow: "$sm",
            }}
          >
            {customerList?.data.map((item) => (
              <ListItem
                title={item.tradingName}
                description={asCNPJ(item.cnpj)}
                onClick={() => {
                  setCustomerContext(item);
                  setSideMenuIsOpen(false);
                  snackbarService.showSnackbar(`Mudou para ${item.companyName}`, "success");
                }}
                isSelected={contexts.customer?.uuid === item.uuid}
                key={item.uuid}
              />
            ))}
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};
