import { Fragment, useCallback } from "react";
import { OrderRoadSegment, OrderStatus, UserContext } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { AgencyRoadValueDetails } from "~/presentation/shared/components/AgencyRoadValueDetails";
import { OrderRoadItemSkeleton } from "~/presentation/shared/components/OrderRoadItemSkeleton";
import { ReasonRejectedItem } from "~/presentation/shared/components/ReasonRejectedItem";
import { RoadTravelerListItem } from "~/presentation/shared/components/RoadTravelerListItem";
import { useCopyTracker } from "~/presentation/shared/hooks/useCopyTracker";
import { useOrder } from "~/presentation/shared/hooks/useOrder/useOrder";
import { canMarkAsRejected } from "~/presentation/shared/utils";
import { OrderRoadCard } from "./OrderRoadCard";
import { useUser } from "~/presentation/core/contexts/UserContext";

export interface OrderRoadItemProps {
  isLoading: boolean;
  data?: OrderRoadSegment[];
  onCopyText: (value: string) => void;
  onOpenDetails: (data: OrderRoadSegment) => void;
}

export function OrderRoadItem({ data, isLoading, onOpenDetails }: OrderRoadItemProps) {
  const { order } = useOrder();
  const orderStatus = order?.status as OrderStatus;
  const { user } = useUser();

  const isAgency = user.context === UserContext.Agency;

  const { renderCopyTracker } = useCopyTracker();

  const listRenderer = useCallback(
    (item: OrderRoadSegment, index: number) => {
      const canMarkItemAsRejected = canMarkAsRejected({
        reasonRejected: item.reasonRejected,
        order,
      });

      const RoadTravel = () => {
        return (
          <Fragment>
            <Flex>
              <Col>
                <Text size="5">Passagem</Text>
              </Col>

              <Tag css={{ mr: "$2" }} variant="info-light">
                {item.credential}
              </Tag>

              <Flex css={{ gap: "$2" }} align="center">
                <Text variant="darkest" css={{ fw: "600" }}>
                  <Flex align="center">{item.tracker && item.tracker}</Flex>
                </Text>

                {item.tracker && renderCopyTracker(item.tracker as string)}
              </Flex>
            </Flex>

            <OrderRoadCard data={item} onOpenDetails={onOpenDetails} />

            {isAgency && (
              <Card>
                <CardBody>
                  <AgencyRoadValueDetails item={item} />
                </CardBody>
              </Card>
            )}

            {canMarkItemAsRejected && (
              <ReasonRejectedItem css={{ mt: "$6" }} reasonRejected={item.reasonRejected} />
            )}
          </Fragment>
        );
      };

      return (
        <Flex direction="column" gap="8">
          <Card>
            <CardBody>
              <Flex direction="column" gap="6" key={index}>
                {canMarkItemAsRejected ? (
                  <Card
                    css={{
                      border: canMarkItemAsRejected ? "1px solid red" : "none",
                    }}
                  >
                    <CardBody>
                      <RoadTravel />
                    </CardBody>
                  </Card>
                ) : (
                  <RoadTravel />
                )}
              </Flex>
            </CardBody>
          </Card>
          <>
            {" "}
            <Text size="5">Passageiros</Text>
            <LazyList
              items={item.travelers}
              render={(traveler) => (
                <RoadTravelerListItem
                  item={item}
                  orderStatus={order?.status as OrderStatus}
                  traveler={traveler}
                  key={traveler.uuid}
                />
              )}
              skeletonHeight={88}
              skeletonQuantity={2}
            />
          </>
        </Flex>
      );
    },
    [onOpenDetails]
  );

  return (
    <Box>
      <LazyList
        isLoading={isLoading}
        items={data}
        gap="8"
        render={listRenderer}
        skeletonQuantity={1}
        SkeletonComponent={<OrderRoadItemSkeleton />}
      />
    </Box>
  );
}
