import type { CustomerMarkdown } from "~/application/types";
import { api } from "~/infrastructure/api";
import { CustomerMarkdownDTO } from "~/infrastructure/api/dtos";
import type { ICustomerMarkdownService } from "./ICustomerMarkdownService";

export class CustomerMarkdownService implements ICustomerMarkdownService {
  async update(data: CustomerMarkdown): Promise<void> {
    return await api
      .patch<undefined, CustomerMarkdownDTO>("/customers/markdown", {
        customer_uuid: data.customerId,
        markdown_uuid: data.markdownId,
      })
      .then(({ data }) => data);
  }
}
