import { Card } from "~/application/types";
import { CardDTO } from "../dtos";

export function mapCardDTO(dto: CardDTO): Card {
  return {
    uuid: dto.uuid,
    owner: dto.owner,
    agencyUuid: dto.agency_uuid,
    description: dto.description,
    number: dto.last_number,
    validity: dto.validity,
    internalCardCode: dto.internal_card_code,
    active: dto.active,
    installments: dto.installments,
    flag: {
      uuid: dto.card_flag.uuid,
      name: dto.card_flag.name,
      identifier: dto.card_flag.identifier,
    },
  } as Card;
}
