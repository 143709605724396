import { FC, Fragment } from "react";
import { AppBar } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Stepper, StepperItem } from "~/components/Stepper";
import { TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { SelectIssuer } from "~/presentation/shared/components/SelectIssuerModal";
import { BookingAirwaySteps } from "../constants";
import { AirwayBookingBarSection } from "./sections/AirwayBookingBarSection";
import { AirwayCartSection } from "./sections/AirwayCartSection";
import { AirwayTimerSection } from "./sections/AirwayTimerSection";
import { ConfirmationSection } from "./sections/ConfirmationSection";
import { GoFlightsSection } from "./sections/GoFlightsSection";
import { ReturnFlightsSection } from "./sections/ReturnFlightsSection";
import { TravelersSection } from "./sections/TravelersSection";
import { FlightsPageUIProps } from "./types";
import useMobile from "~/presentation/shared/hooks/useMobile";

export const FlightsPageUI: FC<FlightsPageUIProps> = ({
  canSelectIssuer,
  bookingSteps,
  currentStep,
  bookingStep,
  issuerId,
  isShowingCart,
  isManyStretch,
  airwayParams,
  setIsShowingCart,
  fetchTravelers,
  setCurrentStep,
  onSelectIssuer,
  deleteOrderAirway,
}) => {
  const bookingStepsMobile = bookingSteps.slice(0, 2);
  const currentBookingStep = bookingStep === "select-travelers" || bookingStep === "confirmation";

  const isMobile = useMobile();

  return (
    <Fragment>
      <AppBar>
        <AirwayBookingBarSection
          currentStepSection={bookingStep}
          currentSteppter={currentStep}
          setStep={setCurrentStep}
        />
      </AppBar>

      <Container
        css={{
          pt: "$10",
          pb: "$20",
          "@mxlg": {
            px: "$5",
          },
        }}
      >
        <Stepper
          activeValue={currentStep}
          setActiveValue={setCurrentStep}
          css={{
            mb: "$6",
            "@mxlg": {
              display: "none",
            },
          }}
        >
          {bookingSteps.map((item, index) => (
            <StepperItem enabled={index <= currentStep} value={index} key={item.key}>
              <Text>{item.title}</Text>
            </StepperItem>
          ))}
        </Stepper>

        <Stepper
          activeValue={currentStep}
          setActiveValue={setCurrentStep}
          css={{
            display: "none",
            mb: "$6",
            "@mxlg": {
              display: currentBookingStep ? "none" : "flex",
            },
          }}
        >
          {bookingStepsMobile.map((item, index) => (
            <StepperItem enabled={index <= currentStep} value={index} key={item.key}>
              <Text>{item.title}</Text>
            </StepperItem>
          ))}
        </Stepper>

        <Tabs
          value={bookingStep}
          defaultValue={isManyStretch ? `0` : BookingAirwaySteps.SELECT_GO_TICKET}
        >
          <Row>
            <Col>
              <Flex direction="column" gap="3">
                {isMobile && <AirwayTimerSection isMobile={isMobile} />}

                {airwayParams?.stretch.map((_, index) => (
                  <TabContent value={`${index}`} key={index}>
                    <GoFlightsSection />
                  </TabContent>
                ))}

                {!isManyStretch && (
                  <>
                    <TabContent value={BookingAirwaySteps.SELECT_GO_TICKET}>
                      <GoFlightsSection />
                    </TabContent>

                    <TabContent value={BookingAirwaySteps.SELECT_RETURN_TICKET}>
                      <ReturnFlightsSection />
                    </TabContent>
                  </>
                )}

                <TabContent value={BookingAirwaySteps.SELECT_TRAVELERS}>
                  <TravelersSection setIsShowingCart={setIsShowingCart} />

                  {canSelectIssuer && (
                    <SelectIssuer
                      fetchTravelers={fetchTravelers}
                      css={{ marginTop: "2rem" }}
                      onChange={onSelectIssuer}
                    />
                  )}
                </TabContent>

                <TabContent value={BookingAirwaySteps.CONFIRMATION}>
                  <ConfirmationSection />
                </TabContent>
              </Flex>
            </Col>

            <Col
              sz="3"
              css={{
                "@mxlg": {
                  width: "0",
                  p: 0,
                },
              }}
            >
              <Flex direction="column" gap="6">
                {!isMobile && <AirwayTimerSection isMobile={isMobile} />}
                <AirwayCartSection
                  isShowingCart={isShowingCart}
                  setIsShowingCart={setIsShowingCart}
                  issuerId={issuerId}
                  deleteOrderAirway={deleteOrderAirway}
                />
              </Flex>
            </Col>
          </Row>
        </Tabs>
      </Container>
    </Fragment>
  );
};
