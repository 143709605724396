import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";

import { QueryKeys } from "~/application/constants";
import {
  Order,
  OrderHotelItem,
  OrderHotelOfflineOption,
  OrderRoadSegment,
  OrderStatus,
} from "~/application/types";
import { orderService } from "~/application/usecases";
import { offlineHotelService } from "~/application/usecases/OfflineHotel";
import { NavigatorUtils } from "~/application/utils";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { CreateAssessmentData } from "~/core/modules/Order/pages/OrderPage/utils";
import { ConfirmRequestApprovalDialog } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/ConfirmRequestApprovalDialog";
import { QuoteOfflineHotelForm } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/QuoteOfflineHotelForm";
import { QuoteOfflineHotelFormData } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/QuoteOfflineHotelForm/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useAssessment } from "~/presentation/core/hooks/useAssessment";
import { RoadDetails } from "~/presentation/shared/components/RoadDetails";
import { useAirwayBookingChannel } from "~/presentation/shared/hooks/useAirwayBookingChannel/useAirwayBookingChannel";
import { useCancelOrderChannel } from "~/presentation/shared/hooks/useCancelOrderChannel";
import { logError } from "~/presentation/shared/utils/errors";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { queryClient } from "~/services/queryClient";
import { UseOrderItemsOptions, UseOrderItemsResult } from "./types";
import { useAdditionalInfo } from "./useAdditionalInfo";
import { useAdvanceItem } from "./useAdvanceItem";
import { useAirwayItem } from "./useAirwayItem";
import { useHotelItem } from "./useHotelItem";
import { useRoadItem } from "./useRoadItem";
import { useVehicleItem } from "./useVehicleItem";

const DEFAULT_FORM_DATA = {
  observation: "",
} as CreateAssessmentData;

export const LOG_TAG = "Agency/Pages/AgencyOrderPage";

const SNACKBAR_MESSAGES = {
  QUOTE_ITEM_SUCCESS_MESSAGE: "Cotação realizada com sucesso",
  ISSUE_ORDER_SUCCESS_MESSAGE: "Pedido emitido com sucesso.",
  ISSUE_ORDER_ERROR_MESSAGE: "Falha ao emitir o pedido.",
  RETURN_ITEM_STATUS_SUCCESS_MESSAGE: "O status do pedido foi alterado com sucesso.",
  RETURN_ITEM_STATUS_ERROR_MESSAGE: "Falha ao retornar status do pedido.",
  QUOTE_ITEM_ERROR_MESSAGE: "Falha ao cotizar item",
  UPDATE_QUOTE_ITEM_SUCCESS_MESSAGE: "Cotação editada com sucesso",
  UPDATE_QUOTE_ITEM_ERROR_MESSAGE: "Falha ao editar cotação",
  QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE: "Falha ao enviar cotação",
  QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE: "Cotação enviada com sucesso",
  REMOVE_QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE: "Cotação removida com sucesso",
  REMOVE_QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE: "Falha ao remover cotação",
  CANCEL_ORDER_SUCCESS_MESSAGE: "Pedido cancelado com sucesso",
  CANCEL_ORDER_ERROR_MESSAGE: "Falha ao cancelar pedido",
} as const;

export function useOrderItems({
  order,
  refetchOrder,
  refetchOrderHistory,
}: UseOrderItemsOptions): UseOrderItemsResult {
  const { user } = useUser();
  const customerId = order?.customer.uuid as string;

  useAirwayBookingChannel({
    enabled: order && order.items?.airway?.flights?.some((flight) => !flight.tracker),
    orderId: order?.uuid as string,
    onAirwayBooked: (data) => {
      dialogService.popAll();

      refetchOrder();

      data?.forEach((item: any) => {
        let message = `Reserva do vôo ${item?.number} feita com sucesso`;

        if (!item?.success) {
          message = `Falha ao reservar vôo ${item?.number}`;
        }

        snackbarService.showSnackbar(message, item?.success ? "success" : "error");
      });
    },
  });

  const { isAdvanceItemExpanded, toggleAdvanceItemVisible } = useAdvanceItem();

  const { isAirwayItemExpanded, toggleAirwayItemVisible, onOpenFlightDetails } = useAirwayItem();

  const { isHotelItemExpanded, toggleHotelItemVisible } = useHotelItem();

  const { isRoadItemExpanded, toggleRoadItemVisible } = useRoadItem();

  const { isVehicleItemExpanded, toggleVehicleItemVisible } = useVehicleItem();
  const { isAdditionalInfoExpanded, toggleAdditionalInfoVisible } = useAdditionalInfo();

  const onCopyText = useCallback((value: string) => NavigatorUtils.copyToClipboard(value), [user]);

  const { mutateAsync: mutateCreateAssessment } = useAssessment({
    order,
    logTag: LOG_TAG,
    refetchOrder,
    refetchOrderHistory,
  });

  const onRequestApproval = useCallback(() => {
    dialogService.popAll();
    dialogService.showDialog(
      <ConfirmRequestApprovalDialog
        order={order}
        defaultData={DEFAULT_FORM_DATA}
        onSubmit={mutateCreateAssessment}
        onCloseClick={() => dialogService.popDialog()}
        customerId={customerId}
      />
    );
  }, [order, customerId, mutateCreateAssessment]);

  const { mutateAsync: onQuoteOfflineHotel } = useMutation(offlineHotelService.quote, {
    onMutate: () => {
      dialogService.showDialog(<LoadingDialog message="Cotizando item" />);
    },
    onSettled: () => {
      dialogService.popAll();
    },
    onSuccess: () => {
      refetchOrder?.();
      refetchOrderHistory?.();

      snackbarService.showSnackbar(SNACKBAR_MESSAGES.QUOTE_ITEM_SUCCESS_MESSAGE, "success");
    },
    onError: (error: { message: string }) => {
      logError({
        error,
        defaultErrorMessage: SNACKBAR_MESSAGES.QUOTE_ITEM_ERROR_MESSAGE,
        logTag: LOG_TAG,
      });
    },
  });

  const { mutateAsync: onReturnOrderStatus } = useMutation(orderService.returnOrderStatus, {
    onMutate: () => {
      dialogService.showDialog(<LoadingDialog message="Alterando status do pedido" />);
    },
    onSettled: () => {
      dialogService.popAll();
    },
    onSuccess: () => {
      refetchOrder?.();
      refetchOrderHistory?.();

      snackbarService.showSnackbar(SNACKBAR_MESSAGES.RETURN_ITEM_STATUS_SUCCESS_MESSAGE, "success");
    },
    onError: (error: { message: string }) => {
      logError({
        error,
        defaultErrorMessage: SNACKBAR_MESSAGES.RETURN_ITEM_STATUS_ERROR_MESSAGE,
        logTag: LOG_TAG,
      });
    },
  });

  const { mutateAsync: onIssueOrder } = useMutation(orderService.issueOrder, {
    onMutate: () => {
      dialogService.showDialog(<LoadingDialog message="Emitindo o pedido" />);
    },
    onSettled: () => {
      dialogService.popAll();
    },
    onSuccess: () => {
      refetchOrder?.();
      refetchOrderHistory?.();

      queryClient.invalidateQueries([QueryKeys.BUDGETS_VIEW, customerId]);
      snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUE_ORDER_SUCCESS_MESSAGE, "success");
    },
    onError: (error: { message: string }) => {
      logError({
        error,
        defaultErrorMessage: SNACKBAR_MESSAGES.ISSUE_ORDER_ERROR_MESSAGE,
        logTag: LOG_TAG,
      });
    },
  });

  const onOpenHotelOptionDetails = useCallback(
    (item: OrderHotelItem, option: OrderHotelOfflineOption) => {
      const defaultData = {
        options: [
          {
            ...option,
            checkIn: option?.checkIn ?? new Date(item.checkIn),
            checkOut: option?.checkOut ?? new Date(item.checkOut),
            regimen: { name: option.regimen },
            roomType: { name: option.roomType },
          },
        ],
      };

      const onSubmit = (data: QuoteOfflineHotelFormData) => {
        const body = {
          room: item,
          options: data.options.map((option) => ({
            uuid: option.uuid,
            description: option.description,
            address: option.address,
            phone: option.phone,
            email: option.email,
            providerValue: option.providerValue || 0,
            customerValue: option.customerValue || 0,
            hotelFee: option.hotelFee || 0,
            hotelMarkup: option.hotelMarkup || 0,
            obsIssuer: option.obsIssuer,
            obsAgency: option.obsAgency,
            paymentPix: option.paymentPix,
            paymentCreditCard: option.paymentCreditCard,
            regimen: option.regimen,
            roomType: option.roomType,
            amenities: option.amenities.map((amenity) => ({
              uuid: amenity.uuid,
              name: amenity.name,
            })),
            status: option.status,
            note: option.note,
            checkIn: option.checkIn || new Date(item.checkIn),
            checkOut: option.checkOut || new Date(item.checkOut),
            otherTaxes: option.otherTaxes,
          })),
        };

        onQuoteOfflineHotel(body);
      };

      dialogService.showDialog(
        <QuoteOfflineHotelForm
          order={order as Order}
          item={item}
          defaultData={defaultData}
          onSubmit={onSubmit}
        />
      );
    },
    [onQuoteOfflineHotel, order]
  );

  const onOpenRoadDetails = useCallback((data: OrderRoadSegment) => {
    const roadData = {
      ...data,
      company: data.companyName,
      from: data.departure,
      to: data.arrival,
    };

    const seats = data.travelers.reduce((seats, traveler) => {
      return seats.concat(traveler.seats.filter((s) => !s.isConnection).map((s) => s.seatNumber));
    }, [] as string[]);

    const seatsConnection = data.travelers.reduce((seats, traveler) => {
      return seats.concat(traveler.seats.filter((s) => s.isConnection).map((s) => s.seatNumber));
    }, [] as string[]);

    dialogService.showDialog(
      <RoadDetails data={roadData} seats={seats} seatsConnection={seatsConnection} />
    );
  }, []);

  const { mutate: onSendOfflineQuote } = useMutation(
    (orderId: string) => orderService.quote(orderId),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Enviando cotação" />);
      },
      onError: (error) => {
        dialogService.popDialog();

        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE,
        });
      },
      onSuccess: () => {
        dialogService.popDialog();
        queryClient.invalidateQueries([QueryKeys.ORDERS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE,
          "success",
          5000
        );
      },
    }
  );

  const { mutateAsync: onCancelOrder } = useMutation(
    async () => await orderService.cancel(order!.uuid),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Cancelando pedido" />);
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CANCEL_ORDER_ERROR_MESSAGE,
        });
        dialogService.popAll();
      },
    }
  );

  const canCancelOrder =
    !!order &&
    [OrderStatus.OPEN, OrderStatus.REJECTED, OrderStatus.CANCELING, OrderStatus.QUOTING].includes(
      order?.status as OrderStatus
    );

  useCancelOrderChannel({
    orderId: order?.uuid as string,
    enabled: canCancelOrder,
    onCancelOrder: (data) => {
      dialogService.popAll();

      refetchOrder();
      refetchOrderHistory();

      const message = data?.success ? "Pedido cancelado com sucesso" : "Falha ao cancelar pedido";

      snackbarService.showSnackbar(message, data?.success ? "success" : "error");
    },
  });

  const { mutate: onDeleteOfflineQuote } = useMutation(
    (optionId: string) => offlineHotelService.deleteOption(optionId),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Removendo cotação" />);
      },
      onError: (error) => {
        dialogService.popDialog();

        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.REMOVE_QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE,
        });
      },
      onSuccess: () => {
        dialogService.popDialog();
        queryClient.invalidateQueries([QueryKeys.ORDERS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.REMOVE_QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE,
          "success",
          5000
        );
      },
    }
  );

  return {
    order,
    isLoading: !order,
    isAirwayItemExpanded,
    isVehicleItemExpanded,
    isHotelItemExpanded,
    isRoadItemExpanded,
    isAdvanceItemExpanded,
    isAdditionalInfoExpanded,
    onIssueOrder,
    onReturnOrderStatus,
    onSendOfflineQuote,
    onDeleteOfflineQuote,
    onRequestApproval,
    onQuoteOfflineHotel,
    onOpenHotelOptionDetails,
    onOpenRoadDetails,
    toggleAdditionalInfoVisible,
    toggleAirwayItemVisible,
    toggleHotelItemVisible,
    toggleRoadItemVisible,
    toggleVehicleItemVisible,
    toggleAdvanceItemVisible,
    onOpenFlightDetails,
    onCancelOrder,
    onCopyText,
  };
}
