import { FlightServicesIncluded } from "~/application/types";
import { FlightBenefit } from "./types";

export function createFlightBenefits(servicesIncluded: FlightServicesIncluded): FlightBenefit[] {
  const {
    baggage,
    checkedBaggage,
    comfortSeat,
    refund,
    refundDescription,
    rescheduling,
    seatAssignment,
  } = servicesIncluded;

  return [
    {
      name: "Bagagens inclusas",
      isExists: !!baggage,
    },
    {
      name: checkedBaggage === 0
        ? "Bagagem despachada"
        : checkedBaggage === 1 ? `${checkedBaggage} bagagem despachada` : `${checkedBaggage} bagagens despachadas`,
      isExists: !!checkedBaggage,
    },
    {
      name: `Remarcação: ${rescheduling}`,
      isExists: true,
    },
    {
      name: `Reembolso: ${refundDescription}`,
      isExists: !!refund,
    },
    {
      name: "Assento conforto",
      isExists: !!comfortSeat,
    },
    {
      name: "Marcação de assento",
      isExists: !!seatAssignment,
    },
  ];
}
