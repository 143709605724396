import { useForm } from "react-hook-form";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { LoginContainer, LoginFormData } from "./LoginContainer";

const DEFAULT_FORM_DATA: LoginFormData = {
  keepSigned: true,
  email: "",
  password: "",
  securityKey: "",
};

export function LoginPage() {
  const { signIn } = useAuth();

  const { formState, control, handleSubmit } = useForm<LoginFormData>({
    defaultValues: DEFAULT_FORM_DATA,
  });

  return (
    <LoginContainer
      control={control}
      isSubmitting={formState.isSubmitting}
      onFormSubmit={handleSubmit(signIn)}
    />
  );
}
