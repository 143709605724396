import { Control } from "react-hook-form";
import { ChangeCustomerEmployeePasswordProps } from "~/application/usecases/CustomerEmployee/ICustomerEmployeeService";
import { BiztripLogo } from "~/components/BiztripLogo";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col } from "~/components/Grid";
import { PasswordInput, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { Label } from "~/components/Typography";

export interface ChangeCustomerEmployeePasswordContainerProps {
  control: Control<ChangeCustomerEmployeePasswordProps, any>;
  isSubmitting: boolean;
  showSecretKeyInput: boolean;
  onSubmit: () => void;
}

export function ChangePasswordContainer({
  isSubmitting,
  control,
  showSecretKeyInput,
  onSubmit,
}: ChangeCustomerEmployeePasswordContainerProps) {
  return (
    <Box css={{ height: "100vh" }}>
      <Box
        css={{
          position: "fixed",
          top: 0,
          bottom: 0,
          right: 0,
          width: "33%",
          backgroundColor: "$primary-base",
        }}
      />

      <Container>
        <Container size="5" css={{ m: 0 }}>
          <Flex justify="center" direction="column" css={{ height: "100vh" }}>
            <BiztripLogo size="lg" css={{ mb: "$6" }} />

            <Text as="h1" size="8" css={{ fw: "700", mb: "$1" }}>
              Alterar Senha
            </Text>

            <Form onSubmit={onSubmit}>
              <Flex direction="column">
                <Col sz="12" css={{ mt: "$4" }}>
                  <FormControl name="password" control={control} required>
                    <FieldLabel>Nova senha</FieldLabel>
                    <PasswordInput />
                  </FormControl>
                </Col>

                <Col sz="12" css={{ mt: "$4", mb: showSecretKeyInput ? 0 : "$4" }}>
                  <FormControl
                    name="confirmedPassword"
                    control={control}
                    required
                  >
                    <FieldLabel>Confirmação da nova senha</FieldLabel>
                    <PasswordInput />
                  </FormControl>
                </Col>

                {showSecretKeyInput && (
                  <Col sz="12" css={{ mt: "$4", mb: "$4" }}>
                    <FormControl name="secretKey" control={control} required>
                      <FieldLabel>Código de segurança</FieldLabel>
                      <TextInput />
                    </FormControl>
                    <Label size="3" css={{ display: "block", mt: "$2", lineHeight: 1.25 }}>
                      Insira o código de segurança gerado pelo aplicativo Google Authenticator para continuar
                    </Label>
                  </Col>
                )}

                <Button type="submit" disabled={isSubmitting}>
                  Alterar senha
                </Button>
              </Flex>
            </Form>
          </Flex>
        </Container>
      </Container>
    </Box>
  );
}
