import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type RowProps = ComponentProps<typeof Row>;

export const Row = styled("div", {
  boxSizing: "border-box",
  display: "flex",

  flexWrap: "wrap",
  marginRight: "calc(-0.5 * $$gutter-x)",
  marginLeft: "calc(-0.5 * $$gutter-x)",
  marginTop: "-$$gutter-y",

  "& > *": {
    flexShrink: 0,
    width: "100%",
    maxWidth: "100%",
    paddingRight: "calc($$gutter-x * 0.5)",
    paddingLeft: "calc($$gutter-x * 0.5)",
    marginTop: "$$gutter-y",
  },

  variants: {
    gap: {
      1: {
        "$$gutter-x": "$space$1",
        "$$gutter-y": "$space$1",
      },
      2: {
        "$$gutter-x": "$space$2",
        "$$gutter-y": "$space$2",
      },
      3: {
        "$$gutter-x": "$space$3",
        "$$gutter-y": "$space$3",
      },
      4: {
        "$$gutter-x": "$space$4",
        "$$gutter-y": "$space$4",
      },
      5: {
        "$$gutter-x": "$space$5",
        "$$gutter-y": "$space$5",
      },
      6: {
        "$$gutter-x": "$space$6",
        "$$gutter-y": "$space$6",
      },
      7: {
        "$$gutter-x": "$space$7",
        "$$gutter-y": "$space$7",
      },
      8: {
        "$$gutter-x": "$space$8",
        "$$gutter-y": "$space$8",
      },
      9: {
        "$$gutter-x": "$space$9",
        "$$gutter-y": "$space$9",
      },
      10: {
        "$$gutter-x": "$space$10",
        "$$gutter-y": "$space$10",
      },
    },
    gapX: {
      1: {
        "$$gutter-x": "$space$1",
      },
      2: {
        "$$gutter-x": "$space$2",
      },
      3: {
        "$$gutter-x": "$space$3",
      },
      4: {
        "$$gutter-x": "$space$4",
      },
      5: {
        "$$gutter-x": "$space$5",
      },
      6: {
        "$$gutter-x": "$space$6",
      },
      7: {
        "$$gutter-x": "$space$7",
      },
      8: {
        "$$gutter-x": "$space$8",
      },
      9: {
        "$$gutter-x": "$space$9",
      },
      10: {
        "$$gutter-x": "$space$10",
      },
    },
    gapY: {
      1: {
        "$$gutter-y": "$space$1",
      },
      2: {
        "$$gutter-y": "$space$2",
      },
      3: {
        "$$gutter-y": "$space$3",
      },
      4: {
        "$$gutter-y": "$space$4",
      },
      5: {
        "$$gutter-y": "$space$5",
      },
      6: {
        "$$gutter-y": "$space$6",
      },
      7: {
        "$$gutter-y": "$space$7",
      },
      8: {
        "$$gutter-y": "$space$8",
      },
      9: {
        "$$gutter-y": "$space$9",
      },
      10: {
        "$$gutter-y": "$space$10",
      },
    },
    align: {
      start: {
        alignItems: "flex-start",
      },
      center: {
        alignItems: "center",
      },
      end: {
        alignItems: "flex-end",
      },
      stretch: {
        alignItems: "stretch",
      },
      baseline: {
        alignItems: "baseline",
      },
    },
    justify: {
      start: {
        justifyContent: "flex-start",
      },
      center: {
        justifyContent: "center",
      },
      end: {
        justifyContent: "flex-end",
      },
      between: {
        justifyContent: "space-between",
      },
      evenly: {
        justifyContent: "space-evenly",
      },
    },
    wrap: {
      noWrap: {
        flexWrap: "nowrap",
      },
      wrap: {
        flexWrap: "wrap",
      },
      wrapReverse: {
        flexWrap: "wrap-reverse",
      },
    },
  },

  defaultVariants: {
    gapX: "6",
  },
});
