import { FC, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col } from "~/components/Grid";
import { NumberInput, Select, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { RoadIssuanceFormProps } from "./types";

export const RoadIssuanceForm: FC<RoadIssuanceFormProps> = ({
  control,
  orderRoad,
  providers,
  isLoading,
  connections,
  watchIssuances,
  formState,
  setValue,
  setError,
  clearErrors,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const { fields } = useFieldArray({
    control,
    name: "issuance.travelers",
  });

  const handleBpeChange = (travelerIndex: number, value: string) => {
    const bpeEqual = watchIssuances.travelers.some((traveler) => {
      return traveler.seats.some((seat) => seat.bpe === value);
    });

    setValue(
      `issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`,
      value
    );

    if (bpeEqual) {
      setError(`issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`, {
        type: "required",
        message: "Os links não podem ser iguais.",
      });
    } else if (value.trim() && value.match(/admin/)) {
      setError(`issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`, {
        type: "required",
        message: "Certifique-se de que o link não contenha 'admin'.",
      });
    } else if (value.trim() && !value.match(/^(http|https):\/\//)) {
      setError(`issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`, {
        type: "required",
        message: "Certifique-se de que o link comece com 'http'.",
      });
    } else {
      clearErrors(
        `issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`
      );
    }
  };

  const nextPage = () => {
    setCurrentPage(1);
  };

  const prevPage = () => {
    setCurrentPage(0);
  };

  const getConectionText = () => {
    if (!connections) return undefined;

    const { arrival, departure, connection } = connections;

    if (!departure || !connection || !arrival) return undefined;

    const [departureCity] = departure.split(", ");
    const [connectionCity] = connection.split(", ");
    const [arrivalCity] = arrival.split(", ");

    return {
      firstSection: [departureCity, connectionCity],
      secondSection: [connectionCity, arrivalCity],
    };
  };

  const conectionText = getConectionText();
  return (
    <Flex
      align="center"
      direction="column"
      css={{
        py: "$10",
        backgroundColor: "$neutrals-lightest",
      }}
      gap="4"
    >
      {conectionText && (
        <Flex gap="3">
          <Button
            onClick={prevPage}
            variant="secondary"
            css={{ opacity: currentPage ? 0.6 : 1 }}
          >
            {conectionText.firstSection[0]}
            {" -> "}
            {conectionText.firstSection[1]}
          </Button>

          <Button
            onClick={nextPage}
            variant="secondary"
            css={{ opacity: currentPage ? 1 : 0.6 }}
          >
            {conectionText.secondSection[0]}
            {" -> "}
            {conectionText.secondSection[1]}
          </Button>
        </Flex>
      )}
      <Flex as={Card} direction="column">
        {orderRoad.travelers.map((traveler, travelerIndex) => (
          <Flex
            as={CardBody}
            direction="column"
            gap="4"
            key={`${traveler.uuid}-${currentPage}`}
          >
            <Col>
              <FieldLabel>Passageiro</FieldLabel>
              <Flex
                as={Card}
                gap="4"
                align="center"
                css={{
                  p: "$4",
                  border: "2px solid $primary-base",
                }}
              >
                <Avatar src={traveler.avatarUrl} />
                <Text>{traveler.fullName}</Text>
              </Flex>
            </Col>

            <Col>
              <FormControl control={control} name={`issuance.tracker`}>
                <FieldLabel>Código da reserva</FieldLabel>
                <TextInput placeholder="Digite o código da reserva" />
              </FormControl>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.seats.${currentPage}.ticket`}
                required
              >
                <FieldLabel>Número do bilhete</FieldLabel>
                <TextInput placeholder="Digite o número do bilhete" />
              </FormControl>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`}
              >
                <FieldLabel>Link do bilhete</FieldLabel>
                <Flex direction="column" gap="3">
                  <TextInput
                    placeholder="Digite o link do bilhete"
                    onChange={(ev) =>
                      handleBpeChange(travelerIndex, ev.target.value)
                    }
                  />

                  <Text as={"p"} css={{ color: "$error-base" }}>
                    {formState?.errors?.issuance?.travelers?.[travelerIndex]
                      ?.seats?.[currentPage]?.bpe?.message ?? ""}
                  </Text>
                </Flex>
              </FormControl>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.provider`}
              >
                <FieldLabel>Fornecedor</FieldLabel>
                <Select
                  isLoading={isLoading}
                  placeholder="Selecione o fornecedor"
                  options={providers}
                  getOptionValue={(provider) => provider.uuid}
                  getOptionLabel={(provider) => provider.name}
                />
              </FormControl>
            </Col>
            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.seats.${currentPage}.seatNumber`}
              >
                <FieldLabel>Número do Assento</FieldLabel>
                <TextInput
                  maxLength={45}
                  placeholder="Digite o número do assento"
                />
              </FormControl>
            </Col>
            <Col>
              <Flex gap="2">
                <Col>
                  <FormControl
                    control={control}
                    name={`issuance.travelers.${travelerIndex}.value`}
                  >
                    <FieldLabel>Tarifa</FieldLabel>
                    <NumberInput prefix="R$ " maxLength={45} />
                  </FormControl>
                </Col>
                <Col>
                  <FormControl
                    control={control}
                    name={`issuance.travelers.${travelerIndex}.providerFees`}
                  >
                    <FieldLabel>Taxas e encargos</FieldLabel>
                    <NumberInput prefix="R$ " maxLength={45} />
                  </FormControl>
                </Col>
              </Flex>
            </Col>
            <hr style={{ width: "100%" }} />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

RoadIssuanceForm.displayName = "RoadIssuanceForm";
