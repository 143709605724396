import { Fragment, useContext } from "react";
import { Order, OrderMessage, OrderStatus, User, UserContext } from "~/application/types";
import { GroupedOrderHistory } from "~/application/types/entities/OrderHistory.type";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, CounterTabBadge, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { TabOrderMessages } from "~/presentation/shared/components/TabOrderMessages";
import { ORDER_MENU_TABS, OrderTab } from "~/presentation/shared/utils";
import { userIsIssuer as isUserIssuer } from "../../utils";
import { UseOrderItemsResult } from "./hooks/useOrderItems/types";
import { userIsOrderApprover } from "./utils";
import {
  TabAccountabilityExpenses,
  TabAccountabilityExpensesProps,
} from "./views/AccountabilityExpense/TabAccountabilityExpenses";
import { TabIssuerAccountabilityExpenses } from "./views/AccountabilityExpense/TabIssuerAccountabilityExpenses";
import {
  TabApproverOrderItems,
  TabApproverOrderItemsProps,
} from "./views/OrderItem/TabApproverOrderItems";
import { TabOrderItems } from "./views/OrderItem/TabOrderItems";
import { TabOrderHistory } from "./views/OrderItem/components/TabOrderHistory";
import { UseAccountabilityExpensesResult } from "./hooks/useAccountabilityExpenses";
import { ApprovalOrderProvider } from "./views/OrderItem/hooks/ApprovalOrderContext";
import {
  BrokenPolicyJustificationContext,
  BrokenPolicyJustificationProvider,
} from "./views/OrderItem/hooks/BrokenPolicyJustificationContext";

export interface OrderContainerProps {
  user: User;
  order?: Order;
  isLoading: boolean;
  isLoadingOrderMessages: boolean;
  orderMessages: OrderMessage[];
  isLoadingOrderHistory: boolean;
  orderHistory: GroupedOrderHistory[];
  activeTab: OrderTab;
  orderItemsState: UseOrderItemsResult;
  accountabilityExpensesState: UseAccountabilityExpensesResult;
}

export function OrderContainer({
  user,
  order,
  isLoadingOrderHistory,
  isLoadingOrderMessages,
  isLoading,
  activeTab,
  orderItemsState,
  orderMessages,
  orderHistory,
  accountabilityExpensesState,
}: OrderContainerProps) {
  const orderStatus = order?.status as OrderStatus;
  const userIsAgencyEmployee = user.context === UserContext.Agency;
  const userIsApprover = userIsOrderApprover(order);
  const userIsIssuer = isUserIssuer(user, order);
  const userIsValidAgencyEmployee = user.agency?.uuid === order?.customer.agencyId;

  const menuTabs = ({ id, title }: { id: string; title: string }) => {
    const counterTab = {
      [OrderTab.ITEMS]: (
        <CounterTabBadge>
          <Text>{order?.itemsIncluded.length}</Text>
        </CounterTabBadge>
      ),
      [OrderTab.OBSERVATIONS]: (
        <CounterTabBadge>
          <Text>{orderMessages.length}</Text>
        </CounterTabBadge>
      ),
      [OrderTab.HISTORY]: (
        <CounterTabBadge>
          <Text>
            {Object.values(orderHistory).reduce((acc, { history }) => acc + history.length, 0)}
          </Text>
        </CounterTabBadge>
      ),
      [OrderTab.ACCOUNTABILITY_EXPENSES]: (
        <CounterTabBadge>
          <Text>{accountabilityExpensesState.data?.length ?? 0}</Text>
        </CounterTabBadge>
      ),
    };

    return (
      <Link to={`#${id}`} title={title} key={id}>
        <CounterTab id={id} value={id}>
          <Text>{title}</Text>
          {counterTab[id as keyof typeof counterTab]}
        </CounterTab>
      </Link>
    );
  };
  const { onCreateAccountabilityExpense } = accountabilityExpensesState;

  const tabAccountabilityExpensesProps = {
    ...accountabilityExpensesState,
    order,
    isLoading,
    userIsIssuer,
  } as TabAccountabilityExpensesProps;

  const location = window.location.href;

  const status = window.location.hash.slice(1);

  const orderMessagesProps = {
    isLoadingOrderMessages,
    orderMessages,
  };

  const orderHistoryProps = { order, isLoadingOrderHistory, orderHistory };

  const getGoBackLink = () => {
    if (userIsIssuer && location.includes("viajantes")) {
      return `/pedidos/${order?.uuid}#despesas`;
    }

    const statuses = {
      [OrderStatus.QUOTING]: "/pedidos/meus-pedidos#open",
      [OrderStatus.CANCELING]: "/pedidos/meus-pedidos#issued",
    };

    if (orderStatus in statuses) {
      return statuses[orderStatus as keyof typeof statuses];
    }

    return `/pedidos/meus-pedidos#${status}`;
  };

  const branchName = order?.approvalModel?.approvalRequests?.branchName;

  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar id="app-bar-header">
          <AppBarContainer>
            <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
              <Link to={getGoBackLink()}>
                <Icon
                  fill="#000"
                  css={{
                    "@mxmd": {
                      fill: "#FFF",
                    },
                  }}
                  as={SvgChevronLeft}
                />
              </Link>
            </Tooltip>

            <AppBarHeading
              title={
                isLoading ? (
                  <Skeleton variant="text-6" style={{ maxWidth: 360 }} />
                ) : (
                  `Pedido #${order?.orderNumber} ${branchName ? `- ${branchName}` : ""} ${
                    userIsAgencyEmployee ? ` - ${order?.customer.name}` : ""
                  }`
                )
              }
            />

            <AppBarActions>
              {!userIsIssuer &&
                activeTab === OrderTab.ACCOUNTABILITY_EXPENSES &&
                [OrderStatus.ISSUED]?.includes(orderStatus) && (
                  <Button onClick={() => onCreateAccountabilityExpense()} variant="primary">
                    <Text>Adicionar nova despesaa</Text>
                  </Button>
                )}
            </AppBarActions>
          </AppBarContainer>

          <Container
            fixed
            css={{
              width: "100%",
              "@mxmd": {
                p: 0,
              },
            }}
          >
            <TabBar css={{ display: "flex", alignItems: "flex-start" }}>
              <Flex
                css={{
                  "@mxmd": {
                    overflowX: "auto",
                  },
                }}
              >
                {isLoading || !order ? (
                  <Flex
                    gap="8"
                    css={{
                      "@mxmd": {
                        p: 5,
                      },
                    }}
                  >
                    <Skeleton variant="text-4" style={{ width: 96 }} />
                    <Skeleton variant="text-4" style={{ width: 96 }} />
                  </Flex>
                ) : (
                  ORDER_MENU_TABS.map(({ title, id }) => {
                    if (id === OrderTab.HISTORY && !userIsIssuer && !userIsApprover) {
                      return;
                    }

                    return menuTabs({ id, title });
                  })
                )}
              </Flex>
            </TabBar>
          </Container>
        </AppBar>

        {!isLoading && (
          <Container css={{ p: 15, mt: 20 }}>
            <TabContent value={OrderTab.ITEMS}>
              <ApprovalOrderProvider
                refetchOrder={orderItemsState.refetchOrder}
                refetchOrderHistory={orderItemsState.refetchOrderHistory}
              >
                {userIsIssuer || userIsValidAgencyEmployee ? (
                  <TabOrderItems {...orderItemsState} />
                ) : (
                  <TabApproverOrderItems {...(orderItemsState as TabApproverOrderItemsProps)} />
                )}
              </ApprovalOrderProvider>
            </TabContent>

            <TabContent value={OrderTab.ACCOUNTABILITY_EXPENSES}>
              {userIsIssuer ? (
                <TabIssuerAccountabilityExpenses order={order} />
              ) : (
                <TabAccountabilityExpenses {...tabAccountabilityExpensesProps} />
              )}
            </TabContent>

            <TabContent value={OrderTab.OBSERVATIONS}>
              <TabOrderMessages {...orderMessagesProps} />
            </TabContent>

            <TabContent value={OrderTab.HISTORY}>
              <TabOrderHistory {...orderHistoryProps} />
            </TabContent>
          </Container>
        )}
      </Tabs>
    </Fragment>
  );
}
