import { useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { DeepPartial, ExpensePolicyTypeValue } from "~/application/types";
import {
  ExpenseCategory,
  ExpensePolicy,
  ExpenseType,
  PolicyParameterExpense,
} from "~/application/types";
import { Policy } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import {
  ContainedRadio,
  NumberInput,
  Select,
  Switch,
} from "~/components/Input";
import { Text } from "~/components/Text";
import { Caption, H4 } from "~/components/Typography";
import { QueryKeys } from "~/constants/queryKeys";
import { EXPENSE_POLICY_RULE_TYPES } from "../../../constants";

const expensePolicySchema = yup.object().shape({
  policy: yup.object().shape({
    uuid: yup.string().required("A política afetada é obrigatória"),
    name: yup.string().nullable(),
  }),
  expenseCategory: yup.object().shape({
    uuid: yup.string().required("A categoria de despesa é obrigatória"),
    name: yup.string().nullable(),
  }),
  expenseType: yup.object().shape({
    uuid: yup.string().required("O tipo de despesa é obrigatório"),
    name: yup.string().nullable(),
  }),
  voucherRequired: yup.boolean().default(false),
  policyParameterExpense: yup.object().shape({
    uuid: yup.string().required("O tipo de regra é obrigatório"),
    description: yup.string().nullable(),
    type: yup.string().nullable(),
  }),
  typeValue: yup
  .string()
  .oneOf(Object.values(ExpensePolicyTypeValue), "Tipo de controle inválido")
  .required("O tipo de controle é obrigatório"),
  value: yup.number().required("O valor é obrigatório"),
});

type ExpensePolicySchema = yup.InferType<typeof expensePolicySchema>;

export interface ExpensePolicyDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<ExpensePolicy>;
  onCloseClick?: () => void;
  onSubmit: (data: ExpensePolicy) => void;
  fetchExpenseCategories: () => Promise<ExpenseCategory[]>;
  fetchExpenseTypes: () => Promise<ExpenseType[]>;
  fetchPolicies: () => Promise<Policy[]>;
  fetchPolicyParameterExpenses: () => Promise<PolicyParameterExpense[]>;
}

export function ExpensePolicyDialog({
  isNew,
  defaultData,
  onCloseClick,
  fetchExpenseCategories,
  fetchPolicies,
  fetchExpenseTypes,
  fetchPolicyParameterExpenses,
  onSubmit,
}: ExpensePolicyDialogProps) {
  const { control, formState, handleSubmit, watch, resetField, setValue } =
    useForm<ExpensePolicySchema>({
      defaultValues: defaultData,
      resolver: yupResolver(expensePolicySchema),
    });

  const { expenseCategory, policyParameterExpense } = watch();

  const { data: expenseCategories, isFetching: isFetchingExpenseCategories } =
    useQuery([QueryKeys.EXPENSE_CATEGORIES], fetchExpenseCategories, {
      cacheTime: 0,
      retry: 2,
    });

  const { data: expenseTypes, isFetching: isFetchingExpenseTypes } = useQuery(
    [QueryKeys.CUSTOMER_EXPENSE_TYPES],
    fetchExpenseTypes,
    {
      cacheTime: 0,
      retry: 2,
      enabled: !!expenseCategory,
    }
  );

  const {
    data: policyParameterExpenses,
    isFetching: isFetchingPolicyParameterExpenses,
  } = useQuery(
    [QueryKeys.POLICY_PARAMETER_EXPENSES],
    fetchPolicyParameterExpenses,
    {
      cacheTime: 0,
      retry: 2,
      onSuccess: (data) => {
        if (!policyParameterExpense) {
          setValue("policyParameterExpense", data[0]);
        }
      },
    }
  );

  const { data: policies, isFetching: isFetchingPolicies } = useQuery(
    [QueryKeys.CUSTOMER_POLICIES],
    fetchPolicies,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  useEffect(() => {
    resetField("typeValue");
  }, [policyParameterExpense]);

  const getExpenseCategoryLabel = useCallback(
    (item: ExpenseCategory) => item.name,
    []
  );
  const getExpenseCategoryValue = useCallback(
    (item: ExpenseCategory) => item.uuid,
    []
  );

  const getPolicyLabel = useCallback((item: Policy) => item.name, []);
  const getPolicyValue = useCallback((item: Policy) => item.uuid, []);

  const getExpenseTypeLabel = useCallback((item: ExpenseType) => item.name, []);
  const getExpenseTypeValue = useCallback((item: ExpenseType) => item.uuid, []);

  const getPolicyParameterExpenseLabel = useCallback(
    (item: PolicyParameterExpense) => item.description,
    []
  );
  const getPolicyParameterExpenseValue = useCallback(
    (item: PolicyParameterExpense) => item.uuid,
    []
  );

  return (
    <Container size="8" fixed>
      <Form
        onSubmit={handleSubmit((data) => {
          return onSubmit(data as ExpensePolicy)
        })}
      >
        <FormDialog
          title={isNew ? "Nova regra" : "Editar regra"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button 
              disabled={
                formState.isSubmitting 
                || isFetchingExpenseCategories 
                || isFetchingExpenseTypes 
                || isFetchingPolicies
                || isFetchingPolicyParameterExpenses
              } 
              type="submit"
            >
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Box css={{ mb: "$6" }}>
              <H4>Detalhes da regra</H4>
            </Box>

            <Row gap="6">
              <Col sz="12">
                <FormControl name="policy" control={control} required>
                  <FieldLabel>Politica afetada</FieldLabel>
                  <Select
                    options={policies}
                    isLoading={isFetchingPolicies}
                    placeholder="Selecione a política afetada"
                    getOptionLabel={getPolicyLabel}
                    getOptionValue={getPolicyValue}
                  />
                  {formState.errors.policy?.uuid && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.policy.uuid.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="expenseCategory" control={control} required>
                  <FieldLabel>Categoria de despesa</FieldLabel>
                  <Select
                    options={expenseCategories}
                    isLoading={isFetchingExpenseCategories}
                    placeholder="Selecione a categoria de despesa"
                    getOptionLabel={getExpenseCategoryLabel}
                    getOptionValue={getExpenseCategoryValue}
                  />
                  {formState.errors.expenseCategory?.uuid && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.expenseCategory.uuid.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="expenseType" control={control} required>
                  <FieldLabel>Tipo de despesa</FieldLabel>
                  <Select
                    options={expenseTypes}
                    isLoading={isFetchingExpenseTypes}
                    placeholder="Selecione o tipo de despesa"
                    getOptionLabel={getExpenseTypeLabel}
                    getOptionValue={getExpenseTypeValue}
                  />
                  {formState.errors.expenseType?.uuid && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.expenseType.uuid.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="voucherRequired" control={control}>
                  <Switch>
                    <Caption>Comprovante obrigatório</Caption>
                  </Switch>
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl
                  name="policyParameterExpense"
                  control={control}
                  required
                >
                  <FieldLabel>Tipo de regra</FieldLabel>
                  <Select
                    options={policyParameterExpenses}
                    isLoading={isFetchingPolicyParameterExpenses}
                    placeholder="Selecione o tipo de regra"
                    getOptionLabel={getPolicyParameterExpenseLabel}
                    getOptionValue={getPolicyParameterExpenseValue}
                  />
                  {formState.errors.policyParameterExpense?.uuid && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.policyParameterExpense.uuid.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Box css={{ mb: "$6", mt: "$10" }}>
              <H4>Controle da regra</H4>
            </Box>

            <FormControl name="typeValue" control={control} required>
              <FieldLabel>Tipo de controle</FieldLabel>
              <Flex gap="2" direction="column">
                {EXPENSE_POLICY_RULE_TYPES.map((rule) => (
                  <ContainedRadio
                    value={rule.value}
                    disabled={rule.ruleType !== policyParameterExpense?.type}
                    key={rule.value}
                  >
                    <Flex direction="column" gap="2">
                      <Text size="3" css={{ fw: "700" }}>
                        {rule.name}
                      </Text>
                      <Text size="2" css={{ color: "$neutrals-dark" }}>
                        {rule.description}
                      </Text>
                    </Flex>
                  </ContainedRadio>
                ))}
              </Flex>
              {formState.errors.typeValue && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {formState.errors.typeValue.message}
                </Text>
              )}
            </FormControl>

            <Box css={{ mb: "$6", mt: "$10" }}>
              <H4>Valores da regra</H4>
            </Box>

            <Row>
              <Col sz="12">
                {policyParameterExpense?.type === "amount" ? (
                  <FormControl name="value" control={control} required>
                    <FieldLabel>Quantidade limite diária</FieldLabel>
                    <NumberInput
                      placeholder="Selecione o tipo de despesa"
                      decimalScale={0}
                    />
                    {formState.errors.value && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.value.message}
                      </Text>
                    )}
                  </FormControl>
                ) : (
                  <FormControl name="value" control={control} required>
                    <FieldLabel>Valor</FieldLabel>
                    <NumberInput placeholder="R$ 200,00" prefix="R$ " />
                    {formState.errors.value && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.value.message}
                      </Text>
                    )}
                  </FormControl>
                )}
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
