import { useState } from "react";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import {
  SvgChevronDown,
  SvgChevronUp,
  SvgNotificationsActive,
} from "~/components/Icon/icons";
import { Text } from "~/components/Text";

type IssueNotificationButtonProps = {
  onIssuerNotify: () => void;
  isNotified: boolean;
  isNotifying: boolean;
  css?: any;
};

export const IssueNotifyButton = ({
  onIssuerNotify,
  isNotified,
  isNotifying,
  css,
}: IssueNotificationButtonProps) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      {isNotified ? (
        <Flex
          justify="start"
          align="center"
          css={{
            position: "relative",
            ...css,
          }}
        >
          <Flex
            onClick={(e) => {
              e.stopPropagation();
            }}
            css={{
              backgroundColor: "rgba(255, 237, 216, 0.3)",
              border: "solid 1px $warning-base",
              borderRadius: " $md 0 0 $md ",
              p: "$3",
              color: "#e3b076",
              cursor: "default",
            }}
          >
            <Text size="4">Notificado</Text>
          </Flex>
          <Flex
            onClick={(e) => {
              e.stopPropagation(), setIsOpened(!isOpened);
            }}
            css={{
              fill: "$warning-dark",
              p: "$3",
              backgroundColor: "rgba(255, 237, 216, 1)",
              borderRadius: " 0 $md $md 0",
              border: "solid 1px $warning-dark",
              cursor: "pointer",
            }}
          >
            <Icon as={isOpened ? SvgChevronDown : SvgChevronUp} size="sm" />
          </Flex>
          {isOpened && (
            <Flex
              gap="2"
              onClick={(e) => {
                e.stopPropagation(), setIsOpened(false), onIssuerNotify();
              }}
              justify="center"
              align="center"
              css={{
                position: "absolute",
                top: "105%",
                right: "0",
                borderRadius: "$md",
                backgroundColor: "white",
                color: "$neutrals-dark",
                zIndex: 999,
                p: "$2",
                border: "solid 1px $neutrals-base",
                width: "120%",
                cursor: "pointer",

                "&:hover": {
                  color: "$neutrals-light",
                  fill: "$neutrals-light",
                },
              }}
            >
              <Icon
                as={SvgNotificationsActive}
                fill="$neutrals-base"
                size="sm"
              />
              <Text
                as="p"
                css={{
                  fontSize: "14px",
                }}
              >
                Notificar Solicitante
              </Text>
            </Flex>
          )}
        </Flex>
      ) : (
        <Button
          onClick={(e) => {
            e.stopPropagation(), onIssuerNotify();
          }}
          variant="warning"
          disabled={isNotifying}
          css={css}
        >
          Notificar Solicitante
        </Button>
      )}
    </>
  );
};
