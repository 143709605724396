import { Order } from "~/application/types";

export const LOG_TAG = "Agency/AgencyOrdersPage";

export const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar pedidos",
  ISSUER_SUCCESS_NOTIFY: "Solicitante notificado com sucesso",
  ISSUER_ERROR_NOTIFY: "Falha ao notificar o solicitante",
} as const;

export interface GroupedAgencyOrder {
  timestamp: number;
  label: string;
  items: Order[];
}
