import { OrderHotel } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { OrderHotelDTO } from "../dtos";
import { mapTravelerDTO } from "./mapTravelerDTO";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapOrderHotelDTO(data: OrderHotelDTO): OrderHotel {
  return {
    uuid: data.uuid,
    orderId: data.order_uuid,
    agreementId: data.agreement_uuid,
    hotelDescription: data.hotel_description,
    roomDescription: data.room_description,
    cityName: data.city_name,
    checkIn: toDate(data.checkin_date),
    checkOut: toDate(data.checkout_date),
    dailyAmount: data.daily_amount,
    roomMode: data.room_mode,
    roomRegimen: data.room_regimen,
    price: parseFloat(data.value),
    justificationId: data.justification_uuid,
    tracker: data.tracker,
    status: data.status,
    failedDescription: data.failed_description,
    priceFee: parseFloat(data.fee),
    markup: data.markup,
    searchKey: data.search_key,
    guests: data.guests.map(mapTravelerDTO),
    reasonRejected: data.reason_rejected,
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
  };
}
