import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { CreateOfflineHotelContainer } from "./CreateOfflineHotelContainer";
import { stateService } from "~/application/usecases";
import { OfflineHotel, State } from "~/application/types";
import { CreateOfflineHotelProps, offlineHotelService } from "~/application/usecases/OfflineHotel";
import { queryClient } from "~/services/queryClient";
import { QueryKeys } from "~/application/constants";
import { snackbarService } from "~/components/SnackbarStack";
import { logError } from "~/presentation/shared/utils/errors";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";

const LOG_TAG = "Agency/CreateOfflineHotelPage";

const SNACKBAR_MESSAGES = {
  CREATE_SUCCESS_MESSAGE: "Hotel offline criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar hotel offline",
} as const;

const DEFAULT_FORM_DATA = {
  description: "",
  cnpj: "",
  integrationCode: "",
  obs: "",
  email: "",
  site: "",
  address: "",
  district: "",
  whatsapp: "+55",
  phone: "+55",
} as OfflineHotel;

export function CreateOfflineHotelPage() {
  const navigate = useNavigate();
  const { user } = useUser();

  const fetchCitiesByState = useCallback(
    (state: State) =>
      stateService
        .findCities(state)
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const { mutate: createOfflineHotel, isLoading: isCreatingOfflineHotel } = useMutation(
    ({ data, image, images }: Omit<CreateOfflineHotelProps, "agencyId">) => {
      return offlineHotelService.create({ agencyId: user.agency.uuid, data, image, images });
    },
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando hotel offline" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.OFFLINE_HOTELS]);

        navigate(`/configuracoes/hoteis-offline`);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  return (
    <CreateOfflineHotelContainer 
      defaultData={DEFAULT_FORM_DATA}
      fetchCitiesByState={fetchCitiesByState}
      createOfflineHotel={createOfflineHotel}
      isCreatingOfflineHotel={isCreatingOfflineHotel}
    />
  );
}