import { FC } from "react";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col } from "~/components/Grid";
import { TextInput } from "~/components/Input";
import { Flex } from "~/components/Flex";
import { VehicleIssuanceFormProps } from "./types";

export const VehicleIssuanceForm: FC<VehicleIssuanceFormProps> = ({
  control,
}) => {
  return (
    <Flex
      align="center"
      direction="column"
      css={{
        py: "$10",
        backgroundColor: "$neutrals-lightest",
      }}
      gap="4"
    >
      <Col>
        <FormControl control={control} name={`tracker`} required>
          <FieldLabel>Código da reserva</FieldLabel>
          <TextInput placeholder="Digite o código da reserva" />
        </FormControl>
      </Col>
    </Flex>
  );
};

VehicleIssuanceForm.displayName = "VehicleIssuanceForm";
