import * as React from "react";
import { SVGProps } from "react";

const SvgSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.317 1.433a.625.625 0 1 0-.884.884l3.308 3.308H3.75a.625.625 0 1 0 0 1.25h10.991l-3.308 3.308a.625.625 0 1 0 .884.884l4.375-4.375a.625.625 0 0 0 0-.884l-4.375-4.375Z" />
    <path d="M8.567 8.933a.625.625 0 0 1 0 .884l-3.308 3.308H16.25a.625.625 0 1 1 0 1.25H5.259l3.308 3.308a.625.625 0 1 1-.884.884l-4.375-4.375a.625.625 0 0 1 0-.884l4.375-4.375a.625.625 0 0 1 .884 0Z" />
  </svg>
);

export default SvgSwap;
