import queryString from "query-string";
import type { Branch, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { BranchDTO } from "~/infrastructure/api/dtos";
import { mapBranchDTO } from "~/infrastructure/api/mappers";
import type { IBranchService, IFindBranchParams } from "./IBranchService";

export class BranchService implements IBranchService {
  async find({
    name,
    page = 1,
    customerId,
  }: IFindBranchParams): Promise<PaginatedResource<Branch[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/branches`,
      query: { page, name },
    });

    return await api
      .get<PaginatedResource<BranchDTO[]>>(url)
      .then(({ data }) => ({
        links: data.links,
        meta: data.meta,
        data: data.data.map(mapBranchDTO),
      }));
  }

  async create(data: Branch): Promise<Branch> {
    return await api
      .post<BranchDTO>(`customers/${data.customerId}/branches`, {
        name: data.name,
        cnpj: data.cnpj.replace(/\D/g, ""),
        email: data.email,
        phone: data.phone.replace(/\D/g, ""),
        integration_id: data.integrationId,
      })
      .then(({ data }) => mapBranchDTO(data));
  }

  async updateById(data: Branch): Promise<Branch> {
    return await api
      .put<BranchDTO>(`customers/branches/${data.uuid}`, {
        name: data.name,
        cnpj: data.cnpj.replace(/\D/g, ""),
        email: data.email,
        phone: data.phone.replace(/\D/g, ""),
        integration_id: data.integrationId,
      })
      .then(({ data }) => mapBranchDTO(data));
  }

  async toggleActive(branch: Branch): Promise<void> {
    return branch.isActive ? this.inactivate(branch) : this.activate(branch);
  }

  private async inactivate({ uuid }: Branch): Promise<void> {
    await api.patch<void>(`/customers/branches/${uuid}/inactivate`);
  }

  private async activate({ uuid }: Branch): Promise<void> {
    await api.patch<void>(`/customers/branches/${uuid}/activate`);
  }
}
