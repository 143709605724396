import { ApprovalModel, Approver, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { ApprovalModelDTO, ApproverDTO, UpdateApprovalModelDTO } from "~/infrastructure/api/dtos";
import { mapApprovalModelDTO, mapApproverDTO } from "~/infrastructure/api/mappers";
import {
  IAddApproversParams,
  IApprovalModelService,
  ICreateApprovalModelParams,
  IFindApprovalModelParams,
  ILinkingNewCostCenterData,
} from "./IApprovalModelService";
import queryString from "query-string";

export class ApprovalModelService implements IApprovalModelService {
  async find({
    name,
    page,
    active,
    customerId,
    approvers_uuid,
  }: IFindApprovalModelParams): Promise<PaginatedResource<ApprovalModel[]>> {
    const url = queryString.stringifyUrl({
      url: `customers/${customerId}/approval-models`,
      query: {
        page,
        name,
        active,
        approvers_uuid,
      },
    }, {
      arrayFormat: "bracket"
    });

    return await api
      .get<PaginatedResource<ApprovalModelDTO[]>>(url)
      .then(({ data: { data, links, meta } }) => ({
        data: data.map(mapApprovalModelDTO),
        links,
        meta,
      }));
  }

  async findById(id: string): Promise<ApprovalModel> {
    return await api
      .get<{ data: ApprovalModelDTO }>(`/customers/approval-models/${id}`)
      .then(({ data }) => mapApprovalModelDTO(data.data));
  }

  async create({ customerId, data }: ICreateApprovalModelParams): Promise<ApprovalModel> {
    const url = `/customers/${customerId}/approval-models`;
    return await api
      .post<PaginatedResource<ApprovalModelDTO>>(url, data)
      .then(({ data: response }) => mapApprovalModelDTO(response.data));
  }

  async update(data: UpdateApprovalModelDTO): Promise<ApprovalModel> {
    return await api
      .put<ApprovalModelDTO>(`customers/approval-models/${data.uuid}`, data)
      .then(({ data }) => mapApprovalModelDTO(data));
  }

  async addApprover({
    uuid,
    selfApprover,
    level,
    approvalModelId,
  }: Approver & { approvalModelId: string }): Promise<Approver> {
    const url = `/customers/approval-models/${approvalModelId}/approvers`;
    const body = {
      approval_model_uuid: approvalModelId,
      self_approver: !!selfApprover,
      approver_uuid: uuid,
      level: level,
    };

    return api.post<ApproverDTO>(url, body).then(({ data }) => mapApproverDTO(data));
  }

  async linkingNewCostCenter({
    costCenter,
    approvalModelId,
  }: ILinkingNewCostCenterData): Promise<void> {
    const body = {
      cost_center_uuid: costCenter.uuid,
    };

    await api.patch<void>(`customers/approval-models/${approvalModelId}/cost-centers`, body);
  }

  async addApprovers({ approvers, approvalModelId }: IAddApproversParams): Promise<Approver[]> {
    return await Promise.all(
      approvers.map(async (approver) => {
        return this.addApprover({ ...approver, approvalModelId });
      })
    );
  }

  async updateApprover({
    uuid,
    selfApprover,
    level,
    approvalModelId,
  }: Approver & { approvalModelId: string }): Promise<Approver> {
    const url = `/customers/approval-models/${approvalModelId}/approvers/${uuid}`;
    return api
      .put<ApproverDTO>(url, { self_approver: selfApprover, level })
      .then(({ data }) => mapApproverDTO(data));
  }

  async updateApprovers(
    approvers: (Approver & { approvalModelId: string })[]
  ): Promise<Approver[]> {
    return await Promise.all(approvers.map(async (approver) => this.updateApprover(approver)));
  }

  async inactivateApprover(approverModelId: string): Promise<void> {
    const url = `/customers/approval-models/approver-models/${approverModelId}/inactive`;
    return api.patch(url).then(({ data }) => data);
  }

  async toggleActive(data: ApprovalModel): Promise<void> {
    return data.isActive ? this.inactivate(data) : this.activate(data);
  }

  private async activate({ uuid }: ApprovalModel): Promise<void> {
    await api.patch<void>(`/customers/approval-models/${uuid}/active`);
  }

  private async inactivate({ uuid }: ApprovalModel): Promise<void> {
    await api.patch<void>(`/customers/approval-models/${uuid}/inactive`);
  }
}
