import { CSS } from "@stitches/react";

export const itemStyle: CSS = {
  // Reset
  all: "unset",
  boxSizing: "border-box",

  // Custom
  position: "relative",
  padding: "$4",
  borderRadius: "$md",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  fontSize: "$sm",
  fontWeight: 500,
  color: "$neutrals-darkest",
  transition: "$normal",
  gap: "$2",

  "&:hover": {
    backgroundColor: "$neutrals-lightest",
    color: "$neutrals-black",
    cursor: "pointer",
  },

  "&[data-active=true]": {
    backgroundColor: "$primary-light",
    color: "$primary-dark",
  },

  "&[data-disabled=true]": {
    pointerEvents: "none",
  },

  "&[data-highlighted]": {},
};
