import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { orderService } from "~/application/usecases";
import { log } from "~/utils/log";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { BookingContext } from "./contexts/BookingContext";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { summaryService } from "~/application/usecases/Summary";
import { QueryTimes } from "~/constants";
import { logError } from "~/presentation/shared/utils/errors";

const LOG_TAG = "Booking/BookingContainer";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar pedido",
  LOAD_SUMMARY_ERROR_MESSAGE: "Falha ao carregar Próximas viagens",
} as const;
  
export function BookingContainer() {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const path = location.pathname;
  const hasResults = path.includes("/resultados");

  const orderId = searchParams.get("orderId");
  const { user, contexts } = useUser();

  const customerId = contexts?.customer?.uuid;

  const { data: order, isFetching: isLoadingOrder } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      staleTime: QueryTimes.LONGEST,
      refetchOnWindowFocus: false,
      enabled: !!orderId,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: summary, isFetching: isFetchingSummary } = useQuery(
    [QueryKeys.SUMMARY, user?.customer?.uuid, contexts?.customer?.uuid],
    () =>
      summaryService.findById(user?.customer?.uuid ?? contexts?.customer?.uuid),
    {
      staleTime: QueryTimes.LONGEST,
      enabled: !!customerId && !hasResults,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_SUMMARY_ERROR_MESSAGE,
        });
      },
    }
  );

  return (
    <BookingContext.Provider
      value={{ order, isLoadingOrder, summary, isFetchingSummary }}
    >
      <Outlet />
    </BookingContext.Provider>
  );
}
