import * as React from "react";
import { SVGProps } from "react";

const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M4.375 1.875c-.331 0-.65.131-.885.365a1.252 1.252 0 0 0-.365.885v13.75a1.252 1.252 0 0 0 1.25 1.25h11.25c.33 0 .649-.132.883-.365v-.002c.233-.234.367-.552.367-.883v-10a.625.625 0 0 0-.047-.238.625.625 0 0 0-.137-.203l-4.375-4.375a.625.625 0 0 0-.203-.137.625.625 0 0 0-.238-.047Zm0 1.25h6.875v3.75a.625.625 0 0 0 .625.625h3.75v9.375H4.375Zm8.125.883 2.242 2.242H12.5Z"
    />
  </svg>
);

export default SvgFile;
