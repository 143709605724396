import dayjs from "dayjs";
import pt from "dayjs/locale/pt";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.locale({
  ...pt,
  weekStart: 0,
});

export { dayjs };
