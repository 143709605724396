import { Fragment } from "react";
import {
  ApiErrorCode,
  Order,
  OrderAirwayTracker,
  OrderItems,
  OrderStatus,
} from "~/application/types";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { Col, Row } from "~/components/Grid";
import { SvgError } from "~/components/Icon/icons";
import { H4 } from "~/components/Typography";
import { UseOrderItemsResult } from "../../hooks/useOrderItems/types";
import { ApproverOrderItems } from "./ApproverOrderItems";
import { ApproverOrderCart } from "./components/OrderCart";

export type TabApproverOrderItemsProps = UseOrderItemsResult & {
  order?: Order;
  isLoading: boolean;
  itemsAvailableToAdd: OrderItems[];
  isRebookingRoad: boolean;
  isRoadRebookError: boolean;
  isSuccessfulRoadRebooking: boolean;
  onAddOrderAdvance: () => void;
  onSendVoucherInWhatsapp: () => void;
  onRequestApproval?: () => void;
  onRequestOrderCancellation?: () => void;
  onReloadAirwayTracker: (itemId: string) => Promise<OrderAirwayTracker | ApiErrorCode>;
};

export function TabApproverOrderItems({
  order,
  onAddOrderAdvance,
  itemsAvailableToAdd,
  onRequestApproval,
  onRequestOrderCancellation,
  onSendVoucherInWhatsapp,
  isRebookingRoad,
  isRoadRebookError,
  isSuccessfulRoadRebooking,
  onNotifyIssuerThatOrderExpired,
  onCancelOrder,
  isIssuingOrder,
  ...props
}: TabApproverOrderItemsProps) {
  const approverOrderCartProps = {
    order,
    isIssuingOrder,
    isLoading: props.isLoading,
    isRoadRebookError,
    isRebookingRoad,
    isSuccessfulRoadRebooking,
    onSendVoucherInWhatsapp,
    onNotifyIssuerThatOrderExpired,
    onRequestOrderCancellation,
  };

  const approverOrderItemsProps = {
    ...props,
    isIssuingOrder,
    order,
    isSuccessfulRoadRebooking,
    itemsAvailableToAdd,
    isRebookingRoad,
    isRoadRebookError,
    onSendVoucherInWhatsapp,
    onAddOrderAdvance,
    onRequestApproval,
    onRequestOrderCancellation,
    onNotifyIssuerThatOrderExpired,
    onCancelOrder,
  };

  const canceled = order?.status === OrderStatus.CANCELED;
  const includesTax = order?.items.road?.travels.some(
    (travel) => travel.cancellationTax
  ) as boolean;

  return (
    <Fragment>
      <Row
        css={{
          "@lg": {
            px: "$10",
          },
        }}
      >
        <Col
          sz="8"
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "$6",
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          {order?.itemsIncluded && order.itemsIncluded.length > 0 && (
            <Box css={{ display: "flex", flexDirection: "column" }}>
              <Box css={{ mb: "$10" }}>
                <H4
                  css={{
                    fw: "600",
                    "@mxlg": {
                      textAlign: "center",
                      fontSize: "$lg",
                    },
                  }}
                >
                  Itens do pedido
                </H4>
              </Box>

              <ApproverOrderItems {...approverOrderItemsProps} />

              {canceled && includesTax && (
                <Alert
                  variant="error"
                  css={{
                    mt: "$10",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SvgError
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />{" "}
                  O seu pedido de cancelamento gerou uma taxa.
                </Alert>
              )}
            </Box>
          )}
        </Col>

        <Col sz="4">
          <ApproverOrderCart {...approverOrderCartProps} />
        </Col>
      </Row>
    </Fragment>
  );
}
