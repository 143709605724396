import type { AirwaySeats, AirwaySeatsForm, OrderAirway } from "~/application/types";
import { api } from "~/infrastructure/api";
import { AirwaySeatsDTO, OrderAirwayDTO } from "~/infrastructure/api/dtos";
import { mapAirwaySeatsDTO, mapOrderAirwayDTO } from "~/infrastructure/api/mappers";
import type { IOrderAirwayService } from "./IOrderAirwayService";
import type { ICreateOrderAirwayData } from "./types";
import { UnflownData } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/UnflownAirwayDialog/type";

export class OrderAirwayService implements IOrderAirwayService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async create({
    orderId,
    returnFlight,
    goFlight,
    isCombined,
    lowerFareViolated,
    passengers,
    brokenPolicyJustification,
  }: ICreateOrderAirwayData): Promise<void> {
    return await api
      .post(`/orders/${orderId}/airway`, {
        departure: {
          search_key: goFlight.flight.searchKey,
          flight_id: goFlight.flight.id,
          fare_id: goFlight.flightOption.id,
          lower_fare_violated: lowerFareViolated?.departure,
          unflown_ticket_uuid: goFlight.unflownsAirway?.at(0)?.unflown.uuid
            ? goFlight.unflownsAirway?.at(0)?.unflown.uuid
            : null,
          broken_policy_justification: brokenPolicyJustification?.goJustification,
        },
        arrival: returnFlight
          ? {
              search_key: returnFlight.flight.searchKey,
              flight_id: returnFlight.flight.id,
              fare_id: returnFlight.flightOption.id,
              lower_fare_violated: lowerFareViolated?.arrival,
              unflown_ticket_uuid: returnFlight.unflownsAirway?.at(0)?.unflown?.uuid
                ? returnFlight.unflownsAirway?.at(0)?.unflown?.uuid
                : null,
              broken_policy_justification: brokenPolicyJustification?.returnJustification,
            }
          : undefined,
        travelers: passengers,
        combined: returnFlight ? isCombined : undefined,
      })
      .then(() => undefined);
  }

  async findByOrderId(orderId: string): Promise<OrderAirway> {
    return await api
      .get<{ data: OrderAirwayDTO }>(`/orders/${orderId}/airway`)
      .then(({ data }) => mapOrderAirwayDTO(data.data));
  }

  async getSeats({ orderItemId }: { orderItemId: string }): Promise<AirwaySeats> {
    return await api
      .get<AirwaySeatsDTO>(`/orders/airway/${orderItemId}/seats`)
      .then(({ data }) => mapAirwaySeatsDTO(data));
  }

  async removeSeat({
    orderItemId,
    seatId,
  }: {
    orderItemId: string;
    seatId: string;
  }): Promise<void> {
    return api.delete<void>(`orders/airway/${orderItemId}/seats/${seatId}`).then();
  }

  async markSeatsAsSelected({
    orderItemId,
    seats,
  }: {
    orderItemId: string;
    seats: AirwaySeatsForm;
  }): Promise<void> {
    const seatsSelected = {
      ...seats,
      seats: seats.seats.filter((seat) => seat.selected),
    } as AirwaySeatsForm;
    await api.post(`/orders/airway/${orderItemId}/seats`, seatsSelected);
  }

  async delete(itemId: string): Promise<void> {
    await api.post<void>(`/orders/airway/${itemId}/cancel`);
  }

  async updateUnflownAirway(data: UnflownData[]): Promise<void> {
    const body = data
      .map((unflown) => ({
        tracker: unflown.tracker,
        markup: unflown.markup,
        value: unflown.totalValue,
        change_tax: unflown.taxOfChange,
        passenger: {
          uuid: unflown.passenger.uuid,
        },
      }))
      ?.at(0);

    const itemId = data?.at(0)?.itemId;

    await api.put<void>(`/orders/airway/${itemId}/update`, body);
  }
}
