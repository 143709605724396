import * as React from "react";
import { SVGProps } from "react";

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="m13.006 7.674-4.152 3.963-1.86-1.776a.625.625 0 0 0-.883.02.625.625 0 0 0 .02.883l2.29 2.187a.625.625 0 0 0 .864 0l4.584-4.375a.625.625 0 0 0 .02-.883.625.625 0 0 0-.883-.02z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 1.875C5.52 1.875 1.875 5.52 1.875 10S5.52 18.125 10 18.125 18.125 14.48 18.125 10 14.48 1.875 10 1.875zm0 1.25A6.866 6.866 0 0 1 16.875 10 6.866 6.866 0 0 1 10 16.875 6.866 6.866 0 0 1 3.125 10 6.866 6.866 0 0 1 10 3.125Z"
    />
  </svg>
);

export default SvgCheck;
