import { SimpleObservable } from "../../services/SimpleObservable";
import { MessageType } from "./Snackbar";

export interface SnackbarType {
  id: number;
  message: { messageText: string; messageType: MessageType };
  milliSeconds: number;
  isMobile: boolean;
}

const SNACKBAR_MILLISECONDS = 1000 * 8; // 8 seconds

export class SnackbarService extends SimpleObservable {
  private nextId = 0;
  private snackbar!: SnackbarType;

  public getSnackbar(): SnackbarType {
    return this.snackbar;
  }

  public showSnackbar(
    message: string,
    type: MessageType = "success",
    milliSeconds = SNACKBAR_MILLISECONDS
  ): Promise<void> {
    return new Promise(() => {
      this.snackbar = {
        id: this.nextId++,
        isMobile: false,
        message: {
          messageText: message,
          messageType: type,
        },
        milliSeconds,
      };
      this.inform();
    });
  }
}

export const snackbarService = new SnackbarService();
