import { FC, Fragment, useCallback } from "react";
import {
  Order,
  OrderItems,
  OrderVehicleItemType,
  OrderVehicleItem as OrderVehicleType,
} from "~/application/types";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCopy, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Image } from "~/components/Image";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H4, Label } from "~/components/Typography";
import { ManualEmissionButtons } from "~/core/modules/Order/pages/ManageOrderPage/views/OrderItems/components/ManualEmissionButtons";
import { OrderItemStatusTag } from "~/presentation/shared/components/OrderItemStatusTag";
import { TravelerListItem } from "~/presentation/shared/components/TravelerListItem";
import { asCurrency } from "~/utils/mask.utils";

export type OrderVehicleItemProps = {
  data: OrderVehicleItemType;
  order?: Order;
  isLoading: boolean;
  onIssueOrder: () => void;
  onCopyText: (value: string) => void;
  onIssueVehicle: (orderData: OrderVehicleType) => void;
  onCancelItem: ({
    orderItemId,
    orderItemType,
  }: {
    orderItemId: string;
    orderItemType: OrderItems;
  }) => void;
};

export const OrderVehicleItem: FC<OrderVehicleItemProps> = ({
  data,
  order,
  onCopyText,
  isLoading,
  onCancelItem,
  onIssueVehicle,
  onIssueOrder,
}: OrderVehicleItemProps) => {
  const renderCopyCredential = useCallback(
    (credential: string) => (
      <Tooltip content={<TooltipLabel>Copiar credencial</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(credential)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  return (
    <Fragment>
      {data.vehicles.map((item) => (
        <Flex direction="column" gap="4" key={item.uuid}>
          <Card>
            <Flex direction="column" as={CardBody} gap="6">
              <Row gap="6">
                <Col sz="6">
                  <Label paragraph>Data</Label>
                  <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                    {DateUtils.format(item.createdAt, DateFormats.SMALL_DATE_TIME)}
                  </Text>
                </Col>

                <Col
                  sz="6"
                  css={{
                    "@mxlg": {
                      ta: "end",
                    },
                  }}
                >
                  <Label paragraph>Status</Label>
                  <Text css={{ fw: "600" }}>
                    <OrderItemStatusTag data={item.status} />
                  </Text>
                </Col>
              </Row>

              <Card
                css={{
                  "@mxlg": {
                    border: "0",
                  },
                }}
              >
                <CardBody
                  css={{
                    "@mxlg": {
                      p: 0,
                      overflow: "hidden",
                    },
                  }}
                >
                  <Row gap="6">
                    <Col sz="12">
                      <Text as="p" size="2" variant="dark">
                        Condutor
                      </Text>

                      <TravelerListItem data={item.conductor} />
                    </Col>

                    <Col sz="6">
                      <Flex justify={"center"} align="center" gap="3" direction="column">
                        <Image src={item.vehicleImage} />
                        <CompanyLogo
                          css={{
                            "@mxlg": {
                              width: "100%",
                            },
                          }}
                          src={item.vehicleCompanyImage}
                          size="lg"
                        />
                      </Flex>
                    </Col>

                    <Col
                      sz="6"
                      css={{
                        "@mxlg": {
                          display: "block",
                          width: "100%",
                        },
                      }}
                    >
                      <Text as="p" size="2" variant="dark">
                        Veiculo
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {item.vehicleDescription}
                      </Text>
                    </Col>

                    <Col sz="6">
                      <Text as="p" size="2" variant="dark">
                        Categoria
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {item.vehicleGroup}
                      </Text>
                    </Col>
                  </Row>

                  <Row gap="6" css={{ py: "$5" }}>
                    <Col sz="6">
                      <Text as="p" size="2" variant="dark">
                        Data da retirada
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {DateUtils.format(item.departureDate, DateFormats.LONG_DATE_TIME)}
                      </Text>
                    </Col>

                    <Col sz="6">
                      <Text as="p" size="2" variant="dark">
                        Data da devolução
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {DateUtils.format(item.arrivalDate, DateFormats.LONG_DATE_TIME)}
                      </Text>
                    </Col>
                  </Row>

                  <Row gap="6">
                    <Col sz="6">
                      <Text as="p" size="2" variant="dark">
                        Local da retirada
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {item.departure}
                      </Text>
                    </Col>

                    <Col sz="6">
                      <Text as="p" size="2" variant="dark">
                        Local da devolução
                      </Text>

                      <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                        {item.arrival}
                      </Text>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card
                css={{
                  "@mxlg": {
                    border: "0",
                    borderRadius: "0",
                    overflow: "hidden",
                  },
                }}
              >
                <CardBody
                  css={{
                    "@mxlg": {
                      p: "0",
                    },
                  }}
                >
                  <Row gap="6">
                    <Col sz="4">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Valor do pedido
                      </Text>
                      <Flex gap="4" align="center">
                        <H4 size={{ "@mxlg": "3" }}>{asCurrency(data.value)}</H4>
                        <Tooltip content={<TooltipLabel>{asCurrency(data.value)}</TooltipLabel>}>
                          <IconButton
                            css={{
                              "@mxlg": {
                                display: "none",
                              },
                            }}
                            size="md"
                          >
                            <Icon as={SvgInfo} />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Valor do fornecedor
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>{asCurrency(data.value)}</H4>
                        <Tooltip content={<TooltipLabel>{asCurrency(data.value)}</TooltipLabel>}>
                          <IconButton
                            css={{
                              "@mxlg": {
                                display: "none",
                              },
                            }}
                            size="md"
                          >
                            <Icon as={SvgInfo} />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Valor antes do reajuste
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }} variant="dark">
                          {asCurrency(data.value)}
                        </H4>
                        <Tooltip content={<TooltipLabel>{asCurrency(data.value)}</TooltipLabel>}>
                          <IconButton
                            css={{
                              "@mxlg": {
                                display: "none",
                              },
                            }}
                            size="md"
                          >
                            <Icon as={SvgInfo} />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Nome do fornecedor
                      </Text>
                      <Text>{item.provider}</Text>
                    </Col>

                    <Col sz="4">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Credenciais
                      </Text>

                      <Flex gap="2" align="center">
                        <Text>{item.credential}</Text>

                        {renderCopyCredential(item.credential)}
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        size={{ "@mxlg": "2" }}
                        as="p"
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Número do bilhete
                      </Text>

                      <Flex gap="2" align="center">
                        {item.tracker}
                      </Flex>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Flex>
          </Card>
          <ManualEmissionButtons
            data={order as Order}
            item={item}
            onCancelItem={onCancelItem}
            onIssueItem={onIssueVehicle}
            itemType={OrderItems.VEHICLE}
          />
        </Flex>
      ))}
    </Fragment>
  );
};

OrderVehicleItem.displayName = "OrderVehicleItem";
