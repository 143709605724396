import { ComponentProps } from "react";
import { components, DropdownIndicatorProps } from "react-select";
import { styled } from "~/application/theme";
import { Icon } from "../../../Icon";
import { SvgChevronDown } from "../../../Icon/icons";

export type SelectDropdownIndicatorProps = ComponentProps<
  typeof SelectDropdownIndicator
>;

export const SelectDropdownIndicatorRoot = styled(
  components.DropdownIndicator,
  {
    display: "flex",

    "[data-open=true] &": {
      transform: "rotate(180deg)",
    },
  }
);

export const SelectDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <SelectDropdownIndicatorRoot {...props}>
      <Icon as={SvgChevronDown} />
    </SelectDropdownIndicatorRoot>
  );
};

SelectDropdownIndicator.displayName = "SelectDropdownIndicator";
