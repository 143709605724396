import { useCallback } from "react";
import { OrderAirwaySegment } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { OrderAirwayItemSkeleton } from "~/presentation/shared/components/OrderAirwayItemSkeleton";
import { PassengerListItem } from "~/presentation/shared/components/PassengerListItem";
import { ReasonRejectedItem } from "~/presentation/shared/components/ReasonRejectedItem";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { canMarkAsRejected } from "~/presentation/shared/utils";
import { OrderAirwayCard } from "./OrderAirwayCard";
import { CombinedOrderAirwayItemSegmentType } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/OrderAirwayItem";

export interface OrderAirwayItemProps {
  isLoading: boolean;
  data?: OrderAirwaySegment[];
  onOpenDetails?: (data: OrderAirwaySegment) => void;
}

export function OrderAirwayItem({ isLoading, data, onOpenDetails }: OrderAirwayItemProps) {
  const { order } = useOrder();

  const listRenderer = useCallback(
    (item: OrderAirwaySegment, index: number) => {
      const reasonRejected = item.reasonRejected;
      const shouldMarkAsRejected = canMarkAsRejected({
        reasonRejected: reasonRejected,
        order,
      });

      return (
        <Card css={{ border: shouldMarkAsRejected ? "1px solid red" : "none" }}>
          <CardBody>
            <Flex direction="column" gap="6" key={index}>
              <Flex>
                <Col>
                  <Text size="5">Passagem</Text>
                </Col>
              </Flex>

              <OrderAirwayCard data={item} onOpenDetails={() => onOpenDetails?.(item)} />

              {item.passengers.length > 0 && <Text size="5">Passageiros</Text>}

              <LazyList
                items={item.passengers}
                render={(traveler) => (
                  <PassengerListItem
                    data={traveler}
                    item={item as CombinedOrderAirwayItemSegmentType}
                    key={traveler.uuid}
                  />
                )}
                skeletonHeight={88}
                skeletonQuantity={2}
              />
            </Flex>

            {shouldMarkAsRejected && (
              <ReasonRejectedItem css={{ mt: "$6" }} reasonRejected={reasonRejected} />
            )}
          </CardBody>
        </Card>
      );
    },
    [onOpenDetails]
  );

  return (
    <Box>
      <LazyList
        gap="8"
        isLoading={isLoading}
        items={data && data}
        render={listRenderer}
        skeletonQuantity={1}
        SkeletonComponent={<OrderAirwayItemSkeleton />}
      />
    </Box>
  );
}

OrderAirwayItem.displayName = "OrderAirwayItem";
