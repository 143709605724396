import { Markdown } from '~/application/types';
import { MarkdownDTO } from '../dtos';

export function mapMarkdownDTO(data: MarkdownDTO): Markdown {
  return {
    uuid: data.uuid,
    name: data.name,
    items: data.items.map((item) => ({
      uuid: item.uuid,
      airline: {
        uuid: item.airline.uuid,
        logo: item.airline.logo,
        slug: item.airline.slug,
        name: item.airline.name,
        isActive: item.airline.active,
      },
      markdownId: item.markdown_uuid,
      value: 100 * parseFloat(item.percent_value),
    })),
    agencyId: data.agency_uuid,
    isActive: Boolean(data.active),
  };
}