import * as React from "react";
import { SVGProps } from "react";

const SvgTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M2.5 3.75c-.331 0-.65.131-.885.365A1.252 1.252 0 0 0 1.25 5v1.938a1.25 1.25 0 0 0 1 1.224 1.878 1.878 0 0 1 1.078 3.024 1.878 1.878 0 0 1-1.078.652 1.249 1.249 0 0 0-1 1.225V15a1.252 1.252 0 0 0 1.25 1.25h15c.33 0 .649-.132.883-.365l.002-.002c.233-.234.365-.553.365-.883v-1.938a1.25 1.25 0 0 0-1-1.224 1.878 1.878 0 0 1-1.078-3.024 1.878 1.878 0 0 1 1.078-.652 1.25 1.25 0 0 0 1-1.225V5a1.253 1.253 0 0 0-1.25-1.25h-10ZM2.5 5h4.375v10H2.5v-1.938a3.125 3.125 0 0 0 0-6.124Zm5.625 0H17.5v1.938a3.125 3.125 0 0 0 0 6.125V15H8.125Z"
    />
  </svg>
);

export default SvgTicket;
