import * as React from "react";
import { SVGProps } from "react";

const SvgReceiptLong = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.798 17.342c-.58 0-1.014-.142-1.305-.425-.29-.283-.436-.72-.436-1.313V12.76h2.2V.835L3.47 1.882 4.703.835l1.232 1.047L7.166.835l1.232 1.047L9.63.835l1.232 1.047L12.094.835l1.232 1.047L14.54.835v14.49c0 .595-.191 1.08-.574 1.455-.382.374-.863.562-1.443.562H1.798Zm10.725-1.192a.83.83 0 0 0 .596-.217c.153-.146.23-.348.23-.608V2.95h-9.9v9.809h8.25v2.566c0 .26.076.463.229.608a.83.83 0 0 0 .595.217ZM4.725 6.021V4.83h4.65V6.02h-4.65Zm0 2.75V7.58h4.65v1.192h-4.65Zm6.645-2.626a.712.712 0 0 1-.493-.21.664.664 0 0 1-.226-.505.68.68 0 0 1 .226-.51.716.716 0 0 1 .505-.214c.186 0 .35.07.493.21a.68.68 0 0 1 .215.504c0 .198-.07.368-.211.51a.687.687 0 0 1-.509.215Zm0 2.75a.712.712 0 0 1-.493-.21.664.664 0 0 1-.226-.505.68.68 0 0 1 .226-.51.716.716 0 0 1 .505-.214c.186 0 .35.07.493.21a.68.68 0 0 1 .215.504c0 .198-.07.368-.211.51a.687.687 0 0 1-.509.215ZM1.798 16.15h8.709v-2.2H1.248v1.65c0 .26.027.417.08.47.054.054.21.08.47.08Z"
      fill="#475A6B"
    />
  </svg>
);

export default SvgReceiptLong;
