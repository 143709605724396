import type { OrderMessage } from "~/application/types";
import { api } from "~/infrastructure/api";
import { OrderMessageDTO } from "~/infrastructure/api/dtos/OrderMessageDTO";
import { mapOrderMessageDTO } from "~/infrastructure/api/mappers/mapOrderMessageDTO";
import { IOrderMessageService } from "./IOrderMessageService";
import { ICreateOrderMessageData } from "./types";

export class OrderMessageService implements IOrderMessageService {
  async find(orderId: string): Promise<OrderMessage[]> {
    return await api
      .get<{ data: OrderMessageDTO[] }>(`/orders/${orderId}/messages`)
      .then(({ data }) => data.data.map(mapOrderMessageDTO));
  }

  async notify(orderId: string): Promise<void> {
    await api.post(`/orders/${orderId}/notify-issuer`);
  }

  async create(data: ICreateOrderMessageData): Promise<OrderMessage> {
    return await api
      .post<OrderMessageDTO>(`/orders/${data.orderId}/messages`, {
        message: data.message,
        notify: data.notify,
      })
      .then(({ data }) => mapOrderMessageDTO(data));
  }
}
