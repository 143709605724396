import { useCallback, useState } from "react";

export interface UseAdvanceItemResult {
  isAdvanceItemExpanded: boolean;
  toggleAdvanceItemVisible: () => void;
}

export function useAdvanceItem(): UseAdvanceItemResult {
  const [isAdvanceItemExpanded, setAdvanceItemExpanded] = useState(true);

  const toggleAdvanceItemVisible = useCallback(() => {
    setAdvanceItemExpanded((old) => !old);
  }, []);

  return {
    isAdvanceItemExpanded,
    toggleAdvanceItemVisible,
  };
}
