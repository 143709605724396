import { Box } from "~/components/Box";
import React from "react";

export interface HotelImagesGridProps {
  children?: React.ReactNode;
}

export function HotelImagesGrid({ children, ...props }: HotelImagesGridProps) {
  return (
    <Box
      css={{
        gap: "$6",
        display: "grid",
        gridAutoRows: "minmax(220px, auto)",

        "&[data-grid-style='1']": {
          gridTemplateColumns: "1fr",
          gridAutoRows: "460px",
        },

        "&[data-grid-style='2']": {
          gridTemplateColumns: "1fr 1fr",
        },

        "&[data-grid-style='3']": {
          gridTemplateColumns: "3fr 2fr",

          "& *:nth-child(2)": {
            gridColumn: "2",
            gridRow: "1 / 2",
          },

          "& *:nth-child(6n+1)": {
            gridColumnEnd: "span 1",
            gridRowEnd: "span 2",
          },
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
