import { Avatar } from "~/components/Avatar";
import { Icon } from "~/components/Icon";
import { SvgCheck } from "~/components/Icon/icons";
import { Caption } from "~/components/Typography";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";

const StyledAvatarListItem = styled("div", {
  position: "relative",
  backgroundColor: "$neutrals-white",
  padding: "$4",
  gap: "$4",

  display: "flex",
  alignItems: "center",

  transition: "$normal",
  userSelect: "none",

  variants: {
    isActive: {
      true: {
        pointerEvents: "none",
        backgroundColor: "$primary-light",

        [`& ${Icon}`]: {
          fill: "$primary-base",
        },
      },
    },
  },

  compoundVariants: [
    {
      isActive: false,

      css: {
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "$neutrals-lightest",
        },
      },
    },
  ],

  defaultVariants: {
    isActive: false,
  },
});
const StyledAvatarContent = styled("div", {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});
const AvatarListName = styled("span", {
  fontSize: "$sm",
  fontWeight: 600,
});

export type AvatarListItemProps = Omit<
  ComponentProps<typeof StyledAvatarListItem>,
  "css"
> & {
  name: string;
  image?: string;
  email?: string;
  isActive?: boolean;
};

export const AvatarListItem = ({
  image,
  name,
  email,
  isActive,
  ...props
}: AvatarListItemProps) => {
  return (
    <StyledAvatarListItem isActive={isActive} {...props}>
      <Avatar src={image} />

      <StyledAvatarContent>
        <AvatarListName>{name}</AvatarListName>
        <Caption>{email}</Caption>
      </StyledAvatarContent>

      {isActive && <Icon as={SvgCheck} />}
    </StyledAvatarListItem>
  );
};

AvatarListItem.displayName = "AvatarListItem";
