import { AgreementDTO } from "../dtos/AgreementDTO";
import { Agreement } from "~/application/types/entities/BindCredential.type";

export const mapAgreementDTO = (item: AgreementDTO): Agreement=> {
  return{
    uuid: item.uuid,
    codeAgreement: item.code_agreement,
    customerId: item.customer_uuid,
    agreementComparation: {
      uuid: item.agreement_comparation?.uuid,
      codeAgreement: item.agreement_comparation?.code_agreement,
    },
    active: item.active,
    credential: {
      uuid: item.credential.uuid,
      serviceType: item.credential.service_type,
      description: item.credential.description,
      provider: {
        name: item.credential.provider.name,
        logo: item.credential.provider.logo,
        slug: item.credential.provider.slug,
        serviceType: item.credential.provider.service_type,
        active: item.credential.provider.active
      }
    }
  };
};
