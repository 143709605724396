import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type DialogHeaderProps = ComponentProps<typeof DialogHeader>;

export const DialogHeader = styled("header", {
  // Reset
  boxSizing: "border-box",

  // Custom
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "$6",
  gap: "$6",
  borderBottom: "1px solid $colors$neutrals-lightest",
});

DialogHeader.displayName = "DialogHeader";
