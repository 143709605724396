import * as React from "react";
import { SVGProps } from "react";

const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M6.875 2.5a.625.625 0 0 0-.625.625V6.25H3.125a.625.625 0 0 0-.625.625v10a.625.625 0 0 0 .625.625h10a.625.625 0 0 0 .625-.625V13.75h3.125a.625.625 0 0 0 .625-.625v-10a.625.625 0 0 0-.625-.625ZM7.5 3.75h8.75v8.75h-2.5V6.875a.625.625 0 0 0-.625-.625H7.5ZM3.75 7.5h8.75v8.75H3.75Z"
    />
  </svg>
);

export default SvgCopy;
