import { useState } from "react";
import { Order, OrderApprover, OrderItems, OrderStatus, UserContext } from "~/application/types";
import { Button } from "~/components/Button";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgChevronDown,
  SvgChevronUp,
  SvgExpenses,
  SvgInfo,
  SvgOrder,
  SvgTicket,
  SvgWhatsapp,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Label } from "~/components/Typography";
import { ApproversList } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/ApproversList";
import { userIsIssuer } from "~/core/modules/Order/utils";
import { OrderStatusSteps } from "~/core/shared/components/OrderStatusSteps";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { ApproversListItem } from "~/presentation/shared/components/ApproversList";
import { DownloadOrderVoucher } from "~/presentation/shared/components/DownloadOrderVoucher";
import { OrderStatusTag } from "~/presentation/shared/components/OrderStatusTag";
import { useDownload } from "~/presentation/shared/hooks/useDownload";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { getTotalProviderFees, getVoucherDownloadUrl } from "~/presentation/shared/utils";
import * as MaskUtils from "~/utils/mask.utils";
import { TabOrderItemsProps } from "./types";

export type AgencyOrderCartProps = {
  order?: Order;
  isLoading: boolean;
  onRequestApproval: () => void;
  onSendVoucherInWhatsapp: () => void;
  onReturnOrderStatus: (orderId: string) => void;
  onIssueOrder: (orderId: string) => void;
  onSendOfflineQuote: (orderId: string) => void;
  onCancelOrder: () => void;
} & Pick<TabOrderItemsProps, "onVoucherClick" | "onVoucherDownloaded">;

const LOG_TAG = "Agency/AgencyOrderPage/AgencyOrderCart";

export function AgencyOrderCart({
  order,
  isLoading,
  onRequestApproval,
  onReturnOrderStatus,
  onIssueOrder,
  onVoucherClick,
  onVoucherDownloaded,
  onSendVoucherInWhatsapp,
  onSendOfflineQuote,
  onCancelOrder,
}: AgencyOrderCartProps) {
  const { user, contexts } = useUser();
  const isMobile = useMobile();
  const totalProviderFees = getTotalProviderFees(order);

  const [cartIsOpen, setCartIsOpen] = useState(false);

  const isIssuer = userIsIssuer(user, order, contexts);
  const approvers = order?.approvalModel?.approvers?.filter(({ uuid, isSelfApprover }) => {
    if (isIssuer && user.profiles.customer.uuid === uuid && !isSelfApprover) {
      return false;
    }

    return true;
  });

  const orderStatus = order?.status as OrderStatus;
  const orderStatusIsOpenOrRejected = [OrderStatus.OPEN, OrderStatus.REJECTED].includes(
    orderStatus
  );

  const approvalModel = order?.approvalModel;

  const { onDownload } = useDownload({ logTag: LOG_TAG });

  const isValidAgencyEmployee = user.context === UserContext.Agency;

  const canRequestApproval = orderStatus && orderStatusIsOpenOrRejected && isValidAgencyEmployee;

  const canCancelOrder =
    isValidAgencyEmployee &&
    [
      OrderStatus.OPEN,
      OrderStatus.REJECTED,
      OrderStatus.APPROVED,
      OrderStatus.QUOTING,
      OrderStatus.CANCELING,
      OrderStatus.PENDING_ISSUE,
      OrderStatus.ON_APPROVAL,
    ].includes(orderStatus);

  const canSendQuotation = order?.items.hotel?.rooms?.every((room) => {
    const options = room?.options ?? [];

    return options.length > 0;
  });

  const isQuotingStatus = order?.status === OrderStatus.QUOTING;

  const canReturnOrderStatus = order?.status === OrderStatus.ON_APPROVAL;

  const isHotelOffline = order?.items.hotel?.rooms.some((room) => room.isOffline);

  const airwaySeatsValue = order?.items.airway?.fees?.seatValue;

  const canShowApprovers =
    [OrderStatus.REJECTED, OrderStatus.ON_APPROVAL].includes(orderStatus) &&
    approvalModel &&
    approvalModel?.approvalRequests &&
    Object.keys(approvalModel?.approvalRequests).length > 0 &&
    approvalModel?.approvalType === "sequential" &&
    (approvers?.length as number) > 0;

  const returnFeeAnotherCity = order?.items.vehicle?.vehicles.reduce(
    (acc, item) => acc + (item.returnFeeAnotherCity || 0),
    0
  );

  const isOfflineQuote = (order: Order | undefined) => {
    if (!order) return false;

    const { itemsIncluded, items } = order;

    const hasOfflineHotel = itemsIncluded.includes(OrderItems.HOTEL_OFFLINE);

    const hasOfflineRoom = items.hotel?.rooms?.some((room) => room.isOffline) || false;

    return hasOfflineHotel || hasOfflineRoom;
  };

  return (
    <Cart
      css={{
        "@mxlg": {
          position: "fixed",
          left: "0",
          bottom: "0",
          height: cartIsOpen ? "auto" : "50px",
          borderRadius: "0",
          width: "100%",
          backgroundColor: "#003161",
        },
      }}
    >
      <CartHeader>
        {!isMobile ? (
          <>
            <Icon as={SvgExpenses} size="sm" />
            <Text>Resumo do pedido</Text>
          </>
        ) : (
          <Flex
            onClick={() => setCartIsOpen((old) => !old)}
            justify="center"
            css={{ width: "100%" }}
          >
            <Icon as={cartIsOpen ? SvgChevronDown : SvgChevronUp} size="sm" />
          </Flex>
        )}
      </CartHeader>

      <OrderStatusSteps status={orderStatus} isOfflineQuote={isOfflineQuote(order)} />

      <Divider css={{ "@mxlg": { display: "none" } }} />

      <CardBody css={{ "@mxlg": { background: "#003161" } }}>
        <Flex direction="column" gap="6">
          <Text size="3" fw="600" css={{ "@mxlg": { color: "White" } }}>
            Informações do pedido
          </Text>

          <Flex direction="column" gap="6">
            {isLoading || !order ? (
              <Skeleton variant="text-6" />
            ) : (
              <Flex gap="2" align="center">
                <Col>
                  <Label css={{ "@mxlg": { color: "White" } }}>Solicitante</Label>
                </Col>

                <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                  {order.issuer.name}
                </Text>
              </Flex>
            )}

            {isLoading || !order ? (
              <>
                <Skeleton variant="text-4" />
                <Skeleton variant="text-4" />
                <Skeleton variant="text-4" />
              </>
            ) : (
              order.itemsIncluded.map((item) => (
                <Flex gap="2" align="center" key={item}>
                  <Icon variant="dark" as={OrderUtils.getServiceIcon(item)} size="sm" />

                  <Col>
                    <Label css={{ "@mxlg": { color: "White" } }}>
                      {OrderUtils.getServiceLabel(item)}
                    </Label>
                  </Col>

                  <Flex align="center">
                    <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                      {MaskUtils.asCurrency(order.items[item]?.value ?? 0)}
                    </Text>

                    {item === OrderItems.AIRWAY && (
                      <Tooltip
                        content={
                          <TooltipLabel>
                            <Flex direction="column" gap="2">
                              <TooltipLabel>
                                Taxa de embarque:{" "}
                                {MaskUtils.asCurrency(
                                  order.items.airway?.flights.reduce(
                                    (acc, flight) => (acc += flight.boardingTax),
                                    0
                                  ) as number
                                )}
                              </TooltipLabel>
                              <TooltipLabel>
                                Du:{" "}
                                {MaskUtils.asCurrency(
                                  order.items.airway?.flights.reduce(
                                    (acc, flight) => (acc += flight.du),
                                    0
                                  ) as number
                                )}
                              </TooltipLabel>
                              <TooltipLabel>
                                Tarifa:{" "}
                                {MaskUtils.asCurrency(
                                  order.items.airway?.flights.reduce(
                                    (acc, flight) => (acc += flight.fare),
                                    0
                                  ) as number
                                )}
                              </TooltipLabel>
                            </Flex>
                          </TooltipLabel>
                        }
                      >
                        <IconButton size="sm">
                          <Icon
                            as={SvgInfo}
                            css={{
                              fill: "$primary-base",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Flex>
                </Flex>
              ))
            )}

            {!!returnFeeAnotherCity && (
              <Flex gap="2" align="center">
                <Icon variant="dark" as={SvgOrder} size="sm" />

                <Col>
                  <Label css={{ "@mxlg": { color: "White" } }}>
                    Taxa de devolução em outra loja
                  </Label>
                </Col>

                <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                  {MaskUtils.asCurrency(returnFeeAnotherCity)}
                </Text>
              </Flex>
            )}

            {isLoading || !order ? (
              <Skeleton variant="text-4" />
            ) : (
              <>
                <Flex gap="2" align="center">
                  <Icon variant="dark" as={SvgOrder} size="sm" />

                  <Col>
                    <Label css={{ "@mxlg": { color: "White" } }}>Taxas e encargos</Label>
                  </Col>

                  <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                    {MaskUtils.asCurrency(totalProviderFees)}
                  </Text>
                </Flex>

                {!!airwaySeatsValue && (
                  <Flex gap="2" align="center">
                    <Icon variant="dark" as={SvgTicket} size="sm" />
                    <Col>
                      <Label css={{ "@mxlg": { color: "White" } }}>Valor dos assentos</Label>
                    </Col>
                    <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                      {MaskUtils.asCurrency(airwaySeatsValue)}
                    </Text>
                  </Flex>
                )}

                {order?.totalFees > 0 && (
                  <Flex gap="2" align="center">
                    <Icon variant="dark" as={SvgOrder} size="sm" />
                    <Col>
                      <Label css={{ "@mxlg": { color: "White" } }}>Taxa de serviço</Label>
                    </Col>
                    <Text variant={{ "@initial": "dark", "@mxlg": "white" }} size="2">
                      {MaskUtils.asCurrency(order.totalFees)}
                    </Text>
                  </Flex>
                )}
              </>
            )}

            {isLoading || !order ? (
              <Skeleton variant="text-4" />
            ) : (
              <Flex gap="2" align="center">
                <Col>
                  <Label css={{ "@mxlg": { color: "White" } }}>Status do pedido</Label>
                </Col>
                <OrderStatusTag data={orderStatus} />
              </Flex>
            )}

            {isLoading || !order ? (
              <Skeleton variant="text-6" />
            ) : (
              <Flex gap="4" align="center">
                <Col>
                  <Label variant="black" size="5" fw="700" css={{ "@mxlg": { color: "#FFF" } }}>
                    Total
                  </Label>
                </Col>

                <Text size="5" fw="700" css={{ "@mxlg": { color: "#FFF" } }}>
                  {MaskUtils.asCurrency(order.totalValue)}
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex direction="column" gap="3">
            {orderStatus === OrderStatus.ISSUED && (
              <DownloadOrderVoucher
                icon={null}
                onDownload={() => onDownload(order as Order)}
                downloadUrl={getVoucherDownloadUrl(order)}
                onDownloaded={onVoucherDownloaded}
                onIconClick={onVoucherClick}
              >
                <Button
                  css={{
                    width: "100%",
                    background: "$primary-base",
                    color: "$primary-light",
                    "&:hover": {
                      background: "$primary-base",
                      opacity: 0.9,
                    },
                  }}
                >
                  <Text css={{ textAlign: "center" }}>Baixar voucher</Text>
                </Button>
              </DownloadOrderVoucher>
            )}

            {canRequestApproval && (
              <Button onClick={onRequestApproval}>
                <Text>Solicitar aprovação </Text>
              </Button>
            )}

            {canReturnOrderStatus && (
              <Button variant="primary" onClick={() => onReturnOrderStatus(order.uuid ?? "")}>
                <Text>Retornar status para aberto</Text>
              </Button>
            )}

            {canReturnOrderStatus && (
              <Button variant="secondary" onClick={() => onIssueOrder(order.uuid ?? "")}>
                <Text>Forçar emissão do pedido</Text>
              </Button>
            )}

            {isQuotingStatus && isHotelOffline && (
              <Button
                onClick={() => onSendOfflineQuote(order?.uuid ?? "")}
                disabled={!canSendQuotation}
              >
                Enviar cotação
              </Button>
            )}

            {orderStatus === OrderStatus.ISSUED && (
              <Button variant="success" onClick={onSendVoucherInWhatsapp}>
                <Icon as={SvgWhatsapp} />
                <Text>Enviar voucher no whatsapp</Text>
              </Button>
            )}

            {canCancelOrder && (
              <Button variant="error" onClick={onCancelOrder}>
                <Text>Cancelar pedido</Text>
              </Button>
            )}
          </Flex>
        </Flex>
      </CardBody>

      <Flex>
        <ApproversList />
      </Flex>

      <ApproversListItem
        approvers={approvers as OrderApprover[]}
        enabled={canShowApprovers as boolean}
      />
    </Cart>
  );
}
