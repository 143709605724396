import { FC, Fragment, useMemo } from "react";
import { MaskUtils } from "~/application/utils";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Divider } from "~/components/Divider";
import { Icon } from "~/components/Icon";
import { SvgExpenses, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { FlightOptionBenefit } from "../FlightOptionBenefit";
import { FlightOptionItemProps } from "./types";
import { createFlightBenefits } from "./utils";
import { Checkbox } from "~/components/Input";
import { Flex } from "~/components/Flex";
import { useFlightsPage } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/contexts/FlightsPageContext";

export const FlightOptionItem: FC<FlightOptionItemProps> = ({
  data,
  isSelected,
  onSelect,
  flight,
  ...props
}) => {
  const flightBenefits = useMemo(() => createFlightBenefits(data.servicesIncluded), [data]);
  const { user } = useUser();
  const { airwayBudget, bookingStep } = useFlightsPage();

  const { activeBudget, state, onSelectBudget } = airwayBudget;

  const typeBudget = bookingStep.currentIndex ? "arrival" : "departure";

  const isExistOption = state[typeBudget]?.some(
    (s) => flight?.id === s?.id && !!s?.options?.find((op) => op?.id === data.id)
  );
  const hasViolatedPolicies = !!data.violatedPolicies.length;

  return (
    <Card
      {...props}
      css={{
        border: hasViolatedPolicies ? "solid #DA763A" : "",
        borderWidth: "1px",
      }}
    >
      <CartHeader css={{ justifyContent: "space-between" }} variant="lightest">
        <Flex
          gap="2"
          align="center"
          justify="between"
          css={{
            width: "100%",
          }}
        >
          {activeBudget && (
            <Checkbox
              checked={isExistOption}
              onChange={() => onSelectBudget(data, flight, typeBudget)}
            />
          )}
          <Text size={{ "@mxlg": "3" }}>{data.familyFare}</Text>
          {hasViolatedPolicies && (
            <Tooltip
              css={{
                backgroundColor: "$warning-light",
                border: "1px solid $warning-dark",
                color: "$warning-dark",
              }}
              content={
                <Flex direction="column" gap="2">
                  {data.violatedPolicies.map((policy) => (
                    <Text key={policy.message}>{policy.message}</Text>
                  ))}
                </Flex>
              }
            >
              <IconButton size="md">
                <Icon as={SvgInfo} fill="#DA763A" />
              </IconButton>
            </Tooltip>
          )}
        </Flex>

        {user.profiles.agency.uuid && (
          <Tooltip
            variant="white"
            content={
              <TooltipLabel>
                <ul
                  style={{
                    listStyleType: "disc",
                    fontSize: ".8rem",
                    padding: ".8rem",
                  }}
                >
                  <li style={{ marginBottom: ".3rem" }}>
                    Valor com acordo:{" "}
                    <strong>{MaskUtils.asCurrency(data.pricingInfo.tariffNet)}</strong>
                  </li>

                  <li style={{ marginBottom: ".3rem" }}>
                    Valor sem acordo:{" "}
                    <strong>{MaskUtils.asCurrency(data.pricingInfo.tariffWithoutAgreement)}</strong>
                  </li>

                  <li style={{ marginBottom: ".3rem" }}>
                    Markdown: <strong>{MaskUtils.asCurrency(data.pricingInfo.markdown)}</strong>
                  </li>

                  <li>
                    Du: <strong>{MaskUtils.asCurrency(data.pricingInfo.du)}</strong>
                  </li>
                </ul>
              </TooltipLabel>
            }
          >
            <IconButton>
              <Icon variant="darkest" as={SvgExpenses} />
            </IconButton>
          </Tooltip>
        )}
      </CartHeader>

      {flightBenefits.map((item) => (
        <Fragment key={item.name}>
          <FlightOptionBenefit name={item.name} isExists={item.isExists} />
          <Divider />
        </Fragment>
      ))}

      <CardBody
        css={{
          "@mxlg": {
            p: "$2",
          },
        }}
      >
        <Text
          size="6"
          css={{
            fw: 600,
            ta: "center",
          }}
        >
          {MaskUtils.asCurrency(data.pricingInfo.baseFare.amount)}
        </Text>
      </CardBody>

      <CardBody
        css={{
          pt: "0",
          "@mxlg": {
            p: "$2",
          },
        }}
      >
        <Button disabled={isSelected || activeBudget} onClick={onSelect}>
          <Text>Selecionar</Text>
        </Button>
      </CardBody>
    </Card>
  );
};

FlightOptionItem.displayName = "FlightOptionItem";
