import { FC, useMemo } from "react";
import { rangedArray } from "~/utils/array.utils";
import { Track, TrackDot } from "./Track";
import { TrackDotsProps } from "./types";

export const TrackDots: FC<TrackDotsProps> = ({ numberOfDots }) => {
  const dotsArray = useMemo(() => rangedArray(numberOfDots), [numberOfDots]);

  return (
    <Track>
      {dotsArray.map((dotIndex) => (
        <TrackDot key={dotIndex} css={{ border: "none" }} />
      ))}
    </Track>
  );
};

TrackDots.displayName = "TrackDots";
