// TODO: Delete this file
import { useQuery } from "@tanstack/react-query";
import { QueryTimes } from "~/application/constants";
import { ApiError, Profile, User, UserContext } from "~/application/types";
import { authService } from "../Auth";

export type FetchProfileResult = {
  profile?: Profile;
  isLoadingProfile: boolean;
};

export function fetchProfile(
  user: User,
  fn?: () => Promise<Profile>
): FetchProfileResult {
  const result = useQuery<Profile, ApiError>(
    [user],
    () => (fn ? fn() : authService.getProfile()),
    {
      enabled: user.context === UserContext.Customer,
      cacheTime: QueryTimes.NORMAL,
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    }
  );

  return { profile: result.data, isLoadingProfile: result.isLoading };
}
