import { FC } from "react";
import { useForm } from "react-hook-form";
import { Customer } from "~/application/types";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Icon } from "~/components/Icon";
import { SvgAlertTriangle, SvgClose } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { NumberInput } from "~/components/Input";
import { Text } from "~/components/Text";

export type CreditLimitUpdateDialogProps = {
  data: Customer;
  onSubmit: (item: Customer) => void;
};

export const CreditLimitUpdateDialog: FC<CreditLimitUpdateDialogProps> = ({
  onSubmit,
  data,
}) => {
  const { handleSubmit, control } = useForm({
    defaultValues: data,
  });

  return (
    <Card
      css={{
        position: "relative",
        width: "500px",
        "@mxlg": {
          width: "350px",
        },
      }}
    >
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap="4">
            <Flex direction="column" align="center" gap="6">
              <Icon as={SvgAlertTriangle} size="3xl" variant="primary" />

              <Text size="5" variant="black" fw="600">
                Alterar o limite de crédito
              </Text>
            </Flex>
            <IconButton
              css={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={() => dialogService.popDialog()}
            >
              <Icon as={SvgClose} size="md" />
            </IconButton>
            <FormControl control={control} name="invoiceLimit" required>
              <FieldLabel>Limite de crédito</FieldLabel>
              <NumberInput prefix="R$: " placeholder="Ex: R$: 100,00" />
            </FormControl>

            <Button type="submit">Enviar</Button>
          </Flex>
        </Form>
      </CardBody>
    </Card>
  );
};
