import * as React from "react";
import { SVGProps } from "react";

const SvgOrder = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M2.625 3.375c-.331 0-.65.131-.885.365a1.252 1.252 0 0 0-.365.885V16.5a.625.625 0 0 0 .904.559l2.221-1.11 2.22 1.11a.625.625 0 0 0 .56 0l2.22-1.11 2.22 1.11a.625.625 0 0 0 .56 0l2.22-1.11 2.22 1.11a.625.625 0 0 0 .905-.559V4.625a1.253 1.253 0 0 0-1.25-1.25Zm0 1.25h13.75v10.863l-1.596-.797a.625.625 0 0 0-.558 0L12 15.801l-2.22-1.11a.625.625 0 0 0-.56 0L7 15.801l-2.22-1.11a.625.625 0 0 0-.56 0l-1.595.797ZM5.438 7.75a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h8.125a.625.625 0 0 0 .624-.625.625.625 0 0 0-.624-.625zm0 2.5a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h8.125a.625.625 0 0 0 .624-.625.625.625 0 0 0-.624-.625z"
    />
  </svg>
);

export default SvgOrder;
