import { mapCostCenterDTO } from "./mapCostCenterDTO";
import { mapProjectDTO } from "./mapProjectDTO";
import { mapPhaseDTO } from "./mapPhaseDTO";
import { Budget } from "~/application/types";
import { BudgetDTO } from "../dtos";

export function mapBudgetDTO(data: BudgetDTO): Budget {
  return {
    uuid: data.uuid,
    costCenter: data.cost_center ? mapCostCenterDTO(data.cost_center) : null,
    project: data.project ? mapProjectDTO(data.project) : null,
    phase: data.phase ? mapPhaseDTO(data.phase) : null,
    periodicity: data.type,
    blocking: data.blocking,
    items: data.items.map((item) => ({
      uuid: item.uuid,
      value: Number(item.value),
      startAt: new Date(item.start_at),
      endAt: item.end_at ? new Date(item.end_at) : null,
    })),
  };
}
