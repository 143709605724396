import queryString from "query-string";
import type { PendingOrder, PendingOrderFilters } from "~/application/types";
import { api } from "~/infrastructure/api";
import { PendingOrderDTO } from "~/infrastructure/api/dtos";
import { mapPendingOrderDTO } from "~/infrastructure/api/mappers";
import type { IFindPendingOrderParams, IPendingOrderService } from "./IPendingOrderService";

export class PendingOrderService implements IPendingOrderService {
  async find({
    consultants,
    customers,
    status,
    travelers,
    orderNumber,
    issuers,
    tracker,
  }: IFindPendingOrderParams): Promise<{
    orders: PendingOrder[];
    filters: PendingOrderFilters;
  }> {
    const url = queryString.stringifyUrl(
      {
        url: `/pending-orders`,
        query: {
          consultants,
          customers,
          status,
          travelers,
          order_number: orderNumber,
          issuers,
          tracker,
        },
      },
      {
        arrayFormat: "bracket",
      }
    );

    return await api.get<PendingOrderDTO>(url).then(({ data }) => mapPendingOrderDTO(data));
  }
}
