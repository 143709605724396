import { ExpensePolicyTypeValue } from "~/application/types";
import {
  EXPENSE_POLICY_RULE_TYPES,
  EXPENSE_POLICY_TYPE_VALUE,
} from "./constants";
import type { ExpensePolicyRule } from "./types";

export function getExpensePolicyTypeName(
  typeValue: ExpensePolicyTypeValue
): string {
  return EXPENSE_POLICY_TYPE_VALUE[typeValue] ?? typeValue;
}

export function getExpensePolicyRules(
  typeValue: ExpensePolicyTypeValue
): ExpensePolicyRule | null {
  return EXPENSE_POLICY_RULE_TYPES.find((r) => r.value === typeValue) ?? null;
}
