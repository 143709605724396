import queryString from "query-string";
import type { Markdown, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { MarkdownDTO } from "~/infrastructure/api/dtos";
import { mapMarkdownDTO } from "~/infrastructure/api/mappers";
import type { IFindMarkdownParams, IMarkdownService } from "./IMarkdownService";

export class MarkdownService implements IMarkdownService {
  async findById(id: string): Promise<Markdown> {
    return await api
      .get<MarkdownDTO>(`/agencies/markdowns/${id}`)
      .then(({ data }) => mapMarkdownDTO(data));
  }

  async find({
    page = 1,
    name,
    agencyId,
  }: IFindMarkdownParams): Promise<PaginatedResource<Markdown[]>> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/markdowns`,
      query: { page, name },
    });

    return await api
      .get<PaginatedResource<MarkdownDTO[]>>(url)
      .then(({ data }) => ({
        links: data.links,
        meta: data.meta,
        data: data.data.map(mapMarkdownDTO),
      }));
  }

  async create(data: Omit<Markdown, "uuid">): Promise<Markdown> {
    return await api
      .post<MarkdownDTO>(`/agencies/${data.agencyId}/markdowns`, {
        name: data.name,
        agency_uuid: data.agencyId,
        items: data.items.map((item) => ({
          airline_uuid: item.airline.uuid,
          percent_value: item.value / 100,
        })),
      })
      .then(({ data }) => mapMarkdownDTO(data));
  }

  async updateById(data: Markdown): Promise<void> {
    await Promise.all(
      data.items.map((item) =>
        api.patch(`/agencies/markdown-items/${item.uuid}`, {
          percent_value: item.value / 100,
        })
      )
    );

    return await api
      .put<undefined>(`/agencies/markdowns/${data.uuid}`, {
        name: data.name,
      })
      .then(({ data }) => data);
  }

  async toggleActive(data: Markdown): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/agencies/markdowns/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/agencies/markdowns/${data.uuid}/active`)
      .then(({ data }) => data);
  }
}
