import { useChannel } from "../useChannel";

export interface UseIssueOrderChannelProps {
  orderId: string;
  enabled?: boolean;
  onOrderIssued?: (data: any) => void;
}

export function useIssueOrderChannel({
  orderId,
  enabled,
  onOrderIssued,
}: UseIssueOrderChannelProps) {
  return useChannel({
    channelName: `orders.confirmation.${orderId}`,
    eventName: 'order.confirmation',
    enabled,
    onMessage: onOrderIssued,
  })
}
