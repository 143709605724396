import { ComponentProps, forwardRef } from "react";

import { styled } from "~/application/theme";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgTrash, SvgUpload } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { H3 } from "~/components/Typography";

const Dropzone = styled("div", {
  backgroundColor: "$primary-light",
  border: "1px dashed $primary-base",
  borderRadius: "$md",

  textAlign: "center",
  padding: "2.8rem 0 3.2rem",
  position: "relative",

  "&:hover": {
    cursor: "pointer",
  },

  input: {
    all: "unset",
    opacity: 0,
    inset: 0,
    position: "absolute",
  },

  "&.dragover": {
      background: "#EBE6FF",
  }
});

interface UploadImageProps extends ComponentProps<'input'> {
  files?: FileList;
  label: string;
  removeFile: (indexToRemove: number) => void;
}

export const UploadImageInput = forwardRef<HTMLInputElement, UploadImageProps>(
  ({ files, label, removeFile, ...props }, forwardRef) => {

    return (
      <>
        <Dropzone>
          <Icon size="2xl" as={SvgUpload} />
          <H3 size="5" fw={700} variant="primary" css={{ my: "$4" }}>{label}</H3>
          <Text>Arraste ou clique para fazer upload</Text>
          <input 
            type="file" 
            ref={forwardRef}
            {...props} 
          />
        </Dropzone>

        {!!files && (
          <Flex direction="column" gap="2" css={{ mt: "$4", width: "100%" }}>
            {Array.from(files).map((file, index) => (
              <Flex 
                key={`${file.name}-${index}`} 
                align="center" 
                justify="between" 
                gap="2"
                css={{
                  backgroundColor: "$primary-light",
                  borderRadius: "$md",
                  py: "$2",
                  px: "$4",
                }}
              >
                <Text size="3" css={{ lineHeight: 1.25 }}>{file.name}</Text>
                <IconButton size="md" onClick={() => removeFile(index)}>
                  <Icon as={SvgTrash} />
                </IconButton>
              </Flex>
            ))}
          </Flex>
        )}
      </>
    );
  }
);