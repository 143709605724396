import { useCallback, useState } from "react";

export interface UseVehicleItemResult {
  isVehicleItemExpanded: boolean;
  toggleVehicleItemVisible: () => void;
}

export interface UseVehicleItemOptions {
  orderId: string;
  enabled: boolean;
}

export function useVehicleItem({
  orderId,
  enabled, // eslint-disable-line @typescript-eslint/no-unused-vars
}: UseVehicleItemOptions): UseVehicleItemResult {
  const [isVehicleItemExpanded, setVehicleItemExpanded] = useState(true);

  const toggleVehicleItemVisible = useCallback(() => {
    setVehicleItemExpanded((old) => !old);
  }, []);

  return {
    isVehicleItemExpanded,
    toggleVehicleItemVisible,
  };
}
