import { useCallback, useState } from "react";

export interface UseVehicleItemResult {
  isVehicleItemExpanded: boolean;
  toggleVehicleItemVisible: () => void;
}

export function useVehicleItem(): UseVehicleItemResult {
  const [isVehicleItemExpanded, setVehicleItemExpanded] = useState(true);

  const toggleVehicleItemVisible = useCallback(() => {
    setVehicleItemExpanded((old) => !old);
  }, []);

  return {
    isVehicleItemExpanded,
    toggleVehicleItemVisible,
  };
}
