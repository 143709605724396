import { PaymentMethod } from "~/application/types";
import { PaymentMethodDTO } from "../dtos";

export function mapPaymentMethodDTO(data: PaymentMethodDTO): PaymentMethod {
  return {
    uuid: data.uuid,
    description: data.description,
    serviceType: data.service_type,
  };
}
