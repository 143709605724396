import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { LOG_TAG } from "../AgenciesOrdersPage/types";
import { DashboardBIContainer } from "./DashboardContainer";
import { dashboardBiService } from "~/application/usecases/DashboardBI";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys, QueryTimes } from "~/constants";
import { logError } from "~/presentation/shared/utils/errors";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { useDebounce } from "use-debounce";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Erro ao buscar dados",
  INTERVENTIONS_ERROR: "Erro ao buscar intervenções operacionais",
  LOGOUT_CUSTOMERS_ERROR: "Erro ao buscar clientes que saíram",
  NEW_CLIENTS_PURCHASE_ERROR: "Erro ao buscar novos clientes do mês",
  NOT_BUYING_CUSTOMERS_ERROR: "Erro ao buscar clientes que não compraram",
  PENDING_FIRST_PURCHASE_ERROR: "Erro ao buscar pendências de primeira compra",
  ORDERS_NOT_ISSUED_WEEK_ERROR: "Erro ao buscar pedidos não emitidos na semana",
};

export function DashboardBIPage() {
  const { user } = useAuth();
  const agencyId = user.agency.uuid;
  const [searchNotBuyingCustomers, setNotBuyingCustomerText] = useState("");
  const [searchLogouCustomersText, setLogoutCustomersText] = useState("");
 
  const [logoutCustomersText] = useDebounce(searchLogouCustomersText, 700)
  const [notBuyingCustomersText] = useDebounce(searchNotBuyingCustomers, 700)

  const defaultStartDate = dayjs().subtract(3, 'month').startOf('day').toDate();
  const defaultEndDate = dayjs().endOf('day').toDate();

  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  const [notBuyingCustomersPage, setNotBuyingCustomersPage] = useState(1)
  const [logoutCustomersPage, setLogoutCustomersPage] = useState(1)

  const { data, isLoading } = useQuery(
    [QueryKeys.DASHBOARDBI, agencyId],
    async () => await dashboardBiService.find({ agencyId }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const {
    data: interventions,
    isLoading: isLoadingInterventions,
  } = useQuery(
    [QueryKeys.INTERVENTIONS, startDate, endDate],
    async () =>
      await dashboardBiService.find({
        agencyId,
        maxDate: displayDate(endDate as Date, DateFormats.ISO_DATE),
        minDate: displayDate(startDate as Date, DateFormats.ISO_DATE),
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.INTERVENTIONS_ERROR,
        });
      },
    }
  );

  const {
    data: logoutCustomers,
    isLoading: isLoadingLogoutCustomers,
  } = useQuery(
    [QueryKeys.LOGOUT_CUSTOMERS, agencyId, logoutCustomersPage, logoutCustomersText],
    async () => await dashboardBiService.findLogoutCustomer({ agencyId, page: logoutCustomersPage, name: logoutCustomersText }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOGOUT_CUSTOMERS_ERROR,
        });
      },
    }
  );

  const {
    data: newClientsPurchaseMonth,
    isLoading: isLoadingNewClientsPurchaseMonth,
  } = useQuery(
    [QueryKeys.NEW_CLIENTS_PURCHASE_MONTH, agencyId],
    async () => await dashboardBiService.findNewClientsPurchaseMonth(agencyId),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.NEW_CLIENTS_PURCHASE_ERROR,
        });
      },
    }
  );

  const {
    data: notBuyingCustomers,
    isLoading: isLoadingNotBuyingCustomers,
  } = useQuery(
    [QueryKeys.NOT_BUYING_CUSTOMERS, agencyId, notBuyingCustomersPage, notBuyingCustomersText],
    async () => await dashboardBiService.findNotBuyingCustomers({ agencyId, page: notBuyingCustomersPage, name: notBuyingCustomersText }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.NOT_BUYING_CUSTOMERS_ERROR,
        });
      },
    }
  );

  const {
    data: pendingFirstPurchase,
    isLoading: isLoadingPendingFirstPurchase,
  } = useQuery(
    [QueryKeys.PENDING_FIRST_PURCHASE, agencyId],
    async () => await dashboardBiService.findPendingFirstPurchase(agencyId),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.PENDING_FIRST_PURCHASE_ERROR,
        });
      },
    }
  );

  const {
    data: ordersNotIssuedWeek,
    isLoading: isLoadingOrdersNotIssuedWeek,
  } = useQuery(
    [QueryKeys.ORDERS_NOT_ISSUED_WEEK, agencyId],
    async () => await dashboardBiService.findOrdersNotIssuedWeek(agencyId),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.ORDERS_NOT_ISSUED_WEEK_ERROR,
        });
      },
    }
  );

  const {
    data: operationalInterventions,
    isLoading: isLoadingOperationalInterventios,
  } = useQuery(
    [QueryKeys.INTERVENTIONS, agencyId, endDate, startDate],
    async () => await dashboardBiService.findOperationalInterventions({
      agencyId,
      maxDate: displayDate(endDate as Date, DateFormats.ISO_DATE),
      minDate: displayDate(startDate as Date, DateFormats.ISO_DATE),
    }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.INTERVENTIONS_ERROR,
        });
      },
    }
  );

  const onSearchNotBuyingCustomers = useCallback((text: string) => {
    setNotBuyingCustomerText(text)
    setNotBuyingCustomersPage(1)
  }, []);

  const onSearchLogoutCustomers = useCallback((text: string) => {
    setLogoutCustomersText(text)
    setLogoutCustomersPage(1)
  }, [])

  return (
    <DashboardBIContainer
      data={data}
      logoutCustomers={logoutCustomers?.data}
      logoutCustomersPage={logoutCustomersPage}
      ordersNotIssuedWeek={ordersNotIssuedWeek}
      notBuyingCustomersPage={notBuyingCustomersPage}
      isLoadingCustomers={isLoadingLogoutCustomers}
      operationalInterventions={operationalInterventions}
      interventions={operationalInterventions}
      isLoadingInterventions={isLoadingInterventions}
      startDate={startDate}
      endDate={endDate}
      isLoading={isLoading}
      newClientsPurchaseMonth={newClientsPurchaseMonth}
      isLoadingNewClientsPurchaseMonth={isLoadingNewClientsPurchaseMonth}
      notBuyingCustomers={notBuyingCustomers?.data}
      logoutCustomersLastPage={logoutCustomers?.meta.last_page || 1}
      isLoadingNotBuyingCustomers={isLoadingNotBuyingCustomers}
      pendingFirstPurchase={pendingFirstPurchase}
      isLoadingPendingFirstPurchase={isLoadingPendingFirstPurchase}
      isLoadingOrdersNotIssuedWeek={isLoadingOrdersNotIssuedWeek}
      notBuyingCustomersLastPage={notBuyingCustomers?.meta.last_page || 1}
      onSearchLogoutCustomers={onSearchLogoutCustomers}
      onSearchNotBuyingCustomersText={onSearchNotBuyingCustomers}
      onChangeNotBuyingCustomers={setNotBuyingCustomersPage}
      onChangeLogoutCustomersPage={setLogoutCustomersPage}
      onChangeMinDate={setEndDate}
      onChangeMaxDate={setStartDate}
    />
  );
}
