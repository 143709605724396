import queryString from "query-string";
import { routes } from "~/application/theme/routes";
import { toISOString } from "~/application/utils/date-functions";
import { AirwayQueryFormData } from "~/presentation/shared/components/AirwayQueryForm";

export function createAirwayQueryUrl(data: Partial<AirwayQueryFormData>, orderId?: string) {
  const airwayQuery = {
    orderId: orderId,
    adultQuantity: data.adultQuantity,
    childrenQuantity: data.childrenQuantity,
    reasonTrip: JSON.stringify(data.reasonTrip),
    stretch: JSON.stringify(
      data?.stretch?.map((stretch) => ({
        destinationSelected: JSON.stringify(stretch.destination),
        originSelected: JSON.stringify(stretch.origin),
        dateGoSelected: toISOString(stretch.outboundDate as Date),
        dateReturnSelected: !stretch.returnDate ? null : toISOString(stretch.returnDate),
      }))
    ),
  };

  const queryUrl = queryString.stringifyUrl({
    url: routes.Booking.Airway.Result.root,
    query: airwayQuery,
  });

  return queryUrl;
}
