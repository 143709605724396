import * as React from "react";
import { SVGProps } from "react";

const SvgPix = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.917 11.71a2.045 2.045 0 0 1-1.454-.602l-2.1-2.1a.4.4 0 0 0-.551 0l-2.108 2.108a2.043 2.043 0 0 1-1.454.602h-.414l2.66 2.66c.83.83 2.177.83 3.007 0l2.667-2.668h-.253ZM4.25 4.282c.55 0 1.066.214 1.454.602l2.108 2.108a.39.39 0 0 0 .552 0l2.1-2.1a2.045 2.045 0 0 1 1.453-.602h.253L9.503 1.623a2.127 2.127 0 0 0-3.007 0l-2.66 2.66h.414Z"
      fill="#475A6B"
    />
    <path
      d="m14.377 6.496-1.613-1.612a.307.307 0 0 1-.114.023h-.732c-.38 0-.75.154-1.018.422l-2.1 2.1a1.005 1.005 0 0 1-1.425 0L5.268 5.32a1.448 1.448 0 0 0-1.018-.422h-.9a.306.306 0 0 1-.11-.021L1.622 6.496c-.83.83-.83 2.177 0 3.008l1.618 1.618a.303.303 0 0 1 .108-.022h.902c.38 0 .75-.153 1.018-.421L7.375 8.57a1.034 1.034 0 0 1 1.425 0l2.1 2.1c.268.268.639.421 1.018.421h.732c.04 0 .08.01.114.024l1.613-1.612c.83-.83.83-2.177 0-3.007Z"
      fill="#475A6B"
    />
  </svg>
);

export default SvgPix;
