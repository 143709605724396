import { CostCenter } from "~/application/types";
import { CostCenterDTO } from "../dtos";

function mapApprovalModelsDTO(
  data: CostCenterDTO["approval_models"]
): CostCenter["approvalModels"] {
  return data.map((approvalModel) => ({
    uuid: approvalModel.uuid,
    active: approvalModel.active,
    approvalType: approvalModel.approval_type,
    name: approvalModel.name,
    approvers: approvalModel.approvers?.map((approver) => ({
      uuid: approver.uuid,
      level: approver.level,
      isSelfApprover: approver.self_approver,
      name: approver.name,
    })),
  }));
}

export function mapCostCenterDTO(data: CostCenterDTO): CostCenter {
  return {
    uuid: data.uuid,
    name: data.name,
    credit: parseFloat(data.credit),
    costCenterCode: data.cost_center_code,
    customerId: data.customer_uuid,
    isActive: Boolean(data.active),
    approvalModels: mapApprovalModelsDTO(data.approval_models || []),
  };
}
