import { useMemo } from "react";
import { Collapse } from "react-collapse";
import {
  Order,
  OrderHotel,
  OrderHotelItem,
  OrderHotelItemType,
  OrderHotelOfflineOption,
  OrderItems,
  OrderStatus,
} from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgCheck,
  SvgChevronDown,
  SvgChevronUp,
  SvgPlus,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataListItem } from "~/components/List";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { OrderHotelOfflineAgencyItem } from "~/core/modules/Agency/pages/AgencyOrderPage/components/Hotel/OrderHotelOfflineAgencyItem";
import { ManualEmissionButtons } from "~/core/modules/Order/pages/ManageOrderPage/views/OrderItems/components/ManualEmissionButtons";

export type OrderHotelOfflineAgencyItemProps = {
  roomExpanded: {
    id: string;
    roomExpanded: boolean;
    checked: boolean;
  }[];
  index: number;
  order: Order;
  item: OrderHotelItem;
  onOpenQuoteOfflineHotelForm: (
    item: OrderHotelItem,
    options: OrderHotelOfflineOption[]
  ) => void;
  handleCopyQuoteOfflineHotelForm?: (
    item: OrderHotelItem,
    options: OrderHotelOfflineOption[]
  ) => void;
  onItemExpand: (room: OrderHotelItem) => void;
  onOpenOptionDetails: (
    item: OrderHotelItem,
    option: OrderHotelOfflineOption
  ) => void;
  onDeleteQuoteOffline?: (optionId: string) => void;
  onCancelItem: ({
    orderItemId,
    orderItemType,
  }: {
    orderItemId: string;
    orderItemType: OrderItems;
  }) => void;
  onIssueHotel: (orderData: OrderHotelItem) => void;
};

export const AgencyOrderHotelOffline = ({
  item,
  index,
  order,
  roomExpanded,
  onOpenQuoteOfflineHotelForm,
  handleCopyQuoteOfflineHotelForm,
  onItemExpand,
  onOpenOptionDetails,
  onDeleteQuoteOffline,
  onCancelItem,
  onIssueHotel,
}: OrderHotelOfflineAgencyItemProps) => {
  const orderStatus = order?.status as OrderStatus;
  const isOpen = roomExpanded.find((room) => room.id === item.uuid)
    ?.roomExpanded as boolean;

  const cannotAddQuote =
    orderStatus !== OrderStatus.QUOTING && orderStatus !== OrderStatus.OPEN;

  const checked = roomExpanded.find((room) => room.id === item.uuid)
    ?.checked as boolean;

  const shouldMarkAsRejected = useMemo(() => {
    return orderStatus === OrderStatus.REJECTED;
  }, [orderStatus]);

  const shouldMarkAsCanceled = useMemo(() => {
    return orderStatus === OrderStatus.CANCELED;
  }, [orderStatus]);

  return (
    <Box>
      <DataListItem css={{ p: "$4 $6" }}>
        <Col>
          <Text size="4" css={{ fw: "400" }}>
            {`Quarto ${index + 1}`}
          </Text>
        </Col>

        {shouldMarkAsRejected && (
          <Tag variant="error-light">
            <Text>Reprovado</Text>
          </Tag>
        )}

        {shouldMarkAsCanceled && (
          <Tag variant="error-light">
            <Text>Cancelado</Text>
          </Tag>
        )}

        {orderStatus !== OrderStatus.CANCELED && (
          <Tooltip
            content={
              <TooltipLabel>
                {isOpen ? "Recolher itens" : "Ver itens"}
              </TooltipLabel>
            }
          >
            <IconButton
              size="lg"
              onClick={() => onItemExpand(item)}
              css={{
                fill: "$success-base",
              }}
            >
              <Icon
                as={checked ? SvgCheck : isOpen ? SvgChevronDown : SvgChevronUp}
              />
            </IconButton>
          </Tooltip>
        )}
      </DataListItem>

      <Collapse isOpened={isOpen}>
        <Box css={{ mt: "$6" }}>
          <Flex gap="8" direction="column">
            <OrderHotelOfflineAgencyItem
              item={item}
              order={order}
              onOpenQuoteOffline={onOpenQuoteOfflineHotelForm}
              onCopyQuoteOffline={handleCopyQuoteOfflineHotelForm}
              onItemExpand={onItemExpand}
              onOpenOptionDetails={onOpenOptionDetails}
              onDeleteQuoteOffline={onDeleteQuoteOffline}
            />

            {!order?.isExpired &&
              !!item.options?.length &&
              order.status !== OrderStatus.PENDING_ISSUE && (
                <Col css={{ alignSelf: "center" }}>
                  <Button
                    variant="primary"
                    onClick={() => onOpenQuoteOfflineHotelForm(item, [])}
                    disabled={cannotAddQuote}
                  >
                    <SvgPlus />
                    Adicionar cotação
                  </Button>
                </Col>
              )}

            {order.status === OrderStatus.PENDING_ISSUE && (
              <ManualEmissionButtons
                data={order}
                item={item}
                onCancelItem={onCancelItem}
                onIssueItem={onIssueHotel}
                itemType={OrderItems.HOTEL}
              />
            )}

            <Text as="h4">Hóspedes</Text>

            {item.guests.map((passenger) => (
              <Card css={{ border: "none" }} key={passenger.uuid}>
                <CardBody css={{ p: "$4" }}>
                  <Flex justify="around" gap="2">
                    <Col>
                      <Flex direction="column" gap="3">
                        <Text fw="600">Nome</Text>
                        <Text
                          size={{ "@mxlg": "2" }}
                        >{`${passenger?.fullName}`}</Text>
                      </Flex>
                    </Col>

                    <Col>
                      <Flex direction="column" gap="3">
                        <Text fw="600">Cpf</Text>
                        <Text
                          size={{ "@mxlg": "2" }}
                        >{`${passenger?.cpf}`}</Text>
                      </Flex>
                    </Col>

                    <Col>
                      <Flex direction="column" gap="3">
                        <Text fw="600">Rg</Text>
                        <Text size={{ "@mxlg": "2" }}>{passenger.rg}</Text>
                      </Flex>
                    </Col>
                  </Flex>
                </CardBody>
              </Card>
            ))}
          </Flex>
        </Box>
      </Collapse>

      <hr style={{ marginTop: "2rem", border: "1px solid  #d6cbcb" }} />
    </Box>
  );
};
