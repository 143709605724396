import { PaymentMethod } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { OrderItemService } from "~/presentation/shared/components/OrderItemsService";

export interface PaymentMethodListItemProps extends DataListItemProps {
  data: PaymentMethod;
  onEditClick: (item: PaymentMethod) => void;
}

export function PaymentMethodListItem({ data, onEditClick, ...props }: PaymentMethodListItemProps) {
  return (
    <DataListItem {...props}>
      <DataColItem
        headerTitle="Descrição"
        boxCss={{
          py: "$1",
          "@mxlg": {
            fontSize: "small",
          },
        }}
        data={data.description}
      />

      <DataColItem headerTitle="Serviço" data={<OrderItemService service={data.serviceType} />} />

      <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
        <IconButton size="md" onClick={() => onEditClick(data)}>
          <Icon as={SvgEdit} />
        </IconButton>
      </Tooltip>
    </DataListItem>
  );
}
