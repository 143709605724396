import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { RoadSeat } from "~/application/types";
import { useQueryRoadSeats } from "~/presentation/shared/hooks/useQueryRoadSeats";
import { StepItem } from "~/presentation/shared/utils";
import { BusSeatFormData } from "../../types";
import { UseRoadSeatsProps, UseRoadSeatsState } from "./types";

export function useRoadSeats({
  data,
  travelers,
  minSeats,
  maxSeats,
  onSelectSeats,
  currentRoadSeats,
}: UseRoadSeatsProps) {
  const [state, setState] = useState<UseRoadSeatsState>({
    currentStep: 0,
    road: { seats: currentRoadSeats ?? [] },
    connection: { seats: [] },
  });
  const { currentStep, road, connection } = state;

  const setCurrentStep = (currentStep: number) => {
    setState((old) => ({ ...old, currentStep }));
  };

  const dataFromTo = `${data.from} -> ${data.connection?.name}`;
  const connectionFromTo = `${data.connection?.name} -> ${data.to}`;
  const connectionSteps: StepItem<string>[] = [
    {
      key: dataFromTo,
      title: dataFromTo,
    },
    {
      key: connectionFromTo,
      title: connectionFromTo,
    },
  ];

  const { control, handleSubmit } = useForm<BusSeatFormData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "seats",
  });

  useEffect(() => {
    if (currentRoadSeats) {
      append(currentRoadSeats);
    }
  }, [append, currentRoadSeats]);

  const {
    data: roadSeatData,
    error: roadSeatsError,
    isLoading: isLoadingRoadSeats,
  } = useQueryRoadSeats({ road: data, maxAttempts: 15 });

  const isLastStep = currentStep === connectionSteps.length - 1;
  const roadSeats = roadSeatData?.seats;
  const roadSeatsConnection = roadSeatData?.seatsConnection;

  const canAddSeat = useMemo(() => {
    if (data.connection && isLastStep) {
      return connection.seats.length < road.seats.length;
    }

    if (travelers?.length > 0) {
      return travelers?.length > fields.length;
    }

    return fields.length < maxSeats;
  }, [data, maxSeats, travelers, isLastStep, connection, fields.length]);

  const isSeatsDisabled = useMemo(() => {
    if (data.connection) {
      if (isLastStep) {
        return connection.seats.length !== road.seats.length;
      }

      return road.seats.length === 0;
    }

    if (travelers?.length > 0) {
      return travelers?.length !== fields.length;
    }

    if (fields.length > maxSeats) return true;
    if (fields.length < minSeats) return true;

    return false;
  }, [data, connection, road, fields.length, maxSeats, minSeats, travelers, isLastStep]);

  const seatActions = (seat: RoadSeat) => {
    const seatsToUpdate = isLastStep ? connection.seats : road.seats;
    const index = seatsToUpdate.findIndex((s) => s.seat === seat.seat);

    const updateSeats = (action: "remove" | "append") => {
      const roadActions = {
        remove: () => road.seats.filter((s) => s.seat !== seat.seat),
        append: () => [...road.seats, seat],
      };

      const connectionActions = {
        remove: () => connection.seats.filter((s) => s.seat !== seat.seat),
        append: () => [...connection.seats, seat],
      };

      const newRoad = {
        ...road,
        seats: roadActions[action](),
      };

      const newConnection = {
        ...connection,
        seats: connectionActions[action](),
      };

      setState((old) => ({
        ...old,
        road: isLastStep ? old.road : newRoad,
        connection: isLastStep ? newConnection : old.connection,
      }));
    };

    if (index > -1) {
      remove(index);
      updateSeats("remove");
    } else {
      append(seat);
      updateSeats("append");
    }
  };

  const onSelectSeat = useCallback(
    (seat: RoadSeat) => seatActions(seat),
    [fields, isLastStep, road, connection]
  );

  const floors = useMemo(() => {
    const seats = isLastStep ? roadSeatsConnection : roadSeats;

    const firstFloor = seats?.filter(({ occupied, position }) => !occupied && position.z === 0);

    const secondFloor = seats?.filter(({ occupied, position }) => !occupied && position.z === 1);

    if (firstFloor?.length && secondFloor?.length) {
      return [{ floor: 1 }, { floor: 2 }];
    }

    if (firstFloor?.length) {
      return [{ floor: 1 }];
    } else if (secondFloor?.length) {
      return [{ floor: 2 }];
    }

    return [];
  }, [roadSeats, roadSeatsConnection, isLastStep]);

  const [currentFloor, setCurrentFloor] = useState(0);

  useEffect(() => {
    const minFloor = Math.min(...floors.map(({ floor }) => floor - 1));
    setCurrentFloor(minFloor);
  }, [floors]);

  let seats = useMemo(() => {
    if (isLastStep) {
      return roadSeatData?.seatsConnection
        ?.filter(({ position }) => position.z === currentFloor)
        .map((s) => {
          return isLastStep &&
            connection.seats.length >= road.seats.length &&
            !connection.seats.find(({ seat }) => seat === s.seat)
            ? { ...s, occupied: true }
            : s;
        });
    }

    return roadSeatData?.seats?.filter(({ position }) => position.z === currentFloor);
  }, [roadSeatData, currentFloor, isLastStep, connection]);

  const onSubmit = useCallback(() => {
    if (!data.connection) {
      return onSelectSeats?.({
        seats: fields,
        seatsConnection: state.connection.seats,
      });
    }

    if (currentStep < connectionSteps.length - 1) {
      setCurrentStep(currentStep + 1);
      setState((old) => ({
        ...old,
        road: { data, seats: fields },
      }));
    } else {
      onSelectSeats?.({
        seats: state.road.seats,
        seatsConnection: fields.filter((_, index) => index >= state.road.seats.length),
      });
    }
  }, [data, onSelectSeats, fields]);

  if (!seats?.length) {
    const seatsData = isLastStep ? roadSeatData?.seatsConnection : roadSeatData?.seats;

    seats = (seatsData || []).map((seat) => ({
      ...seat,
      position: { ...seat.position, z: -1 },
    }));
  }
  const onRemoveSeat = (seat: RoadSeat) => seatActions(seat);

  return {
    connection,
    canAddSeat,
    road,
    connectionSteps,
    isLoadingRoadSeats,
    roadSeats,
    roadSeatsError,
    roadSeatsConnection,
    isLastStep,
    fields,
    seats,
    isSeatsDisabled,
    floors,
    currentFloor,
    currentStep,
    onSelectSeat,
    setCurrentFloor,
    setCurrentStep,
    handleSubmit,
    onRemoveSeat,
    onSubmit,
  };
}
