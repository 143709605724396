export const MASK_CPF = "999.999.999-99";
export const MASK_CNPJ = "99.999.999/9999-99";
export const MASK_CEP = "99999-999";
export const MASK_DATE = "99/99/9999";
export const MASK_PHONE_NUMBER = "+99 (99) 9999-9999";
export const MASK_MOBILE_PHONE_NUMBER = "+55 (99) 9 9999-9999";
export const MASK_MOUTH_DAY = "99/9999";
export const MASK_CARD_NUMBER_MASTERCARD = "9999-9999-9999-9999";
export const MASK_CARD_NUMBER_AMERICAN = "9999-999999-99999";
export const MASK_CARD_NUMBER_VISA = "9999-9999-9999-9999";
export const MASK_CARD_NUMBER_DINERS = "9999-999999-99999";
export const MASK_CARD_NUMBER_HIPERCARD = "9999-9999-9999-9999";
export const MASK_CARD_NUMBER_ELO = "9999-9999-9999-9999";

export const PATTERN_CEP = /\d{5}[-]\d{3}/;
export const PATTERN_CPF = /\d{3}[.]\d{3}[.]\d{3}[-]\d{2}/;
export const PATTERN_CNPJ = /\d{2}[.]\d{3}[.]\d{3}[/]\d{4}[-]\d{2}/;

export function asCNPJ(value: string) {
  return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function asCPF(value: string) {
  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function asCEP(value: string) {
  return value.replace(/^(\d{5})(\d{3})/, "$1-$2");
}

export function asPhoneNumber(value: string) {
  value = value.replace(/\D/g, "i");

  if (value.length <= 12) {
    return value.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
  }

  return value.replace(/^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, "+$1 ($2) $3 $4-$5");
}

export function asCurrency(value: number | string) {
  value = typeof value === "string" ? parseFloat(value) : value;

  return value?.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

export function asPercentage(value: number | string) {
  value = typeof value === "string" ? parseFloat(value) : value;

  return `${value}%`;
}

export function pickPhoneNumberMask(phone: string) {
  return asPhoneNumber(phone).length < 19 ? MASK_PHONE_NUMBER + "9" : MASK_MOBILE_PHONE_NUMBER;
}
