import { EmptyBudgetViewItem } from "../components/EmptyBudgetViewItem";
import { BudgetViewListItem } from "../components/BudgetViewListItem";
import { BudgetViewSkeleton } from "../components/BudgetViewSkeleton";
import { BudgetViewHead } from "../components/BudgetViewHead";
import { BudgetView } from "~/application/types";
import { Pagination } from "~/components/Pagination";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { Flex } from "~/components/Flex";

export type TabBudgetsViewProps = {
  budgets: BudgetView[] | undefined;
  currentPage: number;
  lastPage: number;
  onGoToPage: (page: number) => void;
};

export function TabBudgetsView({
  budgets,
  currentPage,
  lastPage,
  onGoToPage,
}: TabBudgetsViewProps) {
  return (
    <>
      <Container
        css={{
          overflow: "auto",
          pt: "$10",
          pb: "$20",
          "@mxlg": {
            p: "$5",
          },
        }}
      >
        {budgets ? (
          <Flex 
            direction="column"
            css={{
              minWidth: "92rem",
              overflow: "visible",
              border: "1px solid $primary-base", 
              backgroundColor: "$neutrals-white", 
              borderRadius: "$md $md 0 0" 
            }}
          >
            <BudgetViewHead />

            {budgets.length > 0 ? budgets.map((item) => (
              <BudgetViewListItem key={item.budgetId} data={item} />
            )) : <EmptyBudgetViewItem />}
          </Flex>
        ) : (
          <BudgetViewSkeleton />
        )}
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </>
  );
}
