import { api } from "~/infrastructure/api";
import { ApiErrorCode, OrderAirwayTracker } from "~/application/types";
import { IOrderAirwayTrackerService } from "./IOrderAirwayTrackerService";
import { OrderAirwayTrackerDTO } from "~/infrastructure/api/dtos";
import { mapOrderAirwayTrackerDTO } from "~/infrastructure/api/mappers";

export class OrderAirwayTrackerService implements IOrderAirwayTrackerService {
  async findTracker(
    orderId: string
  ): Promise<OrderAirwayTracker | ApiErrorCode> {
    const url = `/orders/airway/${orderId}/reload-tracker`;
    return await api
      .get<OrderAirwayTrackerDTO>(url)
      .then(({ data }) => mapOrderAirwayTrackerDTO(data))
      .catch((error) => {
        if (
          error?.statusCode === 400 &&
          error?.code === ApiErrorCode.AIRWAY_RESERVE_ERROR
        ) {
          return error.code;
        }

        return ApiErrorCode.UNKNOWN;
      });
  }
}
