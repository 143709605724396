import { useCallback, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

import { Phase } from "~/application/types";
import { phaseService } from "~/application/usecases/Phase";
import { dialogService } from "~/components/DialogStack";
import { PhaseDialog } from "../views/Phases/components/PhaseDialog";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { logError } from "~/presentation/shared/utils/errors";
import { snackbarService } from "~/components/SnackbarStack";
import { queryClient } from "~/services/queryClient";
import { QueryKeys } from "~/constants";
import { log } from "~/utils/log";

const LOG_TAG = "Customer/CustomerPage/usePhases";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar fases",
  CREATE_SUCCESS_MESSAGE: "Nova fase adicionada",
  CREATE_ERROR_MESSAGE: "Falha ao criar fase",
  UPDATE_SUCCESS_MESSAGE: "Fase atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar fase",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar fase",
  ACTIVATE_SUCCESS_MESSAGE: "Fase ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar fase",
  INACTIVATE_SUCCESS_MESSAGE: "Fase inativada",
} as const;

export type UsePhasesOptions = {
  projectId: string;
  enabled: boolean;
};

export type UsePhasesResult = {
  data?: Phase[];
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  search: string;
  onCreatePhase: () => void;
  onUpdatePhase: (item: Phase) => void;
  onGoToPage: (value: number) => void;
  fetchPhases: (name: string) => Promise<Phase[]>;
  onSearch: (value: string) => void;
};

export const usePhases = ({ projectId, enabled }: UsePhasesOptions): UsePhasesResult => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState("");

  const [searchDebounced] = useDebounce(search, 700);

  const { data, isLoading } = useQuery(
    [QueryKeys.PHASES, projectId, enabled, currentPage, searchDebounced],
    () =>
      phaseService.find({ projectId, page: currentPage, name: searchDebounced }),
    {
      enabled,
      staleTime: 1000 * 20,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateCreatePhase } = useMutation(
    [QueryKeys.PROJECTS, projectId],
    async (data: Phase) => await phaseService.create({ ...data, projectId }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando nova fase" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PHASES]);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: mutateUpdatePhase } = useMutation(
    async (data: Phase) => await phaseService.update(data),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando fase" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PHASES]);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const fetchPhases = useCallback(
    async (name: string) => {
      const response = await phaseService.find({ projectId, name });
      return response.data;
    },
    [projectId]
  );

  const onOpenCreateModalPhase = useCallback(
    () => dialogService.showDialog(<PhaseDialog onSubmit={mutateCreatePhase} isNew />),
    [mutateCreatePhase]
  );

  const onOpenUpdateModalPhase = useCallback(
    (item: Phase) =>
      dialogService.showDialog(
        <PhaseDialog defaultData={item} onSubmit={mutateUpdatePhase} />
      ),
    [mutateUpdatePhase]
  );

  return {
    data: data?.data,
    currentPage,
    lastPage: data?.meta.last_page || 0,
    isLoading,
    search: search,
    onCreatePhase: onOpenCreateModalPhase,
    onGoToPage: setCurrentPage,
    onUpdatePhase: onOpenUpdateModalPhase,
    fetchPhases,
    onSearch: setSearch,
  };
};
