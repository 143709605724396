import * as DialogPrimitive from "@radix-ui/react-dialog";
import { ComponentProps } from "react";
import { keyframes, styled } from "~/application/theme";

export type DialogContentProps = ComponentProps<typeof DialogContent>;

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

export const DialogContent = styled(DialogPrimitive.Content, {
  display: "flex",
  position: "fixed",
  zIndex: "$modal",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "calc(100vh - 8rem)",
  animation: `${contentShow} $transitions$fast`,
  "&:focus": { outline: "none" },
  "& form": { maxHeight: "100%" },
});
