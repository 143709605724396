import { useNavigate } from "react-router-dom";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { DialogHeader } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { Icon } from "~/components/Icon";
import { SvgRemove, SvgRestartAlt } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";

export function ErrorRemakeSearchDialog() {
  const navigate = useNavigate();
  return (
    <Card
      css={{
        boxShadow: "$sm",
        maxHeight: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "$8",
        px: "$15",
        gap: "$6",
        textAlign: "center",
        maxWidth: "670px",
        position: "relative",
        [`& ${Icon}`]: {
          size: "$18",
        },
      }}
    >
      <Icon
        as={SvgRemove}
        css={{
          stroke: "$error-base",
          fill: "white",
        }}
      />
      <DialogHeader
        css={{
          borderBottom: "none",
          p: "0",
        }}
      >
        <H4
          css={{
            maxWidth: "500px",
            fw: "bold",
          }}
        >
          Não conseguimos refazer seu pedido.
        </H4>
      </DialogHeader>
      <Text
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "$1",
        }}
      >
        <Text>Pedimos desculpas pelo transtorno.</Text>
        <Text>Para prosseguir, refaça o pedido manualmente.</Text>
      </Text>
      <Button
        variant="primary"
        css={{
          px: "$8",
          [`& ${Icon}`]: {
            size: "$5",
          },
        }}
        onClick={() => {
          dialogService.popDialog(), navigate("/busca/passagens-onibus", {});
        }}
      >
        <Icon as={SvgRestartAlt} /> Fazer manualmente
      </Button>
    </Card>
  );
}

ErrorRemakeSearchDialog.displayName = "ErrorRemakeSearchDialog";
