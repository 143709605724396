import { Fragment } from "react";
import { globalStyles } from "~/application/theme";
import { DialogContainer } from "./components/DialogStack";
import { SnackbarContainer } from "./components/SnackbarStack";
import { AppLoading } from "./containers/AppLoading";
import { AppRouter } from "./navigators/AppRouter";
import { ScrollToTop } from "./presentation/core/components/ScrollToTop";
import { useUser } from "./presentation/core/contexts/UserContext";

globalStyles();

function App() {
  const { isLoading } = useUser();

  if (isLoading) return <AppLoading />;

  return (
    <Fragment>
      <SnackbarContainer />
      <DialogContainer />

      <AppRouter />
      <ScrollToTop />
    </Fragment>
  );
}

export default App;
