import {
  SvgBus,
  SvgExpenses,
  SvgHotels,
  SvgLaundry,
  SvgLunch,
  SvgQuestion,
  SvgTicket,
  SvgVooIda,
} from "~/components/Icon/icons";

export const EXPENSE_CATEGORY_ICONS = [
  {
    icon: SvgLunch,
    slug: "lunch",
  },
  {
    icon: SvgBus,
    slug: "bus",
  },
  {
    icon: SvgHotels,
    slug: "hotels",
  },
  {
    icon: SvgQuestion,
    slug: "question",
  },
  {
    icon: SvgTicket,
    slug: "ticket",
  },
  {
    icon: SvgExpenses,
    slug: "expenses",
  },
  {
    icon: SvgVooIda,
    slug: "voo_ida",
  },
  {
    icon: SvgLaundry,
    slug: "laundry",
  },
];
