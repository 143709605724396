import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type CartProps = ComponentProps<typeof Cart>;

export const Cart = styled("div", {
  // Reset
  boxSizing: "border-box",
  appearance: "none",
  display: "flex",
  border: "none",
  outline: "none",

  // Custom Reset?
  position: "relative",
  flexDirection: "column",

  // Custom
  font: "inherit",
  lineHeight: "1",
  padding: 0,
  textAlign: "inherit",
  verticalAlign: "middle",
  backgroundColor: "$neutrals-white",
  color: "inherit",
  borderRadius: "$md",
  overflow: "hidden",
  boxShadow: "$md",
});
