import { ExpenseCategory } from "~/application/types";
import {
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { Fragment, useCallback } from "react";
import { ExpenseCategoryListItem } from "./components/ExpenseCategoryListItem";

export interface ExpenseCategoriesContainerProps {
  isLoading: boolean;
  expenseCategoriesList?: ExpenseCategory[];
  searchText: string;
  setSearchText: (searchO: string) => void;
  onCreateExpenseCategory: () => void;
  onEditExpenseCategory: (item: ExpenseCategory) => void;
  onToggleState: (data: ExpenseCategory) => void;
}

export function ExpenseCategoriesContainer({
  expenseCategoriesList,
  searchText,
  setSearchText,
  onCreateExpenseCategory,
  onEditExpenseCategory,
  onToggleState,
}: ExpenseCategoriesContainerProps) {
  const listRenderer = useCallback(
    (item: ExpenseCategory) => (
      <ExpenseCategoryListItem
        data={item}
        onToggleState={onToggleState}
        onEditClick={onEditExpenseCategory}
        key={item.uuid}
      />
    ),
    [onToggleState, onEditExpenseCategory]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Categorias de despesas" />

          <AppBarActions>
            <SearchBar
              search={searchText}
              onSearchChange={setSearchText}
              placeholder="Encontrar categoria de despesa"
            />

            <Button
              title="Nova categoria de despesa"
              onClick={onCreateExpenseCategory}
            >
              <Icon as={SvgPlus} />
              <Text>Nova categoria de despesa</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={expenseCategoriesList}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>
                  Nenhuma categoria de despesa corresponde aos dados informados
                </Text>
              ) : (
                <Text>Você não possui categorias de despesa cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>
    </Fragment>
  );
}
