import * as React from "react";
import { SVGProps } from "react";

const SvgChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="m12.059 3.309-6.25 6.25a.625.625 0 0 0 0 .882l6.25 6.25a.625.625 0 0 0 .882 0 .625.625 0 0 0 0-.882L7.133 10l5.808-5.809a.625.625 0 0 0 0-.882.625.625 0 0 0-.882 0z"
    />
  </svg>
);

export default SvgChevronLeft;
