import { OfflineHotelAmenity } from "~/application/types";
import { api } from "~/infrastructure/api";
import { IOfflineHotelAmenityService } from "./IOfflineHotelAmenityService";

export class OfflineHotelAmenityService implements IOfflineHotelAmenityService {
  getAll(): Promise<OfflineHotelAmenity[]> {
    return api
      .get("hotel-offline-amenities")
      .then(({ data: response }) => response.data);
  }
}
