import {
  DeepPartial,
  OfflineHotelAmenity,
  Order,
  OrderHotelItem,
  OrderHotelOfflineOption,
} from "~/application/types";

export type QuoteOfflineHotelOption = Omit<OrderHotelOfflineOption, "regimen" | "roomType"> & {
  roomType: { name: string };
  replicateRoom?: {
    name: string;
    uuid: string;
  };
  regimen: { name: string };
  amenities: OfflineHotelAmenity[];
};

export type QuoteOfflineHotelFormData = {
  options: (Omit<QuoteOfflineHotelOption, "amenities"> & {
    checkIn?: Date;
    checkOut?: Date;
    checkOutHour?: string;
    checkInHour?: string;
    othersTaxes?: number;
    amenities: { uuid: string; name: string }[];
  })[];
};

export interface QuoteOfflineHotelFormProps {
  defaultData?: DeepPartial<QuoteOfflineHotelFormData>;
  order: Order;
  isCopy?: boolean;
  item: OrderHotelItem;
  onSubmit?: (data: QuoteOfflineHotelFormData) => void;
}

export const PAYMENT_OPTIONS = [{ name: "Pix" }, { name: "Cartão virtual" }];

export const REGIME_OPTIONS = [{ name: "Com café da manhã" }, { name: "Sem café da manhã" }];
