import { useReducer, useState } from "react";
import {
  RoadBudgetAction,
  RoadBudgetActionType,
  RoadBudgetState,
  onSelectRoadBudgetProps,
  useRoadBudgetResult,
} from "./type";

export const useRoadBudget = (): useRoadBudgetResult => {
  const [activeBudget, setActiveBudget] = useState(false);

  function roadBudgetReducer(state: RoadBudgetState, action: RoadBudgetAction) {
    switch (action.type) {
      case RoadBudgetActionType.DEPARTURE: {
        return { ...state, departure: action.paylod };
      }
      case RoadBudgetActionType.ARRIVAL: {
        return { ...state, arrival: action.paylod };
      }
      case RoadBudgetActionType.CLEAR: {
        return { departure: [], arrival: [] } as RoadBudgetState;
      }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(roadBudgetReducer, {
    departure: [],
    arrival: [],
  } as RoadBudgetState);

  const onSelectRoadBudget = ({ data, type }: onSelectRoadBudgetProps) => {
    const currentType =
      type === "arrival"
        ? RoadBudgetActionType.ARRIVAL
        : RoadBudgetActionType.DEPARTURE;

    const findRoadBudget = state[type].find((rb) => rb?.id === data.id);

    if (findRoadBudget) {
      const roadBudgetFiltered = state[type].filter((rb) => rb?.id !== data.id);

      dispatch({ paylod: roadBudgetFiltered, type: currentType });
      return;
    }

    dispatch({ paylod: [...state[type], data], type: currentType });
  };
  return {
    state,
    activeBudget,
    dispatch,
    onActiveBudget: setActiveBudget,
    onSelectRoadBudget,
  };
};
