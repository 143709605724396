import { FC } from "react";
import { FormDialog } from "~/components/FormDialog";
import { FileViewDialogProps } from "./types";
import { DialogBody } from "~/components/Dialog";
import { Image } from "~/components/Image";
import { Container } from "~/components/Container";
import useMobile from "../../hooks/useMobile";

export const FileViewDialog: FC<FileViewDialogProps> = ({ url, onCloseClick, file, ...props }) => {
  const isFilePdf = file.type === "application/pdf";

  const isMobile = useMobile();
  return (
    <Container
      fixed={isMobile}
      size="11"
      css={{
        "@mxlg": {
          height: "100vh",
        },
      }}
    >
      <FormDialog
        title="Comprovante"
        onClickDismissButton={onCloseClick}
        {...props}
        css={{
          height: "85vh",
        }}
      >
        <DialogBody>
          {isFilePdf ? (
            <object data={url} type="application/pdf" width="100%" height="600px">
              <p>
                Seu navegador não suporta PDF embutido. Você pode baixar o PDF{" "}
                <a target="_blank" href={url}>
                  aqui
                </a>
                .
              </p>
            </object>
          ) : (
            <Image src={url} />
          )}
        </DialogBody>
      </FormDialog>
    </Container>
  );
};

FileViewDialog.displayName = "FileViewDialog";
