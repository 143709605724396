import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { orderService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants";
import { SNACKBAR_MESSAGES } from "~/core/modules/Agency/pages/AgenciesOrdersPage/types";
import { LogTagProvider } from "~/presentation/core/providers/LogTagProvider";
import {
  AirwayQueryFormData,
  TravelAirwayQueryForm,
} from "~/presentation/shared/components/AirwayQueryForm";
import { log } from "~/utils/log";
import { createAirwayQueryUrl } from "../../utils";
import { useAirwayReducer } from "../FlightsPage/hooks/useAirwayReducer";
import { FlightQueryPageUI } from "./ui/FlightQueryPageUI";
import { AirwayBookingDispatchActionType } from "../FlightsPage/hooks/useAirwayReducer/types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingAirway/FlightQueryPage";

const DEFAULT_FORM_DATA: Partial<AirwayQueryFormData> = {
  adultQuantity: 1,
  childrenQuantity: 0,
  stretch: [{} as TravelAirwayQueryForm],
};

export function FlightQueryPage() {
  const navigate = useNavigate();
  const { dispatch } = useAirwayReducer();

  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("orderId");
  const shouldAddItemToOrder = location.search.match(/\?orderId=([0-9a-fA-F-]+)/);

  const { data: order } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      staleTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
      enabled: !!orderId,
    }
  );

  if (shouldAddItemToOrder) {
    sessionStorage.setItem("orderId", shouldAddItemToOrder[1]);
  }

  const onQuery = useCallback(
    async (data: Partial<AirwayQueryFormData>) => {
      const queryUrl = createAirwayQueryUrl(data, orderId ?? "");

      dispatch({ type: AirwayBookingDispatchActionType.CLEAR });

      navigate(queryUrl, {
        replace: true,
      });
    },
    [navigate, orderId]
  );

  return (
    <LogTagProvider logTag={LOG_TAG}>
      <FlightQueryPageUI defaultQuery={DEFAULT_FORM_DATA} onQuery={onQuery} order={order} />
    </LogTagProvider>
  );
}
