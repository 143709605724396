import * as React from "react";
import { SVGProps } from "react";

const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M3.309 7.5a.625.625 0 0 0 0 .885l6.25 6.25a.625.625 0 0 0 .882 0l6.25-6.25a.625.625 0 0 0 0-.885.625.625 0 0 0-.882 0L10 13.309 4.191 7.5a.625.625 0 0 0-.882 0z"
    />
  </svg>
);

export default SvgChevronDown;
