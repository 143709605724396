import { ComponentProps, useContext } from "react";
import { styled } from "~/application/theme";
import { Icon } from "../../Icon";
import { TabsContext } from "../contexts/TabsContext";
import { CounterTabBadge } from "./CounterTabBadge";

export type CounterTabProps<TValue extends string | number = string> =
  ComponentProps<typeof CounterTabRoot> & {
    value: TValue;
  };

const CounterTabRoot = styled("button", {
  // Reset
  all: "unset",
  boxSizing: "border-box",

  // Custom
  display: "inline-flex",
  alignItems: "center",
  gap: "$2",
  padding: "$4",
  color: "$neutrals-dark",
  fill: "CurrentColor",
  fontSize: "$md",
  fontWeight: 500,
  transition: "$normal",
  userSelect: "none",

  "&:hover": {
    cursor: "pointer",
    color: "$neutrals-black",
  },

  "&[data-active=true]": {
    backgroundColor: "$neutrals-white",
    color: "$neutrals-black",
    boxShadow: "inset 0px -2px 0px 0px $colors$primary-base",
  },

  "&:disabled": {
    opacity: "$disabled",
    pointerEvents: "none",

    [`& > ${CounterTabBadge}`]: {
      backgroundColor: "$neutrals-lightest",
      color: "$neutrals-base",
    },
  },

  [`& ${Icon}`]: {
    size: "$5",
  },
});

export const CounterTab = <TValue extends string | number = string>({
  value,
  ...props
}: CounterTabProps<TValue>) => {
  const { activeValue, setActiveValue } = useContext(TabsContext);

  return (
    <CounterTabRoot
      role="tab"
      type="button"
      data-active={value === activeValue}
      onClick={() => setActiveValue(value)}
      {...props}
    />
  );
};

CounterTab.displayName = "CounterTab";
