import { Action, PaginatedResource, Profile } from "~/application/types";
import { api } from "~/infrastructure/api";
import { mapActionDTO, mapProfileDTO } from "~/infrastructure/api/mappers";
import {
  ICreateProfileParams,
  IProfileFindAllParams,
  IProfileService,
  IUpdateProfileActionsParams,
  IUpdateProfileParams,
} from "./IProfileService";
import { ActionDTO, ProfileDTO } from "~/infrastructure/api/dtos";
import queryString from "query-string";

export class ProfileService implements IProfileService {
  async find(): Promise<Profile> {
    return await api.get("/me/profile").then(({ data }) => mapProfileDTO(data));
  }

  async findAll({
    customerId,
    page,
    name,
  }: IProfileFindAllParams): Promise<PaginatedResource<Profile[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/profiles`,
      query: {
        page,
        name,
      },
    });

    return await api.get<PaginatedResource<ProfileDTO[]>>(url).then(({ data }) => ({
      data: data.data.map(mapProfileDTO),
      links: data.links,
      meta: data.meta,
    }));
  }

  async findProfileAction(data: Profile): Promise<Action[]> {
    return await api
      .get<ActionDTO[]>(`/customers/profiles/${data.uuid}/actions`)
      .then(({ data }) => data.map(mapActionDTO));
  }

  async updateProfileActions({ actions, profile }: IUpdateProfileActionsParams): Promise<void> {
    const body = {
      actions,
    };
    await api.put<void>(`/customers/profiles/${profile.uuid}/actions`, body);
  }

  async updateProfile({ profile }: IUpdateProfileParams): Promise<void> {
    const body = {
      name: profile.name,
      approver: profile.isApprover,
    };
    await api.put<void>(`/customers/profiles/${profile.uuid}`, body);
  }

  async createProfile({ customerId, profile }: ICreateProfileParams): Promise<Profile> {
    const body = {
      name: profile?.name,
      approver: profile?.isApprover,
    };

    return await api
      .post<ProfileDTO>(`/customers/${customerId}/profiles`, body)
      .then(({ data }) => mapProfileDTO(data));
  }
}
