import React, { ComponentProps, forwardRef, useEffect, useState } from "react";
import { styled } from "~/application/theme";

export type AvatarProps = ComponentProps<typeof AvatarRoot>;

export const AvatarRoot = styled("img", {
  // Reset
  boxSizing: "border-box",
  position: "relative",

  // Custom
  borderRadius: "$md",
  transition: "$fast",

  "&:hover": {
    filter: "brightness(90%)",
  },

  variants: {
    size: {
      sm: {
        size: "$6",
        boxShadow: "0px 0px 0px 2px $colors$neutrals-white",
      },
      md: {
        size: "$8",
        boxShadow: "0px 0px 0px 4px $colors$neutrals-white",
      },
      lg: {
        size: "$10",
        boxShadow: "0px 0px 0px 4px $colors$neutrals-white",
      },
      xl: {
        size: "$12",
        boxShadow: "0px 0px 0px 4px $colors$neutrals-white",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

export const Avatar = forwardRef<
  React.ElementRef<typeof AvatarRoot>,
  AvatarProps
>(({ src, ...props }, forwardedRef) => {
  const [hasRaisedError, setRaisedError] = useState(false);

  useEffect(() => {
    setRaisedError(false);
  }, [src]);

  const handleImageError: React.ReactEventHandler<HTMLImageElement> = (
    event
  ) => {
    event.preventDefault();

    if (!hasRaisedError) {
      event.currentTarget.src = "/images/no-profile-picture.png";
      setRaisedError(true);
    }
  };

  return (
    <AvatarRoot
      src={src || "/images/no-profile-picture.png"}
      onError={handleImageError}
      draggable={false}
      {...props}
      ref={forwardedRef}
    />
  );
});

Avatar.displayName = "Avatar";
