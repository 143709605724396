
import { useBudgets } from "~/core/modules/Customer/pages/CustomerPage/hooks/useBudgets";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { BudgetsContainer } from "./BudgetsContainer";

export function BudgetsPage() {
  const { contexts } = useUser();

  const budgetsState = useBudgets({
    customerId: contexts.customer.uuid,
    enabled: true,
  });

  return (
    <BudgetsContainer {...budgetsState} />
  );
}
