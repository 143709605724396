import { CheapestRoad, RoadOrderWithCheapestOptionsType } from "~/application/types";
import { DateFormats, format, timestampInterval } from "~/application/utils/date-functions";
import { RoadInfo } from "~/presentation/shared/utils";

export function getCheapestRoads(data: RoadOrderWithCheapestOptionsType): CheapestRoad[] {
  const { roadOrder, cheapestRoads } = data;

  const chosenRoadOrder: CheapestRoad = {
    uuid: roadOrder.uuid,
    company: roadOrder.companyName,
    companyImage: roadOrder.companyImage,
    from: roadOrder.departure,
    to: roadOrder.arrival,
    departureDate: roadOrder.departureDate,
    arrivalDate: roadOrder.arrivalDate,
    travelDuration: roadOrder.duration,
    travelers: roadOrder.travelers,
    seatClass: roadOrder.seatClass,
    price: (roadOrder.value - (roadOrder.providerFees + roadOrder.fee + roadOrder.insuranceValue)),
    totalPrice: roadOrder.value,
    taxPrice: roadOrder.providerFees,
    fee: roadOrder.fee,
    insurance: roadOrder.insuranceValue,
    isInsuranceIncludedOnValue: roadOrder.insuranceValue > 0,
    providerIdentification: roadOrder.companyName,
    connection: roadOrder.connection,
  };

  if (!cheapestRoads || !cheapestRoads.length) {
    return [chosenRoadOrder];
  }

  const cheapestRoadsList = cheapestRoads
    .filter(item => item.totalPrice <= chosenRoadOrder.totalPrice)
    .map(item => ({
      uuid: item.id,
      company: item.company,
      companyImage: item.companyImage,
      from: item.from,
      to: item.to,
      departureDate: item.departureDate,
      arrivalDate: item.arrivalDate,
      travelDuration: item.travelDuration,
      travelers: item.travelers,
      seatClass: item.seatClass,
      totalPrice: item.totalPrice,
      price: item.price - (item.isInsuranceIncludedOnValue ? item.insurance : 0),
      taxPrice: item.taxPrice,
      fee: item.fee,
      insurance: item.insurance,
      isInsuranceIncludedOnValue: item.isInsuranceIncludedOnValue,
      providerIdentification: item.providerIdentification,
      connection: item.connection,
    } as CheapestRoad))
    .sort((a, b) => a.totalPrice - b.totalPrice);


  return [chosenRoadOrder].concat(cheapestRoadsList);
}

export function formatRoadInfo(road: CheapestRoad): RoadInfo {
  return {
    road,
    roadDuration: timestampInterval(road.departureDate, road.arrivalDate),
    departureRoadInfo: {
      name: road.from,
      dateTimeHour12: format(road.departureDate, DateFormats.SMALL_TIME),
      date: format(road.departureDate, DateFormats.LONG_DATE),
    },
    arrivalRoadInfo: {
      name: road.to,
      dateTimeHour12: format(road.arrivalDate, DateFormats.SMALL_TIME),
      date: format(road.arrivalDate, DateFormats.LONG_DATE),
    },
  };
}