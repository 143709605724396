export function convertToWebp(fileList: FileList): Promise<File[]> {
  return Promise.all(
    Array.from(fileList).map((file) => {
      return new Promise<File>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event: ProgressEvent<FileReader>) => {
          const img = new Image();
          img.src = event.target?.result as string;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            if (ctx) {
              canvas.width = img.width;
              canvas.height = img.height;

              ctx.drawImage(img, 0, 0);

              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    const webpFile = new File([blob], `${file.name.split(".")[0]}.webp`, {
                      type: "image/webp",
                    });
                    resolve(webpFile);
                  }
                },
                "image/webp",
                0.8
              );
            }
          };

          img.onerror = (error) => reject(error);
        };

        reader.onerror = (error) => reject(error);
      });
    })
  );
}
