import { PolicyParamsSlug } from "~/application/types";
import { PolicyFilter } from "../../type";
import { NumberInput } from "~/components/Input";
import { FieldLabel } from "~/components/FormControl";
import { Flex } from "~/components/Flex";
import { FC } from "react";
import { PurchansingPolicyType } from "../../hooks/type";

export type InputTypeRenderProps = {
  data: PolicyFilter;
  onChancePolicies: ({
    policyId,
    policyParameterId,
    policyValueId,
    value,
  }: {
    policyId: string;
    policyParameterId: string;
    value: string;
    policyValueId: string;
  }) => void;
};

export const InputTypeRender: FC<InputTypeRenderProps> = ({ data, onChancePolicies }) => {
  switch (data?.value.parameter?.slug) {
    case PolicyParamsSlug.FIXED_VALUE:
      return (
        <Flex direction="column" gap="2">
          <FieldLabel>{data.name}</FieldLabel>

          <NumberInput
            prefix="R$: "
            defaultValue={Number(data.value.value)}
            key={data.value.parameter.uuid}
            onChange={(e) =>
              onChancePolicies({
                policyId: data.uuid || "",
                policyParameterId: data.value.parameter?.uuid || "",
                value: e.target.value.toString(),
                policyValueId: data.value.uuid || "",
              })
            }
          />
        </Flex>
      );
    case PolicyParamsSlug.LEAD_TIME:
      return (
        <Flex direction="column" gap="2">
          <FieldLabel>{data.name}</FieldLabel>

          <NumberInput
            defaultValue={Number(data.value.value.toString())}
            key={data.value.parameter.uuid}
            onChange={(e) => {
              onChancePolicies({
                policyId: data.uuid || "",
                policyParameterId: data.value.parameter?.uuid || "",
                value: e.target.value.toString(),
                policyValueId: data.value.uuid || "",
              });
            }}
          />
        </Flex>
      );
    case PolicyParamsSlug.LOWER_FARE: {
      return (
        <Flex direction="column" gap="2">
          <FieldLabel>{data.name}</FieldLabel>
          <NumberInput
            suffix="%"
            defaultValue={Number(data.value.value)}
            key={data.value.parameter.uuid}
            onChange={(e) =>
              onChancePolicies({
                policyId: data.uuid || "",
                policyParameterId: data.value.parameter?.uuid || "",
                value: e.target.value.toString(),
                policyValueId: data.value.uuid || "",
              })
            }
          />
        </Flex>
      );
    }
    default:
      return <NumberInput />;
  }
};
