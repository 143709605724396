import { Box } from "~/components/Box";
import { Col, Row } from "~/components/Grid";
import { Skeleton } from "~/components/Skeleton";
import { Fragment } from "react";

export interface AgencyFormSkeletonProps {}

export function AgencyFormSkeleton() {
  return (
    <Fragment>
      <Box css={{ mb: "$10" }}>
        <Skeleton variant="text-6" />
      </Box>

      <Row gap="6">
        <Col sz="8">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="4">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="12">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="12">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="8">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="4">
          <Skeleton variant="input-md" />
        </Col>
      </Row>

      <Box css={{ my: "$10" }}>
        <Skeleton variant="text-6" />
      </Box>

      <Row gap="6">
        <Col sz="5">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="5">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="2">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="6">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="6">
          <Skeleton variant="input-md" />
        </Col>

        <Col sz="12">
          <Skeleton variant="input-md" />
        </Col>
      </Row>
    </Fragment>
  );
}
