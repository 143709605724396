import { FieldArrayWithId, UseFormReturn } from "react-hook-form";
import { Flex } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col } from "~/components/Grid";
import { UnflownData } from "../../type";
import { FC, useEffect } from "react";
import { NumberInput, TextInput } from "~/components/Input";
import { Divider } from "~/components/Divider";

export type UnflownAirwayFormProps = {
  data: UseFormReturn<
    {
      data: UnflownData[];
    },
    any
  >;
  field: FieldArrayWithId<
    {
      data: UnflownData[];
    },
    "data",
    "id"
  >;
  index: number;
};

export const UnflownAirwayForm: FC<UnflownAirwayFormProps> = ({ data, index, field }) => {
  const { control, watch, setValue } = data;
  const { data: formData } = watch();
  const currenteData = formData.at(index);
  const markup = currenteData?.markup || 0;
  const differenceValue = currenteData?.differenceValue || 0;
  const taxOfChange = currenteData?.taxOfChange || 0;

  useEffect(() => {
    setValue(`data.${index}.totalValue`, markup + differenceValue + taxOfChange);
  }, [setValue, markup, differenceValue, taxOfChange]);

  return (
    <Flex direction="column" key={field.id} gap="5">
      <Col sz="12">
        <FormControl control={control} name={`data.${index}.tracker`} required>
          <FieldLabel>Localizador</FieldLabel>
          <TextInput />
        </FormControl>
      </Col>
      <Divider />
      <Col sz="12">
        <FormControl control={control} name={`data.${index}.differenceValue`} required>
          <FieldLabel>Diferença</FieldLabel>
          <NumberInput prefix="R$ " />
        </FormControl>
      </Col>
      <Col sz="12">
        <FormControl control={control} name={`data.${index}.taxOfChange`} required>
          <FieldLabel>Multa de alteração</FieldLabel>
          <NumberInput prefix="R$ " />
        </FormControl>
      </Col>
      <Col sz="12">
        <FormControl control={control} name={`data.${index}.markup`} required>
          <FieldLabel>Over</FieldLabel>
          <NumberInput prefix="R$ " />
        </FormControl>
      </Col>
      <Col sz="12">
        <FormControl control={control} name={`data.${index}.totalValue`} disabled>
          <FieldLabel>Valor total a pagar</FieldLabel>
          <NumberInput prefix="R$ " />
        </FormControl>
      </Col>
    </Flex>
  );
};
