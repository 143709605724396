import { Provider } from "~/application/types/entities/Provider.type";
import { ProviderDTO } from "../dtos/ProviderDTO";

export const mapProviderDTO = (data: ProviderDTO[]): Provider[] => {
  return data.map((item) => ({
    uuid: item.uuid,
    name: item.name,
    slug: item.slug,
    logo: item.logo,
    active: Boolean(item.active),
    providerServices: item.provider_services.map((providerService) => ({
      uuid: providerService.uuid,
      providerUuid: providerService.provider_uuid,
      serviceType: providerService.service_type,
      ordersOnline: providerService.orders_online,
    })),
  }));
};
