import { Paper } from "~/application/components/Paper";
import { styled } from "~/application/theme";

export const Message = styled(Paper, {
  p: "$1 $2",
  position: "relative",

  variants: {
    senderIsMe: {
      true: {
        borderBottomRightRadius: 0,
        alignSelf: "start",
        backgroundColor: "$primary-light",
      },
      false: {
        borderBottomLeftRadius: 0,
        alignSelf: "end",
        backgroundColor: "transparent",
      },
      default: {
        borderBottomLeftRadius: 0,
        alignSelf: "start",
        backgroundColor: "transparent",
        border: "none",
        wordBreak: "break-word",
      },
    },
  },
});
