import { FC } from "react";
import { Text } from "~/components/Text";
import { AirwaySeatProps } from "./types";
import { Styled } from "./styled";

export const AirwaySeat: FC<AirwaySeatProps> = ({
  isInvisible,
  seatNumber,
  size = "md",
  onClick,
  ...props
}) => {
  const css = {
    "&[data-available=false]": isInvisible
      ? {
          pointerEvents: "none",
          backgroundColor: "transparent",
          borderColor: "transparent",
          "&::after": { backgroundColor: "transparent" },
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "transparent",
            "&::after": { backgroundColor: "transparent" },
          },
        }
      : {
          pointerEvents: "none",
          backgroundColor: "$neutrals-dark",
          borderColor: "$neutrals-base",
          "&::after": { backgroundColor: "$neutrals-darkest" },
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "$primary-dark",
            "&::after": { backgroundColor: "$primary-base" },
          },
        },
  };

  return (
    <Styled.Container
      css={{
        ...css,
        transform: "rotate(90deg)",
      }}
      size={size}
      onClick={onClick}
      {...props}
    >
      {seatNumber && (
        <Text
          css={{
            transform: "rotate(270deg)",
          }}
        >
          {seatNumber}
        </Text>
      )}
    </Styled.Container>
  );
};

AirwaySeat.displayName = "AirwaySeat";
