import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { log } from "~/utils/log";
import { BindCredentialDialog } from "../views/Credential/components/CredentialDialog";
import { dialogService } from "~/components/DialogStack";
import { credentialService } from "~/application/usecases/Credentials";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { queryClient } from "~/services/queryClient";
import { AgreementData } from "~/application/usecases/Credentials/ICredentialService";
import { Agreement } from "~/application/types/entities/BindCredential.type";
import { logError } from "~/presentation/shared/utils/errors";

export interface UseCredentialOptions {
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useCredential";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar credenciais",
  CREATE_SUCCESS_MESSAGE: "Credencial vinculada com sucesso",
  ACTIVE_SUCCESS_MESSAGE: "Credencial ativada com sucesso",
  UPDATE_SUCCESS_MESSAGE: "Credencial atualizada com sucesso",
  INACTIVE_ERROR_MESSAGE: "Erro ao inativar credencial",
  INACTIVE_SUCCESS_MESSAGE: "Credencial Inativada com sucesso",
  ACTIVE_ERROR_MESSAGE: "Erro ao ativar credencial",
  UPDATE_ERROR_MESSAGE: "Erro ao vincular credencial",
  CREATE_ERROR_MESSAGE: "Falha ao vincular credencial",
} as const;

export function useCredentials({ customerId }: UseCredentialOptions) {
  const { mutate: mutateCreateBindCredential } = useMutation(
    (data: AgreementData) => credentialService.bind({ ...data, customerId }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Vinculando credencial" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.AGREEMENTS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: handleToggleAgreement } = useMutation(
    ({ credentialId, isActive }: { credentialId: string; isActive: boolean }) =>
      credentialService.toggleAgreement({ agreementId: credentialId, isActive }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="ativando credencial" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.AGREEMENTS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ACTIVE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ACTIVE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: mutateEditBindCredential, isLoading: isLoadingAgreement } = useMutation(
    [QueryKeys.AGREEMENTS],
    (data: AgreementData) => credentialService.updateAgreement({ ...data }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando credencial" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.AGREEMENTS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const fetchCredential = useCallback((name: string) => credentialService.find({ name }), []);

  const fetchAgreements = useCallback(() => credentialService.agreements(customerId), []);

  const { data } = useQuery<Agreement[]>([QueryKeys.AGREEMENTS], () => fetchAgreements());

  const onOpenDialog = useCallback(() => {
    dialogService.showDialog(
      <BindCredentialDialog
        isNew
        onSubmitProp={mutateCreateBindCredential}
        fetchAgreements={fetchAgreements}
        fetchCredential={(name) => fetchCredential(name).then((data) => data.data)}
      />
    );
  }, []);

  const openEditDialog = useCallback((item: Agreement) => {
    dialogService.showDialog(
      <BindCredentialDialog
        onSubmitProp={mutateEditBindCredential}
        fetchAgreements={fetchAgreements}
        defaultData={item}
        fetchCredential={(name) => fetchCredential(name).then((data) => data.data)}
      />
    );
  }, []);

  return {
    data,
    onOpenDialog,
    openEditDialog,
    isLoadingAgreement,
    onToggleAgreement: handleToggleAgreement,
  };
}
