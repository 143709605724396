import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  ApiError,
  DeepPartial,
  Fee,
  OrderItems,
  PaginatedResource,
} from "~/application/types";
import { feeService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { FeesContainer } from "./FeesContainer";
import { FeeDialog } from "./components/FeeDialog";

const LOG_TAG = "Fee/FeesPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar taxas",
  CREATE_SUCCESS_MESSAGE: "Taxa criada",
  CREATE_ERROR_MESSAGE: "Falha ao criar taxa",
  UPDATE_SUCCESS_MESSAGE: "Taxa atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar taxa",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar taxas",
  ACTIVATE_SUCCESS_MESSAGE: "Taxa ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar taxas",
  INACTIVATE_SUCCESS_MESSAGE: "Taxa inativada",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<Fee> = {
  name: "",
  items: [
    { serviceType: OrderItems.AIRWAY, type: "percent" },
    { serviceType: OrderItems.HOTEL, type: "percent" },
    { serviceType: OrderItems.ROAD, type: "percent" },
    { serviceType: OrderItems.VEHICLE, type: "percent" },
  ],
};

export function FeesPage() {
  const { user } = useUser();
  const agencyId = user.agency.uuid;

  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<PaginatedResource<Fee[]>, ApiError>(
    [QueryKeys.AGENCY_FEES, agencyId, currentPage, { search }],
    () => feeService.find({ page: currentPage, name: search, agencyId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutateAsync: mutateCreateFee } = useMutation(
    async (item: Fee) => await feeService.create({ ...item, agencyId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Fee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created Fee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_FEES, agencyId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutateAsync: mutateUpdateFee } = useMutation(
    async (item: Fee) => await feeService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Fee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Fee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_FEES, agencyId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: Fee) => feeService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Fee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Fee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_FEES, agencyId]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: Fee) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando taxa"
        onConfirm={() => mutateToggleState(item)}
      />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <FeeDialog
        isNew
        onSubmit={mutateCreateFee}
        defaultData={DEFAULT_FORM_DATA}
      />
    );
  }, [agencyId]);

  const handleOpenEditModal = useCallback((item: Fee) => {
    dialogService.showDialog(
      <FeeDialog onSubmit={mutateUpdateFee} defaultData={item} />
    );
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return (
    <FeesContainer
      data={data?.data}
      isLoading={isFetching}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      searchText={searchText}
      setSearchText={handleChangeSearch}
      onGoToPage={setCurrentPage}
      onToggleState={handleToggleState}
      onCreateFee={handleOpenCreateModal}
      onEditFee={handleOpenEditModal}
    />
  );
}
