import React from "react";
import { SimpleDialog } from "../SimpleDialog";

export interface InactivateDialogProps {
  children?: React.ReactNode;
  loadingMessage?: string;
  onConfirm: () => void;
  onCloseClick?: () => void;
}

export function InactivateDialog({
  children,
  loadingMessage,
  onConfirm,
  onCloseClick,
}: InactivateDialogProps) {
  return (
    <SimpleDialog
      size="8"
      title="Confirmar inativação"
      cancelTitle="Cancelar"
      confirmTitle="Aplicar"
      onConfirm={onConfirm}
      onCloseClick={onCloseClick}
      loadingMessage={loadingMessage}
    >
      {children}
    </SimpleDialog>
  );
}
