import { OrderItems } from "~/application/types";
import { PurchansingPolicyType } from "../hooks/type";
import {
  SvgAirplane,
  SvgBus,
  SvgCar,
  SvgHotels,
  SvgMail,
  SvgTicket,
} from "~/components/Icon/icons";

export const POLICIES_TYPES = [
  {
    id: OrderItems.ROAD,
    name: "Rodoviário",
    icon: SvgBus,
  },
  {
    id: OrderItems.AIRWAY,
    name: "Aéreo",
    icon: SvgAirplane,
  },
  {
    id: OrderItems.HOTEL,
    name: "Hotel",
    icon: SvgHotels,
  },
  {
    id: OrderItems.VEHICLE,
    name: "Carro",
    icon: SvgCar,
  },
  {
    id: "reason-trip",
    name: "Motivo de viagem",
    icon: SvgTicket,
  },
] as PurchansingPolicyType[];
