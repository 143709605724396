import { useEffect, useRef, useState } from "react";
import { keyframes, styled } from "~/application/theme";
import { Alert, AlertIcon } from "../Alert";
import { Icon } from "../Icon";
import { SvgClose } from "../Icon/icons";
import { IconButton } from "../IconButton";
import { Text } from "../Text";

export type MessageType = "success" | "error" | "warning" | "info";

export interface SnackbarProps {
  message: { messageText: string; messageType: MessageType };
  milliSeconds: number;
  mobile: boolean;
  onRemoveSnack?: () => void;
}

const SNACKBAR_LEAVE_DURATION_MS = 300;

const slideInAnim = keyframes({
  "0%": {
    transform: "translateX(100%)",
  },
  "100%": {
    transform: "translateX(0%)",
  },
});

const slideOutAndFadeAnim = keyframes({
  "0%": {
    transform: "translateX(0%)",
    opacity: "100%",
  },
  "100%": {
    transform: "translateX(100%)",
    opacity: "0%",
  },
});

const SnackbarRoot = styled("div", {
  animation: `${slideInAnim} $transitions$normal ease-out forwards`,

  "&[data-leaving=true]": {
    animation: `${slideOutAndFadeAnim} ${SNACKBAR_LEAVE_DURATION_MS}ms ease-out forwards`,
  },
});

export function Snackbar({
  message,
  onRemoveSnack,
  milliSeconds = 2500,
}: SnackbarProps) {
  const leaveTimeout = useRef<NodeJS.Timeout>();

  const [isLeaving, setLeaving] = useState(false);

  const removeSnack = () => {
    setLeaving(true);
    if (onRemoveSnack) setTimeout(onRemoveSnack, SNACKBAR_LEAVE_DURATION_MS);
  };

  useEffect(() => {
    leaveTimeout.current = setTimeout(removeSnack, milliSeconds);

    return () => clearTimeout(leaveTimeout.current);
  }, []);

  return (
    <SnackbarRoot data-leaving={isLeaving}>
      <Alert variant={message.messageType}>
        <AlertIcon />

        <Text>{message.messageText}</Text>

        <IconButton onClick={removeSnack}>
          <Icon as={SvgClose} />
        </IconButton>
      </Alert>
    </SnackbarRoot>
  );
}
