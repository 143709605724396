import { useEffect, useState } from "react";
import { FieldError, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { QueryKeys, QueryTimes } from "~/application/constants";
import { OrderItems, OrderStatus, UserContext } from "~/application/types";
import { offlineHotelAmenityService } from "~/application/usecases/OfflineHotelAmenityService";
import { snackbarService } from "~/components/SnackbarStack";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { getNameRoomTypes } from "../../../../utils";
import { EMPTY_OPTION } from "../../constants";
import { QuoteOfflineHotelFormData } from "../../types";
import { formSchema } from "./schema";
import { UseQuoteOfflineHotelFormProps } from "./types";
import { feeService, markupService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";

const SNACKBAR_MESSAGES = {
  LOAD_MARKUP_ERROR_MESSAGE: "Falha ao buscar markup",
  LOAD_FEE_ERROR_MESSAGE: "Falha ao buscar fee",
  LOAD_AMENITIES_ERROR_MESSAGE: "Falha ao listar as necessidades mínimas",
} as const;

export function useQuoteOfflineHotelForm({
  item,
  order,
  defaultData,
  onSubmit,
}: UseQuoteOfflineHotelFormProps) {
  const { user } = useUser();
  const agencyId = user.agency.uuid;
  const customerId = order.customer.uuid;

  const { LOG_TAG } = useLogTag();

  const isEditing = !!defaultData?.options?.at(0)?.uuid;

  const defaultOptions = defaultData?.options;

  const [currentPage, setCurrentPage] = useState(1);

  const [providerValue, setProviderValue] = useState(
    defaultOptions?.at(currentPage - 1)?.providerValue || 0
  );

  const [hotelMarkup, setHotelMarkup] = useState(
    defaultOptions?.at(currentPage - 1)?.hotelMarkup || 0
  );

  const [hotelFee, setHotelFee] = useState(defaultOptions?.at(currentPage - 1)?.hotelFee || 0);

  const [hotelFeeType, setHotelFeeType] = useState<"fixed" | "percent">("fixed");

  const { data: amenities, isLoading: isLoadingAmenities } = useQuery(
    [QueryKeys.AMENITIES],
    () => offlineHotelAmenityService.getAll(),
    {
      staleTime: QueryTimes.NORMAL,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_AMENITIES_ERROR_MESSAGE, "error");
      },
    }
  );

  const { data: markup } = useQuery(
    [QueryKeys.MARKUP_PER_CUSTOMER, agencyId, customerId],
    () => markupService.findByCustomer({ agencyId, customerId }),
    {
      staleTime: QueryTimes.NONE,
      cacheTime: QueryTimes.NONE,
      onSuccess(data) {
        if (!isEditing && data) {
          const markup = data.items.find(
            (item) => item.serviceType === (OrderItems.HOTEL || OrderItems.HOTEL_OFFLINE)
          );
          setHotelMarkup(markup!.offlineValue);
          setValue(`options.${currentPage - 1}.hotelMarkup`, markup!.offlineValue);
        }
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_MARKUP_ERROR_MESSAGE, "error");
      },
    }
  );

  const { data: fee } = useQuery(
    [QueryKeys.FEE_PER_CUSTOMER, agencyId, customerId],
    () => feeService.findByCustomer({ agencyId, customerId }),
    {
      staleTime: QueryTimes.NONE,
      cacheTime: QueryTimes.NONE,
      onSuccess(data) {
        if (!isEditing && data) {
          const fee = data.items.find(
            (item) => item.serviceType === (OrderItems.HOTEL || OrderItems.HOTEL_OFFLINE)
          );
          setHotelFee(fee!.offlineValue);
          setHotelFeeType(fee!.type);
          setValue(`options.${currentPage - 1}.hotelFee`, fee!.offlineValue);
        }
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_FEE_ERROR_MESSAGE, "error");
      },
    }
  );

  const roomTypeOptions = getNameRoomTypes(item)?.map((item) => ({
    uuid: item,
    name: item,
  }));

  const isAgencyEmployee = user?.context === UserContext.Agency;

  const {
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    watch,
    getFieldState,
  } = useForm<QuoteOfflineHotelFormData>({
    defaultValues: defaultData as QuoteOfflineHotelFormData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const { options } = watch();

  useEffect(() => {
    const errors = getFieldState("options")?.error as unknown as FieldError[];
    const index = (errors || []).findIndex((error) => error);

    if (index >= 0) {
      setCurrentPage(index + 1);
    }
  }, [formState.errors, getFieldState]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  const onAddQuote = () => {
    setCurrentPage((old) => old + 1);
    append({
      ...EMPTY_OPTION,
      checkIn: item.checkIn,
      checkOut: item.checkOut,
      hotelFee,
      hotelMarkup,
    });
  };

  const onSubmitForm = (data: QuoteOfflineHotelFormData) => {
    const invalidProviderValueIndex = data.options.findIndex((o) => !o.providerValue);

    if (invalidProviderValueIndex >= 0) {
      setCurrentPage(invalidProviderValueIndex + 1);
      return setError(`options.${invalidProviderValueIndex}.providerValue`, {
        type: "required",
      });
    }

    const options = data.options.map((option) => {
      const hotelDailyValue =
        option.providerValue * (option.hotelMarkup / 100 + 1) + option.hotelFee;

      const hotelOthersTaxesValue = option.otherTaxes || 0;
      const checkIn = option.checkIn;
      const checkOut = option.checkOut;
      checkIn?.setHours(0, 0, 0, 0);
      checkOut?.setHours(0, 0, 0, 0);

      const hotelDays = dayjs(option.checkOut).diff(option.checkIn, "day") || 1;

      return {
        ...option,
        customerValue: hotelDailyValue * hotelDays + hotelOthersTaxesValue,
      };
    });

    onSubmit?.({ ...data, options });
  };

  useEffect(() => {
    if (formState.errors.options) {
      const errors = Object.entries(formState.errors.options[currentPage - 1] || {});

      if (errors?.length === 0) return;

      const [, value] = errors?.at(0) as [
        string,
        (
          | FieldError & {
              name: { message: string };
            }
        )
      ];

      const snackbar = snackbarService.getSnackbar();
      const hasError = snackbar?.message?.messageText === value?.message;

      if (hasError) return;

      if (value?.message) {
        snackbarService.showSnackbar(`${value?.message}`, "error", 2000);
        return;
      }

      snackbarService.showSnackbar(`${value?.name?.message}`, "error", 2000);
    }
  }, [formState.errors]);

  const onRemoveQuote = () => {
    remove(currentPage - 1);
    setCurrentPage((old) => (old === 1 ? old : old - 1));
  };

  const isQuotingStatus = order?.status === OrderStatus.QUOTING;

  const cannotRemoveQuote = !isQuotingStatus || (currentPage < 2 && fields.length < 2);

  return {
    cannotRemoveQuote,
    isEditing,
    isAgencyEmployee,
    roomTypeOptions,
    options,
    providerValue,
    hotelMarkup,
    hotelFee,
    amenities,
    isLoadingAmenities,
    fields,
    control,
    currentPage,
    setError,
    clearErrors,
    setCurrentPage,
    setProviderValue,
    setHotelMarkup,
    setHotelFee,
    onRemoveQuote,
    onAddQuote,
    onSubmitForm,
    handleSubmit,
    setValue,
    hotelFeeType,
    fee,
    markup,
  };
}
