import {
  Markup,
  MarkupItem,
  MarkupPerSuplier,
  Provider,
} from "~/application/types";
import {
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { Fragment, useCallback, useState } from "react";
import { MarkupListItem } from "./components/MarkupListItem";
import { MarkupForm, ProviderForm } from "./MarkupsPage";
import { UseQueryResult } from "@tanstack/react-query";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Box } from "~/components/Box";

export interface MarkupsContainerProps {
  isLoading: boolean;
  markupsList: Markup[] | undefined;
  currentPage: number;
  lastPage: number;
  searchText: string;
  providersList: ProviderForm[];
  setSearchText: (searchO: string) => void;
  onGoToPage: (page: number) => void;
  onCreateMarkup: () => void;
  onEditMarkup: (item: Markup) => void;
  onToggleState: (data: Markup) => void;
  onMarkupPerSuplier: (data: MarkupForm) => Promise<void>;
  useMarkupsPerSuplier: (
    markupId: string
  ) => UseQueryResult<MarkupPerSuplier[], unknown>;
}

export function MarkupsContainer({
  markupsList,
  currentPage,
  lastPage,
  searchText,
  setSearchText,
  onGoToPage,
  onCreateMarkup,
  onEditMarkup,
  onToggleState,
  onMarkupPerSuplier,
  providersList,
  useMarkupsPerSuplier,
}: MarkupsContainerProps) {
  const isMobile = useMobile();
  const [searchInput, setSearchInput] = useState(false);

  const listRenderer = useCallback(
    (item: Markup) => (
      <MarkupListItem
        data={item}
        providersList={providersList || []}
        onToggleState={onToggleState}
        onEditClick={onEditMarkup}
        submit={onMarkupPerSuplier}
        key={item.uuid}
        useMarkupsPerSuplier={useMarkupsPerSuplier}
      />
    ),
    [onToggleState, onEditMarkup, providersList, onMarkupPerSuplier]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title={searchInput && isMobile ? "" : "Markups"} />

          <Box
            onClick={() => setSearchInput(false)}
            css={{
              zIndex: 10000,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(100, 100, 100, 0.5)",
              display: !searchInput ? "none" : "block",
            }}
          />

          <AppBarActions>
          {!isMobile ? (
              <SearchBar
                search={searchText}
                onSearchChange={setSearchText}
                placeholder="Encontrar empresa"
              />
            ) : (
              <SearchBar
                search={searchText}
                onSearchChange={setSearchText}
                style={{
                  width: searchInput ? "100%" : "27%",
                  marginRight: "10px",
                  paddingTop: "0px",
                  zIndex: "10001",
                }}
                onClick={() => setSearchInput(true)}
                onBlur={() => setSearchInput(false)}
              />
            )}

            <Button title="Novo markup" onClick={onCreateMarkup} css={{ "@mxlg": { background: "#fff", height: "$13", px: "$5" }}}>
              <Icon as={SvgPlus} css={{ "@mxlg": { fill: "#000" }}} />
              {!isMobile && <Text>Novo markup</Text>}
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20", "@mxlg": { p: "$5" }}}>
        <LazyList
          gap="6"
          items={markupsList}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhum markup corresponde aos dados informados</Text>
              ) : (
                <Text>Você não possui markups cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </Fragment>
  );
}
