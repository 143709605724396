import React, { useState } from "react";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Text } from "~/components/Text";
import { PurchaseReport } from "../types";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Collapse } from "react-collapse";
import { LogoutCustomers, NewClientsPurchaseMonth, NotBuyingCustomers, OperationalInterventions, OrdersNotIssuedWeek, PendingFirstPurchase } from "~/infrastructure/api/mappers/mapDashboardBIDTO";
import { NotBuyingCustomerReport } from "~/infrastructure/api/dtos/DashboardBIDTO";
import { Card } from "~/components/Card";
import { Pagination } from "~/components/Pagination";

export const ClientPendingFirstPurchaseDialogContent: React.FC<
  PendingFirstPurchase
> = ({ report }) => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const toggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <Flex direction="column" css={{ width: "100%" }}>
      <Grid
        columns={{ "@initial": 4, "@mxlg": 2 }}
        css={{
          borderBottom: "1px solid $colors$neutrals-light",
          pb: "$5",
          "@mxlg": { display: "none" },
        }}
      >
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Nome Fantasia
        </Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Nome Colaborador
        </Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Qtd. Logins
        </Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Qtd. Pedidos não emitidos
        </Text>
      </Grid>

      {report?.map((client, index) => (
        <Flex
          key={index}
          direction="column"
          css={{ borderBottom: "1px solid $colors$neutrals-light", py: "$5" }}
        >
          <Grid gap={3} columns={{ "@initial": 4, "@mxlg": 2 }}>
            <Flex gap="2" direction="column" align="start" css={{ pb: "$4" }}>
              <Text
                size="3"
                variant="dark"
                css={{ fw: 500, "@lg": { display: "none" } }}
              >
                Nome Fantasia
              </Text>
              <Text size="3" fw="600">
                {client.tradingName}
              </Text>
            </Flex>
            <Flex direction="column" gap="2">
              <Text
                size="3"
                variant="dark"
                css={{ "@lg": { display: "none" } }}
              >
                Nome Colaborador
              </Text>
              <Text size="3" fw="600">
                {client.loggedUserName}
              </Text>
            </Flex>

            <Text css={{ "@mxlg": { display: "none" } }}>
              {client.loginAmount}
            </Text>
            <Text css={{ "@mxlg": { display: "none" } }}>
              {client.notIssuedOrdersAmount}
            </Text>
          </Grid>

          <Flex justify="center">
            <IconButton
              onClick={() => toggleDropdown(index)}
              size="md"
              css={{ "@mxlg": { marginTop: "$2" }, "@lg": { display: "none" } }}
            >
              <Icon
                as={openDropdown === index ? SvgChevronUp : SvgChevronDown}
              />
            </IconButton>
          </Flex>

          <Collapse
            isOpened={openDropdown === index}
            style={{ padding: "10px", background: "#f0f0f0" }}
          >
            {openDropdown === index && (
              <Flex direction="column" gap="2" css={{ mt: "$2" }}>
                <Grid
                  columns={2}
                  gap="3"
                  css={{ justifyContent: "space-between" }}
                >
                  <Text css={{ fontWeight: "bold" }}>Qtd. Logins:</Text>
                  <Text css={{ ta: "end" }}>{client.loginAmount}</Text>
                </Grid>
                <Grid
                  columns={2}
                  gap="3"
                  css={{ justifyContent: "space-between" }}
                >
                  <Text css={{ fontWeight: "bold" }}>
                    Qtd. Pedidos não emitidos:
                  </Text>
                  <Text css={{ ta: "end" }}>
                    {client.notIssuedOrdersAmount}
                  </Text>
                </Grid>
              </Flex>
            )}
          </Collapse>
        </Flex>
      ))}
    </Flex>
  );
};

export const NewClientsDialogContent: React.FC<NewClientsPurchaseMonth> = (report) => {
  
  return (
    <Flex direction="column" css={{ width: "100%" }}>
      <Grid
        columns={2}
        css={{ borderBottom: "1px solid $colors$neutrals-light", pb: "$5" }}
      >
        <Text css={{ fontWeight: "bold" }}>Nome Fantasia</Text>
        <Text css={{ fontWeight: "bold" }}>Qtd. pedidos</Text>
      </Grid>

      {!report?.report.length ? (
        <Flex css={{ p: "$5" }} align="center" justify="center">
          <Text>Nenhum dado para ser exibido</Text>
        </Flex>
      ) : (
        <>
          {report?.report?.map((client, index) => (
            <Grid
              key={index}
              columns={3}
              css={{
                py: "$5",
                borderBottom: "1px solid $colors$neutrals-light",
              }}
            >
              <Text>{client.tradingName}</Text>
              <Text css={{ ta: "center" }}>{client.orderAmount}</Text>
            </Grid>
          ))}
        </>
      )}
    </Flex>
  );
};

export const InactiveClientsDialogContent: React.FC<NotBuyingCustomers> = ({
  report,
}) => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const toggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <Flex direction="column" css={{ width: "100%" }}>
      <Grid
        columns={{ "@initial": 4, "@mxlg": 2 }}
        css={{ borderBottom: "1px solid $colors$neutrals-light", pb: "$5" }}
      >
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Nome Fantasia
        </Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Nome do colaborador
        </Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Qtd de logins
        </Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Qtd. Pedidos não emitidos
        </Text>
      </Grid>

      {!report?.length ? (
        <Flex css={{ p: "$5" }} align="center" justify="center">
          <Text>Nenhum dado para ser exibido</Text>
        </Flex>
      ) : (
        <>
          {report?.map((client, index) => (
            <Flex
              direction="column"
              css={{
                py: "$5",
                borderBottom: "1px solid $colors$neutrals-light",
              }}
            >
              <Grid gap="3" key={index} columns={{ "@initial": 4, "@mxlg": 2 }}>
                <Flex
                  gap="2"
                  direction="column"
                  align="start"
                  css={{ pb: "$4" }}
                >
                  <Text
                    size="3"
                    variant="dark"
                    css={{ fw: 500, "@lg": { display: "none" } }}
                  >
                    Nome Fantasia
                  </Text>
                  <Text size="3" fw="600">
                    {client.tradingName}
                  </Text>
                </Flex>
                <Flex
                  gap="2"
                  direction="column"
                  align="start"
                  css={{ pb: "$4" }}
                >
                  <Text
                    size="3"
                    variant="dark"
                    css={{ fw: 500, "@lg": { display: "none" } }}
                  >
                    Nome Fantasia
                  </Text>
                  <Text size="3" fw="600">
                    {client.loggedUserName}
                  </Text>
                </Flex>
                <Text css={{ "@mxlg": { display: "none" } }}>
                  {client.loginAmount}
                </Text>
                <Text css={{ "@mxlg": { display: "none" } }}>
                  {client.notIssuedOrdersAmount}
                </Text>
                <Text>{client.issuedOrdersAmount}</Text>
              </Grid>

              <Flex justify="center">
                <IconButton
                  onClick={() => toggleDropdown(index)}
                  size="md"
                  css={{
                    "@mxlg": { marginTop: "$2" },
                    "@lg": { display: "none" },
                  }}
                >
                  <Icon
                    as={openDropdown === index ? SvgChevronUp : SvgChevronDown}
                  />
                </IconButton>
              </Flex>

              <Collapse
                isOpened={openDropdown === index}
                style={{ padding: "10px", background: "#f0f0f0" }}
              >
                {openDropdown === index && (
                  <Flex direction="column" gap="2" css={{ mt: "$2" }}>
                    <Grid
                      columns={2}
                      gap="3"
                      css={{ justifyContent: "space-between" }}
                    >
                      <Text size="3" fw="600" css={{ fontWeight: "bold" }}>
                        Qtd. Logins:
                      </Text>
                      <Text css={{ ta: "end" }}>{client.loginAmount}</Text>
                      <Text size="3" fw="600" css={{ fontWeight: "bold" }}>
                        Qtd. Pedidos não emitidos:
                      </Text>
                      <Text css={{ ta: "end" }}>
                        {client.notIssuedOrdersAmount}
                      </Text>
                    </Grid>
                  </Flex>
                )}
              </Collapse>
            </Flex>
          ))}
        </>
      )}
    </Flex>
  );
};

export const UnaccessibleCustomers: React.FC<LogoutCustomers> = ({ report }) => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const toggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <Flex direction="column" css={{ width: "100%" }}>
      <Grid
        columns={{ "@initial": 5, "@mxlg": 2 }}
        css={{ borderBottom: "1px solid $colors$neutrals-light", pb: "$5", "@mxlg": { display: "none" } }}
      >
        <Text css={{ fontWeight: "bold" }}>Nome Fantasia</Text>
        <Text css={{ fontWeight: "bold" }}>Nome do colaborador</Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Qtd de logins
        </Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Qtd. Pedidos emitidos
        </Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Qtd. Pedidos não emitidos
        </Text>
      </Grid>

      {!report?.length ? (
        <Flex css={{ p: "$5" }} align="center" justify="center">
          <Text>Nenhum dado para ser exibido</Text>
        </Flex>
      ) : (
        <>
          {report?.map((client, index) => (
            <Flex
              direction="column"
              css={{
                py: "$5",
                borderBottom: "1px solid $colors$neutrals-light",
              }}
            >
              <Grid gap="3" key={index} columns={{ "@initial": 5, "@mxlg": 2 }}>
                <Flex
                  gap="2"
                  direction="column"
                  align="start"
                  css={{ pb: "$4" }}
                >
                  <Text
                    size="3"
                    variant="dark"
                    css={{ fw: 500, "@lg": { display: "none" } }}
                  >
                    Nome Fantasia
                  </Text>
                  <Text size="3" fw="600">
                    {client.tradingName}
                  </Text>
                </Flex>
                <Flex
                  gap="2"
                  direction="column"
                  align="start"
                  css={{ pb: "$4" }}
                >
                  <Text
                    size="3"
                    variant="dark"
                    css={{ fw: 500, "@lg": { display: "none" } }}
                  >
                    Nome Fantasia
                  </Text>
                  <Text size="3" fw="600">
                    {client.loggedUserName}
                  </Text>
                </Flex>
                <Text css={{ "@mxlg": { display: "none" } }}>
                  {client.loginAmount}
                </Text>
                <Text css={{ "@mxlg": { display: "none" } }}>
                  {client.issuedOrdersAmount}
                </Text>
                <Text css={{ "@mxlg": { display: "none" } }}>
                  {client.notIssuedOrdersAmount}
                </Text>
              </Grid>

              <Flex justify="center">
                <IconButton
                  onClick={() => toggleDropdown(index)}
                  size="md"
                  css={{
                    "@mxlg": { marginTop: "$2" },
                    "@lg": { display: "none" },
                  }}
                >
                  <Icon
                    as={openDropdown === index ? SvgChevronUp : SvgChevronDown}
                  />
                </IconButton>
              </Flex>

              <Collapse
                isOpened={openDropdown === index}
                style={{ padding: "10px", background: "#f0f0f0" }}
              >
                {openDropdown === index && (
                  <Flex direction="column" gap="2" css={{ mt: "$2" }}>
                    <Grid
                      columns={2}
                      gap="3"
                      css={{ justifyContent: "space-between" }}
                    >
                      <Text size="3" fw="600" css={{ fontWeight: "bold" }}>
                        Qtd. Logins:
                      </Text>
                      <Text size="3" fw="600" css={{ ta: "end" }}>
                        {client.loginAmount}
                      </Text>
                      <Text size="3" fw="600" css={{ fontWeight: "bold" }}>
                        Qtd. Pedidos emitidos:
                      </Text>
                      <Text size="3" fw="600" css={{ ta: "end" }}>
                        {client.issuedOrdersAmount}
                      </Text>
                      <Text size="3" fw="600" css={{ fontWeight: "bold" }}>
                        Qtd. Pedidos não emitidos:
                      </Text>
                      <Text size="3" fw="600" css={{ ta: "end" }}>
                        {client.notIssuedOrdersAmount}
                      </Text>
                    </Grid>
                  </Flex>
                )}
              </Collapse>
            </Flex>
          ))}
        </>
      )}
    </Flex>
  );
};

export const OrderNotIssuedWeek: React.FC<OrdersNotIssuedWeek> = ({ report }) => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const toggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <Flex direction="column">
      <Grid
        columns={{ "@initial": 3 ,"@mxlg": 2 }}
        css={{ borderBottom: "1px solid $colors$neutrals-light", pb: "$5", "@mxlg": { display: "none" }}}
      >
        <Text css={{ fontWeight: "bold" }}>Nome Fantasia</Text>
        <Text css={{ fontWeight: "bold" }}>Nome do colaborador</Text>
        <Text css={{ fontWeight: "bold", "@mxlg": { display: "none" } }}>
          Qtd. Pedidos não emitidos
        </Text>
      </Grid>

      {!report?.length ? (
        <Flex css={{ p: "$5" }} align="center" justify="center">
          <Text>Nenhum dado para ser exibido</Text>
        </Flex>
      ) : (
        <>
          {report?.map((client, index) => (
            <Flex
              direction="column"
              css={{
                py: "$5",
                borderBottom: "1px solid $colors$neutrals-light",
              }}
            >
              <Grid gap="3" key={index} columns={{ "@initial": 3, "@mxlg": 2 }}>
                <Flex
                  gap="2"
                  direction="column"
                  align="start"
                  css={{ pb: "$4" }}
                >
                  <Text
                    size="3"
                    variant="dark"
                    css={{ fw: 500, "@lg": { display: "none" } }}
                  >
                    Nome Fantasia
                  </Text>
                  <Text size="3" fw="600">
                    {client.tradingName}
                  </Text>
                </Flex>
                <Flex
                  gap="2"
                  direction="column"
                  align="start"
                  css={{ pb: "$4" }}
                >
                  <Text
                    size="3"
                    variant="dark"
                    css={{ fw: 500, "@lg": { display: "none" } }}
                  >
                    Nome Fantasia
                  </Text>
                  <Text size="3" fw="600">
                    {client.loggedUserName}
                  </Text>
                </Flex>
                <Text css={{ "@mxlg": { display: "none" } }}>
                  {client.notIssuedOrdersAmount}
                </Text>
              </Grid>

              <Flex justify="center">
                <IconButton
                  onClick={() => toggleDropdown(index)}
                  size="md"
                  css={{
                    "@mxlg": { marginTop: "$2" },
                    "@lg": { display: "none" },
                  }}
                >
                  <Icon
                    as={openDropdown === index ? SvgChevronUp : SvgChevronDown}
                  />
                </IconButton>
              </Flex>

              <Collapse
                isOpened={openDropdown === index}
                style={{ padding: "10px", background: "#f0f0f0" }}
              >
                {openDropdown === index && (
                  <Flex direction="column" gap="2" css={{ mt: "$2" }}>
                    <Grid
                      columns={2}
                      gap="3"
                      css={{ justifyContent: "space-between" }}
                    >
                      <Text size="3" fw="600" css={{ fontWeight: "bold" }}>
                        Qtd. Pedidos não emitidos:
                      </Text>
                      <Text size="3" fw="600" css={{ ta: "end" }}>
                        {client.notIssuedOrdersAmount}
                      </Text>
                    </Grid>
                  </Flex>
                )}
              </Collapse>
            </Flex>
          ))}
        </>
      )}
    </Flex>
  );
};

export const OperationInterventions: React.FC<OperationalInterventions | undefined> = (
  report
) => {

  return (
    <Flex direction="column" css={{ width: "100%" }}>
      <Grid
        columns={2}
        css={{ borderBottom: "1px solid $colors$neutrals-light", pb: "$5" }}
      >
        <Text css={{ fontWeight: "bold" }}>Nome do fornecedor</Text>
        <Text css={{ fontWeight: "bold" }}>Qtd. de intervenções</Text>
      </Grid>

      {report?.report && report.report.length > 0 ? (
        <>
          {report?.report?.map((client, index) => (
            <Grid
              key={index}
              columns={2}
              css={{
                py: "$5",
                borderBottom: "1px solid $colors$neutrals-light",
              }}
            >
              <Text>{client.providerName}</Text>
              <Text>{client.interventionsAmount}</Text>
            </Grid>
          ))}
        </>
      ) : (
        <Flex css={{ p: "$5" }} align="center" justify="center">
          <Text>Nenhum dado para ser exibido</Text>
        </Flex>
      )}
    </Flex>
  );
};
