import { useCallback, useState } from "react";
import { To, useSearchParams } from "react-router-dom";

import { OfflineHotelRoom } from "~/application/types";
import {
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { Text } from "~/components/Text";
import { OfflineHotelRoomListItem } from "./components/OfflineHotelRoomListItem";
import { Flex } from "~/components/Flex";
import { Box } from "~/components/Box";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { IconButton } from "~/components/IconButton";
import { Footer } from "~/components/Document";
import { Pagination } from "~/components/Pagination";

export interface OfflineHotelRoomsContainerProps {
  offlineHotelRooms: OfflineHotelRoom[] | undefined;
  currentPage: number;
  lastPage: number;
  onCreateOfflineHotelRoom: () => void;
  onEditOfflineHotelRoom: (data: OfflineHotelRoom) => void;
  onGoToPage: (page: number) => void;
}

export function OfflineHotelRoomsContainer({
  offlineHotelRooms,
  currentPage,
  lastPage,
  onCreateOfflineHotelRoom,
  onEditOfflineHotelRoom,
  onGoToPage,
}: OfflineHotelRoomsContainerProps) {
  const [searchInput, setSearchInput] = useState(false);

  const [searchParams] = useSearchParams();
  const hotelDescription = searchParams.get('hotel_description');

  const listRenderer = useCallback(
    (item: OfflineHotelRoom) => (
      <OfflineHotelRoomListItem
        key={item.uuid}
        data={item}
        onEditOfflineHotelRoom={onEditOfflineHotelRoom}
      />
    ),
    [onEditOfflineHotelRoom]
  );

  return (
    <>
      <AppBar>
        <AppBarContainer>
          <Flex
            align="center"
            gap="6"
            css={{
              "@mxlg": {
                display: searchInput ? "none" : "flex",
                width: "100%",
              },
            }}
          >
            <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
              <Link to={-1 as To}>
                <IconButton size="md">
                  <Icon as={SvgChevronLeft} />
                </IconButton>
              </Link>
            </Tooltip>

            <AppBarHeading title={hotelDescription} />
          </Flex>

          <Box
            onClick={() => setSearchInput(false)}
            css={{
              zIndex: 10000,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(100, 100, 100, 0.5)",
              display: !searchInput ? "none" : "block",
            }}
          />

          <AppBarActions>
            <Button title="Novo quarto" onClick={onCreateOfflineHotelRoom}>
              <Icon as={SvgPlus} css={{ "@mxlg": { fill: "black" } }} />
              <Text>Novo quarto</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container
        css={{
          pt: "$10",
          pb: "$20",
          "@mxlg": {
            p: "$5",
          },
        }}
      >
        <LazyList
          gap="6"
          items={offlineHotelRooms}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você não possui quartos cadastrados</Text>
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </>
  );
}
