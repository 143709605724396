import { Action, Profile } from "~/application/types";
import { ActionDTO, ProfileDTO } from "../dtos";

export function mapActionDTO(data: ActionDTO): Action {
  return {
    action: data.action,
    description: data.description,
    slug: data.slug,
    uuid: data.uuid,
    group: data.group,
    groupDescription: data.group_description,
  };
}

export function mapProfileDTO(data: ProfileDTO): Profile {
  return {
    uuid: data.uuid,
    name: data.name,
    actions: data?.actions?.map(mapActionDTO),
    isApprover: Boolean(data.approver),
    isDefault: Boolean(data.default),
    isExpenseApprover: Boolean(data.is_expense_approver),
  };
}
