import { Control } from "react-hook-form";
import { CustomerEmployee } from "~/application/types";
import { CustomerEmployeeFilter, ICustomerEmployeeFilters } from "~/presentation/shared/types";

export const LOG_TAG = "CustomerSettings/CustomerEmployeesPage";

export const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar funcionários da agência",
  CREATE_SUCCESS_MESSAGE: "Funcionário criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar funcionário",
  UPDATE_SUCCESS_MESSAGE: "Funcionário atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar funcionário",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar funcionário",
  ACTIVATE_SUCCESS_MESSAGE: "Funcionário ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar funcionário",
  INACTIVATE_SUCCESS_MESSAGE: "Funcionário inativado",
} as const;

export interface UseCustomerEmployeesPageResult {
  profilesOptions?: CustomerEmployeeFilter[];
  control: Control<ICustomerEmployeeFilters, any>;
  activenessOptions?: CustomerEmployeeFilter[];
  data?: CustomerEmployee[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  searchText: string;
  setSearchText: (search: string) => void;
  onGoToPage: (value: number) => void;
  onCreateCustomerEmployee: () => void;

  onCleanFilters: () => void;
  onEditCustomerEmployee: (item: CustomerEmployee) => void;
  onToggleCustomerEmployeeState: (item: CustomerEmployee) => void;
}
