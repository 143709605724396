import { Dispatch, Fragment, SetStateAction, useCallback, useState } from "react";
import { Customer } from "~/application/types";
import { AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar, Select } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { CustomerListItem } from "./components/CustomerListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Flex } from "~/components/Flex";
import { Box } from "~/components/Box";
import { Col } from "~/components/Grid";

export interface CustomersContainerProps {
  isLoading: boolean;
  agenciesList: Customer[] | undefined;
  currentPage: number;
  lastPage: number;
  searchText: string;
  setSearchText: (searchO: string) => void;
  onGoToPage: (page: number) => void;
  onToggleState: (data: Customer) => void;
  onToggleFraudBlocking: (data: Customer) => void;
  onChangeCustomerFilter: (data: { label: string, value: null }) => void
  updateCreditLimit: (item: Customer) => void;
}

export function CustomersContainer({
  agenciesList,
  currentPage,
  lastPage,
  searchText,
  setSearchText,
  onGoToPage,
  onToggleState,
  onToggleFraudBlocking,
  onChangeCustomerFilter,
  updateCreditLimit,
}: CustomersContainerProps) {
  const [searchInput, setSearchInput] = useState(false);
  const isMobile = useMobile();

  const customerStatusOptions = [
  { 
    label: "",
    value:  null
  },
  {
    label: "Ativo",
    value: true
  },
  {
    label: "Inativo",
    value: false
  }
]

  const listRenderer = useCallback(
    (item: Customer) => (
      <CustomerListItem
        data={item}
        onToggleState={onToggleState}
        onToggleFraudBlocking={onToggleFraudBlocking}
        key={item.uuid}
        updateCreditLimit={updateCreditLimit}
      />
    ),
    [onToggleState, onToggleFraudBlocking, updateCreditLimit]
  );
  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <Flex
            css={{
              "@mxlg": {
                display: searchInput ? "none" : "flex",
                width: "100%",
              },
            }}
          >
            <AppBarHeading title="Empresas" description="Empresas vinculadas ao biztrip" />
          </Flex>

          {/* overlay input focus */}
          <Box
            onClick={() => setSearchInput(false)}
            css={{
              zIndex: 10000,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(100, 100, 100, 0.5)",
              display: !searchInput ? "none" : "block",
            }}
          />
          

          <AppBarActions>
            {!isMobile ? (
             <>
               <Col sz="auto">
                <Select 
                  onChange={onChangeCustomerFilter} 
                  options={customerStatusOptions} 
                  size="sm" 
                  placeholder="Status"  
                  portalled 
                />
               </Col>
               <Col>
                  <SearchBar
                    search={searchText}
                    style={{ padding: "2.8px" }}
                    onSearchChange={setSearchText}
                    placeholder="Encontrar empresa"
                  />
               </Col>
             </>
            ) : (
              
              <SearchBar
                search={searchText}
                onSearchChange={setSearchText}
                style={{
                  width: searchInput ? "100%" : "30%",
                  marginRight: "10px",
                  paddingTop: "0px",
                  zIndex: "10001",
                }}
                onClick={() => setSearchInput(true)}
                onBlur={() => setSearchInput(false)}
              />
            )}

            <Link title="Nova empresa" to="/configuracoes/empresas/nova-empresa">
              <Button
                css={{
                  p: "$7",
                  "@mxlg": {
                    background: "#fff",
                  },
                }}
              >
                <Icon
                  as={SvgPlus}
                  css={{
                    "@mxlg": {
                      fill: "black",
                    },
                  }}
                />
                <Text
                  css={{
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  Nova empresa
                </Text>
              </Button>
            </Link>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container
        css={{
          pt: "$10",
          pb: "$20",
          "@mxlg": {
            p: "$5",
          },
        }}
      >
        <LazyList
          gap="6"
          items={agenciesList}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhuma empresa corresponde aos dados informados</Text>
              ) : (
                <Text>Você não possui empresas cadastradas</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Fragment>
  );
}
