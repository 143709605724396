import type { OrderVehicle } from "~/application/types";
import { api } from "~/infrastructure/api";
import { OrderVehicleDTO } from "~/infrastructure/api/dtos";
import { mapOrderVehicleDTO } from "~/infrastructure/api/mappers";
import type {
  ICreateOrderVehicleParams,
  IOrderVehicleService,
} from "./IOrderVehicleService";

export class OrderVehicleService implements IOrderVehicleService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async create(data: ICreateOrderVehicleParams): Promise<void> {
    throw new Error("Method not implemented.");
  }

  async findByOrderId(orderId: string): Promise<OrderVehicle[]> {
    return await api
      .get<{ data: OrderVehicleDTO[] }>(`/orders/${orderId}/vehicle`)
      .then(({ data }) => data.data.map(mapOrderVehicleDTO));
  }

  async delete(itemId: string): Promise<void> {
    await api.post<void>(`/orders/vehicle/${itemId}/cancel`);
  }
}
