import { createContext } from "react";
import { Order, VehicleQuery } from "~/application/types";
import { VehicleBookingActions, VehicleBookingInfo } from "../utils/vehicle.utils";
import { VehicleQueryClient } from "../utils/vehicleQueryClient";
import { useVehicleBudgetResult } from "../hooks/useVehicleBudget/type";

export type VehicleBookingContextType = {
  queryClient: VehicleQueryClient;
  queryData: VehicleQuery;
  order?: Order;
  bookingInfo: VehicleBookingInfo;
  actions: VehicleBookingActions;
  vehicleBudget: useVehicleBudgetResult;
};

export const VehicleBookingContext = createContext({} as VehicleBookingContextType);

export const VehicleBookingProvider = VehicleBookingContext.Provider;
