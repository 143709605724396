import { ApiError, HotelDetails, HotelDetailsLinks } from "~/application/types";
import { api } from "~/infrastructure/api";
import {
  HotelDetailsDTO,
  HotelDetailsLinksDTO,
} from "~/infrastructure/api/dtos";
import { mapHotelDetailsDTO } from "~/infrastructure/api/mappers";
import type { IHotelDetailsService } from "./IHotelDetailsService";

export class HotelDetailsService implements IHotelDetailsService {
  async query(searchKey: string, hotelId: string): Promise<HotelDetailsLinks> {
    return api
      .post<HotelDetailsLinksDTO>("/hotels/detail", {
        search_key: searchKey,
        id: hotelId,
      })
      .then(({ data }) => ({
        link: data.link,
        waitTime: data.wait_time,
      }));
  }

  async find(url: string): Promise<HotelDetails> {
    return api.get<HotelDetailsDTO>(url).then(({ data }) => {
      if (ApiError.isAsyncError(data)) {
        throw new ApiError({
          data: data,
          statusCode: 204,
        });
      }

      return mapHotelDetailsDTO(data);
    });
  }
}
