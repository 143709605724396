import { FC, Fragment, useCallback } from "react";
import { FlightSegment } from "~/application/types";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClock } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Track } from "~/presentation/shared/components/Track";
import { timeInterval } from "~/utils/date.utils";
import { getAirlineUrl } from "../../../utils";
import { FlightSegmentListItem } from "../FlightSegmentListItem";
import { FlightDetailsProps } from "./types";

export const FlightDetails: FC<FlightDetailsProps> = ({ data }) => {
  const renderFlightSegment = useCallback(
    (item: FlightSegment, index: number, array: FlightSegment[]) => {
      const nextSeg = array[1 + index];

      return (
        <Fragment key={index}>
          <FlightSegmentListItem data={item} airline={item.airline} />

          {nextSeg && (
            <>
              <Flex css={{ height: "$4", justifyContent: "center" }}>
                <Track data-orientation="vertical" />
              </Flex>
              <Alert
                variant="neutral"
                size="sm"
                css={{
                  borderStyle: "dashed",
                  justifyContent: "center",
                }}
              >
                <Icon as={SvgClock} />

                <Text>
                  {"Espera de "}
                  <strong>
                    {timeInterval(
                      item.arrivalFlightInfo.dateTime,
                      nextSeg.departureFlightInfo.dateTime
                    )}
                  </strong>
                  {" em "}
                  {`${item.arrivalFlightInfo.airport}`}
                </Text>
              </Alert>
              <Flex css={{ height: "$4", justifyContent: "center" }}>
                <Track data-orientation="vertical" />
              </Flex>
            </>
          )}
        </Fragment>
      );
    },
    []
  );

  return (
    <Flex direction="column" gap="6">
      <Box>
        <H4 css={{ fw: 500, mb: "$8" }}>Companhia Aérea</H4>

        <CompanyLogo src={getAirlineUrl(data.airline)} size="lg" />
      </Box>

      {/* TODO: Questionar sobre informações da bagagem */}
      {/* <Box>
        <H4 css={{ fw: 500, mb: "$8" }}>Informações sobre bagagem</H4>
      </Box> */}

      <H4 css={{ fw: 500 }}>Itinerário</H4>

      <Flex direction="column" css={{ flexGrow: 1 }}>
        {data.segments.map(renderFlightSegment)}
      </Flex>
    </Flex>
  );
};

// TrackItem.displayName = "TrackItem";
FlightDetails.displayName = "FlightDetails";
