import { Dispatch } from "react";
import type {
  Flight,
  FlightOption,
  FlightQuery,
  FlightQueryLinks,
  Traveler,
  UnflownAirway,
} from "~/application/types";
import {
  AirwayBookingState,
  AirwayTraveler,
  UnflowDispatch,
} from "~/presentation/Booking/BookingAirway/types";
import { StepItem } from "~/presentation/shared/utils";
import { BookingAirwaySteps } from "../../constants";
import { AirwayQueryFormData } from "~/presentation/shared/components/AirwayQueryForm";

export type OneWayFlightSelected = {
  flight: Flight;
  combinedFlight: Flight | null;
  flightOption: FlightOption;
};

export type CombinedFlightSelected = {
  oneWayFlight: Flight;
  combinedFlight: Flight | null;
  flightOption: FlightOption;
};

export enum AirwayBookingDispatchActionType {
  GO_FLIGHT = "SET_GO_FLIGHT",
  GO_UNFLOW = "SET_GO_UNFLOW",
  RETURN_UNFLOW = "SET_RETURN_UNFLOW",
  SET_STRETCH = "SET_STRETCH",
  UPDATE_STRETCH = "UPDATE_STRETCH",
  RETURN_FLIGHT = "SET_RETURN_FLIGHT",
  TRAVELERS = "SET_TRAVELERS",
  ADULT_QUANTITY = "SET_ADULT_QUANTITY",
  CLEAR = "CLEAR",
}

export type AirwayBookingDispatchAction =
  | {
      type: AirwayBookingDispatchActionType.GO_FLIGHT;
      payload: OneWayFlightSelected;
    }
  | {
      type: AirwayBookingDispatchActionType.GO_UNFLOW;
      payload: UnflowDispatch;
    }
  | {
      type: AirwayBookingDispatchActionType.RETURN_UNFLOW;
      payload: UnflowDispatch;
    }
  | {
      type: AirwayBookingDispatchActionType.SET_STRETCH;
      payload: OneWayFlightSelected & { index: number };
    }
  | {
      type: AirwayBookingDispatchActionType.UPDATE_STRETCH;
      payload: OneWayFlightSelected & { index: number };
    }
  | {
      type: AirwayBookingDispatchActionType.RETURN_FLIGHT;
      payload: {
        goFlight: OneWayFlightSelected | null;
        returnFlight: CombinedFlightSelected | null;
      };
    }
  | {
      type: AirwayBookingDispatchActionType.TRAVELERS;
      payload: AirwayTraveler[];
    }
  | {
      type: AirwayBookingDispatchActionType.ADULT_QUANTITY;
      payload: number;
    }
  | {
      type: AirwayBookingDispatchActionType.CLEAR;
    };

export interface UseAirwayOptions {
  flightQuery: FlightQuery | null;
}

export interface AirwayReducer {
  bookingState: AirwayBookingState;
  dispatch: Dispatch<AirwayBookingDispatchAction>;
}

export interface AirwayFlightQuery {
  data: AirwayQueryFormData | null;
  links?: FlightQueryLinks;
  isLoading: boolean;
}

export interface AirwayBookingStep {
  current: BookingAirwaySteps | string;
  currentIndex: number;
  steps: StepItem<BookingAirwaySteps | string>[];
  setStep: (step: number) => void;
}
