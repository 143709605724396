import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Select } from "~/components/Input";
import { DAYS_OPTIONS } from "../utils";
import { LazyList } from "~/components/LazyList";
import { EmptyState } from "~/components/EmptyState";
import { useCallback } from "react";
import { ReportsItem } from "../components/ReportsItem";
import { Customer, AccessReport } from "~/application/types";
import { Footer } from "~/components/Document";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";

export type TabAccessReportsProps = {
  reports?: AccessReport[];
  lastPage: number;
  currentPage: number;
  searchText: string;
  customerList?: Customer[];
  loadingCustomerList?: boolean;
  onSearchCustomer?: (customer: string) => void;
  onSelectCustomer?: (customer: Customer) => void;
  onSelectDate?: ({ label, value }: { label: string; value: string }) => void;
  onGoPage: (page: number) => void;
};

export function TabAccessReports({
  currentPage,
  lastPage,
  onGoPage,
  searchText,
  customerList,
  loadingCustomerList,
  onSearchCustomer,
  onSelectCustomer,
  onSelectDate,
  reports,
}: TabAccessReportsProps) {
  const listRenderer = useCallback(
    (item: AccessReport) => <ReportsItem item={item} />,
    []
  );
  return (
    <>
      <Container
        css={{
          pt: "$10",
          pb: "$20",
          display: "flex",
          flexDirection: "column",
          gap: "$6",
        }}
      >
        <Flex gap="3" justify="end">
          <Select
            placeholder="Empresas"
            isLoading={loadingCustomerList}
            options={customerList}
            getOptionLabel={(option) => option.tradingName}
            getOptionValue={(option) => option.uuid}
            onInputChange={onSearchCustomer}
            onChange={onSelectCustomer}
          />

          <Select
            defaultValue={DAYS_OPTIONS[0]}
            options={DAYS_OPTIONS}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={onSelectDate}
          />
        </Flex>
        <LazyList
          gap="6"
          items={reports}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhum resultado encontrado</Text>
              ) : (
                <Text>Nenhum relatório encontrado</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoPage}
          />
        </Container>
      </Footer>
    </>
  );
}
