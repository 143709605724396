import { FC } from "react";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { FormDialog } from "~/components/FormDialog";
import { Text } from "~/components/Text";
import { SimpleDialogProps } from "./types";

export const SimpleDialog: FC<SimpleDialogProps> = ({
  title,
  size = "8",
  children,
  negativeTitle,
  withHeader = true,
  withCloseButton = true,
  negativeDisabled = false,
  onNegativeClick,
  positiveTitle,
  positiveDisabled = false,
  onPositiveClick,
  onCloseClick,
}) => {
  
  return (
    <Container size={size} fixed>
      <FormDialog
        css={{ 
          '@mxmd': {
            width: "80%", margin: "0 auto"
          }
         }}
        title={title}
        withHeader={withHeader}
        withCloseButton={withCloseButton}
        negativeButton={
          <Button
            disabled={negativeDisabled}
            onClick={onNegativeClick ?? onCloseClick}
            variant="tertiary"
          >
            <Text>{negativeTitle}</Text>
          </Button>
        }
        positiveButton={
          <Button disabled={positiveDisabled} onClick={onPositiveClick}>
            <Text>{positiveTitle}</Text>
          </Button>
        }
        onClickDismissButton={onCloseClick}
      >
        {children}
      </FormDialog>
    </Container>
  );
};

SimpleDialog.displayName = "SimpleDialog";
