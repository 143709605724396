import { Action, Profile } from "~/application/types";

export const createFormData = ({
  actions,
  item,
}: {
  item?: Profile;
  actions?: Action[];
}): {
  item?: Profile;
  actions?: string[];
} => {
  return {
    item,
    actions: actions?.map((action) => action.uuid),
  };
};
