import { UnflownAirway } from "~/application/types";
import { UnflownAirwayDTO } from "../dtos";
import { toDate } from "~/utils/date.utils";

export const mapUnflownAirway = (data: UnflownAirwayDTO): UnflownAirway => {
  return {
    airline: data.airline,
    issueDate: data.issue_date ? toDate(data.issue_date) : new Date(),
    tracker: data.tracker,
    uuid: data.uuid,
    value: data.credit_value,
    airwayOrderId: data.airway_order_uuid,
    expiredAt: toDate(data.expired_at),
    passengersTravelersId: data.passengers_travelers_uuid,
    status: data.status,
  };
};
