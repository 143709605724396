import { Fragment, useCallback } from "react";
import { Order, OrderItems, OrderStatus } from "~/application/types";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { H4 } from "~/components/Typography";
import { OrderAdditionalInfoItem } from "~/presentation/shared/components/OrderAdditionalInfoItem";
import { OrderAdvanceItem } from "~/presentation/shared/components/OrderAdvanceItem";
import {
  getAdvanceFrom,
  getAirwayFrom,
  getHotelFrom,
  getRoadFrom,
  getVehicleFrom,
  isAirwayRejected,
  isHotelRejected,
  isRoadRejected,
  isVehicleRejected,
} from "~/presentation/shared/utils/order-functions";
import { UseOrderItemsResult } from "../../hooks/useOrderItems/types";
import { AdditionalInfoListItem } from "./components/AdditionalInfoListItem";
import { OrderAddItemListItem } from "./components/OrderAddItemListItem";
import { OrderAirwayItem } from "./components/OrderAirwayItem";
import { ApproverOrderCart, TravelerOrderCart } from "./components/OrderCart";
import { OrderHotelItem } from "./components/OrderHotelItem";
import { OrderItemListItem } from "./components/OrderItemListItem";
import { OrderRoadItem } from "./components/OrderRoadItem/index";
import { OrderVehicleItem } from "./components/OrderVehicleItem";
import { Alert, AlertIcon } from "~/components/Alert";
import { Text } from "~/components/Text";
import { Pulse } from "~/components/Pulse/Pulse";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { userIsOrderApprover } from "../../utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { userIsIssuer } from "~/core/modules/Order/utils";

export function TabOrderItems({
  order,
  isLoading,
  currentTime,
  isAirwayItemExpanded,
  isVehicleItemExpanded,
  isHotelItemExpanded,
  isRoadItemExpanded,
  isAdvanceItemExpanded,
  isAdditionalInfoExpanded,
  itemsAvailableToAdd,
  isRebookingRoad,
  isRoadRebookError,
  isSuccessfulRoadRebooking,
  isIssuingOrder,
  refetchOrder,
  onNotifyIssuerThatOrderExpired,
  onOpenRoadDetails,
  onRemakeOrder,
  onAddOrderAdvance,
  onRequestOrderCancellation,
  onSendVoucherInWhatsapp,
  toggleAirwayItemVisible,
  toggleVehicleItemVisible,
  toggleHotelItemVisible,
  toggleRoadItemVisible,
  toggleAdvanceItemVisible,
  toggleAdditionalInfoVisible,
  onOpenHotelDetails,
  onOpenHotelPolicies,
  onOpenFlightDetails,
  onRequestApproval,
  onCancelOrder,
  onDeleteOrderAirway,
  onDeleteOrderHotel,
  onDeleteOrderRoad,
  onDeleteOrderVehicle,
  onReloadAirwayTracker,
  onOpenHotelOptionDetails,
  onOpenAirwaySeats,
  onRemoveSeat,
  onOfflineReject,
}: UseOrderItemsResult) {
  const { user, contexts } = useUser();
  const isMobile = useMobile();
  const orderId = order?.uuid;
  const orderStatus = order?.status as OrderStatus;
  const orderStatusIsApprovedOrIssued = [OrderStatus.APPROVED, OrderStatus.ISSUED].includes(
    orderStatus
  );

  const airwayIsRejected = isAirwayRejected(order);
  const hotelIsRejected = isHotelRejected(order);
  const vehicleIsRejected = isVehicleRejected(order);
  const roadIsRejected = isRoadRejected(order);
  const airway = getAirwayFrom({ order });
  const hotel = getHotelFrom({ order });
  const vehicle = getVehicleFrom({ order });
  const road = getRoadFrom({ order });
  const advances = getAdvanceFrom({ order });

  const userIsApprover = userIsOrderApprover(order);
  const isUserIssuer = userIsIssuer(user, order);
  const userIsValidAgencyEmployee = user.agency?.uuid === order?.customer.agencyId;

  const canShowApprovalButtons = order?.status === OrderStatus.ON_APPROVAL && userIsApprover;

  const renderAirwayItem = useCallback(
    (itemIndex: number) => (
      <OrderItemListItem
        id={`item-${itemIndex}`}
        className="item-card"
        tabIndex={-1}
        isOpen={isAirwayItemExpanded}
        onItemExpand={toggleAirwayItemVisible}
        item={{
          type: OrderItems.AIRWAY,
          isRejected: airwayIsRejected,
        }}
        orderId={orderId}
        key={OrderItems.AIRWAY}
      >
        <OrderAirwayItem
          itemIndex={itemIndex}
          canShowApprovalButtons={canShowApprovalButtons}
          isLoading={isLoading}
          order={order}
          data={airway}
          onOpenDetails={onOpenFlightDetails}
          onDeleteItem={orderStatusIsApprovedOrIssued ? undefined : onDeleteOrderAirway}
          onReloadTracker={onReloadAirwayTracker}
          onRemakeOrder={onRemakeOrder}
          onOpenAirwaySeats={onOpenAirwaySeats}
          onRemoveSeat={onRemoveSeat}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      order,
      isAirwayItemExpanded,
      orderStatusIsApprovedOrIssued,
      canShowApprovalButtons,
      onDeleteOrderAirway,
      toggleAirwayItemVisible,
      onOpenFlightDetails,
      onReloadAirwayTracker,
      airwayIsRejected,
      airway,
      orderId,
    ]
  );

  const renderHotelItem = useCallback(
    (itemIndex: number) => (
      <OrderItemListItem
        id={`item-${itemIndex}`}
        className="item-card"
        tabIndex={-1}
        isOpen={isHotelItemExpanded}
        onItemExpand={toggleHotelItemVisible}
        item={{
          type: OrderItems.HOTEL,
          isRejected: hotelIsRejected,
        }}
        orderId={orderId}
        key={OrderItems.HOTEL}
      >
        <OrderHotelItem
          itemIndex={itemIndex}
          canShowApprovalButtons={canShowApprovalButtons}
          isLoading={isLoading}
          data={hotel}
          onOpenDetails={onOpenHotelDetails}
          onOpenPolicies={onOpenHotelPolicies}
          onOpenOptionDetails={onOpenHotelOptionDetails}
          onDeleteItem={orderStatusIsApprovedOrIssued ? undefined : onDeleteOrderHotel}
        />
      </OrderItemListItem>
    ),
    [
      hotel,
      orderId,
      isLoading,
      isHotelItemExpanded,
      orderStatusIsApprovedOrIssued,
      hotelIsRejected,
      onDeleteOrderHotel,
      toggleHotelItemVisible,
      onOpenHotelDetails,
      onOpenHotelPolicies,
      onOpenHotelOptionDetails,
    ]
  );

  const renderVehicleItem = useCallback(
    (itemIndex: number) => (
      <OrderItemListItem
        id={`item-${itemIndex}`}
        className="item-card"
        tabIndex={-1}
        isOpen={isVehicleItemExpanded}
        onItemExpand={toggleVehicleItemVisible}
        item={{
          type: OrderItems.VEHICLE,
          isRejected: vehicleIsRejected,
        }}
        orderId={orderId}
        key={OrderItems.VEHICLE}
      >
        <OrderVehicleItem
          itemIndex={itemIndex}
          canShowApprovalButtons={canShowApprovalButtons}
          isLoading={isLoading}
          data={vehicle}
          onDeleteItem={orderStatusIsApprovedOrIssued ? undefined : onDeleteOrderVehicle}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      isVehicleItemExpanded,
      orderStatusIsApprovedOrIssued,
      canShowApprovalButtons,
      onDeleteOrderVehicle,
      toggleVehicleItemVisible,
      vehicleIsRejected,
      vehicle,
      orderId,
    ]
  );

  const renderRoadItem = useCallback(
    (itemIndex: number) => (
      <OrderItemListItem
        id={`item-${itemIndex}`}
        className="item-card"
        tabIndex={-1}
        isOpen={isRoadItemExpanded}
        onItemExpand={toggleRoadItemVisible}
        item={{
          type: OrderItems.ROAD,
          isRejected: roadIsRejected,
        }}
        orderId={orderId}
        key={OrderItems.ROAD}
      >
        <OrderRoadItem
          itemIndex={itemIndex}
          canShowApprovalButtons={canShowApprovalButtons}
          isLoading={isLoading}
          data={road}
          onDeleteItem={orderStatusIsApprovedOrIssued ? undefined : onDeleteOrderRoad}
          onOpenDetails={onOpenRoadDetails}
        />
      </OrderItemListItem>
    ),
    [
      orderStatusIsApprovedOrIssued,
      onOpenRoadDetails,
      isLoading,
      isRoadItemExpanded,
      canShowApprovalButtons,
      toggleRoadItemVisible,
      onDeleteOrderRoad,
      roadIsRejected,
      road,
      orderId,
    ]
  );

  const renderAdvanceItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isAdvanceItemExpanded}
        onItemExpand={toggleAdvanceItemVisible}
        item={{ type: OrderItems.ADVANCE }}
        orderId={orderId}
        key={OrderItems.ADVANCE}
      >
        <OrderAdvanceItem isLoading={isLoading} data={advances} />
      </OrderItemListItem>
    ),
    [isLoading, isAdvanceItemExpanded, toggleAdvanceItemVisible, advances, orderId]
  );

  const renderAdditionalInfo = useCallback(
    () => (
      <AdditionalInfoListItem
        isOpen={isAdditionalInfoExpanded}
        onExpand={toggleAdditionalInfoVisible}
      >
        <OrderAdditionalInfoItem order={order} />
      </AdditionalInfoListItem>
    ),
    [isAdditionalInfoExpanded, toggleAdditionalInfoVisible, order]
  );

  const approverOrderCartProps = {
    order,
    isIssuingOrder,
    isLoading,
    isRoadRebookError,
    isRebookingRoad,
    isSuccessfulRoadRebooking,
    onSendVoucherInWhatsapp,
    onNotifyIssuerThatOrderExpired,
    onRequestOrderCancellation,
  };

  const orderCartProps = {
    isIssuingOrder,
    isRoadRebookError,
    isRebookingRoad,
    onNotifyIssuerThatOrderExpired,
    onRequestOrderCancellation,
    onSendVoucherInWhatsapp,
    onOfflineReject,
    onRequestApproval,
    onCancelOrder,
    onRemakeOrder,
    refetchOrder,
    currentTime,
    isLoading,
    order,
    airwayOrder: airway,
  };

  const orderStatusIsCancellingOrCancelled = [OrderStatus.CANCELED, OrderStatus.CANCELING].includes(
    orderStatus
  );

  const canAddOrderItems =
    !orderStatusIsApprovedOrIssued &&
    !orderStatusIsCancellingOrCancelled &&
    itemsAvailableToAdd.length > 0;

  const isIssuer = userIsIssuer(user, order, contexts);
  const isApprover = userIsOrderApprover(order);

  const canShowAdditionalInfo =
    ![OrderStatus.OPEN, OrderStatus.CANCELED, OrderStatus.REJECTED].includes(orderStatus) &&
    (isIssuer || isApprover);

  return (
    <Fragment>
      <Row
        css={{
          "@lg": {
            px: "$10",
          },
        }}
      >
        <Col
          sz="8"
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "$6",
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          {order?.itemsIncluded && order.itemsIncluded.length > 0 && (
            <Box
              css={{
                display: "flex",
                flexDirection: "column",
                gap: "$8",
              }}
            >
              <Box>
                <H4
                  css={{
                    fw: "600",
                    "@mxlg": {
                      textAlign: "center",
                      fontSize: "$lg",
                    },
                  }}
                >
                  Itens do pedido
                </H4>
              </Box>
              {!canAddOrderItems && (
                <Alert
                  variant="info"
                  css={{
                    "@mxlg": {
                      p: "$3",
                    },
                  }}
                >
                  {isMobile ? (
                    <Pulse css={{ height: "$5" }}>
                      <AlertIcon size="md" />
                    </Pulse>
                  ) : (
                    <AlertIcon />
                  )}
                  <Text size={{ "@mxlg": "3" }}>
                    {`Não é possível adicionar itens a um pedido ${
                      orderStatusIsCancellingOrCancelled ? "cancelado" : "emitido"
                    }. Por favor,
                    crie um novo pedido.`}
                  </Text>
                </Alert>
              )}

              <Flex direction="column" gap="8">
                {order.itemsIncluded
                  .sort((item) => (item === OrderItems.ADVANCE ? 1 : -1))
                  .map((item, i) => {
                    return {
                      [OrderItems.AIRWAY]: renderAirwayItem(i),
                      [OrderItems.HOTEL]: renderHotelItem(i),
                      [OrderItems.VEHICLE]: renderVehicleItem(i),
                      [OrderItems.ROAD]: renderRoadItem(i),
                      [OrderItems.ADVANCE]: renderAdvanceItem(),
                      [OrderItems.HOTEL_OFFLINE]: [],
                    }[item];
                  })}

                {canShowAdditionalInfo && renderAdditionalInfo()}
              </Flex>
            </Box>
          )}

          {canAddOrderItems && (
            <Fragment>
              <Box css={{ mb: "$10" }}>
                <H4
                  css={{
                    fw: "600",
                    "@mxlg": {
                      fontSize: "$lg",
                      mt: "$4",
                    },
                  }}
                >
                  Adicionar mais itens ao pedido
                </H4>
              </Box>
              <Flex direction="column" gap="8">
                {itemsAvailableToAdd.map((item) => {
                  if (item !== OrderItems.HOTEL_OFFLINE)
                    return (
                      <OrderAddItemListItem
                        order={order as Order}
                        onAddItem={item === OrderItems.ADVANCE ? onAddOrderAdvance : undefined}
                        item={item}
                        key={item}
                      />
                    );
                })}
              </Flex>
            </Fragment>
          )}
        </Col>

        <Col
          sz="4"
          css={{
            "@mxlg": {
              width: 0,
            },
          }}
        >
          {isUserIssuer || userIsValidAgencyEmployee ? (
            <TravelerOrderCart {...orderCartProps} />
          ) : (
            <ApproverOrderCart {...approverOrderCartProps} />
          )}
        </Col>
      </Row>
    </Fragment>
  );
}
