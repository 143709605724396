import { Justification } from "~/application/types";
import { getServiceLabel } from "~/core/shared/utils/order.utils";
import { JustificationSchema } from "../components/JustificationDialog";

export function createJustification(editable: JustificationSchema): Justification {
  return {
    ...editable,
    serviceType: editable.serviceType.value,
  } as Justification;
}

export function editJustification(data: Justification): JustificationSchema {
  return {
    ...data,
    serviceType: data.serviceType && {
      value: data.serviceType,
      label: getServiceLabel(data.serviceType) || "",
    },
  };
}
