import * as DialogPrimitive from "@radix-ui/react-dialog";
import { ComponentProps } from "react";
import { keyframes, styled } from "~/application/theme";

export type DialogOverlayProps = ComponentProps<typeof DialogOverlay>;

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

export const DialogOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: "rgba(99, 105, 115, 0.32)",
  position: "fixed",
  zIndex: "$overlay",
  inset: 0,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
});
