import { FC } from "react";
import { DialogBody } from "~/components/Dialog";
import { FormDialog } from "~/components/FormDialog";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { useQueryFlightDetails } from "../../hooks/useQueryFlightDetails";
import { FlightDetails } from "./FlightDetails";
import { FlightDetailsDialogProps } from "./types";
import { Container } from "~/components/Container";

export const FlightDetailsDialog: FC<FlightDetailsDialogProps> = ({
  data: flight,
  flightOrigin,
  onCloseClick,
  ...props
}) => {
  const { data, isFetching } = useQueryFlightDetails({
    flight,
    flightOrigin,
  });

  if (isFetching || !data) {
    return (
      <LoadingModal isOpen css={{ textAlign: "center" }} message="Carregando detalhes do vôo" />
    );
  }

  return (
    <Container
      size="8"
      fixed
      css={{
        "@mxlg": {
          width: "99%",
        },
      }}
    >
      <FormDialog title="Detalhes da passagem" onClickDismissButton={onCloseClick} {...props}>
        <DialogBody css={{ p: "$6" }}>
          <FlightDetails data={data} />
        </DialogBody>
      </FormDialog>
    </Container>
  );
};

FlightDetailsDialog.displayName = "FlightDetailsDialog";
