import { AlertIcon } from "~/components/Alert";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H4 } from "~/components/Typography";
import OfflineQuoteImage from "../../../../assets/images/offline-quote.jpg";
import { OfflineQuoteProps } from "./types";

export function OfflineQuote({ quoteUrl, ...props }: OfflineQuoteProps) {
  return (
    <Card
      css={{
        flexDirection: "row",
        border: "none",
        display: "flex",
        flexWrap: "wrap",
        pb: "$3"
      }}
      {...props}
    >
      <Col
        sz="4"
        css={{
          height: "100%",
          "@mxsm": {
            width: "100%",
            height: "$40",
          },
        }}
      >
        <img
          src={OfflineQuoteImage}
          style={{
            height: "100%",
            maxHeight: "400px",
            width: "100%",
          }}
        />
      </Col>

      <Flex
        direction="column"
        justify="center"
        css={{
          // pl: "$8",
          "@mxsm": {
            pl: 10,
            margin: "0 auto",
            width: "100%",
          },
        }}
      >
        <Flex
          direction="column"
          wrap="wrap"
          align={{ "@initial": "start", "@mxsm": "center" }}
          css={{
            ml: "$8",
            "@mxsm": {
              ml: 0,
            },
          }}
        >
          <Flex align="center" gap="9">
            <H4 size={{ "@mxsm": "3" }} css={{ fw: "500" }}>
              Não encontrou o hotel que você deseja ?
            </H4>

            <Tooltip
              css={{ backgroundColor: "white", color: "black" }}
              side="bottom"
              content={
                <TooltipLabel css={{ p: "$3" }}>
                  <Flex direction="column" gap="3">
                    <Text size="4" css={{ fw: "700" }}>
                      O que é uma cotação offline ?
                    </Text>

                    <Text
                      size="4"
                      css={{ width: "$54", color: "$neutrals-dark" }}
                    >
                      Ao solicitar uma cotação offline será necessária a
                      indicação de um hotel ou um ponto de referência para que
                      nossos consultores escolham a melhor opção disponível.
                    </Text>
                  </Flex>
                </TooltipLabel>
              }
            >
              <IconButton size="md" css={{ ml: "$5" }}>
                <AlertIcon
                  css={{
                    fill: "$warning-base",
                    width: "$4",
                    height: "$4",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Flex>

          <Text css={{ my: "$2" }}>
            Solicite uma <b>Cotação Offline</b>
          </Text>
        </Flex>

        <Flex>
          <Link css={{ "@mxsm": {
            width: "80%"  
          }}} to={quoteUrl as string}>
            <Button css={{ mt: "$2", ml: "$8", "@mxsm": {
              margin: "o auto",
              width: "100%"
            }}}>
              Solicitar cotação offline
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Card>
  );
}
