import {
  AirwaySeatLocation,
  AirwaySeats,
  AirwaySeatsForm,
  Passenger,
} from "~/application/types";
import { AirwaySeatsReducerState } from "./type";

export const createAirwaySeatsFormData = ({
  seats,
  seatsSelected,
}: {
  seats?: AirwaySeats;
  seatsSelected: AirwaySeatsReducerState[];
}): AirwaySeatsForm => {
  return {
    search_key: seats?.searchKey || "",
    seats: seatsSelected.map((seat, index) => ({
      column: seat.column || "",
      row: seat.row || "",
      stretch_id: seat.stretchId || "",
      customer_employee_uuid: seat.passenger?.uuid || "",
      value: seat.value,
      selected: seat.selected,
    })),
  };
};

export const insertColumn = (
  data: AirwaySeats["data"][0]["mapSeats"][0]["rowns"][0]["columns"]
) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const latestColumn = alphabet.findIndex(
    (leter) => leter === data[data.length - 1].column
  );

  const newAlphabet = alphabet.slice(0, latestColumn + 1);

  const newColumns = newAlphabet.map((letter) => {
    const column = data.find((column) => column.column === letter);

    return (
      column || {
        column: letter,
        busy: true,
        location: AirwaySeatLocation.AISLE,
        situation: "",
        available: false,
        seatTypeDescription: "",
        value: 0,
      }
    );
  });

  return newColumns;
};
