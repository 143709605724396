import { UseQueryResult } from "@tanstack/react-query";
import { FC, useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import { Action, Profile } from "~/application/types";
import { Box } from "~/components/Box";
import { CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgCancel,
  SvgCheck,
  SvgChevronDown,
  SvgChevronUp,
  SvgClose,
  SvgEdit,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { EditProfileProps } from "../../../../hooks/useProfiles";
import { formatActionGroup } from "../../utils";

export type ProfileListeItemProps = {
  item: Profile;
  onFetchProfileAction: (data: { item: Profile }) => UseQueryResult<Action[], unknown>;
  onEditProfile: ({ item }: EditProfileProps) => void;
} & DataListItemProps;

export type GroupedPolicy = {
  group: string;
  groupDescription: string;
  policies: Action[];
};

export const ProfileListeItem: FC<ProfileListeItemProps> = ({
  item,
  onFetchProfileAction,
  onEditProfile,
  ...props
}) => {
  const [isCollapsed, onCollapsed] = useState(false);

  const { refetch, isLoading, data } = onFetchProfileAction({ item });

  const groupPoliciesByGroup = useMemo(() => formatActionGroup({ data }), [data]);

  return (
    <>
      <DataListItem
        data-active={isCollapsed}
        data-list={isCollapsed}
        {...props}
        onClick={() => {
          refetch();
          onCollapsed((old) => !old);
        }}
        css={{
          cursor: "pointer",
          borderBottomLeftRadius: isCollapsed ? "$none" : undefined,
          borderBottomRightRadius: isCollapsed ? "$none" : undefined,
          "&:hover::after": {
            boxShadow: isCollapsed ? "$none" : undefined,
          },
        }}
      >
        <DataColItem headerTitle="Nome" data={item.name} />
        <DataColItem
          headerTitle="Aprova"
          data={
            <Flex align="center" gap="2">
              <Text fw="600">{item.isApprover ? "Sim" : "Não"}</Text>
              <Icon
                as={item.isApprover ? SvgCheck : SvgClose}
                variant={item.isApprover ? "success" : "error"}
              />
            </Flex>
          }
        />
        <DataColItem
          data={
            <Tooltip content={<TooltipLabel>Editar perfil</TooltipLabel>}>
              <IconButton
                size="md"
                onClick={(event) => {
                  event.stopPropagation();
                  onEditProfile({
                    item,
                  });
                }}
              >
                <Icon as={SvgEdit} />
              </IconButton>
            </Tooltip>
          }
          css={{
            flex: 0,
          }}
        />
        <IconButton size="md">
          <Icon as={isCollapsed ? SvgChevronUp : SvgChevronDown} />
        </IconButton>
      </DataListItem>
      <Collapse isOpened={isCollapsed} style={{ padding: "10px", background: "#f0f0f0" }}>
        <Box
          css={{
            background: "#fff",
            borderWidth: "0 2px 2px 2px",
            borderStyle: "solid",
            borderColor: "$primary-base",
            borderBottomLeftRadius: "$md",
            borderBottomRightRadius: "$md",
            pb: "$5",
          }}
        >
          <Flex align="center" justify="center">
            {isLoading ? (
              <Spinner />
            ) : (
              <CardBody
                css={{
                  width: "100%",
                }}
              >
                <Grid gap="2" columns={2}>
                  {groupPoliciesByGroup?.map((group) => (
                    <Flex
                      key={group.group}
                      direction="column"
                      css={{
                        height: "100%",
                      }}
                    >
                      <Flex
                        css={{
                          p: "$3",
                          backgroundColor: "$primary-base",
                        }}
                        justify="center"
                      >
                        <Text fw="600" variant="white">
                          {group.groupDescription}
                        </Text>
                      </Flex>
                      <Flex
                        gap="3"
                        css={{
                          p: "$2",
                          backgroundColor: "$neutrals-lightest",
                          height: "100%",
                        }}
                        wrap="wrap"
                      >
                        {group.policies.map((policy) => (
                          <Flex align="center" gap="1">
                            <Icon as={SvgCheck} variant="success" />
                            <Text fw="500">{policy.description}</Text>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                </Grid>
              </CardBody>
            )}
          </Flex>
        </Box>
      </Collapse>
    </>
  );
};
