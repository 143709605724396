import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { scroller } from "react-scroll";
import { Order, OrderItems } from "~/application/types";

export interface BrokenPolicyJustification {
  uuid: string;
  justification: string;
}

export interface IBrokenPolicyJustificationContext {
  brokenPolicyItems: BrokenPolicyJustification[];
  allBrokenPolicyItemsAreJustificated: boolean;
  setBrokenPolicyItems: Dispatch<SetStateAction<BrokenPolicyJustification[]>>;
  scrollToSection: (items: string[]) => void;
}

interface BrokenPolicyJustificationProviderParams {
  order?: Order;
  children: ReactNode;
}

export const BrokenPolicyJustificationContext = createContext<IBrokenPolicyJustificationContext>(
  {} as IBrokenPolicyJustificationContext
);

export const useBrokenPolicyJustification = () => useContext(BrokenPolicyJustificationContext);

export function BrokenPolicyJustificationProvider({
  order,
  children,
}: BrokenPolicyJustificationProviderParams) {
  const [brokenPolicyItems, setBrokenPolicyItems] = useState<BrokenPolicyJustification[]>([]);

  const scrollToSection = (items: string[]) => {
    scroller.scrollTo(`scroll-${items?.at(0)}`, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const airwayBrokenPolicyAmount =
    order?.items[OrderItems.AIRWAY]?.flights.reduce(
      (acc, flight) => (flight.violatedPolicies?.length ? acc + 1 : acc),
      0
    ) || 0;

  const roadBrokenPolicyAmount =
    order?.items[OrderItems.ROAD]?.travels.reduce(
      (acc, travel) => (travel.violatedPolicies?.length ? acc + 1 : acc),
      0
    ) || 0;

  const hotelBrokenPolicyAmount =
    order?.items[OrderItems.HOTEL]?.rooms.reduce(
      (acc, room) => (room.violatedPolicies?.length ? acc + 1 : acc),
      0
    ) || 0;

  const vehicleBrokenPolicyAmount =
    order?.items[OrderItems.VEHICLE]?.vehicles.reduce(
      (acc, vehicle) => (vehicle.violatedPolicies?.length ? acc + 1 : acc),
      0
    ) || 0;

  const brokenPolicyItemsAmount =
    airwayBrokenPolicyAmount +
    roadBrokenPolicyAmount +
    hotelBrokenPolicyAmount +
    vehicleBrokenPolicyAmount;

  const allBrokenPolicyItemsAreJustificated = brokenPolicyItemsAmount === brokenPolicyItems.length;

  return (
    <BrokenPolicyJustificationContext.Provider
      value={{
        brokenPolicyItems,
        allBrokenPolicyItemsAreJustificated,
        setBrokenPolicyItems,
        scrollToSection,
      }}
    >
      {children}
    </BrokenPolicyJustificationContext.Provider>
  );
}
