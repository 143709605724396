import { useCallback, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { getAvailableParameterOptions, SettingParameterTypeValue } from "~/application/types/entities/SettingParameter.type";
import { customerSettingParameterService } from "~/application/usecases/CustomerSettingParameter";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { CustomerSettingParameterDialog } from "../views/CustomerSettingParameter/components/CustomerSettingParameterDialog";
import { CustomerSettingParameter } from "~/application/types";

const LOG_TAG = "Customer/CustomerPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar paramêtros de configuração",
  CREATE_SUCCESS_MESSAGE: "Parâmetro de configuração criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar paramêtro de configuração",
  UPDATE_SUCCESS_MESSAGE: "Parâmetro de configuração atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar paramêtro de configuração",
} as const;

export interface UseSettingParametersResult {
  data?: CustomerSettingParameter[];
  isLoading: boolean;
  isUpdating: boolean;
  onEditClick: (item: CustomerSettingParameter) => void;
}
export type UseSettingParametersProps = { customerId: string; enabled: boolean };

export function useCustomerSettingParameters({
  enabled,
  customerId,
}: UseSettingParametersProps): UseSettingParametersResult {
  const { data: customerSettingParameters, isLoading: isLoading } = useQuery(
    [QueryKeys.CUSTOMER_SETTING_PARAMETERS, customerId],
    () => customerSettingParameterService.find({ customerId }),
    {
      refetchOnWindowFocus: false,
      enabled,
    }
  );

  const { mutateAsync: updateCustomerSettingParameter, isLoading: isUpdating } =
    useMutation(
      async (data: CustomerSettingParameter) => {
        return await customerSettingParameterService.update(data);
      },
      {
        onMutate: (item) => {
          log.i(LOG_TAG, `Updating CustomerSettingParameter(${item.uuid})`);
        },
        onSuccess: (_, item) => {
          log.i(
            LOG_TAG,
            `Successfully updated CustomerSettingParameter(${item.uuid})`
          );

          queryClient.invalidateQueries([
            QueryKeys.CUSTOMER_SETTING_PARAMETERS,
            customerId,
          ]);

          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
            "success"
          );

          dialogService.popDialog();
        },
        onError: (error) => {
          log.e(LOG_TAG, error);

          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
            "error"
          );
        },
      }
    );

  useEffect(() => {
    if (isUpdating) {
      dialogService.popDialog();
    }
  }, [isUpdating]);

  const handleOpenEditModal = useCallback((item: CustomerSettingParameter) => {
    if (item.typeValue === SettingParameterTypeValue.BOOLEAN) {
      updateCustomerSettingParameter({ ...item, value: !item.value });
      return;
    }

    const options = item.typeValue === SettingParameterTypeValue.SELECT 
      ? getAvailableParameterOptions(item.slug) 
      : undefined;

    dialogService.showDialog(
      <CustomerSettingParameterDialog
        onSubmit={updateCustomerSettingParameter}
        data={item}
        options={options}
      />
    );
  }, []);

  return {
    data: customerSettingParameters,
    onEditClick: handleOpenEditModal,
    isLoading,
    isUpdating,
  };
}
