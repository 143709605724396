import { CSS } from "@stitches/react";
import { ReactNode, useContext } from "react";
import { Text } from "../Text";
import { FormControlContext } from "./FormControlContext";

export type FieldLabelProps = {
  children: ReactNode;
  css?: CSS;
  withRules?: boolean;
};

export const RequiredSpan = () => (
  <Text
    as="label"
    size="2"
    variant="warning-dark"
    css={{ fw: "700", ml: "$2", userSelect: "none" }}
  >
    Obrigatório
  </Text>
);

export const FieldLabel = ({
  children,
  css,
  withRules = true,
}: FieldLabelProps) => {
  const formControl = useContext(FormControlContext);

  return (
    <Text
      as="label"
      size="2"
      variant="darkest"
      css={{ fw: "700", display: "block", mb: "$2", ...css }}
    >
      {children}
      {withRules && formControl.rules?.required && <RequiredSpan />}
    </Text>
  );
};

FieldLabel.displayName = "FieldLabel";
