import { OrderItemStatus } from "~/application/types";
import { createTagHOC } from "~/core/shared/components/TagHOC";

export const OrderItemStatusTag = createTagHOC({
  config: {
    [OrderItemStatus.APPROVED]: {
      title: "Aprovado",
      variant: "success-light",
    },
    [OrderItemStatus.OPEN]: {
      title: "Aberto",
      variant: "neutral-light",
    },
    [OrderItemStatus.REJECTED]: {
      title: "Rejeitado",
      variant: "error-light",
    },
    [OrderItemStatus.DONE]: {
      title: "Emitido",
      variant: "success-light",
    },
    [OrderItemStatus.FAILED]: {
      title: "Falha na emissão",
      variant: "error-light",
    },
    [OrderItemStatus.EXPIRED_TIME]: {
      title: "Expirado",
      variant: "error-light",
    },
    [OrderItemStatus.QUOTING]: {
      title: "Em cotação",
      variant: "neutral-light",
    },
    [OrderItemStatus.QUOTED]: {
      title: "Em cotação",
      variant: "success-light",
    },
    [OrderItemStatus.FAILED_CANCEL]: {
      title: "Falha ao cancelar",
      variant: "error-light",
    },
    [OrderItemStatus.CANCELED]: {
      title: "Cancelado",
      variant: "error-light",
    },
  },
});
