import { useMutation } from "@tanstack/react-query";
import { QueryKeys } from "~/application/constants";
import { orderService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { queryClient } from "~/services/queryClient";
import { logError } from "../../utils/errors";
import { CreateOrderParams, UseCreateOrderOptions, UseCreateOrderReturn } from "./types";

const SNACKBAR_MESSAGES = {
  CREATE_ERROR_MESSAGE: "Falha ao criar pedido",
} as const;

export function useCreateOrder({
  customerId,
  issuerId,
  reasonTripId,
}: UseCreateOrderOptions): UseCreateOrderReturn {
  const { LOG_TAG } = useLogTag();

  const { mutateAsync: mutateCreateAsync } = useMutation(
    (params: CreateOrderParams) =>
      orderService.create({ ...params, customerId, issuerId, reasonTripId }),
    {
      onSuccess: (item) => {
        log.i(LOG_TAG, `Successfully created Order(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_ORDERS, customerId]);
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
    }
  );

  return {
    create: mutateCreateAsync,
  };
}
