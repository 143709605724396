import { FC, useCallback } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { SvgKey } from "~/components/Icon/icons";
import { MaskedInput, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import * as MaskUtils from "~/utils/mask.utils";
import { BranchDialogProps } from "./types";
import { EditableBranch, createBranch, editBranch } from "./utils";

export const BranchDialog: FC<BranchDialogProps> = ({
  data,
  onCloseClick,
  onSubmit: onSubmitProp,
}) => {
  const isNew = !data;
  
  const { control, formState, watch, handleSubmit } = useForm<EditableBranch>({
    defaultValues: editBranch(data),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { phone } = watch();

  const onSubmit = useCallback(
    (formData: EditableBranch) => {
      onSubmitProp?.(createBranch(formData));
    },
    [onSubmitProp]
  );

  const onError = useCallback<SubmitErrorHandler<EditableBranch>>(
    (formErrors) => {
      // eslint-disable-next-line no-console
      console.log(formErrors);
    },
    []
  );

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormDialog
          title={isNew ? "Nova filial" : "Editar filial"}
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col sz="6">
                <FormControl name="cnpj" control={control} required>
                  <FieldLabel>CNPJ</FieldLabel>
                  <MaskedInput
                    mask={MaskUtils.MASK_CNPJ}
                    placeholder="Digite o CNPJ da filial"
                  />
                </FormControl>
              </Col>

              <Col sz="6">
                <FormControl name="integrationId" control={control}>
                  <FieldLabel>ID da integração</FieldLabel>
                  <TextInput leftIcon={SvgKey} placeholder="Número do ID" />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome da filial</FieldLabel>
                  <TextInput placeholder="Digite o nome da filial" />
                </FormControl>
              </Col>

              <Col sz="7">
                <FormControl name="email" control={control} required>
                  <FieldLabel>E-mail</FieldLabel>
                  <TextInput placeholder="Digite o nome da filial" />
                </FormControl>
              </Col>

              <Col sz="5">
                <FormControl name="phone" control={control} required>
                  <FieldLabel>Telefone da empresa</FieldLabel>
                  <MaskedInput mask={MaskUtils.pickPhoneNumberMask(phone)} />
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

BranchDialog.displayName = "BranchDialog";
