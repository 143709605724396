import { useLocation } from "react-router-dom";

import { EXPENSE_MENU_TABS, ExpensesTab } from "../utils";

export function useExpensesTab(): ExpensesTab {
  let { hash } = useLocation();
  hash = hash.substring(1);

  if (hash && Object.values<string>(ExpensesTab).includes(hash)) {
    return hash as ExpensesTab;
  }

  return EXPENSE_MENU_TABS[0].id;
}
