import { Fee } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { FeeListItem } from "./components/FeeListItem";

export interface TabFeesProps {
  data?: Fee[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  activeFeeId?: string;
  onGoToPage: (value: number) => void;
  onUpdateCustomerFee: (item: Fee) => void;
}

export function TabFees({
  data,
  currentPage,
  lastPage,
  activeFeeId,
  onGoToPage,
  onUpdateCustomerFee,
}: TabFeesProps) {
  const listRenderer = useCallback(
    (item: Fee) => (
      <FeeListItem
        data={item}
        checked={activeFeeId === item.uuid}
        onSelectFee={onUpdateCustomerFee}
        key={item.uuid}
      />
    ),
    [activeFeeId, onUpdateCustomerFee]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Col>
          <H4>Taxas</H4>
        </Col>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={108}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui taxas cadastradas</Text>
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
