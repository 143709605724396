import queryString from "query-string";
import type { Customer, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { CustomerDTO } from "~/infrastructure/api/dtos";
import { mapCustomerDTO } from "~/infrastructure/api/mappers";
import type { ICustomerService, IFindCustomerParams } from "./ICustomerService";

export class CustomerService implements ICustomerService {
  async findById(
    id: string,
    params: { withIssuers?: boolean; withApprovers?: boolean; withAdditionalInfo?: boolean } = {}
  ): Promise<Customer> {
    const url = queryString.stringifyUrl({
      url: `/customers/${id}`,
      query: {
        with_issuers: params.withIssuers,
        with_approvers: params.withApprovers,
        with_additional_info: params.withAdditionalInfo,
      },
    });
    return await api.get<CustomerDTO>(url).then(({ data }) => mapCustomerDTO(data));
  }

  async find({
    page = 1,
    name,
    active,
  }: IFindCustomerParams): Promise<PaginatedResource<Customer[]>> {
    const url = queryString.stringifyUrl({
      url: "/customers",
      query: { page, name, active },
    });

    return await api.get<PaginatedResource<CustomerDTO[]>>(url).then(({ data }) => ({
      links: data.links,
      meta: data.meta,
      data: data.data.map(mapCustomerDTO),
    }));
  }

  async create(data: Omit<Customer, "uuid">): Promise<Customer> {
    return await api
      .post<CustomerDTO>("/customers", {
        trading_name: data.tradingName,
        company_name: data.companyName,
        cnpj: data.cnpj.replace(/\D/g, ""),
        state_registration: data.stateRegistration,
        phone: data.phone.replace(/\D/g, ""),
        email: data.email,
        integration_id: data.integrationId,
        wintour_code: data.wintourCode,
        flight_manager: data.flightManager,
        zip_code: data.zipCode,
        address: data.address,
        number: data.number,
        district: data.district,
        state: data.state.state,
        city_uuid: data.city.uuid,
        city: data.city.name,
        agency_uuid: data.agency.uuid,
        fee_uuid: data.feeId,
        markdown_uuid: data.markdownId,
        markup_uuid: data.markupId,
        dashboard_link: data.dashboardLink,
        domains: data.domains.map((domain) => domain.domain).filter((domain) => domain),
      })
      .then(({ data }) => mapCustomerDTO(data));
  }

  async updateById(data: Customer): Promise<Customer> {
    return await api
      .put<CustomerDTO>(`/customers/${data.uuid}`, {
        trading_name: data.tradingName,
        company_name: data.companyName,
        cnpj: data.cnpj.replace(/\D/g, ""),
        state_registration: data.stateRegistration,
        phone: data.phone.replace(/\D/g, ""),
        email: data.email,
        wintour_code: data.wintourCode,
        integration_id: data.integrationId,
        flight_manager: data.flightManager,
        zip_code: data.zipCode.replace(/\D/g, ""),
        address: data.address,
        number: data.number,
        district: data.district,
        state: data.state.state,
        city_uuid: data.city.uuid,
        city: data.city.name,
        dashboard_link: data.dashboardLink,
        domains: data.domains.map((domain) => domain.domain).filter((domain) => domain),
      })
      .then(({ data }) => mapCustomerDTO(data));
  }

  async creditLimit({
    customerId,
    creditLimit,
  }: {
    customerId: string;
    creditLimit: number;
  }): Promise<void> {
    return api
      .patch<void>(`/customers/${customerId}/credit-limits`, {
        credit_limit: creditLimit,
      })
      .then();
  }

  async toggleActive(data: Customer): Promise<void> {
    if (data.isActive) {
      return await api.patch<void>(`/customers/${data.uuid}/inactive`).then(({ data }) => data);
    }

    return await api.patch<void>(`/customers/${data.uuid}/active`).then(({ data }) => data);
  }

  async toggleFraudBlocking(data: Customer): Promise<void> {
    return await api
      .patch<void>(`/customers/${data.uuid}/${data.fraudBlocking ? "unblock" : "block"}`)
      .then(({ data }) => data);
  }
}
