import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  ApprovalModel,
  ApprovalTypeEnum,
  CostCenter,
  Order,
  TravelerAdvance,
  UserContext,
} from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { CreateAssessmentData } from "../../../../utils";
import { expenseTypeService } from "~/application/usecases";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "~/constants";

export interface ConfirmRequestApprovalDialogProps {
  data?: Order;
  advanceOrder: TravelerAdvance;
  fetchApprovalModels: () => Promise<ApprovalModel[]>;
  onSubmit:
    | ((data: TravelerAdvance & CostCenter & { costCenterId: string }) => void)
    | undefined;
}

export function ConfirmRequestApprovalExpenseDialog({
  data,
  advanceOrder,
  onSubmit,
}: ConfirmRequestApprovalDialogProps) {
  const { control, formState, handleSubmit, watch, setError, clearErrors } =
    useForm<CreateAssessmentData>({
      reValidateMode: "onBlur",
      mode: "onSubmit",
    });

  const { dirtyFields } = formState;
  const { costCenter } = watch();
  const { user } = useUser();

  const fetchCostCenter = useCallback(
    () => expenseTypeService.findCostCenter(user.customer.uuid),
    []
  );

  const onCloseDialog = () => dialogService.popDialog();

  const { data: costsCenterData, isFetching: isFetchingCoastCenter } = useQuery(
    [QueryKeys.CUSTOMER_COST_CENTERS],
    fetchCostCenter
  );

  const customerEmployeeUuid = user.profiles.customer.uuid;

  const issuerIsSelfApprover = costCenter?.approvalModels?.some(
    (approvalModel) => {
      return (
        approvalModel.approvalType === ApprovalTypeEnum.SINGLE &&
        approvalModel.approvers.some(({ uuid, isSelfApprover }) => {
          return uuid === data?.issuer?.uuid && isSelfApprover;
        })
      );
    }
  );

  const userIsSelfApprover = costCenter?.approvalModels.some(
    (approvalModel) => {
      return (
        approvalModel.approvalType === ApprovalTypeEnum.SINGLE &&
        approvalModel.approvers.some(({ uuid, isSelfApprover }) => {
          return uuid === customerEmployeeUuid && isSelfApprover;
        })
      );
    }
  );

  const canApproveInstantly =
    userIsSelfApprover ||
    (user.context === UserContext.Agency && issuerIsSelfApprover);

  useEffect(() => {
    if (Object.keys(dirtyFields).length === 0) {
      return;
    }

    if (!dirtyFields.costCenter) {
      setError("costCenter", { type: "required" });
    } else {
      clearErrors("costCenter");
    }
  }, [data]);

  const onContinue = async (data: CreateAssessmentData) => {
    await onSubmit?.({
      costCenterId: (data.costCenter as CostCenter).uuid,
      ...(data.costCenter as CostCenter),
      name: data.costCenter?.name || '',
      ...advanceOrder,
    });

    dialogService.popAll();
  };

  const onError = (error: any) => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  const getLoadingMessage = () => {
    return canApproveInstantly
      ? "Aprovando automaticamente o pedido"
      : "Solicitando Aprovação";
  };

  if (formState.isSubmitting) {
    return (
      <LoadingModal
        isOpen={formState.isSubmitting}
        message={getLoadingMessage()}
      />
    );
  }

  return (
    <Container size={{ "@initial": "8", "@mxlg": "4" }} fixed>
      <Form
        onSubmit={handleSubmit(async (data) => {
          if (!data.costCenter) {
            return;
          }

          if (canApproveInstantly) {
            data.canApproveInstantly = true;

            dialogService.popDialog();
          }

          return await onContinue(data);
        }, onError)}
      >
        <FormDialog
          title="Confirmar solicitação de aprovação da despesa"
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseDialog}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button
              disabled={
                formState.isSubmitting ||
                costCenter?.approvalModels.length === 0
              }
              type="submit"
            >
              <Text>{canApproveInstantly ? "Aprovar" : "Enviar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseDialog}
        >
          <DialogBody css={{ p: "$6", height: "250px" }}>
            <Row
              gap="6"
              css={{
                "@mxlg": {
                  flexDirection: "column",
                },
              }}
            >
              <Col sz="12">
                <FormControl name="costCenter" control={control} required>
                  <FieldLabel>Nome do centro de custo</FieldLabel>
                  <AsyncSelect
                    defaultOptions={costsCenterData}
                    isLoading={isFetchingCoastCenter}
                    fetchOptions={fetchCostCenter}
                    getOptionLabel={(item: CostCenter) => item.name}
                    getOptionValue={(item: CostCenter) => item.uuid}
                    placeholder="Selecione o centro de custo"
                  />
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
