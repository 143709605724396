import { Project } from "~/application/types";
import { ProjectDTO } from "../dtos";

export const mapProjectDTO = (data: ProjectDTO): Project => {
  return {
    uuid: data.uuid,
    name: data.name,
    code: data.code,
    customerId: data.customer_uuid,
    active: data.active,
  };
};
