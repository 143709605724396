import { useQuery } from "@tanstack/react-query";
import { Fragment, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Agency, City, State } from "~/application/types";
import { Box } from "~/components/Box";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { MaskedInput, Select, TextInput } from "~/components/Input";
import { H4 } from "~/components/Typography";
import { QueryKeys } from "~/constants/queryKeys";
import * as MaskUtils from "~/utils/mask.utils";
import { states } from "../../../../data";
import { EditableAgency, editAgency } from "../utils/form";

export interface AgencyFormProps {
  formId?: string;
  defaultData?: Agency;
  onSubmit: (data: Agency) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function AgencyForm({ formId, defaultData, fetchCitiesByState, onSubmit }: AgencyFormProps) {
  const { control, watch, handleSubmit } = useForm<EditableAgency>({
    defaultValues: defaultData && editAgency(defaultData),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { state, phone } = watch();

  const { data: stateCities, isFetching } = useQuery(
    [QueryKeys.STATE_CITIES, state?.state],
    () => fetchCitiesByState(state!),
    {
      cacheTime: 0,
      retry: 2,
      enabled: !!state,
    }
  );

  const getStateLabel = useCallback((state: State) => state.name, []);
  const getStateValue = useCallback((state: State) => state.state, []);

  const getCityLabel = useCallback((city: City) => city.name, []);
  const getCityValue = useCallback((city: City) => city.uuid, []);

  return (
    <Fragment>
      <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Box css={{ mb: "$10" }}>
          <H4>Informações básicas</H4>
        </Box>

        <Row gap="6">
          <Col sz="8">
            <FormControl name="cnpj" control={control} pattern={MaskUtils.PATTERN_CNPJ} required>
              <FieldLabel>CNPJ</FieldLabel>
              <MaskedInput mask={MaskUtils.MASK_CNPJ} />
            </FormControl>
          </Col>

          <Col sz="4">
            <FormControl name="stateRegistration" control={control}>
              <FieldLabel>Inscrição Estadual</FieldLabel>
              <TextInput />
            </FormControl>
          </Col>

          <Col sz="12">
            <FormControl name="companyName" control={control} required>
              <FieldLabel>Razão social</FieldLabel>
              <TextInput />
            </FormControl>
          </Col>

          <Col sz="12">
            <FormControl name="tradingName" control={control} required>
              <FieldLabel>Nome Fantasia</FieldLabel>
              <TextInput />
            </FormControl>
          </Col>

          <Col sz="8">
            <FormControl name="email" control={control} required>
              <FieldLabel>E-mail</FieldLabel>
              <TextInput />
            </FormControl>
          </Col>

          <Col sz="4">
            <FormControl name="phone" control={control} required>
              <FieldLabel>Telefone</FieldLabel>
              <MaskedInput mask={MaskUtils.pickPhoneNumberMask(phone)} />
            </FormControl>
          </Col>
        </Row>

        <Box css={{ my: "$10" }}>
          <H4>Endereço</H4>
        </Box>

        <Row gap="6">
          <Col sz="5">
            <FormControl name="zipCode" control={control} pattern={MaskUtils.PATTERN_CEP} required>
              <FieldLabel>CEP</FieldLabel>
              <MaskedInput mask={MaskUtils.MASK_CEP} />
            </FormControl>
          </Col>

          <Col sz="5">
            <FormControl name="district" control={control} required>
              <FieldLabel>Bairro</FieldLabel>
              <TextInput />
            </FormControl>
          </Col>

          <Col sz="2">
            <FormControl name="number" control={control} required>
              <FieldLabel>Número</FieldLabel>
              <TextInput />
            </FormControl>
          </Col>

          <Col sz="6">
            <FormControl name="state" control={control} required>
              <FieldLabel>Estado</FieldLabel>
              <Select
                placeholder="Selecione o estado"
                getOptionLabel={getStateLabel}
                getOptionValue={getStateValue}
                options={states}
              />
            </FormControl>
          </Col>

          <Col sz="6">
            <FormControl name="city" control={control} required>
              <FieldLabel>Cidade</FieldLabel>
              <Select
                placeholder="Selecione a cidade"
                options={stateCities}
                getOptionLabel={getCityLabel}
                getOptionValue={getCityValue}
                isLoading={isFetching}
              />
            </FormControl>
          </Col>

          <Col sz="12">
            <FormControl name="address" control={control} required>
              <FieldLabel>Logradouro</FieldLabel>
              <TextInput />
            </FormControl>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}
