import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import React, { ComponentProps } from "react";
import { keyframes, styled } from "~/application/theme";

export type TooltipProps = ComponentProps<typeof ContentRoot> & {
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactElement;
  content: React.ReactNode;
};

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-4px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const ArrowRoot = styled(TooltipPrimitive.Arrow, {});

const ContentRoot = styled(TooltipPrimitive.Content, {
  // Custom
  boxShadow: "$md",
  borderRadius: "$md",
  padding: "$2",
  zIndex: "$tooltip",

  '&[data-state="delayed-open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
  },

  variants: {
    variant: {
      black: {
        color: "$neutrals-white",
        backgroundColor: "$neutrals-black",
        [`& ${ArrowRoot}`]: {
          fill: "$neutrals-black",
        },
      },
      white: {
        color: "$neutrals-darkest",
        backgroundColor: "$neutrals-white",
        [`& ${ArrowRoot}`]: {
          fill: "$neutrals-white",
        },
      },
      info: {
        color: "$neutrals-white",
        backgroundColor: "$primary-base",
        [`& ${ArrowRoot}`]: {
          fill: "$primary-base",
        },
      },
      error: {
        color: "$neutrals-white",
        backgroundColor: "$error-base",
        [`& ${ArrowRoot}`]: {
          fill: "$error-base",
        },
      },
      warning: {
        color: "$neutrals-black",
        backgroundColor: "$warning-base",
        [`& ${ArrowRoot}`]: {
          fill: "$warning-base",
        },
      },
      success: {
        color: "$neutrals-white",
        backgroundColor: "$success-base",
        [`& ${ArrowRoot}`]: {
          fill: "$success-base",
        },
      },
    },
  },

  defaultVariants: {
    variant: "black",
  },
});

export const Tooltip = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  ...props
}: TooltipProps) => {
  return (
    <TooltipPrimitive.Provider delayDuration={200}>
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>

        <ContentRoot side="top" align="center" sideOffset={-3} {...props}>
          {content}
          <ArrowRoot width={8} height={4} />
        </ContentRoot>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

Tooltip.displayName = "Tooltip";
