import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { DeepPartial } from "~/application/types";
import { CostCenter } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { SvgKey } from "~/components/Icon/icons";
import { NumberInput, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";

const costCenterSchema = yup.object().shape({
  costCenterCode: yup.string().required("O ID da integração interna é obrigatório"),
  name: yup.string().required("O nome do centro de custo é obrigatório"),
  credit: yup.number().nullable(),
});

type CostCenterSchema = yup.InferType<typeof costCenterSchema>;

export interface CostCenterDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<CostCenter>;
  onCloseClick?: () => void;
  onSubmit: (data: CostCenter) => void;
}

export function CostCenterDialog({
  isNew,
  defaultData,
  onCloseClick,
  onSubmit,
}: CostCenterDialogProps) {
  const { control, formState, handleSubmit } = useForm<CostCenterSchema>({
    defaultValues: defaultData,
    resolver: yupResolver(costCenterSchema),
  });

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit((data) => onSubmit(data as CostCenter))}>
        <FormDialog
          title={isNew ? "Novo centro de custo" : "Editar centro de custo"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button
              disabled={formState.isSubmitting}
              type="submit"
            >
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col sz="5">
                <FormControl name="costCenterCode" control={control} required>
                  <FieldLabel>Integração externa</FieldLabel>
                  <TextInput leftIcon={SvgKey} placeholder="Número do ID" />
                  {formState.errors.costCenterCode && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.costCenterCode.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="7">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome do centro de custo</FieldLabel>
                  <TextInput placeholder="Digite o nome do centro de custo" />
                  {formState.errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Box css={{ my: "$10" }}>
              <H5>Orçamento</H5>
            </Box>

            <Row>
              <Col sz="12">
                <FormControl name="credit" control={control}>
                  <FieldLabel>Orçamento deste centro de custo</FieldLabel>
                  <NumberInput prefix="R$ " placeholder="R$ 5.000,00" />
                  {formState.errors.credit && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.credit.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
