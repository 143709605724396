import { FC } from "react";
import { Hotel } from "~/application/types";
import { Card, CardBody, CardSectionHeader } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgFilter } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Slider } from "~/components/Slider";
import { Text } from "~/components/Text";
import { Filter } from "~/presentation/shared/utils";
import { asCurrency } from "~/utils/mask.utils";

export type FiltersDialogProps = {
  dataDefault: Hotel[];
  rangeValue: {
    max?: number;
    min?: number;
    providersFinished: number;
  };
  filters: Filter[];
  countProvidersFinished: number;
  filterRenderer: (item: Filter, index: number) => JSX.Element;
  onRangeValue: ({
    max,
    min,
    providersFinished,
  }: {
    max: number;
    min: number;
    providersFinished: number;
  }) => void;
  onClose: () => void;
};

export const FiltersDialog: FC<FiltersDialogProps> = ({
  dataDefault,
  countProvidersFinished,
  filters,
  rangeValue,
  filterRenderer,
  onRangeValue,
  onClose,
}) => {
  return (
    <Card
      css={{
        width: "400px",
      }}
    >
      <CartHeader>
        <Flex justify="between" css={{ width: "100%" }} align="center">
          <Flex gap="2">
            <Icon as={SvgFilter} />
            <Text>Filtrar resultados</Text>
          </Flex>
          <IconButton onClick={onClose}>
            <Icon as={SvgClose} />
          </IconButton>
        </Flex>
      </CartHeader>

      <>
        <CardSectionHeader>Valor total</CardSectionHeader>
        {rangeValue.providersFinished === countProvidersFinished && (
          <CardBody css={{ p: "$4" }}>
            <Flex align="center" gap="1" justify="center" direction="column">
              <Flex
                justify="between"
                align="center"
                css={{
                  width: "80%",
                }}
              >
                <Flex direction="column" align="center">
                  <Text size="2" fw="600">
                    Min
                  </Text>
                  <Text size="1">{asCurrency(rangeValue.min || 0)}</Text>
                </Flex>
                <Flex direction="column" align="center">
                  <Text size="2" fw="600">
                    Max
                  </Text>
                  <Text size="1">{asCurrency(rangeValue.max || 0)}</Text>
                </Flex>
              </Flex>
              <Slider
                css={{
                  width: "70%",
                }}
                max={dataDefault[(dataDefault.length || 0) - 1]?.bestValueTotal}
                min={dataDefault[0]?.bestValueTotal}
                onValueChange={([min, max]) =>
                  onRangeValue({
                    min,
                    max,
                    providersFinished: countProvidersFinished,
                  })
                }
                defaultValue={[
                  dataDefault[0]?.bestValueTotal || 0,
                  dataDefault[(dataDefault.length || 0) - 1]?.bestValueTotal || 0,
                ]}
              />
            </Flex>
          </CardBody>
        )}
        {filters.map(filterRenderer)}
      </>
    </Card>
  );
};
