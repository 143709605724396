import * as React from "react";
import { SVGProps } from "react";

const SvgGear = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 5.625A4.385 4.385 0 0 0 5.625 10 4.385 4.385 0 0 0 10 14.375 4.384 4.384 0 0 0 14.375 10 4.385 4.385 0 0 0 10 5.625zm0 1.25A3.116 3.116 0 0 1 13.125 10 3.116 3.116 0 0 1 10 13.125 3.116 3.116 0 0 1 6.875 10 3.116 3.116 0 0 1 10 6.875Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M7.713 1.553a8.708 8.708 0 0 0-2.068.855.625.625 0 0 0-.307.453l-.276 1.93a7.2 7.2 0 0 0-.271.271l-1.928.276a.625.625 0 0 0-.453.307 8.7 8.7 0 0 0-.857 2.066.625.625 0 0 0 .103.539l1.17 1.56c0 .171-.005.281-.002.381L1.656 11.75a.625.625 0 0 0-.103.537c.195.723.482 1.42.855 2.068a.625.625 0 0 0 .453.307l1.93.275c.088.094.178.184.271.272l.276 1.928a.625.625 0 0 0 .307.453 8.7 8.7 0 0 0 2.066.857.625.625 0 0 0 .539-.103l1.56-1.17c.127.003.253.003.38 0l1.56 1.17a.625.625 0 0 0 .537.103 8.71 8.71 0 0 0 2.068-.855.625.625 0 0 0 .307-.453l.275-1.93c.124-.122.205-.198.274-.272l1.926-.275a.625.625 0 0 0 .453-.307 8.7 8.7 0 0 0 .857-2.066.625.625 0 0 0-.103-.539l-1.17-1.56c0-.171.005-.281.002-.381l1.168-1.559a.625.625 0 0 0 .103-.537 8.71 8.71 0 0 0-.855-2.068.625.625 0 0 0-.453-.307l-1.93-.276a7.21 7.21 0 0 0-.271-.269l-.276-1.93a.625.625 0 0 0-.307-.453 8.7 8.7 0 0 0-2.066-.857.625.625 0 0 0-.539.103l-1.56 1.17a7.22 7.22 0 0 0-.38 0l-1.56-1.17a.625.625 0 0 0-.537-.103Zm.066 1.312L9.225 3.95a.625.625 0 0 0 .412.125c.241-.014.485-.014.726 0a.625.625 0 0 0 .412-.125l1.446-1.084c.433.135.852.31 1.254.522l.255 1.787a.625.625 0 0 0 .204.379c.18.16.353.332.513.513a.625.625 0 0 0 .38.204l1.786.255c.212.403.387.82.522 1.254L16.05 9.225a.625.625 0 0 0-.125.394s.011.553 0 .744a.625.625 0 0 0 .125.412l1.084 1.446a7.43 7.43 0 0 1-.522 1.254l-1.787.255a.625.625 0 0 0-.367.19s-.382.4-.525.527a.625.625 0 0 0-.204.38l-.255 1.786a7.43 7.43 0 0 1-1.254.522l-1.446-1.084a.625.625 0 0 0-.412-.125 5.97 5.97 0 0 1-.726 0 .625.625 0 0 0-.412.125l-1.446 1.084a7.43 7.43 0 0 1-1.254-.522l-.255-1.787a.625.625 0 0 0-.204-.379 6 6 0 0 1-.513-.513.625.625 0 0 0-.38-.204l-1.786-.255a7.429 7.429 0 0 1-.522-1.254l1.084-1.446a.625.625 0 0 0 .125-.394s-.011-.553 0-.744a.625.625 0 0 0-.125-.412L2.865 7.779a7.43 7.43 0 0 1 .522-1.254l1.787-.255a.625.625 0 0 0 .379-.204c.16-.18.332-.353.513-.513a.625.625 0 0 0 .204-.38l.255-1.786a7.43 7.43 0 0 1 1.254-.522Z"
    />
  </svg>
);

export default SvgGear;
