import { AgencyEmployee } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import {
  DataColItem,
  DataListItem,
  DataListItemProps
} from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import * as MaskUtils from "~/utils/mask.utils";

export interface AgencyEmployeeListItemProps extends DataListItemProps {
  data: AgencyEmployee;
  onEditClick: (item: AgencyEmployee) => void;
  onToggleState: (item: AgencyEmployee) => void;
}

export function AgencyEmployeeListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: AgencyEmployeeListItemProps) {
  return (
    <DataListItem data-active={data.isActive} {...props}>
      <DataColItem headerTitle="Nome" data={data.name} />
      <DataColItem headerTitle="CPF" data={MaskUtils.asCPF(data.cpf)} />
      <DataColItem
        headerTitle="Tipo de acesso"
        data={data.type === "M" ? "Administrador" : "Padrão"}
      />
      <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
        <IconButton size="md" onClick={() => onEditClick(data)}>
          <Icon as={SvgEdit} />
        </IconButton>
      </Tooltip>
      <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
        <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
      </Switch>
    </DataListItem>
  );
}

AgencyEmployeeListItem.displayName = "AgencyEmployeeListItem";
