import { Road } from "~/application/types";
import { dayTimeOptions } from "~/presentation/Booking/constants";
import { IFilterGenerator, IFilterGroup } from "~/presentation/shared/utils";

export const STATIC_ROAD_FILTERS: IFilterGroup<Road>[] = [
  {
    key: "withBPE",
    label: "Bilhete eletrônico",
    isValid: (item, optionValue) => item.withBPE === optionValue,
    options: [
      {
        key: "yes",
        label: "Sim",
        value: true,
      },
    ],
  },
  {
    key: "allowCanceling",
    label: "Permite cancelamento",
    isValid: (item, optionValue) => item.withBPE === optionValue,
    options: [
      {
        key: "yes",
        label: "Sim",
        value: true,
      },
    ],
  },
  {
    key: "departureTime",
    label: "Período de partida",
    isValid: (item, optionValue) => {
      const departureTime = item.departureDate.getHours();

      return optionValue[0] <= departureTime && departureTime < optionValue[1];
    },
    options: dayTimeOptions,
  },
  {
    key: "arrivalTime",
    label: "Período de chegada",
    isValid: (item, optionValue) => {
      const arrivalTime = item.arrivalDate.getHours();

      return optionValue[0] <= arrivalTime && arrivalTime < optionValue[1];
    },
    options: dayTimeOptions,
  },
];

export const DYNAMIC_ROAD_FILTERS: IFilterGenerator<Road>[] = [
  {
    key: "departureTerminal",
    label: "Terminal de embarque",
    generator: (item) => item.from,
  },
  {
    key: "landingTerminal",
    label: "Terminal de desembarque",
    generator: (item) => item.to,
  },
  {
    key: "company",
    label: "Empresa",
    generator: (item) => item.company,
  },
  {
    key: "seatClass",
    label: "Classe de assento",
    generator: (item) => item.seatClass,
  },
];
