import * as React from "react";
import { SVGProps } from "react";

const SvgKey = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M13.125 1.291a6.248 6.248 0 0 0-6.59 4.35c-.384 1.227-.317 2.527.07 3.746L2.06 13.934a.625.625 0 0 0-.184.441V17.5a.625.625 0 0 0 .625.625h3.125a.625.625 0 0 0 .625-.625v-1.25H7.5a.625.625 0 0 0 .625-.625v-1.25h1.25a.625.625 0 0 0 .443-.184l.797-.796c1.218.387 2.518.453 3.744.07a6.242 6.242 0 0 0 4.35-6.59 6.243 6.243 0 0 0-5.584-5.584ZM13 2.535a4.992 4.992 0 0 1 4.465 4.467 4.99 4.99 0 0 1-3.479 5.27 4.995 4.995 0 0 1-3.35-.131l.005.004a.625.625 0 0 0-.678.134l-.846.846H7.5a.625.625 0 0 0-.625.625V15h-1.25a.625.625 0 0 0-.625.625v1.25H3.125v-2.24l4.596-4.596a.625.625 0 0 0 .054-.707l.084.031A4.995 4.995 0 0 1 13 2.535Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
        opacity: 0.5,
      }}
      d="M14.063 5a.947.947 0 0 0-.938.938c0 .51.427.937.938.937.51 0 .937-.427.937-.938A.947.947 0 0 0 14.062 5Zm0 .625c.165 0 .312.147.312.313a.323.323 0 0 1-.313.312.323.323 0 0 1-.312-.313c0-.165.147-.312.313-.312z"
    />
    <path d="M14.063 6.875a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z" />
  </svg>
);

export default SvgKey;
