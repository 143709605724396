import { ApprovalModel } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface ApprovalModelListItemProps extends DataListItemProps {
  data: ApprovalModel;
  onEditClick: (item: ApprovalModel) => void;
  onToggleState: (item: ApprovalModel) => void;
}

export function ApprovalModelListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: ApprovalModelListItemProps) {
  const isMobile = useMobile();
  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Nome" data={data.name} />

      <DataColItem
        headerTitle="Aplica-se para todas as filiais"
        data={data.isAllBranches ? "sim" : "não"}
      />

      {isMobile ? (
        <Flex justify="between" css={{ width: "100%" }}>
          <Tooltip content={<TooltipLabel>Editar Filial</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </Flex>
      ) : (
        <>
          <Tooltip content={<TooltipLabel>Editar Filial</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </>
      )}
    </DataListItem>
  );
}

ApprovalModelListItem.displayName = "ApprovalModelListItem";
