import { useCallback, useState } from "react";

export interface UseRoadItemResult {
  isRoadItemExpanded: boolean;
  toggleRoadItemVisible: () => void;
}

export function useRoadItem(): UseRoadItemResult {
  const [isRoadItemExpanded, setRoadItemExpanded] = useState(true);

  const toggleRoadItemVisible = useCallback(() => {
    setRoadItemExpanded((old) => !old);
  }, [isRoadItemExpanded]);

  return {
    isRoadItemExpanded,
    toggleRoadItemVisible,
  };
}
