import { useMutation } from "@tanstack/react-query";
import { OrderItemStatus } from "~/application/types";
import { assessmentService } from "~/application/usecases";
import { offlineHotelService } from "~/application/usecases/OfflineHotel";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { CreateAssessmentData } from "~/core/modules/Order/pages/OrderPage/utils";
import { CreditLimitExceededDiolog } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/ConfirmRequestApprovalDialog/components/creditLimitExceededDiolog";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { logError } from "~/presentation/shared/utils/errors";
import { useUser } from "../../contexts/UserContext";
import { UseAssessmentProps } from "./types";
const LIMIT_CREDIT_EXCEEDED = "INVOICE_LIMIT_UNAVAILABLE";

const SNACKBAR_MESSAGES = {
  REQUEST_APPROVAL_SUCCESS_MESSAGE: "Solicitação de aprovação feita com sucesso",
  ORDER_APPROVAL_SUCCESS_MESSAGE: "Pedido aprovado com sucesso",
  ORDER_REPROVAL_SUCCESS_MESSAGE: "Pedido rejeitado com sucesso",
  CANCEL_ORDER_SUCCESS_MESSAGE: "Pedido cancelado com sucesso",
  CANCEL_ORDER_ERROR_MESSAGE: "Falha ao cancelar pedido",
  REQUEST_CANCEL_ORDER_SUCCESS_MESSAGE: "Solitação de cancelamento feita com sucesso",
  REQUEST_CANCEL_ORDER_ERROR_MESSAGE: "Falha ao solicitar cancelamento",
  REQUEST_APPROVAL_ERROR_MESSAGE: "Falha ao solicitar aprovação",
  LOAD_COST_CENTERS_ERROR_MESSAGE: "Falha ao carregar centros de custo",
} as const;

export function useAssessment({
  order,
  logTag,
  refetchOrder,
  refetchOrderHistory,
}: UseAssessmentProps) {
  const { user } = useUser();
  return useMutation(
    async (item: CreateAssessmentData) => {
      if (item.approver?.uuid === user.profiles.customer.uuid) {
        item.approver = undefined;
      }
      order?.items.hotel?.rooms.forEach(async (room) => {
        room.options?.forEach(async (option) => {
          if (option.status === OrderItemStatus.QUOTED) {
            await offlineHotelService.selectOption(option.uuid as string);
          }
        });
      });

      return await assessmentService.create({
        orderId: order?.uuid || "",
        branchId: item?.branch?.uuid,
        brokenPolicyItemsJustification: item.brokenPolicyItems,
        phaseId: item.phase?.uuid,
        approvers: item.approver
          ? [item.approver].map((approver) => approver?.uuid).filter((item) => item)
          : [],
        ...item,
      });
    },
    {
      onMutate: (requestApprovalVariables) => {
        dialogService.popDialog();

        if (
          requestApprovalVariables.canApproveInstantly &&
          requestApprovalVariables.rejectedItems === undefined
        ) {
          return dialogService.showDialog(
            <LoadingDialog message="Aprovando automaticamente o pedido" />
          );
        }

        if (
          requestApprovalVariables.rejectedItems &&
          requestApprovalVariables.rejectedItems.length > 0
        ) {
          return dialogService.showDialog(
            <LoadingDialog message="Rejeitando pedido devido à mudança de preço" />
          );
        }

        dialogService.showDialog(<LoadingDialog message="Solicitando aprovação" />);
      },
      onSuccess: (data) => {
        refetchOrder();
        refetchOrderHistory();

        dialogService.popAll();

        if (data.message) {
          snackbarService.showSnackbar(data.message, "warning");
        }

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.REQUEST_APPROVAL_SUCCESS_MESSAGE, "success");
      },
      onError: (error: { code: string }) => {
        const message = error.code;

        dialogService.popAll();
        if (message?.includes(LIMIT_CREDIT_EXCEEDED)) {
          dialogService.showDialog(<CreditLimitExceededDiolog />);
          return;
        }

        logError({
          error,
          logTag,
          defaultErrorMessage: SNACKBAR_MESSAGES.REQUEST_APPROVAL_ERROR_MESSAGE,
        });

        dialogService.popAll();
      },
    }
  );
}
