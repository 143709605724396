import { useChannel } from "../useChannel";

export interface UseAirwayBookingChannelProps {
  orderId: string;
  enabled?: boolean;
  onAirwayBooked?: (data: any) => void;
}

export function useAirwayBookingChannel({
  orderId,
  enabled,
  onAirwayBooked,
}: UseAirwayBookingChannelProps) {
  useChannel({
    enabled,
    channelName: `orders.booked.${orderId}`,
    eventName: 'order.booked',
    onMessage: onAirwayBooked,
  })

}
