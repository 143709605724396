import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgChevronRight } from "~/components/Icon/icons";
import { useCarousel } from "~/hooks/useCarousel";

export function Carrossel({
  images,
  size,
}: {
  images: string[];
  size: number;
}) {
  const { currentItem, toNextItem, toPreviousItem } = useCarousel({
    size,
  });
  
  return (
    <Flex
      css={{
        width: "100%",
        height: "120px",
        backgroundImage: `url(${images[currentItem]}), url(/images/broken-image.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "$md $md 0 0",
        px: "$2",
      }}
    >
      <Flex
        align="center"
        justify="between"
        gap="4"
        css={{
          width: "100%",
        }}
      >
        <Flex
          css={{
            backgroundColor: "$neutrals-lightest",
            p: "$2",
            display: images.length <= 1 ? "none" : "inline",
            borderRadius: "$circular",
            cursor: "pointer",
            fill: "$neutrals-black",
            transition: "background-color 0.2s ease-in-out",
            ["&:hover"]: {
              backgroundColor: "$neutrals-light",
            },
            "@mxlg": {
              p: "$1"
            }
          }}
          onClick={toNextItem}
        >
          <Icon as={SvgChevronLeft} />
        </Flex>

        <Flex
          css={{
            backgroundColor: "$neutrals-lightest",
            p: "$2",
            borderRadius: "$circular",
            display: images.length <= 1 ? "none" : "inline",
            cursor: "pointer",
            fill: "$neutrals-black",
            transition: "background-color 0.2s ease-in-out",
            ["&:hover"]: {
              backgroundColor: "$neutrals-light",
            },
            "@mxlg": {
              p: "$1"
            }
          }}
          onClick={toPreviousItem}
        >
          <Icon as={SvgChevronRight} />
        </Flex>
      </Flex>
    </Flex>
  );
}
