import { JudgementStatus } from "~/application/types";
import { Card } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { getApprovalStatus } from "../../utils/approval-model-functions/functions";

export interface ApproversListProps {
  enabled: boolean;
  approvers: {
    uuid: string;
    name: string;
    approvalStatus: JudgementStatus;
    isSelfApprover: boolean;
  }[];
}

export function ApproversListItem({ enabled, approvers }: ApproversListProps) {
  return (
    <>
      {enabled && (
        <Card>
          <CartHeader
            css={{
              borderRadius: "$none",
              backgroundColor: "$primary-light",
              color: "$primary-dark",
            }}
          >
            <Text>Aprovadores</Text>
          </CartHeader>

          {approvers.map((approver) => {
            const variants = {
              [JudgementStatus.APPROVED]: () => "success-light" as const,
              [JudgementStatus.DISAPPROVED]: () => "error-light" as const,
            };

            type Variant = "neutral" | "success-light" | "error-light";
            let variant: Variant = "neutral";

            if (approver.approvalStatus in variants) {
              variant = variants[approver.approvalStatus]();
            }

            return (
              <Flex key={approver.uuid} gap="4" align="center" css={{ p: "$4" }}>
                <Col>
                  <Text>{approver.name}</Text>
                </Col>

                <Tag variant={variant}>
                  <Text>{getApprovalStatus(approver.approvalStatus)}</Text>
                </Tag>
              </Flex>
            );
          })}
        </Card>
      )}
    </>
  );
}
