import { useReducer } from "react";
import type {
  AirwayBookingState,
  FlightSelected,
  UnflowDispatch,
} from "~/presentation/Booking/BookingAirway/types";
import type { AirwayBookingDispatchAction, AirwayReducer } from "./types";

const initialState: AirwayBookingState = {
  goFlightSelected: null,
  returnFlightSelected: null,
  stretch: [],
  isCombined: false,
  adultQuantity: 1,
  travelers: [],
};

export function airwayBookingReducer(
  state: AirwayBookingState,
  action: AirwayBookingDispatchAction
): AirwayBookingState {
  switch (action.type) {
    case "SET_ADULT_QUANTITY":
      return { ...state, adultQuantity: action.payload };
    case "SET_STRETCH":
      return {
        ...state,
        stretch: [
          ...state.stretch,
          {
            flight: action.payload.flight,
            oneWayFlight: action.payload.flight,
            flightOption: action.payload.flightOption,
            combinedFlight: null,
            isCombined: false,
            index: action.payload.index,
          },
        ],
      };
    case "UPDATE_STRETCH":
      return {
        ...state,
        stretch: state.stretch.map((stretchItem, index) =>
          index === action.payload.index
            ? {
                flight: action.payload.flight,
                oneWayFlight: action.payload.flight,
                flightOption: action.payload.flightOption,
                combinedFlight: null,
                isCombined: false,
                index: action.payload.index,
              }
            : stretchItem
        ),
      };
    case "SET_GO_FLIGHT":
      return {
        ...state,
        goFlightSelected: {
          flight: action.payload.flight,
          oneWayFlight: action.payload.flight,
          flightOption: action.payload.flightOption,
          combinedFlight: null,
          isCombined: false,
        },
        returnFlightSelected: null,
        isCombined: false,
      };
    case "SET_RETURN_FLIGHT":
      return {
        ...state,
        ...(action.payload.goFlight &&
          state.goFlightSelected && {
            goFlightSelected: {
              flight: action.payload.goFlight.flight,
              oneWayFlight: state.goFlightSelected.oneWayFlight,
              flightOption: action.payload.goFlight.flightOption,
              combinedFlight: action.payload.goFlight.combinedFlight,
              isCombined: !!action.payload.goFlight?.combinedFlight,
            },
          }),
        returnFlightSelected: action.payload.returnFlight && {
          flight:
            action.payload.returnFlight.combinedFlight ?? action.payload.returnFlight.oneWayFlight,
          oneWayFlight: action.payload.returnFlight.oneWayFlight,
          flightOption: action.payload.returnFlight.flightOption,
          combinedFlight: action.payload.returnFlight.combinedFlight,
          isCombined: !!action.payload.returnFlight.combinedFlight,
        },
        isCombined: !!action.payload.goFlight && !!action.payload.returnFlight?.combinedFlight,
      };
    case "SET_TRAVELERS":
      return { ...state, travelers: action.payload };
    case "SET_GO_UNFLOW":
      return {
        ...state,
        goFlightSelected: {
          ...(state.goFlightSelected as FlightSelected),
          unflownAirway: state.goFlightSelected?.unflownAirway?.length
            ? [
                ...(state.goFlightSelected?.unflownAirway?.filter(
                  (unflown) => unflown?.traveler?.uuid !== action.payload?.traveler?.uuid
                ) as UnflowDispatch[]),
                action.payload,
              ]
            : [action.payload],
        },
      };
    case "SET_RETURN_UNFLOW":
      return {
        ...state,
        returnFlightSelected: {
          ...(state.returnFlightSelected as FlightSelected),
          unflownAirway: state.returnFlightSelected?.unflownAirway?.length
            ? [
                ...(state.returnFlightSelected?.unflownAirway?.filter(
                  (unflown) => unflown?.traveler?.uuid !== action.payload?.traveler?.uuid
                ) as UnflowDispatch[]),
                action.payload,
              ]
            : [action.payload],
        },
      };
    case "CLEAR":
      return { ...initialState };
    default:
      return state;
  }
}

export function useAirwayReducer(): AirwayReducer {
  const [state, dispatch] = useReducer(airwayBookingReducer, initialState);

  return { bookingState: state, dispatch };
}
