import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Phase } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { PhaseListItem } from "./components/PhaseListItem";
import { SearchBar } from "~/components/Input";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { IconButton } from "~/components/IconButton";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Flex } from "~/components/Flex";

export interface TabPhasesProps {
  data?: Phase[];
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  search: string;
  onCreatePhase: () => void;
  onUpdatePhase: (item: Phase) => void;
  onGoToPage: (value: number) => void;
  onSearch: (value: string) => void;
}

export function TabPhases({
  data,
  currentPage,
  lastPage,
  isLoading,
  onCreatePhase,
  search,
  onSearch,
  onGoToPage,
  onUpdatePhase,
}: TabPhasesProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const project = searchParams.get('projeto');

  const isMobile = useMobile();

  const listRenderer = useCallback(
    (item: Phase) => (
      <PhaseListItem
        data={item}
        onEditClick={onUpdatePhase}
        key={item.uuid}
      />
    ),
    [onUpdatePhase]
  );

  return (
    <>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <Flex align="center" gap="4">
              <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
                <IconButton size="md" onClick={() => navigate("#projetos")}>
                  <Icon as={SvgChevronLeft} />
                </IconButton>
              </Tooltip>
              <H4>Fases do Projeto {project}</H4>
            </Flex>
          </Col>

          <Col sz="auto" css={{ "@mxlg": { margin: "$5 auto", width: "100%" } }}>
            <SearchBar
              search={search}
              onSearchChange={onSearch}
              placeholder="Encontrar"
              style={isMobile ? { width: "100%" } : undefined}
            />
          </Col>

          <Col sz={{ "@lg": "auto" }}>
            <Button
              title="Novo motivo"
              color="primary"
              onClick={onCreatePhase}
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <Icon as={SvgPlus} />
              <Text>Nova fase</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          isLoading={isLoading}
          EmptyComponent={
            <EmptyState>
              {search.length > 0 ? (
                <Text>Nenhuma fase corresponde aos dados informados</Text>
              ) : (
                <Text>Você não possui fases cadastradas</Text>
              )}
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </>
  );
}
