export enum AgencyEmployeeType {
  ISSUER = "I",
  MANAGER = "M",
}

export type AgencyEmployee = {
  uuid: string;
  name: string;
  phone: string;
  email: string;
  cpf: string;
  type: AgencyEmployeeType;
  userId: string;
  agencyId: string;
  isActive: boolean;
};
