import { FC } from "react";
import { useForm } from "react-hook-form";
import { CostCenter } from "~/application/types";
import { LinkingCostCenterContainer } from "./LinkingCostCenterContainer";
import { dialogService } from "~/components/DialogStack";
import { UseApprovalModelsResult } from "~/core/modules/Customer/pages/CustomerPage/hooks/useApprovalModels";
import { useNavigate } from "react-router-dom";
import { routes } from "~/application/theme/routes";
import { useUser } from "~/presentation/core/contexts/UserContext";

export type LinkingCostCenterData = {
  costCenter: CostCenter;
  approvalModelId: string;
};

export type LinkingCostCenterProps = {
  data?: CostCenter;
  onCloseClick: () => void;
  approvalModelsData: UseApprovalModelsResult;
  customerId?: string;
};

export const LinkingCostCenterPage: FC<LinkingCostCenterProps> = ({
  data,
  onCloseClick,
  approvalModelsData,
  customerId,
}) => {
  const formState = useForm<LinkingCostCenterData>({
    defaultValues: {
      costCenter: data,
      approvalModelId: "",
    },
  });

  const { user } = useUser();

  const navigate = useNavigate();

  const {
    data: approvalModels,
    onAddingNewCostCenter,
    currentPage,
    lastPage,
    onGoToPage,
  } = approvalModelsData;

  const handleRedirectNewApproval = () => {
    dialogService.popAll();
    navigate(
      user.agency
        ? `configuracoes/empresas/${customerId}#modelos-de-aprovacao`
        : "configuracoes/modelos-de-aprovacao"
    );
  };

  return (
    <LinkingCostCenterContainer
      formState={formState}
      onCloseClick={onCloseClick}
      onSubmit={onAddingNewCostCenter}
      approvalModels={approvalModels}
      onRedirectNewApproval={handleRedirectNewApproval}
      currentPage={currentPage}
      lastPage={lastPage}
      onGoToPage={onGoToPage}
    />
  );
};
