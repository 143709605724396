import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { Link, To } from "react-router-dom";
import { useForm } from "react-hook-form";

import { QueryKeys } from "~/application/constants";
import { states } from "~/application/data";
import { City, OfflineHotel, State } from "~/application/types";
import { MaskUtils } from "~/application/utils";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { MaskedInput, NumberInput, Select, TextAreaInput, TextInput } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { createOfflineHotelSchema, RegisterOfflineHotelSchema } from "../utils/form";
import { CreateOfflineHotelProps } from "~/application/usecases/OfflineHotel";
import { UploadImageInput } from "../../../components/UploadImageInput";
import { convertToWebp } from "~/presentation/shared/utils/image-functions/functions";

interface CreateOfflineHotelFormProps {
  defaultData: OfflineHotel;
  isCreatingOfflineHotel: boolean;
  createOfflineHotel: (data: Omit<CreateOfflineHotelProps, "agencyId">) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function CreateOfflineHotelForm({
  defaultData,
  isCreatingOfflineHotel,
  createOfflineHotel,
  fetchCitiesByState,
}: CreateOfflineHotelFormProps) {
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<RegisterOfflineHotelSchema>({
    defaultValues: {
      ...defaultData,
      image: FileList,
      images: FileList,
    },
    resolver: yupResolver(createOfflineHotelSchema),
  });

  const { state, phone, whatsapp, image, images } = watch();

  const { data: stateCities, isFetching: isFetchingCities } = useQuery(
    [QueryKeys.STATE_CITIES, state?.state],
    () => fetchCitiesByState(state!),
    {
      cacheTime: 0,
      enabled: !!state,
    }
  );

  const removeImage = (indexToRemove: number) => {
    const dataTransfer = new DataTransfer();

    Array.from(images as FileList).forEach((file, index) => {
      if (index !== indexToRemove) {
        dataTransfer.items.add(file);
      }
    });

    setValue("images", dataTransfer.files);
  };

  const onSubmit = async (data: RegisterOfflineHotelSchema) => {
    const imageToUpload = await convertToWebp(image as FileList);
    const imagesToUpload = await convertToWebp(images as FileList);
    const payload = { data, image: imageToUpload.at(0), images: imagesToUpload };

    createOfflineHotel(payload as Omit<CreateOfflineHotelProps, "agencyId">);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap="8">
        <H5>Informações básicas</H5>

        <Row gap="6">
          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="cnpj" control={control} pattern={MaskUtils.PATTERN_CNPJ}>
              <FieldLabel>CNPJ</FieldLabel>
              <MaskedInput mask={MaskUtils.MASK_CNPJ} placeholder="Digite o CNPJ" />
              {errors.cnpj && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.cnpj.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="integrationCode" control={control}>
              <FieldLabel>Código de integração</FieldLabel>
              <TextInput placeholder="Digite o código de integração" />
              {errors.integrationCode && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.integrationCode.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="stars" control={control} required>
              <FieldLabel>Número de estrelas</FieldLabel>
              <NumberInput
                placeholder="Digite o número de estrelas"
                allowNegative={false}
                decimalScale={0}
                min={1}
                max={5}
              />
              {errors.stars && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.stars.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="12">
            <FormControl name="description" control={control} required>
              <FieldLabel>Nome</FieldLabel>
              <TextInput placeholder="Digite o nome do hotel" />
              {errors.description && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.description.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="12">
            <FormControl name="obs" control={control}>
              <FieldLabel>Observação</FieldLabel>
              <TextAreaInput placeholder="Digite alguma observação sobre o hotel" />
              {errors.obs && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.obs.message}
                </Text>
              )}
            </FormControl>
          </Col>
        </Row>
      </Flex>

      <Flex direction="column" gap="8" css={{ mt: "$10 " }}>
        <H5>Endereço</H5>

        <Row gap="6">
          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="state" control={control} required>
              <FieldLabel>Estado</FieldLabel>
              <Select
                placeholder="Selecione o estado"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.state}
                options={states}
                size="md"
              />
              {errors.state?.name && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.state.name.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="city" control={control} required>
              <FieldLabel>Cidade</FieldLabel>
              <Select
                placeholder="Selecione a cidade"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.uuid}
                options={stateCities}
                isLoading={isFetchingCities}
                size="md"
              />
              {errors.city?.name && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.city.name.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="district" control={control} required>
              <FieldLabel>Bairro</FieldLabel>
              <TextInput placeholder="Digite o bairro" />
              {errors.district && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.district.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="12">
            <FormControl name="address" control={control} required>
              <FieldLabel>Logradouro</FieldLabel>
              <TextInput placeholder="Digite o logradouro" />
              {errors.address && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.address.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz={{ "@initial": "6", "@mxlg": "12" }}>
            <FormControl name="latitude" control={control}>
              <FieldLabel>Latitude</FieldLabel>
              <NumberInput
                placeholder="Digite a latitude"
                decimalSeparator="."
                thousandSeparator={false}
                fixedDecimalScale={false}
                decimalScale={8}
              />
              {errors.latitude && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.latitude.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz={{ "@initial": "6", "@mxlg": "12" }}>
            <FormControl name="longitude" control={control}>
              <FieldLabel>Longitude</FieldLabel>
              <NumberInput
                placeholder="Digite a longitude"
                decimalSeparator="."
                thousandSeparator={false}
                fixedDecimalScale={false}
                decimalScale={8}
              />
              {errors.longitude && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.longitude.message}
                </Text>
              )}
            </FormControl>
          </Col>
        </Row>
      </Flex>

      <Flex direction="column" gap="8" css={{ mt: "$10 " }}>
        <H5>Contato</H5>

        <Row gap="6">
          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="whatsapp" control={control} required>
              <FieldLabel>Whatsapp</FieldLabel>
              <MaskedInput
                mask={whatsapp ? MaskUtils.pickPhoneNumberMask(whatsapp) : ""}
                placeholder="Digite o whatsapp"
              />
              {errors.whatsapp && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.whatsapp.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="phone" control={control}>
              <FieldLabel>Telefone</FieldLabel>
              <MaskedInput
                mask={phone ? MaskUtils.pickPhoneNumberMask(phone) : ""}
                placeholder="Digite o telefone"
              />
              {errors.phone && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.phone.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
            <FormControl name="email" control={control}>
              <FieldLabel>Email</FieldLabel>
              <TextInput placeholder="Digite o e-mail" />
              {errors.email && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.email.message}
                </Text>
              )}
            </FormControl>
          </Col>

          <Col sz="12">
            <FormControl name="site" control={control}>
              <FieldLabel>Site</FieldLabel>
              <TextInput placeholder="Digite a url do site" />
              {errors.site && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.site.message}
                </Text>
              )}
            </FormControl>
          </Col>
        </Row>
      </Flex>

      <Flex direction="column" gap="8" css={{ mt: "$10 " }}>
        <H5>Fotos</H5>

        <Row gap="6">
          <Col sz={{ "@initial": "6", "@mxlg": "12" }}>
            <UploadImageInput
              {...register("image")}
              label="Importe a foto principal"
              files={image as FileList}
              removeFile={() => setValue("image", [])}
              accept="image/*"
            />
            {errors.image && (
              <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                {errors.image.message}
              </Text>
            )}
          </Col>

          <Col sz={{ "@initial": "6", "@mxlg": "12" }}>
            <UploadImageInput
              {...register("images")}
              label="Importe outras fotos"
              files={images as FileList}
              removeFile={removeImage}
              accept="image/*"
              multiple
            />
            {errors.images && (
              <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                {errors.images.message}
              </Text>
            )}
          </Col>
        </Row>
      </Flex>

      <Flex gap="4" justify="end" css={{ mt: "$10" }}>
        <Link to={-1 as To}>
          <Button variant="tertiary">Cancelar</Button>
        </Link>

        <Button type="submit" disabled={isSubmitting || isFetchingCities || isCreatingOfflineHotel}>
          {isSubmitting && (
            <Spinner
              css={{
                borderLeftColor: "$neutrals-white",
                width: "$4",
                height: "$4",
                borderWidth: "2px",
              }}
            />
          )}
          Cadastrar
        </Button>
      </Flex>
    </Form>
  );
}

