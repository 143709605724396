import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type CardBodyProps = ComponentProps<typeof CardBody>;

export const CardBody = styled("div", {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  padding: "$6",

  // Custom Reset?
  flexDirection: "column",
});
