import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type DialogFooterProps = ComponentProps<typeof DialogFooter>;

export const DialogFooter = styled("footer", {
  // Reset
  boxSizing: "border-box",
  display: "flex",

  // Custom
  gap: "$4",
  padding: "$6",
  alignItems: "center",
  justifyContent: "flex-end",
  borderTop: "1px solid $colors$neutrals-lightest",
});