import type { Hotel } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col, Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgChevronRight } from "~/components/Icon/icons";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { StarRating } from "~/core/shared/components/StarsRating";
import { useCarousel } from "~/hooks/useCarousel";
import * as MaskUtils from "~/utils/mask.utils";
import { formatHotelAddress } from "../../../utils";
import { Checkbox } from "~/components/Input";
import { useHotelBudgetResult } from "../../HotelDetailsPage/hooks/useHotelBudget/type";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";
import { useUser } from "~/presentation/core/contexts/UserContext";
import useMobile from "~/presentation/shared/hooks/useMobile";

interface OnMapViewData {
  description?: string;
  data?: Hotel;
  onSelect: (data: Hotel) => void;
}

export interface HotelListItemProps {
  data: Hotel;
  description?: string;
  onSelect: (data: Hotel) => void;
  hotelBudget: useHotelBudgetResult;
  ishotelSelectedHover: boolean;
}

export function HotelListItem({
  data,
  hotelBudget,
  description,
  onSelect,
  ishotelSelectedHover,
}: HotelListItemProps) {
  const { currentItem, toNextItem, toPreviousItem } = useCarousel({
    size: data?.hotelImages.length || 0,
  });

  const { contexts } = useUser();
  const HOTEL_OFF_TAG = "OFF";

  const { activeBudget, onSelectHotelBudget, state } = hotelBudget;

  const isCheckedBudget = state.some((hotel) => hotel?.uuid === data?.uuid);

  const isMobile = useMobile();

  return (
    <Card
      css={{
        overflow: "hidden",
        borderRadius: "8px",
        border: ishotelSelectedHover ? "2px solid $success-base" : "",
        width: "100%",
        maxWidth: "100%",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        "@media (min-width: 600px)": {
          flexDirection: "row",
        },
      }}
    >
      <Box
        css={{
          width: "100%",
          height: "180px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${data?.hotelImages[currentItem]}), url(/images/broken-image.png)`,
          "@media (min-width: 600px)": {
            width: "35%",
          },
        }}
      >
        <Flex justify="between" align="center" css={{ height: "100%", p: "$2" }}>
          <Button
            variant="tertiary"
            rounded
            onClick={toPreviousItem}
            disabled={currentItem === 0}
            css={{ p: "$3" }}
          >
            <Icon as={SvgChevronLeft} />
          </Button>
          <Button
            variant="tertiary"
            rounded
            onClick={toNextItem}
            disabled={currentItem === (data?.hotelImages.length || 0) - 1}
            css={{ p: "$3" }}
          >
            <Icon as={SvgChevronRight} />
          </Button>
        </Flex>
      </Box>

      <Flex
        direction="column"
        justify="between"
        css={{
          width: "100%",
          padding: "$3",
          "@media (min-width: 600px)": {
            width: "65%",
            p: "$4",
          },
        }}
        gap="2"
      >
        <Flex justify="between" align="center" css={{ mb: "$2" }}>
          <H4 css={{ fontSize: "$md" }}>{data?.name}</H4>
          <Flex gap="2">
            <StarRating stars={data?.stars || 0} />
            {activeBudget && (
              <Checkbox checked={isCheckedBudget} onChange={() => onSelectHotelBudget({ data })} />
            )}
          </Flex>
        </Flex>

        <Text variant="dark" size="3" css={{ fw: "500", mb: "$2" }}>
          {formatHotelAddress(data)}.
        </Text>

        <Flex css={{ py: "$5" }}>
          {data?.provider.name === HOTEL_OFF_TAG && (
            <Tag variant="info-light">
              Este hotel não oferece a confirmação de forma online. Nossa equipe fará o processo
              manualmente
            </Tag>
          )}
        </Flex>

        <Flex justify="between" align="center">
          <Flex align="center" gap="2">
            {contexts?.agency?.uuid && <Tag variant="neutral-light">{data?.provider?.name}</Tag>}
            <Text size="3" css={{ fontWeight: "bold" }}>
              {MaskUtils.asCurrency(data?.bestValueTotal || 0)}
            </Text>
          </Flex>

          <Flex gap="2">
            {!!data?.violatedPolicies?.length && !isMobile && (
              <ViolatedPoliciesButton data={data.violatedPolicies} />
            )}
            <Button
              size="sm"
              variant="primary"
              onClick={() => onSelect(data)}
              disabled={activeBudget}
            >
              Ver opções
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
