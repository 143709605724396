import { CSS } from "@stitches/react";
import { Fragment, useCallback, useState } from "react";
import {
  AccountabilityExpense,
  AdvanceOrderStatus,
  ApprovalModel,
  CostCenter,
  Order,
  TravelerAdvance,
} from "~/application/types";
import { Button } from "~/components/Button";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgChevronDown,
  SvgChevronUp,
  SvgMinus,
  SvgOrder,
  SvgPlus,
} from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Skeleton } from "~/components/Skeleton";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import * as MaskUtils from "~/utils/mask.utils";
import { advanceOrderStatus as advanceOrderStatusConst } from "../../utils";
import { AccountabilityExpenseListItem } from "./components/AccountabilityExpenseListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { dialogService } from "~/components/DialogStack";
import { ConfirmRequestApprovalExpenseDialog } from "../OrderItem/components/ConfirmRequestApprovalExpenseDialog";

export interface TabTravelerExpenseProps {
  order: Order;
  data?: AccountabilityExpense[];
  isLoading: boolean;
  onEditAccountabilityExpense: (data: AccountabilityExpense) => void;
  onDeleteAccountabilityExpense: (data: AccountabilityExpense) => void;
  fetchApprovalModels: () => Promise<ApprovalModel[]>;
  onShowVoucher: (data: AccountabilityExpense) => void;
  onRequestApproval?:
    | ((data: TravelerAdvance & CostCenter & { costCenterId: string }) => void)
    | undefined;
  isSuccessfulRequestApproval?: boolean;
  isLoadingRequestApproval?: boolean;
}

export function TabTravelerExpense({
  order,
  data,
  isLoading,
  onDeleteAccountabilityExpense,
  onEditAccountabilityExpense,
  onRequestApproval,
  fetchApprovalModels,
  isSuccessfulRequestApproval,
  isLoadingRequestApproval,
  onShowVoucher,
}: TabTravelerExpenseProps) {
  const listRenderer = useCallback(
    (item: AccountabilityExpense) => (
      <AccountabilityExpenseListItem
        data={item}
        onShowVoucher={onShowVoucher}
        onEditClick={onEditAccountabilityExpense}
        onDeleteClick={onDeleteAccountabilityExpense}
        key={item.uuid}
      />
    ),
    []
  );

  const advanceOrder = data?.at(0)?.advanceOrder;
  const advanceOrderStatus = advanceOrder?.status;
  const totalAdvances = advanceOrder?.value || 0;
  const isMobile = useMobile();
  const [cartIsOpen, setCartIsOpen] = useState(false);

  const getTotalExpenses = () => {
    return (data || []).reduce((total, { value }) => total + value, 0);
  };

  const totalExpenses = getTotalExpenses();

  const isAbleToRequestApproval = () => {
    if (isLoading || isSuccessfulRequestApproval || isLoadingRequestApproval) {
      return false;
    }

    if (!data || data.length === 0) {
      return false;
    }

    const advanceOrderStatus = advanceOrder?.status;

    if (!advanceOrderStatus) {
      return false;
    }

    const statuses = [AdvanceOrderStatus.OPEN, AdvanceOrderStatus.DISAPPROVED];
    return statuses.includes(advanceOrderStatus);
  };

  const canRequestApproval = isAbleToRequestApproval();

  const getAdvanceOrderStatusCss = (): CSS => {
    const incompleteBorderCss = "1px solid ";
    const css: CSS = {
      border: incompleteBorderCss.concat("black"),
    };

    const advanceOrderStatusCss = {
      [AdvanceOrderStatus.APPROVED]: () => {
        css.border = incompleteBorderCss.concat("green");
      },
      [AdvanceOrderStatus.DISAPPROVED]: () => {
        css.border = incompleteBorderCss.concat("red");
      },
    };

    if (advanceOrderStatus && advanceOrderStatus in advanceOrderStatusCss) {
      const status = advanceOrderStatus as keyof typeof advanceOrderStatusCss;
      advanceOrderStatusCss[status]();
    }

    return css;
  };

  const advanceOrderStatusCss = getAdvanceOrderStatusCss();

  const getAdvanceOrderStatusVariant = () => {
    type Variant = "error-light" | "success-light" | "neutral-light";
    let variant: Variant = "neutral-light";

    const advanceOrderStatusVariant = {
      [AdvanceOrderStatus.DISAPPROVED]: () => {
        variant = "error-light";
      },
      [AdvanceOrderStatus.APPROVED]: () => {
        variant = "success-light";
      },
    };

    if (advanceOrderStatus && advanceOrderStatus in advanceOrderStatusVariant) {
      advanceOrderStatusVariant[
        advanceOrderStatus as keyof typeof advanceOrderStatusVariant
      ]();
    }

    return variant;
  };

  const advanceOrderStatusVariant = getAdvanceOrderStatusVariant();

  const getAdvanceOrderTextCss = (): CSS => {
    const css = {
      color: "$neutrals-black",
    };

    const advanceOrderTextCss = {
      [AdvanceOrderStatus.DISAPPROVED]: () => {
        css.color = "red";
      },
      [AdvanceOrderStatus.APPROVED]: () => {
        css.color = "$success-base";
      },
    };

    if (advanceOrderStatus && advanceOrderStatus in advanceOrderTextCss) {
      const status = advanceOrderStatus as keyof typeof advanceOrderTextCss;
      advanceOrderTextCss[status]();
    }

    return css;
  };

  const advanceOrderTextCss = getAdvanceOrderTextCss();

  const advanceOrderStatusToUpper = (): string => {
    const status = advanceOrderStatus as AdvanceOrderStatus;
    return (advanceOrderStatusConst[status] || "Em aberto").toUpperCase();
  };

  const advanceOrderStatusUppercase = advanceOrderStatusToUpper();
  const showApprovalExpenseDialog = useCallback(() => {
    dialogService.showDialog(
      <ConfirmRequestApprovalExpenseDialog
        data={order}
        onSubmit={onRequestApproval}
        fetchApprovalModels={fetchApprovalModels}
        advanceOrder={advanceOrder as TravelerAdvance}
      />
    );
  }, [advanceOrder, onRequestApproval]);

  if (isLoadingRequestApproval) {
    return (
      <LoadingModal
        isOpen={isLoadingRequestApproval}
        message="Solicitando aprovação"
      />
    );
  }

  return (
    <Fragment>
      <Row>
        <Col
          sz="8"
          css={{
            "@mxlg": {
              width: "100%",
              p: "0",
            },
          }}
        >
          <LazyList
            gap="6"
            items={data}
            render={listRenderer}
            skeletonQuantity={4}
            skeletonHeight={370}
            EmptyComponent={
              <EmptyState>
                <Text>
                  Você ainda não possui despesas criadas para este pedido
                </Text>
              </EmptyState>
            }
          />
        </Col>

        <Col sz="4" css={{ background: "red", width: "0", p: "0" }}>
          <Cart
            css={{
              position: "fixed",
              left: "66%",
              width: "30%",
              "@mxlg": {
                position: "fixed",
                bottom: "0",
                height: cartIsOpen ? "330px" : "50px",
                background: "#003161",
                borderRadius: "0",
                left: "0",
                width: "100%",
              },
            }}
          >
            <CartHeader>
              {!isMobile ? (
                <>
                  <Icon as={SvgOrder} size="sm" />
                  <Text>Resumo do pedido</Text>
                </>
              ) : (
                <Flex
                  justify="center"
                  css={{ width: "100%" }}
                  onClick={() => setCartIsOpen((old) => !old)}
                >
                  <Icon as={cartIsOpen ? SvgChevronDown : SvgChevronUp} />
                </Flex>
              )}
            </CartHeader>

            <CardBody>
              <Flex direction="column" gap="6">
                {isLoading ? (
                  <>
                    <Skeleton variant="text-4" />
                    <Skeleton variant="text-4" />
                    <Skeleton variant="text-4" />
                  </>
                ) : (
                  <>
                    <Flex gap="4" align="center">
                      <Icon variant="dark" as={SvgPlus} size="sm" />
                      <Col>
                        <Text
                          variant={{ "@initial": "darkest", "@mxlg": "white" }}
                          css={{ fw: 700 }}
                          size="2"
                        >
                          Adiantamentos
                        </Text>
                      </Col>
                      <Text
                        variant={{ "@initial": "darkest", "@mxlg": "white" }}
                        size="2"
                      >
                        {MaskUtils.asCurrency(totalAdvances ?? 0)}
                      </Text>
                    </Flex>

                    <Flex gap="4" align="center">
                      <Icon variant="dark" as={SvgMinus} size="sm" />
                      <Col>
                        <Text
                          variant={{ "@initial": "darkest", "@mxlg": "white" }}
                          css={{ fw: 700 }}
                          size="2"
                        >
                          Despesas
                        </Text>
                      </Col>
                      <Text
                        variant={{ "@initial": "darkest", "@mxlg": "white" }}
                        size="2"
                      >
                        {MaskUtils.asCurrency(totalExpenses)}
                      </Text>
                    </Flex>

                    <Flex gap="4" align="center">
                      <Icon variant="dark" as={SvgOrder} size="sm" />
                      <Col>
                        <Text
                          variant={{ "@initial": "darkest", "@mxlg": "white" }}
                          css={{ fw: 700 }}
                          size="2"
                        >
                          Valor reembolsável
                        </Text>
                      </Col>
                      <Text
                        variant={{ "@initial": "darkest", "@mxlg": "white" }}
                        size="2"
                      >
                        {MaskUtils.asCurrency(totalExpenses - totalAdvances)}
                      </Text>
                    </Flex>
                  </>
                )}

                <Flex gap="4" align="center">
                  <Icon variant="dark" as={SvgOrder} size="sm" />
                  <Col>
                    <Text
                      variant={{ "@initial": "darkest", "@mxlg": "white" }}
                      css={{ fw: 700 }}
                      size="2"
                    >
                      Status da despesa
                    </Text>
                  </Col>

                  <Tag
                    css={advanceOrderStatusCss}
                    variant={advanceOrderStatusVariant}
                  >
                    <Text
                      css={advanceOrderTextCss}
                      variant={{ "@initial": "darkest", "@mxlg": "white" }}
                      size="2"
                    >
                      {advanceOrderStatusUppercase}
                    </Text>
                  </Tag>
                </Flex>
              </Flex>
            </CardBody>

            {canRequestApproval && (
              <CardBody css={{ pt: "$0" }}>
                <Button
                  disabled={!canRequestApproval}
                  onClick={() => showApprovalExpenseDialog()}
                >
                  <Text>Solicitar aprovação</Text>
                </Button>
              </CardBody>
            )}
          </Cart>
        </Col>
      </Row>
    </Fragment>
  );
}
