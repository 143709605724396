import { Route, Routes } from "react-router-dom";

import { routes } from "~/application/theme/routes";
import { UserContext } from "~/application/types";
import { CheapestFlightsPage } from "~/core/modules/Order/pages/CheapestFlightsPage";
import { CheapestRoadsPage } from "~/core/modules/Order/pages/CheapestRoadsPage";
import { ManageOrderPage } from "~/core/modules/Order/pages/ManageOrderPage";
import { AdvancesPage } from "../core/modules/Order/pages/AdvancesPage";
import { OrderPage } from "../core/modules/Order/pages/OrderPage";
import { AccountabilityExpensePage } from "../core/modules/Order/pages/OrderPage/views/AccountabilityExpense/components/AccountabilityExpensePage";
import { OrdersPage } from "../core/modules/Order/pages/OrdersPage";
import { PendingOrdersPage } from "../core/modules/Order/pages/PendingOrdersPage";
import { RequireRole } from "./helpers/RequireRole";

export const OrderRouter = () => {
  return (
    <Routes>
      <Route path="*">
        <Route
          path="meus-pedidos"
          element={
            <RequireRole role={UserContext.Customer}>
              <OrdersPage />
            </RequireRole>
          }
        />

        <Route
          path="adiantamentos"
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<AdvancesPage />} />
        </Route>

        <Route
          path="pedidos-pendentes"
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route index element={<PendingOrdersPage />} />
        </Route>

        <Route path="pedidos-pendentes/:orderId">
          <Route index element={<ManageOrderPage />} />
        </Route>

        <Route
          path=":orderId"
          element={
            <RequireRole role={[UserContext.Customer, UserContext.Agency]} />
          }
        >
          <Route index element={<OrderPage />} />
          <Route path={routes.Orders.Order.CheapestFlights.path} element={<CheapestFlightsPage />} />
          <Route path={routes.Orders.Order.CheapestRoads.path} element={<CheapestRoadsPage />} />
          <Route path="viajantes/:travelerId/despesas">
            <Route index element={<AccountabilityExpensePage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

OrderRouter.displayName = "OrderRouter";
