import { OrderMessage } from "~/application/types";

export type ReactQueryHooksOptions = {
  enabled?: boolean;
  retry?: number | boolean;
};

export type OrderMessageGroup = {
  uuid: string;
  sender: {
    uuid: string;
    fullName: string;
    avatar?: string;
    agencyName: string;
    customerName: string;
  };
  senderIsMe: boolean;
  messages: OrderMessage[];
};

export type CustomerEmployeeFilter = { label: string; value: string };
export type ActivenessFilter = { label: string; value: string };
export type ApproversFilter = { label: string; value: boolean | null };

export enum CustomerEmployeeFilterValue {
  Approver = "approver",
  Active = "active",
}
export enum CustomerEmployeeFilterLabel {
  Approver = "Aprovador",
  Active = "Ativo",
}

export enum CustomerEmployeeApproverFilterLabel {
  Approver = "Aprovador",
  Active = "Ativo",
}

export interface ICustomerEmployeeFilters {
  profiles: CustomerEmployeeFilter;
  activeness: ActivenessFilter;
  approvers: ApproversFilter;
}

export const EMPTY_CUSTOMER_EMPLOYEE_FILTERS: ICustomerEmployeeFilters = {
  profiles: {
    label: "",
    value: "",
  },
  activeness: {
    label: "",
    value: "",
  },
  approvers: {
    label: "",
    value: null
  }
};
