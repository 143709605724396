import { ComponentProps, forwardRef } from "react";
import { styled } from "~/application/theme";
import { Icon } from "../Icon/Icon";

export type IconButtonProps = ComponentProps<typeof IconButtonRoot>;

const IconButtonRoot = styled("button", {
  // Reset
  all: "unset",
  boxSizing: "border-box",
  display: "inline-flex",
  userSelect: "none",
  whiteSpace: "nowrap",

  // Custom
  alignItems: "center",
  appearance: "none",
  borderWidth: "0",
  flexShrink: 0,
  fontFamily: "inherit",
  fontSize: "14px",
  justifyContent: "center",
  lineHeight: "1",
  outline: "none",
  padding: "0",
  textDecoration: "none",
  border: 0,
  backgroundColor: "transparent",
  borderRadius: "$md",
  transition: "$normal",

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$neutrals-lightest",
  },

  "&:disabled": {
    pointerEvents: "none",
    backgroundColor: "transparent",
  },

  "&:focus": {
    boxShadow: "$focus-sm",
  },

  variants: {
    size: {
      sm: {
        size: "$6",

        [`& ${Icon}`]: {
          size: "$4",
        },
      },
      md: {
        size: "$8",

        [`& ${Icon}`]: {
          size: "$5",
        },
      },
      lg: {
        size: "$10",

        [`& ${Icon}`]: {
          size: "$6",
        },
      },
      xl: {
        size: "$12",

        [`& ${Icon}`]: {
          size: "$8",
        },
      },
      "2xl": {
        size: "$14",

        [`& ${Icon}`]: {
          size: "$10",
        },
      },
      "4xl": {
        size: "$20",

        [`& ${Icon}`]: {
          size: "$12",
        },
      },
      smlg: {
        size: "$6",
        width: "390px",

        [`& ${Icon}`]: {
          size: "$4",
        },
      },
    },
  },

  defaultVariants: {
    size: "sm",
  },
});

export const IconButton = forwardRef<
  React.ElementRef<typeof IconButtonRoot>,
  IconButtonProps
>((props, forwardedRef) => {
  return <IconButtonRoot type="button" {...props} ref={forwardedRef} />;
});

IconButton.displayName = "IconButton";
