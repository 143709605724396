export enum OrderStatus {
  OPEN = "open",
  APPROVED = "approved",
  WAITING = "waiting",
  CANCELED = "canceled",
  ON_APPROVAL = "on_approval",
  CHANGING = "changing",
  REJECTED = "rejected",
  CANCELING = "canceling",
  QUOTING = "quoting",
  EXPIRED = "expired",
  QUOTED = "quoted",
  EXPIRED_TIME = "expired_time",
  PENDING_ISSUE = "pending_issue",
  ISSUED = "issued",
}
