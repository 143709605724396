import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { CustomerEmployee, Traveler } from "~/application/types";
import { Box } from "~/components/Box";
import { Flex, FlexProps } from "~/components/Flex";
import { FieldLabel } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { H4 } from "~/components/Typography";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { AsyncSelect } from "../AsyncSelect";

export type SelectIssuerModalProps = {
  css?: FlexProps["css"];
  fetchTravelers: (name: string) => Promise<Traveler[]>;
  onChange: (issuer: CustomerEmployee) => void;
};

interface FormData {
  issuer: CustomerEmployee;
}

export function SelectIssuer({ css, onChange, fetchTravelers }: SelectIssuerModalProps) {
  const { contexts } = useUser();
  const { setValue } = useForm<FormData>({
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const customerId = contexts.customer?.uuid;

  const onSelectTraveler = (value: any) => {
    setValue("issuer", value);
    onChange(value);
  };

  const getTravelerLabel = useCallback((item: Traveler) => {
    return item.fullName;
  }, []);

  const getTravelerValue = useCallback((item: Traveler) => item.uuid, []);

  return (
    <Flex direction="column" gap="8" css={css}>
      <Col>
        <H4>Selecione o solicitante</H4>
      </Col>

      <Row gap="6">
        <Col sz="12">
          <FieldLabel>Solicitantes</FieldLabel>

          <Flex direction="column" gap="6">
            <Box>
              <AsyncSelect
                placeholder="Digite o solicitante"
                fetchOptions={fetchTravelers}
                getOptionValue={getTravelerValue}
                getOptionLabel={getTravelerLabel}
                onChange={onSelectTraveler}
              />
            </Box>
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
}
