import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { DeepPartial, OfflineHotelAmenity, OfflineHotelRoom } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { NumberInput, TextInput, Select } from "~/components/Input";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { CreateOfflineHotelRoomSchema, createOfflineHotelRoomSchema } from "../utils/form";
import { Col } from "~/components/Grid";
import { UploadImageInput } from "../../../components/UploadImageInput";
import { CreateOfflineHotelRoomProps } from "~/application/usecases/OfflineHotelRoom";
import { Flex } from "~/components/Flex";
import { MultiSelect } from "~/components/Input/MultiSelect";

export interface CreateOfflineHotelRoomDialogProps {
  defaultData: DeepPartial<OfflineHotelRoom>;
  amenities?: OfflineHotelAmenity[];
  isLoadingAmenities: boolean;
  onCloseClick?: () => void;
  onSubmit: (data: Omit<CreateOfflineHotelRoomProps, "offlineHotelId">) => void;
}

export function CreateOfflineHotelRoomDialog({
  defaultData,
  amenities,
  isLoadingAmenities,
  onCloseClick,
  onSubmit,
}: CreateOfflineHotelRoomDialogProps) {
  const { 
    control, 
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors, isSubmitting }, 
  } = useForm<CreateOfflineHotelRoomSchema>({
    defaultValues: {
      ...defaultData,
      regime: defaultData?.regime ? { name: defaultData?.regime } : undefined,
      type: defaultData?.type ? { name: defaultData?.type } : undefined,
      amenities: [],
    },
    resolver: yupResolver(createOfflineHotelRoomSchema),
  });

  if (isSubmitting) {
    return (
      <LoadingModal
        isOpen={isSubmitting}
        message="Criando quarto"
      />
    );
  }

  const { images } = watch();
  const REGIMES = [{ name: "Com café da manhã" }, { name: "Sem café da manhã" }];
  const ROOM_TYPES = [{ name: "Single" }, { name: "Duplo" }, { name: "Triple" }, { name: "Quadruplo" }];

  const removeImage = (indexToRemove: number) => {
    const dataTransfer = new DataTransfer();

    Array.from(images as FileList).forEach((file, index) => {
      if (index !== indexToRemove) {
        dataTransfer.items.add(file);
      }
    });

    setValue("images", dataTransfer.files);
  };

  const createOfflineHotelRoom = (data: CreateOfflineHotelRoomSchema) => {
    const imagesToUpload = images as FileList;
    const amenities = data.amenities?.map((amenity) => amenity.name);
   
    onSubmit({
      data: {
        ...data,
        regime: data.regime?.name,
        type: data.type?.name,
        amenities,
      },
      images: imagesToUpload,
    } as Omit<CreateOfflineHotelRoomProps, "offlineHotelId">);
  }

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(createOfflineHotelRoom)}>
        <FormDialog
          title="Novo quarto"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={isSubmitting} type="submit">
              <Text>Adicionar</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Flex direction="column" gap="6">
              <H4>Informações básicas</H4>

              <Flex direction="column" gap="6">
                <FormControl name="description" control={control} required>
                  <FieldLabel>Descrição</FieldLabel>
                  <TextInput placeholder="Digite uma descrição para o quarto" />
                  {errors.description && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.description.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl name="type" control={control} required>
                  <FieldLabel>Tipo</FieldLabel>
                  <Select
                    placeholder="Selecione o tipo de quarto"
                    options={ROOM_TYPES}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                  />
                  {errors.type?.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.type.name.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl name="regime" control={control} required>
                  <FieldLabel>Regime</FieldLabel>
                  <Select
                    placeholder="Selecione o regime do quarto"
                    options={REGIMES}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                  />
                  {errors.regime?.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.regime.name.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl name="value" control={control} required>
                  <FieldLabel>Valor</FieldLabel>
                  <NumberInput
                    prefix="R$ "
                    placeholder="Digite o valor para este quarto"
                  />
                  {errors.value && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.value.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl name="adultsAmount" control={control} required>
                  <FieldLabel>Número de adultos</FieldLabel>
                  <NumberInput 
                    placeholder="Digite o número de adultos permitidos"
                    allowNegative={false} 
                    decimalScale={0} 
                    min={1}
                  />
                  {errors.adultsAmount && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.adultsAmount.message}
                    </Text>
                  )}
                </FormControl>

                <FormControl control={control} name="amenities" required>
                  <FieldLabel>Comodidades da hospedagem</FieldLabel>
                  <MultiSelect
                    placeholder="Selecione uma ou mais opções"
                    options={amenities}
                    isLoading={isLoadingAmenities}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.uuid}
                  />
                  {errors.amenities && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.amenities.message}
                    </Text>
                  )}
                </FormControl>
              </Flex>
            </Flex>

            <Flex direction="column" gap="6" css={{ mt: "$10" }}>
              <H4>Fotos</H4>

              <Col>
                <UploadImageInput 
                  {...register("images")}
                  label="Importe fotos do quarto"
                  files={images as FileList}
                  removeFile={removeImage}
                  accept="image/*"
                  multiple
                />
                {errors.images && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {errors.images.message}
                  </Text>
                )}
              </Col>
            </Flex>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
