import { useContext } from "react";

import { Button } from "~/components/Button";
import { Text } from "~/components/Text";
import { canShowApprovalButtons } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/utils";
import { useOrder } from "../../hooks/useOrder";
import { CartApprovalButtonsProps } from "./types";
import { ApprovalOrderContext } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/ApprovalOrderContext";
import { OrderStatus } from "~/application/types";
import { Spinner } from "~/components/Spinner";
import { Flex } from "~/components/Flex";

export function CartApprovalButtons({
  isIssuingOrder,
  isRebookingRoad,
  cannotApproveItems,
  cannotReproveItems,
  getApproveButtonText,
  onSubmit,
}: CartApprovalButtonsProps) {
  const { order } = useOrder();
  const context = useContext(ApprovalOrderContext);
  const items = context.items || { approved: [], rejected: [] };
  const hasMessage = items.rejected.some((item) => item.reasonRejected !== "");
  const orderStatus = order?.status as OrderStatus;

  isIssuingOrder = isIssuingOrder && orderStatus === OrderStatus.APPROVED;

  if (!canShowApprovalButtons(order) && !isIssuingOrder) {
    return null;
  }

  return (
    <Flex direction="column" gap="3">
      <Button disabled={cannotApproveItems || isRebookingRoad} onClick={onSubmit}>
        {isIssuingOrder && (
          <Spinner
            css={{
              borderLeftColor: "white",
              borderBottomColor: "white",
              scale: 0.5,
            }}
          />
        )}

        <Text>{getApproveButtonText()}</Text>
      </Button>

      <Button
        variant="error"
        onClick={onSubmit}
        disabled={cannotReproveItems || isRebookingRoad || !hasMessage}
      >
        <Text>Solicitar revisão dos itens do pedido</Text>
      </Button>
    </Flex>
  );
}
