import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { DeepPartial } from "react-hook-form";
import {
  AccountabilityExpense,
  ApprovalModel,
  CostCenter,
  Order,
  TravelerAdvance,
  User,
} from "~/application/types";
import {
  accountabilityExpenseService,
  approvalModelService,
  orderAdvanceService,
} from "~/application/usecases";
import { IFindAccountabilityExpenseResult } from "~/application/usecases/AccountabilityExpense/IAccountabilityExpenseService";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { SimpleDialog } from "~/core/shared/components/SimpleDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { log } from "~/utils/log";
import { useAccountabilityExpenses } from "../../../hooks/useAccountabilityExpenses";
import { AccountabilityExpenseDialog } from "../components/AccountabilityExpenseDialog";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { logError } from "~/presentation/shared/utils/errors";
import { useParams } from "react-router-dom";

export interface UseTabAccountabilityExpensesParams {
  order?: Order;
}

export interface UseTabAccountabilityExpenseResult {
  user: User;
  isLoadingExpenses: boolean;
  isLoadingRequestApproval: boolean;
  expenses?: AccountabilityExpense[];
  cartSummary?: IFindAccountabilityExpenseResult["summary"];
  isSuccessfulRequestApproval: boolean;
  totalExpenses: number;
  totalAdvances: number;
  handleRequestApproval: (item: TravelerAdvance & CostCenter & { costCenterId: string }) => void;
  onShowVoucher: (data: AccountabilityExpense) => void;
  onCreateAccountabilityExpense: (viatorId: string) => void;
  onEditAccountabilityExpense: (data: AccountabilityExpense) => void;
  onDeleteAccountabilityExpense: (data: AccountabilityExpense) => void;
  fetchApprovalModels: () => Promise<ApprovalModel[]>;
}

export const LOG_TAG = "Order/OrderPage/useTabAccountabilityExpenses";

export const SNACKBAR_MESSAGES = {
  CREATE_SUCCESS_MESSAGE: "Despesa criada",
  CREATE_ERROR_MESSAGE: "Falha ao criar despesa",
  UPDATE_SUCCESS_MESSAGE: "Despesa atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar despesa",
  DELETE_SUCCESS_MESSAGE: "Despesa deletada",
  DELETE_ERROR_MESSAGE: "Falha ao deletar despesa",
  EXPENSE_APPROVAL_SUCCESS_MESSAGE: "Despesas aprovadas",
  EXPENSE_APPROVAL_ERROR_MESSAGE: "Falha ao aprovar despesas",
  EXPENSE_REPROVAL_SUCCESS_MESSAGE: "Solicitado revisão de despesas",
  EXPENSE_REPROVAL_ERROR_MESSAGE: "Falha ao solicitar revisão de despesas",
  REQUEST_APPROVAL_SUCCESS_MESSAGE: "Solicitação de aprovação concluída com sucesso",
  REQUEST_APPROVAL_ERROR_MESSAGE: "Falha ao solicitar aprovação",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<AccountabilityExpense> = {
  company: "",
  expenseDate: "",
  justification: "",
};

export function useTabAccountabilityExpenses({
  order,
}: UseTabAccountabilityExpensesParams): UseTabAccountabilityExpenseResult {
  const { user, contexts } = useUser();
  const orderId = order?.uuid as string;
  const { travelerId } = useParams() as { travelerId: string };

  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const fetchApprovalModels = useCallback(
    () => approvalModelService.find({ customerId }).then(({ data }) => data),
    []
  );

  const {
    data: result,
    isLoading: isLoadingExpenses,
    refetch: refetchExpenses,
  } = useQuery(
    [QueryKeys.ORDER_ACCOUNTABILITY_EXPENSES, order],
    () =>
      accountabilityExpenseService.find({
        orderId,
        travelerId: "",
      }),
    {
      staleTime: QueryTimes.NORMAL,
      cacheTime: user.isExpenseApprover ? QueryTimes.NONE : QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!orderId,
    }
  );

  const { mutate: handleCreateAccountabilityExpense } = useMutation(
    (item: AccountabilityExpense & { travelerId?: string }) => {
      return accountabilityExpenseService.create({
        ...item,
        travelerId: travelerId,
        orderId,
      });
    },
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando despesa" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created AccountabilityExpense(${item.uuid})`);

        refetchExpenses();

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: handleUpdateAccountabilityExpense } = useMutation(
    (item: AccountabilityExpense) => accountabilityExpenseService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating AccountabilityExpense(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated AccountabilityExpense(${item.uuid})`);

        refetchExpenses();

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: handleDeleteAccountabilityExpense } = useMutation(
    (item: AccountabilityExpense) => accountabilityExpenseService.deleteById(item),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Deletando despesa" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully deleted AccountabilityExpense(${item.uuid})`);

        refetchExpenses();

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.DELETE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.DELETE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const {
    mutateAsync: handleRequestApproval,
    isLoading: isLoadingRequestApproval,
    isSuccess: isSuccessfulRequestApproval,
  } = useMutation(
    async (item: TravelerAdvance & CostCenter & { costCenterId: string }) => {
      await orderAdvanceService.requestApproval(item);
    },
    {
      onMutate: (item: TravelerAdvance) => {
        log.i(LOG_TAG, `Request Approval of AccountabilityExpense(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        refetchExpenses();

        log.i(LOG_TAG, `Successfully request approval of AccountabilityExpense(${item.uuid})`);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.REQUEST_APPROVAL_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.REQUEST_APPROVAL_ERROR_MESSAGE, "error");
      },
    }
  );

  const cartSummary = result?.summary;

  const getTotalAdvances = () => {
    if (cartSummary?.advances) {
      return cartSummary.advances;
    }

    return (result?.data || []).reduce(
      (total, { advanceOrder }) => total + (advanceOrder?.value || 0),
      0
    );
  };

  const totalAdvances = getTotalAdvances();

  const getTotalExpenses = () => {
    if (cartSummary?.expenses) {
      return cartSummary.expenses;
    }

    const expenses = result?.data || [];

    return expenses.reduce((total, { value }) => total + value, 0);
  };

  const totalExpenses = getTotalExpenses();

  const { fetchExpensePolicies, fetchExpenseCategories, onShowVoucher } = useAccountabilityExpenses(
    {
      order,
      customerEmployeeId: travelerId,
      enabled: false,
    }
  );

  const handleOpenCreateModal = useCallback(
    (viatorId?: string) => {
      dialogService.showDialog(
        <AccountabilityExpenseDialog
          isNew
          customerEmployeeId={viatorId ?? travelerId}
          customerId={customerId}
          orderId={orderId}
          onSubmit={handleCreateAccountabilityExpense}
          fetchExpenseCategories={fetchExpenseCategories}
          fetchExpensePolicies={fetchExpensePolicies}
          defaultData={DEFAULT_FORM_DATA}
        />
      );
    },
    [
      travelerId,
      customerId,
      orderId,
      handleCreateAccountabilityExpense,
      fetchExpenseCategories,
      fetchExpensePolicies,
    ]
  );

  const handleOpenEditModal = useCallback(
    (item: AccountabilityExpense) => {
      dialogService.showDialog(
        <AccountabilityExpenseDialog
          customerEmployeeId={travelerId}
          customerId={customerId}
          orderId={orderId}
          onSubmit={handleUpdateAccountabilityExpense}
          fetchExpenseCategories={fetchExpenseCategories}
          fetchExpensePolicies={fetchExpensePolicies}
          defaultData={item}
        />
      );
    },
    [fetchExpenseCategories]
  );

  const handleOpenDeleteModal = useCallback(
    (item: AccountabilityExpense) => {
      dialogService.showDialog(
        <SimpleDialog
          title="Deseja apagar despesa?"
          loadingMessage="Apagando despesa"
          cancelTitle="Cancelar"
          confirmTitle="Apagar"
          onConfirm={() => handleDeleteAccountabilityExpense(item)}
        />
      );
    },
    [handleDeleteAccountabilityExpense]
  );

  return {
    user,
    isLoadingExpenses,
    isLoadingRequestApproval,
    expenses: result?.data,
    cartSummary: result?.summary,
    isSuccessfulRequestApproval,
    totalAdvances,
    totalExpenses,
    onShowVoucher,
    fetchApprovalModels,
    onCreateAccountabilityExpense: handleOpenCreateModal,
    onEditAccountabilityExpense: handleOpenEditModal,
    onDeleteAccountabilityExpense: handleOpenDeleteModal,
    handleRequestApproval,
  };
}
