import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { BudgetOption, SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { usePhases } from "~/core/modules/Customer/pages/CustomerPage/hooks/usePhases";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import { Budget, DeepPartial } from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Checkbox, NumberInput, Select } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { Caption, H5 } from "~/components/Typography";
import { getYearsRange } from "~/utils/date.utils";
import { 
  generateBudgetPeriods, 
  getLabelsByPeriodicity, 
  getPeriodicity, 
  getYear, 
  Periodicity, 
  periodicityOptions 
} from "../utils";
import { Flex } from "~/components/Flex";
import { useCostCenters } from "../../../hooks/useCostCenters";
import { useProjects } from "../../../hooks/useProjects";

const budgetSchema = yup.object().shape({
  uuid: yup.string().nullable(),
  costCenter: yup.object().shape({
    uuid: yup.string(),
    label: yup.string(),
  }).nullable(),
  project: yup.object().shape({
    uuid: yup.string(),
    label: yup.string(),
  }).nullable(),
  phase: yup.object().shape({
    uuid: yup.string(),
    label: yup.string(),
  }).nullable(),
  periodicity: yup.object().shape({
    value: yup.string().required("A periodicidade do orçamento é obrigatória"),
    label: yup.string(),
  }),
  year: yup.object().shape({
    value: yup.number(),
    label: yup.string(),
  }),
  blocking: yup.boolean().required().default(false),
  items: yup.array().of(
    yup.object().shape({
      uuid: yup.string().nullable(),
      value: yup.number().typeError("Digite um valor válido").required("O valor é obrigatório"),
    })
  ),
});

type BudgetSchema = yup.InferType<typeof budgetSchema>;

export interface BudgetDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<Budget>;
  onCloseClick?: () => void;
  onSubmit: (data: Budget) => void;
}

export function BudgetDialog({
  isNew,
  defaultData,
  onCloseClick,
  onSubmit: onSubmitProp,
}: BudgetDialogProps) {
  const [currentInputIndex, setCurrentInputIndex] = useState<number | undefined>();
  const { contexts } = useUser();

  const { 
    control, 
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    setValue,
    getValues,
   } = useForm<BudgetSchema>({
    defaultValues: {
      ...defaultData,
      year: getYear(defaultData?.items && defaultData.items[0]?.startAt as Date),
      periodicity: getPeriodicity(defaultData?.periodicity as Periodicity),
    },
    resolver: yupResolver(budgetSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const isPhaseParameter = useVerifyParameter({
    customer: contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.PHASE,
  });

  const isCostCenterParameter = useVerifyParameter({
    customer: contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.COST_CENTER,
  });

  const isProjectParameter = useVerifyParameter({
    customer: contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.PROJECT,
  });

  const { periodicity: selectedPeriodicity, project, items } = watch();
  const budgetItemsValues = fields.map((_, index) => watch(`items.${index}.value`));

  const labelsByPeriodicity = getLabelsByPeriodicity(selectedPeriodicity?.value as Periodicity);

  const budgetTotal = items ? items.reduce((acc, item) => acc + (item.value || 0), 0) : 0;

  useEffect(() => {
    remove();
    labelsByPeriodicity.forEach((_, index) => {
      append({} as { value: number });

      if (isNew && items && !!items.length) {
        setValue(`items.${index}.value`, 0);
      }
    });
  }, [selectedPeriodicity]);

  useEffect(() => {
    const isToAutoFillInputs = isNew && items && items.length > 1 && currentInputIndex !== undefined && currentInputIndex < items.length - 1;

    if (isToAutoFillInputs) {
      items.forEach((_, index) => {
        if (index > currentInputIndex) {
          const currentValue = items[currentInputIndex].value;
          const existingValue = getValues(`items.${index}.value`);

          if (existingValue !== currentValue) {
            setValue(`items.${index}.value`, currentValue);
          }
        }
      });
    }
  }, [budgetItemsValues, items, setValue, isNew, currentInputIndex]);

  const { data: costCenters, isLoading: isLoadingCostCenters, setSearchText: fetchCostCenters } = useCostCenters({ 
    customerId: contexts.customer.uuid, 
    enabled: isCostCenterParameter, 
  });

  const { data: projects, isLoading: isLoadingProjects, onSearch: fetchProjects } = useProjects({ 
    customerId: contexts.customer.uuid,
    enabled: isProjectParameter || isPhaseParameter, 
  });

  const { data: phases, onSearch: fetchPhases, isLoading: isLoadingPhases } = usePhases({
    projectId: project?.uuid as string,
    enabled: !!project && isPhaseParameter,
  });

  const handleInputChange = (index: number, value: string) => {
    setCurrentInputIndex(index);
    setValue(`items.${index}.value`, Number(value));
  };

  const onSubmit = (data: BudgetSchema) => {
    if (isCostCenterParameter && !data.costCenter?.uuid) {
      setError("costCenter.uuid", { message: "O centro de custo é obrigatório"});
      return;
    }

    if (isProjectParameter && !data.project?.uuid) {
      setError("project.uuid", { message: "O projeto é obrigatório"});
      return;
    }

    if (isPhaseParameter) {
      if (!data.project?.uuid) {
        setError("project.uuid", { message: "O projeto é obrigatório"});
        return;
      }

      if (!data.phase?.uuid) {
        setError("phase.uuid", { message: "A fase é obrigatória"});
        return;
      }
    }

    clearErrors();

    const budgetItemsDates = generateBudgetPeriods(data.year.value!, selectedPeriodicity.value as Periodicity);

    onSubmitProp({
      ...data,
      costCenter: isCostCenterParameter ? data.costCenter : null,
      project: isProjectParameter ? data.project : null,
      phase: isPhaseParameter ? data.phase : null,
      periodicity: data.periodicity.value,
      items: data.items?.map((item, i) => {
        const { startAt, endAt } = budgetItemsDates[i];
        return {
          ...item,
          startAt,
          endAt,
        };
      }),
    } as Budget);
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit)} >
        <FormDialog
          title={isNew ? "Novo orçamento" : "Editar orçamento"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={isSubmitting} type="submit">
              {isSubmitting && (
                <Spinner 
                  css={{ 
                    borderLeftColor: "$neutrals-white", 
                    width: "$4", 
                    height: "$4", 
                    borderWidth: "2px" 
                  }} 
                />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Box css={{ mb: "$6" }}>
              <H5>Informações gerais</H5>
            </Box>

            <Row gap="6">
              {isCostCenterParameter && (
                <Col sz="12">
                  <FormControl name="costCenter" control={control} required>
                    <FieldLabel>Centro de custo</FieldLabel>
                    <Select
                      placeholder="Selecione o centro de custo"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.uuid}
                      options={costCenters}
                      isLoading={isLoadingCostCenters}
                      onInputChange={fetchCostCenters}
                    />
                    {errors.costCenter?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {errors.costCenter.uuid.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}

              {isProjectParameter && (
                <Col sz="12">
                  <FormControl name="project" control={control} required>
                    <FieldLabel>Projeto</FieldLabel>
                    <Select
                      placeholder="Selecione o projeto"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.uuid}
                      options={projects}
                      isLoading={isLoadingProjects}
                      onInputChange={fetchProjects}
                    />
                    {errors.project?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {errors.project.uuid.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}

              {isPhaseParameter && (
                <>
                  <Col sz="12">
                    <FormControl name="project" control={control} required>
                      <FieldLabel>Projeto</FieldLabel>
                      <Select
                        placeholder="Selecione o projeto"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.uuid}
                        options={projects}
                        isLoading={isLoadingProjects}
                        onInputChange={(name) => {
                          fetchProjects(name);
                          setValue("phase", null);
                        }}
                      />
                      {errors.project?.uuid && (
                        <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                          {errors.project.uuid.message}
                        </Text>
                      )}
                    </FormControl>
                  </Col>

                  <Col sz="12">
                    <FormControl name="phase" control={control} required>
                      <FieldLabel>Fase</FieldLabel>
                      <Select
                        placeholder="Selecione a fase"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.uuid}
                        options={phases}
                        isLoading={!!project && isLoadingPhases}
                        onInputChange={fetchPhases}
                      />
                      {errors.phase?.uuid && (
                        <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                          {errors.phase.uuid.message}
                        </Text>
                      )}
                    </FormControl>
                  </Col>
                </>
              )}

              <Col sz={{ "@initial": "12", "@lg": "9" }}>
                <FormControl name="periodicity" control={control} required>
                  <FieldLabel>Periodicidade</FieldLabel>
                  <Select 
                    options={periodicityOptions} 
                    defaultValue={2024}
                    placeholder="Qual a periodicidade desse orçamento?"
                  />
                  {errors.periodicity && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.periodicity.value?.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "12", "@lg": "3" }}>
                <FormControl name="year" control={control} required>
                  <FieldLabel>Ano</FieldLabel>
                  <Select 
                    options={getYearsRange()} 
                    placeholder="Ano"
                  />
                  {errors.year && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.year.value?.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              {fields.map((field, index) => (
                <Col 
                  key={field.id} 
                  sz={{ 
                    "@initial": selectedPeriodicity.value === "monthly" ? "6" : "12",
                    "@sm": (labelsByPeriodicity.length > 1 && selectedPeriodicity.value === "monthly") 
                      ? "4" 
                      : (labelsByPeriodicity.length > 1 && selectedPeriodicity.value !== "monthly") ? "6" : "12",
                  }}
                >
                  <FormControl name={`items.${index}.value`} control={control} required>
                    <FieldLabel>{labelsByPeriodicity[index]}</FieldLabel>
                    <NumberInput 
                      prefix="R$ " 
                      placeholder="R$ 0,00" 
                      allowNegative={false} 
                      onChange={(event) => handleInputChange(index, event.target.value)} 
                    />
                    {errors.items?.[index]?.value && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {errors.items?.[index]?.value?.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              ))}

              {(items && !!items.length) && (
                <Col sz="12">
                  <Flex direction="column">
                    <FieldLabel>Valor total do orçamento</FieldLabel>
                    <NumberInput prefix="R$ " allowNegative={false} value={budgetTotal} disabled />
                  </Flex>
                </Col>
              )}

              <Col sz="12">
                <FormControl name="blocking" control={control}>
                  <Checkbox size="sm">
                    <Caption>Bloquear emissões ao extrapolar o valor orçado</Caption>
                  </Checkbox>
                  {errors.blocking && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.blocking.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
