import { useMutation, useQuery } from "@tanstack/react-query";
import { MutableRefObject, useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AccountabilityExpense,
  Order,
  RejectedItem,
  TravelerAdvance,
  User,
} from "~/application/types";
import {
  accountabilityExpenseService,
  expenseApprovalService,
} from "~/application/usecases";
import { IFindExpenseByAdvanceOrderResult } from "~/application/usecases/AccountabilityExpense/IAccountabilityExpenseService";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";

export interface UseTabAccountabilityExpensesParams {
  order?: Order;
}

export interface UseTabApproverAccountabilityExpensesState {
  items: {
    rejected: Partial<RejectedItem>[];
    approved: { uuid: string }[];
  };
}

export interface UseTabApproverAccountabilityExpenseResult {
  user: User;
  isLoadingExpenses: boolean;
  result?: IFindExpenseByAdvanceOrderResult;
  items: UseTabApproverAccountabilityExpensesState["items"];
  totalExpenses: number;
  totalAdvances: number;
  isAllExpensesJudged: boolean;
  isSuccessExpensesJudgment: boolean;
  reasonRejectedRefs: MutableRefObject<HTMLDivElement[]>;
  isLoadingExpensesJudgment: boolean;
  handleExpensesJudgment: () => void;
  approveItem: (data: AccountabilityExpense, index: number) => void;
  reproveItem: (data: AccountabilityExpense, index: number) => void;
}

export const LOG_TAG = "Order/OrderPage/useTabApproverAccountabilityExpenses";

export const SNACKBAR_MESSAGES = {
  EXPENSE_APPROVAL_SUCCESS_MESSAGE: "Despesas aprovadas",
  EXPENSE_APPROVAL_ERROR_MESSAGE: "Falha ao aprovar despesas",
  EXPENSE_REPROVAL_SUCCESS_MESSAGE: "Solicitado revisão de despesas",
  EXPENSE_REPROVAL_ERROR_MESSAGE: "Falha ao solicitar revisão de despesas",
  REQUEST_APPROVAL_SUCCESS_MESSAGE:
    "Solicitação de aprovação concluída com sucesso",
  REQUEST_APPROVAL_ERROR_MESSAGE: "Falha ao solicitar aprovação",
} as const;

export function useTabApproverAccountabilityExpenses({
  order,
}: UseTabAccountabilityExpensesParams): UseTabApproverAccountabilityExpenseResult {
  const { user } = useUser();
  const { advanceOrderId } = useParams() as {
    advanceOrderId: string;
  };

  const { data: result, isLoading: isLoadingExpenses } = useQuery(
    [QueryKeys.ORDER_ACCOUNTABILITY_EXPENSES, order],
    () => accountabilityExpenseService.findByAdvanceOrder(advanceOrderId),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      enabled: !!advanceOrderId,
    }
  );

  const expenses = result?.expenses || [];
  const advanceOrder = result?.advanceOrder || ({} as TravelerAdvance);
  const reasonRejectedRefs = useRef<HTMLDivElement[]>([]);

  const [state, setState] = useState<UseTabApproverAccountabilityExpensesState>(
    {
      items: {
        rejected: [],
        approved: [],
      },
    }
  );

  const { items } = state;
  const amountOfItemsJudged = items.approved.length + items.rejected.length;
  const isAllExpensesJudged = expenses.length === amountOfItemsJudged;

  const reproveItem = useCallback(
    ({ uuid }: AccountabilityExpense, index: number) => {
      setState(({ items }) => {
        const thereIsSomeRejectedItem = items.rejected.some(
          ({ accountabilityExpenseId }) => accountabilityExpenseId === uuid
        );

        if (thereIsSomeRejectedItem) {
          return { items };
        }

        const { current } = reasonRejectedRefs;
        const reasonRejected =
          current?.at(index)?.firstChild?.textContent || "";

        const rejectedItems = [
          ...items.rejected,
          {
            accountabilityExpenseId: uuid,
            reasonRejected,
          },
        ];

        return {
          items: {
            approved: items.approved.filter((item) => item.uuid !== uuid),
            rejected: rejectedItems,
          },
        };
      });
    },
    []
  );

  const approveItem = ({ uuid }: AccountabilityExpense, index: number) => {
    setState(({ items }) => {
      const { current } = reasonRejectedRefs;

      if (items.approved.some((item) => item.uuid === uuid)) {
        return { items };
      }

      if (current.length > 0) {
        reasonRejectedRefs.current = current.splice(index, 1);
      }

      const rejectedItems = items.rejected.filter(
        ({ accountabilityExpenseId }) => accountabilityExpenseId !== uuid
      );

      return {
        items: {
          approved: [...items.approved, { uuid }],
          rejected: rejectedItems,
        },
      };
    });
  };

  const {
    mutate: handleExpensesJudgment,
    isSuccess: isSuccessExpensesJudgment,
    isLoading: isLoadingExpensesJudgment,
  } = useMutation(
    () => {
      const rejectedItems = items.rejected.map((item, index) => {
        const reasonRejected = reasonRejectedRefs.current
          .map((element) => element?.firstChild?.textContent)
          .filter((item) => item && item?.length > 0)[index];

        return { ...item, reasonRejected } as RejectedItem;
      });

      return expenseApprovalService.approve({
        advanceOrderId: advanceOrder?.uuid || "",
        reasonRejected: "",
        rejectedItems,
        obs: "",
      });
    },
    {
      onSuccess: (_, item) => {
        queryClient.invalidateQueries([
          QueryKeys.ORDER_ACCOUNTABILITY_EXPENSES,
          order,
        ]);

        if (items.rejected.length > 0) {
          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.EXPENSE_REPROVAL_SUCCESS_MESSAGE,
            "success"
          );
        } else {
          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.EXPENSE_APPROVAL_SUCCESS_MESSAGE,
            "success"
          );
        }
        dialogService.popDialog();
      },
      onError: (error) => {
        if (items.rejected.length > 0) {
          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.EXPENSE_REPROVAL_ERROR_MESSAGE,
            "error"
          );
        } else {
          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.EXPENSE_APPROVAL_ERROR_MESSAGE,
            "error"
          );
        }
      },
    }
  );

  const totalAdvances = advanceOrder.value;
  const totalExpenses = expenses.reduce((total, { value }) => total + value, 0);

  return {
    user,
    result,
    items: state.items,
    isLoadingExpenses,
    isAllExpensesJudged,
    isSuccessExpensesJudgment,
    reasonRejectedRefs,
    totalAdvances,
    totalExpenses,
    approveItem,
    reproveItem,
    handleExpensesJudgment,
    isLoadingExpensesJudgment,
  };
}
