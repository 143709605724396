import { Outlet } from "react-router-dom";
import { BookingVehicleContext } from "./contexts/BookingVehicleContext";
import { BrokenPolicyJustificationProvider } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingVehicle/BookingVehicleContainer";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SNACKBAR_MESSAGES = {} as const;

export function BookingVehicleContainer() {
  return (
    <BookingVehicleContext.Provider value={{}}>
      <BrokenPolicyJustificationProvider>
        <Outlet />
      </BrokenPolicyJustificationProvider>
    </BookingVehicleContext.Provider>
  );
}
