export interface ObserverEvent {
  type: string;
  data: any;
}

export class SimpleObservable {
  private observers: Array<(change?: ObserverEvent) => void> = [];

  public subscribe(observer: (change?: ObserverEvent) => void): () => void {
    this.observers.push(observer);

    return (): void => {
      const index = this.observers.indexOf(observer);
      this.observers.splice(index, 1);
    };
  }

  public inform(change?: ObserverEvent): void {
    this.observers.forEach((observer) => observer(change));
  }
}
