import * as React from "react";
import { SVGProps } from "react";

const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10 8.75a.625.625 0 0 0-.625.625v7.5A.625.625 0 0 0 10 17.5a.625.625 0 0 0 .625-.625v-7.5A.625.625 0 0 0 10 8.75ZM10 2.5a.625.625 0 0 0-.625.625v3.75A.625.625 0 0 0 10 7.5a.625.625 0 0 0 .625-.625v-3.75A.625.625 0 0 0 10 2.5ZM15.625 15a.625.625 0 0 0-.625.625v1.25a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625v-1.25a.625.625 0 0 0-.625-.625ZM15.625 2.5a.625.625 0 0 0-.625.625v10a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625v-10a.625.625 0 0 0-.625-.625Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M13.75 12.5a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h3.75a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625ZM4.375 12.5a.625.625 0 0 0-.625.625v3.75a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625v-3.75a.625.625 0 0 0-.625-.625ZM4.375 2.5a.625.625 0 0 0-.625.625v7.5a.625.625 0 0 0 .625.625.625.625 0 0 0 .625-.625v-7.5a.625.625 0 0 0-.625-.625Z"
    />
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M2.5 10a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h3.75a.625.625 0 0 0 .625-.625A.625.625 0 0 0 6.25 10ZM8.125 6.25a.625.625 0 0 0-.625.625.625.625 0 0 0 .625.625h3.75a.625.625 0 0 0 .625-.625.625.625 0 0 0-.625-.625Z"
    />
  </svg>
);

export default SvgFilter;
