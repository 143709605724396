import { api } from "~/infrastructure/api";
import { Card } from "~/application/types";
import { EditableCard, ICardService } from "./ICardService";
import { mapCardDTO } from "~/infrastructure/api/mappers";

export class CardService implements ICardService {
  async find(agencyUuid: string): Promise<Card[]> {
    return api
      .get(`/agencies/${agencyUuid}/cards`)
      .then(({ data: response }) => response.data.map(mapCardDTO));
  }

  async create(data: EditableCard): Promise<Card> {
    return api
      .post(`/agencies/${data.agencyUuid}/cards`, this.body(data))
      .then(({ data }) => mapCardDTO(data));
  }

  async update(data: EditableCard): Promise<Card> {
    return api
      .put(`/agencies/cards/${data.uuid}`, this.body(data))
      .then(({ data }) => mapCardDTO(data));
  }

  async toggleActive(data: EditableCard): Promise<void> {
    data.active ? this.inactivate(data.uuid) : this.activate(data.uuid);
  }

  async activate(uuid: string): Promise<Card> {
    return api.patch(`/agencies/cards/${uuid}/active`).then(({ data }) => data);
  }

  async inactivate(uuid: string): Promise<Card> {
    return api
      .patch(`/agencies/cards/${uuid}/inactive`)
      .then(({ data }) => data);
  }

  body(data: EditableCard) {
    return {
      agency_uuid: data.agencyUuid,
      card_flag_uuid: data.flag.uuid,
      description: data.description,
      internal_card_code: data.internalCardCode,
      number: data.number.replaceAll("-", ""),
      owner: data.owner,
      security_code: data.securityCode,
      validity: data.validity,
      installments: data.installments,
    };
  }
}
