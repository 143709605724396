import { ApiError } from "~/application/types";
import { PaginatedResource } from "~/application/types";
import { Markdown } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import {
  customerMarkdownService,
  markdownService,
} from "~/application/usecases";
import { log } from "~/utils/log";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";

export interface UseMarkdownsResult {
  activeMarkdownId?: string;
  data?: Markdown[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  searchText: string;
  setSearchText: (search: string) => void;
  onGoToPage: (value: number) => void;
  onUpdateCustomerMarkdown: (item: Markdown) => void;
}

export interface UseMarkdownsOptions {
  activeMarkdownId?: string;
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useMarkdowns";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar markdowns",
  UPDATE_SUCCESS_MESSAGE: "Markdown atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar markdown",
} as const;

export function useMarkdowns({
  activeMarkdownId,
  customerId,
  enabled,
}: UseMarkdownsOptions): UseMarkdownsResult {
  const { user } = useUser();
  const agencyId = user.agency.uuid;

  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<
    PaginatedResource<Markdown[]>,
    ApiError
  >(
    [QueryKeys.AGENCY_MARKDOWNS, agencyId, currentPage, { search }],
    () => markdownService.find({ page: currentPage, name: search, agencyId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateUpdateCustomerMarkdown } = useMutation(
    (item: Markdown) =>
      customerMarkdownService.update({
        customerId,
        markdownId: item.uuid,
      }),
    {
      onMutate: (item) => {
        log.i(
          LOG_TAG,
          `Updating CustomerMarkdown(customerId: ${customerId}, markdownId: ${item.uuid})`
        );
      },
      onSuccess: (_, item) => {
        log.i(
          LOG_TAG,
          `Successfully downdated CustomerMarkdown(customerId: ${customerId}, markdownId: ${item.uuid})`
        );

        queryClient.invalidateQueries([QueryKeys.CUSTOMERS]);
        queryClient.invalidateQueries([QueryKeys.CUSTOMER, customerId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page || 0,
    activeMarkdownId,
    searchText: searchText,
    setSearchText: handleChangeSearch,
    onGoToPage: setCurrentPage,
    onUpdateCustomerMarkdown: mutateUpdateCustomerMarkdown,
  };
}
