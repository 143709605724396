import { Road } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { RoadDTO } from "../dtos";
import { mapTravelerDTO } from "./mapTravelerDTO";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapRoadWithoutSearchKeyDTO(data: RoadDTO): Omit<Road, 'searchKey'> {
  return {
    id: data.id,
    uuid: data.uuid,
    company: data.company,
    companyImage: data.companyImage,
    from: data.from,
    to: data.to,
    availableSeats: data.availableSeats,
    withBPE: data.withBPE,
    departureDate: toDate(data.departure.date + "T" + data.departure.time),
    arrivalDate: toDate(data.arrival.date + "T" + data.arrival.time),
    travelDuration: 1000 * data.travelDuration,
    seatClass: data.seatClass,
    totalPrice: data.totalPrice,
    price: data.price,
    taxPrice: data.taxPrice,
    fee: data.fee,
    insurance: data.insurance,
    isInsuranceIncludedOnValue: data.value_with_insurance,
    allowCanceling: data.allowCanceling,
    providerIdentification: data.provider_identification,
    travelers: (data.travelers || []).map((traveler) => ({
      ...mapTravelerDTO(traveler),
      seats: traveler.seats.map((seat) => ({
        seatType: seat.seat_type,
        isConnection: seat.is_connection,
        seatNumber: seat.seat_number,
        ticket: seat.ticket,
        bpe: seat.bpe,
      })),
    })),
    connection: data.connection
      ? {
        name: data.connection?.name,
        seatClass: data.connection?.seat_class,
        departureDate: toDate(data.connection?.departure_date),
        arrivalDate: toDate(data.connection?.arrival_date),
        availableSeats: data.connection?.available_seats,
        bpe: data.connection?.bpe,
        companyName: data.connection?.company_name,
        companyUrl: data.connection?.company_url,
      }
      : undefined,
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
  };
}

export function mapRoadDTO(data: RoadDTO, searchKey: string): Road {
  return {
    searchKey,
    ...mapRoadWithoutSearchKeyDTO(data),
  };
}

export function mapCheapestRoadsDTO(
  data: RoadDTO[]
): Omit<Road, 'searchKey'>[] {
  return data.map((item) => mapRoadWithoutSearchKeyDTO(item));
}