import * as yup from "yup";

export function isValidImageType(fileName?: string) {
  return fileName
    ? ["jpg", "png", "jpeg", "webp"].indexOf(fileName.split('.').pop()!) > -1
    : false;
}

export const createOfflineHotelRoomSchema = yup.object().shape({
  description: yup.string().required("A descrição é obrigatória"),
  regime: yup.object()
    .shape({
      name: yup.string().required("O regime é obrigatório"),
    }),
  type: yup.object()
    .shape({
      name: yup.string().required("O tipo é obrigatório"),
    }),
  value: yup.number().typeError("Digite um valor válido").required("O valor é obrigatório"),
  adultsAmount: yup
    .number()
    .typeError("Digite um número válido")
    .min(1, "O número de adultos deve ser pelo menos 1")
    .required("O número de adultos é obrigatório"),
  images: yup
    .mixed()
    .required("As fotos são obrigatórias")
    .test("is-valid-type", "Selecione imagens válidas", (value: any) => {
      if (!value.length) return true;
      return isValidImageType(value[0].name.toLowerCase());
    }),
  amenities: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Digite uma comodidade"),
    })
  ).min(1, "Informe pelo menos uma comodidade"),
});

export type CreateOfflineHotelRoomSchema = yup.InferType<typeof createOfflineHotelRoomSchema>;

export const editOfflineHotelRoomSchema = yup.object().shape({
  description: yup.string().required("A descrição é obrigatória"),
  regime: yup.object()
    .shape({
      name: yup.string().required("O regime é obrigatório"),
    }),
  type: yup.object()
    .shape({
      name: yup.string().required("O tipo é obrigatório"),
    }),
  value: yup.number().typeError("Digite um valor válido").required("O valor é obrigatório"),
  adultsAmount: yup
    .number()
    .typeError("Digite um número válido")
    .min(1, "O número de adultos deve ser pelo menos 1")
    .required("O número de adultos é obrigatório"),
  images: yup
    .mixed()
    .test("is-valid-type", "Selecione uma imagem válida", (value: any) => {
      if (!value.length) return true;
      return isValidImageType(value[0].name.toLowerCase());
    }),
  amenities: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Digite uma comodidade"),
    })
  ).min(1, "Informe pelo menos uma comodidade"),
});

export type EditOfflineHotelRoomSchema = yup.InferType<typeof editOfflineHotelRoomSchema>;