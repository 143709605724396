import { Justification } from '~/application/types';
import { JustificationDTO } from '../dtos';

export function mapJustificationDTO(data: JustificationDTO): Justification {
  return {
    uuid: data.uuid,
    name: data.name,
    customerId: data.customer_uuid,
    isActive: Boolean(data.active),
    serviceType: data.service_type,
  };
}