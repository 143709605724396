import { Control, FormState } from "react-hook-form";

import { Col } from "~/components/Grid";
import { Approver, CustomerEmployee } from "~/application/types";
import { Flex, FlexProps } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Icon } from "~/components/Icon/Icon";
import { IconButton } from "~/components/IconButton";
import { TooltipLabel, Tooltip } from "~/components/Tooltip";
import { Caption, H6 } from "~/components/Typography";
import { AsyncSelect } from "../../components/AsyncSelect/AsyncSelect";
import { EMPTY_LEVEL } from "./utils";
import { Level } from "~/application/types";
import { ApprovalModelSchema, OnSelectApproverParams } from "./ApprovalModelDialog";
import { SvgPlus, SvgTrash } from "~/components/Icon/icons";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem } from "~/components/List";
import { Text } from "~/components/Text";

export interface ApprovalLevelListItemProps extends FlexProps {
  level: Level;
  index: number;
  defaultApprovers: Approver[];
  control: Control<ApprovalModelSchema>;
  formState: FormState<ApprovalModelSchema>;
  onSelectApprover: (params: OnSelectApproverParams) => void;
  fetchApprovers: (name: string) => Promise<CustomerEmployee[]>;
  onAddLevel: (level: Level, levelIndex: number) => void;
  onRemoveLevel: (index: number) => void;
  onRemoveApprover: (approver: Approver, levelIndex: number) => void;
}

export const ApprovalLevelListItem = ({
  level,
  index: levelIndex,
  control,
  formState,
  defaultApprovers,
  onSelectApprover,
  fetchApprovers,
  onAddLevel,
  onRemoveLevel,
  onRemoveApprover,
  ...props
}: ApprovalLevelListItemProps) => {
  return (
    <Flex gap="2" direction="column" {...props}>
      <Flex align="center" justify="between">
        <H6>Nível {levelIndex + 1}</H6>

        <Flex>
          <Tooltip content={<TooltipLabel>Adicionar nível</TooltipLabel>}>
            <IconButton size="lg" onClick={() => onAddLevel(EMPTY_LEVEL, levelIndex + 1)}>
              <Icon as={SvgPlus} />
            </IconButton>
          </Tooltip>

          <Tooltip content={<TooltipLabel>Remover nível </TooltipLabel>}>
            <IconButton size="lg" onClick={() => onRemoveLevel(levelIndex)}>
              <Icon as={SvgTrash} />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>

      <AsyncSelect
        queryKey="fetch-approvers"
        onChange={(approver) => onSelectApprover({ approver, levelIndex })}
        fetchOptions={fetchApprovers}
        defaultOptions={defaultApprovers}
        getOptionLabel={(option) => `${option.name ?? ""} ${option.lastName ?? ""}`}
        getOptionValue={(option) => option.uuid}
        placeholder="Selecione o aprovador"
        size="sm"
      />

      {formState.errors.levels && formState.errors.levels[levelIndex]?.approvers && (
        <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
          {formState.errors.levels[levelIndex as number]!.approvers?.message}
        </Text>
      )}

      <FieldLabel css={{ mt: "$4" }}>Aprovadores</FieldLabel>

      {level.approvers
        .filter((a) => a.uuid)
        .map((approver, approverIndex) => {
          return (
            <DataListItem data-active key={`approver-${approverIndex}`}>
              <Col>
                <FormControl
                  control={control}
                  name={`levels.${levelIndex}.approvers.${approverIndex}.name`}
                >
                  <DataColItem
                    headerTitle="Aprovador"
                    boxCss={{ mb: "$2", mt: "$4" }}
                    data={`${approver.name ?? ""} ${approver.lastName ?? ""}`}
                  />
                </FormControl>
              </Col>

              <Col>
                <FormControl
                  name={`levels.${levelIndex}.approvers.${approverIndex}.selfApprover`}
                  control={control}
                >
                  <Flex justify="end" css={{ width: "100%" }}>
                    <Col sz="5">
                      <DataColItem
                        headerTitle="Aprova a si mesmo"
                        boxCss={{ mt: "$2" }}
                        data={
                          <Switch checked={approver.selfApprover}>
                            <Caption>{approver.selfApprover ? "Sim" : "Não"}</Caption>
                          </Switch>
                        }
                      />
                    </Col>

                    <Col sz="1">
                      <Tooltip content={<TooltipLabel>Remover aprovador</TooltipLabel>}>
                        <IconButton
                          css={{ mt: "$4" }}
                          size="lg"
                          onClick={() => onRemoveApprover(approver, levelIndex)}
                        >
                          <Icon as={SvgTrash} />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </Flex>
                </FormControl>
              </Col>
            </DataListItem>
          );
        })}
    </Flex>
  );
};
