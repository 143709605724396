import { styled } from "~/application/theme";

export const CalendarRow = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  rowGap: "$1",
});

export const CalendarCell = styled("div", {
  size: "$8",
  padding: 2,
  textAlign: "center",
  fontSize: "$xs",
  borderRadius: "$circular",
  userSelect: "none",
});

export const CalendarHead = styled(CalendarCell, {
  color: "$neutrals-black",
  fontWeight: "600",
});

export const CalendarDate = styled(CalendarCell, {
  color: "$neutrals-darkest",

  "& > div": {
    size: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "$circular",
  },

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$primary-light",
  },

  "&[data-active=true] > div": {
    backgroundColor: "$primary-base",
    color: "$neutrals-white",
  },

  "&[data-selected=true]": {
    backgroundColor: "$primary-light",
    borderRadius: "$none",
  },

  "&[data-disabled=true]": {
    color: "$neutrals-base",
    pointerEvents: "none",
  },

  variants: {
    startColumn: {
      0: {
        gridColumnStart: 1,
      },
      1: {
        gridColumnStart: 2,
      },
      2: {
        gridColumnStart: 3,
      },
      3: {
        gridColumnStart: 4,
      },
      4: {
        gridColumnStart: 5,
      },
      5: {
        gridColumnStart: 7,
      },
      6: {
        gridColumnStart: 7,
      },
    },
  },
});
