import { Route, Routes } from "react-router-dom";
import { UserContext } from "~/application/types";
import { AgencyOrdersPage } from "~/core/modules/Agency/pages/AgenciesOrdersPage";
import { AgencyOrderPage } from "~/core/modules/Agency/pages/AgencyOrderPage";
import { RequireRole } from "./helpers/RequireRole";

export const AgencyRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<RequireRole role={UserContext.Agency} />}>
        <Route path="pedidos" element={<AgencyOrdersPage />} />
        <Route path="pedidos/:orderId">
          <Route index element={<AgencyOrderPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

AgencyRouter.displayName = "AgencyRouter";
