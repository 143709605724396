import { Phase } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";

export interface PhaseListItemProps extends DataListItemProps {
  data: Phase;
  onEditClick: (item: Phase) => void;
}

export function PhaseListItem({
  data,
  onEditClick,
  ...props
}: PhaseListItemProps) {
  return (
    <DataListItem {...props}>
      <DataColItem headerTitle="Fase" data={data.name} />
      <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
        <IconButton size="md" onClick={() => onEditClick(data)}>
          <Icon as={SvgEdit} />
        </IconButton>
      </Tooltip>
    </DataListItem>
  );
}
