import { Fragment, useMemo } from "react";

import { CheapestFlight, FlightServicesIncluded } from "~/application/types";
import { MaskUtils, StringUtils } from "~/application/utils";
import { Box } from "~/components/Box";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip } from "~/components/Tooltip";
import { Label } from "~/components/Typography";
import { FlightOptionBenefit } from "~/presentation/shared/components/FlightListItem";
import { TrackDots } from "~/presentation/shared/components/Track";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { getAirlineUrl } from "~/presentation/shared/utils";
import { formatFlightInfo } from "../../utils";
import { Styled } from "./styled";
import { createFlightBenefits } from "~/presentation/shared/components/FlightListItem/FlightOptionItem/utils";

interface FlightItemProps {
  data: CheapestFlight;
  isChosen?: boolean;
  isBestOffer?: boolean;
}

export function FlightItem({
  data,
  isChosen = false,
  isBestOffer = false,
}: FlightItemProps) {
  const isMobile = useMobile();

  const flightInfo = useMemo(() => formatFlightInfo(data), [data]);

  const scales = flightInfo.flight.segments.length - 1;

  const displayText = useMemo(() => {
    return StringUtils.formatSentence([scales, "conexão", "conexões"]);
  }, [data]);

  const flightBenefits = useMemo(() => createFlightBenefits(data.servicesIncluded as FlightServicesIncluded), [data]);

  return (
    <Styled.Container 
      css={{
        borderColor: isChosen && isBestOffer 
          ? "$success-base" 
          : (isChosen ? "$primary-base" : "$neutrals-white")
      }}
    >
      <Flex
        align="center"
        justify="between"
        gap="4"
        wrap="wrap"
        css={{
          px: "$6",
          py: "$3",
        }}
      >
        <CompanyLogo src={getAirlineUrl(flightInfo.flight.airline)} size="lg" />

        {isBestOffer && <Tag variant="warning-light" css={{ border: "none" }}>Melhor oferta</Tag>}
      </Flex>

      <Flex
        direction={{ "@mxmd": "column" }}
        gap="4"
        css={{
          px: "$6",
          pt: "$3",
          pb: "$9",
        }}
      >
        <Col>
          <Flex direction="column" gap="2">
            <Label css={{ fw: 400 }}>Saída</Label>
            <Text css={{ fw: 600 }}>{flightInfo.departureFlightInfo.airportIata}</Text>
            <Text css={{ lineHeight: "1.4" }}>{flightInfo.departureFlightInfo.airport}</Text>
            <Text size="2">{flightInfo.departureFlightInfo.date}</Text>
            <Label css={{ fw: 400 }}>{flightInfo.departureFlightInfo.dateTimeHour12}</Label>
          </Flex>
        </Col>

        <Col>
          <Flex
            direction="column"
            gap="3"
            css={{
              flex: "1 1 0%",
              width: "$40",
              "@xl": {
                ml: "$8",
              },
            }}
          >
            <Label css={{ fw: 400 }}>Duração</Label>
            {scales > 0 && (
              <>
                {isMobile ? (
                  <Box>
                    <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                      {displayText}
                    </Text>
                    <TrackDots numberOfDots={scales} />
                  </Box>
                ) : (
                  <Box css={{ width: "70px" }}>
                    <TrackDots numberOfDots={scales} />
                    <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                      {displayText}
                    </Text>
                  </Box>
                )}
              </>
            )}
            <Label css={{ fw: 400 }}>{flightInfo.flightDuration}</Label>
            <Label>Voo {data.segments[0].flightNumber}</Label>

            <Flex align="center" gap="1" css={{ mt: "$1" }}>
              <Label css={{ fw: 400 }}>Tarifa</Label>
              <Label>{data.familyFare}</Label>
              <Tooltip
                variant="white"
                content={
                  <Flex direction="column" gap="2" css={{ maxWidth: "20rem" }}>
                    {flightBenefits.map((item, i) => (
                      <Fragment key={item.name}>
                        <FlightOptionBenefit name={item.name} isExists={item.isExists} />
                        {(i !== flightBenefits.length - 1 ) && <Divider />}
                      </Fragment>
                    ))}
                  </Flex>
                }
              >
                <IconButton size="sm">
                  <Icon as={SvgInfo} />
                </IconButton>
              </Tooltip>
            </Flex>
          </Flex>
        </Col>

        <Col>
          <Flex direction="column" gap="2">
            <Label css={{ fw: 400 }}>Chegada</Label>
            <Text css={{ fw: 600 }}>{flightInfo.arrivalInfo.airportIata}</Text>
            <Text css={{ lineHeight: "1.4" }}>{flightInfo.arrivalInfo.airport}</Text>
            <Text size="2">{flightInfo.arrivalInfo.date}</Text>
            <Label css={{ fw: 400 }}>{flightInfo.arrivalInfo.dateTimeHour12}</Label>
          </Flex>
        </Col>

        <Col>
          <Flex direction="column" align={{ "@initial": "end", "@mxmd": "center" }} gap="2" css={{ height: "100%" }}>
            <Label css={{ ta: "end" }}>A partir de</Label>
            <Flex>
              <Text size="5" css={{ fw: "600", lineHeight: "$8", letterSpacing: "0.015em", ta: "end" }}>
                {MaskUtils.asCurrency(flightInfo.flight.value)}
              </Text>
              
              <Tooltip
                css={{  p: "$5" }}
                variant="white"
                content={
                  <Flex direction="column" gap="3">
                    <Text>Tarifa: <Text fw="700">{MaskUtils.asCurrency(flightInfo.flight.fare)}</Text></Text>
                    <Text>Taxa de embarque: <Text fw="700">{MaskUtils.asCurrency(flightInfo.flight.boardingTax)}</Text></Text>
                    <Text>Taxa de serviço: <Text fw="700">{MaskUtils.asCurrency(flightInfo.flight.serviceTax)}</Text></Text>
                  </Flex>
                }
              >
                <IconButton size="md">
                  <Icon as={SvgInfo} />
                </IconButton>
              </Tooltip>
            </Flex>

            {isChosen && (
              <Flex
                align="center"
                gap="2" css={{
                  px: "$2",
                  py: "$3",
                  borderRadius: "$md",
                  border: `1px solid ${isBestOffer ? '$success-base' : '$primary-base'}`,
                  mt: "auto"
                }}
              >
                <Icon variant={isBestOffer ? "success" : "primary"} as={SvgCheck} css={{ size: "$6" }} />
                <Text variant={isBestOffer ? "sucess" : "primary"} css={{ fw: 600 }}>Escolhida</Text>
              </Flex>
            )}
          </Flex>
        </Col>
      </Flex>
    </Styled.Container>
  );
}

FlightItem.displayName = "FlightItem";
