import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { ApiError, Markup, PaginatedResource } from "~/application/types";
import { customerMarkupService, markupService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { logError } from "~/presentation/shared/utils/errors";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";

export interface UseMarkupsResult {
  activeMarkupId?: string;
  data?: Markup[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  searchText: string;
  setSearchText: (search: string) => void;
  onGoToPage: (value: number) => void;
  onUpdateCustomerMarkup: (item: Markup) => void;
  onToggleMarkupState: (item: Markup) => void;
}

export interface UseMarkupsOptions {
  activeMarkupId?: string;
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useMarkups";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar markups",
  REMOVE_SUCCESS_MESSAGE: "Markup removido com sucesso",
  REMOVE_ERROR_MESSAGE: "Falha ao remover markup",
  UPDATE_SUCCESS_MESSAGE: "Markup atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar markup",
} as const;

export function useMarkups({
  activeMarkupId,
  customerId,
  enabled,
}: UseMarkupsOptions): UseMarkupsResult {
  const { user } = useUser();
  const agencyId = user.agency.uuid;

  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<PaginatedResource<Markup[]>, ApiError>(
    [QueryKeys.AGENCY_MARKUPS, agencyId, currentPage, { search }],
    () => markupService.find({ page: currentPage, name: search, agencyId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateUpdateCustomerMarkup } = useMutation(
    (item: Markup) =>
      customerMarkupService.update({
        customerId,
        markupId: item.uuid,
      }),
    {
      onMutate: (item) => {
        log.i(
          LOG_TAG,
          `Updating CustomerMarkup(customerId: ${customerId}, markupId: ${item.uuid})`
        );
      },
      onSuccess: (_, item) => {
        log.i(
          LOG_TAG,
          `Successfully updated CustomerMarkup(customerId: ${customerId}, markupId: ${item.uuid})`
        );

        queryClient.invalidateQueries([QueryKeys.CUSTOMERS]);
        queryClient.invalidateQueries([QueryKeys.CUSTOMER, customerId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateRemoveMarkup } = useMutation(
    () => customerMarkupService.remove(customerId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CUSTOMERS]);
        queryClient.invalidateQueries([QueryKeys.CUSTOMER, customerId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.REMOVE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error, item) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.REMOVE_ERROR_MESSAGE,
        });
      },
    }
  );

  const handleToggleState = useCallback(
    (markup: Markup) => {
      if (markup.uuid === activeMarkupId) {
        return mutateRemoveMarkup();
      }

      mutateUpdateCustomerMarkup(markup);
    },
    [activeMarkupId]
  );

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page || 0,
    activeMarkupId,
    searchText: searchText,
    setSearchText: handleChangeSearch,
    onGoToPage: setCurrentPage,
    onUpdateCustomerMarkup: mutateUpdateCustomerMarkup,
    onToggleMarkupState: handleToggleState,
  };
}
