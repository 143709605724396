import { months } from "../utils";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Skeleton } from "~/components/Skeleton";

export function BudgetViewSkeleton() {
  return (
    <Flex 
      direction="column"
      css={{
        minWidth: "92rem",
        overflow: "visible",
        border: "1px solid $primary-base", 
        backgroundColor: "$neutrals-white", 
        borderRadius: "$md $md 0 0" 
      }}
    >
      <Row gapX="1" gapY="1" css={{ height: "$18" }}>
        <Col sz="1" css={{ alignSelf: "stretch" }}>
          <Flex align="center" justify="center" css={{ flex: 1, height: "100%"  }}>
            <Skeleton variant="text-4" css={{ width: "$18" }} />
          </Flex>
        </Col>

        <Col sz="1" />

        <Col sz="9" css={{ alignSelf: "stretch" }}>
          <Flex css={{ flex: 1, height: "100%"  }}>
            {months.map((month) => (
              <Flex key={month} align="center" justify="center" css={{ flex: 1 }}>
                <Skeleton variant="text-4" css={{ width: "$10" }} />
              </Flex>
            ))}
          </Flex>
        </Col>

        <Col sz="1" css={{ alignSelf: "stretch" }}>
          <Flex align="center" justify="center" css={{ flex: 1, height: "100%" }}>
            <Skeleton variant="text-4" css={{ width: "$18"}} />
          </Flex>
        </Col>
      </Row>

      {Array.from({ length: 4 }).map((_, i) => (
        <Row key={i} gapX="1" gapY="1" css={{ minHeight: "8rem", borderTop: "1px solid $primary-base" }}>
          <Col sz="1" css={{ alignSelf: "stretch", p: "$4", borderRight: "1px solid $primary-base" }}>
            <Flex align="center" justify="center" css={{ flex: 1, height: "100%", textAlign: "center" }}>
              <Skeleton variant="text-4" css={{ width: "6rem" }} />
            </Flex>
          </Col>

          <Col sz="1" css={{ alignSelf: "stretch", p: "$4", borderRight: "1px solid $primary-base" }}>
            <Flex direction="column" gap="6" css={{ flex: 1, height: "100%" }}>
              <Skeleton variant="text-4" css={{ width: "4rem" }} />
              <Skeleton variant="text-4" css={{ width: "6rem" }} />
              <Skeleton variant="text-4" css={{ width: "4rem" }} />
            </Flex>
          </Col>

          <Col sz="9" css={{ alignSelf: "stretch", py: "$4", borderRight: "1px solid $primary-base" }}>
            <Flex css={{ flex: 1, height: "100%" }}>
              {Array.from({ length: 12 }).map((_, i) => (
                <Flex key={i} direction="column" align="center" justify="between" css={{ flex: 1, height: "100%" }}>
                  <Skeleton variant="text-4" css={{ width: "4rem" }} />
                  <Skeleton variant="text-4" css={{ width: "4rem" }} />
                  <Skeleton variant="text-4" css={{ width: "4rem" }} />
                </Flex>
              ))}
            </Flex>
          </Col>

          <Col sz="1" css={{ alignSelf: "stretch", py: "$4" }}>
            <Flex direction="column" align="center" justify="between" css={{ flex: 1, height: "100%" }}>
              <Skeleton variant="text-4" css={{ width: "6rem" }} />
              <Skeleton variant="text-4" css={{ width: "6rem" }} />
              <Skeleton variant="text-4" css={{ width: "6rem" }} />
            </Flex>
          </Col>
        </Row>
      ))}
    </Flex>
  );
}