import { dialogService } from "~/components/DialogStack";
import { CancelDialog } from "~/components/CancelDialog";
import { Text } from "~/components/Text";
import { asCurrency } from "~/utils/mask.utils";
import { SvgDolar } from "~/components/Icon/icons";

export type ConfirmOrderCancellationProps = {
  onRequestOrderCancellation: () => Promise<void>;
  taxCancellation?: number;
};

export function ConfirmOrderCancellation({
  onRequestOrderCancellation,
  taxCancellation,
}: ConfirmOrderCancellationProps) {
  return (
    <CancelDialog
      title="Seu pedido ira gerar uma taxa."
      textCancelation="Cancelar pedido"
      onConfirm={onRequestOrderCancellation}
      onCloseClick={() => dialogService.popDialog()}
      svg={SvgDolar}
    >
      <Text as="p">
        {" "}
        O valor de{" "}
        <Text
          css={{
            fontWeight: "bold",
          }}
        >
          {asCurrency(taxCancellation || "0")}
        </Text>{" "}
        será cobrado por conta das políticas da companhia. Deseja contiuar?
      </Text>
    </CancelDialog>
  );
}
