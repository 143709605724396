import { useCallback, useMemo, useState } from "react";
import {
  Filter,
  IFilterGroup,
  createFilterGroups,
} from "~/presentation/shared/utils";
import { SelectedFilter, UseFiltersOptions, UseFiltersReturn } from "./types";

const extract = <TData, TItem = TData>(
  data: TData[],
  itemExtractor?: (item: TData) => TItem
): TItem[] => {
  return itemExtractor ? data.map(itemExtractor) : (data as unknown as TItem[]);
};

const extractItem = <TData, TItem = TData>(
  data: TData,
  itemExtractor?: (item: TData) => TItem
): TItem => itemExtractor?.(data) ?? (data as unknown as TItem);

export function useFilters<TData, TItem = TData>({
  data,
  filters: filtersOption,
  itemExtractor,
  filtersGenerator = [],
  sort = true,
}: UseFiltersOptions<TData, TItem>): UseFiltersReturn<TData> {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter>({});

  const generatedFilters = useMemo<IFilterGroup<TItem>[]>(
    () => createFilterGroups(extract(data, itemExtractor), filtersGenerator),
    [data, itemExtractor, filtersGenerator]
  );

  const filterGroups = useMemo<IFilterGroup<TItem>[]>(() => {
    let filters = [...filtersOption, ...generatedFilters];

    if (sort) {
      filters = filters.sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    return filters;
  }, [sort, generatedFilters, filtersOption]);

  const memoizedFilters = useMemo(
    () =>
      filterGroups.map((group) => ({
        key: group.key,
        label: group.label,
        options: group.options.map((item) => ({
          key: item.key,
          label: item.label,
          quantity: data.filter((i) => {
            const exItem = extractItem(i, itemExtractor);

            return group.isValid(exItem, item.value);
          }).length,
        })),
      })),
    [data, filterGroups]
  );

  const filters = useMemo<Filter[]>(
    () =>
      memoizedFilters.map((group) => ({
        ...group,
        options: group.options.map((item) => ({
          ...item,
          selected: !!selectedFilters[group.key]?.has(item.key),
        })),
      })),
    [selectedFilters, memoizedFilters]
  );

  const filteredData = useMemo<TData[]>(
    () =>
      data.filter((item) => {
        const exItem = extractItem(item, itemExtractor);

        return filterGroups.every((group) => {
          const selected = group.options.filter(
            (option) => !!selectedFilters[group.key]?.has(option.key)
          );

          // If no item from group is selected, don't filter anything.
          if (!selected.length) return true;

          return selected.some((option) => group.isValid(exItem, option.value));
        });
      }),
    [data, itemExtractor, selectedFilters, filterGroups]
  );

  const onFilterChange = useCallback((groupKey: string, optionKey: string) => {
    setSelectedFilters((old) => {
      const filtersSet = new Set(old[groupKey]);

      if (!filtersSet.has(optionKey)) {
        filtersSet.add(optionKey);
      } else {
        filtersSet.delete(optionKey);
      }

      return {
        ...old,
        [groupKey]: filtersSet,
      };
    });
  }, []);

  const onClearFilters = useCallback(() => {
    setSelectedFilters({});
  }, []);

  return {
    filters,
    filteredData,
    onFilterChange,
    onClearFilters,
  };
}
