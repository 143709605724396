import { ReasonTrip } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { ReasonTripListItem } from "./components/ReasonTripListItem";

export interface TabReasonTripsProps {
  data?: ReasonTrip[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  onGoToPage: (value: number) => void;
  onCreateReasonTrip: () => void;
  onEditReasonTrip: (item: ReasonTrip) => void;
  onToggleReasonTripState: (item: ReasonTrip) => void;
}

export function TabReasonTrips({
  data,
  currentPage,
  lastPage,
  onGoToPage,
  onCreateReasonTrip,
  onEditReasonTrip,
  onToggleReasonTripState,
}: TabReasonTripsProps) {
  const listRenderer = useCallback(
    (item: ReasonTrip) => (
      <ReasonTripListItem
        data={item}
        onEditClick={onEditReasonTrip}
        onToggleState={onToggleReasonTripState}
        key={item.uuid}
      />
    ),
    [onEditReasonTrip, onToggleReasonTripState]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Motivos de viagem</H4>
          </Col>

          <Col sz="auto">
            <Button title="Novo motivo" color="primary" onClick={onCreateReasonTrip}>
              <Icon as={SvgPlus} />
              <Text>Novo motivo</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui motivos de viagem cadastrados</Text>
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
