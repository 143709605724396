import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../presentation/core/contexts/AuthContext";

export type RequireAuthProps = {
  children?: React.ReactElement;
};

export function RequireAuth({ children }: RequireAuthProps) {
  const { isSigned } = useAuth();
  const location = useLocation();

  if (!isSigned) {
    return <Navigate replace to="/" state={{ from: location }} />;
  }

  return children || <Outlet />;
}
