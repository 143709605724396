import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InstantlyApprovalAuthContainer } from "./InstantlyApprovalAuthContainer";
import { yupResolver } from "@hookform/resolvers/yup";
import { snackbarService } from "~/components/SnackbarStack";
import { useMutation } from "@tanstack/react-query";
import { logError } from "~/presentation/shared/utils/errors";
import { orderApprovalService } from "~/services/resources/OrderApproval";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { authService } from "~/application/usecases";

const LOG_TAG = "Auth/pages/InstantlyApprovalAuthPage";

export type InstantlyApprovalAuthFormData = {
  password: string;
};

const DEFAULT_FORM_DATA: InstantlyApprovalAuthFormData = {
  password: "",
};

const formDataSchema = yup.object().shape({
  password: yup.string().required("Informe a senha"),
});

export const SNACKBAR_MESSAGES = {
  INSTANTLY_APPROVAL_SUCCESS_MESSAGE: "Aprovação concluída com sucesso",
  INSTANTLY_APPROVAL_ERROR_MESSAGE: "Falha ao aprovar pedido",
} as const;

export type InstantlyApprovalProps = {
  password: string;
  token: string;
  authToken: string;
};

export function InstantlyApprovalAuthPage() {
  const { formState, control, handleSubmit } = useForm<InstantlyApprovalAuthFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: yupResolver(formDataSchema),
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? "";
  const token = searchParams.get("token") ?? "";

  const { mutate: mutateRequestRedefinePassword } = useMutation(
    async (data: InstantlyApprovalProps) => {
      return await orderApprovalService.instantlyApproval(data);
    },
    {
      onSuccess: () => {
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.INSTANTLY_APPROVAL_SUCCESS_MESSAGE,
          "success"
        );

        navigate(`/pedidos/${searchParams.get("orderId")}`);
      },
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Aprovando pedido" />);
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.INSTANTLY_APPROVAL_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const onFormSubmit = async ({ password }: InstantlyApprovalAuthFormData) => {
    const { authToken } = await authService.signIn({ email, password });

    mutateRequestRedefinePassword({
      password: password,
      token,
      authToken: authToken.value,
    });
  };

  return (
    <InstantlyApprovalAuthContainer
      control={control}
      formState={formState}
      onFormSubmit={handleSubmit(onFormSubmit)}
    />
  );
}
