import * as React from "react";
import { SVGProps } from "react";

const SvgRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM7.354 6.646a.5.5 0 1 0-.708.708L9.293 10l-2.647 2.646a.5.5 0 0 0 .708.708L10 10.707l2.646 2.647a.5.5 0 1 0 .707-.708L10.707 10l2.646-2.646a.5.5 0 1 0-.707-.708L10 9.293 7.354 6.646Z"
    />
  </svg>
);

export default SvgRemove;
