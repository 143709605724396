import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { queryClient } from "~/services/queryClient";
import { logError } from "~/presentation/shared/utils/errors";
import { CustomerCrmDialog } from "../views/Crm/components/CustomerCrmDialog";
import { customerCrmService } from "~/application/usecases/crm";
import { CrmData } from "~/application/usecases/crm/ICrmService";

export interface UserCustomerCrmOptions {
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useCustomerCrm";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar os CRM's",
  CREATE_SUCCESS_MESSAGE: "CRM criado com sucesso",
  ACTIVE_SUCCESS_MESSAGE: "CRM ativado com sucesso",
  UPDATE_SUCCESS_MESSAGE: "CRM atualizado com sucesso",
  INACTIVE_ERROR_MESSAGE: "Erro ao inativar CRM",
  INACTIVE_SUCCESS_MESSAGE: "CRM inativado com sucesso",
  ACTIVE_ERROR_MESSAGE: "Erro ao ativar CRM",
  UPDATE_ERROR_MESSAGE: "Erro ao autalizar CRM",
  CREATE_ERROR_MESSAGE: "Falha ao criar novo CRM",
} as const;

export function useCustomerCrm({ customerId }: UserCustomerCrmOptions) {

  const { mutate: mutateCreateCrm } = useMutation(
    [customerId],
    (data: CrmData) => customerCrmService.create({ ...data, customerId }),
    {
      onMutate: () => {
 
        dialogService.showDialog(<LoadingDialog message="Criando novo crm" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CRMs]);
        dialogService.popAll(),
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        dialogService.popDialog()
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      
    }
  );

  const { mutate: handleUpdateCrm } = useMutation(
    (data: CrmData) => customerCrmService.update(data),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Atualizando crm" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CRMs]);
        dialogService.popAll();
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        dialogService.popDialog(),
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const { mutate: handleToggleCrm } = useMutation(
    (data: CrmData) => customerCrmService.toggleCrm(data),
    {
      onMutate: (vars) => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message={`${vars.isActive ? "Inativando" : "Ativando"} crm`} />);
      },
      onSuccess: (vars) => {
        queryClient.invalidateQueries([QueryKeys.CRMs]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { data, isLoading } = useQuery(
    [QueryKeys.CRMs, customerId],
    () => customerCrmService.find(customerId)
  );
 
  const onOpenCreateDialog = useCallback(() => {
    dialogService.showDialog(
      <CustomerCrmDialog
        isNew
        crmData={data}
        onSubmitProp={mutateCreateCrm}
      />
    );
  }, [data, mutateCreateCrm]);

  const openEditDialog = useCallback((item: CrmData) => {
    dialogService.showDialog(
      <CustomerCrmDialog
        onSubmitProp={handleUpdateCrm}
        defaultData={item}
      />
    );
  }, []);

  return {
    data,
    isLoading,
    onEditClick: openEditDialog,
    onOpenDialog: onOpenCreateDialog,
    onToggle: handleToggleCrm,
  };
}
