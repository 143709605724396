export function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.download = filename;
  link.click();

  link.parentNode?.removeChild(link);
  URL.revokeObjectURL(url);
}
