import { UseOrderApprovalProps } from "./types";

export function useOrderApproval({
  canShowApprovalButtons,
  cannotApproveItems,
  isRebookingRoad,
  isRoadRebookError,
  handleSubmit,
  handleJudgment,
  onConsultOrderItemsPrice,
  onOrderItemsPriceChange,
}: UseOrderApprovalProps) {
  const onSubmit = handleSubmit(
    async () => {
      if (
        !!canShowApprovalButtons &&
        !cannotApproveItems &&
        !isRebookingRoad &&
        !isRoadRebookError
      ) {
        const orderItems = await onConsultOrderItemsPrice();
        const someRoadIncreasedPrice = orderItems?.road?.some(({ newValue }) => newValue);

        if (someRoadIncreasedPrice) {
          return onOrderItemsPriceChange(orderItems);
        }
      }

      handleJudgment();
    },
    (error: any) => {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  );

  return {
    onSubmit,
  };
}
