import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgStar } from "~/components/Icon/icons";
import { useMemo } from "react";

export interface StarRatingProps {
  stars: number;
  maxStars?: number;
}

export function StarRating({ maxStars = 5, stars }: StarRatingProps) {
  const starsArray = useMemo(
    () => Array.from({ length: maxStars }).fill(null),
    [maxStars]
  );

  return (
    <Flex align="center">
      {starsArray.map((_, starIndex) => (
        <Icon
          key={`stars-${starIndex}`}
          variant={starIndex < stars ? "warning" : "light"}
          as={SvgStar}
          size="sm"
        />
      ))}
    </Flex>
  );
}

StarRating.displayName = "StarRate";
