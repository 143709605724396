import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { approvalModelService, orderService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { OrderTab } from "~/presentation/shared/utils";
import { log } from "~/utils/log";
import { useOrderTab } from "../../../../../../../../presentation/shared/hooks/useOrderTab";
import { useAccountabilityExpenses } from "../../../hooks/useAccountabilityExpenses";
import { AccountabilityExpenseContainer } from "./AccountabilityExpenseContainer";
import { useCallback } from "react";
import { useUser } from "~/presentation/core/contexts/UserContext";

const LOG_TAG = "AccountabilityExpense/AccountabilityExpensePage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar despesas",
} as const;

export function AccountabilityExpensePage() {
  const { orderId, travelerId } = useParams() as {
    orderId: string;
    travelerId: string;
  };
  const { contexts, user } = useUser();
  const tabValue = useOrderTab();
  const customerId = user.customer?.uuid || contexts.customer?.uuid;

  const fetchApprovalModels = useCallback(
    () => approvalModelService.find({ customerId }).then(({ data }) => data),
    []
  );

  const { data: order, isLoading: isLoadingOrder } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId),
    {
      staleTime: QueryTimes.LONG,
      refetchOnWindowFocus: true,
      onError: (error) => {
        log.e(LOG_TAG, error);
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { isLoading: isLoadingExpenses } = useAccountabilityExpenses({
    order,
    customerEmployeeId: travelerId,
    enabled: tabValue === OrderTab.ACCOUNTABILITY_EXPENSES,
  });

  return (
    <AccountabilityExpenseContainer
      travelerId={travelerId}
      fetchApprovalModels={fetchApprovalModels}
      isLoading={isLoadingOrder && isLoadingExpenses}
      activeTab={tabValue}
      order={order}
    />
  );
}
