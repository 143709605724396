import { FC } from "react";
import { HotelBudgetState } from "../type";
import { FormDialog } from "~/components/FormDialog";
import { Card, CardBody } from "~/components/Card";
import { usePDF } from "react-to-pdf";
import { Flex } from "~/components/Flex";
import { Button } from "~/components/Button";
import { Divider } from "~/components/Divider";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { Text } from "~/components/Text";
import { SvgCheck, SvgFavicon } from "~/components/Icon/icons";
import { Icon } from "~/components/Icon";
import { Customer, HotelQuery, User } from "~/application/types";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Grid } from "~/components/Grid";
import { titleize } from "~/utils/string.utils";
import { asCurrency } from "~/utils/mask.utils";
import { StarRating } from "~/core/shared/components/StarsRating";
import useMobile from "~/presentation/shared/hooks/useMobile";

export type HotelBudgetDialogProps = {
  data: HotelBudgetState;
  user?: User;
  customer?: Customer;
  hotelParams: HotelQuery | null;
};

export const HotelBudgetDialog: FC<HotelBudgetDialogProps> = ({
  data,
  customer,
  user,
  hotelParams,
}) => {
  const { toPDF, targetRef } = usePDF({
    filename: "Orçamento.pdf",
  });
  const isMobile = useMobile();

  return (
    <Card
      css={{
        width: isMobile ? "100vw" : "55vw",
      }}
    >
      <FormDialog
        title={"PDF"}
        css={{
          width: "100%",
          overflow: "hidden",
        }}
        positiveButton={
          <Flex justify="end">
            <Button onClick={() => toPDF()}>Baixar PDF</Button>
          </Flex>
        }
      >
        <Flex
          css={{
            width: "100%",
            overflow: "auto",
          }}
          direction="column"
        >
          <CardBody
            ref={targetRef}
            css={{
              width: "100%",
            }}
          >
            <Flex gap="2" direction="column">
              <Flex
                css={{
                  width: "100%",
                  p: "$3 $6",
                  borderRadius: "$md $md $none $none",
                  backgroundColor: "$primary-base",
                }}
                align="center"
                justify="between"
              >
                <Icon as={SvgFavicon} size="lg" />
                <Flex direction="column" gap="1">
                  <Text size="1" variant="white">
                    Empresa
                  </Text>
                  <Text size="3" variant="white" fw="600">
                    {customer?.companyName}
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="2" direction="column">
                <Text fw="700">
                  Agente: <Text fw="400">{`${user?.name}`}</Text>
                </Text>
                <Text fw="700">
                  Telefone: <Text fw="400">{user?.phone}</Text>
                </Text>
                <Text fw="700">
                  E-mail: <Text fw="400">{user?.email}</Text>
                </Text>
              </Flex>
              <Divider
                css={{
                  borderColor: "$neutrals-dark",
                  width: "100%",
                }}
              />
              <Flex
                css={{
                  width: "100%",
                }}
                align="center"
                justify="between"
              >
                <Flex
                  css={{
                    p: "$3",
                    borderRadius: "$pill",
                    backgroundColor: "$primary-base",
                  }}
                >
                  <Text variant="white" fw="700" size="4">
                    Cotação Eletrônica
                  </Text>
                </Flex>
                <Flex direction="column" gap="1">
                  <Text size="1" fw="400">
                    Data da cotação
                  </Text>
                  <Text size="3" fw="600">
                    {displayDate(Date(), DateFormats.SMALL_DATE)}
                  </Text>
                </Flex>
              </Flex>
              <Divider
                css={{
                  width: "100%",
                  borderColor: "$neutrals-dark",
                }}
              />
              <Flex direction="column" gap="3">
                <Text variant="primary-dark" fw="700" size="3">
                  Período:{" "}
                  <Text fw="500">
                    {" "}
                    {titleize(
                      displayDate(
                        hotelParams?.checkInDate as Date,
                        DateFormats.SMALL_COMPLETE_WEEK_DATE
                      )
                    )}
                    {" até "}
                  </Text>
                  <Text fw="500">
                    {" "}
                    {titleize(
                      displayDate(
                        hotelParams?.checkOutDate as Date,
                        DateFormats.SMALL_COMPLETE_WEEK_DATE
                      )
                    )}
                  </Text>
                </Text>
                <Flex direction="column" gap="6">
                  {data.map((hotel, index) => (
                    <Flex key={hotel?.uuid} direction="column" gap="2">
                      <Text fw="700" size="5" variant="primary">{`Opção ${index + 1}`}</Text>
                      <Flex
                        align="center"
                        justify="between"
                        css={{
                          p: "$8",
                          borderRadius: "$md",
                          border: "1px solid $neutrals-darkest",
                        }}
                      >
                        <Flex direction="column" gap="2">
                          <Flex align="center" justify="between" gap="4">
                            <Text fw="700">{hotel?.name}</Text>
                            <StarRating stars={hotel?.stars || 0} />
                          </Flex>
                          <Text variant="neutral">
                            {hotel?.address?.street}
                            {hotel?.address?.number}, {" - "}
                            {hotel?.address?.cityName}
                          </Text>
                        </Flex>
                        <Flex direction="column" align="end" gap="3">
                          <Text size="1"> a partir de</Text>
                          <Text size="5" fw="700">
                            {asCurrency(hotel?.bestValueTotal || 0)}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </CardBody>
        </Flex>
      </FormDialog>
    </Card>
  );
};
