import queryString from "query-string";
import type { Justification } from "~/application/types";
import { api } from "~/infrastructure/api";
import { JustificationDTO } from "~/infrastructure/api/dtos";
import { mapJustificationDTO } from "~/infrastructure/api/mappers";
import type { IJustificationService } from "./IJustificationService";

export class JustificationService implements IJustificationService {
  async findById(id: string): Promise<Justification> {
    return await api
      .get<JustificationDTO>(`/customers/justifications/${id}`)
      .then(({ data }) => mapJustificationDTO(data));
  }

  async find({ customerId }: { customerId: string }): Promise<Justification[]> {
    const url = queryString.stringifyUrl({
      url: `/customers/justifications`,
      query: { customer_uuid: customerId },
    });

    return await api
      .get<JustificationDTO[]>(url)
      .then(({ data }) => data.map(mapJustificationDTO));
  }

  async create(data: Omit<Justification, "uuid">): Promise<Justification> {
    return await api
      .post<JustificationDTO>("/customers/justifications", {
        name: data.name,
        customer_uuid: data.customerId,
        service_type: data.serviceType,
      })
      .then(({ data }) => mapJustificationDTO(data));
  }

  async updateById(data: Justification): Promise<Justification> {
    return await api
      .patch<JustificationDTO>(`/customers/justifications/${data.uuid}`, {
        name: data.name,
        customer_uuid: data.customerId,
        service_type: data.serviceType,
      })
      .then(({ data }) => mapJustificationDTO(data));
  }

  async toggleActive(data: Justification): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/customers/justifications/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/customers/justifications/${data.uuid}/active`)
      .then(({ data }) => data);
  }
}
