import { CSS } from "@stitches/react";
import { Flex } from "~/components/Flex";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { Card, CardBody } from "./Card";
import { ModalPortal } from "./ModalPortal";

export type LoadingModalProps = {
  isOpen: boolean;
  message: string;
  css?: CSS;
};

/**
 * @deprecated
 */
export const LoadingModal = ({ css, isOpen, message }: LoadingModalProps) => (
  <ModalPortal isOpen={isOpen}>
    <Card css={css} spacing="8" elevated>
      <Flex as={CardBody} align="center" direction="column" css={{ gap: "$8" }}>
        <Spinner />
        <Text css={{
          "@mxlg": {
            textAlign: "center",
          }
         }}>{message}</Text>
      </Flex>
    </Card>
  </ModalPortal>
);

LoadingModal.displayName = "LoadingModal";
