import { useContext, useState } from "react";
import { ApiErrorCode, OrderAirwaySegment, OrderStatus } from "~/application/types";
import { Actions, OrderAirwayTracker, OrderItems, Passenger } from "~/application/types/entities";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlane } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { ModalPortal } from "~/core/modules/DeprecatedBooking/components/ModalPortal";
import { PassengerListItem } from "~/presentation/shared/components/PassengerListItem";
import { ReasonRejectedItem } from "~/presentation/shared/components/ReasonRejectedItem";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { OrderApprovalItem, canMarkAsRejected } from "~/presentation/shared/utils";
import { ApprovalOrderContext } from "../../hooks/ApprovalOrderContext";
import { ReprovalMessage } from "../ApprovalMessage";
import { OrderAirwayCard } from "./OrderAirwayCard";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import { RemoveItemButton } from "../RemoveItemButton";
import { CombinedOrderAirwayItemSegmentType } from ".";
import { ApprovalButtons } from "../ApprovalButtons";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { ItemBrokenPolicyJustication } from "~/presentation/shared/components/BrokenPolicyJusticationItem/ItemBrokenPolicyJusticationItem";

export interface IOrderAirwayItemSegment {
  item: CombinedOrderAirwayItemSegmentType;
  canShowApprovalButtons?: boolean;
  onOpenDetails: (data?: OrderAirwaySegment) => void;
  onDeleteItem?: (itemId: string) => void;
  onReloadTracker?: (flightId: string) => Promise<OrderAirwayTracker | ApiErrorCode>;
  onRemakeOrder: () => void;
  onOpenAirwaySeats?: ({
    itemId,
    passengers,
  }: {
    itemId: string;
    passengers: Passenger[];
    item: CombinedOrderAirwayItemSegmentType;
  }) => void;
  onRemoveSeat?: ({ orderItemId, seatId }: { orderItemId: string; seatId: string }) => void;
  itemIndex: number;
}

const MAX_RELOAD_TRACKER_TRIES = 15;
const LOG_TAG = "Order/OrderPage/OrderAirwayItem";

export function OrderAirwayItemSegment({
  canShowApprovalButtons,
  onOpenDetails,
  onDeleteItem,
  onReloadTracker,
  onRemakeOrder,
  onOpenAirwaySeats,
  onRemoveSeat,
  item,
  itemIndex,
}: IOrderAirwayItemSegment) {
  const { order } = useOrder();
  const { items } = useContext(ApprovalOrderContext);
  const [reloadTrackerTries, setReloadTrackerTries] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { setTravelers, setOrderId } = useRemakeSearch();
  const { contexts, profile } = useUser();

  const goFlight = item.segments[0].departureFlightInfo;
  const returnFlight = item.segments?.at(item.segments?.length - 1)?.arrivalFlightInfo;

  const canReloadTracker =
    reloadTrackerTries < MAX_RELOAD_TRACKER_TRIES &&
    (!item.tracker || item?.tracker?.toLowerCase() === "gerando localizador");

  const orderAirwayItem = {
    item: OrderApprovalItem.AIRWAY_ORDERS,
    itemOrderId: item.uuid,
  };

  if (isOpen) {
    return (
      <ModalPortal isOpen={isOpen}>
        <Card>
          <Flex
            as={CardBody}
            align="center"
            direction="column"
            justify="center"
            css={{
              gap: "$8",
              "@mxlg": {
                p: 10,
              },
            }}
          >
            <Flex gap="4" direction="column" align="center">
              <Icon as={SvgPlane} size="3xl" />

              <Text
                size="5"
                fw={600}
                css={{
                  px: "$5",
                  ta: "center",

                  "@mxlg": {
                    fontSize: "$sm",
                  },
                }}
              >
                Falha ao criar a reserva junto à companhia.
              </Text>
              <Text>
                {goFlight.airport} {"->"} {returnFlight?.airport}
              </Text>
              <Text
                size="2"
                variant="darkest"
                css={{
                  ta: "center",
                  "@mxlg": {
                    fontSize: "$sm",
                  },
                  width: "65%",
                }}
              >
                Para garantir uma experiência melhor para você, tente refazer a reserva.
              </Text>
            </Flex>
            <Flex
              gap="3"
              wrap="wrap"
              css={{
                width: "100%",
              }}
            >
              <Button
                variant="error"
                onClick={() => onDeleteItem?.(item.uuid)}
                css={{
                  flex: 1,
                }}
              >
                Remover item
              </Button>
              <Button
                onClick={() => {
                  setTravelers(order?.travelers || []);
                  setOrderId(order?.uuid || "");
                  onRemakeOrder();
                }}
                css={{
                  flex: 1,
                }}
              >
                Realizar nova reserva
              </Button>
            </Flex>
          </Flex>
        </Card>
      </ModalPortal>
    );
  }

  const reasonRejected = item.reasonRejected;
  const shouldMarkAsRejected = canMarkAsRejected({
    reasonRejected: reasonRejected,
    order,
  });

  const airwayItem = {
    item: OrderApprovalItem.AIRWAY_ORDERS,
    itemOrderId: item.uuid,
  };

  const canShowReprovalMessage =
    items?.rejected && items.rejected.some(({ itemOrderId }) => itemOrderId === item.uuid);

  const orderStatus = order?.status as OrderStatus;

  const canShowBrokenPolicyJustification =
    !!item?.violatedPolicies?.length &&
    [OrderStatus.OPEN, OrderStatus.REJECTED].includes(orderStatus);

  const { approveItem, reproveItem } = useContext(ApprovalOrderContext);

  const canMarkAirwaySeats =
    item.availableSeatMap &&
    orderStatus === OrderStatus.OPEN &&
    !order?.isExpired &&
    useVerifyActions({ actions: [Actions.ViewAirwaySeats], contexts, profile });

  return (
    <Flex direction="column" gap="4">
      <Card
        css={{
          border: shouldMarkAsRejected ? "1px solid red" : "none",
          overflow: "visible",
          backgroundColor: "transparent",
        }}
      >
        <CardBody
          css={{
            p: "0",
            "@mxlg": {
              p: 5,
            },
          }}
        >
          <Flex direction="column" gap="6">
            <Flex>
              <Col>
                <Text size="5">Passagem</Text>
              </Col>

              <Flex
                css={{
                  "@mxlg": {
                    display: "none",
                  },
                }}
              >
                <ApprovalButtons
                  itemIndex={itemIndex}
                  approveItem={approveItem}
                  reproveItem={reproveItem}
                  item={orderAirwayItem}
                />
              </Flex>
            </Flex>

            <OrderAirwayCard
              itemIndex={itemIndex}
              airwayItem={airwayItem}
              canShowReprovalMessage={canShowReprovalMessage}
              data={item}
              canReloadTracker={canReloadTracker}
              onOpenDetails={onOpenDetails}
              onDeleteItem={onDeleteItem}
              onReloadTracker={() => onReloadTracker?.(item.uuid)}
            />

            {canShowBrokenPolicyJustification && (
              <ItemBrokenPolicyJustication
                itemId={item.uuid}
                brokenPolicyJustification={item.brokenPolicyJustification}
              />
            )}

            {item.passengers.length > 0 && <Text size="5">Passageiros</Text>}

            <LazyList
              items={item.passengers}
              render={(traveler) => (
                <PassengerListItem
                  data={traveler}
                  item={item}
                  key={traveler.uuid}
                  onRemoveSeat={onRemoveSeat}
                />
              )}
              skeletonHeight={88}
              skeletonQuantity={2}
            />
          </Flex>

          {shouldMarkAsRejected && (
            <ReasonRejectedItem css={{ mt: "$6" }} reasonRejected={reasonRejected} />
          )}

          {canShowReprovalMessage && <ReprovalMessage itemIndex={itemIndex} item={airwayItem} />}
        </CardBody>
      </Card>

      <Flex
        justify="end"
        css={{
          width: "100%",
        }}
        gap="2"
      >
        {canMarkAirwaySeats && (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              onOpenAirwaySeats?.({
                itemId: item.uuid,
                passengers: item.passengers,
                item,
              });
            }}
          >
            <Text size="3">Marcar assento</Text>
          </Button>
        )}
        <RemoveItemButton onRemove={() => onDeleteItem?.(item.uuid)} />
      </Flex>
    </Flex>
  );
}
