import * as React from "react";
import { SVGProps } from "react";

const SvgDolar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 4.625v1.25m0 6.25v1.25m-1.875-1.25h2.813a1.563 1.563 0 0 0 0-3.125H8.062a1.562 1.562 0 1 1 0-3.125h2.813M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
      stroke="#AE1437"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgDolar;
