import { Card } from "~/components/Card";
import { Icon } from "~/components/Icon";
import { SvgCancel, SvgClose } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { H2, H4, H5 } from "~/components/Typography";
import { Button } from "~/components/Button";
import { Box } from "~/components/Box";
import { Text } from "~/components/Text";
import { Flex } from "~/components/Flex";

export interface CancelItemDialogProps {
  title: string;
  children?: React.ReactNode;
  onConfirm: () => void;
  onCloseClick?: () => void;
  textCancelation?: string;
}

export function CancelItemDialog({
  title,
  children,
  textCancelation,
  onConfirm,
  onCloseClick,
}: CancelItemDialogProps) {
  return (
    <Card
      css={{
        boxShadow: "$sm",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "500px",
        py: "$5",
        px: "$10",
        gap: "$7",
        textAlign: "center",
        position: "relative",
        color: "$neutrals-dark",
        "@mxlg": {
          width: "330px",
        }
      }}
    >
      <Flex
        css={{
          [`& ${Icon}`]: {
            fill: "$primary-base",
          },
        }}
      >
        <Icon size="3xl" as={SvgCancel} />
      </Flex>
      <Text as={H4} variant="black">
        {title}
      </Text>
      <IconButton
        css={{
          position: "absolute",
          right: "$5",
          stroke: "$primary-base",
        }}
        onClick={onCloseClick}
      >
        <Icon as={SvgClose} />
      </IconButton>

      {children}
      <Button
        variant="primary"
        css={{
          [`& ${Icon}`]: {
            size: "$4",
            fill: "$neutrals-white",
          },
          width: "80%",
        }}
        onClick={onConfirm}
      >
        <Icon as={SvgCancel} />
        {textCancelation}
      </Button>
    </Card>
  );
}

CancelItemDialog.displayName = "CancelItemDialog";
