import { styled } from "~/application/theme";
import * as Sliders from "@radix-ui/react-slider";
import { SliderRoot } from "./SliderRoot";
import { ComponentProps } from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

export type SliderProps = ComponentProps<typeof SliderRoot> & {
  ToolTipValue: string;
  isTooltipVisible: boolean;
};

export const SliderTrack = styled(Sliders.Track, {
  backgroundColor: "$primary-base",
  position: "relative",
  flexGrow: 1,
  borderRadius: "9999px",
  height: 3,
});

export const SliderRange = styled(Sliders.Range, {
  position: "absolute",
  backgroundColor: "white",
  borderRadius: "9999px",
  height: "100%",
});

export const SliderThumb = styled(Sliders.Thumb, {
  display: "block",
  width: 20,
  height: 20,
  backgroundColor: "white",
  boxShadow: `0 2px 10px `,
  borderRadius: 10,
  "&:hover": {},
  "&:focus": { outline: "none", boxShadow: `0 0 0 5px ` },
});

const Tooltip = TooltipPrimitive.Root;
const TooltipProvider = TooltipPrimitive.Provider;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipContent = styled(TooltipPrimitive.Content, {
  borderRadius: 4,
  padding: "10px 15px",
  fontSize: 15,
  lineHeight: 1,
  backgroundColor: "white",
  boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
});

const TooltipText = styled("span", {
  fontFamily: "inherit",
});

export const SimpleSlider = ({ ToolTipValue, isTooltipVisible, ...props }: SliderProps) => {
  return (
    <SliderRoot {...props} defaultValue={[props.max as number]}>
      <SliderTrack></SliderTrack>
      <TooltipProvider>
        <Tooltip open={isTooltipVisible}>
          <TooltipTrigger asChild>
            <SliderThumb />
          </TooltipTrigger>
          <TooltipContent side="top" sideOffset={5}>
            <TooltipText>{ToolTipValue}</TooltipText>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </SliderRoot>
  );
};

SimpleSlider.displayName = "Slider";
