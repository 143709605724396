import { Markdown } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { MarkdownListItem } from "./components/MarkdownListItem";

export interface TabMarkdownsProps {
  data?: Markdown[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  activeMarkdownId?: string;
  searchText: string;
  setSearchText: (search: string) => void;
  onGoToPage: (value: number) => void;
  onUpdateCustomerMarkdown: (item: Markdown) => void;
}

export function TabMarkdowns({
  data,
  currentPage,
  lastPage,
  activeMarkdownId,
  searchText,
  setSearchText,
  onGoToPage,
  onUpdateCustomerMarkdown,
}: TabMarkdownsProps) {
  const listRenderer = useCallback(
    (item: Markdown) => (
      <MarkdownListItem
        data={item}
        checked={activeMarkdownId === item.uuid}
        onSelectMarkdown={onUpdateCustomerMarkdown}
        key={item.uuid}
      />
    ),
    [activeMarkdownId, onUpdateCustomerMarkdown]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Markdowns</H4>
          </Col>
          <Col sz="auto">
            <SearchBar
              search={searchText}
              onSearchChange={setSearchText}
              placeholder="Encontrar"
            />
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhum markdown corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui markdowns cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
}
