import { GroupedOrderHistory } from "~/application/types";
import { api } from "~/infrastructure/api";
import { OrderHistoryDTO } from "~/infrastructure/api/dtos/OrderHistoryDTO";
import { mapOrderHistoryDTO } from "~/infrastructure/api/mappers/mapOrderHistoryDTO";
import { IOrderHistoryService } from "./IOrderHistoryService";

export class OrderHistoryService implements IOrderHistoryService {
  async get(orderId: string): Promise<GroupedOrderHistory[]> {
    const url = `/orders/${orderId}/history`;
    return await api
      .get<{ data: Record<string, OrderHistoryDTO[]> }>(url)
      .then(({ data: response }) => {
        return Object.entries(response.data).map(([key, history]) => ({
          date: key,
          history: history.map(mapOrderHistoryDTO),
        }));
      });
  }
}
