import { SVGProps } from "react";

export const SvgArrowSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="send">
      <mask
        id="mask0_1540_58574"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect id="Bounding box" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1540_58574)">
        <path
          id="send_2"
          d="M2 14.8753V3.76166L14.6667 9.31845L2 14.8753ZM3.33333 12.7915L11.2333 9.31845L3.33333 5.84546V8.27656L7.33333 9.31845L3.33333 10.3604V12.7915Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);
