import * as React from "react";
import { SVGProps } from "react";

const SvgDollarSign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 1a1 1 0 0 0-2 0h2ZM6 23a1 1 0 1 0 2 0H6Zm6-17a1 1 0 1 0 0-2v2ZM1 8.5H0h1ZM4.5 12v1-1Zm5 0v-1 1Zm0 7v1-1ZM1 18a1 1 0 1 0 0 2v-2ZM6 1v22h2V1H6Zm6 3H4.5v2H12V4ZM4.5 4a4.5 4.5 0 0 0-3.182 1.318l1.414 1.414A2.5 2.5 0 0 1 4.5 6V4ZM1.318 5.318A4.5 4.5 0 0 0 0 8.5h2a2.5 2.5 0 0 1 .732-1.768L1.318 5.318ZM0 8.5a4.5 4.5 0 0 0 1.318 3.182l1.414-1.414A2.5 2.5 0 0 1 2 8.5H0Zm1.318 3.182A4.5 4.5 0 0 0 4.5 13v-2a2.5 2.5 0 0 1-1.768-.732l-1.414 1.414ZM4.5 13h5v-2h-5v2Zm5 0a2.5 2.5 0 0 1 1.768.732l1.414-1.414A4.5 4.5 0 0 0 9.5 11v2Zm1.768.732A2.5 2.5 0 0 1 12 15.5h2a4.5 4.5 0 0 0-1.318-3.182l-1.414 1.414ZM12 15.5a2.5 2.5 0 0 1-.732 1.768l1.414 1.414A4.5 4.5 0 0 0 14 15.5h-2Zm-.732 1.768A2.5 2.5 0 0 1 9.5 18v2a4.5 4.5 0 0 0 3.182-1.318l-1.414-1.414ZM9.5 18H1v2h8.5v-2Z"
      fill="#079455"
    />
  </svg>
);

export default SvgDollarSign;
