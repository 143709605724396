import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { OrderAdvance, OrderStatus } from "~/application/types";
import {
  customerEmployeeService,
  customerOrderService,
  orderAdvanceService,
} from "~/application/usecases";
import { employeeToTraveler } from "~/application/usecases/Traveler/TravelerService";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { AdvanceDialog } from "../../components/AdvanceDialog";
import { groupCustomerOrderByDate } from "../../utils";
import { AdvancesContainer } from "./AdvancesContainer";
import { ConfirmCreateAdvanceDialog } from "./components/ConfirmCreateAdvanceDialog";
import { useAdvancesTab } from "./hooks/useAdvancesTab";
import { ADVANCES_MENU_TABS } from "./utils";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { logError } from "~/presentation/shared/utils/errors";

const LOG_TAG = "Order/AdvancesPage";

const SNACKBAR_MESSAGES = {
  CREATE_SUCCESS_MESSAGE: "Novo adiantamento adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar adiantamento",
  LOAD_ERROR_MESSAGE: "Falha ao listar adiantamentos",
} as const;

const DEFAULT_FORM_DATA: Partial<OrderAdvance> = {
  travelers: [
    {
      value: "",
      description: "",
    },
  ] as any,
};

export function AdvancesPage() {
  const { user } = useUser();
  const tabValue = useAdvancesTab();

  const customerId = user.customer.uuid;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching } = useQuery(
    [
      QueryKeys.ORDER_ADVANCE,
      customerId,
      currentPage,
      tabValue,
      { expenseOnly: true },
    ],
    async () => {
      return await customerOrderService.find({
        customerId,
        page: currentPage,
        expenseOnly: true,
        status: [
          ADVANCES_MENU_TABS.find((tab) => tab.id === tabValue)
            ?.status as OrderStatus,
        ],
      });
    },
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: true,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateCreateOrderAdvance } = useMutation(
    (item: OrderAdvance) => orderAdvanceService.create(item),
    {
      onMutate: () => {
        dialogService.showDialog(
          <LoadingDialog message="Criando adiantamento" />
        );
      },
      onSuccess: (_, item) => {
        log.i(
          LOG_TAG,
          `Successfully created OrderAdvance(orderId: ${item.orderId})`
        );

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_ORDERS, customerId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => {
        dialogService.popAll();
      },
    }
  );

  const fetchTravelers = useCallback(
    () =>
      customerEmployeeService
        .find({ customerId, page: 1 })
        .then(({ data }) =>
          data.map((e) => employeeToTraveler(user.customer, e))
        ),
    [user, customerId]
  );

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <ConfirmCreateAdvanceDialog
        onConfirm={() => {
          dialogService.popDialog();

          dialogService.showDialog(
            <AdvanceDialog
              onSubmit={mutateCreateOrderAdvance}
              defaultData={DEFAULT_FORM_DATA}
              fetchTravelers={fetchTravelers}
            />
          );
        }}
      />
    );
  }, [customerId, fetchTravelers, mutateCreateOrderAdvance]);

  const advanceSummaryStatus = useMemo(() => {
    return data?.data.summary.byStatus.map((s) => {
      const menuTab = ADVANCES_MENU_TABS.find(
        (tab) => tab.status === s.status
      )!;

      return {
        id: menuTab?.id,
        total: s.total,
        title: menuTab?.title,
      };
    });
  }, [data?.data.summary]);

  const advancesGroupsList = useMemo(
    () => data?.data.orders && groupCustomerOrderByDate(data.data.orders),
    [data?.data.orders]
  );

  return (
    <AdvancesContainer
      isLoading={isFetching}
      advancesGroupsList={advancesGroupsList}
      activeTab={tabValue}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      onGoToPage={setCurrentPage}
      onCreateAdvance={handleOpenCreateModal}
      tabSummary={advanceSummaryStatus}
    />
  );
}
