import { api } from "~/infrastructure/api";
import { IActionService } from "./IActionService";
import { ActionDTO } from "~/infrastructure/api/dtos";
import { Action } from "~/application/types";
import { mapActionDTO } from "~/infrastructure/api/mappers";

export class ActionService implements IActionService {
  async findAll(): Promise<Action[]> {
    return api.get<ActionDTO[]>(`/actions`).then(({ data }) => data.map(mapActionDTO));
  }
}
