import { City } from '~/application/types';
import { CityDTO } from '../dtos';

export function mapCityDTO(data: CityDTO): City {
  return {
    uuid: data.uuid,
    name: data.name,
    country: data.country,
    state: data.state,
  };
}