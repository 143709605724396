import { FC, useCallback, useEffect, useState } from "react";
import { MaskUtils } from "~/application/utils";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgExpenses } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { Caption, Label } from "~/components/Typography";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { RoadCartItem } from "./components/RoadCartItem";
import { RoadCartUIProps } from "./types";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { dialogService } from "~/components/DialogStack";
import { RoadBudgetDialog } from "../../../hooks/useRoadBudget/components/RoadBudgetDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { RoadQuery } from "~/application/types";

export const RoadCartUI: FC<RoadCartUIProps> = ({
  goRoad,
  feesValue,
  returnRoad,
  isLastStep,
  totalValue,
  seatsQuantity,
  individualValue,
  totalTaxesValue,
  isProceedDisabled,
  roadTravelersQuantity,
  isConfirmationEnabled,
  onNextStep,
  stretches,
  onCreateRoadBooking,
}) => {
  const [isShowCartDetails, setIsShowingCartDetails] = useState(false);
  const isMobile = useMobile();
  const { roadBudget, bookingStep, roadQuery } = useRoadsPage();
  const { contexts, user } = useUser();

  const { activeBudget, state } = roadBudget;

  const typeBudget = bookingStep.currentIndex ? "arrival" : "departure";

  const handleGeneratePDF = useCallback(
    () =>
      dialogService.showDialog(
        <RoadBudgetDialog
          data={state}
          roadParams={roadQuery.data as RoadQuery}
          customer={contexts?.customer}
          user={user}
        />
      ),
    [state]
  );

  useEffect(() => {
    if (!isMobile) {
      return setIsShowingCartDetails(true);
    }
    return setIsShowingCartDetails(false);
  }, [isMobile]);

  useEffect(() => {
    if (totalValue > 0) {
      setIsShowingCartDetails(true);
    }
  }, [totalValue]);

  useEffect(() => {
    if (seatsQuantity === roadTravelersQuantity) {
      setIsShowingCartDetails((prev) => !prev);
    }
  }, [roadTravelersQuantity]);

  const onNextBookingStep = () => {
    onNextStep();
    setIsShowingCartDetails(false);
  };

  return (
    <Cart
      css={{
        "@mxlg": {
          position: "fixed",
          bottom: "0",
          display: totalValue <= 0 ? "none" : "flex",
          backgroundColor: "#003161",
          borderRadius: "0",
          pt: 0,
          height: isShowCartDetails ? "340px" : "55px",
          transition: "$slow",
          left: "0",
          width: "100%",
        },
      }}
    >
      <CartHeader>
        <Flex align="center" css={{ "@mxlg": { display: "none" } }}>
          <Icon as={SvgExpenses} css={{ mr: "$2" }} />
          <Text>Sua reserva </Text>
        </Flex>

        <Flex
          align="center"
          justify="center"
          css={{
            display: "none",
            "@mxlg": {
              width: "30%",
              margin: "0 auto",
              display: "flex",
            },
          }}
          onClick={() => setIsShowingCartDetails((prev) => !prev)}
        >
          <Box>
            <Icon
              as={isShowCartDetails ? SvgChevronDown : SvgChevronUp}
              css={{ margin: "0 auto" }}
              size="md"
            />
          </Box>
        </Flex>
      </CartHeader>

      <CardBody
        css={{
          "@mxlg": {
            width: "100%",
            pt: 0,
            margin: "0 auto",
          },
        }}
      >
        <Flex direction="column" gap="5">
          <Flex gap="4" align="center">
            <Col>
              <Label
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Valor Total
              </Label>
            </Col>
            <Text
              size="6"
              css={{
                fw: "600",
                "@mxlg": {
                  color: "White",
                },
              }}
            >
              {MaskUtils.asCurrency(totalValue)}
            </Text>
            <Flex direction="column" gap="5"></Flex>
          </Flex>

          <Flex gap="4" align="center">
            <Col>
              <Label
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Assentos
              </Label>
            </Col>
            <Text
              css={{
                "@mxlg": {
                  color: "White",
                },
              }}
              variant="darkest"
              size="2"
            >
              {seatsQuantity}
            </Text>
          </Flex>

          <Flex gap="4" align="center">
            <Col>
              <Label
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Preço por assento
              </Label>
            </Col>
            <Text
              css={{
                "@mxlg": {
                  color: "White",
                },
              }}
              variant="darkest"
              size="2"
            >
              {MaskUtils.asCurrency(individualValue)}
            </Text>
          </Flex>

          <Flex gap="4" align="center">
            <Col>
              <Label
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Taxas e encargos
              </Label>
            </Col>
            <Text
              css={{
                "@mxlg": {
                  color: "White",
                },
              }}
              variant="darkest"
              size="2"
            >
              {MaskUtils.asCurrency(totalTaxesValue)}
            </Text>
          </Flex>

          {feesValue > 0 && (
            <Flex gap="4" justify="between" align="center">
              <Caption>Taxa de serviço</Caption>
              <Text
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
                variant="darkest"
                size="2"
              >
                {MaskUtils.asCurrency(feesValue)}
              </Text>
            </Flex>
          )}
        </Flex>
      </CardBody>

      <CardBody>
        {activeBudget ? (
          <Button
            disabled={!state[typeBudget].length}
            css={{
              "@mxlg": {
                width: "80%",
                margin: "0 auto",
                mt: "$5",
              },
            }}
            onClick={isLastStep ? handleGeneratePDF : onNextBookingStep}
          >
            <Text>{isLastStep ? "Gerar PDF" : "Avançar"}</Text>
          </Button>
        ) : (
          <Button
            disabled={isProceedDisabled}
            css={{
              "@mxlg": {
                width: "80%",
                margin: "0 auto",
                mt: "$5",
              },
            }}
            onClick={isConfirmationEnabled ? onCreateRoadBooking : onNextBookingStep}
          >
            <Text>{isConfirmationEnabled ? "Efetuar reserva" : "Avançar"}</Text>
          </Button>
        )}
      </CardBody>

      <Box css={{ "@mxlg": { display: "none" } }}>
        {goRoad && <RoadCartItem title="Ida" road={goRoad} />}
        {stretches?.map((stretch, index) => (
          <RoadCartItem title={`Trecho ${index + 1}`} road={stretch.road} key={stretch.road.uuid} />
        ))}

        {returnRoad && <RoadCartItem title="Volta" road={returnRoad} />}
      </Box>
    </Cart>
  );
};

RoadCartUI.displayName = "RoadCartUI";
