import * as DialogPrimitive from "@radix-ui/react-dialog";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type DialogProps = ComponentProps<typeof DialogRoot>;
export type DialogTriggerProps = ComponentProps<typeof DialogTrigger>;
export type DialogCloseProps = ComponentProps<typeof DialogClose>;

const DialogRoot = styled(DialogPrimitive.Root, {});
export const DialogTrigger = styled(DialogPrimitive.Trigger, {});
export const DialogClose = DialogPrimitive.Close;

export function Dialog({ children, ...props }: DialogProps) {
  return (
    <DialogRoot {...props}>
      <DialogPrimitive.Portal>{children}</DialogPrimitive.Portal>
    </DialogRoot>
  );
}

Dialog.displayName = "Dialog";
