import { JudgementStatus } from "~/application/types";

export function getApprovalStatus(status: JudgementStatus): string {
  switch (status) {
    case JudgementStatus.APPROVED:
      return "Aprovado";
    case JudgementStatus.DISAPPROVED:
      return "Reprovado";
    default:
      return "Em aberto";
  }
}
