export const dayTimeOptions = [
  {
    key: "dawn",
    label: "Madrugada (00:00 - 05:59)",
    value: [0, 6],
  },
  {
    key: "morning",
    label: "Manhã (06:00 - 11:59)",
    value: [6, 12],
  },
  {
    key: "afternoon",
    label: "Tarde (12:00 - 17:59)",
    value: [12, 18],
  },
  {
    key: "night",
    label: "Noite (18:00 - 23:59)",
    value: [18, 24],
  },
];
