import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { DeepPartial, Fee } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { ContainedRadio, NumberInput, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import * as OrderUtils from "~/core/shared/utils/order.utils";

export interface FeeDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<Fee>;
  onCloseClick?: () => void;
  onSubmit: (data: Fee) => void;
}

export function FeeDialog({
  isNew,
  defaultData,
  onCloseClick,
  onSubmit,
}: FeeDialogProps) {
  const { control, formState, watch, handleSubmit } = useForm<Fee>({
    defaultValues: defaultData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { items } = watch();

  if (formState.isSubmitting) {
    return (
      <LoadingModal
        isOpen={formState.isSubmitting}
        message={isNew ? "Criando taxa" : "Editando taxa"}
      />
    );
  }

  return (
    <Container size="8" fixed>
      <Form
        onSubmit={handleSubmit(onSubmit, (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        })}
      >
        <FormDialog
          title={isNew ? "Nova taxa" : "Editar taxa"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome do fee</FieldLabel>
                  <TextInput placeholder="Digite um nome para o fee" />
                </FormControl>
              </Col>
            </Row>

            {items.map((item, index) => (
              <Fragment key={item.serviceType}>
                <Box css={{ mt: "$10", mb: "$6" }}>
                  <H5>{OrderUtils.getServiceLabel(item.serviceType)}</H5>
                </Box>

                <Row gap="6">
                  <Col sz="12">
                    <FormControl name={`items.${index}.type`} control={control}>
                      <FieldLabel>Tipo de controle</FieldLabel>

                      <Flex direction="column" gap="2">
                        <ContainedRadio value="percent">
                          <Text size="3" css={{ fw: "600" }}>
                            Percentaual
                          </Text>
                        </ContainedRadio>

                        <ContainedRadio value="fixed">
                          <Text size="3" css={{ fw: "600" }}>
                            Espécie
                          </Text>
                        </ContainedRadio>
                      </Flex>
                    </FormControl>
                  </Col>

                  <Col sz="6">
                    <FormControl
                      name={`items.${index}.value`}
                      control={control}
                      required
                    >
                      <FieldLabel>Valor</FieldLabel>
                      {item.type === "fixed" ? (
                        <NumberInput
                          prefix="R$ "
                          placeholder="Digite o valor para este serviço"
                        />
                      ) : (
                        <NumberInput
                          suffix="%"
                          placeholder="Digite o valor para este serviço"
                          fixedDecimalScale={false}
                        />
                      )}
                    </FormControl>
                  </Col>
                  <Col sz="6">
                    <FormControl
                      name={`items.${index}.offlineValue`}
                      control={control}
                      required
                    >
                      <FieldLabel>Valor offline</FieldLabel>
                      {item.type === "fixed" ? (
                        <NumberInput
                          prefix="R$ "
                          placeholder="Digite o valor para este serviço offline"
                        />
                      ) : (
                        <NumberInput
                          suffix="%"
                          placeholder="Digite o valor para este serviço offline"
                          fixedDecimalScale={false}
                        />
                      )}
                    </FormControl>
                  </Col>
                </Row>
              </Fragment>
            ))}
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
