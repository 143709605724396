import * as React from "react";
import { SVGProps } from "react";

const SvgNotBenefit = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.942 7.942a.625.625 0 1 0-.884-.884l.884.884Zm-5.884 4.116a.625.625 0 1 0 .884.884l-.884-.884Zm5 .884a.625.625 0 1 0 .884-.884l-.884.884ZM7.942 7.058a.625.625 0 1 0-.884.884l.884-.884Zm4.116 0-5 5 .884.884 5-5-.884-.884ZM16.875 10A6.875 6.875 0 0 1 10 16.875v1.25A8.125 8.125 0 0 0 18.125 10h-1.25ZM10 16.875A6.875 6.875 0 0 1 3.125 10h-1.25A8.125 8.125 0 0 0 10 18.125v-1.25ZM3.125 10A6.875 6.875 0 0 1 10 3.125v-1.25A8.125 8.125 0 0 0 1.875 10h1.25ZM10 3.125A6.875 6.875 0 0 1 16.875 10h1.25A8.125 8.125 0 0 0 10 1.875v1.25Zm2.942 8.933-5-5-.884.884 5 5 .884-.884Z" />
  </svg>
);

export default SvgNotBenefit;
