import { CustomerSettingParameter } from "~/application/types";
import { SettingParameterTypeValue } from "~/application/types/entities/SettingParameter.type";
import { Switch } from "~/components/Input";
import {
  DataColItem,
  DataListItem,
  DataListItemProps,
} from "~/components/List/DataListItem";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { EditSettingParameter } from "~/core/shared/components/EditSettingParameter";

export interface CustomerSettingParameterListItemProps extends DataListItemProps {
  data: CustomerSettingParameter;
  isUpdating: boolean;
  onEditClick: (item: CustomerSettingParameter) => void;
}

export function CustomerSettingParameterListItem({
  data,
  onEditClick,
  isUpdating,
  ...props
}: CustomerSettingParameterListItemProps) {
  if (isUpdating) {
    return (
      <LoadingModal
        css={{ width: "100%" }}
        isOpen={isUpdating}
        message={"Editando parâmetro"}
      />
    );
  }

  const getDefaultData = () => {
    if (data.value === null) {
      return "Não definido";
    }

    if (data.typeValue === SettingParameterTypeValue.BOOLEAN) {
      return (
        <Switch checked={!!data.value} onClick={() => onEditClick(data)}>
          <Caption>{data.value ? "Ativo" : "Inativo"}</Caption>
        </Switch>
      );
    }

    if (
      data.typeValue === SettingParameterTypeValue.PERCENTAGE
    ) {
      return `${data.value}%`;
    }

    return data.value;
  };

  return (
    <DataListItem {...props}>
      <DataColItem
        headerTitle="Descrição"
        data={data.description}
      />

      {data.typeValue === SettingParameterTypeValue.BOOLEAN ? (
        getDefaultData()
      ) : (
        <Tag variant="info-light">
          <Text size="2">{getDefaultData()}</Text>
        </Tag>
      )}

      {(data.typeValue !== SettingParameterTypeValue.BOOLEAN) && (
        <EditSettingParameter data={data} onEditClick={onEditClick} />
      )}
    </DataListItem>
  );
}

CustomerSettingParameterListItem.displayName = "CustomerSettingParameterListItem";
