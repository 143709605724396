import { Helmet } from "react-helmet";
import { HeadProps } from "./types";

export const Head = ({ colorScheme = "light" }: HeadProps) => {
  return (
    <Helmet>
      <html color-scheme={colorScheme} />
    </Helmet>
  );
};

Head.displayName = "Head";
