export const routes = {
  root: "/",
  path: "/",
  Auth: {
    Login: {
      root: "/entrar",
      path: "entrar",
    },
  },
  Booking: {
    root: "/busca",
    path: "busca",
    route: "busca/*",
    Airway: {
      root: "/busca/passagens-aereas",
      path: "passagens-aereas",
      Query: {
        root: "/busca/passagens-aereas",
        path: "/",
      },
      Result: {
        root: "/busca/passagens-aereas/resultados",
        path: "resultados",
      },
    },
    Vehicle: {
      root: "/busca/alugar-carros",
      path: "alugar-carros",
      Query: {
        root: "/busca/alugar-carros",
        path: "/",
      },
      Result: {
        root: "/busca/alugar-carros/resultados",
        path: "resultados",
      },
    },
    Road: {
      root: "/busca/passagens-onibus",
      path: "passagens-onibus",
      Query: {
        root: "/busca/passagens-onibus",
        path: "/",
      },
      Result: {
        root: "/busca/passagens-onibus/resultados",
        path: "resultados",
      },
    },
    Hotel: {
      root: "/busca/hospedagens",
      path: "hospedagens",
      Query: {
        root: "/busca/hospedagens",
        path: "/",
      },
      Result: {
        root: "/busca/hospedagens/resultados",
        path: "resultados",
      },
      Details: {
        root: "/busca/hospedagens/detalhes",
        path: "detalhes",
      },
    },
    OfflineHotel: {
      root: "/busca/hospedagens-offline",
      path: "hospedagens-offline",
      Query: {
        root: "/busca/hospedagens-offline",
        path: "/",
      },
      Result: {
        root: "/busca/hospedagens-offline/resultados",
        path: "resultados",
      },
      Details: {
        root: "/busca/hospedagens-offline/detalhes",
        path: "detalhes",
      },
    },
  },
  Agencies: {
    root: "/agencias",
    route: "agencias/*",
    path: "agencias",
    Orders: {
      root: "/agencias/pedidos",
      path: "pedidos",
    },
  },
  Orders: {
    root: "/pedidos",
    path: "pedidos",
    route: "pedidos/*",
    CustomerOrders: {
      root: "/pedidos/meus-pedidos",
      path: "meus-pedidos",
      Open: {
        root: "/pedidos/meus-pedidos/abertos",
        path: "abertos",
      },
      Approved: {
        root: "/pedidos/meus-pedidos/aprovados",
        path: "aprovados",
      },
      Waiting: {
        root: "/pedidos/meus-pedidos/aguardando-aprovacao",
        path: "aguardando-aprovacao",
      },
      Canceled: {
        root: "/pedidos/meus-pedidos/cancelados",
        path: "cancelados",
      },
    },
    Advances: {
      root: "/pedidos/adiantamentos",
      path: "adiantamentos",
    },
    PendingOrders: {
      root: "/pedidos/pedidos-pendentes",
      path: "pedidos-pendentes",
    },
    Order: {
      root: "/pedidos/:orderId",
      path: ":orderId",
      route: ":orderId/*",
      OrderExpenses: {
        root: "/pedidos/:orderId/despesas",
        path: "despesas",
      },
      CheapestFlights: {
        root: "/pedidos/:orderId/voos-mais-baratos",
        path: "voos-mais-baratos",
      },
      CheapestRoads: {
        root: "/pedidos/:orderId/onibus-mais-baratos",
        path: "onibus-mais-baratos",
      },
      Vouchers: {
        root: "/pedidos/:orderId/vouchers",
        path: "vouchers",
      },
    },
  },
  Dashboard: {
    root: "/dashboard",
    path: "dashboard",
    route: "dashboard/*",
  },
  Configuration: {
    root: "/configuracoes",
    path: "configuracoes",
    route: "configuracoes/*",
    Parameters: {
      root: "/configuracoes/parametros",
      path: "parametros",
      Customers: {
        root: "/configuracoes/parametros/empresas",
        path: "empresas",
      },
    },
    DashboardBI: {
      root: "/configuracoes/dashboard-bi",
      path: "dashboard-bi",
      Dashboard: {
        root: "/configuracoes/dashboard-bi",
        path: "dashboard-bi",
      },
    },
    Agencies: {
      root: "/configuracoes/agencias",
      path: "agencias",
      Agency: {
        root: "/configuracoes/agencias/:agencyId",
        path: ":agencyId",
        AgencyEmployees: {
          root: "/configuracoes/agencias/:agencyId/administradores",
          path: "administradores",
        },
      },
    },
    Customers: {
      root: "/configuracoes/empresas",
      path: "empresas",
      Customer: {
        root: "/configuracoes/empresas/:customerId",
        path: ":customerId",
        Payments: {
          root: "/configuracoes/empresas/:customerId/pagamentos",
          path: "pagamentos",
        },
        Credentials: {
          root: "/configuracoes/empresas/:customerId/credenciais",
          path: "credenciais",
        },
        Credits: {
          root: "/configuracoes/empresas/:customerId/creditos",
          path: "creditos",
        },
        Branches: {
          root: "/configuracoes/empresas/:customerId/filiais",
          path: "filiais",
        },
        ApprovalModels: {
          root: "/configuracoes/empresas/:customerId/modelos-de-aprovacao",
          path: "modelos-de-aprovacao",
        },
        CostCenters: {
          root: "/configuracoes/empresas/:customerId/centros-de-custo",
          path: "centros-de-custo",
        },
        CustomerEmployees: {
          root: "/configuracoes/empresas/:customerId/funcionarios",
          path: "funcionarios",
        },
        PoliciesExpenses: {
          root: "/configuracoes/empresas/:customerId/politicas-de-despesas",
          path: "politicas-de-despesas",
        },
        ReasonTrips: {
          root: "/configuracoes/empresas/:customerId/motivos-de-viagem",
          path: "motivos-de-viagem",
        },
        Justifications: {
          root: "/configuracoes/empresas/:customerId/justificativas",
          path: "justificativas",
        },
        ExpenseTypes: {
          root: "/configuracoes/empresas/:customerId/tipos-de-despesas",
          path: "tipos-de-despesas",
        },
        Fee: {
          root: "/configuracoes/empresas/:customerId/taxa",
          path: "taxas",
        },
        Markup: {
          root: "/configuracoes/empresas/:customerId/markups",
          path: "markup",
        },
        Markdown: {
          root: "/configuracoes/empresas/:customerId/markdown",
          path: "markdown",
        },
        Projects: {
          root: "/configuracoes/empresas/:customerId/projetos",
          path: "projetos",
        },
      },
    },
    ExpenseCategories: {
      root: "/configuracoes/categorias-de-despesas",
      path: "categorias-de-despesas",
    },
    Fees: {
      root: "/configuracoes/taxas",
      path: "taxas",
    },
    Credentials: {
      root: "/configuracoes/credenciais",
      path: "credenciais",
    },
    CreditCards: {
      root: "/configuracoes/cartoes-creditos",
      path: "cartoes-creditos",
    },
    Reports: {
      root: "/configuracoes/relatorios",
      path: "relatorios",
    },
    Markups: {
      root: "/configuracoes/markups",
      path: "markups",
    },
    Markdowns: {
      root: "/configuracoes/markdowns",
      path: "markdowns",
    },
    Branches: {
      root: "/configuracoes/filiais",
      path: "filiais",
    },
    ApprovalModels: {
      root: "/configuracoes/modelos-de-aprovacao",
      path: "modelos-de-aprovacao",
    },
    CostCenters: {
      root: "/configuracoes/centros-de-custo",
      path: "centros-de-custo",
    },
    Projects: {
      root: "/configuracoes/projetos",
      path: "projetos",
      Project: {
        root: "/configuracoes/projetos/:projectId",
        path: ":projectId",
        Phases: {
          root: "/configuracoes/projetos/:projectId/fases",
          path: "fases",
        },
      },
    },
    Budgets: {
      root: "/configuracoes/orcamentos",
      path: "orcamentos",
    },
    PurchasingPolicies: {
      root: "/configuracoes/politicas-de-compra",
      path: "politicas-de-compra",
    },
    CustomerEmployees: {
      root: "/configuracoes/funcionarios",
      path: "funcionarios",
    },
    PoliciesExpenses: {
      root: "/configuracoes/politicas-de-despesas",
      path: "politicas-de-despesas",
    },
    ReasonTrips: {
      root: "/configuracoes/motivos-de-viagem",
      path: "motivos-de-viagem",
    },
    Justifications: {
      root: "/configuracoes/justificativas",
      path: "justificativas",
    },
    ExpenseTypes: {
      root: "/configuracoes/tipos-de-despesas",
      path: "tipos-de-despesas",
    },
    OfflineHotels: {
      root: "/configuracoes/hoteis-offline",
      path: "hoteis-offline",
      RegisterOfflineHotel: {
        root: "/configuracoes/hoteis-offline/cadastrar",
        path: "cadastrar",
      },
      OfflineHotel: {
        root: "/configuracoes/hoteis-offline/:offlineHotelId",
        path: ":offlineHotelId",
        EditOfflineHotel: {
          root: "/configuracoes/hoteis-offline/:offlineHotelId/editar",
          path: "editar",
        },
        OfflineHotelRoom: {
          root: "/configuracoes/hoteis-offline/:offlineHotelId/quartos",
          path: "quartos",
        }
      }
    },
  },
  Expenses: {
    root: "/despesas",
    path: "despesas",
    route: "despesas/*",
  },
} as const;
