import * as React from "react";
import { SVGProps } from "react";

const SvgAirplane = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M10.01 1.25a2.499 2.499 0 0 0-2.5 2.5L7.5 7.113l-5.904 2.953a.625.625 0 0 0-.346.559v2.5a.625.625 0 0 0 .748.613l5.502-1.1v1.477l-1.066 1.069a.625.625 0 0 0-.184.441v2.5a.625.625 0 0 0 .857.58L10 17.55l2.893 1.156a.625.625 0 0 0 .857-.58v-2.5a.625.625 0 0 0-.18-.44l-1.052-1.066v-1.48l5.502 1.1a.625.625 0 0 0 .748-.614v-2.5a.625.625 0 0 0-.346-.559l-5.904-2.953-.008-3.363a2.5 2.5 0 0 0-2.5-2.5Zm0 1.25a1.252 1.252 0 0 1 1.25 1.25.625.625 0 0 0 0 .002l.008 3.75a.625.625 0 0 0 .345.557l5.905 2.953v1.35l-5.502-1.1a.625.625 0 0 0-.748.613v2.5a.625.625 0 0 0 .18.44L12.5 15.88v1.32l-2.268-.906a.625.625 0 0 0-.464 0L7.5 17.2v-1.316l1.066-1.069a.625.625 0 0 0 .184-.441v-2.5a.625.625 0 0 0-.748-.613l-5.502 1.1v-1.35l5.904-2.953a.625.625 0 0 0 .346-.557l.008-3.75a.625.625 0 0 0 .002-.002 1.25 1.25 0 0 1 1.25-1.25Z"
    />
  </svg>
);

export default SvgAirplane;
