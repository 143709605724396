import { SettingParameterContainer } from "./SettingParametersContainer";
import { useSettingParameters } from "./hooks/useSettingParameters";

export function SettingParametersPage() {
  const {
    customerSettingParameters,
    isLoadingCustomerSettingParameters,
    isUpdatingCustomerSettingParameter,
    onEditClick,
  } = useSettingParameters();

  return (
    <SettingParameterContainer
      isLoading={isLoadingCustomerSettingParameters}
      data={customerSettingParameters}
      onEditClick={onEditClick}
      isUpdatingCustomerSettingParameter={isUpdatingCustomerSettingParameter}
    />
  );
}
