import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { dialogService } from "~/components/DialogStack";
import { paymentMethodService } from "~/application/usecases/PaymentMethod";
import { queryClient } from "~/services/queryClient";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { BindPaymentMethodsProps } from "~/application/usecases/PaymentMethod/types";
import { logError } from "~/presentation/shared/utils/errors";
import { OrderItems, PaymentMethod } from "~/application/types";
import { BindPaymentMethodDialog } from "../views/Payments/components/BindPaymentMethodDialog";
import { QueryTimes } from "~/constants";

export interface UsePaymentMethodOptions {
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/usePaymentMethods";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar métodos de pagamentos",
  CREATE_SUCCESS_MESSAGE: "Método de pagamento vinculada com sucesso",
  CREATE_ERROR_MESSAGE: "Falha ao vincular método de pagamento",
  UPDATE_SUCCESS_MESSAGE: "Método de pagamento atualizada com sucesso",
  UPDATE_ERROR_MESSAGE: "Erro ao vincular método de pagamento",
} as const;

export function usePaymentMethods({ customerId }: UsePaymentMethodOptions) {
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useQuery<PaymentMethod[]>(
    [QueryKeys.PAYMENT_METHODS],
    () => paymentMethodService.findAll(),
    {
      staleTime: QueryTimes.NORMAL,
      onError: (error: any) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        })
      },
    }
  );

  const { data, isLoading } = useQuery<PaymentMethod[]>(
    [QueryKeys.PAYMENT_METHODS, customerId],
    () => paymentMethodService.find({ customerId }),
    {
      staleTime: QueryTimes.NORMAL,
      onError: (error: any) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        })
      },
    }
  );

  const { mutate: mutateBindPaymentMethod } = useMutation(
    async (data: Omit<BindPaymentMethodsProps, "customerId">) => paymentMethodService.bind({ ...data, customerId }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message="Vinculando método de pagamento" />
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PAYMENT_METHODS, customerId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        })
      },
      onSettled: () => dialogService.popAll(),
    }
  );


  const { mutate: mutateUpdatePaymentMethod } = useMutation(
    async (data: Omit<BindPaymentMethodsProps, "customerId">) => paymentMethodService.update({ ...data, customerId }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message="Atualizando método de pagamento" />
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PAYMENT_METHODS, customerId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        })
      },
      onSettled: () => dialogService.popAll(),
    }
  );
  
  const serviceTypes = [
    { uuid: OrderItems.ROAD, description: 'Rodoviário' },
    { uuid: OrderItems.AIRWAY, description: 'Aéreo' },
    { uuid: OrderItems.HOTEL, description: 'Hotel' },
    { uuid: OrderItems.VEHICLE, description: 'Veículo' },
  ]

  const onCreatePaymentMethod = useCallback(() => {
    const services = serviceTypes.filter(
      (item) => !data?.some((data) => data.serviceType === item.uuid)
    )

    dialogService.showDialog(
      <BindPaymentMethodDialog
        isNew
        serviceTypes={services}
        isLoading={isLoadingPaymentMethods}
        data={paymentMethods}
        onSubmit={mutateBindPaymentMethod}
      />
    );
  }, [isLoadingPaymentMethods, data, paymentMethods, serviceTypes, mutateBindPaymentMethod,]);

  const onUpdatePaymentMethod = useCallback((item: PaymentMethod) => {
    dialogService.showDialog(
      <BindPaymentMethodDialog
        onSubmit={mutateUpdatePaymentMethod}
        isLoading={isLoadingPaymentMethods}
        serviceTypes={serviceTypes}
        defaultData={item}
        data={paymentMethods}
      />
    );
  }, [paymentMethods, isLoadingPaymentMethods, serviceTypes, mutateBindPaymentMethod]);

  return {
    data,
    isLoading: isLoading,
    onCreatePaymentMethod,
    onUpdatePaymentMethod
  };
}
