import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { offlineHotelAmenityService } from "~/application/usecases/OfflineHotelAmenityService";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { useBookingOfflineHotel } from "../../contexts/BookingOfflineHotelContext";
import { useOfflineHotelParams } from "../../hooks/useOfflineHotelParams";
import { OfflineHotelQueryContainer } from "./OfflineHotelQueryContainer";
import {
  CustomerEmployeeDialog,
  EditableCustomerEmployee,
} from "~/presentation/shared/views/CustomerEmployeeDialog";
import { customerEmployeeService } from "~/application/usecases";
import { log } from "~/utils/log";
import { queryClient } from "~/services/queryClient";
import { snackbarService } from "~/components/SnackbarStack";
import { SNACKBAR_MESSAGES } from "~/presentation/CustomerSettings/pages/CustomerEmployeesPage/logic/types";
import { logError } from "~/presentation/shared/utils/errors";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useCallback, useEffect, useState } from "react";
import { DEFAULT_FORM_DATA } from "~/core/modules/Customer/pages/CustomerPage/hooks/useCustomerEmployees";
import { CustomerEmployee, DeepPartial, OfflineHotelQuery } from "~/application/types";
import { fetchCostCenters } from "~/application/usecases/CostCenter/utils";
import { fetchProfile } from "~/application/usecases/Profile/utils";

const LOG_TAG = "Booking/OfflineHotelQueryPage";

export function OfflineHotelQueryPage() {
  const defaultQuery = useOfflineHotelParams();
  const {
    onRequestOfflineHotel,
    fetchTravelersByName,
    isRequestingOfflineHotel,
    setQuantityRooms,
    setIssuer,
  } = useBookingOfflineHotel();

  const { user, contexts } = useUser();

  const [query, setQuery] = useState<DeepPartial<OfflineHotelQuery>>(defaultQuery || {});

  const customerId = user.customer?.uuid || contexts.customer?.uuid;

  const { data: amenities, isLoading: isLoadingAmenities } = useQuery(
    [QueryKeys.AMENITIES],
    () => offlineHotelAmenityService.getAll(),
    {
      staleTime: QueryTimes.NORMAL,
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    }
  );

  const { data: travelers } = useQuery(
    [QueryKeys.TRAVELERS, customerId, contexts],
    async () => await customerEmployeeService.find({ customerId, isActive: "Ativo" }),
    {
      staleTime: QueryTimes.NORMAL,
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    }
  );

  const { data: issuers } = useQuery(
    [QueryKeys.ISSUERS, customerId],
    async () =>
      await customerEmployeeService
        .find({
          customerId,
          onlyUser: true,
          isActive: "Ativo",
        })
        .then((data) => data.data),
    {
      staleTime: QueryTimes.NORMAL,
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    }
  );

  const { mutateAsync: mutateCreateCustomerEmployee } = useMutation(
    async (item: EditableCustomerEmployee) =>
      await customerEmployeeService.create({
        ...item,
        customerId,
      }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating CustomerEmployee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created CustomerEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");

        setQuery((prev) => ({
          ...prev,
          accommodations:
            prev.accommodations?.map((accommodation) => ({
              ...accommodation,
              travelers: accommodation?.travelers?.concat(item) || [],
            })) || [],
        }));

        dialogService.popDialog();
      },
      onError: (error: { message?: string }) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const getCostCenters = useCallback(() => fetchCostCenters({ customerId }), [customerId]);

  const fetchIssuers = useCallback(
    async (name: string) => {
      const response = await customerEmployeeService.find({
        customerId,
        name,
        onlyUser: true,
        isActive: "Ativo",
      });
      return response.data;
    },
    [customerId]
  );

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <CustomerEmployeeDialog
        customerId={customerId}
        isNew
        fetchProfile={fetchProfile}
        user={user}
        onSubmit={mutateCreateCustomerEmployee}
        data={DEFAULT_FORM_DATA as CustomerEmployee}
        fetchCostCenters={getCostCenters}
      />
    );
  }, [mutateCreateCustomerEmployee, getCostCenters, customerId]);

  useEffect(() => {
    if (isRequestingOfflineHotel) {
      dialogService.showDialog(<LoadingDialog message="Solicitando hospedagem offline" />);
    } else {
      dialogService.popDialog();
    }
  }, [isRequestingOfflineHotel]);

  return (
    <OfflineHotelQueryContainer
      amenities={amenities}
      isLoadingAmenities={isLoadingAmenities}
      defaultTravelers={travelers?.data}
      defaultQuery={query as OfflineHotelQuery}
      onSubmit={onRequestOfflineHotel}
      fetchTravelersByName={fetchTravelersByName}
      onCreateCustomerEmployee={handleOpenCreateModal}
      setQuantityRooms={setQuantityRooms}
      fetchIssuers={fetchIssuers}
      issuer={issuers}
      setIssuer={setIssuer}
    />
  );
}
