import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { DeepPartial, Project } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { SvgKey } from "~/components/Icon/icons";
import { TextInput } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";

const projectSchema = yup.object().shape({
  name: yup.string().required("O nome do projeto é obrigatório"),
  code: yup.string().nullable(),
});

type ProjectSchema = yup.InferType<typeof projectSchema>;

export interface ReasonTripDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<Project>;
  onCloseClick?: () => void;
  onSubmit: (data: Project) => void;
}

export function ProjectDialog({
  isNew,
  defaultData,
  onCloseClick,
  onSubmit,
}: ReasonTripDialogProps) {
  const { control, formState, handleSubmit } = useForm<ProjectSchema>({
    defaultValues: defaultData,
    resolver: yupResolver(projectSchema),
  });

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit((data) => onSubmit(data as Project))} >
        <FormDialog
          title={isNew ? "Novo Projeto" : "Editar Projeto"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              {formState.isSubmitting && (
                <Spinner 
                  css={{ 
                    borderLeftColor: "$neutrals-white", 
                    width: "$4", 
                    height: "$4", 
                    borderWidth: "2px" 
                  }} 
                />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Row gap="6">
              <Col sz="12">
                <FormControl name="code" control={control}>
                  <FieldLabel>Integração externa</FieldLabel>
                  <TextInput leftIcon={SvgKey} placeholder="Número do ID" />
                  {formState.errors.code && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.code.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Projeto</FieldLabel>
                  <TextInput placeholder="Digite o nome do projeto" />
                  {formState.errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
