import { ComponentProps, FC } from "react";
import { Root } from "./styled";

export type PaperProps = ComponentProps<typeof Root>;

export const Paper: FC<PaperProps> = ({ ...props }) => {
  return <Root {...props} />;
};

Paper.displayName = "Paper";
