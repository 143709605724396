import * as React from "react";
import { SVGProps } from "react";

const SvgHotels = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.5 13.125a.625.625 0 1 0 1.25 0zm8.75-6.875v-.625Zm-15.625 10a.625.625 0 1 0 1.25 0zm1.25-12.5a.625.625 0 1 0-1.25 0ZM1.25 12.5a.625.625 0 1 0 0 1.25zm16.875 3.75a.625.625 0 1 0 1.25 0zM1.25 5.625a.625.625 0 1 0 0 1.25zm7.5 7.5V6.25H7.5v6.875zm-.625-6.25h8.125v-1.25H8.125Zm8.125 0c.497 0 .974.198 1.326.55l.884-.885a3.125 3.125 0 0 0-2.21-.915Zm1.326.55c.351.35.549.828.549 1.325h1.25c0-.829-.33-1.624-.915-2.21zm.549 1.325v4.375h1.25V8.75Zm-16.25 7.5V3.75H.625v12.5zm-.625-2.5h17.5V12.5H1.25Zm16.875-.625v3.125h1.25v-3.125zm-10-7.5H1.25v1.25h6.875z" />
  </svg>
);

export default SvgHotels;
