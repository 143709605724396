import { components, OptionProps } from "react-select";
import { styled } from "~/application/theme";

const StyledOption = styled(components.Option, {
  // Reset
  boxSizing: "border-box",
  display: "flex",

  // Custom
  padding: "$3 $4",
  background: "red",
  width: "100%",
});

const SelectOptionRoot = styled("div", {
  // Reset
  all: "unset",
  display: "flex",
  position: "relative",

  // Custom
  userSelect: "none",
  fontSize: "$sm",
  lineHeight: "1",
  fontWeight: "500",
  color: "$neutrals-darkest",
  transition: "$normal",

  "&[data-disabled=true]": {
    pointerEvents: "none",
  },

  "&:hover": {
    cursor: "pointer",
    color: "$neutrals-black",
    backgroundColor: "$neutrals-lightest",
  },

  "&:active": {
    color: "$neutrals-black",
    backgroundColor: "$neutrals-light",
  },

  "&[data-checked=true]": {
    color: "$primary-base",
    backgroundColor: "$primary-light",
  },
});

export const SelectOption = (props: OptionProps) => {
  return (
    <SelectOptionRoot data-checked={props.isSelected}>
      <StyledOption {...props} />
    </SelectOptionRoot>
  );
};

SelectOption.displayName = "SelectOption";