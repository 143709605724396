import { Box } from "~/components/Box";
import { Icon } from "~/components/Icon";
import { SvgRemove } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { ComponentProps, ComponentType, forwardRef, Fragment } from "react";
import { styled } from "~/application/theme";
import { Chip } from "./Chip";

export type ChipInputProps<TChip = any> = Omit<TextInputProps, "rightIcon"> & {
  leftIcon?: ComponentType;
  onClearChip?: VoidFunction;
  chipValue?: TChip;
  chipRender?: (value: TChip) => JSX.Element;
};

type TextInputProps = Omit<ComponentProps<typeof Input>, "size"> & {
  leftIcon?: ComponentType;
  rightIcon?: ComponentType;
  size?: "sm" | "md";
};

const Input = styled("input", {
  display: "inherit",
  font: "inherit",
  fontSize: "inherit",
  lineHeight: "inherit",
  color: "currentColor",
  outline: "none",
  border: "none",
  padding: "$6",
  transition: "$fast",
  borderRadius: "$md",
  backgroundColor: "transparent",
  width: "100%",

  "&:focus": {
    boxShadow: "$focus",
  },

  "&::placeholder": {
    color: "$neutrals-dark",
  },
});

const StyledInputBase = styled("div", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  borderRadius: "$md",
  transition: "$fast",
  color: "$neutrals-darkest",
  fontSize: "$md",
  lineHeight: "$fontSizes$md",
  backgroundColor: "$neutrals-white",

  "&::after": {
    content: "",
    pointerEvents: "none",
    position: "absolute",
    inset: 0,
    margin: 0,

    borderColor: "$neutrals-light",
    borderRadius: "inherit",
    borderStyle: "solid",
    borderWidth: "1px",

    transition: "$faster",
  },

  variants: {
    disabled: {
      true: {
        opacity: "$disabled",
      },
      false: {
        "&:hover::after": {
          borderWidth: "2px",
          borderColor: "$primary-base",
        },
      },
    },
    size: {
      sm: {
        height: "$12",
        [`& ${Input}`]: {
          padding: "$4",
        },
        "& > svg": {
          left: "$4",

          [`& + ${Input}`]: {
            paddingLeft: "$10",
          },
        },
      },
      md: {
        height: "$16",
        [`& ${Input}`]: {
          padding: "$6",
        },
        "& > svg": {
          left: "$6",

          [`& + ${Input}`]: {
            paddingLeft: "$12",
          },
        },
      },
    },
  },

  "& > svg": {
    fill: "$neutrals-dark",
    pointerEvents: "none",
    position: "absolute",
  },

  [`& > ${IconButton}`]: {
    position: "absolute",
    right: "$4",
  },

  [`& ${Input} + ${Chip}`]: {
    left: "$12",
    right: "$12",
  },

  [`& ${Input}[data-size=sm] + ${Chip}`]: {
    left: "$10",
    right: "$10",
  },

  defaultVariants: {
    disabled: false,
    size: "md",
  },
});

/**
 * @deprecated this component needs to be refactored.
 */
export const ChipInput = forwardRef<
  React.ElementRef<typeof Input>,
  ChipInputProps
>(
  (
    { chipValue, chipRender, onClearChip, leftIcon, size, ...props },
    forwardedRef
  ) => {
    const isChipVisible = !!chipValue;

    return (
      <StyledInputBase css={{ overflow: "hidden" }} size={size}>
        {leftIcon && <Icon as={leftIcon} size="md" />}
        <Input
          readOnly={isChipVisible}
          data-size={size}
          {...props}
          ref={forwardedRef}
        />
        {isChipVisible && (
          <Fragment>
            {isChipVisible && chipRender?.(chipValue)}

            <Box css={{ mr: "$4" }}>
              <IconButton onClick={onClearChip} type="button" size="sm">
                <Icon as={SvgRemove} />
              </IconButton>
            </Box>
          </Fragment>
        )}
      </StyledInputBase>
    );
  }
);

ChipInput.displayName = "ChipInput";
