import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  ApiError,
  DeepPartial,
  Markup,
  MarkupPerSuplier,
  OrderItems,
  PaginatedResource,
  Provider,
} from "~/application/types";
import { markupService } from "~/application/usecases";
import { CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { Text } from "~/components/Text";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { MarkupsContainer } from "./MarkupsContainer";
import { MarkupDialog } from "./components/MarkupDialog";

const LOG_TAG = "Agency/MarkupsPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar markups",
  CREATE_SUCCESS_MESSAGE: "Markup criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar markup",
  UPDATE_SUCCESS_MESSAGE: "Markup atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar markup",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar markups",
  ACTIVATE_SUCCESS_MESSAGE: "Markup ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar markups",
  INACTIVATE_SUCCESS_MESSAGE: "Markup inativado",
  MARKUP_PER_SUPLIER_ERROR_MESSAGE: "Falha ao atualizar markups",
  MARKUP_PER_SUPLIER_CREATE_SUCCESS_MESSAGE:
    "Novo markup definido com sucesso!",
  MARKUP_PER_SUPLIER_UPDATE_SUCCESS_MESSAGE: "Markup editado com sucesso!",
} as const;

export type ItemsProvider = Exclude<OrderItems, OrderItems.ADVANCE>;

export type ProviderForm = {
  provider: Provider;
  items: {
    [key in ItemsProvider]: number;
  };
};

const DEFAULT_FORM_DATA: DeepPartial<Markup> = {
  name: "",
  items: [
    { serviceType: OrderItems.AIRWAY },
    { serviceType: OrderItems.HOTEL },
    { serviceType: OrderItems.ROAD },
    { serviceType: OrderItems.VEHICLE },
  ],
};

export type MarkupForm = {
  markup: Markup;
  provider?: ProviderForm;
  markupPerSuplier?: MarkupPerSuplier[];
  providersListFiltred?: ProviderForm[];
  isEditing?: boolean;
};

export function MarkupsPage() {
  const { user } = useUser();
  const agencyId = user.agency.uuid;

  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<PaginatedResource<Markup[]>, ApiError>(
    [QueryKeys.AGENCY_MARKUPS, agencyId, currentPage, { search }],
    () => markupService.find({ page: currentPage, name: search, agencyId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutateAsync: mutateCreateMarkup } = useMutation(
    async (item: Markup) => await markupService.create({ ...item, agencyId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating Markup(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created Markup(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_MARKUPS, agencyId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutateAsync: mutateUpdateMarkup } = useMutation(
    async (item: Markup) => await markupService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Markup(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Markup(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_MARKUPS, agencyId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const useMarkupsPerSuplier = (markupId: string) => {
    return useQuery<MarkupPerSuplier[]>(
      [QueryKeys.MARKUP_PER_SUPLIER, markupId],
      async () => await markupService.getMarkupItemsPerSuplier(markupId),
      {
        staleTime: QueryTimes.NORMAL,
        refetchOnWindowFocus: false,
        enabled: false,
        onError: (error) => {
          log.e(LOG_TAG, error);

          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
            "error"
          );
        },
      }
    );
  };

  const { mutate: mutateMarkupPerSuplier } = useMutation(
    ({
      markupId,
      providerId,
      percentValue,
      isEditing,
    }: {
      markupId: string;
      providerId: string;
      percentValue: number;
      isEditing?: boolean;
    }) =>
      markupService.updateMarkupPerSuplier({
        markupId,
        providerId,
        percentValue,
      }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Markup(${item.markupId})`);
      },
      onSuccess: (_, { isEditing, markupId }) => {
        log.i(LOG_TAG, `Successfully updated Markup(${markupId})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_MARKUPS, agencyId]);

        if (isEditing) {
          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.MARKUP_PER_SUPLIER_UPDATE_SUCCESS_MESSAGE,
            "success"
          );
        } else {
          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.MARKUP_PER_SUPLIER_CREATE_SUCCESS_MESSAGE,
            "success"
          );
        }
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.MARKUP_PER_SUPLIER_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleMarkupPerSuplier = useCallback(
    async ({ markup, provider, isEditing }: MarkupForm) => {
      if (provider) {
        Object.entries(provider.items).forEach(async ([key, value]) => {
          const percentValue = value;
          const markupId = markup.items.find(
            (item) => item.serviceType === key
          )?.uuid;

          if (!markupId) return;

          const providerId = provider.provider.providerServices.find(
            (item) => item.serviceType === key
          )?.uuid;

          if (!providerId) return;

          await mutateMarkupPerSuplier({
            markupId,
            providerId,
            percentValue,
            isEditing,
          });
        });
      }
    },
    []
  );

  const { data: providers } = useQuery<Provider[]>(
    ["providers", agencyId],
    () => markupService.allProviders(),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const providersList = providers?.map((provider) => ({
    provider,
    items: {
      [OrderItems.AIRWAY]: 0,
      [OrderItems.HOTEL]: 0,
      [OrderItems.ROAD]: 0,
      [OrderItems.VEHICLE]: 0,
      [OrderItems.HOTEL_OFFLINE]: 0
    },
  }));

  const { mutate: mutateToggleState } = useMutation(
    (item: Markup) => markupService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Markup(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Markup(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY_MARKUPS]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: Markup) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando markup"
        onConfirm={() => mutateToggleState(item)}
      >
        <CardBody>
          <Text variant="darkest">Você poderá ativa-lo novamente.</Text>
        </CardBody>
      </InactivateDialog>
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <MarkupDialog
        isNew
        onSubmit={mutateCreateMarkup}
        defaultData={DEFAULT_FORM_DATA}
      />
    );
  }, []);

  const handleOpenEditModal = useCallback((item: Markup) => {
    dialogService.showDialog(
      <MarkupDialog onSubmit={mutateUpdateMarkup} defaultData={item} />
    );
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return (
    <MarkupsContainer
      isLoading={isFetching}
      markupsList={data?.data}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      searchText={searchText}
      setSearchText={handleChangeSearch}
      onGoToPage={setCurrentPage}
      onToggleState={handleToggleState}
      onCreateMarkup={handleOpenCreateModal}
      onEditMarkup={handleOpenEditModal}
      onMarkupPerSuplier={handleMarkupPerSuplier}
      providersList={providersList ?? []}
      useMarkupsPerSuplier={useMarkupsPerSuplier}
    />
  );
}
