import { TravelerAdvance, User } from "~/application/types";
import { Avatar } from "~/components/Avatar";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronRight } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import {
  DataColItem,
  DataListItem,
  DataListItemProps,
} from "~/components/List/DataListItem";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as DateUtils from "~/utils/date.utils";
import * as MaskUtils from "~/utils/mask.utils";

export interface ExpenseListItemProps extends DataListItemProps {
  advanceOrder: TravelerAdvance;
  user: User;
}

export function ExpenseListItem({
  advanceOrder,
  user,
  ...props
}: ExpenseListItemProps) {
  return (
    <Link to={`aprovações/adiantamentos/${advanceOrder.uuid}`}>
      <DataListItem
        {...props}
        css={{
          "@mxlg": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DataColItem
          headerTitle="Data da solicitação"
          data={DateUtils.displayDate(
            advanceOrder.approvalRequestDate ?? "",
            DateUtils.DateFormats.LONG_DATE_TIME
          )}
        />
        <DataColItem
          headerTitle="Funcionário"
          data={
            <Flex align="center">
              <Avatar
                css={{ mr: "$2" }}
                src={advanceOrder.traveler.avatarUrl}
              />
              {advanceOrder.traveler.fullName}
            </Flex>
          }
        />

        <DataColItem
          css={{
            alignItems: "flex-end",
            "@mxlg": {
              alignItems: "start",
            },
          }}
          headerTitle="Despesas"
          data={
            <Text css={{ fw: "600" }} size={{ "@initial": "6", "@mxlg": "5" }}>
              {MaskUtils.asCurrency(advanceOrder?.expenses || 0)}
            </Text>
          }
        />

        <Tooltip content={<TooltipLabel>Detalhes do pedido</TooltipLabel>}>
          <IconButton
            size="md"
            css={{
              "@mxlg": {
                display: "none",
              },
            }}
          >
            <Icon as={SvgChevronRight} />
          </IconButton>
        </Tooltip>
      </DataListItem>
    </Link>
  );
}

ExpenseListItem.displayName = "ExpenseListItem";
