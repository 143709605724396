import { styled } from "~/application/theme";

export const TripSectionItemBadge = styled("span", {
  // Reset
  boxSizing: "border-box",

  // Custom
  borderRadius: "$circular",
  size: "$8",
  fontSize: "$xxs",
  fontWeight: 500,

  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "$2",

  backgroundColor: "$$badgeBackground",
  color: "$$badgeColor",
});

TripSectionItemBadge.displayName = "TripSectionItemBadge";
