import { OfflineHotelRoom } from "~/application/types";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as MaskUtils from "~/utils/mask.utils";

export interface OfflineHotelRoomListItemProps extends DataListItemProps {
  data: OfflineHotelRoom;
  onEditOfflineHotelRoom: (data: OfflineHotelRoom) => void;
}

export function OfflineHotelRoomListItem({ data, onEditOfflineHotelRoom }: OfflineHotelRoomListItemProps) {
  return (
    <DataListItem
      css={{
        "@mxlg": {
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Tipo" data={data.type} />
      <DataColItem headerTitle="Descrição" data={data.description} />
      <DataColItem headerTitle="Regime" data={data.regime} />
      <DataColItem headerTitle="Número de adultos" data={data.adultsAmount} />
      <DataColItem headerTitle="Valor" data={MaskUtils.asCurrency(data.value)} />
      <DataColItem
        headerTitle="Editar"
        data={
          <Tooltip content={<TooltipLabel>Editar quarto</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditOfflineHotelRoom(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
        }
      />
    </DataListItem>
  );
}

OfflineHotelRoomListItem.displayName = "OfflineHotelRoomListItem";
