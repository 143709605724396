import { toDate } from "~/application/utils/date-functions";
import { api } from "~/infrastructure/api";

export class TimeService {
  async now(): Promise<Date> {
    return api
      .get(`/current-time`)
      .then(({ data: response }) => toDate(response.data));
  }
}
