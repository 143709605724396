import * as React from "react";
import { SVGProps } from "react";

const SvgMystery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mystery_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={62}
      height={62}
    >
      <path fill="#D9D9D9" d="M0 .001h61.998v61.998H0z" />
    </mask>
    <g mask="url(#mystery_svg__a)">
      <path
        d="M28.417 46.499c4.994 0 9.257-1.754 12.787-5.263 3.53-3.51 5.296-7.782 5.296-12.82 0-4.994-1.765-9.256-5.296-12.787-3.53-3.53-7.793-5.295-12.787-5.295-5.037 0-9.31 1.765-12.82 5.295-3.508 3.53-5.262 7.793-5.262 12.787 0 5.038 1.754 9.31 5.263 12.82 3.509 3.509 7.782 5.263 12.82 5.263Zm0-7.75c-3.573 0-6.748-.958-9.526-2.874-2.776-1.916-4.768-4.402-5.973-7.459 1.205-3.014 3.197-5.49 5.974-7.427 2.777-1.937 5.952-2.906 9.525-2.906 3.53 0 6.684.969 9.461 2.906 2.777 1.938 4.79 4.413 6.039 7.427-1.249 3.057-3.262 5.543-6.039 7.46-2.777 1.915-5.93 2.873-9.46 2.873Zm0-3.875c2.368 0 4.542-.57 6.523-1.711 1.98-1.141 3.53-2.723 4.65-4.747a12.531 12.531 0 0 0-4.65-4.714c-1.98-1.163-4.155-1.744-6.523-1.744-2.41 0-4.607.581-6.587 1.744a12.531 12.531 0 0 0-4.65 4.714c1.12 2.024 2.67 3.606 4.65 4.747 1.98 1.14 4.176 1.711 6.587 1.711Zm0-2.583c1.077 0 1.991-.366 2.745-1.098.753-.732 1.13-1.657 1.13-2.777 0-1.076-.377-1.991-1.13-2.744-.753-.754-1.668-1.13-2.745-1.13-1.12 0-2.045.376-2.777 1.13-.732.753-1.098 1.668-1.098 2.744 0 1.12.366 2.045 1.098 2.777.732.732 1.658 1.098 2.777 1.098Zm0 19.375c-3.229 0-6.253-.614-9.073-1.841-2.82-1.227-5.275-2.885-7.363-4.973S8.236 40.31 7.01 37.49c-1.228-2.82-1.841-5.845-1.841-9.074 0-3.186.613-6.189 1.84-9.009 1.228-2.82 2.885-5.285 4.973-7.394 2.088-2.11 4.542-3.778 7.363-5.005 2.82-1.227 5.844-1.841 9.073-1.841 3.186 0 6.19.614 9.01 1.84 2.82 1.228 5.284 2.896 7.394 5.006 2.11 2.11 3.778 4.574 5.005 7.394 1.227 2.82 1.84 5.823 1.84 9.01 0 2.755-.452 5.36-1.356 7.814a24.448 24.448 0 0 1-3.745 6.716l10.268 10.268-3.681 3.617-10.269-10.204a23.808 23.808 0 0 1-6.716 3.714c-2.454.882-5.037 1.324-7.75 1.324Z"
        fill="#0064C5"
      />
    </g>
  </svg>
);

export default SvgMystery;
