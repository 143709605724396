import { OfflineHotel } from "~/application/types";
import { states } from "~/application/data";
import { OfflineHotelDTO } from "../dtos";

export function mapOfflineHotelDTO(data: OfflineHotelDTO): OfflineHotel {
  const state = states.find((s) => s.state === data.state)!;

  return {
    uuid: data.uuid,
    agencyUuid: data.agency_uuid,
    integrationCode: data.integration_code,
    description: data.description,
    obs: data.obs,
    cnpj: data.cnpj ? data.cnpj.replace(/\D/g, "") : null,
    stars: Number(data.stars),
    whatsapp: data.whatsapp.replace(/\D/g, ""),
    phone: data.phone ? data.phone.replace(/\D/g, "") : null,
    email: data.email,
    site: data.site,
    address: data.address,
    district: data.district,
    state,
    city: {
      uuid: data.city_uuid,
      name: data.city,
      state: state?.state,
      country: "",
    },
    latitude: Number(data?.latitude) ?? null,
    longitude: Number(data?.longitude) ?? null,
    imageUrl: data.image_url,
    imagesUrl: data.images_url.map((image) => ({
      uuid: image.uuid,
      imageUrl: image.image_url,
    })),
    isActive: data.is_active,
  };
}
