import { HotelQueryForm } from "~/core/modules/Booking/modules/BookingHotel/components/HotelQueryForm";
import { HotelQuery, Order } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgArrowBack, SvgSearch } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { displayDate } from "~/utils/date.utils";
import React, { useCallback, useState } from "react";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { useNavigate } from "react-router-dom";
import { useHotelBudgetResult } from "~/core/modules/Booking/modules/BookingHotel/pages/HotelDetailsPage/hooks/useHotelBudget/type";

export interface HotelBookingBarProps {
  data: HotelQuery;
  order?: Order;
  onSubmit: (data: HotelQuery) => void;
  hotelBudget: useHotelBudgetResult;
}

export function HotelBookingBar({ data, onSubmit, hotelBudget, order }: HotelBookingBarProps) {
  const [isEditable, setEditable] = useState<boolean>(false);
  const navigate = useNavigate();

  const isMobile = useMobile();

  const handleSubmit = useCallback(
    (data: HotelQuery) => {
      onSubmit({
        accommodations: data?.accommodations,
        city: data?.city,
        checkInDate: data?.checkInDate,
        checkOutDate: data?.checkOutDate,
      });
      setEditable(false);
    },
    [onSubmit]
  );

  const handleBarClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      setEditable((old) => {
        // ignore clicks while editing
        if (!old) {
          // Ignore clicks on the bar itself
          if (event.target !== event.currentTarget) {
            return true;
          }
        }

        return old;
      });
    },
    [setEditable]
  );

  return (
    <>
      {!isMobile ? (
        <Container
          css={{
            borderTop: "1px solid $neutrals-light",
            borderBottom: "1px solid $neutrals-light",
            "&::after": {
              position: "absolute",
              content: "",
              left: 0,
              width: "100%",
              bottom: 0,
              border: "0",
              borderBottomWidth: "1px",
              borderStyle: "solid",
              borderColor: "$neutrals-light",
            },

            backgroundColor: "$neutrals-white",
            "@mxlg": {
              display: "none",
            },
          }}
        >
          {isEditable ? (
            <Box css={{ pt: "$4" }}>
              <HotelQueryForm
                onSubmit={handleSubmit}
                defaultData={data}
                hotelBudget={hotelBudget}
                order={order}
              />
            </Box>
          ) : (
            <Flex align="center" gap="6" onClick={handleBarClick} css={{ py: "$4" }}>
              <Flex direction="column" gap="4">
                <Text variant="dark" size="2" css={{ fw: "700" }}>
                  Localização
                </Text>
                <Text>
                  {data.city.name}, <strong>{data.city.state}</strong>
                </Text>
              </Flex>

              <Flex direction="column" gap="4">
                <Text variant="dark" size="2" css={{ fw: "700" }}>
                  Data do checkin
                </Text>
                <Text>{displayDate(data.checkInDate as Date)}</Text>
              </Flex>

              <Flex direction="column" gap="4">
                <Text variant="dark" size="2" css={{ fw: "700" }}>
                  Data do checkout
                </Text>
                <Text>{displayDate(data.checkOutDate as Date)}</Text>
              </Flex>

              <Button variant="tertiary" css={{ ml: "auto" }}>
                <Icon as={SvgSearch} />
              </Button>
            </Flex>
          )}
        </Container>
      ) : (
        <Container css={{ backgroundColor: "#0064C5" }}>
          <Flex justify="between" align="center" css={{ height: "$20", color: "#FFF" }}>
            <Box>
              <Icon onClick={() => navigate("/")} fill="#FFF" as={SvgArrowBack} />
            </Box>
            <Box>Reserva Hotel</Box>
            <Box></Box>
          </Flex>
        </Container>
      )}
    </>
  );
}

HotelBookingBar.displayName = "HotelBookingBar";
