import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type FormProps = ComponentProps<typeof Form>;

export const Form = styled("form", {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
});
