import { FC, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Order, OrderAirwayItem } from "~/application/types";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FormDialog } from "~/components/FormDialog";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgDolar } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { getAirlineLogo } from "~/core/shared/utils";
import { TrackDots } from "~/presentation/shared/components/Track";
import { asCurrency } from "~/utils/mask.utils";
import { handleCreateFormData } from "./utils";
import { UnflownAirwayForm } from "./components/UnflownAirwayForm";
import { DropdownCollapse } from "~/components/Collapse/Collapse";
import { UnflownData } from "./type";
import useMobile from "~/presentation/shared/hooks/useMobile";

export type UnflownAirwayDialogProps = {
  data: Order;
  onSubmit: (unflowns: UnflownData[]) => void;
};

export const UnflownAirwayDialog: FC<UnflownAirwayDialogProps> = ({ data, onSubmit }) => {
  const airwayItens = data.items.airway?.flights.map((flight) => ({
    ...flight,
    passengers: flight.passengers.filter((passenger) => passenger.unflownAirway),
  })) as OrderAirwayItem[];
  const [collapsedItems, setCollapsedItems] = useState<Record<string, boolean>>({});
  const formData = useForm({ defaultValues: handleCreateFormData(airwayItens) });
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
  } = formData;
  const { fields } = useFieldArray({ control, name: "data" });
  const toggleCollapse = (id: string) => {
    setCollapsedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const isMobile = useMobile();

  const { data: formState } = watch();

  const onSubmitHandle = (data: UnflownData[]) => {
    airwayItens.forEach((airway) => {
      const airwayFiltered = data.filter((unflow) => unflow.itemId === airway.uuid);
      if (airway?.passengers?.length) {
        onSubmit(airwayFiltered);
      }
    });
  };

  return (
    <Form
      css={{
        width: !isMobile ? "700px" : "",
      }}
      onSubmit={handleSubmit(({ data }) => onSubmitHandle(data))}
    >
      <FormDialog
        css={{ width: "100%" }}
        title="Finalizar pedido"
        negativeButton={<Button variant="tertiary">Cancelar</Button>}
        positiveButton={
          <Button variant="primary" type="submit" disabled={!isDirty}>
            Salvar dados
          </Button>
        }
      >
        <Flex
          css={{
            p: "$6",
            backgroundColor: "$neutrals-lightest",
            width: "100%",
            overflow: "auto",
          }}
        >
          <Card
            css={{
              width: "100%",
              maxHeight: "800px",
            }}
          >
            <CardBody>
              {airwayItens?.map((airway) => {
                if (!airway?.passengers?.length) return;
                return (
                  <Flex direction="column" key={airway.uuid} gap="2" align="start">
                    <Flex
                      gap="2"
                      direction="column"
                      justify="between"
                      align="center"
                      css={{
                        width: "100%",
                      }}
                    >
                      <CompanyLogo
                        src={getAirlineLogo(airway.segments?.at(0)?.airline || "")}
                        size="md"
                      />
                      <Flex
                        gap="2"
                        align="center"
                        justify="around"
                        css={{
                          width: "100%",
                        }}
                      >
                        <Flex direction="column" gap="1">
                          <Text size="1" variant="darkest">
                            Saída
                          </Text>
                          <Text size="3" fw="700" variant="black">
                            {airway.segments.at(0)?.departureFlightInfo.shortAirport}
                          </Text>
                        </Flex>
                        <Flex direction="column" gap="1">
                          <Text size="1" variant="darkest">
                            Duração
                          </Text>
                          <Flex
                            direction={{ "@mxlg": "column" }}
                            align={{ "@mxlg": "center" }}
                            css={{
                              display: "none",
                              "@mxlg": {
                                width: "$20",
                                fontSize: "12px",
                                display: "flex",
                              },
                            }}
                          >
                            {airway.segments.length > 1 && (
                              <>
                                <TrackDots numberOfDots={airway.segments.length - 1} />
                                <Text css={{ "@mxlg": { mt: "$3" } }}>{`${
                                  airway.segments.length - 1
                                } conexão`}</Text>
                              </>
                            )}
                          </Flex>
                        </Flex>
                        <Flex direction="column" gap="1">
                          <Text size="1" variant="darkest">
                            Saída
                          </Text>
                          <Text size="3" fw="700" variant="black">
                            {
                              airway.segments.at(airway.segments.length - 1)?.arrivalFlightInfo
                                .shortAirport
                            }
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex
                      direction="column"
                      gap="2"
                      css={{
                        width: "100%",
                      }}
                    >
                      <Caption>Passageiros</Caption>
                      {airway.passengers.map((passenger) => (
                        <Flex css={{}} key={passenger.uuid} direction="column" gap="4">
                          <Flex
                            onClick={() => toggleCollapse(passenger.uuid)}
                            justify="between"
                            css={{
                              p: "$4",
                              border: !collapsedItems[passenger.uuid]
                                ? "1px solid $neutrals-light"
                                : "2px solid $primary-base",
                              borderRadius: "$sm",
                              cursor: "pointer",
                            }}
                          >
                            <Text>{passenger.fullName}</Text>
                            <Icon as={SvgChevronDown} variant="darkest" />
                          </Flex>
                          <DropdownCollapse isOpened={collapsedItems[passenger.uuid]}>
                            <Flex direction="column" gap="3">
                              {fields.map((field, index) => {
                                if (
                                  field.passenger.uuid === passenger.uuid &&
                                  field.tracker === airway.tracker
                                )
                                  return (
                                    <UnflownAirwayForm
                                      data={formData}
                                      field={field}
                                      index={index}
                                      key={field.id}
                                    />
                                  );
                              })}
                            </Flex>
                          </DropdownCollapse>
                        </Flex>
                      ))}
                    </Flex>
                    <Divider css={{ width: "100%", border: "1px solid $neutrals-lightest" }} />
                  </Flex>
                );
              })}
            </CardBody>
          </Card>
        </Flex>
      </FormDialog>
    </Form>
  );
};
