import { FC, useCallback } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { ApprovalModel, CostCenter } from "~/application/types";
import { Button } from "~/components/Button";
import { FormDialog } from "~/components/FormDialog";
import { Text } from "~/components/Text";
import { Card, CardBody } from "~/components/Card";
import { Form } from "~/components/Form";
import { Flex } from "~/components/Flex";
import { Divider } from "~/components/Divider";
import { LazyList } from "~/components/LazyList";
import { FormControl } from "~/components/FormControl";
import { Radio } from "~/components/Input";
import { DataListItem } from "~/components/List";
import { LinkingCostCenterData } from "./LinkingCostCenterPage";
import { Pagination } from "~/components/Pagination";
import { Container } from "~/components/Container";

export type LinkingCostCenterContainerProps = {
  formState: UseFormReturn<LinkingCostCenterData, any>;
  onCloseClick: () => void;
  onSubmit: (data: LinkingCostCenterData) => void;
  onRedirectNewApproval: () => void;
  approvalModels?: ApprovalModel[];
  currentPage: number;
  lastPage: number;
  onGoToPage: (value: number) => void;
};

export const LinkingCostCenterContainer: FC<LinkingCostCenterContainerProps> = ({
  onCloseClick,
  formState: formContent,
  onSubmit,
  approvalModels,
  onRedirectNewApproval,
  currentPage,
  lastPage,
  onGoToPage,
}) => {
  const { control, formState, handleSubmit, watch, setValue } = formContent;

  const approvalModelId = watch("approvalModelId");

  const renderAprovalModels = useCallback(
    (item: ApprovalModel) => {
      return (
        <FormControl control={control} name="approvalModelId" key={item.uuid}>
          <DataListItem
            isActive={approvalModelId === item.uuid}
            css={{
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() => setValue("approvalModelId", item.uuid)}
          >
            <Flex
              gap="5"
              css={{
                width: "100%",
              }}
            >
              <Radio value={item.uuid} />
              <Flex
                direction="column"
                gap="2"
                css={{
                  width: "100%",
                }}
              >
                <Flex align="center" justify="between">
                  <Text size="5" fw="500">
                    {item.name}
                  </Text>
                  <Flex>
                    {item.isActive ? (
                      <Text variant="sucess">Ativo</Text>
                    ) : (
                      <Text variant="error-base">Inativo</Text>
                    )}
                  </Flex>
                </Flex>
                <Divider />
                <Flex direction="column" gap="2">
                  <Text size="3" variant="darkest" fw="500">
                    Aprovadores{" "}
                  </Text>
                  <Flex gap="2" align="center">
                    {item.approvers.map((approver, index) => (
                      <Text key={approver.uuid}>
                        {approver.name}
                        {index === item.approvers.length - 1 ? "" : " -"}
                      </Text>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </DataListItem>
        </FormControl>
      );
    },
    [control, approvalModelId]
  );

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          css={{
            overflow: "hidden",
          }}
          title="Vincular o centro de custo"
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <>
              <Button disabled={formState.isSubmitting} onClick={onRedirectNewApproval}>
                <Text>{"Novo modelo"}</Text>
              </Button>
              <Button disabled={formState.isSubmitting} type="submit">
                <Text>{"Aplicar"}</Text>
              </Button>
            </>
          }
          onClickDismissButton={onCloseClick}
        >
          <CardBody
            css={{
              overflow: "auto",
              backgroundColor: "$neutrals-lightest",
            }}
          >
            <Flex direction="column" gap="2">
              <LazyList
                items={approvalModels}
                skeletonQuantity={10}
                skeletonHeight={40}
                gap="8"
                render={renderAprovalModels}
                isLoading={!approvalModels}
              />
              <Container
                css={{
                  backgroundColor: "White",
                  p: "$2",
                  borderRadius: "$md",
                }}
              >
                <Pagination
                  activePage={currentPage}
                  pagesCount={lastPage}
                  setActivePage={onGoToPage}
                />
              </Container>
            </Flex>
          </CardBody>
        </FormDialog>
      </Form>
    </Card>
  );
};
