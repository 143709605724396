import React, { ComponentProps, ComponentType, forwardRef } from "react";
import { useFormControl } from "../../FormControl/FormControlContext";
import { SwitchBase, SwitchBaseInputProps } from "../base/SwitchBase";
import { SwitchButton, SwitchButtonVariants } from "./SwitchButton";

export type SwitchHOCProps = ComponentProps<typeof SwitchBase>;

export const createSwitchHOC = <P extends SwitchHOCProps = SwitchHOCProps>(
  Wrapper: ComponentType<P>
) =>
  forwardRef<
    React.ElementRef<typeof Wrapper>,
    SwitchBaseInputProps & SwitchButtonVariants
  >(
    (
      {
        // variants
        // others props
        name: nameProp,
        disabled: disabledProp,
        onBlur: onBlurProp,
        onChange: onChangeProp,
        value: valueProp,
        ...props
      },
      forwardedRef
    ) => {
      const formControl = useFormControl();

      const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
        event
      ) => {
        if (onChangeProp) onChangeProp(event);
        if (formControl.onChange) formControl.onChange(event);
      };

      const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        if (onBlurProp) onBlurProp(event);
        if (formControl.onBlur) formControl.onBlur(event);
      };

      const name = nameProp ?? formControl.name;
      const disabled = disabledProp ?? formControl.disabled;
      const value = valueProp ?? formControl.value;
      const isDirty = !!formControl.error;

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Wrapper
          type="checkbox"
          name={name}
          disabled={disabled}
          onBlur={handleBlur}
          onChange={handleChange}
          data-dirty={isDirty}
          checked={value}
          {...props}
          render={({ isChecked, isDisabled }) => (
            <SwitchButton data-checked={isChecked} data-disabled={isDisabled} />
          )}
          ref={forwardedRef}
        />
      );
    }
  );
