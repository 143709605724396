import * as React from "react";
import { SVGProps } from "react";

const SvgClockCounterClockwise = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.625 6.25a.625.625 0 1 0-1.25 0h1.25ZM10 10h-.625c0 .223.12.43.313.541L10 10Zm2.938 2.416a.625.625 0 1 0 .624-1.082l-.624 1.082ZM5.609 8.414a.625.625 0 0 0 0-1.25v1.25Zm-3.125-.625H1.86c0 .345.28.625.625.625v-.625Zm.625-3.125a.625.625 0 0 0-1.25 0h1.25Zm2.474 9.754a.625.625 0 0 0-.885.883l.885-.883ZM5.14 5.14l.44.442h.002L5.14 5.14ZM9.375 6.25V10h1.25V6.25h-1.25Zm4.187 5.084-3.25-1.875-.624 1.082 3.25 1.875.624-1.082ZM5.61 7.164H2.484v1.25H5.61v-1.25Zm-2.5.625V4.664H1.86v3.125h1.25Zm1.59 7.512a7.5 7.5 0 0 0 3.84 2.054l.244-1.226a6.25 6.25 0 0 1-3.2-1.711l-.885.883Zm3.84 2.054a7.5 7.5 0 0 0 4.334-.425l-.478-1.155a6.25 6.25 0 0 1-3.612.354l-.244 1.226Zm4.334-.425a7.5 7.5 0 0 0 3.367-2.763l-1.04-.694a6.25 6.25 0 0 1-2.805 2.302l.478 1.155Zm3.367-2.763A7.5 7.5 0 0 0 17.504 10h-1.25a6.25 6.25 0 0 1-1.054 3.473l1.04.694ZM17.504 10a7.5 7.5 0 0 0-1.264-4.167l-1.04.694A6.25 6.25 0 0 1 16.254 10h1.25ZM16.24 5.833a7.5 7.5 0 0 0-3.367-2.763l-.478 1.155A6.25 6.25 0 0 1 15.2 6.527l1.04-.694ZM12.873 3.07a7.5 7.5 0 0 0-4.334-.425l.244 1.225a6.25 6.25 0 0 1 3.612.355l.478-1.155Zm-4.334-.425a7.5 7.5 0 0 0-3.84 2.054l.884.883a6.25 6.25 0 0 1 3.2-1.712l-.244-1.225Zm-3.84 2.053L2.043 7.346l.883.886 2.656-2.649-.883-.885Z" />
  </svg>
);

export default SvgClockCounterClockwise;
