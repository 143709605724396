import { styled } from "~/application/theme";

const Container = styled("div", {
  // Reset
  boxSizing: "border-box",
  display: "flex",

  // Custom
  alignItems: "center",
  gap: "$2",
  padding: "$2",
  borderRadius: "$md",
  transition: "$normal",
  userSelect: "none",

  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$neutrals-lightest",
  },

  "&[data-state=open]": {
    boxShadow: "$focus",
    backgroundColor: "$neutrals-lightest",
  },
});

export const Styled = {
  Container,
} as const;
