import { FC, Fragment, useCallback, useMemo } from "react";
import { Road } from "~/application/types";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Spinner } from "~/components/Spinner";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { BookingRoadListItem } from "~/presentation/Booking/BookingRoad/pages/RoadsPage/ui/views/BookingRoadListItem";
import { FiltersSection } from "~/presentation/shared/components/FiltersSection";
import { GoRoadsSectionUIProps } from "./types";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { Checkbox } from "~/components/Input";

export const GoRoadsSectionUI: FC<GoRoadsSectionUIProps> = ({
  data,
  filters,
  isAvailable,
  isLoading,
  bookingInfo,
  allRoadBudget,
  onSelectAllBookingRoad,
  onFilterChange,
  onSelectRoad,
  onOpenDetails,
}) => {
  const minimumPrice = useMemo(() => data[0]?.totalPrice, [data]);
  const { roadBudget, isManyStretch, bookingStep, roadReducer } = useRoadsPage();

  const minSeats = bookingStep.currentIndex > 0 ? bookingInfo.stretches.at(0)?.seats.length : 1;
  const maxSeats = bookingStep.currentIndex > 0 ? bookingInfo.stretches.at(0)?.seats.length : 6;

  const { activeBudget, onActiveBudget } = roadBudget;

  const listRenderer = useCallback(
    (item: Road) => {
      const currentRoad =
        roadReducer?.bookingInfo?.stretches?.at(bookingStep.currentIndex) ||
        bookingInfo.goRoadSelected;
      const currentRoadSeats = currentRoad?.road.id === item.id ? currentRoad?.seats : undefined;
      const currentStep = bookingStep.currentIndex;
      const isSelected =
        bookingInfo.goRoadSelected?.road.id === item.id ||
        bookingInfo.stretches.at(currentStep)?.road.id === item.id;

      return (
        <BookingRoadListItem
          data={item}
          currentRoadSeats={currentRoadSeats}
          minSeats={minSeats ?? 1}
          maxSeats={maxSeats ?? 6}
          isSelected={isSelected}
          onSelectRoad={onSelectRoad}
          onOpenDetails={onOpenDetails}
          isGoRoad={true}
          TagsElement={
            <Flex
              css={{
                maxHeight: "$7",
              }}
              gap="3"
              align="center"
            >
              {item.withBPE && (
                <Tag
                  css={{
                    "@mxlg": {
                      border: 0,
                      fontSize: "10px",
                    },
                  }}
                  variant="info-light"
                >
                  Bilhete eletrônico
                </Tag>
              )}

              {minimumPrice === item.totalPrice && (
                <Tag
                  variant="success-light"
                  css={{
                    "@mxlg": {
                      border: 0,
                      fontSize: "10px",
                    },
                  }}
                >
                  Melhor oferta
                </Tag>
              )}

              {item.connection && onOpenDetails && (
                <Button
                  css={{
                    "@mxlg": {
                      height: "$2",
                      p: "$3",
                      fontSize: "$sm",
                    },
                  }}
                  variant="tertiary"
                  onClick={() => onOpenDetails(item)}
                >
                  Ver detalhes
                </Button>
              )}
            </Flex>
          }
          key={item.id}
        />
      );
    },
    [
      bookingInfo.goRoadSelected,
      minimumPrice,
      onSelectRoad,
      onOpenDetails,
      minSeats,
      maxSeats,
      bookingStep,
    ]
  );

  return (
    <Row
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <Col
        sz={{ "@initial": "4", "@mxlg": "11" }}
        css={{
          "@mxlg": {
            mb: "$10",
          },
        }}
      >
        <Box>
          <FiltersSection
            isLoading={!isAvailable}
            filters={filters}
            title="Filtrar resultados"
            onFilterChange={onFilterChange}
          />
        </Box>
      </Col>

      <Col
        sz="8"
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <Flex direction="column" gap="6">
          <Flex justify="between">
            {activeBudget && (
              <Flex align="center" gap="2">
                <Checkbox checked={allRoadBudget} onChange={onSelectAllBookingRoad} />
                <Text fw="500" size="3">
                  Selecionar todos desta página
                </Text>
              </Flex>
            )}
            <Flex></Flex>
            <Button
              variant="secondary-light"
              onClick={() => onActiveBudget(!activeBudget)}
              disabled={!data.length || isManyStretch}
              css={{
                backgroundColor: activeBudget ? "$neutrals-black" : "transparent",
                color: activeBudget ? "$neutrals-white" : "$neutrals-darkest",
              }}
            >
              Cotação eletrônica
            </Button>
          </Flex>

          {isLoading && (
            <Box>
              <Alert variant="info">
                <Spinner size="sm" />
                <Text css={{ lineHeight: "$6" }}>
                  Aguarde, estamos buscando as melhores tarifas para sua passagem rodoviária
                </Text>
              </Alert>
            </Box>
          )}

          <LazyList
            gap="8"
            items={data}
            isLoading={!isAvailable}
            render={listRenderer}
            skeletonQuantity={10}
            skeletonHeight={200}
            EmptyComponent={
              <Fragment>
                {!isLoading && (
                  <EmptyState>
                    <Text>Nenhuma passagem encontrada</Text>
                  </EmptyState>
                )}
              </Fragment>
            }
          />
        </Flex>
      </Col>
    </Row>
  );
};

GoRoadsSectionUI.displayName = "GoRoadsSectionUI";
