import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type DividerProps = ComponentProps<typeof Divider>;

export const Divider = styled("hr", {
  // Reset
  boxSizing: "border-box",

  // Custom
  margin: 0,
  height: 0,
  border: "none",
  borderBottom: "1px solid",
  borderBottomColor: "$neutrals-lightest",
});
