import { createContext, useContext } from "react";
import { Profile, User, UserActions, UserContexts } from "~/application/types";

export type UserContextType = {
  user: User;
  profile?: Profile;
  contexts: UserContexts;
  userActions: UserActions;
  isLoading: boolean;
  setUserContext: (context: Partial<UserContexts>) => void;
};

export const UserContext = createContext({} as UserContextType);

export const useUser = () => useContext(UserContext);
