import { To } from "react-router-dom";

import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { CreateOfflineHotelForm } from "./components/CreateOfflineHotelForm";
import { City, OfflineHotel, State } from "~/application/types";
import { CreateOfflineHotelProps } from "~/application/usecases/OfflineHotel";

interface CreateOfflineHotelContainerProps {
  defaultData: OfflineHotel;
  isCreatingOfflineHotel: boolean;
  createOfflineHotel: (data: Omit<CreateOfflineHotelProps, "agencyId">) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function CreateOfflineHotelContainer({
  defaultData,
  createOfflineHotel,
  isCreatingOfflineHotel,
  fetchCitiesByState,
}: CreateOfflineHotelContainerProps) {
  return (
    <>
    <AppBar>
        <AppBarContainer>
          <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
            <Link to={-1 as To}>
              <IconButton size="md">
                <Icon as={SvgChevronLeft} />
              </IconButton>
            </Link>
          </Tooltip>

          <AppBarHeading title="Cadastrar Hotel Offline" />
        </AppBarContainer>
      </AppBar>

      <Container css={{ py: "$10", "@mxsm": { px: 0 } }}>
        <Card css={{ p: "$10", overflow: "visible", "@mxsm": { p: "$8" } }}>
          <CreateOfflineHotelForm
            defaultData={defaultData}
            fetchCitiesByState={fetchCitiesByState}
            createOfflineHotel={createOfflineHotel}
            isCreatingOfflineHotel={isCreatingOfflineHotel}
          />
        </Card>
      </Container>
    </>
  );
}