import { useChannel } from "../useChannel";

export interface UseCancelOrderChannelProps {
  logTag?: string;
  orderId: string;
  enabled?: boolean;
  onCancelOrder: (data: any) => void;
}

export function useCancelOrderChannel({
  logTag,
  orderId,
  enabled,
  onCancelOrder,
}: UseCancelOrderChannelProps) {
  useChannel({
    logTag,
    enabled,
    channelName: `orders.cancelled.${orderId}`,
    eventName: 'order.cancelled',
    onMessage: onCancelOrder,
  })

}
