import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type EmptyStateProps = ComponentProps<typeof EmptyState>;

export const EmptyState = styled("div", {
  // Reset
  boxSizing: "border-box",

  // Custom
  padding: "$10",
  border: "1px dashed $colors$neutrals-base",
  textAlign: "center",
  borderRadius: "$md",
  backgroundColor: "$neutrals-lightest",
  color: "$neutrals-black",
  fontSize: "$md",
});

EmptyState.displayName = "EmptyState";
