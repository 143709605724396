import { FC, useMemo } from "react";
import { Box } from "~/components/Box";
import { CardBody, CardSectionHeader } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import { formatRoadInfo } from "~/presentation/shared/utils";
import { RoadCartItemProps } from "./types";

export const RoadCartItem: FC<RoadCartItemProps> = ({ title, road }) => {
  const roadInfo = useMemo(() => formatRoadInfo(road), [road]);

  return (
    <Box>
      <CardSectionHeader>{title}</CardSectionHeader>

      <CardBody>
        <Flex align="center" gap="4" css={{ mb: "$6" }}>
          <Box css={{ flexGrow: "1" }}>
            {roadInfo.road.companyImage ? (
              <CompanyLogo src={roadInfo.road.companyImage} alt={roadInfo.road.company} size="lg" />
            ) : (
              <DefaultCompanyLogo companyName={roadInfo.road.company} />
            )}
          </Box>
        </Flex>

        <Grid gap="6" columns="2">
          <Flex direction="column" gap="4">
            <Caption>Saída</Caption>
            <Text size="4" css={{ fw: "600" }}>
              {roadInfo.departureRoadInfo.name}
            </Text>
            <Text size="3">{roadInfo.departureRoadInfo.date}</Text>
            <Caption css={{ fw: 500 }}>{roadInfo.departureRoadInfo.dateTimeHour12}</Caption>
          </Flex>

          <Flex direction="column" gap="4">
            <Caption>Chegada</Caption>
            <Text size="4" css={{ fw: 600 }}>
              {roadInfo.arrivalRoadInfo.name}
            </Text>
            <Text size="3">{roadInfo.arrivalRoadInfo.date}</Text>
            <Caption css={{ fw: 500 }}>{roadInfo.arrivalRoadInfo.dateTimeHour12}</Caption>
          </Flex>
        </Grid>
      </CardBody>
    </Box>
  );
};

RoadCartItem.displayName = "RoadCartItem";
