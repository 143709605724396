import { FC } from "react";
import { Card } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Text } from "~/components/Text";
import { H5, H6, Label } from "~/components/Typography";
import { getAirlineUrl } from "~/presentation/shared/utils";
import { DateFormats, displayDate, timeInterval, toHour12 } from "~/utils/date.utils";
import { FlightSegmentListItemProps } from "./types";

export const FlightSegmentListItem: FC<FlightSegmentListItemProps> = ({ data, airline }) => {
  return (
    <Card>
      <Grid
        gap="2"
        columns="2"
        justify="between"
        align="center"
        css={{
          padding: "$4",
          width: "100%",
          borderBottom: "solid 1px $neutrals-lightest",
        }}
      >
        <CompanyLogo
          src={getAirlineUrl(airline)}
          size="lg"
          css={{
            maxWidth: "100px",
          }}
        />

        <Flex justify="end">
          <Label>Voo N° {data.flightNumber}</Label>
        </Flex>
      </Grid>

      <Grid gap="6" columns="3" align="center" css={{ padding: "$4", width: "100%" }}>
        <Flex direction="column" gap="3" align="center">
          <Text css={{ "@mxlg": { ta: "center" } }} size="3" variant="darkest">
            {displayDate(data.departureFlightInfo.dateTime, DateFormats.SMALL_WEEK_DATE)}
          </Text>
          <H5>{toHour12(data.departureFlightInfo.dateTime)}</H5>
          <H6 css={{ "@mxlg": { ta: "center" } }} size={{ "@mxlg": "1" }} variant="dark">
            {data.departureFlightInfo.airportIata}
            {" - "}
            {data.departureFlightInfo.airport}
          </H6>
        </Flex>

        <Flex direction="row" gap="4" align="center">
          <Divider css={{ flexGrow: 1 }} />
          <Flex direction="column" align="center" gap="2">
            <Label size="2">Duração</Label>
            <Text size="2" css={{ fw: "bold" }}>
              {timeInterval(data.departureFlightInfo.dateTime, data.arrivalFlightInfo.dateTime)}
            </Text>
          </Flex>
          <Divider css={{ flexGrow: 1 }} />
        </Flex>

        <Flex direction="column" gap="3" align="center">
          <Text css={{ ta: "center" }} size="3" variant="darkest">
            {displayDate(data.arrivalFlightInfo.dateTime, DateFormats.SMALL_WEEK_DATE)}
          </Text>
          <H5>{toHour12(data.arrivalFlightInfo.dateTime)}</H5>
          <H6 css={{ "@mxlg": { ta: "center" } }} size={{ "@mxlg": "1" }} variant="dark">
            {data.arrivalFlightInfo.airportIata}
            {" - "}
            {data.arrivalFlightInfo.airport}
          </H6>
        </Flex>
      </Grid>
    </Card>
  );
};
