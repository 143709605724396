import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FormDialog } from "~/components/FormDialog";
import { Text } from "~/components/Text";
import { RoadIssuanceForm } from "./RoadIssuanceForm";
import { RoadIssuanceDialogProps, RoadIssuanceFormData } from "./types";
import { parseFormData, createFormData } from "./utils";

export const RoadIssuanceDialog: FC<RoadIssuanceDialogProps> = ({
  orderRoad,
  onCloseClick,
  providers,
  isLoading,
  onSubmit: onSubmitProp,
}) => {
  const connections = {
    connection: orderRoad.connection?.name,
    departure: orderRoad.departure,
    arrival: orderRoad.arrival,
  };
  const { control, formState, handleSubmit, watch, setValue, setError, clearErrors } =
    useForm<RoadIssuanceFormData>({
      defaultValues: createFormData({ data: orderRoad, providers }),
      reValidateMode: "onBlur",
      mode: "onSubmit",
    });
    
  const watchIssuances = watch("issuance");

  const onSubmit = useCallback(
    async (formData: RoadIssuanceFormData) => {
      const data = parseFormData(formData);

      return await onSubmitProp(data);
    },
    [onSubmitProp]
  );

  return (
    <Container size="8" fixed>
      <Form
        css={{
          "@mxlg": {
            width: "98%",
            margin: "0 auto",
          },
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormDialog
          title="Emissão de item rodoviário"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button
              disabled={formState.isSubmitting || !!formState.errors.issuance?.travelers}
              type="submit"
            >
              <Text>Emitir</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody>
            <RoadIssuanceForm
              control={control}
              providers={providers}
              isLoading={isLoading}
              formState={formState}
              orderRoad={orderRoad}
              watchIssuances={watchIssuances}
              connections={connections}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
            />
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};
