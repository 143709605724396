import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { Flight } from "~/application/types";
import { flightQueryService } from "~/application/usecases";
import { UseQueryCombinedFlightsOptions, UseQueryCombinedFlightsReturn } from "./types";

export function useQueryCombinedFlights({
  goFlight,
  flightQueryLinks,
  enabled,
  retryCount,
}: UseQueryCombinedFlightsOptions): UseQueryCombinedFlightsReturn {
  const flightQueries = useQueries({
    queries:
      flightQueryLinks?.links.map((link) => ({
        enabled: enabled && !!goFlight,
        refetchOnMount: true,
        retry: retryCount,
        retryDelay: flightQueryLinks.waitTime,
        staleTime: QueryTimes.NONE,
        queryKey: [QueryKeys.QUERY_FLIGHTS_COMBINED, link, goFlight],
        queryFn: () => {
          return flightQueryService.findCombined(link.departureArrival, {
            searchKey: goFlight!.flight.searchKey,
            travelId: goFlight!.flight.id,
            fareId: goFlight!.flightOption.id,
          });
        },
      })) ?? [],
  });

  const data = useMemo(() => {
    return flightQueries.reduce<Flight[]>((p, c) => [...p, ...(c.data ?? [])], []);
  }, [flightQueries]);

  const isLoading = useMemo(() => flightQueries.some((q) => q.isFetching), [flightQueries]);

  const isAvailable = useMemo(() => flightQueries.some((q) => !q.isFetching), [flightQueries]);

  return {
    data,
    isLoading,
    isAvailable,
  };
}
