import { ReactNode, useState } from "react";
import { TabsContext } from "./contexts/TabsContext";

export type TabsProps<TValue extends string | number> = {
  vertical?: boolean;
  children: ReactNode;
  value?: TValue;
  defaultValue?: TValue;
  setValue?: (value: TValue) => void;
};

export const Tabs = <TValue extends string | number>({
  children,
  value,
  setValue,
  vertical = false,
  defaultValue,
}: TabsProps<TValue>) => {
  const [activeValue, setActiveValue] = useState(defaultValue);

  return (
    <TabsContext.Provider
      value={{
        vertical,
        activeValue: value ?? activeValue,
        setActiveValue: setValue ?? setActiveValue,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

Tabs.displayName = "Tabs";
