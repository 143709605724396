import { DeepPartial, Road } from "~/application/types";

export type useRoadBudgetResult = {
  activeBudget: boolean;
  onActiveBudget: (active: boolean) => void;
  state: RoadBudgetState;
  dispatch?: React.Dispatch<RoadBudgetAction>;
  onSelectRoadBudget: ({ data, type }: onSelectRoadBudgetProps) => void;
};

export type onSelectRoadBudgetProps = {
  data: Road;
  type: "departure" | "arrival";
};

export type RoadBudgetState = {
  departure: DeepPartial<Road[]>;
  arrival: DeepPartial<Road[]>;
};

export enum RoadBudgetActionType {
  CLEAR = "CLEAR",
  DEPARTURE = "DEPARTURE",
  ARRIVAL = "ARRIVAL",
}

export type RoadBudgetAction =
  | {
      type: RoadBudgetActionType.DEPARTURE;
      paylod: DeepPartial<Road[]>;
    }
  | {
      type: RoadBudgetActionType.ARRIVAL;
      paylod: DeepPartial<Road[]>;
    }
  | {
      type: RoadBudgetActionType.CLEAR;
    };
