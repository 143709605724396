import { styled } from "~/application/theme";
import { Box } from "~/components/Box";

const Container = styled(Box, {
  // Reset
  boxSizing: "border-box",
  display: "flex",

  // Custom
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: "$neutrals-white",
  height: "$sizes$app-bar-height",
  gap: "$6",
  py: "$2",

  "&::after": {
    position: "absolute",
    content: "",
    top: 0,
    left: 0,
    width: "100%",
    border: "0",
    borderBottomWidth: "1px",
    borderStyle: "solid",
    borderColor: "$neutrals-light",
  },
});

export const Styled = {
  Container,
} as const;
