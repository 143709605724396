import { OrderStatus } from "~/application/types";
import { getCartOrderStatus } from "~/presentation/shared/utils";
import { SvgOrder, SvgSimpleCheck } from "~/components/Icon/icons";
import { SvgCurrencyDollar } from "~/components/Icon/icons";
import { SvgClockCountdown } from "~/components/Icon/icons";
import { SvgPaperPlaneRight } from "~/components/Icon/icons";
import { OrderStatusStep } from "./OrderStatusStep";
import { CardBody } from "~/components/Card";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";

export interface OrderStatusStepsProps {
  status: OrderStatus;
  isOfflineQuote?: boolean;
}

export function OrderStatusSteps({ status, isOfflineQuote = false }: OrderStatusStepsProps) {
  const isOpenOrQuotingStatus = [OrderStatus.OPEN, OrderStatus.QUOTING].includes(status);

  const isQuotedStatus = [OrderStatus.QUOTED].includes(status);

  const isOnApprovalStatus = [OrderStatus.ON_APPROVAL].includes(status);

  const isPendingIssueStatus = [OrderStatus.PENDING_ISSUE].includes(status);

  const isIssuedStatus = [OrderStatus.ISSUED].includes(status);

  const showOrderStatusSteps = isOpenOrQuotingStatus
    || isQuotedStatus 
    || isOnApprovalStatus 
    || isPendingIssueStatus
    || isIssuedStatus;

  if (!showOrderStatusSteps) {
    return null;
  }

  return (
    <CardBody>
      <Flex 
        align="center" 
        justify="between" 
        css={{ position: "relative", pb: 22 }}
      >
        {isOfflineQuote && (
          <>
            <OrderStatusStep 
              icon={SvgPaperPlaneRight}
              label={getCartOrderStatus(OrderStatus.QUOTING)} 
              isActive={isOpenOrQuotingStatus} 
              isCompleted={!isOpenOrQuotingStatus} 
            />

            <Divider 
              css={{ 
                flex: 1, 
                borderBottomWidth: 2, 
                borderBottomColor: isOpenOrQuotingStatus ? "$neutrals-light" : "$primary-base"  
              }} 
            />

            <OrderStatusStep 
              icon={SvgCurrencyDollar} 
              label={getCartOrderStatus(OrderStatus.QUOTED)} 
              isActive={isQuotedStatus} 
              isCompleted={isOnApprovalStatus || isPendingIssueStatus || isIssuedStatus} 
            />

            <Divider 
              css={{ 
                flex: 1, 
                borderBottomWidth: 2, 
                borderBottomColor: (isOnApprovalStatus || isPendingIssueStatus || isIssuedStatus) ? "$primary-base" : "$neutrals-light" 
              }} 
            />

            <OrderStatusStep 
              icon={SvgClockCountdown} 
              label={getCartOrderStatus(OrderStatus.ON_APPROVAL)} 
              isActive={isOnApprovalStatus} 
              isCompleted={isPendingIssueStatus || isIssuedStatus} 
            />

            <Divider 
              css={{ 
                flex: 1, 
                borderBottomWidth: 2, 
                borderBottomColor: (isPendingIssueStatus || isIssuedStatus) ? "$primary-base" : "$neutrals-light" 
              }} 
            />

            <OrderStatusStep 
              icon={SvgSimpleCheck} 
              label={getCartOrderStatus(OrderStatus.PENDING_ISSUE)} 
              isActive={isPendingIssueStatus} 
              isCompleted={isIssuedStatus} 
            />

            <Divider 
              css={{ 
                flex: 1, 
                borderBottomWidth: 2, 
                borderBottomColor: isIssuedStatus ? "$primary-base" : "$neutrals-light" 
              }} 
            />

            <OrderStatusStep 
              icon={SvgOrder} 
              label={getCartOrderStatus(OrderStatus.ISSUED)} 
              isActive={isIssuedStatus} 
              isCompleted={isIssuedStatus}
            />
          </>
        )}

        {!isOfflineQuote && (
          <>
            <OrderStatusStep 
              icon={SvgPaperPlaneRight} 
              label={getCartOrderStatus(OrderStatus.OPEN)} 
              isActive={isOpenOrQuotingStatus} 
              isCompleted={!isOpenOrQuotingStatus} 
            />

            <Divider 
              css={{ 
                flex: 1, 
                borderBottomWidth: 2, 
                borderBottomColor: isOpenOrQuotingStatus ? "$neutrals-light" : "$primary-base"  
              }} 
            />

            <OrderStatusStep 
              icon={SvgClockCountdown} 
              label={getCartOrderStatus(OrderStatus.ON_APPROVAL)} 
              isActive={isOnApprovalStatus} 
              isCompleted={isPendingIssueStatus || isIssuedStatus} 
            />

            <Divider 
              css={{ 
                flex: 1, 
                borderBottomWidth: 2, 
                borderBottomColor: (isPendingIssueStatus || isIssuedStatus) ? "$primary-base" : "$neutrals-light"  
              }} 
            />

            <OrderStatusStep 
              icon={SvgSimpleCheck} 
              label={getCartOrderStatus(OrderStatus.PENDING_ISSUE)} 
              isActive={isPendingIssueStatus} 
              isCompleted={isIssuedStatus} 
            />

            <Divider 
              css={{ 
                flex: 1, 
                borderBottomWidth: 2, 
                borderBottomColor: isIssuedStatus ? "$primary-base" : "$neutrals-light"  
              }} 
            />

            <OrderStatusStep 
              icon={SvgOrder} 
              label={getCartOrderStatus(OrderStatus.ISSUED)} 
              isActive={isIssuedStatus} 
              isCompleted={isIssuedStatus}
            />
          </>
        )}
      </Flex>
    </CardBody>
  );
}
