import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type IconProps = ComponentProps<typeof Icon>;

export const Icon = styled("svg", {
  // Reset
  boxSizing: "border-box",

  variants: {
    size: {
      smlg: {
        size: "$2",
      },
      sm: {
        size: "$4",
      },
      md: {
        size: "$5",
      },
      lg: {
        size: "$8",
      },
      xl: {
        size: "$10",
      },
      "2xl": {
        size: "$12",
      },
      "3xl": {
        size: "$16",
      },
      "4xl": {
        size: "$20",
      },
      "5xl": {
        size: "$24",
      },
      "6xl": {
        size: "$32",
      },
    },
    variant: {
      primary: {
        fill: "$primary-base",
      },
      "primary-dark": {
        fill: "$primary-dark",
      },
      black: {
        fill: "$neutrals-black",
      },
      light: {
        fill: "$neutrals-light",
      },
      dark: {
        fill: "$neutrals-dark",
      },
      darkest: {
        fill: "$neutrals-darkest",
      },
      white: {
        fill: "$neutrals-white",
      },
      warning: {
        fill: "$warning-base",
      },
      "warning-dark": {
        fill: "$warning-dark",
      },
      error: {
        fill: "$error-base",
      },
      "error-light": {
        fill: "$error-light",
      },
      success: {
        fill: "$success-base",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});
