import { AirwayWithCheapestFlightsItemType, CheapestFlight } from "~/application/types";
import { DateFormats, format, timestampInterval } from "~/application/utils/date-functions";

export enum TripSections {
  OUTBOUND_SECTION = "outbound-section",
  RETURN_SECTION = "return-section",
}

export const TRIP_MENU_SECTIONS = [
  { title: "Trecho de ida", id: TripSections.OUTBOUND_SECTION },
  { title: "Trecho de volta", id: TripSections.RETURN_SECTION },
];

export function getCheapestFlights(data: AirwayWithCheapestFlightsItemType): CheapestFlight[] {
  const orderItem: CheapestFlight = {
    flightId: data.airwayOrder.uuid,
    airline: data.airwayOrder.airline,
    segments: data.airwayOrder.segments,
    scales: data.airwayOrder.segments.length - 1,
    value: data.airwayOrder.value / data.airwayOrder.passengers.length,
    fare: data.airwayOrder.fare,
    boardingTax: data.airwayOrder.boardingTax,
    serviceTax: data.airwayOrder.fee,
    familyFare: data.airwayOrder.familyFare,
    servicesIncluded: data.airwayOrder.servicesIncluded,
  };

  if (!data.cheapestFlights) {
    return [orderItem];
  }

  const cheapestFlights = data.cheapestFlights
    .flatMap(flight =>
      flight.options
        .map(option => ({
          flightId: flight.id,
          optionId: option.id,
          airline: flight.airline,
          scales: flight.scales,
          segments: flight.segments,
          value: option.pricingInfo.total.amount,
          fare: option.pricingInfo.baseFare.amount,
          boardingTax: option.pricingInfo.taxes.find((tax) => tax.code === "EMBARQUE")?.amount,
          serviceTax: option.pricingInfo.taxes.find((tax) => tax.code === "AGENCY_FEE")?.amount,
          familyFare: option.familyFare,
          servicesIncluded: option.servicesIncluded,
        } as CheapestFlight))
    )
    .sort((a, b) => a.value - b.value);

  return [orderItem].concat(cheapestFlights);
}

export function formatFlightInfo(flight: CheapestFlight) {
  const departureInfo = flight.segments[0].departureFlightInfo;
  const arrivalInfo =
    flight.segments[flight.segments.length - 1].arrivalFlightInfo;

  return {
    flight,
    flightDuration: timestampInterval(
      departureInfo.dateTime,
      arrivalInfo.dateTime
    ),
    departureFlightInfo: {
      airportIata: departureInfo.airportIata,
      airport: departureInfo.airport,
      dateTimeHour12: format(
        departureInfo.dateTime,
        DateFormats.SMALL_TIME_AM_PM
      ),
      date: format(departureInfo.dateTime, DateFormats.LONG_DATE),
    },
    arrivalInfo: {
      airportIata: arrivalInfo.airportIata,
      airport: arrivalInfo.airport,
      dateTimeHour12: format(
        arrivalInfo.dateTime,
        DateFormats.SMALL_TIME_AM_PM
      ),
      date: format(arrivalInfo.dateTime, DateFormats.LONG_DATE),
    },
  };
}
