import { Judgement } from "~/application/types";
import { InstantlyApprovalProps } from "~/core/modules/Auth/pages/InstantlyApprovalAuthPage";
import { api } from "~/infrastructure/api";
import { JudgementDTO } from "~/infrastructure/api/dtos";
import { mapJudgementDTO } from "~/infrastructure/api/mappers";
import { IOrderApprovalParams, IOrderApprovalService } from "./IOrderApprovalService";
import { OrderApprovalDTO } from "./OrderApprovalDTO";

export function mapOrderApprovalRejectedItems(items: IOrderApprovalParams["rejectedItems"]) {
  return items?.map((element) => {
    const combinedItem = items.find(
      (item) => item.combinedItemAirway && item.itemOrderId !== element.itemOrderId
    );

    return {
      table: element.item,
      item_order_uuid: element.itemOrderId,
      reason_rejected: element.reasonRejected || combinedItem?.reasonRejected,
    };
  });
}

function parseOrderApprovalParams(data: IOrderApprovalParams): OrderApprovalDTO {
  return {
    order_uuid: data.orderId,
    reason_rejected: data.reasonRejected,
    obs: data.obs,
    rejected_items: mapOrderApprovalRejectedItems(data.rejectedItems || []),
  };
}

export class OrderApprovalService implements IOrderApprovalService {
  async approval(data: IOrderApprovalParams): Promise<Judgement> {
    return await api
      .post<JudgementDTO>("/customers/orders/approval", parseOrderApprovalParams(data))
      .then(({ data }) => mapJudgementDTO(data));
  }

  async instantlyApproval(data: InstantlyApprovalProps): Promise<Judgement> {
    return await api
      .post<JudgementDTO>(
        "/customers/orders/approval-instantly",
        { instantly_approval_token: data.token },
        { Authorization: `Bearer ${data.authToken}` }
      )
      .then(({ data }) => mapJudgementDTO(data));
  }
}
