import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { ApiError, CostCenter, PaginatedResource } from "~/application/types";
import { costCenterService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryTimes } from "~/constants";
import { QueryKeys } from "~/constants/queryKeys";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { logError } from "~/presentation/shared/utils/errors";
import { CostCenterDialog } from "~/presentation/shared/views/CostCenterDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { LinkingCostCenterPage } from "../components/LinkingCostCenter/LinkingCostCenterPage";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { useApprovalModels } from "~/core/modules/Customer/pages/CustomerPage/hooks/useApprovalModels";

const LOG_TAG = "CustomerSettings/CostCentersPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar centros de custos",
  CREATE_SUCCESS_MESSAGE: "Novo centro de custo adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar centro de custo",
  UPDATE_SUCCESS_MESSAGE: "Centro de custo atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar centro de custo",
  DELETE_ERROR_MESSAGE: "Falha ao excluir centro de custo",
  DELETE_SUCCESS_MESSAGE: "Centro de custo excluido",
} as const;

export function useCostCentersPage() {
  const [searchText, setSearchText] = useState("");
  const [search] = useDebounce<string>(searchText, 700);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { contexts } = useUser();

  const customerId = contexts.customer.uuid;

  const { data, isFetching } = useQuery(
    [QueryKeys.CUSTOMER_COST_CENTERS, customerId, search, currentPage],
    () => {
      return costCenterService.findPaginated({
        customerId,
        name: searchText,
        page: currentPage,
      });
    },
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const approvalModelsData = useApprovalModels({
    customerId,
    enabled: true,
  });

  const { mutate: mutateCreateCostCenter } = useMutation(
    (item: CostCenter) => costCenterService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating CostCenter(${item.uuid})`);
        dialogService.showDialog(<LoadingDialog message="Criando centro de custo" />);
      },
      onSuccess: (item) => {
        log.i(LOG_TAG, `Successfully created CostCenter(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_COST_CENTERS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");

        dialogService.popAll();
        if (approvalModelsData.data.length) {
          dialogService.showDialog(
            <LinkingCostCenterPage
              data={item}
              customerId={customerId}
              onCloseClick={() => dialogService.popDialog()}
              approvalModelsData={approvalModelsData}
            />
          );
        }
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateUpdateCostCenter } = useMutation(
    (item: CostCenter) => {
      if (!item.credit) {
        return costCenterService.updateById({ ...item, credit: 0 });
      }
      return costCenterService.updateById(item);
    },
    {
      onMutate: (item) => {
        dialogService.showDialog(<LoadingDialog message="Atualizando centro de custo" />);
        log.i(LOG_TAG, `Updating CostCenter(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated CostCenter(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_COST_CENTERS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateToggleActiveCostCenter } = useMutation(
    (item: CostCenter) => costCenterService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Toggle CostCenter(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully Toggle CostCenter(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_COST_CENTERS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "error");
      },
    }
  );

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(<CostCenterDialog onSubmit={mutateCreateCostCenter} />);
  }, [customerId]);

  const handleOpenEditModal = useCallback((item: CostCenter) => {
    dialogService.showDialog(<CostCenterDialog onSubmit={mutateUpdateCostCenter} data={item} />);
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    searchText,
    currentPage: currentPage,
    lastPage: data?.meta.last_page || 0,
    onGoToPage: setCurrentPage,
    setCurrentPage,
    setSearchText: handleChangeSearch,
    onCreateCostCenter: handleOpenCreateModal,
    onEditCostCenter: handleOpenEditModal,
    onToggleActiveCostCenter: mutateToggleActiveCostCenter,
  };
}
