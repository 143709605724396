import { IStepItem, StepItem } from "./types";

export function createStepItems<TKey, TParams>(
  data: IStepItem<TKey, TParams>[],
  params: TParams
): StepItem<TKey>[] {
  return data
    .filter((i) => i.isAvailable?.(params) !== false)
    .map((i) => ({ key: i.key, title: i.title }));
}
