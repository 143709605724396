import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { MetricItem } from "./MetricItem";

interface MetricItemGroupProps {
  amountPaid: number;
  lowestAmountFound: number;
}

export function MetricItemGroup({ amountPaid, lowestAmountFound }: MetricItemGroupProps) {
  const percentagePaidAbove = ((amountPaid - lowestAmountFound) / lowestAmountFound) * 100;
  const amountSaved = amountPaid - lowestAmountFound;
  const percentageSaved = (amountSaved / amountPaid) * 100;

  return (
    <Flex gap="6" css={{ mt: "$6" }} direction={{ "@mxmd": "column" }}>
      <Col>
        <MetricItem
          title="Valor Pago"
          value={amountPaid}
          percentage={{ 
            type: "spending", 
            value: percentagePaidAbove, 
            message: percentagePaidAbove === 0 ? "fez a melhor escolha" : "mais caro",
          }}
        />
      </Col>

      <Col>
        <MetricItem 
          title="Menor Valor Encontrado" 
          value={lowestAmountFound} 
          percentage={{ 
            type: "saving", 
            value: percentageSaved,
            message: "mais barato"
          }}
        />
      </Col>

      <Col>
        <MetricItem
          title="Deixou de Economizar"
          value={amountSaved}
        />
      </Col>
    </Flex>
  )
}