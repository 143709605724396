import queryString from "query-string";
import type { Airline } from "~/application/types";
import { api } from "~/infrastructure/api";
import { AirlineDTO } from "~/infrastructure/api/dtos";
import { mapAirlineDTO } from "~/infrastructure/api/mappers";
import type { IAirlineService } from "./IAirlineService";

export class AirlineService implements IAirlineService {
  async find(): Promise<Airline[]> {
    const url = queryString.stringifyUrl({
      url: "/airlines",
      query: {},
    });

    return await api
      .get<AirlineDTO[]>(url)
      .then(({ data }) => data.map(mapAirlineDTO));
  }
}
