import {
  CustomerOrder,
  Order,
  OrderItemStatus,
  OrderStatus,
} from "~/application/types";

export const ORDER_STATUS_LABELS: Record<
  OrderStatus & OrderItemStatus.QUOTED,
  string
> = {
  [OrderStatus.OPEN]: "Aberto",
  [OrderItemStatus.QUOTED]: "Cotado",
  [OrderStatus.APPROVED]: "Aprovado",
  [OrderStatus.WAITING]: "Aguardando aprovação",
  [OrderStatus.CANCELED]: "Cancelado",
  [OrderStatus.ON_APPROVAL]: "Em aprovação",
  [OrderStatus.CHANGING]: "Em alteração",
  [OrderStatus.REJECTED]: "Rejeitado",
  [OrderStatus.CANCELING]: "Em cancelamento",
  [OrderStatus.QUOTING]: "Em cotação",
  [OrderStatus.EXPIRED]: "Expirado",
  [OrderStatus.ISSUED]: "Emitido",
  [OrderStatus.PENDING_ISSUE]: "Emissão pendente",
  [OrderStatus.EXPIRED_TIME]: "Expirado",
};

export const CART_ORDER_STATUS_LABELS: Record<
  OrderStatus & OrderItemStatus.QUOTED,
  string
> = {
  [OrderStatus.OPEN]: "Aberto",
  [OrderItemStatus.QUOTED]: "Cotado",
  [OrderStatus.APPROVED]: "Aprovado",
  [OrderStatus.WAITING]: "Aguardando aprovação",
  [OrderStatus.CANCELED]: "Cancelado",
  [OrderStatus.ON_APPROVAL]: "Em aprovação",
  [OrderStatus.CHANGING]: "Em alteração",
  [OrderStatus.REJECTED]: "Rejeitado",
  [OrderStatus.CANCELING]: "Em cancelamento",
  [OrderStatus.QUOTING]: "Cotando",
  [OrderStatus.EXPIRED]: "Expirado",
  [OrderStatus.ISSUED]: "Emitido",
  [OrderStatus.PENDING_ISSUE]: "Aprovado",
  [OrderStatus.EXPIRED_TIME]: "Expirado",
};

export enum OrderTab {
  ITEMS = "itens-do-pedido",
  ACCOUNTABILITY_EXPENSES = "despesas",
  MESSAGES = "mensagens",
  HISTORY = "historico",
  OBSERVATIONS = "observacoes",
}

export const ORDER_MENU_TABS = [
  { title: "Itens do Pedido", id: OrderTab.ITEMS },
  { title: "Observações", id: OrderTab.OBSERVATIONS },
  { title: "Despesas", id: OrderTab.ACCOUNTABILITY_EXPENSES },
  { title: "Histórico", id: OrderTab.HISTORY },
];

export enum OrderApprovalItem {
  AIRWAY_ORDERS = "airwayOrders",
  ROOM_HOTEL_ORDERS = "roomHotelOrders",
  VEHICLE_ORDERS = "vehicleOrders",
  ROAD_ORDERS = "roadOrders",
}

export const getVoucherDownloadUrl = (order?: Order | CustomerOrder) => {
  if (!order || order.status !== OrderStatus.ISSUED) {
    return "";
  }

  return `${import.meta.env.VITE_API_URL}/orders/${order.uuid}/voucher`;
};
