import { ApiError } from "~/application/types";
import { PaginatedResource } from "~/application/types";
import { Fee } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { customerFeeService, feeService } from "~/application/usecases";
import { log } from "~/utils/log";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";

export interface UseFeesResult {
  activeFeeId?: string;
  data?: Fee[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  onGoToPage: (value: number) => void;
  onUpdateCustomerFee: (item: Fee) => void;
}

export interface UseFeesOptions {
  activeFeeId?: string;
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useFees";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar taxas",
  UPDATE_SUCCESS_MESSAGE: "Taxa atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar taxa",
} as const;

export function useFees({
  activeFeeId,
  customerId,
  enabled,
}: UseFeesOptions): UseFeesResult {
  const { user } = useUser();
  const agencyId = user.agency.uuid;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isFetching } = useQuery<PaginatedResource<Fee[]>, ApiError>(
    [QueryKeys.AGENCY_FEES, agencyId, currentPage],
    () => feeService.find({ page: currentPage, agencyId }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateUpdateCustomerFee } = useMutation(
    (item: Fee) =>
      customerFeeService.update({
        customerId,
        feeId: item.uuid,
      }),
    {
      onMutate: (item) => {
        log.i(
          LOG_TAG,
          `Updating CustomerFee(customerId: ${customerId}, feeId: ${item.uuid})`
        );
      },
      onSuccess: (_, item) => {
        log.i(
          LOG_TAG,
          `Successfully updated CustomerFee(customerId: ${customerId}, feeId: ${item.uuid})`
        );

        queryClient.invalidateQueries([QueryKeys.CUSTOMERS]);
        queryClient.invalidateQueries([QueryKeys.CUSTOMER, customerId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page || 0,
    activeFeeId,
    onGoToPage: setCurrentPage,
    onUpdateCustomerFee: mutateUpdateCustomerFee,
  };
}
