import * as React from "react";
import { SVGProps } from "react";

const SvgNotificationsActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="notifications_active_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#notifications_active_svg__a)">
      <path
        d="M2 10c0-1.667.37-3.196 1.112-4.588A10.158 10.158 0 0 1 6.1 1.95l1.175 1.6a8.088 8.088 0 0 0-2.388 2.775C4.296 7.442 4 8.667 4 10H2Zm18 0c0-1.333-.296-2.558-.887-3.675a8.088 8.088 0 0 0-2.388-2.775l1.175-1.6c1.25.917 2.246 2.07 2.988 3.462C21.628 6.804 22 8.333 22 10h-2ZM4 19v-2h2v-7c0-1.383.417-2.612 1.25-3.688.833-1.075 1.917-1.779 3.25-2.112v-.7c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 12 2c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v.7c1.333.333 2.417 1.037 3.25 2.112C17.583 7.388 18 8.617 18 10v7h2v2H4Zm8 3c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 10 20h4c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 12 22Zm-4-5h8v-7c0-1.1-.392-2.042-1.175-2.825C14.042 6.392 13.1 6 12 6s-2.042.392-2.825 1.175C8.392 7.958 8 8.9 8 10v7Z"
        fill="#C36700"
      />
    </g>
  </svg>
);

export default SvgNotificationsActive;
