import { Badge } from "~/components/Badge";
import { Box } from "~/components/Box";
import { CardBody, CardSectionHeader } from "~/components/Card";
import { Col } from "~/components/Grid";
import { Checkbox } from "~/components/Input";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import type { Filter } from "../utils";
import { useEffect, useState } from "react";

export interface FilterListItemProps {
  data: Filter;
  isLoading?: boolean;
  onFilterChange: (key: string, optionKey: string) => void;
}

export function FilterListItem({ data, isLoading, onFilterChange, ...props }: FilterListItemProps) {
  return (
    <Box {...props}>
      <CardSectionHeader>
        <Text>{data.label}</Text>
      </CardSectionHeader>
      <CardBody css={{ p: "$4", gap: "$2" }}>
        {data.options.map((option) => (
          <Checkbox
            css={{
              width: "100%",
            }}
            checked={option.selected}
            onChange={() => onFilterChange(data.key, option.key)}
            key={option.key}
          >
            <Col>
              <Text>{option.label}</Text>
            </Col>
            <Badge>{option.quantity}</Badge>
          </Checkbox>
        ))}

        {isLoading &&
          data.key === "suppliers" &&
          Array.from({ length: !data.options.length ? 2 : 1 }).map((_, index) => (
            <Skeleton
              key={`skeleton-${index}`}
              css={{ display: "flex", alignItems: "center", height: "20px", width: "$50" }}
            />
          ))}
      </CardBody>
    </Box>
  );
}

FilterListItem.displayName = "FilterListItem";
