import { Flex } from "~/components/Flex";
import { Skeleton } from "~/components/Skeleton";

export interface OrderAirwayItemSkeletonProps {}

export function OrderAirwayItemSkeleton() {
  return (
    <Flex direction="column" gap="6">
      <Skeleton variant="text-6" />

      <Skeleton variant="fluid" style={{ height: "238px" }} />

      <Skeleton variant="text-6" />

      <Skeleton variant="fluid" style={{ height: "238px" }} />

      <Skeleton variant="text-6" />

      <Skeleton variant="fluid" style={{ height: "88px" }} />

      <Skeleton variant="fluid" style={{ height: "88px" }} />
    </Flex>
  );
}

OrderAirwayItemSkeleton.displayName = "OrderAirwayItemSkeleton";
