import * as yup from "yup";

import { PATTERN_CNPJ } from "~/utils/mask.utils";

export function isValidImageType(fileName?: string) {
  return fileName
    ? ["jpg", "png", "jpeg", "webp"].indexOf(fileName.split('.').pop()!) > -1
    : false;
}

export const createOfflineHotelSchema = yup.object().shape({
  description: yup.string().required("A descrição é obrigatória"),
  cnpj: yup.string().nullable().test('is-valid-cnpj', 'CNPJ inválido', (value) => {
    if (!value || value.trim() === '') {
      return true;
    }
    return PATTERN_CNPJ.test(value);
  }),
  integrationCode: yup.string().nullable(),
  stars: yup
    .number()
    .min(1, "O número de estrelas deve estar entre 1 e 5")
    .max(5, "O número de estrelas deve estar entre 1 e 5")
    .required("O número de estrelas é obrigatório"),
  obs: yup.string().nullable(),
  state: yup.object().shape({
    name: yup.string().required("O estado é obrigatório"),
    state: yup.string().required(),
  }),
  city: yup.object().shape({
    name: yup.string().required("A cidade é obrigatória"),
    uuid: yup.string(),
    state: yup.string(),
    country: yup.string(),
  }),
  district: yup.string().required("O bairro é obrigatório"),
  address: yup.string().required("O logradouro é obrigatório"),
  latitude: yup
    .number()
    .nullable()
    .min(-90, "A latitude deve estar entre -90° e 90°")
    .max(90, "A latitude deve estar entre -90° e 90°")
    .transform((value) => value ? Number(value) : null),
  longitude: yup
    .number()
    .nullable()
    .min(-180, "A longitude deve estar entre -180° e 180°")
    .max(180, "A longitude deve estar entre -180° e 180°")
    .transform((value) => value ? Number(value) : null),
  whatsapp: yup.string().min(18, "Número de whatsapp inválido").required("O número de whatsapp é obrigatório"),
  phone: yup
    .string()
    .test("is-valid-phone", "Telefone inválido", (value) => {
      if (!value || value === "+") return true;
      return value.length >= 18;
    })
    .nullable(),
  email: yup.string().email("E-mail inválido").nullable(),
  site: yup.string().url("Link inválido").nullable(),
  image: yup
    .mixed()
    .required("A foto principal é obrigatória")
    .test("is-valid-type", "Selecione uma imagem válida", (value: any) => {
      if (!value.length) return true;
      return isValidImageType(value[0].name.toLowerCase());
    }),
  images: yup
    .mixed()
    .required("As outras fotos são obrigatórias")
    .test("is-valid-type", "Selecione uma imagem válida", (value: any) => {
      if (!value.length) return true;
      return isValidImageType(value[0].name.toLowerCase());
    }),
});

export type RegisterOfflineHotelSchema = yup.InferType<typeof createOfflineHotelSchema>;