import { memo } from "react";
import { DropdownMenuItem } from "~/application/components/DropdownMenu";
import { NavLink } from "~/components/Link";

export type NavItemLinkProps = {
  href: string;
  title: string;
  children: React.ReactNode;
};

export const NavItemLink = memo(
  ({ href, children, title }: NavItemLinkProps) => {
    return (
      <NavLink to={href} title={title}>
        {({ isActive }) => (
          <DropdownMenuItem data-active={isActive}>{children}</DropdownMenuItem>
        )}
      </NavLink>
    );
  }
);

NavItemLink.displayName = "NavItemLink";
