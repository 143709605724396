import { CSS } from "@stitches/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  AccountabilityExpense,
  AdvanceOrderStatus,
  Order,
  TravelerAdvance,
} from "~/application/types";
import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgAlert,
  SvgCheck,
  SvgChevronDown,
  SvgChevronUp,
  SvgFile,
  SvgMinus,
  SvgOrder,
  SvgPerson,
  SvgPlus,
  SvgRemove,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { TextAreaInput } from "~/components/Input";
import { Skeleton } from "~/components/Skeleton";
import { CounterTabBadge } from "~/components/Tabs";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H5 } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { EXPENSE_CATEGORY_ICONS } from "~/core/modules/ExpenseCategory/pages/ExpenseCategoriesPage/utils";
import { getUnsatisfiedPolicyMessage } from "~/core/shared/utils/order.utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import * as DateUtils from "~/utils/date.utils";
import * as MaskUtils from "~/utils/mask.utils";
import { ApproversListItem } from "../../../../../../../presentation/shared/components/ApproversList";
import {
  ReasonRejectedItem,
  ReasonRejectedProps,
} from "../../../../../../../presentation/shared/components/ReasonRejectedItem";
import { advanceOrderStatus, getExpenseIdentifier } from "../../utils";
import { useTabApproverAccountabilityExpenses } from "./hooks/useTabApproverAccountabilityExpenses";
import { canShowExpenseApprovers, getExpenseApprovers } from "./utils";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface TabApproverAccountabilityExpensesProps {
  order?: Order;
  isLoading: boolean;
  onShowVoucher: (data: AccountabilityExpense) => void;
}

export function TabApproverAccountabilityExpenses({
  order,
  isLoading,
  onShowVoucher,
}: TabApproverAccountabilityExpensesProps) {
  const {
    items,
    result,
    reasonRejectedRefs,
    reproveItem,
    approveItem,
    isAllExpensesJudged,
    isSuccessExpensesJudgment,
    isLoadingExpensesJudgment,
    handleExpensesJudgment,
    totalAdvances,
    totalExpenses,
  } = useTabApproverAccountabilityExpenses({
    order,
  });
  const [cartIsOpen, setCartIsOpen] = useState(false);
  const isMobile = useMobile();
  const unableToApproveExpense = () => {
    const thereIsSomeRejectedItem = items.rejected.length > 0;
    const thereIsNoneApprovedItem = items.approved.length === 0;

    return (
      thereIsSomeRejectedItem ||
      thereIsNoneApprovedItem ||
      isSuccessExpensesJudgment ||
      !isAllExpensesJudged
    );
  };

  const cannotApproveExpenses = unableToApproveExpense();

  const unableToReproveExpense = () => {
    const thereIsNoneRejectedItem = items.rejected.length === 0;

    return (
      thereIsNoneRejectedItem ||
      isSuccessExpensesJudgment ||
      !isAllExpensesJudged
    );
  };

  const cannotReproveExpenses = unableToReproveExpense();
  const expenses = result?.expenses || [];
  const advanceOrder = result?.advanceOrder || ({} as TravelerAdvance);

  const { user } = useUser();
  const approverAlreadyJudged = result?.approvers.some(
    ({ id, approvalStatus }) =>
      id === user.profiles.customer.uuid && approvalStatus !== null
  );

  const canShowApprovalButtons =
    advanceOrder.status === AdvanceOrderStatus.APPROVING &&
    isAllExpensesJudged &&
    !approverAlreadyJudged;

  const canJudge =
    advanceOrder.status === AdvanceOrderStatus.APPROVING &&
    !approverAlreadyJudged;

  const getAdvanceOrderStatusCss = (): CSS => {
    const incompleteBorderCss = "1px solid ";
    const css: CSS = {
      border: incompleteBorderCss.concat("black"),
    };

    const advanceOrderStatusCss = {
      [AdvanceOrderStatus.APPROVED]: () => {
        css.border = incompleteBorderCss.concat("green");
      },
      [AdvanceOrderStatus.DISAPPROVED]: () => {
        css.border = incompleteBorderCss.concat("red");
      },
    };

    if (advanceOrder.status in advanceOrderStatusCss) {
      const status = advanceOrder.status as keyof typeof advanceOrderStatusCss;
      advanceOrderStatusCss[status]();
    }

    return css;
  };

  const advanceOrderStatusCss = getAdvanceOrderStatusCss();

  const getAdvanceOrderStatusVariant = () => {
    type Variant = "error-light" | "success-light" | "neutral-light";
    let variant: Variant = "neutral-light";

    const advanceOrderStatusVariant = {
      [AdvanceOrderStatus.DISAPPROVED]: () => {
        variant = "error-light";
      },
      [AdvanceOrderStatus.APPROVED]: () => {
        variant = "success-light";
      },
    };

    if (advanceOrder.status in advanceOrderStatusVariant) {
      advanceOrderStatusVariant[
        advanceOrder.status as keyof typeof advanceOrderStatusVariant
      ]();
    }

    return variant;
  };

  const advanceOrderStatusVariant = getAdvanceOrderStatusVariant();

  const advanceOrderStatusToUpper = (): string => {
    const status = advanceOrder.status as keyof typeof advanceOrderStatus;
    return advanceOrderStatus[status]?.toUpperCase();
  };

  const advanceOrderStatusUppercase = advanceOrderStatusToUpper();

  const getAdvanceOrderTextCss = (): CSS => {
    const css = {
      color: "$neutrals-black",
    };

    const advanceOrderTextCss = {
      [AdvanceOrderStatus.DISAPPROVED]: () => {
        css.color = "red";
      },
      [AdvanceOrderStatus.APPROVED]: () => {
        css.color = "$success-base";
      },
    };

    if (advanceOrder.status in advanceOrderTextCss) {
      const status = advanceOrder.status as keyof typeof advanceOrderTextCss;
      advanceOrderTextCss[status]();
    }

    return css;
  };

  const advanceOrderTextCss = getAdvanceOrderTextCss();

  const { control } = useForm<{ reasonsRejected: string[] }>({
    defaultValues: { reasonsRejected: [] },
  });

  if (isLoadingExpensesJudgment) {
    return (
      <LoadingModal
        isOpen={isLoadingExpensesJudgment}
        message={
          cannotApproveExpenses
            ? "Solicitando revisão das despesas"
            : "Aprovando despesas"
        }
      />
    );
  }

  return (
    <Row
      css={{
        "@mxlg": {
          width: "100%",
          ml: "1px",
        },
      }}
    >
      <Col
        sz="8"
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        {expenses.map((expense, index) => {
          const icon = EXPENSE_CATEGORY_ICONS.find(
            ({ slug }) => slug === expense.expenseCategory.icon
          )?.icon;

          const expenseDate = DateUtils.displayDate(
            expense.expenseDate,
            DateUtils.DateFormats.LONG_DATE
          );

          const approvedItem = items.approved.some(
            ({ uuid }) => uuid === expense.uuid
          );

          const rejectedItem = items.rejected.some(
            (item) => item.accountabilityExpenseId === expense.uuid
          );

          return (
            <Card
              key={expense.uuid}
              css={{
                mb: "$6",
                border:
                  advanceOrder.status === AdvanceOrderStatus.DISAPPROVED &&
                  expense.reasonRejected
                    ? "1px solid red"
                    : "",
              }}
            >
              <CardBody>
                <Flex direction="column" gap="6">
                  <H5>#{getExpenseIdentifier(expense)}</H5>
                  <Flex justify="between">
                    <Text
                      as="p"
                      variant="dark"
                      css={{ fw: "500", lineHeight: 1.25 }}
                    >
                      {expenseDate}
                    </Text>
                  </Flex>

                  <Flex gap="2">
                    <Flex gap="2">
                      <Tag variant="info-light">
                        {icon && <Icon as={icon} />}
                        <Text
                          size="2"
                          css={{
                            p: "$0",
                            fw: "600",
                            display: "block",
                            mr: icon ? "$2" : "$0",
                          }}
                        >
                          {expense.expenseCategory.name}
                        </Text>
                      </Tag>
                    </Flex>

                    {expense.unsatisfiedPolicies.length > 0 && (
                      <>
                        <Tooltip
                          content={
                            <Flex direction="column" gap="2" css={{ p: "$2" }}>
                              <Text variant="black" size="3" css={{ fw: 700 }}>
                                Esta opção está fora da politica de compra.
                              </Text>
                              {expense.unsatisfiedPolicies.map(
                                ({ uuid, typeValue, value }) => (
                                  <Text size="3" as="p" key={uuid}>
                                    {getUnsatisfiedPolicyMessage(
                                      typeValue,
                                      value
                                    )}
                                  </Text>
                                )
                              )}
                              <Text size="3" as="p">
                                Ao selecionar esta opção, você precisará criar
                                uma justificativa para o seu aprovador.
                              </Text>
                            </Flex>
                          }
                          variant="white"
                        >
                          <Tag variant="warning-light">
                            <Icon size="sm" as={SvgAlert} />
                            <Text>FORA DA POLÍTICA</Text>
                          </Tag>
                        </Tooltip>
                      </>
                    )}
                  </Flex>

                  <Row align="center">
                    <Col>
                      <Text size="2" variant="dark">
                        Tipo de Despesa
                      </Text>
                      <Text css={{ fw: "600", display: "block", mt: "$2" }}>
                        {expense.expenseType.name}
                      </Text>
                    </Col>

                    <Col>
                      <Text size="2" variant="dark">
                        Estabelecimento
                      </Text>
                      <Text css={{ fw: "600", display: "block", mt: "$2" }}>
                        {expense.company}
                      </Text>
                    </Col>

                    <Col sz="auto">
                      <Flex gap="4">
                        <Col>
                          <Text size="2" variant="dark">
                            Valor
                          </Text>
                          <Text
                            css={{
                              fw: "600",
                              display: "block",
                              mt: "$2",
                            }}
                          >
                            {MaskUtils.asCurrency(expense.value)}
                          </Text>
                        </Col>

                        <Col>
                          {expense.voucherPath && (
                            <Flex>
                              <Tooltip
                                content={
                                  <TooltipLabel>
                                    Visualizar Comprovante
                                  </TooltipLabel>
                                }
                              >
                                <IconButton
                                  size="md"
                                  css={{ m: "$0", width: "auto" }}
                                  onClick={() => onShowVoucher(expense)}
                                >
                                  <Icon as={SvgFile} />
                                </IconButton>
                              </Tooltip>
                              <CounterTabBadge
                                css={{ alignSelf: "start", p: "$1" }}
                              >
                                1
                              </CounterTabBadge>
                            </Flex>
                          )}
                        </Col>

                        {canJudge && (
                          <Flex>
                            <Col>
                              <Tooltip
                                content={
                                  <TooltipLabel>Aprovar Despesa</TooltipLabel>
                                }
                              >
                                <IconButton
                                  size="md"
                                  disabled={
                                    isSuccessExpensesJudgment ||
                                    advanceOrder.status !==
                                      AdvanceOrderStatus.APPROVING
                                  }
                                  css={{
                                    opacity: isSuccessExpensesJudgment
                                      ? 0.5
                                      : 1,
                                    background: approvedItem ? "#c3fadb" : "",
                                  }}
                                  onClick={() => approveItem(expense, index)}
                                >
                                  <Icon
                                    css={{
                                      border: "$success-dark",
                                      borderRadius: "$circular",
                                      opacity:
                                        approvedItem || expense.reasonRejected
                                          ? 1
                                          : 0.7,
                                    }}
                                    as={SvgCheck}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Col>

                            <Col>
                              <Tooltip
                                content={
                                  <TooltipLabel>Reprovar Despesa</TooltipLabel>
                                }
                              >
                                <IconButton
                                  size="md"
                                  disabled={
                                    isSuccessExpensesJudgment ||
                                    (advanceOrder &&
                                      advanceOrder.status !==
                                        AdvanceOrderStatus.APPROVING)
                                  }
                                  css={{
                                    opacity: rejectedItem ? 1 : 0.5,
                                  }}
                                  onClick={() => reproveItem(expense, index)}
                                >
                                  <Icon
                                    css={{
                                      border: "$circular",
                                      opacity:
                                        !rejectedItem || expense.reasonRejected
                                          ? 0.7
                                          : 1,
                                    }}
                                    variant="error"
                                    as={SvgRemove}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Col>
                          </Flex>
                        )}
                      </Flex>
                    </Col>
                  </Row>
                </Flex>
              </CardBody>

              <Divider />

              <CardBody>
                <Flex gap="3">
                  <Avatar />
                  <Flex gap="2" direction="column">
                    <Text size="3" css={{ fw: 600 }}>
                      {advanceOrder?.traveler.fullName}
                    </Text>
                    <Text size="2" variant="dark">
                      {DateUtils.getTimeFromNow(expense.expenseDate)}
                    </Text>
                    <Text
                      as="p"
                      variant="darkest"
                      css={{ mt: "$2", lineHeight: 1.5 }}
                    >
                      {expense.justification}
                    </Text>
                  </Flex>
                </Flex>
              </CardBody>

              {rejectedItem &&
                advanceOrder.status === AdvanceOrderStatus.APPROVING && (
                  <>
                    <Divider />

                    <CardBody>
                      <Flex gap="3" css={{ mb: "$4" }}>
                        <Avatar />
                        <Flex gap="3" direction="column">
                          <Text size="3" css={{ fw: 600 }}>
                            Você
                          </Text>
                          <Text size="2" variant="dark">
                            {DateUtils.getTimeFromNow(new Date())}
                          </Text>
                        </Flex>
                      </Flex>
                      <Col>
                        <FormControl
                          name={`reasonsRejected.${index}`}
                          control={control}
                          required
                        >
                          <FieldLabel>Mensagem</FieldLabel>
                          <TextAreaInput
                            ref={(ref) => {
                              if (reasonRejectedRefs?.current) {
                                reasonRejectedRefs!.current[index] =
                                  ref as HTMLDivElement;
                              }
                            }}
                            placeholder="Digite o motivo para revisão das despesas"
                          />
                        </FormControl>
                      </Col>
                    </CardBody>
                  </>
                )}

              {expense.reasonRejected && (
                <ReasonRejectedItem
                  css={{ p: "$5" }}
                  reasonRejected={
                    expense.reasonRejected as ReasonRejectedProps["reasonRejected"]
                  }
                />
              )}
            </Card>
          );
        })}
      </Col>

      <Col sz="4" css={{ position: "fixed", left: "66%", maxWidth: "30%" }}>
        <Cart
          css={{
            "@mxlg": {
              backgroundColor: "#003161",
              position: "fixed",
              left: "0",
              width: "100%",
              borderRadius: "0",
              height: cartIsOpen ? "$80" : "50px",
              transition: "$slow",
              bottom: "0",
            },
          }}
        >
          <CartHeader>
            {!isMobile ? (
              <>
                <Icon as={SvgOrder} size="sm" />
                <Text>Resumo do Adiantamento</Text>
              </>
            ) : (
              <Flex
                justify="center"
                css={{ width: "100%" }}
                onClick={() => setCartIsOpen((old) => !old)}
              >
                <Icon as={cartIsOpen ? SvgChevronDown : SvgChevronUp} />
              </Flex>
            )}
          </CartHeader>

          <CardBody>
            <Flex direction="column" gap="6">
              {isLoading ? (
                <>
                  <Skeleton variant="text-4" />
                  <Skeleton variant="text-4" />
                  <Skeleton variant="text-4" />
                </>
              ) : (
                <>
                  <Flex gap="4" align="center">
                    <Icon variant="dark" as={SvgPlus} size="sm" />
                    <Col>
                      <Text variant="darkest" css={{ fw: 700 }} size="2">
                        Adiantamentos
                      </Text>
                    </Col>
                    <Text variant="darkest" size="2">
                      {MaskUtils.asCurrency(totalAdvances ?? 0)}
                    </Text>
                  </Flex>

                  <Flex gap="4" align="center">
                    <Icon variant="dark" as={SvgMinus} size="sm" />
                    <Col>
                      <Text variant="darkest" css={{ fw: 700 }} size="2">
                        Despesas
                      </Text>
                    </Col>
                    <Text variant="darkest" size="2">
                      {MaskUtils.asCurrency(totalExpenses)}
                    </Text>
                  </Flex>
                  <Flex gap="4" align="center">
                    <Icon variant="dark" as={SvgOrder} size="sm" />
                    <Col>
                      <Text variant="darkest" css={{ fw: 700 }} size="2">
                        Valor reembolsável
                      </Text>
                    </Col>
                    <Text variant="darkest" size="2">
                      {MaskUtils.asCurrency(totalExpenses - totalAdvances)}
                    </Text>
                  </Flex>

                  <Flex gap="4" align="center">
                    <Icon variant="dark" as={SvgOrder} size="sm" />
                    <Col>
                      <Text variant="darkest" css={{ fw: 700 }} size="2">
                        Status da despesa
                      </Text>
                    </Col>

                    <Tag
                      css={advanceOrderStatusCss}
                      variant={advanceOrderStatusVariant}
                    >
                      <Text
                        css={advanceOrderTextCss}
                        variant="darkest"
                        size="2"
                      >
                        {advanceOrderStatusUppercase}
                      </Text>
                    </Tag>
                  </Flex>

                  {advanceOrder.approvedBy && (
                    <Flex gap="4" align="center">
                      <Icon variant="dark" as={SvgPerson} size="sm" />
                      <Col>
                        <Text variant="darkest" css={{ fw: 700 }} size="2">
                          Aprovado por {advanceOrder.approvedBy}
                        </Text>
                      </Col>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </CardBody>

          {canShowApprovalButtons && cannotReproveExpenses && (
            <CardBody css={{ pt: "$0" }}>
              <Button onClick={handleExpensesJudgment}>
                <Text>Aprovar despesas</Text>
              </Button>
            </CardBody>
          )}

          {canShowApprovalButtons && !cannotReproveExpenses && (
            <CardBody css={{ pt: "$0" }}>
              <Button onClick={handleExpensesJudgment} variant="tertiary">
                <Text>Solicitar revisão das despesas</Text>
              </Button>
            </CardBody>
          )}
        </Cart>

        <ApproversListItem
          enabled={canShowExpenseApprovers(result)}
          approvers={getExpenseApprovers(result)}
        />
      </Col>
    </Row>
  );
}
