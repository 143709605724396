import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Flight, FlightOption, OrderItems, UserContext } from "~/application/types";
import { NavigatorUtils } from "~/application/utils";
import { log } from "~/application/utils/log";
import { useQueryGoFlights } from "~/presentation/Booking/BookingAirway/hooks/useQueryGoFlights";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { useFilters } from "~/presentation/shared/hooks/useFilters";
import {
  BookingAirwaySteps,
  DYNAMIC_FLIGHT_FILTERS,
  STATIC_FLIGHT_FILTERS,
} from "../../../constants";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { GoFlightsSectionUI } from "./GoFlightsSectionUI";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { flightDuration, flightsDuration } from "~/presentation/shared/utils";
import { useDebounce } from "use-debounce";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { usePoliciesFormats } from "~/presentation/shared/hooks/usePoliciesFormats";
import { AirwayBookingDispatchActionType } from "../../../hooks/useAirwayReducer/types";

const VIEW_ID = BookingAirwaySteps.SELECT_GO_TICKET;

export const GoFlightsSection: FC = () => {
  const { LOG_TAG } = useLogTag();
  const { contexts, user } = useUser();

  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const [duration, setDuration] = useState({ min: 0, max: 0 });
  const [durations] = useDebounce(duration, 700);

  const isMobile = useMobile();

  const { bookingStep, airwayReducer, flightQuery, isManyStretch } = useFlightsPage();

  const { bookingState } = airwayReducer;

  const { data, isAvailable, isLoading } = useQueryGoFlights({
    enabled:
      bookingStep.current === VIEW_ID || bookingStep.current === `${bookingStep.currentIndex}`,
    flightQueryLinks: flightQuery.links,
    retryCount: 50,
  });

  const currentStep = bookingStep.currentIndex;

  const flightsList = useMemo(
    () => data.sort((a, b) => a.minimumPrice.amount - b.minimumPrice.amount),
    [data]
  );

  const flights = usePoliciesFormats({
    customerId,
    data: flightsList,
    itemType: OrderItems.AIRWAY,
  }) as Flight[];

  const onSelectFlight = useCallback(
    (flight: Flight, option: FlightOption) => {
      log.i(LOG_TAG, "onSelectFlight", { flight, option });

      if (!isMobile) {
        NavigatorUtils.scrollToTop(true);
      }

      if (!isManyStretch) {
        airwayReducer.dispatch({
          type: AirwayBookingDispatchActionType.GO_FLIGHT,
          payload: { flight: flight, flightOption: option, combinedFlight: null },
        });
        return;
      }
      const sectionAlreadySelected = airwayReducer.bookingState.stretch.some(
        (stre) => stre.index === currentStep
      );

      airwayReducer.dispatch({
        type: sectionAlreadySelected
          ? AirwayBookingDispatchActionType.UPDATE_STRETCH
          : AirwayBookingDispatchActionType.SET_STRETCH,
        payload: { flight: flight, flightOption: option, combinedFlight: null, index: currentStep },
      });
    },
    [flightQuery, currentStep]
  );

  const { filteredData, filters, onFilterChange } = useFilters({
    filters: STATIC_FLIGHT_FILTERS,
    filtersGenerator: DYNAMIC_FLIGHT_FILTERS,
    data: flights,
    sort: false,
  });

  useEffect(() => {
    if (filteredData.length) {
      const { max, min } = flightsDuration(flights);
      setDuration({ max, min });
    }
  }, [setDuration]);

  const filterDurationData = useMemo(
    () =>
      filteredData.filter((flight) => {
        const currentFlightDuration = flightDuration(flight);
        return currentFlightDuration <= durations.min;
      }),
    [filteredData, durations]
  );

  const currentFilteredData =
    duration.max === 0 && duration.min === 0 ? filteredData : filterDurationData;

  return (
    <GoFlightsSectionUI
      data={currentFilteredData}
      flightsList={flights}
      currentStep={currentStep}
      onFilterDurationChange={setDuration}
      filters={filters}
      isManyStretch={isManyStretch}
      isLoading={flightQuery.isLoading || isLoading}
      isAgency={user.context === UserContext.Agency}
      isAvailable={isAvailable}
      bookingState={bookingState}
      onFilterChange={onFilterChange}
      onSelectFlight={onSelectFlight}
    />
  );
};

GoFlightsSection.displayName = "GoFlightsSection";
