import { styled } from "~/application/theme";
import * as Sliders from "@radix-ui/react-slider";
import { SliderRoot } from "./SliderRoot";
import { ComponentProps } from "react";

export type SliderProps = ComponentProps<typeof SliderRoot>;

export const SliderTrack = styled(Sliders.Track, {
  backgroundColor: "$primary-base",
  position: "relative",
  flexGrow: 1,
  borderRadius: "9999px",
  height: 3,
});

export const SliderRange = styled(Sliders.Range, {
  position: "absolute",
  backgroundColor: "white",
  borderRadius: "9999px",
  height: "100%",
});

export const SliderThumb = styled(Sliders.Thumb, {
  display: "block",
  width: 20,
  height: 20,
  backgroundColor: "white",
  boxShadow: `0 2px 10px `,
  borderRadius: 10,
  "&:hover": {},
  "&:focus": { outline: "none", boxShadow: `0 0 0 5px ` },
});

export const Slider = ({ ...props }: SliderProps) => (
  <SliderRoot {...props}>
    <SliderTrack></SliderTrack>
    <SliderThumb />
    <SliderThumb />
  </SliderRoot>
);

Slider.displayName = "Slider";
