import type * as Stitches from "@stitches/react";
import { createStitches, defaultThemeMap } from "@stitches/react";
import { colors } from "./colors";

export const { config, createTheme, css, getCssText, globalCss, keyframes, styled, theme } =
  createStitches({
    themeMap: {
      ...defaultThemeMap,
      width: "space",
      height: "space",
      opacity: "opacities",
      lineHeight: "space",
    },

    theme: {
      colors,
      space: {
        1: "0.25rem",
        2: "0.5rem",
        3: "0.75rem",
        4: "1rem",
        5: "1.25rem",
        6: "1.5rem",
        7: "1.75rem",
        8: "2rem",
        9: "2.25rem",
        10: "2.5rem",
        11: "2.75rem",
        12: "3rem",
        13: "3.25rem",
        14: "3.5rem",
        15: "3.75rem",
        16: "4rem",
        18: "4.5rem",
        20: "5rem",
        40: "10rem",
        50: "12.5rem",
        54: "13.5rem",
        64: "16rem",
        80: "20rem",
      },
      fontSizes: {
        xxs: "0.625rem",
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.25rem",
        xl: "1.5rem",
        "2xl": "2rem",
        "4xl": "2.5rem",
        "5xl": "3rem",
        "6xl": "3.5rem",
        "7xl": "4rem",
        "8xl": "4.5rem",
        "9xl": "6rem",
      },

      fonts: {
        default: "Inter, Roboto, sans-serif",
      },

      opacities: {
        transparent: "16%",
        semitransparent: "24%",
        medium: "64%",
        semiopaque: "80%",
        disabled: "32%",
      },

      radii: {
        none: "0px",
        sm: "4px",
        md: "8px",
        lg: "24px",
        rounded: "16px",
        circular: "50%",
        pill: "9999px",
      },

      transitions: {
        instant: "0ms",
        faster: "100ms",
        fast: "200ms",
        normal: "300ms",
        slow: "400ms",
        slower: "500ms",
      },

      shadows: {
        "focus-sm": "0 0 0 2px #a3b3c2",
        "focus-md": "0 0 0 4px #a3b3c2",
        none: "none",
        sm: "0px 8px 16px rgba(27, 27, 27, 0.16)",
        md: "0px 16px 24px rgba(27, 27, 27, 0.16)",
        lg: "0px 24px 32px rgba(27, 27, 27, 0.16)",
      },

      sizes: {
        "dropdown-content": "200px",
        "app-bar-height": "84px",
      },

      zIndices: {
        navigationBar: 2,
        appBar: 3,
        dropdown: 99,
        overlay: 995,
        modal: 996,
        alert: 998,
        tooltip: 999,
        scrollbar: 9999,
      },
    },

    media: {
      sm: "(min-width: 576px)",
      md: "(min-width: 768px)",
      lg: "(min-width: 992px)",
      xl: "(min-width: 1200px)",
      xxl: "(min-width: 1400px)",

      mxsm: "(max-width: 576px)",
      mxmd: "(max-width: 768px)",
      mxlg: "(max-width: 992px)",
      mxxl: "(max-width: 1200px)",
      mxxxl: "(max-width: 1400px)",
    },

    utils: {
      // Padding
      p: (value: Stitches.PropertyValue<"padding">) => ({
        padding: value,
      }),
      pt: (value: Stitches.PropertyValue<"paddingTop">) => ({
        paddingTop: value,
      }),
      pr: (value: Stitches.PropertyValue<"paddingRight">) => ({
        paddingRight: value,
      }),
      pb: (value: Stitches.PropertyValue<"paddingBottom">) => ({
        paddingBottom: value,
      }),
      pl: (value: Stitches.PropertyValue<"paddingLeft">) => ({
        paddingLeft: value,
      }),
      px: (value: Stitches.PropertyValue<"paddingLeft">) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: Stitches.PropertyValue<"paddingTop">) => ({
        paddingTop: value,
        paddingBottom: value,
      }),

      // Margin
      m: (value: Stitches.PropertyValue<"margin">) => ({
        margin: value,
      }),
      mt: (value: Stitches.PropertyValue<"marginTop">) => ({
        marginTop: value,
      }),
      mr: (value: Stitches.PropertyValue<"marginRight">) => ({
        marginRight: value,
      }),
      mb: (value: Stitches.PropertyValue<"marginBottom">) => ({
        marginBottom: value,
      }),
      ml: (value: Stitches.PropertyValue<"marginLeft">) => ({
        marginLeft: value,
      }),
      mx: (value: Stitches.PropertyValue<"marginLeft">) => ({
        marginLeft: value,
        marginRight: value,
      }),
      my: (value: Stitches.PropertyValue<"marginTop">) => ({
        marginTop: value,
        marginBottom: value,
      }),

      // Text
      ta: (value: Stitches.PropertyValue<"textAlign">) => ({ textAlign: value }),

      // Font
      fw: (value: Stitches.PropertyValue<"fontWeight">) => ({
        fontWeight: value,
      }),

      // Shape
      size: (value: Stitches.PropertyValue<"width">) => ({
        width: value,
        height: value,
      }),
      fixedWidth: (value: Stitches.PropertyValue<"width">) => ({
        maxWidth: value,
        minWidth: value,
      }),
    },
  });

export const globalStyles = globalCss({
  "*, *::before, *::after": {
    boxSizing: "border-box",
  },

  html: {
    // scrollbarGutter: "stable",
  },

  body: {
    margin: 0,
    fontSize: "$md",
    fontWeight: 400,
    color: "$neutrals-black",
    fill: "$neutrals-dark",
    fontFamily: "$default",
  },

  "h1, h2, h3, h4, h5": { fontWeight: 500 },

  "html[color-scheme=primary]": { backgroundColor: "$primary-base" },
  "html[color-scheme=light]": { backgroundColor: "$neutrals-lightest" },

  // For react-collapse package
  ".ReactCollapse--collapse": {
    transition: "height $normal",
  },

  ".c-ePBChY": {
    maxHeight: "100px",
  },

  ".gm-style-iw-c": {
    maxHeight: "none !important",
  },
});
