import type { Approver } from "./Approver.type";
import type { Branch } from "./Branch.type";
import type { CostCenter } from "./CostCenter.type";

export type ApprovalType = "sequential" | "single";

export type Level = {
  approvers: Approver[];
};

export enum ApprovalTypeEnum {
  SINGLE = "single",
  SEQUENTIAL = "sequential",
}

export type ApprovalModel = {
  uuid: string;
  name: string;
  approvalType: ApprovalType;
  customerEmployeeId?: string;
  agencyEmployeeId?: string;
  branch?: Branch;
  costCenters: CostCenter[];
  isActive: boolean;
  isAllBranches: boolean;
  applyExpense: boolean;
  approvers: Approver[];
  outPolicyApprovalModel?: ApprovalModel;
  approverExpense?: {
    uuid?: string;
    name?: string;
  };
};
