import queryString from "query-string";
import type { Stop } from "~/application/types";
import { api } from "~/infrastructure/api";
import { StopDTO } from "~/infrastructure/api/dtos";
import { mapStopDTO } from "~/infrastructure/api/mappers";
import type { IFindStopParams, IStopService } from "./IStopService";

export class StopService implements IStopService {
  async find({ name }: IFindStopParams): Promise<Stop[]> {
    const url = queryString.stringifyUrl({
      url: `/stops/${name}`,
      query: {},
    });

    return await api
      .get<{ data: StopDTO[] }>(url)
      .then(({ data }) => data.data.map(mapStopDTO));
  }
}
