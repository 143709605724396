import { useCallback } from "react";
import { OrderItems, OrderStatus } from "~/application/types";
import { Flex } from "~/components/Flex";
import { userIsIssuer } from "~/core/modules/Order/utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { OrderAdditionalInfoItem } from "~/presentation/shared/components/OrderAdditionalInfoItem";
import {
  getAirwayFrom,
  getHotelFrom,
  getRoadFrom,
  getVehicleFrom,
  isAirwayRejected,
  isHotelRejected,
  isRoadRejected,
  isVehicleRejected,
} from "~/presentation/shared/utils/order-functions";
import { UseOrderItemsResult } from "../../hooks/useOrderItems/types";
import { userIsOrderApprover } from "../../utils";
import { AdditionalInfoListItem } from "./components/AdditionalInfoListItem";
import { OrderAirwayItem } from "./components/OrderAirwayItem";
import { OrderHotelItem } from "./components/OrderHotelItem";
import { OrderItemListItem } from "./components/OrderItemListItem";
import { OrderRoadItem } from "./components/OrderRoadItem/index";
import { OrderVehicleItem } from "./components/OrderVehicleItem";
import { canShowApprovalButtons } from "./utils";

export function ApproverOrderItems({
  order,
  isLoading,
  isAirwayItemExpanded,
  isVehicleItemExpanded,
  isHotelItemExpanded,
  onRemakeOrder,
  onOpenRoadDetails,
  isAdditionalInfoExpanded,
  toggleAdditionalInfoVisible,
  toggleAirwayItemVisible,
  toggleVehicleItemVisible,
  toggleHotelItemVisible,
  toggleRoadItemVisible,
  onOpenHotelDetails,
  onOpenHotelPolicies,
  onOpenFlightDetails,
  onReloadAirwayTracker,
  onOpenHotelOptionDetails,
  onOpenAirwaySeats,
  onRemoveSeat,
}: UseOrderItemsResult) {
  const airwayIsRejected = isAirwayRejected(order);
  const hotelIsRejected = isHotelRejected(order);
  const vehicleIsRejected = isVehicleRejected(order);
  const roadIsRejected = isRoadRejected(order);
  const canceled = order?.status === OrderStatus.CANCELED;

  const { user, contexts } = useUser();

  const airway = getAirwayFrom({ order });
  const hotel = getHotelFrom({ order });
  const vehicle = getVehicleFrom({ order });
  const road = getRoadFrom({ order });

  const shouldShowApprovalButtons = canShowApprovalButtons(order);

  const isIssuer = userIsIssuer(user, order, contexts);
  const isApprover = userIsOrderApprover(order);

  const canShowAdditionalInfo =
    ![OrderStatus.OPEN, OrderStatus.CANCELED, OrderStatus.REJECTED].includes(
      order?.status as OrderStatus
    ) &&
    (isIssuer || isApprover);

  const renderAirwayItem = useCallback(
    (itemIndex: number) => (
      <OrderItemListItem
        id={`item-${itemIndex}`} 
        className="item-card" 
        tabIndex={-1}
        isOpen={!canceled}
        onItemExpand={toggleAirwayItemVisible}
        item={{
          type: OrderItems.AIRWAY,
          isRejected: airwayIsRejected,
        }}
        key={OrderItems.AIRWAY}
      >
        <OrderAirwayItem
          itemIndex={itemIndex}
          canShowApprovalButtons={shouldShowApprovalButtons}
          isLoading={isLoading}
          data={airway}
          onOpenDetails={onOpenFlightDetails}
          onRemakeOrder={onRemakeOrder}
          onReloadTracker={onReloadAirwayTracker}
          onOpenAirwaySeats={onOpenAirwaySeats}
          onRemoveSeat={onRemoveSeat}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      isAirwayItemExpanded,
      toggleAirwayItemVisible,
      onOpenFlightDetails,
      onReloadAirwayTracker,
      shouldShowApprovalButtons,
      airwayIsRejected,
      airway,
      onOpenAirwaySeats,
    ]
  );

  const renderHotelItem = useCallback(
    (itemIndex: number) => (
      <OrderItemListItem
        id={`item-${itemIndex}`} 
        className="item-card" 
        tabIndex={-1}
        isOpen={isHotelItemExpanded}
        onItemExpand={toggleHotelItemVisible}
        item={{
          type: OrderItems.HOTEL,
          isRejected: hotelIsRejected,
        }}
        key={OrderItems.HOTEL}
      >
        <OrderHotelItem
          itemIndex={itemIndex}
          onOpenOptionDetails={onOpenHotelOptionDetails}
          canShowApprovalButtons={shouldShowApprovalButtons}
          isLoading={isLoading}
          data={hotel}
          onOpenDetails={onOpenHotelDetails}
          onOpenPolicies={onOpenHotelPolicies}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      isHotelItemExpanded,
      toggleHotelItemVisible,
      onOpenHotelDetails,
      onOpenHotelPolicies,
      onOpenHotelOptionDetails,
      shouldShowApprovalButtons,
      hotelIsRejected,
      hotel,
    ]
  );

  const renderVehicleItem = useCallback(
    (itemIndex: number) => (
      <OrderItemListItem
        id={`item-${itemIndex}`} 
        className="item-card" 
        tabIndex={-1}
        isOpen={isVehicleItemExpanded}
        onItemExpand={toggleVehicleItemVisible}
        item={{
          type: OrderItems.VEHICLE,
          isRejected: vehicleIsRejected,
        }}
        key={OrderItems.VEHICLE}
      >
        <OrderVehicleItem
          itemIndex={itemIndex}
          canShowApprovalButtons={shouldShowApprovalButtons}
          isLoading={isLoading}
          data={vehicle}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      isVehicleItemExpanded,
      toggleVehicleItemVisible,
      shouldShowApprovalButtons,
      vehicleIsRejected,
      vehicle,
    ]
  );
  
  const renderRoadItem = useCallback(
    (itemIndex: number) => (
      <OrderItemListItem
        id={`item-${itemIndex}`} 
        className="item-card" 
        tabIndex={-1}
        isOpen={!canceled}
        onItemExpand={toggleRoadItemVisible}
        item={{
          type: OrderItems.ROAD,
          isRejected: roadIsRejected,
        }}
        key={OrderItems.ROAD}
        canceled={canceled}
      >
        <OrderRoadItem
          itemIndex={itemIndex}
          isLoading={isLoading}
          data={road}
          canShowApprovalButtons={shouldShowApprovalButtons}
          onOpenDetails={onOpenRoadDetails}
        />
      </OrderItemListItem>
    ),
    [
      isLoading,
      toggleRoadItemVisible,
      shouldShowApprovalButtons,
      roadIsRejected,
      road,
      onOpenRoadDetails,
    ]
  );

  const renderAdditionalInfo = useCallback(
    () => (
      <AdditionalInfoListItem
        isOpen={isAdditionalInfoExpanded}
        onExpand={toggleAdditionalInfoVisible}
      >
        <OrderAdditionalInfoItem order={order} />
      </AdditionalInfoListItem>
    ),
    [isAdditionalInfoExpanded, toggleAdditionalInfoVisible, order]
  );

  return (
    <Flex direction="column" gap="8">
      {order?.itemsIncluded.map(
        (item, i) =>
          ({
            [OrderItems.AIRWAY]: renderAirwayItem(i),
            [OrderItems.HOTEL]: renderHotelItem(i),
            [OrderItems.VEHICLE]: renderVehicleItem(i),
            [OrderItems.ROAD]: renderRoadItem(i),
            [OrderItems.HOTEL_OFFLINE]: null,
            [OrderItems.ADVANCE]: null,
          }[item])
      )}

      {canShowAdditionalInfo && renderAdditionalInfo()}
    </Flex>
  );
}
