import * as React from "react";
import { SVGProps } from "react";

const SvgExpenses = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.625 5.625a.625.625 0 1 0-1.25 0h1.25Zm-1.25 1.25a.625.625 0 1 0 1.25 0h-1.25Zm1.25 6.25a.625.625 0 1 0-1.25 0h1.25Zm-1.25 1.25a.625.625 0 1 0 1.25 0h-1.25ZM8.125 12.5a.625.625 0 1 0 0 1.25V12.5Zm2.813-2.5v.625V10Zm.937-2.5a.625.625 0 1 0 0-1.25V7.5Zm-2.5-1.875v1.25h1.25v-1.25h-1.25Zm0 7.5v1.25h1.25v-1.25h-1.25Zm7.5-3.125A6.875 6.875 0 0 1 10 16.875v1.25A8.125 8.125 0 0 0 18.125 10h-1.25ZM10 16.875A6.875 6.875 0 0 1 3.125 10h-1.25A8.125 8.125 0 0 0 10 18.125v-1.25ZM3.125 10A6.875 6.875 0 0 1 10 3.125v-1.25A8.125 8.125 0 0 0 1.875 10h1.25ZM10 3.125A6.875 6.875 0 0 1 16.875 10h1.25A8.125 8.125 0 0 0 10 1.875v1.25ZM8.125 13.75h2.813V12.5H8.124v1.25Zm2.813 0c.58 0 1.136-.23 1.546-.64l-.884-.885a.938.938 0 0 1-.662.275v1.25Zm1.546-.64c.41-.41.641-.967.641-1.547h-1.25a.938.938 0 0 1-.275.662l.884.884Zm.641-1.547c0-.58-.23-1.137-.64-1.547l-.885.884a.938.938 0 0 1 .275.662h1.25Zm-.64-1.547c-.41-.41-.967-.641-1.547-.641v1.25c.248 0 .487.099.662.275l.884-.884Zm-1.547-.641H9.062v1.25h1.876v-1.25Zm-1.876 0A.937.937 0 0 1 8.4 9.1l-.884.884c.41.41.966.641 1.546.641v-1.25ZM8.4 9.1a.937.937 0 0 1-.275-.662h-1.25c0 .58.23 1.136.64 1.546L8.4 9.1Zm-.275-.662c0-.25.099-.488.275-.663l-.884-.884c-.41.41-.641.966-.641 1.546h1.25Zm.275-.663a.937.937 0 0 1 .662-.275V6.25c-.58 0-1.136.23-1.546.64l.884.885Zm.662-.275h2.813V6.25H9.062V7.5Z" />
  </svg>
);

export default SvgExpenses;
