import { QueryClientProvider } from "@tanstack/react-query";
import { FC, ReactNode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppErrorBoundary } from "~/containers/AppErrorBoundary";
import { queryClient } from "~/services/queryClient";
import { AuthProvider } from "../AuthProvider";
import { LogTagProvider } from "../LogTagProvider";
import { UserProvider } from "../UserProvider";

export type AppProviderProps = {
  children?: ReactNode;
};

const LOG_TAG = "App";

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  return (
    <Router>
      <AppErrorBoundary>
        <LogTagProvider logTag={LOG_TAG}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <UserProvider>{children}</UserProvider>
            </AuthProvider>
          </QueryClientProvider>
        </LogTagProvider>
      </AppErrorBoundary>
    </Router>
  );
};

AppProvider.displayName = "AppProvider";
