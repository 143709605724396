import { FC } from "react";
import { DateUtils } from "~/application/utils";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClock } from "~/components/Icon/icons";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import { AirwayTimerSectionUIProps } from "./types";

export const AirwayTimerSectionUI: FC<AirwayTimerSectionUIProps> = ({
  time,
  isLoading,
  isMobile,
}) => {
  return isMobile ? (
    <Cart
      css={{
        p: "$3",
        border: "1px solid",
        borderColor: "$neutrals-light",
      }}
    >
      <Flex as={CardBody} direction="column" gap="3">
        {isLoading || time === null ? (
          <>
            <Skeleton variant="text-4" />
            <Skeleton variant="text-6" />
          </>
        ) : (
          <Flex align="center" justify="center" gap="3" direction="column">
            <Text as="p">Sua sessão irá expirar em:</Text>
            <Flex align="center" justify="center" gap="3">
              <Icon as={SvgClock} size="lg" />
              <Text size="6" fw="600">
                {DateUtils.timestamp(time)}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Cart>
  ) : (
    <Cart>
      <CartHeader>
        <Icon as={SvgClock} size="sm" />
        <Text>Tempo de sessão</Text>
      </CartHeader>

      <Flex as={CardBody} direction="column" gap="3">
        {isLoading || time === null ? (
          <>
            <Skeleton variant="text-4" />
            <Skeleton variant="text-6" />
          </>
        ) : (
          <>
            <Text as="p" css={{ mb: "$3" }}>
              Sua sessão irá expirar em:
            </Text>
            <Flex align="center" justify="center" gap="5">
              <Icon as={SvgClock} size="lg" />
              <Text size="6">{DateUtils.timestamp(time)}</Text>
            </Flex>
          </>
        )}
      </Flex>
    </Cart>
  );
};

AirwayTimerSectionUI.displayName = "AirwayTimerSectionUI";
