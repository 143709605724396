import { useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FlightSegment, OrderStatus } from "~/application/types";
import { NavigatorUtils } from "~/application/utils";
import { Alert } from "~/components/Alert";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgAlertTriangle, SvgCheck, SvgCopy, SvgRemove } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H5, Label } from "~/components/Typography";
import { TrackDots } from "~/presentation/shared/components/Track";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";
import { OrderApprovalItem, getAirlineUrl } from "~/presentation/shared/utils";
import * as DateUtils from "~/utils/date.utils";
import { CombinedOrderAirwayItemSegmentType } from "..";
import { ApprovalOrderContext } from "../../../hooks/ApprovalOrderContext";
import { ApprovalButtons } from "../../ApprovalButtons";
import { OrderAirwayCardProps } from "../OrderAirwayCard";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { AirwayPassengerType } from "~/core/modules/Agency/pages/AgencyOrderPage/components/Airway";
import { useOrder } from "~/presentation/shared/hooks/useOrder";

export type CombinedOrderAirwayItemCardProps = Omit<
  OrderAirwayCardProps,
  "canReloadTracker" | "data"
> & { data: CombinedOrderAirwayItemSegmentType };

export function CombinedOrderAirwayItemCard({
  data,
  onOpenDetails,
}: CombinedOrderAirwayItemCardProps) {
  const navigate = useNavigate();
  const { orderId } = useParams() as { orderId: string };
  const { order } = useOrder();

  const isMobile = useMobile();

  const goFlight = data.segments[0].departureFlightInfo;
  const returnFlight = data.segments?.at(data.segments?.length - 1)?.arrivalFlightInfo;

  const goFlightCombined = data.combined?.at(0)?.departureFlightInfo;
  const returnFlightCombined = data.combined?.at(data.combined?.length - 1)?.arrivalFlightInfo;

  const goFlightName = goFlight?.airport.split(" - ");
  const returnFlightName = returnFlight?.airport.split(" - ");
  const goFlightNameCombined = goFlightCombined?.airport.split(" - ");
  const returnFlightNameCombined = returnFlightCombined?.airport.split(" - ");

  const item = {
    item: OrderApprovalItem.AIRWAY_ORDERS,
    itemOrderId: data.uuid,
    combinedItemAirway: data.uuid2,
  };

  const { approveItem, reproveItem } = useContext(ApprovalOrderContext);
  const airline = data.segments[0].airline;
  const canShowTracker = data.tracker && data.tracker.toLowerCase() !== "gerando localizador";

  const onCopyText = useCallback((value: string) => NavigatorUtils.copyToClipboard(value), []);

  const renderCopyTracker = useCallback(
    (tracker: string) => (
      <Tooltip content={<TooltipLabel>Copiar localizador</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(tracker)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  function hasAirportChangeInConnections(segments: FlightSegment[]): boolean {
    return segments.some(
      (segment, index) =>
        index < segments.length - 1 &&
        segment.arrivalFlightInfo.airportIata !==
          segments[index + 1].departureFlightInfo.airportIata
    );
  }

  function navigateToCheapestFlights() {
    navigate(
      `/pedidos/${orderId}/voos-mais-baratos?departureId=${data.uuid}&arrivalId=${data.uuid2}`
    );
  }

  const showAirportChangeAlert =
    data.segments.length > 1 && hasAirportChangeInConnections(data.segments);

  const showAlertUnflownAirway =
    data?.passengers?.some((passenger: AirwayPassengerType) => passenger?.unflownAirway?.uuid) &&
    order?.status === OrderStatus.QUOTING;

  return (
    <>
      {showAirportChangeAlert && (
        <Alert variant="warning">
          <Flex direction="column" gap="3">
            <Text variant="warning-dark">Atenção: Troca de aeroporto necessária</Text>
            <Text css={{ lineHeight: "$6" }}>
              Você precisará se deslocar entre diferentes aeroportos durante sua conexão. Planeje
              tempo suficiente para o trajeto.
            </Text>
          </Flex>
        </Alert>
      )}

      <Col>
        <Card>
          <Flex css={{ p: "$4" }} gap="4" align="center">
            <Col>
              <CompanyLogo src={getAirlineUrl(airline)} />
            </Col>

            {data.expiresAt.toString().toLowerCase() !== "invalid date" && (
              <Text size="2" variant="darkest" css={{ fw: "600" }}>
                Expira em{" "}
                {DateUtils.displayDate(data.expiresAt, DateUtils.DateFormats.SMALL_DATE_TIME)}
              </Text>
            )}

            <Text variant="darkest" css={{ fw: "600" }}>
              <Flex align="center">
                {data.tracker && data.tracker}
                {canShowTracker && renderCopyTracker(data.tracker as string)}
              </Flex>
            </Text>
          </Flex>
        </Card>
      </Col>
      {showAlertUnflownAirway && (
        <Alert variant="warning">
          <Flex
            align="center"
            justify="center"
            css={{
              width: "100%",
            }}
            gap="4"
          >
            <Icon as={SvgAlertTriangle} />
            <Text>Solicitação de remarcação de crédito</Text>
          </Flex>
        </Alert>
      )}

      <Col>
        <Card
          css={{
            overflow: "visible",
            "@mxlg": {
              border: "0",
            },
          }}
        >
          <Flex direction="column" as={CardBody} gap="6" css={{ "@mxlg": { py: 20, px: 10 } }}>
            <Flex direction={{ "@mxlg": "column" }} align="start" justify="between" gap="6">
              <Flex justify={{ "@mxlg": "between" }} css={{ "@mxlg": { width: "100%" } }}>
                <CompanyLogo src={`/images/airlines/${airline?.toLowerCase()}.png`} alt={airline} />
                {isMobile && (
                  <Button
                    variant="tertiary"
                    onClick={() => onOpenDetails(data)}
                    css={{
                      "@mxlg": {
                        p: "$3",
                        height: "$5",
                      },
                    }}
                  >
                    <Text
                      css={{
                        "@mxlg": {
                          fontSize: "12px",
                        },
                      }}
                    >
                      Ver detalhes
                    </Text>
                  </Button>
                )}
              </Flex>

              <Flex align="center" gap="2" direction={{ "@mxlg": "column" }} css={{ "@mxlg": { width: "100%" }}}>
                {!!data.violatedPolicies?.length && (
                  <ViolatedPoliciesButton data={data.violatedPolicies} />
                )}
                <ApprovalButtons approveItem={approveItem} reproveItem={reproveItem} item={item} />
                <Flex align="center" gap="2" wrap="wrap"  css={{ "@mxlg": { width: "100%" }}}>
                  {data.isTheCheapestFlight ? (
                    <Button  css={{ "@mxlg": { width: "100%" }}} variant="success-light" onClick={navigateToCheapestFlights}>
                      <Icon as={SvgCheck} />
                      <Text>Fez a melhor escolha</Text>
                    </Button>
                  ) : (
                    <Button  css={{ "@mxlg": { width: "100%" }}} variant="error-light" onClick={navigateToCheapestFlights}>
                      <Icon as={SvgRemove} />
                      <Text>Melhor opção disponível</Text>
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>

            <Flex
              css={{
                gap: "8rem",
              }}
            >
              <Flex direction="column" gap="4">
                <Flex align="start" direction="column" gap="4">
                  <Label>Saída</Label>

                  <Flex direction="column" gap="2">
                    <H5 size={{ "@initial": "5", "@mxlg": "3" }}>{goFlightName?.at(0)}</H5>
                    <Text size={{ "@initial": "3", "@mxlg": "1" }}>{goFlightName?.at(1)}</Text>
                  </Flex>

                  <Text
                    size="3"
                    css={{
                      "@mxlg": {
                        display: "none",
                      },
                    }}
                  >
                    {DateUtils.displayDate(goFlight.dateTime)}
                  </Text>

                  <Label>{DateUtils.toHour12(goFlight.dateTime)}</Label>
                </Flex>
                <Flex align="start" direction="column" gap="4">
                  <Label>Saída</Label>

                  <Flex direction="column" gap="2">
                    <H5 size={{ "@initial": "5", "@mxlg": "3" }}>{goFlightNameCombined?.at(0)}</H5>
                    <Text size={{ "@initial": "3", "@mxlg": "1" }}>
                      {goFlightNameCombined?.at(1)}
                    </Text>
                  </Flex>

                  <Text
                    size="3"
                    css={{
                      "@mxlg": {
                        display: "none",
                      },
                    }}
                  >
                    {DateUtils.displayDate(goFlightCombined!.dateTime)}
                  </Text>

                  <Label>{DateUtils.toHour12(goFlightCombined!.dateTime)}</Label>
                </Flex>
              </Flex>

              <Flex
                direction="column"
                css={{
                  gap: "4.4rem",
                  "@mxlg": {
                    display: "none",
                  },
                }}
              >
                <Flex align="start" direction="column" gap="6" justify="between">
                  <Label>Duração</Label>

                  <TrackDots numberOfDots={data.segments.length - 1} />

                  <Label>
                    {DateUtils.timeInterval(goFlight.dateTime, returnFlight?.dateTime as Date)}
                  </Label>
                </Flex>

                <Flex align="start" direction="column" gap="6" justify="between">
                  <Label>Duração</Label>

                  <TrackDots numberOfDots={data.segments.length - 1} />

                  <Label>
                    {DateUtils.timeInterval(
                      goFlightCombined?.dateTime as Date,
                      returnFlightCombined?.dateTime as Date
                    )}
                  </Label>
                </Flex>
              </Flex>
              <Flex direction="column" gap="4">
                {returnFlight && (
                  <Flex align="start" direction="column" gap="4">
                    <Label>Chegada</Label>

                    <Flex direction="column" gap="2">
                      <H5 size={{ "@initial": "5", "@mxlg": "3" }}>{returnFlightName?.at(0)}</H5>
                      <Text size={{ "@initial": "3", "@mxlg": "1" }}>
                        {returnFlightName?.at(1)}
                      </Text>
                    </Flex>

                    <Text
                      size="3"
                      css={{
                        "@mxlg": {
                          display: "none",
                        },
                      }}
                    >
                      {DateUtils.displayDate(returnFlight.dateTime)}
                    </Text>

                    <Label>{DateUtils.toHour12(returnFlight.dateTime)}</Label>
                  </Flex>
                )}
                {returnFlightCombined && (
                  <Flex align="start" direction="column" gap="4">
                    <Label>Chegada</Label>

                    <Flex direction="column" gap="2">
                      <H5 size={{ "@initial": "5", "@mxlg": "3" }}>
                        {returnFlightNameCombined?.at(0)}
                      </H5>
                      <Text size={{ "@initial": "3", "@mxlg": "1" }}>
                        {returnFlightNameCombined?.at(1)}
                      </Text>
                    </Flex>

                    <Text
                      size="3"
                      css={{
                        "@mxlg": {
                          display: "none",
                        },
                      }}
                    >
                      {DateUtils.displayDate(returnFlightCombined.dateTime)}
                    </Text>

                    <Label>{DateUtils.toHour12(returnFlightCombined.dateTime)}</Label>
                  </Flex>
                )}
              </Flex>
              <Flex
                direction="column"
                css={{
                  gap: "6.7rem",
                  "@mxlg": {
                    display: "none",
                  },
                }}
              >
                <Flex align="start" direction="column" gap="4">
                  <Label>Tarifa</Label>
                  <Label>{data.familyFare}</Label>
                </Flex>
                <Flex align="start" direction="column" gap="4">
                  <Label>Tarifa</Label>
                  <Label>{data.familyFare2}</Label>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Col>
    </>
  );
}
