import { useMutation } from "@tanstack/react-query";
import { MouseEventHandler, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderItemsWithChangedPrice, OrderStatus, User } from "~/application/types";
import { orderService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { Button } from "~/components/Button";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { Text } from "~/components/Text";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { useIssueOrderChannel } from "~/presentation/shared/hooks/useIssueOrderChannel";
import { useOrder } from "~/presentation/shared/hooks/useOrder/useOrder";
import { OrderApprovalItem, consultOrderItemsPriceChange } from "~/presentation/shared/utils";
import { logError } from "~/presentation/shared/utils/errors";
import { orderApprovalService } from "~/services/resources/OrderApproval";
import {
  IOrderApprovalParams,
  OrderItem,
} from "~/services/resources/OrderApproval/IOrderApprovalService";
import { ApprovalOrderState } from "./ApprovalOrderContext";
import { queryClient } from "~/services/queryClient";
import { QueryKeys } from "~/constants";

export interface HandleJudgmentParams {
  orderId?: string;
  rejectedItems?: IOrderApprovalParams["rejectedItems"];
}

export interface UseApprovalOrderResult {
  user: User;
  items: ApprovalOrderState["items"];
  isJudging: boolean;
  amountOfItems: number;
  isRejectingOrderDuePriceChange: boolean;
  cannotApproveItems: boolean;
  cannotReproveItems: boolean;
  isSuccessfulJudgment: boolean;
  handleJudgment: (data?: HandleJudgmentParams) => void;
  approveItem: (data: OrderItem) => void;
  reproveItem: (data: OrderItem) => void;
  setItems: (items: ApprovalOrderState["items"]) => void;
  onOrderItemsPriceChange: (items: OrderItemsWithChangedPrice) => void;
  onConsultOrderItemsPrice: (orderUuid?: string) => Promise<OrderItemsWithChangedPrice>;
}

export const LOG_TAG = "Order/OrderPage/useApprovalOrder";
export const SNACKBAR_MESSAGES = {
  ORDER_REJECTED_DUE_PRICE_CHANGE_MESSAGE: "Pedido rejeitado devido mudança de preço",
  ORDER_CANCEL_SUCCESS_MESSAGE: "Pedido cancelado com successo",
  ORDER_CANCEL_ERROR_MESSAGE: "Falha ao cancelar pedido",
  ORDER_APPROVAL_SUCCESS_MESSAGE: "Itens do pedido aprovados",
  ORDER_APPROVAL_ERROR_MESSAGE: "Falha ao aprovar pedido",
  ORDER_REPROVAL_SUCCESS_MESSAGE: "Solicitado revisão de pedido",
  ORDER_REPROVAL_ERROR_MESSAGE: "Falha ao solicitar revisão de pedido",
  REQUEST_APPROVAL_SUCCESS_MESSAGE: "Solicitação de aprovação concluída com sucesso",
  REQUEST_APPROVAL_ERROR_MESSAGE: "Falha ao solicitar aprovação",
} as const;

export interface UseApprovalOrderProps {
  onOrderIssued?: () => void;
  refetchOrder?: () => void;
  refetchOrderHistory?: () => void;
}

export function useApprovalOrder({
  refetchOrder,
  refetchOrderHistory,
  onOrderIssued,
}: UseApprovalOrderProps): UseApprovalOrderResult {
  const { user } = useAuth();
  const { order } = useOrder();
  const { orderId } = useParams() as { orderId: string };

  const canIssueOrder =
    !!orderId &&
    [OrderStatus.OPEN, OrderStatus.REJECTED, OrderStatus.ON_APPROVAL].includes(
      order?.status as OrderStatus
    );

  useIssueOrderChannel({
    orderId,
    enabled: canIssueOrder,
    onOrderIssued,
  });

  const [state, setState] = useState<ApprovalOrderState>({
    isRejectingOrderDuePriceChange: false,
    items: {
      rejected: [],
      approved: [],
    },
  });

  const { items, isRejectingOrderDuePriceChange } = state;

  const setItems = (items: ApprovalOrderState["items"]) => {
    setState((old) => ({ ...old, items }));
  };

  const setIsRejectingOrderDuePriceChange = (isRejectingOrderDuePriceChange: boolean) => {
    setState((old) => ({ ...old, isRejectingOrderDuePriceChange }));
  };

  const reproveItem = useCallback((item: OrderItem) => {
    setState(({ items, ...restState }) => {
      const { combinedItemAirway } = item;
      const itemsIsAlreadyRejected = items.rejected.some(
        ({ itemOrderId }) => itemOrderId === item.itemOrderId
      );

      if (itemsIsAlreadyRejected) {
        return { items, ...restState };
      }

      let rejectedItems = [
        ...items.rejected,
        {
          ...item,
          reasonRejected: item.reasonRejected || "",
        },
      ];

      if (combinedItemAirway) {
        rejectedItems = [
          ...rejectedItems,
          {
            ...item,
            itemOrderId: combinedItemAirway,
            reasonRejected: item.reasonRejected || "",
          },
        ];
      }

      return {
        ...restState,
        items: {
          approved: items.approved.filter(
            ({ uuid }) => uuid !== item.itemOrderId && uuid !== item.combinedItemAirway
          ),
          rejected: rejectedItems,
        },
      };
    });
  }, []);

  const approveItem = useCallback(({ item, itemOrderId, combinedItemAirway }: OrderItem) => {
    setState(({ items, ...restState }) => {
      const itemIsAlreadyApproved = items.approved.some(({ uuid }) => uuid === itemOrderId);

      if (itemIsAlreadyApproved) {
        return { items, ...restState };
      }

      const rejectedItems = items.rejected.filter(
        ({ itemOrderId: id }) => itemOrderId !== id && id !== combinedItemAirway
      );

      let approved = [...items.approved, { uuid: itemOrderId, item }];

      if (combinedItemAirway) {
        approved = [...approved, { uuid: combinedItemAirway, item }];
      }

      return {
        ...restState,
        items: {
          approved,
          rejected: rejectedItems,
        },
      };
    });
  }, []);

  const userId = user.profiles.customer.uuid;

  const {
    mutateAsync: handleJudgment,
    isSuccess: isSuccessfulJudgment,
    isLoading: isJudging,
  } = useMutation(
    async () => {
      return orderApprovalService.approval({
        orderId,
        rejectedItems: items.rejected,
        reasonRejected: "",
        obs: "",
      });
    },
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Julgando pedido" />);
      },
      onSuccess: async (_, props: any) => {
        const rejectedItems = props?.rejectedItems;

        const isRejectingOrderDuePriceChange = rejectedItems?.some((r: any) => {
          return r?.reasonRejected === SNACKBAR_MESSAGES.ORDER_REJECTED_DUE_PRICE_CHANGE_MESSAGE;
        });

        if (isRejectingOrderDuePriceChange) {
          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.ORDER_REJECTED_DUE_PRICE_CHANGE_MESSAGE,
            "success"
          );

          dialogService.popAll();
          queryClient.invalidateQueries([QueryKeys.ORDERS]);
        } else if (items.rejected.length > 0) {
          snackbarService.showSnackbar(SNACKBAR_MESSAGES.ORDER_REPROVAL_SUCCESS_MESSAGE, "success");

          dialogService.popAll();
          queryClient.invalidateQueries([QueryKeys.ORDERS]);
        }

        const approvers = order?.approvalModel?.approvers || [];
        const approverIndex = approvers.findIndex((a) => a.uuid === userId);
        const approverLevel = approvers[approverIndex]?.level ?? 1;
        const thereIsNextLevelApprover = approvers.some((a) => a.level === approverLevel + 1);

        refetchOrder?.();
        refetchOrderHistory?.();
        dialogService.popAll();
      },
      onError: (error) => {
        if (items.rejected.length > 0) {
          logError({
            logTag: LOG_TAG,
            error,
            defaultErrorMessage: SNACKBAR_MESSAGES.ORDER_REPROVAL_ERROR_MESSAGE,
          });
        } else {
          logError({
            logTag: LOG_TAG,
            error,
            defaultErrorMessage: SNACKBAR_MESSAGES.ORDER_APPROVAL_ERROR_MESSAGE,
          });

          dialogService.popAll();
        }
      },
    }
  );

  const amountOfItems =
    (order?.items.airway?.flights.length ?? 0) +
    (order?.items.hotel?.rooms.length ?? 0) +
    (order?.items.road?.travels.length ?? 0) +
    (order?.items.vehicle?.vehicles.length ?? 0);

  const unableToApproveItems = () => {
    return isSuccessfulJudgment || items.approved.length !== amountOfItems;
  };

  const unableToReproveItems = () => {
    return (
      isSuccessfulJudgment ||
      items.rejected.length === 0 ||
      items.approved.length + items.rejected.length !== amountOfItems
    );
  };

  const onRejectOrderDuePriceChange = () => {
    const rejectedItems = order?.items.road?.travels.map((travel) => ({
      item: OrderApprovalItem.ROAD_ORDERS,
      itemOrderId: travel.uuid,
      reasonRejected: SNACKBAR_MESSAGES.ORDER_REJECTED_DUE_PRICE_CHANGE_MESSAGE,
    }));

    setIsRejectingOrderDuePriceChange(true);
    handleJudgment({ rejectedItems: rejectedItems || [] });
  };

  const onContinue: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    handleJudgment({ rejectedItems: [] });
  };

  const negativeButton = (
    <Button
      disabled={isJudging}
      variant="tertiary"
      type="reset"
      onClick={onRejectOrderDuePriceChange}
    >
      <Text>Cancelar</Text>
    </Button>
  );

  const positiveButton = (
    <Button disabled={isJudging} type="submit" onClick={onContinue}>
      <Text>Prosseguir</Text>
    </Button>
  );

  const { mutateAsync: onConsultOrderItemsPrice } = useMutation(
    async (orderUuid?: string) => {
      return await orderService.getOrderItemsPriceChange(orderUuid ?? orderId);
    },
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Verificando alteração de preço" />);
      },
      onError: (error) => log.e(LOG_TAG, error),
      onSettled: () => dialogService.popAll(),
    }
  );

  const { onOrderItemsPriceChange } = consultOrderItemsPriceChange({
    negativeButton,
    positiveButton,
  });

  return {
    user,
    items,
    isJudging,
    isSuccessfulJudgment,
    amountOfItems,
    isRejectingOrderDuePriceChange,
    cannotApproveItems: unableToApproveItems(),
    cannotReproveItems: unableToReproveItems(),
    setItems,
    approveItem,
    reproveItem,
    onOrderItemsPriceChange,
    handleJudgment: (data?: HandleJudgmentParams) => handleJudgment(data),
    onConsultOrderItemsPrice: (orderUuid = "") => {
      return onConsultOrderItemsPrice(orderUuid || orderId);
    },
  };
}
