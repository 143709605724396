import { Flight, FlightOriginType } from "~/application/types";
import { FlightDTO } from "../dtos";
import { mapFlightOptionDTO } from "./mapFlightOptionDTO";
import { mapFlightSegmentDTO } from "./mapFlightSegmentDTO";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapFlightDTO(data: FlightDTO, searchKey: string): Flight {
  const hasDiscount = data.discount && !Array.isArray(data.discount);

  return {
    searchKey,
    id: data.id,
    airline: data.airline,
    scales: data.scales,
    hash: data.hash,
    flightOrigin: data.flightOrigin.toLowerCase() as FlightOriginType,
    segments: data.segments.map(mapFlightSegmentDTO),
    minimumPrice: data.minimumPrice,
    discount: hasDiscount && data.discount,
    options: data.options.map(mapFlightOptionDTO),
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
  };
}
