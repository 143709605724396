import * as React from "react";
import { SVGProps } from "react";

const SvgBag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 1V0a1 1 0 0 0-.8.4L4 1ZM1 5l-.8-.6A1 1 0 0 0 0 5h1Zm0 14H0h1ZM19 5h1a1 1 0 0 0-.2-.6L19 5Zm-3-4 .8-.6A1 1 0 0 0 16 0v1Zm-1 8a1 1 0 1 0-2 0h2Zm-5 4v1-1ZM7 9a1 1 0 0 0-2 0h2ZM3.2.4l-3 4 1.6 1.2 3-4L3.2.4ZM0 5v14h2V5H0Zm0 14a3 3 0 0 0 .879 2.121l1.414-1.414A1 1 0 0 1 2 19H0Zm.879 2.121A3 3 0 0 0 3 22v-2a1 1 0 0 1-.707-.293L.879 21.121ZM3 22h14v-2H3v2Zm14 0a3 3 0 0 0 2.121-.879l-1.414-1.414A1 1 0 0 1 17 20v2Zm2.121-.879A3 3 0 0 0 20 19h-2a1 1 0 0 1-.293.707l1.414 1.414ZM20 19V5h-2v14h2Zm-.2-14.6-3-4-1.6 1.2 3 4 1.6-1.2ZM16 0H4v2h12V0ZM1 6h18V4H1v2Zm12 3a3 3 0 0 1-.879 2.121l1.415 1.415A5 5 0 0 0 15 9h-2Zm-.879 2.121A3 3 0 0 1 10 12v2a5 5 0 0 0 3.536-1.464L12.12 11.12ZM10 12a3 3 0 0 1-2.121-.879l-1.415 1.415A5 5 0 0 0 10 14v-2Zm-2.121-.879A3 3 0 0 1 7 9H5a5 5 0 0 0 1.464 3.536L7.88 11.12Z"
      fill="#079455"
    />
  </svg>
);

export default SvgBag;
