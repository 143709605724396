import { asCurrency } from "~/application/utils/mask-functions";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgArrowBack, SvgCurrencyDollar } from "~/components/Icon/icons";
import { Text } from "~/components/Text";

interface MetricItemProps {
  title: string;
  value: number;
  percentage?: {
    type: "saving" | "spending";
    value: number;
    message: string;
  };
}

export function MetricItem({ title, value, percentage }: MetricItemProps) {
  return (
    <Card css={{ height: "100%", minHeight: "11rem", border: "none" }}>
      <CardBody>
        <Flex direction="column" gap="6">
          <Text css={{ fw: "600", lineHeight: "$6" }}>{title}</Text>

          <Flex direction="column" gap="4">
            <Text css={{ fontSize: "2.25rem", fw: "600", lineHeight: "$11", letterSpacing: "-0.02em" }}>
              {asCurrency(value)}
            </Text>

            {percentage && (
              <Flex gap="2">
                <Flex align="center">
                  {percentage.type === "saving" ? (
                    <>
                      <Icon variant="success" as={SvgArrowBack} css={{ transform: "rotate(-90deg)" }} />
                      <Text variant="sucess" size="3" css={{ fw: "500", lineHeight: "$5" }}>{percentage.value.toFixed(2)}%</Text>
                    </>
                  ) : (
                    percentage.value == 0 ? <Icon variant="success" as={SvgCurrencyDollar} /> : (
                      <>
                        <Icon variant="error" as={SvgArrowBack} css={{ transform: "rotate(90deg)" }} />
                        <Text variant="error-base" size="3" css={{ fw: "500", lineHeight: "$5" }}>{percentage.value.toFixed(2)}%</Text>
                      </>
                    )
                  )}
                </Flex>

                <Text variant="darkest" size="3" css={{ fw: "500", lineHeight: "$5" }}>
                  {percentage.message}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  )
}