import {
  CustomerEmployee,
  OfflineHotelAmenity,
  OfflineHotelQuery,
  OrderItems,
} from "~/application/types";
import { OfflineHotelMenuLayout } from "../../components/OfflineHotelMenuLayout";
import { OfflineHotelQueryForm } from "../../components/OfflineHotelQueryForm";

export interface OfflineHotelQueryContainerProps {
  amenities?: OfflineHotelAmenity[];
  isLoadingAmenities: boolean;
  defaultQuery: Partial<OfflineHotelQuery> | null;
  defaultTravelers?: CustomerEmployee[];
  fetchTravelersByName: (name: string) => Promise<CustomerEmployee[]>;
  onSubmit: (data: OfflineHotelQuery) => void;
  onCreateCustomerEmployee: () => void;
  setQuantityRooms: (quantity: number) => void;
  fetchIssuers: (name: string) => Promise<CustomerEmployee[]>;
  setIssuer: (customer: CustomerEmployee) => void;
  issuer?: CustomerEmployee[];
}

export function OfflineHotelQueryContainer({
  defaultQuery,
  ...props
}: OfflineHotelQueryContainerProps) {
  return (
    <OfflineHotelMenuLayout activeTab={OrderItems.HOTEL} title="Solicitação de hospedagem offline">
      <OfflineHotelQueryForm defaultData={defaultQuery} {...props} />
    </OfflineHotelMenuLayout>
  );
}
