import { css, keyframes } from "~/application/theme";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY($space$2)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-$space$2)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-$space$2)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX($space$2)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

export const contentStyle = css({
  // Reset
  boxSizing: "border-box",

  // Custom
  overflow: "hidden",
  zIndex: "$dropdown",
  minWidth: 224,
  backgroundColor: "$neutrals-white",
  boxShadow: "$lg",
  borderRadius: "$md",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "$neutrals-light",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});
