import queryString from "query-string";
import type { Airport } from "~/application/types";
import { api } from "~/infrastructure/api";
import { AirportDTO } from "~/infrastructure/api/dtos";
import { mapAirportDTO } from "~/infrastructure/api/mappers";
import type { IAirportService, IFindAirportParams } from "./IAirportService";

export class AirportService implements IAirportService {
  async find({ name }: IFindAirportParams): Promise<Airport[]> {
    const url = queryString.stringifyUrl({
      url: `/airports/${name}`,
      query: {},
    });

    return await api
      .get<AirportDTO[]>(url)
      .then(({ data }) => data.map(mapAirportDTO));
  }
}
