import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Skeleton } from "~/components/Skeleton";

export interface OrderVehicleItemSkeletonProps {}

export function OrderVehicleItemSkeleton() {
  return (
    <Flex direction="column" gap="8">
      <Flex direction="column" gap="6">
        <Skeleton variant="text-6" />

        <Skeleton variant="fluid" style={{ height: "186px" }} />
      </Flex>

      <Flex direction="column" gap="6">
        <Skeleton variant="text-6" />

        <Grid columns="2" gap="6">
          <Card>
            <Flex direction="column" as={CardBody} gap="4">
              <Skeleton variant="text-4" />

              <Skeleton variant="text-4" />

              <Skeleton variant="text-4" />
            </Flex>
          </Card>

          <Card>
            <Flex direction="column" as={CardBody} gap="4">
              <Skeleton variant="text-4" />

              <Skeleton variant="text-4" />

              <Skeleton variant="text-4" />
            </Flex>
          </Card>
        </Grid>
      </Flex>

      <Flex direction="column" gap="6">
        <Skeleton variant="text-6" />

        <Skeleton variant="fluid" style={{ height: "88px" }} />
      </Flex>
    </Flex>
  );
}

OrderVehicleItemSkeleton.displayName = "OrderVehicleItemSkeleton";
