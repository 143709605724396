import queryString from "query-string";

import { api } from "~/infrastructure/api";
import { CredentialDTO } from "~/infrastructure/api/dtos/CredentialDTO";
import {
  mapBindCredentialDTO,
  mapCredentialDTO,
  mapCustomerCredentialDTO,
} from "~/infrastructure/api/mappers/mapCredentialDTO";
import { Credential, CredentialCustomer } from "~/application/types/entities/Credential.type";
import { PaginatedResource } from "~/application/types";
import {
  AgreementData,
  FindCredentialsProps,
  ICredentialService,
  SubmissionData,
} from "./ICredentialService";
import { Agreement } from "~/application/types/entities/BindCredential.type";

export class CredentialService implements ICredentialService {
  async findByProvider(providerId: string) {
    return await api
      .get(`/credentials/providers/${providerId}/parameters`)
      .then(({ data }) => data);
  }

  async find({
    page = 1,
    provider,
    name,
  }: FindCredentialsProps): Promise<PaginatedResource<Credential[]>> {
    const url = queryString.stringifyUrl({
      url: `/credentials`,
      query: { page, name },
    });

    return await api.get<PaginatedResource<CredentialDTO[]>>(url).then(({ data }) => ({
      meta: data.meta,
      links: data.links,
      data: data.data.map(mapCredentialDTO),
    }));
  }
  async customers({
    credentialId,
    trading_name,
    page = 1,
  }: {
    credentialId: string;
    page: number;
    trading_name: string;
  }): Promise<PaginatedResource<CredentialCustomer[]>> {
    const url = queryString.stringifyUrl({
      url: `/credentials/${credentialId}/customers`,
      query: { page, trading_name },
    });
    return await api.get(url).then(({ data }) => ({
      meta: data.meta,
      links: data.links,
      data: data.data.map(mapCustomerCredentialDTO),
    }));
  }

  async agreements(customerId: string): Promise<Agreement[]> {
    return await api
      .get(`/customers/${customerId}/agreements`)
      .then(({ data }) => data.data.map(mapBindCredentialDTO));
  }

  async updateAgreement(data: AgreementData): Promise<Agreement> {
    return await api
      .put(`customers/agreements/${data.agreementId}`, {
        credential_uuid: data.credentialId,
        agreement_comparation_uuid: data.agreementComparationId,
        code_agreement: data.codeAgreement,
      })
      .then(({ data }) => data);
  }

  async active(credentialId: string): Promise<Credential[]> {
    return await api.patch(`/credentials/${credentialId}/active`).then(({ data }) => data);
  }

  async toggleAgreement({
    agreementId,
    isActive,
  }: {
    agreementId: string;
    isActive: boolean;
  }): Promise<void> {
    return await api
      .patch(`customers/agreements/${agreementId}/${isActive ? "inactive" : "active"}`)
      .then(({ data }) => data);
  }

  async update(payload: SubmissionData): Promise<Credential[]> {
    return await api
      .put(`credentials/`, {
        description: payload.description,
        identifier: payload.identifier,
        service_type: payload.serviceType,
        credentials: payload.payload,
      })
      .then(({ data }) => data);
  }

  async inactive(credentialId: string): Promise<Credential[]> {
    return await api.patch(`/credentials/${credentialId}/inactive`).then(({ data }) => data);
  }

  async bind(data: AgreementData): Promise<AgreementData[]> {
    return await api
      .post(`customers/${data.customerId}/agreements`, {
        credential_uuid: data.credentialId,
        agreement_comparation_uuid: data.agreementComparationId,
        code_agreement: data.codeAgreement,
      })
      .then(({ data }) => data);
  }

  async create(parameters: SubmissionData): Promise<Credential[]> {
    return await api
      .post(`/credentials/providers/${parameters.providerId}`, {
        description: parameters.description,
        service_type: parameters.serviceType,
        identifier: parameters.identifier,
        parameters: parameters.payload.map((param) => ({
          credential_parameter_uuid: param.credentialParameterId,
          value: param.value,
        })),
      })
      .then(({ data }) => data);
  }
}
