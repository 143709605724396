import { useReducer } from "react";
import type { RoadBookingInfo } from "~/presentation/Booking/BookingRoad/types";
import type { RoadBookingDispatchAction, RoadReducer } from "./types";

const initialState: RoadBookingInfo = {
  goRoadSelected: null,
  returnRoadSelected: null,
  travelers: [],
  seatsQuantity: 0,
  stretches: [],
  price: {
    fees: 0,
    individualValue: 0,
    totalTaxesValue: 0,
    totalValue: 0,
  },
  query: undefined,
};

export function roadBookingReducer(
  state: RoadBookingInfo,
  action: RoadBookingDispatchAction
): RoadBookingInfo {
  switch (action.type) {
    case "SET_GO_ROAD":
      return {
        ...state,
        goRoadSelected: action.payload,
        returnRoadSelected: null,
        seatsQuantity: action.payload.seats.length,
      };
    case "SET_STRETCH": {
      return {
        ...state,
        seatsQuantity: Number(action.payload.seats.length),
        stretches: [...state.stretches, action.payload],
      };
    }
    case "UPDATE_STRETCH": {
      return {
        ...state,
        seatsQuantity: Number(action.payload.seats.length),
        stretches:
          !action.payload.index &&
          action.payload.seats.length !== state.stretches?.at(0)?.seats.length
            ? [action.payload]
            : state.stretches.map((stretchItem, index) =>
                index === action.payload.index ? action.payload : stretchItem
              ),
      };
    }

    case "SET_RETURN_ROAD":
      return {
        ...state,
        returnRoadSelected: action.payload,
      };
    case "SET_ISSUER":
      return {
        ...state,
        issuerId: action.payload,
      };
    case "SET_TRAVELERS":
      return {
        ...state,
        travelers: action.payload,
      };
    case "CLEAR":
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export function useRoadReducer(): RoadReducer {
  const [state, dispatch] = useReducer(roadBookingReducer, initialState);

  return { bookingInfo: state, dispatch };
}
