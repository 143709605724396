import { User } from "~/application/types";
import { UserDTO } from "../dtos";
import { mapAgencyDTO } from "./mapAgencyDTO";
import { mapCustomerDTO } from "./mapCustomerDTO";

export function mapUserDTO(data: UserDTO): User {
  return {
    uuid: data.uuid,
    context: data.context,
    agency: data.agency ? mapAgencyDTO(data.agency) : null!,
    customer: data.customer ? mapCustomerDTO(data.customer) : null!,
    email: data.email,
    type: data.type,
    name: data.name,
    phone: data.phone,
    profiles: {
      agency: {
        uuid: data.agency?.agency_employee.uuid,
        name: data.agency?.agency_employee.name,
      },
      customer: {
        uuid: data.customer?.customer_employee.uuid,
        name: data.customer?.customer_employee.name,
      },
    },
    avatarUrl: data.avatar_url ?? undefined,
    isExpenseApprover: Boolean(data.is_expense_approver),
  };
}
