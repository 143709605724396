import { FC } from "react";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgTrash } from "~/components/Icon/icons";
import { Text } from "~/components/Text";

export type RemoveItemButtonProps = {
  onRemove: () => void;
};

export const RemoveItemButton: FC<RemoveItemButtonProps> = ({ onRemove }) => {
  return (
    <Flex
      css={{
        backgroundColor: "transparent",
        border: "1px solid $neutrals-dark",
        borderRadius: "$md",
        padding: "4px",
        px: "8px",
        cursor: "pointer",
        color: "$neutrals-dark",
        transition: "background-color 0.2s",

        "&:hover": {
          backgroundColor: "$neutrals-light",
          color: "$neutrals-white",
          fill: "$neutrals-white",
          borderColor: "$neutrals-light",
        },
      }}
      onClick={onRemove}
      gap="2"
      align="center"
    >
      <Icon as={SvgTrash} />
      <Text size="2" fw="500">
        Excluir item
      </Text>
    </Flex>
  );
};
