import { useContext } from "react";
import { Icon, type IconProps } from "../Icon";
import { SvgAlert, SvgAlertTriangle, SvgCheck, SvgInfo } from "../Icon/icons";
import { AlertContext } from "./AlertContext";

export type AlertIconProps = IconProps;

export const AlertIcon = (props: IconProps) => {
  const { variant } = useContext(AlertContext);

  switch (variant) {
    case "success":
      return <Icon as={SvgCheck} size="lg" {...props} />;
    case "error":
      return <Icon as={SvgAlert} size="lg" {...props} />;
    case "warning":
      return <Icon as={SvgAlertTriangle} size="lg" {...props} />;
    default:
      return <Icon as={SvgInfo} size="lg" {...props} />;
  }
};

AlertIcon.displayName = "AlertIcon";
