import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { QueryTimes } from "~/application/constants";
import { OrderStatus } from "~/application/types";
import {
  customerOrderService,
  orderMessageService,
  orderService,
} from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useFetchRoadQuery } from "~/presentation/shared/hooks/useFetchRoadQuery";
import { getOrderStatus } from "~/presentation/shared/utils";
import { logError } from "~/presentation/shared/utils/errors";
import { log } from "~/utils/log";
import { groupCustomerOrderByDate } from "../../utils";
import { OrdersContainer } from "./OrdersContainer";
import { useCustomerOrdersTab } from "./hooks/useCustomerOrdersTab";

const LOG_TAG = "Order/OrdersPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar pedidos",
  ISSUER_SUCCESS_NOTIFY: "Solicitante notificado com sucesso",
  ISSUER_ERROR_NOTIFY: "Falha ao notificar o solicitante",
} as const;

export type OrderSearch = {
  readonly orderNumber: string;
  readonly employeeName: string;
  readonly issuerName: string;
  readonly approverName: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly tracker: string;
};

export function OrdersPage() {
  const { user, contexts } = useUser();
  const customerId = user.customer?.uuid || contexts.customer?.uuid;

  const [searches, setSearches] = useState<OrderSearch | undefined>();
  const [search] = useDebounce<typeof searches>(searches, 700);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const tabOrderStatus = useCustomerOrdersTab();

  const status =
    tabOrderStatus === OrderStatus.APPROVED
      ? [tabOrderStatus, OrderStatus.PENDING_ISSUE]
      : [tabOrderStatus];

  const { data, isLoading } = useQuery(
    [
      QueryKeys.CUSTOMER_ORDERS,
      customerId,
      currentPage,
      tabOrderStatus,
      search,
    ],
    () =>
      customerOrderService.find({
        customerId,
        page: currentPage,
        status: search ? undefined : status,
        orderNumber: search?.orderNumber,
        employeeName: search?.employeeName,
        issuerName: search?.issuerName,
        approverName: search?.approverName,
        startDate: search?.startDate,
        endDate: search?.endDate,
        tracker: search?.tracker,
      }),
    {
      staleTime: QueryTimes.SMALL,
      refetchInterval: 1000 * 60 * 0.5, // 30 seconds
      refetchOnMount: "always",
      refetchOnWindowFocus: true,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const orderSummaryStatus = useMemo(() => {
    return data?.data.summary.byStatus.map((s) => {
      return {
        id: s.status,
        total: s.total,
        title: getOrderStatus(s.status),
      };
    });
  }, [data?.data.summary]);

  const customerOrdersGroupsList = useMemo(
    () => data?.data && groupCustomerOrderByDate(data.data.orders),
    [data?.data.orders]
  );

  const onIssuerNotify = () =>
    useMutation(
      async (orderUuid: string) => await orderMessageService.notify(orderUuid),
      {
        onMutate: (item) => {
          log.i(LOG_TAG, `Notifying Issuer(${item})`);
        },
        onSuccess: (_, item) => {
          log.i(LOG_TAG, `Successfully Notify Issuer(${item})`);

          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.ISSUER_SUCCESS_NOTIFY,
            "success"
          );
        },
        onError: (error) => {
          log.e(LOG_TAG, error);

          snackbarService.showSnackbar(
            SNACKBAR_MESSAGES.ISSUER_ERROR_NOTIFY,
            "error"
          );
        },
      }
    );

  const { mutate: onCancelOrder } = useMutation(
    (orderUuid: string) => orderService.cancel(orderUuid),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar("Falha ao cancelar o pedido", "error");
      },
    }
  );

  const onRefetchRoadQuery = useFetchRoadQuery({
    onCancelOrder,
  });

  const handleOrderNumberChangeSearch = useCallback((text: string) => {
    if (/\D/.test(text)) {
      return;
    }

    setSearches((old) => ({ ...old, orderNumber: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleEmployeeNameChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, employeeName: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleIssuerNameChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, issuerName: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleApproverNameChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, approverName: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleStartDateChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, startDate: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleEndDateChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, endDate: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleTrackerChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, tracker: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleCleanFilters = useCallback(() => {
    setSearches({
      orderNumber: "",
      employeeName: "",
      approverName: "",
      issuerName: "",
      endDate: "",
      startDate: "",
      tracker: "",
    });

    setCurrentPage(1);
  }, []);

  return (
    <OrdersContainer
      isLoading={isLoading}
      customerOrdersGroupsList={customerOrdersGroupsList}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      onGoToPage={setCurrentPage}
      activeStatusTab={tabOrderStatus}
      tabSummary={orderSummaryStatus}
      searches={searches}
      setStartDate={handleStartDateChangeSearch}
      setEndDate={handleEndDateChangeSearch}
      setOrderNumber={handleOrderNumberChangeSearch}
      setEmployeeName={handleEmployeeNameChangeSearch}
      setIssuerName={handleIssuerNameChangeSearch}
      setApproverName={handleApproverNameChangeSearch}
      setTracker={handleTrackerChangeSearch}
      onClearFilters={handleCleanFilters}
      onIssuerNotify={onIssuerNotify}
      onRefetchRoadQuery={onRefetchRoadQuery}
    />
  );
}
