import { useMutation } from "@tanstack/react-query";
import queryString from "query-string";
import { useCallback, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CustomerEmployee, HotelQuery, OfflineHotelQuery } from "~/application/types";
import { customerEmployeeService, orderService, travelerService } from "~/application/usecases";
import { offlineHotelService } from "~/application/usecases/OfflineHotel";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { logError } from "~/presentation/shared/utils/errors";
import { toISOString } from "~/utils/date.utils";
import { useBooking } from "../../contexts/BookingContext";
import { BookingOfflineHotelContext } from "./contexts/BookingOfflineHotelContext";
import { useOfflineHotelParams } from "./hooks/useOfflineHotelParams";
import { useOfflineHotelReducer } from "./hooks/useOfflineHotelReducer";

const logTag = "Booking/BookingHotel/BookingOfflineHotelContainer";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao procurar hotéis offline",
  REQUEST_OFFLINE_HOTEL_ERROR_MESSAGE: "Falha ao solicitar hospedagem offline",
  REQUEST_OFFLINE_HOTEL_SUCCESS_MESSAGE: "Hotel offline solicitado com sucesso!",
} as const;

export function BookingOfflineHotelContainer() {
  const { user, contexts } = useUser();
  const [quantityRooms, setQuantityRooms] = useState(1);
  const navigate = useNavigate();

  let { order } = useBooking();

  const customerId = contexts.customer?.uuid;
  const hotelQuery = useOfflineHotelParams();
  const hotelReducer = useOfflineHotelReducer();
  const [issuer, setIssuer] = useState<CustomerEmployee>();

  const fetchTravelersByName = useCallback(
    async (name: string) =>
      customerEmployeeService
        .find({ name, customerId, isActive: "Ativo" })
        .then((data) => data.data),
    [contexts]
  );
  
  const { mutateAsync: onRequestOfflineHotel, isLoading: isRequestingOfflineHotel } = useMutation(
    async (data: OfflineHotelQuery) => {
      const travelersIds = data.accommodations.reduce((acc, accomodation) => {
        acc.push(...accomodation.travelers.filter((t) => t).map((t) => t.uuid));
        return acc;
      }, [] as string[]);

      if (!order) {
        order = await orderService.create({
          customerId,
          issuerId: user.profiles.customer.uuid ?? issuer?.uuid,
          travelersIds,
        });
      }

      await offlineHotelService.request({
        orderId: order.uuid,
        data: { ...data, customerId },
      });

      return order;
    },
    {
      onSuccess: (order, item) => {
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.REQUEST_OFFLINE_HOTEL_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
        navigate(`/pedidos/${order.uuid}`);
      },
      onError: (error) => {
        logError({
          error,
          logTag,
          defaultErrorMessage: SNACKBAR_MESSAGES.REQUEST_OFFLINE_HOTEL_ERROR_MESSAGE,
        });
      },
    }
  );

  const onSearchHotels = useCallback(
    (data: HotelQuery) => {
      const url = queryString.stringifyUrl({
        url: "/busca/hospedagens/resultados",
        query: {
          city: JSON.stringify(data.city),
          accommodations: JSON.stringify(data.accommodations),
          checkIn: toISOString(data.checkInDate as Date),
          checkOut: toISOString(data.checkOutDate as Date),
          orderId: order?.uuid,
        },
      });

      navigate(url);
    },
    [order, navigate]
  );

  return (
    <BookingOfflineHotelContext.Provider
      value={{
        hotelQuery,
        hotelReducer,
        quantityRooms,
        isRequestingOfflineHotel,
        fetchTravelersByName,
        onRequestOfflineHotel,
        setQuantityRooms,
        onSearchHotels,
        setIssuer,
      }}
    >
      <Outlet />
    </BookingOfflineHotelContext.Provider>
  );
}
