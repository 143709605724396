import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { Actions, Phase } from "~/application/types";
import { Button } from "~/components/Button";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { PhaseListItem } from "~/core/modules/Customer/pages/CustomerPage/views/Phases/components/PhaseListItem";
import { Flex } from "~/components/Flex";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { SearchBar } from "~/components/Input";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Link } from "~/components/Link";
import { IconButton } from "~/components/IconButton";
import { routes } from "~/application/theme/routes";

export interface PhasesContainerProps {
  data?: Phase[];
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  onCreatePhase: () => void;
  onUpdatePhase: (item: Phase) => void;
  onGoToPage: (value: number) => void;
  onSearch: (value: string) => void;
}

export function PhasesContainer({
  data,
  currentPage,
  lastPage,
  isLoading,
  onCreatePhase,
  onGoToPage,
  onUpdatePhase,
  onSearch,
}: PhasesContainerProps) {
  const [searchParams] = useSearchParams();
  const project = searchParams.get('projeto');

  const { contexts, profile } = useUser();
  
  const listRenderer = useCallback(
    (item: Phase) => (
      <PhaseListItem
        key={item.uuid}
        data={item}
        onEditClick={onUpdatePhase}
      />
    ),
    [onUpdatePhase]
  );

  const canCreatePhase = useVerifyActions({
    actions: [Actions.CreateUpdateProject],
    contexts,
    profile,
  });

  return (
    <Flex direction="column" gap="4">
      <AppBar>
        <AppBarContainer>
          <Flex align="center" gap="6">
            <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
              <Link to={routes.Configuration.Projects.root}>
                <IconButton size="md">
                  <Icon as={SvgChevronLeft} />
                </IconButton>
              </Link>
            </Tooltip>

            <AppBarHeading title={`Fases do Projeto ${project}`} />
          </Flex>

          <AppBarActions>
            <Flex gap="4">
              <SearchBar placeholder="Buscar fase" onSearchChange={(name) => onSearch(name)} />
              {canCreatePhase && (
                <Button title="Novo fase" color="primary" onClick={onCreatePhase}>
                  <Icon as={SvgPlus} />
                  <Text>Nova fase</Text>
                </Button>
              )}
            </Flex>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui fases cadastradas</Text>
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Flex>
  );
}
