import * as React from "react";
import { SVGProps } from "react";

const SvgInactiveCustomer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 19a1 1 0 1 0 2 0h-2Zm1-2h1-1Zm-4-4v-1 1Zm-7 0v-1 1Zm-4 4H0h1Zm-1 2a1 1 0 1 0 2 0H0ZM18.707 5.293a1 1 0 1 0-1.414 1.414l1.414-1.414Zm3.586 6.414a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm1.414-5a1 1 0 0 0-1.414-1.414l1.414 1.414Zm-6.414 3.586a1 1 0 0 0 1.414 1.414l-1.414-1.414ZM17 19v-2h-2v2h2Zm0-2a5 5 0 0 0-1.464-3.536L14.12 14.88A3 3 0 0 1 15 17h2Zm-1.464-3.536A5 5 0 0 0 12 12v2a3 3 0 0 1 2.121.879l1.415-1.415ZM12 12H5v2h7v-2Zm-7 0a5 5 0 0 0-3.536 1.464L2.88 14.88A3 3 0 0 1 5 14v-2Zm-3.536 1.464A5 5 0 0 0 0 17h2a3 3 0 0 1 .879-2.121l-1.415-1.415ZM0 17v2h2v-2H0ZM11.5 5a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5h-2Zm-3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5V8Zm-3-3a3 3 0 0 1 3-3V0a5 5 0 0 0-5 5h2Zm3-3a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2Zm8.793 4.707 5 5 1.414-1.414-5-5-1.414 1.414Zm5-1.414-5 5 1.414 1.414 5-5-1.414-1.414Z"
      fill="#079455"
    />
  </svg>
);

export default SvgInactiveCustomer;
