import { FC } from "react";
import { Road } from "~/application/types";
import { AppBar } from "~/components/AppBar";
import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { Col, Row } from "~/components/Grid";
import { Stepper, StepperItem } from "~/components/Stepper";
import { TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { BookingRoadSteps } from "../constants";
import { useRoadsPage } from "../contexts/RoadsPageContext";
import { ConfirmationSection } from "./sections/ConfirmationSection";
import { GoRoadsSection } from "./sections/GoRoadsSection";
import { ReturnRoadsSection } from "./sections/ReturnRoadsSection";
import { RoadBookingBarSection } from "./sections/RoadBookingBarSection";
import { RoadCartSection } from "./sections/RoadCartSection";
import { TravelersSection } from "./sections/TravelersSection";
import { RoadsPageUIProps } from "./types";

export const RoadsPageUI: FC<RoadsPageUIProps> = ({
  bookingSteps,
  currentStep,
  bookingStep,
  bookingInfo,
  setCurrentStep,
  onOpenDetails,
}) => {
  const goRoad = bookingInfo.goRoadSelected;
  const returnRoad = bookingInfo.returnRoadSelected;

  const { bookingStep: contextBookingStep, roadParams, isManyStretch } = useRoadsPage();
  const showStepper =
    contextBookingStep.current === "select-travelers" ||
    contextBookingStep.current === "confirmation";

  const onOpenGoRoadDetails = (road: Road) => {
    return onOpenDetails({
      road,
      seats: goRoad?.seats,
      seatsConnection: goRoad?.seatsConnection,
    });
  };

  const onOpenReturnRoadDetails = (road: Road) => {
    return onOpenDetails({
      road,
      seats: returnRoad?.seats,
      seatsConnection: returnRoad?.seatsConnection,
    });
  };

  const bookingMobileSteps = bookingSteps.slice(0, 2);

  return (
    <Box css={{ margin: "0 auto" }}>
      <AppBar>
        <RoadBookingBarSection currentStep={currentStep} setCurrentStep={setCurrentStep} />
      </AppBar>

      <Container
        css={{
          pt: "$10",
          pb: "1000px",
          "@mxlg": {
            p: 30,
          },
        }}
      >
        <Stepper
          activeValue={currentStep}
          setActiveValue={setCurrentStep}
          css={{
            mb: "$6",
            "@mxmd": {
              display: "none",
            },
          }}
        >
          {bookingSteps.map((item, index) => (
            <StepperItem enabled={index <= currentStep} value={index} key={item.key}>
              <Text>{item.title}</Text>
            </StepperItem>
          ))}
        </Stepper>

        <Stepper
          activeValue={currentStep}
          setActiveValue={setCurrentStep}
          css={{
            mb: "$6",
            display: showStepper ? "none" : "flex",
            "@md": {
              display: "none",
            },
          }}
        >
          {bookingMobileSteps.map((item, index) => (
            <StepperItem
              enabled={index <= currentStep}
              value={index}
              css={{ width: "100%" }}
              key={item.key}
            >
              <Text>{item.title}</Text>
            </StepperItem>
          ))}
        </Stepper>

        <Tabs value={bookingStep} defaultValue={BookingRoadSteps.SELECT_GO_TICKET}>
          <Row>
            <Col>
              {roadParams?.map((road, index) => (
                <TabContent value={`${index}`} key={`${index}`}>
                  <GoRoadsSection onOpenDetails={onOpenGoRoadDetails} />
                </TabContent>
              ))}

              {!isManyStretch && (
                <>
                  <TabContent value={BookingRoadSteps.SELECT_GO_TICKET}>
                    <GoRoadsSection onOpenDetails={onOpenGoRoadDetails} />
                  </TabContent>

                  <TabContent value={BookingRoadSteps.SELECT_RETURN_TICKET}>
                    <ReturnRoadsSection onOpenDetails={onOpenReturnRoadDetails} />
                  </TabContent>
                </>
              )}

              <TabContent value={BookingRoadSteps.SELECT_TRAVELERS}>
                <TravelersSection />
              </TabContent>

              <TabContent value={BookingRoadSteps.CONFIRMATION}>
                <ConfirmationSection onOpenDetails={onOpenDetails} />
              </TabContent>
            </Col>

            <Col
              sz="3"
              css={{
                "@mxlg": {
                  width: "0%",
                  p: 0,
                },
              }}
            >
              <RoadCartSection />
            </Col>
          </Row>
        </Tabs>
      </Container>
    </Box>
  );
};
