import { styled } from "~/application/theme";

export const Root = styled("div", {
  // Reset
  boxSizing: "border-box",
  appearance: "none",

  // Custom Reset?
  position: "relative",
  overflow: "hidden",

  // Custom
  borderRadius: "$md",
  backgroundColor: "$neutrals-white",
  border: "1px solid $colors$neutrals-light",
});
