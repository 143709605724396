import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type InputAdornmentProps = ComponentProps<typeof InputAdornmentRoot> & {
  position?: "start" | "end";
  disablePointerEvents?: boolean;
};

const InputAdornmentRoot = styled("span", {
  // Reset
  boxSizing: "border-box",
  display: "flex",

  // Custom
  whiteSpace: "nowrap",

  "&[data-position=start]": {
    marginRight: "$2",
  },
  "&[data-position=end]": {
    marginLeft: "$2",
  },

  "&[data-disable-pointer-events=true]": {
    pointerEvents: "none",
  },
});

export const InputAdornment = ({
  position,
  disablePointerEvents = false,
  ...props
}: InputAdornmentProps) => {
  return (
    <InputAdornmentRoot
      data-position={position}
      data-disable-pointer-events={disablePointerEvents}
      {...props}
    />
  );
};

InputAdornment.displayName = "InputAdornment";
