import { FC, useCallback } from "react";
import { dialogService } from "~/components/DialogStack";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import { RoadListItem } from "~/presentation/shared/components/RoadListItem";
import { RoadSeatFormData, RoadSeatsDialog } from "~/presentation/shared/views/RoadSeatsDialog";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { BookingRoadListItemProps } from "./types";

export const BookingRoadListItem: FC<BookingRoadListItemProps> = ({
  data,
  maxSeats,
  minSeats,
  isSelected,
  TagsElement,
  isGoRoad,
  onOpenDetails,
  onSelectRoad,
  currentRoadSeats,
}) => {
  const { order } = useRoadsPage();
  const { travelers } = useRemakeSearch();

  const onSelectSeats = useCallback(
    (props: RoadSeatFormData) => {
      onSelectRoad(data, props);

      dialogService.popDialog();
    },
    [data, onSelectRoad]
  );

  const onOpenSeats = useCallback(() => {
    dialogService.showDialog(
      <RoadSeatsDialog
        data={data}
        minSeats={minSeats}
        maxSeats={maxSeats}
        onSelectSeats={onSelectSeats}
        travelers={order?.travelers ?? travelers}
        isGoRoad={!!isGoRoad}
        currentRoadSeats={currentRoadSeats}
      />
    );
  }, [data, minSeats, maxSeats, order, isGoRoad, onSelectSeats, travelers, currentRoadSeats]);

  return (
    <RoadListItem
      data={data}
      isSelected={isSelected}
      onSelect={onOpenSeats}
      onOpenDetails={onOpenDetails}
      isGoRoad={isGoRoad}
      TagsElement={TagsElement}
    />
  );
};

BookingRoadListItem.displayName = "BookingRoadListItem";
