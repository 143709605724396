import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "~/application/constants";
import { CostCenter } from "~/application/types";
import { costCenterService } from ".";
import { IFindCostCenterParams } from "./ICostCenterService";

export interface FetchCostCentersOptions {
  readonly cacheTime: number;
  readonly retry: number;
  readonly onError: (err: unknown) => void;
  readonly onSuccess: (data: CostCenter[]) => void;
}

export interface FetchCostCentersResult {
  costCenters?: CostCenter[];
  isLoadingCostCenters: boolean;
}

export function fetchCostCenters(
  { customerId, name }: IFindCostCenterParams,
  fn?: () => Promise<CostCenter[]>,
  options = {} as FetchCostCentersOptions
): FetchCostCentersResult {
  const defaultOptions = {
    cacheTime: 0,
    retry: 2,
  };

  const result = useQuery(
    [QueryKeys.CUSTOMER_COST_CENTERS],
    () => (fn ? fn() : costCenterService.find({ customerId, name })),
    {
      ...defaultOptions,
      ...options,
    }
  );

  return { costCenters: result.data, isLoadingCostCenters: result.isLoading };
}
