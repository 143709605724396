import { FC } from "react";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClose } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";

export type AmenitiesModalProps = {
  amenities: string[];
  onClose: () => void;
};

export const AmenitiesModal: FC<AmenitiesModalProps> = ({
  amenities,
  onClose,
}) => {
  return (
    <Card
      css={{
        maxWidth: "400px",
      }}
    >
      <CardBody
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Flex align="center" justify="between" gap="2">
          <Text size="5" fw="600">
            Oferece
          </Text>

          <IconButton size="lg" onClick={() => onClose()}>
            <Icon as={SvgClose} />
          </IconButton>
        </Flex>

        <Text>Obs: Amenidades sujeito a cobrança por parte do hotel.</Text>
        <Flex
          css={{
            flexWrap: "wrap",
          }}
          gap="3"
        >
          {amenities.map((amenity, index) => (
            <Tag key={index} variant="info-light">
              {amenity}
            </Tag>
          ))}
        </Flex>
      </CardBody>
    </Card>
  );
};
