import { FC } from "react";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { DialogHeader } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgOrder } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";

export type ConfirmOrderDialogProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmOrderDialog: FC<ConfirmOrderDialogProps> = ({ onCancel, onConfirm }) => {
  return (
    <Card
      css={{
        boxShadow: "$sm",
        height: "340px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "$5",
        px: "$15",
        gap: "$4",
        textAlign: "center",
        width: "600px",
        position: "relative",
        color: "$neutrals-dark",
        "@mxsm": {
          width: "60%",
          margin: "0 auto",
          overflow: "hidden",
        },
      }}
    >
      <Icon
        size="4xl"
        as={SvgOrder}
        css={{
          fill: "$primary-base",
        }}
      />
      <IconButton
        css={{
          position: "absolute",
          right: "$5",
          stroke: "$primary-base",
        }}
        onClick={onCancel}
      >
        <Icon as={SvgClose} />
      </IconButton>
      <DialogHeader
        css={{
          borderBottom: "none",
          p: 0,
        }}
      >
        <H4
          fw="600"
          css={{
            width: "390px",
            gap: "$13",
            color: "$neutrals-black",
            "@mxmd": {
              fontSize: "$lg",
            },
          }}
        >
          Finalizar pedido
        </H4>
      </DialogHeader>
      <Flex
        direction="column"
        css={{
          width: "85%",
          textAlign: "center",
          gap: "$3",
        }}
      >
        <Text>Todos os itens do seu pedido foram emitidos. Você deseja finalizar o pedido?</Text>
      </Flex>
      <Flex
        gap="2"
        css={{
          width: "100%",
          mt: "$7",
        }}
        align="center"
        justify="center"
      >
        <Button
          onClick={onCancel}
          variant="tertiary"
          css={{
            flex: "1",
          }}
        >
          Voltar
        </Button>
        <Button
          onClick={onConfirm}
          css={{
            flex: "1",
          }}
        >
          Finalizar pedido
        </Button>
      </Flex>
    </Card>
  );
};
