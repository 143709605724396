import { useCallback, useMemo, useRef, useState } from "react";
import { UseInputControlOptions, UseInputControlReturn } from "./types";

export function useInputControl<TValue>({
  value: valueProp,
  defaultValue: defaultValueProp,
  onChange: onChangeProp,
  ...otherProps
}: UseInputControlOptions<TValue>): UseInputControlReturn<TValue> {
  const inputRef = useRef<HTMLInputElement>(null);

  const isControlled = useMemo(() => valueProp !== undefined, [valueProp]);

  const [value, setValue] = useState<TValue | undefined>(
    valueProp ?? defaultValueProp
  );

  const onChange = useCallback(
    (option: TValue) => {
      if (!isControlled) setValue(option);

      onChangeProp?.(option);
    },
    [isControlled, onChangeProp]
  );

  const inputProps = useMemo(
    () => ({
      ...otherProps,
    }),
    [otherProps]
  );

  return {
    inputRef,
    isControlled,
    value,
    inputProps,
    onChange,
    setValue,
  };
}
