import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { DropdownMenuButton } from "./DropdownMenuButton";

export type DropdownMenuItemsProps = ComponentProps<typeof DropdownMenuItems>;

export const DropdownMenuItems = styled("div", {
  // Reset
  boxSizing: "border-box",
  display: "flex",

  // Custom
  flexDirection: "column",
  padding: "$2",
  gap: "$2",

  [`& ${DropdownMenuButton}`]: {
    width: "100%",
  },
});
