import { FC } from "react";
import { DialogBody } from "~/components/Dialog";
import { FormDialog } from "~/components/FormDialog";
import { Container } from "~/components/Container";
import { FlightSegment } from "~/application/types";
import { OrderFlightDetails } from "./OrderFlightDetails";

export interface OrderFlightDetailsDialogProps {
  data: FlightSegment[];
  onCloseClick: () => void;
  flightValue: number;
  tracker: string | null;
}

export const OrderFlightDetailsDialog: FC<OrderFlightDetailsDialogProps> = ({
  onCloseClick,
  flightValue,
  data,
  tracker,
  ...props
}) => {
  return (
    <Container size="8" fixed css={{ overflow: "auto" }}>
      <FormDialog
        title="Detalhes da passagem"
        onClickDismissButton={onCloseClick}
        css={{
          "@mxlg": {
            width: "95%",
            margin: "0 auto",
          },
        }}
        {...props}
      >
        <DialogBody css={{ p: "$6" }}>
          <OrderFlightDetails tracker={tracker} segments={data} flightValue={flightValue} />
        </DialogBody>
      </FormDialog>
    </Container>
  );
};

OrderFlightDetailsDialog.displayName = "OrderFlightDetailsDialog";
