import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Select } from "~/components/Input";
import { Text } from "~/components/Text";
import { Spinner } from "~/components/Spinner";
import ReactQuill from "react-quill";
import { useState, useEffect, useCallback, useMemo } from "react";
import { CrmData } from "~/application/usecases/crm/ICrmService";
import { CustomerCRM } from "~/application/types/entities/CustomerCrm.type";
import { crmObsClean, formats, modules, serviceTypeOptions } from "../utils";
import { Buffer } from "buffer/";
import { styled } from "~/application/theme";
import SlateEditor from "~/components/SlateEditor/SlateEditor";
import { convertToHTML, htmlToSlate } from "~/utils/slateValue.util";
import { Descendant } from "slate";

const CrmSchema = yup.object().shape({
  uuid: yup.string().optional(),
  serviceType: yup.string().required("O tipo de fornecimento é obrigatório"),
  crmObs: yup.string().optional(),
});

export type CrmSchema = yup.InferType<typeof CrmSchema>;

export interface CustomerCrmDialogProp {
  isNew?: boolean;
  defaultData?: CrmData;
  crmData?: CustomerCRM[];
  onCloseClick?: () => void;
  onSubmitProp: (data: CrmData) => void;
}

export const ReactQuillRoot = styled(ReactQuill, {});

export function CustomerCrmDialog({
  isNew = false,
  crmData,
  defaultData,
  onCloseClick,
  onSubmitProp,
}: CustomerCrmDialogProp) {
  const { control, handleSubmit, setValue, formState } = useForm<CrmSchema>({
    defaultValues: {
      serviceType: defaultData?.serviceType || "",
      crmObs: defaultData?.obs || "",
    },
    resolver: yupResolver(CrmSchema),
  });

  const [value, setValueText] = useState(htmlToSlate(defaultData?.obs || ''));

  const [selectedServiceType, setSelectedServiceType] =
    useState<{ label: string; type: string } | null>(null);

  const filteredServiceTypeOptions = serviceTypeOptions.filter(
    (option) => !crmData?.some((data) => data.serviceType === option.type)
  );

  useEffect(() => {
    if (defaultData?.serviceType) {
      const initialOption = serviceTypeOptions.find(
        (option) => option.type === defaultData.serviceType
      );
      setSelectedServiceType(initialOption || null);
    }
  }, [defaultData]);

  const htmlText = useMemo(() => convertToHTML(value), [value])
  const parseSlate = useMemo(() => htmlToSlate(htmlText), [htmlText]) as Descendant[]

  const handleServiceTypeChange = useCallback((option: { label: string; type: string }) => {
    setSelectedServiceType(option);
    setValue("serviceType", option.type);
  }, [setValue]);
  
  
  const encoded = useCallback((obs: string) => Buffer.from(obs || "").toString("base64"), []);
  
  const onSubmit = (data: CrmSchema) => {
    onSubmitProp({
      uuid: defaultData?.uuid,
      serviceType: data.serviceType,
      obs: encoded(crmObsClean(htmlText)),
    });
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          title={isNew ? "Novo CRM" : "Editar CRM"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button type="submit" disabled={formState.isSubmitting}>
              {formState.isSubmitting && (
                <Spinner
                  css={{
                    borderLeftColor: "$neutrals-white",
                    width: "$4",
                    height: "$4",
                    borderWidth: "2px",
                  }}
                />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Row css={{ mb: "$5" }}></Row>

            <Row>
              <Col sz="12">
                <FormControl name="serviceType" control={control} required>
                  <FieldLabel>Tipo de fornecimento</FieldLabel>
                  <Select
                    options={filteredServiceTypeOptions}
                    disabled={!!defaultData?.serviceType}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.type}
                    value={selectedServiceType}
                    placeholder="Selecione tipo de fornecimento"
                    onChange={handleServiceTypeChange}
                  />
                  {formState.errors.serviceType && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.serviceType.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Row css={{ mt: "$4", py: "$3" }}>
              <FormControl name="crmObs" control={control}>
                <FieldLabel>Observações</FieldLabel>
                <SlateEditor value={parseSlate} onChangeText={setValueText} />
              </FormControl>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
