import { User, VehicleQuery, VehicleQueryLinks } from "~/application/types";
import { vehicleQueryService } from "~/application/usecases";

export class CacheClient {
  public cachedItems: Record<string, any>;

  constructor() {
    this.cachedItems = {};
  }

  retrieveItem = async <T>(key: string, promise: () => Promise<T>): Promise<T> => {
    const itemValue = this.cachedItems[key];
    if (itemValue) return itemValue;

    const response = await promise();
    this.cachedItems[key] = response;

    return response;
  };

  clear = () => {
    this.cachedItems = {};
  };
}

export class VehicleQueryClient {
  private readonly vehicleQuery: VehicleQuery;
  private readonly user: User;

  private cacheClient: CacheClient;

  private links?: VehicleQueryLinks;

  constructor(vehicleQuery: VehicleQuery, user: User) {
    this.user = user;
    this.vehicleQuery = vehicleQuery;

    this.cacheClient = new CacheClient();
  }

  requestLinks = async () => {
    if (this.links) return this.links;

    return await vehicleQueryService
      .query(this.user.customer.uuid, {
        dateGetSelected: this.vehicleQuery.dateGetSelected,
        dateReturnSelected: this.vehicleQuery.dateReturnSelected,
        destinationSelected: this.vehicleQuery.destinationSelected,
        originSelected: this.vehicleQuery.originSelected,
        timeGetSelected: this.vehicleQuery.timeGetSelected,
        timeReturnSelected: this.vehicleQuery.timeReturnSelected,
      })
      .then((value) => {
        this.links = value;
        return this.links;
      });
  };

  query = async (): Promise<VehicleQueryLinks> => {
    return await this.cacheClient.retrieveItem("query", () =>
      vehicleQueryService.query(this.user.customer.uuid, {
        dateGetSelected: this.vehicleQuery.dateGetSelected,
        dateReturnSelected: this.vehicleQuery.dateReturnSelected,
        destinationSelected: this.vehicleQuery.destinationSelected,
        originSelected: this.vehicleQuery.originSelected,
        timeGetSelected: this.vehicleQuery.timeGetSelected,
        timeReturnSelected: this.vehicleQuery.timeReturnSelected,
        reasonTrip: this.vehicleQuery.reasonTrip,
      })
    );
  };

  queryVehicles = async (): Promise<VehicleQueryLinks> => {
    return await this.cacheClient.retrieveItem("vehicles", () => this.query());
  };
}
