import { QueryObserverResult } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { Order, RoadQuery, RoadQueryLinks } from "~/application/types";
import { BookingRoadQuery, RoadBookingStep, RoadReducer } from "../hooks/useRoadReducer/types";
import { DeleteOrderRoadParams } from "~/presentation/shared/hooks/useDeleteOrderRoad/types";
import { useRoadBudgetResult } from "../hooks/useRoadBudget/type";

export interface RoadsPageContextType {
  isOneWay: boolean;
  roadReducer: RoadReducer;
  roadQuery: BookingRoadQuery;
  bookingStep: RoadBookingStep;
  order?: Order;
  refetchRoadQueryLinks: () => Promise<QueryObserverResult<RoadQueryLinks>>;
  deleteOrderRoad: (data: DeleteOrderRoadParams) => Promise<void>;
  roadBudget: useRoadBudgetResult;
  roadParams?: RoadQuery[];
  isManyStretch: boolean;
}

export const RoadsPageContext = createContext({} as RoadsPageContextType);

export const useRoadsPage = () => useContext(RoadsPageContext);

export const RoadsPageProvider = RoadsPageContext.Provider;
